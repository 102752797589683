import type {
  ILoadingState,
  LoadingActionTypes,
} from '../constants/actionTypes';
import { LOADING_START, LOADING_STOP } from '../constants/actionTypes';

const INITIAL_STATE: ILoadingState = {
  loading: false,
};

export default (
  state: ILoadingState = INITIAL_STATE,
  action: LoadingActionTypes
): ILoadingState => {
  switch (action.type) {
    case LOADING_START:
      return { loading: true };
    case LOADING_STOP:
      return { loading: false };
    default:
      return state;
  }
};

export const loadingStateSelector = (state: ILoadingState): boolean =>
  state.loading;
