import type { EmptyParams } from 'api/client';
import { hosts } from 'api/hosts';
import { toaster } from 'toaster';
import { FEEDBACK_MAIL } from 'constants/contact';
import type { TicketTeam } from 'api/tickets/types';
import { apiFetchHandler } from 'api/clientFetch';

export * from 'api/tickets/types';

export type PostTicketBody = {
  title: string;
  description: string;
  team: TicketTeam;
  fallbackEmail?: string;
};

export const postTicket = apiFetchHandler<
  undefined,
  EmptyParams,
  PostTicketBody
>({
  host: hosts.data.v0,
  endpoint: '/ticket/create',
  method: 'POST',
  errorMessage: 'Could not create a new ticket',
  onError: ({ body }) => {
    toaster.show({
      message: 'Opening the mail client...',
      intent: 'none',
      icon: 'info-sign',
    });
    setTimeout(() => {
      let mailto = `mailto:${body.fallbackEmail ?? FEEDBACK_MAIL}?`;
      mailto += `subject=${body.title}&`;
      mailto += `body=${body.description.split('\n').join('%0D%0A')}`;
      window.open(mailto, '_blank');
    }, 2000);
  },
  onSuccess: () => {
    toaster.show({
      message: 'Ticket submitted successfully',
      intent: 'success',
    });
  },
  errorDescription: (e) => e.join('; '),
});
