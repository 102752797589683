import { Tooltip2 } from '@blueprintjs/popover2';

export type TooltipProps = {
  children: React.ReactNode;
  content: string | JSX.Element;
  disabled?: boolean;
  position?: 'top' | 'right' | 'bottom' | 'left';
  className?: string; // TODO: deprecate
  isOpen?: boolean;
};

export const Tooltip = ({
  children,
  content,
  disabled,
  position = 'top',
  className,
  isOpen,
}: TooltipProps): JSX.Element | null => {
  if (disabled) return <>{children}</>;
  return (
    <Tooltip2
      className={className}
      position={position}
      content={content}
      disabled={disabled}
      isOpen={isOpen}
    >
      {children}
    </Tooltip2>
  );
};
