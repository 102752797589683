import React from 'react';
import CustomPayloadModeRow from './CustomPayloadModeRow';
import styles from './styles.module.scss';
import subformStyles from './DetailedView/styles.module.scss';
import { FieldArray, ErrorMessage } from 'formik';
import type { IRawModeRow } from './CustomPayloadForm';
import { InitialValueFactory } from '../../../reducers/ui/moduleSelector';
import { Button } from '@blueprintjs/core';

interface IProps {
  values: any;
  readOnly: boolean;
}

const CustomPayloadModes = ({ values, readOnly }: IProps) => {
  return (
    <div>
      <div>
        <h3 className={subformStyles.title}>Operational Modes</h3>
      </div>
      <FieldArray
        name="data.modes"
        render={(arrayHelpers) => (
          <div>
            <table>
              <thead>
                <tr>
                  <th>
                    <p className={styles.modeNameHeadingCell}>Name</p>
                  </th>
                  <th>
                    <p className={styles.modeHeadingCell}>
                      Data Generation Rate [KBps]
                    </p>
                  </th>
                  <th>
                    <p className={styles.modeHeadingCell}>
                      Data Download Rate [KBps]
                    </p>
                  </th>
                  <th>
                    <p className={styles.modeHeadingCell}>
                      Power Consumption [W]
                    </p>
                  </th>
                  <th />
                </tr>
              </thead>
              <tbody>
                {values.data.modes.map((mode: IRawModeRow, index: number) => (
                  <CustomPayloadModeRow
                    key={index}
                    rowIndex={index}
                    readOnly={readOnly}
                    removeHandler={() => arrayHelpers.remove(index)}
                  />
                ))}
              </tbody>
            </table>
            <div>
              {!readOnly && (
                <Button
                  minimal
                  icon="add"
                  onClick={() =>
                    arrayHelpers.push(InitialValueFactory.operationalMode())
                  }
                />
              )}
            </div>
            <ErrorMessage
              name="data.modes"
              render={(message: any) =>
                !(message instanceof Array) && (
                  <div className="bp4-callout bp4-intent-danger bp4-icon-error">
                    {message}
                  </div>
                )
              }
            />
          </div>
        )}
      />
    </div>
  );
};

export default CustomPayloadModes;
