import Popover2 from '_atoms/Popover/Popover2';
import { useLocalisation } from 'utils/hooks/useLocalisation';
import { toaster } from 'toaster';
import booleanOverlap from '@turf/boolean-overlap';
import type { Layer } from 'datacosmos/entities/layer';
import Icon from '_atoms/Icon/Icon';
import type { PolygonLayer, ViewLayer } from 'api/views/types';
import { useMapLayers } from 'datacosmos/stores/MapLayersProvider';
import { PerformVectorOperation } from 'datacosmos/utils/vector';

interface VectorProps {
  selectedLayers: Layer[];
}

const VectorOperations = (props: VectorProps) => {
  const { translate } = useLocalisation();
  const { addLayer, removeLayer } = useMapLayers();

  const handleVectorOperation = (operationType: string) => {
    if (props.selectedLayers.length !== 2) {
      toaster.show({
        message: `${operationType.toUpperCase()} can only be applied to two layers`,
        icon: 'issue',
        intent: 'danger',
      });
      return;
    }

    const areAllSelectedLayersPolygons = props.selectedLayers.every(
      (item) => item.layerClass === 'PolygonLayer'
    );

    if (!areAllSelectedLayersPolygons) {
      toaster.show({
        message: `${operationType.toUpperCase()} can only be applied on polygon layers`,
        icon: 'issue',
        intent: 'danger',
      });
      return;
    }

    const layer1 = props.selectedLayers[0] as ViewLayer | Layer;
    const layer2 = props.selectedLayers[1] as ViewLayer | Layer;

    const doOverlap = booleanOverlap(
      (layer1 as PolygonLayer).data.geometry as GeoJSON.Polygon,
      (layer2 as PolygonLayer).data.geometry as GeoJSON.Polygon
    );

    if (!doOverlap) {
      toaster.show({
        message: `The selected layers do not overlap for ${operationType.toUpperCase()}`,
        icon: 'issue',
        intent: 'danger',
      });
      return;
    }

    PerformVectorOperation(
      operationType,
      props.selectedLayers,
      addLayer,
      removeLayer
    );
  };

  return (
    <div className="bg-surface flex items-center pl-4 pr-4 justify-between w-full gap-6 dark:bg-surface-dark dark:text-item-dark-contrast">
      <Popover2
        placement="bottom start"
        isDismissable={true}
        popupContent={
          <div className="p-1">
            <div
              className="mb-2 border-b-2 border-neutral-200 hover:bg-neutral-300 bg-surface cursor-pointer px-5 w-full dark:bg-surface-dark dark:text-item-dark-contrast"
              onClick={() => handleVectorOperation('union')}
            >
              {translate('datacosmos.layers.vectorOperations.union')}
            </div>

            <div
              className="mb-2 border-b-2 border-neutral-200 hover:bg-neutral-300 bg-surface cursor-pointer px-5 w-full dark:bg-surface-dark dark:text-item-dark-contrast"
              onClick={() => handleVectorOperation('intersection')}
            >
              {translate('datacosmos.layers.vectorOperations.intersection')}
            </div>

            <div
              className="mb-2 border-b-2 border-neutral-200 hover:bg-neutral-300 bg-surface cursor-pointer px-5 w-full dark:bg-surface-dark dark:text-item-dark-contrast"
              onClick={() => handleVectorOperation('difference')}
            >
              {translate('datacosmos.layers.vectorOperations.difference')}
            </div>
          </div>
        }
      >
        <div className="w-full gap-1 flex items-center justify-between cursor-pointer">
          <span>{translate('datacosmos.layers.vectorOperations.title')}</span>
          <Icon icon="ChevronDown" />
        </div>
      </Popover2>
    </div>
  );
};

export default VectorOperations;
