import type { ISatelliteOrbits } from 'constants/msd/geometry/satelliteOrbits/interfaces';
import { GEOMETRY_TYPE } from 'constants/msd/geometry/constants';
import type { IConstraintsObject } from 'constants/msd/geometry/interfaces';
import { SEMIMAJOR_AXIS_AND_ECCENTRICITY } from 'components/popUp/geometryForm/subForms/constants';
import { universe } from 'constants/msd/geometry/Universe';

export const PARAMETERISATION_TLE = {
  SPECIFY: 'specify',
  FETCH: 'fetch',
};

export const orbitInitialValues = (astrum: string): ISatelliteOrbits => ({
  id: '',
  name: '',
  type: GEOMETRY_TYPE.KEPLERIAN,
  force: true,

  parameterisation: SEMIMAJOR_AXIS_AND_ECCENTRICITY,
  parameterisationTle: PARAMETERISATION_TLE.SPECIFY,

  tle1: '',
  tle2: '',
  astrum,

  semimajorAxis: {
    value: universe[astrum].defaultSemimajorAxis,
    min: 0,
    max: 1000000,
    optimisation: false,
  },
  altitude: {
    value: universe[astrum].defaultSemimajorAxis,
    min: 0,
    max: 1000000,
    optimisation: false,
  },
  eccentricity: { value: 0, min: 0, max: 1, optimisation: false },

  ltan: { value: 0, min: 0, max: 24, optimisation: false },
  raan: { value: 180, min: 0, max: 360, optimisation: false },

  longitude: { value: 0, min: -180, max: 180, optimisation: false },

  inclination: { value: 0, min: 0, max: 180, optimisation: false },
  argumentOfPerigee: { value: 0, min: 0, max: 360, optimisation: false },
  trueAnomaly: { value: 0, min: 0, max: 360, optimisation: false },

  satellitesPerPlane: { value: 15, min: 0, max: 15, optimisation: false },
  firstPlaneRaan: { value: 0, min: 0, max: 360, optimisation: false },
  relativeSpacing: { value: 0, min: 0, max: 14, optimisation: false },
});

export const SatellitesOrbitConstraints: {
  [key: string]: IConstraintsObject;
} = {
  longitude: {
    min: -180,
    max: 180,
    labelStepSize: 180 - -180,
    measurement: 'deg',
    measurementType: 'angle',
  },
  ltan: {
    min: 0,
    max: 24,
    labelStepSize: 24,
    measurement: 'h',
    measurementType: 'ltan',
  },
  raan: {
    min: 0,
    max: 360,
    labelStepSize: 360,
    measurement: 'deg',
    measurementType: 'angle',
  },
  numberOfSatellites: {
    min: 1,
    max: 30,
    labelStepSize: 30 - 1,
    measurement: '',
    measurementType: '',
  },
  numberOfPlanes: {
    min: 1,
    max: 15,
    labelStepSize: 15 - 1,
    measurement: '',
    measurementType: '',
  },
  inclination: {
    min: 0,
    max: 180,
    labelStepSize: 180,
    measurement: 'deg',
    measurementType: 'angle',
  },
  semimajorAxis: {
    min: 0,
    max: 1000000,
    labelStepSize: 1000000 - 6800,
    measurement: 'km',
    measurementType: 'dist',
  },
  altitude: {
    min: 0,
    max: 1000000,
    labelStepSize: 1000000 - 6800,
    measurement: 'km',
    measurementType: 'dist',
  },
  eccentricity: {
    min: 0,
    max: 1,
    labelStepSize: 1,
    measurement: '',
    measurementType: '',
  },
  trueAnomaly: {
    min: 0,
    max: 360,
    labelStepSize: 0 - -360,
    measurement: 'deg',
    measurementType: 'angle',
  },
  argumentOfPerigee: {
    min: 0,
    max: 360,
    labelStepSize: 0 - -360,
    measurement: 'deg',
    measurementType: 'angle',
  },
  satellitesPerPlane: {
    min: 1,
    max: 15,
    labelStepSize: 15 - 1,
    measurement: '',
    measurementType: '',
  },
  firstPlaneRaan: {
    min: 0,
    max: 360,
    labelStepSize: 360,
    measurement: 'deg',
    measurementType: 'angle',
  },
  relativeSpacing: {
    min: 0,
    max: 14,
    labelStepSize: 14,
    measurement: '',
    measurementType: '',
  },
};
