import Victor from 'victor';
import area from '@turf/area';
import intersect from '@turf/intersect';
import union from '@turf/union';
import difference from '@turf/difference';
import { LayerSourceType, type Layer } from 'datacosmos/entities/layer';
import type { PolygonLayer, ViewLayer } from 'api/views/types';
import { PolygonLayerFactory } from 'datacosmos/entities/polygonLayer';

/**
 * Calculates the angle between two edges.
 * @param edge1Start The starting point of the first edge.
 * @param edge1End The ending point of the first edge.
 * @param edge2Start The starting point of the second edge.
 * @param edge2End The ending point of the second edge.
 * @returns The angle between the two edges in radians.
 */
export const angleBetweenTwoEdges = ({
  edge1Start,
  edge1End,
  edge2Start,
  edge2End,
}: {
  edge1Start: Victor;
  edge1End: Victor;
  edge2Start: Victor;
  edge2End: Victor;
}) => {
  const edge1Vector = edge1End.clone().subtract(edge1Start);
  const edge2Vector = edge2End.clone().subtract(edge2Start);
  const edge1Norm = edge1Vector.clone().normalize();
  const edge2Norm = edge2Vector.clone().normalize();

  return Math.acos(edge1Norm.clone().dot(edge2Norm));
};

/**
 * Finds the closest point on a given edge, from another point.
 * @param edgeStart The starting point of the edge.
 * @param edgeEnd The ending point of the edge.
 * @param point The point.
 * @returns The point on the edge which is closest to the given point.
 */
export const findClosestPointOnEdgeFromPoint = ({
  edgeStart,
  edgeEnd,
  point,
}: {
  edgeStart: number[];
  edgeEnd: number[];
  point: number[];
}) => {
  // Create vectors for the edge and clicked point
  const startVector = Victor.fromObject({
    x: edgeStart[0],
    y: edgeStart[1],
  });
  const endVector = Victor.fromObject({
    x: edgeEnd[0],
    y: edgeEnd[1],
  });
  const pointVector = Victor.fromObject({
    x: point[0],
    y: point[1],
  });

  const startToPoint = pointVector.clone().subtract(startVector);
  const startToEnd = endVector.clone().subtract(startVector);

  const startToPointNorm = startToPoint.clone().normalize();
  const startToEndNorm = startToEnd.clone().normalize();

  const cosine = startToPointNorm.clone().dot(startToEndNorm);

  const startToPointLength = startToPoint.magnitude();
  const startToClosestLength = startToPointLength * cosine;

  return startVector
    .clone()
    .add(startToEndNorm.clone().multiplyScalar(startToClosestLength));
};

/**
 * Finds the point on a given parallelogram edge that is closest to a clicked point, such that
 * the line between the result and the clicked point is parallel to the line between edgeStart
 * and adjacentPoint.
 * @param edgeStart The start point of the edge.
 * @param edgeEnd The end point of the edge.
 * @param clickedPoint The clicked point.
 * @param adjacentPoint The point adjacent to edgeStart (besides edgeEnd).
 * @returns The resultant point, lying on the edge.
 */
export const findClosestPointOnParallelogramEdgeFromClickedPoint = ({
  edgeStart,
  edgeEnd,
  clickedPoint,
  adjacentPoint,
}: {
  edgeStart: number[];
  edgeEnd: number[];
  clickedPoint: number[];
  adjacentPoint: number[];
}) => {
  const closestVector = findClosestPointOnEdgeFromPoint({
    edgeStart: edgeStart,
    edgeEnd: edgeEnd,
    point: clickedPoint,
  });

  const startVector = Victor.fromObject({
    x: edgeStart[0],
    y: edgeStart[1],
  });
  const endVector = Victor.fromObject({
    x: edgeEnd[0],
    y: edgeEnd[1],
  });
  const clickedVector = Victor.fromObject({
    x: clickedPoint[0],
    y: clickedPoint[1],
  });
  const adjacentVector = Victor.fromObject({
    x: adjacentPoint[0],
    y: adjacentPoint[1],
  });

  const startToEnd = endVector.clone().subtract(startVector);
  const startToEndNorm = startToEnd.clone().normalize();

  const startToClicked = clickedVector.clone().subtract(startVector);
  const startToClickedLength = startToClicked.magnitude();
  const startToClickedNorm = startToClicked.clone().normalize();

  const cosine = startToClickedNorm.clone().dot(startToEndNorm);
  const startToClosestLength = startToClickedLength * cosine;

  const closestToClicked = clickedVector.clone().subtract(closestVector);
  const closestToClickedLength = closestToClicked.magnitude();

  const slantAngle = angleBetweenTwoEdges({
    edge1Start: startVector,
    edge1End: endVector,
    edge2Start: startVector,
    edge2End: adjacentVector,
  });

  const slantDifference = closestToClickedLength / Math.tan(slantAngle);
  const newClosestPointLength = startToClosestLength - slantDifference;

  return startVector
    .clone()
    .add(startToEndNorm.clone().multiplyScalar(newClosestPointLength))
    .toArray();
};

export const addPolygonToLayers = (
  addLayer: (...newLayers: Layer[]) => void,
  polygon: GeoJSON.Feature<GeoJSON.Polygon | GeoJSON.MultiPolygon>,
  layerName: string
) => {
  addLayer(
    PolygonLayerFactory(
      LayerSourceType.GEOMETRY_LAYER,
      layerName,
      polygon as GeoJSON.GeoJSON,
      area(polygon),
      null
    )
  );
};

export const getIndividualLayerOfSelectedLayers = (
  selectedPolygons: Layer[]
) => {
  const layer1 = selectedPolygons[0] as ViewLayer | Layer;
  const layer2 = selectedPolygons[1] as ViewLayer | Layer;
  return { layer1, layer2 };
};

export const getUnionOfPolygons = (selectedPolygons: Layer[]) => {
  const { layer1, layer2 } =
    getIndividualLayerOfSelectedLayers(selectedPolygons);
  const unionResult = union(
    (layer1 as PolygonLayer).data.geometry as GeoJSON.Polygon,
    (layer2 as PolygonLayer).data.geometry as GeoJSON.Polygon
  );
  return unionResult;
};

export const getIntersectionOfPolygons = (selectedPolygons: Layer[]) => {
  const { layer1, layer2 } =
    getIndividualLayerOfSelectedLayers(selectedPolygons);
  const intersectionResult = intersect(
    (layer1 as PolygonLayer).data.geometry as GeoJSON.Polygon,
    (layer2 as PolygonLayer).data.geometry as GeoJSON.Polygon
  );
  return intersectionResult;
};

export const getDifferenceOfPolygons = (selectedPolygons: Layer[]) => {
  const { layer1, layer2 } =
    getIndividualLayerOfSelectedLayers(selectedPolygons);
  const differenceResult = difference(
    (layer1 as PolygonLayer).data.geometry as GeoJSON.Polygon,
    (layer2 as PolygonLayer).data.geometry as GeoJSON.Polygon
  );
  return differenceResult;
};

export const PerformVectorOperation = (
  operationType: string,
  layers: Layer[],
  addLayer: (...newLayers: Layer[]) => void,
  removeLayer: (id: string) => void
) => {
  switch (operationType) {
    case 'intersection':
      const intersectionResult = getIntersectionOfPolygons(layers);
      if (intersectionResult) {
        addPolygonToLayers(
          addLayer,
          intersectionResult,
          'Polygon-Intersection'
        );
        const { layer1, layer2 } = getIndividualLayerOfSelectedLayers(layers);
        removeLayer(layer1.id);
        removeLayer(layer2.id);
      }
      break;
    case 'union':
      const unionResult = getUnionOfPolygons(layers);
      if (unionResult) {
        addPolygonToLayers(addLayer, unionResult, 'Polygon-Union');
        const { layer1, layer2 } = getIndividualLayerOfSelectedLayers(layers);
        removeLayer(layer1.id);
        removeLayer(layer2.id);
      }
      break;
    case 'difference':
      const differenceResult = getDifferenceOfPolygons(layers);
      if (differenceResult) {
        addPolygonToLayers(addLayer, differenceResult, 'Polygon-Difference');
        const { layer1, layer2 } = getIndividualLayerOfSelectedLayers(layers);
        removeLayer(layer1.id);
        removeLayer(layer2.id);
      }
      break;
    default:
      break;
  }
};
