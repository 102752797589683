import type { Action } from 'redux';
import type { ThunkAction } from 'redux-thunk';
import {
  addRegionOfInterest,
  updateRegionOfInterest,
  removeRegionOfInterest,
} from 'actions/regionsOfInterest/action';
import type { IRegionOfInterest } from 'constants/regionsOfInterest/actionTypes';
import type { AppState } from 'reducers/rootReducer';
import { SubmissionError } from 'redux-form';
import polygonSelfIntersecting from 'utils/polygonSelfIntersecting';
import { resetFocus } from 'actions/focus/thunks';
import type { IGoal } from 'constants/goals/interfaceas';
import { getObjectiveName } from 'utils/getObjectiveName';
import { toaster } from 'toaster';
import { removeGoal, updateGoal } from 'actions/goal/thunks';
import { showAlert } from 'services/mapService/alert/services';

export const addRI =
  (
    regionOfInterest: IRegionOfInterest
  ): ThunkAction<void, AppState, null, Action<string>> =>
  async (dispatch, store): Promise<void> => {
    const astrum = store().astrum['current'];
    dispatch(
      addRegionOfInterest({ ...regionOfInterest, astrum: astrum.planet })
    );
  };

export const removeRI =
  (id: string): ThunkAction<void, AppState, null, Action<string>> =>
  async (dispatch, state): Promise<void> => {
    const goals = state().goals;
    const foundedElement = goals.find((g) => {
      if (typeof g.object === 'object') {
        return g.object.id === id;
      }
      return false;
    });
    if (foundedElement) {
      showAlert((): void => {
        dispatch(resetFocus());
        dispatch(removeRegionOfInterest(id));
        dispatch(removeGoal(foundedElement));
        const message = 'Goals was changed';
        toaster.show({ icon: 'warning-sign', intent: 'warning', message });
      }, `This region: '${foundedElement.object.name}' is using in Goals, do you want to remove it?`);
    } else {
      dispatch(resetFocus());
      dispatch(removeRegionOfInterest(id));
    }
  };

export const updateRI =
  (
    regionOfInterestData: IRegionOfInterest
  ): ThunkAction<void, AppState, null, Action<string>> =>
  async (dispatch, state): Promise<void> => {
    const { regionsOfInterest } = state();
    const astrum = state().astrum['current'];
    regionsOfInterest.list.forEach((regionOfInterest): void => {
      if (regionOfInterest.name === regionOfInterestData.name) {
        if (regionOfInterest.id !== regionOfInterestData.id) {
          const errorMessage = 'name_not_unique';
          throw new SubmissionError({
            name: errorMessage,
            _error: errorMessage,
          });
        }
      }
    });
    regionOfInterestData.paths = regionOfInterestData.paths.map((path) =>
      path.map((point) => {
        return {
          lat: +point.lat,
          lng: +point.lng,
          lon: +point.lon,
        };
      })
    );

    regionOfInterestData.paths.forEach((path, indexPath): void => {
      if (path.length < 4) {
        return undefined;
      }
      path.forEach((_point, indexPoint): void => {
        if (polygonSelfIntersecting({ vertex: indexPoint, full: true }, path)) {
          const errorMessage = 'self_intersecting';
          const paths = [];
          paths[indexPath] = { _error: errorMessage };
          const error: object = { _error: errorMessage, paths };
          throw new SubmissionError(error);
        }
      });
    });

    const goals = state().goals;
    const foundedElement = goals.find((g) => {
      if (typeof g.object === 'object') {
        return g.object.id === regionOfInterestData.id;
      }
      return false;
    });
    if (foundedElement) {
      foundedElement.object = regionOfInterestData;
      const goalWithName: IGoal = {
        ...foundedElement,
        name: getObjectiveName(foundedElement, () => {})(),
      };
      const message = 'Goals was changed';
      toaster.show({ icon: 'warning-sign', intent: 'warning', message });
      dispatch(updateGoal(goalWithName));
    }

    dispatch(
      updateRegionOfInterest({ ...regionOfInterestData, astrum: astrum.planet })
    );
  };
