export function debounce(
  func: Function,
  wait: number,
  immediate: boolean
): Function {
  let timeout: NodeJS.Timeout;
  let result: void;
  return function (): void {
    // @ts-expect-error
    const context = this;
    const args = arguments;
    const later = function (): void {
      timeout = null;
      if (!immediate) {
        result = func.apply(context, args);
      }
    };
    const callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) {
      result = func.apply(context, args);
    }
    return result;
  };
}
