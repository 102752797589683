import classNames from 'classnames';
import type { RowProps } from './Row';

export type RowLayoutProps = {
  children: React.ReactElement<RowProps>[] | React.ReactElement<RowProps>;
  rows: number;
  rowRatio: number[];
  gap?: number;
};

/**
 * Stylized row grid layout. Can be used to easily separate the screen in vertical segments of a given width and a given number.
 * To be used with the Row component.
 * Number of rows must be equal to the length of the rowRatio array
 * @param rows number - total number of rows to display
 * @param rowRatio number array - width of each row in fr
 * @param gap number - gap between row items
 *
 * @example <RowLayout rows={2} rowRatio={[1,1]}><Row>{CONTENT}</Row></RowLayout>
 */

export const RowLayout = ({
  children,
  gap,
  rows,
  rowRatio,
}: RowLayoutProps) => {
  if (rows !== rowRatio.length) {
    throw new Error('Row ratio array must be the same length as row number');
  }
  return (
    <div
      className={classNames('h-full w-full bg-header dark:bg-header-dark')}
      style={{
        display: 'grid',
        rowGap: gap ?? 0,
        gridTemplateRows: rowRatio.map((r) => `${r}fr`).join(' '),
      }}
    >
      {children}
    </div>
  );
};
