import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';
import Icon from '_atoms/Icon/Icon';
import Tooltip from '_atoms/Tooltip/Tooltip';
import IconButton from '_molecules/IconButton/IconButton';
import type { TaskingRequest } from 'api/tasking/service';
import { postSwathSearch } from 'api/tasking/service';
import { patchActivity, type Activity } from 'api/activities/service';
import {
  getRegionOfInterestCoverageArea,
  getRegionOfInterestCoveragePercent,
} from 'datacosmos/utils/geojson';
import Button from '_molecules/Button/Button';
import { SwathControl } from '_organisms/SwathControl/SwathControl';
import { useSwathControlData } from 'datacosmos/utils/hooks/useSwathControlData';
import { useLocalisation } from 'utils/hooks/useLocalisation';
import type {
  AoiLayersDisplayed,
  RemoveSwathEdits,
  SwathLayersDisplayed,
  ToggleDisplayActivitySwathOnMap,
  ToggleDisplayAoIOnMap,
  UpdateSwathLayer,
} from './useTaskingRequestList';
import type { ICheckPermissions } from 'services/auth/useAuthorisation';
import { useRouteMatch } from 'react-router';

type IProps = {
  activity: Activity;
  request: TaskingRequest;
  toggleDisplayAoIOnMap: ToggleDisplayAoIOnMap;
  toggleDisplayActivitySwathOnMap: ToggleDisplayActivitySwathOnMap;
  shownAoiLayer?: AoiLayersDisplayed[0];
  shownActivitySwathsLayer?: SwathLayersDisplayed[0];
  updateSwathLayer: UpdateSwathLayer;
  removeSwathEdits: RemoveSwathEdits;
  refetchRequests: () => Promise<void>;
  checkPermissions: {
    (perms: ICheckPermissions[]): Promise<boolean[]>;
    (perms: ICheckPermissions): Promise<boolean>;
  };
};

const ActivityCard = ({
  activity,
  request,
  toggleDisplayAoIOnMap,
  toggleDisplayActivitySwathOnMap,
  shownAoiLayer,
  shownActivitySwathsLayer,
  updateSwathLayer,
  removeSwathEdits,
  refetchRequests,
  checkPermissions,
}: IProps) => {
  const [isEditing, setIsEditing] = useState<boolean>(false);

  const [isAllowedToEditTaskingRequest, setIsAllowedToEditTaskingRequest] =
    useState<boolean>(false);

  useEffect(() => {
    const getTaskingRequestEditPermission = async () => {
      const permission = await checkPermissions([
        {
          id: activity.mission_id,
          type: 'mission',
          actionScope:
            'data:tasking:request:update:activities:parameters:platform:roll_angle',
        },
        {
          id: activity.mission_id,
          type: 'mission',
          actionScope: 'data:tasking:request:update:activities:start_date',
        },
        {
          id: activity.mission_id,
          type: 'mission',
          actionScope: 'data:tasking:request:update:activities:end_date',
        },
      ]);
      setIsAllowedToEditTaskingRequest(
        permission.reduce((prev, current) => prev && current)
      );
    };

    void getTaskingRequestEditPermission();
  }, [activity.mission_id, checkPermissions]);

  const {
    debouncedSetSwathData,
    swathControlData,
    resetSwathDataToDefault,
    setSwathControlData,
  } = useSwathControlData({
    area:
      request && activity.parameters.physical?.latest
        ? getRegionOfInterestCoverageArea(
            [activity.parameters.physical.latest.geojson],
            [request.region]
          )
        : 0,
    coverage:
      request && activity.parameters.physical?.latest
        ? getRegionOfInterestCoveragePercent(
            [activity.parameters.physical.latest.geojson],
            [request.region]
          )
        : 0,
    duration: {
      end: moment.utc(activity.end_date).toDate(),
      start: moment.utc(activity.start_date).toDate(),
    },
    rotation: activity.parameters.platform.roll_angle,
    sza: activity.parameters.physical?.latest?.midpoint.sza_deg,
    oza: activity.parameters.physical?.latest?.midpoint.oza_deg,
    parameters: activity.parameters,
  });

  const isSwathShown = typeof shownActivitySwathsLayer !== 'undefined';
  const isAoIShown = typeof shownAoiLayer !== 'undefined';

  const { translate } = useLocalisation();

  const getDuration = () => {
    const diff = moment.utc(
      moment(activity.end_date).diff(activity.start_date)
    );

    if (diff.minutes() > 0) {
      return moment
        .utc(moment(activity.end_date).diff(activity.start_date))
        .format('mm[m] ss[s]');
    }

    return moment
      .utc(moment(activity.end_date).diff(activity.start_date))
      .format('ss[s]');
  };

  const isInPast = useMemo<boolean>(() => {
    return moment(moment.now()).diff(activity.start_date) >= 0;
  }, [activity.start_date]);

  const urlMatch = useRouteMatch<{ projectId: string }>({
    path: '/data/project/:projectId',
    exact: false,
  });

  const projectId = urlMatch?.params.projectId;

  const disableEditButton =
    activity.status !== 'SUGGESTED' ||
    isInPast ||
    !isAllowedToEditTaskingRequest;

  return (
    <div className="bg-item dark:bg-item-dark p-2 flex flex-col gap-2 text-xs">
      <div className="flex items-center justify-between">
        <div className="flex items-center gap-2">
          <span>
            {moment(activity.start_date).format('YYYY MMM DD - HH:mm:ss')}
          </span>

          <span>
            <Tooltip
              content={translate(
                'datacosmos.tooltips.tasking.existing.activities.duration'
              )}
              delay={0}
            >
              {getDuration()}
            </Tooltip>
          </span>
        </div>

        <span>
          <Tooltip
            content={translate(
              'datacosmos.tooltips.tasking.existing.activities.imager'
            )}
            delay={0}
          >
            {activity.parameters.imager.name}
          </Tooltip>
        </span>
        {swathControlData?.parameters?.ground_processing?.processing_level && (
          <span>
            <Tooltip
              content={translate(
                'datacosmos.tooltips.tasking.existing.activities.processingLevel'
              )}
              delay={0}
            >
              {
                swathControlData?.parameters?.ground_processing
                  ?.processing_level
              }
            </Tooltip>
          </span>
        )}
      </div>

      <div className="flex items-center justify-between text-[10px]">
        <div className="flex items-center gap-1">
          <Tooltip
            content={translate(
              'datacosmos.tooltips.tasking.existing.activities.aoiCoverage'
            )}
            delay={0}
          >
            <span className="flex items-center gap-2">
              <Icon icon="Coverage" className="dark:invert" />
              {request && activity.parameters.physical?.latest
                ? getRegionOfInterestCoveragePercent(
                    [activity.parameters.physical?.latest.geojson],
                    [request.region]
                  )
                : '- %'}
              %
            </span>
          </Tooltip>
          <Tooltip
            content={translate(
              'datacosmos.tooltips.tasking.existing.activities.oza'
            )}
            delay={0}
          >
            <span className="flex items-center gap-1">
              <Icon icon="Angle" />
              {swathControlData.oza?.toFixed(2) ??
                activity.parameters.physical?.latest?.midpoint?.oza_deg?.toFixed(
                  2
                )}
              °
            </span>
          </Tooltip>
          <Tooltip
            content={translate(
              'datacosmos.tooltips.tasking.existing.activities.sza'
            )}
            delay={0}
          >
            <span className="flex items-center gap-1">
              <Icon icon="SunAngle" />
              {swathControlData.sza?.toFixed(2) ??
                activity.parameters.physical?.latest?.midpoint?.sza_deg?.toFixed(
                  2
                )}
              °
            </span>
          </Tooltip>
          <Tooltip
            content={translate(
              'datacosmos.tooltips.tasking.existing.activities.rollAngle'
            )}
            delay={0}
          >
            <span className="flex items-center gap-1">
              <Icon icon="RollAngle" />
              {swathControlData?.rotation?.toFixed(2)}°
            </span>
          </Tooltip>
        </div>

        <div className="flex items-center gap-1">
          <span>
            {translate(
              `datacosmos.tasking.existing.activityStatus.${activity.status}`
            )}
          </span>

          <Tooltip
            content={translate(
              'datacosmos.tooltips.tasking.existing.activities.showSwath'
            )}
            delay={0}
          >
            <IconButton
              icon="Swath"
              onPress={() => {
                toggleDisplayActivitySwathOnMap(activity);
              }}
              active={isSwathShown}
              data-testid="request-add-single-swath"
            />
          </Tooltip>

          <Tooltip
            content={translate(
              'datacosmos.tooltips.tasking.existing.activities.viewStac'
            )}
            delay={0}
          >
            <IconButton
              icon="StacAsset"
              onPress={() => {
                //TODO: should display stac item from activity
              }}
            />
          </Tooltip>

          <Tooltip
            content={
              disableEditButton
                ? translate(
                    'datacosmos.tooltips.tasking.existing.activities.editSwathDisabled'
                  )
                : translate(
                    'datacosmos.tooltips.tasking.existing.activities.editSwath'
                  )
            }
            delay={0}
          >
            <IconButton
              icon="Edit"
              disabled={disableEditButton}
              onPress={() => {
                if (!isAoIShown) {
                  toggleDisplayAoIOnMap(request);
                }

                if (!isSwathShown) {
                  toggleDisplayActivitySwathOnMap(activity);
                }
                setIsEditing(true);
              }}
            />
          </Tooltip>
        </div>
      </div>

      {isEditing && (
        <div className="mt-4" onClick={(e) => e.stopPropagation()}>
          <SwathControl
            opportunity={request}
            swath={activity}
            setSwathControlData={(data) => {
              debouncedSetSwathData(data, async () => {
                if (!request) {
                  return;
                }
                if (!projectId) {
                  return;
                }

                const updated = await postSwathSearch({
                  body: {
                    area_of_interest: {
                      name: request.region_name,
                      geojson: {
                        geometry: request.region.geometry,
                        properties: {},
                        type: request.region.type,
                      },
                    },
                    instrument: {
                      mission_id: activity.mission_id,
                      sensor_id: activity.parameters.imager.name,
                    },
                    roll_angle: data.rotation,
                    start: data.duration.start.toISOString(),
                    stop: data.duration.end.toISOString(),
                    project_id: projectId,
                  },
                });

                if (!updated.data) return;

                updateSwathLayer(activity, updated.data);

                // Set oza, sza additionally
                setSwathControlData({
                  ...data,
                  oza: updated.data?.midpoint.oza_deg,
                  sza: updated.data?.midpoint.sza_deg,
                });
              });
            }}
            swathControlData={swathControlData}
          />

          <div className="flex w-full justify-between gap-2">
            <Button
              text={translate('datacosmos.buttons.cancel')}
              className="w-full !bg-item-contrast-surface"
              onPress={() => {
                setIsEditing(false);
                // Disabled the check here because I have no idea why this would be a problem
                // considering the function has an explicitly defined void return type

                // eslint-disable-next-line @typescript-eslint/no-unsafe-call
                resetSwathDataToDefault();
              }}
            />
            <Button
              text={translate('datacosmos.buttons.update')}
              className="w-full  !bg-item-contrast-surface"
              onPress={async () => {
                const { success } = await patchActivity({
                  body: {
                    end_date: swathControlData.duration.end.toISOString(),
                    start_date: swathControlData.duration.start.toISOString(),
                    priority: swathControlData?.priority?.priority_level,
                    parameters: {
                      platform: {
                        roll_angle: swathControlData.rotation,
                      },
                    },
                  },
                  params: {
                    activityId: activity.id,
                    missionId: activity.mission_id,
                  },
                });

                setIsEditing(false);
                if (success) {
                  await refetchRequests();
                }
                removeSwathEdits(activity);
              }}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default ActivityCard;
