import type { ReactElement } from 'react';
import { Fields } from 'redux-form';
import React from 'react';
import RowWithConstraints from '../inputField/RowWithConstraints';
import type { IOption } from '../inputField/SelectField';
import { SEPARATOR } from '../../constants/popUp/constants';

export const getRow = (
  name: string,
  constraints: object,
  change: Function,
  measurement: string,
  measurementType: string,
  astrum: string,
  disable = false
): ReactElement => {
  let nameSelected = '';
  let nameOptions: IOption[] = [];

  const names = name.split(SEPARATOR);
  let nameLocal = name;
  if (names.length > 1) {
    nameLocal = names[0];
    nameSelected = nameLocal;
    nameOptions = names.map((n) => {
      return {
        value: n,
        text: n,
      };
    });
  }
  return (
    <tr key={name}>
      <Fields
        names={[
          `${nameLocal}.optimisation`,
          `${nameLocal}.value`,
          `${nameLocal}.min`,
          `${nameLocal}.max`,
        ]}
        name={`${nameLocal}`}
        measurement={measurement}
        measurementType={measurementType}
        disable={disable}
        constraints={constraints}
        change={change}
        nameSelected={nameSelected}
        nameOptions={nameOptions}
        astrum={astrum}
        component={RowWithConstraints}
      />
    </tr>
  );
};
