import area from '@turf/area';
import Button from '_molecules/Button/Button';
import { LayerSourceType } from 'datacosmos/entities/layer';
import { PolygonLayerFactory } from 'datacosmos/entities/polygonLayer';
import { useMapLayers } from 'datacosmos/stores/MapLayersProvider';
import { useMap } from 'datacosmos/stores/MapProvider';
import type { Polygon } from 'geojson';
import { useCallback, useEffect, useState } from 'react';
import type { Dispatch, SetStateAction } from 'react';
import bbox from '@turf/bbox';
import DebouncedInput from '_molecules/DebouncedInput/DebouncedInput';
import { coordinateStringToGeoJson } from 'datacosmos/utils/coordinates/coordinatesToGeoJson';
import Select2 from '_molecules/Select2/Select2';
import { Item } from 'react-stately';
import type { EPSG } from 'datacosmos/utils/coordinates/epsgCoordinates';
import {
  EPSG4326,
  coordinateFormats,
} from 'datacosmos/utils/coordinates/epsgCoordinates';
import { useLocalisation } from 'utils/hooks/useLocalisation';

type Props = {
  setAreaOfInterest: Dispatch<SetStateAction<Polygon[] | undefined>>;
};

const SearchByPoint = ({ setAreaOfInterest }: Props) => {
  const [isCoordinateBoxOpen, setCoordinateBoxOpen] = useState<boolean>(false);
  const { removeLayersBySourceType, addLayer } = useMapLayers();
  const { setViewToFitBbox } = useMap();

  const [selectedEPSG, setSelectedEPSG] = useState<EPSG>(EPSG4326);

  const [coordinateInput, setCoordinateInput] = useState<string>();
  const [latitudeInput, setLatitudeInput] = useState<string>('');
  const [longtitudeInput, setLongtitudeInput] = useState<string>('');

  const { translate } = useLocalisation();

  const handleCoordinateInput = useCallback(() => {
    if (!coordinateInput) {
      return;
    }

    if (!latitudeInput.length || !longtitudeInput.length) {
      return;
    }

    const geo = coordinateStringToGeoJson(coordinateInput, selectedEPSG);

    if (!geo) {
      return;
    }

    removeLayersBySourceType(LayerSourceType.AREA_OF_INTEREST);

    addLayer(
      PolygonLayerFactory(
        LayerSourceType.AREA_OF_INTEREST,
        'Area of interest',
        geo,
        area(geo),
        undefined
      )
    );

    const polyBbox = bbox(geo);

    setViewToFitBbox(polyBbox);
    setAreaOfInterest([geo.geometry]);
  }, [
    addLayer,
    coordinateInput,
    removeLayersBySourceType,
    selectedEPSG,
    setAreaOfInterest,
    setViewToFitBbox,
    latitudeInput,
    longtitudeInput,
  ]);

  /**
   * We're handling coordinate input inside useEffect to enable search to be run again
   * when selecting a different coordinate system from the dropdown menu.
   */
  useEffect(() => {
    handleCoordinateInput();
  }, [handleCoordinateInput]);

  return (
    <>
      {!isCoordinateBoxOpen && (
        <Button
          text={translate('datacosmos.buttons.searchByPoint')}
          icon="Pin"
          className="color-item h-8 w-full text-start border-2 border-item"
          onPress={() => setCoordinateBoxOpen(true)}
        />
      )}
      {isCoordinateBoxOpen && (
        <div>
          <Select2
            items={coordinateFormats}
            selectedKey={selectedEPSG.code}
            onSelectionChange={(key) => {
              const epsg = coordinateFormats.find((c) => c.code === key);
              if (!epsg) {
                return;
              }
              setSelectedEPSG(epsg);
            }}
            label="EPGS Coordinate system"
          >
            {(items) => <Item key={items.code}>{items.code}</Item>}
          </Select2>

          <DebouncedInput
            debounceTimeMs={500}
            type="text"
            label={{ position: 'top', text: 'Latitude Coordinates' }}
            placeholder="eg. -2.745344"
            onChange={(e) => {
              setLatitudeInput(e.target.value);
              setCoordinateInput(`${longtitudeInput},${e.target.value}`);
            }}
          />

          <DebouncedInput
            debounceTimeMs={500}
            type="text"
            label={{ position: 'top', text: 'Longitude Coordinates' }}
            placeholder="eg. 26.831494"
            onChange={(e) => {
              setLongtitudeInput(e.target.value);
              setCoordinateInput(`${e.target.value},${latitudeInput}`);
            }}
          />

          <div className="flex items-center">
            <Button
              text={translate('datacosmos.buttons.delete')}
              onPress={() => {
                setCoordinateBoxOpen(false);
                removeLayersBySourceType(LayerSourceType.AREA_OF_INTEREST);
              }}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default SearchByPoint;
