import type { ActivityParameters } from 'api/activities/types';
import type { SwathControlData } from 'api/tasking/helpers';
import type { IProperty } from 'components/CommandArguments';
import CommandGroupArgument from 'components/CommandArguments/CommandGroupArgument';
import { assoc } from 'lodash/fp';
import { Dialog, Tabs } from 'opencosmos-ui';
import SecondaryButton from 'opencosmos-ui/src/core/Button/SecondaryButton';
import { useEffect, useState } from 'react';
import { Item } from 'react-stately';
import { useLocalisation } from 'utils/hooks/useLocalisation';

interface AdvancedTaskingProps {
  disabled?: boolean;
  schema: IProperty[];
  handleSwathOptionsChange: (controlData: {}) => void;
  swathControlData: SwathControlData | undefined;
  defaultValues: ActivityParameters;
}

const AdvancedSwathControl = ({
  schema,
  handleSwathOptionsChange,
  swathControlData,
  defaultValues,
}: AdvancedTaskingProps) => {
  const [openAdvancedOptions, setOpenAdvancedOptions] =
    useState<boolean>(false);

  const [advancedOptions, setAdvancedOptions] = useState({});

  const { translate } = useLocalisation();

  const handleAdvancedTaskingParamsChange = (path: string, value: unknown) => {
    const advancedParams = assoc(path, value, advancedOptions);
    if (advancedOptions) {
      setAdvancedOptions({ ...advancedOptions, ...advancedParams });
      return;
    }
    setAdvancedOptions({ ...advancedParams } as ActivityParameters);
  };

  useEffect(() => {
    if (openAdvancedOptions) {
      setAdvancedOptions({ ...defaultValues, ...swathControlData?.parameters });
    }
  }, [swathControlData?.parameters, openAdvancedOptions, defaultValues]);

  return (
    <>
      <SecondaryButton
        text={'Advanced options'}
        onPress={() => setOpenAdvancedOptions(true)}
        fill
      />

      <Dialog
        buttons={[
          {
            shown: true,
            onPress: () => {
              handleSwathOptionsChange(advancedOptions);
            },
            text: translate('datacosmos.buttons.save'),
          },
          {
            shown: true,
            onPress: () => {
              setAdvancedOptions({});
              handleSwathOptionsChange(defaultValues);
            },
            text: translate('datacosmos.buttons.resetAndClose'),
          },
        ]}
        isOpen={openAdvancedOptions}
        onClose={() => {
          setAdvancedOptions({});
          setOpenAdvancedOptions(false);
        }}
        title="Advanced tasking options"
        hideCancelButton
        showButtonsInFooter
      >
        <>
          <Tabs>
            {schema.map((property: IProperty) => (
              <Item
                title={
                  <div className="text-xs dark:text-neutral">
                    {property.optionalFields.title ?? property.path}
                  </div>
                }
                key={property.optionalFields.title}
              >
                <CommandGroupArgument
                  key={property.path}
                  property={property}
                  value={advancedOptions}
                  onBlur={() => {}}
                  onChange={(value, path) =>
                    handleAdvancedTaskingParamsChange(path, value)
                  }
                />
              </Item>
            ))}
          </Tabs>
        </>
      </Dialog>
    </>
  );
};

export default AdvancedSwathControl;
