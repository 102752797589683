import React, { useState, useEffect } from 'react';
import { Formik, Form } from 'formik';
import { connect } from 'react-redux';
import type { AppState } from '../../../reducers/rootReducer';
import type {
  IPayload,
  IBasePayload,
  IModeGroup,
} from '../../../constants/moduleData/types';
import {
  addCustomPayload,
  updateCustomPayload,
  removeCustomPayload,
  updatePayload,
} from '../../../actions/ui/moduleSelector/action';
import type { WithTranslation } from 'react-i18next';
import { withTranslation } from 'react-i18next';
import styles from './styles.module.scss';
import CustomPayloadModes from './CustomPayloadModes';
import { Tabs, Tab, Navbar, Icon, Alignment } from '@blueprintjs/core';
import GeneralProperties from './DetailedView/GeneralProperties';
import PhysicalProperties from './DetailedView/PhysicalProperties';
import ThermalProperties from './DetailedView/ThermalProperties';
import {
  selectCurrentPayload,
  selectIsEditingCustomPayload,
  selectIsCreatingCustomPayload,
  selectCurrentPayloadName,
  selectAreModuleDetailsShowing,
} from '../../../selectors/moduleSelector';
import {
  formatFromKelvinToCelsius,
  formatFromCelsiusToKelvin,
} from '../../../utils/common/temperature';
import {
  toKilobytes,
  fromKilobytesToBytes,
} from '../../../utils/common/dataRate';
import getCustomPayloadFormSchema from './validation';
import { msd } from '../../../constants/mixpanelAnalytics';
import { useAnalytics } from 'utils/hooks/analytics/useAnalytics';

type IFormPayload = IBasePayload & {
  data: {
    modes?: (IModeGroup & { id: string })[];
  };
};

interface IOwnProps {
  readOnly: boolean;
  existingNames: string[];
}

interface IMapStateToProps {
  initialValues?: IFormPayload;
  editMode: boolean;
  createMode: boolean;
  currentName: string;
}

interface IMapDispatchToProps {
  closeDetailedView: () => void;
  addCustomPayload: typeof addCustomPayload;
  updateCustomPayload: typeof updateCustomPayload;
  removeCustomPayload: typeof removeCustomPayload;
}

type IProps = IOwnProps &
  IMapStateToProps &
  IMapDispatchToProps &
  WithTranslation;

export interface IRawModeRow {
  id: string;
  dataGeneration: number;
  dataConsumption: number;
  powerGeneration: number;
  powerConsumption: number;
}

const PAYLOAD_TABS = {
  GENERAL: 'general',
  PHYSICAL: 'physical',
  THERMAL: 'thermal',
  MODES: 'modes',
};

const getCurrentTab = ({
  currentTab,
  values,
  readOnly,
  handleChange,
}: {
  currentTab: string;
  values: any;
  readOnly: boolean;
  handleChange: Function;
}) => {
  switch (currentTab) {
    case PAYLOAD_TABS.PHYSICAL:
      return <PhysicalProperties readOnly={readOnly} />;
    case PAYLOAD_TABS.THERMAL:
      return <ThermalProperties readOnly={readOnly} />;
    case PAYLOAD_TABS.MODES:
      return <CustomPayloadModes values={values} readOnly={readOnly} />;
    case PAYLOAD_TABS.GENERAL:
    default:
      return (
        <GeneralProperties readOnly={readOnly} handleChange={handleChange} />
      );
  }
};

const formatMode = (mode: IModeGroup, up: boolean): IModeGroup => ({
  dataConsumptionMode: {
    ...mode,
    type: 'FIXED',
    data: {
      value: up
        ? toKilobytes(mode.dataConsumptionMode.data.value)
        : fromKilobytesToBytes(mode.dataConsumptionMode.data.value),
    },
  },
  dataGenerationMode: {
    ...mode,
    type: 'FIXED',
    data: {
      value: up
        ? toKilobytes(mode.dataGenerationMode.data.value)
        : fromKilobytesToBytes(mode.dataGenerationMode.data.value),
    },
  },
  powerConsumptionMode: {
    ...mode.powerConsumptionMode,
    type: 'FIXED',
  },
});

const transformFormToPayload = (
  formValues: IFormPayload,
  custom?: boolean
): IPayload => {
  const newPayload: IPayload = {
    ...formValues,
    data: {
      ...formValues.data,
      physical: {
        ...formValues.data.physical,
        xLength: formValues.data.physical.xLength / 100,
        yLength: formValues.data.physical.yLength / 100,
        zLength: formValues.data.physical.zLength / 100,
      },
      thermal: {
        minimumTemperature: formatFromCelsiusToKelvin(
          Number(formValues.data.thermal.minimumTemperature)
        ),
        maximumTemperature: formatFromCelsiusToKelvin(
          Number(formValues.data.thermal.maximumTemperature)
        ),
      },
      modes: {},
    },
  };

  if (formValues.data.modes) {
    newPayload.data.modes = {};

    formValues.data.modes.forEach(
      (mode) => (newPayload.data.modes[mode.id] = formatMode(mode, false))
    );
  }

  if (custom) newPayload.custom = true;

  return newPayload;
};

const transformPayloadToForm = (payload: IPayload): IFormPayload => {
  const newPayload: any = {
    ...payload,
    data: {
      ...payload.data,
      physical: {
        ...payload.data.physical,
        xLength: payload.data.physical.xLength * 100,
        yLength: payload.data.physical.yLength * 100,
        zLength: payload.data.physical.zLength * 100,
      },
      thermal: {
        minimumTemperature: formatFromKelvinToCelsius(
          payload.data.thermal.minimumTemperature
        ),
        maximumTemperature: formatFromKelvinToCelsius(
          payload.data.thermal.maximumTemperature
        ),
      },
    },
  };

  if (payload.data.modes) {
    newPayload.data.modes = Object.entries(payload.data.modes).map(
      ([modeName, modeValues]) => ({
        ...formatMode(modeValues, true),
        id: modeName,
      })
    );
  }

  return newPayload;
};

const CustomPayloadForm: React.FunctionComponent<IProps> = ({
  addCustomPayload,
  updateCustomPayload,
  removeCustomPayload,
  initialValues,
  editMode,
  createMode,
  readOnly,
  existingNames,
  currentName,
  closeDetailedView,
}) => {
  // If form hasn't loaded yet
  if (!initialValues) return null;

  const [currentTab, setCurrentTab] = useState(PAYLOAD_TABS.GENERAL);

  const { sendInfo } = useAnalytics();

  useEffect(() => setCurrentTab(PAYLOAD_TABS.GENERAL), [currentName]);

  const submitHandler = (values: IFormPayload) => {
    if (editMode) {
      sendInfo({
        action: 'Edit payload',
        type: msd.PAYLOAD_SELECTION.CUSTOM.EDIT,
        item: 'Payload selection',
        additionalParams: values,
        module: 'MSD',
      });
      updateCustomPayload(transformFormToPayload(values), initialValues.id);
    } else {
      sendInfo({
        action: 'Create payload',
        type: msd.PAYLOAD_SELECTION.CUSTOM.CREATE,
        item: 'Payload selection',
        additionalParams: values,
        module: 'MSD',
      });
      addCustomPayload(transformFormToPayload(values, true));
    }
  };

  // If in edit mode, allow initial value as a valid name
  const validNames = createMode
    ? existingNames
    : existingNames.filter((name) => name !== initialValues.id);
  const validationSchema = getCustomPayloadFormSchema(validNames);

  return (
    <div>
      <div className={styles.bottomBar}>
        <Navbar className={styles.customNavbar}>
          <Navbar.Group align={Alignment.CENTER} className={styles.center}>
            <Tabs
              id={`BottomTabs-${currentName}`}
              selectedTabId={currentTab}
              onChange={(tab: string) => {
                sendInfo({
                  action: 'Clicked detailed tab view',
                  type: msd.PAYLOAD_SELECTION.CUSTOM.EDIT,
                  item: 'Payload selection Tab view',
                  additionalParams: { tab, ...initialValues },
                  module: 'MSD',
                });
                setCurrentTab(tab);
              }}
            >
              <Tabs.Expander />
              <Tab id={PAYLOAD_TABS.GENERAL}>
                <span className={styles.tabTitle}>General</span>
              </Tab>
              <Tabs.Expander />
              <Tab id={PAYLOAD_TABS.PHYSICAL}>
                <Icon icon="cube" />
                <span className={styles.tabTitle}>Physical</span>
              </Tab>
              <Tabs.Expander />
              <Tab id={PAYLOAD_TABS.THERMAL}>
                <Icon icon="flame" />
                <span className={styles.tabTitle}>Thermal</span>
              </Tab>
              <Tabs.Expander />
              {Boolean(initialValues.data.modes) && (
                <Tab id={PAYLOAD_TABS.MODES}>
                  <Icon icon="settings" />
                  <span className={styles.tabTitle}>Modes</span>
                </Tab>
              )}
            </Tabs>
          </Navbar.Group>
        </Navbar>
      </div>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={submitHandler}
        validationSchema={validationSchema}
        render={({
          isSubmitting,
          values,
          handleChange,
        }: {
          isSubmitting: boolean;
          values: any;
          handleChange: Function;
        }) => {
          const CurrentTabForm = getCurrentTab({
            currentTab,
            values,
            readOnly,
            handleChange,
          });

          return (
            <Form>
              <div>{CurrentTabForm}</div>
              <div className={styles.footer}>
                {createMode && (
                  <div className="bp4-dialog-footer-actions">
                    <button
                      title="Cancel"
                      className="bp4-button bp4-icon-cross"
                      onClick={closeDetailedView}
                      type="button"
                    >
                      Cancel
                    </button>
                  </div>
                )}
                {editMode && (
                  <div className="bp4-dialog-footer-actions">
                    <button
                      title="Cancel"
                      className="bp4-button bp4-icon-trash"
                      onClick={() => removeCustomPayload(initialValues.id)}
                    >
                      Remove
                    </button>
                  </div>
                )}
                {!readOnly && (
                  <div className="bp4-dialog-footer-actions">
                    <button
                      type="submit"
                      title="Submit"
                      className="bp4-button bp4-icon-saved"
                      disabled={isSubmitting}
                    >
                      Save
                    </button>
                  </div>
                )}
              </div>
            </Form>
          );
        }}
      />
    </div>
  );
};

const mapStateToProps = (state: AppState): IMapStateToProps => ({
  initialValues: selectAreModuleDetailsShowing(state)
    ? transformPayloadToForm(selectCurrentPayload(state))
    : undefined,
  editMode: selectIsEditingCustomPayload(state),
  createMode: selectIsCreatingCustomPayload(state),
  currentName: selectCurrentPayloadName(state),
});

const mapDispatchToProps = {
  closeDetailedView: () => updatePayload(undefined),
  addCustomPayload,
  updateCustomPayload,
  removeCustomPayload,
};

export default withTranslation()(
  connect<IMapStateToProps, IMapDispatchToProps, IOwnProps>(
    mapStateToProps,
    mapDispatchToProps
  )(CustomPayloadForm)
);
