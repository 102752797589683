import Calendar from '_molecules/Calendars/Calendar/Calendar';
import type { Activity } from 'api/activities/types';
import classNames from 'classnames';
import { TIME_FORMAT_NO_SECONDS } from 'constants/datetime';
import moment from 'moment';
import React, { useMemo } from 'react';
import type { DateValue } from '@react-types/calendar';
import { parseDate } from '@internationalized/date';
import Popover2 from '_atoms/Popover/Popover2';
import type { TaskingRequest } from 'api/tasking/types';

type Props = {
  /**
   * Activity array to show on the calendar. Activities are objects matching `Activity` type
   */
  activities: Activity[];
  /**
   * Tasking request array corresponding to the activities. Tasking requests are objects matching `TaskingRequest` type
   */
  requests: TaskingRequest[];
  /**
   * Calendar activity click handler. Provides access to the clicked activity.
   * @param activity clicked activity
   */
  onActivityClick: (activity: Activity) => void;
  /**
   * Id of the currently selected activity
   */
  selectedActivityId: string | undefined;
  /**
   * Calendar field/date click handler. Provides access to the `DateValue` to be used within the handler
   * @param clickedDate clicked date
   */
  onCellClick: (clickedDate: DateValue) => void;
};

/**
 * ActivityCalendar represents a calendar designed to display tasking activities.
 * In case there's more than 2 activities per date a message is displayed saying
 * how many activities are left on that date.
 */
const ActivityCalendar = ({
  activities,
  requests,
  onActivityClick,
  selectedActivityId,
  onCellClick,
}: Props) => {
  const selectedActivity = useMemo(() => {
    return activities.find((a) => a.id === selectedActivityId);
  }, [activities, selectedActivityId]);

  const findRequestForGivenActivity = (a: Activity) => {
    return requests.find((r) => r.id === a.tasking_request_ids[0]);
  };

  const renderCalendarActivities = (a: Activity) => {
    const selected = a.id === selectedActivityId;
    return (
      <div
        className={classNames(
          'text-[10px] mt-2 hover:text-item-contrast group-hover:text-item-contrast bg-surface pl-1 pr-1 box-border border-[1px] border-solid',
          {
            'bg-warning':
              a.status === 'CANCELLED' ||
              a.status === 'EXPIRED' ||
              a.status === 'FAILED',
            ' border-item-contrast': selected,
            ' border-surface': !selected,
          }
        )}
        onClick={(e) => {
          e.stopPropagation();
          onActivityClick(a);
          e.stopPropagation();
        }}
      >
        <div className="flex items-center gap-[1px] justify-between">
          <div className="flex items-center justify-start text-left">
            {findRequestForGivenActivity(a)?.region_name ?? 'n/a'}
          </div>
          <div className="text-[9px]">
            {moment.utc(a.start_date).format(TIME_FORMAT_NO_SECONDS)}
          </div>
        </div>
        <div className="flex items-center justify-start text-left ">
          {a.status}
        </div>
      </div>
    );
  };

  const renderCellItems = () => {
    const byDate = activities.reduce((acc, a) => {
      const formattedDate = moment(a.start_date).format('YYYY-MM-DD');

      return { ...acc, [formattedDate]: [...(acc[formattedDate] ?? []), a] };
    }, {} as { [key: string]: Activity[] | undefined });

    return Object.entries(byDate).map(([date, acts], i) => {
      if (!acts) {
        return {
          id: 1,
          date: new Date(Date.parse(date)),
          item: null,
        };
      }

      const split = acts.length && acts.length > 2 ? acts.slice(0, 2) : acts;
      return {
        id: i,
        date: new Date(Date.parse(date)),
        item: (
          <div className="flex flex-col gap-1">
            <div>{split.map((a) => renderCalendarActivities(a))}</div>
            {split.length === acts.length ? null : (
              <Popover2
                placement="top"
                isDismissable={true}
                popupContent={
                  <div className="text-[10px] p-2 w-36 max-h-52 overflow-y-auto">
                    {acts.map((a) => renderCalendarActivities(a))}
                  </div>
                }
              >
                <span>{acts.length - split.length} more</span>
              </Popover2>
            )}
          </div>
        ),
      };
    });
  };

  return (
    <div className="h-[89%]">
      <Calendar
        highligtedDates={[]}
        defaultValue={parseDate(
          moment(
            selectedActivity?.start_date ?? new Date().toISOString()
          ).format('YYYY-MM-DD')
        )}
        cellItemsByDate={renderCellItems()}
        onChange={(val) => onCellClick(val)}
      />
    </div>
  );
};

export default ActivityCalendar;
