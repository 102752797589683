import { useViewsData } from './useViewsData';
import { Screen } from 'ui/Screen';
import { Navbar } from 'ui/Navbar';
import { Card, CardGrid } from 'ui/Card';
import { ItemTabContainer } from 'ui/ItemContainer';
import { TextInput } from 'ui/TextInput';
import { SelectInput } from 'ui/SelectInput';
import { DateInput } from 'ui/DateInput';
import { Loader } from 'ui/Loader';
import { EmptyMessage } from 'ui/EmptyMessage';
import { useLocalisation } from 'utils/hooks/useLocalisation';

export const ViewsScreen = () => {
  const {
    loading,
    filter,
    setFilter,
    date,
    views,
    sortByOptions,
    sortOrderOptions,
    projectOptions,
  } = useViewsData();

  const { translate } = useLocalisation();

  return (
    <Screen>
      <Navbar />
      <ItemTabContainer>
        <SelectInput
          value={filter.orderBy}
          setValue={setFilter.orderBy}
          options={sortByOptions}
          title={translate('datacosmos.views.order')}
          icon="Filter"
        />
        <SelectInput
          value={filter.sortOrder}
          setValue={setFilter.sortOrder}
          options={sortOrderOptions}
          title={translate('datacosmos.views.by')}
        />
        <DateInput
          value={date.from}
          setValue={date.setFrom}
          minValue={date.range?.min}
          maxValue={date.range?.max}
          title={translate('datacosmos.views.from')}
        />
        <DateInput
          value={date.to}
          setValue={date.setTo}
          minValue={date.range?.min}
          maxValue={date.range?.max}
          title={translate('datacosmos.views.to')}
        />
        <SelectInput
          value={filter.project}
          setValue={setFilter.project}
          options={projectOptions}
          title={translate('datacosmos.views.inProject')}
        />
        <TextInput
          value={filter.search}
          setValue={setFilter.search}
          icon="MagnifyingGlass"
          placeholder={translate('datacosmos.views.search')}
        />
      </ItemTabContainer>

      {loading ? (
        <Loader />
      ) : (
        <>
          {views.length === 0 ? (
            <EmptyMessage
              title={translate('datacosmos.fetchErrors.views.noViewsAvailable')}
            />
          ) : (
            <CardGrid>
              {views.map((view, index) => (
                <Card
                  // eslint-disable-next-line react/no-array-index-key
                  key={`view-card-${view.title}-${index}`}
                  column="col4"
                  {...view}
                />
              ))}
            </CardGrid>
          )}
        </>
      )}
    </Screen>
  );
};
