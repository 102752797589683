import React, { useEffect } from 'react';
import type { Scene } from '@babylonjs/core';
import { Axis, Color4 } from '@babylonjs/core';
import { connect } from 'react-redux';
import { AdvancedDynamicTexture } from '@babylonjs/gui';

import SceneComponent from './SceneComponent'; // ^^ point to file we created above or 'babylonjs-hook' NPM.

import type { IPlatformCharacteristics } from '../constants/ui/platformConfigurator/types';
import type { AppState } from '../store';
import { selectPlatformCharacteristics } from '../selectors/platformConfigurator';
import {
  addLight,
  createCamera,
  createSatelliteGroup,
  configurateSatelliteMeshes,
} from './utilities';
import type { ISatelliteObjects } from './types';
import { isNil } from 'lodash';

let satelliteObjects: ISatelliteObjects | null = null;

const onSceneReady = (scene: Scene) => {
  // Scene globals
  const guiTexture = AdvancedDynamicTexture.CreateFullscreenUI('GUI texture');

  // This targets the camera to scene origin
  const camera = createCamera(scene);
  camera.wheelDeltaPercentage = 0.002;

  // Light
  addLight(scene);

  // Objects
  satelliteObjects = createSatelliteGroup(scene, guiTexture);

  scene.clearColor = new Color4(0, 0, 0, 0);
};

/**
 * Will run on every frame render.  We are spinning the satellite on y-axis.
 */
const onRender = (scene: Scene) => {
  if (!isNil(satelliteObjects)) {
    var deltaTimeInMillis = scene.getEngine().getDeltaTime();

    // Rotate at one revolution a minute
    const salliteAngleToRotate = (2 * Math.PI * deltaTimeInMillis) / 60000;

    satelliteObjects.objectGroup.rotate(Axis.Y, salliteAngleToRotate);
  }
};

const SatelliteAttitude3DView = ({ platformCharacteristics }: IStateProps) => {
  useEffect(() => {
    configurateSatelliteMeshes(satelliteObjects, platformCharacteristics);
  }, []);

  return (
    <div style={{ display: 'flex' }}>
      <SceneComponent
        antialias
        onSceneReady={onSceneReady}
        onRender={onRender}
        id="my-canvas"
        width={() => window.innerWidth * 0.3}
        height={() => window.innerHeight * 0.4}
      />
    </div>
  );
};

interface IStateProps {
  platformCharacteristics: IPlatformCharacteristics;
}

const mapStateToProps = (state: AppState): IStateProps => ({
  platformCharacteristics: selectPlatformCharacteristics(state),
});

export default connect<IStateProps>(mapStateToProps)(SatelliteAttitude3DView);
