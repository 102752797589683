import type { ChangeEvent, ReactElement } from 'react';
import React, { Component } from 'react';
import type { WithTranslation } from 'react-i18next';
import { withTranslation } from 'react-i18next';
import type { BaseFieldsProps, WrappedFieldProps } from 'redux-form';
import { Field } from 'redux-form';
import type { IAnyKey } from '../../utils/getFormData';
import type { NumberRange } from '@blueprintjs/core';
import { H6, HTMLSelect, RangeSlider } from '@blueprintjs/core';
import Toggle from '../3WayToggle/Toggle';
import { isNumeric } from '../../utils/common/CommonValidator';
import { measurementTypes } from '../../constants/measurementTypes';
import type { IOption } from './SelectField';
import { connectedMetrics } from '../../constants/connectedMetrics';
import astrumsJSON from '../../__mock__/astrums.json';
import { SEPARATOR } from '../../constants/popUp/constants';

export interface IInputRowFieldProps
  extends WithTranslation,
    BaseFieldsProps,
    IAnyKey {
  measurement: string;
  measurementType: string;
  name: string;
  disable?: boolean;
  constraints?: object & IAnyKey;
  nameSelected?: string;
  nameOptions?: IOption[];
  change: Function;
  astrum: string;
}

export interface IInputRowFieldState {
  selectedMeasurement: string;
  selectedName: string;
  normalizeTo: (value: number) => number;
  normalizeFrom: (value: number) => number;
  localValues: {
    value: string;
    min: string;
    max: string;
    constraints?: object & IAnyKey;
  };
}

const toExp: Function = (num: string, digits: number = 2) => {
  const intNum = parseInt(num);
  if (intNum < 1000) {
    return num;
  }
  return intNum.toExponential(digits);
};

class RowWithConstraints extends Component<
  IInputRowFieldProps,
  IInputRowFieldState
> {
  public constructor(props: IInputRowFieldProps) {
    super(props);
    this.state = {
      selectedMeasurement: '',
      normalizeTo: (value: number) => value,
      normalizeFrom: (value: number) => value,
      localValues: {
        value: '',
        min: '',
        max: '',
        constraints: {},
      },
      selectedName: '',
    };
  }

  public componentDidMount(): void {
    const { measurement, nameSelected, nameOptions } = this.props;
    const { value, min, max } = this.props[this.props.name];
    const localValues = {
      value: value.input.value.toString(),
      min: min.input.value.toString(),
      max: max.input.value.toString(),
      constraints: JSON.parse(JSON.stringify(this.props.constraints)),
    };
    this.setState({
      selectedMeasurement: measurement,
      selectedName: nameOptions.length > 0 ? nameSelected : '',
      localValues: localValues,
    });
  }

  public componentDidUpdate(
    prevProps: Readonly<IInputRowFieldProps>,
    prevState: Readonly<IInputRowFieldState>
  ): void {
    const { name } = this.props;
    if (
      this.state.selectedMeasurement !== prevState.selectedMeasurement ||
      this.state.selectedName !== prevState.selectedName
    ) {
      if (measurementTypes[this.props.measurementType]) {
        const { measurement: originalMeasurement, measurementType } =
          this.props;
        const { selectedMeasurement, selectedName } = this.state;
        let normalizeFrom = (value: number) => value;
        let normalizeTo = (value: number) => value;
        if (selectedMeasurement !== originalMeasurement) {
          normalizeFrom =
            measurementTypes[measurementType][selectedMeasurement][
              `${selectedMeasurement}To${originalMeasurement}`
            ];
          normalizeTo =
            measurementTypes[measurementType][originalMeasurement][
              `${originalMeasurement}To${selectedMeasurement}`
            ];
        }
        if (this.props.nameOptions.length > 1) {
          const normalizeFromC = normalizeFrom.bind(this);
          const normalizeToC = normalizeTo.bind(this);
          const { astrum } = this.props;
          const radius = (
            astrumsJSON as {
              [key: string]: { maximumRadius: number };
            }
          )[astrum].maximumRadius;
          normalizeFrom = (value) =>
            connectedMetrics(radius / 1000)[selectedName].fromOriginal(
              normalizeFromC(value)
            );
          normalizeTo = (value) =>
            normalizeToC(
              connectedMetrics(radius / 1000)[selectedName].toOriginal(value)
            );
        }
        const { value, min, max } = this.props[name];
        const localValues = {
          ...this.state.localValues,
          value: normalizeTo(value.input.value).toString(),
          min: normalizeTo(min.input.value).toString(),
          max: normalizeTo(max.input.value).toString(),
        };
        this.setState({ normalizeFrom, normalizeTo, localValues });
      }
    }
    if (
      JSON.stringify(this.state.localValues.constraints[name]) !==
      JSON.stringify(this.props.constraints[name])
    ) {
      const { localValues } = this.state;
      const rangerSliderParams = this.props.constraints[name];
      this.setState({
        localValues: {
          ...localValues,
          min: rangerSliderParams.min,
          max: rangerSliderParams.max,
          constraints: JSON.parse(JSON.stringify(this.props.constraints)),
        },
      });
    }
  }

  private onChangeOptions = (event: ChangeEvent<HTMLSelectElement>): void => {
    this.setState({ selectedMeasurement: event.target.value });
  };
  private onChangeValue = (event: ChangeEvent<HTMLInputElement>): void => {
    const { normalizeFrom } = this.state;
    const { value } = this.props[this.props.name];
    let v = event.target.value;
    if (isNumeric(v)) {
      value.input.onChange(normalizeFrom(+v));
    } else {
      value.input.onChange(v);
    }

    const { localValues } = this.state;
    this.setState({ localValues: { ...localValues, value: v } });
  };
  private onChangeRangeSlider = (value: NumberRange): void => {
    const { normalizeFrom } = this.state;
    const { min, max } = this.props[this.props.name];
    min.input.onChange(normalizeFrom(value[0]));
    max.input.onChange(normalizeFrom(value[1]));

    const { localValues } = this.state;
    this.setState({
      localValues: {
        ...localValues,
        min: value[0].toString(),
        max: value[1].toString(),
      },
    });
  };
  private onChangeMin = (event: ChangeEvent<HTMLInputElement>): void => {
    const { normalizeFrom } = this.state;
    const { min } = this.props[this.props.name];
    let value = event.target.value;
    if (isNumeric(value)) {
      let value = +event.target.value;
      value = normalizeFrom(value);
      min.input.onChange(value);
    } else {
      min.input.onChange(value);
    }

    const { localValues } = this.state;
    this.setState({ localValues: { ...localValues, min: value } });
  };
  private onChangeMax = (event: ChangeEvent<HTMLInputElement>): void => {
    const { normalizeFrom } = this.state;
    const { max } = this.props[this.props.name];
    let value = event.target.value;
    if (isNumeric(value)) {
      let value = +event.target.value;
      value = normalizeFrom(value);
      max.input.onChange(value);
    } else {
      max.input.onChange(value);
    }

    const { localValues } = this.state;
    this.setState({ localValues: { ...localValues, max: value } });
  };

  private onChangeName = (value: string): void => {
    this.setState({ selectedName: value });
  };

  public render(): React.ReactNode {
    const {
      onChangeMin,
      onChangeMax,
      onChangeOptions,
      onChangeRangeSlider,
      onChangeValue,
    } = this;
    const { t, measurement, name, disable, constraints, measurementType } =
      this.props;
    const { value, min, max, optimisation } = this.props[name];
    const { selectedMeasurement, normalizeTo } = this.state;
    const rangerSliderParams = constraints[name];
    const optionsMeasurement = measurementTypes[measurementType]
      ? Object.keys(measurementTypes[measurementType])
      : [];
    const isLeft = optimisation.input.value;

    const errors = {
      value: {
        text: value.meta.error
          ? t(`geometry.errors.${value.meta.error}` as any)
          : '',
      },
      min: {
        text: min.meta.error
          ? t(`geometry.errors.${min.meta.error}` as any)
          : '',
      },
      max: {
        text: max.meta.error
          ? t(`geometry.errors.${max.meta.error}` as any)
          : '',
      },
    };
    const styles = {
      value: value.meta.error
        ? 'bp4-input bp4-intent-danger input'
        : 'bp4-input input',
      min: min.meta.error
        ? 'bp4-input block bp4-intent-danger'
        : 'bp4-input block',
      max: max.meta.error
        ? 'bp4-input block bp4-intent-danger'
        : 'bp4-input block',
    };

    let stepSize =
      normalizeTo(rangerSliderParams.max) -
        normalizeTo(rangerSliderParams.min) >
      100
        ? 1
        : 0.01;
    if (!measurement) {
      stepSize = 1;
    }

    const normalizeValues = {
      value: this.state.localValues.value,
      min: this.state.localValues.min,
      max: this.state.localValues.max,
      rangerSliderParams: {
        min: normalizeTo(rangerSliderParams.min),
        max: normalizeTo(rangerSliderParams.max),
        labelStepSize: normalizeTo(rangerSliderParams.labelStepSize),
      },
    };
    const {
      min: nrMin,
      max: nrMax,
      labelStepSize: nrLabelStepSize,
    } = normalizeValues.rangerSliderParams;
    let errorSlider = nrMin >= nrMax || !nrLabelStepSize;
    if (errorSlider) {
      optimisation.input.onChange(false);
    }
    const errorDiv = (
      <div className="bp4-callout bp4-intent-danger bp4-icon-error">
        <H6 className="bp4-heading">{t('validation.errors.error')}</H6>
      </div>
    );
    const errorsDiv = {
      value: value.meta.error ? errorDiv : null,
      min: min.meta.error ? errorDiv : null,
      max: max.meta.error ? errorDiv : null,
    };
    const Slider = errorSlider /*
            <div className=" bp4-callout bp4-intent-danger bp4-icon-error">
              <H6 className="bp4-heading">{t('validation.errors.error')}</H6>
            </div>
      */ ? null : (
      <div className="bp4-control-group">
        <input
          className={styles.min}
          title={errors.min.text}
          value={toExp(normalizeValues.min)}
          disabled={!isLeft || disable}
          onChange={onChangeMin}
        />
        <RangeSlider
          className="slider"
          stepSize={stepSize}
          min={normalizeValues.rangerSliderParams.min}
          max={normalizeValues.rangerSliderParams.max}
          labelStepSize={normalizeValues.rangerSliderParams.labelStepSize}
          value={
            errorsDiv.min || errorsDiv.max
              ? [
                  normalizeValues.rangerSliderParams.min,
                  normalizeValues.rangerSliderParams.max,
                ]
              : [
                  isNumeric(normalizeValues.min)
                    ? +normalizeValues.min
                    : normalizeValues.rangerSliderParams.min,
                  isNumeric(normalizeValues.max)
                    ? +normalizeValues.max
                    : normalizeValues.rangerSliderParams.max,
                ]
          }
          disabled={!isLeft || disable}
          onChange={onChangeRangeSlider}
        />
        <input
          className={styles.max}
          title={errors.max.text}
          value={toExp(normalizeValues.max)}
          disabled={!isLeft || disable}
          onChange={onChangeMax}
        />
      </div>
    );

    const optionsName = this.props.nameOptions
      .map((o) => o.value)
      .join(SEPARATOR);
    const Name = !this.state.selectedName ? (
      t(`geometry.${name}` as any)
    ) : (
      <Field
        name={optionsName}
        component={(innerProps: WrappedFieldProps) => {
          if (
            innerProps.input.value !== this.state.selectedName &&
            innerProps.input.value
          ) {
            this.onChangeName(innerProps.input.value);
          } else if (!innerProps.input.value) {
            innerProps.input.onChange(this.state.selectedName);
          }
          return (
            <div className="bp4-select bp4-fill disable-left-padding">
              <select
                value={innerProps.input.value}
                onChange={(arg) => {
                  const { value } = arg.target;
                  innerProps.input.onChange(value);
                  this.onChangeName(value);
                }}
                onBlur={(arg) => {
                  const { value } = arg.target;
                  innerProps.input.onChange(value);
                  this.onChangeName(value);
                }}
              >
                {this.props.nameOptions.map(
                  (option: IOption, index: number): ReactElement => (
                    <option key={index} value={option.value}>
                      {t(`geometry.${option.text}` as any)}
                    </option>
                  )
                )}
              </select>
            </div>
          );
        }}
      />
    );

    return (
      <>
        <td className="parameter">{Name}</td>
        <td className="value">
          <input
            className={styles.value}
            title={errors.value.text}
            value={normalizeValues.value}
            disabled={isLeft || disable}
            placeholder="0"
            onChange={onChangeValue}
          />
          {errorsDiv.value}
        </td>
        <td className="measurement">
          {measurement ? (
            <HTMLSelect
              fill={true}
              value={selectedMeasurement}
              options={optionsMeasurement}
              onChange={onChangeOptions}
            />
          ) : null}
        </td>
        <td className="optimisation">
          <div className="toggle">
            <Toggle
              //disabled={errorSlider} //TODO: disable if the slider has wrong parameters
              disabled //TODO: beta release do not allow optimization on FE
              labelRenderer={false}
              onChange={(arg: number) => optimisation.input.onChange(!!arg)}
              value={+optimisation.input.value}
              //TODO beta release
              values={[0, 1]}
            />
          </div>
        </td>
        <td className="constraints">
          {Slider}
          {errorsDiv.min}
          {errorsDiv.max}
        </td>
      </>
    );
  }
}

export default withTranslation()(RowWithConstraints);
