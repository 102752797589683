import { Dialog } from '@blueprintjs/core';
import type { ProviderView } from 'datacosmos/stores/ViewsProvider';
import React, { useState } from 'react';
import s from 'datacosmos/components/DatacosmosViews/index.module.scss';

type IProps = {
  view: ProviderView | undefined;
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  deleteHandler: (viewId: string) => void;
};

//TODO: Make the scenario delete dialog more generic and use it here in the meantime
const DeleteViewDialog = ({
  view,
  isOpen,
  setIsOpen,
  deleteHandler,
}: IProps) => {
  const [deleteInput, setDeleteInput] = useState<string>('');
  return (
    <Dialog
      isOpen={isOpen}
      canEscapeKeyClose
      onClose={() => setIsOpen(false)}
      hasBackdrop={false}
      className="dark:bg-surface-dark dark:text-item-dark-contrast bg-surface"
    >
      <div
        className={
          s.overlayContentWrapper +
          ' dark:bg-surface-dark dark:text-item-dark-contrast bg-surface'
        }
      >
        <div>
          <h1 className={s.overlayTitle}>WARNING!</h1>
          <p>
            Are you sure you want to delete view &quot;<b>{view?.name}</b>
            &quot;?
          </p>
        </div>

        <div className={s.overlayBody}>
          <p>
            You are about to delete this view, and will lose all your saved
            layers. <strong>This cannot be undone</strong>. Type{' '}
            <b>&quot;delete&quot;</b> in the box below and click{' '}
            <b>&quot;Confirm&quot;</b> if you are sure you wish to proceed.
          </p>
          <input
            type="text"
            style={{ width: '100%' }}
            placeholder="Type 'delete' to confirm your choice"
            onChange={(e) => setDeleteInput(e.target.value)}
            className="dark:bg-item-dark dark:text-item-dark-contrast h-8 border-2 border-item-contrast-text-field-stroke"
          />
        </div>

        <div className={s.overlayFooter}>
          <button onClick={() => setIsOpen(false)}>Cancel</button>
          <button
            className={s.dangerButton}
            onClick={() => {
              if (view) {
                deleteHandler(view.id);
                setIsOpen(false);
              }
            }}
            disabled={deleteInput.toLowerCase() !== 'delete'}
          >
            Confirm
          </button>
        </div>
      </div>
    </Dialog>
  );
};

export default DeleteViewDialog;
