import classNames from 'classnames';
import type { IconName, IconSize } from '_atoms/Icon/Icon';
import DefaultIcon from '_atoms/Icon/Icon';

export { IconName, IconSize };

export type IconProps = {
  kind?: IconName;
  size?: IconSize;
  className?: string;
  inherit?: boolean;
};

export const Icon = ({ kind, size, inherit, className = '' }: IconProps) => {
  if (!kind) return null;
  return (
    <DefaultIcon
      icon={kind}
      size={size}
      className={className}
      inherit={inherit}
    />
  );
};

export const ICON_THEME_COLOR = classNames(
  'stroke-item-contrast dark:stroke-item-dark-contrast',
  'hover:stroke-accent-900 dark:hover:stroke-item-dark-hover'
);

export const ICON_DARK_COLOR = classNames(
  'stroke-item-contrast',
  'hover:stroke-item-dark-hover'
);
