import NotFoundPage from 'pages/NotFoundPage';
import { LoginPage } from 'pages/LoginPage';
import LogoutPage from 'pages/LogoutPage';
import MissionDesignPage from 'pages/MissionDesignPage';
import PayloadSelectionPage from 'pages/msd/PayloadSelectionPage';
import MissionGeometryPage from 'pages/msd/MissionGeometryPage';
import GroundSegmentPage from 'pages/msd/GroundSegmentPage';
import PlatformPage from 'pages/msd/PlatformPage';
import FinalPage from 'pages/msd/FinalPage';
import GSScheduling from 'pages/ops/GSScheduling';
import Library from 'pages/ops/Library';
import Telemetry from 'pages/ops/Telemetry';
import Scripting from 'pages/ops/Scripting';
import BasePage from 'pages/BasePage';
import ROUTES from 'routeConstants';
import OrganisationAdministration from 'pages/portal/Administration/OrganisationAdministration';
import ProgrammeAdministration from 'pages/portal/Administration/ProgrammeAdministration';
import SessionManager from 'pages/ops/RTI/SessionManager';
import Operate from 'pages/ops/RTI/Operate/OperateWrapper';
import RolesAdministration from 'pages/portal/Administration/RolesAdministration';
import UserAdministration from 'pages/portal/Administration/UserAdministration';
import { HomeWrapper } from 'datacosmos';
import UserPage from 'pages/portal/Administration/UserPage';
import { Scheduling } from 'pages/ops/Scheduling';
import { ViewsScreen } from 'pages/views/ViewsScreen';
import TaskingOverviewScreen from 'pages/tasking_overview/TaskingOverviewScreen';
import PdgsScreen from 'pages/ops/Pdgs/PdgsScreen';
import OrdersScreen from 'pages/orders/OrdersScreen';
import AuthErrorPage from 'pages/AuthErrorPage';
import GenericResourcePage from 'pages/datacosmos/GenericResourcePage';
import type {
  PermissionScope,
  PermissionType,
} from 'api/administration/permissions';
import type { IData } from 'actions/uploadData';
import type { ComponentType } from 'react';

export type RouteType = {
  path?: string;
  auth: PermissionScope[];
  permissionType?: PermissionType;
  isPrivate?: boolean;
  exact?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  component: ComponentType<any>;
  loadData?: (data: IData, accessNames: string[]) => void;
};

// Define app routes here.
// Each route should have a path, a component, and an array of auth strings.
// The auth strings are used to check if the user has the required permissions to access the route.
// If the route is set to private, user must have proper permissions
export const routes: RouteType[] = [
  {
    path: '/login',
    auth: [],
    exact: true,
    component: LoginPage,
  },
  {
    path: '/',
    auth: [],
    exact: true,
    component: BasePage,
  },
  {
    path: '/ops',
    auth: [],
    exact: true,
    component: MissionDesignPage,
  },
  {
    path: '/data',
    auth: [],
    exact: true,
    component: MissionDesignPage,
  },
  {
    path: '/msd/payload',
    auth: ['msd'],
    exact: true,
    component: PayloadSelectionPage,
  },
  {
    path: '/ops/mission/:mission',
    auth: ['mission:read'],
    isPrivate: true,
    exact: true,
    component: MissionDesignPage,
    permissionType: 'mission',
  },
  {
    path: ROUTES.MSD,
    auth: ['msd'],
    exact: true,
    isPrivate: true,
    component: MissionDesignPage,
  },
  {
    path: '/msd/mission-geometry',
    auth: ['msd'],
    exact: true,
    component: MissionGeometryPage,
  },
  {
    path: '/msd/ground-segment',
    auth: ['msd'],
    exact: true,
    component: GroundSegmentPage,
  },
  {
    path: '/msd/platform',
    auth: ['msd'],
    exact: true,
    component: PlatformPage,
  },
  {
    path: ROUTES.FINAL_PAGE,
    auth: ['msd'],
    exact: true,
    component: FinalPage,
  },
  {
    path: '/loggedin',
    auth: [],
    exact: true,
    component: BasePage,
  },
  {
    path: '/loggedout',
    auth: [],
    exact: true,
    component: LogoutPage,
  },
  {
    path: '/error',
    auth: [],
    exact: true,
    component: AuthErrorPage,
  },
  {
    path: '/ops/mission/:mission/rti',
    auth: ['ops:rti:session:read'],
    permissionType: 'mission',
    exact: true,
    component: SessionManager,
    isPrivate: true,
  },
  {
    path: '/ops/mission/:mission/rti/session/:session',
    auth: ['ops:rti:session:read'],
    permissionType: 'mission',
    exact: true,
    component: Operate,
    isPrivate: true,
  },
  {
    path: '/ops/mission/:mission/gs-scheduling',
    auth: ['portal:gs:mission:read'],
    permissionType: 'mission',
    exact: false,
    component: GSScheduling,
    isPrivate: true,
  },
  {
    path: '/ops/mission/:mission/library',
    auth: ['ops:satellitegateway'],
    permissionType: 'mission',
    exact: false,
    component: Library,
    isPrivate: true,
  },
  {
    path: '/ops/telemetry',
    auth: ['ops:tm:grafana:user'],
    exact: false,
    component: Telemetry,
    permissionType: 'mission',
    isPrivate: true,
  },
  {
    path: '/ops/mission/:mission/scripting',
    auth: ['ops:scripting:read'],
    permissionType: 'mission',
    exact: false,
    component: Scripting,
    isPrivate: true,
  },
  {
    path: '/ops/mission/:mission/schedule',
    auth: ['ops:activity:read'],
    permissionType: 'mission',
    exact: false,
    component: Scheduling,
    isPrivate: true,
  },
  {
    path: '/ops/mission/:mission/schedule/activity/:activity',
    auth: ['ops:activity:read'],
    permissionType: 'mission',
    exact: false,
    component: Scheduling,
    isPrivate: true,
  },
  {
    path: '/ops/mission/:mission/pdgs/image-processing',
    auth: ['ops:command:pdgs'],
    permissionType: 'mission',
    exact: false,
    component: PdgsScreen,
    isPrivate: true,
  },
  {
    path: '/portal/organisation/:organisation',
    auth: ['organisation:assignment:read'],
    permissionType: 'organisation',
    exact: false,
    component: OrganisationAdministration,
    isPrivate: true,
  },
  {
    path: '/portal/programme/:programme',
    auth: ['programme:assignment:read'],
    permissionType: 'programme',
    exact: false,
    component: ProgrammeAdministration,
    isPrivate: true,
  },
  {
    path: '/portal/roles',
    auth: ['role:read'],
    exact: false,
    component: RolesAdministration,
    isPrivate: true,
  },
  {
    path: '/portal/users',
    auth: ['user:assignment:write'],
    exact: true,
    component: UserAdministration,
    isPrivate: true,
  },
  {
    path: '/portal/users/:user_subject',
    auth: ['role:read'],
    component: UserPage,
    exact: true,
    isPrivate: true,
  },
  {
    path: '/data/map',
    auth: ['data:feature'],
    exact: false,
    component: HomeWrapper,
    isPrivate: true,
  },
  {
    path: '/data/project',
    auth: ['data:scenario:read:own', 'data:tasking:search'],
    exact: false,
    component: HomeWrapper,
    isPrivate: true,
  },
  {
    path: '/data/views',
    auth: ['data:view:read:own'],
    exact: false,
    component: ViewsScreen,
    permissionType: 'datacosmos_view',
    isPrivate: true,
  },
  {
    path: '/data/tasking/overview',
    auth: ['data:tasking:request:read:own'],
    exact: false,
    component: TaskingOverviewScreen,
    permissionType: 'mission',
    isPrivate: true,
  },
  {
    path: '/data/orders',
    auth: [],
    exact: false,
    component: OrdersScreen,
    isPrivate: true,
  },
  {
    path: '/resource/:resource_type/:resource_id',
    auth: [],
    exact: false,
    component: GenericResourcePage,
    isPrivate: true,
  },
  {
    auth: [],
    component: NotFoundPage,
  },
];
