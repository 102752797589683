import classNames from "classnames";
import React, { useRef } from "react";
import type { IconName, IconSize } from "../../icons/Icon";
import Icon from "../../icons/Icon";
import { useButton } from "react-aria";
import type { PressEvent } from "@react-types/shared";
import Tooltip from "../../core/Tooltip/Tooltip";

export type IconButtonProps = {
  /**
   * Whether the button is active or not.
   */
  active?: boolean;
  "data-testid"?: string;
  /**
   * Whether the button is disabled.
   */
  disabled?: boolean;
  /**
   * The icon to display in the button.
   */
  icon: IconName;
  /**
   * The text to display in the tooltip.
   */
  tooltipText?: string;
  /**
   * The size of the icon.
   */
  size?: IconSize;
  /**
   * The color of the icon.
   */
  stroke?: string;
  className?: string;
  /**
   * The function to call when the user clicks the button.
   */
  onPress?: (e: PressEvent) => void;
  /**
   * The function to call when the user enters the button with the mouse.
   */
  onMouseEnter?: React.MouseEventHandler<HTMLButtonElement>;
  /**
   * The function to call when the user leaves the button with the mouse.
   */
  onMouseLeave?: React.MouseEventHandler<HTMLButtonElement>;
  style?: React.CSSProperties;
  "aria-label"?: string; //TODO: since this button does not have text, should the label be mandatory?
};

/**
 * IconButton is a component that can be used to trigger an action or event,
 * such as submitting a form, opening a dialog, canceling an action, or performing a delete operation.
 * It has an icon and can have a tooltip.
 */
export const IconButton = (props: IconButtonProps) => {
  const ref = useRef<HTMLButtonElement>(null);
  const { buttonProps } = useButton(
    {
      "aria-label": props["aria-label"] ?? props.icon,
      isDisabled: props.disabled,
      onPress: props.onPress,
    },
    ref
  );

  return (
    <Tooltip
      content={props.tooltipText ? props.tooltipText : ""}
      isDisabled={!props.tooltipText}
    >
      <button
        {...buttonProps}
        className={classNames("flex flex-col justify-center", props.className)}
        data-testid={props["data-testid"]}
        disabled={props.disabled}
        style={props.style}
        onMouseEnter={props.onMouseEnter}
        onMouseLeave={props.onMouseLeave}
      >
        <div className="flex flex-col justify-center h-full">
          <Icon
            icon={props.icon}
            size={props.size}
            stroke={props.stroke}
            className={classNames({
              "cursor-pointer": !props.disabled,

              "stroke-accent-900 dark:stroke-accent-400": props.active,
              "stroke-contrast-inactive fill-contrast-inactive": props.disabled,
              "hover:stroke-accent-950 hover:dark:stroke-accent-200":
                !props.disabled,
            })}
          />
        </div>
      </button>
    </Tooltip>
  );
};

export default IconButton;
