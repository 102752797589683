import type { AxiosRequestConfig, AxiosResponse } from 'axios';
import axios from 'axios';
import { SubmissionError } from 'redux-form';
import { BACKEND_BASE_URL, MOCK_API } from 'env';
import MockAPI from '__mock__/api';

const API_PREFIX = '/portal/v0';

const axiosConfig: AxiosRequestConfig = {
  baseURL: `${BACKEND_BASE_URL}${API_PREFIX}`,
};

const axiosInst = axios.create(axiosConfig);

if (MOCK_API) {
  MockAPI(axiosInst);
}

const PdfApi = {
  exportPDF: {
    post: (data: any, token: string, fileName?: string): Promise<BlobPart> => {
      return axiosInst
        .post(`/html-to-pdf/pdf?filename=${fileName || 'result.pdf'}`, data, {
          headers: {
            'Content-Type': 'text/html',
          },
          responseType: 'blob',
        })
        .then((res): any => res.data);
    },
  },
};

// apply interceptor on response
axiosInst.interceptors.response.use(
  (response): AxiosResponse<void> => response,
  (error): void => {
    if (error.response) {
      throw new SubmissionError(error.response.data.payload);
    }
  }
);

export default PdfApi;
