import { DateRangeInput } from '@blueprintjs/datetime';
import { DATE_FORMAT } from 'constants/datetime';
import moment from 'moment';
import React from 'react';
import type { ActivityType, ActivityStatus } from 'api/activities/service';
import { toFormattedDate } from 'utils/common/dateUtils';
import SchedulingFilter from 'pages/ops/Scheduling/SchedulingFilter';
import s from './index.module.scss';
import type { FromToDate } from 'pages/ops/Scheduling';
import { Button } from '@blueprintjs/core';

type SchedulingHeaderProps = {
  selectedStatuses: ActivityStatus[];
  setActivityStatus: React.Dispatch<React.SetStateAction<ActivityStatus[]>>;
  fromToDate: FromToDate;
  setFromToDate: React.Dispatch<React.SetStateAction<FromToDate>>;
  selectedTypes: ActivityType[];
  setSelectedTypes: React.Dispatch<React.SetStateAction<ActivityType[]>>;
  setCalendarMode: React.Dispatch<React.SetStateAction<boolean>>;
  isCalendarMode: boolean;
};

const SchedulingHeader = ({
  selectedStatuses: activityStatus,
  setActivityStatus,
  setFromToDate,
  fromToDate,
  selectedTypes: activityType,
  setSelectedTypes: setActivityType,
  setCalendarMode,
  isCalendarMode,
}: SchedulingHeaderProps) => {
  return (
    <div className={s.container}>
      <h1>Activities</h1>
      <div className={s.filtersWrapper}>
        <Button
          aria-label="calendar button"
          name="calendar"
          icon="calendar"
          onClick={() => setCalendarMode((prev) => !prev)}
          active={isCalendarMode}
        />
        <SchedulingFilter
          setSelectedStatuses={setActivityStatus}
          selectedStatuses={activityStatus}
          selectedTypes={activityType}
          setSelectedTypes={setActivityType}
        />
        <div>
          <DateRangeInput
            formatDate={toFormattedDate}
            parseDate={(str) => moment(str, DATE_FORMAT).toDate()}
            popoverProps={{
              position: 'auto-end',
            }}
            value={[fromToDate.from, fromToDate.to]}
            onChange={(value) =>
              setFromToDate({ from: value[0], to: value[1] })
            }
            shortcuts={false}
            startInputProps={{ className: s.datePicker }}
            endInputProps={{ className: s.datePicker }}
            maxDate={moment().endOf('year').add(5, 'years').toDate()}
          />
        </div>
      </div>
    </div>
  );
};

export default SchedulingHeader;
