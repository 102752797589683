import React from 'react';
import styles from './styles.module.scss';
import FieldWithErrorCallout from '../../../../components/formik/FieldWithErrorCallout';

interface IProps {
  readOnly: boolean;
}

const PhysicalProperties: React.FunctionComponent<IProps> = ({ readOnly }) => {
  return (
    <div>
      <div>
        <h3 className={styles.title}>Physical Characteristics</h3>
      </div>
      <div className={styles.body}>
        <div className={styles.row}>
          <div className={styles.labelColumn}>
            <span>Mass [kg]</span>
          </div>

          <div className={styles.valueColumn}>
            <FieldWithErrorCallout
              type="text"
              name="data.physical.mass"
              className={`bp4-input ${styles.customBp3Cell}`}
              disabled={readOnly}
            />
          </div>
        </div>
        <div className={styles.row}>
          <div className={styles.labelColumn}>
            <span>X Size [cm]</span>
          </div>

          <div className={styles.valueColumn}>
            <FieldWithErrorCallout
              type="text"
              name="data.physical.xLength"
              className={`bp4-input ${styles.customBp3Cell}`}
              disabled={readOnly}
            />
          </div>
        </div>
        <div className={styles.row}>
          <div className={styles.labelColumn}>
            <span>Y Size [cm]</span>
          </div>

          <div className={styles.valueColumn}>
            <FieldWithErrorCallout
              type="text"
              name="data.physical.yLength"
              className={`bp4-input ${styles.customBp3Cell}`}
              disabled={readOnly}
            />
          </div>
        </div>
        <div className={styles.row}>
          <div className={styles.labelColumn}>
            <span>Z Size [cm]</span>
          </div>

          <div className={styles.valueColumn}>
            <FieldWithErrorCallout
              type="text"
              name="data.physical.zLength"
              className={`bp4-input ${styles.customBp3Cell}`}
              disabled={readOnly}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PhysicalProperties;
