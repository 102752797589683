import React, { useEffect } from 'react';
import Editor from '@monaco-editor/react';
import type { IFolder } from '../../../services/api/ops/cloudStorage';
import { splitLongStringByChunk } from '../../../utils/common/stringUtils';
import type { IMode, IModeProps } from '..';
import { IconNames } from '@blueprintjs/icons';
import {
  base16StringToByteArray,
  byteArrayToBase16String,
} from '../../../utils/common/binnaryUtils';

const HexMode: IMode<string> = ({
  fileDetails,
  renderedContent,
  setRenderedContent,
}: IModeProps<string>) => {
  useEffect(() => {
    if (fileDetails) {
      const { file } = fileDetails;

      if (!file) {
        return;
      }

      const data = splitLongStringByChunk(
        byteArrayToBase16String(file),
        16
      ).join('\n');

      setRenderedContent(data);
    }
  }, [fileDetails, setRenderedContent]);

  return (
    <Editor
      options={{
        lineNumbersMinChars: 8,
        lineNumbers: (lineNumber: number) => {
          const number = ((lineNumber - 1) * 16).toString(16);
          return '000000'.substr(number.length) + number;
        },
      }}
      value={renderedContent}
      onChange={(value) => value && setRenderedContent(value)}
    />
  );
};

HexMode.isValid = (_: IFolder) => {
  return true;
};

HexMode.isPreferred = (fileDetails: IFolder) => {
  return Boolean(fileDetails.name.includes('.bin'));
};

HexMode.saveContent = (content: string) => base16StringToByteArray(content);

HexMode.isEditable = true;

HexMode.icon = IconNames.EDIT;

HexMode.id = 'Hex';

export default HexMode;
