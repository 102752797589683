import { useCallback, useEffect, useRef, useState } from 'react';
import { useApplicationCatalog } from 'datacosmos/stores/ApplicationCatalogContext';
import type { IApplication } from 'datacosmos/types/applications';
import { useMapLayers } from 'datacosmos/stores/MapLayersProvider';

import { SingleBandSTACLayer } from 'datacosmos/entities/singleBandLayer';

import UnopenedAppCard from 'datacosmos/components/Applications/SubscriptionApps/Common/UnopenedAppCard';
import OpenedAppCard from 'datacosmos/components/Applications/SubscriptionApps/Common/OpenedAppCard';
import { btoaSafe } from 'utils/common/btoaSafe';
import { useActivePage } from 'datacosmos/components/Toolbar/ActivePageProvider';
import { Input, NumberInput, RangeSlider } from 'opencosmos-ui';
import { useClickedStacItem } from 'datacosmos/utils/hooks/useClickedStacItem';
import { LayerSourceType } from 'datacosmos/entities/layer';
import { postGeontransformImage } from 'api/sampling/service';
import Spinner from 'opencosmos-ui/src/core/Spinner/Spinner';

interface IProps {
  app: IApplication;
}

export const ShiftImageApplication: IApplication = {
  get id() {
    return btoaSafe(
      JSON.stringify(
        this.name +
          JSON.stringify(this.provider) +
          this.description +
          this.appScreenshotUrl
      ).substring(0, 75)
    );
  },
  name: 'Shift image',
  description: 'Shift an image by a given offset in the x and y directions',
  inputs: [
    {
      field: 'imageId',
      example: '',
    },
    {
      field: 'xOffset',
      example: '10',
    },
    {
      field: 'yOffset',
      example: '10',
    },
  ],
  values: {
    imageId: { value: undefined, isError: false, message: '' },
    xOffset: { value: 0, isError: false, message: '' },
    yOffset: { value: 0, isError: false, message: '' },
  },
  provider: {
    id: 1,
    name: 'Open Cosmos',
    description: 'Making space accessible',
    url: 'https://www.open-cosmos.com',
    icon_url:
      'https://storage.googleapis.com/datacosmos-public/manual_outputs/AG/OpenCosmos.svg',
  },
  shortDescription:
    'Shift an image by a given offset in the x and y directions',
  renderer: (app: IApplication) => <ShiftImage app={app} />,
  appScreenshotUrl: '',
  tags: [],
};

/**
 * MeasureHeight application renderer
 */
export function ShiftImage({ app }: IProps) {
  const {
    layers,
    replaceLayer,
    replaceSpecificLayerWithAnother,
    removeLayersBySourceType,
  } = useMapLayers();
  const {
    setInputData,
    toggleAppInstall,
    getInstalledStatus,
    shouldAutoOpen,
    setSelectedInstalledApp,
  } = useApplicationCatalog();
  const [isAppOpened, setIsAppOpened] = useState<boolean>(false);

  const { activePage, setActivePage } = useActivePage();

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const { clickedStacLayer, layersContainStacItems } = useClickedStacItem({
    outlineClickedItem: true,
    onImageClick: (img) => {
      setValue('imageId', img.item.id);
    },
  });

  const clickedStacLayerFromLayers = (
    layers.filter(
      (l) => l instanceof SingleBandSTACLayer
    ) as SingleBandSTACLayer[]
  ).find((l) => l.item.id === clickedStacLayer?.item.id);

  const setValue = useCallback(
    (key: string, value: string | number | undefined) => {
      setInputData(app.name, {
        ...app.values,
        [key]: {
          value: value === undefined ? '' : `${value}`,
          isError: false,
          message: '',
        },
      });
    },
    [app.name, app.values, setInputData]
  );

  const setError = (key: string, message: string) => {
    setInputData(app.name, {
      ...app.values,
      [key]: { value: '', isError: true, message: message },
    });
  };

  useEffect(() => {
    if (!layersContainStacItems) {
      setValue('imageId', undefined);
    }
  }, [layersContainStacItems, setValue]);

  const xOffsetDebounceTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  const yOffsetDebounceTimeoutRef = useRef<NodeJS.Timeout | null>(null);

  const inputs = () => {
    return (
      <div className="flex flex-col gap-2">
        <Input
          type="text"
          readOnly
          value={clickedStacLayer?.item.id}
          placeholder={'Select a full res image on the map...'}
          label={{ position: 'top', text: 'Selected image' }}
        />
        <RangeSlider
          numberOfHandles={1}
          label={{ text: 'Opacity' }}
          showValuesAboveHandles={true}
          showScale={false}
          value={clickedStacLayerFromLayers?.options.opacity}
          minValue={0}
          maxValue={1}
          step={0.1}
          onChange={(val) => {
            if (clickedStacLayerFromLayers && val !== undefined) {
              replaceLayer(
                clickedStacLayerFromLayers.cloneWithOptions({
                  opacity: val as number,
                })
              );
            }
          }}
        />
        <NumberInput
          label="X offset"
          onChange={(val) => {
            if (!clickedStacLayerFromLayers) {
              return;
            }

            if (xOffsetDebounceTimeoutRef.current) {
              clearTimeout(xOffsetDebounceTimeoutRef.current);
            }

            setValue('xOffset', val);

            // Remove outline when changing offset
            removeLayersBySourceType(LayerSourceType.ASSET_OUTLINE);

            xOffsetDebounceTimeoutRef.current = setTimeout(() => {
              const newLayer = new SingleBandSTACLayer(
                clickedStacLayerFromLayers.item,
                clickedStacLayerFromLayers.assetKey
              ).cloneWithOptions({
                ...clickedStacLayerFromLayers.options,
                offset: {
                  x: val,
                  y: clickedStacLayerFromLayers.options.offset?.y ?? 0,
                },
              });

              replaceSpecificLayerWithAnother(
                newLayer,
                clickedStacLayerFromLayers
              );
            }, 500);
          }}
        />
        <NumberInput
          label="Y offset"
          onChange={(val) => {
            if (!clickedStacLayerFromLayers) {
              return;
            }

            if (yOffsetDebounceTimeoutRef.current) {
              clearTimeout(yOffsetDebounceTimeoutRef.current);
            }

            setValue('yOffset', val);

            // Remove outline when changing offset
            removeLayersBySourceType(LayerSourceType.ASSET_OUTLINE);

            yOffsetDebounceTimeoutRef.current = setTimeout(() => {
              const newLayer = new SingleBandSTACLayer(
                clickedStacLayerFromLayers.item,
                clickedStacLayerFromLayers.assetKey
              ).cloneWithOptions({
                ...clickedStacLayerFromLayers.options,
                offset: {
                  x: clickedStacLayerFromLayers.options.offset?.x ?? 0,
                  y: val,
                },
              });

              replaceSpecificLayerWithAnother(
                newLayer,
                clickedStacLayerFromLayers
              );
            }, 500);
          }}
        />
      </div>
    );
  };

  if (shouldAutoOpen || (isAppOpened && getInstalledStatus(app))) {
    return (
      <OpenedAppCard
        app={app}
        inputsRenderer={inputs}
        setIsAppOpened={setIsAppOpened}
        toggleAppInstall={toggleAppInstall}
        isInstalled={getInstalledStatus(app)}
        handleSubmit={async () => {
          if (!clickedStacLayerFromLayers) {
            setError('imageId', 'Please select a full res image');
            return;
          }

          if (clickedStacLayerFromLayers?.assetKey !== 'visual') {
            setError('imageId', 'Please select a full res image');
            return;
          }

          setIsSubmitting(true);
          await postGeontransformImage({
            body: {
              x_offset: parseInt(app.values.xOffset.value as string),
              y_offset: parseInt(app.values.yOffset.value as string),
            },
            params: {
              collection: clickedStacLayerFromLayers.item.collection ?? '',
              items: app.values.imageId.value as string,
            },
          });

          setIsSubmitting(false);
        }}
        submitButtonLabel={isSubmitting ? <Spinner size={18} /> : 'Apply'}
      />
    );
  }

  return (
    <UnopenedAppCard
      app={app}
      setIsAppOpened={setIsAppOpened}
      toggleAppInstall={toggleAppInstall}
      isInstalled={getInstalledStatus(app)}
      setSelectedInstalledApp={(selectedApp) => {
        setSelectedInstalledApp(selectedApp);
        if (activePage === 'application') {
          setActivePage(undefined);
        }
      }}
    />
  );
}
