import DangerButton from '_molecules/Button/DangerButton';
import PrimaryButton from '_molecules/Button/PrimaryButton';
import OrderCard from '_organisms/OrderCard/OrderCard';
import OrderItemCard from '_organisms/OrderItemCard/OrderItemCard';
import {
  CompositeDetailsItem,
  DetailsItem,
} from 'pages/ops/Scheduling/ScheduleActivityDetails/DetailCards/DetailsItem';
import React, { useEffect } from 'react';
import { Column } from 'ui/ColumnLayout/Column';
import { ColumnLayout } from 'ui/ColumnLayout/ColumnLayout';
import { DateInput } from 'ui/DateInput';
import { Header } from 'ui/Header';
import { ItemTabContainer } from 'ui/ItemContainer';
import { Navbar } from 'ui/Navbar';
import { Row } from 'ui/RowLayout/Row';
import { RowLayout } from 'ui/RowLayout/RowLayout';
import { Screen } from 'ui/Screen';
import { Text } from 'ui/Text';
import { useOrdersScreenData } from './useOrdersScreenData';
import { NonIdealState, Spinner } from '@blueprintjs/core';
import { useLocalisation } from 'utils/hooks/useLocalisation';

const OrdersScreen = () => {
  const {
    ordersByDate,
    isOrdersLoading,
    toggleOrderCheck,
    checkedOrder,
    setToDateFilter,
    setFromDateFilter,
    fromDateFilter,
    toDateFilter,
    toggleOrderSelect,
    isOrderSelected,
    selectedOrder,
    customer,
    isCustomerLoading,
    isAdmin,
    markOrderAsPaid,
    markOrderAsCancelled,
  } = useOrdersScreenData();

  const { translate } = useLocalisation();

  const withNoOrderSelected = (children: React.ReactNode) => {
    if (!selectedOrder) {
      return (
        <NonIdealState
          title={
            <span className="dark:text-item-dark-contrast">
              {translate('datacosmos.fetchErrors.orders.order.noOrderSelected')}
            </span>
          }
          description={translate(
            'datacosmos.fetchErrors.orders.order.noOrderSelectedDescription'
          )}
          className="dark:text-item-dark-contrast"
          icon="info-sign"
        />
      );
    }

    return children;
  };

  const withNoOrdersFoundInDateRange = (children: React.ReactNode) => {
    if (
      ordersByDate &&
      Object.keys(ordersByDate).length === 0 &&
      (fromDateFilter || toDateFilter)
    ) {
      return (
        <NonIdealState
          icon="info-sign"
          title={
            <span className="dark:text-item-dark-contrast">
              {translate('datacosmos.fetchErrors.orders.order.noOrdersFound')}
            </span>
          }
          description={translate(
            'datacosmos.fetchErrors.orders.order.noOrdersFoundFiltersDescription'
          )}
          className="dark:text-item-dark-contrast"
        />
      );
    }

    return children;
  };

  const withNoOrdersFetched = (children: React.ReactNode) => {
    if (
      ordersByDate &&
      Object.keys(ordersByDate).length === 0 &&
      (!fromDateFilter || !toDateFilter)
    ) {
      return (
        <NonIdealState
          icon="info-sign"
          title={
            <span className="dark:text-item-dark-contrast">
              {translate('datacosmos.fetchErrors.orders.order.noOrdersFound')}
            </span>
          }
          description={translate(
            'datacosmos.fetchErrors.orders.order.noOrdersFoundDescription'
          )}
          className="dark:text-item-dark-contrast"
        />
      );
    }

    return children;
  };

  const withLoadingOrders = (children: React.ReactNode) => {
    if (isOrdersLoading) {
      return <Spinner />;
    }

    return children;
  };

  // This fixes issue in Chrome where screen would overflow even though everything was set with appropriate overflow
  //TODO: Remove if the Chrome issue gets fixed
  useEffect(() => {
    // Add overflow hidden to html element on page mount
    document.documentElement.classList.add('overflow-hidden');

    // Remove overflow hidden on page unmount
    return () => document.documentElement.classList.remove('overflow-hidden');
  }, []);

  return (
    <Screen>
      <Navbar />
      <ColumnLayout cols={2} colRatio={[1, 0.5]} horizontalGap={1}>
        <Column style={{ overflow: 'auto' }}>
          <RowLayout rows={3} rowRatio={[0.1, 0.1, 1]}>
            <Row justify="space-between">
              <Header size="h1">Orders</Header>
              <ItemTabContainer>
                <DateInput
                  setValue={(from) => {
                    setFromDateFilter(from);
                  }}
                  value={fromDateFilter}
                  title={translate('datacosmos.orders.start')}
                />
                <DateInput
                  setValue={(to) => {
                    setToDateFilter(to);
                  }}
                  value={toDateFilter}
                  title={translate('datacosmos.orders.end')}
                />
              </ItemTabContainer>
            </Row>

            <Row justify="end">
              {checkedOrder.length > 0 && (
                <ItemTabContainer className="gap-1">
                  <Text>Selected actions</Text>
                  {isAdmin && (
                    <PrimaryButton
                      text={'Mark as paid'}
                      onPress={async () => {
                        if (checkedOrder.length === 0) {
                          return;
                        }
                        await Promise.all(
                          checkedOrder.map((o) => markOrderAsPaid(o))
                        );
                      }}
                    />
                  )}
                  <DangerButton
                    text={'Cancel'}
                    onPress={async () => {
                      if (checkedOrder.length === 0) {
                        return;
                      }
                      await Promise.all(
                        checkedOrder.map((o) => markOrderAsCancelled(o))
                      );
                    }}
                  />
                </ItemTabContainer>
              )}
            </Row>

            <Row style={{ overflow: 'auto' }}>
              <Column style={{ padding: '8px' }} data-testid="orders-list">
                {withLoadingOrders(
                  withNoOrdersFetched(
                    withNoOrdersFoundInDateRange(
                      Object.entries(ordersByDate ?? {}).map(
                        ([date, orders]) => (
                          <>
                            <Header key={date} size="h3">
                              {date}
                            </Header>
                            {orders.map((o) => (
                              <OrderCard
                                onClick={() => {
                                  toggleOrderSelect(o);
                                }}
                                key={o.id}
                                fieldsToShow={['all']}
                                order={o}
                                isSelected={isOrderSelected(o)}
                                checkbox={{
                                  onChange: () => {
                                    toggleOrderCheck(o);
                                  },
                                }}
                              />
                            ))}
                          </>
                        )
                      )
                    )
                  )
                )}
              </Column>
            </Row>
          </RowLayout>
        </Column>

        {/* Details */}
        <Column style={{ overflow: 'auto' }}>
          {withNoOrderSelected(
            <RowLayout rows={3} rowRatio={[0.2, 0.5, 1]}>
              <Row justify="space-between">
                <Header size="h2">Details</Header>
                <ItemTabContainer className="gap-1 items-center mt-2">
                  {isAdmin && (
                    <PrimaryButton
                      text={'Mark as paid'}
                      onPress={async () => {
                        if (!selectedOrder) {
                          return;
                        }

                        await markOrderAsPaid(selectedOrder);
                      }}
                    />
                  )}
                  <DangerButton
                    text={'Cancel'}
                    onPress={async () => {
                      if (!selectedOrder) {
                        return;
                      }

                      await markOrderAsCancelled(selectedOrder);
                    }}
                  />
                </ItemTabContainer>
              </Row>

              <Row>
                <Column>
                  <DetailsItem
                    title="Order id"
                    value={selectedOrder?.id ?? '-'}
                  />
                  <DetailsItem
                    title="Order status"
                    value={selectedOrder?.status ?? '-'}
                  />
                  <DetailsItem
                    title="Payment method"
                    value={selectedOrder?.payment_method ?? '-'}
                  />
                  <DetailsItem
                    title="Payment id"
                    //TODO: Add when available
                    value={selectedOrder?.payment_reference_number ?? '-'}
                  />
                  <DetailsItem
                    title="Downloaded?"
                    //TODO: Add when available
                    value={'unknown'}
                  />
                  <DetailsItem
                    title="Visualized?"
                    //TODO: Add when available
                    value={'unknown'}
                  />
                  <DetailsItem
                    title="Access expire in"
                    //TODO: Add when available
                    value={'unknown'}
                  />
                  <CompositeDetailsItem
                    title="Ordered by"
                    element={
                      isCustomerLoading ? (
                        <Spinner size={12} />
                      ) : (
                        customer?.name ?? '-'
                      )
                    }
                  />
                </Column>
              </Row>

              <Row>
                <Column>
                  <Header size="h2">Items</Header>
                  {selectedOrder?.order_line_items.map((item) => (
                    <OrderItemCard orderItem={item} key={item.item} />
                  ))}
                </Column>
              </Row>
            </RowLayout>
          )}
        </Column>
      </ColumnLayout>
    </Screen>
  );
};

export default OrdersScreen;
