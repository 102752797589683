import type { IOption } from '../../inputField/SelectField';
import { OBJECTIVE_TYPES } from '../../../constants/objectivesTable/constants';
import { Pre } from '@blueprintjs/core';
import { Field, Fields } from 'redux-form';
import selectField from '../../inputField/SelectField';
import type { ReactElement } from 'react';
import React from 'react';
import CriterionField from '../../inputField/CriterionField';

export const MissionMetric = (props: { options: IOption[] }): ReactElement => {
  const { options } = props;
  return (
    <Pre key={OBJECTIVE_TYPES.MISSION_METRIC}>
      <Field
        name="metric"
        title={'objectives.metric.title'}
        options={options}
        component={selectField}
      />
      <Fields
        names={['criterion', 'constraints.min', 'constraints.max', 'metric']}
        component={CriterionField}
      />
    </Pre>
  );
};
