import React, { useEffect } from 'react';
import Editor from '@monaco-editor/react';
import {
  UTF8StringToByteArray,
  byteArrayToUTF8String,
} from '../../../utils/common/binnaryUtils';
import type { IFolder } from '../../../services/api/ops/cloudStorage';
import type { IMode, IModeProps } from '..';
import { IconNames } from '@blueprintjs/icons';
import { isNil } from 'lodash';

const UTF8Mode: IMode<string> = ({
  fileDetails,
  renderedContent,
  setRenderedContent,
}: IModeProps<string>) => {
  useEffect(() => {
    if (fileDetails) {
      const { file } = fileDetails;

      if (!file) {
        return;
      }

      const data = byteArrayToUTF8String(file);

      setRenderedContent(data);
    }
  }, [fileDetails, setRenderedContent]);

  const usingPython =
    fileDetails?.name &&
    fileDetails.name.length > 3 &&
    fileDetails.name.endsWith('.py');

  const language = usingPython ? 'python' : undefined;

  return (
    <Editor
      options={{
        lineNumbersMinChars: 5,
      }}
      value={renderedContent}
      onChange={(value) => value && setRenderedContent(value)}
      language={language}
    />
  );
};

UTF8Mode.isValid = (fileDetails: IFolder) => {
  if (fileDetails && !isNil(fileDetails.file)) {
    const data = byteArrayToUTF8String(fileDetails.file);
    if (typeof data !== 'undefined') {
      return true;
    }
  }
  return false;
};

UTF8Mode.isPreferred = (fileDetails: IFolder) => {
  return Boolean(fileDetails?.name.includes('.txt'));
};

UTF8Mode.saveContent = (content: string) => UTF8StringToByteArray(content);

UTF8Mode.isEditable = true;

UTF8Mode.icon = IconNames.EDIT;

UTF8Mode.id = 'utf-8';

export default UTF8Mode;
