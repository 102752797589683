import React, { useRef } from 'react';
import type { AriaOverlayProps } from '@react-aria/overlays';
import type { AriaDialogProps } from '@react-types/dialog';
import {
  FocusScope,
  OverlayContainer,
  mergeProps,
  useDialog,
  useModal,
  useOverlay,
  usePreventScroll,
} from 'react-aria';
import Tabs from 'opencosmos-ui/src/core/Tabs/Tabs';
import { Item } from 'react-stately';
import type { Dispatch, SetStateAction } from 'react';
import type { MimeType } from '../../utils/screenshot';
import { downloadScreenshotAsGeoJson } from '../../datacosmos/download/geojson';
import type { IconName } from 'opencosmos-ui/src/icons/Icon';
import { Button } from 'opencosmos-ui/src/core/Button/Button';
import type { Layer } from 'datacosmos/entities/layer';

type BaseModalProps = AriaOverlayProps &
  AriaDialogProps & {
    children: React.ReactNode;
    title?: string;
  };
const BaseModal = (props: BaseModalProps) => {
  const { children, title } = props;
  const ref = useRef<HTMLDivElement>(null);

  const { overlayProps, underlayProps } = useOverlay(props, ref);
  usePreventScroll();

  const { modalProps } = useModal();
  const { dialogProps, titleProps } = useDialog(props, ref);

  return (
    <div
      {...underlayProps}
      className="fixed inset-0 w-full flex justify-center items-center bg-item-contrast/50"
      style={{ zIndex: 10 }}
    >
      <FocusScope contain restoreFocus autoFocus>
        <div
          {...mergeProps(dialogProps, modalProps, overlayProps)}
          ref={ref}
          className="bg-surface !opacity-100 dark:bg-surface-dark dark:text-item-dark-contrast pt-3 pb-3 pl-6 pr-6"
        >
          {title ? <h4 {...titleProps}>{title}</h4> : <div />}
          <div className="flex items-center gap-2">{children}</div>
        </div>
      </FocusScope>
    </div>
  );
};

interface Option {
  name: string;
  icon?: IconName;
  onClick?: () => void;
}

interface OptionWithTabs {
  optionType: string;
  formats: {
    name: string;
    icon?: IconName;
    onClick?: () => void;
  }[];
}

type Props = {
  /**
   * Whether the modal is open or not.
   */
  isOpen: boolean;
  /**
   * Callback to set the open state of the modal.
   */
  setIsOpen: (isOpen: boolean) => void;
  /**
   * The options to display in the modal.
   * Each option has a name, an optional icon, and a onClick callback.
   */
  options: Option[] | OptionWithTabs[];
  /**
   * The title of the modal.
   */
  title?: string;
  setMimeType?: Dispatch<SetStateAction<MimeType>>;
  showOptionsUnderTabs?: boolean;
  layers: Layer[];
};

// TODO: Restrict imports from openapp eslint: importRestrictedPaths/noRestrictedPaths
/**
 * OptionDialog is a modal that displays a list of selectable options.
 */
const OptionsDialog = ({
  options,
  isOpen,
  setIsOpen,
  title,
  setMimeType,
  showOptionsUnderTabs,
  layers,
}: Props) => {
  return isOpen ? (
    <OverlayContainer>
      <BaseModal
        isOpen={isOpen}
        isDismissable={true}
        onClose={() => {
          setIsOpen(false);
        }}
        title={title}
      >
        {showOptionsUnderTabs ? (
          <Tabs customPadding="10px">
            {(options as OptionWithTabs[]).map((o) => (
              <Item
                title={<div className="w-full">{o.optionType}</div>}
                key={o.optionType}
              >
                {o.formats.map((format) => (
                  <Button
                    key={format.name + format.icon}
                    text={format.name}
                    icon={format.icon}
                    onPress={() => {
                      setIsOpen(false);
                      if (format.onClick) {
                        format.onClick();
                        return;
                      }
                      if (setMimeType) {
                        downloadScreenshotAsGeoJson(layers);
                        setMimeType('file/geojson');
                      }
                    }}
                    className="mx-1"
                  />
                ))}
              </Item>
            ))}
          </Tabs>
        ) : (
          (options as Option[]).map((o) => (
            <Button
              key={o.name + o.icon}
              text={o.name}
              icon={o.icon}
              onPress={() => {
                setIsOpen(false);
                if (o.onClick) {
                  o.onClick();
                }
              }}
            />
          ))
        )}
      </BaseModal>
    </OverlayContainer>
  ) : null;
};

export default OptionsDialog;
