import { apiFetchHandler } from 'api/clientFetch';
import { hosts } from 'api/hosts';
import type { ActiveSessionResponse } from './types';

export type GetActiveSessionsParams = {
  missionId: number;
};

export const getActiveSessions = apiFetchHandler<
  ActiveSessionResponse,
  GetActiveSessionsParams
>({
  host: hosts.portal.v0,
  method: 'GET',
  endpoint: ({ missionId }) => `/sessions/${missionId}`,
  errorMessage: 'Could not get active sessions',
});

export type CreateActiveSessionParams = GetActiveSessionsParams;

export const createActiveSession = apiFetchHandler<
  undefined,
  CreateActiveSessionParams
>({
  host: hosts.portal.v0,
  method: 'POST',
  endpoint: ({ missionId }) => `/sessions/${missionId}`,
  errorMessage: 'Could not create active session',
});
