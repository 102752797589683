import type { IFocus, focusTypes } from '../../constants/focus/actionTypes';
import { SET_FOCUS, RESET_FOCUS } from '../../constants/focus/actionTypes';

export function setFocusAction(focus: IFocus): focusTypes {
  return {
    type: SET_FOCUS,
    focus: focus,
  };
}

export function resetFocusAction(): focusTypes {
  return {
    type: RESET_FOCUS,
  };
}
