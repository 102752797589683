import { Field } from 'formik';
import TwoTargetAttitudeForm from 'components/modesPanel/TwoTargetAttitudeForm';
import type { IFormValues } from 'components/modesPanel/ModeForm';
import { ATTITUDE_LAW_TYPE_KEY } from 'components/modesPanel/constants';
import styles from 'components/modesPanel/styles.module.scss';

export enum ATTITUDE_TYPES {
  TWO_TARGET = 'Two-Targets',
}
const AVAILABLE_ATTITUDE_LAWS = [ATTITUDE_TYPES.TWO_TARGET];

// Expects to be in a formik form already
const AttitudeForm = ({ values }: { values: IFormValues }) => {
  return (
    <div className={styles.attitudeForm}>
      <div className="bp4-select bp4-fill disable-left-padding">
        <Field as="select" name={ATTITUDE_LAW_TYPE_KEY}>
          {AVAILABLE_ATTITUDE_LAWS.map((name) => (
            <option key={name} value={name}>
              {name}
            </option>
          ))}
        </Field>
      </div>
      <TwoTargetAttitudeForm values={values} />
    </div>
  );
};

export default AttitudeForm;
