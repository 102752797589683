import type { IPopUp, popUpTypes } from '../../constants/popUp/actionTypes';
import { SET_POPUP, RESET_POPUP } from '../../constants/popUp/actionTypes';

export function setPopUpAction(popUp: IPopUp): popUpTypes {
  return {
    type: SET_POPUP,
    popUp: popUp,
  };
}

export function resetPopUpAction(): popUpTypes {
  return {
    type: RESET_POPUP,
  };
}
