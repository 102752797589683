import React from 'react';
import ModuleSelectionPage from '../../pages/msd/PayloadConfiguration/ModuleSelectionPage';
import { H3 } from '@blueprintjs/core';
import type { ISatelliteModuleType } from '../../constants/satellite/types';
import styles from './styles.module.scss';
import { connect } from 'react-redux';
import { selectSatelliteModules } from '../../selectors/satellite';
import type { AppState } from '../../store';
import { updateSatelliteModule } from '../../actions/ui/platformConfigurator/action';

interface IOwnProps {
  title: string;
  type: ISatelliteModuleType;
  resetPopUp: () => void;
}

interface IStateProps {}

interface IDispatchProps {
  updateSatelliteModule: typeof updateSatelliteModule;
}

type IProps = IOwnProps & IStateProps & IDispatchProps;

const ModuleSelectionPopup = ({
  title,
  type,
  resetPopUp,
  updateSatelliteModule,
}: IProps) => (
  <div className="bp4-dialog dialog-margin-set-out">
    <div className="bp4-dialog-header">
      <H3>{title}</H3>
      {
        // eslint-disable-next-line
        <div
          role="button"
          tabIndex={0}
          className="bp4-dialog-close-button bp4-button bp4-minimal bp4-icon-cross"
          onClick={resetPopUp}
        />
      }
    </div>
    <div className={`bp4-dialog-body ${styles.bodyContainer}`}>
      {
        //@ts-ignore
        <ModuleSelectionPage
          type={type}
          submitHandler={(selectedNames: string[]) => {
            resetPopUp();
            updateSatelliteModule(type, selectedNames);
          }}
        />
      }
    </div>
  </div>
);

const mapStateToProps = (state: AppState): IStateProps => ({
  moduleData: selectSatelliteModules(state),
});

const mapDispatchToProps: IDispatchProps = {
  updateSatelliteModule,
};

export default connect<IStateProps, IDispatchProps>(
  mapStateToProps,
  mapDispatchToProps
)(ModuleSelectionPopup);
