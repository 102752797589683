import { DATETIME_FORMAT } from 'constants/datetime';
import { BandAlgebraSTACLayer } from 'datacosmos/entities/bandAlgebraLayer';
import { CircleLayer } from 'datacosmos/entities/circleLayer';
import { FieldOfRegardLayer } from 'datacosmos/entities/FieldOfRegardLayer';
import type { Layer } from 'datacosmos/entities/layer';
import { LineLayer } from 'datacosmos/entities/lineLayer';
import { OutlineLayer } from 'datacosmos/entities/outlineLayer';
import { PolygonLayer } from 'datacosmos/entities/polygonLayer';
import { SingleBandSTACLayer } from 'datacosmos/entities/singleBandLayer';
import { STACLayerFactory } from 'datacosmos/entities/stacLayer';
import { SwathLayer } from 'datacosmos/entities/SwathLayer';
import { OpportunityLayer } from 'datacosmos/entities/TaskingOpportunityLayer';
import { useMapLayers } from 'datacosmos/stores/MapLayersProvider';
import { useMap } from 'datacosmos/stores/MapProvider';
import { StacItem } from 'datacosmos/types/stac-types';
import { isAssetPreviewable } from 'datacosmos/utils/stac';
import utils from 'datacosmos/utils/utils';
import moment from 'moment';
import { Item } from 'react-stately';
import { BarChart, CartesianGrid, XAxis, YAxis, Bar, Tooltip } from 'recharts';
import ListItem from '_atoms/ListItem/ListItem';
import Select2 from '_molecules/Select2/Select2';
import Tabs from '_molecules/Tabs/Tabs';
import ListCard from '_organisms/ListCard/ListCard';
import type { IStacInfo } from '../../../utils/hooks/useStacInfo';
import useStacInfo from '../../../utils/hooks/useStacInfo';
import bbox from '@turf/bbox';
import Popover2 from '_atoms/Popover/Popover2';
import IconButton from '_molecules/IconButton/IconButton';

import { default as CustomTooltip } from '_atoms/Tooltip/Tooltip';
import { useLocalisation } from 'utils/hooks/useLocalisation';
import type { DownloadType } from './helpers';
import { getDownloadTypes } from './helpers';
import { useState } from 'react';
import { useAnalytics } from 'utils/hooks/analytics/useAnalytics';

type IProps = {
  layer: Layer;
};

const LayerListCard = ({ layer }: IProps) => {
  const { replaceLayer, removeLayer } = useMapLayers();
  const { disableEditing, setViewToFitBbox } = useMap();

  const { replaceSpecificLayerWithAnother } = useMapLayers();

  const { translate, translateWithExplicitFallback } = useLocalisation();

  const { sendInfo } = useAnalytics();

  const [selectedDlType, setSelectedDlType] =
    useState<DownloadType['ext']>('kml');

  const renderDownloadAsFormat = (
    l: LineLayer | PolygonLayer | CircleLayer
  ) => (
    <Select2
      items={getDownloadTypes(l)}
      selectedKey={selectedDlType}
      onSelectionChange={(k) => setSelectedDlType(k as DownloadType['ext'])}
    >
      {(item) => (
        <Item key={item.ext}>
          <div className="flex items-center justify-between gap-4">
            <span>Download as {item.ext}</span>
            <IconButton icon="Install" onPress={() => item.handler()} />
          </div>
        </Item>
      )}
    </Select2>
  );

  const renderStacItemLayerCard = (
    iLayer: SingleBandSTACLayer,
    stacInfo: IStacInfo
  ) => {
    const {
      handleBandRemove,
      observationZenithAngle,
      sensors,
      handleBandSelect,
      sunElevationAngle,
      selectedBands,
      getSelectedAsset,
      maximumPixelValue,
      minimumPixelValue,
      fetchAssetsMetadata,
      isFetchingMetadata,
      histogramData,
      getHistogramColor,
      jsonMetadata,
    } = stacInfo;

    const tooltipContentJsonItems =
      Object.keys(jsonMetadata ?? {}).length !== 0 ? (
        <ul className="flex flex-col gap-2">
          {Object.entries(jsonMetadata ?? {}).map(([key, val]) => (
            <li className="flex items-center gap-2" key={key}>
              <span>{key}: </span>
              <span>{val}</span>
            </li>
          ))}
        </ul>
      ) : undefined;

    const tooltipContent = (
      <ul className="flex flex-col gap-2">
        <li className="flex items-center gap-2">
          <span>{translate('datacosmos.catalogAndItems.metadata.oza')} </span>
          <span>{observationZenithAngle}</span>
        </li>
        <li className="flex items-center gap-2">
          <span>
            {translate('datacosmos.catalogAndItems.metadata.sensors')}{' '}
          </span>
          <span>{sensors}</span>
        </li>
        <li className="flex items-center gap-2">
          <span>
            {translate('datacosmos.catalogAndItems.metadata.resolution')}{' '}
          </span>
          <span>
            {iLayer.item.properties.gsd
              ? utils.gsdToUnit(iLayer.item.properties.gsd)
              : '-'}
          </span>
        </li>
        <li className="flex items-center gap-2">
          <span>
            {translate('datacosmos.catalogAndItems.metadata.minPixelValue')}{' '}
          </span>
          <span>{isFetchingMetadata ? '...loading' : minimumPixelValue}</span>
        </li>
        <li className="flex items-center gap-2">
          <span>
            {translate('datacosmos.catalogAndItems.metadata.maxPixelValue')}{' '}
          </span>
          <span>{isFetchingMetadata ? '...loading' : maximumPixelValue}</span>
        </li>
        <li className="flex items-center gap-2">
          <Popover2
            isDismissable={true}
            placement="bottom left"
            disabled={isFetchingMetadata || !histogramData}
            popupContent={
              <div>
                <Tabs>
                  {histogramData && histogramData.length > 0 ? (
                    [
                      ...new Set(
                        Object.keys(histogramData[0]).map((k) =>
                          k.replace(/X|Y/, '')
                        )
                      ),
                    ].map((data) => {
                      return (
                        <Item
                          title={translateWithExplicitFallback(
                            `datacosmos.catalogAndItems.metadata.bandColors.${data}` as unknown as TemplateStringsArray,
                            data
                          )}
                        >
                          <BarChart
                            data={histogramData}
                            height={200}
                            width={320}
                            barSize={'25px'}
                          >
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey={`${data}X`} />
                            <YAxis dataKey={`${data}Y`} />
                            <Tooltip />
                            <Bar
                              dataKey={`${data}Y`}
                              fill={getHistogramColor(data)}
                            />
                          </BarChart>
                        </Item>
                      );
                    })
                  ) : (
                    <Item>
                      <div />
                    </Item>
                  )}
                </Tabs>
              </div>
            }
            className="shadow-sm shadow-item-contrast bg-item dark:bg-item-dark  dark:text-item-dark-contrast w-fit p-1"
          >
            <div className="flex items-center gap-2">
              <span>
                {histogramData?.length !== 0
                  ? translate(
                      'datacosmos.catalogAndItems.metadata.showStatistics'
                    )
                  : translate(
                      'datacosmos.catalogAndItems.metadata.noHistogramData'
                    )}
              </span>
            </div>
          </Popover2>
        </li>
      </ul>
    );

    return (
      <ListCard
        title={moment(iLayer.item.date()).format('YYYY MMM DD')}
        info={
          <>
            <ListItem
              icon="Satellite"
              content={iLayer.item.title()}
              className="text-item-contrast-inactive"
              onClick={() => {}}
            />
            {layer instanceof BandAlgebraSTACLayer ? (
              <ListItem
                icon="BandAlgebra"
                content={layer.expression}
                className="text-item-contrast-inactive"
                onClick={() => {}}
              />
            ) : (
              <ListItem
                icon="SunAngle"
                content={sunElevationAngle}
                className="text-item-contrast-inactive"
                onClick={() => {}}
              />
            )}

            <Popover2
              isDismissable={true}
              placement="right"
              popupContent={tooltipContentJsonItems ?? tooltipContent}
              className="p-2 color-item w-fit"
            >
              <ListItem
                icon="More"
                content={translate('datacosmos.catalogAndItems.moreInfo')}
                className="text-item-contrast-inactive"
                onClick={() => {
                  fetchAssetsMetadata();
                }}
              />
            </Popover2>
          </>
        }
        rightIcons={[
          {
            icon: layer.options.visible ? 'Eye' : 'EyeClosed',
            onClick: () => {
              replaceLayer(
                layer.cloneWithOptions({
                  visible: !layer.options.visible,
                })
              );
            },
            shown: true,
          },
          {
            icon: 'Center',
            onClick: () => {
              setViewToFitBbox(iLayer.item.bbox);
            },
            shown: true,
          },
          {
            icon: 'Close',
            onClick: () => {
              removeLayer(layer.id);
            },
            shown: true,
          },
        ]}
        actionElement={
          <Select2
            items={Object.entries(iLayer.item.assets).filter(([, asset]) =>
              isAssetPreviewable(asset)
            )}
            onSelectionChange={(key) => {
              if (selectedBands.find((b) => b[0] === key)) {
                handleBandRemove(selectedBands.find((b) => b[0] === key));
              } else {
                handleBandSelect(
                  Object.entries(iLayer.item.assets).find(
                    (item) => item[0] === key
                  )
                );
              }
              const l = STACLayerFactory({
                item: new StacItem(iLayer.item),
                assetKey: key.toString(),
                expression: undefined,
              });
              replaceSpecificLayerWithAnother(l, layer);
            }}
            selectedItem={getSelectedAsset()}
            fill
          >
            {(item) => (
              <Item key={item[0]}>
                <div className="flex items-center justify-between gap-1">
                  <div className="flex flex-col">
                    <span>{item[1].title ?? item[0]}</span>
                    {item[1].title && (
                      <span className="text-xs">{item[0]}</span>
                    )}
                  </div>
                  <CustomTooltip
                    content={translate(
                      'datacosmos.tooltips.catalogAndItems.downloadAsset'
                    )}
                    delay={0}
                  >
                    <IconButton
                      icon="Install"
                      onPress={() => {
                        window.open(item[1].href, '_blank');

                        sendInfo({
                          action: `Download asset: ${
                            item[1].title ?? item[0]
                          }}`,
                          item: 'Download asset button',
                          type: 'Click',
                          module: 'DataCosmos',
                          download: item[1].href,
                          additionalParams: {
                            asset: item[1].title ?? item[0],
                          },
                        });
                      }}
                    />
                  </CustomTooltip>
                </div>
              </Item>
            )}
          </Select2>
        }
        indicators={[
          {
            icon: 'Brightness',
            shown: iLayer.isBrightnessApplied?.(),
          },
          {
            icon: 'Saturation',
            shown: iLayer.isSaturationApplied?.(),
          },
          { icon: 'Contrast', shown: iLayer.isContrastApplied?.() },
        ]}
      />
    );
  };

  const renderOpportunityLayerCard = (iLayer: OpportunityLayer<any>) => (
    <ListCard
      title={
        iLayer.metadata.OpportunityIndex !== undefined
          ? `#${iLayer.metadata.OpportunityIndex} ` + layer.getName()
          : layer.getName()
      }
      info={
        <>
          <ListItem
            content={layer.getDescription() ?? '-'}
            onClick={() => {}}
          />
          <ListItem
            content={
              iLayer &&
              iLayer.getStartTime() &&
              moment(iLayer.getStartTime()).utc().format(DATETIME_FORMAT)
            }
            onClick={() => {}}
          />
        </>
      }
      rightIcons={[
        {
          icon: layer.options.visible ? 'Eye' : 'EyeClosed',
          onClick: () => {
            replaceLayer(
              layer.cloneWithOptions({
                visible: !layer.options.visible,
              })
            );
          },
          shown: true,
        },
        {
          icon: 'Center',
          onClick: () => {
            setViewToFitBbox(bbox(iLayer.data));
          },
          shown: true,
        },
        {
          icon: 'Close',
          onClick: () => {
            removeLayer(layer.id);
          },
          shown: true,
        },
      ]}
    />
  );

  const renderLineAndPolygonLayerCards = (iLayer: LineLayer | PolygonLayer) => (
    <ListCard
      title={layer.getName()}
      info={
        <>
          <ListItem
            content={layer.getDescription() ?? '-'}
            onClick={() => {}}
          />
          <ListItem content={iLayer.getMeasurements()} onClick={() => {}} />
        </>
      }
      rightIcons={[
        {
          icon: layer.options.visible ? 'Eye' : 'EyeClosed',
          onClick: () => {
            replaceLayer(
              layer.cloneWithOptions({
                visible: !layer.options.visible,
              })
            );
          },
          shown: true,
        },
        {
          icon: 'Center',
          onClick: () => {
            setViewToFitBbox(bbox(iLayer.data));
          },
          shown: true,
        },
        {
          icon: 'Close',
          onClick: () => {
            removeLayer(layer.id);
            if (layer instanceof PolygonLayer) {
              disableEditing(layer.getLeafletLayerMetadata());
            }
          },
          shown: true,
        },
      ]}
      actionElement={renderDownloadAsFormat(iLayer)}
    />
  );

  const renderCircleLayerCard = (iLayer: CircleLayer) => (
    <ListCard
      title={iLayer.getName()}
      info={
        <>
          <ListItem
            content={iLayer.getDescription() ?? '-'}
            onClick={() => {}}
          />
          <ListItem content={iLayer.getMeasurements()} onClick={() => {}} />
          <ListItem content={iLayer.getRadius()} onClick={() => {}} />
        </>
      }
      rightIcons={[
        {
          icon: iLayer.options.visible ? 'Eye' : 'EyeClosed',
          onClick: () => {
            replaceLayer(
              iLayer.cloneWithOptions({
                visible: !iLayer.options.visible,
              })
            );
          },
          shown: true,
        },
        {
          icon: 'Center',
          onClick: () => {
            setViewToFitBbox(bbox(iLayer.data));
          },
          shown: true,
        },
        {
          icon: 'Close',
          onClick: () => {
            removeLayer(iLayer.id);

            disableEditing(iLayer.getLeafletLayerMetadata());
          },
          shown: true,
        },
      ]}
      actionElement={renderDownloadAsFormat(iLayer)}
    />
  );

  const renderUnknownLayerCard = (iLayer: Layer) => (
    <ListCard
      title={iLayer.getName()}
      info={
        <ListItem
          icon="Info"
          content={iLayer.getDescription()}
          onClick={() => {}}
        />
      }
      rightIcons={[
        {
          icon: iLayer.options.visible ? 'Eye' : 'EyeClosed',
          onClick: () => {
            replaceLayer(
              iLayer.cloneWithOptions({
                visible: !iLayer.options.visible,
              })
            );
          },
          shown: true,
        },
      ]}
    />
  );

  if (
    layer instanceof SingleBandSTACLayer ||
    layer instanceof BandAlgebraSTACLayer ||
    layer instanceof OutlineLayer
  ) {
    const iLayer = layer;
    const stacInfo = useStacInfo(layer as SingleBandSTACLayer);
    return renderStacItemLayerCard(
      iLayer as SingleBandSTACLayer,
      stacInfo as IStacInfo
    );
  } else if (
    layer instanceof OpportunityLayer ||
    layer instanceof FieldOfRegardLayer ||
    layer instanceof SwathLayer
  ) {
    const iLayer = layer as OpportunityLayer<any>;
    return renderOpportunityLayerCard(iLayer);
  } else if (layer instanceof LineLayer || layer instanceof PolygonLayer) {
    const iLayer = layer as LineLayer;
    return renderLineAndPolygonLayerCards(iLayer);
  } else if (layer instanceof CircleLayer) {
    return renderCircleLayerCard(layer);
  }

  return renderUnknownLayerCard(layer);
};

export default LayerListCard;
