import { filter, find, get, pipe, toNumber } from 'lodash/fp';
import { IconNames } from '@blueprintjs/icons';
import { useParams } from 'react-router-dom';
import AdministrationHeader from 'components/Administration/AdministrationHeader';
import AdministrationUsers from 'components/Administration/AdministrationUsers/AdministrationUsers';
import useResource from 'services/api/portal/administration/hook/useResource';
import AdministrationLayout from './AdministrationLayout';
import { useMission } from 'services/Missions';
import AdministrationProgrammeList from 'components/Administration/AdministrationProgrammeList';
import ResourcePermissionsManager from 'components/Administration/PermissionManager/ResourcePermissionsManager';

const OrganisationAdministration = () => {
  const { organisation } = useParams<{ organisation: string }>();
  const { organisations, programmes } = useMission();
  const organisationId = toNumber(organisation);

  const {
    availableRoles,
    resourceRoleAssignments,
    deleteRoleAssignment,
    assignRole,
    resourceRoleAttachments,
    getRoleAttachmentsByResourceTypeAndId,
  } = useResource('organisation', organisationId);

  const organisationName = pipe(
    find(['id', organisationId]),
    get('name')
  )(organisations) as string;

  return (
    <AdministrationLayout
      breadcrumbs={[{ text: 'Organisation' }]}
      showEmptyData={!organisationName}
      emptyDataIcon={IconNames.OFFICE}
      emptyDataTitle={`There is no organisation found with Id: ${organisationId}`}
      graphic="operations"
    >
      <AdministrationHeader
        id={organisationId}
        title={organisationName}
        icon={IconNames.OFFICE}
      />
      <AdministrationProgrammeList
        programmesList={filter(['organisationId', organisationId], programmes)}
      />
      <div className="mt-3 border-t-[1px] border-accent-700 dark:border-item-dark-hover" />
      <AdministrationUsers
        currentId={organisationId}
        assignRole={assignRole}
        deleteRoleAssignments={(
          userId: string,
          assignmentIds: number[],
          showDeletedMessage?: boolean
        ) => deleteRoleAssignment(assignmentIds, showDeletedMessage)}
        availableRoles={availableRoles}
        roleAssignments={resourceRoleAssignments}
      />
      <div className="my-5 border-t-[1px] border-accent-700 dark:border-item-dark-hover" />
      {resourceRoleAttachments && (
        <ResourcePermissionsManager
          resourceAttachments={resourceRoleAttachments}
          resourceType="organisation"
          resourceId={organisationId.toString()}
          getAttachmentsCallback={() => getRoleAttachmentsByResourceTypeAndId()}
          availableRoles={availableRoles}
        />
      )}
    </AdministrationLayout>
  );
};

export default OrganisationAdministration;
