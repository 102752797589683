import classNames from "classnames";
import React, { useRef } from "react";
import { DismissButton, FocusScope, useOverlay } from "react-aria";

export type IOverlayProps = {
  /**
   * The reference to the overlay element.
   */
  overlayRef?: React.RefObject<HTMLDivElement>;
  /**
   * The content to display inside the overlay.
   */
  children: JSX.Element;
  /**
   * Whether the overlay is open or not.
   */
  isOpen?: boolean;
  /**
   * The function to call when the user clicks the close button.
   */
  onClose: () => void;
  className?: string;
  style?: React.CSSProperties;
  /**
   * Whether the overlay is dismissable or not.
   * If true, the overlay will close when the user clicks outside of it.
   */
  isDismissable?: boolean;
  /**
   * Whether the overlay should close when the user clicks outside of it.
   */
  shouldCloseOnBlur?: boolean;
  /**
   * Whether the overlay should close when the user interacts with a specified element outside of it.
   *
   * @param element The element to check.
   * @returns Whether the overlay should close or not.
   */
  shouldCloseOnInteractOutside?: (element: Element) => boolean;
  /**
   * Whether the overlay should close when the user presses the `Escape` key.
   */
  isKeyboardDismissDisabled?: boolean;
};

/**
 * Overlay is a component that can be used to display content on top of other content.
 * It is used to display a dialog, tooltip, or menu.
 *
 * To be used as a building block for other overlay components, such as various modals, dialogs, tooltips etc.
 *
 * **NOT TO BE USED STANDALONE**
 */
export const Overlay = (props: IOverlayProps) => {
  const ref = useRef<HTMLDivElement>(null);

  const overlayRef = props.overlayRef ?? ref;

  const { overlayProps } = useOverlay(
    {
      isDismissable: props.isDismissable,
      shouldCloseOnBlur: props.shouldCloseOnBlur,
      isOpen: props.isOpen,
      onClose: props.onClose,
      shouldCloseOnInteractOutside: props.shouldCloseOnInteractOutside,
      isKeyboardDismissDisabled: props.isKeyboardDismissDisabled,
    },
    overlayRef
  );

  return (
    <FocusScope restoreFocus>
      <div
        ref={overlayRef}
        className={classNames(
          props.className,
          "absolute shadow-lg border border-neutral-300 color-surface rounded-md mt-2",
          " dark:border-none overflow-hidden",
          {
            "top-full": !props.style,
          }
        )}
        {...overlayProps}
        style={{ zIndex: 10, ...props.style }}
      >
        {props.children}
        <DismissButton onDismiss={props.onClose} />
      </div>
    </FocusScope>
  );
};

export default Overlay;
