import type { Store } from 'redux';
import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from '@redux-devtools/extension';
import rootReducer from 'reducers/rootReducer';
import * as Sentry from '@sentry/react';
import { MODE } from 'env';

const sentryReduxEnhancer = Sentry.createReduxEnhancer({
  // Optionally pass options
});

export type AppState = ReturnType<typeof rootReducer>;
export let scopedStore: Store = null;
const defaultInitialState: any = {};
const configureStore = (initialState = defaultInitialState): Store => {
  let store: Store;
  if (MODE !== 'production') {
    store = createStore(
      rootReducer,
      initialState,
      composeWithDevTools(applyMiddleware(thunk), sentryReduxEnhancer)
    );
  } else {
    store = createStore(
      rootReducer,
      initialState,
      compose(applyMiddleware(thunk), sentryReduxEnhancer)
    );
  }
  scopedStore = store;
  return store;
};

export default configureStore;
