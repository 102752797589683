import type { TrackingError } from 'services/analytics/mixpanel';

import { useAuth } from 'services/auth/AuthWrapper';
import { matomoClient, mixpanelClient } from './clients';
import { UTF8ToBase64 } from 'utils/common/base64Utils';
import { ANALYTICS_CLIENT } from 'env';
import { useCallback, useMemo } from 'react';
import { findHighestPriorityRole } from 'utils/auth/common';
import type { UserAssignments } from 'api/administration/types';

export type TrackingModule = 'MSD' | 'OPS' | 'DataCosmos';

export interface MatomoDimensions {
  /*Collection Id/ Item Id*/
  dimension1?: string;

  /*User type*/
  dimension2?: string;

  /*Item Id*/
  dimension3?: string;

  /*Project Id*/
  dimension4?: string;
}

export type TrackingEvent = {
  type: string; //e_n
  module?: TrackingModule;
  action: string; //e_a
  item: string; // e_c
  download?: string; //download
  dimensions?: MatomoDimensions;
  additionalParams?: { [key: string]: unknown } | unknown;
};

export type TrackingParams = {
  // url
  url: string;
  // _id
  visitorId: string;
  // resolution
  resolution: string;
  // h
  hour: number;
  // m
  minute: number;
  // s
  second: number;
  // uid
  userId: string;
  // dimension2
  userRole: string;
  // cvar
  userName: string;
};

export type TrackingEventWithError = TrackingEvent & {
  error: TrackingError;
};

/**
 * analyticsClient exposes a specific AnalyticsClient under a generalized name
 */
export const analyticsClient =
  ANALYTICS_CLIENT === 'mixpanel' ? mixpanelClient : matomoClient;

export const useAnalytics = () => {
  const { user } = useAuth();

  const url = window.location.href;
  const visitorId = UTF8ToBase64(user?.id ?? '')?.substring(0, 16);
  const userId = user?.sub ?? 'unknown';
  const userName = user?.name ?? 'unknown';
  const userRole =
    findHighestPriorityRole(user?.assignments as UserAssignments[]) ?? 'N/A';
  const resolution = `${window.screen.width}x${window.screen.height}`;
  const date = useMemo(() => {
    return new Date();
  }, []);
  const hour = date.getHours();
  const minute = date.getMinutes();
  const second = date.getSeconds();

  const trackingParams: TrackingParams = useMemo(
    () => ({
      url,
      visitorId: visitorId ?? 'unknown',
      resolution,
      hour,
      minute,
      second,
      userId,
      userRole,
      userName,
    }),
    [
      hour,
      minute,
      resolution,
      second,
      url,
      userId,
      visitorId,
      userRole,
      userName,
    ]
  );

  const sendInfo = useCallback(
    (ev: TrackingEvent) => analyticsClient.sendInfo(trackingParams)(ev),
    [trackingParams]
  );
  const startTracking = useCallback(
    (ev: TrackingEvent) => analyticsClient.startTracking(trackingParams)(ev),
    [trackingParams]
  );
  const stopTracking = useCallback(
    (ev: TrackingEvent) => analyticsClient.stopTracking(trackingParams)(ev),
    [trackingParams]
  );
  const sendError = analyticsClient.sendError(trackingParams);

  return {
    sendError,
    sendInfo,
    startTracking,
    stopTracking,
  };
};
