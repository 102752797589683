import React, { useCallback, useEffect, useMemo } from "react";
import { Command, CommandDialog, CommandList } from "cmdk";
import { keyCodeLookup } from "./keyCodeLookupTable";

export type Cmd = {
  name: string;
  group: string;
  keyCombination: string[];
  description?: string;
  action: () => void;
};

type Props = {
  toggleHotkey: string | undefined;
  commands: Cmd[];
  containerRef?: React.RefObject<HTMLDivElement> | string;
};

/**
 * CommandMenu is a component that renders a list of commands in a dialog
 * at the top of the screen. It is triggered by a hotkey combination.
 *
 * @param toggleHotkey The hotkey combination that triggers the dialog.
 * @param commands The list of commands to display in the dialog.
 * @param containerRef The ref of the container element that the dialog should be rendered in.
 * Can be a string pointing to an element ID or a React ref. If nothing is provided, the dialog
 * will be rendered in the body.
 */
const CommandMenu = ({ toggleHotkey, commands, containerRef }: Props) => {
  const [open, setOpen] = React.useState<boolean>(false);
  const keyQueue = React.useRef<string[]>([]);

  const container = useMemo(
    () =>
      typeof containerRef === "string"
        ? (document.getElementById(containerRef) as HTMLElement | undefined)
        : containerRef?.current!,
    [containerRef]
  );

  const groupedCommands = useMemo(
    () =>
      commands.reduce((acc, cmd) => {
        const group = cmd.group;
        if (!acc[group]) {
          acc[group] = [];
        }
        acc[group].push(cmd);
        return acc;
      }, {} as { [key: string]: Cmd[] }),
    [commands]
  );

  const addToKeyQueue = (code: string) => {
    keyQueue.current = [...keyQueue.current, keyCodeLookup[code].toUpperCase()];
  };

  const clearKeyQueue = () => {
    keyQueue.current = [];
  };

  const checkToggleHotkeyMatch = useCallback(() => {
    const hotkeyArr = toggleHotkey
      ?.split("+")
      .map((s) => s.trim().toUpperCase())
      .sort();

    if (!hotkeyArr) {
      return false;
    }

    if (keyQueue.current.length < hotkeyArr.length) {
      return false;
    }

    const keyQueueArr = keyQueue.current
      .map((s) => s.trim().toUpperCase())
      .sort();

    return keyQueueArr.every((key) => hotkeyArr.includes(key));
  }, [toggleHotkey]);

  useEffect(() => {
    const down = (e: KeyboardEvent) => {
      addToKeyQueue(e.code);

      if (checkToggleHotkeyMatch()) {
        e.preventDefault();
        setOpen((o) => !o);
        clearKeyQueue();
      }
    };

    const up = () => {
      clearKeyQueue();
    };

    document.addEventListener("keydown", down);
    document.addEventListener("keyup", up);
    return () => {
      document.removeEventListener("keydown", down);
      document.removeEventListener("keyup", up);
    };
  }, [checkToggleHotkeyMatch]);

  return (
    <CommandDialog
      open={open}
      onOpenChange={setOpen}
      className="absolute font-mono top-0 left-1/2 transform -translate-x-1/2 w-[40%] bg-surface text-black dark:bg-surface-dark drop-shadow-2xl shadow-lg text-sm dark:text-white flex flex-col gap-3 overflow-auto max-h-96 scrollbar-light dark:scrollbar-dark"
      style={{
        zIndex: 100000,
      }}
      container={container}
      loop
    >
      <Command.Input
        className="bg-transparent border-none outline-none dark:text-white placeholder:text-item-contrast-inactive p-2"
        placeholder="Search a command..."
        autoFocus
      />
      <CommandList className="flex flex-col gap-4 w-full p-0">
        <Command.Empty className="text-xs font-mono">
          No such command
        </Command.Empty>
        {Object.entries(groupedCommands).map(([group, cmds]) => (
          <>
            <Command.Group
              heading={group}
              className="text-xs text-item-contrast-inactive font-mono"
            >
              {cmds.map((cmd) => (
                <Command.Item
                  key={cmd.name}
                  value={cmd.name}
                  className="!grid data-[selected=true]:bg-item-hover grid-cols-[2fr_1fr] items-center gap-1 text-black p-2 text-sm dark:text-white cursor-pointer"
                  onSelect={() => {
                    cmd.action();
                  }}
                >
                  <div className="flex flex-col justify-between ">
                    <div>{cmd.name}</div>
                    <div className="text-xs">{cmd.description}</div>
                  </div>
                  <div className="flex justify-center h-8 items-center text-[10px] font-mono bg-item-dark text-white dark:bg-item-dark-contrast dark:text-item-dark p-1">
                    {cmd.keyCombination.join(", ")}
                  </div>
                </Command.Item>
              ))}
            </Command.Group>
          </>
        ))}
      </CommandList>
    </CommandDialog>
  );
};

export default CommandMenu;
