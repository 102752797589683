import { useState, useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import type { IUserInformation } from 'constants/user/actionTypes';
import { setUserThunks } from 'actions/user/thunks';
import { setAuthorisationCookie } from 'utils/common/CommonUtils';
import axios from 'axios';
import { setSentryUser } from './sentryUser';
import type { IUserWithPermissions } from 'services/api/portal/administration/api/types';
import Cookie from 'js-cookie';
import { fetchClient } from 'api/clientFetch';
import { axiosClient } from 'api/clientAxios';

// Contains information about an error encountered during authentication
export interface IAuthError {
  name: string;
  description: string;
}

const REFRESH_TOKEN_TIMEOUT = 3600000; // 1h;

export const useAuthentication = (
  i: AuthenticationBackendInitialiser,
  afterAuthentication: (a?: AppState) => void
) => {
  const [user, setUser] = useState<IUserWithPermissions>();
  const [authBackend, setAuthBackend] = useState<
    AuthenticationBackend | undefined
  >();
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<IAuthError>();
  const dispatch = useDispatch();
  const history = useHistory();
  const [token, setToken] = useState<string>();
  const isAuthenticated = Boolean(user);

  const applyToken = useCallback((newToken: string | undefined) => {
    setAuthorisationCookie('Authorization', newToken);
    setToken(newToken);

    const Authorization = newToken ? `Bearer ${newToken}` : undefined;

    /* eslint-disable @typescript-eslint/no-unsafe-member-access */
    if (axios.defaults.headers?.common) {
      if (newToken) {
        axios.defaults.headers.common.Authorization = Authorization;
      } else {
        if (
          Object.prototype.hasOwnProperty.call(
            axios.defaults.headers.common,
            'Authorization'
          )
        ) {
          delete axios.defaults.headers.common.Authorization;
        }
      }
    }
    /* eslint-enable @typescript-eslint/no-unsafe-member-access */

    fetchClient.headers.Authorization = Authorization;
    axiosClient.headers.Authorization = Authorization;
  }, []);

  useEffect(() => {
    const userInformation: IUserInformation = { ...user, token };
    // @ts-expect-error
    dispatch(setUserThunks(userInformation));
    setSentryUser(userInformation);
  }, [dispatch, token, user]);

  useEffect(() => {
    if (!user) return () => {};

    const timeout = setInterval(() => {
      void authBackend?.upToDateTokenInBackground().then(applyToken);
    }, REFRESH_TOKEN_TIMEOUT);

    return () => {
      clearInterval(timeout);
    };
  }, [applyToken, authBackend, user]);

  useEffect(() => {
    const initAuth = async () => {
      if (authBackend === undefined) {
        setAuthBackend(await i());
        return;
      }

      if (
        !window.location.pathname.includes('/error') &&
        window.location.search.includes('error=')
      ) {
        setLoading(false);
        history.push('/error' + window.location.search);
        return;
      } else if (window.location.search.includes('code=')) {
        try {
          // Note: see how this is set in loginRedirect.js
          const target = Cookie.get('authentication.original.url');
          const appState = await authBackend.authenticationRedirectResult();
          if (appState.url === undefined) {
            appState.url = target;
          }
          afterAuthentication(appState);
        } catch (err) {
          setError({
            name: 'Login error',
            description: JSON.stringify(err),
          });
        }
      }

      if (await authBackend.authenticated()) {
        const newToken = await authBackend.upToDateTokenInBackground();
        if (newToken !== undefined) {
          applyToken(newToken);

          const newUser = await authBackend.user();
          if (newUser !== undefined) {
            setUser(newUser);
          }
        }
      } else {
        await authBackend.login({ url: window.location.pathname });
      }

      setLoading(false);
    };

    setLoading(true);
    initAuth().catch((authError) => {
      type AuthError =
        | { error?: string; error_description?: string }
        | undefined;
      if (
        authError &&
        typeof (authError as AuthError)?.error === 'string' &&
        typeof (authError as AuthError)?.error_description === 'string'
      ) {
        setError({
          name: (authError as AuthError)?.error!,
          description: (authError as AuthError)?.error_description!,
        });
      } else {
        setError({
          name: 'Auth error',
          description: JSON.stringify(authError),
        });
      }
      setLoading(false);
    });
  }, [applyToken, history, authBackend, afterAuthentication]);

  const loginWithRedirect = useCallback(() => {
    return authBackend?.login({});
  }, [authBackend]);

  const logout = useCallback(
    (returnTo?: string) => {
      return authBackend?.logout(returnTo);
    },
    [authBackend]
  );

  return {
    user,
    loading,
    error,
    token,
    isAuthenticated,
    authBackend,
    loginWithRedirect,
    logout,
  };
};

/**
 * An AuthenticationBackendInitialiser configures/sets up a new authentication backend for this hook to use
 */
export type AuthenticationBackendInitialiser =
  () => Promise<AuthenticationBackend>;

/**
 * An AuthenticationBackend is a client which connects to a specific backend for authentication,
 * e.g. auth0 or Keycloak
 */
export interface AuthenticationBackend {
  // upToDateTokenInBackground returns a non-expired token if possible, without
  // re-authenticating the user i.e. fetching a fresh token in the background if needed
  upToDateTokenInBackground(): Promise<string | undefined>;
  // login starts the user login flow via the authentication provider
  login(currentState: AppState): Promise<void>;
  // authenticationRedirectResult returns, after a login flow with redirects,
  // the state of the application before the login/redirects
  authenticationRedirectResult(): Promise<AppState>;
  // authenticated returns true if the current user has already been authenticated
  authenticated(): Promise<boolean>;
  // user returns information about the current user, including their permissions
  user(): Promise<IUserWithPermissions | undefined>;
  // logout logs out (deauthenticates) the current user/ends the current session
  logout(returnTo?: string): void;
}

/**
 * AppState contains the state of the application before a login flow redirect cycle
 */
export interface AppState {
  url?: string;
}
