import * as Yup from 'yup';

Yup.addMethod(Yup.array, 'unique', function (message, mapper = (a: any) => a) {
  return this.test('unique', message, function (list) {
    return list.length === new Set(list.map(mapper)).size;
  });
});

const getCustomPayloadFormSchema = (existingPayloads: string[]) =>
  Yup.object().shape({
    id: Yup.string()
      .required('Required')
      .matches(/^[0-9a-zA-Z ]+$/, 'Only letters, numbers, and spaces are valid')
      .notOneOf(existingPayloads, 'Name already in use'),
    data: Yup.object().shape({
      physical: Yup.object().shape({
        mass: Yup.number().min(0.01, 'Too light').max(30, 'Too heavy'),
        xLength: Yup.number().min(0.01, 'Too small').max(26.2, 'Too big'),
        yLength: Yup.number().min(0.01, 'Too small').max(26.2, 'Too big'),
        zLength: Yup.number().min(0.01, 'Too small').max(26.2, 'Too big'),
      }),
      thermal: Yup.object().shape({
        minimumTemperature: Yup.number()
          .min(-100, 'Too low')
          .max(100, 'Too high'),
        maximumTemperature: Yup.number()
          .min(-100, 'Too low')
          .max(100, 'Too high'),
      }),
      modes: Yup.array()
        .of(
          Yup.object().shape({
            id: Yup.string().required('Required'),
          })
        )
        .min(
          1,
          'You need to define at least one operational mode for the payload.'
        )
        //@ts-ignore - I created unique above, doesn't come with lib
        .unique('Names must be unique', (mode: any) => mode.id),
    }),
  });

export default getCustomPayloadFormSchema;
