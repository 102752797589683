import { pipe, toPairs, get, map, omit, fromPairs, has } from 'lodash/fp';
import type { IJsonSchema } from './dereference';
import dereference from './dereference';

// TODO: Do not return any

export const getDereferenceValue =
  (definitions: object | undefined) =>
  ([key, value]: any) => {
    const parsed = dereference({ ...value, definitions });

    if (has('properties', parsed)) {
      /* eslint-disable @typescript-eslint/no-use-before-define */
      const res: any = parseRefFromProperties(definitions)(parsed);
      return [key, { ...parsed, properties: fromPairs(res) }];
    }

    return [key, omit('definitions', parsed)];
  };

export const parseRefFromProperties = (definitions: object | undefined) =>
  pipe(get('properties'), toPairs, map(getDereferenceValue(definitions)));

const parseRefDefinitions = (schema: IJsonSchema) => {
  try {
    if (!has('$schema', schema)) return undefined;
    return pipe(
      dereference,
      parseRefFromProperties(schema.definitions),
      (parsed: [string, any]) => {
        return parsed.map(([key, val]) => {
          const derefrencedSchema = dereference(schema).required as string[];
          if (derefrencedSchema && derefrencedSchema.includes(key as string)) {
            return val.required
              ? [key, { ...val, required: [...val.required, key] }]
              : [key, { ...val, required: [key] }];
          } else {
            return [key, val];
          }
        });
      },
      fromPairs
    )(schema);
  } catch (err) {
    console.error(err);
    return undefined;
  }
};

export default parseRefDefinitions;
