import type { ISummaryChildren } from '../../constants/summary/constants';
import { COMPONENTS_TYPE } from '../../constants/summary/constants';
import type { ReactElement } from 'react';
import React from 'react';
import { SummaryText } from '../../components/summary/SummaryText';
import { SummaryBulletList } from '../../components/summary/SummaryBulletList';
import { SummaryTable } from '../../components/summary/SummaryTable';
import SummaryCartesian2DPlot from '../../components/summary/SummaryCartesian2DPlot';
import { getHash } from '../../utils/common/getHash';
import type { ITreeNodeCustom } from '../../declarations/blueprintjs';

export const getComponent = (
  props: ISummaryChildren,
  node: ITreeNodeCustom,
  id: string
): ReactElement[] => {
  if (node.disabled) {
    return null;
  }
  const className = id.split('.').length - 1 > 1 ? 'section' : 'chapter';
  const components: ReactElement[] = props.components.map((c): ReactElement => {
    let e = null;
    const key = `component${getHash(JSON.stringify(c))}`;
    switch (c.type) {
      case COMPONENTS_TYPE.TEXT: {
        e = <div key={key}>{SummaryText(c)}</div>;
        break;
      }
      case COMPONENTS_TYPE.BULLET_LIST: {
        e = <div key={key}>{SummaryBulletList(c)}</div>;
        break;
      }
      case COMPONENTS_TYPE.TABLE: {
        e = <div key={key}>{SummaryTable(c)}</div>;
        break;
      }
      case COMPONENTS_TYPE.CARTESIAN_2D_PLOT: {
        e = (
          <div key={key}>
            <SummaryCartesian2DPlot {...c} />
          </div>
        );
        break;
      }
      default:
        e = (
          <div key={key}>
            <hr />
            <h1>{`Unknown type - ${c.type}`}</h1>
            <div>{JSON.stringify(c, null, 2)}</div>
            <hr />
          </div>
        );
    }
    return e;
  });
  const numDepth = id ? id.split('.').length : 0;
  const splittedTitle = id
    ? id
        .split('.')
        .map((i): string => (+i + 1).toString())
        .join('.')
    : '';
  const tabs = new Array(numDepth - 1).fill('    ').join('');
  const style = {
    paddingLeft: `${(numDepth - 1) / 2}cm`,
  };
  const title = (
    <div style={style} className={`title-${numDepth}`} id={props.title}>
      {`${tabs}${splittedTitle} ${props.title}`}
    </div>
  );

  let children: ReactElement[] = [];
  let indexId = 0;
  props.children.forEach((c, index): void => {
    const subChildren = getComponent(
      c,
      node.childNodes[index],
      props.components.length === 0 ? `${indexId}` : `${id}.${indexId}`
    );
    if (subChildren) {
      children = [...children, ...subChildren];
    }
    if (!node.childNodes[index].disabled) {
      indexId++;
    }
  });
  return [
    props.components.length !== 0 ? (
      <div className={className} key={`${props.title}`}>
        {title}
        {components}
      </div>
    ) : null,
    ...children,
  ];
};
