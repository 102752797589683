import React from 'react';
import CommandStringDateInput from './CommandStringDateInput';
import CommandStringEnumInput from './CommandStringEnumInput';
import CommandStringBase64Input from './CommandStringBase64Input';
import CommandStringInput from './CommandStringInput';

export interface IProps {
  inputRef?: React.RefObject<HTMLInputElement>;
  showInfoMark?: boolean;
  className?: string;
  meta?: string;
  media?: { binaryEncoding?: string };
  format?: string;
  fullPath: string;
  value: string | Date;
  disabled: boolean;
  enumValue?: string[];
  path: string;
  canSetDefaultValue: boolean;
  onChange: Function;
  handleBlur: () => void;
  handleFocus: () => void;
  validateChange: Function;
  setDefaultValue: Function;
  handleMetaButtonClick: ((value: string) => void) | undefined;
  setValidationParams: Function;
  setTooltipOpen: (open: boolean) => void;
}

// TODO add input logic for ipv4, ipv6, uri, hostname, email
const CommandPrimitiveString = ({
  inputRef,
  className,
  value,
  onChange,
  fullPath,
  disabled,
  validateChange,
  handleBlur,
  handleFocus,
  path,
  format,
  enumValue,
  media,
  meta,
  showInfoMark,
  setDefaultValue,
  handleMetaButtonClick,
  canSetDefaultValue,
  setValidationParams,
  setTooltipOpen,
}: IProps) => {
  if (format === 'date-time') {
    return (
      <CommandStringDateInput
        handleBlur={handleBlur}
        handleFocus={handleFocus}
        handleChange={onChange}
        disabled={disabled}
        fullPath={fullPath}
        value={value as Date}
        setTooltipOpen={setTooltipOpen}
      />
    );
  }

  if (enumValue) {
    return (
      <CommandStringEnumInput
        value={value as string}
        fullPath={fullPath}
        enumValue={enumValue}
        handleBlur={handleBlur}
        handleFocus={handleFocus}
        disabled={disabled}
        canSetDefaultValue={canSetDefaultValue}
        onChange={onChange}
        setTooltipOpen={setTooltipOpen}
        className={className}
      />
    );
  }

  if (media?.binaryEncoding === 'base64' || meta === 'base64string') {
    return (
      <CommandStringBase64Input
        inputRef={inputRef}
        showInfoMark={showInfoMark}
        className={className}
        value={value as string}
        fullPath={fullPath}
        path={path}
        handleBlur={handleBlur}
        handleFocus={handleFocus}
        disabled={disabled}
        onChange={onChange}
        validateChange={validateChange}
        setValidationParams={setValidationParams}
        setTooltipOpen={setTooltipOpen}
      />
    );
  }

  return (
    <CommandStringInput
      inputRef={inputRef}
      showInfoMark={showInfoMark}
      className={className}
      value={value as string}
      fullPath={fullPath}
      path={path}
      handleBlur={handleBlur}
      handleFocus={handleFocus}
      disabled={disabled}
      onChange={onChange}
      validateChange={validateChange}
      meta={meta}
      handleMetaButtonClick={handleMetaButtonClick}
      setDefaultValue={setDefaultValue}
      setTooltipOpen={setTooltipOpen}
    />
  );
};

export default CommandPrimitiveString;
