import type { EmptyParams } from 'api/client';
import { hosts } from 'api/hosts';
import type {
  TaskingRequest,
  TaskingRequestFeature,
  RequestStatus,
  ManualOpportunity,
  Swath,
  TaskingRequestConstraint,
  TaskingRequestInstrument,
  TaskingRequests,
  TaskingSatellites,
  TaskingRequestObjective,
} from 'api/tasking/types';
import type { SensorId } from 'api/sensors/types';
import type { SatelliteId } from 'api/satellites/types';
import { toaster } from 'toaster';
import { apiFetchHandler } from 'api/clientFetch';
import { clientTranslate } from 'utils/hooks/useLocalisation';
import { formatBaseURL } from 'datacosmos/utils/utils';
import type { ActivityParameters } from 'api/activities/types';

export * from 'api/tasking/types';

export type PostTaskingRequestParams = {
  projectId: string;
};

export type TaskingRequestType = 'MANUAL' | 'AUTOMATED';

export type PostActivity = {
  start_date: string;
  end_date: string;
  mission_id: string;
  type: 'IMAGE_ACQUISITION';
  priority?: number;
  parameters: ActivityParameters;
};

export type PostTaskingRequestBody = {
  type: TaskingRequestType;
  region_name: string;
  region: TaskingRequestFeature;
  parameters?: {
    objectives?: TaskingRequestObjective[];
  };
  activities?: PostActivity[];
  notes: string;
  constraints: TaskingRequestConstraint[];
  instruments: TaskingRequestInstrument[];
};

export type PostTaskingRequestData = {
  id: string;
  msg: string;
};

export const postTaskingRequest = apiFetchHandler<
  PostTaskingRequestData,
  PostTaskingRequestParams,
  PostTaskingRequestBody
>({
  host: hosts.data.v1,
  endpoint: ({ projectId }) => `/projects/${projectId}/tasking/requests`,
  method: 'POST',
  errorMessage: clientTranslate('datacosmos.fetchErrors.tasking.cannotCreate'),
  onSuccess: (data) => {
    toaster.show({
      message: data?.msg!,
      intent: 'success',
    });
  },
  errorDescription: (e) => e.join('; '),
});

export type GetTaskingRequestParams = {
  requestId: string;
};

export const getTaskingRequest = apiFetchHandler<
  TaskingRequest,
  GetTaskingRequestParams
>({
  host: hosts.data.v1,
  endpoint: ({ requestId }) => `/tasking/requests/${requestId}`,
  method: 'GET',
  errorMessage: clientTranslate('datacosmos.fetchErrors.tasking.cannotGet'),
  errorDescription: (e) => e.join('; '),
});

export type PatchTaskingRequestParams = {
  requestId: string;
};

export type PatchTaskingRequestBody = {
  status: RequestStatus;
};

export type PatchTaskingRequestData = {
  id: string;
  msg: string;
};

export const patchTaskingRequest = apiFetchHandler<
  PatchTaskingRequestData,
  PatchTaskingRequestParams,
  PatchTaskingRequestBody
>({
  host: hosts.data.v1,
  endpoint: ({ requestId }) => `/tasking/requests/${requestId}`,
  method: 'PATCH',
  errorMessage: clientTranslate('datacosmos.fetchErrors.tasking.cannotPatch'),
  errorDescription: (e) => e.join('; '),
});

export type TaskingRequestsByProjectIdQueryParams = {
  status?: string;
  end_date?: Date | null;
  start_date?: Date | null;
};

export type GetTaskingRequestsByProjectIdParams = {
  projectId: string;
  cursor?: string;
  filters?: TaskingRequestsByProjectIdQueryParams;
};

export const getTaskingRequestsByProjectId = apiFetchHandler<
  TaskingRequests,
  GetTaskingRequestsByProjectIdParams
>({
  host: hosts.data.v1,
  endpoint: ({ projectId, cursor, filters }) => {
    const baseURL = formatBaseURL({ projectId, cursor, filters });
    return baseURL;
  },
  method: 'GET',
  errorMessage: clientTranslate('datacosmos.fetchErrors.tasking.cannotGetById'),
  errorDescription: (e) => e.join('; '),
  omitCaptureDataKey: true,
});

export const getSatellitesForTasking = apiFetchHandler<TaskingSatellites>({
  host: hosts.data.v1,
  endpoint: `/tasking/satellites`,
  method: 'GET',
  errorMessage: clientTranslate('datacosmos.fetchErrors.tasking.cannotGet'),
  errorDescription: (e) => e.join('; '),
  omitCaptureDataKey: true,
});

export type PostSwathSearchBody = {
  area_of_interest: {
    name: string;
    geojson: {
      type: string;
      geometry: GeoJSON.Polygon;
      properties: {};
    };
  };
  instrument: {
    mission_id: string;
    sensor_id: string;
  };
  roll_angle: number;
  start: string;
  stop: string;
  project_id: string;
};

export const postSwathSearch = apiFetchHandler<
  Swath,
  EmptyParams,
  PostSwathSearchBody
>({
  host: hosts.data.v1,
  endpoint: '/tasking/swath',
  method: 'POST',
  errorMessage: clientTranslate('datacosmos.fetchErrors.tasking.cannotPost'),
  errorDescription: (e) => e.join('; '),
});

export type PostManualTaskingSearchBody = {
  instruments: {
    mission_id: SatelliteId;
    sensor_id: SensorId;
  }[];
  areas_of_interest: {
    name: string;
    geojson: GeoJSON.Feature<GeoJSON.Polygon>;
  }[];
  start: string;
  stop: string;
  constraints: {
    type: string;
    min: number;
    max: number;
  }[];
};

export const postManualTaskingSearch = apiFetchHandler<
  ManualOpportunity[],
  EmptyParams,
  PostManualTaskingSearchBody
>({
  host: hosts.data.v1,
  endpoint: '/tasking/search',
  method: 'POST',
  errorMessage: clientTranslate(
    'datacosmos.fetchErrors.tasking.cannotPostManualTasking'
  ),
  errorDescription: (e) => e.join('; '),
});

export const getAllTaskingRequests = apiFetchHandler<TaskingRequest[]>({
  host: hosts.data.v1,
  endpoint: '/tasking/requests',
  method: 'GET',
  errorMessage: clientTranslate('datacosmos.fetchErrors.tasking.cannotGetAll'),
  errorDescription: (e) => e.join('; '),
});
