import ModuleSelectionPage from './PayloadConfiguration/ModuleSelectionPage';
import { PAYLOAD_DATA } from '../../constants/moduleData/constants';
import React from 'react';
import DefaultLayout from '../../layouts/DefaultLayout';
import RedirectToMainPage from '../../layouts/RedirectToMainPage';
import styles from './PayloadConfiguration/styles.module.scss';
import { useHistory } from 'react-router-dom';
import { updateSatellitePayloads } from '../../actions/satellite/action';
import { connect } from 'react-redux';
import type { AppState } from '../../store';
import type { ISelectedPayloads } from '../../constants/moduleData/types';
import { selectAllPayloads } from '../../selectors/moduleSelector';

interface IStateProps {
  allPayloads: ISelectedPayloads;
}

interface IDispatchProps {
  updateSatellitePayloads: typeof updateSatellitePayloads;
}

type IProps = IStateProps & IDispatchProps;

const PayloadSelectionPage = ({
  updateSatellitePayloads,
  allPayloads,
}: IProps) => {
  const history = useHistory();

  return (
    <>
      <RedirectToMainPage />
      <DefaultLayout offLight>
        <div className={styles.pageContainer}>
          <div className={styles.headingBar}>
            <h1 className={styles.mainTitle}>Select your payload</h1>
            <div
              className={`bp4-callout bp4-icon-info-sign ${styles.nextFeatureInfo}`}
            >
              Payload operational modes will be considered in the next release
              to compute power and data budgets.
            </div>
          </div>
          <div className={styles.contentContainer}>
            {
              //@ts-ignore
              <ModuleSelectionPage
                //@ts-ignore
                type={PAYLOAD_DATA}
                submitHandler={(selectedNames: string[]) => {
                  history.push('/msd');
                  updateSatellitePayloads(selectedNames, allPayloads);
                }}
                customisable
                multipleSelectable
              />
            }
          </div>
        </div>
      </DefaultLayout>
    </>
  );
};

const mapStateToProps = (state: AppState): IStateProps => ({
  allPayloads: selectAllPayloads(state),
});

const mapDispatchToProps = {
  updateSatellitePayloads,
};

export default connect<IStateProps, IDispatchProps>(
  mapStateToProps,
  mapDispatchToProps
)(PayloadSelectionPage);
