import DefaultLayout from '../layouts/DefaultLayout';
import NotFound from '../components/common/NotFound';
import type { ReactElement } from 'react';
import React from 'react';

const NotFoundPage = (): ReactElement => (
  <DefaultLayout>
    <NotFound />
  </DefaultLayout>
);

export default NotFoundPage;
