import { useState, useCallback } from 'react';
import { find, head, tail } from 'lodash/fp';
import axios from 'axios';
import { IconNames } from '@blueprintjs/icons';
import { Intent } from '@blueprintjs/core';
import { useAuth } from 'services/auth/AuthWrapper';
import type { IFolder } from 'services/api/ops/cloudStorage';
import { showErrorMessage } from 'utils/common/CommonUtils';
import { AVAILABLE_CLOUD_STORAGE } from 'constants/fileExplorer/constants';
import { toaster } from 'toaster';
import { toCloudContent } from 'utils/fileExplorer/cloudStorageUtils';
import { BACKEND_BASE_URL } from 'env';

const SERVICE_URL = `${BACKEND_BASE_URL}/portal/v0/cloud-storage/`;

export const useCloudStorage = (
  missionId: number | undefined,
  programmeId: number | undefined
) => {
  const [isFetching, setFetching] = useState(false);
  const [currentStorage, setCurrentStorage] = useState<string>();

  const createServiceUrl = useCallback(
    (requestedPath: string) => {
      const splittedPath = requestedPath.split('/');
      const serviceName = head(splittedPath);

      const storage = find(['name', serviceName], AVAILABLE_CLOUD_STORAGE);

      let clearPath = requestedPath;
      let service = currentStorage;

      if (storage) {
        service = serviceName;
        clearPath = tail(splittedPath).join('/');
        setCurrentStorage(serviceName);
      }

      const id = service === 'mission' ? missionId : programmeId;
      if (service === undefined || id === undefined) return '';
      const fullClearPath = `${service}/${id}/${clearPath}`;

      return `${SERVICE_URL}${fullClearPath}`;
    },
    [currentStorage, missionId, programmeId]
  );

  const { token } = useAuth();

  const putContent = useCallback(
    async (file: IFolder, path: string) => {
      if (!token) return;

      setFetching(true);

      const requestedPath = createServiceUrl(path);

      try {
        const { fileContent, filePath, mimeType } = toCloudContent(file);

        await axios.put(`${requestedPath}${filePath}${mimeType}`, fileContent, {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });

        setFetching(false);
        toaster.show({
          message: 'File was updated',
          icon: IconNames.TICK_CIRCLE,
          intent: Intent.SUCCESS,
        });
      } catch (e) {
        showErrorMessage('Something went wrong during your request!');
        setFetching(false);
      }
    },
    [createServiceUrl, token]
  );

  return {
    createServiceUrl,
    isFetching,
    putContent,
  };
};
