export const PAYLOAD_TYPE_DEFAULT = 'Other';

export const PAYLOAD_TYPE = {
  OPTICAL_PAYLOAD: 'Optical',
  COMMUNICATIONS_PAYLOAD: 'Communications',
  RESEARCH_PAYLOAD: 'Research',
  ADCS: 'ADCS',
};

export interface IMode {
  type: string;
  data: {
    value: number;
  };
}

export interface IModeGroup {
  dataGenerationMode: IMode;
  dataConsumptionMode: IMode;
  powerConsumptionMode: IMode;
}

export interface IBasePayload {
  id: string;
  type: string;
  data: {
    unitPrice: string;
    physical: {
      mass: number;
      xLength: number;
      yLength: number;
      zLength: number;
    };
    thermal: {
      minimumTemperature: number;
      maximumTemperature: number;
    };
  };
  custom?: boolean;
}

export type IPayload = IBasePayload & {
  data: {
    modes?: {
      [modeName: string]: IModeGroup;
    };
  };
};

export interface ISelectedPayloads {
  [payloadId: string]: IPayload;
}
