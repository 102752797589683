import { useEffect, useState, useCallback, useRef } from 'react';
import type { RequestError } from 'api/client';
import { v1 as uuid } from 'uuid';

export const useQuery = <Data, Params, Body>(
  apiCall: (_: { params?: Params; body?: Body }) => Promise<{
    data?: Data;
    errors?: RequestError[];
    success: boolean;
  }>,
  options: {
    params?: Params;
    body?: Body;
    skip?: boolean;
    initialData: Data;
  }
) => {
  const [initialData, setInitialData] = useState(options.initialData);

  useEffect(() => {
    if (JSON.stringify(options.initialData) !== JSON.stringify(initialData)) {
      setInitialData(options.initialData);
    }
  }, [initialData, options.initialData]);

  const [data, setData] = useState<Data>(initialData);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState<RequestError[]>([]);
  const paramsRef = useRef<Params | null | undefined>(null);
  const bodyRef = useRef<Body | null | undefined>(null);
  const requestId = useRef<string>();

  const cancel = useCallback(() => {
    requestId.current = undefined;
    setLoading(false);
  }, []);

  const refetch = useCallback(async () => {
    if (paramsRef.current === null) return;
    const refetchId = uuid();
    requestId.current = refetchId;
    setLoading(true);
    const response = await apiCall({
      params: paramsRef.current as unknown as Params,
    });

    if (requestId.current === refetchId) {
      setData(response.success ? (response.data as Data) : initialData);
      setErrors(response.errors ?? []);
      setLoading(false);
    }
  }, [apiCall, initialData]);

  useEffect(() => {
    const paramsDidChange =
      JSON.stringify(paramsRef.current) !== JSON.stringify(options.params);

    if (paramsDidChange) paramsRef.current = options.params ?? undefined;

    const bodyDidChange =
      JSON.stringify(bodyRef.current) !== JSON.stringify(options.body);

    if (bodyDidChange) bodyRef.current = options.body ?? undefined;

    if (paramsDidChange || bodyDidChange) {
      if (options.skip) {
        setData(initialData);
      } else {
        void refetch();
      }
    }
  }, [initialData, options.body, options.params, options.skip, refetch]);

  return {
    data,
    loading,
    errors,
    refetch,
    cancel,
  };
};
