import React from 'react';
import { Classes } from '@blueprintjs/core';

import AlertsRow from './AlertsRow';

import s from '../index.module.scss';
import type { Alert } from 'api/alerts/types';

interface IProps {
  list: Alert[];
  mutedAlerts: string[];
  showSilenceAlertPopup: (id: string) => void;
}

const LibraryAlertsTable = ({
  list,
  mutedAlerts,
  showSilenceAlertPopup,
}: IProps) => {
  return (
    <table className={[Classes.HTML_TABLE, s.tableContainer].join(' ')}>
      <thead>
        <tr>
          <th>Name</th>
          <th>Severity</th>
        </tr>
      </thead>
      <tbody>
        {list.map((alert) => (
          <AlertsRow
            key={alert.labels.alertname}
            alert={alert}
            silenceHandler={() =>
              showSilenceAlertPopup(alert.labels.alertname ?? '')
            }
            muted={mutedAlerts.includes(alert.labels.alertname ?? '')}
          />
        ))}
      </tbody>
    </table>
  );
};

export default LibraryAlertsTable;
