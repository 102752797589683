import classNames from 'classnames';
import { useEffect, useRef } from 'react';
import type { AriaTabListOptions } from '@react-aria/tabs';
import {
  mergeProps,
  useFocusRing,
  useTab,
  useTabList,
  useTabPanel,
} from 'react-aria';
import type { TabListState } from 'react-stately';
import { useTabListState } from 'react-stately';
import type { Node } from '@react-types/shared/src/collections';

interface ITabProps {
  item: Node<object>;
  state: TabListState<object>;
  isSelected: boolean;
}

interface ITabListProps {
  state: TabListState<object>;
  customPadding?: string;
}

type IProps = AriaTabListOptions<ITabProps> & {
  children: JSX.Element | JSX.Element[];
  className?: string;
  customPadding?: string;
  selectedItemKey?: string;
};

const Tab = ({ item, state, isSelected }: ITabProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const { tabProps } = useTab(item, state, ref);

  return (
    <div
      {...tabProps}
      ref={ref}
      className={classNames('cursor-pointer w-full mb-4', {
        'border-b-4 border-b-accent-900 text-accent-900 dark:border-b-item-dark-hover dark:text-item-dark-hover':
          isSelected,
        'border-b-4 border-b-item-contrast-inactive text-item-contrast-inactive':
          !isSelected,
      })}
    >
      {item.rendered}
    </div>
  );
};

const TabPanel = ({ state, customPadding }: ITabListProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const { tabPanelProps } = useTabPanel({}, state, ref);
  return (
    <div
      {...tabPanelProps}
      ref={ref}
      style={{
        maxHeight: 'inherit',
        overflowY: 'auto',
        paddingBottom: customPadding ?? '150px',
      }}
    >
      {state?.selectedItem?.props.children}
    </div>
  );
};

const Tabs = (props: IProps) => {
  const state = useTabListState(props);
  const ref = useRef(null);
  const { tabListProps } = useTabList(props, state, ref);
  const { focusProps } = useFocusRing({
    within: true,
  });

  useEffect(() => {
    if (props.selectedItemKey) {
      state.setSelectedKey(props.selectedItemKey);
    }
  }, [props.selectedItemKey, state]);

  return (
    <div
      className={props.className}
      style={{
        maxHeight: 'inherit',
      }}
    >
      <div
        {...mergeProps(tabListProps, focusProps)}
        ref={ref}
        className="flex justify-between gap-4 text-start w-full mb-4"
        style={{
          maxHeight: 'inherit',
          overflowY: 'auto',
        }}
      >
        {[...state.collection].map((item) => (
          <Tab
            key={item.key}
            item={item}
            state={state}
            isSelected={
              item.key === state.selectedItem?.key ||
              item.key === props.selectedItemKey
            }
          />
        ))}
      </div>
      <TabPanel
        key={state.selectedItem && state?.selectedItem.key}
        state={state}
        customPadding={props.customPadding}
      />
    </div>
  );
};

export default Tabs;
