import { useEffect, useState } from 'react';
import axios from 'axios';
import { isNil } from 'lodash/fp';
import { useAuth } from 'services/auth/AuthWrapper';
import { showErrorMessage } from 'utils/common/CommonUtils';
import { BACKEND_BASE_URL } from 'env';

export interface IMissionGatewayStatus {
  isClientConnected: boolean;
  message: string;
  createdAt: string;
  createdBy: string;
}

const SERVICE_URL = `${BACKEND_BASE_URL}/ops/v0/gateway`;

const useGatewayStatus = (missionId: number | undefined) => {
  const [isFetching, setFetching] = useState(false);
  const [gatewayStatus, setGatewayStatus] = useState<IMissionGatewayStatus>();

  const { token } = useAuth();

  const getGatewayStatusByMission = async (id: number) => {
    try {
      setFetching(true);

      const {
        data: { data },
      } = await axios.get(`${SERVICE_URL}/mission/${id}/status`, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });

      setGatewayStatus(data);
      setFetching(false);
    } catch (e) {
      showErrorMessage('Could not get gateway connection status');
      setFetching(false);
      console.error(e);
    }
  };

  useEffect(() => {
    if (!isNil(missionId)) {
      missionId && getGatewayStatusByMission(missionId);
    }
  }, [missionId]);

  return {
    isFetching,
    gatewayStatus,
    getGatewayStatusByMission,
  };
};

export default useGatewayStatus;
