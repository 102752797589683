import type { ReactElement } from 'react';
import React, { PureComponent } from 'react';
import type { FormErrors, InjectedFormProps } from 'redux-form';
import { Field, reduxForm } from 'redux-form';
import { Classes, H3, H6 } from '@blueprintjs/core';
import {
  DRAGGABLE,
  DRAGGABLE_HEADER,
} from '../../constants/draggable/constants';
import { dragElement } from '../../services/draggableService/dragElement';
import type {
  IObjectiveType,
  IOptimisationObjective,
} from '../../constants/objectivesTable/constants';
import {
  GROUND_SEGMENT_EVENT,
  MISSION_METRIC,
  OBJECTIVE_METRIC,
  OBJECTIVE_TYPES,
  OPTIMISATION_FORM_NAME,
  POINT_OF_INTEREST_EVENT,
  REGION_OF_INTEREST_EVENT,
  SCOPE,
} from '../../constants/objectivesTable/constants';
import type { WithTranslation } from 'react-i18next';
import type { IOption } from '../inputField/SelectField';
import selectField from '../inputField/SelectField';
import { MissionMetric } from './optimisationObjectivesSubForms/MissionMetric';
import { GroundSegment } from './optimisationObjectivesSubForms/GroundSegment';
import { PointOfInterest } from './optimisationObjectivesSubForms/PointOfInterest';
import { RegionOfInterest } from './optimisationObjectivesSubForms/RegionOfInterest';
import type { IValidateError } from '../../constants/actionTypes';

function validate(values: FormData & IOptimisationObjective): FormErrors {
  const { criterion, object, type } = values;
  const errors: IValidateError = {};
  if (!criterion) {
    errors.criterion = errors._error = 'criterion_error';
  }
  if (!object || object === 'undefined') {
    if (type !== OBJECTIVE_TYPES.MISSION_METRIC) {
      errors.object = errors._error = 'object_error';
    }
  }
  return errors;
}

interface IOptimisationFormProps {
  resetPopUp?: Function;
  onClose: Function;
  pointsOfInterestNames: string[];
  regionsOfInterestNames: string[];
}

interface IOptimisationFormState {
  objectiveType: IObjectiveType;
}

interface IComponentProps
  extends IOptimisationFormProps,
    WithTranslation,
    InjectedFormProps<FormData, IOptimisationFormProps> {
  options: IOption[];
  missionMetricOptions: IOption[];
}

type ComponentState = IOptimisationFormState;

class OptimisationObjectivesForm extends PureComponent<
  IComponentProps,
  ComponentState
> {
  public constructor(props: IComponentProps) {
    super(props);
    this.state = {
      // @ts-ignore
      objectiveType: this.props.initialValues.type,
    };
  }

  public componentDidMount(): void {
    dragElement(document.getElementById(DRAGGABLE));
  }

  private onChangeState = (objectiveType: IObjectiveType): void => {
    const { change, pointsOfInterestNames, regionsOfInterestNames } =
      this.props;
    switch (objectiveType) {
      case OBJECTIVE_TYPES.MISSION_METRIC: {
        change('metric', MISSION_METRIC.NUMBER_OF_SATELLITES);
        change('object', '');
        change('event', '');
        change('scope', '');
        break;
      }
      case OBJECTIVE_TYPES.GROUND_SEGMENT_EVENT: {
        change('metric', OBJECTIVE_METRIC.INCIDENCE);
        change('object', GROUND_SEGMENT_EVENT.OBJECT);
        change('event', GROUND_SEGMENT_EVENT.EVENT.VISIBILITY);
        change('scope', SCOPE.COVERAGE);
        break;
      }
      case OBJECTIVE_TYPES.POINT_OF_INTEREST_EVENT: {
        change('metric', OBJECTIVE_METRIC.INCIDENCE);

        change('object', `${pointsOfInterestNames[0]}`);
        change('event', POINT_OF_INTEREST_EVENT.EVENT.VISIBILITY);
        change('scope', SCOPE.COVERAGE);
        break;
      }
      case OBJECTIVE_TYPES.REGION_OF_INTEREST_EVENT: {
        change('metric', OBJECTIVE_METRIC.INCIDENCE);
        change('object', `${regionsOfInterestNames[0]}`);
        change('event', REGION_OF_INTEREST_EVENT.EVENT.VISIBILITY);
        change('scope', SCOPE.COVERAGE);
        break;
      }
      default:
        break;
    }

    this.setState({ objectiveType });
  };

  private onCloseWrapper = (): void => {
    const { initialValues } = this.props;
    const { onClose, resetPopUp } = this.props;
    onClose(initialValues);
    resetPopUp();
  };

  public render(): ReactElement {
    const {
      handleSubmit,
      submitSucceeded,
      resetPopUp,
      error,
      invalid,
      t,
      pointsOfInterestNames,
      regionsOfInterestNames,
      anyTouched,
      options,
      missionMetricOptions,
    } = this.props;
    const { objectiveType } = this.state;
    if (submitSucceeded) {
      resetPopUp();
    }
    const errorDiv =
      error && anyTouched ? (
        <div className="bp4-callout bp4-intent-danger bp4-icon-error">
          <H6 className="bp4-heading">
            {t(`validation.errors.${error}` as any)}
          </H6>
        </div>
      ) : null;
    const saveTitle =
      error && anyTouched
        ? t(`validation.errors.${error}` as any)
        : t('validation.save_title');

    let subForm;
    switch (objectiveType) {
      case OBJECTIVE_TYPES.MISSION_METRIC: {
        subForm = MissionMetric({ options: missionMetricOptions });
        break;
      }
      case OBJECTIVE_TYPES.GROUND_SEGMENT_EVENT: {
        subForm = GroundSegment();
        break;
      }
      case OBJECTIVE_TYPES.POINT_OF_INTEREST_EVENT: {
        subForm = PointOfInterest(pointsOfInterestNames);
        break;
      }
      case OBJECTIVE_TYPES.REGION_OF_INTEREST_EVENT: {
        subForm = RegionOfInterest(regionsOfInterestNames);
        break;
      }
    }

    return (
      <form onSubmit={handleSubmit}>
        <div className="bp4-dialog dialog-margin-set-out">
          <div id={DRAGGABLE_HEADER} className="bp4-dialog-header">
            <H3>{t('objectives.form_title')}</H3>
            <div
              role="button"
              tabIndex={0}
              className="bp4-dialog-close-button bp4-button bp4-minimal bp4-icon-cross"
              onClick={this.onCloseWrapper}
              onKeyDown={(ev) => ev.keyCode === 13 && this.onCloseWrapper()}
            />
          </div>
          <div className="bp4-dialog-body">
            <Field
              name="type"
              onChangeState={this.onChangeState}
              title={'objectives.types.title'}
              options={options}
              component={selectField}
            />
            {subForm}
            {errorDiv}
          </div>
          <div className="bp4-dialog-footer">
            <div className="bp4-dialog-footer-actions">
              <button
                type="submit"
                title={saveTitle}
                className={`bp4-button bp4-icon-saved  ${
                  invalid && anyTouched
                    ? Classes.INTENT_DANGER
                    : Classes.INTENT_SUCCESS
                }`}
                disabled={invalid && anyTouched}
                onClick={handleSubmit}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </form>
    );
  }
}

export default reduxForm<FormData>({
  form: OPTIMISATION_FORM_NAME,
  validate,
  enableReinitialize: true,
})(OptimisationObjectivesForm);
