import { useCallback, useMemo } from 'react';
import { useMission } from 'services/Missions';

import {
  getCommandList as apiGetCommandList,
  getCommandHistory,
  patchCommandHistoryCommand,
} from 'api/telecommands/service';
import type {
  CommandDefinition,
  CommandHistoryCommand,
} from 'api/telecommands/types';

export const COMMANDS_PER_REQUEST = 10;

export type TelecomandApi = {
  getCommandList: () => Promise<CommandDefinition[]>;
  getCommandLog: (
    sessionId: number,
    timeCursor: number,
    timeCursorMilliseconds: number,
    limit?: number
  ) => Promise<CommandHistoryCommand[]>;
  saveCommandNote: (commandUUID: string, note: string) => void;
};

export const useTelecommandsApi = (): TelecomandApi => {
  const { currentMissionId } = useMission();

  const getCommandList = useCallback(async () => {
    if (!currentMissionId) {
      return [] as CommandDefinition[];
    }

    const { data: commandList } = await apiGetCommandList({
      params: {
        missionId: currentMissionId,
      },
    });

    return commandList ?? [];
  }, [currentMissionId]);

  const getCommandLog = useCallback(
    async (
      sessionId: number,
      timeCursor: number,
      timeCursorMilliseconds: number,
      limit?: number
    ) => {
      if (!currentMissionId) {
        return [];
      }

      // Minimum bound since UI thinks its the end if less than min
      let validLimit = COMMANDS_PER_REQUEST;

      if (limit) {
        validLimit = Math.max(limit, COMMANDS_PER_REQUEST);
      }

      const { data: commandHistory } = await getCommandHistory({
        params: {
          currentMissionId,
          sessionId,
          timeCursor,
          timeCursorMilliseconds,
          limit: validLimit,
        },
      });

      return commandHistory ?? [];
    },
    [currentMissionId]
  );

  const saveCommandNote = useCallback(
    async (commandUUID: string, note: string) => {
      if (!currentMissionId) {
        return;
      }

      await patchCommandHistoryCommand({
        body: {
          note,
        },
        params: {
          currentMissionId,
          commandUUID,
        },
      });
    },
    [currentMissionId]
  );

  const telecommandApi = useMemo(() => {
    return {
      getCommandList,
      getCommandLog,
      saveCommandNote,
    };
  }, [getCommandList, getCommandLog, saveCommandNote]);

  return telecommandApi;
};
