import type { ChangeEvent, ReactElement } from 'react';
import React from 'react';
import type { WithTranslation } from 'react-i18next';
import { withTranslation } from 'react-i18next';

import DefaultLayout from 'layouts/DefaultLayout';
import MapContainer from 'components/eventsMap/MapContainer';
import { MAP_ID, MAP_TYPE } from 'constants/map/constants';
import PopUp from 'components/popUp/PopUp';
import EventsPanel from 'components/eventsPanel/EventsPanel';
import type { IMapWrapper } from 'declarations/mapDeclarations/Map';
import Geometry from 'components/geometry/Geometry';
import Goals from 'components/goal/Goals';
import { Button } from '@blueprintjs/core';
import { Tooltip } from 'ui/Tooltip';
import Results from 'components/optimizationResults/Results';
import type { ISimulateState } from 'components/simulation/Simulation';
import Simulation from 'components/simulation/Simulation';
import { DateInput } from '@blueprintjs/datetime';
import type { IMission } from 'services/Missions';
import { connect } from 'react-redux';
import type { AppState } from 'store';
import type { IPopUp } from 'constants/popUp/actionTypes';
import { setPopUp } from 'actions/popUp/thunks';
import type {
  IGeometryObject,
  IGeometryObjectState,
} from 'constants/msd/geometry/interfaces';
import {
  addGeometry,
  removeGeometry,
  updateGeometry,
} from 'actions/geometry/thunks';
import { getMissionsThunk, updateMissionThunk } from 'actions/missions/thunks';
import type { IGoal, IGoalReduceState } from 'constants/goals/interfaceas';
import { addGoal, removeGoal, updateGoal } from 'actions/goal/thunks';
import type {
  IPIState,
  IPointOfInterest,
} from 'constants/pointsOfInterest/actionTypes';
import type {
  IRegionOfInterest,
  IRIState,
} from 'constants/regionsOfInterest/actionTypes';
import { computeGeometry } from 'utils/api/compute/computeGeometry';
import { normalizeRegionsOfInterest } from 'utils/normalizeRegionsOfInterest';
import moment from 'moment';
import type { IGroup } from 'constants/groupSatelliteBalls/actionTypes';
import { updateGroupSB } from 'actions/groupSatelliteBalls/thunks';
import { downloadFile, showErrorMessage } from 'utils/common/CommonUtils';
import type { IData } from 'actions/uploadData';
import { uploadData } from 'actions/uploadData';
import {
  DAYS_INPUT,
  GEOMETRY_FILE_NAME,
  RESULTS_PATH,
} from 'constants/msd/geometry/constants';
import type { IUserInformation } from 'constants/user/actionTypes';
import { DATE_FORMAT, DATETIME_FORMAT } from 'constants/datetime';
import type { IAnyKey } from 'utils/getFormData';
import { DaysInput } from 'components/common/DaysInput';
import type {
  ISatelliteData,
  ISTState,
} from 'constants/satelliteOrbits/actionTypes';
import { addSO, removeAllSO, updateSO } from 'actions/satelliteOrbits/thunks';
import BlockOverlay from 'layouts/BlockOverlay';
import RedirectToMainPage from 'layouts/RedirectToMainPage';
import { updateResultsThunk } from 'actions/results/thunks';
import { removePI, updatePI } from 'actions/pointsOfInterest/helpers';
import { removeRI, updateRI } from 'actions/regionsOfInterest/helpers';

type OverlayType = google.maps.drawing.OverlayType;
import type { ASTRUMS } from 'constants/API/constant';
import { defaultAustrum } from 'constants/austrum/constant';
import type { IAstrum, IAstrumState } from 'constants/austrum/interfaces';
import { updateAstrumThunk } from 'actions/astrum/thunks';
import type { Action } from 'redux';
import type { ThunkDispatch } from 'redux-thunk';
import { setFocus } from 'actions/focus/thunks';
import type { IFocus } from 'constants/focus/actionTypes';
import type { RouteComponentProps } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import { MSD_URLS } from 'services/socket/urls';
import { msd } from 'constants/mixpanelAnalytics';
import ScenarioUploader from 'components/ScenarioUploader';
import { toTimezone } from 'utils/common/dateUtils';
import { analyticsClient } from 'utils/hooks/analytics/useAnalytics';

const pageKey = 'MGP';

interface IStateProps {
  mission: IMission;
  user: IUserInformation;
  geometry: IGeometryObjectState;
  goals: IGoalReduceState;
  results: ISimulateState;

  groups: IGroup[];

  pointsOfInterest: IPIState;
  regionsOfInterest: IRIState;
  satelliteOrbits: ISTState;
  visiblePopUp: boolean;
  astrums: IAstrumState;
  focus: IFocus;
}

interface IDispatchProps {
  updateGroupSB: Function;
  getMissions: Function;
  updateMission: Function;
  uploadData: Function;
  setPopUp: Function;
  addGeometry: Function;
  updateGeometry: Function;
  removeGeometry: Function;
  addGoal: Function;
  updateGoal: Function;
  removeGoal: Function;
  addSO: Function;
  updateSO: Function;
  removeAllSO: Function;
  updateResultsThunk: Function;
  updatePI: Function;
  removePI: Function;
  updateRI: Function;
  removeRI: Function;
  updateAstrumThunk: (astrum: IAstrum) => void;
  setFocus: (focus: IFocus) => void;
}

interface IState {
  map: IMapWrapper;
  isOpenOptimizationResult: boolean;
  isOpenSimulation: boolean;
  drawingMode: OverlayType;
  days: {
    value: number | string;
    error: boolean;
  };
  isMissionDateFormatedToUtc: boolean;
}

type IProps = IStateProps &
  IDispatchProps &
  WithTranslation &
  RouteComponentProps;

class MissionGeometryPage extends React.Component<IProps, IState> {
  public constructor(props: IProps) {
    super(props);
    this.state = {
      map: null,
      isOpenOptimizationResult: false,
      isOpenSimulation: false,
      drawingMode: null,
      days: {
        value:
          this.props.mission && this.props.mission.days
            ? this.props.mission.days
            : DAYS_INPUT.defaultValue,
        error: false,
      },
      isMissionDateFormatedToUtc: false,
    };
  }

  public componentDidMount(): void {
    analyticsClient.startTracking()({
      type: msd.CONSTELLATION.VISIT,
      action: 'Visit',
      item: 'Mission geometry page constellation',
      module: 'MSD',
    });

    if (!this.props.mission) {
      this.props.getMissions();
    }
    if (this.props.astrums) {
      const astrum = this.props.astrums[pageKey];

      this.props.updateAstrumThunk(astrum ? astrum : defaultAustrum);
    }
  }

  public componentWillUnmount(): void {
    analyticsClient.stopTracking()({
      type: msd.CONSTELLATION.VISIT,
      action: 'Visit',
      item: 'Mission geometry page constellation',
      module: 'MSD',
    });
  }

  public componentDidUpdate(prevProps: Readonly<IProps>): void {
    if (this.props.mission && this.props.mission !== prevProps.mission) {
      const days = {
        value: this.props.mission.days,
        error: false,
      };
      this.setState({ days });
    }

    if (!this.props.mission && prevProps.mission) {
      this.props.getMissions();
    }
  }

  private onChangeMissionDate = (date: Date, isUserChanged: boolean) => {
    if (!isUserChanged) {
      return;
    }
    this.props.updateMission({
      ...this.props.mission,
      date: date,
    });
  };
  private handleShowOptimize = () => {
    this.setState({ isOpenOptimizationResult: true });
  };
  private handleShowSimulate = () => {
    this.setState({ isOpenSimulation: true });
    if (this.props.results) {
      this.handleOnComplete(null);
    }

    analyticsClient.sendInfo()({
      type: msd.CONSTELLATION.SIMULATION.START,
      action: 'Start simulation',
      item: 'Mission geometry page simulation',
      module: 'MSD',
      additionalParams: {
        client: 'frontend',
        'Total Points of Interest': this.props.pointsOfInterest.list.length,
        'Total Regions of Interest': this.props.regionsOfInterest.list.length,
        'Points of Interest': this.props.pointsOfInterest.list,
        'Regions of Interest': this.props.regionsOfInterest.list,
      },
    });
  };
  private handleContinue = async () => {
    await this.handleOnComplete({ simulated: false });
    await this.props.removeAllSO();

    analyticsClient.sendInfo()({
      type: msd.CONSTELLATION.SIMULATION.CONTINUE,
      action: 'Continue simulation',
      item: 'Mission geometry page simulation',
      module: 'MSD',
      additionalParams: {
        'Total Points of Interest': this.props.pointsOfInterest.list.length,
        'Total Regions of Interest': this.props.regionsOfInterest.list.length,
        'Points of Interest': this.props.pointsOfInterest.list,
        'Regions of Interest': this.props.regionsOfInterest.list,
      },
    });
    this.props.history.push('/msd');
  };

  private handleOnClickLogo = () => {
    this.handleOnComplete(null);
  };
  private handleOnComplete = (data: object) => {
    const {
      updateResultsThunk,
      geometry,
      goals,
      mission,
      user,
      regionsOfInterest,
      pointsOfInterest,
    } = this.props;
    updateResultsThunk(
      {
        geometry,
        goals,
        mission,
        user,
        regionsOfInterest,
        pointsOfInterest,
      },
      data
    );
  };
  protected saveResults = (): void => {
    const { pointsOfInterest, regionsOfInterest, geometry, goals, mission } =
      this.props;
    const json = JSON.stringify(
      {
        pointsOfInterest,
        regionsOfInterest: normalizeRegionsOfInterest(regionsOfInterest),
        geometry,
        goals,
        mission,
      },
      null,
      2
    );
    downloadFile(json, GEOMETRY_FILE_NAME, 'application/json');
  };
  protected uploadResults = (event: ChangeEvent<HTMLInputElement>): void => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (evt: ProgressEvent<FileReader>): void => {
        try {
          // eslint-disable-next-line @typescript-eslint/no-base-to-string
          const result = evt.target.result.toString();
          const data = JSON.parse(result) as IData;
          this.props.uploadData(data, [
            'pointsOfInterest',
            'regionsOfInterest',
            'geometry',
            'goals',
            'mission',
          ]);
        } catch (e) {
          console.error(e);
          showErrorMessage(
            `File data is incorrect! Error: ${
              (e as { message?: string })?.message
            }`
          );
        }
      };
      reader.readAsText(file);
    }
  };

  private renderMissionDatePanel = (mission: IMission) => {
    if (!mission) {
      return null;
    }
    return (
      <div className="date-container white">
        <h3 className="text">Date</h3>
        <DateInput
          className="date-holder"
          placeholder={DATE_FORMAT}
          value={mission.date}
          onChange={this.onChangeMissionDate}
          formatDate={(date) => moment(date).format(DATETIME_FORMAT)}
          parseDate={(str) => moment(str, DATETIME_FORMAT).toDate()}
          invalidDateMessage={DATE_FORMAT}
          minDate={new Date(1970, 0, 0)}
          maxDate={new Date(2050, 0, 0)}
          timePrecision={'minute'}
        />
      </div>
    );
  };
  private handleDaysChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    let error = false;
    if (
      Number(value).toString() !== value ||
      Number(value) > DAYS_INPUT.max ||
      Number(value) < DAYS_INPUT.min
    ) {
      error = true;
    }
    const days = { value, error };
    this.setState({ days });
    if (!error) {
      this.props.updateMission({ ...this.props.mission, days: Number(value) });
    }
  };

  private renderCompute = (
    user: IUserInformation,
    mission: IMission,
    astrum: ASTRUMS,
    days: number,
    geometry: IGeometryObjectState,
    pointsOfInterest: IPIState,
    regionsOfInterest: IRIState,
    onClose: Function,
    onComplete: Function,
    addSO: Function,
    updateSO: Function,
    removeAllSO: Function,
    satelliteOrbits: ISTState,
    predefinedData?: ISimulateState
  ) => {
    if (!user || !mission) {
      return null;
    }

    const regionsOfInterestNormalized =
      normalizeRegionsOfInterest(regionsOfInterest);
    const computeData = computeGeometry(
      { ...mission, date: toTimezone(mission.date, true) },
      days,
      geometry,
      pointsOfInterest,
      regionsOfInterestNormalized,
      astrum
    );

    return (
      <Simulation
        mission={mission}
        user={user}
        computeData={computeData}
        onClose={onClose}
        onComplete={onComplete}
        addSO={addSO}
        updateSO={updateSO}
        removeAllSO={removeAllSO}
        satellites={satelliteOrbits}
        predefinedData={predefinedData}
        url={MSD_URLS.EVENT_ANALYSIS}
        analytics={msd.CONSTELLATION.SIMULATION}
      />
    );
  };

  private renderOptimize = (onClose: Function) => (
    //TODO: not finished
    <Results onClose={onClose} />
  );

  private renderMap = () => {
    const { isOpenSimulation } = this.state;
    const mapType = isOpenSimulation ? MAP_TYPE.SIMULATED : MAP_TYPE.CONTROLLED;
    return (
      <MapContainer
        pageKey={pageKey}
        key={mapType}
        mapId={MAP_ID}
        mapType={mapType}
        onMapLoaded={(map) => this.setState({ map })}
        onChangeDrawingMode={(drawingMode) => this.setState({ drawingMode })}
      />
    );
  };

  public render(): ReactElement {
    const {
      t,
      mission,
      user,
      geometry,
      goals,
      addGeometry,
      addGoal,
      updateGeometry,
      updateGoal,
      setPopUp,
      removeGeometry,
      regionsOfInterest,
      pointsOfInterest,
      removeGoal,
      addSO,
      updateSO,
      removeAllSO,
      satelliteOrbits,
      results,
      astrums,

      updatePI,
      removePI,
      updateRI,
      removeRI,

      focus,
      setFocus,
    } = this.props;
    const {
      map,
      isOpenOptimizationResult,
      isOpenSimulation,
      drawingMode,
      days,
    } = this.state;

    const isOptimize = Boolean(
      geometry.find((geometryObject: IGeometryObject & IAnyKey) => {
        const optimisation = Object.keys(geometryObject).find(
          (key) => geometryObject[key].optimisation
        );
        return optimisation || !geometryObject.force;
      })
    );

    return (
      <>
        <RedirectToMainPage />
        <DefaultLayout
          onClickLogo={this.handleOnClickLogo}
          className={`mission-geometry-page ${
            this.props.visiblePopUp ? 'openedPopUp' : ''
          }`}
        >
          <div className="left-layout">
            <div className="big-part">
              <BlockOverlay disable={isOpenSimulation}>
                {/* { TODO: Release Week #9
                  //@ts-ignore
                  <ModesPanel />
                } */}
                <EventsPanel
                  map={map}
                  removePI={removePI}
                  removeRI={removeRI}
                  updatePI={updatePI}
                  updateRI={updateRI}
                  pointsOfInterest={pointsOfInterest}
                  regionOfInterest={regionsOfInterest}
                  highlightedButton={drawingMode && drawingMode.toString()}
                  setPopUp={setPopUp}
                  setFocus={setFocus}
                  focus={focus}
                />
              </BlockOverlay>
            </div>
            <div className="small-part transparent" />
          </div>
          <div className="center-layout map-layout">
            {this.renderMap()}
            <div className="center-layout-header geometry-panel">
              <BlockOverlay disable={isOpenSimulation}>
                <div className="geometry-panel center-bottom">
                  <div className="white geometry-header bottom-header-button-group">
                    {this.renderMissionDatePanel(mission)}
                    <DaysInput
                      days={days}
                      handleDaysChange={this.handleDaysChange}
                    />
                  </div>
                </div>
              </BlockOverlay>
            </div>
          </div>
          <div className="right-layout">
            <div className="big-part">
              <BlockOverlay disable={isOpenSimulation}>
                <Geometry
                  astrum={astrums.current.planet}
                  geometry={geometry}
                  setPopUp={setPopUp}
                  addGeometry={addGeometry}
                  removeGeometry={removeGeometry}
                  updateGeometry={updateGeometry}
                />
                <Goals
                  setPopUp={setPopUp}
                  geometry={geometry}
                  regionsOfInterest={regionsOfInterest}
                  pointsOfInterest={pointsOfInterest}
                  addGoal={addGoal}
                  removeGoal={removeGoal}
                  updateGoal={updateGoal}
                  goals={goals}
                />
              </BlockOverlay>
            </div>
            <div className="small-part">
              <div className="bottom-element">
                <div className="bp4-dialog-footer-actions">
                  <div className="mission-geometry-button-group">
                    <Tooltip
                      className={`${isOptimize ? 'hidden' : ''}`}
                      position="left"
                      disabled={geometry.length > 0}
                      content="Please enter an orbit to Simulate results"
                    >
                      <Button
                        className="button"
                        disabled={
                          geometry.length === 0 ||
                          isOpenSimulation ||
                          !(mission && mission.date) ||
                          days.error
                        }
                        icon="database"
                        intent="primary"
                        onClick={this.handleShowSimulate}
                      >
                        Simulate
                      </Button>
                    </Tooltip>
                    <Tooltip
                      className={`${!isOptimize ? 'hidden' : ''}`}
                      position="top"
                      disabled={geometry.length > 0}
                      content="Please enter an orbit to optimize results"
                    >
                      <Button
                        // TODO disabled={isOpenOptimizationResult}
                        className="button"
                        disabled={true}
                        icon="calculator"
                        intent="primary"
                        onClick={this.handleShowOptimize}
                      >
                        Optimize
                      </Button>
                    </Tooltip>
                    <Button
                      className="button"
                      icon="saved"
                      intent="none"
                      onClick={this.saveResults}
                    >
                      {t('geometry.save')}
                    </Button>
                    <ScenarioUploader
                      text="Upload data"
                      handler={(event) => this.uploadResults(event)}
                    />
                    <Tooltip
                      className={`${isOptimize ? 'hidden' : ''}`}
                      position="left"
                      disabled={geometry.length > 0}
                      content="Please enter an orbit to continue"
                    >
                      <Button
                        className="button"
                        disabled={
                          geometry.length === 0 ||
                          isOpenSimulation ||
                          !(mission && mission.date) ||
                          days.error
                        }
                        icon="tick"
                        intent="success"
                        onClick={this.handleContinue}
                      >
                        Continue
                      </Button>
                    </Tooltip>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </DefaultLayout>
        {isOpenSimulation &&
          this.renderCompute(
            user,
            mission,
            map.getAstrum(),
            Number(mission.days),
            geometry,
            pointsOfInterest,
            regionsOfInterest,
            (): void => this.setState({ isOpenSimulation: false }),
            (data: object) => this.handleOnComplete(data),
            addSO,
            updateSO,
            removeAllSO,
            satelliteOrbits,
            results
          )}
        {isOpenOptimizationResult &&
          this.renderOptimize((): void =>
            this.setState({ isOpenOptimizationResult: false })
          )}
        <PopUp />
      </>
    );
  }
}

export default connect<IStateProps, IDispatchProps>(
  (state: AppState): IStateProps => ({
    mission: state.missions.find((mission) => mission.current),
    user: state.user,
    geometry: state.geometry,
    goals: state.goals,
    pointsOfInterest: state.pointsOfInterest,
    regionsOfInterest: state.regionsOfInterest,
    groups: state.groups,
    satelliteOrbits: state.satelliteOrbits,
    results: state.results.msd.missionGeometryPage.data,
    astrums: state.astrum,
    visiblePopUp: state.popUp && state.popUp.visible,
    focus: state.focus,
  }),
  (
    dispatch: ThunkDispatch<AppState, null, Action<string>>
  ): IDispatchProps => ({
    getMissions: (): void => dispatch(getMissionsThunk()),
    updateMission: (mission: IMission): void =>
      dispatch(updateMissionThunk(mission)),
    updateGroupSB: (group: IGroup): void => dispatch(updateGroupSB(group)),
    uploadData: (data: IData, accessNames: string[]): void =>
      dispatch(uploadData(data, accessNames)),
    setPopUp: (popUp: IPopUp): void => dispatch(setPopUp(popUp)),
    setFocus: (focus: IFocus): void => dispatch(setFocus(focus)),
    addGeometry: (geometry: IGeometryObject): void =>
      dispatch(addGeometry(geometry)),
    updateGeometry: (geometry: IGeometryObject): void =>
      dispatch(updateGeometry(geometry)),
    removeGeometry: (geometry: IGeometryObject): void =>
      dispatch(removeGeometry(geometry)),

    addGoal: (goalObject: IGoal): void => dispatch(addGoal(goalObject)),
    updateGoal: (goalObject: IGoal): void => dispatch(updateGoal(goalObject)),
    removeGoal: (goalObject: IGoal): void => dispatch(removeGoal(goalObject)),
    addSO: (satelliteOrbit: ISatelliteData): void =>
      dispatch(addSO(satelliteOrbit)),
    updateSO: (satelliteOrbit: ISatelliteData): void =>
      dispatch(updateSO(satelliteOrbit)),
    removeAllSO: (): void => dispatch(removeAllSO()),
    updateResultsThunk: (hash: IAnyKey, data: object): void =>
      dispatch(updateResultsThunk(RESULTS_PATH, hash, data, undefined)),

    updatePI: (pointOfInterest: IPointOfInterest): void =>
      dispatch(updatePI(pointOfInterest)),
    removePI: (id: string): void => dispatch(removePI(id)),

    updateRI: (regionOfInterest: IRegionOfInterest): void =>
      dispatch(updateRI(regionOfInterest)),
    removeRI: (id: string): void => dispatch(removeRI(id)),
    updateAstrumThunk: (astrum: IAstrum) =>
      dispatch(updateAstrumThunk(astrum, false, pageKey)),
  })
)(withTranslation()(withRouter(MissionGeometryPage)));
