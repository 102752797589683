import { toaster } from 'toaster';

import { SHOW_TOASTER_ON_TYPE_ASSERT_ERROR } from 'env';

export type ClientHeaders = {
  'Content-Type'?: 'application/json' | 'multipart/form-data';
  Authorization?: string;
};

export type ClientMethod = 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH';

export type EmptyResponse = undefined;
export type EmptyParams = {};
export type EmptyBody = undefined;

export type RequestError = string;

export type HandlerState<Data, TransformedData> = {
  success: boolean;
  status?: number;
  errors: RequestError[];
  data?: Data;
  /**
   * transformedData is the data that has been transformed by the responseTransformer
   * function.
   *
   * If no responseTransformer function has been provided, this will be undefined.
   */
  transformedData?: TransformedData | undefined;
};

export type ApiHandlerDataType =
  | { [key: string]: unknown }
  | string
  | number
  | undefined
  | null
  | unknown[]
  | Blob
  | ArrayBuffer;

export type HandlerOptions<Data, Params, Body, TransformedData> = {
  host: string;
  endpoint: string | ((params: Params) => string);
  assert?: (data: Data) => void; // A custom function that can throw after performing validation on the data response
  method: ClientMethod;
  errorMessage: string;
  /**
   * If provided, error toast will be composite
   * and this description will be included in the details section
   * Exposes errors string array to be handled as an error message if desired
   * @param errors errors string array from the api response
   */
  errorDescription?: string | ((errors: string[]) => string);
  onError?: (context: {
    state: HandlerState<Data, TransformedData>;
    params: Params;
    body: Body;
  }) => boolean | void;
  onSuccess?: (data: Data | undefined) => void;
  /**
   * If set to true, the API handler returns the complete response object. If not (which is default),
   * the API handler returns response.data
   */
  omitCaptureDataKey?: boolean;
  /**
   * Specify the content type of the request, if the overwrite is needed.
   * Default is 'application/json'
   */
  contentType?: ClientHeaders['Content-Type'];
  /*
    responseTransformer is a function that can be used to transform the response data
    before it is returned to the caller. This can be useful for example to transform
    a response from a server into a format that is more convenient for the client.
   
    When the responseTransformer is used, the transformed data will be returned to the caller
    alongside the original data as a `transformedData` property in the response object.
    @param response - The response data from the server
    @param params - The parameters that were passed to the API handler
    @returns The transformed response data
   */
  responseTransformer?: (
    response: Data | undefined,
    params: Params
  ) => TransformedData | undefined;
};

/**
 * RequestClientArgs are the arguments from the RequestClientFunction implementation for a particular
 * request client. A particular implementation might use additional arguments.
 */
interface RequestClientArgs<Params, Body> {
  params?: Params;
  body?: Body;
  /**
   * If set to true, the API handler will abort all but the last request
   * if many requests are made in a short period of time.
   */
  abortable?: boolean;
}

/**
 * RequestClientFunction is the custom request client implementation that must be passed to genericApiHandler.
 */
type RequestClientFunction<
  Data extends ApiHandlerDataType,
  Args extends RequestClientArgs<Params, Body>,
  Params extends {},
  Body extends {} | undefined
> = (options: {
  url: string;
  method: ClientMethod;
  options: Args;
}) => RequestClientResponse<Data>;

/**
 * RequestClientResponse is the response that must be returned by the custom request client implementation.
 */
type RequestClientResponse<Data extends ApiHandlerDataType> = Promise<{
  response: Data;
  status: number;
  ok: boolean;
}>;

/**
 * genericApiHandler must be used by custom implementations of apiHandler who pass their
 * own implementation of RequestClientFunction. Those implementations might use different request clients
 * such as axios, fetch or others.
 *
 * Such fetch functions, must use at least the arguments from RequestClientArgs, however, they can
 * also use addition arguments if needed.
 *
 * It returns a handler function that accepts `body` and `params`
 * as well as optional `onDownloadProgress` and `onUploadProgress` trackers as its arguments
 * if `client` has been set to `axios`
 *
 * @returns Handler function promise
 */
export function genericApiHandler<
  Data extends ApiHandlerDataType,
  Args extends RequestClientArgs<Params, Body>,
  Params extends {},
  Body extends {} | undefined,
  TransformedData extends ApiHandlerDataType | undefined,
  FetchFunction extends RequestClientFunction<Data, Args, Params, Body>
>(
  options: HandlerOptions<Data, Params, Body, TransformedData>,
  fetchFunction: FetchFunction
) {
  return async (args: Parameters<FetchFunction>[0]['options']) => {
    const state: HandlerState<Data, TransformedData> = {
      status: undefined,
      errors: [],
      data: undefined,
      success: true,
      transformedData: undefined,
    };

    let endpoint = '';
    if (typeof options.endpoint === 'string') {
      endpoint = options.endpoint;
    } else if (args.params) {
      endpoint = options.endpoint(args.params);
    }

    const url = options.host + endpoint;

    const errorHandler = (
      error: unknown,
      toasterMessage = options.errorMessage,
      showToaster = true
    ) => {
      if (args.abortable && (error as { name: string }).name === 'AbortError') {
        return;
      }

      if (!error) return;

      const hasMessage = Boolean(
        Object.prototype.hasOwnProperty.call(error, 'message')
      );

      const stringError = hasMessage
        ? (error as { message: string }).message
        : JSON.stringify(error);

      state.errors.push(stringError);

      if (options.onError) {
        const handled = options.onError({
          state,
          params: args.params!,
          body: args.body!,
        });
        // If the error was handled then there is no need to display the error message
        if (handled) return;
      }

      if (showToaster) {
        if (options.errorDescription) {
          toaster.showCompositeToast({
            title: toasterMessage,
            description:
              typeof options.errorDescription === 'string'
                ? options.errorDescription
                : options.errorDescription(state.errors),
            showCopyButton: true,
            icon: 'issue',
            intent: 'danger',
          });
        } else {
          toaster.show({
            message: toasterMessage,
            icon: 'issue',
            intent: 'danger',
          });
        }
      }

      // eslint-disable-next-line no-console
      console.error(error);

      state.success = false;
    };

    try {
      const { response, status } = await fetchFunction({
        method: options.method,
        url,
        options: { ...args, contentType: options.contentType },
      });

      state.status = status;

      if (Array.isArray(response)) {
        state.data = response;
      } else {
        const hasDataProperty = Boolean(
          Object.prototype.hasOwnProperty.call(response ?? {}, 'data')
        );

        state.data = (
          hasDataProperty && !options?.omitCaptureDataKey
            ? (response as { data: unknown }).data
            : response
        ) as Data;

        const hasErrorsProperty =
          Boolean(
            Object.prototype.hasOwnProperty.call(response ?? {}, 'errors')
          ) && Array.isArray((response as { errors: unknown }).errors);

        if (hasErrorsProperty) {
          (response as { errors: unknown[] }).errors.forEach((error) => {
            errorHandler(error);
          });
        }
      }
    } catch (error) {
      errorHandler(error);
    }

    if (options.responseTransformer) {
      state.transformedData = options.responseTransformer(
        state.data,
        args.params ?? ({} as Params)
      );
    }

    if (options.assert && state.data) {
      try {
        options.assert(state.data);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('Failed to assert data:', state.data);
        if (SHOW_TOASTER_ON_TYPE_ASSERT_ERROR) {
          errorHandler(
            error,
            `Type error assertion fetching data from ${endpoint} endpoint`
          );
        } else {
          errorHandler(error, undefined, false);
        }
      }
    }

    if (options.onSuccess && state.success) {
      options.onSuccess(state.data);
    }

    return state;
  };
}
