import type { ISummaryChildren } from '../../constants/summary/constants';
import React from 'react';
import { Icon } from '@blueprintjs/core';
import type { ITreeNodeCustom } from '../../declarations/blueprintjs';

export const getMenu = (
  props: ISummaryChildren,
  id: string,
  handleClick: Function
): ITreeNodeCustom => {
  const childNodes = props.children.map(
    (c, index): ITreeNodeCustom => getMenu(c, `${id}.${index}`, handleClick)
  );
  // const href = id.split('.').map(s => +s + 1).join('.');
  const href = props.title;
  return {
    label: <a href={`#${href}`}>{props.title}</a>,
    title: props.title,
    id: id,
    isExpanded: true,
    childNodes: childNodes.length ? childNodes : undefined,
    secondaryLabel: (
      <Icon
        className="bp4-button bp4-minimal"
        icon="eye-open"
        onClick={(): void => handleClick(id)}
      />
    ),
  };
};
