// Check https://wiki.o-c.space/software:piu:tm-log-format for the format

const HEADER_SIZE_BYTES = 16;
const HEADER_TIME_INDEX = 0;
const HEADER_COMPONENTS_INDEX = 8;
const HEADER_C_SIZE_INDEX = 10;
const HEADER_TMID_INDEX = 12;

const POINT_TIME_SIZE = 2;
const POINT_VARIABLESIZE_SIZE = 4;

const SIZE_VARIABLE = 0;

type header = {
  timestamp: Date;
  nComponents: number;
  pointSize: number;
  tmId: number;
};

type point = {
  timestamp: Date;
  values: ArrayBuffer[];
};

export class TelemetryFile {
  private data: DataView;
  private header: header;
  private currentIndex: number;
  private tmSegmentSize: number;

  constructor(data: DataView) {
    this.data = data;
    this.header = this.parseHeader();
    this.setIndexAfterHeader();
  }

  private parseHeader = (): header => {
    return {
      timestamp: new Date(
        Number(this.data.getBigUint64(HEADER_TIME_INDEX, false)) * 1000
      ),
      nComponents: this.data.getUint16(HEADER_COMPONENTS_INDEX, false),
      pointSize: this.data.getUint16(HEADER_C_SIZE_INDEX, false),
      tmId: this.data.getUint32(HEADER_TMID_INDEX, false),
    };
  };

  private setIndexAfterHeader = () => {
    this.currentIndex = HEADER_SIZE_BYTES;
  };

  public getHeader = (): header => {
    return this.header;
  };

  public parseNextPoint = (): point | false => {
    try {
      return this.tryParsingNextPoint();
    } catch {
      return false;
    }
  };

  private progressToNextPoint = () => {
    this.currentIndex += POINT_TIME_SIZE;

    let size = this.header.pointSize;
    if (size === SIZE_VARIABLE) {
      this.tmSegmentSize = this.data.getUint32(this.currentIndex, false);
      this.currentIndex += POINT_VARIABLESIZE_SIZE;
    }
  };

  private processAndGetTelemetryValues = (): ArrayBuffer[] => {
    let values = [];

    for (let i = 0; i < this.header.nComponents; i++) {
      values[i] = this.data.buffer.slice(
        this.currentIndex,
        this.currentIndex + (this.tmSegmentSize ?? this.header.pointSize)
      );
      this.currentIndex += this.header.pointSize + (this.tmSegmentSize ?? 0);
    }

    return values;
  };

  private tryParsingNextPoint = (): point => {
    const timeDelta = this.data.getUint16(this.currentIndex, false);
    const timestamp = new Date(
      timeDelta * 1000 + this.header.timestamp.getTime()
    );

    this.progressToNextPoint();
    const values = this.processAndGetTelemetryValues();

    return {
      timestamp,
      values,
    };
  };
}
