import type { GatewayNetworkConfig, Schema } from 'api/gateway/types';
import CommandArguments from 'components/CommandArguments';
import { ops } from 'constants/mixpanelAnalytics';
import { assoc } from 'lodash/fp';
import React, { useEffect, useState } from 'react';
import { useAnalytics } from 'utils/hooks/analytics/useAnalytics';
import parseRefDefinitions from 'utils/rtiCommands/parseRefDefinitions';

interface IProps {
  configuration: any;
  protocol: string;
  missionId: number;
  schema: Schema;
  handleProtocolEdit: (gateway: GatewayNetworkConfig) => void;
  protocolToEdit: GatewayNetworkConfig;
}

const LibraryProtocolSettings = ({
  configuration,
  protocol,
  schema,
  missionId,
  handleProtocolEdit,
  protocolToEdit,
}: IProps) => {
  const [parsedSchemas, setParsedSchemas] = useState<Schema | null>(null);

  const { sendInfo } = useAnalytics();

  const handleSettingsChange = (path: string, value: any) => {
    const modifiedProtocol: GatewayNetworkConfig = {
      ...protocolToEdit,
      protocol_config: assoc(path, value, configuration),
    };
    handleProtocolEdit(modifiedProtocol);

    sendInfo({
      type: ops.LIBRARY.PROTOCOL.CHANGE,
      module: 'OPS',
      action: 'Change',
      item: 'Library protocol',
      additionalParams: { mission: missionId },
    });
  };

  useEffect(() => {
    if (schema) {
      const parsed = {
        ...schema,
        schema: parseRefDefinitions(schema.schema),
      };

      setParsedSchemas(parsed as Schema);
    }
  }, [schema]);

  return (
    <>
      <span style={{ fontSize: '45px' }}>Protocol configuration</span>

      <div style={{ marginTop: '15px', marginBottom: '15px' }}>
        <span style={{ fontSize: '18px' }}>Protocol: {protocol}</span>
      </div>

      <div style={{ paddingBottom: '100px' }}>
        {parsedSchemas && (
          <CommandArguments
            commandProperties={parsedSchemas.schema}
            commandPayload={configuration}
            handleCommandPayloadChange={handleSettingsChange}
            disabled={false}
            canSetDefaultValue={true}
          />
        )}
      </div>
    </>
  );
};

export default LibraryProtocolSettings;
