import type {
  IResults,
  IResultsActionTypes,
} from '../constants/results/actionTypes';
import {
  RESULTS_RESET,
  RESULTS_UPDATE,
} from '../constants/results/actionTypes';

const initialState: IResults = {
  msd: {
    payloadModePage: {
      deps: ['msd.platformPage', 'msd.finalPage'],
      depsHash: null,
      hash: '',
      data: null,
    },
    missionGeometryPage: {
      deps: ['msd.groundSegmentPage', 'msd.platformPage', 'msd.finalPage'],
      depsHash: null,
      hash: '',
      data: null,
    },
    groundSegmentPage: {
      deps: ['msd.platformPage', 'msd.finalPage'],
      depsHash: null,
      hash: '',
      data: null,
    },
    platformPage: {
      deps: ['msd.finalPage'],
      depsHash: null,
      hash: '',
      data: null,
    },
    finalPage: {
      deps: [],
      depsHash: null,
      hash: '',
      data: null,
    },
  },
};

export default function resultsReducer(
  state: IResults = initialState,
  action: IResultsActionTypes
): IResults {
  switch (action.type) {
    case RESULTS_UPDATE: {
      return { ...state, ...action.results };
    }
    case RESULTS_RESET: {
      return { ...initialState };
    }
    default: {
      return state;
    }
  }
}
