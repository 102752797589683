import { Button, Intent } from '@blueprintjs/core';
import { DATETIME_FORMAT } from 'constants/datetime';
import moment from 'moment';
import type { TaskingRequest } from 'api/tasking/types';
import {
  CompositeDetailsItem,
  DetailsItem,
} from 'pages/ops/Scheduling/ScheduleActivityDetails/DetailCards/DetailsItem';
import UserInfo from '_organisms/UserInfo/UserInfo';
import { useQuery } from 'api/useQuery';
import { getUser } from 'api/users/service';
import Textarea from '_atoms/Textarea/Textarea';

type RequestDetailsCardProps = {
  request: TaskingRequest | undefined;
  totalActivities: number;
  cancelRequest: () => Promise<void>;
};

export const RequestDetailsCard = ({
  request,
  totalActivities,
  cancelRequest,
}: RequestDetailsCardProps) => {
  const getUserQuery = useQuery(getUser, {
    initialData: undefined,
    params: request ? { subject: request.created_by } : undefined,
  });

  if (!request) {
    return <div>NO REQUEST</div>;
  }
  return (
    <div className="flex flex-col gap-1">
      <div className="flex items-center justify-between">
        <h2>Request</h2>
        <Button
          text="Cancel"
          className="w-32 h-5"
          intent={Intent.DANGER}
          onClick={() => {
            void cancelRequest();
          }}
          disabled={request.status === 'CANCELLED'}
        />
      </div>

      <div className="flex flex-col justify-between gap-3">
        <DetailsItem title="ID: " value={request.id} />
        <DetailsItem title="Project ID: " value={request.project_id} />
        <DetailsItem
          title="Created At: "
          value={moment.utc(request.created_at).format(DATETIME_FORMAT)}
        />
        <DetailsItem
          title="Updated At: "
          value={moment.utc(request.updated_at).format(DATETIME_FORMAT)}
        />
        <DetailsItem title="Type: " value={request.type} />
        <DetailsItem title="Status: " value={request.status} />
        <DetailsItem
          title="Number of Activities: "
          value={totalActivities.toString()}
        />
        <DetailsItem title="Region Name: " value={request.region_name} />
        <CompositeDetailsItem
          title="Customer: "
          element={
            <UserInfo
              user={getUserQuery.data}
              loading={getUserQuery.loading}
              fieldsToShow={['email']}
            />
          }
        />
        <CompositeDetailsItem
          title="Notes: "
          element={<Textarea disabled value={request.notes} />}
        />
      </div>
    </div>
  );
};
