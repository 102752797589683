import type { OZAConstraints, SZAConstraints } from 'api/tasking/types';
import RangeSlider from '_molecules/RangeSlider/RangeSlider';
import Icon from '_atoms/Icon/Icon';
import IconButton from '_molecules/IconButton/IconButton';
import InfoCard from '_molecules/InfoCard/InfoCard';
import { useLocalisation } from 'utils/hooks/useLocalisation';
import classNames from 'classnames';

export type ObjectivesProps = {
  setSZAConstraints: (SZAConstraints: SZAConstraints) => void;
  SZAConstraints: SZAConstraints;
  OZAConstraints: OZAConstraints;
  setOZAConstraints: (OZAConstraints: OZAConstraints) => void;
  isSZAObjective: boolean;
  setIsSZAObjective: (isSZA: boolean) => void;
  setIsOZAObjective: (isOZA: boolean) => void;
  isOZAObjective: boolean;
  isOpen: boolean;
  setIsOpen: (opn: boolean) => void;
  getNoOfActiveObjectives: () => number;
  disabled: boolean;
};

export const Objectives = ({
  setSZAConstraints,
  SZAConstraints,
  OZAConstraints,
  setOZAConstraints,
  isSZAObjective,
  setIsSZAObjective,
  setIsOZAObjective,
  isOZAObjective,
  isOpen,
  setIsOpen,
  getNoOfActiveObjectives,
  disabled,
}: ObjectivesProps) => {
  const { translate } = useLocalisation();

  const handleSZAObjectiveChange = (values: number[]) => {
    setSZAConstraints({
      ...SZAConstraints,
      Objective: values[0],
    });
  };

  const handleOZAObjectiveChange = (values: number[]) => {
    setOZAConstraints({
      ...OZAConstraints,
      Objective: values[0],
    });
  };

  const getValues = () => {
    return {
      sza: [SZAConstraints.Objective].filter(
        (value) => value !== null
      ) as number[],
      oza: [OZAConstraints.Objective].filter(
        (value) => value !== null
      ) as number[],
    };
  };

  const areConstraintsApplied = getNoOfActiveObjectives() !== 0;

  const SZA_DESC = translate('datacosmos.tooltips.tasking.automated.sza');
  const OZA_DESC = translate('datacosmos.tooltips.tasking.automated.oza');
  const OBJ_DESC = translate('datacosmos.tooltips.tasking.automated.objective');

  return (
    <div className="w-full">
      <InfoCard
        cardContent={
          <div className="flex items-center gap-2">
            <span>{translate('datacosmos.tasking.new.objectives.title')}</span>
            {areConstraintsApplied && (
              <span className="-right-4 -top-3 rounded-full bg-surface dark:bg-item-dark dark:text-item-dark-contrast text-xs  h-4 w-4 text-center border-[1px] border-item-contrast">
                {getNoOfActiveObjectives()}
              </span>
            )}
          </div>
        }
        showInfoOn="hover"
        info={{
          iconSize: 16,
          text: OBJ_DESC,
        }}
        onClick={() => {
          setIsOpen(isOpen);
        }}
        className={classNames('text-sm w-full', {
          'text-item-contrast-inactive dark:text-item-dark-contrast-inactive !cursor-not-allowed':
            disabled,
          'bg-item-selected dark:bg-item-dark-selected': isOpen,
        })}
        absoluteInfoPosition
      />

      {isOpen && !disabled && (
        <div
          className="absolute w-80 bg-item flex flex-col gap-2 p-1 dark:bg-item-dark dark:text-item-dark-contrast"
          style={{
            left: '445px',
            top: '17rem',
          }}
        >
          <div className="p-2 flex justify-between items-center border-b-2 border-b-contrast-inactive">
            <span>{translate('datacosmos.tasking.new.objectives.title')}</span>
            <IconButton
              icon="Collapse"
              onPress={() => {
                setIsOpen(isOpen);
              }}
              size={24}
            />
          </div>

          <div className="flex-col w-full">
            <div className="flex items-center w-full">
              <div className="flex items-center w-full gap-4">
                <InfoCard
                  showInfoOn="hover"
                  cardContent={
                    <div className="flex items-center w-full gap-4">
                      <Icon icon="Angle" />
                      <span>
                        {translate('datacosmos.tasking.new.objectives.oza')}
                      </span>
                    </div>
                  }
                  info={{ text: OZA_DESC, iconSize: 16 }}
                  absoluteInfoPosition
                  onClick={() => {}}
                  className="hover:bg-transparent"
                />
              </div>
              <IconButton
                icon={isOZAObjective ? 'Trash' : 'PlusSquare'}
                onPress={() => {
                  if (isOZAObjective) {
                    setIsOZAObjective(false);
                  } else {
                    setIsOZAObjective(true);

                    setOZAConstraints({
                      ...OZAConstraints,
                      Objective: null,
                      Active: true,
                    });
                  }
                }}
                data-testid="reset-oza-filter-btn"
              />
            </div>
            {isOZAObjective && (
              <RangeSlider
                minValue={0}
                maxValue={180}
                numberOfHandles={1}
                onChange={(values) => {
                  handleOZAObjectiveChange(values);
                }}
                showValuesAboveHandles
                step={0.1}
                value={getValues().oza}
              />
            )}
          </div>

          <div className="flex-col w-full">
            <div className="flex items-center w-full">
              <div className="flex items-center w-full gap-4">
                <InfoCard
                  showInfoOn="hover"
                  cardContent={
                    <div className="flex items-center w-full gap-4">
                      <Icon icon="SunAngle" />
                      <span>
                        {translate('datacosmos.tasking.new.objectives.sza')}
                      </span>
                    </div>
                  }
                  info={{ text: SZA_DESC, iconSize: 16 }}
                  absoluteInfoPosition
                  onClick={() => {}}
                  className="hover:bg-transparent"
                />
              </div>
              <IconButton
                icon={isSZAObjective ? 'Trash' : 'PlusSquare'}
                onPress={() => {
                  if (isSZAObjective) {
                    setIsSZAObjective(false);
                  } else {
                    setIsSZAObjective(true);

                    setSZAConstraints({
                      ...SZAConstraints,
                      Objective: null,
                    });
                  }
                }}
                data-testid="reset-oza-filter-btn"
              />
            </div>
            {isSZAObjective && (
              <RangeSlider
                minValue={0}
                maxValue={180}
                numberOfHandles={1}
                onChange={(values) => {
                  handleSZAObjectiveChange(values);
                }}
                showValuesAboveHandles
                step={0.1}
                value={getValues().sza}
              />
            )}
          </div>
        </div>
      )}
    </div>
  );
};
