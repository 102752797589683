import {
  finishHistoryCommandList,
  lastHistoryCommandReached,
} from 'actions/operate/actions';
import type { ICommandHistory, IReply } from 'utils/hooks/useCommandHistory';
import type { ITransformResponseMappingBody } from './index';
import { STATUS_SUCCESS } from 'constants/ops/rti/oparate/constants';
import type { CommandHistoryCommand } from 'api/telecommands/types';

export const handleHistoryCommandList = (
  dispatch: Function,
  handleNewHistoryCommandsAndReplies: Function
): ITransformResponseMappingBody => ({
  validator: (data: IReply) => {
    if (!data.data) {
      return;
    }

    if (!data.data.length) {
      dispatch(lastHistoryCommandReached());
      dispatch(finishHistoryCommandList());
    }
  },
  transformer: (data) => {
    const d = data as CommandHistoryCommand[];

    return d.map((item) => {
      const parsedMessage = item.message;

      if (item.type === 'response') {
        return {
          sequenceId: item.uuid,
          data: parsedMessage,
          links: null,
          meta: null,
          type: item.message_type,
          createdAt: item.created_at,
        };
      }

      // Otherwise is a request (item.type === 'request')
      const status = STATUS_SUCCESS;
      const payload = parsedMessage;
      const command = item.message_type;

      return {
        command,
        status,
        payload,
        createdAt: item.created_at,
        sequenceId: item.uuid,
        responsesContent: [],
        note: item.note,
        subjectID: item.subject_id,
        procedureName: item.procedure_name,
        customCommandName: item.custom_command_name,
        satelliteSequenceID: item.sequence_id,
        uuid: item.uuid,
      };
    });
  },
  handler: (data) => {
    const d = data as ICommandHistory[];
    const commands = d.filter((item) => Boolean(item.command));
    const replies = d.filter((item) => !item.command);

    handleNewHistoryCommandsAndReplies(commands, replies);

    dispatch(finishHistoryCommandList());
  },
});
