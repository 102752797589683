import type { ReactElement } from 'react';
import React from 'react';

/**
 * Displayed when there is no matching route
 */
const NoPermissions = (): ReactElement => (
  <div className="notfound">
    <h1 className="h1">
      {'You do not have required permissions to visit this page'}
    </h1>
  </div>
);

export default NoPermissions;
