import classNames from "classnames";
import { useRef } from "react";
import {
  AriaTabListOptions,
  mergeProps,
  useFocusRing,
  useTab,
  useTabList,
  useTabPanel,
} from "react-aria";
import type { TabListState } from "react-stately";
import { useTabListState } from "react-stately";
import type { Node } from "@react-types/shared/src/collections";

type Props = AriaTabListOptions<object> & {
  children: JSX.Element | JSX.Element[];
  className?: string;
  customPadding?: string;
  selectedItemKey?: string;
};

type TabProps = {
  item: Node<object>;
  state: TabListState<any>;
  isSelected: boolean;
};

type TabListProps = {
  state: TabListState<any>;
  customPadding?: string;
};

const Tab = ({ item, state, isSelected }: TabProps) => {
  const ref = useRef(null);
  const { tabProps } = useTab(item, state, ref);

  return (
    <div
      {...tabProps}
      ref={ref}
      className={classNames("cursor-pointer w-full mb-4", {
        "border-b-4 border-b-accent-900 text-accent-900 dark:border-b-item-dark-hover dark:text-item-dark-hover":
          isSelected,
        "border-b-4 border-b-item-contrast-inactive text-item-contrast-inactive":
          !isSelected,
      })}
    >
      {item.rendered}
    </div>
  );
};

const TabPanel = ({ state, customPadding }: TabListProps) => {
  const ref = useRef(null);
  const { tabPanelProps } = useTabPanel({}, state, ref);
  return (
    <div
      {...tabPanelProps}
      ref={ref}
      style={{
        maxHeight: "inherit",
        overflowY: "auto",
        paddingBottom: customPadding ?? "150px",
      }}
    >
      {state.selectedItem && state.selectedItem.props.children}
    </div>
  );
};

const Tabs = (props: Props) => {
  const state = useTabListState(props);
  const ref = useRef(null);
  const { tabListProps } = useTabList(props, state, ref);
  const { focusProps } = useFocusRing({
    within: true,
  });

  return (
    <div
      className={props.className}
      style={{
        maxHeight: "inherit",
      }}
    >
      <div
        {...mergeProps(tabListProps, focusProps)}
        ref={ref}
        className="flex justify-between gap-4 text-start w-full mb-4"
        style={{
          maxHeight: "inherit",
          overflowY: "auto",
        }}
      >
        {[...state.collection].map((item) => (
          <Tab
            key={item.key}
            item={item}
            state={state}
            isSelected={
              item.key === state.selectedItem?.key ||
              item.key === props.selectedItemKey
            }
          />
        ))}
      </div>
      <TabPanel
        key={state.selectedItem && state?.selectedItem.key}
        state={state}
        customPadding={props.customPadding}
      />
    </div>
  );
};

export default Tabs;
