import { useState } from 'react';
import type {
  IRole,
  IRoleAssignment,
} from '../../../services/api/portal/administration/api/types';
import AdministrationUserSelect from 'components/Administration/AdministrationUserSelect';
import UserListItem from 'components/Administration/AdministrationUsers/UserListItemWithQuery';
import { Button } from 'opencosmos-ui/src/core/Button/Button';

interface IProps {
  currentId?: number | string;
  availableRoles?: IRole[];
  roleAssignments?: { [userId: string]: IRoleAssignment[] };
  assignRole?: (userId: string, roleIds: number[]) => void;
  deleteRoleAssignments?: (
    userId: string,
    assignmentIds: number[],
    showDeletedMessage?: boolean
  ) => void;
}

const ResourceUsers = (props: IProps) => {
  const [isAddingUser, setAddingUser] = useState(false);

  return (
    <div className="mt-5 w-1/2 flex-2">
      <div className={`text-base font-bold mb-2 uppercase tracking-wide`}>
        Users
      </div>
      <ul>
        {props.roleAssignments &&
          Object.entries(props.roleAssignments).map((val) => {
            const [userId, roleAssignments] = val;
            return (
              props.currentId && (
                <UserListItem
                  key={userId}
                  userId={userId}
                  currentId={props.currentId as number}
                  availableRoles={props.availableRoles ?? []}
                  roleAssignments={roleAssignments}
                  assignRole={props.assignRole}
                  deleteRoleAssignments={props.deleteRoleAssignments}
                />
              )
            );
          })}
        {isAddingUser ? (
          <AdministrationUserSelect
            currentId={props.currentId as number}
            assignRole={props.assignRole}
            availableRoles={props.availableRoles ?? []}
            onCancel={() => setAddingUser(false)}
          />
        ) : (
          <Button
            text="Add User"
            icon={'Plus'}
            onPress={() => setAddingUser(true)}
          />
        )}
      </ul>
    </div>
  );
};

export default ResourceUsers;
