import type { EmptyParams } from 'api/client';
import { apiFetchHandler } from 'api/clientFetch';
import { hosts } from 'api/hosts';
import type { BBox } from 'geojson';
import { toaster } from 'toaster';
import { clientTranslate } from 'utils/hooks/useLocalisation';

export type PostGeoPDF = {
  metadata: {
    author: string;
    subject: string;
  };
  pages: {
    settings: {
      dpi: string;
      width: string;
      height: string;
    };
    content: {
      layers: {
        enabled: boolean;
        georeferencing: {
          bbox: BBox;
        };
        raster?: string;
      }[];
    };
  }[];
};

export const getGeoPdfFile = apiFetchHandler<Blob, EmptyParams, PostGeoPDF>({
  host: hosts.data.v1,
  endpoint: '/geopdf/generate',
  method: 'POST',
  errorMessage: 'Cannot get GeoPDF file',
  errorDescription: (e) => e.join('; '),
});

export type PostNetCDFParams = {
  projectId: string;
};

export type PostNetCDFBody = FormData;

// TODO: Update response Data type when known
export const postNetCDFFile = apiFetchHandler<
  undefined,
  PostNetCDFParams,
  PostNetCDFBody
>({
  host: hosts.data.v1,
  contentType: 'multipart/form-data',
  method: 'POST',
  endpoint: ({ projectId }) => `/geopdf/upload/${projectId}`,
  errorMessage: clientTranslate(
    'datacosmos.fetchErrors.geopdf.postNetCDFFile.cannotPost'
  ),
  onSuccess: () => {
    toaster.show({
      message: clientTranslate(
        'datacosmos.fetchSuccesses.postNetCDFFile.successfullyUploaded'
      ),
      intent: 'success',
    });
  },
});
