import type { IHistogramData } from 'datacosmos/utils/hooks/useStacInfo';
import { get } from 'lodash';
import type { ICOGMetadataStatistics, ICOGMetadata } from '.';

export interface COGMetadataForRange {
  statistics: Object | undefined;
  meanValue(): number | null;
  stdValue(): number | null;
  maximumPixelValue(): number | null;
  minimumPixelValue(): number | null;
  varianceValue(): number | null;
  percentile25Value(): number | null;
  percentile75Value(): number | null;
  histogramData: () => IHistogramData[] | null;
}

// Creates an empty COGMetadata object (with null metadata)
export const emptyCOGMetadataForRange = function (): COGMetadataForRange {
  return {
    statistics: {},
    meanValue: () => null,
    maximumPixelValue: () => null,
    stdValue: () => null,
    minimumPixelValue: () => null,
    varianceValue: () => null,
    histogramData: () => null,
    percentile75Value: () => null,
    percentile25Value: () => null,
  };
};

export class NonEmptyCOGMetadataForRange implements COGMetadataForRange {
  statistics: ICOGMetadataStatistics | undefined;
  metadata: ICOGMetadata;

  constructor(statistics: ICOGMetadataStatistics, metadata: ICOGMetadata) {
    this.statistics = statistics;
    this.metadata = metadata;
  }

  //mean value
  meanValue(): number | null {
    let mean = 0;
    for (let i = 1; i < 11; i++) {
      const meanForBand = get(
        this.statistics,
        [`b${i}`, 'mean'],
        null
      ) as number;
      if (meanForBand !== null) {
        mean = Number(Math.max(mean, meanForBand).toFixed(2));
      }
    }
    if (mean === 0) {
      return null;
    }
    return mean;
  }

  //standard deviation value
  stdValue(): number | null {
    let std = 0;
    for (let i = 1; i < 11; i++) {
      const stdForBand = get(this.statistics, [`b${i}`, 'std'], null);
      if (stdForBand !== null) {
        std = Number(Math.max(std, stdForBand).toFixed(2));
      }
    }
    if (std === 0) {
      return null;
    }
    return std;
  }

  ///variance value
  varianceValue(): number | null {
    let variance = 0;
    for (let i = 1; i < 11; i++) {
      const stdForBand = get(this.statistics, [`b${i}`, 'std'], null);
      if (stdForBand !== null) {
        variance = Number((stdForBand * stdForBand).toFixed(2));
      }
    }
    if (variance === 0) {
      return null;
    }
    return variance;
  }

  //percentile 25 value
  percentile25Value(): number | null {
    let percentile_25 = 0;
    for (let i = 1; i < 11; i++) {
      const percentile25ForBand = get(
        this.statistics,
        [`b${i}`, 'percentile_25'],
        null
      );
      if (percentile25ForBand !== null) {
        percentile_25 = Math.max(percentile_25, percentile25ForBand);
      }
    }
    if (percentile_25 === 0) {
      return null;
    }
    return percentile_25;
  }

  //percentile 75 value
  percentile75Value(): number | null {
    let percentile_75 = 0;
    for (let i = 1; i < 11; i++) {
      const percentile75ForBand = get(
        this.statistics,
        [`b${i}`, 'percentile_75'],
        null
      );
      if (percentile75ForBand !== null) {
        percentile_75 = Math.max(percentile_75, percentile75ForBand);
      }
    }
    if (percentile_75 === 0) {
      return null;
    }
    return percentile_75;
  }

  // If a "max" pixel value exists for the first band in this metadata, return it, else return null
  maximumPixelValue(): number | null {
    let maximum = 0;
    for (let i = 1; i < 11; i++) {
      const maxForBand = get(this.statistics, [`b${i}`, 'max'], null);
      if (maxForBand !== null) {
        maximum = Math.max(maximum, maxForBand);
      }
    }
    if (maximum === 0) {
      return null;
    }
    return maximum;
  }

  minimumPixelValue(): number | null {
    let minimum = Infinity;
    for (let i = 1; i <= 10; i++) {
      const minForBand = get(this.statistics, [`b${i}`, 'min'], null);

      if (minForBand !== null) {
        minimum = Math.min(minimum, minForBand);
      }
    }

    return minimum;
  }

  histogramData(): IHistogramData[] | null {
    const histogramData: IHistogramData[] = Array(11)
      .fill(null)
      .map((_, i) => {
        return Object.keys(this.statistics ?? {})
          .map((s, j) => {
            return {
              [get(this.metadata, ['colorinterp', `${j}`], 'unknown') + 'X']:
                get(
                  this.statistics,
                  [`b${j + 1}`, 'histogram', 1, `${i}`],
                  null
                ),

              [get(this.metadata, ['colorinterp', `${j}`], 'unknown') + 'Y']:
                get(
                  this.statistics,
                  [`b${j + 1}`, 'histogram', 0, `${i}`],
                  null
                ),
            };
          })
          .reduce((acc, value) => {
            acc = { ...acc, ...value };
            return acc;
          }, {});
      });
    return histogramData.filter((d) =>
      Object.keys(d).some((k) => k !== 'unknown')
    );
  }
}
