import React, { useState } from 'react';
import type { IconName } from '@blueprintjs/core';
import { Icon } from '@blueprintjs/core';
import s from './InputFileButton.module.scss';

interface IProps {
  id: string;
  disabled?: boolean;
  className?: string;
  text?: string;
  icon?: IconName;
  multiple?: boolean;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: () => void;
  onFocus?: () => void;
  onMouseOut?: () => void;
  onMouseOver?: () => void;
}

const FILE_INPUT_1 = 'fileInput-1';
const FILE_INPUT_2 = 'fileInput-2';

const InputFileButton = (props: IProps) => {
  const [fileInputRefresher, setFileInputRefresher] = useState(FILE_INPUT_1);
  const renderIcon = props.icon && <Icon icon={props.icon} />;

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    props.onChange(e);

    /* This is a super hack to make browser pick up the same file submitted twice */
    setFileInputRefresher(
      fileInputRefresher === FILE_INPUT_1 ? FILE_INPUT_2 : FILE_INPUT_1
    );
  };

  const renderFileInput = (key: string) => (
    <div
      key={key}
      className={[s.inputFileButton, props.className].join(' ')}
      onMouseOut={props.onMouseOut}
      onMouseOver={props.onMouseOver}
      onBlur={props.onBlur}
      onFocus={props.onFocus}
    >
      <label htmlFor={props.id}>
        <i className="fa fa-cloud-upload" />
        {renderIcon}
        {props.text}
      </label>
      <input
        id={props.id}
        type="file"
        disabled={props.disabled}
        multiple={props.multiple}
        onChange={handleOnChange}
      />
    </div>
  );

  return renderFileInput(fileInputRefresher);
};

export default InputFileButton;
