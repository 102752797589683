import React from 'react';
import type { CSSProperties, ReactElement } from 'react';
import type { ISatelliteBall } from '../../constants/satelliteBalls/actionTypes';
import { MODULE_STATE_STYLE } from './utils';

interface ISatelliteBallProps extends ISatelliteBall {
  handleChangeState: Function;
  ballsState: ISatelliteBall[];
  small?: boolean;
}

const SatelliteBall = (props: ISatelliteBallProps): ReactElement => {
  const { position, size, state } = props;

  const style: CSSProperties = {
    position: 'absolute',
    top: position.top,
    left: position.left,
    width: size.width,
    height: size.height,
  };

  let comingSoon = props.comingSoon ? 'satellite-ball-coming-soon' : '';

  return (
    <div
      role="link"
      tabIndex={0}
      style={style}
      className={`satellite-ball satellite-ball-small ${comingSoon} ${MODULE_STATE_STYLE[state]}`}
    />
  );
};

export default SatelliteBall;
