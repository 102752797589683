import React, { useEffect } from 'react';
import type { IFolder } from '../../../services/api/ops/cloudStorage';
import { pipe, split, last, get } from 'lodash/fp';
import { IMAGE_EXTENSIONS } from '../../../constants/fileExplorer/constants';
import type { IMode, IModeProps } from '..';
import { IconNames } from '@blueprintjs/icons';
import { isNil } from 'lodash';
import { byteArrayToBase64String } from 'utils/common/binnaryUtils';

const ImageMode: IMode<string> = ({
  fileDetails,
  renderedContent,
  setRenderedContent,
}: IModeProps<string>) => {
  useEffect(() => {
    if (fileDetails) {
      const { file, name } = fileDetails;

      if (!file) {
        return;
      }

      const imageType = pipe(split('.'), last, (it) =>
        get(it, IMAGE_EXTENSIONS)
      )(name);

      setRenderedContent(
        `data:${imageType};base64,${byteArrayToBase64String(file)}`
      );
    }
  }, [fileDetails, setRenderedContent]);

  return <img src={renderedContent} alt="preview" />;
};

ImageMode.isValid = (fileDetails: IFolder) => {
  if (fileDetails && !isNil(fileDetails.file)) {
    const imageType = pipe(split('.'), last, (it) => get(it, IMAGE_EXTENSIONS))(
      fileDetails.name
    );

    if (imageType !== undefined) {
      return true;
    }
  }
  return false;
};

ImageMode.isPreferred = (fileDetails: IFolder) => {
  return (
    Object.keys(IMAGE_EXTENSIONS).findIndex((ext) =>
      Boolean(fileDetails.name.includes(ext))
    ) !== -1
  );
};

// Not in use because it is not editable
ImageMode.saveContent = (_: string) => new Uint8Array();
ImageMode.isEditable = false;

ImageMode.icon = IconNames.MEDIA;

ImageMode.id = 'Image';

export default ImageMode;
