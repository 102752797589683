import { lazy, useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { find, has, get } from 'lodash/fp';
import { AnchorButton, Icon } from '@blueprintjs/core';
// import { Tooltip } from 'ui/Tooltip';
import { toFormattedDateTimeUTC } from 'utils/common/dateUtils';
import type {
  CommandHistoryPayload,
  ICommandHistory,
} from 'utils/hooks/useCommandHistory';
import { HISTORY_STATUS_COLOR_MAPPING } from 'constants/rtiCommands/constants';
import { STATUS_ERROR } from 'constants/ops/rti/oparate/constants';
import { useAuth } from 'services/auth/AuthWrapper';

import s from './index.module.scss';
import useUser from 'services/api/portal/administration/hook/useUser';
import type { IUser } from 'services/api/portal/administration/api/types';
import type { CommandDefinition } from 'api/telecommands/types';
import useSessionStorage from 'utils/hooks/useSessionStorage';

const Tooltip = lazy(() => import('opencosmos-ui/src/core/Tooltip/Tooltip'));

interface IProps {
  availableCommands: CommandDefinition[];
  history: ICommandHistory;
  currentHistoryItemId: number | string;
  handleCommandCopyToEditorClick: (
    command: string,
    payload: CommandHistoryPayload,
    commandCustomName: string | undefined
  ) => void;
  handleHistoryCommandClick: (reply: ICommandHistory) => void;
  isTablet?: boolean;
  style?: React.CSSProperties;
}

const ERROR_PATHS = [
  'data.postMessage.errorMessage',
  'data.error.message',
  'data.reason',
  'data.error',
  'responsesContent[0].data.error.message',
];

const printError = (reply: unknown) => {
  const specificPath = find((path) => has(path, reply), ERROR_PATHS);
  const message = get(specificPath ?? 'data', reply) as string;
  return JSON.stringify(message || reply);
};

const CommandHistoryItem = ({
  history,
  availableCommands,
  currentHistoryItemId,
  handleHistoryCommandClick,
  handleCommandCopyToEditorClick,
  style,
}: IProps) => {
  const { checkPermissions } = useAuth();
  const { getUsersById } = useUser();

  // Cache of command permissions for a particular mission
  const [cmdPermissions, setCmdPermissions] = useSessionStorage<{
    [mission: string]: { [cmdName: string]: boolean };
  }>('cmd_history_cmd_permissions', {});

  // User who sent the cmd
  const [user, setUser] = useState<
    (Omit<IUser, 'picture'> & { picture: string | JSX.Element }) | undefined
  >();

  const [isAllowedToOpenCommand, setIsAllowedToOpenCommand] =
    useState<boolean>(false);

  const { mission } = useParams<{ mission: string }>();

  const currentCommand = find(['name', history.command], availableCommands);

  const getUserWhoSentCmd = useCallback(async () => {
    if (!history.subjectID) {
      return;
    }

    if (history.subjectID?.toLowerCase().includes('@clients')) {
      return setUser({
        email: '',
        email_verified: true,
        id: history.subjectID,
        name: 'API Key',
        picture: <Icon icon="key" />,
      });
    }

    const u = await getUsersById(history.subjectID);
    setUser(u);
  }, [getUsersById, history.subjectID]);

  const renderErrorMessage = (commandHistory: ICommandHistory) => {
    const message = find(
      ['type', STATUS_ERROR],
      commandHistory.responsesContent
    );

    if (!message) return undefined;

    return (
      <div className={s.commandHistoryError}>
        <em>Error:</em> {printError(message)}
      </div>
    );
  };

  const commandHistoryItemContainerStyle =
    currentHistoryItemId === history.sequenceId
      ? [s.commandHistoryItem, s.commandHistoryItem__selected].join(' ')
      : s.commandHistoryItem;

  let iconColor = HISTORY_STATUS_COLOR_MAPPING.error;
  const colorKey = history.status as keyof typeof HISTORY_STATUS_COLOR_MAPPING;
  if (HISTORY_STATUS_COLOR_MAPPING[colorKey]) {
    iconColor = HISTORY_STATUS_COLOR_MAPPING[colorKey];
  }

  useEffect(() => {
    void getUserWhoSentCmd();
  }, [getUserWhoSentCmd]);

  useEffect(() => {
    const getOpenCmdPermission = async () => {
      if (cmdPermissions[mission]?.[currentCommand?.name ?? '']) {
        setIsAllowedToOpenCommand(
          cmdPermissions[mission][currentCommand?.name ?? '']
        );
        return;
      }

      const hasPerm = await checkPermissions({
        id: mission,
        type: 'mission',
        actionScope: currentCommand?.scope,
      });

      setCmdPermissions({
        ...cmdPermissions,
        [mission]: {
          ...cmdPermissions[mission],
          [currentCommand?.name ?? '']: hasPerm,
        },
      });
      setIsAllowedToOpenCommand(hasPerm);
    };

    if (!currentCommand?.scope) {
      return setIsAllowedToOpenCommand(false);
    }

    void getOpenCmdPermission();
  }, [
    checkPermissions,
    cmdPermissions,
    currentCommand?.name,
    currentCommand?.scope,
    mission,
    setCmdPermissions,
  ]);

  return (
    <div
      role="presentation"
      className={commandHistoryItemContainerStyle}
      onClick={() => handleHistoryCommandClick({ ...history })}
      style={style}
    >
      <div className={s.commandHistoryDetails}>
        {history.createdAt ? (
          <div className={s.commandHistoryDate}>
            {toFormattedDateTimeUTC(history.createdAt)}
          </div>
        ) : null}
        <div className={s.commandHistoryReplies}>
          <div>
            <Icon icon="chat" className={s.repliesHistory} />
            <span
              //@ts-expect-error
              name="repliesCount"
            >
              {history.responsesContent.length}
            </span>
          </div>
        </div>
        <div className={s.commandHistoryStatus}>
          <Icon iconSize={20} icon="symbol-circle" color={iconColor} />
        </div>
        <div className={s.commandHistoryName}>
          <span>
            {history.customCommandName ? `${history.customCommandName} - ` : ''}
          </span>
          <span
            //@ts-expect-error
            name="commandName"
            className={s.commandHistoryNameText}
          >
            {history.command}
          </span>
          <span>
            {history.procedureName ? ` (${history.procedureName})` : ''}
          </span>
        </div>
        {history.note && history.note !== '' ? (
          <div className={s.commandHistoryNotes}>
            <Tooltip content={history.note} delay={0}>
              <div>
                <Icon icon="comment" />
              </div>
            </Tooltip>
          </div>
        ) : null}

        <Tooltip
          content={
            user?.name ? `Sent by ${user?.name}` : 'Sent by unknown user'
          }
          delay={0}
          placement="left"
        >
          {typeof user?.picture === 'string' ? (
            <img
              src={user?.picture}
              className="h-8 w-8 block rounded-full mr-2"
            />
          ) : (
            <div className="flex h-8 w-8 rounded-full mr-2 items-center justify-center bg-neutral-400">
              {user?.picture}
            </div>
          )}
        </Tooltip>

        <div className={s.commandHistoryActionButton}>
          <Tooltip
            content="Copy to editor"
            placement="left"
            isDisabled={!isAllowedToOpenCommand}
            delay={0}
          >
            <AnchorButton
              className={isAllowedToOpenCommand ? s.activeButton : undefined}
              icon="send-to"
              disabled={!isAllowedToOpenCommand}
              onClick={(event) => {
                if (!history.payload) {
                  return;
                }
                event.preventDefault();
                event.stopPropagation();
                handleCommandCopyToEditorClick(
                  history.command,
                  history.payload,
                  history.customCommandName
                );
              }}
            />
          </Tooltip>
        </div>
      </div>
      {renderErrorMessage(history)}
    </div>
  );
};

export default CommandHistoryItem;
