import type { Action } from 'redux';
import type { ThunkAction } from 'redux-thunk';
import { uploadRegionsOfInterest } from 'actions/regionsOfInterest/action';
import type {
  IRegionOfInterest,
  IRIState,
} from 'constants/regionsOfInterest/actionTypes';
import type { AppState } from 'reducers/rootReducer';
import { setPolygonSpecify } from 'services/mapService/contextMenu/map/events';
import type { IDrawingManagerWrapper } from 'declarations/mapDeclarations/DrawinManager';
import { generateName } from 'utils/common/generateName';
import type { IOSMFetchResult } from 'declarations/osm';
import { convertGeoJSONtoPaths } from 'utils/OSM/convertGeoJSONtoPaths';
import { isAreaEnabled } from 'utils/OSM/isAreaEnabled';

export const uploadRI =
  (
    regionsOfInterest: IRIState
  ): ThunkAction<void, AppState, null, Action<string>> =>
  async (dispatch): Promise<void> => {
    dispatch(uploadRegionsOfInterest(regionsOfInterest));
  };

export const fetchRI =
  (
    result: IOSMFetchResult,
    drawingManager: IDrawingManagerWrapper,
    withPopUp?: boolean,
    id?: string
  ): ThunkAction<void, AppState, null, Action<string>> =>
  async (dispatch, state): Promise<void> => {
    const regionOfInterest = {
      name: generateName(state().regionsOfInterest, result.display_name),
      paths: convertGeoJSONtoPaths(result),
      id,
    };
    //TODO filter results
    regionOfInterest.paths = regionOfInterest.paths.filter((path) =>
      isAreaEnabled(path)
    );
    dispatch(
      setPolygonSpecify(
        drawingManager,
        regionOfInterest as IRegionOfInterest,
        withPopUp
      )
    );
  };
