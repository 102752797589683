import { useCallback, useState } from 'react';
import type { ClickedPoint } from '../useClickedStacItem';
import type { PixelValueWithWavelength } from 'api/sampling/service';
import { getSTACItemPixelsVsWavelength } from 'api/sampling/service';
import { useMapLayers } from 'datacosmos/stores/MapLayersProvider';
import { LayerSourceType } from 'datacosmos/entities/layer';
import { generateMarkerLayer } from './helpers';
import { useLocalisation } from 'utils/hooks/useLocalisation';

export const useSinglePixelVsWavelength = () => {
  const [isToggled, setIsToggled] = useState<boolean>(false);
  const [isFetching, setIsFetching] = useState<boolean>(false);

  const { removeLayersBySourceType, addLayer } = useMapLayers();
  const { translate } = useLocalisation();

  const [
    singlePixelVsWavelengthChartData,
    setSinglePixelVsWavelengthChartData,
  ] = useState<PixelValueWithWavelength[]>([]);

  const markClickedPoint = useCallback(
    (latLng: { lat: number; lng: number }, pixelData: string) => {
      removeLayersBySourceType(LayerSourceType.PIXEL_MODE_MARKER);
      const marker = generateMarkerLayer(latLng, pixelData, false);
      addLayer(marker);
    },
    [addLayer, removeLayersBySourceType]
  );

  const getPixelValuesVsWavelength = useCallback(
    async (
      clickedPoint: ClickedPoint,
      collection: string | undefined,
      itemId: string | undefined
    ) => {
      if (!collection || !itemId) {
        return;
      }

      setIsFetching(true);
      markClickedPoint(
        { lat: clickedPoint.lat, lng: clickedPoint.lng },
        translate('datacosmos.layers.pixelOperations.tooltip.loading')
      );

      const { data } = await getSTACItemPixelsVsWavelength({
        params: {
          collection,
          items: itemId,
          latitude: clickedPoint.lat,
          longitude: clickedPoint.lng,
        },
      });
      setIsFetching(false);

      if (!data) {
        markClickedPoint(
          { lat: clickedPoint.lat, lng: clickedPoint.lng },
          translate('datacosmos.layers.pixelOperations.tooltip.noData')
        );

        setIsFetching(false);

        return;
      }

      setSinglePixelVsWavelengthChartData(data.values);
    },
    [markClickedPoint, translate]
  );

  const toggleSinglePixelVsWavelength = useCallback(
    (toggle?: boolean) => {
      if (isToggled) {
        removeLayersBySourceType(LayerSourceType.PIXEL_MODE_MARKER);
      }
      setIsToggled(toggle !== undefined ? toggle : !isToggled);
    },
    [isToggled, removeLayersBySourceType]
  );

  return {
    isToggled,
    isFetching,
    toggleSinglePixelVsWavelength,
    getPixelValuesVsWavelength,
    singlePixelVsWavelengthChartData,
  };
};
