import { useRef } from 'react';
import {
  OverlayContainer,
  useOverlayPosition,
  useOverlayTrigger,
  useButton,
} from 'react-aria';
import { useOverlayTriggerState } from 'react-stately';
import { Overlay } from '_atoms/Overlay/Overlay';
import type { Placement } from '@react-types/overlays';
import IconButton from '_molecules/IconButton/IconButton';

type IPopover2Props = {
  placement: Placement;
  popupContent: JSX.Element;
  children: JSX.Element;
  offset?: number;
  className?: string;
  toggleClassName?: string;
  disabled?: boolean;
  hideCloseBtn?: true | undefined;
  isDismissable?: boolean;
  shouldCloseOnBlur?: boolean;
  isKeyboardDismissDisabled?: boolean;
  shouldCloseOnInteractOutside?: (element: HTMLElement) => boolean;
};

const Popover2 = (props: IPopover2Props) => {
  const state = useOverlayTriggerState({});

  const triggerRef = useRef<HTMLDivElement>(null);
  const overlayRef = useRef<HTMLDivElement>(null);

  const { overlayProps, triggerProps } = useOverlayTrigger(
    { type: 'dialog' },
    state,
    triggerRef
  );

  const { overlayProps: positionProps } = useOverlayPosition({
    targetRef: triggerRef,
    overlayRef,
    placement: props.placement,
    isOpen: state.isOpen,
    offset: props.offset,
  });

  const { buttonProps } = useButton(
    {
      onPress: () => {
        state.toggle();
      },
      isDisabled: props.disabled,
    },
    triggerRef
  );

  return (
    <>
      <div
        {...buttonProps}
        {...triggerProps}
        ref={triggerRef}
        className={props.toggleClassName}
      >
        {props.children}
      </div>

      {state.isOpen && !props.disabled && (
        <OverlayContainer>
          <Overlay
            isOpen={state.isOpen}
            onClose={() => state.close()}
            {...positionProps}
            {...overlayProps}
            overlayRef={overlayRef}
            className={props.className + 'rounded-none'}
            isDismissable={props.isDismissable}
            shouldCloseOnInteractOutside={props.shouldCloseOnInteractOutside}
            shouldCloseOnBlur={props.shouldCloseOnBlur}
            isKeyboardDismissDisabled={props.isKeyboardDismissDisabled}
            style={{ ...positionProps.style, zIndex: 999999 }}
          >
            <>
              {props.popupContent}
              {!props.hideCloseBtn && (
                <IconButton
                  icon="Close"
                  onPress={() => {
                    state.close();
                  }}
                  className="absolute top-0 right-0 p-1"
                />
              )}
            </>
          </Overlay>
        </OverlayContainer>
      )}
    </>
  );
};

export default Popover2;
