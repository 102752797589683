import classNames from 'classnames';
import React, { useState } from 'react';
import Icon from '_atoms/Icon/Icon';
import type { IPopoverProps } from '_atoms/Popover/Popover';
import Popover, { Direction } from '_atoms/Popover/Popover';

interface IProps extends IPopoverProps {
  children: React.ReactNode;
  labelText: string;
  startExpanded?: boolean;
  labelClassName?: string;
}

const Accordion = (props: IProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(
    props.startExpanded ? true : false
  );
  return (
    <Popover
      direction={Direction.BottomRight}
      relative
      usePortal={false}
      content={props.children as JSX.Element}
      fill
      className={classNames(props.className, 'w-fill')}
      isOpen={isOpen}
    >
      <div
        className={classNames(
          props.className,
          'flex items-center color-item p-3 mb-px cursor-pointer'
        )}
        onClick={() => {
          props.isOpen ? null : setIsOpen(!isOpen);
        }}
      >
        {isOpen || props.isOpen ? (
          <Icon icon="ChevronDown" size={16} />
        ) : (
          <Icon icon="ChevronRight" size={16} />
        )}

        <div className={props.labelClassName}>
          <span>{props.labelText}</span>
        </div>
      </div>
    </Popover>
  );
};

export default Accordion;
