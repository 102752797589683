import type React from 'react';
import type { IGroundStation } from '../groundStations/actionTypes';
import type { IPointOfInterest } from '../pointsOfInterest/actionTypes';
import type { IRegionOfInterest } from '../regionsOfInterest/actionTypes';
import type { ISatellite } from '../../components/optimizationPanel/constraints/genericConstellation/GenericConstellation';

export const SET_POPUP = 'SET_POPUP';
export const RESET_POPUP = 'RESET_POPUP';

export interface IPopUp {
  visible: boolean;
  classNames: string[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  component: React.Component | any;
  functions?: {
    onSubmit?: Function;
    onClose?: Function;
    onClick?: Function;
    panToBounds?: Function;
    addRegion?: Function;
  };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  otherProps?: any;
  data?:
    | IGroundStation
    | IPointOfInterest
    | IRegionOfInterest
    | ISatellite
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    | any;
}

interface ISetPopUp {
  type: typeof SET_POPUP;
  popUp: IPopUp;
}

interface IResetPopUp {
  type: typeof RESET_POPUP;
}

export type popUpTypes = ISetPopUp | IResetPopUp;
