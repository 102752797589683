import { Redirect } from 'react-router-dom';
import ROUTES from '../routeConstants';
import { useAuth } from '../services/auth/AuthWrapper';
import Loading from '../components/common/Loading';
import { DATACOSMOS_SKIP_HOME } from 'env';

const BasePage = () => {
  const { loading } = useAuth();
  if (loading) return <Loading isLoading={false} />;
  if (DATACOSMOS_SKIP_HOME) return <Redirect to={ROUTES.DATA_CATALOG} />;
  else return <Redirect to={ROUTES.MSD} />;
};

export default BasePage;
