import { LayerSourceType } from 'datacosmos/entities/layer';
import { useMapLayers } from 'datacosmos/stores/MapLayersProvider';
import React from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import s from './index.module.scss';
import Layer from './Layer';
import CommonLayerControls from './CommonLayerControls';
import SidebarHeader from '_organisms/SidebarHeader/SidebarHeader';
import useLocalStorage from 'utils/hooks/useLocalStorage';
import { useTimeSeries } from 'datacosmos/stores/TimeSeriesProvider';
import { useLocalisation } from 'utils/hooks/useLocalisation';

export function Layers() {
  const { layers, replaceLayer, setLayersMenuOpen } = useMapLayers();

  const [isLayerGridView, setIsLayerGridView] = useLocalStorage(
    'isLayerGridView',
    true
  );

  const { translate } = useLocalisation();
  const { isTimeSeriesToggled, timeSeriesLayers } = useTimeSeries();

  const layersMenuDisplayableLayers = layers.filter(
    (l) =>
      l.sourceType !== LayerSourceType.ASSET_OUTLINE &&
      l.sourceType !== LayerSourceType.PIXEL_MODE_MARKER
  );

  return (
    <div
      className={s.container}
      style={{ maxHeight: 'inherit', overflowY: 'auto' }}
    >
      <SidebarHeader
        title={translate('datacosmos.layers.layers')}
        forLayers={{ isLayerGridView, setIsLayerGridView }}
        rightIcon={{
          iconName: 'Expand',
          onIconClick: () => setLayersMenuOpen(false),
        }}
      />
      <CommonLayerControls layers={layers} />
      <DndProvider backend={HTML5Backend}>
        <ul className={s.layerList + ' bg-surface dark:bg-surface-dark'}>
          {(isTimeSeriesToggled
            ? timeSeriesLayers
            : layersMenuDisplayableLayers
          ).map((layer, i) => (
            <Layer
              key={layer.id}
              index={i}
              layer={layer}
              moveLayer={(fromIndex, moveToIdex) => {
                replaceLayer(layers[fromIndex], moveToIdex);
              }}
              isGridView={isLayerGridView}
            />
          ))}
        </ul>
      </DndProvider>
    </div>
  );
}
