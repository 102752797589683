import type {
  IPoint,
  IRegionOfInterest,
  IRIState,
} from '../constants/regionsOfInterest/actionTypes';

export const normalizeRegionsOfInterest = (
  regionsOfInterest: IRIState
): IRIState => {
  const regions = regionsOfInterest.list;
  const normalizedRegions: IRIState = {
    list: regions.map((region): IRegionOfInterest => {
      const newRegionPaths = region.paths.map((path): IPoint[] => {
        const isClockWise =
          google.maps.geometry.spherical.computeSignedArea(
            path.map(
              (point): google.maps.LatLng =>
                new google.maps.LatLng(point.lat, point.lng)
            )
          ) < 0;
        if (isClockWise) {
          const newPath = [...path];
          return newPath.reverse().map((point): IPoint => {
            return { lat: point.lat, lon: point.lng };
          });
        }
        return path.map((point): IPoint => {
          return { lat: point.lat, lon: point.lng };
        });
      });
      return { ...region, paths: newRegionPaths };
    }),
  };
  return normalizedRegions;
};
