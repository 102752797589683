import React, { useState } from 'react';
import { Slider, Button, Intent } from '@blueprintjs/core';

import s from '../index.module.scss';

interface IProps {
  onSubmitHandler: Function;
  onCancelHandler: Function;
}

const MuteAlertForm = ({ onSubmitHandler, onCancelHandler }: IProps) => {
  const [daysToMuteAlert, setDaysToMuteAlert] = useState<number>(1);

  const clickHandler = () => {
    onSubmitHandler(daysToMuteAlert);
  };

  return (
    <>
      <Slider
        min={1}
        max={7}
        labelRenderer={(value) => value + ' days'}
        value={daysToMuteAlert}
        onChange={setDaysToMuteAlert}
      />
      <div className={s.dialogueButtonContainer}>
        <Button
          //@ts-ignore
          onClick={onCancelHandler}
        >
          Cancel
        </Button>
        <div className={s.dialogueChoiceButtons}>
          <Button intent={Intent.PRIMARY} onClick={clickHandler}>
            Mute
          </Button>
        </div>
      </div>
    </>
  );
};

export default MuteAlertForm;
