// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const handleWheelBlocking = (arg: any): void => {
  const { nativeEvent, currentTarget } = arg;
  const { deltaY } = nativeEvent;
  const { scrollTop, scrollTopMax } = currentTarget;
  if (deltaY > 0 && scrollTop === scrollTopMax) {
    arg.preventDefault();
  } else if (deltaY < 0 && !scrollTop) {
    arg.preventDefault();
  }
};
