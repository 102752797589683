import PdfApi from 'services/api/portal/pdfApi';
import { prepareFile } from 'utils/common/CommonUtils';
import { PAGE } from 'constants/summary/page';
import { scopedStore } from 'store';
import logo from 'images/logo-centric-name-compact.svg';
import { printStyles } from 'styles/print-summary';
import { getFormattedDate } from 'utils/common/getFormattedDate';

export interface IDocumentInformation {
  mission: string;
  username: string;
  date: Date;
  fileName?: string;
}

const exportToPDF = async (
  documentInformation: IDocumentInformation
): Promise<BlobPart> => {
  const { date, username, mission } = documentInformation;
  const body =
    document.getElementsByClassName('summary-body-body')[0].innerHTML;
  const metaHTML = '<meta charset="utf-8" />';
  const styleHTML = `<style>${printStyles}</style>`;
  const headHTML = `<head>${metaHTML}${styleHTML}</head>`;
  const bodyHTML = `<body>${body}</body>`;
  const newHTML = `<html lang="en">${headHTML}${bodyHTML}</html>`
    .replace(
      'key_user_mission_timestamp',
      `${username}-${mission}-${getFormattedDate(date)}`
    )
    .replace('key_open_cosmos_copyright', PAGE.COPYRIGHT)
    .replace('key_open_cosmos_email', PAGE.EMAIL)
    .replace('key_top_right_logo', logo)
    .replace(
      'key_full_year',
      documentInformation.date.getUTCFullYear().toString()
    );
  const token = scopedStore.getState().user.token;
  return PdfApi.exportPDF.post(newHTML, token, documentInformation.fileName);
};

export const preparePDF = async (
  documentInformation: IDocumentInformation
): Promise<string> => {
  const res: BlobPart = await exportToPDF(documentInformation);
  return prepareFile(res, documentInformation.fileName, 'application/pdf');
};
