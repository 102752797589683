/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import React, { useRef, useState } from 'react';
import type { TreeState } from 'react-stately';
import type { Node } from '@react-types/shared';
import classNames from 'classnames';
import { useMenuItem } from 'react-aria';

export type IMenuItemProps<T> = {
  state: TreeState<T>;
  item: Node<T>;
  onAction?: (key: React.Key) => void;
  isNested?: boolean;
};

export const MenuItem = <T extends object>(props: IMenuItemProps<T>) => {
  const menuItemRef = useRef<HTMLDivElement>(null);
  const [toggled, setToggled] = useState(false);

  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const onPress: (() => void) | undefined =
    props.item.props?.children?.props?.onClick ||
    props.item.props?.children?.props?.onPress;

  const isPressable = Boolean(onPress) || props.isNested;

  const { menuItemProps } = useMenuItem(
    { key: props.item.key, onAction: props.onAction },
    props.state,
    menuItemRef
  );

  const active = Boolean(props.item.props?.children?.props?.active);

  return (
    <div
      {...menuItemProps}
      ref={menuItemRef}
      className={classNames('p-2 px-4 flex items-center select-none w-full', {
        'cursor-default': !isPressable,
        'cursor-pointer': active,
        'dark:text-accent text-accent-900': active || toggled,
        'hover:dark:bg-item-dark-hover hover:bg-item-hover cursor-pointer':
          isPressable,
      })}
      onClick={() => {
        if (props.isNested) {
          // Press parent popover button
          menuItemRef.current?.parentElement?.click();
          setToggled((prev) => !prev);
        }
      }}
    >
      {props.item.rendered}
      {props.isNested ? (
        <span aria-disabled className="whitespace-pre text-lg">
          {' >'}
        </span>
      ) : null}
    </div>
  );
};

export default MenuItem;
