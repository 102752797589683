import { Icon } from '@blueprintjs/core';
import React, { useRef } from 'react';
import type { Layer } from 'datacosmos/entities/layer';
import type { DropTargetMonitor } from 'react-dnd';
import { useDrag, useDrop } from 'react-dnd';
import type { XYCoord } from 'dnd-core';

import s from './index.module.scss';
import { useMapLayers } from 'datacosmos/stores/MapLayersProvider';
import LayerListCard from './LayerCards/LayerListCard';
import LayerGridCard from './LayerCards/LayerGridCard';
import classNames from 'classnames';

const dragAndDropType = Symbol('layer');

interface DragItem {
  index: number;
  id: string;
  type: string;
}

/**
 * Returns true if the move should be performed
 * Only perform the move when the mouse has crossed half of the items height
 * When dragging downwards, only move when the cursor is below 50% the next card
 * When dragging upwards, only move when the cursor is above 50% the previous card
 */
function hasUserCrossedTheNextHalfItem(
  dragIndex: number,
  hoverIndex: number,
  layerRef: React.MutableRefObject<HTMLLIElement>,
  monitor: DropTargetMonitor
) {
  // Determine rectangle on screen
  const hoverBoundingRect =
    layerRef.current && layerRef.current.getBoundingClientRect();

  // Get vertical middle
  const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

  // Determine mouse position
  const clientOffset = monitor.getClientOffset();

  // Get pixels to the top
  const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

  // Dragging downwards
  if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
    return true;
  }

  // Dragging upwards
  if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
    return true;
  }

  return false;
}

function useDragAndDrop(
  layer: Layer,
  layerRef: React.MutableRefObject<HTMLLIElement>,
  handleRef: React.MutableRefObject<HTMLLIElement>,
  index: number,
  moveLayer: (dragIndex: number, hoverIndex: number) => void
) {
  const [{ handlerId }, drop] = useDrop({
    accept: dragAndDropType,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item: DragItem, monitor: DropTargetMonitor) {
      if (!layerRef.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }

      if (
        !hasUserCrossedTheNextHalfItem(dragIndex, hoverIndex, layerRef, monitor)
      ) {
        return;
      }

      // Time to actually perform the action
      moveLayer(dragIndex, hoverIndex);

      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag, preview] = useDrag({
    type: dragAndDropType,
    item: () => {
      return { id: layer.id, index };
    },
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  preview(drop(layerRef));
  drag(handleRef);

  return {
    handlerId,
    isDragging,
  };
}

interface IProps {
  layer: Layer;
  index: number;
  moveLayer: (dragIndex: number, hoverIndex: number) => void;
  isGridView: boolean;
}

export default function LayerItem({
  layer,
  index,
  moveLayer,
  isGridView,
}: IProps) {
  const layerRef = useRef<HTMLLIElement>(null);
  const handleRef = useRef<HTMLLIElement>(null);
  const { selectLayer } = useMapLayers();
  const { isDragging, handlerId } = useDragAndDrop(
    layer,
    layerRef,
    handleRef,
    index,
    moveLayer
  );

  const opacity = isDragging ? 0 : 1;

  return (
    <li
      ref={layerRef}
      className={classNames(s.layerItem, {
        'bg-item-selected dark:bg-item-dark-selected': layer.options.isSelected,
      })}
      style={{
        opacity,
      }}
      data-handler-id={handlerId}
      onClick={(e) =>
        e.ctrlKey || e.metaKey
          ? selectLayer(layer, true)
          : selectLayer(layer, false)
      }
    >
      <span className={s.grab} ref={handleRef}>
        <Icon
          icon="drag-handle-vertical"
          className="dark:stroke-item-dark-contrast"
        />
      </span>
      {isGridView ? (
        <LayerGridCard layer={layer} />
      ) : (
        <LayerListCard layer={layer} />
      )}
    </li>
  );
}
