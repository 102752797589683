import type { BaseFieldsProps, WrappedFieldProps } from 'redux-form';
import type { ChangeEvent, ReactElement } from 'react';
import React, { useEffect, useState } from 'react';
import type { WithTranslation } from 'react-i18next';
import { withTranslation } from 'react-i18next';
import { constraintsHandler } from '../../constants/objectivesTable/constraintsHendler';
import { Checkbox, H6, RangeSlider, Slider } from '@blueprintjs/core';

export interface ICriterionField extends WithTranslation, BaseFieldsProps {
  criterion: WrappedFieldProps;
  metric: WrappedFieldProps;
  constraints?: {
    min: WrappedFieldProps;
    max: WrappedFieldProps;
  };
}

const labelRenderer = (value: number): ReactElement => {
  switch (value) {
    case -1:
      return <div className="min">Min</div>;
    case 0:
      return null;
    case 1:
      return <div className="max">Max</div>;
  }
  return undefined;
};

function CriterionField(props: ICriterionField): ReactElement {
  const { t, criterion, constraints, metric } = props;
  const [isChecked, setCheck] = useState(false);
  const [metricState, setMetric] = useState(metric.input.value);
  const toggleCheck = (): void => setCheck(!isChecked);
  const constraintsParameters = constraintsHandler[metric.input.value];
  useEffect((): void => {
    if (metricState !== metric.input.value) {
      constraints.min.input.onChange(constraintsParameters.min);
      constraints.max.input.onChange(constraintsParameters.max);
      setMetric(metric.input.value);
    }
  });
  const onChangeSlider = (value: [number, number]): void => {
    if (criterion.input.value !== -1) {
      constraints.min.input.onChange(value[0]);
    }
    if (criterion.input.value !== 1) {
      constraints.max.input.onChange(value[1]);
    }
  };
  const onChangeCriterion = (value: number): void => {
    switch (value) {
      case -1:
        constraints.min.input.onChange(constraintsParameters.min);
        break;
      case 0:
        return undefined;
      case 1:
        constraints.max.input.onChange(constraintsParameters.max);
        break;
    }
    criterion.input.onChange(value);
  };
  const onChangeMin = (event: ChangeEvent<HTMLInputElement>): void => {
    let value = +event.target.value;
    if (event.target.value !== (+event.target.value).toString()) {
      value = constraintsParameters.min;
    }

    value =
      value < constraintsParameters.min ? constraintsParameters.min : value;
    value =
      value > constraints.max.input.value ? constraints.max.input.value : value;
    constraints.min.input.onChange(value);
  };
  const onChangeMax = (event: ChangeEvent<HTMLInputElement>): void => {
    let value = +event.target.value;
    if (event.target.value !== (+event.target.value).toString()) {
      value = constraintsParameters.max;
    }
    value =
      value > constraintsParameters.max ? constraintsParameters.max : value;
    value =
      value < constraints.min.input.value ? constraints.min.input.value : value;
    constraints.max.input.onChange(value);
  };

  const errorDiv =
    criterion.meta.error && criterion.meta.touched ? (
      <div className="bp4-callout bp4-intent-danger bp4-icon-error">
        <H6 className="bp4-heading">
          {t(`validation.errors.${criterion.meta.error}` as any)}
        </H6>
      </div>
    ) : null;

  const sliderStyle =
    criterion.input.value === -1
      ? 'min'
      : criterion.input.value === 1
      ? 'max'
      : '';

  const minBlockStyle = isChecked
    ? criterion.input.value === -1
      ? 'bp4-input block min-block'
      : 'bp4-input block'
    : 'bp4-input block';
  const maxBlockStyle = isChecked
    ? criterion.input.value === 1
      ? 'bp4-input block max-block'
      : 'bp4-input block'
    : 'bp4-input block';
  return (
    <div className="bp4-input-group bp4-vertical field-row">
      <div className="bp4-control-group bp4-fill objectives-pop-up">
        <div className="bp4-form-group criterion">
          <label className="bp4-label" htmlFor="form-group-input">
            Criterion
          </label>
          <div className="bp4-form-content">
            <div className="bp4-input-group">
              <Slider
                className={sliderStyle}
                labelRenderer={labelRenderer}
                min={-1}
                max={1}
                value={criterion.input.value}
                onChange={onChangeCriterion}
                showTrackFill={false}
              />
            </div>
          </div>
        </div>
        <Checkbox
          disabled={!criterion.input.value}
          className="checkbox"
          large={true}
          checked={isChecked}
          onChange={toggleCheck}
        />
        <div className="bp4-form-group constraints">
          <label className="bp4-label" htmlFor="form-group-input">
            Constraints
          </label>
          <div className="bp4-form-content">
            <div className="bp4-control-group">
              <input
                value={+constraints.min.input.value}
                disabled={!isChecked || criterion.input.value === -1}
                onChange={onChangeMin}
                className={maxBlockStyle}
              />
              <RangeSlider
                labelStepSize={constraintsParameters.labelStepSize}
                min={constraintsParameters.min}
                max={constraintsParameters.max}
                value={[
                  +constraints.min.input.value,
                  +constraints.max.input.value,
                ]}
                disabled={!isChecked}
                onChange={onChangeSlider}
                className="slider"
              />
              <input
                value={+constraints.max.input.value}
                disabled={!isChecked || criterion.input.value === 1}
                onChange={onChangeMax}
                className={minBlockStyle}
              />
            </div>
          </div>
        </div>
      </div>
      {errorDiv}
    </div>
  );
}

export default withTranslation()(CriterionField);
