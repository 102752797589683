export const DetailsItem = ({
  title,
  value,
  ...aria
}: {
  title: string;
  value: string;
} & React.AriaAttributes) => {
  return (
    <div
      style={{
        display: 'grid',
        gridTemplateColumns: '2fr 2fr',
      }}
      className="dark:text-item-dark-contrast text-item-contrast pr-1 pl-1"
      {...aria}
    >
      <span className="break-all">{title}</span>
      <span className="break-all" style={{ textAlign: 'end' }}>
        {value}
      </span>
    </div>
  );
};

export const CompositeDetailsItem = ({
  title,
  element,
}: {
  title: string;
  element: React.ReactNode;
}) => {
  return (
    <div
      style={{
        display: 'grid',
        gridTemplateColumns: '2fr 2fr',
      }}
      className="dark:text-item-dark-contrast text-item-contrast pr-1 pl-1"
    >
      <span>{title}</span>
      <div className="text-end">{element}</div>
    </div>
  );
};

export const JSONDetailsItem = ({
  title,
  value,
  ...aria
}: {
  title: string;
  value: { [key: string]: unknown } | { [key: string]: unknown }[];
} & React.AriaAttributes) => {
  return (
    <div
      style={{
        display: 'grid',
        gridTemplateColumns: '2fr 2fr',
      }}
      className="dark:text-item-dark-contrast text-item-contrast"
      {...aria}
    >
      <span className="break-all">{title}</span>
      <div className="bg-item-dark-contrast dark:bg-item-contrast flex justify-center">
        <pre>{JSON.stringify(value, null, 2)}</pre>
      </div>
    </div>
  );
};
