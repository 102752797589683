import { Icon } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { useState } from 'react';
import Dialog from '_atoms/Dialog/Dialog';
import Input from '_molecules/Input/Input';
import type { TicketTeam } from 'api/tickets/service';
import { postTicket } from 'api/tickets/service';

export type MailToButtonProps = {
  team: TicketTeam;
  subject: string;
  fallbackEmail?: string;
};

export const MailToButton = ({
  team,
  subject,
  fallbackEmail,
}: MailToButtonProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [body, setBody] = useState('');

  return (
    <>
      <a
        target="_blank"
        className="flex items-center p-2 dark:text-item-dark-contrast hover:no-underline whitespace-nowrap"
        rel="noreferrer"
        onClick={() => {
          setIsOpen(true);
        }}
      >
        <Icon className="mr-1" icon={IconNames.ENVELOPE} />
        {subject}
      </a>

      <Dialog
        buttons={[
          {
            shown: true,
            onPress: () => {
              void postTicket({
                body: {
                  title: subject,
                  description: body,
                  team,
                  fallbackEmail,
                },
              });
            },
            text: 'Submit',
          },
        ]}
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        title={subject}
      >
        <Input
          type="textarea"
          style={{ height: '100%' }}
          onChange={(event) => {
            setBody(event.target.value);
          }}
          className="min-h-[21.875rem]"
        />
      </Dialog>
    </>
  );
};

export default MailToButton;
