import { useState } from 'react';
import { Icon } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import Popover2 from '_atoms/Popover/Popover2';
import { Menu } from 'ui/Menu';
import { useTheme } from 'datacosmos/stores/ThemeProvider';
import { useAuth } from 'services/auth/AuthWrapper';
import { useHistory } from 'react-router';
import { getHost } from 'utils/common/CommonUtils';
import { AUTH0_LOGOUT_URI, ENABLE_DARK_MODE } from 'env';
import { useAnalytics } from 'utils/hooks/analytics/useAnalytics';

const AVATAR_HEIGHT = 40;
interface Props {
  showThemesInUserMenu?: boolean;
}

export const UserMenu = ({ showThemesInUserMenu }: Props) => {
  const [invalidAvatarPicture, setInvalidAvatarPicture] = useState(false);
  const { setTheme, theme } = useTheme();
  const history = useHistory();
  const auth = useAuth();

  const { sendInfo } = useAnalytics();
  let avatar = (
    <Icon className="dark:invert" icon={IconNames.PERSON} iconSize={30} />
  );

  let content = (
    <Menu
      items={[
        {
          content: 'Login',
          onPress: () => void auth.loginWithRedirect(),
        },
      ]}
    />
  );

  if (auth.isAuthenticated) {
    if (auth.user?.picture && !invalidAvatarPicture) {
      avatar = (
        <img
          src={auth.user.picture}
          alt="Avatar"
          className="h-full"
          onError={() => {
            setInvalidAvatarPicture(true);
          }}
        />
      );
    }

    const manageRoles = () => history.push('/portal/roles');

    const manageUsers = () => history.push('/portal/users');

    const logout = () => {
      sendInfo({ action: 'Logout', item: 'Logout button', type: 'Logout' });
      if (AUTH0_LOGOUT_URI) {
        auth.logout(`${getHost()}${AUTH0_LOGOUT_URI}`);
      }
    };

    content =
      ENABLE_DARK_MODE && showThemesInUserMenu ? (
        <Menu
          items={[
            {
              content: auth.user?.name,
              icon: 'PERSON',
            },
            {
              content: 'Theme',
              icon: 'LIGHTBULB',
              items: [
                {
                  content: 'Dark',
                  onPress: () => setTheme('DARK'),
                  active: theme === 'DARK',
                },
                {
                  content: 'Light',
                  onPress: () => setTheme('LIGHT'),
                  active: theme === 'LIGHT',
                },
                {
                  content: 'Auto',
                  onPress: () => setTheme('AUTO'),
                  active: theme === 'AUTO',
                },
              ],
            },
            {
              content: 'Roles Manager',
              icon: 'CROWN',
              onPress: manageRoles,
              show: auth.isAllowedToReadRoles,
            },
            {
              content: 'Users',
              icon: 'People',
              onPress: manageUsers,
              show: auth.isAllowedToReadUsersRoles,
            },
            {
              content: 'Logout',
              icon: 'LogOut',
              onPress: logout,
            },
          ]}
        />
      ) : (
        <Menu
          items={[
            {
              content: auth.user?.name,
              icon: 'PERSON',
            },
            {
              content: 'Roles Manager',
              icon: 'CROWN',
              onPress: manageRoles,
              show: auth.isAllowedToReadRoles,
            },
            {
              content: 'Users',
              icon: 'People',
              onPress: manageUsers,
              show: auth.isAllowedToReadUsersRoles,
            },
            {
              content: 'Logout',
              icon: 'LogOut',
              onPress: logout,
            },
          ]}
        />
      );
  }

  const avatarSize = `${AVATAR_HEIGHT}px`;

  return (
    <Popover2
      hideCloseBtn
      popupContent={content}
      placement="bottom left"
      offset={0}
      isDismissable={true}
    >
      <div
        className="mx-2 overflow-hidden"
        style={{ borderRadius: '50%', width: avatarSize, height: avatarSize }}
      >
        {avatar}
      </div>
    </Popover2>
  );
};
