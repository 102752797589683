import { MenuItem } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import area from '@turf/area';
import type { Layer } from 'datacosmos/entities/layer';
import { LayerSourceType } from 'datacosmos/entities/layer';
import { PolygonLayerFactory } from 'datacosmos/entities/polygonLayer';
import type { Polygon } from 'geojson';
import { useLocalisation } from 'utils/hooks/useLocalisation';

interface IProps {
  drawPolygon: (options?: { color: string; weight: number }) => Promise<{
    polygon: GeoJSON.Feature<Polygon>;
    polygonMetadata: unknown;
  }>;
  addLayer: (...newLayers: Layer[]) => void;
}

export const DrawPolygon = (props: IProps) => {
  const { translate } = useLocalisation();
  return (
    <MenuItem
      className="dark:hover:text-neutral hover:text-accent-900"
      text={translate('datacosmos.rightClickMenu.polygon')}
      icon={IconNames.POLYGON_FILTER}
      onClick={async () => {
        const { polygon, polygonMetadata } = await props.drawPolygon();
        const m2 = area(polygon);

        props.addLayer(
          PolygonLayerFactory(
            LayerSourceType.GEOMETRY_LAYER,
            'Polygon',
            polygon,
            m2,
            polygonMetadata
          )
        );
      }}
    />
  );
};
