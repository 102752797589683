import Button from "./Button";
import classNames from "classnames";
import type { ButtonProps } from "./Button";

const SecondaryButton = (props: ButtonProps) => {
  return (
    <Button
      {...props}
      textHoverClassName={{}}
      iconHoverClassName={{
        "stroke-item-contrast": !props.disabled,
      }}
      className={classNames(
        "color-item border border-neutral-500 dark:border-neutral-700",
        {
          "hover:bg-item-hover dark:hover:bg-item-dark-hover": !props.disabled,
          // 'text-item-contrast-inactive dark:hover:text-item-dark-contrast-inactive':
          //   props.disabled,
          // 'stroke-item-contrast-inactive dark:hover:stroke-item-dark-contrast-inactive':
          //   props.disabled,
        }
      )}
    />
  );
};

export default SecondaryButton;
