import { Intent } from '@blueprintjs/core';
import DrawAreaOfInterest from 'datacosmos/components/DrawAreaOfInterest';
import UploadRegion from 'datacosmos/components/UploadRegion';
import { LayerSourceType } from 'datacosmos/entities/layer';
import { useApplicationCatalog } from 'datacosmos/stores/ApplicationCatalogContext';
import { useMapLayers } from 'datacosmos/stores/MapLayersProvider';
import type { IApplication, IAPIAppValue } from 'datacosmos/types/applications';
import { AppTags } from 'datacosmos/types/applications';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { toaster } from 'toaster';
import DatePicker from '_molecules/DatePicker/DatePicker';
import IconButton from '_molecules/IconButton/IconButton';
import OpenedAppCard from 'datacosmos/components/Applications/SubscriptionApps/Common/OpenedAppCard';
import UnopenedAppCard from 'datacosmos/components/Applications/SubscriptionApps/Common/UnopenedAppCard';
import { parseDate } from '@internationalized/date';
import { FEEDBACK_MAIL } from 'constants/contact';
import { btoaSafe } from 'utils/common/btoaSafe';
import { useActivePage } from 'datacosmos/components/Toolbar/ActivePageProvider';

const DATACOSMOS_IMG = '/images/datacosmos/';

type IProps = {
  app: IApplication;
};

export const EOSOilSpillApp: IApplication = {
  get id() {
    return btoaSafe(
      JSON.stringify(
        this.name + this.provider + this.description + this.appScreenshotUrl
      ).substring(0, 75)
    );
  },
  name: 'Oil spill detection',
  description:
    'Oil spills can lead to severe disruption, affecting the environment, the economy and human health. But in order to act, first we need to see, because oil spills happen every day. Orbital EOS delivers the first integrated solution for oil spills at sea, working as a one-stop-shop for the detection, characterisation and forecasting of these events, using advanced analysis of satellite optical and radar imagery.',
  shortDescription:
    'Orbital EOS uses radar and optical satellites to detect and characterize oil spills at sea',
  provider: {
    name: 'Orbital EOS',
    description: '',
    id: 10,
    url: 'https://www.orbitaleos.com/',
    icon_url: DATACOSMOS_IMG + 'eos_oil_logo.png',
  },
  appScreenshotUrl: DATACOSMOS_IMG + 'eos_oil_screenshot.png',
  frequencyOfData: 'Between 1 and 7 images per week',
  price: 'Contact us',
  leadTime: '1-3 days',
  inputs: [
    {
      field: 'AOI',
      example: '',
    },
    {
      field: 'startDateTime',
      example: '',
    },
    {
      field: 'endDateTime',
      example: '',
    },
  ],
  values: {
    AOI: { value: null, isError: false, message: '' },
    startDateTime: { value: null, isError: false, message: '' },
    endDateTime: { value: null, isError: false, message: '' },
  },
  renderer: (app: IApplication) => <EOSOilSpill app={app} />,
  tags: [AppTags.maritime, AppTags.water_quality],
};

const EOSOilSpill = ({ app }: IProps) => {
  const [isAppOpened, setIsAppOpened] = useState<boolean>(false);
  const {
    setApplicationAOIs: setEosAoi,
    applicationAOIs: eosAoi,
    setInputData,
    toggleAppInstall,
    getInstalledStatus,
    shouldAutoOpen,
    setSelectedInstalledApp,
  } = useApplicationCatalog();

  const { activePage, setActivePage } = useActivePage();

  const { removeLayersBySourceType } = useMapLayers();

  const [startDate, setStartDate] = useState<Date>();
  const [endDate, setEndDate] = useState<Date>();

  const setValue = (key: string, value: IAPIAppValue['value']) => {
    setInputData(app.name, {
      ...app.values,
      [key]: { value, isError: false, message: '' },
    });
  };

  useEffect(() => {
    setValue('startDateTime', startDate);
    setValue('endDateTime', endDate);
    setValue('AOI', eosAoi);
  }, [startDate, eosAoi, endDate]);

  const inputs = (): JSX.Element => {
    return (
      <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
        <div>
          <label htmlFor="aoi">Area of interest: </label>
          <div
            style={{
              display: 'grid',
              gridTemplateColumns: '2fr 2fr 0.2fr',
              gap: '5px',
            }}
            id="aoi"
          >
            <DrawAreaOfInterest
              aoiSourceType={LayerSourceType.APPLICATION_AOI}
              setAreasOfInterest={setEosAoi}
              buttonForApplications
              multipleAois
            />

            <UploadRegion
              aoiSourceType={LayerSourceType.APPLICATION_AOI}
              setAreaOfInterest={setEosAoi}
              buttonForApplications
              buttonTitle="Upload"
              multipleAois
            />

            <IconButton
              icon="Trash"
              size={24}
              onPress={() => {
                setEosAoi([]);
                removeLayersBySourceType(LayerSourceType.APPLICATION_AOI);
              }}
              className="justify-self-center self-center"
            />
          </div>
        </div>

        <div id="startDate">
          <DatePicker
            fill
            label="Start date: "
            onChange={(d) => setStartDate(d.toDate('Etc/UTC'))}
            maxValue={parseDate(moment().add(10, 'years').format('YYYY-MM-DD'))}
            granularity="second"
          />
        </div>

        <div id="endDate">
          <DatePicker
            fill
            label="End date: "
            onChange={(d) => setEndDate(d.toDate('Etc/UTC'))}
            maxValue={parseDate(moment().add(10, 'years').format('YYYY-MM-DD'))}
            granularity="second"
          />
        </div>
      </div>
    );
  };

  const formatValuesForBody = () => {
    let body = '';

    for (const [field, value] of Object.entries(app.values)) {
      body += `${field}: ${JSON.stringify(value.value)}%0D%0A`;
    }

    return body;
  };

  const handleSubmit = () => {
    console.log('App values: ', app.values);

    toaster.show({
      message:
        'A new email will be opened in your mail program. Please add any additional details in the body and send it to us, we will be in contact about your subscription.',
      intent: Intent.SUCCESS,
    });
    let mailBody = `ENTER EXTRA DETAILS HERE%0D%0A----------------------------%0D%0A`;

    mailBody += formatValuesForBody();
    window.location.href = `mailto:${FEEDBACK_MAIL}?subject='DataCosmos Subscription to ${app.name}'&body=${mailBody}`;
  };

  if (shouldAutoOpen || (isAppOpened && getInstalledStatus(app))) {
    return (
      <OpenedAppCard
        app={app}
        inputsRenderer={inputs}
        setIsAppOpened={setIsAppOpened}
        handleSubmit={handleSubmit}
        isInstalled={getInstalledStatus(app)}
        toggleAppInstall={toggleAppInstall}
      />
    );
  }

  return (
    <UnopenedAppCard
      app={app}
      setIsAppOpened={setIsAppOpened}
      toggleAppInstall={toggleAppInstall}
      isInstalled={getInstalledStatus(app)}
      setSelectedInstalledApp={(app) => {
        setSelectedInstalledApp(app);
        activePage === 'application' && setActivePage(undefined);
      }}
    />
  );
};
