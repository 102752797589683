import { BACKEND_BASE_URL } from 'env';

function getWebsocketBaseUrl() {
  const base = BACKEND_BASE_URL;

  if (base.startsWith('/')) {
    return `wss://${window.location.host}${base}`;
  } else if (base.startsWith('http')) {
    return base.replace('http', 'ws');
  } else {
    return `wss://${base}`;
  }
}

export const hosts = {
  portal: {
    v0: BACKEND_BASE_URL + '/portal/v0',
    v1: BACKEND_BASE_URL + '/portal/v1',
    v2: BACKEND_BASE_URL + '/portal/v2',
  },
  data: {
    v0: BACKEND_BASE_URL + '/data/v0',
    v1: BACKEND_BASE_URL + '/data/v1',
  },
  ops: {
    v0: BACKEND_BASE_URL + '/ops/v0',
    v1: BACKEND_BASE_URL + '/ops/v1',
  },
  storage: {
    v0: BACKEND_BASE_URL + '/data/v0/storage',
  },
  OSM: {
    search: 'https://nominatim.openstreetmap.org',
  },
};

export const websocketHost = {
  ops: {
    v0: getWebsocketBaseUrl() + '/ops/v0',
  },
};
