import React, { useEffect, useMemo, useState, useCallback } from 'react';
import _, { xor } from 'lodash';
import type { SatelliteDataType } from 'api/satellites/types';
import type { Sensor } from 'api/sensors/types';
import { SENSOR_LIST } from 'api/sensors/constants';
import type { IInstrument } from 'constants/satellite/types';
import { InstrumentFilters } from 'datacosmos/components/Tasking/InstrumentFilters';
import Select2 from '_molecules/Select2/Select2';
import { Item } from 'react-stately';
import Button from '_molecules/Button/Button';
import InfoCard from '_molecules/InfoCard/InfoCard';
import IconButton from '_molecules/IconButton/IconButton';
import classNames from 'classnames';
import { NonIdealState } from '@blueprintjs/core';
import { IconNames as BpIcons } from '@blueprintjs/icons';
import { useLocalisation } from 'utils/hooks/useLocalisation';
import type { TaskingSatellite } from 'api/tasking/types';
import { Tooltip } from 'opencosmos-ui';

type InstrumentsProps = {
  satellites: TaskingSatellite[];
  addSatellite?: IInstrument[];
  setAddSatellite: (addSatellite: IInstrument[]) => void;
  isTaskingError: boolean;
  setIsTaskingError: React.Dispatch<React.SetStateAction<boolean>>;
};

export const Instruments = ({
  satellites,
  addSatellite,
  setAddSatellite,
  setIsTaskingError,
  isTaskingError,
}: InstrumentsProps) => {
  const [satellite, setSatellite] = useState(satellites[0]);
  const [sensor, setSensor] = useState(SENSOR_LIST[0]);

  const [sensorAsPerSatellite, setSensorAsPerSatellite] = useState<Sensor[]>(
    []
  );

  const [selectableSatellites, setSelectableSatellites] = useState<
    TaskingSatellite[]
  >([]);

  const [isFilterOpen, setIsFilterOpen] = useState<boolean>(false);
  const [selectedDataTypes, setSelectedDataTypes] = useState<
    SatelliteDataType[]
  >([]);

  const [gsd, setGsd] = useState<{ min: number; max: number } | null>(null);

  const { translate } = useLocalisation();

  const areFiltersActive = selectedDataTypes.length > 0 || gsd !== null;

  const handleDataTypeSelect = useCallback((item: SatelliteDataType) => {
    setSelectedDataTypes((previous) => xor(previous, [item]));
  }, []);

  const handleDataTypeRemove = useCallback(
    (item: SatelliteDataType) => {
      setSelectedDataTypes(
        selectedDataTypes.filter((selected) => selected !== item)
      );
    },
    [selectedDataTypes]
  );

  const getActiveFiltersCount = useCallback(() => {
    let ctr = 0;
    gsd !== null && ctr++;
    selectedDataTypes.length > 0 && ctr++;
    return ctr;
  }, [gsd, selectedDataTypes.length]);

  const filteredSatellites = useMemo(() => {
    if (areFiltersActive) {
      const filtered = satellites
        .filter((sat) =>
          selectedDataTypes.length > 0
            ? selectedDataTypes.some((selected) =>
                sat.payloads.some(
                  (payload) => payload.payload_type === selected
                )
              )
            : true
        )
        .filter((sat) => (gsd?.min ? sat.gsd >= gsd.min : true))
        .filter((sat) => (gsd?.max ? sat.gsd <= gsd.max : true));

      return filtered;
    } else {
      return satellites;
    }
  }, [areFiltersActive, satellites, selectedDataTypes, gsd?.max, gsd?.min]);

  const initSensorsForSatellites = useCallback((sat?: TaskingSatellite) => {
    const sensors = SENSOR_LIST.filter(
      (_sensor) => sat && _sensor.satellite === sat.mission_id
    );
    setSensorAsPerSatellite(sensors);
    setSensor(sensors[0]);
  }, []);

  useEffect(() => {
    //Create a function to init all satellites since it defaults to all if user backs out of opps selection
    setSelectableSatellites(satellites);
    setSatellite(satellites[0]);
    initSensorsForSatellites(satellites[0]);
  }, [initSensorsForSatellites, satellites]);

  useEffect(() => {
    if (addSatellite && addSatellite.length > 0) {
      setSelectableSatellites(filteredSatellites);
      setSatellite(filteredSatellites[0]);
      initSensorsForSatellites(filteredSatellites[0]);
    } else {
      setSelectableSatellites(filteredSatellites);
      setSatellite(filteredSatellites[0]);
      initSensorsForSatellites(filteredSatellites[0]);
    }
  }, [addSatellite, filteredSatellites, initSensorsForSatellites]);

  const handleSatelliteValueChange = useCallback(
    (satelliteChange?: TaskingSatellite) => {
      if (!satelliteChange) return;

      const newSensors: Sensor[] = [];

      SENSOR_LIST.map((value) => {
        if (value.satellite === satelliteChange.mission_id) {
          newSensors.push(value);
          setSensor(value);
        }
      });
      const sortedSensorList = _.sortBy(newSensors, ['sensorId']);
      setSensorAsPerSatellite(sortedSensorList);
      setSatellite(satelliteChange);
    },
    []
  );

  const addInstruments = useCallback(() => {
    let satelliteArray;
    if (addSatellite && addSatellite.length > 0) {
      satelliteArray = [...addSatellite];
      satelliteArray.push({
        id: satelliteArray[satelliteArray.length - 1].id + 1,
        satellite: satellite,
        sensor: sensor,
      });
    } else {
      satelliteArray = [{ id: 0, satellite: satellite, sensor: sensor }];
    }

    setSelectableSatellites(filteredSatellites);
    setAddSatellite(satelliteArray);
  }, [addSatellite, filteredSatellites, satellite, sensor, setAddSatellite]);

  const addAllInstruments = useCallback(() => {
    setAddSatellite(
      selectableSatellites.map((satelliteToAdd, index) => {
        return {
          id: index,
          satellite: satelliteToAdd,
          sensor: SENSOR_LIST.filter(
            (sensorToAdd) => sensorToAdd.satellite === satellite?.mission_id
          )[0],
        };
      })
    );
  }, [satellite?.mission_id, selectableSatellites, setAddSatellite]);

  const removeInstrument = useCallback(
    (i: number) => {
      if (!addSatellite) return;
      const satelliteArray = [...addSatellite];
      const updatedArray = satelliteArray.filter(
        (instrument) => instrument.id !== i
      );

      setSelectableSatellites(updatedArray as unknown as TaskingSatellite[]);
      satelliteArray.length > 0 && setAddSatellite(updatedArray);
    },
    [addSatellite, setAddSatellite]
  );

  const hasDuplicates = useCallback(
    (arr?: IInstrument[]) => {
      if (!arr) return false;
      return _.find(arr, { satellite: satellite, sensor: sensor })
        ? true
        : false;
    },
    [satellite, sensor]
  );

  return (
    <div className="color-item">
      <div className="flex justify-between ml-2 items-center">
        <div className="whitespace-nowrap font-bold">
          {translate('datacosmos.tasking.new.satellites')}
        </div>

        <InstrumentFilters
          isFilterOpen={isFilterOpen}
          gsd={gsd}
          selectedDataTypes={selectedDataTypes}
          setGsd={setGsd}
          handleDataTypeRemove={handleDataTypeRemove}
          handleDataTypeSelect={handleDataTypeSelect}
          setIsFilterOpen={setIsFilterOpen}
          getActiveFiltersCount={getActiveFiltersCount}
        />
      </div>
      <div className="flex pb-2 gap-px items-center border-b border-neutral-500 dark:border-neutral-700">
        <div
          className={classNames('flex gap-px w-full', {
            'border-[1px] border-warning': isTaskingError,
          })}
        >
          <Tooltip
            content={
              !selectableSatellites.length
                ? 'No satellites with permission for tasking'
                : 'Select satellite'
            }
            delay={0}
            closeDelay={0}
          >
            <Select2
              items={selectableSatellites}
              onSelectionChange={(key) => {
                setIsTaskingError(false);
                handleSatelliteValueChange(
                  satellites.find(({ name }) => name === key)
                );
              }}
              selectedKey={satellite?.name}
              fill
              filterable
              filterHandler={(query, item) =>
                item?.name.toLowerCase().includes(query.toLowerCase())
              }
              data-testid="select-satellite-button"
              isDisabled={!selectableSatellites?.length}
              className={
                !selectableSatellites?.length ? 'opacity-50' : 'initial'
              }
            >
              {(item) => (
                <Item key={item?.name} data-testid="satellite-dropdown-item">
                  {item?.name}
                </Item>
              )}
            </Select2>
          </Tooltip>

          <Tooltip
            content={
              !sensorAsPerSatellite.length
                ? 'No sensors with permission for tasking'
                : 'Select sensor'
            }
            delay={0}
            closeDelay={0}
          >
            <Select2
              items={sensorAsPerSatellite}
              onSelectionChange={(key) => {
                setIsTaskingError(false);
                const selection = SENSOR_LIST.find((s) => s?.sensorId === key);
                if (selection) setSensor(selection);
              }}
              selectedKey={sensor?.sensorId}
              fill
              filterable
              filterHandler={(query, item) =>
                item?.sensorId.toLowerCase().includes(query.toLowerCase())
              }
              isDisabled={!sensorAsPerSatellite?.length}
              className={
                !sensorAsPerSatellite?.length ? 'opacity-50' : 'initial'
              }
            >
              {(item) => <Item key={item?.sensorId}>{item?.sensorId}</Item>}
            </Select2>
          </Tooltip>
        </div>

        <Button
          text={translate('datacosmos.tasking.new.instruments.add')}
          disabled={
            hasDuplicates(addSatellite) || filteredSatellites.length === 0
          }
          onPress={() => addInstruments()}
          className="color-item h-full"
        />

        <Button
          text={translate('datacosmos.tasking.new.instruments.addAll')}
          disabled={filteredSatellites.length === 0}
          onPress={() => addAllInstruments()}
          className="color-item h-full"
        />
      </div>
      <div className="flex flex-col gap-px">
        {addSatellite && addSatellite.length > 0 ? (
          addSatellite.map((sat) => (
            <div
              key={sat.id}
              className="flex gap-2 items-center bg-item pr-2 dark:bg-item-dark dark:text-item-dark-contrast"
            >
              <InfoCard
                showInfoOn="hover"
                cardContent={
                  <div data-testid="instrument-name">{sat.satellite?.name}</div>
                }
                info={{
                  text: translate(
                    `datacosmos.tooltips.tasking.satelliteDescriptions.${sat.satellite?.name.toUpperCase()}` as unknown as TemplateStringsArray
                  ),
                  iconSize: 16,
                }}
                className="bg-transparent w-full hover:bg-transparent text-sm"
                onClick={() => {}}
                absoluteInfoPosition
              />
              <InfoCard
                showInfoOn="hover"
                cardContent={<div>{sat.sensor.sensorId}</div>}
                info={{
                  text: translate(
                    `datacosmos.tooltips.tasking.sensorDescriptions.${sat.sensor.sensorId}` as unknown as TemplateStringsArray
                  ),
                  iconSize: 16,
                }}
                className="bg-transparent w-full hover:bg-transparent text-sm"
                onClick={() => {}}
                absoluteInfoPosition
              />

              <IconButton
                icon="Trash"
                onPress={() => removeInstrument(sat.id)}
              />
            </div>
          ))
        ) : (
          <NonIdealState
            icon={BpIcons.INFO_SIGN}
            title={
              <span className="dark:bg-item-dark dark:text-item-dark-contrast">
                {translate(
                  'datacosmos.tasking.new.instruments.errors.noSatellitesSelected'
                )}
              </span>
            }
            description="Select satellites above to search for opportunities"
            className="py-4 dark:bg-item-dark dark:text-item-dark-contrast"
          />
        )}
      </div>
    </div>
  );
};
