import type { ISummaryData } from '../../constants/summary/constants';

export const convertGSAnalysisToCSVRows = (data: ISummaryData) => {
  const stationResults: any[] = [];

  data.data.children[2].children.forEach((stationAnalysis) => {
    stationResults.push({
      station: stationAnalysis.title,
      event:
        stationAnalysis.children &&
        stationAnalysis.children[0].components[1].cells[1],
      revisit:
        stationAnalysis.children &&
        stationAnalysis.children[0].components[1].cells[2],
    });
  });

  const csvRows: string[] = [];

  // Heading
  csvRows.push(
    'Station Name;Type;Number;Minimum [s];Maximum [s];Mean [s];Std. Dev. [s]'
  );

  // Body
  stationResults.forEach((stationResult) => {
    const eventInfo = stationResult.event
      .map((eventField: any) => eventField.value)
      .join(';');
    const revisitInfo = stationResult.revisit
      .map((eventField: any) => eventField.value)
      .join(';');

    csvRows.push(`${stationResult.station};${eventInfo}`);
    csvRows.push(`${stationResult.station};${revisitInfo}`);
  });

  return csvRows;
};

export const convertAnnexToCSVRows = (data: ISummaryData) => {
  const annexIndex: number = data.data.children.length - 1;
  const csvRows: string[] = [];

  data.data.children[annexIndex].components[0].cells.forEach((row) => {
    const cellValues = row.map((cell: any) => cell.value).join(';');

    csvRows.push(`${cellValues}`);
  });

  return csvRows;
};
