import { useState, useCallback, useEffect } from 'react';
import type {
  PermissionScope,
  PermissionType,
} from 'api/administration/permissions';
import { getAuthorisationResults } from 'api/administration/service';
import { usePermissionCache } from 'utils/context/PermissionCacheProvider';

export type ActionScope = PermissionScope | PermissionScope[];

export type ICheckPermissions =
  | {
      type: 'global' | undefined;
      actionScope: ActionScope | undefined;
      id?: undefined;
      routing?: boolean;
    }
  | {
      type: Exclude<PermissionType, 'global'> | undefined;
      actionScope: ActionScope | undefined;
      id: number | string | undefined;
      routing?: boolean;
    };

export const useAuthorisation = (
  userId: string | undefined,
  token: string | undefined
) => {
  const { cachePermission, getIsPermissionCached, retrievePermission } =
    usePermissionCache();

  const checkSinglePermission = useCallback(
    async (perms: ICheckPermissions) => {
      const { type, actionScope, id } = perms;

      if (!token) {
        return false;
      }

      if (!perms) {
        return false;
      }

      if (!userId) {
        return false;
      }

      const cached = getIsPermissionCached({ actionScope, type, id }, userId);

      if (cached) {
        return retrievePermission({ actionScope, type, id }, userId);
      }

      const { data: hasPerm } = await getAuthorisationResults({
        params: {
          userId,
        },

        body: {
          subjectToken: token,
          intents: [
            {
              scope: actionScope as PermissionScope,
              resource: {
                id,
                type: type ?? 'global',
              },
            },
          ],
        },
      });

      if (hasPerm?.every((p) => p)) {
        cachePermission(
          userId,
          { actionScope, type: type ?? 'global', id },
          true
        );
      }

      if (!hasPerm) {
        return false;
      }

      return hasPerm[0];
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [token, userId]
  );

  const checkArrayPermissions = useCallback(
    async (perms: ICheckPermissions[]) => {
      if (!token) {
        return [];
      }

      if (perms.length === 0) {
        return [];
      }

      if (!userId) {
        return [];
      }

      const cached = perms.every((p) => getIsPermissionCached(p, userId));

      if (cached && perms.length > 0) {
        return perms.map((p) => retrievePermission(p, userId));
      }

      const { data: hasPerm } = await getAuthorisationResults({
        params: {
          userId,
        },

        body: {
          subjectToken: token,
          intents: perms.map(({ actionScope, type, id }) => ({
            scope: actionScope as PermissionScope,
            resource: {
              id: id ?? null,
              type: type ?? 'global',
            },
          })),
        },
      });

      perms.forEach(({ actionScope, type, id }) => {
        if (hasPerm?.every((p) => p)) {
          cachePermission(
            userId,
            { actionScope, type: type ?? 'global', id },
            true
          );
        }
      });

      if (!hasPerm) {
        return [];
      }

      return hasPerm;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [token, userId]
  );

  type Overload = {
    (perms: ICheckPermissions[]): Promise<boolean[]>;
    (perms: ICheckPermissions): Promise<boolean>;
  };

  const checkPermissions: Overload = useCallback(
    async (perms) => {
      if (Array.isArray(perms)) {
        const p = await checkArrayPermissions(perms);
        return p;
      } else {
        const p = await checkSinglePermission(perms);
        return p;
      }
    },
    [checkArrayPermissions, checkSinglePermission]
  ) as Overload;

  const [loading, setLoading] = useState(true);
  const [isAllowedToReadRoles, setIsAllowedToReadRoles] =
    useState<boolean>(false);
  const [isAllowedToReadUsersRoles, setIsAllowedToReadUsersRoles] =
    useState<boolean>(false);
  const [isAllowedToOpenMissionSelector, setIsAllowedToOpenMissionSelector] =
    useState<boolean>(false);

  useEffect(() => {
    const checkRoleAssignmentAndOrgPerms = async () => {
      const hasPerm = await checkPermissions([
        {
          type: 'global',
          actionScope: 'role:read',
        },
        {
          type: 'global',
          actionScope: 'user:assignment:write',
        },
        {
          type: 'global',
          actionScope: 'organisation:read:own',
        },
      ]);
      setIsAllowedToReadRoles(hasPerm[0]);
      setIsAllowedToReadUsersRoles(hasPerm[1]);
      setIsAllowedToOpenMissionSelector(hasPerm[2]);
    };

    void checkRoleAssignmentAndOrgPerms();
    setLoading(false);
  }, [checkPermissions]);

  return {
    loading,
    checkPermissions,
    isAllowedToReadRoles,
    isAllowedToReadUsersRoles,
    isAllowedToOpenMissionSelector,
  };
};
