import type { WithTranslation } from 'react-i18next';
import { withTranslation } from 'react-i18next';
import type { ReactElement } from 'react';
import { Pre } from '@blueprintjs/core';
import { renderName } from 'components/popUp/geometryForm/subForms/renderName';
import { renderGroupName } from 'components/popUp/geometryForm/subForms/renderGroupName';
import { parseControlNames } from 'components/popUp/geometryForm/subForms/parseControlNames';
import type { IOption } from 'components/inputField/SelectField';
import { SatellitesOrbitConstraints } from 'constants/msd/geometry/satelliteOrbits/constants';

interface ISubFormProps extends WithTranslation {
  controlNames: (string[][] | string)[];
  astrum: string;
  change?: Function;
}

const renderSubForm = (props: ISubFormProps): ReactElement => {
  const { t, change, controlNames, astrum } = props;
  const { parsedControlNames, selectedGroup, setSelectedGroup } =
    parseControlNames({ controlNames, t });
  const renderedControlNames = parsedControlNames.map((cN, index) => {
    const type = typeof cN;
    switch (type) {
      case 'string': {
        return renderName({
          name: cN as string,
          change,
          constraints: SatellitesOrbitConstraints,
          astrum,
        });
      }
      case 'object': {
        return renderGroupName({
          index,
          groupNamesWithOption: cN as {
            options: IOption[];
            groupNames: string[][];
          },
          selectedGroup,
          setSelectedGroup,
          change,
          constraints: SatellitesOrbitConstraints,
          astrum,
        });
      }
    }
    return undefined;
  });
  return (
    <Pre>
      <table className="bp4-html-table bp4-small bp4-fill walker-constellation table">
        <thead>
          <tr>
            <th className="parameter">
              {t('constraints.WALKER_CONSTELLATION.Parameter')}
            </th>
            <th className="value">
              {t('constraints.WALKER_CONSTELLATION.Value')}
            </th>
            <th className="measurement" />
            <th className="optimisation">
              {t('constraints.WALKER_CONSTELLATION.Optimise')}
            </th>
            <th className="constraints">
              {t('constraints.WALKER_CONSTELLATION.Boundaries')}
            </th>
          </tr>
        </thead>
        <tbody>{renderedControlNames}</tbody>
      </table>
    </Pre>
  );
};
export const SubForm = withTranslation()(renderSubForm);
