import React from 'react';
import styles from './styles.module.scss';
import { PAYLOAD_TYPE } from '../../../../constants/moduleData/types';
import FieldWithErrorCallout from '../../../../components/formik/FieldWithErrorCallout';

interface IProps {
  readOnly: boolean;
  handleChange: Function;
}

interface IFormField {
  field: { value: string };
}

const GeneralProperties: React.FunctionComponent<IProps> = ({
  readOnly,
  handleChange,
}) => {
  return (
    <div>
      <div>
        <h3 className={styles.title}>General Characteristics</h3>
      </div>
      <div className={styles.body}>
        <div className={styles.row}>
          <div className={styles.labelColumn}>
            <span>Name</span>
          </div>
          <FieldWithErrorCallout
            type="text"
            name="id"
            className={`bp4-input ${styles.customBp3Cell}`}
            disabled={readOnly}
          />
        </div>
        <div className={styles.row}>
          <div className={styles.labelColumn}>
            <span>Type</span>
          </div>

          <FieldWithErrorCallout
            type="text"
            name="type"
            className={`bp4-input ${styles.customBp3Cell}`}
            disabled={readOnly}
            component={({ field: { value } }: IFormField) => (
              <div className={`bp4-select ${styles.customBp3Cell}`}>
                {/* eslint-disable-next-line */}
                <select
                  name="type"
                  value={value}
                  //@ts-ignore
                  onChange={handleChange}
                  disabled={readOnly}
                >
                  {Object.entries(PAYLOAD_TYPE).map(([typeCode, typeValue]) => (
                    <option key={typeCode} value={typeCode}>
                      {typeValue}
                    </option>
                  ))}
                </select>
              </div>
            )}
          />
        </div>
      </div>
    </div>
  );
};

export default GeneralProperties;
