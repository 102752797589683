interface IConnectedMetrics {
  [key: string]: {
    toOriginal: (value: number) => number;
    fromOriginal: (value: number) => number;
  };
}

export const connectedMetrics = (radius: number): IConnectedMetrics => {
  return {
    ['semimajorAxis']: {
      toOriginal: (value: number) => value,
      fromOriginal: (value: number) => value,
    },
    ['altitude']: {
      toOriginal: (value: number) => value - radius,
      fromOriginal: (value: number) => value + radius,
    },
  };
};
