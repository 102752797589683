import React, { useEffect, useMemo, useState, useCallback } from 'react';
import { Spinner } from '@blueprintjs/core';
import { useTasking } from 'datacosmos/stores/TaskingProvider';
import { TaskingDate } from 'datacosmos/components/Tasking/TaskingDate/TaskingDate';
import { Instruments } from 'datacosmos/components/Tasking/Instruments';
import { AreaOfInterest } from 'datacosmos/components/Tasking/AreaOfInterest/AreaOfInterest';
import {
  SearchSteps,
  Steps,
  MAX_ALLOWED_AOI_SIZE_M2,
} from 'datacosmos/components/Tasking/helpers';
import { useMapLayers } from 'datacosmos/stores/MapLayersProvider';
import { useMap } from 'datacosmos/stores/MapProvider';
import type { PolygonLayer } from 'datacosmos/entities/polygonLayer';
import { TaskingType } from 'datacosmos/components/Tasking/TaskingType';
import { Parameters } from 'datacosmos/components/Tasking/ObjectivesAndConstraints/Parameters';
import bbox from '@turf/bbox';
import { OpportunityCalendar } from 'datacosmos/components/Tasking/TaskingDate/OpportunityCalendar';
import { useLocalisation } from 'utils/hooks/useLocalisation';
import type { TaskingSatellite } from 'api/tasking/types';
import { PrimaryButton } from 'opencosmos-ui';

type SearchOpportunityProps = {
  setSwitchStep: (step: Steps) => void;
  searchSteps: SearchSteps[];
  setSearchSteps: React.Dispatch<React.SetStateAction<SearchSteps[]>>;
  switchStep: Steps;
  satellitesForTasking: TaskingSatellite[];
  setIsRequestModalOpen: (value: boolean) => void;
};

export const SearchOpportunity = ({
  setSearchSteps,
  searchSteps,
  switchStep,
  setSwitchStep,
  satellitesForTasking,
  setIsRequestModalOpen,
}: SearchOpportunityProps) => {
  const {
    searchOpportunities,
    dateTo,
    setDateTo,
    dateFrom,
    setDateFrom,
    addSatellite,
    setAddSatellite,
    isFetching,
    SZAConstraints,
    setSZAConstraints,
    OZAConstraints,
    setOZAConstraints,
    isSZA,
    setIsSZA,
    setSZAError,
    hasSZAError,
    isOZAObjective,
    isSZAObjective,
    setIsOZAObjective,
    setIsSZAObjective,
    rollAngleConstraint,
    isRollAngle,
    setRollAngleConstraint,
    setIsRollAngle,
    setRollAngleError,
    hasRollAngleError,
    regions,
  } = useTasking();
  const { layers, replaceLayer, addLayer, removeLayer } = useMapLayers();

  const { drawPolygon, editPolygon, disableEditing, setViewToFitBbox } =
    useMap();

  const { translate } = useLocalisation();

  const makeRegionsEditable = useCallback(() => {
    regions.forEach((region) => {
      if (region.modifiers?.uneditable) {
        replaceLayer(region.cloneWithModifiers({ uneditable: false }));
      }
    });
  }, [regions, replaceLayer]);

  const [isTaskingError, setIsTaskingError] = useState<boolean>(false);

  const isRegionValid = useMemo(
    () => !regions.some((region) => region.areaInM2 > MAX_ALLOWED_AOI_SIZE_M2),
    [regions]
  );

  useEffect(() => {
    if (addSatellite && addSatellite.length > 0) {
      setIsTaskingError(false);
    }
  }, [addSatellite]);

  useEffect(() => {
    makeRegionsEditable();
  }, [makeRegionsEditable]);

  const includesDateAndInstruments = searchSteps.includes(
    SearchSteps.SelectTaskingDateAndInstruments
  );

  useEffect(() => {
    if (regions.length > 0 && isRegionValid) {
      if (!includesDateAndInstruments) {
        setSearchSteps((prev) => [...prev, SearchSteps.SelectTaskingType]);
      }
    } else {
      setSearchSteps([SearchSteps.AddAOI]);
      setSwitchStep(Steps.DataAcquisition);
    }
  }, [
    includesDateAndInstruments,
    isRegionValid,
    regions.length,
    setSearchSteps,
    setSwitchStep,
  ]);

  const centerRegion = useCallback(
    (region: PolygonLayer) => {
      setViewToFitBbox(bbox(region.data));
    },
    [setViewToFitBbox]
  );

  return (
    <>
      <div className="search-opportunity">
        <div className="search-form">
          <div>
            {searchSteps.includes(SearchSteps.AddAOI) && (
              <AreaOfInterest
                disabled={
                  switchStep === Steps.ListOfOpportunities || isFetching
                }
                regions={regions}
                replaceLayer={replaceLayer}
                drawPolygon={drawPolygon}
                addLayer={addLayer}
                removeLayer={removeLayer}
                editPolygon={editPolygon}
                disableEditing={disableEditing}
                isFetching={isFetching}
                centerRegion={centerRegion}
                isRegionValid={isRegionValid}
              />
            )}

            {searchSteps.includes(SearchSteps.SelectTaskingType) && (
              <TaskingType
                disabled={
                  switchStep === Steps.ListOfOpportunities || isFetching
                }
                setSearchSteps={setSearchSteps}
                setSwitchStep={setSwitchStep}
                switchStep={switchStep}
              />
            )}

            {searchSteps.includes(
              SearchSteps.SelectTaskingDateAndInstruments
            ) && (
              <div className="flex flex-col gap-4 w-full">
                <Parameters
                  disabled={
                    switchStep === Steps.ListOfOpportunities || isFetching
                  }
                  switchStep={switchStep}
                  constraintsProps={{
                    isRollAngle,
                    hasRollAngleError,
                    rollAngleConstraint,
                    setIsRollAngle,
                    setIsRollAngleError: setRollAngleError,
                    setRollAngleConstraint,
                    isSZA,
                    setIsSZA,
                    setSZAConstraints,
                    setSZAError,
                    SZAConstraints,
                    SZAError: hasSZAError,
                  }}
                  objectivesProps={{
                    isOZAObjective,
                    isSZAObjective,
                    OZAConstraints,
                    setIsOZAObjective,
                    setIsSZAObjective,
                    setOZAConstraints,
                    setSZAConstraints,
                    SZAConstraints,
                  }}
                />

                {switchStep === Steps.ListOfOpportunities ? (
                  <OpportunityCalendar
                    setDateTo={setDateTo}
                    setDateFrom={setDateFrom}
                    dateFrom={dateFrom}
                    dateTo={dateTo}
                    layers={layers}
                  />
                ) : (
                  <TaskingDate
                    setDateTo={setDateTo}
                    setDateFrom={setDateFrom}
                    dateFrom={dateFrom}
                    dateTo={dateTo}
                  />
                )}
                {switchStep !== Steps.ListOfOpportunities && (
                  <Instruments
                    satellites={satellitesForTasking}
                    setAddSatellite={setAddSatellite}
                    addSatellite={addSatellite}
                    isTaskingError={isTaskingError}
                    setIsTaskingError={setIsTaskingError}
                  />
                )}
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="tasking-footer">
        {searchSteps.includes(SearchSteps.SelectTaskingDateAndInstruments) &&
          switchStep !== Steps.ListOfOpportunities && (
            <div className="p-4">
              <PrimaryButton
                className="flex bg-item w-full h-12 items-center justify-center dark:bg-item-dark dark:text-item-dark-contrast mb-4"
                onPress={async () => {
                  if (switchStep === Steps.AutomatedTasking) {
                    setIsRequestModalOpen(true);
                  } else {
                    regions.forEach((r) => {
                      disableEditing(r.getLeafletLayerMetadata());
                      replaceLayer(r.cloneWithModifiers({ uneditable: true }));
                    });
                    const hasWorked = await searchOpportunities();
                    if (hasWorked) {
                      setSwitchStep(Steps.ListOfOpportunities);
                      setIsTaskingError(false);
                    } else {
                      makeRegionsEditable();
                      setIsTaskingError(true);
                    }
                  }
                }}
                text={
                  // eslint-disable-next-line no-nested-ternary
                  isFetching ? (
                    <Spinner className="float-spinner" size={20} />
                  ) : switchStep === Steps.AutomatedTasking ? (
                    translate('datacosmos.tasking.new.request')
                  ) : (
                    translate('datacosmos.tasking.new.search')
                  )
                }
              />
            </div>
          )}
      </div>
    </>
  );
};
