import type {
  IAddSatelliteOperationalMode,
  IRemoveSatelliteOperationalMode,
  IUpdateSatelliteOperationalMode,
  IAddDefaultSatellitePayloadModeAction,
  IRemoveDefaultSatellitePayloadModeAction,
  IUpdateSelectedPayloads,
  IUpdateSelectedPlatform,
  IUploadSatelliteConfiguration,
} from '../../constants/satellite/actionTypes';
import {
  ADD_SATELLITE_OPERATIONAL_MODE,
  REMOVE_SATELLITE_OPERATIONAL_MODE,
  UPDATE_SATELLITE_OPERATIONAL_MODE,
  ADD_DEFAULT_SATELLITE_PAYLOAD_MODE,
  REMOVE_DEFAULT_SATELLITE_PAYLOAD_MODE,
  UPDATE_SELECTED_SATELLITE_PAYLOAD,
  UPDATE_SELECTED_SATELLITE_PLATFORM,
  UPLOAD_SATELLITE_CONFIGURATION,
} from '../../constants/satellite/actionTypes';
import type { IPointOfInterest } from '../../constants/pointsOfInterest/actionTypes';
import type { IRegionOfInterest } from '../../constants/regionsOfInterest/actionTypes';
import type {
  IPayloadModeConfiguration,
  IPlatformModules,
} from '../../constants/satellite/types';
import type { ISelectedPayloads } from '../../constants/moduleData/types';

export const addDefaultSatelliteOperationalMode = (
  name: string
): IAddDefaultSatellitePayloadModeAction => {
  return {
    type: ADD_DEFAULT_SATELLITE_PAYLOAD_MODE,
    name,
  };
};

export const removeDefaultSatelliteOperationalMode = (
  name: string
): IRemoveDefaultSatellitePayloadModeAction => {
  return {
    type: REMOVE_DEFAULT_SATELLITE_PAYLOAD_MODE,
    name,
  };
};

export const addSatelliteOperationalMode = (
  values: {
    name: string;
  } & IPayloadModeConfiguration
): IAddSatelliteOperationalMode => {
  return {
    type: ADD_SATELLITE_OPERATIONAL_MODE,
    values,
  };
};

export const updateSatelliteOperationalMode = (
  values: {
    name: string;
    oldName: string;
  } & IPayloadModeConfiguration
): IUpdateSatelliteOperationalMode => {
  return {
    type: UPDATE_SATELLITE_OPERATIONAL_MODE,
    values,
  };
};

export const removeSatelliteOperationalMode = (
  name: string,
  POIs: IPointOfInterest[],
  ROIs: IRegionOfInterest[]
): IRemoveSatelliteOperationalMode => {
  return {
    type: REMOVE_SATELLITE_OPERATIONAL_MODE,
    name,
    POIs,
    ROIs,
  };
};

export const updateSatellitePayloads = (
  names: string[],
  allModuleData: ISelectedPayloads
): IUpdateSelectedPayloads => {
  return {
    type: UPDATE_SELECTED_SATELLITE_PAYLOAD,
    names,
    allModuleData,
  };
};

export const updateSatellitePlatform = (
  names: IPlatformModules
): IUpdateSelectedPlatform => {
  return {
    type: UPDATE_SELECTED_SATELLITE_PLATFORM,
    names,
  };
};

export const uploadSatelliteConfiguration = (
  sallite: any
): IUploadSatelliteConfiguration => {
  return {
    type: UPLOAD_SATELLITE_CONFIGURATION,
    sallite,
  };
};
