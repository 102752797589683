import type { ReactElement } from 'react';
import React from 'react';
import { SubForm } from './SubForm';

interface IKeplerianOrbitProps {
  astrum: string;
  change?: Function;
}

const controlNames = [
  'semimajorAxis',
  'eccentricity',
  'inclination',
  'raan',
  'argumentOfPerigee',
  'trueAnomaly',
];

const renderKeplerianOrbit = (props: IKeplerianOrbitProps): ReactElement => {
  const { change, astrum } = props;
  return (
    <SubForm astrum={astrum} change={change} controlNames={controlNames} />
  );
};
export const KeplerianOrbit = renderKeplerianOrbit;
