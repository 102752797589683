import type { ThunkAction } from 'redux-thunk';
import type { AppState } from 'reducers/rootReducer';
import type { Action } from 'redux';
import type { IPointOfInterest } from 'constants/pointsOfInterest/actionTypes';
import { addPI, removePI, updatePI } from 'actions/pointsOfInterest/helpers';
import { resetPopUp, setPopUp } from 'actions/popUp/thunks';
import type { IMarkerWrapper } from 'declarations/mapDeclarations/Marker';
import { v1 as uuid } from 'uuid';
import type { IMapWrapper } from 'declarations/mapDeclarations/Map';
import { pointOfInterestDefine } from '../pointOfInterest/options';
import MarkerForm from 'components/popUp/MarkerForm';
import type { IPolygonWrapper } from 'declarations/mapDeclarations/Polygon';
import type {
  IPoint,
  IRegionOfInterest,
} from 'constants/regionsOfInterest/actionTypes';
import { regionOfInterestDefine } from '../regionOfInterest/options';
import { addRI, removeRI, updateRI } from 'actions/regionsOfInterest/helpers';
import PolygonForm from 'components/popUp/PolygonForm';
import { setFocusPointOfInterest } from '../pointOfInterest/events';
import { setFocusRegionOfInterest } from '../regionOfInterest/events';
import { setFocus } from 'actions/focus/thunks';
import { addContextMenu as pointContextMenu } from '../contextMenu/pointsOfInterest/services';
import { addContextMenu as regionContextMenu } from '../contextMenu/regionsOfInterest/services';
import { addContextMenu as groundStationContextMenu } from '../contextMenu/groundStations/services';
import { setPathsEvents } from '../regionOfInterest/paths/services';
import polygonSelfIntersecting from 'utils/polygonSelfIntersecting';
import { toaster } from 'toaster';
import type { IGroundStation } from 'constants/groundStations/actionTypes';
import { groundStationDefine } from '../groundStation/options';
import {
  setFocusGroundStation,
  switchSelectedGroundStation,
} from '../groundStation/events';
import { addGS, removeGS, updateGS } from 'actions/groundStations/thunks';
import { generateName } from 'utils/common/generateName';
import { GROUND_STATION_FORM_NAME } from 'constants/popUp/constants';
import { generateOrder } from 'utils/common/generateOrderIndex';
import { generateId } from 'utils/common/generateId';
import { selectLastSatelliteMode } from 'selectors/satellite';
import { FocusTypes } from 'constants/focus/actionTypes';
import { DEFAULT_SATELLITE_MODE } from 'constants/satellite/constants';

export const pointCompleted =
  (
    map: IMapWrapper,
    marker: IMarkerWrapper
  ): ThunkAction<void, AppState, null, Action<string>> =>
  async (dispatch, state): Promise<void> => {
    if (map.drawingManager.isGroundStation) {
      marker.id = generateId(FocusTypes.GS_SUB_ID);
      marker.altitude = 0;
      marker.elevationAngle = 30;
      marker.custom = true;

      const groundStation: IGroundStation = {
        id: marker.id,
        name: generateName(state().groundStations, 'GS'),
        lat: marker.getPosition().lat(),
        lon: marker.getPosition().lng(),
        altitude: marker.altitude,
        elevationAngle: marker.elevationAngle,
        select: false,
        optimize: false,
        custom: true,
        astrum: state().astrum.current.planet,
      };
      marker.setOptions(groundStationDefine());
      map.groundStations.push(marker);
      map.drawingManager.isGroundStation = false;
      map.drawingManager.setDrawingMode(null);

      const boundContextMenu = (
        googleMouseEvent: google.maps.MouseEvent
      ): void =>
        dispatch(
          groundStationContextMenu.bind(null, marker, map)(googleMouseEvent)
        );
      const boundSetFocus = (): void =>
        dispatch(setFocusGroundStation(groundStation));
      const boundSwitchSelected = (): void =>
        dispatch(switchSelectedGroundStation.bind(null, marker)());

      marker.addListener('click', boundSetFocus);
      marker.addListener('rightclick', boundContextMenu);
      marker.addListener('dblclick', boundSwitchSelected);

      await dispatch(addGS(groundStation));
      dispatch(
        setFocus({
          type: FocusTypes.GS_SUB_ID,
          id: groundStation.id,
          needScroll: true,
          withPopUp: true,
        })
      );

      dispatch(
        setPopUp({
          component: MarkerForm,
          visible: true,
          classNames: ['short-container'],
          data: groundStation,
          otherProps: {
            type: FocusTypes.GS_SUB_ID,
            form: GROUND_STATION_FORM_NAME,
          },
          functions: {
            onSubmit: (groundStationLocal: IGroundStation): void =>
              dispatch(updateGS({ ...groundStation, ...groundStationLocal })),
            onClose: (groundStationLocal: IPointOfInterest): void =>
              dispatch(removeGS(groundStationLocal.id)),
          },
        })
      );
    } else {
      const stateData = state();
      const orderIndex = generateOrder([
        FocusTypes.PI_SUB_ID as string,
        FocusTypes.RI_SUB_ID as string,
      ]);
      marker.id = generateId(FocusTypes.PI_SUB_ID);
      marker.altitude = 0;
      marker.elevationAngle = 30;
      const pointOfInterest: IPointOfInterest = {
        id: marker.id,
        name: generateName(stateData.pointsOfInterest, 'Point'),
        lat: marker.getPosition().lat(),
        lon: marker.getPosition().lng(),
        altitude: marker.altitude,
        elevationAngle: marker.elevationAngle,
        orderIndex,
        astrum: stateData.astrum.current.planet,
        satelliteMode: DEFAULT_SATELLITE_MODE,
      };
      marker.setOptions(pointOfInterestDefine());
      map.pointsOfInterest.push(marker);
      map.drawingManager.isGroundStation = false;
      map.drawingManager.setDrawingMode(null);

      const boundContextMenu = (
        googleMouseEvent: google.maps.MouseEvent
      ): void =>
        dispatch(pointContextMenu.bind(null, marker, map)(googleMouseEvent));
      const boundSetFocus = (): void =>
        dispatch(setFocusPointOfInterest(pointOfInterest));

      marker.addListener('click', boundSetFocus);
      marker.addListener('rightclick', boundContextMenu);

      dispatch(addPI(pointOfInterest));
      dispatch(
        setFocus({
          type: FocusTypes.PI_SUB_ID,
          id: pointOfInterest.id,
          needScroll: true,
          withPopUp: true,
        })
      );
      dispatch(
        setPopUp({
          component: MarkerForm,
          visible: true,
          classNames: ['short-container'],
          data: pointOfInterest,
          otherProps: {
            type: FocusTypes.PI_SUB_ID,
          },
          functions: {
            onSubmit: (pointOfInterest: IPointOfInterest): void =>
              dispatch(updatePI(pointOfInterest)),
            onClose: (pointOfInterest: IPointOfInterest): void =>
              dispatch(removePI(pointOfInterest.id)),
          },
        })
      );
    }
  };

export const regionCompleted =
  (
    map: IMapWrapper,
    region: IPolygonWrapper
  ): ThunkAction<void, AppState, null, Action<string>> =>
  async (dispatch, state): Promise<void> => {
    const stateData = state();
    const { focus, regionsOfInterest } = stateData;
    const orderIndex = generateOrder([
      FocusTypes.PI_SUB_ID,
      FocusTypes.RI_SUB_ID,
    ]);
    if (focus.type === FocusTypes.RI_SUB_ID) {
      const regionOfInterest = {
        ...regionsOfInterest.list.find((reg): boolean => reg.id === focus.id),
      };
      const additionalPath = region
        .getPath()
        .getArray()
        .map((LatLng): IPoint => {
          return {
            lat: LatLng.lat(),
            lng: LatLng.lng(),
          };
        });
      region.setMap(null);
      region = null;

      let selfIntersecting = false;
      if (additionalPath.length >= 4) {
        additionalPath.forEach((point, indexPoint): void => {
          if (
            polygonSelfIntersecting(
              { vertex: indexPoint, full: true },
              additionalPath
            )
          ) {
            selfIntersecting = true;
          }
        });
      }

      if (!selfIntersecting) {
        regionOfInterest.paths.push(additionalPath);
        dispatch(updateRI(regionOfInterest));
      } else {
        const message = 'Region is self intersecting!';
        toaster.show({ icon: 'delete', intent: 'danger', message });
      }
      dispatch(resetPopUp(false));
      dispatch(
        setPopUp({
          component: PolygonForm,
          visible: true,
          classNames: ['short-container'],
          data: regionOfInterest,
          otherProps: {
            type: FocusTypes.RI_SUB_ID,
          },
          functions: {
            onSubmit: async (
              regionOfInterest: IRegionOfInterest
            ): Promise<void> => {
              map.drawingManager.isGroundStation = false;
              map.drawingManager.setDrawingMode(null);
              await dispatch(updateRI(regionOfInterest));
            },
            onClose: (): void => {},
            addRegion: (): void => {
              map.drawingManager.setDrawingMode(
                google.maps.drawing.OverlayType.POLYGON
              );
            },
            panToBounds: (bound: google.maps.LatLngBoundsLiteral): void => {
              map.panToBounds(bound, 0);
            },
          },
        })
      );
    } else {
      const stateData = state();
      region.id = `${FocusTypes.RI_SUB_ID}-${uuid()}`;
      const regionOfInterestData: IRegionOfInterest = {
        id: region.id,
        name: generateName(stateData.regionsOfInterest, 'Region'),
        astrum: stateData.astrum.current.planet,
        paths: region
          .getPaths()
          .getArray()
          .map((path): IPoint[] => {
            return path.getArray().map((LatLng): IPoint => {
              return {
                lat: LatLng.lat(),
                lng: LatLng.lng(),
              };
            });
          }),
        orderIndex,
        satelliteMode: selectLastSatelliteMode(stateData),
        elevationAngle: 75,
      };

      let selfIntersecting = false;
      regionOfInterestData.paths.forEach((path): void => {
        if (path.length < 4) {
          return undefined;
        }
        path.forEach((point, indexPoint): void => {
          if (
            polygonSelfIntersecting({ vertex: indexPoint, full: true }, path)
          ) {
            selfIntersecting = true;
          }
        });
      });
      if (selfIntersecting) {
        region.setMap(null);
        region = null;
        const message = 'Region is self intersecting!';
        toaster.show({ icon: 'delete', intent: 'danger', message });
        return undefined;
      }

      region.setOptions(regionOfInterestDefine());
      map.regionsOfInterest.push(region);
      map.drawingManager.isGroundStation = false;
      map.drawingManager.setDrawingMode(null);

      const boundContextMenu = (event: google.maps.PolyMouseEvent): void =>
        dispatch(regionContextMenu.bind(null, region, map)(event));
      const boundSetFocus = (): void =>
        dispatch(setFocusRegionOfInterest(regionOfInterestData));

      const boundSetPathsEvents = (region: IPolygonWrapper): void =>
        dispatch(setPathsEvents(region));

      boundSetPathsEvents(region);
      region.addListener('rightclick', boundContextMenu);
      region.addListener('click', boundSetFocus);

      dispatch(addRI(regionOfInterestData));
      dispatch(
        setFocus({
          type: FocusTypes.RI_SUB_ID,
          id: regionOfInterestData.id,
          needScroll: true,
          withPopUp: true,
        })
      );
      dispatch(
        setPopUp({
          component: PolygonForm,
          visible: true,
          classNames: ['short-container'],
          data: regionOfInterestData,
          otherProps: {
            type: FocusTypes.RI_SUB_ID,
          },
          functions: {
            onSubmit: async (
              regionOfInterest: IRegionOfInterest
            ): Promise<void> => {
              map.drawingManager.isGroundStation = false;
              map.drawingManager.setDrawingMode(null);
              await dispatch(updateRI(regionOfInterest));
            },
            onClose: (regionOfInterest: IRegionOfInterest): void =>
              dispatch(removeRI(regionOfInterest.id)),
            addRegion: (): void => {
              map.drawingManager.setDrawingMode(
                google.maps.drawing.OverlayType.POLYGON
              );
            },
            panToBounds: (bound: google.maps.LatLngBoundsLiteral): void => {
              map.panToBounds(bound, 0);
            },
          },
        })
      );
    }
  };
