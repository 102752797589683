import { useState } from 'react';
import type { StacItem } from 'datacosmos/types/stac-types';
import { Icon } from 'ui/Icon';
import useStacInfo from 'datacosmos/utils/hooks/useStacInfo';
import { Tooltip } from 'ui/Tooltip';
import Popover2 from '_atoms/Popover/Popover2';
import { TooltipContent } from './TooltipContent';
import { useLocalisation } from 'utils/hooks/useLocalisation';
import { Thumbnail } from './Thumbnail';
import { ResultCardButton } from './ResultCardButton';
import AssetsViewAndDownload from './AssetsViewAndDownload';
import { hasVisualHighresRoleAsset } from 'datacosmos/utils/stac';

export type ResultsListCardProps = {
  data: StacItem;
  isViewCatalog: boolean;
  isItemDisplayed: boolean;
  isAssetDisplayed: (id: string) => boolean;
  isItemInCart: boolean;
  isItemInProject: boolean;
  onMouseOver: () => void;
  onMouseOut: () => void;
  handleToggleDisplayItem: (isHighRes: boolean) => void;
  handleToggleDisplayAsset: (id: string) => void;
  handleToggleCartClick: () => void;
  handleToggleProjectClick: () => void;
  handleCenterMapClick: () => void;
};

export const ResultsListCard = (props: ResultsListCardProps) => {
  const {
    cloudCoverage,
    sunElevationAngle,
    thumbnailAsset,
    nonPreviewableAssets,
    previewableAssets,
    stacItemPrice,
    isHighResPermissionGranted,
    isCartActionDisabled,
  } = useStacInfo(props.data);

  const [isAssetsSectionOpen, setIsAssetsSectionOpen] =
    useState<boolean>(false);

  const { translate } = useLocalisation();

  let projectIcon: 'InProject' | 'AddToProject' | 'RemoveFromProject';
  projectIcon = props.isItemInProject ? 'InProject' : 'AddToProject';
  projectIcon = props.isViewCatalog ? projectIcon : 'RemoveFromProject';

  const projectTooltip = props.isItemInProject
    ? translate('datacosmos.tooltips.catalogAndItems.icons.addedToProject')
    : translate('datacosmos.tooltips.catalogAndItems.icons.addToProject');

  const cartToolTipActions = props.isItemInCart
    ? translate('datacosmos.tooltips.catalogAndItems.icons.removeFromCart')
    : translate('datacosmos.tooltips.catalogAndItems.icons.addToCart');

  const cartToolTip = isCartActionDisabled
    ? translate(
        'datacosmos.tooltips.catalogAndItems.icons.imageCannotBePurchased'
      )
    : cartToolTipActions;

  const shouldShowHiRes = hasVisualHighresRoleAsset(props.data);

  const previewToolTip = isHighResPermissionGranted
    ? translate('datacosmos.tooltips.catalogAndItems.icons.showHighResPreview')
    : translate('datacosmos.tooltips.catalogAndItems.icons.previewOnMap');

  const eyeIconToolTip = shouldShowHiRes
    ? previewToolTip
    : translate('datacosmos.tooltips.catalogAndItems.icons.previewOnMap');

  return (
    <ul
      data-testid="stac-item-card"
      className="w-full py-2 border-t-2 first:border-t-0 border-neutral-300 dark:border-neutral-700"
      onMouseOver={props.onMouseOver}
      onMouseOut={props.onMouseOut}
    >
      <li className="px-2 flex text-sm w-full min-w-0">
        <div className="flex-1 flex flex-col min-w-0 overflow-hidden">
          <ul className="flex flex-col gap-2 min-w-0">
            <Tooltip
              content={translate(
                'datacosmos.tooltips.catalogAndItems.metadata.platformName'
              )}
            >
              <li className="pl-2 flex items-center gap-2">
                <Icon kind="Satellite" className="align-middle" />{' '}
                <span className="dark:text-neutral">{props.data.title()}</span>
              </li>
            </Tooltip>

            <Tooltip
              content={translate(
                'datacosmos.tooltips.catalogAndItems.metadata.sza'
              )}
            >
              <li className="pl-2 flex items-center gap-2">
                <Icon kind="Sun" className="align-middle" />{' '}
                <span className="dark:text-neutral">{sunElevationAngle}</span>
              </li>
            </Tooltip>

            <Tooltip
              content={translate(
                'datacosmos.tooltips.catalogAndItems.metadata.cloudCoverage'
              )}
            >
              <li className="pl-2 flex items-center gap-2">
                <Icon kind="Cloud" className="align-middle" />{' '}
                <span className="dark:text-neutral">{cloudCoverage}</span>
              </li>
            </Tooltip>

            <Tooltip
              content={translate(
                'datacosmos.tooltips.catalogAndItems.metadata.location'
              )}
            >
              <li
                className="pl-2 flex items-center gap-2"
                role="button"
                onClick={props.handleCenterMapClick}
              >
                <Icon kind="Pin" className="align-middle" />{' '}
                <span className="dark:text-neutral">
                  {props.data.location()}
                </span>
              </li>
            </Tooltip>

            <Tooltip
              content={translate(
                'datacosmos.tooltips.catalogAndItems.metadata.price'
              )}
            >
              <li className="pl-2 flex items-center gap-2">
                <Icon kind="Price" className="align-middle" />{' '}
                <span className="dark:text-neutral">{stacItemPrice}</span>
              </li>
            </Tooltip>

            <Popover2
              isDismissable={true}
              placement={'top'}
              popupContent={<TooltipContent data={props.data} />}
              className="shadow-sm shadow-item-contrast bg-item dark:bg-item-dark p-2 w-fit"
            >
              <li className="pl-2 flex items-center gap-2 cursor-pointer hover:bg-item-hover dark:hover:bg-item-dark-hover">
                <Icon kind="More" className="align-middle" />{' '}
                <span className="dark:text-neutral">
                  {translate('datacosmos.catalogAndItems.moreInfo')}
                </span>
              </li>
            </Popover2>
          </ul>
        </div>
        <div className="flex">
          <Thumbnail
            displayAsSelected={props.isItemDisplayed}
            thumbnailAsset={thumbnailAsset}
            handleClick={() => {
              props.handleToggleDisplayItem(shouldShowHiRes);
            }}
          />
          <div className="h-40 flex flex-col gap-0 items-center justify-center">
            <ResultCardButton
              isActive={props.isItemDisplayed}
              onPress={() => {
                props.handleToggleDisplayItem(shouldShowHiRes);
              }}
              icon={props.isItemDisplayed ? 'EyeClosed' : 'Eye'}
              tooltip={
                props.isItemDisplayed
                  ? translate(
                      'datacosmos.tooltips.catalogAndItems.icons.removeFromMap'
                    )
                  : eyeIconToolTip
              }
              data-testid="stac-item-card-preview"
            />
            <ResultCardButton
              isActive={props.isItemInCart}
              onPress={props.handleToggleCartClick}
              icon={props.isItemInCart ? 'InCart' : 'AddToCart'}
              tooltip={
                isHighResPermissionGranted
                  ? translate(
                      'datacosmos.tooltips.catalogAndItems.fullResAvailable'
                    )
                  : cartToolTip
              }
              disabled={isHighResPermissionGranted || isCartActionDisabled}
            />
            <ResultCardButton
              onPress={props.handleToggleProjectClick}
              icon={projectIcon}
              tooltip={
                props.isViewCatalog
                  ? projectTooltip
                  : translate(
                      'datacosmos.tooltips.catalogAndItems.icons.removeFromProject'
                    )
              }
              disabled={props.isViewCatalog && props.isItemInProject}
            />
            <ResultCardButton
              onPress={props.handleCenterMapClick}
              icon="Center"
              tooltip={translate(
                'datacosmos.tooltips.catalogAndItems.icons.centreItemOnMap'
              )}
            />
            <ResultCardButton
              isActive={isAssetsSectionOpen}
              onPress={() => {
                setIsAssetsSectionOpen(!isAssetsSectionOpen);
              }}
              icon={isAssetsSectionOpen ? 'ChevronDown' : 'ChevronRight'}
              tooltip={translate(
                'datacosmos.tooltips.catalogAndItems.icons.showMetadataAssets'
              )}
              data-testid="assets-icon-btn"
            />
          </div>
        </div>
      </li>
      <li className="flex items-end w-full">
        {isAssetsSectionOpen && (
          <AssetsViewAndDownload
            itemID={props.data.id}
            collectionID={props.data.collection}
            previewableAssets={previewableAssets}
            nonPreviewableAssets={nonPreviewableAssets}
            isAssetDisplayed={props.isAssetDisplayed}
            handleToggleDisplayAsset={props.handleToggleDisplayAsset}
            isHighResPermissionGranted={isHighResPermissionGranted}
          />
        )}
      </li>
    </ul>
  );
};
