export default {
  PAYLOAD_SELECTION: {
    VISIT: 'MSD Payload-Selection Visit',
    CUSTOM: {
      VISIT: 'MSD Payload-Selection Custom Visit',
      CREATE: 'MSD Payload-Selection Custom Create',
      EDIT: 'MSD Payload-Selection Custom Edit',
    },
    DETAILED: {
      TAB_VIEW: 'MSD Payload-Selection Detailed-View Tab Visit',
    },
    VIEW: 'MSD Payload-Selection View',
    SELECT: 'MSD Payload-Selection Select',
    COMPLETE: 'MSD Payload-Selection Complete',
  },
  CONSTELLATION: {
    VISIT: 'MSD Constellation Visit',
    SIMULATION: {
      START: 'MSD Constellation Simulation Start',
      SUCCESS: 'MSD Constellation Simulation Success',
      CONTINUE: 'MSD Constellation Simulation Continue',
      FAILURE: 'MSD Constellation Simulation Failure',
      CANCEL: 'MSD Constellation Simulation Cancel',
    },
  },
  GROUND_SEGMENT: {
    VISIT: 'MSD Ground-Segment Visit',
    SIMULATION: {
      START: 'MSD Ground-Segment Simulation Start',
      SUCCESS: 'MSD Ground-Segment Simulation Success',
      CONTINUE: 'MSD Ground-Segment Simulation Continue',
      FAILURE: 'MSD Ground-Segment Simulation Failure',
      CANCEL: 'MSD Ground-Segment Simulation Cancel',
    },
  },
  PLATFORM: {
    VISIT: 'MSD Platform Visit',
    MODULE_SELECTION: {
      POWER: {
        VISIT: 'MSD Platform Module-Selector Power Visit',
        COMPLETE: 'MSD Platform Module-Selector Power Complete',
      },
      EPS: {
        VISIT: 'MSD Platform Module-Selector EPS Visit',
        COMPLETE: 'MSD Platform Module-Selector EPS Complete',
      },
      COMMS: {
        VISIT: 'MSD Platform Module-Selector Comms Visit',
        COMPLETE: 'MSD Platform Module-Selector Comms Complete',
      },
      AOCS: {
        VISIT: 'MSD Platform Module-Selector AOCS Visit',
        COMPLETE: 'MSD Platform Module-Selector AOCS Complete',
      },
      OBDH: {
        VISIT: 'MSD Platform Module-Selector OBDH Visit',
        COMPLETE: 'MSD Platform Module-Selector OBDH Complete',
      },
    },
    COMPLETE: 'MSD Platform Complete',
  },
  MODES: {
    VISIT: 'MSD Modes Visit',
    SIMULATION: {
      START: 'MSD Modes Simulation Start',
      SUCCESS: 'MSD Modes Simulation Success',
      CONTINUE: 'MSD Modes Simulation Continue',
      FAILURE: 'MSD Modes Simulation Failure',
      CANCEL: 'MSD Modes Simulation Cancel',
    },
  },
};
