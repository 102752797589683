import React from 'react';
import type { ShownCards } from 'components/LibraryEncoding/types';
import LibraryEncodingSplitter from 'components/LibraryEncoding/RadioSettings/LibraryEncoderSplitter';
import TwoColumnLayout from 'components/LibraryEncoding/TwoColumnLayout';
import LibraryRadioTransformers from 'components/LibraryEncoding/RadioSettings/LibraryRadioTransformers';
import type {
  GatewayConfigurationOptions,
  GatewayRadioConfig,
} from 'api/gateway/types';

interface Props {
  radioToEdit: GatewayRadioConfig;
  missionId: number;
  networkConfigOptions: GatewayConfigurationOptions;
  handleRadioEdit: (radio: GatewayRadioConfig) => void;
  setShownCards: React.Dispatch<React.SetStateAction<ShownCards>>;
}

const LibraryRadioEncodings = (props: Props) => {
  const uplink = props.radioToEdit.transformers.uplink;
  const downlink = props.radioToEdit.transformers.downlink;

  const uplinkSchemas = props.networkConfigOptions.transformers.filter(
    (trans) => uplink.some((up) => up.transformer === trans.name)
  );

  const downlinkSchemas = props.networkConfigOptions.transformers.filter(
    (trans) => downlink.some((down) => down && down.transformer === trans.name)
  );

  const allAvailableTransformersSchemas =
    props.networkConfigOptions.transformers;

  return (
    <TwoColumnLayout
      sectionGap="20px"
      setShownCards={props.setShownCards}
      backButton
      leftWidthFr={7}
      rightWidthFr={7}
      left={
        <LibraryRadioTransformers
          title="Uplink transformers"
          schemas={uplinkSchemas}
          payload={uplink}
          handleChangeGatewaySettings={props.handleRadioEdit}
          radioToEdit={props.radioToEdit}
          transformerType="uplink"
          missionId={props.missionId}
          allAvailableTransformersSchemas={allAvailableTransformersSchemas}
        />
      }
      right={
        <LibraryRadioTransformers
          title="Downlink transformers"
          schemas={downlinkSchemas}
          payload={downlink}
          handleChangeGatewaySettings={props.handleRadioEdit}
          radioToEdit={props.radioToEdit}
          transformerType="downlink"
          missionId={props.missionId}
          allAvailableTransformersSchemas={allAvailableTransformersSchemas}
        />
      }
      middleElement={<LibraryEncodingSplitter />}
    />
  );
};

export default LibraryRadioEncodings;
