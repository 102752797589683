import { Text } from 'ui/Text';

export type EmptyMessageProps = {
  title: string;
};

/*
 * A placeholder component used to display a message when there is no data to present
 */
export const EmptyMessage = ({ title }: EmptyMessageProps) => {
  return (
    <div className="flex justify-center w-full mt-5">
      <Text className="text-lg">{title}</Text>
    </div>
  );
};
