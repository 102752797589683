export const PAGE = {
  TITLE: 'Copyright and license conditions',
  COPYRIGHT: '©Open Cosmos Ltd.',
  EMAIL: 'open-cosmos.com',
  SUPPORT_EMAIL: 'info@open-cosmos.com',

  P1: 'The copyright in this document is the property of Open Cosmos Ltd.',
  P2: 'All rights reserved. No part of this document may be reproduced, distributed or transmitted in any form or by any means, including photocopying, recording, or other electronic or mechanical methods, without the prior permission of the Copyright Owner, except in accordance with the Copyright, Designs and Patents Act, 1988, or under the terms of a licence and/or confidentiality agreement issued by the Copyright Owner, Open Cosmos Ltd. Applications for the copyright owners permission to reproduce any part of this documentation should be addressed to',
  P3: `Open Cosmos Ltd., RAL Atlas Building R27, Fermi Avenue, Harwell Campus, OX11
  0QX, Oxfordshire, United Kingdom`,
  P4_RELEVANT: `Any person, other than the authorised holder, who finds or
  otherwise obtains possession of the document, should post
  it together with his name and address to:`,
  P5_RELEVANT: `Open Cosmos Ltd., RAL Atlas Building R27, Fermi Avenue,
  Harwell Campus, OX11 0QX, Oxfordshire, United Kingdom`,
  P6_RELEVANT: 'Postage will be refunded.',
};
