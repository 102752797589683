import React, { createContext, useContext, useState, useEffect } from 'react';
import { useAuth } from '../../../auth/AuthWrapper';
import API from './API';
import { NODE_RED_OPERATOR_URL } from './constants';

export interface INodeREDServiceOutput {
  isLoading: boolean;
  isCreating: boolean;
  checkNodeREDPodIsRunning: () => void;
  instanceExists: boolean;
  createNewInstance: () => void;
}

export const NodeREDContext = createContext<INodeREDServiceOutput>(null);
export const useApiNodeREDService = () =>
  useContext<INodeREDServiceOutput>(NodeREDContext);

export const useNodeREDInner = (missionId: number) => {
  const { token } = useAuth();
  const [instanceExists, setInstanceExistence] = useState<boolean>();
  const [isLoading, setLoading] = useState<boolean>(false);
  const [isCreating, setCreating] = useState<boolean>(false);
  const ApiInstance = API(NODE_RED_OPERATOR_URL, missionId, token);

  const checkNodeREDPodIsRunning = async (): Promise<boolean> => {
    setLoading(true);
    try {
      const isRunning = await ApiInstance.checkPodIsRunning();
      setInstanceExistence(isRunning);
      setLoading(false);
      return isRunning;
    } catch (e) {
      console.error(e);
    }
    setLoading(false);
    return false;
  };

  const createNewInstance = async () => {
    setCreating(true);
    try {
      const data = await ApiInstance.createNewInstance();
      console.log(data);
    } catch (e) {
      console.error(e);
    }
    await checkUntilExists();
  };

  const deleteAnInstance = async () => {
    try {
      const data = await ApiInstance.deleteAnInstance();
      console.log(data);
    } catch (e) {
      console.error(e);
    }
    // checkUntilDeleted
  };

  useEffect(() => {
    void checkNodeREDPodIsRunning();
  }, [missionId]);

  const checkUntilExists = async () => {
    const exists = await checkNodeREDPodIsRunning();
    if (exists) {
      setCreating(false);
      setTimeout(() => {
        window.location.reload();
      }, 1500);
      return;
    }
    setTimeout(() => {
      void checkUntilExists();
    }, 13000);
  };

  return {
    isLoading,
    isCreating,
    checkNodeREDPodIsRunning,
    instanceExists,
    createNewInstance,
    deleteAnInstance,
  };
};

export const NodeREDProvider = ({ children, missionId }: any) => {
  const props = useNodeREDInner(missionId);
  return (
    <NodeREDContext.Provider value={{ ...props }}>
      {children}
    </NodeREDContext.Provider>
  );
};
