import toKml from 'tokml';
import shpwrite from '@mapbox/shp-write';
import JSZip from 'jszip';
import type { Layer } from 'datacosmos/entities/layer';
import type { ViewLayer } from 'api/views/types';
import { SingleBandSTACLayer } from 'datacosmos/entities/singleBandLayer';
import { BandAlgebraSTACLayer } from 'datacosmos/entities/bandAlgebraLayer';
import { CircleLayer } from 'datacosmos/entities/circleLayer';
import { PolygonLayer } from 'datacosmos/entities/polygonLayer';
import { LineLayer } from 'datacosmos/entities/lineLayer';
import { GeoJSONLayer } from 'datacosmos/entities/geojsonLayer';
import { geoJsonFeatureToGeoJsonFeatureCollection } from 'datacosmos/utils/geojson';
import { FieldOfRegardLayer } from 'datacosmos/entities/FieldOfRegardLayer';
/**
 * downloadFilUrlAsExtension takes a `fileUrl` and downloads it as `extension` to user's local machine
 * @param fileUrl File url to download, usually in a format `FILE_TYPE;ENCODING, FILE_CONTENTS` e.g. `data:application/zip;base64,UEsDBBQAAAAIA...`
 * @param fileName Desired file name for the file
 * @param extension Desired file extension e.g. `zip`
 */
export const downloadFilUrlAsExtension = (
  fileUrl: string,
  fileName: string,
  extension: string
) => {
  const downloadLink = document.createElement('a');

  downloadLink.href = `data:${fileUrl}`;
  downloadLink.download = `${fileName}.${extension}`;
  document.body.appendChild(downloadLink);
  downloadLink.click();
  document.body.removeChild(downloadLink);
  downloadLink.remove();
};

/**
 * downloadGeojsonAsKml takes an arbitrary `geojson` file and downloads it as `.kml` to user's local machine
 * @param geo GeoJSON object to download
 * @param fileName Desired file name for the `.kml` file
 */
export const downloadGeojsonAsKml = (
  geo: GeoJSON.GeoJSON,
  fileName: string
) => {
  const kml = toKml(geo);
  const kmlConverted =
    'application/vnd.google-earth.kml+xml;charset=utf-8,' +
    encodeURIComponent(kml);

  downloadFilUrlAsExtension(kmlConverted, fileName, 'kml');
};

/**
 * downloadGeojsonAsShp takes an arbitrary `geojson` file and downloads it as `.zip`
 * containing all the necessary shapefile related files to user's local machine
 * @param geo GeoJSON object to download
 * @param fileName Desired file name for the `.zip` file
 */
export const downloadGeojsonAsShp = async (
  geo: GeoJSON.FeatureCollection,
  fileName: string
) => {
  const shp = (await shpwrite.zip(geo, {
    outputType: 'base64',
    compression: 'DEFLATE',
  })) as string;

  const shpUrl = `application/zip;base64, ${shp}`;
  downloadFilUrlAsExtension(shpUrl, fileName, 'zip');
};

/**
 * downloadGeojsonAsKmz takes an arbitrary `geojson` file and downloads it as `.kmz`
 * containing the zipped kml files to user's local machine
 * @param geo GeoJSON object to download
 * @param fileName Desired file name for the `.kmz` file
 */
export const downloadGeojsonAsKmz = async (
  geo: GeoJSON.GeoJSON,
  fileName: string
) => {
  const kml = toKml(geo);
  // Creates a KMZ file by zipping the KML
  const kmz = new JSZip();
  kmz.file(`${fileName}.kml`, kml);

  const kmzData = await kmz.generateAsync({ type: 'base64' });
  const kmzURL = `application/vnd.google-earth.kmz;base64, ${kmzData}`;
  downloadFilUrlAsExtension(kmzURL, fileName, 'kmz');
};

/**
 * downloadAOIasGeojson takes an arbitrary `geojson` file and downloads it as `.geojson`
 * @param geo GeoJSON object to download
 * @param fileName Desired file name for the `.geojson` file
 */
export const downloadAOIasGeojson = (
  geo: GeoJSON.GeoJSON,
  fileName: string
) => {
  const geojsonURL = `application/vnd.geo+json;charset=utf-8, ${encodeURIComponent(
    JSON.stringify(geo)
  )}`;

  downloadFilUrlAsExtension(geojsonURL, fileName, 'geojson');
};

export const downloadScreenshotAsGeoJson = (layers: ViewLayer[] | Layer[]) => {
  const featCollectionToDownload: GeoJSON.FeatureCollection = {
    type: 'FeatureCollection',
    features: [],
  };
  const layersToDownload = [...layers];
  layersToDownload.map((layer) => {
    if (
      layer instanceof SingleBandSTACLayer ||
      layer instanceof BandAlgebraSTACLayer
    ) {
      const noDataPropertyLayer = layer;
      noDataPropertyLayer.item.properties = {
        ...noDataPropertyLayer.item.properties,
        item_id: noDataPropertyLayer.item.id,
        collection_id: noDataPropertyLayer.item.collection,
      };
      featCollectionToDownload.features.push({
        geometry: noDataPropertyLayer.item.geometry as GeoJSON.Geometry,
        properties: noDataPropertyLayer.item.properties,
        type: 'Feature',
      });
    } else if (
      layer instanceof CircleLayer ||
      layer instanceof PolygonLayer ||
      layer instanceof LineLayer ||
      layer instanceof GeoJSONLayer ||
      layer instanceof FieldOfRegardLayer
    ) {
      const dataPropertyLayer = layer;
      (dataPropertyLayer.data as GeoJSON.Feature).properties = {
        id: dataPropertyLayer.id,
        name: dataPropertyLayer.name,
      };
      featCollectionToDownload.features.push(
        dataPropertyLayer.data as GeoJSON.Feature
      );
    }
  });

  const geojsonURL = `application/vnd.geo+json;charset=utf-8, ${encodeURIComponent(
    JSON.stringify(featCollectionToDownload)
  )}`;

  downloadFilUrlAsExtension(
    geojsonURL,
    `open_cosmos_screenshot_${new Date().toISOString()}`,
    'geojson'
  );
};

export const getShpBase64 = async (geo: GeoJSON.Feature) => {
  const featCol = geoJsonFeatureToGeoJsonFeatureCollection(geo);
  const shp = await shpwrite.zip(featCol, {
    outputType: 'base64',
    compression: 'DEFLATE',
  });
  return shp;
};
