import type { Margin } from 'ui/utils';
import { getMargin } from 'ui/utils';

export type HeaderProps = {
  size?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
  children: React.ReactNode;
  margin?:
    | Margin
    | { top?: Margin; bottom?: Margin; left?: Margin; right?: Margin };
};

export const Header = ({ size = 'h1', children, margin }: HeaderProps) => {
  const H: keyof JSX.IntrinsicElements = `${size}`;
  return (
    <H
      className="dark:text-item-dark-contrast p-0 h-fit max-h-fit"
      style={margin && getMargin(margin)}
    >
      {children}
    </H>
  );
};
