import { getRow } from '../../getRowWithConstraints';
import type { IAnyKey } from '../../../../utils/getFormData';
import { SEPARATOR } from '../../../../constants/popUp/constants';

interface IRenderNames {
  name: string;
  constraints: object & IAnyKey;
  change?: Function;
  astrum: string;
}

export const renderName = (props: IRenderNames) => {
  const { measurement, measurementType } =
    props.constraints[props.name.split(SEPARATOR)[0] as string];
  return getRow(
    props.name as string,
    props.constraints,
    props.change,
    measurement,
    measurementType,
    props.astrum
  );
};
