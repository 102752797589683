import classNames from "classnames";
import Button from "./Button";
import type { ButtonProps } from "./Button";

const DangerButton = (props: ButtonProps) => {
  return (
    <Button
      {...props}
      className={classNames(
        "border bg-warning-400 border-warning-500 dark:bg-warning-600 dark:border-warning-500",
        {
          "hover:bg-warning dark:hover:bg-warning": !props.disabled,
          "stroke-item-contrast": !props.disabled,
        }
      )}
    />
  );
};

export default DangerButton;
