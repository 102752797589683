import type { AppState } from '../store';
import type { IPlatformCharacteristicType } from '../constants/ui/platformConfigurator/types';
import { hydrateModulesFromNames } from './satellite';
import type { IPlatformModuleType } from '../constants/satellite/types';

export const selectCharacteristicsThatCascade = (
  state: AppState
): IPlatformCharacteristicType[] =>
  state.platformConfigurator.order
    .slice(0, -1)
    .filter(
      (characteristic, index) =>
        state.platformConfigurator.characteristics[characteristic] &&
        state.platformConfigurator.characteristics[
          state.platformConfigurator.order[index + 1]
        ]
    );

export const selectDisabledCharacteristics = (
  state: AppState
): IPlatformCharacteristicType[] =>
  state.platformConfigurator.order.slice(1).filter(
    (characteristic, index) =>
      !state.platformConfigurator.characteristics[
        // would be index-1 but the slice+1 normalizes it
        state.platformConfigurator.order[index]
      ]
  );

export const selectOptionsForCharacteristics = (state: AppState) => {
  const decisionTree = state.platformConfigurator.decisionTree;

  const optionsForCharacteristics = {
    [state.platformConfigurator.order[0]]:
      decisionTree[state.platformConfigurator.order[0]],
  };

  state.platformConfigurator.order.slice(1).forEach((characteristic, index) => {
    const previousValue =
      state.platformConfigurator.characteristics[
        // would be index-1 but the slice+1 normalizes it
        state.platformConfigurator.order[index]
      ];

    optionsForCharacteristics[characteristic] =
      //@ts-ignore
      decisionTree[characteristic][previousValue];
  });

  return optionsForCharacteristics;
};

export const selectPlatformDecisionTree = (state: AppState) =>
  state.platformConfigurator.decisionTree;

export const selectPlatformCharacteristics = (state: AppState) =>
  state.platformConfigurator.characteristics;

export const selectedPlatformModuleNames = (state: AppState) =>
  state.platformConfigurator.moduleNames;

export const selectedPlatformModules = (state: AppState) =>
  hydrateModulesFromNames(state, state.platformConfigurator.moduleNames);

export const selectPlatformModule = (
  state: AppState,
  moduleType: IPlatformModuleType
) =>
  state.platformConfigurator.moduleNames[moduleType].map(
    (moduleName) => state.moduleData[moduleType][moduleName]
  );
