import type { WrappedFieldArrayProps } from 'redux-form';
import { FieldArray } from 'redux-form';
import RenderPath from './PathField';
import { Collapse, Pre } from '@blueprintjs/core';
import type { WithTranslation } from 'react-i18next';
import { withTranslation } from 'react-i18next';
import type { FunctionComponent, ReactElement, MouseEventHandler } from 'react';
import React, { useState } from 'react';

interface IRenderPaths extends WithTranslation, WrappedFieldArrayProps {
  drawRegion: MouseEventHandler;
  panToBounds?: (bound: google.maps.LatLngBoundsLiteral) => void;
  hideAddRegion: boolean;
}

const RenderPaths = (props: IRenderPaths): ReactElement => {
  const [isOpen, setOpen] = useState(true);
  const switchIsOpen = (): void => setOpen(!isOpen);
  const setIsOpenTrue = (): void => setOpen(true);

  const { fields, t, drawRegion, panToBounds } = props;
  const minPaths = 1;
  const isMinPaths = fields.length <= minPaths;
  if (fields.length < minPaths) {
    fields.push([]);
    return null;
  }

  const addRegion = (): void => {
    fields.push([]);
    setIsOpenTrue();
  };
  const renderedPaths = fields.map((member, index): ReactElement => {
    const removeRegion = (): void => fields.remove(index);
    return (
      <div key={index}>
        <FieldArray
          name={`${member}`}
          // @ts-expect-error
          isParentOpen={fields.length === 1 ? isOpen : false}
          removeRegion={removeRegion}
          panToBounds={panToBounds}
          pathIndex={index}
          isMinPaths={isMinPaths}
          component={RenderPath}
        />
      </div>
    );
  });
  return (
    <>
      <div className="bp4-control-group">
        <div className="bp4-input-group bp4-fill">
          <div className="bp4-input-group">
            <div className="bp4-input">{`${t(
              'validation.paths-field.regions'
            )}`}</div>
            {/* eslint-disable-next-line */}
            <span
              className={`bp4-button bp4-minimal  ${
                isOpen ? 'bp4-icon-arrow-up' : 'bp4-icon-arrow-down'
              }`}
              onClick={switchIsOpen}
            />
          </div>
        </div>
        <button
          type="button"
          className="bp4-button bp4-icon-geolocation bp4-intent-primary"
          onClick={addRegion}
        >
          Specify
        </button>
        {!props.hideAddRegion && (
          <button
            type="button"
            title={t('validation.paths-field.draw_title')}
            className="bp4-button bp4-icon-draw bp4-intent-primary"
            onClick={drawRegion}
          >
            Draw
          </button>
        )}
      </div>
      <Collapse isOpen={isOpen} keepChildrenMounted={true}>
        <Pre>{renderedPaths}</Pre>
      </Collapse>
    </>
  );
};

export default withTranslation()(RenderPaths) as FunctionComponent;
