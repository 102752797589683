import type { ReactElement } from 'react';
import React from 'react';

interface IPDFPreview {
  className: string;
  src: string;
}

export default function PDFPreview(props: IPDFPreview): ReactElement {
  // eslint-disable-next-line
  return <iframe {...props} />;
}
