import { getGeoPdfFile, type PostGeoPDF } from 'api/geopdf/service';
import { getShpBase64 } from 'datacosmos/download/geojson';
import { BandAlgebraSTACLayer } from 'datacosmos/entities/bandAlgebraLayer';
import { CircleLayer } from 'datacosmos/entities/circleLayer';
import { GeoJSONLayer } from 'datacosmos/entities/geojsonLayer';
import { LineLayer } from 'datacosmos/entities/lineLayer';
import { PolygonLayer } from 'datacosmos/entities/polygonLayer';
import { SingleBandSTACLayer } from 'datacosmos/entities/singleBandLayer';
import tilingApi from 'datacosmos/services/tilingApi';
import { useMapLayers } from 'datacosmos/stores/MapLayersProvider';
import { useMap } from 'datacosmos/stores/MapProvider';
// import { getStaticMapURLFromBoundingBox } from 'datacosmos/utils/utils';
import {
  DefaultDPI,
  DefaultPaperSize,
  PapersizesForGeoPDF,
} from 'datacosmos/utils/views';
import { Formik, type FormikProps } from 'formik';
import type { BBox } from 'geojson';
import { isEmpty } from 'lodash';
import { Dialog, Icon, Input, Select2 } from 'opencosmos-ui';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Item } from 'react-stately';
import { useAuth } from 'services/auth/AuthWrapper';
import { downloadFile } from 'utils/common/CommonUtils';
import { useLocalisation } from 'utils/hooks/useLocalisation';

interface GeoPDFProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
}

interface shpURL {
  id: string;
  shp: string;
}
const GeoPDFDetails = ({ isOpen, setIsOpen }: GeoPDFProps) => {
  const { translate } = useLocalisation();
  const [selectedPaperSize, setSelectedPaperSize] =
    useState<string>(DefaultPaperSize);

  const [selectedOrientation, setSelectedOrientation] =
    useState<string>('Portrait');

  interface FormValues {
    dpi: string;
    description: string;
  }

  const initialValues: FormValues = { dpi: DefaultDPI, description: '' };

  const formRef = useRef<FormikProps<FormValues>>(null);

  const [loading, setLoading] = useState<boolean>(false);
  const [shpURLsForVectors, setShpURLsForVectors] = useState<shpURL[]>();
  const { layers } = useMapLayers();
  const { user } = useAuth();
  const { getMapBounds } = useMap();

  const mapBbox = getMapBounds();

  const shouldDisplayWarning = useMemo(() => {
    const stacLayers = layers.filter(
      (l) => l instanceof SingleBandSTACLayer
    ) as SingleBandSTACLayer[];
    const nonTiffLayers = stacLayers.filter(
      (layer) => !layer.item.assets[layer.assetKey].type?.includes('image/tiff')
    );
    return !isEmpty(nonTiffLayers);
  }, [layers]);

  //filters the layers that do not contain tiff assets
  const layersToBeAddedInGeoPDF = useMemo(() => {
    return layers.filter(
      (mapLayer) =>
        (mapLayer instanceof SingleBandSTACLayer &&
          mapLayer.item.assets[mapLayer.assetKey].type?.includes(
            'image/tiff'
          )) ||
        !(mapLayer instanceof SingleBandSTACLayer)
    );
  }, [layers]);

  useEffect(() => {
    if (!isOpen || !layersToBeAddedInGeoPDF.length) {
      return;
    }
    const vectorLayers = layersToBeAddedInGeoPDF.filter(
      (mapLayer) => mapLayer instanceof PolygonLayer
    ) as PolygonLayer[];

    void Promise.all(
      vectorLayers.map(async (addedLayer) => {
        return {
          id: addedLayer.id,
          shp: (await getShpBase64(
            addedLayer.data as GeoJSON.Feature
          )) as string,
        };
      })
    ).then((vectorURLArray) => {
      // vectorURLArray contains the resolved values
      setShpURLsForVectors(vectorURLArray);
    });
  }, [layersToBeAddedInGeoPDF, isOpen]);

  const getLayersForPayload = useCallback(
    (coords: BBox, width: number, height: number) => {
      return layersToBeAddedInGeoPDF.map((layer) => {
        if (layer instanceof SingleBandSTACLayer) {
          return {
            enabled: true,
            georeferencing: {
              bbox: coords,
            },
            raster: tilingApi.generateSingleBandBboxURL(
              layer.item.assets[layer.assetKey].href,
              {
                bbox: coords,
                width: width,
                height: height,
              },
              layer.options
            ),
          };
        }
        if (layer instanceof BandAlgebraSTACLayer && layer.item.collection) {
          const formattedExpression =
            layer.expression.split('::').length > 1
              ? layer.expression.split('::')[1]
              : layer.expression.split('::')[0];
          return {
            enabled: true,
            georeferencing: {
              bbox: coords,
            },
            raster: tilingApi.generateBandAlgebraBboxURL(
              `/collections/${layer.item.collection}/items/${layer.item.id}`,
              formattedExpression,
              {
                bbox: coords,
                width: width,
                height: height,
              },
              layer.options
            ),
          };
        }
        if (
          layer instanceof CircleLayer ||
          layer instanceof PolygonLayer ||
          layer instanceof LineLayer ||
          layer instanceof GeoJSONLayer
        ) {
          return {
            enabled: true,
            georeferencing: {
              bbox: coords,
            },
            vector: shpURLsForVectors?.find((v) => v.id === layer.id)?.shp,
          };
        }
        return {
          enabled: true,
          georeferencing: {
            bbox: coords,
          },
        };
      });

      //TODO: Enable this after BE implements basemap
      // const mapBaseLayer = {
      //   enabled: true,
      //   georeferencing: {
      //     bbox: coords,
      //   },
      //   raster: getStaticMapURLFromBoundingBox(
      //     coords,
      //     width?.toString(),
      //     height?.toString()
      //   ),
      // };
      // return [mapBaseLayer, ...mapLayers];
    },
    [layersToBeAddedInGeoPDF, shpURLsForVectors]
  );

  return (
    <>
      <Dialog
        title={translate('datacosmos.viewsDialog.geoPDFHeader')}
        isOpen={isOpen}
        buttons={[
          {
            text: translate('datacosmos.viewsDialog.createGeoPDF'),
            onPress: () => {
              if (formRef.current) {
                formRef.current.handleSubmit();
              }
            },
            shown: true,
            showLoadingIndicator: loading,
            keepDialogOpenOnPress: true,
          },
        ]}
        onClose={() => {
          setIsOpen(!isOpen);
          setSelectedPaperSize(DefaultPaperSize);
          setSelectedOrientation('Portrait');
        }}
        showButtonsInFooter
      >
        <Formik
          initialValues={initialValues}
          innerRef={formRef}
          onSubmit={async (values, { setErrors, resetForm }) => {
            if (values.dpi === '') {
              setErrors({
                dpi: translate(
                  'datacosmos.addNewProjectDialog.errors.description'
                ),
              });
              return;
            }

            if (values.description === '') {
              setErrors({
                description: translate(
                  'datacosmos.addNewProjectDialog.errors.description'
                ),
              });
              return;
            }

            const width =
              PapersizesForGeoPDF[selectedPaperSize][values.dpi][
                selectedOrientation === 'Portrait' ? 0 : 1
              ];
            const height =
              PapersizesForGeoPDF[selectedPaperSize][values.dpi][
                selectedOrientation === 'Portrait' ? 1 : 0
              ];

            if (!user || !mapBbox) {
              return;
            }
            setLoading(true);
            const geoPDFRequestBody: PostGeoPDF = {
              metadata: {
                author: user.name,
                subject: values.description,
              },
              pages: [
                {
                  settings: {
                    dpi: values.dpi,
                    width: width?.toString(),
                    height: height?.toString(),
                  },
                  content: {
                    layers: getLayersForPayload(
                      mapBbox,
                      width,
                      height
                    ).reverse(),
                  },
                },
              ],
            };

            await getGeoPdfFile({
              body: geoPDFRequestBody,
            })
              .then((res) => {
                if (res.status === 200) {
                  downloadFile(
                    res.data as BlobPart,
                    `geopdf-${values.dpi}-${width}x${height}.pdf`
                  );
                  resetForm();
                }
                setIsOpen(false);
                setLoading(false);
              })
              .catch(() => {
                setLoading(false);
              });
          }}
        >
          {({ values, errors, setFieldValue }) => (
            <>
              <div className="grid grid-cols-2 gap-2">
                <Select2
                  selectedItem={selectedPaperSize}
                  onSelectionChange={(size) => {
                    setSelectedPaperSize(size as string);
                  }}
                  placeholder="Select paper size"
                  fill
                  label="Paper size"
                  defaultSelectedKey={DefaultPaperSize}
                >
                  {Object.entries(PapersizesForGeoPDF).map((item) => (
                    <Item key={item[0]}>{item[0]}</Item>
                  ))}
                </Select2>

                <Select2
                  selectedItem={selectedOrientation}
                  onSelectionChange={(or) => {
                    setSelectedOrientation(or as string);
                  }}
                  placeholder="Select orientation"
                  fill
                  label="Orientation"
                  defaultSelectedKey={'Portrait'}
                >
                  <Item key={'Portrait'}>{'Portrait'}</Item>
                  <Item key={'Landscape'}>{'Landscape'}</Item>
                </Select2>
              </div>

              <div className="mt-3">
                <Select2
                  selectedItem={values.dpi}
                  onSelectionChange={(size) => {
                    void setFieldValue('dpi', size);
                  }}
                  placeholder="Select DPI"
                  fill
                  label="DPI"
                  isDisabled={!selectedPaperSize}
                  //by default 72 is set for all GeoPDF
                  defaultSelectedKey={DefaultDPI}
                >
                  {Object.keys(PapersizesForGeoPDF[selectedPaperSize]).map(
                    (dpi) => (
                      <Item key={dpi}>{dpi}</Item>
                    )
                  )}
                </Select2>
                <small style={{ color: 'red' }}>{errors.dpi?.toString()}</small>
              </div>

              <div className="mt-3">
                <Input
                  type="textarea"
                  value={values.description}
                  label={{ text: 'Description', position: 'top' }}
                  onChange={(e) =>
                    void setFieldValue('description', e.target.value)
                  }
                  placeholder="Enter description"
                />
                <small style={{ color: 'red' }}>
                  {errors.description?.toString()}
                </small>
              </div>

              {shouldDisplayWarning && (
                <div className="flex items-center gap-2 bg-accent-300 dark:bg-accent-dark my-2 p-1 text-xs">
                  <Icon icon="Info" />
                  <span>
                    {translate('datacosmos.viewsDialog.nonTiffWarning')}
                  </span>
                </div>
              )}
            </>
          )}
        </Formik>
      </Dialog>
    </>
  );
};

export default GeoPDFDetails;
