import type { IAPICompute } from 'constants/API/interfaces';
import { GEOMETRY_TYPES, SECONDS_PER_DAY } from 'constants/API/constant';
import sum from 'lodash/sum';
import {
  LIMITATION_MAX_SATELLITES,
  LIMITATION_MAX_DAYS,
  LIMITATION_MAX_COMBINED,
} from 'env';

interface ILimit {
  maxSatellites: number;
  maxDays: number;
  maxCombine: number;
}

export const checkLimit = (data: IAPICompute, limit?: ILimit): boolean => {
  const walkers = sum(
    data.data.constellation.geometry
      .filter((g) => g.type === GEOMETRY_TYPES.WALKER)
      .map(
        (g: any) =>
          g.data.numberOfPlanes.value * g.data.satellitesPerPlane.value
      )
  );
  const common = data.data.constellation.geometry.filter(
    (g) => g.type !== GEOMETRY_TYPES.WALKER
  ).length;
  const numberOfSatellites = walkers + common;
  const days = data.data.span / SECONDS_PER_DAY;

  let innerLimit = {
    maxSatellites: Number(LIMITATION_MAX_SATELLITES),
    maxDays: Number(LIMITATION_MAX_DAYS),
    maxCombine: Number(LIMITATION_MAX_COMBINED),
  };
  if (limit) {
    innerLimit = { ...limit };
  }

  if (numberOfSatellites > innerLimit.maxSatellites) {
    return false;
  }
  if (days > innerLimit.maxDays) {
    return false;
  }
  return numberOfSatellites * days <= innerLimit.maxCombine;
};
