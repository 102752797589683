import { useState, useEffect, useMemo, useCallback, useRef } from 'react';
import { useAuth } from 'services/auth/AuthWrapper';
import userApi from 'services/api/portal/administration/api/userApi';
import roleApi from 'services/api/portal/administration/api/roleApi';
import { mapAsync } from 'utils/common/asyncUtils';
import { showSuccessMessage, showErrorMessage } from 'utils/common/CommonUtils';
import type {
  IRole,
  IUserWithPermissions,
} from 'services/api/portal/administration/api/types';
import { BACKEND_BASE_URL } from 'env';
import { getAllUsers } from 'api/users/service';

const PORTAL_URL = `${BACKEND_BASE_URL}/portal/v0`;

const HEADER_HEIGHT = 50;
const PAGINATOR_HEIGHT = 90;
const MARGIN = 30;
export const TOTAL_SPACE_FOR_USER_LIST =
  window.innerHeight - HEADER_HEIGHT - PAGINATOR_HEIGHT - MARGIN;
export const RESULTS_PER_PAGE = Math.max(
  Math.floor(TOTAL_SPACE_FOR_USER_LIST / 55),
  10
);

const useUser = () => {
  const [isFetching, setFetching] = useState(false);
  const [users, setUsers] = useState<IUserWithPermissions[]>();
  const [availableRoles, setAvailableRoles] = useState<IRole[]>([]);
  const [page, setPage] = useState(0);

  const [userSearchQuery, setUserSearchQuery] = useState<string>();

  const { token } = useAuth();

  const userInstance = useMemo(() => userApi(PORTAL_URL, token), [token]);
  const roleInstance = useMemo(() => roleApi(PORTAL_URL, token), [token]);

  const getAssignmentsByUser = useCallback(async () => {
    setFetching(true);
    const { data: userWithPermissions } = await getAllUsers({
      params: {
        includeRoles: true,
        query:
          userSearchQuery && userSearchQuery?.length > 2 ? userSearchQuery : '',
        resultsPerPage: RESULTS_PER_PAGE,
        page,
      },
    });
    setUsers(userWithPermissions);
    setFetching(false);
    return userWithPermissions;
  }, [page, userSearchQuery]);

  const getRoles = useCallback(async () => {
    const data = await roleInstance.getRoles('global');
    data && setAvailableRoles(data);
  }, [roleInstance]);

  const assignRole = useCallback(
    async (userId: string, roleIds: number[]) => {
      await mapAsync((id: number) => userInstance.assignRoleToUser(userId, id))(
        roleIds
      );
      void getAssignmentsByUser();
    },
    [getAssignmentsByUser, userInstance]
  );

  const deleteRoleAssignments = useCallback(
    async (
      userId: string,
      assignmentIds: number[],
      showDeletedMessage?: boolean
    ) => {
      try {
        await mapAsync((id: number) =>
          userInstance.deleteUserRoleAssignments(userId, id)
        )(assignmentIds);
        showDeletedMessage && showSuccessMessage("User's role was removed");
        void getAssignmentsByUser();
      } catch (e) {
        showErrorMessage(
          "Something went wrong when trying to remove a user's role"
        );
      }
    },
    [getAssignmentsByUser, userInstance]
  );

  useEffect(() => {
    void getRoles();
  }, [getRoles]);

  const debounceTimer = useRef<NodeJS.Timeout | null>(null);
  useEffect(() => {
    setFetching(true);
    if (debounceTimer.current) {
      clearTimeout(debounceTimer.current);
    }
    debounceTimer.current = setTimeout(() => {
      void getAssignmentsByUser();
    }, 500);
  }, [getAssignmentsByUser]);

  return {
    isFetching,
    users,
    availableRoles,
    page,
    getAssignmentsByUser,
    assignRole,
    deleteRoleAssignments,
    setPage,
    setUserSearchQuery,
    userSearchQuery,
  };
};

export default useUser;
