import { useRouteMatch, useHistory, useLocation } from 'react-router';
import { Breadcrumbs } from '@blueprintjs/core';
import DefaultLayout from 'layouts/DefaultLayout';
import { useCallback, useEffect, useMemo, useState } from 'react';
import mainS from 'pages/ops/RTI/index.module.scss';
import { ScheduleActivityList } from 'pages/ops/Scheduling/ScheduleActivityList';
import { SchedulingLayout } from 'pages/ops/Scheduling/SchedulingLayout';
import { ScheduleActivityDetails } from 'pages/ops/Scheduling/ScheduleActivityDetails';
import moment from 'moment';
import { useQuery } from 'api/useQuery';
import { getActivitiesByMissionId } from 'api/activities/service';
import ThemeProvider from 'datacosmos/stores/ThemeProvider';
import type { TaskingRequest } from 'api/tasking/types';
import { getTaskingRequest } from 'api/tasking/service';

export type FromToDate = {
  from: Date | null;
  to: Date | null;
};

export const SchedulingInner = () => {
  const history = useHistory();
  const { search, pathname } = useLocation();

  const [isCalendarMode, setCalendarMode] = useState<boolean>(false);

  const [fromToDate, setFromToDate] = useState<FromToDate>(() => {
    const params = new URLSearchParams(search);
    const from = params.get('from');
    const to = params.get('to');
    return from && to
      ? {
          from: moment.utc(from).toDate(),
          to: moment.utc(to).toDate(),
        }
      : {
          from: moment.utc().startOf('day').toDate(),
          to: moment.utc().startOf('day').add(7, 'days').toDate(),
        };
  });

  const urlMatch = useRouteMatch<{ mission: string }>({
    path: '/ops/mission/:mission/:route*',
    exact: false,
  });

  const missionId = urlMatch ? urlMatch.params.mission : '55';

  const {
    data: activities,
    refetch: refetchActivities,
    loading: isFetchingActivities,
  } = useQuery(getActivitiesByMissionId, {
    skip: !fromToDate.from || !fromToDate.to,
    params: {
      missionId: missionId,
      startDate:
        fromToDate.from && !isCalendarMode ? fromToDate.from.toISOString() : '',
      endDate:
        fromToDate.to && !isCalendarMode ? fromToDate.to.toISOString() : '',
    },
    initialData: [],
  });

  const activitiesRequestIds = useMemo(() => {
    return [...new Set(activities.map((a) => a.tasking_request_ids[0]))].filter(
      (id) => id !== undefined
    );
  }, [activities]);

  const [requests, setRequests] = useState<TaskingRequest[]>([]);
  const [isFetchingRequests, setIsFetchingRequests] = useState<boolean>(false);

  const loading = useMemo(
    () => isFetchingRequests || isFetchingActivities,
    [isFetchingActivities, isFetchingRequests]
  );

  const fetchTaskingRequestById = useCallback(async (id: string) => {
    const { data } = await getTaskingRequest({ params: { requestId: id } });
    if (!data) {
      return undefined;
    }
    return data;
  }, []);

  const fetchAllActivitiesRequests = useCallback(async () => {
    setIsFetchingRequests(true);
    const requestPromises = activitiesRequestIds.map((id) =>
      fetchTaskingRequestById(id)
    );

    const reqs = (await Promise.all(requestPromises)).filter(
      (r) => r !== undefined
    ) as TaskingRequest[];

    setRequests(reqs);

    setIsFetchingRequests(false);
  }, [activitiesRequestIds, fetchTaskingRequestById]);

  useEffect(() => {
    void fetchAllActivitiesRequests();
  }, [fetchAllActivitiesRequests]);

  useEffect(() => {
    void refetchActivities();
  }, [isCalendarMode, refetchActivities]);

  useEffect(() => {
    if (!fromToDate.from || !fromToDate.to) return;
    history.replace(
      `${pathname}?from=${fromToDate.from.toISOString()}&to=${fromToDate.to.toISOString()}`
    );
  }, [history, pathname, fromToDate]);

  return (
    <DefaultLayout
      shouldHideClock
      showMissionSelector
      leftHeader={
        <div className={mainS.headerContainer}>
          <Breadcrumbs items={[{ text: 'Schedule' }]} />
        </div>
      }
    >
      <SchedulingLayout
        left={
          <ScheduleActivityList
            setFromToDate={setFromToDate}
            fromToDate={fromToDate}
            activities={activities}
            isCalendarMode={isCalendarMode}
            setCalendarMode={setCalendarMode}
            loading={loading}
            requests={requests}
          />
        }
        right={
          <ScheduleActivityDetails
            activities={activities}
            missionId={missionId}
            refetchActivities={refetchActivities}
            requests={requests}
          />
        }
      />
    </DefaultLayout>
  );
};

export const Scheduling = () => {
  return (
    <ThemeProvider>
      <SchedulingInner />
    </ThemeProvider>
  );
};
