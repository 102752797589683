import { getAllTaskingRequests } from 'api/tasking/service';
import type { TaskingRequest } from 'api/tasking/service';
import { useQuery } from 'api/useQuery';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useComposedState } from 'utils/hooks/useComposedState';
import { pipe } from 'lodash/fp';
import { getUser } from 'api/users/service';
import type { User } from 'api/users/types';
import { useHistory } from 'react-router';

export type ClassifiedRequests = {
  pending: TaskingRequest[];
  confirmed: TaskingRequest[];
  acquiring: TaskingRequest[];
  successful: TaskingRequest[];
  failed: TaskingRequest[];
  canceled: TaskingRequest[];
};

export type OverviewFilters = {
  missionIds: string[];
  availableMissionIds: string[];
  fromDate: Date | undefined;
  toDate: Date | undefined;
};

const REQUEST_URL_PARAM_KEY = 'request';

//TODO: Filter by: sat = mission

export const useTaskingOverviewData = () => {
  const [selectedRequest, setSelectedRequest] = useState<TaskingRequest>();
  const history = useHistory();

  const [isAddActivityModalOpen, setIsAddActivityModalOpen] =
    useState<boolean>(false);

  const taskingRequestsQuery = useQuery(getAllTaskingRequests, {
    initialData: [],
  });

  const getUserQuery = useQuery(getUser, {
    params: selectedRequest
      ? { subject: selectedRequest.created_by }
      : undefined,
    skip: !selectedRequest,
    initialData: {} as User,
  });

  const [filter, setFilter, setFilterState] = useComposedState<OverviewFilters>(
    {
      fromDate: undefined,
      toDate: undefined,
      missionIds: [],
      availableMissionIds: [],
    }
  );

  const filterByMissionId = useCallback(
    (requests: TaskingRequest[]) => {
      const filtered = requests.filter((req) =>
        req.activities.some((a) =>
          filter.missionIds.some((id) => a.mission_id === id)
        )
      );

      return filtered.length === 0 ? requests : filtered;
    },
    [filter.missionIds]
  );

  // Would by created date be more appropriate?
  const filterByUpdateDateStart = useCallback(
    (requests: TaskingRequest[]) => {
      return filter.fromDate
        ? requests.filter((req) => new Date(req.updated_at) >= filter.fromDate!)
        : requests;
    },
    [filter.fromDate]
  );

  // Would by created date be more appropriate?
  const filterByUpdateDateEnd = useCallback(
    (requests: TaskingRequest[]) => {
      return filter.toDate
        ? requests.filter((req) => new Date(req.updated_at) <= filter.toDate!)
        : requests;
    },
    [filter.toDate]
  );

  const selectRequestByItsURLId = useCallback(() => {
    if (taskingRequestsQuery.loading) return;
    if (taskingRequestsQuery.data.length === 0) return;

    const urlReqId = new URLSearchParams(history.location.search).get(
      REQUEST_URL_PARAM_KEY
    );

    if (!urlReqId) return;

    setSelectedRequest(
      taskingRequestsQuery.data.find((req) => req.id === urlReqId)
    );
  }, [
    history.location.search,
    taskingRequestsQuery.data,
    taskingRequestsQuery.loading,
  ]);

  const addTaskingRequestIdToURL = useCallback(
    (reqId: string) => {
      history.replace(
        `${history.location.pathname}?${REQUEST_URL_PARAM_KEY}=${reqId}`
      );
    },
    [history]
  );

  const removeTaskingRequestIdFromURL = useCallback(() => {
    history.replace(history.location.pathname);
  }, [history]);

  const handleTaskingRequestClick = useCallback(
    (req: TaskingRequest) => {
      const wasDeselected = selectedRequest?.id === req.id;

      if (wasDeselected) {
        removeTaskingRequestIdFromURL();
        setSelectedRequest(undefined);
        return;
      }

      addTaskingRequestIdToURL(req.id);
      setSelectedRequest(req);
    },
    [
      addTaskingRequestIdToURL,
      removeTaskingRequestIdFromURL,
      selectedRequest?.id,
    ]
  );

  const classifiedRequests = useMemo(
    () =>
      pipe(
        filterByMissionId,
        filterByUpdateDateStart,
        filterByUpdateDateEnd
      )(taskingRequestsQuery.data).reduce(
        (acc, request) => {
          if (request.status === 'ACQUIRING')
            acc = { ...acc, acquiring: [...acc.acquiring, request] };
          if (request.status === 'CANCELLED')
            acc = { ...acc, canceled: [...acc.canceled, request] };
          if (request.status === 'CONFIRMED')
            acc = { ...acc, confirmed: [...acc.confirmed, request] };
          if (request.status === 'FAILED')
            acc = { ...acc, failed: [...acc.failed, request] };
          if (request.status === 'PENDING')
            acc = { ...acc, pending: [...acc.pending, request] };
          if (request.status === 'SUCCESSFUL')
            acc = { ...acc, successful: [...acc.successful, request] };

          return acc;
        },
        {
          acquiring: [],
          canceled: [],
          confirmed: [],
          failed: [],
          pending: [],
          successful: [],
        } as ClassifiedRequests
      ),
    [
      filterByMissionId,
      filterByUpdateDateEnd,
      filterByUpdateDateStart,
      taskingRequestsQuery.data,
    ]
  );

  const handleMissionIdFilterClick = useCallback(
    (missionId: string) => {
      if (filter.missionIds.includes(missionId)) {
        setFilter.missionIds(
          filter.missionIds.filter((id) => id !== missionId)
        );
      } else {
        setFilter.missionIds([...filter.missionIds, missionId]);
      }
    },
    [filter.missionIds, setFilter]
  );

  useEffect(() => {
    selectRequestByItsURLId();
    if (taskingRequestsQuery.data.length > 0) {
      setFilterState((prev) => ({
        ...prev,
        availableMissionIds: [
          ...new Set(
            taskingRequestsQuery.data
              .map((r) => r.activities.map((a) => a.mission_id))
              .flat()
          ),
        ],
      }));
    }
  }, [selectRequestByItsURLId, setFilterState, taskingRequestsQuery.data]);

  return {
    classifiedRequests,
    loading: taskingRequestsQuery.loading || getUserQuery.loading,
    handleTaskingRequestClick,
    selectedRequest,
    setFilter,
    filter,
    handleMissionIdFilterClick,
    customer: getUserQuery.data,
    isAddActivityModalOpen,
    setIsAddActivityModalOpen,
    refetchTaskingRequests: taskingRequestsQuery.refetch,
  };
};
