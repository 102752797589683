import type { ReactElement } from 'react';
import React from 'react';
import type { ECharts } from 'echarts';
import echarts from 'echarts';
import type { WithTranslation } from 'react-i18next';
import { withTranslation } from 'react-i18next';
import type { IDataZoom } from '../gridLayout/GridLayout';
import type { IInitData } from '../simulation/Simulation';
import { Button, Popover } from '@blueprintjs/core';
import type { ISTState } from '../../constants/satelliteOrbits/actionTypes';
import SelectBar from '../common/SelectBar';

interface IChartsProps extends WithTranslation {
  divId: string;
  dataZoom: IDataZoom;
  initData: IInitData;
  onChangeDataZoom: Function;
  satellites: ISTState;
  onChangeSatellitesShow: Function;
}

export interface IChartsState {
  chart: ECharts;
}

class DataZoom extends React.PureComponent<IChartsProps, IChartsState> {
  public state: IChartsState = {
    chart: null,
  };

  public async componentDidMount(): Promise<void> {
    const { dataZoom, initData } = this.props;
    // @ts-ignore
    const chart = echarts.init(document.getElementById(this.props.divId));
    chart.setOption({
      xAxis: [
        {
          show: false,
          type: 'time',
          min: initData.data.startDate,
          max: initData.data.endDate,
        },
      ],
      yAxis: {
        show: false,
      },
      dataZoom: [
        {
          throttle: 500,
          bottom: 0,
          top: 0,
          left: 30,
          right: 30,
          xAxisIndex: 0,
          type: 'slider',
          ...dataZoom,
        },
      ],
    });
    chart.on('datazoom', (arg: { start: number; end: number }) => {
      const { start, end } = arg;
      this.props.onChangeDataZoom({ start, end });
    });

    this.setState({ chart });
  }

  public componentDidUpdate(prevProps: Readonly<IChartsProps>): void {
    if (this.props.dataZoom !== prevProps.dataZoom) {
      const { chart } = this.state;
      const { dataZoom } = this.props;
      chart.setOption({
        dataZoom: [
          {
            throttle: 500,
            bottom: 0,
            top: 0,
            xAxisIndex: 0,
            type: 'slider',
            ...dataZoom,
          },
        ],
      });
    }
  }

  public render(): ReactElement {
    const { divId, satellites } = this.props;

    let content = (
      <div className="bp4-dialog-container">
        <div className="bp4-dialog satellite-container">
          <SelectBar
            onChangeSatelliteShow={this.props.onChangeSatellitesShow}
            satellites={satellites}
          />
        </div>
      </div>
    );

    return (
      <div className="data-zoom">
        <div className="container-button">
          <Popover minimal={true} content={content}>
            <Button icon={'satellite'} />
          </Popover>
        </div>
        <div className="container-chart">
          <div id={divId} />
        </div>
      </div>
    );
  }
}

export default withTranslation()(DataZoom);
