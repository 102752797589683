import { useState } from 'react';
import { Button } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import AdministrationUserSelect from '../AdministrationUserSelect';
import type {
  IRole,
  IRoleAssignment,
} from '../../../services/api/portal/administration/api/types';
import UserListItem from './UserListItemWithQuery';

interface IProps {
  currentId?: number;
  availableRoles?: IRole[];
  roleAssignments?: { [userId: string]: IRoleAssignment[] };
  assignRole?: (userId: string, roleIds: number[]) => void;
  deleteRoleAssignments?: (
    userId: string,
    assignmentIds: number[],
    showDeletedMessage?: boolean
  ) => void;
}

const AdministrationUsers = (props: IProps) => {
  const [isAddingUser, setAddingUser] = useState(false);

  return (
    <div className="mt-5 w-1/2 flex-2">
      <div className={`text-base font-bold mb-2`}>Users</div>
      <ul>
        {props.roleAssignments &&
          Object.entries(props.roleAssignments).map((val) => {
            const [userId, roleAssignments] = val;

            return (
              <UserListItem
                key={userId}
                userId={userId}
                currentId={props.currentId}
                availableRoles={props.availableRoles ?? []}
                roleAssignments={roleAssignments}
                assignRole={props.assignRole}
                deleteRoleAssignments={props.deleteRoleAssignments}
              />
            );
          })}
        {isAddingUser ? (
          <AdministrationUserSelect
            currentId={props.currentId}
            assignRole={props.assignRole}
            availableRoles={props.availableRoles ?? []}
            onCancel={() => setAddingUser(false)}
          />
        ) : (
          <Button
            large
            minimal
            text="Add User"
            rightIcon={IconNames.PLUS}
            onClick={() => setAddingUser(true)}
          />
        )}
      </ul>
    </div>
  );
};

export default AdministrationUsers;
