import React, { useContext } from 'react';
import { useAuthentication } from 'services/auth/useAuthentication';
import { useAuthorisation } from 'services/auth/useAuthorisation';

export interface IAuthContext
  extends ReturnType<typeof useAuthentication>,
    ReturnType<typeof useAuthorisation> {}

export const AuthContext = React.createContext<IAuthContext>(
  {} as IAuthContext
);

export const useAuth = () => useContext<IAuthContext>(AuthContext);
