import type { Action } from 'redux';
import type { ThunkAction } from 'redux-thunk';
import { loadMissions, setCurrentMission, updateMission } from './action';
import type { AppState } from '../../reducers/rootReducer';
import type { IMission } from '../../services/Missions';
import CommonMsdApi from '../../services/api/msd/commonMsdApi';

export const getMissionsThunk =
  (): ThunkAction<void, AppState, null, Action<string>> =>
  async (dispatch): Promise<void> => {
    //TODO mission mock
    let missions: IMission[];
    try {
      missions = await CommonMsdApi.missions.get();
    } catch {
      missions = [];
    }
    missions.forEach((mission: IMission) => {
      if (!mission.date) {
        mission.date = new Date(
          new Date().toLocaleString('en-US', { timeZone: 'Etc/UTC' })
        );
      }
    });
    dispatch(loadMissions(missions));
  };

export const updateMissionThunk =
  (mission: IMission): ThunkAction<void, AppState, null, Action<string>> =>
  async (dispatch): Promise<void> => {
    dispatch(updateMission({ ...mission, date: new Date(mission.date) }));
  };

export const setCurrentMissionThunk =
  (mission: IMission): ThunkAction<void, AppState, null, Action<string>> =>
  async (dispatch): Promise<void> => {
    dispatch(
      setCurrentMission({
        ...mission,
        date: new Date(mission.date),
        current: true,
      })
    );
  };
