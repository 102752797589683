import type { IAnyKey } from '../getFormData';

const orders: IAnyKey = {};

export const generateOrder = (types: string[]) => {
  console.log(orders);
  const sortedType = types.sort();
  const key = sortedType.join('-');
  if (orders[key] === undefined) {
    orders[key] = 0;
    return orders[key];
  }
  return ++orders[key];
};
