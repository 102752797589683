import type { IResValue } from './downSample';

export const booleanDownSample = (
  triple: boolean,
  points: { value: [number, number, number?] }[]
): IResValue[] => {
  const res: IResValue[] = [];
  let flag: boolean = undefined;
  if (triple) {
    points.forEach((s, index, array): void => {
      if (index === 0 || index === points.length - 1) {
        flag = !!s.value[1];
        res.push({
          value: [s.value[0], s.value[1], new Date(s.value[2]).toISOString()],
        });
        return undefined;
      }
      if (flag !== !!s.value[1]) {
        flag = !!s.value[1];
        if (index - 1) {
          res.push({
            value: [
              array[index - 1].value[0],
              array[index - 1].value[1],
              new Date(array[index - 1].value[2]).toISOString(),
            ],
          });
        }
        res.push({
          value: [s.value[0], s.value[1], new Date(s.value[2]).toISOString()],
        });
      }
    });
  } else {
    points.forEach((s, index, array): void => {
      if (index === 0 || index === points.length - 1) {
        flag = !!s.value[1];
        res.push({ value: [new Date(s.value[0]).toISOString(), s.value[1]] });
        return undefined;
      }
      if (flag !== !!s.value[1]) {
        flag = !!s.value[1];
        if (index - 1) {
          let value = undefined;
          // If going from high to low, do it straight away
          if (array[index - 1].value[1] && !array[index].value[1])
            value = array[index].value[1];
          else value = array[index - 1].value[1];

          res.push({
            value: [new Date(array[index - 1].value[0]).toISOString(), value],
          });
        }
        res.push({ value: [new Date(s.value[0]).toISOString(), s.value[1]] });
      }
    });
  }
  return res;
};
