import type { ItemProps } from 'ui/ItemContainer';
import { ItemContainer } from 'ui/ItemContainer';
import type { IDatePickerProps } from '_molecules/DatePicker/DatePicker';
import DatePicker from '_molecules/DatePicker/DatePicker';
import { parseDate } from '@internationalized/date';

type Granularity = NonNullable<IDatePickerProps['granularity']>;

export type DateInputProps = ItemProps & {
  value?: Date;
  minValue?: Date;
  maxValue?: Date;
  setValue: (value: Date) => void;
  granularity?: IDatePickerProps['granularity'];
};

export const DateInput = ({
  value,
  setValue,
  minValue,
  maxValue,
  granularity = 'day',
  ...itemProps
}: DateInputProps) => {
  return (
    <ItemContainer {...itemProps}>
      <DatePicker
        className="dark:text-item-dark-contrast"
        aria-label="Date selection"
        onChange={(date: { toDate: (value: string) => Date } | null) => {
          if (!date) return;
          setValue(date.toDate('Etc/UTC'));
        }}
        granularity={granularity}
        value={parseTime(value, granularity)}
        minValue={parseTime(minValue, granularity)}
        maxValue={parseTime(maxValue, granularity)}
      />
    </ItemContainer>
  );
};

const granularityAmount: {
  [key in Granularity]: number;
} = {
  day: 10,
  hour: 13,
  minute: 16,
  second: 19,
};

const parseTime = (
  date: Date | undefined,
  granularity: IDatePickerProps['granularity']
) => {
  if (!date || !granularity) return undefined;
  return parseDate(
    date.toISOString().substring(0, granularityAmount[granularity])
  );
};
