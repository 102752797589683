import type { ThunkAction } from 'redux-thunk';
import type { AppState } from '../../reducers/rootReducer';
import type { Action } from 'redux';
import type { IConstraints } from '../../constants/constraintsTable/actionTypes';
import { removeConstraints, updateConstraints } from './action';

export const removeConstr =
  (): ThunkAction<void, AppState, null, Action<string>> => (dispatch) => {
    dispatch(removeConstraints());
  };

export const uploadConstr =
  (
    constraintsData: IConstraints
  ): ThunkAction<void, AppState, null, Action<string>> =>
  (dispatch) => {
    dispatch(updateConstraints(constraintsData));
  };
