import {
  byteArrayToBase16String,
  byteArrayToUTF8String,
} from 'utils/common/binnaryUtils';
import { TelemetryFile } from '.';

export class TelemetryPrinter extends TelemetryFile {
  constructor(data: DataView) {
    super(data);
  }

  public printHeader = (): string => {
    const header = this.getHeader();

    return `TM LOG for TM ID: '${header.tmId}', point size is: '${header.pointSize}' with '${header.nComponents}' components per point.`;
  };

  public printNextPoint = (): string | false => {
    const point = this.parseNextPoint();

    if (point === false) {
      return false;
    }

    let line = `${point.timestamp.toUTCString()}: [`;
    line += this.printPointValues(point.values);
    line += ']';

    return line;
  };

  private printPointValues = (values: ArrayBuffer[]): string => {
    return values
      .map((val) => {
        const view = new DataView(val);
        const byteArray = new Uint8Array(val);
        switch (val.byteLength) {
          case 1:
            return `uint:${view.getUint8(0)}`;
          case 4:
            return `float:${view.getFloat32(0)}`;
          case 8:
            return `uint:${view.getBigUint64(0)}|int:${view.getBigInt64(0)}`;
          default:
            return `hex:${byteArrayToBase16String(
              byteArray
            )}|utf8:${byteArrayToUTF8String(byteArray)}`;
        }
      })
      .join(', ');
  };
}
