import rawUniverse from './universe.json';

export interface IUniverse {
  [key: string]: {
    equatorialRadius: number;
    minimumAltitude: number;
    maximumAltitude: number;
    defaultSemimajorAxis: number;
    allowTLE: true;
  };
}

export const universe = rawUniverse as IUniverse;
