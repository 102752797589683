import { useState, useEffect } from 'react';
import {
  AnchorButton,
  TagInput,
  ControlGroup,
  EditableText,
} from '@blueprintjs/core';
import { Tooltip } from 'ui/Tooltip';
import s from './index.module.scss';
import type {
  IRole,
  IUpdateRole,
} from 'services/api/portal/administration/api/types';
import PrimaryButton from '_molecules/Button/PrimaryButton';

interface IProps {
  role: IRole;
  isAllowedToWriteRole: boolean;
  saveRole: (newRole: IUpdateRole) => void;
  initialEdit?: boolean;
}

const AdministrationRole = ({
  role,
  isAllowedToWriteRole,
  saveRole,
  initialEdit = false,
}: IProps) => {
  const [values, setModifiedValues] = useState<IRole>(role);
  const [isEditing, setEditing] = useState<boolean>(initialEdit);

  useEffect(() => {
    setModifiedValues(role);
  }, [role]);

  const startEditing = () => {
    setEditing(true);
  };

  const finishEditing = () => {
    setEditing(false);
    saveRole({
      id: values.id,
      name: values.name,
      permissions: values.permissions,
      userAssignable: values.userAssignable,
      description: values.description,
      resourceType: role.resourceType,
    });
  };

  const descriptionText = Boolean(values.description.length) && (
    <p>{values.description}</p>
  );

  const descriptionComponent = isEditing ? (
    <input
      className="bp4-input bp4-fill"
      onChange={(event) => {
        setModifiedValues({ ...values, description: event.target.value });
      }}
      placeholder="Description"
      value={values.description}
    />
  ) : (
    descriptionText
  );

  return (
    <li key={role.id}>
      <div className={s.roleInfoContainer}>
        <div className={s.roleId}>#{role.id}</div>
        <div className={s.roleName}>
          <EditableText
            disabled={!isEditing}
            value={values.name}
            onChange={(value) => {
              setModifiedValues({ ...values, name: value });
            }}
          />
        </div>
        <ControlGroup className={s.roleBtns}>
          <Tooltip content="Visible and assignable by non-admin users">
            <AnchorButton
              disabled={!isEditing}
              icon={values.userAssignable ? 'eye-open' : 'eye-off'}
              onClick={() => {
                setModifiedValues({
                  ...values,
                  userAssignable: !values.userAssignable,
                });
              }}
            />
          </Tooltip>
          {isEditing ? (
            <PrimaryButton
              disabled={!isAllowedToWriteRole}
              onPress={finishEditing}
              text="Save"
            />
          ) : (
            <AnchorButton
              disabled={!isAllowedToWriteRole}
              onClick={startEditing}
              text="Edit"
            />
          )}
        </ControlGroup>
      </div>
      {descriptionComponent}
      <div>
        <TagInput
          disabled={!isEditing}
          values={values.permissions}
          onAdd={(vals) => {
            setModifiedValues({
              ...values,
              permissions: values.permissions.concat(vals),
            });
          }}
          onRemove={(val, i) => {
            setModifiedValues({
              ...values,
              permissions: values.permissions.filter((v, j) => j !== i),
            });
          }}
        />
      </div>
    </li>
  );
};

export default AdministrationRole;
