export type PaperSize = {
  [key: string]: {
    [key: string]: number[];
  };
};

export const DefaultDPI: string = '72';
export const DefaultPaperSize: string = 'A4';
export const PapersizesForGeoPDF: PaperSize = {
  '4A0': {
    '72': [4768, 6741],
    '96': [6357, 8988],
    '150': [9933, 14043],
    '300': [19866, 28087],
  },
  '2A0': {
    '72': [3370, 4768],
    '96': [4494, 6357],
    '150': [7022, 9933],
    '300': [14043, 19866],
  },
  A0: {
    '72': [2384, 3370],
    '96': [3179, 4494],
    '150': [4967, 7022],
    '300': [9933, 14043],
  },
  A1: {
    '72': [1684, 2384],
    '96': [2245, 3179],
    '150': [3508, 4967],
    '300': [7016, 9933],
  },
  A2: {
    '72': [1191, 1684],
    '96': [1587, 2245],
    '150': [2480, 3508],
    '300': [4960, 7016],
  },
  A3: {
    '72': [842, 1191],
    '96': [1123, 1587],
    '150': [1754, 2480],
    '300': [3508, 4960],
  },
  A4: {
    '72': [595, 842],
    '96': [794, 1123],
    '150': [1240, 1754],
    '300': [2480, 3508],
  },
  A5: {
    '72': [420, 595],
    '96': [559, 794],
    '150': [874, 1240],
    '300': [1748, 2480],
  },
  A6: {
    '72': [298, 420],
    '96': [397, 559],
    '150': [620, 874],
    '300': [1240, 1748],
  },
  A7: {
    '72': [210, 298],
    '96': [280, 397],
    '150': [437, 620],
    '300': [874, 1240],
  },
  A8: {
    '72': [147, 210],
    '96': [197, 280],
    '150': [307, 437],
    '300': [614, 874],
  },
  A9: {
    '72': [105, 147],
    '96': [140, 197],
    '150': [219, 307],
    '300': [437, 614],
  },
  A10: {
    '72': [74, 105],
    '96': [98, 140],
    '150': [154, 219],
    '300': [307, 437],
  },
};
