import React from 'react';
import { Icon, Intent, Button } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { get } from 'lodash';

import { useApiGroundStationService } from 'services/api/portal/groundStations/GroundStationProvider';
import { durationToReadableFormat } from 'utils/common/dateUtils';

import s from './bookPassModal.module.scss';

const ConfirmationOfBooking = () => {
  const {
    selectedPassBooked,
    passJustBooked,
    antennas,
    cancelAndCloseModal,
    selectedRadioLinks,
  } = useApiGroundStationService();

  if (!selectedPassBooked) {
    return (
      <div className={s.confirmContainer}>
        <div className={s.failedBookingIcon}>
          <Icon icon={IconNames.ERROR} iconSize={30} />
        </div>
        <div>
          <h3 className="bp4-heading">Could not book pass.</h3>
          <div>
            You can try again by going to the previous step and re-booking.
          </div>
        </div>
      </div>
    );
  }

  // @ts-ignore - lodash assumes wrong resultant type
  const antennaBooked = get(
    antennas,
    `[${passJustBooked.antenna}].name`
  ) as string;
  // @ts-ignore - lodash assumes wrong resultant type
  const siteBooked = get(
    antennas,
    `[${passJustBooked.antenna}].site.name`
  ) as string;

  return (
    <div className={s.confirmContainer}>
      <div className={s.failedBookingIcon}>
        <Icon
          icon={IconNames.TICK_CIRCLE}
          iconSize={30}
          intent={Intent.SUCCESS}
        />
      </div>
      <div className={s.bookingConfirmation}>
        <h3 className="bp4-heading">Pass booked!</h3>
        <table>
          <tbody>
            <tr>
              <td className={s.confirmInfoColumn}>
                <b>Site</b>
              </td>
              <td className={s.confirmInfoColumn}>{siteBooked}</td>
            </tr>
            <tr>
              <td className={s.confirmInfoColumn}>
                <b>Antenna</b>
              </td>
              <td className={s.confirmInfoColumn}>{antennaBooked}</td>
            </tr>
            <tr>
              <td className={s.confirmInfoColumn}>
                <b>From (UTC)</b>
              </td>
              <td className={s.confirmInfoColumn}>
                {passJustBooked.window.start}
              </td>
            </tr>
            <tr>
              <td className={s.confirmInfoColumn}>
                <b>To (UTC)</b>
              </td>
              <td className={s.confirmInfoColumn}>
                {passJustBooked.window.end}
              </td>
            </tr>
            <tr>
              <td className={s.confirmInfoColumn}>
                <b>Duration</b>
              </td>
              <td className={s.confirmInfoColumn}>
                {durationToReadableFormat(
                  passJustBooked.additionalInfo.duration
                )}
              </td>
            </tr>
            <tr>
              <td className={s.confirmInfoColumn}>
                <b>Max. Elevation (°)</b>
              </td>
              <td className={s.confirmInfoColumn}>
                {passJustBooked.additionalInfo.maxElevation.toFixed(2)}
              </td>
            </tr>
            <tr>
              <td className={s.confirmInfoColumn}>
                <b>Radio links</b>
              </td>
              <td>
                {selectedRadioLinks
                  .map((srl) => `${srl.frequency} (${srl.direction})`)
                  .join(', ')}
              </td>
            </tr>
          </tbody>
        </table>
        <div className={s.buttonContainer}>
          <Button
            // @ts-ignore
            onClick={cancelAndCloseModal}
            intent={Intent.DANGER}
            text="Cancel the booking"
            icon={IconNames.TRASH}
          />
        </div>
      </div>
    </div>
  );
};

export default ConfirmationOfBooking;
