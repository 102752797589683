import type { EmptyParams, ClientMethod } from 'api/client';
import { apiFetchHandler } from 'api/clientFetch';
import { hosts } from 'api/hosts';
import type {
  Scenario,
  ProcessingLevel,
  SearchScenarioBbox,
} from 'api/scenarios/types';
import { clientTranslate } from 'utils/hooks/useLocalisation';
import type { STACItem } from 'api/stac/types';

export * from 'api/scenarios/types';

export const getScenarios = apiFetchHandler<Scenario[]>({
  host: hosts.data.v0,
  endpoint: '/scenario/scenarios',
  method: 'GET',
  errorMessage: clientTranslate(
    'datacosmos.fetchErrors.scenarios.cannotGetMultiple'
  ),
  errorDescription: (e) => e.join('; '),
});

export type GetScenarioParams = {
  scenarioId: string;
};

export const getScenario = apiFetchHandler<Scenario, GetScenarioParams>({
  host: hosts.data.v0,
  endpoint: ({ scenarioId }) => `/scenario/scenario/${scenarioId}`,
  method: 'GET',
  errorMessage: clientTranslate(
    'datacosmos.fetchErrors.scenarios.cannotGetOne'
  ),
  errorDescription: (e) => e.join('; '),
});

export type PostScenarioBody = {
  name: string;
  description: string;
  organisation_id: number;
};

export const postScenario = apiFetchHandler<
  Scenario,
  EmptyParams,
  PostScenarioBody
>({
  host: hosts.data.v0,
  endpoint: '/scenario/scenario',
  method: 'POST',
  errorMessage: clientTranslate('datacosmos.fetchErrors.scenarios.cannotPost'),
  errorDescription: (e) => e.join('; '),
});

export type PutScenarioParams = {
  scenarioId: string;
};

export type PutScenarioBody = {
  name: string;
  description: string;
  organisation_id: number;
};

export const putScenario = apiFetchHandler<
  Scenario,
  PutScenarioParams,
  PutScenarioBody
>({
  host: hosts.data.v0,
  endpoint: ({ scenarioId }) => `/scenario/scenario/${scenarioId}`,
  method: 'PUT',
  errorMessage: clientTranslate('datacosmos.fetchErrors.scenarios.cannotPut'),
  errorDescription: (e) => e.join('; '),
});

export type DeleteScenarioData = {
  id: string;
};

export type DeleteScenarioParams = {
  scenarioId: string;
};

export const deleteScenario = apiFetchHandler<
  DeleteScenarioData,
  DeleteScenarioParams
>({
  host: hosts.data.v0,
  endpoint: ({ scenarioId }) => `/scenario/scenario/${scenarioId}`,
  method: 'DELETE',
  errorMessage: clientTranslate(
    'datacosmos.fetchErrors.scenarios.cannotDelete'
  ),
  errorDescription: (e) => e.join('; '),
});

export type AddItemToScenarioData = {
  id: string;
  scenario: string;
  collection: string;
  item: string;
};

export type AddItemToScenarioBody = {
  scenario: string;
  collection: string;
  item: string;
};

export const addItemToScenario = apiFetchHandler<
  AddItemToScenarioData,
  EmptyParams,
  AddItemToScenarioBody
>({
  host: hosts.data.v0,
  endpoint: '/scenario/relation',
  method: 'POST',
  errorMessage: clientTranslate(
    'datacosmos.fetchErrors.scenarios.cannotAddToScenario'
  ),
  errorDescription: (e) => e.join('; '),
});

export type DeleteItemFromScenarioData = {
  id: string;
};

export type DeleteItemFromScenarioParams = {
  relationId: string;
};

export const deleteItemFromScenario = apiFetchHandler<
  DeleteItemFromScenarioData,
  DeleteItemFromScenarioParams
>({
  host: hosts.data.v0,
  endpoint: ({ relationId }) => `/scenario/relation/${relationId}`,
  method: 'DELETE',
  errorMessage: clientTranslate(
    'datacosmos.fetchErrors.scenarios.cannotRemoveFromScenario'
  ),
  errorDescription: (e) => e.join('; '),
});

export type SearchScenarioLink = {
  body: {
    bbox: SearchScenarioBbox | null;
    collections: string[];
    datetime: null;
    ids: string[];
    intersects: null;
    limit: number;
    next: null;
    query: Record<string, string>;
  };
  headers: Record<string, string>;
  href: string;
  merge: boolean;
  method: ClientMethod;
  rel: 'next';
  type: 'application/json';
};

export type SearchScenariosData = {
  context: {
    matched: number;
    returned: number;
  };
  features: STACItem[];
  links: SearchScenarioLink[];
  stac_version: string;
  type: 'FeatureCollection';
  next: string | null;
  prev: string | null;
};

export type SearchScenariosParams = {
  scenarioId: string;
  cursor: string | undefined;
};

export type LteGteQuery = { lte?: number; gte?: number };

export type SearchScenariosQuery = {
  datetime?: LteGteQuery;
  time?: LteGteQuery;
  gsd?: LteGteQuery;
  'view:sun_elevation'?: LteGteQuery;
  'view:incidence_angle'?: LteGteQuery;
  'opencosmos:sun_glint'?: LteGteQuery;
  'eo:cloud_cover'?: LteGteQuery;
  'sat:platform_international_designator'?: { in: string[] };
  'opencosmos:source'?: { in: string[] };
  'opencosmos:season'?: { in: string[] };
  'opencosmos:resolution'?: { in: string[] };
  'processing:level'?: { in: ProcessingLevel[] };
};

export type SearchScenariosBody = {
  query: SearchScenariosQuery;
  limit: number | undefined;
  bbox?: SearchScenarioBbox;
  intersects?: GeoJSON.Polygon;
};

export const searchScenarios = apiFetchHandler<
  SearchScenariosData,
  SearchScenariosParams,
  SearchScenariosBody
>({
  host: hosts.data.v0,
  endpoint: ({ scenarioId, cursor }) =>
    cursor
      ? `/scenario/scenario/${scenarioId}/search?cursor=${cursor}`
      : `/scenario/scenario/${scenarioId}/search`,
  method: 'POST',
  errorMessage: clientTranslate(
    'datacosmos.fetchErrors.scenarios.cannotSearch'
  ),
  errorDescription: (e) => e.join('; '),
});
