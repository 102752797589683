import type { ThunkAction } from 'redux-thunk';
import type { AppState } from '../../reducers/rootReducer';
import type { Action } from 'redux';
import { updateObjectives, removeObjectives } from './action';
import type { IOptimisationObjective } from '../../constants/objectivesTable/constants';
import { OBJECTIVE_TYPES } from '../../constants/objectivesTable/constants';
import { toaster } from 'toaster';

export const updateObj =
  (
    objectives: IOptimisationObjective[]
  ): ThunkAction<void, AppState, null, Action<string>> =>
  async (dispatch, state): Promise<void> => {
    let isCorrect = true;
    const { pointsOfInterest, regionsOfInterest } = state();
    objectives.forEach((object): void => {
      if (object.type === OBJECTIVE_TYPES.POINT_OF_INTEREST_EVENT) {
        if (
          !pointsOfInterest.list.find(
            (point: { name: string }): boolean =>
              point.name === object.object.name
          )
        ) {
          toaster.show({
            icon: 'error',
            intent: 'danger',
            message:
              'One of objects has incorrect name. Compare with events table!',
          });
          isCorrect = false;
        }
      }
      if (object.type === OBJECTIVE_TYPES.REGION_OF_INTEREST_EVENT) {
        if (
          !regionsOfInterest.list.find(
            (region: { name: string }): boolean =>
              region.name === object.object.name
          )
        ) {
          toaster.show({
            icon: 'error',
            intent: 'danger',
            message:
              'One of objects has incorrect name. Compare with events table!',
          });
          isCorrect = false;
        }
      }
    });
    if (!objectives.length) {
      toaster.show({
        icon: 'warning-sign',
        intent: 'warning',
        message: 'Must create at least one row!',
      });
      isCorrect = false;
    }

    if (isCorrect) {
      dispatch(updateObjectives(objectives));
    }
  };

export const removeObj =
  (): ThunkAction<void, AppState, null, Action<string>> =>
  async (dispatch): Promise<void> => {
    dispatch(removeObjectives());
  };
