import React, { useRef } from "react";
import type { AriaDialogProps } from "@react-types/dialog";
import type { OverlayProps } from "@react-aria/overlays";
import {
  FocusScope,
  mergeProps,
  OverlayContainer,
  PressEvent,
  useDialog,
  useModal,
  useOverlay,
  usePreventScroll,
} from "react-aria";
import IconButton from "../../core/IconButton/IconButton";
import Button from "../../core/Button/Button";
import { IconName } from "../../icons/Icon";
import Spinner from "../Spinner/Spinner";
import classNames from "classnames";

type Btn = {
  text: string;
  onPress: (e: PressEvent) => Promise<void> | void;
  icon?: IconName;
  shown: boolean;
  showLoadingIndicator?: boolean;
  keepDialogOpenOnPress?: boolean;
};

type DialogProps = AriaDialogProps &
  OverlayProps & {
    children: JSX.Element | JSX.Element[];
    buttons: Btn[];
    title?: string;
    cancelButtonText?: string;
    hideCancelButton?: boolean;
    onClose?: () => void;
    isOpen?: boolean;
    showButtonsInFooter?: boolean;
  };

const Modal = (props: DialogProps) => {
  const { children, title, onClose } = props;
  const ref = useRef<HTMLDivElement>(null);

  const { overlayProps, underlayProps } = useOverlay(props, ref);
  usePreventScroll();

  const { modalProps } = useModal();
  const { dialogProps, titleProps } = useDialog(props, ref);

  return (
    <div
      {...underlayProps}
      className="fixed inset-0 w-full flex justify-center items-center bg-surface/50 dark:bg-surface-dark/50"
      style={{ zIndex: 9999999 }}
    >
      <FocusScope contain restoreFocus autoFocus>
        <div
          {...mergeProps(dialogProps, overlayProps, modalProps)}
          ref={ref}
          className="color-surface !opacity-100 w-1/2 max-h-[80vh] h-full overflow-auto relative"
        >
          <div className="h-12 color-header flex justify-between items-center p-2 w-full">
            {title ? <h4 {...titleProps}>{title}</h4> : <div />}
            <IconButton icon="Cross" onPress={() => onClose?.()} size={24} />
          </div>
          <div className="p-4">{children}</div>
          <div
            className={classNames(
              "flex flex-col justify-end w-full gap-1",
              {
                "pt-8 p-4": !props.showButtonsInFooter,
              },
              {
                "sticky bottom-0 bg-surface dark:bg-surface-dark py-1 px-4":
                  props.showButtonsInFooter,
              }
            )}
          >
            {props.buttons
              .filter((b) => b.shown)
              .map((b) => (
                <Button
                  key={b.text}
                  text={b.showLoadingIndicator ? <Spinner size={16} /> : b.text}
                  onPress={async (e) => {
                    await b.onPress(e);
                    if (!b.keepDialogOpenOnPress) {
                      onClose?.();
                    }
                  }}
                  className="color-item h-12 w-full"
                />
              ))}
            {!props.hideCancelButton && (
              <Button
                text={props.cancelButtonText ?? "Cancel"}
                onPress={() => onClose?.()}
                className="color-item h-12"
              />
            )}
          </div>
        </div>
      </FocusScope>
    </div>
  );
};

/**
 * Dialog is a component that can be used to show a modal dialog over the rest of the page.
 */
const Dialog = (props: DialogProps) => {
  return (
    <OverlayContainer>{props.isOpen && <Modal {...props} />}</OverlayContainer>
  );
};

export default Dialog;
