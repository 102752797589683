import type { MESSAGE_TYPE } from '../../services/api/msd/responseCodes';

export const COMPONENTS_TYPE = {
  TEXT: 'TEXT',
  BULLET_LIST: 'BULLET_LIST',
  TABLE: 'TABLE',
  CARTESIAN_2D_PLOT: 'CARTESIAN_2D_PLOT',
};

export const PDF = {
  FILE_NAME: 'result.pdf',
};

export const VIEW_TYPES = {
  PDF: 'pdf',
  HTML: 'html',
};

export const BUTTON_TITLE = {
  SWITCH: 'Switch mode',
  DOWNLOAD_PDF: 'Download PDF',
  EXPORT_PDF: 'Export to PDF',
  DOWNLOAD_GROUND_SEGMENT_CSV: 'Download Ground Segment CSV',
  DOWNLOAD_ANNEX_CSV: 'Download Annex CSV',
};

interface ISummaryTableCell {
  value: string;
  backgroundColor?: string;
  bold?: true;
}

export interface ISummarySerie {
  name: string;
  color: string;
  xPoints: number[];
  yPoints: number[];
}

export interface ISummaryText {
  type: typeof COMPONENTS_TYPE.TEXT;
  paragraphs: string[];
}

export interface ISummaryBulletList {
  type: typeof COMPONENTS_TYPE.BULLET_LIST;
  list: string[];
}

export interface ISummaryTable {
  type: typeof COMPONENTS_TYPE.TABLE;
  numberRows: number;
  numberColumns: number;
  defaultBackgroundColor: string;
  cells: ISummaryTableCell[][];
}

export interface ISummaryCartesian2DPlot {
  type: typeof COMPONENTS_TYPE.CARTESIAN_2D_PLOT;
  title: string;
  xAxis: string;
  series: ISummarySerie[];
}

export type ISummaryComponents = ISummaryText &
  ISummaryBulletList &
  ISummaryTable &
  ISummaryCartesian2DPlot;

export interface ISummaryChildren {
  title: string;
  components?: ISummaryComponents[];
  children?: ISummaryChildren[];
}

export interface ISummaryData {
  data: ISummaryChildren;
  id: string;
  type: MESSAGE_TYPE;
}
