import type { ECharts } from 'echarts';

export const setDataZoom = (
  chart: ECharts,
  dataZoom: { start: number; end: number }
): void => {
  chart.setOption({
    dataZoom: [
      {
        type: 'slider',
        ...dataZoom,
      },
      {
        type: 'inside',
        ...dataZoom,
      },
    ],
  });
};
