import Modal from '_atoms/Modal/Modal';
import type { SearchInputProps } from '_atoms/SearchInput/SearchInput';
import SearchInput from '_atoms/SearchInput/SearchInput';
import Button from '_molecules/Button/Button';
import React, { useState } from 'react';
import { OverlayContainer } from 'react-aria';
import type { IconName } from 'ui/Icon';
import { useLocalisation } from 'utils/hooks/useLocalisation';

type Data = { [key: string]: string | number };

type Props = {
  /**
   * Data to be displayed in the modal list.
   * Accepted format is in the form of key-value pairs
   */
  data: Data;

  /**
   * Text for the button responsible for modal opening
   */
  buttonText: string;

  /**
   * Icon for the button responsible for modal opening. If omitted, Expand will be used by default
   */
  buttonIcon?: IconName;
  /**
   * Search bar input props. If omitted, search bar will not be present in the modal
   */
  searchBarProps?: SearchInputProps;
  /**
   * Optional title to display in the modal
   */
  modalTitle?: string;
};

const getTranslationKey = (key: string) => {
  return `datacosmos.catalogAndItems.metadataModal.${key.replace(
    /:/g,
    '_'
  )}` as unknown as TemplateStringsArray;
};

/**
 * MetadataModal component should be used when displaying arbitrary key-value pair data
 * in a separate overlay in the app.
 *
 */
const MetadataModal = ({
  data,
  modalTitle,
  searchBarProps,
  buttonText,
  buttonIcon,
}: Props) => {
  const [open, setOpen] = useState<boolean>(false);

  const { translate, translateWithExplicitFallback } = useLocalisation();

  const getFormattedTranslationLabel = (key: string) => {
    const formattedKey = key
      .replace(/^opencosmos:/, '')
      .replace(/_/g, ' ')
      .replace(/:/g, ' ');

    return translateWithExplicitFallback(getTranslationKey(key), formattedKey);
  };

  return (
    <>
      <OverlayContainer>
        {open && (
          <Modal
            title={modalTitle}
            isDismissable={true}
            shouldCloseOnBlur={true}
            isOpen={open}
            onClose={() => setOpen(false)}
          >
            {searchBarProps && <SearchInput {...searchBarProps} />}
            <ul className="flex flex-col mt-2 items-center">
              <li className="grid !shadow-none grid-cols-2 items-center bg-header dark:bg-header-dark pl-2 py-3 w-full">
                <span className="font-bold">
                  {translate(
                    'datacosmos.catalogAndItems.metadataModal.properties'
                  )}
                </span>
                <span className="font-bold">
                  {translate('datacosmos.catalogAndItems.metadataModal.values')}
                </span>
              </li>
              {Object.entries(data).map(([key, val]) => {
                return (
                  <li
                    key={key}
                    className="grid !shadow-none grid-cols-2 items-center border-b pl-2 py-1 w-full"
                  >
                    <span
                      className="font-bold border-slate-300 capitalize"
                      data-translation-key={getTranslationKey(key)}
                    >
                      {getFormattedTranslationLabel(key)}
                    </span>
                    <span className="break-words">
                      {typeof val === 'object' ? JSON.stringify(val) : val}
                    </span>
                  </li>
                );
              })}
            </ul>
          </Modal>
        )}
      </OverlayContainer>
      <Button
        text={buttonText}
        icon={buttonIcon ?? 'Expand'}
        iconPlacement="right"
        onPress={() => setOpen(true)}
      />
    </>
  );
};

export default MetadataModal;
