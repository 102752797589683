import createAuth0Client from '@auth0/auth0-spa-js';
import type Auth0Client from '@auth0/auth0-spa-js/dist/typings/Auth0Client';
import _ from 'lodash';
import type { IUserWithPermissions } from 'services/api/portal/administration/api/types';
import type { AppState, AuthenticationBackend } from './useAuthentication';

/**
 * Create a new authentication backend using auth0 as the authentication source
 * @param o options to initialise the auth0 client
 */
export const newAuth0Backend = (o: Options): Promise<AuthenticationBackend> => {
  return createAuth0Client({
    domain: o.domain,
    client_id: o.clientId,
    scope: o.scope,
    audience: o.audience,
    redirect_uri: o.redirectUri,
  }).then((c) => new Auth0Backend(c));
};

/**
 * Auth0Backend allows to use auth0 as a authentication backend
 */
export class Auth0Backend {
  c: Auth0Client;

  /**
   * Creates a new connection to an auth0 authentication backend via the provided client
   * @param c the wrapper auth0 client
   */
  constructor(c: Auth0Client) {
    this.c = c;
  }

  /**
   * Returns an up to date token, if one is available, fetching a fresh token in the background if needed
   * @returns will resolve to an up to date token, if possible
   */
  upToDateTokenInBackground = (): Promise<string | undefined> => {
    return this.c
      .getTokenSilently()
      .then((t: string | undefined): string | undefined => {
        if (typeof t === typeof '') {
          return t;
        }
        return undefined;
      });
  };

  /**
   * Starts the login flow, redirecting to the authentication provider
   * @returns will resolve when login succeeds or fails(?)
   */
  login = (s: AppState): Promise<void> => {
    return this.c.loginWithRedirect(s);
  };

  /**
   * Returns, after a return redirect from the login page, the state of the app before the login flow
   * @returns resolves to the state of the app before the login flow happened
   */
  authenticationRedirectResult = (): Promise<AppState> => {
    const sleep = (ms: number) => new Promise((r) => setTimeout(r, ms));

    // TODO: I've got no idea why, but this does not work without the sleep.
    // My suspicion is that handleRedirectCallback is editing the object "pointer"
    // after the function returns, creating a race condition.
    return Promise.all([this.c.handleRedirectCallback(), sleep(100)])
      .then((r) => {
        return r[0];
      })
      .then((r): { appState: { targetUrl?: string } } => {
        if (r.appState === undefined || !_.isObject(r.appState)) {
          return { appState: {} };
        }
        return r.appState as { appState: { targetUrl?: string } };
      })
      .then((s) => {
        if (
          Object.prototype.hasOwnProperty.call(s, 'targetUrl') &&
          typeof s.appState.targetUrl === typeof ''
        ) {
          return { url: s.appState.targetUrl };
        }
        return {};
      });
  };

  /**
   * Returns information about the user, including the permissions the authentication provider is aware of
   * @returns resolves to the user's information from the authentication backend
   */
  user = (): Promise<IUserWithPermissions | undefined> => {
    return this.c
      .getUser()
      .then(
        (
          u: IUserWithPermissions | undefined
        ): IUserWithPermissions | undefined => {
          return u;
        }
      );
  };

  /**
   * Logs out/unauthenticates the currently authenticated user/ends the current session
   */
  logout = (returnTo?: string): void => {
    this.c.logout({ returnTo: returnTo });
  };

  /**
   * Checks whether the current user is authenticated
   * @returns resolves to true if the current user is authenticated
   */
  authenticated = (): Promise<boolean> => {
    return this.c.isAuthenticated();
  };
}

/**
 * Contains the options to configure the auth0 client
 */
export interface Options {
  domain: string;
  clientId: string;
  scope?: string;
  audience?: string;
  redirectUri?: string;
}
