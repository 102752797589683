import type { ReactElement } from 'react';
import React from 'react';
import type { ISummaryText } from '../../constants/summary/constants';

export const SummaryText = (props: ISummaryText): ReactElement => {
  return (
    <div className="summary-text">
      {props.paragraphs.map(
        (p, index): ReactElement => (
          <p key={index}>{p}</p>
        )
      )}
    </div>
  );
};
