import type { Action } from 'redux';
import type { ThunkAction } from 'redux-thunk';
import { uploadPointOfInterest } from 'actions/pointsOfInterest/action';
import type {
  IPIState,
  IPointOfInterest,
} from 'constants/pointsOfInterest/actionTypes';
import type { AppState } from 'reducers/rootReducer';
import { setMarkerSpecify } from 'services/mapService/contextMenu/map/events';
import { generateName } from 'utils/common/generateName';
import type { IOSMFetchResult } from 'declarations/osm';

export const uploadPI =
  (
    pointsOfInterest: IPIState
  ): ThunkAction<void, AppState, null, Action<string>> =>
  async (dispatch): Promise<void> => {
    dispatch(uploadPointOfInterest(pointsOfInterest));
  };

export const fetchPI =
  (
    result: IOSMFetchResult,
    withPopUp?: boolean,
    id?: string
  ): ThunkAction<void, AppState, null, Action<string>> =>
  async (dispatch, state): Promise<void> => {
    const pointsOfInterest = {
      lat: +result.lat,
      lon: +result.lon,
      name: generateName(state().pointsOfInterest, result.display_name),
      id,
    };
    dispatch(setMarkerSpecify(pointsOfInterest as IPointOfInterest, withPopUp));
  };
