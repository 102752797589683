const translation = {
  common: {
    project_name: 'PROJECTNAME',
    lorem_ipsum:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut vel lacus diam. Nunc egestas, elit ut pretium dapibus, nisi purus hendrerit velit, et tincidunt turpis massa non ante. Donec eget nunc tempor, ultricies libero pulvinar, faucibus dolor.',
  },
  currentTime: 'Current Time',
  validation: {
    header: {
      GS: 'Ground Stations',
      PI: 'Points of Interest',
      RI: 'Region of Interest',
    },
    point: {
      lat: 'Latitude',
      lng: 'Longitude',
    },
    'paths-field': {
      regions: 'Regions',
      draw_title: 'Draw new region use drawing manager',
    },
    'path-field': {
      region: 'Region',
    },
    'text-field': {
      names: {
        name: 'Name',
        altitude: 'Altitude',
        elevationAngle: 'Elevation Angle',
        tle1: 'TLE1',
        tle2: 'TLE2',
        date: 'Date',
        semimajorAxis: 'Semi-major Axis',
        eccentricity: 'Eccentricity',
        inclination: 'Inclination',
        rightAscensionOfAscendingNode: 'Right Ascension of Ascending Node',
        argumentOfPerigee: 'Argument of Perigee',
        trueAnomaly: 'True Anomaly',
      },
    },
    errors: {
      type_not_empty: 'Type cannot be empty.',
      name_not_empty: 'Name cannot be empty.',
      name_not_unique: 'Name has to be unique.',
      self_intersecting: 'Polygon path is self intersecting.',
      lat_error: 'Latitude has to to be within bounds [-90,90].',
      lng_error: 'Longitude has to to be within bounds [-180,180].',
      alt_error: 'Altitude has to be greater than 0.',
      poi_elevation_ang_error:
        'Elevation Angle has to to be within bounds [0,90].',
      roi_elevation_ang_error:
        'Elevation Angle has to to be within bounds [15,90].',
      error: 'Error.',
      semimajorAxis: 'has to to be within bounds [6800, 1000000].',
      degrees: 'has to to be within bounds [-360, 360].',
      eccentricity: 'has to to be within bounds [0, 1].',
      tle: 'TLE cannot be empty.',
      criterion_error: 'Select Min|Max criterion.',
      object_error: 'Must select an object.',
      objective_name:
        'One of objects has an incorrect name. Compare with the events table.',
      periapsis_error:
        'The semi-major axis/eccentricity must generate a periapsis altitude ',
      apoapsis_error:
        'The semi-major axis/eccentricity must generate an apoapsis altitude ',
    },
    save_title: 'Save item to store',
  },
  module_msd: {
    module_name: 'Mission design',
    step_payload_configurator: 'Payload',
    step_mission_geometry: 'Mission Geometry',
    step_ground_segment: 'Ground Segment',
    step_satellite_configurator: 'Platform',
    step_modes: 'Modes',
    payload_definition: {
      detailed_title: 'Payload Characteristics',
      detailed_view: {
        general_tab_title: 'General',
        physical_tab_title: 'Physical',
        thermal_tab_title: 'Thermal',
        modes_tab_title: 'Modes',
        general_title: 'General Characteristics',
        physical_title: 'Physical Characteristics',
        thermal_title: 'Thermal Characteristics',
        modes_title: 'Operational Modes',
      },
    },
    mission_geometry: {
      orbit: 'Orbit',
      orbit_plural: 'Orbits',
    },
    ground_segment: {
      save: 'Save Ground Segment',
    },
    satellite: {
      modes: {
        single: 'Mode',
        plural: 'Modes',
        full: 'Satellite Operational Mode',
        satellite_mode: 'Satellite Mode',
      },
    },
  },
  module_ops: {
    module_name: 'Operations',
    telemetry: 'Telemetry Visualiser',
    library: 'Library',
    gss: 'Ground Station Scheduler',
    rti: 'Real-Time Interface',
    scripting: 'Scripting',
    scheduling: 'Schedule',
  },
  module_pdgs: {
    module_name: 'Processing',
    processing: 'Payload Processing',
  },
  module_data: {
    module_name: 'DataCosmos',
    search: 'Search',
    scenario: 'Project items',
    application: 'Applications',
    tasking: 'Tasking',
    docs: 'Documentation',
    views: 'Views',
    tasking_overview: 'Tasking overview',
  },
  'events-table': {
    header: {
      type: 'Type',
      name: 'Name',
    },
    types: {
      PI: 'Point',
      RI: 'Region',
    },
  },
  constraints: {
    GENERIC_CONSTELLATION: {
      title: 'Generic Constellation',
      header: {
        Satellites: 'Satellites',
        Name: 'Name',
        Orbit_Definition: 'Orbit Definition',
      },
      satellite: {
        orbit_type: 'Orbit type',
        type: {
          KEPLERIAN: 'Keplerian Elements',
          TLE: 'TLE',
        },
      },
      form_title: 'Satellite:',
    },
    LAUNCH_OPPORTUNITIES: {
      title: 'Launch Opportunities',
    },
    WALKER_CONSTELLATION: {
      title: 'Walker Constellation',
      altitude: 'Altitude',
      numberOfSatellites: 'Number of satellites',
      numberOfPlanes: 'Number of planes',
      inclination: 'Inclination',
      Parameter: 'Parameter',
      Value: 'Value',
      Optimise: 'Optimise',
      Boundaries: 'Boundaries',
      measurement: {
        m: '[m]',
        km: '[km]',
        deg: '[deg]',
        h: '[h]',
      },
      errors: {
        value: 'Incorrect value!',
      },
    },
  },
  objectives: {
    title: 'Goals',
    form_title: 'Optimisation Objective:',
    table: {
      name: 'Name',
      type: 'Type',
      criterion: 'Criterion',
      max: 'Maximise',
      min: 'Minimise',
    },
    types: {
      title: 'Objective Types',
      mission_metric: 'Mission Metric',
      ground_segment_event: 'Ground Segment Event',
      point_of_interest_event: 'Point of Interest Event',
      region_of_interest_event: 'Region of Interest Event',
    },
    object: {
      title: 'Object',
      all_ground_stations: 'All Ground Stations',
    },
    event: {
      title: 'Event',
      visibility: 'Visibility',
      illuminated_visibility: 'Illuminated Visibility',
    },
    scope: {
      title: 'Scope',
      coverage: 'Coverage',
      revisit: 'Revisit',
    },
    metric: {
      title: 'Metric',
      number_of_satellites: 'Number of Satellites',
      number_of_planes: 'Number of Planes',
      number_of_ground_stations: 'Number of Ground Stations',
      incidence: 'Incidence [%]',
      avg_duration: 'Avg. Duration [s]',
      max_duration: 'Max. Duration [s]',
      min_duration: 'Min. Duration [s]',
      duration_std_deviation: 'Duration Std. Deviation [s]',
      average: 'Avg. Duration [s]',
      maximum: 'Max. Duration [s]',
      minimum: 'Min. Duration [s]',
      std: 'Duration Std. Deviation [s]',
    },
  },
  SOSTable: {
    launchOpportunities: {
      id: 'ID',
      launcher: 'Launcher',
      date: 'Date',
      altitude: 'Altitude [km]',
      inclination: 'Inclination [deg]',
      ltan: 'LTAN  [h]',
      sso: 'SSO',
    },
    groundStations: {
      name: 'Name',
      custom: 'Custom',
      elevationAngle: 'Min Elevation',
      lat: 'Latitude [deg]',
      lon: 'Longitude [deg]',
      altitude: 'Altitude [m]',
    },
    select: 'Select',
    optimise: 'Optimise',
    yes: 'Yes',
    no: 'No',
  },
  results: {
    title: 'Results table',
    cancel: 'Cancel',
    close: 'Close',
    save: 'Save',
    json: 'JSON',
    parameter: 'Parameter',
    objective: 'Objective',
  },
  simulate: {
    title: 'Progress',
    cancel: 'Cancel',
    close: 'Close',
    save: 'Save',
    json: 'JSON',
  },
  optimization_table: {
    optimize: 'Optimize',
    save: 'JSON',
  },
  events_table: {
    type: 'Type',
    name: 'Name',
  },
  alert: {
    ok: 'OK',
    cancel: 'Cancel',
    remove: 'Remove',
    'switch-astrum':
      'You are about to switch the astrum. The events, goals, orbits will be reset. Proceed?',
    'switched-to': 'Switched to {{astrum}}',
  },
  geometry: {
    satelliteMode: 'Satellite Mode',
    satellitesPerPlane: 'Satellites per plane',
    firstPlaneRaan: 'First plane RAAN',
    relativeSpacing: 'Relative spacing',
    type: 'Type',
    parameterisation: 'Parameterisation',
    tle_objects_label: 'Select object',
    KEPLERIAN: 'Keplerian',
    STATIONARY: 'Stationary',
    SYNCHRONOUS: 'Synchronous',
    POLAR: 'Polar',
    TLE: 'TLE',
    WALKER_CONSTELLATION: 'Walker constellation',
    ltan: 'LTAN',
    raan: 'RAAN',
    semimajorAxis: 'Semi-major Axis',
    eccentricity: 'Eccentricity',
    inclination: 'Inclination',
    longitude: 'Longitude',
    trueAnomaly: 'True Anomaly',
    rightAscensionOfAscendingNode: 'Right Ascension of Ascending Node',
    argumentOfPerigee: 'Argument of Perigee',
    altitude: 'Altitude',
    numberOfSatellites: 'Number of satellites',
    numberOfPlanes: 'Number of planes',
    mission_metric: 'Mission Metric',
    ground_segment_event: 'Ground Segment Event',
    point_of_interest_event: 'Point of Interest Event',
    region_of_interest_event: 'Region of Interest Event',
    save: 'Save Mission Geometry',
    measurement: {
      m: '[m]',
      km: '[km]',
      deg: '[deg]',
      rad: '[rad]',
      h: '[h]',
    },
    errors: {
      value: 'Incorrect value!',
    },
  },
  api: {
    error: 'Error in the simulation, please send your scenario to {{email}}',
  },
  userPage: {
    addResource: 'Add resource',
    resource: 'Resource',
    role: 'Role',
    users: 'Users',
    showHiddenRoles: 'Show hidden roles',
    roles: 'Roles',
    searchPlaceholder:
      'Search users by name or email, (e.g: John Doe, *@open-cosmos.com)',
    buttons: {
      add: 'Add',
    },
    assignedPermissions: {
      title: 'Assigned permissions',

      placeholders: {
        resourceId: 'Type resource id',
        resourceName: 'Search by resource name or resource id',
        resourceType: 'Search by resource type',
      },
      areYouSureYouWantToRemove:
        'Are you sure you want to remove ALL permissions for the selected resource?',

      selectARole: 'Select a role',

      success: {
        added: `Role has been added successfully`,
      },

      errors: {
        notEmpty: 'Resource ID must not be empty',
        noRole: 'Select a role',
        noResultsForResourceType: 'No results for resource type',
        cannotSaveEmptyUser: 'Cannot save empty user',
        cannotSaveUserwithoutRole: 'Cannot save user without role',
      },
    },
  },
  rolesPage: {
    viewAttachments: 'View role attachments',
    closeAttachments: 'Close role attachments',
    addAttachment: 'Add Role Attachment',
  },
  datacosmos: {
    header: {
      freeEditingMode: 'Free editing mode',
      sendFeedback: 'Send feedback',
      upgradePermission: 'Upgrade',
      group: 'Group',

      menu: {
        orders: 'Orders',
        theme: {
          title: 'Theme',
          dark: 'Dark',
          light: 'Light',
          auto: 'Auto',
        },
        rolesManager: 'Roles manager',
        users: 'Users',
        logout: 'Logout',
        taskingOverview: 'Tasking Overview',
        matomo: 'Matomo dashboard',
        grafana: 'Grafana monitoring',
        keycloak: 'Keycloak dashboard',
      },
    },
    bottomBar: {
      coordinates: 'Coordinates',
      selectedCoordinateSystem: 'Selected Coordinate System',
    },
    map: {
      dark: 'Dark',
      light: 'Light',
      satellite: 'Mapbox Satellite',
    },
    fetchErrors: {
      views: {
        cannotGet: 'Could not get views',
        cannotPut: 'Could not put view',
        cannotDelete: 'Could not delete view',
        cannotPost: 'Could not post view',
        noViewsAvailable: 'There are no views available for this query',
      },

      activities: {
        cannotGetByMissionId: 'Could not get activities by mission id',
        cannotPatch: 'Could not patch activity',
      },

      scenarios: {
        cannotGetMultiple: 'Could not get scenarios',
        cannotGetOne: 'Could not get scenario',
        cannotPut: 'Could not put scenario',
        cannotDelete: 'Could not delete scenario',
        cannotPost: 'Could not post scenario',
        cannotAddToScenario: 'Could not add item to scenario',
        cannotRemoveFromScenario: 'Could not delete item from scenario',
        cannotSearch: 'Could not search scenarios',
      },

      stac: {
        cannotSearch: 'Failed to search DataCosmos catalog',
      },

      tasking: {
        cannotCreate: 'Could not create a new tasking request',
        cannotGet: 'Could not get tasking request',
        cannotPatch: 'Could not patch tasking request',
        cannotGetById: 'Could not get tasking request by project id',
        cannotPost: 'Could not post a new swath search request',
        cannotPostManualTasking:
          'Could not post a new manual tasking search request',
        cannotGetAll: 'Could not get tasking requests',
      },

      osm: {
        cannotGet: 'Could not fetch region info',
      },

      samplingPixels: {
        cannotGet: 'Could not get STAC item pixels',
      },

      orders: {
        price: {
          cannotGet: "Cannot get image's price",
        },
        orderList: {
          cannotGet: 'Cannot get order list',
        },
        customers: {
          cannotGet: 'Could not get customer by id',
        },
        order: {
          cannotPost: 'Could not create order',
          cannotPostPreferences: 'Could not create preferences',
          cannotPatch: 'Could not update order',
          noOrderSelected: 'No order selected',
          noOrderSelectedDescription: 'Select an order to view it here',
          noOrdersFound: 'No orders found',
          noOrdersFoundFiltersDescription:
            'No orders found with the current filters',
          noOrdersFoundDescription: 'We could not find any orders',
        },
        checkout: {
          cannotGetURL: 'Could not get checkout URL',
        },
      },
      geopdf: {
        postNetCDFFile: {
          cannotPost: 'Could not upload file',
        },
      },
    },

    fetchSuccesses: {
      activities: {
        successfullyPatched: 'Activity successfully updated',
      },
      orders: {
        successfullyCreated: 'Order successfully created',
      },
      postNetCDFFile: {
        successfullyUploaded: 'File successfully uploaded',
      },
    },
    upgradePermissionsDialog: {
      upgradePermissionsHeader: 'Intermediate user level upgrade',
      permissionsBody:
        'Thanks for using CONIDA geoinformation platform. Unfortunately, your current user level does not allow you to see full resolution images. We need a bit more information about you to upgrade your access and allow you to use full resolution data.',
      typeOfUser: 'Please select the type of organisation you work for:',
      userType: {
        institutional: 'Public institutions',
        institutionalTooltipText:
          'Organisations that are part of the Peruvian government.',
        private: 'Private entities',
        privateTooltipText: 'Peruvian companies and individuals',
      },
      sendButtonText: 'Request Upgrade',
      sendEmailText: 'Send email',
      downloadAndFillDocument: `${'In order to proceed, please read through the <a href="https://cdn.www.gob.pe/uploads/document/file/1225819/dir_014_compressed__1_.pdf?v=1597110644" target="_blank" rel="noreferrer" class="font-bold underline text-accent"> Directive</a>. Once completed, please download, print, sign, and send the following <a href="https://cdn.www.gob.pe/uploads/document/file/1299316/Anexo%205.3.pdf?v=1599794505" target="_blank" rel="noreferrer" class="font-bold underline text-accent"> document</a> to the email address xxx@conida.gob.pe. Make sure to use the title "Request to upgrade to intermediate user" and include the email addresses of the users.'}`,
    },
    viewsDialog: {
      returnToFreeEditingMode: 'Return to free editing mode',
      views: 'Views',
      sharedWith: 'Shared with: {{with}}',
      createdAt: 'Created at: {{at}}',
      lastUpdated: 'Last updated: {{at}}',
      openView: 'Open view',
      deleteView: 'Delete view',
      shareView: 'Share view',
      createGeoPDF: 'Create GeoPDF',
      geoPDFHeader: 'Enter GeoPDF details',
      nonTiffWarning:
        'Layers without .tiff assets will be excluded from download',
    },

    optionsDialog: {
      changeFormat: 'Change format',
    },
    saveViewDialog: {
      overwrite: 'Overwrite view {{name}}',
      save: 'Save as new view',

      inputs: {
        name: 'Name:',
        namePlaceholder: 'View name...',
        description: 'Description:',
        descriptionPlaceholder: 'View description...',
      },
    },
    projectsDialog: {
      title: 'Open a project',
      openProject: 'Open project',
      deleteProject: 'Delete project',
      editProject: 'Edit project',
      shareProject: 'Share project',
      createNewProject: 'Create new project',
      organisation: 'Organisation',
    },
    deleteViewDialog: {
      areYouSure: 'Are you sure you want to delete view',
      longWarning:
        'You are about to delete this view, and will lose all your saved layers. This cannot be undone. Type "delete" in the box below and click "Confirm" if you are sure you wish to proceed.',
    },
    deleteProjectDialog: {
      warning: 'WARNING!',
      areYouSure: 'Are you sure you want to delete project {{project}}?',
      longWarning:
        'You are about to delete this project, and will lose links to all images in the project. This cannot be undone. Type "delete" in the box below and click "Confirm" if you are sure you wish to proceed',
      errors: {
        deletionFailed: 'Deletion failed',
      },
      success: 'Project deleted successfully',
      placeholder: "Type 'delete' to confirm your choice",
    },
    editProjectDialog: {
      title: 'Edit project',
      name: 'Name',
      description: 'Description',
      labels: 'Labels',
      key: 'Key',
      value: 'Value',
      keyPlaceholder: 'Project key',
      valuePlaceholder: 'Project value',
      errors: {
        name: 'Name Must not be empty',
        description: 'Description Must not be empty',
        organisation: 'Organisation Must not be empty',
      },
      successfullyEdited: 'Project {{name}} was successfully updated',
    },
    addNewProjectDialog: {
      name: 'Name',
      description: 'Description',
      labels: 'Labels',
      key: 'Key',
      value: 'Value',
      keyPlaceholder: 'Project key',
      valuePlaceholder: 'Project value',
      errors: {
        name: 'Name Must not be empty',
        description: 'Description Must not be empty',
        organisation: 'Organisation Must not be empty',
      },
      successfullyCreated: 'Project {{name}} created successfully',
    },

    buttons: {
      cancel: 'Cancel',
      save: 'Save',
      confirm: 'Confirm',
      overwrite: 'Overwrite',
      draw: 'Draw',
      update: 'Update',
      purchase: 'Purchase',
      request: 'Request',
      upload: 'Upload',
      enable: 'Enable',
      disable: 'Disable',
      completeOrder: 'Complete order',
      payByCard: 'Pay via Credit or Debt Card',
      payByBank: 'Pay via Bank Transfer',
      accept: 'Accept',
      filters: 'Filters',
      closeFilters: 'Close filters',
      drawAoiForStatistics: 'Draw AoI for statistics',
      removeAoiForStatistics: 'Remove AoI',
      searchByRegion: 'Search by region',
      searchByPoint: 'Search by point',
      delete: 'Delete',
      backToSearch: 'Back to search',
      resetAndClose: 'Reset and Close',
      close: 'Close',
    },

    catalogAndItems: {
      catalog: 'Catalog',
      group: 'Group',
      moreInfo: 'More info',
      project: 'Project',
      buyFullResImages: 'Buy full resolution images',

      metadata: {
        sensors: 'Sensors: ',
        resolution: 'Resolution: ',
        minPixelValue: 'Minimum:',
        maxPixelValue: 'Maximum:',
        meanValue: 'Mean:',
        stdValue: 'Standard Deviation:',
        variance: 'Variance:',
        percentile25: '25th Percentile:',
        percentile75: '75th Percentile:',
        showStatistics: 'Show statistics',
        noHistogramData: 'No histogram data',
        statistics: 'Statistics',
        summaryStatistics: 'Summary Statistics',
        yAxisLabel: 'Frequency',
        xAxisLabel: 'Value',
        oza: 'Oza: ',
        noStatistics: {
          title: ' No statistics available',
          description: 'There are no statistics available for this item.',
        },
        copyLink: 'Copy link',
        bandColors: {
          red: 'Red',
          green: 'Green',
          blue: 'Blue',
          gray: 'Gray',
        },
      },

      metadataModal: {
        collection: 'Collection',
        constellation: 'Constellation',
        created: 'Created',
        datetime: 'Datetime',
        eo_cloud_cover: 'Cloud Coverage Percentage',
        end_datetime: 'End Datetime',
        gsd: 'Gsd',
        instruments: 'Instruments',
        item: 'Item',
        opencosmos_copyright: 'Copyright',
        opencosmos_data_area_km2: 'Data Area Km2',
        opencosmos_high_resolution_read_permission:
          'high Resolution Read Permission',
        opencosmos_product_type: 'Product Type',
        opencosmos_rid: 'Rid',
        opencosmos_source: 'Source',
        placeholder: 'Search metadata...',
        platform: 'Platform',
        price_currency: 'Price Currency',
        price: 'Price',
        processing_level: 'Processing Level',
        properties: 'Properties',
        resolution: 'Resolution',
        sat_platform_international_designator:
          'Satellite Platform International Designator',
        scale: 'Scale',
        season: 'Season',
        sensor_type: 'Sensor Type',
        sentinel_grid_square: 'Sentinel Grid Square',
        sentinel_latitude_band: 'Sentinel Latitude Band',
        sentinel_utm_zone: 'Sentinel Utm Zone',
        simera_calibration: 'Simera Calibration',
        simera_capture_mode: 'Simera Capture Mode',
        simera_compression: 'Simera Compression',
        simera_relative_correction: 'Simera Relative Correction',
        simera_session_id: 'Simera Session Id',
        start_datetime: 'Start Datetime',
        sun_glint: 'Sun Glint',
        title: 'STAC metadata',
        triggerButton: 'Show all metadata',
        updated: 'Updated',
        values: 'Values',
        video: 'Video',
      },

      amountAvailable: '{{amount}} / {{total}} available',

      addToProjectPopup: {
        title: 'Select a project to add items to',
        dropdownInitialItem: 'Select a project',
        errors: {
          selectAProject: 'Select a project',
        },
      },

      errors: {
        noResults: {
          title: 'No results',
          description: 'This project is empty',
        },
        fetchingFailed: {
          title: 'Something went wrong',
          description: "Couldn't fetch items",
        },
        nothingMatchesFilters: 'No features match specified filters',
        noAssetsAvailable: 'No assets available',
        nothingMatchesSearchedItem: 'No asset found',
      },

      purchaseDialog: {
        title: 'Title',
        location: 'Location',
        date: 'Date',

        subtotal: 'Subtotal: {{subtotal}} {{currency}}',
        discount: 'Discount: {{discount}} {{currency}}',
        totalPrice: 'Total price',
        price: 'Price',
        noPrice: 'Unable to fetch price, please contact us for more details',

        orderCheckout: 'Order checkout',
        payingViaBankTransfer: 'Paying via bank transfer',

        paymentViaCardComplete: 'Payment via Debit or Credit Card completed',

        dataPurchasedWillNotBeAvailableUntil:
          'Data purchased in this order will not be available until the payment details have been verified',
        pleaseMakeATransferWithRef:
          'Please make a bank transfer with reference {{reference}} to:',
        verificationOfPaymentDetailsCanTake:
          '  Verification of payment details can take up to 2 working days from the payment date',

        couldNotFinalizePayment: 'Could not finalize payment',
      },
    },

    tasking: {
      title: 'Tasking',

      existing: {
        title: 'Existing',
        newRequestFromExisting: 'New request from existing',
        errors: {
          noRequests: {
            title: 'No requests found',
            description: 'Create a new tasking request to get started',
          },
          newRequestBtn: 'New request',
        },
        createNewTaskingRequest: 'Create new tasking request',

        requestType: {
          MANUAL: 'MANUAL',
          AUTOMATED: 'AUTOMATED',
        },

        requestStatus: {
          PENDING: 'PENDING',
          CONFIRMED: 'CONFIRMED',
          ACQUIRING: 'ACQUIRING',
          SUCCESSFUL: 'SUCCESSFUL',
          FAILED: 'FAILED',
          CANCELLED: 'CANCELLED',
        },

        activityStatus: {
          SUGGESTED: 'SUGGESTED',
          CONFIRMED: 'CONFIRMED',
          SCHEDULED: 'SCHEDULED',
          SUCCEEDED: 'SUCCEEDED',
          FAILED: 'FAILED',
          EXPIRED: 'EXPIRED',
          CANCELLED: 'CANCELLED',
        },
      },

      new: {
        aoi: 'Area of Interest',
        satellites: 'Satellites',
        title: 'New',
        draw: 'Draw',

        type: 'Request type',
        label: 'Region name',
        labelTooltip: 'Area of Interest',
        manual: {
          title: 'Manual',
          description:
            'Manual tasking allows you to search for opportunities within the given AoI and dates. You will be able to adjust swaths manually',
        },

        automated: {
          title: 'Automatic',
          description:
            'Automated tasking allows you to request optimized search results according to the set tasking date and AoI',
        },

        calendar: {
          availableOpps: 'Available opportunities',
        },

        instruments: {
          add: 'Add',
          addAll: 'Add all',

          filters: {
            dataType: 'Data type',
            gsd: 'GSD (m)',
          },

          errors: {
            noSatellitesSelected: 'No satellites selected',
          },
        },

        upload: 'Upload',
        parameters: 'Parameters',
        start: 'Start',
        end: 'End',
        selectedInstruments: 'Selected instruments',
        nonIdealStateTitle: 'No satellites selected',
        nonIdealStateDesc:
          'Select satellites above to search for opportunities',
        constraints: {
          title: 'Constraints',
          rollAngle: 'Roll angle',
          sza: 'Sun zenith angle',
          szaDescription:
            'Sun Zenith Angle constraint [degrees]. The SZA is the angle between a vector perpendicular to the Earth surface at the centre of the swath and a vector pointing to the sun from the same point.',
          rollAngleDescription:
            'Satellite Roll Angle constraint [degrees]. The angle between the NADIR vector and satellite pointing vector, projected to the plane perpendicular to the satellite velocity vector.',
          szaDayButton:
            'Day: ensure acquisition when the sun is above the horizon',
          szaNightButton:
            'Night: ensure acquisition happens when the sun is below the horizon',
        },
        objectives: {
          title: 'Objectives',
          oza: 'Observation zenith angle',
          sza: 'Sun zenith angle',
        },
        search: 'Search',
        noneFoundTitle: 'No results',
        noneFoundDesc:
          'No opportunities were found for selected area on chosen dates for selected satellites',
        request: 'Request',
      },
      swathControl: {
        start: 'Start',
        end: 'End',
        duration: 'Duration: {{duration}}',
        rollAngle: 'Roll angle',
        min: 'Min: {{min}}',
        max: 'Max: {{max}}',
      },
      purchaseDialog: {
        thisRequestIsComposed_one:
          'This request is composed of {{count}} swath',
        thisRequestIsComposed_other:
          'This request is composed of {{count}} swaths',
        youCanFindTheDetailed:
          'You can find the detailed request statistics in the section below',
        statistics: {
          combinedRequestStatistics: 'Combined request statistics',
          joinedOverlaping: 'Joined overlaping area',
          totalImaged: 'Total imaged area',
          roiCoverage: 'Region of interest coverage',
          additionalNotes: 'Additional notes:',
          totalPrice: 'Total price {{price}}',
        },
      },
      automatedRequestModal: {
        title: 'Automated request details',

        regionInfo: 'Region info',

        instrumentDetails: 'Instrument details',
        satelliteName: 'Satellite name',
        satelliteCOSPAR: 'Cospar ID',
        sensor: 'Sensor',

        regionName: 'Region name',
        regionArea: 'Region area',
        sza: {
          min: 'Min SZA',
          max: 'Max SZA',
        },
        oza: {
          min: 'Min OZA',
          max: 'Max OZA',
        },
        from: 'From',
        to: 'To',
      },

      legend: {
        failed: 'Failed',
        planned: 'Planned',
        succeeded: 'Succeeded',
        target: 'Target swath',
        totalArea: 'Total area',
        uniqueArea: 'Unique area',
        roiCoverage: 'Roi coverage',
        fieldOfRegard: 'Field of regard',
        currentSwath: 'Current swath',
        confirmedSwath: 'Confirmed swath',
        aoi: 'AoI',
      },
      errors: {
        tooLarge: 'The AoI must not be larger than 15,000,000 km²',
      },
      filters: {
        status: 'Status',
      },
    },
    layers: {
      layers: 'Layers',
      blendMode: 'Blend mode',
      opacity: 'Opacity',
      brightness: 'Brightness',
      saturation: 'Saturation',
      contrast: 'Contrast',
      layerOperationDisabled: 'Applied for full resolution images',
      backToLayers: 'Back to layers',
      timeSeries: 'Time series',
      reset: 'Reset',
      blendModes: {
        normal: 'normal',
        multiply: 'multiply',
        screen: 'screen',
        overlay: 'overlay',
        darken: 'darken',
        'color-dodge': 'color-dodge',
        'color-burn': 'color-burn',
        'hard-light': 'hard-light',
        'soft-light': 'soft-light',
        difference: 'difference',
        exclusion: 'exclusion',
        hue: 'hue',
        saturation: 'saturation',
        color: 'color',
        luminosity: 'luminosity',
      },
      pixelOperations: {
        title: 'Pixel operation modes',
        singlePixelValue: 'Single pixel value',
        linePixelValues: 'Line pixel values',
        vsWavelength: 'Single pixel value vs wavelength',
        pixelSpectralSignatures: 'Pixel spectral signatures',
        tooltip: {
          loading: 'Loading...',
          noData: 'No data',
        },
      },

      pixelOperationsChart: {
        wavelength: {
          noData: 'No wavelength pixel data',
          download: 'Download data',
          xAxisLabel: 'Wavelength',
          downloadDataAs: 'Download data as: ',
        },

        linePixels: {
          noData: 'No line pixel data',
          horizontalPixels: 'Horizontal pixels',
          verticalPixels: 'Vertical pixels',
          xAxisLabelVertical: 'Latitude',
          xAxisLabelHorizontal: 'Longitude',
          downloadDataAs: 'Download data as: ',
          download: 'Download {{axis}} pixel data',
        },

        spectralSignature: {
          noData: 'No spectral signature data',
        },
      },
      bandAlgebra: {
        indexTab: 'Index',
        customTab: 'Custom',
        title: 'Band algebra',
        expressionNotSupported: 'Expression not supported for this satellite',
        predefined: {
          title: 'Predefined',
          index: {
            ndvi: {
              title: 'Vegetation index (NDVI)',
              description:
                'Normalised Difference Vegetation Index - indicates how much vegetation there is in an area, and the health of that vegetation',
            },

            gndvi: {
              title: 'Vegetation index (GNDVI)',
              description:
                'Green Normalised Difference Vegetation Index - provides an estimation of how much chlorophyll/photosynthetic activity there is in an area',
            },

            avi: {
              title: 'Vegetation index (AVI)',
              description:
                'Advanced Vegetation Index - indicates how healthy vegetation is in an area',
            },

            savi: {
              title: 'Soil Adjusted Vegetation index (SAVI)',
              description:
                'Soil Adjusted Vegetation Index - indicates how healthy vegetation is in areas with low vegetation, correcting for reflections from bare soil',
            },

            arvi: {
              title: 'Vegetation index (ARVI)',
              description:
                'Atmospherically Resistant Vegetation Index - a measure of vegetation which is less affected by atmospheric reflectance (haze, etc...)',
            },

            ndmi: {
              title: 'Moisture index (NDMI)',
              description:
                'Normalised Difference Moisture Index - measures the level of moisture in vegetation',
            },
            msi: {
              title: 'Moisture index (MSI)',
              description:
                'Moisture Stress Index - distinguishes between dry and moist vegetation',
            },
            gci: {
              title: 'Green coverage index (GCI)',
              description:
                'Green Cover Index - measures the quantity of chlorophyll in plants in an area, an indicator of stress/health of the vegetation',
            },
            nbr: {
              title: 'Burn ratio index (NBR)',
              description:
                'Normalised Burn Ratio - highlights burned areas and measures the burn severity, e.g. after a wild fire',
            },
            bsi: {
              title: 'Soil index (BSI)',
              description: 'Bare Soil Index - identifies regions of bare soil',
            },
            ndwi_gao: {
              title: 'Water index (NDWI, Gao)',
              description:
                'Normalised Difference Water Index (Gao) - measures water content in leaves',
            },
            ndwi_mcfeeters: {
              title: 'Water index (NDWI, McFeeters)',
              description:
                'Normalised Difference Water Index (McFeeters) - measures water content in bodies of water',
            },
            ndsi: {
              title: 'Snow index (NDSI)',
              description:
                'Normalised Difference Snow Index - detects the presence of snow',
            },
            ndgi: {
              title: 'Glacier index (NDGI)',
              description:
                'Normalised Difference Glacier Index - identifies glaciers',
            },
            sipi: {
              title: 'Pigment index (SIPI)',
              description:
                'Structure Insensitive Pigment Index - measures the amount of carotenoid pigments in vegetation',
            },
            sr: {
              title: 'Simple ratio index (SR)',
              description:
                'Simple Ratio Index - distinguishes between vegetated and non-vegetated areas, stressed and unstressed plants',
            },
            npcri: {
              title: 'Normalized pigment chlorophyll ratio index (npcri)',
              description:
                'Normalized pigment chlorophyll ratio index - a numerical indicator which can be used to determine crop and/or vegetation chlorophyll content. This formula is usually found within application in precession agriculture.',
            },
          },
        },
        custom: {
          title: 'Custom algebra',
          name: 'Name',
          description: 'Description',
          save: 'Save',
          saveAndApply: 'Save and apply',
          pallet: 'Pallet',
          none: 'None',

          success: `New band algebra {{name}} successfully created.`,

          single: {
            title: 'Single',
            showOnlyBand: 'Show only band',

            errors: {
              bandNotSelected: 'Select a band',
              noMinimum: 'Minimum Must not be empty',
              noMaximum: 'Maximum Must not be empty',
              noName: 'Name Must not be empty',
            },
          },
          rgb: {
            title: 'RGB',
            minimmum: 'Minimum',
            maximum: 'Maximum',

            errors: {
              min: 'Minimum Must not be empty',
              max: 'Maximum Must not be empty',
              r: 'R Must not be empty',
              g: 'G Must not be empty',
              b: 'B Must not be empty',
              name: 'Name Must not be empty',
            },
          },
          index: {
            title: 'Index',
            expression: 'Expression',
            expressionPlaceholder: 'Enter your expression',

            errors: {
              noExpression: 'Input an expression',
              noMinimum: 'Minimum Must not be empty',
              noMaximum: 'Maximum Must not be empty',
              noName: 'Name Must not be empty',
            },
          },
        },
      },
      vectorOperations: {
        title: 'Vector operations',
        union: 'Union',
        intersection: 'Intersection',
        difference: 'Difference',
      },
    },
    rightClickMenu: {
      copyCoords: {
        copyCurrentCoords: 'Copy current coordinates',
        success: 'Copied current coordinates {{coordinates}}',
      },
      line: 'Draw a line',
      polygon: 'Draw an area (polygon)',
      circle: 'Draw an area (circle)',
      rectangle: 'Draw an area (rectangle)',
      select: 'Select this layer',
      polyline: 'Draw a polyline',
    },
    tooltips: {
      header: {
        saveView: 'Save View',
        downloadScreenshot: 'Download Screenshot as {{format}}',
        collapse: 'Collapse',
        listView: 'List View',
        gridView: 'Grid View',
        uploadFile: 'Upload an image to this project',
        noUploadPermissions:
          "You don't have permission to upload images to this project",
      },
      toolbar: {
        catalog: 'Catalog',
        items: 'Project items',
        tasking: 'Satellite tasking',
        applications: 'Applications',
        layers: 'Layers menu',
        expandMinimap: 'Expand minimap',
        noPermissionForTasking:
          'You do not have permissions to task for this project',
      },

      tasking: {
        noMultiple: 'Tasking multiple AoIs is curently not supported',

        gsd: {
          title: 'Ground sampling distance',
          description:
            'The Ground Sample Distance is the distance on ground between the centres of two adjacent pixels, equivalently the length of the side of each pixel on ground. Particularly at high roll angles, the GSD may not be be uniform across the image.',
        },

        automated: {
          objective:
            'The automated tasking system will prefer to select captures whose parameters most closely match the selected values when possible',
          constraints:
            'The automated tasking system will not select captures whose parameters are outside of the selected limits',
          sza: 'Sun Zenith Angle constraint. The SZA is measured at the centre of the swath on the Earth Surface.',
          oza: 'Observation Zenith Angle constraint is the angle between the satellite vector and the local zenith measured at the observation point.',
        },

        manual: {
          constraints:
            "Don't show opportunities for which the parameters are outside of the selected limits",
        },

        satelliteDescriptions: {
          'GAOFEN-1A':
            'Gaofen 1A is a Chinese high-resolution optical Earth observation satellite',
          'GAOFEN-1B':
            'Gaofen 1B is a Chinese high-resolution optical Earth observation satellite',
          'GAOFEN-1C':
            'Gaofen 1C is a Chinese high-resolution optical Earth observation satellite',
          'GAOFEN-1D':
            'Gaofen 1D is a Chinese high-resolution optical Earth observation satellite',
          'CBERS-4':
            'CBERS-4 is a remote sensing satellite intended for operation as part of the China–Brazil Earth Resources Satellite program',
          'GAOFEN-2':
            'Gaofen-2 is a Chinese high-resolution optical Earth observation satellite',
          'GAOFEN-6':
            'Gaofen-6 is a Chinese high-resolution optical Earth observation satellite',
          'SENTINEL-1A':
            'Sentinel-1A is a European radar imaging satellite launched in 2014',
          'SENTINEL-1B':
            'Sentinel-1B is a European radar imaging satellite launched on 25 April 2016',
          'SENTINEL-2A':
            'Sentinel-2A is a European optical imaging satellite launched in 2015',
          'SENTINEL-2B':
            'Sentinel-2B is a European optical imaging satellite that was launched on 7 March 2017',
          'SENTINEL-3A':
            'Sentinel-3A is a European Space Agency Earth observation satellite dedicated to oceanography which launched on 16 February 2016',
          'SENTINEL-3B':
            'Sentinel-3B is a European Space Agency Earth observation satellite dedicated to oceanography which launched on 25 April 2018',
          'SENTINEL-5P':
            'Sentinel-5P is an Earth observation satellite developed by ESA as part of the Copernicus Programme to close the gap in continuity of observations between Envisat and Sentinel-5',
          'SENTINEL-6':
            'The Sentinel-6 Michael Freilich satellite is a radar altimeter satellite developed in partnership between several European and American organizations',
          'LANDSAT-7':
            'Landsat 7 is the seventh satellite of the Landsat program launched on 15 April 1999',
          'LANDSAT-8':
            'Landsat 8 is an American Earth observation satellite launched on 11 February 2013',
          'LANDSAT-9':
            'Landsat 9 is an Earth observation satellite launched on 27 September 2021',
          'SPOT-6':
            'SPOT 6 is a commercial high-resolution optical Earth imaging satellite',
          'SPOT-7':
            'SPOT 7 is a commercial high-resolution optical Earth imaging satellite',
          SMAP: 'Soil Moisture Active Passive, a NASA mission to measure soil moisture and land freeze/thaw state',
          SMOS: 'Soil Moisture and Ocean Salinity, an ESA mission to measure soil moisture, sea surface salinity, and other environmental properties',
          'GEOSAT-2':
            'Geosat-2 is a Spanish remote sensing Earth observation satellite built for Elecnor Deimos under an agreement with Satrec Initiative',
          'SUPERVIEW-1-01':
            'SuperView-1 or GaoJing-1 constellation consists of four Chinese commercial remote sensing satellites operated by Beijing Space View Tech Co Ltd',
          'SUPERVIEW-1-02':
            'SuperView-1 or GaoJing-1 constellation consists of four Chinese commercial remote sensing satellites operated by Beijing Space View Tech Co Ltd',
          'SUPERVIEW-1-03':
            'SuperView-1 or GaoJing-1 constellation consists of four Chinese commercial remote sensing satellites operated by Beijing Space View Tech Co Ltd',
          'SUPERVIEW-1-04':
            'SuperView-1 or GaoJing-1 constellation consists of four Chinese commercial remote sensing satellites operated by Beijing Space View Tech Co Ltd',
          'JILIN-1-03-B':
            'Chinese commercial remote sensing satellites for high definition video',
          'JILIN-1-04-B':
            'Chinese commercial remote sensing satellites for high definition video',
          'JILIN-1-05-B':
            'Chinese commercial remote sensing satellites for high definition video',
          'JILIN-1-06-B':
            'Chinese commercial remote sensing satellites for high definition video',
          'JILIN-1-07-B':
            'Chinese commercial remote sensing satellites for high definition video',
          'JILIN-1-08-B':
            'Chinese commercial remote sensing satellites for high definition video',
          'JILIN-1-GAOFEN-03-C-01':
            'Chinese commercial remote sensing satellites for high definition video',
          'JILIN-1-GAOFEN-03-C-02':
            'Chinese commercial remote sensing satellites for high definition video',
          'JILIN-1-GAOFEN-03-C-03':
            'Chinese commercial remote sensing satellites for high definition video',
          MANTIS:
            'MANTIS is an Open Cosmos high-resolution multispectral Earth observation satellite',
          MENUT:
            'Menut is an Open Cosmos medium-resolution multispectral Earth observation satellite',
          'COGNISAT-6':
            'Cognisat-6 is an Open Cosmos medium-resolution hyperspectral Earth observation satellite with an Inter-Satellite Link',
          'COGNISAT-6-EM':
            'Engineering Model (EM) for Cognisat-6, for test purposes only',
        },

        sensorDescriptions: {
          HiRAIS:
            'An optical payload with 0.75m pan-sharpened resolution, 4 bands and a 10bit sensor',
          SLIM6:
            'Surrey Linear Imager Multispectral 6 channels - but 3 spectral bands',
          'MultiScape100 CIS':
            'Medium-resolution optical multispectral - 7 available bands',
          HRI: 'High-resolution optical multispectral - 4 available bands',
          PMC: 'An optical CCD array payload with 2m pan-sharpened resolution, 4 bands and 10bit sensor',
          WFI: 'Wide Field Imager with 16 m MS medium-resolution and a combined swath of 830 km',
          PMC2: 'An optical CCD payload with 0.8m pan-sharpened resolution, 4 bands and 10bit sensor',
          'CSAR/EWS': 'S-1 Synthetic Aperture Radar Extra Wide Swath',
          'CSAR/IWS': 'S-1 Synthetic Aperture Radar Interferometric Wide Swath',
          'CSAR/S': 'S-1 Synthetic Aperture Radar Strip Map Mode',
          'CSAR/WV': 'S-1 Synthetic Aperture Radar Wave Mode',
          RFC: 'RF calibration mode',
          'CAL-DARK-O':
            'MSI dark calibration CSM (calibration shutter mechanism) open',
          'CAL-SUN': 'MSI sun calibration mode',
          'CAL-VIC': 'MSI vicarious calibration mode',
          'MSI-NOBS': 'Multispectral Imager nominal imaging mode',
          OLCI: 'Ocean and Land Color Instrument',
          'SLSTR-B':
            'Sea and Land Surface Temperature Radiometer - Backward Inclined view',
          'SLSTR-N':
            'Sea and Land Surface Temperature Radiometer - Near Nadir view',
          SRAL: 'SAR Radar Altimeter',
          TROPOMI: 'Tropospheric Monitoring Instrument',
          'Poseidon-4': 'Synthetic aperture Radar Altimeter',
          'ETM+': 'Enhanced Thematic Mapper Plus',
          TIR: 'Thermal infrared',
          OLI: 'Operational Land Imager (Panchromatic and Multispectral)',
          TIRS: 'Thermal Infrared Sensor',
          Imager: 'GaoJing / SuperView Earth Observation Constellation Imager',
          'NAOMI-1': 'New AstroSat Optical Modular Instrument',
          'NAOMI-2': 'New AstroSat Optical Modular Instrument',
          MUXCam:
            'Multispectral camera for high resolution land and vegetation observation',
          'PanMUX-PAN':
            'Panchromatic and Multispectral imager for high resolution land and vegetation observation',
          IRS: 'Infrared Medium Resolution Scanner',
          'Jilin Imager': 'High resolution (4K) video imager',
          'Jilin GaoFen Imager': 'High resolution (4K) video imager',
          HyperScape100:
            'Medium-resolution optical hyperspectral - 32 simultaneous spectral bands',
        },

        existing: {
          viewAoi: 'View AoI',
          viewSwath: 'Swath',

          activities: {
            duration: 'Duration',
            imager: 'Imager',
            aoiCoverage: 'AoI coverage',
            oza: 'Observation zenith angle (OZA)',
            sza: 'Sun zenith angle (SZA)',
            rollAngle: 'Roll angle',
            showSwath: 'Show swath',
            viewStac: 'View stac item',
            editSwath: 'Edit swath',
            editSwathDisabled: '(Disabled) Edit swath',
            processingLevel: 'Processing level',
          },
        },
      },

      catalogAndItems: {
        metadata: {
          platformName: 'Platform name',
          sza: 'Sun zenith angle',
          cloudCoverage: 'Cloud coverage',
          location: 'Location',
          price: 'Price',
        },

        icons: {
          previewOnMap: 'Display preview on map',
          showHighResPreview: 'Display full resolution on map',
          removeFromMap: 'Remove from map',
          addToCart: 'Add to shopping cart',
          removeFromCart: 'Remove from shopping cart',
          addToProject: 'Add item to project',
          addedToProject: 'Item added to project',
          centreItemOnMap: 'Center map on item',
          showMetadataAssets: 'Show metadata assets',
          removeFromProject: 'Remove from project',
          imageCannotBePurchased: 'This image cannot be purchased',
        },

        removeFromProject: 'Remove selected items from {{projectName}}',
        addToProject: 'Add selected items to {{projectName}}',
        fullResAvailable: 'Full resolution images available for this item',
        downloadAsset: 'Download asset',
        disableFullRes: 'Disable full resolution image',
        showFullRes: 'Show full resolution image',

        purchaseDialog: {
          satelliteName: 'Satellite name',
          location: 'Location',
          date: 'Date',
          sunElevationAngle: 'Sun elevation angle',
          cloudCoverage: 'Cloud coverage',
          title: 'Title',
        },
      },
    },
    filters: {
      geometry: 'Geometry',
      currentViewArea: 'Current view area only',
      uploadAoi: 'Upload area of interest',
      drawAoi: 'Draw area of interest',
      clearAoi: 'Clear area of interest',
      satellite: 'Satellite',
      dates: 'Dates',
      hours: 'Hours of the day (UTC)',
      fromHours: 'From',
      toHours: 'To',
      cloudCoverage: 'Cloud coverage (%)',
      processingLevel: 'Processing level',
      productType: 'Product type',
      imageBand: 'Image band',
      seasons: 'Seasons',
      assetType: 'Asset type',
      source: 'Sources',
      resolution: 'Resolution',
      clearAll: 'Clear all filters',
      sza: 'SZA',
      oza: 'OZA (degrees)',
      sunGlint: 'Sun glint',
      gsd: 'GSD (m)',
      sensorType: 'Sensor type',
      platformType: 'Platform type',
      placeholder: 'Search...',
      startDate: 'Start date',
      endDate: 'End date',
      min: 'Min',
      max: 'Max',
      fetchRegion: 'Fetch region',
      display: 'Display',
      detailedTooltips: {
        gsd: {
          content: 'Ground sampling distance',
          description:
            'The Ground Sample Distance is the distance on ground between the centres of two adjacent pixels, equivalently the length of the side of each pixel on ground. Particularly at high roll angles, the GSD may not be be uniform across the image.',
        },
        currentViewArea: {
          content: 'Current view area',
          description:
            'Display images for the currently visible area on the map. When panning the map, the images will be updated automatically.',
        },
        satellite: {
          content: 'Filter by satellite',
          description: "Choose which satellite's images to display.",
        },
        dates: {
          content: 'Filter by dates',
          description:
            'A date range used to filter images. When active, only images taken within the selected date range will be displayed.',
        },
        sza: {
          content: 'Sun zenith angle',
          description:
            "The angle between the sun and the vertical, or the angle between the direction of the sun and a line perpendicular to the Earth's surface at a specific location and time. This angle is measured from the observer's location, and it tells you how high the sun is in the sky.",
        },
        oza: {
          content: 'Observation zenith angle',
          description:
            "OZA refers to the angle between the zenith (the point in the sky directly above an observer) and the line of sight from a satellite or sensor to a specific point on the Earth's surface.",
        },
        cloudCoverage: {
          content: 'Cloud coverage percent',
          description:
            'A measure of the extent to which the sky is covered by clouds at a specific location and time. It is expressed as a percentage and indicates the portion of the sky that is obscured or covered by clouds in relation to the total sky area',
        },
        sunGlint: {
          content: 'Sun glint amount',
          description:
            "Sun glint is a measurement of the intensity of sunlight reflected off the Earth's surface, particularly bodies of water, when observed from satellites or aircraft. It is typically expressed as a numeric value and is used in remote sensing to assess and correct for the impact of intense sunlight reflection on data quality and analysis.",
        },
        hoursOfDay: {
          content: 'Hours of the day',
          description:
            'An hour range used to filter images. When active, only images taken within the selected hour range will be displayed.',
        },
        sensorType: {
          content: 'Sensor type',
          description:
            'The type of sensor on the satellite. When active, only images taken with the selected sensor type will be displayed.',
        },
        processingLevel: {
          content: 'Processing level',
          description:
            "Processing level indicates the stage of data processing and calibration that remote sensing or satellite data has undergone. It provides insight into the data's quality and level of refinement, aiding in its interpretation and use.",
        },
        productType: {
          content: 'Product type',
          description: 'Search by product type.',
        },
        imageBand: {
          content: 'Image band',
          description:
            'Search for images which have data available for a specific band.',
        },
        assetType: {
          content: 'Asset type',
          description: 'Search by type of the asset, (e.g Raster or Vector)',
        },
        sources: {
          content: 'Sources',
          description:
            'The source of the image. When active, only images taken from the selected source will be displayed.',
        },
        resolution: {
          content: 'Resolution',
          description:
            'Search for images with a given resolution such as full or limited',
        },
        platformType: {
          content: 'Platform type',
          description:
            'Search for images taken from a specific platform type (e.g. satellite or uav)',
        },
        seasons: {
          content: 'Seasons',
          description:
            'Search for images taken during a specific season (e.g. Summer).',
        },
      },

      resolutions: {
        full: 'Full',
        limited: 'Limited',
      },
      tooltips: {
        closeFilters: 'Close Filters',
      },
      errors: {
        noFilteringByMultiple:
          'Filtering by multiple areas of interest is not supported',
      },
    },

    uploadRegion: {
      fileAlreadyPresent: 'File already present on map',
      filesSupported: '{{files}} files supported',

      geoJsonValidator: {
        invalidType:
          'Uploaded AoI type invalid - Uploaded AoI type should be one of the following: {{allowed}}',
        invalidGeometryType:
          'Uploaded AoI type invalid - Uploaded AoI geometry type should be one of the following: {{allowed}}',

        typeUnsupported:
          'Uploaded AoI type unsupported - the uploaded AoI geometry type must not be any of the following: {{disallowed}}',
        tooLarge:
          'Area too large - the uploaded AoI area must not be larger than 15,000,000 km^2',
        hasHoles:
          'Invalid uploaded AoI - the uploaded AoI has interior rings (holes) that are not supported',
        isClockwise:
          'Invalid uploaded AoI coordinates - Uploaded AoI coordinates must always be in anti-clockwise direction',
      },
    },

    orders: {
      title: 'Orders',
      start: 'Start date',
      end: 'End date',
      buttons: {
        markAsPaid: 'Mark as paid',
        cancel: 'Cancel',
      },
      orderSummary: {
        title: 'Order summary',
        description_one: '{{count}} catalog item',
        description_other: '{{count}} catalog items',
      },
      orderStatus: {
        title: 'Order status',
        paid: 'PAID',
        unpaid: 'UNPAID',
        cancelled: 'CANCELLED',
      },
      accessStatus: {
        title: 'Access status',
      },
      orderedOn: 'Ordered on {{date}}',
    },

    views: {
      order: 'Order',
      by: 'By',
      from: 'From',
      to: 'To',
      inProject: 'In project',
      search: 'Search',
    },
    applications: {
      title: 'Applications',
      install: 'Install',
      uninstall: 'Uninstall',
      global: {
        buttons: {
          submit: 'Submit',
        },
      },
      cropImg: {
        title: 'Crop image by AoI',
        description:
          'Creates a workflow that crops an image by a given area of interest',
        shortDescription: 'Crops the image by a given area of interest',

        inputs: {
          fullResImg: {
            title: 'Click a full resolution image on the map',
            placeholder: 'Full res image id...',
          },
          aoi: {
            title: 'Area of interest: ',
            placeholder: 'AoI name',
          },
          targetProject: {
            title: 'Target project: ',
            description:
              ' Choose the project in which the result of the processing will be stored',
          },
        },
      },
      toa: {
        title: 'TOA',
        shortDescription: 'Perform TOA calculations on satellite imagery',
        description:
          'Calculate Top of Atmosphere (TOA) values for accurate pre-atmospheric correction measurements. Ideal for researchers and professionals in remote sensing.',
        inputs: {
          fullResImg: {
            title: 'Click a full resolution image on the map',
            placeholder: 'Full res image id...',
          },
        },
      },
      digitalLevelsToRadiance: {
        title: 'Digital Levels to Radiance',
        shortDescription:
          'Convert digital levels to radiance in satellite imagery',
        description:
          'Efficiently convert raw digital values to radiance measurements for satellite imagery. Simplifies the process for researchers and analysts.',
        inputs: {
          fullResImg: {
            title: 'Click a full resolution image on the map',
            placeholder: 'Full res image id...',
          },
        },
      },
      perusatIngestion: {
        title: 'Perusat-1 Ingestion',
        shortDescription: 'Ingest Perusat-1 satellite images via FTP',
        description:
          'Streamline the ingestion of Perusat-1 satellite images by entering the FTP URL. Essential for users working with Perusat-1 imagery in analysis workflows.',
        inputs: {
          folderPath: 'FTP folder path',
          sceneId: 'Scene Id',
        },
      },
      spotIngestion: {
        title: 'SPOT Ingestion',
        shortDescription: 'Ingest SPOT satellite images via FTP',
        description:
          'Streamline the ingestion of SPOT satellite images by entering the FTP folder URL in CONIDAs servers. This application is used by administrators who will make SPOT imagery available in the platform for all users.',
        inputs: {
          zipFile: 'Enter FTP .zip path of a SPOT image',
        },
      },
      terraSatXIngestion: {
        title: 'TerraSAT-X Ingestion',
        shortDescription: 'Ingest TerraSAT-X satellite images via FTP',
        description:
          'Streamline the ingestion of TerraSAT-X satellite images by entering the FTP folder URL in CONIDAs servers. This application is used by administrators who will make TerraSAT-X imagery available in the platform for all users.',
        inputs: {
          zipFile: 'Enter FTP .zip path of a TerraSAT-X image',
        },
      },
      pleiadesIngestion: {
        title: 'PLEIADES Ingestion',
        shortDescription: 'Ingest PLEIADES satellite images via FTP',
        description:
          'Streamline the ingestion of PLEIADES satellite images by entering the FTP folder URL in CONIDAs servers. This application is used by administrators who will make PLEIADES imagery available in the platform for all users.',
        inputs: {
          zipFile: 'Enter FTP .zip path of a PLEIADES image',
        },
      },
      cosmoSkymedIngestion: {
        title: 'COSMO-skymed Ingestion',
        shortDescription: 'Ingest COSMO-skymed satellite images via FTP',
        description:
          'Streamline the ingestion of COSMO-skymed satellite images by entering the FTP folder URL in CONIDAs servers. This application is used by administrators who will make COSMO-skymed imagery available in the platform for all users.',
        inputs: {
          tarFile: 'Enter FTP .tar path of a COSMO-skymed image',
        },
      },
      kompsat3Ingestion: {
        title: 'KompSAT-3 Ingestion',
        shortDescription: 'Ingest KompSAT-3 satellite images via FTP',
        description:
          'Streamline the ingestion of KompSAT-3 satellite images by entering the FTP folder URL in CONIDAs servers. This application is used by administrators who will make KompSAT-3 imagery available in the platform for all users.',
        inputs: {
          zipFile: 'Enter FTP .zip path of a KompSAT-3 image',
        },
      },
      kazeoSatIngestion: {
        title: 'KazEOSAT Ingestion',
        shortDescription: 'Ingest KazEOSAT satellite images via FTP',
        description:
          'Streamline the ingestion of KazEOSAT satellite images by entering the FTP folder URL in CONIDAs servers. This application is used by administrators who will make KazEOSAT imagery available in the platform for all users.',
        inputs: {
          zipFile: 'Enter FTP .zip path of a KazEOSAT image',
        },
      },
      changeDetection: {
        title: 'Change Detection',
        shortDescription: 'Detect changes between two satellite images',
        description:
          'Efficiently identify changes over time by comparing two full-resolution satellite images. Essential for monitoring environmental shifts and land use changes.',
        inputs: {
          fullResImg1: {
            title: 'Click a full resolution image on the map',
            placeholder: 'Full res image id...',
          },
          fullResImg2: {
            title: 'Click a second full resolution image on the map',
            placeholder: 'Full res image id...',
          },
          type: {
            title: 'Select type',
          },
          scale: {
            title: 'Enter scale',
            placeholder: 'Eg. 5',
          },
        },
      },
      supervisedClassification: {
        title: 'Supervised Classification',
        shortDescription:
          'Perform supervised classification on satellite imagery',
        // Copilot generated this. Seems ok.
        description:
          'Classify satellite imagery by specifying classes on image pixels. Ideal for researchers and professionals in remote sensing.',
        inputs: {
          fullResImg: {
            title: 'Click a full resolution image on the map',
            placeholder: 'Full res image id...',
          },
          classes: {
            tableTitles: {
              name: 'Name',
              color: 'Colour',
            },
            placeholders: {
              name: 'Insert name',
            },
            buttons: {
              addClass: 'Add class',
            },
          },
        },

        errors: {
          noClasses: 'At least one class must be defined',
          notUnique: 'Class names must be unique',
          missingNames: 'All classes must have a name',
        },
      },
    },
    tours: {
      mainAppTour: {
        catalog: 'Catalog',
        project: 'Projects',
        tasking: 'Tasking',
        applications: 'Applications',
        layers: 'Layers',
        endTour: 'End tour',
        catalogDescription:
          'Shows the list of scenes available in the catalog that match the specified filters. These scenes can be selected to perform different operations. A variety of buttons will appear adjacent to data items to enable those operations. Common operations include: <br></br> <ol> <li> 1. Displaying low resolution preview on the map </li> <li> 2. Centring the map on a particular scene </li> <li> 3. Purchasing a scene </li><li> 4. Adding a scene to a project.</li></ol>',
        projectDescription:
          'Organize relevant scenes in your work using projects. Collaborate seamlessly with team members by sharing your projects.',
        taskingDescription:
          'The tasking menu empowers you to direct OpenConstellation and partner satellites for capturing data in a specific region. With customization options like region, date range, sun zenith angles, roll angles, etc., you can precisely define capture conditions. <br></br> DataCosmos will present tasking opportunities that align with these conditions. You can select and task any of the provided opportunities. Upon successful payment and image capture, the resulting images will be delivered to DataCosmos in your project area.',
        applicationsDescription:
          'Here, you will find algorithms, from simple to advanced, that can be run on data but which cannot be achieved with any other tool in DataCosmos. This menu lists a collection of in-house and 3rd party image processing tools which you may install to further derive insights into the selected data. <br></br> You can open the application, choose the settings, and run it on data, typically data that is already displayed within DataCosmos. Once the application has run, its outputs will appear back in your project, and you can then display them over the map, allowing further analysis to take place. ',
        layersDescription:
          'Any visuals that overlayed to the map are represented by a Layer in this menu. The layers menu contains a set of tools to help with this, and to start to draw insights from the data. Layers may be reordered, shown/hidden, made partially transparent or have “blend modes” applied. <br></br> Additionally, the displayed spectral band for multispectral data can be changed, and bands can be combined from the Band algebra menu to create indices or false colour imagery',
      },
    },
  },
} as const;

export default translation;
