import { Alert, Button, Intent, MenuItem } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import type { ItemRenderer } from '@blueprintjs/select';
import { MultiSelect } from '@blueprintjs/select';
import type { PermissionType } from 'api/administration/permissions';
import type { Dictionary } from 'lodash';
import { groupBy, mapValues } from 'lodash';
import { useEffect, useState } from 'react';
import type {
  IRoleAssignment,
  IUserWithPermissions,
} from 'services/api/portal/administration/api/types';
import s from './index.module.scss';
import { PERMISSION_TYPE_LIST } from 'api/administration/permissions';
import { clientTranslate } from 'utils/hooks/useLocalisation';

interface IProps {
  user: IUserWithPermissions;
  userAssignments: IRoleAssignment[];
  deleteRoleAssignments: (
    userId: string,
    assignmentIds: number[],
    showDeletedMessage?: boolean
  ) => Promise<void>;
  resources: {
    id: string | number;
    name: string;
    type: string;
  }[];
}

const PermissionsMultiSelect = MultiSelect.ofType<PermissionType>();
const AssignmentMultiSelect = MultiSelect.ofType<IRoleAssignment>();

const ListPermissions = ({
  user,
  userAssignments,
  deleteRoleAssignments,
  resources,
}: IProps) => {
  const [selectedPermissions, setSelectedPermissions] = useState<
    PermissionType[]
  >([]);

  const [groupedAssignments, setGroupedAssignments] = useState<{
    [key: string]: Dictionary<IRoleAssignment[]>;
  }>();

  const [searchTerm, setSearchTerm] = useState<string>('');

  const [isAlertOpen, setIsAlertOpen] = useState<boolean>(false);
  const [assignmentsToRemove, setAssignmentsToRemove] = useState<
    IRoleAssignment[]
  >([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const permissionRenderer: ItemRenderer<PermissionType> = (
    item,
    { modifiers, handleClick }
  ) => {
    if (!modifiers.matchesPredicate) {
      return null;
    }
    const selected = selectedPermissions.includes(item);

    return (
      <MenuItem
        key={item}
        active={modifiers.active}
        text={item.replace(/_/g, ' ')}
        onClick={handleClick}
        icon={selected ? IconNames.TICK : IconNames.BLANK}
        shouldDismissPopover={false}
      />
    );
  };

  const assignmentRenderer: ItemRenderer<IRoleAssignment> = (
    item,
    { modifiers, handleClick }
  ) => {
    if (!modifiers.matchesPredicate) {
      return null;
    }

    return (
      <MenuItem
        key={item.role.id}
        active={modifiers.active}
        text={item.role.name}
        onClick={handleClick}
        shouldDismissPopover={false}
      />
    );
  };

  const handleSelect = (permission: PermissionType) => {
    const isAlreadySelected = selectedPermissions.includes(permission);

    if (isAlreadySelected) {
      return;
    }

    setSelectedPermissions((prev) => [...prev, permission]);
  };

  const handleRemove = (permission: PermissionType) => {
    setSelectedPermissions(
      selectedPermissions.filter((selected) => selected !== permission)
    );
  };

  const handleAssignmentRemove = async (assignment: IRoleAssignment) => {
    await deleteRoleAssignments(user.id, [assignment.id], true);
    setGroupedAssignments((grouped) => {
      if (grouped) {
        grouped[assignment.type][assignment.resourceId] = grouped[
          assignment.type
        ][assignment.resourceId].filter((ass) => ass.id !== assignment.id);
      }
      return grouped;
    });
  };

  const handleRemoveAll = async (assignments: IRoleAssignment[]) => {
    const assignmentIds = assignments.map((ass) => ass.id);
    setIsLoading(true);
    await deleteRoleAssignments(user.id, assignmentIds, true);
    setGroupedAssignments((grouped) => {
      if (grouped) {
        grouped[assignments[0].type][assignments[0].resourceId] = grouped[
          assignments[0].type
        ][assignments[0].resourceId].filter(
          (ass) => !assignmentIds.includes(ass.id)
        );
      }
      return grouped;
    });
    setIsLoading(false);
    setIsAlertOpen(false);
  };

  const groupAssignmetsByTypeAndResourceId = (
    assignments: IRoleAssignment[]
  ) => {
    const groupedByType = mapValues(groupBy(assignments, 'type'));

    let groupedByTypeAndResourceId: {
      [key: string]: Dictionary<IRoleAssignment[]>;
    } = {};
    for (const type in groupedByType) {
      groupedByTypeAndResourceId = {
        ...groupedByTypeAndResourceId,
        [type]: mapValues(groupBy(groupedByType[type], 'resourceId')),
      };
    }
    return groupedByTypeAndResourceId;
  };

  useEffect(() => {
    const handleFilter = () => {
      if (searchTerm === '') {
        if (selectedPermissions.length > 0) {
          return userAssignments.filter((all) =>
            selectedPermissions.some((perm) => all.type === perm)
          );
        }

        return userAssignments;
      }
      const foundById = userAssignments.filter((all) =>
        all.resourceId.toLowerCase().startsWith(searchTerm.toLowerCase())
      );

      const foundByName = userAssignments.filter((all) =>
        resources
          .filter((resource) =>
            resource.name.toLowerCase().startsWith(searchTerm.toLowerCase())
          )
          .some(
            (found) =>
              found.id.toString() === all.resourceId.toString() &&
              found.type === all.type
          )
      );

      const filteredByNameAndPermissions =
        selectedPermissions.length === 0
          ? foundByName
          : foundByName.filter((found) =>
              selectedPermissions.some((perm) => perm === found.type)
            );

      const filteredByIdAndPermissions =
        selectedPermissions.length === 0
          ? foundById
          : foundById.filter((found) =>
              selectedPermissions.some((perm) => perm === found.type)
            );

      return filteredByNameAndPermissions.concat(filteredByIdAndPermissions);
    };
    setGroupedAssignments(groupAssignmetsByTypeAndResourceId(handleFilter()));
  }, [userAssignments, selectedPermissions, searchTerm]);

  return (
    <div>
      <h1>{clientTranslate('userPage.assignedPermissions.title')}</h1>

      <div className={s.searchInputWrapper}>
        <input
          type="text"
          className="bp4-input"
          placeholder={clientTranslate(
            'userPage.assignedPermissions.placeholders.resourceName'
          )}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <div>
          <PermissionsMultiSelect
            fill
            itemRenderer={permissionRenderer}
            items={PERMISSION_TYPE_LIST as unknown as PermissionType[]}
            onItemSelect={handleSelect}
            tagRenderer={(item) => item.replace(/_/g, ' ')}
            selectedItems={selectedPermissions}
            onRemove={handleRemove}
            placeholder={clientTranslate(
              'userPage.assignedPermissions.placeholders.resourceType'
            )}
          />
        </div>
      </div>

      <div>
        {userAssignments &&
          userAssignments.length !== 0 &&
          groupedAssignments &&
          Object.keys(groupedAssignments).map((resourceType) => (
            <div key={resourceType}>
              {groupedAssignments[resourceType] ? (
                Object.keys(groupedAssignments[resourceType]).map(
                  (resourceId) => (
                    <div className={s.listItemWrapper} key={resourceId}>
                      <div className={s.listTitleWrapper}>
                        <span className={s.listResourceType}>
                          {resourceType.toUpperCase().replace(/_/g, ' ')}
                        </span>

                        {resourceId === '' ? (
                          <span className={s.listResourceId}>-</span>
                        ) : resources &&
                          resources.find(
                            (resource) =>
                              resource.id == resourceId &&
                              resource.type == resourceType
                          ) ? (
                          <>
                            <span className={s.listResourceId}>
                              {
                                resources.find(
                                  (resource) =>
                                    resource.id == resourceId &&
                                    resource.type == resourceType
                                )?.name
                              }
                            </span>
                            <small>Id: {resourceId}</small>
                          </>
                        ) : (
                          <span className={s.listResourceId}>{resourceId}</span>
                        )}
                      </div>
                      <div style={{ display: 'flex' }}>
                        <AssignmentMultiSelect
                          fill
                          items={groupedAssignments[resourceType][resourceId]}
                          itemRenderer={assignmentRenderer}
                          tagRenderer={(item) => item.role.name}
                          selectedItems={
                            groupedAssignments[resourceType][resourceId]
                          }
                          onItemSelect={() => {}}
                          popoverProps={{ disabled: true }}
                          onRemove={handleAssignmentRemove}
                        />
                        <Button
                          icon={IconNames.TRASH}
                          intent={Intent.DANGER}
                          onClick={() => {
                            setAssignmentsToRemove(
                              groupedAssignments[resourceType][resourceId]
                            );
                            setIsAlertOpen(true);
                          }}
                        />
                      </div>
                    </div>
                  )
                )
              ) : (
                <div className={s.listItemWrapper}>
                  <span className={s.listResourceType}>
                    {clientTranslate(
                      'userPage.assignedPermissions.errors.noResultsForResourceType'
                    )}
                    {resourceType.toUpperCase().replace(/_/g, ' ')}'
                  </span>
                </div>
              )}
            </div>
          ))}
      </div>
      <Alert
        confirmButtonText={clientTranslate('datacosmos.buttons.delete')}
        cancelButtonText={clientTranslate('datacosmos.buttons.cancel')}
        intent={Intent.DANGER}
        icon={IconNames.TRASH}
        isOpen={isAlertOpen}
        onCancel={() => setIsAlertOpen(false)}
        onConfirm={() => handleRemoveAll(assignmentsToRemove)}
        loading={isLoading}
      >
        <p>
          {clientTranslate(
            'userPage.assignedPermissions.areYouSureYouWantToRemove'
          )}
        </p>
      </Alert>
    </div>
  );
};

export default ListPermissions;
