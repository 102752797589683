import React, { useEffect, useState } from 'react';
import type { IconName, Intent } from '@blueprintjs/core';
import { AnchorButton } from '@blueprintjs/core';

import AlertConfirm from 'components/common/AlertConfirm';

interface IProps {
  onClick: () => void;
  text?: string;
  disabled: boolean;
  icon: IconName;
  intent: Intent;
  alertMessage: string;
  className?: string;
}

const WarnAlertButton = (props: IProps) => {
  const [isOpenAlert, setOpenAlert] = useState(false);
  const [isDebouncedButtonClick, setDebouncedButtonClick] = useState(false);
  let timer: NodeJS.Timeout | null = null;

  const handleDebouncedDirectSend = () => {
    if (isDebouncedButtonClick) {
      setOpenAlert(true);
      return;
    }

    setDebouncedButtonClick(true);
    props.onClick();
    timer = setTimeout(() => setDebouncedButtonClick(false), 1000);
  };

  useEffect(() => {
    return () => {
      if (timer) clearTimeout(timer);
    };
  }, []);

  return (
    <>
      <AlertConfirm
        onConfirm={props.onClick}
        isOpen={isOpenAlert}
        setIsOpen={setOpenAlert}
        message={props.alertMessage}
      />
      <AnchorButton
        icon={props.icon}
        intent={props.intent}
        onClick={handleDebouncedDirectSend}
        disabled={props.disabled}
        className={props.className}
      >
        {props.text}
      </AnchorButton>
    </>
  );
};

export default WarnAlertButton;
