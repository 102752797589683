import type { ThunkAction } from 'redux-thunk';
import type { AppState } from 'reducers/rootReducer';
import type { Action } from 'redux';
import { resetFocus } from 'actions/focus/thunks';
import { setMarkerSpecify } from '../map/events';
import type { IMapWrapper } from 'declarations/mapDeclarations/Map';
import type { IContextMenu } from '../map/services';
import AddressField from 'components/inputField/AddressField';
import { fetchPI } from 'actions/pointsOfInterest/thunks';
import { removePI, updatePI } from 'actions/pointsOfInterest/helpers';
import { resetPopUp, setPopUp } from 'actions/popUp/thunks';
import type { IOSMFetchResult } from 'declarations/osm';
import MarkerForm from 'components/popUp/MarkerForm';
import type { IPointOfInterest } from 'constants/pointsOfInterest/actionTypes';
import { ASTRUMS } from 'constants/API/constant';
import { FocusTypes } from 'constants/focus/actionTypes';

export const getAddPoint =
  (
    map: IMapWrapper
  ): ThunkAction<IContextMenu, AppState, null, Action<string>> =>
  (dispatch, store): IContextMenu => {
    const astrum = store().astrum['current'];
    const fetch = {
      text: 'Fetch',
      icon: 'search',
      clickEvent: (): void => {
        dispatch(
          setPopUp({
            classNames: ['short-container'],
            component: AddressField,
            visible: true,
            data: {},
            otherProps: {
              type: 'Point',
              secondProps: {
                component: MarkerForm,
                visible: true,
                classNames: ['short-container'],
                data: null,
                otherProps: {
                  type: FocusTypes.PI_SUB_ID,
                },
                functions: {
                  onSubmit: (pointOfInterest: IPointOfInterest): void =>
                    dispatch(updatePI(pointOfInterest)),
                  onClose: (pointOfInterest: IPointOfInterest): void =>
                    dispatch(removePI(pointOfInterest.id)),
                },
              },
            },
            functions: {
              onSubmit: (result: IOSMFetchResult, id?: string) =>
                dispatch(fetchPI(result, false, id)),
              onClose: (): void => dispatch(resetPopUp()),
            },
          })
        );
      },
    };
    const contextMenu = {
      text: 'Add point',
      subItems: [
        {
          text: 'Draw',
          clickEvent: (): void => {
            dispatch(resetFocus());
            map.drawingManager.isGroundStation = false;
            map.setDrawingMarkerMod();
          },
          icon: 'draw',
        },
        {
          text: 'Specify',
          clickEvent: (): void => {
            dispatch(resetFocus());
            dispatch(setMarkerSpecify());
          },
          icon: 'geolocation',
        },
      ],
    };
    if (astrum.planet === ASTRUMS.EARTH) {
      contextMenu.subItems.push(fetch);
    }
    return contextMenu;
  };
