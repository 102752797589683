import type { ReactElement } from 'react';
import React, { Component } from 'react';
import type { InjectedFormProps, WrappedFieldProps } from 'redux-form';
import { Field, reduxForm } from 'redux-form';
import { Classes, H3, H6, Radio, RadioGroup } from '@blueprintjs/core';
import TextField from '../../inputField/TextField';
import type { IOption } from '../../inputField/SelectField';
import SelectField from '../../inputField/SelectField';
import { DRAGGABLE, DRAGGABLE_HEADER } from 'constants/draggable/constants';
import { dragElement } from 'services/draggableService/dragElement';
import type { WithTranslation } from 'react-i18next';
import type {
  FormApsideError,
  FormError,
  GeometryFormErrors,
} from 'constants/actionTypes';
import type {
  ISatelliteOrbits,
  ISatelliteTLE,
} from 'constants/msd/geometry/satelliteOrbits/interfaces';
import { GEOMETRY_FORM_NAME } from 'constants/popUp/constants';
import {
  GEOMETRY_TYPE,
  geometryOptions,
} from 'constants/msd/geometry/constants';
import WalkerConstellation from 'components/popUp/geometryForm/subForms/WalkerConstellation';
import type { IWalkerConstellation } from 'constants/msd/geometry/walkerConstellation/interfaces';
import { SatellitesOrbitConstraints } from 'constants/msd/geometry/satelliteOrbits/constants';
import TLE from 'components/popUp/geometryForm/subForms/TLE';
import { StationaryOrbit } from 'components/popUp/geometryForm/subForms/StationaryOrbit';
import SynchronousOrbit from 'components/popUp/geometryForm/subForms/SynchronousOrbit';
import { SEMIMAJOR_AXIS_AND_ECCENTRICITY } from 'components/popUp/geometryForm/subForms/constants';
import { PolarOrbit } from 'components/popUp/geometryForm/subForms/PolarOrbit';
import { KeplerianOrbit } from 'components/popUp/geometryForm/subForms/KeplarianOrbit';
import type { IAnyKey } from 'utils/getFormData';
import { isNumeric } from 'utils/common/CommonValidator';
import { universe } from 'constants/msd/geometry/Universe';
import { get, isNil } from 'lodash';

export interface IParameter {
  optimisation: boolean & WrappedFieldProps;
  value: number & WrappedFieldProps;
  min: number & WrappedFieldProps;
  max: number & WrappedFieldProps;
}
const getFormFromOrbitType = (
  orbitType: string,
  astrum: string,
  change: Function
) => {
  switch (orbitType) {
    case GEOMETRY_TYPE.TLE: {
      return <TLE astrum={astrum} changeHandler={change} />;
    }
    case GEOMETRY_TYPE.KEPLERIAN: {
      return <KeplerianOrbit astrum={astrum} />;
    }
    case GEOMETRY_TYPE.WALKER_CONSTELLATION: {
      return <WalkerConstellation astrum={astrum} />;
    }
    case GEOMETRY_TYPE.POLAR: {
      return <PolarOrbit astrum={astrum} />;
    }
    case GEOMETRY_TYPE.STATIONARY: {
      return <StationaryOrbit astrum={astrum} />;
    }
    case GEOMETRY_TYPE.SYNCHRONOUS: {
      return <SynchronousOrbit astrum={astrum} changeHandler={change} />;
    }
  }
  return undefined;
};

const validateFunctions = {
  tle: (value?: string) => {
    if (!value) {
      return 'tle';
    }
    return undefined;
  },
  constraints: (value: IParameter) => {
    if (!value) {
      return { value: 'value_not_found' };
    }
    if (value.optimisation) {
      {
        if (value.min === undefined) {
          return { min: 'value_not_found' };
        }
        if (!isNumeric(value.min.toString())) {
          return { min: 'value_not_number' };
        }
        if (value.value > value.max) {
          return { min: 'value_out_of_range' };
        }
      }
      {
        if (value.max === undefined) {
          return { max: 'value_not_found' };
        }
        if (!isNumeric(value.max.toString())) {
          return { max: 'value_not_number' };
        }
        if (value.value < value.min) {
          return { max: 'value_out_of_range' };
        }
      }
    } else {
      if (value.value === undefined) {
        return { value: 'value_not_found' };
      }
      if (!isNumeric(value.value.toString())) {
        return { value: 'value_not_number' };
      }
      if (value.value < value.min || value.value > value.max) {
        return { value: 'value_out_of_range' };
      }
    }
    return undefined;
  },
};

type IValues = FormData & ISatelliteOrbits & IWalkerConstellation;

const validateConstraints = (
  constraintsFields: string[],
  values: IValues,
  errors: GeometryFormErrors
) => {
  constraintsFields.forEach((field) => {
    const value = (values as IAnyKey)[field];

    const err = validateFunctions.constraints(value);

    errors[field] = err;
  });
};

const integerFields = [
  'relativeSpacing',
  'numberOfPlanes',
  'satellitesPerPlane',
];
const validateNumbers = (values: IValues, errors: GeometryFormErrors) =>
  integerFields.map((field) => {
    if (
      errors[field] &&
      !Number.isInteger(Number(values.relativeSpacing.value))
    ) {
      errors[field] = 'value_out_of_range';
    }
  });

// TODO: Unit tests validation rules with input + output expected values
const validateOrbitalRules = ({
  values,
  errors,
  astrumName,
}: {
  values: IValues;
  errors: GeometryFormErrors;
  astrumName: 'EARTH' | 'MOON';
}) => {
  let semimajorAxis, eccentricity;

  // Only continue for certain orbits, don't validate otherwise (return)
  switch (values.type) {
    // @ts-expect-error
    case geometryOptions.SYNCHRONOUS.value:
      if (values.parameterisation !== SEMIMAJOR_AXIS_AND_ECCENTRICITY) return;
    case geometryOptions.KEPLERIAN.value:
    case geometryOptions.POLAR.value:
      semimajorAxis = get(values, 'semimajorAxis.value') as number | undefined;
      eccentricity = get(values, 'eccentricity.value') as number | undefined;
      break;
    case geometryOptions.WALKER_CONSTELLATION.value:
      semimajorAxis = get(values, 'semimajorAxis.value') as number | undefined;
      eccentricity = 0;
      break;
    default:
      return;
  }

  if (!isNil(semimajorAxis) && !isNil(eccentricity)) {
    const astrum = universe[astrumName];
    const { equatorialRadius, minimumAltitude, maximumAltitude } = astrum;

    const apoapsisAltitude =
      (1 + eccentricity) * semimajorAxis - equatorialRadius;
    const periapsisAltitude =
      (1 - eccentricity) * semimajorAxis - equatorialRadius;

    let error: FormApsideError | undefined;
    if (
      !(
        minimumAltitude <= periapsisAltitude &&
        periapsisAltitude <= maximumAltitude
      )
    ) {
      error = { value: 'periapsis_error' };
    } else if (
      !(
        minimumAltitude <= apoapsisAltitude &&
        apoapsisAltitude <= maximumAltitude
      )
    ) {
      error = { value: 'apoapsis_error' };
    }

    if (error !== undefined) {
      error.periapsis_altitude = periapsisAltitude;
      error.apoapsis_altitude = apoapsisAltitude;

      errors._error = error;
    }
  }
};

function validate(values: IValues, props: any): GeometryFormErrors {
  const constraintsFields = [] as string[];
  for (const key in props.registeredFields) {
    const splittedName = key.split('.');
    if (splittedName.length === 2) {
      if (!constraintsFields.find((f) => splittedName[0] === f)) {
        constraintsFields.push(splittedName[0]);
      }
    }
  }
  const errors: GeometryFormErrors = {};
  const { name, type } = values;
  if (!name) {
    errors.name = 'name_not_empty';
  }
  if (!type) {
    errors.type = 'type_not_empty';
    return errors;
  }

  switch (type) {
    case GEOMETRY_TYPE.TLE: {
      const { tle1, tle2 }: ISatelliteTLE = values;
      errors.tle1 = validateFunctions.tle(tle1);
      errors.tle2 = validateFunctions.tle(tle2);
      break;
    }
    case GEOMETRY_TYPE.KEPLERIAN:
    case GEOMETRY_TYPE.POLAR:
    case GEOMETRY_TYPE.SYNCHRONOUS:
    case GEOMETRY_TYPE.STATIONARY:
    case GEOMETRY_TYPE.WALKER_CONSTELLATION: {
      values.relativeSpacing.max = values.numberOfPlanes.value - 1;
      // SatellitesOrbitConstraints.relativeSpacing = {
      //   ...SatellitesOrbitConstraints.relativeSpacing,
      //   max: values.numberOfPlanes.value - 1,
      //   labelStepSize: values.numberOfPlanes.value - 1,
      // };
      SatellitesOrbitConstraints.relativeSpacing.max =
        values.numberOfPlanes.value - 1;
      SatellitesOrbitConstraints.relativeSpacing.labelStepSize =
        values.numberOfPlanes.value - 1;

      validateConstraints(constraintsFields, values, errors);
      validateNumbers(values, errors);
    }
  }

  validateOrbitalRules({
    values,
    errors,
    astrumName: props.astrum,
  });

  return errors;
}

interface ISatelliteFormProps {
  resetPopUp?: Function;
  onClose: Function;
  options: IOption[];
  astrum: string;
}

interface ISatelliteFormState {
  geometryType: typeof GEOMETRY_TYPE.KEPLERIAN &
    typeof GEOMETRY_TYPE.TLE &
    typeof GEOMETRY_TYPE.WALKER_CONSTELLATION;
  force: boolean;
}

interface IComponentProps
  extends ISatelliteFormProps,
    WithTranslation,
    InjectedFormProps<FormData, ISatelliteFormProps, FormError> {}

type ComponentState = ISatelliteFormState;

class GeometryForm extends Component<IComponentProps, ComponentState> {
  public constructor(props: IComponentProps) {
    super(props);
    this.state = {
      geometryType: (props.initialValues as { type: string }).type,
      force: (props.initialValues as { force: boolean }).force,
    };
  }

  public componentDidMount(): void {
    dragElement(document.getElementById(DRAGGABLE));
  }

  public onChangeState = (geometryType: string): void => {
    this.setState({ geometryType }, () => {
      const initData = {
        ...this.props.initialValues,
        type: this.state.geometryType,
      };

      this.props.reset();
      this.props.initialize(initData as Partial<FormData>);
    });
  };

  public onCloseWrapper = (): void => {
    const { initialValues } = this.props;
    const { onClose, resetPopUp } = this.props;
    onClose(initialValues);
    resetPopUp();
  };

  public getErrExtension = (altitude: number, astrum: string): string => {
    return (
      'between ' +
      universe[astrum].minimumAltitude.toString() +
      'km and ' +
      universe[astrum].maximumAltitude.toString() +
      'km. The current altitude is: ' +
      altitude +
      'km.'
    );
  };

  public render(): ReactElement {
    const {
      change,
      handleSubmit,
      submitSucceeded,
      resetPopUp,
      error,
      invalid,
      options,
      t,
      astrum,
    } = this.props;
    const { geometryType } = this.state;

    if (submitSucceeded) {
      resetPopUp();
    }

    const errType = ((error || {}) as FormApsideError).value || error;

    let errExtension = '';
    if (errType == 'periapsis_error') {
      errExtension = this.getErrExtension(
        (error as FormApsideError).periapsis_altitude,
        astrum
      );
    }
    if (errType == 'apoapsis_error') {
      errExtension = this.getErrExtension(
        (error as FormApsideError).apoapsis_altitude,
        astrum
      );
    }

    const errorDiv = errType ? (
      <div className="bp4-callout bp4-intent-danger bp4-icon-error">
        <H6 className="bp4-heading">
          {t(`validation.errors.${errType}` as any) + errExtension}
        </H6>
      </div>
    ) : null;

    const saveTitle = errType
      ? t(`validation.errors.${errType}` as any)
      : t('validation.save_title');

    const subForm = getFormFromOrbitType(geometryType, astrum, change);

    const typeOptions = universe[astrum].allowTLE
      ? options
      : options.filter((option) => option.value !== geometryOptions.TLE.value);

    return (
      <form
        className={this.state.force ? 'short-container' : ''}
        onSubmit={handleSubmit}
      >
        <div className="bp4-dialog dialog-margin-set-out">
          <div id={DRAGGABLE_HEADER} className="bp4-dialog-header">
            <H3>{t('module_msd.mission_geometry.orbit')}</H3>
            <div
              role="button"
              tabIndex={0}
              className="bp4-dialog-close-button bp4-button bp4-minimal bp4-icon-cross"
              onClick={this.onCloseWrapper}
              onKeyDown={(ev) => ev.keyCode === 13 && this.onCloseWrapper()}
            />
          </div>
          <div className={`bp4-dialog-body ${this.state.force ? 'hide' : ''}`}>
            <div className="body-header">
              <div className="body-header-left">
                <Field name="name" component={TextField} type="textarea" />
                <Field
                  name="type"
                  onChangeState={this.onChangeState}
                  title="geometry.type"
                  options={typeOptions}
                  component={SelectField}
                />
              </div>
              <div className="body-header-right">
                <Field
                  name={'force'}
                  component={(props: WrappedFieldProps) => {
                    const { input } = props;
                    return (
                      <div className="toggle">
                        <RadioGroup
                          label={
                            input.value
                              ? 'Specify as a result'
                              : 'To consider in optimisation'
                          }
                          onChange={(
                            event: React.ChangeEvent<HTMLInputElement>
                          ) => {
                            const force = Boolean(Number(event.target.value));
                            input.onChange(force);
                            this.setState({ force });
                          }}
                          selectedValue={input.value ? -1 : 0}
                        >
                          <Radio
                            className={'orbit-specify'}
                            label="Specify"
                            value={-1}
                          />
                          {/*TODO disabled for beta release*/}
                          <Radio
                            disabled={true}
                            className={'orbit-optimize'}
                            label="Optimize"
                            value={0}
                          />
                        </RadioGroup>
                      </div>
                    );
                  }}
                />
              </div>
            </div>
            {subForm}
            {errorDiv}
          </div>
          <div className="bp4-dialog-footer">
            <div className="bp4-dialog-footer-actions">
              <button
                type="submit"
                title={saveTitle}
                className={`bp4-button bp4-icon-saved  ${
                  error || invalid
                    ? Classes.INTENT_DANGER
                    : Classes.INTENT_SUCCESS
                }`}
                disabled={Boolean(error) || invalid}
                onClick={handleSubmit}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </form>
    );
  }
}

export default reduxForm<FormData, ISatelliteFormProps, FormError>({
  form: GEOMETRY_FORM_NAME,
  validate,
})(GeometryForm);
