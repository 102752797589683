import s from 'pages/ops/Scripting/Node-red/index.module.scss';
import { useMission } from 'services/Missions';
import { useAuth } from 'services/auth/AuthWrapper';
import { NODE_RED_INSTANCE_URL } from 'env';

const NodeRED = () => {
  const { currentMissionId } = useMission();
  const { token } = useAuth();

  const url =
    NODE_RED_INSTANCE_URL + currentMissionId + '/' + '?access_token=' + token;

  return (
    <iframe
      id="node_red_iframe"
      title="Node-RED frame"
      className={s.nodered}
      src={url}
    />
  );
};

export default NodeRED;
