import { GOAL_TYPES } from '../../constants/goals/constants';
import type { IGoal } from '../../constants/goals/interfaceas';
import type { IPIState } from '../../constants/pointsOfInterest/actionTypes';
import type { IRIState } from '../../constants/regionsOfInterest/actionTypes';

export const goalNormalize = (
  goal: IGoal,
  pointsOfInterest: IPIState,
  regionsOfInterest: IRIState
) => {
  const { type } = goal;
  const resGoal = { ...goal };
  switch (type) {
    case GOAL_TYPES.POINT_OF_INTEREST_EVENT: {
      resGoal.object = pointsOfInterest.list.find(
        (p) => p.name === goal.object
      );
      break;
    }
    case GOAL_TYPES.REGION_OF_INTEREST_EVENT: {
      resGoal.object = regionsOfInterest.list.find(
        (r) => r.name === goal.object
      );
      break;
    }
  }
  return resGoal;
};
