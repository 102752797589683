export const loadAndConvertToBase64 = (file: any): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result.toString().split('base64,')[1]);
    reader.onerror = (error) => reject(error);
  });

export const loadAndConvertToByteArray = (
  file: File
): Promise<ArrayBuffer | string> => {
  return new Promise((res, rej) => {
    const reader = new FileReader();
    reader.readAsArrayBuffer(file);
    reader.onload = () => res(reader.result!);
    reader.onerror = (error) => rej(error);
  });
};
