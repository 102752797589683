import { useTheme } from 'datacosmos/stores/ThemeProvider';
import {
  CartesianGrid,
  Label,
  Legend,
  Line,
  LineChart as RechartsLineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

export type ChartData = {
  [key: string]: number | string;
};

export type LineChartLine = {
  key: string;
  color: string;
};
type Props = {
  /**
   * The data to be displayed in the chart. The data should be an array of key value pairs.
   */
  data: ChartData[];
  /**
   * Keys to display on the x axis. Should be a key in the data array.
   */
  xAxisKey: string;

  /**
   * LineChartLine is a line in the line chart. It consists of a line key and a color.
   * The line key should be a key in the data array.
   * The color should be a valid css color.
   */
  lines: LineChartLine[];
  /**
   * Whether to display dots on the line.
   */
  lineDot: boolean;
  /**
   * Whether to display the legend.
   */
  showLegend: boolean;
  /**
   * Whether to display the grid.
   */
  showGrid: boolean;
  /**
   * Height of the chart.
   */
  height?: number;
  /**
   * The range of the y axis.
   */
  yValueRange?: number[];

  /**
   * Specific values to display on the y axis.
   */
  yValueTicksValue?: number[];

  /**
   * Function to format the x axis label.
   * @param value The value to format.
   * @returns The formatted value.
   */
  xAxisLabelFormatter?: (value: string) => string;

  /**
   * Function to format the y axis label.
   * @param value The value to format.
   * @returns The formatted value.
   */
  yAxisLabelFormatter?: (value: string) => string;

  /**
   * Label for the y axis.
   */
  yLabel?: string;

  /**
   * Label for the x axis.
   */
  xLabel?: string;

  /**
   * Reference to the chart wrapper. Used mainly for screenshotting.
   */
  reference?: React.RefObject<HTMLDivElement>;
};

/**
 * LineChart is a chart that displays data as a series of points connected by a line.
 *
 */
const LineChart = ({
  data,
  xAxisKey,
  lines,
  lineDot,
  height,
  showLegend,
  showGrid,
  yValueRange,
  yValueTicksValue,
  xAxisLabelFormatter,
  yAxisLabelFormatter,
  xLabel,
  yLabel,
  reference,
}: Props) => {
  const { isDarkmode } = useTheme();
  return (
    <div ref={reference}>
      <ResponsiveContainer
        width={'100%'}
        height="100%"
        minHeight={height ?? 300}
      >
        <RechartsLineChart data={data} className="pb-0 text-xs ">
          {showGrid && <CartesianGrid strokeDasharray="3 3" />}
          <Label xlinkTitle="TEST" />
          <XAxis
            tickFormatter={xAxisLabelFormatter}
            stroke={isDarkmode ? '#E6E1DC' : '#191919'}
            dataKey={xAxisKey}
          >
            <Label
              value={xLabel}
              offset={-2}
              position={'insideBottom'}
              stroke={isDarkmode ? '#E6E1DC' : '#191919'}
            />
          </XAxis>
          <YAxis
            domain={yValueRange}
            ticks={yValueTicksValue}
            stroke={isDarkmode ? '#E6E1DC' : '#191919'}
            tickFormatter={yAxisLabelFormatter}
          >
            <Label
              value={yLabel}
              offset={0}
              position={'insideLeft'}
              angle={-90}
              stroke={isDarkmode ? '#E6E1DC' : '#191919'}
            />
          </YAxis>
          <Tooltip />
          {showLegend && <Legend />}
          {lines.map((line) => (
            <Line
              key={line.key}
              dataKey={line.key}
              stroke={line.color}
              dot={lineDot}
            />
          ))}
        </RechartsLineChart>
      </ResponsiveContainer>
    </div>
  );
};
export default LineChart;
