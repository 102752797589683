import { MISSION_METRIC, OBJECTIVE_METRIC } from './constants';

export const constraintsHandler = {
  [OBJECTIVE_METRIC.INCIDENCE]: {
    max: 100,
    min: 0,
    labelStepSize: 100,
  },
  [OBJECTIVE_METRIC.AVG_DURATION]: {
    max: 50000,
    min: 0,
    labelStepSize: 50000,
  },
  [OBJECTIVE_METRIC.MAX_DURATION]: {
    max: 50000,
    min: 0,
    labelStepSize: 50000,
  },
  [OBJECTIVE_METRIC.MIN_DURATION]: {
    max: 50000,
    min: 0,
    labelStepSize: 50000,
  },
  [OBJECTIVE_METRIC.DURATION_STD_DEVIATION]: {
    max: 50000,
    min: 0,
    labelStepSize: 50000,
  },
  [MISSION_METRIC.NUMBER_OF_SATELLITES]: {
    max: 30,
    min: 1,
    labelStepSize: 30,
  },
  [MISSION_METRIC.NUMBER_OF_PLANES]: {
    max: 15,
    min: 1,
    labelStepSize: 15,
  },
  [MISSION_METRIC.NUMBER_OF_GROUND_STATIONS]: {
    max: 30,
    min: 1,
    labelStepSize: 30,
  },
};
