import { useState, useMemo, useEffect } from 'react';
import { useParams } from 'react-router';
import { IconNames } from '@blueprintjs/icons';
import { Button, ControlGroup, Intent, AnchorButton } from '@blueprintjs/core';
import { Tooltip } from 'ui/Tooltip';
import WarnAlertButton from 'components/buttons/WarnAlertButton';
import { useAuth } from 'services/auth/AuthWrapper';

import CommandInput from './CommandInput';

import s from './index.module.scss';
import AlertConfirm from 'components/common/AlertConfirm';
import { useAnalytics } from 'utils/hooks/analytics/useAnalytics';
import { useOperate } from 'services/Operate';
import { useCommandCopyPaste } from 'utils/hooks/commands/useCommandCopyPaste';
import type { CommandDefinition } from 'api/telecommands/types';
interface IProps {
  className?: string;
  availableCommands: CommandDefinition[];
  handleSendUnarmedCommand: () => void;
  handleSendToPileCommand: () => void;
  selectedCommand?: CommandDefinition;
  isSendCommandDisabled: boolean;
  isTablet: boolean;
  handleSelectCommandEditorItem: (cmd: CommandDefinition) => void;
  isSessionOccupied: boolean;
  userIsActiveInCurrentMission: boolean;
  startUserSession: () => Promise<void>;
  fetchActiveSessions: (abort?: { state: boolean }) => Promise<void>;
}

export const CommandEditor = (props: IProps) => {
  const { checkPermissions } = useAuth();
  const { mission } = useParams<{ mission: string }>();

  const { sendInfo } = useAnalytics();

  const [isSessionWarningOpened, setIsSessionWarningOpened] =
    useState<boolean>(false);

  const [warningFor, setWarningFor] = useState<'direct' | 'pile'>();

  const [isAllowedToOpenCommand, setIsAllowedToOpenCommand] =
    useState<boolean>(false);

  const { handleCopySelectedCommands, handlePasteCmd } = useCommandCopyPaste();

  const {
    state: { commandPileWorkingSpace },
  } = useOperate();

  const buttonDisabled = useMemo(
    () =>
      !props.selectedCommand ||
      props.isSendCommandDisabled ||
      !isAllowedToOpenCommand,
    [isAllowedToOpenCommand, props.isSendCommandDisabled, props.selectedCommand]
  );

  const {
    state: { selectedMultiplePileItems, selectedPileItem },
  } = useOperate();

  useEffect(() => {
    const checkOpenPermission = async () => {
      const hasPerm = await checkPermissions({
        id: mission,
        type: 'mission',
        actionScope: props.selectedCommand?.scope,
      });
      setIsAllowedToOpenCommand(hasPerm);
    };

    void checkOpenPermission();
  }, [checkPermissions, mission, props.selectedCommand?.scope]);

  return (
    <div className={[props.className, s.main].join(' ')}>
      <ControlGroup className={s.row}>
        <Tooltip
          content="Danger: Send directly to the satellite!"
          disabled={buttonDisabled}
        >
          <WarnAlertButton
            icon={IconNames.SATELLITE}
            intent={Intent.DANGER}
            text={props.isTablet ? undefined : 'Direct send'}
            alertMessage="Attempted to send command to the satellite twice."
            disabled={buttonDisabled}
            className="mr-1"
            onClick={async () => {
              sendInfo({
                type: 'Direct send command from command editor',
                action: 'Click',
                item: 'Direct send command button',
                module: 'OPS',
              });
              await props.fetchActiveSessions();
              if (
                props.isSessionOccupied &&
                !props.userIsActiveInCurrentMission
              ) {
                setIsSessionWarningOpened(true);
                setWarningFor('direct');
              } else {
                props.handleSendUnarmedCommand();
                await props.startUserSession();
              }
            }}
          />
        </Tooltip>
        <CommandInput
          className={[s.commandInput, s.commandInputTableWidth].join(' ')}
          availableCommands={props.availableCommands}
          handleSelectCommand={props.handleSelectCommandEditorItem}
          selectedCommand={props.selectedCommand}
        />
        <Tooltip
          content={
            selectedPileItem || selectedMultiplePileItems
              ? 'Copy command from pile'
              : 'Copy command'
          }
          disabled={buttonDisabled}
        >
          <Button
            icon={IconNames.DUPLICATE}
            onClick={() => {
              sendInfo({
                type: 'Copy command from command editor',
                action: 'Click',
                item: 'Copy command button',
                module: 'OPS',
              });
              handleCopySelectedCommands();
            }}
            className="mx-1"
          />
        </Tooltip>
        <Tooltip
          content={
            selectedPileItem || selectedMultiplePileItems
              ? 'Paste command from pile'
              : 'Paste command'
          }
          disabled={buttonDisabled}
        >
          <Button
            icon={IconNames.CLIPBOARD}
            onClick={() => {
              sendInfo({
                type: 'Paste command from command editor',
                action: 'Click',
                item: 'Paste command button',
                module: 'OPS',
              });
              void handlePasteCmd(
                commandPileWorkingSpace?.findIndex(
                  (cmd) => cmd.id === selectedPileItem?.id
                ) ?? 0
              );
            }}
            className="mr-1"
          />
        </Tooltip>
        <Tooltip content="Add to command pile" disabled={buttonDisabled}>
          <AnchorButton
            data-testid="add-command-to-pile"
            id="add-command-to-pile"
            icon={IconNames.ADD_TO_ARTIFACT}
            intent={Intent.PRIMARY}
            onClick={async () => {
              sendInfo({
                type: 'Add command to pile from command editor',
                action: 'Click',
                item: 'Add command to pile button',
                module: 'OPS',
              });
              await props.fetchActiveSessions();
              if (
                props.isSessionOccupied &&
                !props.userIsActiveInCurrentMission
              ) {
                setIsSessionWarningOpened(true);
                setWarningFor('pile');
              } else {
                props.handleSendToPileCommand();
                await props.startUserSession();
              }
            }}
            disabled={buttonDisabled}
            text={props.isTablet ? undefined : 'Add to pile'}
          />
        </Tooltip>
      </ControlGroup>

      <AlertConfirm
        isOpen={isSessionWarningOpened && !props.userIsActiveInCurrentMission}
        setIsOpen={setIsSessionWarningOpened}
        onConfirm={() => {
          if (warningFor === 'direct') {
            props.handleSendUnarmedCommand();
          }

          if (warningFor === 'pile') {
            props.handleSendToPileCommand();
          }
          void props.startUserSession();
        }}
        message="The session is currently in use. Are you sure you want to send the command?"
      />
    </div>
  );
};

export default CommandEditor;
