import { useEffect, useRef, useState, useCallback } from 'react';
import { useFormik } from 'formik';
import { get, isEmpty, isNil } from 'lodash/fp';
import { Classes, Label, ControlGroup } from '@blueprintjs/core';
import { createValidator } from 'utils/rtiCommands/primitiveInputValidation';
import type { IProperty } from '../CommandArguments';
import CommandPrimitiveString from './CommandPrimitiveString';
import CommandPrimitiveBoolean from './CommandPrimitiveBoolean';
import CommandPrimitiveNumber from './CommandPrimitiveNumber';
import GenericRemoveButton from '../CommandArguments/GenericRemoveButton';
import CommandPrimitivePopover from './CommandPrimitivePopover';
import s from './index.module.scss';

export interface IProps {
  property: IProperty;
  value: any;
  onChange: (value: any, path: string) => any;
  onBlur: () => any;
  onRemove?: () => any;
  handleMetaButtonClick?: (value: string) => void;
  canBlur?: boolean;
  canSetDefaultValue?: boolean;
  disabled?: boolean;
  hideInputPath?: boolean;
}

const CommandPrimitiveArguments = (props: IProps) => {
  const popOverInputReference = useRef(null);
  const [formikParams, setFormikParams] = useState({});
  const [isTooltipOpen, setTooltipOpen] = useState<boolean>(false);
  const { path, type, groupPath, optionalFields, required } = props.property;
  const {
    title,
    description,
    defaultValue,
    format,
    enumValue,
    meta,
    media,
    examples,
  } = optionalFields;
  const fullPath = groupPath.length ? [...groupPath, path].join('.') : path;
  const value = get(fullPath, props.value);

  const formik = useFormik({
    initialValues: {
      [path]: defaultValue ?? value,
    },
    validate: (valueToValidate) => {
      const errors = createValidator(path, type, required, {
        ...optionalFields,
        ...formikParams,
      })(valueToValidate);
      return errors;
    },
    onSubmit: () => {},
    validateOnMount: true,
  });

  const handleBlur = () => {
    if (isEmpty(formik.errors) && props.canBlur) {
      props.onBlur();
    }
    setTooltipOpen(false);
  };

  const handleFocus = () => {
    setTooltipOpen(true);
  };

  const setDefaultValue = (check: boolean = true) => {
    if (isNil(value) && props.canSetDefaultValue && defaultValue && check) {
      props.onChange(defaultValue, fullPath);
    }
  };

  const showInfoMark = Boolean(description ?? examples);

  const darkModeDisabledStyles = props.disabled
    ? 'dark:text-neutral dark:bg-surface/50'
    : '';

  const defaultSelectClassName = [
    Classes.FILL,
    isEmpty(formik.errors) ? '' : Classes.INTENT_DANGER,
    darkModeDisabledStyles,
  ].join(' ');

  const defaultInputClassName = [
    Classes.INPUT,
    Classes.FILL,
    isEmpty(formik.errors) ? '' : Classes.INTENT_DANGER,
    showInfoMark ? s.inputWithIcon : '',
    darkModeDisabledStyles,
  ].join(' ');

  const commonProps = {
    value,
    fullPath,
    handleBlur,
    handleFocus,
    showInfoMark,
    disabled: props.disabled,
    onChange: props.onChange,
    required,
    setTooltipOpen,
  };

  useEffect(() => {
    formik.validateForm({
      [path]: defaultValue ?? value,
    });
  }, [value]);

  let content = <span>Type {type} not available</span>;

  const setValidationParameters = useCallback(
    (params: any) => setFormikParams((prev) => ({ ...prev, ...params })),
    []
  );

  if (type === 'string') {
    content = (
      <CommandPrimitiveString
        {...commonProps}
        inputRef={popOverInputReference}
        className={enumValue ? defaultSelectClassName : defaultInputClassName}
        format={format}
        path={path}
        meta={meta ?? undefined}
        enumValue={enumValue}
        media={media}
        setValidationParams={setValidationParameters}
        validateChange={formik.handleChange}
        handleMetaButtonClick={props.handleMetaButtonClick}
        canSetDefaultValue={props.canSetDefaultValue ?? false}
        setDefaultValue={setDefaultValue}
        disabled={props.disabled ?? false}
      />
    );
  } else if (type === 'boolean') {
    content = (
      <CommandPrimitiveBoolean
        {...commonProps}
        defaultValue={Boolean(defaultValue)}
        disabled={props.disabled ?? false}
      />
    );
  } else if (['number', 'integer'].includes(type)) {
    content = (
      <CommandPrimitiveNumber
        {...commonProps}
        inputRef={popOverInputReference}
        className={defaultInputClassName}
        path={path}
        meta={meta ?? undefined}
        handleMetaButtonClick={props.handleMetaButtonClick}
        setDefaultValue={setDefaultValue}
        validateChange={formik.handleChange}
        disabled={props.disabled ?? false}
      />
    );
  }

  const toolTipTitle = (formik.errors[path]! || description) as string;
  const isTooltipDisabled = isEmpty(formik.errors) && !description && !examples;

  const handleExampleClick = (value: any) => {
    props.onChange(value, fullPath);
    props.onBlur();
  };

  return (
    <div>
      <Label>
        {!props.hideInputPath && (title || path)}
        {required && <span className="relative -right-1 text-red-500">*</span>}
        <CommandPrimitivePopover
          inputWidth={get('current.clientWidth', popOverInputReference)}
          handleExampleClick={handleExampleClick}
          title={toolTipTitle}
          disabled={props.disabled ?? isTooltipDisabled}
          errors={formik.errors}
          examples={examples}
          isOpen={showInfoMark && isTooltipOpen}
        >
          <ControlGroup>
            {content}
            <GenericRemoveButton
              disabled={props.disabled}
              onRemove={props.onRemove}
            />
          </ControlGroup>
        </CommandPrimitivePopover>
      </Label>
    </div>
  );
};

export default CommandPrimitiveArguments;
