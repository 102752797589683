import type {
  ISummaryChildren,
  ISummaryComponents,
  ISummarySerie,
} from '../../constants/summary/constants';
import { COMPONENTS_TYPE } from '../../constants/summary/constants';
import { color } from '../../constants/chart/constants';

export const changeCartesian2DPlotColor = (
  data: ISummaryChildren,
  satellitesId: string[]
): ISummaryChildren => {
  return {
    ...data,
    components: data.components.map((c): ISummaryComponents => {
      if (c.type === COMPONENTS_TYPE.CARTESIAN_2D_PLOT) {
        return {
          ...c,
          series: c.series.map((s): ISummarySerie => {
            const indexColor = satellitesId.findIndex(
              (id): boolean => id === s.color
            );
            const newColor = s.color.includes('#')
              ? s.color
              : indexColor >= 0
              ? color[indexColor]
              : '#000000';
            return {
              ...s,
              color: newColor,
            };
          }),
        };
      } else {
        return c;
      }
    }),
    children: data.children.map((c): ISummaryChildren => {
      return changeCartesian2DPlotColor(c, satellitesId);
    }),
  };
};
