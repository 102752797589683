import type { AppState } from '../store';
import { CUSTOM_PAYLOAD_NAME } from '../constants/ui/moduleSelector/constants';
import type {
  IPayload,
  ISelectedPayloads,
} from '../constants/moduleData/types';
import { InitialValueFactory } from '../reducers/ui/moduleSelector';
import { merge } from 'lodash';

/* Here, `selectCurrentModule` is used before every select
   to make sure that everything has loaded before attempting access */

export const selectCurrentModule = (state: AppState): string =>
  state.moduleSelector.moduleName;

export const selectAreModuleDetailsShowing = (state: AppState): string =>
  state.moduleSelector.current;

export const selectHasModuleLoaded = (state: AppState): boolean =>
  selectCurrentModule(state) !== undefined;

export const selectCurrentPayloadNames = (state: AppState): string[] =>
  selectHasModuleLoaded(state) && state.moduleSelector.selectedPayloads;

export const selectIsCreatingCustomPayload = (state: AppState): boolean =>
  selectHasModuleLoaded(state) &&
  state.moduleSelector.current === CUSTOM_PAYLOAD_NAME;

export const selectIsEditingCustomPayload = (state: AppState): boolean =>
  selectHasModuleLoaded(state) &&
  Object.keys(state.moduleSelector.customModules).includes(
    state.moduleSelector.current
  );

export const selectIsTouchingCustomPayload = (state: AppState): boolean =>
  selectHasModuleLoaded(state) &&
  (selectIsCreatingCustomPayload(state) || selectIsEditingCustomPayload(state));

export const selectAllPayloads = (state: AppState): ISelectedPayloads =>
  selectHasModuleLoaded(state) &&
  merge(
    {},
    //@ts-ignore
    state.moduleData[selectCurrentModule(state)],
    state.moduleSelector.customModules
  );

export const selectCurrentPayload = (state: AppState): IPayload => {
  if (!selectHasModuleLoaded(state)) return undefined;

  return selectIsCreatingCustomPayload(state)
    ? InitialValueFactory.payload()
    : selectAllPayloads(state)[state.moduleSelector.current];
};

export const selectCurrentPayloadName = (state: AppState): string =>
  selectHasModuleLoaded(state) && state.moduleSelector.current;

// Needs to be here to include custom names created
export const selectExistingNames = (state: AppState): string[] =>
  selectHasModuleLoaded(state) && Object.keys(selectAllPayloads(state));
