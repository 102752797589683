import Overlay from '_atoms/Overlay/Overlay';
import React, { useRef, useState } from 'react';
import { OverlayContainer, useOverlayTrigger } from 'react-aria';
import { useOverlayTriggerState } from 'react-stately';
import type { AriaButtonProps } from '@react-types/button';

type Props = {
  /**
   * The content of the modal. Can be a ReactNode or a function that exposes `toggleModal()`, and returns a ReactNode.
   */
  children: React.ReactNode | ((toggleModal: () => void) => React.ReactNode);

  /**
   * clientWidth of a map container. Used to set the width of the modal.
   */
  mapContainerWidthPx: number;
  disabled?: boolean;
  /**
   * A function that exposes a button ref, button props, and a toggleModal function.
   * These props should be passed to the button that triggers the modal.
   */
  getModalTrigger: (
    triggerRef: React.RefObject<HTMLButtonElement>,
    triggerProps: AriaButtonProps,
    toggleModal: () => void
  ) => React.ReactNode;
  /**
   * Height of a map container. Used to set the height of the modal. When set to true, the height is max-content
   */
  setHeightToContentHeight?: boolean;
};

const BOTTOM_BAR_SIZE = '45px';
const TOP_BAR_SIZE = '40px';
const SIDE_BAR_SIZE = '445px';

/**
 * MapCoveringModal is a large `<Popover2/>` that covers the entire DataCosmos map.
 * This allows interaction with the sidebar while the modal is open.
 */
const MapCoveringModal = (props: Props) => {
  const state = useOverlayTriggerState({});

  const triggerRef = useRef<HTMLButtonElement>(null);
  const overlayRef = useRef<HTMLDivElement>(null);

  const { overlayProps, triggerProps } = useOverlayTrigger(
    { type: 'dialog' },
    state,
    triggerRef
  );

  const [closable, setClosable] = useState<boolean>(true);

  return (
    <>
      {props.getModalTrigger(triggerRef, triggerProps, state.toggle)}
      {state.isOpen && (
        <OverlayContainer>
          <Overlay
            {...overlayProps}
            isOpen={state.isOpen}
            overlayRef={overlayRef}
            onClose={() => {
              if (closable) {
                state.close();
              }
            }}
            style={{
              position: 'absolute',
              top: TOP_BAR_SIZE,
              left: SIDE_BAR_SIZE,
              width: `${props.mapContainerWidthPx}px`,
              height: props?.setHeightToContentHeight
                ? `max-content`
                : `calc(100vh - ${BOTTOM_BAR_SIZE} - ${TOP_BAR_SIZE})`,
              overflowY: props?.setHeightToContentHeight ? 'hidden' : 'auto',
            }}
            isDismissable={true}
          >
            <div
              className="text-item-contrast dark:text-item-dark-contrast p-2 h-full"
              onMouseEnter={() => {
                setClosable(false);
              }}
              onMouseLeave={() => {
                setClosable(true);
              }}
            >
              {typeof props.children === 'function'
                ? props.children(state.toggle)
                : props.children}
            </div>
          </Overlay>
        </OverlayContainer>
      )}
    </>
  );
};

export default MapCoveringModal;
