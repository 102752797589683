import axios from 'axios';
import { showErrorMessage } from 'utils/common/CommonUtils';
import { REQUEST_ERROR } from 'constants/errors';
import type { IRoleAssignment } from './types';

// TODO: Migrate this to api handlers when needed
export default (url: string, token: string | undefined) => {
  const getUsersByEmail = async (email: string): Promise<[]> => {
    try {
      const {
        data: { data },
      } = await axios.get(`${url}/users`, {
        params: {
          email,
        },
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });

      return data;
    } catch (e) {
      showErrorMessage(REQUEST_ERROR);
      console.error(e);
      return [];
    }
  };

  const getUserAssignments = async (id: string): Promise<IRoleAssignment[]> => {
    try {
      const {
        data: { data },
      } = await axios.get<{ data: IRoleAssignment[] }>(
        `${url}/users/${id}/role-assignments`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      );

      return data;
    } catch (e) {
      showErrorMessage(REQUEST_ERROR);
      console.error(e);
      return [];
    }
  };

  const assignRoleToUser = async (
    userId: string,
    roleID: number
  ): Promise<[]> => {
    try {
      const {
        data: { data },
      } = await axios.post(
        `${url}/users/${userId}/role-assignments`,
        { type: 'global', roleID },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      );

      return data;
    } catch (e) {
      showErrorMessage(REQUEST_ERROR);
      console.error(e);
      return [];
    }
  };

  const deleteUserRoleAssignments = async (
    userId: string,
    assignmentId: number
  ) => {
    try {
      await axios.delete(
        `${url}/users/${userId}/role-assignments/${assignmentId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      );
    } catch (e) {
      showErrorMessage(REQUEST_ERROR);
      console.error(e);
    }
  };

  return {
    getUsersByEmail,
    getUserAssignments,
    assignRoleToUser,
    deleteUserRoleAssignments,
  };
};
