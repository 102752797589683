import type { ThunkAction } from 'redux-thunk';
import type { AppState } from '../../../../reducers/rootReducer';
import type { Action } from 'redux';
import type { IMarkerWrapper } from '../../../../declarations/mapDeclarations/Marker';
import type { IMapWrapper } from '../../../../declarations/mapDeclarations/Map';
import type { IconName } from '@blueprintjs/core';
import { ContextMenu, Menu, MenuItem } from '@blueprintjs/core';
import type { MaybeElement } from '@blueprintjs/core/src/common/props';
import {
  removeGroundStation,
  showGroundStation,
  updateGroundStation,
} from './events';
import type { ReactElement } from 'react';
import React from 'react';
import type { IGroundStation } from '../../../../constants/groundStations/actionTypes';
import type { IContextMenuSub } from '../map/services';
import type { IAnyKey } from '../../../../utils/getFormData';

// used for unconnected to redux store components
export const addContextMenuWithoutRedux = (
  groundStation: IMarkerWrapper | IGroundStation,
  map: IMapWrapper,
  groundStationData: IGroundStation,
  googleMouseEvent?: google.maps.MouseEvent & IAnyKey
) => {
  if (map) {
    if (map.drawingManager.getDrawingMode()) {
      map.drawingManager.isGroundStation = false;
      map.drawingManager.setDrawingMode(null);
      return;
    }
  }

  if (googleMouseEvent) {
    let e = null;
    //NB: google map types doesnt have Mouse Event with ClientX|ClientY
    try {
      for (const key in googleMouseEvent) {
        if (
          googleMouseEvent[key].clientX !== undefined &&
          googleMouseEvent[key].clientY
        ) {
          e = googleMouseEvent[key];
        }
      }
    } catch (e) {
      console.warn('Event type: ', googleMouseEvent);
    }
    if (!e) {
      e = googleMouseEvent as unknown as MouseEvent;
    }

    let gsId;
    let antennaId;

    if (groundStationData.id.includes('antenna')) {
      const splittedId = groundStationData.id.split('_antenna_');
      gsId = splittedId[0];
      antennaId = splittedId[1];
    }

    ContextMenu.show(
      <div style={{ width: 150, height: 140, padding: 5 }}>
        <div>ID: {gsId || groundStationData.id}</div>
        {antennaId && <div>Antenna ID: {antennaId}</div>}
        <div>Name: {groundStationData.name}</div>
        <div>Latitude: {groundStationData.lat}</div>
        <div>Longitude: {groundStationData.lon}</div>
        <div>Altitude: {groundStationData.altitude}</div>
        <div>Min Elevation: {groundStationData.elevationAngle}</div>
      </div>,
      { left: e.clientX, top: e.clientY },
      (): void => {}
    );
  }
};

export const addContextMenu =
  (
    groundStation: IMarkerWrapper | IGroundStation,
    map: IMapWrapper,
    googleMouseEvent?: google.maps.MouseEvent & IAnyKey
  ): ThunkAction<void, AppState, null, Action<string>> =>
  (dispatch, state): ReactElement => {
    const { popUp } = state();
    if (popUp && popUp.visible) {
      return undefined;
    }

    if (map) {
      if (map.drawingManager.getDrawingMode()) {
        map.drawingManager.isGroundStation = false;
        map.drawingManager.setDrawingMode(null);
        return undefined;
      }
    }

    const isCustom = state().groundStations.list.find(
      (g): boolean => g.id === groundStation.id
    ).custom;
    let menuHandlers: IContextMenuSub[] = [];
    if (isCustom) {
      menuHandlers = [
        {
          text: 'Update ground station',
          icon: 'inbox-update',
          clickEvent: (): void => dispatch(updateGroundStation(groundStation)),
        },
        {
          text: 'Remove ground station',
          icon: 'delete',
          clickEvent: (): void => dispatch(removeGroundStation(groundStation)),
        },
      ];
    } else {
      menuHandlers = [
        {
          text: 'Show ground station',
          icon: 'list',
          clickEvent: (): void => dispatch(showGroundStation(groundStation)),
        },
      ];
    }

    const menuItems = menuHandlers.map((item, index): ReactElement => {
      const icon = item.icon as IconName | MaybeElement;
      return (
        <MenuItem
          key={index}
          icon={icon}
          text={item.text}
          onClick={item.clickEvent as any}
        />
      );
    });

    if (googleMouseEvent) {
      let e = null;
      //NB: google map types doesnt have Mouse Event with ClientX|ClientY
      try {
        for (const key in googleMouseEvent) {
          if (
            googleMouseEvent[key].clientX !== undefined &&
            googleMouseEvent[key].clientY
          ) {
            e = googleMouseEvent[key];
          }
        }
      } catch (e) {
        console.warn('Event type: ', googleMouseEvent);
      }
      if (!e) {
        e = googleMouseEvent as unknown as MouseEvent;
      }
      ContextMenu.show(
        <Menu>{menuItems}</Menu>,
        { left: e.clientX, top: e.clientY },
        (): void => {}
      );
    } else {
      return <Menu>{menuItems}</Menu>;
    }
    return null;
  };
