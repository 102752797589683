import Loading from 'components/common/Loading';
import React from 'react';
import { useAuthentication } from 'services/auth/useAuthentication';
import type { AppState } from 'services/auth/useAuthentication';
import { useAuthorisation } from 'services/auth/useAuthorisation';
import { AuthContext } from 'services/auth/AuthWrapper';
import { newAuth0Backend } from './auth0Backend';
import type { Options as Auth0Options } from './auth0Backend';
import type { Options as KeycloakOptions } from './keycloakBackend';
import { AUTHENTICATION_PROVIDER } from 'env';
import { newKeycloakBackend } from './keycloakBackend';
import { history } from 'config';

interface IProps {
  children: React.ReactNode;
  authenticationOptions: Options;
}

export type Options = Auth0Options | KeycloakOptions;

export const AuthProvider = (props: IProps) => {
  let authBackendProvider = undefined;
  switch (AUTHENTICATION_PROVIDER) {
    case 'keycloak':
      if (!isKeycloakOptions(props.authenticationOptions)) {
        throw Error(
          'keycloak authentication provider configured, but options inappropriate for keycloak received'
        );
      }
      authBackendProvider = () =>
        newKeycloakBackend(props.authenticationOptions as KeycloakOptions);
      break;
    case 'auth0':
    default:
      if (!isAuth0Options(props.authenticationOptions)) {
        throw Error(
          'auth0 authentication provider configured, but options inappropriate for auth0 received'
        );
      }
      authBackendProvider = () =>
        newAuth0Backend(props.authenticationOptions as Auth0Options);
  }
  const authentication = useAuthentication(
    authBackendProvider,
    postAuthenticationCallback
  );
  const authorisation = useAuthorisation(
    authentication.user?.sub,
    authentication.token
  );

  if (authentication.loading || authorisation.loading) {
    return <Loading isLoading={false} />;
  }

  return (
    <AuthContext.Provider
      value={{
        ...authentication,
        ...authorisation,
        loading: false,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};

/**
 * Checks whether the type is authentication options for auth0
 * @param o An object containing authentication options
 * @returns true only if the options appear to be appropriate for auth0
 */
const isAuth0Options = (o: Options): o is Auth0Options => {
  return typeof (o as Auth0Options).domain === typeof '';
};

/**
 * Checks whether the type is authentication options for keycloak
 * @param o An object containing authentication options
 * @returns true only if the options appear to be appropriate for keycloak
 */
const isKeycloakOptions = (
  o: Options
): o is { onRedirectCallback: (s?: AppState) => void } & KeycloakOptions => {
  return (
    typeof (o as KeycloakOptions).realm === typeof '' &&
    typeof (o as KeycloakOptions).redirectUri === typeof ''
  );
};

/**
 * Called after redirect back from successful authentication,
 * moves the user back to the page they were originally viewing or the root page.
 * @param appState previous state of the application returned from the redirect
 */
const postAuthenticationCallback = (appState?: AppState): void => {
  const url = appState?.url ? appState.url : '/';
  history.replace(url);
};
