import React from "react";
import type { ListState } from "react-stately";
import type { Node } from "@react-types/shared";
import { useListBoxSection } from "react-aria";
import Option from "../Option/Option";

type ListboxSectionProps<T> = {
  section: Node<T>;
  state: ListState<T>;
};

const ListBoxSection = <T extends object>({
  section,
  state,
}: ListboxSectionProps<T>) => {
  const { itemProps, groupProps, headingProps } = useListBoxSection({
    heading: section.rendered,
    "aria-label": section["aria-label"],
  });
  return (
    <>
      <li {...itemProps} className="pt-2">
        {section.rendered && (
          <span
            {...headingProps}
            className="text-xs font-bold uppercase text-data-text-75 dark:text-data-text"
          >
            {section.rendered}
          </span>
        )}
        <ul {...groupProps}>
          {[...section.childNodes].map((node) => (
            <Option key={node.key} item={node} state={state} />
          ))}
        </ul>
      </li>
    </>
  );
};

export default ListBoxSection;
