import { Item } from 'react-stately';
import Select2 from '_molecules/Select2/Select2';
import type { ItemProps } from 'ui/ItemContainer';
import { ItemContainer } from 'ui/ItemContainer';

export type SelectInputProps = ItemProps & {
  options: string[];
  value: string | string[];
  setValue: (value: string) => void;
};

export const SelectInput = ({
  options,
  value,
  setValue,
  ...itemProps
}: SelectInputProps) => {
  return (
    <ItemContainer {...itemProps}>
      <Select2
        aria-label={itemProps.title ?? 'select'}
        onSelectionChange={(item) => {
          if (typeof item === 'string') {
            setValue(item);
          }
        }}
        selectedItem={value}
      >
        {options.map((option) => (
          <Item key={option}>{option}</Item>
        ))}
      </Select2>
    </ItemContainer>
  );
};
