import { useEffect } from 'react';
import PayloadTable from '../../../components/PayloadTable/PayloadTable';
import styles from './styles.module.scss';
import { connect } from 'react-redux';
import type { AppState } from '../../../reducers/rootReducer';
import { Position, Intent, Button } from '@blueprintjs/core';
import { Tooltip } from 'ui/Tooltip';
import type {
  IPayload,
  ISelectedPayloads,
} from '../../../constants/moduleData/types';
import { updateResultsThunk } from '../../../actions/results/thunks';
import {
  addDefaultSatelliteOperationalMode,
  removeDefaultSatelliteOperationalMode,
} from '../../../actions/satellite/action';
import {
  selectCurrentPayloadNames,
  selectIsTouchingCustomPayload,
  selectIsCreatingCustomPayload,
  selectCurrentModule,
  selectAllPayloads,
  selectExistingNames,
  selectCurrentPayloadName,
} from '../../../selectors/moduleSelector';

import CustomPayloadForm from './CustomPayloadForm';
import { CUSTOM_PAYLOAD_NAME } from '../../../constants/ui/moduleSelector/constants';
import {
  updatePayload,
  confirmPayloadSelection,
  cancelPayloadSelection,
  loadModule,
  clearModule,
} from '../../../actions/ui/moduleSelector/action';
import { selectSatelliteModuleNames } from '../../../selectors/satellite';
import type {
  ISatelliteModules,
  ISatelliteModuleType,
} from '../../../constants/satellite/types';
import Loader from '../../../components/Loader';
import { msd } from '../../../constants/mixpanelAnalytics';
import {
  PAYLOAD_DATA,
  POWER_DATA,
  EPS_DATA,
  COMMS_DATA,
  AOCS_DATA,
  OBDH_DATA,
} from '../../../constants/moduleData/constants';
import useOnLoadMetric from '../../../utils/hooks/analytics/useOnLoadMetric';
import { useAnalytics } from 'utils/hooks/analytics/useAnalytics';

const PAYLOAD_RESULTS_PATH = 'msd.payloadModePage';
const PLATFORM_RESULTS_PATH = 'msd.platformPage';

interface IOwnProps {
  type: ISatelliteModuleType;
  updateSatelliteModule: () => void;
  loadModule: () => void;
  submitHandler?: (selectedNames: string[]) => void;
  customisable?: boolean;
  multipleSelectable?: boolean;
}

interface IStateProps {
  moduleName: string | undefined;
  currentPayload?: string;
  currentPayloadData?: IPayload;
  isCreatingCustomPayload: boolean;
  isTouchingCustomPayload: boolean;
  selectedPayloads: string[];
  existingPayloadNames: string[];
  allModuleData: ISelectedPayloads;
  satelliteModules: ISatelliteModules;
}

interface IDispatchProps {
  updateResultsThunk: typeof updateResultsThunk;
  confirmPayloadSelection: typeof confirmPayloadSelection;
  cancelPayloadSelection: typeof cancelPayloadSelection;
  addDefaultSatelliteOperationalMode: typeof addDefaultSatelliteOperationalMode;
  removeDefaultSatelliteOperationalMode: typeof removeDefaultSatelliteOperationalMode;
  updatePayload: typeof updatePayload;
  loadModule: typeof loadModule;
  clearModule: typeof clearModule;
}

type IProps = IOwnProps & IStateProps & IDispatchProps;

const typeToVisitMetric = {
  [PAYLOAD_DATA]: msd.PAYLOAD_SELECTION.VISIT,
  [POWER_DATA]: msd.PLATFORM.MODULE_SELECTION.POWER.VISIT,
  [EPS_DATA]: msd.PLATFORM.MODULE_SELECTION.EPS.VISIT,
  [COMMS_DATA]: msd.PLATFORM.MODULE_SELECTION.COMMS.VISIT,
  [AOCS_DATA]: msd.PLATFORM.MODULE_SELECTION.AOCS.VISIT,
  [OBDH_DATA]: msd.PLATFORM.MODULE_SELECTION.OBDH.VISIT,
};

const typeToCompletionMetric = {
  [PAYLOAD_DATA]: msd.PAYLOAD_SELECTION.VISIT,
  [POWER_DATA]: msd.PLATFORM.MODULE_SELECTION.POWER.VISIT,
  [EPS_DATA]: msd.PLATFORM.MODULE_SELECTION.EPS.VISIT,
  [COMMS_DATA]: msd.PLATFORM.MODULE_SELECTION.COMMS.VISIT,
  [AOCS_DATA]: msd.PLATFORM.MODULE_SELECTION.AOCS.VISIT,
  [OBDH_DATA]: msd.PLATFORM.MODULE_SELECTION.OBDH.VISIT,
};

const typeToResultsPath = {
  [PAYLOAD_DATA]: PAYLOAD_RESULTS_PATH,
  [POWER_DATA]: PLATFORM_RESULTS_PATH,
  [EPS_DATA]: PLATFORM_RESULTS_PATH,
  [COMMS_DATA]: PLATFORM_RESULTS_PATH,
  [AOCS_DATA]: PLATFORM_RESULTS_PATH,
  [OBDH_DATA]: PLATFORM_RESULTS_PATH,
};

const ModuleSelectionPage = (props: IProps) => {
  const {
    moduleName,
    currentPayload,
    isCreatingCustomPayload,
    isTouchingCustomPayload,
    selectedPayloads,
    updateResultsThunk,
    updatePayload,
    existingPayloadNames,
    type,
    loadModule,
    clearModule,
    submitHandler,
    customisable,
  } = props;

  const { sendInfo } = useAnalytics();

  useOnLoadMetric({
    action: 'Visit',
    item: typeToVisitMetric[type],
    module: 'MSD',
    type: typeToVisitMetric[type],
  });

  useEffect(() => {
    loadModule();
    return () => {
      clearModule();
    };
  }, [type]);

  if (!moduleName) return <Loader />;

  const updateModuleBubble = () => {
    updateResultsThunk(
      typeToResultsPath[type],
      {
        selectedPayloads,
      },
      selectedPayloads,
      undefined
    );
  };

  const canProceed = Boolean(selectedPayloads.length);

  const completeHandler = () => {
    sendInfo({
      type: typeToCompletionMetric[type],
      action: 'Complete',
      item: 'Payload module',
      module: 'MSD',
      additionalParams: {
        selectedPayloadNames: selectedPayloads,
      },
    });
    updateModuleBubble();
    submitHandler && submitHandler(selectedPayloads);
  };

  return (
    <>
      <div className={styles.container}>
        <PayloadTable />
      </div>
      <div className={styles.completeButtonContainer}>
        {customisable && !isCreatingCustomPayload && (
          <Button
            onClick={() => {
              sendInfo({
                type: msd.PAYLOAD_SELECTION.CUSTOM.VISIT,
                action: 'Visit',
                item: 'Payload selection',
                module: 'MSD',
              });
              updatePayload(CUSTOM_PAYLOAD_NAME);
            }}
          >
            Add Custom Payload
          </Button>
        )}
        <Tooltip
          content="Select a payload to continue"
          position={Position.BOTTOM}
          disabled={canProceed}
        >
          <Button
            intent={Intent.SUCCESS}
            onClick={completeHandler}
            disabled={!canProceed}
            text="Continue"
          />
        </Tooltip>
      </div>
      {Boolean(currentPayload) && (
        <CustomPayloadForm
          readOnly={!isTouchingCustomPayload}
          existingNames={existingPayloadNames}
        />
      )}
    </>
  );
};

const mapStateToProps = (state: AppState): IStateProps => ({
  moduleName: selectCurrentModule(state),
  currentPayload: selectCurrentPayloadName(state),
  isCreatingCustomPayload: selectIsCreatingCustomPayload(state),
  isTouchingCustomPayload: selectIsTouchingCustomPayload(state),
  selectedPayloads: selectCurrentPayloadNames(state),
  existingPayloadNames: selectExistingNames(state),
  allModuleData: selectAllPayloads(state),
  satelliteModules: selectSatelliteModuleNames(state),
});

const mapDispatchToProps: IDispatchProps = {
  updatePayload,
  updateResultsThunk,
  confirmPayloadSelection,
  cancelPayloadSelection,
  addDefaultSatelliteOperationalMode,
  removeDefaultSatelliteOperationalMode,
  loadModule,
  clearModule,
};

const mergeProps = (
  propsFromState: IStateProps,
  propsFromDispatch: IDispatchProps,
  ownProps: IOwnProps
) => ({
  ...propsFromState,
  ...propsFromDispatch,
  ...ownProps,
  loadModule: () =>
    propsFromDispatch.loadModule({
      moduleName: ownProps.type,
      satelliteModules: propsFromState.satelliteModules,
      multipleSelectable: ownProps.multipleSelectable,
    }),
});

export default connect<IStateProps, IDispatchProps, IOwnProps>(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(ModuleSelectionPage);
