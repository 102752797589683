import type { Dispatch, ReactElement } from 'react';
import React from 'react';
import type { WithTranslation } from 'react-i18next';
import { withTranslation } from 'react-i18next';
import type {
  IGeometryObject,
  IGeometryObjectState,
} from '../../constants/msd/geometry/interfaces';
import { Button } from '@blueprintjs/core';
import type { IPopUp } from '../../constants/popUp/actionTypes';
import GeometryForm from '../popUp/geometryForm/GeometryForm';
import {
  GEOMETRY_TYPE,
  geometryOptions,
} from '../../constants/msd/geometry/constants';
import { orbitInitialValues } from '../../constants/msd/geometry/satelliteOrbits/constants';
import type { InjectedFormProps } from 'redux-form';
import { SubmissionError } from 'redux-form';
import { geometryNormalize } from '../../utils/geometry/geometryNormalize';
import { walkerConstellationInitialValues } from '../../constants/msd/geometry/walkerConstellation/constants';

interface IGeometryProps extends WithTranslation {
  geometryObject?: IGeometryObject;
  setPopUp?: Function;
  updateGeometry?: Function;
  removeGeometry?: Function;
  geometry?: IGeometryObjectState;
  astrum: string;
}

interface IGeometryState {}

class GeometryObject extends React.Component<IGeometryProps, IGeometryState> {
  public state: IGeometryState = {};

  private updateGeometryObject = (): void => {
    const { setPopUp, updateGeometry, geometryObject, geometry, astrum } =
      this.props;
    const { groups, ...rest } = geometryObject;
    const popUp: IPopUp = {
      visible: true,
      classNames: ['centered'],
      component: GeometryForm,
      data: {
        ...orbitInitialValues(astrum),
        ...walkerConstellationInitialValues,
        name: `Geometry`,
        type: GEOMETRY_TYPE.KEPLERIAN,
        ...rest,
        ...groups,
      },
      otherProps: {
        options: [
          geometryOptions.KEPLERIAN,
          geometryOptions.SYNCHRONOUS,
          geometryOptions.STATIONARY,
          geometryOptions.POLAR,
          geometryOptions.TLE,
          //TODO hide WALKER_CONSTELLATION
          geometryOptions.WALKER_CONSTELLATION,
        ],
        apsides: {},
        astrum: astrum,
      },
      functions: {
        onSubmit: (
          geometryObject: IGeometryObject,
          dispatch: Dispatch<Function>,
          props: InjectedFormProps<FormData>
        ): void => {
          if (
            geometry.find(
              (g) =>
                g.name === geometryObject.name && g.id !== geometryObject.id
            )
          ) {
            const errorMessage = 'name_not_unique';
            throw new SubmissionError({
              name: errorMessage,
              _error: errorMessage,
            });
          }
          const normalizedGeometry = geometryNormalize(geometryObject, props);
          updateGeometry(normalizedGeometry);
        },
        onClose: (): void => {},
      },
    };
    setPopUp(popUp);
  };
  private removeGeometryObject = () => {
    const { geometryObject, removeGeometry } = this.props;
    removeGeometry(geometryObject);
  };

  public render(): ReactElement {
    const { geometryObject, t } = this.props;
    const optimisation = Object.values(geometryObject).find(
      (val) => val.optimisation
    );
    const optimize = optimisation || !geometryObject.force;

    return (
      <div className={`geometry-object ${optimize ? 'blue' : 'green'}`}>
        <div
          title={`type:\t${t(
            `geometry.${geometryObject.type}` as any
          )}\nname:\t${geometryObject.name}`}
          className="geometry-object_name"
        >
          <div>{t(`geometry.${geometryObject.type}` as any)}</div>
          <div>{geometryObject.name}</div>
        </div>
        <div className="geometry-object_buttons">
          <Button icon={'settings'} onClick={this.updateGeometryObject} />
          <Button
            intent={'danger'}
            icon={'delete'}
            onClick={this.removeGeometryObject}
          />
        </div>
      </div>
    );
  }
}

export default withTranslation()(GeometryObject);
