import { useEffect, useState } from 'react';
import { map, mean, remove } from 'lodash/fp';
import { difference, isNil, union } from 'lodash';

import { MIN_ZOOM, STATION_ZOOM } from 'constants/ops/gs_scheduling/constants';
import type { IGroundStation } from 'constants/groundStations/actionTypes';
import type { IFocus } from 'constants/focus/actionTypes';
import { FocusTypes } from 'constants/focus/actionTypes';
import { showErrorMessage } from 'utils/common/CommonUtils';
import type { GS } from 'api/groundStation/types';

interface IProps {
  chosenStationIdList: number[];
  setChosenStationIdList: (value: number[]) => void;
}

const useGroundStationList = (props: IProps) => {
  const [focus, setFocus] = useState<IFocus>();
  const [mapPickedStationId, setMapPickedStationId] = useState<number>();
  const [focusedCoordinate, setFocusedCoordinate] =
    useState<google.maps.LatLngLiteral>();
  const [currentZoom, setCurrentZoom] = useState<number>(MIN_ZOOM);
  const [currentFocusId, setCurrentFocusId] = useState<number>();

  const { chosenStationIdList, setChosenStationIdList } = props;

  useEffect(() => {
    if (!focusedCoordinate && !currentFocusId) {
      setFocus({ type: null, id: null, needScroll: false });
      return;
    }

    const focus = currentFocusId
      ? {
          type: FocusTypes.GS_SUB_ID,
          id: `${currentFocusId}_antenna_0`,
          needScroll: false,
        }
      : { type: FocusTypes.COORDINATES_SUB, id: null, needScroll: false };

    setFocus(focus);
  }, [focusedCoordinate]);

  useEffect(() => {
    if (!isNil(mapPickedStationId)) {
      if (chosenStationIdList.includes(mapPickedStationId)) {
        const stationIdList = remove(
          (id) => id === mapPickedStationId,
          chosenStationIdList
        );
        setChosenStationIdList([...stationIdList]);
      } else {
        setChosenStationIdList([...chosenStationIdList, mapPickedStationId]);
      }
      setMapPickedStationId(undefined);
    }
  }, [mapPickedStationId]);

  const handleGSDetailZoomClick = (stationId: number) => {
    if (currentFocusId === stationId && currentZoom >= STATION_ZOOM) {
      setCurrentFocusId(undefined);
      setCurrentZoom(MIN_ZOOM);
      return;
    }

    setCurrentFocusId(stationId);
    setCurrentZoom(STATION_ZOOM);
  };

  const handleProviderCheckBoxChange = (providerStations: GS[]) => {
    const ids = map('id', providerStations);

    const lat = mean(map('antennas[0].location.latitude', providerStations));
    const lng = mean(map('antennas[0].location.longitude', providerStations));

    setFocusedCoordinate({ lat, lng });

    let newGroundStations = union(chosenStationIdList, ids);

    // If there are new ground stations to add
    if (newGroundStations.length != chosenStationIdList.length) {
      setChosenStationIdList(newGroundStations);
    }
    // Otherwise deselect them
    else {
      newGroundStations = difference(chosenStationIdList, ids);
      setChosenStationIdList(newGroundStations);
    }
  };

  const handleGSCheckBoxChange = (stationId: any) => {
    if (chosenStationIdList.includes(stationId)) {
      const stationIdList = remove(
        (id) => id === stationId,
        chosenStationIdList
      );
      setChosenStationIdList([...stationIdList]);
      return;
    }

    setChosenStationIdList([...chosenStationIdList, stationId]);
  };

  const handleZoomChange = (zoom: number) => {
    setCurrentZoom(zoom);
  };

  const handleMapStationClick = (groundStationData: IGroundStation) => {
    const stationId = groundStationData.id as number;

    try {
      setMapPickedStationId(stationId);
      setCurrentFocusId(stationId);
    } catch (e) {
      showErrorMessage('Could not parse station ID');
    }
  };

  const moveToAndFocusId = (newFocusId: number): any => {
    const focus = newFocusId
      ? {
          type: FocusTypes.GS_SUB_ID,
          id: `${newFocusId}_antenna_0`,
          needScroll: false,
        }
      : { type: FocusTypes.COORDINATES_SUB, id: null, needScroll: false };

    setFocus(focus);
  };

  return {
    focus,
    focusedCoordinate,
    currentZoom,
    currentFocusId,
    setCurrentFocusId: moveToAndFocusId,
    handleGSDetailZoomClick,
    handleProviderCheckBoxChange,
    handleGSCheckBoxChange,
    handleZoomChange,
    handleMapStationClick,
  };
};

export default useGroundStationList;
