import {
  useState,
  useContext,
  createContext,
  useCallback,
  useMemo,
} from 'react';
import type {
  OZAConstraints,
  RollAngleConstraint,
  SZAConstraints,
} from 'api/tasking/types';
import type { IInstrument } from 'constants/satellite/types';
import { useMapLayers } from './MapLayersProvider';
import { toaster } from 'toaster';
import type { Layer } from 'datacosmos/entities/layer';
import { LayerSourceType } from 'datacosmos/entities/layer';
import type { GeoJSONLayer } from 'datacosmos/entities/geojsonLayer';
import type { OpportunityLayer } from 'datacosmos/entities/TaskingOpportunityLayer';
import {
  extractSwaths,
  pairSwathToFieldOfRegard,
  extractFieldsOfRegard,
} from 'datacosmos/components/Tasking/helpers';
import type { CommonOpportunity, SwathControlData } from 'api/tasking/helpers';
import {
  searchTaskingOpportunities,
  getSwathWithControlData,
} from 'api/tasking/helpers';
import { SwathLayer } from 'datacosmos/entities/SwathLayer';
import { FieldOfRegardLayer } from 'datacosmos/entities/FieldOfRegardLayer';
import moment from 'moment';
import { getRegionOfInterestCoveragePercent } from 'datacosmos/utils/geojson';
import type { PostActivity } from 'api/tasking/service';
import { postTaskingRequest } from 'api/tasking/service';
import { useRouteMatch } from 'react-router-dom';
import type { PolygonLayer } from 'datacosmos/entities/polygonLayer';
import {
  jsDateToMomentObj,
  momentObjToISOString,
} from 'utils/common/dateUtils';
import type { SatelliteId } from 'api/satellites/types';

const getOpportunityLayersFromLayers = (layers: Layer[]) => {
  return layers.filter(
    ({ sourceType }) => sourceType === LayerSourceType.TASKING_OPPORTUNITIES
  ) as OpportunityLayer<CommonOpportunity>[];
};

const getRegionsOfInterestOnMap = (layers: Layer[]) => {
  return layers.filter(
    ({ sourceType }) => sourceType === LayerSourceType.TASKING_REGIONS
  ) as GeoJSONLayer<unknown>[];
};

export type ITaskingContext = ReturnType<typeof useTaskingProvider>;

export const TaskingContext = createContext<ITaskingContext>(
  null as unknown as ITaskingContext
);

export const useTasking = () => useContext<ITaskingContext>(TaskingContext);

export const useTaskingProvider = () => {
  const { layers, removeLayer, removeEverythingFromMap, setLayers } =
    useMapLayers();
  const [isFetching, setFetching] = useState(false);

  const [hasSZAError, setSZAError] = useState(false);
  const [hasRollAngleError, setRollAngleError] = useState(false);

  const [dateTo, setDateTo] = useState<Date>();
  const [dateFrom, setDateFrom] = useState<Date>();
  const [addSatellite, setAddSatellite] = useState<IInstrument[]>();
  const [isSZA, setIsSZA] = useState(true);
  const [isOZA, setIsOZA] = useState(false);
  const [isSZAObjective, setIsSZAObjective] = useState(false);
  const [isOZAObjective, setIsOZAObjective] = useState(false);
  const [SZAConstraints, setSZAConstraints] = useState<SZAConstraints>({
    Type: 'SZA',
    Active: true,
    Min: 0,
    Max: 90,
    Objective: null,
  });

  const [OZAConstraints, setOZAConstraints] = useState<OZAConstraints>({
    Type: 'OZA',
    Active: false,
    Min: 0,
    Max: 180,
    MaxEdge: '90',
    MinEdge: '0',
    Objective: null,
  });

  const [isRollAngle, setIsRollAngle] = useState<boolean>(true);
  const [rollAngleConstraint, setRollAngleConstraint] =
    useState<RollAngleConstraint>({
      type: 'ROLL',
      min: -15,
      max: 15,
    });

  const [requestNotes, setRequestNotes] = useState<string>('');

  // TODO: What is the difference between opportunityLayers and opportunities?
  const [opportunityLayers, setOpportunityLayers] = useState<
    OpportunityLayer<CommonOpportunity>[]
  >([]);

  const [opportunities, setOpportunities] = useState<
    OpportunityLayer<CommonOpportunity>[]
  >([]);

  const [confirmedSwaths, setConfirmedSwaths] = useState<
    SwathLayer<CommonOpportunity>[]
  >([]);

  const [savedLayers, setSavedLayers] = useState<
    (OpportunityLayer<CommonOpportunity> | GeoJSONLayer<unknown>)[]
  >([]);

  const regions = useMemo(
    () =>
      layers.filter(
        ({ sourceType }) => sourceType === LayerSourceType.TASKING_REGIONS
      ) as PolygonLayer[],
    [layers]
  );

  const urlMatch = useRouteMatch<{ projectId: string }>({
    path: '/data/project/:projectId',
    exact: false,
  });

  const projectId = urlMatch?.params.projectId;

  const modifySwath = useCallback(
    async (
      swath: SwathLayer<CommonOpportunity>,
      swathData: SwathControlData
    ) => {
      const swathSat = (addSatellite ?? []).filter(
        (instrument) =>
          instrument.satellite.mission_id === swath.metadata.SatelliteId
      );
      if (!projectId) {
        return;
      }

      const data = await getSwathWithControlData({
        instruments: swathSat,
        regions,
        swathControlData: swathData,
        projectId: projectId,
      });

      if (!data) return;

      const modifiedLayer = new SwathLayer(
        swath.sourceType,
        swath.name,
        data.footprint.geojson,
        {
          ...swath.metadata,
          OpportunityIndex: swath.metadata.OpportunityIndex,
          Oza: data.midpoint.oza_deg,
          Sza: data.midpoint.sza_deg,
          Benchmark: {
            ...swath.metadata.Benchmark,
            Coverage: getRegionOfInterestCoveragePercent(
              [data.footprint.geojson],
              regions.map(
                (region) => region.data
              ) as GeoJSON.Feature<GeoJSON.Polygon>[]
            ),
          },
          Area: data.area_km2,
          visible: true,
        }
      );

      setLayers((prev) => {
        const clickedSwath = getOpportunityLayersFromLayers(prev).find(
          (layer) =>
            layer.metadata.Id === swath.metadata.Id &&
            layer.layerClass === 'SwathLayer'
        );
        if (clickedSwath) {
          return prev.map((layer) => {
            if (layer.id === clickedSwath.id) {
              const isModifiedLayerConfirmed = (
                clickedSwath as SwathLayer<CommonOpportunity>
              ).isConfirmed;
              modifiedLayer.isConfirmed = isModifiedLayerConfirmed;
              if (isModifiedLayerConfirmed)
                modifiedLayer.options.color = '#00FF00';
              return modifiedLayer;
            }
            return layer;
          });
        }
        return [modifiedLayer, ...prev];
      });

      return { id: modifiedLayer.id, modified_swath: data };
    },
    [addSatellite, regions, setLayers, projectId]
  );

  const handleSwathConfirm = useCallback(
    (
      swathId: string,
      swathControlData: SwathControlData,
      confirmed: boolean
    ) => {
      setLayers((prev) => {
        return prev.map((layer) => {
          if (swathId === layer.id) {
            const swath = layer as SwathLayer<CommonOpportunity>;
            swath.isConfirmed = confirmed;
            swath.options.color = confirmed ? '#00FF00' : '#FFCC55';
            swath.options.visible = confirmed;
            swath.metadata = {
              ...swath.metadata,
              Start: momentObjToISOString(
                jsDateToMomentObj(swathControlData.duration.start)
              ),
              End: momentObjToISOString(
                jsDateToMomentObj(swathControlData.duration.end)
              ),
              RollAngle: swathControlData.rotation.toFixed(2),
              Duration: moment(swathControlData.duration.end).diff(
                moment(swathControlData.duration.start),
                'seconds'
              ),
              Parameters: swathControlData.parameters,
              Priority: swathControlData.priority,
            };

            setConfirmedSwaths((prevConfirmed) => {
              if (!confirmed) {
                return prevConfirmed.filter(
                  (s) => s.metadata.Id !== swath.metadata.Id
                );
              }

              // Find index of the already confirmed swath
              const i = prevConfirmed.findIndex(
                (s) => s.metadata.Id === swath.metadata.Id
              );

              // If it exists, replace with itself but with updated swath data
              if (i !== -1) {
                const conf = prevConfirmed;
                conf.splice(i, 1, swath);
                return conf;
              }

              return [...prevConfirmed, swath];
            });

            return swath as unknown as Layer;
          }
          return layer;
        });
      });
    },
    [setLayers]
  );

  const saveMapLayerState = useCallback(() => {
    setSavedLayers([
      ...getOpportunityLayersFromLayers(layers),
      ...getRegionsOfInterestOnMap(layers),
    ]);
  }, [layers]);

  const loadMapLayerState = useCallback(() => {
    removeEverythingFromMap();
    setLayers((prev) => [...savedLayers, ...prev]);
  }, [removeEverythingFromMap, savedLayers, setLayers]);

  const clearTaskingDetail = useCallback(() => {
    setIsRollAngle(false);
    setIsSZA(false);
    setAddSatellite([]);
    setDateTo(undefined);
    setDateFrom(undefined);
  }, []);

  const requestActivities = useCallback(async () => {
    if (!projectId) {
      return;
    }

    if (!addSatellite) {
      return;
    }

    const activities = confirmedSwaths.map(
      ({ metadata }): PostActivity => ({
        mission_id: metadata.SatelliteId,
        start_date: metadata.Start,
        end_date: metadata.End,
        type: 'IMAGE_ACQUISITION',
        parameters: metadata.Parameters,
        priority: metadata.Priority?.priority_level,
      })
    );
    const taskinRequestType = 'MANUAL';

    const postTaskingRequests = regions.map((region) =>
      postTaskingRequest({
        params: {
          projectId,
        },
        body: {
          type: taskinRequestType,
          region_name: region.name,
          region: region.data as GeoJSON.Feature<GeoJSON.Polygon>,
          parameters: {},
          activities: activities,
          notes: requestNotes,
          constraints: [
            { type: 'SZA', max: SZAConstraints.Max, min: SZAConstraints.Min },
            {
              type: 'ROLL_ANGLE',
              max: rollAngleConstraint.max,
              min: rollAngleConstraint.min,
            },
            {
              type: 'ACQUISITION_DATE',
              min: moment(dateFrom).unix(),
              max: moment(dateTo).unix(),
            },
          ],
          instruments: addSatellite.map((i) => {
            return {
              mission_id: i.satellite.mission_id as SatelliteId,
              sensor_id: i.sensor.sensorId,
            };
          }),
        },
      })
    );

    await Promise.all(postTaskingRequests);
  }, [
    SZAConstraints.Max,
    SZAConstraints.Min,
    addSatellite,
    confirmedSwaths,
    dateFrom,
    dateTo,
    projectId,
    regions,
    requestNotes,
    rollAngleConstraint.max,
    rollAngleConstraint.min,
  ]);

  const submitAutomatedTaskingRequest = useCallback(async () => {
    const region = regions[0];

    const projId = urlMatch?.params.projectId;

    if (!projId) {
      return;
    }

    const { success } = await postTaskingRequest({
      params: {
        projectId: projId,
      },
      body: {
        type: 'AUTOMATED',
        region_name: region.name,
        region: region.data as GeoJSON.Feature<GeoJSON.Polygon>,
        parameters: {},
        notes: requestNotes,
        constraints: [
          {
            type: 'SZA',
            max: SZAConstraints.Max,
            min: SZAConstraints.Min,
          },
          {
            type: 'ROLL_ANGLE',
            max: rollAngleConstraint.max,
            min: rollAngleConstraint.min,
          },
          {
            type: 'ACQUISITION_DATE',
            min: moment(dateFrom).unix(),
            max: moment(dateTo).unix(),
          },
        ],
        instruments: (addSatellite ?? []).map((i) => {
          return {
            mission_id: i.satellite.mission_id,
            sensor_id: i.sensor.sensorId,
          };
        }),
      },
    });

    return success;
  }, [
    SZAConstraints.Max,
    SZAConstraints.Min,
    addSatellite,
    dateFrom,
    dateTo,
    regions,
    requestNotes,
    rollAngleConstraint.max,
    rollAngleConstraint.min,
    urlMatch?.params.projectId,
  ]);

  /**
   * calls the API to search for opportunities
   */
  const searchOpportunities = useCallback(async (): Promise<boolean> => {
    const invalidRequestMessage = () => {
      let errorMessage = '';
      if (hasSZAError || hasRollAngleError) {
        errorMessage = 'invalid constraints';
      } else if (!dateFrom && !dateTo) {
        errorMessage = 'please select capture date.';
      } else if (!addSatellite || addSatellite.length === 0) {
        errorMessage = 'Please select satellites to search for opportunities';
      } else if (regions.length === 0) {
        errorMessage = 'please draw area of interest.';
      }
      return errorMessage;
    };

    const errorMessage = invalidRequestMessage();
    if (errorMessage !== '') {
      toaster.show({
        icon: 'warning-sign',
        intent: 'warning',
        message: errorMessage,
      });

      return false;
    }

    if (!addSatellite || !dateFrom || !dateTo) return false;

    setFetching(true);

    // TODO: this constraint breaks the MSD - restore sending it to the MSD
    // when the MSD can utilise it, or rework this if we move filtering to FE
    const neverOZA = false;
    if (!projectId) {
      return false;
    }

    const data = await searchTaskingOpportunities({
      instruments: addSatellite,
      regions,
      dateFrom,
      dateTo,
      szaConstraint: SZAConstraints,
      ozaConstraint: OZAConstraints,
      isOZA: neverOZA,
      isSZA,
      isRollAngle,
      rollAngleConstraint: rollAngleConstraint,
      projectId: projectId,
    });

    setFetching(false);

    const newOpportunityLayers = data
      .sort(
        (a, b) =>
          moment(a.Start).toDate().getTime() -
          moment(b.Start).toDate().getTime()
      )
      .map((op, i) => {
        op = { ...op, OpportunityIndex: i + 1 };
        return new SwathLayer(
          LayerSourceType.TASKING_OPPORTUNITIES,
          'Opportunity ' + (i + 1),
          op.FieldOfRegard.Footprint.Geojson,
          op,
          {
            color: op.Kind === 'internal' ? '#E4695E' : '#FFCC55',
          }
        );
      });

    setOpportunityLayers(newOpportunityLayers);
    setConfirmedSwaths([]);

    layers.forEach((layer) => {
      if (layer.sourceType === LayerSourceType.TASKING_OPPORTUNITIES) {
        removeLayer(layer.id);
      }
    });

    const fieldOfRegardLayers = extractFieldsOfRegard(newOpportunityLayers).map(
      (fieldOfRegard, i) => {
        fieldOfRegard.metadata.OpportunityIndex = i + 1;
        fieldOfRegard.name = `Opportunity ${i + 1}`;
        return new FieldOfRegardLayer(
          LayerSourceType.TASKING_OPPORTUNITIES,
          fieldOfRegard.name,
          fieldOfRegard.data,
          fieldOfRegard.metadata,
          fieldOfRegard.options
        );
      }
    );
    const swathLayers = extractSwaths(newOpportunityLayers);
    // Pair initial swaths to their fields of regard
    const pairs = pairSwathToFieldOfRegard(fieldOfRegardLayers, swathLayers);

    // Add initial swath layers if there's no field of regard available
    const newLayers = [
      ...pairs
        .filter((pair) => !pair.fieldOfRegard && pair.swath)
        .map((pair, i) => {
          const swath = pair.swath!;
          if (fieldOfRegardLayers.length > 0) {
            const lastFoRIndex =
              fieldOfRegardLayers[fieldOfRegardLayers.length - 1].metadata
                .OpportunityIndex;
            if (lastFoRIndex !== undefined) {
              swath.metadata.OpportunityIndex = lastFoRIndex + i + 1;
              swath.name = `Opportunity ${lastFoRIndex + i + 1}`;
            }
          } else {
            swath.metadata.OpportunityIndex = i + 1;
            swath.name = `Opportunity ${i + 1}`;
          }
          return new FieldOfRegardLayer(
            swath.sourceType,
            swath.name,
            swath.data,
            swath.metadata,
            swath.options
          );
        }),
      // Add initial field of regard layers to map
      ...fieldOfRegardLayers,
    ];

    setOpportunities(
      (
        newLayers.filter(
          ({ sourceType }) =>
            sourceType === LayerSourceType.TASKING_OPPORTUNITIES
        ) as OpportunityLayer<CommonOpportunity>[]
      ).sort((a, b) => {
        return (
          (a.metadata.OpportunityIndex ?? 0) -
          (b.metadata.OpportunityIndex ?? 0)
        );
      })
    );

    setLayers((prev) => [...newLayers, ...prev]);
    return true;
  }, [
    OZAConstraints,
    SZAConstraints,
    addSatellite,
    dateFrom,
    dateTo,
    hasRollAngleError,
    hasSZAError,
    isRollAngle,
    isSZA,
    layers,
    regions,
    removeLayer,
    rollAngleConstraint,
    setLayers,
    projectId,
  ]);

  return {
    searchOpportunities,
    dateTo,
    setDateTo,
    dateFrom,
    setDateFrom,
    addSatellite,
    setAddSatellite,
    SZAConstraints,
    setSZAConstraints,
    OZAConstraints,
    setOZAConstraints,
    isOZA,
    isSZA,
    setIsOZA,
    setIsSZA,
    isOZAObjective,
    setIsOZAObjective,
    isSZAObjective,
    setIsSZAObjective,
    clearTaskingDetail,
    setSZAError,
    setRollAngleError,
    hasSZAError,
    hasRollAngleError,
    isFetching,
    opportunityLayers,
    modifySwath,
    handleSwathConfirm,
    confirmedSwaths,
    saveMapLayerState,
    loadMapLayerState,
    requestActivities,
    isRollAngle,
    setIsRollAngle,
    rollAngleConstraint,
    setRollAngleConstraint,
    regions,
    opportunities,
    requestNotes,
    setRequestNotes,
    submitAutomatedTaskingRequest,
  };
};

export const TaskingProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  return (
    <TaskingContext.Provider value={useTaskingProvider()}>
      {children}
    </TaskingContext.Provider>
  );
};
