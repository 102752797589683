import type {
  ITourInformation,
  tourTypes,
} from '../../constants/tour/actionTypes';

export function setTourInformation(
  type: string,
  tourInformation: Partial<ITourInformation>
): tourTypes {
  return {
    type: type,
    payload: tourInformation,
  };
}
