import { useQuery } from 'api/useQuery';
import { getSatellitesForTasking } from 'api/tasking/service';

export const useTaskingPermissions = () => {
  const { data: taskingSatellites } = useQuery(getSatellitesForTasking, {
    initialData: undefined,
    params: undefined,
  });

  return {
    taskingSatellites,
  };
};
