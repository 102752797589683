import type { ChangeEvent } from 'react';
import { useEffect, useState } from 'react';
import {
  Classes,
  Icon,
  MenuItem,
  Checkbox,
  AnchorButton,
} from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import type { ItemRenderer } from '@blueprintjs/select';
import { MultiSelect } from '@blueprintjs/select';
import { xor } from 'lodash';
import type { DateRange } from '@blueprintjs/datetime';
import {
  DateRangeInput,
  TimePicker,
  TimePrecision,
} from '@blueprintjs/datetime';

import moment from 'moment';

import { toFormattedDateNoSeconds } from 'utils/common/dateUtils';
import { useMap } from 'datacosmos/stores/MapProvider';

import s from './filters.module.scss';

import { IMAGE_BAND } from 'datacosmos/types/stac-types';

import UploadRegion from './UploadRegion';
import DrawAreaOfInterest from './DrawAreaOfInterest';
import { LayerSourceType } from 'datacosmos/entities/layer';
import { useMapLayers } from 'datacosmos/stores/MapLayersProvider';
import { useFilters } from '../stores/FiltersProvider';
import type { Satellite } from 'api/satellites/types';
import { SATELLITES_FOR_PRODUCTION } from 'api/satellites/constants';
import RangeSlider from '_molecules/RangeSlider/RangeSlider';
import DetailedTooltip from '_molecules/DetailedTooltip/DetailedTooltip';
import { useLocalisation } from 'utils/hooks/useLocalisation';
import SearchByPoint from './SearchByPoint';
import SecondaryButton from '_molecules/Button/SecondaryButton';
import MapCoveringModal from '_organisms/MapCoveringModal/MapCoveringModal';
import PrimaryButton from '_molecules/Button/PrimaryButton';
import SearchBySpecificRegion from './SearchBySpecificRegion';
import {
  HOURS_OF_THE_DAY_FILTER_ENABLE,
  SENSOR_TYPE_FILTER_ENABLE,
  PLATFORM_TYPE_FILTER_ENABLE,
} from 'env';
import DangerButton from '_molecules/Button/DangerButton';
import { useTheme } from 'datacosmos/stores/ThemeProvider';
import Fuse from 'fuse.js';
import { useHistory } from 'react-router';
import bboxPolygon from '@turf/bbox-polygon';
import type { IconName } from '_atoms/Icon/Icon';

const RESOLUTION = ['limited', 'full'];

const ASSET_TYPE = ['Raster', 'Vector'];

const SOURCE = ['Satellite Imagery', 'Algorithm'];

const SEASONS = ['Summer', 'Winter', 'Autumn', 'Spring', 'Rainy', 'Dry'];

const PROCESSING_LEVEL = ['L0', 'L1A', 'L1B', 'L1C', 'L1D', 'L2A'];

const PRODUCT_TYPE = ['Satellite', 'Vector', 'Insight'];

const SENSOR_TYPE = ['Multispectral', 'Hyperspectral', 'Radar'];
const PLATOFRM_TYPE = ['Satellite', 'UAV'];

const SatelliteMultiSelect = MultiSelect.ofType<Satellite>();

interface Props {
  isViewingSingleItem: boolean;
}

const Filters = ({ isViewingSingleItem }: Props) => {
  const {
    satelliteFilters,
    setSatelliteFilters,
    dateRangeFilter,
    setDateRangeFilter,
    hoursRangeFilter,
    setHoursRangeFilter,
    areFiltersApplied,
    clearAllFilters,
    SZAFilter,
    setSZAFilter,
    OZAFilter,
    setOZAFilter,
    GSDFilter,
    setGSDFilter,
    cloudCoverageFilter,
    setCloudCoverageFilter,
    imageBandFilter,
    processingLevelFilter,
    productTypeFilter,
    setImageBandFilter,
    setProcessingLevelFilter,
    setProductTypeFilter,
    setAssetTypeFilter,
    assetTypeFilter,
    sourceFilter,
    setSourceFilter,
    seasonsFilter,
    setSeasonsFilter,
    sunGlintFilter,
    setSunGlintFilter,
    setAreaOfInterest,
    getNumberOfActiveFilters,
    areaOfInterest,
    resolutionFilter,
    setResolutionFilter,
    sensorFilter,
    setSensorFilter,
    platformTypeFilter,
    setPlatformTypeFilter,
  } = useFilters();

  const isSzaFilterActive =
    SZAFilter.min !== undefined || SZAFilter.max !== undefined;
  const isOzaFilterActive =
    OZAFilter.min !== undefined || OZAFilter.max !== undefined;
  const isCloudCoverageFilterActive = cloudCoverageFilter !== undefined;

  const isSunGlintFilterActive =
    sunGlintFilter.max !== undefined || sunGlintFilter.min !== undefined;

  const { removeLayersBySourceType } = useMapLayers();

  const { getMapBounds, currentCenter, mapContainerRef } = useMap();

  const [isCheckedCurrentView, setIsCheckedCurrentView] = useState(false);

  const { translate } = useLocalisation();
  const { isDarkmode } = useTheme();
  const history = useHistory();

  useEffect(() => {
    if (isCheckedCurrentView) {
      const bboxValue = getMapBounds();
      if (bboxValue) {
        setAreaOfInterest([bboxPolygon(bboxValue).geometry]);
      }
    }
  }, [getMapBounds, isCheckedCurrentView, setAreaOfInterest, currentCenter]);

  useEffect(() => {
    if (!areaOfInterest?.length) {
      setIsCheckedCurrentView(false);
      removeLayersBySourceType(LayerSourceType.AREA_OF_INTEREST);
    }
  }, [areaOfInterest, removeLayersBySourceType]);
  const renderItem: ItemRenderer<string> = (
    item,
    { handleClick, modifiers }
  ) => {
    const selected = satelliteFilters.includes(item);

    return (
      <MenuItem
        key={item}
        text={item}
        onClick={handleClick}
        icon={selected ? IconNames.TICK : IconNames.BLANK}
        active={modifiers.active}
      />
    );
  };

  const renderSatellite: ItemRenderer<Satellite> = (
    item,
    { handleClick, modifiers }
  ) => {
    const selected = satelliteFilters.includes(item.COSPAR);

    return (
      <MenuItem
        key={item.COSPAR}
        text={item.uiName}
        onClick={handleClick}
        icon={selected ? IconNames.TICK : IconNames.BLANK}
        active={modifiers.active}
      />
    );
  };

  const productTypeRenderItem: ItemRenderer<string> = (
    item,
    { handleClick, modifiers }
  ) => {
    const selected = productTypeFilter.includes(item);

    return (
      <MenuItem
        key={item}
        text={item}
        onClick={handleClick}
        icon={selected ? IconNames.TICK : IconNames.BLANK}
        active={modifiers.active}
      />
    );
  };

  const imageBandRenderItem: ItemRenderer<string> = (
    item,
    { handleClick, modifiers }
  ) => {
    const selected = imageBandFilter.includes(item);

    return (
      <MenuItem
        key={item}
        text={item}
        onClick={handleClick}
        icon={selected ? IconNames.TICK : IconNames.BLANK}
        active={modifiers.active}
      />
    );
  };

  const processingLevelRenderItem: ItemRenderer<string> = (
    item,
    { handleClick, modifiers }
  ) => {
    const selected = processingLevelFilter.includes(item);

    return (
      <MenuItem
        key={item}
        text={item}
        onClick={handleClick}
        icon={selected ? IconNames.TICK : IconNames.BLANK}
        active={modifiers.active}
      />
    );
  };

  const handleSatelliteToggle = (item: Satellite) =>
    setSatelliteFilters((previousFilters: string[]) =>
      xor(previousFilters, [item.COSPAR])
    );

  const handleProcessingLevelToggle = (item: string | undefined) => {
    if (item === undefined) {
      return;
    }
    setProcessingLevelFilter((previousFilters: string[]) =>
      xor(previousFilters, [item])
    );
  };

  const handleImageBandToggle = (item: string | undefined) => {
    if (item === undefined) {
      return;
    }
    setImageBandFilter((previousFilters: string[]) =>
      xor(previousFilters, [item])
    );
  };

  const handleProductTypeToggle = (item: string | undefined) => {
    if (item === undefined) {
      return;
    }
    setProductTypeFilter((previousFilters: string[]) =>
      xor(previousFilters, [item])
    );
  };

  const handleOnDateRangeChange = (value: DateRange) => {
    setDateRangeFilter(value);
  };

  const handleOnHoursRangeChange = (value: Date, type: string) => {
    type === 'lte' &&
      setHoursRangeFilter({
        ...hoursRangeFilter,
        lte: value,
      });
    type === 'gte' &&
      setHoursRangeFilter({
        ...hoursRangeFilter,
        gte: value,
      });
  };

  const handleAssetTypeToggle = (item: string | undefined) => {
    if (item === undefined) {
      return;
    }
    setAssetTypeFilter((previousFilters: string[]) =>
      xor(previousFilters, [item])
    );
  };

  const handleSourceToggle = (item: string | undefined) => {
    if (item === undefined) {
      return;
    }
    setSourceFilter((previousFilters: string[]) =>
      xor(previousFilters, [item])
    );
  };

  const handleSeasonsToggle = (item: string | undefined) => {
    if (item === undefined) {
      return;
    }
    setSeasonsFilter((previousFilters: string[]) =>
      xor(previousFilters, [item])
    );
  };

  const handleResolutionToggle = (item: string | undefined) => {
    if (item === undefined) {
      return;
    }
    setResolutionFilter((previousFilters: string[]) =>
      xor(previousFilters, [item])
    );
  };

  const handleSensorTypeToggle = (item: string | undefined) => {
    if (item === undefined) {
      return;
    }
    setSensorFilter((previousFilters: string[]) =>
      xor(previousFilters, [item])
    );
  };
  const handlePlatformTypeToggle = (item: string | undefined) => {
    if (item === undefined) {
      return;
    }
    setPlatformTypeFilter((previousFilters: string[]) =>
      xor(previousFilters, [item])
    );
  };

  const handleSZAChange = (values: number[]) => {
    setSZAFilter({ ...SZAFilter, min: values[0], max: values[1] });
  };

  const handleOZAChange = (values: number[]) => {
    setOZAFilter({ ...OZAFilter, min: values[0], max: values[1] });
  };

  const options: Fuse.FuseOptions<Satellite> = {
    shouldSort: true,
    tokenize: true,
    matchAllTokens: true,
    keys: ['uiName'],
  };

  const filterSatellites = (query: string, items: Satellite[]) => {
    if (query) {
      const fuse = new Fuse(items, options);
      const searchResults = fuse.search(query) as Satellite[];
      return searchResults;
    }
    return SATELLITES_FOR_PRODUCTION;
  };

  const handleGSDChange = (
    event: ChangeEvent<HTMLInputElement>,
    range: string
  ) => {
    let { value } = event.target;
    const { min, max } = event.target;
    value === ''
      ? (value = '')
      : (value = String(
          Math.max(Number(min), Math.min(Number(max), Number(value)))
        ));
    range === 'min' && setGSDFilter({ ...GSDFilter, min: Number(value) });
    range === 'max' && setGSDFilter({ ...GSDFilter, max: Number(value) });
  };
  const handleSunGlintChange = (values: number[]) => {
    setSunGlintFilter({ ...sunGlintFilter, min: values[0], max: values[1] });
  };

  const noOfActiveFilters = getNumberOfActiveFilters();

  const getFilterIconAndText = (isExpanded: boolean) => {
    const icon: IconName = isExpanded ? 'Collapse' : 'Filter';

    const btnText = isExpanded
      ? translate('datacosmos.buttons.closeFilters')
      : translate('datacosmos.buttons.filters');
    return {
      icon: icon,
      btnText: btnText,
    };
  };

  return (
    <div className="z-20 w-full">
      <MapCoveringModal
        mapContainerWidthPx={mapContainerRef.current?.clientWidth ?? 0}
        getModalTrigger={(triggerRef, triggerProps, toggleModal) => (
          <div className="relative w-full">
            <SecondaryButton
              icon={
                isViewingSingleItem
                  ? 'ChevronLeft'
                  : getFilterIconAndText(
                      triggerProps['aria-expanded'] as boolean
                    ).icon
              }
              text={
                isViewingSingleItem
                  ? translate('datacosmos.buttons.backToSearch')
                  : getFilterIconAndText(
                      triggerProps['aria-expanded'] as boolean
                    ).btnText
              }
              data-testid="datacosmos-search-results-filter-toggle-button"
              {...triggerProps}
              onPress={() => {
                if (!isViewingSingleItem) {
                  toggleModal();
                  return;
                }
                history.replace({
                  pathname: history.location.pathname,
                  search: undefined,
                });
              }}
              ref={triggerRef}
              fill={true}
            />

            {noOfActiveFilters !== 0 && (
              <span
                style={{
                  position: 'absolute',
                  borderRadius: '50%',
                  height: '19px',
                  border: '1px solid rgba(115, 134, 148, 0.3)',
                  width: '19px',
                  textAlign: 'center',
                  top: -11,
                  right: -10,
                  fontSize: '13px',
                  cursor: 'pointer',
                }}
                className="color-item"
                onClick={toggleModal}
              >
                {noOfActiveFilters}
              </span>
            )}
          </div>
        )}
      >
        {(toggleModal) => (
          <div className="h-full w-full flex flex-col gap-4">
            <div
              className={
                'dark:bg-surface-dark dark:text-item-dark-contrast bg-surface text-item-contrast gap-2 !shadow-none md:flex md:flex-col lg:grid lg:grid-cols-3'
              }
            >
              <div className="dark:text-item-dark-contrast flex flex-col gap-1">
                <h5
                  className={
                    Classes.HEADING +
                    ' ' +
                    s.filterHeading +
                    ' dark:text-item-dark-contrast'
                  }
                >
                  <Icon icon={IconNames.SHAPES} />{' '}
                  <span>{translate('datacosmos.filters.geometry')}</span>
                </h5>

                <DetailedTooltip
                  content={translate(
                    'datacosmos.filters.detailedTooltips.currentViewArea.content'
                  )}
                  description={translate(
                    'datacosmos.filters.detailedTooltips.currentViewArea.description'
                  )}
                  delay={0}
                >
                  <Checkbox
                    large={true}
                    checked={isCheckedCurrentView}
                    label={translate('datacosmos.filters.currentViewArea')}
                    onChange={() => {
                      if (!isCheckedCurrentView) {
                        toggleModal();
                      }
                      setIsCheckedCurrentView(!isCheckedCurrentView);
                    }}
                  />
                </DetailedTooltip>

                <UploadRegion
                  aoiSourceType={LayerSourceType.AREA_OF_INTEREST}
                  areaOfInterest={areaOfInterest}
                  setAreaOfInterest={setAreaOfInterest}
                  buttonTitle={translate('datacosmos.filters.uploadAoi')}
                  onPressExtraAction={toggleModal}
                  multipleAois={true}
                  disableUploadValidation={true}
                />
                <DrawAreaOfInterest
                  aoiSourceType={LayerSourceType.AREA_OF_INTEREST}
                  setAreasOfInterest={setAreaOfInterest}
                  onPressExtraAction={toggleModal}
                />

                <SearchByPoint setAreaOfInterest={setAreaOfInterest} />
                <SearchBySpecificRegion setAreaOfInterest={setAreaOfInterest} />

                <DangerButton
                  text={translate('datacosmos.filters.clearAoi')}
                  icon="Trash"
                  size={24}
                  className="!bg-warning h-8 w-full text-start border-2 border-item"
                  onPress={() => {
                    if (areaOfInterest) {
                      removeLayersBySourceType(
                        LayerSourceType.AREA_OF_INTEREST
                      );
                      setAreaOfInterest(undefined);
                    }
                  }}
                />
              </div>

              {/* Satellite related filters (date, gsd, sza...) */}
              <div className="flex flex-col gap-4">
                <div>
                  <h5
                    className={
                      Classes.HEADING +
                      ' ' +
                      s.filterHeading +
                      ' dark:text-item-dark-contrast'
                    }
                  >
                    <DetailedTooltip
                      content={translate(
                        'datacosmos.filters.detailedTooltips.satellite.content'
                      )}
                      description={translate(
                        'datacosmos.filters.detailedTooltips.satellite.description'
                      )}
                      delay={0}
                      placement="bottom"
                    >
                      <Icon icon={IconNames.SATELLITE} />{' '}
                      <span>{translate('datacosmos.filters.satellite')}</span>
                    </DetailedTooltip>
                  </h5>
                  <SatelliteMultiSelect
                    className={[Classes.FILL].join(' ')}
                    items={SATELLITES_FOR_PRODUCTION}
                    tagRenderer={(tag) => tag.uiName}
                    itemRenderer={renderSatellite}
                    onItemSelect={handleSatelliteToggle}
                    itemListPredicate={filterSatellites}
                    resetOnSelect
                    selectedItems={SATELLITES_FOR_PRODUCTION.filter((sfp) =>
                      satelliteFilters.some((sf) => sf === sfp.COSPAR)
                    )}
                    fill={true}
                    tagInputProps={{
                      onRemove: (sat) => {
                        const toRemove = SATELLITES_FOR_PRODUCTION.find(
                          (sfp) => sfp.uiName === sat
                        );
                        if (toRemove !== undefined) {
                          handleSatelliteToggle(toRemove);
                        }
                      },
                    }}
                    placeholder={translate('datacosmos.filters.placeholder')}
                  />
                </div>

                <div className="filter-datepicker">
                  <h5
                    className={
                      Classes.HEADING +
                      ' ' +
                      s.filterHeading +
                      ' dark:text-item-dark-contrast'
                    }
                  >
                    <DetailedTooltip
                      content={translate(
                        'datacosmos.filters.detailedTooltips.dates.content'
                      )}
                      description={translate(
                        'datacosmos.filters.detailedTooltips.dates.description'
                      )}
                      delay={0}
                    >
                      <Icon icon={IconNames.CALENDAR} />
                      <span>{translate('datacosmos.filters.dates')}</span>
                    </DetailedTooltip>
                  </h5>
                  <div className={s.dateRangeContainer}>
                    <DateRangeInput
                      formatDate={toFormattedDateNoSeconds}
                      parseDate={(str) => moment.utc(str).toDate()}
                      timePrecision="minute"
                      minDate={moment.utc().subtract(10, 'year').toDate()}
                      maxDate={moment().toDate()}
                      popoverProps={{
                        modifiers: {
                          arrow: { enabled: false },
                        },
                      }}
                      className="dateRangeContainer"
                      onChange={handleOnDateRangeChange}
                      value={dateRangeFilter}
                      startInputProps={{
                        placeholder: translate('datacosmos.filters.startDate'),
                      }}
                      endInputProps={{
                        placeholder: translate('datacosmos.filters.endDate'),
                      }}
                    />
                  </div>
                </div>

                <div className="flex-col w-full">
                  <div
                    className={`flex items-center w-full cursor-pointer hover:bg-item-selected dark:hover:bg-neutral-800`}
                    onClick={() => {
                      if (isSzaFilterActive) {
                        setSZAFilter({
                          min: undefined,
                          max: undefined,
                        });
                      } else {
                        setSZAFilter({
                          min: 0,
                          max: 0,
                        });
                      }
                    }}
                  >
                    <div className="flex items-center w-full gap-4">
                      <DetailedTooltip
                        content={translate(
                          'datacosmos.filters.detailedTooltips.sza.content'
                        )}
                        description={translate(
                          'datacosmos.filters.detailedTooltips.sza.description'
                        )}
                        delay={0}
                      >
                        <Icon icon={IconNames.FLASH} />
                        <span>{translate('datacosmos.filters.sza')}</span>
                      </DetailedTooltip>
                    </div>
                    <AnchorButton
                      icon={
                        isSzaFilterActive ? (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-5 w-5"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke={isDarkmode ? 'white' : 'currentColor'}
                            strokeWidth={2}
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                            />
                          </svg>
                        ) : (
                          IconNames.ADD
                        )
                      }
                      minimal
                      small
                      data-testid="datacosmos-item-search-filters-sza-add-remove"
                    />
                  </div>
                  {isSzaFilterActive && (
                    <RangeSlider
                      minValue={0}
                      maxValue={180}
                      numberOfHandles={2}
                      onChange={(values) => {
                        handleSZAChange(values);
                      }}
                      showValuesAboveHandles
                      step={0.1}
                      value={[SZAFilter.min ?? 0, SZAFilter.max ?? 180]}
                      data-testid="datacosmos-item-search-filters-sza-slider"
                    />
                  )}
                </div>

                <div className="flex-col w-full">
                  <div
                    className={`flex items-center w-full cursor-pointer hover:bg-item-selected dark:hover:bg-neutral-800`}
                    onClick={() => {
                      if (isOzaFilterActive) {
                        setOZAFilter({
                          min: undefined,
                          max: undefined,
                        });
                      } else {
                        setOZAFilter({
                          min: 0,
                          max: 0,
                        });
                      }
                    }}
                  >
                    <div className="flex items-center w-full gap-4">
                      <DetailedTooltip
                        content={translate(
                          'datacosmos.filters.detailedTooltips.oza.content'
                        )}
                        description={translate(
                          'datacosmos.filters.detailedTooltips.oza.description'
                        )}
                        delay={0}
                      >
                        <Icon icon={IconNames.FLASH} />
                        <span>{translate('datacosmos.filters.oza')}</span>
                      </DetailedTooltip>
                    </div>
                    <AnchorButton
                      icon={
                        isOzaFilterActive ? (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-5 w-5"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke={isDarkmode ? 'white' : 'currentColor'}
                            strokeWidth={2}
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                            />
                          </svg>
                        ) : (
                          IconNames.ADD
                        )
                      }
                      minimal
                      small
                      data-testid="datacosmos-item-search-filters-oza-add-remove"
                    />
                  </div>
                  {isOzaFilterActive && (
                    <RangeSlider
                      minValue={0}
                      maxValue={180}
                      numberOfHandles={2}
                      onChange={(values) => {
                        handleOZAChange(values);
                      }}
                      showValuesAboveHandles
                      step={0.1}
                      value={[OZAFilter.min ?? 0, OZAFilter.max ?? 180]}
                    />
                  )}
                </div>

                <div className="flex-col w-full">
                  <div
                    className={`flex items-center w-full cursor-pointer hover:bg-item-selected dark:hover:bg-neutral-800`}
                    onClick={() => {
                      if (isCloudCoverageFilterActive) {
                        setCloudCoverageFilter(undefined);
                      } else {
                        setCloudCoverageFilter(0);
                      }
                    }}
                  >
                    <div className="flex items-center w-full gap-4">
                      <DetailedTooltip
                        content={translate(
                          'datacosmos.filters.detailedTooltips.cloudCoverage.content'
                        )}
                        description={translate(
                          'datacosmos.filters.detailedTooltips.cloudCoverage.description'
                        )}
                        delay={0}
                      >
                        <Icon icon={IconNames.CLOUD} />
                        <span>
                          {translate('datacosmos.filters.cloudCoverage')}
                        </span>
                      </DetailedTooltip>
                    </div>
                    <AnchorButton
                      icon={
                        isCloudCoverageFilterActive ? (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-5 w-5"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke={isDarkmode ? 'white' : 'currentColor'}
                            strokeWidth={2}
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                            />
                          </svg>
                        ) : (
                          IconNames.ADD
                        )
                      }
                      minimal
                      small
                      data-testid="datacosmos-item-search-filters-cloud-cover-add-remove"
                    />
                  </div>
                  {isCloudCoverageFilterActive && (
                    <RangeSlider
                      minValue={0}
                      maxValue={100}
                      numberOfHandles={1}
                      onChange={(values) => {
                        setCloudCoverageFilter(values[0]);
                      }}
                      showValuesAboveHandles
                      step={1}
                      value={[cloudCoverageFilter]}
                    />
                  )}
                </div>

                {/* Sun Glint */}

                <div className="flex-col w-full">
                  <div
                    className={`flex items-center w-full cursor-pointer hover:bg-item-selected dark:hover:bg-neutral-800`}
                    onClick={() => {
                      if (isSunGlintFilterActive) {
                        setSunGlintFilter({
                          min: undefined,
                          max: undefined,
                        });
                      } else {
                        setSunGlintFilter({
                          min: 0,
                          max: 0,
                        });
                      }
                    }}
                  >
                    <div className="flex items-center w-full gap-4">
                      <DetailedTooltip
                        content={translate(
                          'datacosmos.filters.detailedTooltips.sunGlint.content'
                        )}
                        description={translate(
                          'datacosmos.filters.detailedTooltips.sunGlint.description'
                        )}
                        delay={0}
                      >
                        <Icon icon={IconNames.CONTRAST} />
                        <span>{translate('datacosmos.filters.sunGlint')}</span>
                      </DetailedTooltip>
                    </div>
                    <AnchorButton
                      icon={
                        isSunGlintFilterActive ? (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-5 w-5"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke={isDarkmode ? 'white' : 'currentColor'}
                            strokeWidth={2}
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                            />
                          </svg>
                        ) : (
                          IconNames.ADD
                        )
                      }
                      minimal
                      small
                      data-testid="datacosmos-item-search-filters-sun-glint-add-remove"
                    />
                  </div>
                  {isSunGlintFilterActive && (
                    <RangeSlider
                      minValue={0}
                      maxValue={180}
                      numberOfHandles={2}
                      onChange={(values) => {
                        handleSunGlintChange(values);
                      }}
                      showValuesAboveHandles
                      step={0.1}
                      value={[
                        sunGlintFilter.min ?? 0,
                        sunGlintFilter.max ?? 180,
                      ]}
                    />
                  )}
                </div>

                {/* Min Max GSD */}
                <div className="filter-datepicker">
                  <h5
                    className={
                      Classes.HEADING +
                      '  ' +
                      s.filterHeading +
                      ' dark:text-item-dark-contrast'
                    }
                  >
                    <DetailedTooltip
                      delay={0}
                      content={translate(
                        'datacosmos.filters.detailedTooltips.gsd.content'
                      )}
                      description={translate(
                        'datacosmos.filters.detailedTooltips.gsd.description'
                      )}
                    >
                      <Icon icon={IconNames.ZOOM_TO_FIT} />
                      <span>{translate('datacosmos.filters.gsd')}</span>
                    </DetailedTooltip>
                  </h5>

                  <div className={Classes.CONTROL_GROUP}>
                    <div
                      className={Classes.INPUT_GROUP + '  ' + s.gsdInputFields}
                    >
                      <input
                        type="number"
                        className={Classes.INPUT}
                        placeholder={translate('datacosmos.filters.min')}
                        value={GSDFilter.min}
                        min={0}
                        max={100}
                        onChange={(e) => handleGSDChange(e, 'min')}
                      />
                      <br />
                    </div>
                    <div
                      className={Classes.INPUT_GROUP + '  ' + s.gsdInputFields}
                    >
                      <input
                        type="number"
                        placeholder={translate('datacosmos.filters.min')}
                        className={Classes.INPUT}
                        value={GSDFilter.max}
                        min={0}
                        max={100}
                        onChange={(e) => handleGSDChange(e, 'max')}
                      />
                    </div>
                  </div>
                </div>

                {HOURS_OF_THE_DAY_FILTER_ENABLE && (
                  <div className="hours-of-the-day-picker">
                    <h5
                      className={
                        Classes.HEADING +
                        ' ' +
                        s.filterHeading +
                        ' dark:text-item-dark-contrast'
                      }
                    >
                      <DetailedTooltip
                        content={translate(
                          'datacosmos.filters.detailedTooltips.hoursOfDay.content'
                        )}
                        description={translate(
                          'datacosmos.filters.detailedTooltips.hoursOfDay.description'
                        )}
                        delay={0}
                      >
                        <Icon icon={IconNames.TIME} />
                        <span>{translate('datacosmos.filters.hours')}</span>
                      </DetailedTooltip>
                    </h5>

                    <div className="flex justify-between w-full gap-2">
                      <div className="flex items-center gap-2">
                        <h6 className="m-0">
                          {translate('datacosmos.filters.fromHours')}
                        </h6>
                        <TimePicker
                          autoFocus={false}
                          precision={TimePrecision.MINUTE}
                          useAmPm={false}
                          showArrowButtons={false}
                          value={hoursRangeFilter.gte}
                          onChange={(value: Date) =>
                            handleOnHoursRangeChange(value, 'gte')
                          }
                          className="hoursOfTheDayInput"
                        />
                      </div>

                      <div className="flex items-center gap-2">
                        <h6 className="m-0">
                          {translate('datacosmos.filters.toHours')}
                        </h6>
                        <TimePicker
                          autoFocus={false}
                          precision={TimePrecision.MINUTE}
                          useAmPm={false}
                          showArrowButtons={false}
                          value={
                            hoursRangeFilter.lte
                              ? hoursRangeFilter.lte
                              : new Date(new Date().setHours(23, 59, 0, 0))
                          }
                          onChange={(value: Date) =>
                            handleOnHoursRangeChange(value, 'lte')
                          }
                          className="hoursOfTheDayInput"
                        />
                      </div>
                    </div>
                  </div>
                )}

                {SENSOR_TYPE_FILTER_ENABLE && (
                  <div>
                    <h5
                      className={
                        Classes.HEADING +
                        ' ' +
                        s.filterHeading +
                        ' dark:text-item-dark-contrast'
                      }
                    >
                      <DetailedTooltip
                        content={translate(
                          'datacosmos.filters.detailedTooltips.sensorType.content'
                        )}
                        description={translate(
                          'datacosmos.filters.detailedTooltips.sensorType.description'
                        )}
                        delay={0}
                      >
                        <Icon icon={IconNames.ANTENNA} />{' '}
                        <span>
                          {translate('datacosmos.filters.sensorType')}
                        </span>
                      </DetailedTooltip>
                    </h5>
                    <MultiSelect
                      placeholder={translate('datacosmos.filters.placeholder')}
                      className={[Classes.FILL].join(' ')}
                      items={SENSOR_TYPE}
                      tagRenderer={(tag) => tag}
                      itemRenderer={renderItem}
                      onItemSelect={handleSensorTypeToggle}
                      selectedItems={sensorFilter}
                      fill={true}
                      tagInputProps={{
                        onRemove: (n, _) =>
                          handleSensorTypeToggle(n?.toString()),
                      }}
                    />
                  </div>
                )}
              </div>

              {/* Product type, image band, src, ...*/}
              <div className="flex flex-col gap-4">
                {/* Processing Level */}
                <div>
                  <h5
                    className={
                      Classes.HEADING +
                      ' ' +
                      s.filterHeading +
                      ' dark:text-item-dark-contrast'
                    }
                  >
                    <DetailedTooltip
                      content={translate(
                        'datacosmos.filters.detailedTooltips.processingLevel.content'
                      )}
                      description={translate(
                        'datacosmos.filters.detailedTooltips.processingLevel.description'
                      )}
                      delay={0}
                    >
                      <Icon icon={IconNames.LAYERS} />{' '}
                      <span>
                        {translate('datacosmos.filters.processingLevel')}
                      </span>
                    </DetailedTooltip>
                  </h5>
                  <MultiSelect
                    placeholder={translate('datacosmos.filters.placeholder')}
                    className={[Classes.FILL].join(' ')}
                    items={PROCESSING_LEVEL}
                    tagRenderer={(tag) => tag}
                    itemRenderer={processingLevelRenderItem}
                    onItemSelect={handleProcessingLevelToggle}
                    selectedItems={processingLevelFilter}
                    fill={true}
                    tagInputProps={{
                      onRemove: (n, _) =>
                        handleProcessingLevelToggle(n?.toString()),
                    }}
                  />
                </div>

                {/* Product Type */}
                <div>
                  <h5
                    className={
                      Classes.HEADING +
                      ' ' +
                      s.filterHeading +
                      ' dark:text-item-dark-contrast'
                    }
                  >
                    <DetailedTooltip
                      content={translate(
                        'datacosmos.filters.detailedTooltips.productType.content'
                      )}
                      description={translate(
                        'datacosmos.filters.detailedTooltips.productType.description'
                      )}
                      delay={0}
                    >
                      <Icon icon={IconNames.TAG} />{' '}
                      <span>{translate('datacosmos.filters.productType')}</span>
                    </DetailedTooltip>
                  </h5>
                  <MultiSelect
                    placeholder={translate('datacosmos.filters.placeholder')}
                    className={[Classes.FILL].join(' ')}
                    items={PRODUCT_TYPE}
                    tagRenderer={(tag) => tag}
                    itemRenderer={productTypeRenderItem}
                    onItemSelect={handleProductTypeToggle}
                    selectedItems={productTypeFilter}
                    fill={true}
                    tagInputProps={{
                      onRemove: (n, _) =>
                        handleProductTypeToggle(n?.toString()),
                      disabled: true,
                    }}
                  />
                </div>

                {/* Image Band */}
                <div>
                  <h5
                    className={
                      Classes.HEADING +
                      ' ' +
                      s.filterHeading +
                      ' dark:text-item-dark-contrast'
                    }
                  >
                    <DetailedTooltip
                      content={translate(
                        'datacosmos.filters.detailedTooltips.imageBand.content'
                      )}
                      description={translate(
                        'datacosmos.filters.detailedTooltips.imageBand.description'
                      )}
                      delay={0}
                    >
                      <Icon icon={IconNames.HELPER_MANAGEMENT} />{' '}
                      <span>{translate('datacosmos.filters.imageBand')}</span>
                    </DetailedTooltip>
                  </h5>
                  <MultiSelect
                    placeholder={translate('datacosmos.filters.placeholder')}
                    className={[Classes.FILL].join(' ')}
                    items={Object.keys(IMAGE_BAND)}
                    tagRenderer={(tag) => tag}
                    itemRenderer={imageBandRenderItem}
                    onItemSelect={handleImageBandToggle}
                    selectedItems={imageBandFilter}
                    fill={true}
                    tagInputProps={{
                      onRemove: (n, _) => handleImageBandToggle(n?.toString()),
                    }}
                  />
                </div>

                {/* Asset type */}
                <div>
                  <h5
                    className={
                      Classes.HEADING +
                      ' ' +
                      s.filterHeading +
                      ' dark:text-item-dark-contrast'
                    }
                  >
                    <DetailedTooltip
                      content={translate(
                        'datacosmos.filters.detailedTooltips.assetType.content'
                      )}
                      description={translate(
                        'datacosmos.filters.detailedTooltips.assetType.description'
                      )}
                      delay={0}
                    >
                      <Icon icon={IconNames.MEDIA} />{' '}
                      <span>{translate('datacosmos.filters.assetType')}</span>
                    </DetailedTooltip>
                  </h5>
                  <MultiSelect
                    placeholder={translate('datacosmos.filters.placeholder')}
                    className={[Classes.FILL].join(' ')}
                    items={ASSET_TYPE}
                    tagRenderer={(tag) => tag}
                    itemRenderer={renderItem}
                    onItemSelect={handleAssetTypeToggle}
                    selectedItems={assetTypeFilter}
                    fill={true}
                    tagInputProps={{
                      onRemove: (n, _) => handleAssetTypeToggle(n?.toString()),
                    }}
                  />
                </div>

                {/* Source */}
                <div>
                  <h5
                    className={
                      Classes.HEADING +
                      ' ' +
                      s.filterHeading +
                      ' dark:text-item-dark-contrast'
                    }
                  >
                    <DetailedTooltip
                      content={translate(
                        'datacosmos.filters.detailedTooltips.sources.content'
                      )}
                      description={translate(
                        'datacosmos.filters.detailedTooltips.sources.description'
                      )}
                      delay={0}
                    >
                      <Icon icon={IconNames.DIAGRAM_TREE} />{' '}
                      <span>{translate('datacosmos.filters.source')}</span>
                    </DetailedTooltip>
                  </h5>
                  <MultiSelect
                    placeholder={translate('datacosmos.filters.placeholder')}
                    className={[Classes.FILL].join(' ')}
                    items={SOURCE}
                    tagRenderer={(tag) => tag}
                    itemRenderer={renderItem}
                    onItemSelect={handleSourceToggle}
                    selectedItems={sourceFilter}
                    fill={true}
                    tagInputProps={{
                      onRemove: (n, _) => handleSourceToggle(n?.toString()),
                    }}
                  />
                </div>

                <div>
                  <h5
                    className={
                      Classes.HEADING +
                      ' ' +
                      s.filterHeading +
                      ' dark:text-item-dark-contrast'
                    }
                  >
                    <DetailedTooltip
                      content={translate(
                        'datacosmos.filters.detailedTooltips.resolution.content'
                      )}
                      description={translate(
                        'datacosmos.filters.detailedTooltips.resolution.description'
                      )}
                      delay={0}
                    >
                      <Icon icon={IconNames.MEDIA} />{' '}
                      <span>{translate('datacosmos.filters.resolution')}</span>
                    </DetailedTooltip>
                  </h5>
                  <MultiSelect
                    placeholder={translate('datacosmos.filters.placeholder')}
                    className={[Classes.FILL].join(' ')}
                    items={RESOLUTION}
                    tagRenderer={(tag) => tag}
                    itemRenderer={renderItem}
                    onItemSelect={handleResolutionToggle}
                    selectedItems={resolutionFilter}
                    fill={true}
                    tagInputProps={{
                      onRemove: (n, _) => handleResolutionToggle(n?.toString()),
                    }}
                  />
                </div>

                {PLATFORM_TYPE_FILTER_ENABLE && (
                  <div>
                    <h5
                      className={
                        Classes.HEADING +
                        ' ' +
                        s.filterHeading +
                        ' dark:text-item-dark-contrast'
                      }
                    >
                      <DetailedTooltip
                        content={translate(
                          'datacosmos.filters.detailedTooltips.platformType.content'
                        )}
                        description={translate(
                          'datacosmos.filters.detailedTooltips.platformType.description'
                        )}
                        delay={0}
                      >
                        <Icon icon={IconNames.Satellite} />{' '}
                        <span>
                          {translate('datacosmos.filters.platformType')}
                        </span>
                      </DetailedTooltip>
                    </h5>
                    <MultiSelect
                      placeholder={translate('datacosmos.filters.placeholder')}
                      className={[Classes.FILL].join(' ')}
                      items={PLATOFRM_TYPE}
                      tagRenderer={(tag) => tag}
                      itemRenderer={renderItem}
                      onItemSelect={handlePlatformTypeToggle}
                      selectedItems={platformTypeFilter}
                      fill={true}
                      tagInputProps={{
                        onRemove: (n, _) =>
                          handlePlatformTypeToggle(n?.toString()),
                      }}
                    />
                  </div>
                )}

                <div>
                  <h5
                    className={
                      Classes.HEADING +
                      ' ' +
                      s.filterHeading +
                      ' dark:text-item-dark-contrast'
                    }
                  >
                    <DetailedTooltip
                      content={translate(
                        'datacosmos.filters.detailedTooltips.seasons.content'
                      )}
                      description={translate(
                        'datacosmos.filters.detailedTooltips.seasons.description'
                      )}
                      delay={0}
                    >
                      <Icon icon={IconNames.SNOWFLAKE} />{' '}
                      <span>{translate('datacosmos.filters.seasons')}</span>
                    </DetailedTooltip>
                  </h5>
                  <MultiSelect
                    placeholder={translate('datacosmos.filters.placeholder')}
                    className={[Classes.FILL].join(' ')}
                    items={SEASONS}
                    tagRenderer={(tag) => tag}
                    itemRenderer={renderItem}
                    onItemSelect={handleSeasonsToggle}
                    selectedItems={seasonsFilter}
                    fill={true}
                    tagInputProps={{
                      onRemove: (n, _) => handleSeasonsToggle(n?.toString()),
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="flex items-end justify-center h-full">
              {areFiltersApplied && (
                <PrimaryButton
                  icon="Close"
                  text={translate('datacosmos.filters.clearAll')}
                  onPress={clearAllFilters}
                  className="w-full h-16 text-center"
                />
              )}
            </div>
          </div>
        )}
      </MapCoveringModal>
    </div>
  );
};

export default Filters;
