export const mapAsync = (fn: Function) => async (items: any[]) => {
  const resp = [];

  for (const item of items) {
    const res = await fn(item);

    if (res) resp.push(res);
  }

  return resp;
};
