import { useRef, useState } from 'react';
import { isNull } from 'lodash/fp';
import { Classes, Icon, Spinner } from '@blueprintjs/core';
import { Tooltip } from 'ui/Tooltip';
import { IconNames } from '@blueprintjs/icons';
import AdministrationRoleSelect from '../AdministrationRoleSelect';
import useUser from '../../../services/api/portal/administration/hook/useUser';
import { validateEmail } from '../../../utils/common/stringUtils';
import ErrorMessage from './ErrorMessage';
import s from './index.module.scss';
import type { IRole } from 'services/api/portal/administration/api/types';

interface IProps {
  onCancel: () => void;
  availableRoles: IRole[];
  assignRole?: (userId: string, roleIds: number[]) => void;
  currentId?: number;
}

const AdministrationUserSelect = (props: IProps) => {
  const inputRef = useRef(null);
  const { isFetching, user, getUsersByEmail } = useUser();
  const [isNotValidEmail, setNotValidEmail] = useState(false);

  const [value, setValue] = useState('');

  const handleSearching = () => {
    if (!validateEmail(value)) {
      setNotValidEmail(true);
      return;
    }

    setNotValidEmail(false);
    getUsersByEmail(value);
  };

  const renderIcon = user ? (
    <img className={s.userIcon} src={user.picture} alt="avatar" />
  ) : (
    <Icon icon={IconNames.USER} iconSize={30} />
  );

  const showError = isNotValidEmail || isNull(user);

  const notValidClass = showError ? Classes.INTENT_DANGER : '';

  const errorMessage = isNotValidEmail
    ? 'Email is incorrect!'
    : 'User does not exist';

  return (
    <div className={s.wrapper}>
      <div className={s.container}>
        <div className={s.userInfo}>
          {isFetching ? <Spinner size={30} /> : renderIcon}
          <Tooltip
            disabled={!user}
            isOpen={Boolean(user)}
            content={user ? user.name : ''}
          >
            <input
              ref={inputRef}
              className={[Classes.INPUT, notValidClass].join(' ')}
              placeholder="example@domain.com"
              value={value}
              onChange={(e) => setValue(e.target.value)}
              onBlur={handleSearching}
              onKeyDown={(e) => e.keyCode === 13 && handleSearching()}
            />
          </Tooltip>
        </div>
        {user && props.assignRole && (
          <AdministrationRoleSelect
            creationMode
            currentId={props.currentId}
            userId={user.id}
            assignRole={props.assignRole}
            availableRoles={props.availableRoles}
            onCancel={props.onCancel}
          />
        )}
      </div>
      {showError && (
        <ErrorMessage
          width={inputRef.current && inputRef.current.clientWidth}
          message={errorMessage}
        />
      )}
    </div>
  );
};

export default AdministrationUserSelect;
