import type {
  IGroundStation,
  GroundStationsActionTypes,
  IGSState,
} from '../../constants/groundStations/actionTypes';
import {
  GS_ADD,
  GS_UPDATE,
  GS_REMOVE,
  GS_UPLOAD,
} from '../../constants/groundStations/actionTypes';
import { generateId } from '../../utils/common/generateId';
import { FocusTypes } from '../../constants/focus/actionTypes';

export function addGroundStation(
  groundStation: IGroundStation
): GroundStationsActionTypes {
  const id = groundStation.id
    ? groundStation.id
    : generateId(FocusTypes.GS_SUB_ID);
  return {
    type: GS_ADD,
    groundStation: { ...groundStation, id },
  };
}

export function updateGroundStation(
  groundStation: IGroundStation
): GroundStationsActionTypes {
  return {
    type: GS_UPDATE,
    groundStation,
  };
}

export function removeGroundStation(id: string): GroundStationsActionTypes {
  return {
    type: GS_REMOVE,
    id,
  };
}

export function uploadGroundStations(
  groundStations: IGSState
): GroundStationsActionTypes {
  return {
    type: GS_UPLOAD,
    groundStations,
  };
}
