import classNames from 'classnames';
import { TIME_FORMAT } from 'constants/datetime';
import moment from 'moment';

type StatusColorMap = {
  [status: string]: string | undefined;
};

type Props = {
  /**
   * Handles behaviour on card click
   */
  onClick: () => void;
  /**
   * Start date represents the small label that appears on the left side of the card.
   * Item's start date should be passed here
   */
  startDate: Date | string;
  /**
   * End date represents the small label that appears on the left side of the card.
   * Item's end date should be passed here
   */
  endDate: Date | string | undefined;
  /**
   * Status of the item
   */
  status: string;

  /**
   * Key value pairs object where key is the status name and value its color
   */
  statusColorMap: StatusColorMap;
  /**
   * Additional fields that should appear in the card such as name, id, type etc.
   */
  fields: string[];

  /**
   * Whether or not the card is selected (focused)
   */
  focused: boolean;

  /**
   * Whether or not the color of the status would color the entire card
   * or just the status bubble
   */
  statusColorAsBackgroundColor: boolean;

  /**
   * Optional action element that appears on the right side of the card
   * (e.g. a button)
   */
  actionElement?: React.ReactNode;
};

/**
 * StatusCardWith dates represents a card that has information about
 * an item that is expected to have a status of some sort.
 */
const StatusCardWithDates = ({
  onClick,
  startDate,
  endDate,
  status,
  fields,
  focused,
  statusColorMap,
  statusColorAsBackgroundColor,
  actionElement,
}: Props) => {
  return (
    <div
      className={classNames('flex h-12 w-full gap-2 cursor-pointer')}
      onClick={() => onClick()}
      data-testid="activity-card"
    >
      <div className={'flex flex-col justify-between w-16 text-xs'}>
        <span>{moment.utc(startDate).format(TIME_FORMAT)}</span>
        <span>{endDate ? moment.utc(endDate).format(TIME_FORMAT) : 'N/A'}</span>
      </div>

      <div
        className={classNames(
          'flex items-center justify-between gap-3 w-full pt-0 pb-0 pl-2 pr-2 bg-[#e6e1dc]',
          {
            'box-border border-2 border-item-contrast': focused,
          }
        )}
        style={{
          backgroundColor: statusColorAsBackgroundColor
            ? statusColorMap[status]
            : '#e6e1dc',
        }}
      >
        {fields.map((f, i) => (
          <span
            className={classNames('text-[13px] w-full whitespace-nowrap', {
              'text-left pl-2': i === 0,
              'text-center': i > 0,
            })}
            key={f}
          >
            {f}
          </span>
        ))}
        <div
          className={classNames('text-[13px] w-full text-right pr-8', {
            'border rounded': !statusColorAsBackgroundColor,
          })}
        >
          <span
            className={classNames({
              'border rounded-full  pr-4 pl-4 pt-2 pb-2 ':
                !statusColorAsBackgroundColor,
            })}
            style={{
              backgroundColor: !statusColorAsBackgroundColor
                ? statusColorMap[status]
                : 'transparent',
            }}
          >
            {status.toUpperCase()}
          </span>
        </div>

        {actionElement && <div className="mr-2">{actionElement}</div>}
      </div>
    </div>
  );
};

export default StatusCardWithDates;
