import { hosts } from 'api/hosts';
import type { View } from 'api/views/types';
import type { EmptyParams } from 'api/client';
import { apiFetchHandler } from 'api/clientFetch';
import { clientTranslate } from 'utils/hooks/useLocalisation';

export * from 'api/views/types';

export const getViews = apiFetchHandler<View[]>({
  host: hosts.data.v0,
  endpoint: '/view/views',
  method: 'GET',
  errorMessage: clientTranslate('datacosmos.fetchErrors.views.cannotGet'),

  /*
   * This assert might be nonsense, because the views data doesn't has a schema,
   * though is kind of nice to get some certainty based on the observation of the data.
   *
   * This is a warning for the next one that will try updating the type of this response:
   * The real solution is to properly type the view generation originated by the Layer class
   * at `client/datacosmos/entities/layer.ts`. To accomplish this I will suggest the most
   * simple way will be stop using a class and just use plain and simple objects, which are
   * the most easy thing to type in typescript.
   *
   * To give a bit more of context: The data for the views gets generated on the client side,
   * this is so the data can be used on the app before sending it. After it gets sent to the
   * backend it won't be validated. If the class layer changes, data will get out of sync.
   * What you get through this endpoint is just an almost random JSON blob.
   *
   * To turn off this assert you can just use `assert: null`
   */
});

export type GetViewParams = {
  viewId: string;
};

export const getView = apiFetchHandler<View, GetViewParams>({
  host: hosts.data.v0,
  endpoint: ({ viewId }) => `/view/views/${viewId}`,
  method: 'GET',
  errorMessage: 'Could not get view',
  onError: () => {},
  errorDescription: (e) => e.join('; '),
});

export type PostViewBody = Omit<
  View,
  'created_at' | 'updated_at' | 'screenshot_link'
>;

export type PostViewData = string;

export const postView = apiFetchHandler<
  PostViewData,
  EmptyParams,
  PostViewBody
>({
  host: hosts.data.v0,
  endpoint: '/view/views',
  method: 'POST',
  errorMessage: clientTranslate('datacosmos.fetchErrors.views.cannotPost'),
  errorDescription: (e) => e.join('; '),
});

export type PutViewData = string;

export type PutViewParams = {
  viewId: string;
};

export type PutViewBody = Omit<
  View,
  'created_at' | 'updated_at' | 'screenshot_link'
>;

export const putView = apiFetchHandler<PutViewData, PutViewParams, PutViewBody>(
  {
    host: hosts.data.v0,
    endpoint: ({ viewId }) => `/view/views/${viewId}`,
    method: 'PUT',
    errorMessage: clientTranslate('datacosmos.fetchErrors.views.cannotPut'),
    errorDescription: (e) => e.join('; '),
  }
);

export type DeleteViewData = string;

export type DeleteViewParams = {
  viewId: string;
};

export const deleteView = apiFetchHandler<DeleteViewData, DeleteViewParams>({
  host: hosts.data.v0,
  endpoint: ({ viewId }) => `/view/views/${viewId}`,
  method: 'DELETE',
  errorMessage: clientTranslate('datacosmos.fetchErrors.views.cannotDelete'),
  errorDescription: (e) => e.join('; '),
});
