import { DATETIME_FORMAT } from 'constants/datetime';
import moment from 'moment';
import { useEffect, useMemo, useState, useCallback } from 'react';
import { useAuth } from 'services/auth/AuthWrapper';
import { createActiveSession, getActiveSessions } from 'api/sessions/service';
import useUser from './useUser';

const REFRESH_INTERVAL_MS = 10 * 1000;
const SESSION_DURATION_SECONDS = 20 * 60;

type ActiveSessionState = {
  user: {
    id: string;
    email: string;
    pictureUrl: string;
    name: string;
  };
  start: moment.Moment;
  end: moment.Moment;
};

export type ActiveSession = ReturnType<typeof newActiveSession>;

export const newActiveSession = ({ user, start, end }: ActiveSessionState) => {
  const lastActivity = () => {
    return (
      SESSION_DURATION_SECONDS - moment.duration(end.diff(moment())).asSeconds()
    );
  };
  const startDate = () => {
    return start.format(DATETIME_FORMAT);
  };
  const endDate = () => {
    return end.format(DATETIME_FORMAT);
  };
  const timeLeft = () => {
    return moment.duration(end.diff(moment())).humanize();
  };
  const sessionDuration = () => {
    return moment.duration(moment().diff(start)).humanize();
  };
  const timeSinceLastActivity = () => {
    return moment.duration(lastActivity(), 'seconds').humanize();
  };

  return {
    user,
    start,
    end,
    startDate,
    endDate,
    timeLeft,
    sessionDuration,
    lastActivity,
    timeSinceLastActivity,
  };
};

const useActiveSession = (mission: string | number) => {
  const { token, user } = useAuth();
  const { getUsersById } = useUser();
  const [activeSessions, setActiveSessions] = useState<ActiveSession[]>([]);

  const userIsActiveInCurrentMission = useMemo(
    () =>
      user && activeSessions.some((session) => session.user.id === user.sub),
    [activeSessions, user]
  );

  const fetchActiveSessions = useCallback(
    async (abort?: { state: boolean }) => {
      if (!token || !mission) {
        return;
      }

      const { data: sessionRes } = await getActiveSessions({
        params: { missionId: Number(mission) },
      });

      if (!sessionRes?.data) {
        return;
      }

      if (abort?.state) {
        return;
      }

      const sessions = sessionRes.data;

      const fetchParsedSessions = sessions.map(
        async ({ subject, start, end }) => {
          const profile = await getUsersById(subject);

          if (!profile) {
            return newActiveSession({} as ActiveSession);
          }

          const u = {
            id: profile?.id,
            email: profile?.email,
            pictureUrl: profile?.picture,
            name: profile?.name,
          };

          return newActiveSession({
            user: u,
            start: moment(start),
            end: moment(end),
          });
        }
      );

      const parsedSessions = await Promise.all(fetchParsedSessions);

      if (abort?.state) return;

      setActiveSessions(
        parsedSessions.sort(
          (a, b) => b.start.toDate().getTime() - a.start.toDate().getTime()
        )
      );
    },
    [getUsersById, mission, token]
  );

  const startUserSession = useCallback(async () => {
    await createActiveSession({
      params: {
        missionId: Number(mission),
      },
    });
    await fetchActiveSessions();
  }, [fetchActiveSessions, mission]);

  const userId = user?.sub;
  const isSessionOccupied = useMemo(() => {
    if (typeof userId === 'undefined') return true;

    return activeSessions.some((session) => session.user.id !== userId);
  }, [activeSessions, userId]);

  useEffect(() => {
    const abort = { state: false };
    void fetchActiveSessions(abort);

    const interval = setInterval(() => {
      void fetchActiveSessions(abort);
    }, REFRESH_INTERVAL_MS);

    return () => {
      abort.state = true;
      clearInterval(interval);
    };
  }, [fetchActiveSessions]);

  return {
    activeSessions,
    startUserSession,
    isSessionOccupied,
    fetchActiveSessions,
    userIsActiveInCurrentMission,
  };
};

export default useActiveSession;
