import { BALL_STATES } from '../satelliteBalls/constants';
import type { ISatelliteBall } from '../satelliteBalls/actionTypes';

export const initialSatelliteBall: ISatelliteBall = {
  size: {
    width: 150,
    height: 150,
  },
  position: {
    top: 0,
    left: 0,
  },
  scopes: [],
  text: '',
  state: BALL_STATES.DISABLE,
  dependsOf: [-1],
  compareFunction: () => BALL_STATES.DISABLE,
  resetFunction: undefined,
  handleOnClick: undefined,
  alt: 'image Example',
};
