import { hosts } from 'api/hosts';
import type {
  Mission,
  UserAssignments,
  Organisation,
  Programme,
  Intent,
} from 'api/administration/types';
import { apiFetchHandler } from 'api/clientFetch';

export * from 'api/administration/types';

export type GetMissionsParams = {
  userId: string;
};

export const getMissions = apiFetchHandler<Mission[], GetMissionsParams>({
  host: hosts.portal.v0,
  endpoint: ({ userId }) => `/missions?user_id=${userId}`,
  method: 'GET',
  errorMessage: 'Could not get missions',
  errorDescription: (e) => e.join('; '),
});

export type GetRoleAssignmentsParams = {
  userId: string;
  resourceType?: string;
};

export const getRoleAssignments = apiFetchHandler<
  UserAssignments[],
  GetRoleAssignmentsParams
>({
  host: hosts.portal.v2,
  endpoint: ({ userId, resourceType }) =>
    `/users/${userId}/role-assignments?resourceType=${encodeURIComponent(
      resourceType ?? ''
    )}`,
  method: 'GET',
  errorMessage: 'Could not get user role assignments',
  errorDescription: (e) => e.join('; '),
});

export type GetOrganisationsParams = {
  userId: string;
};

export const getOrganisations = apiFetchHandler<
  Organisation[],
  GetOrganisationsParams
>({
  host: hosts.portal.v0,
  endpoint: ({ userId }) => `/organisations?user_id=${userId}`,
  method: 'GET',
  errorMessage: 'Could not fetch organisations',
  errorDescription: (e) => e.join('; '),
});

export type GetProgrammesParams = {
  userId: string;
};

export const getProgrammes = apiFetchHandler<
  Programme[],
  GetOrganisationsParams
>({
  host: hosts.portal.v0,
  endpoint: ({ userId }) => `/programmes?user_id=${userId}`,
  method: 'GET',
  errorMessage: 'Could not fetch programmes',
  errorDescription: (e) => e.join('; '),
});

export type GetAuthorisationResultsParams = {
  userId: string | undefined;
};

export type GetAuthorisationResultsBody = {
  subjectToken: string | undefined;
  intents: Intent[];
};

export const getAuthorisationResults = apiFetchHandler<
  boolean[],
  GetAuthorisationResultsParams,
  GetAuthorisationResultsBody
>({
  host: hosts.portal.v2,
  endpoint: ({ userId }) =>
    `/users/${encodeURIComponent(userId ?? '')}/rpc/getAuthorisationResult`,
  method: 'POST',
  errorMessage: 'Could not fetch authorisation results',
});
