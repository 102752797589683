import { AnchorButton, ProgressBar, Intent } from '@blueprintjs/core';
import { Tooltip } from 'ui/Tooltip';
import { useApplicationCatalog } from 'datacosmos/stores/ApplicationCatalogContext';
import { useHistory } from 'react-router';
import { IconNames } from '@blueprintjs/icons';
import type { IApplicationStatus } from 'datacosmos/types/applications';
import {
  routeApplication,
  routeParamProjectId,
} from 'datacosmos/components/routePath';
import s from './index.module.scss';
import { useProjects } from 'datacosmos/stores/ProjectProvider';

/**
 * @param phase
 * @return true if transfer phase is running
 */

export const isTransferOngoing = (phase?: string) => {
  if (!phase) return false;
  return phase === 'Running';
};

/**
 * @param phase
 * @return  intent as SUCCESS if transfer phase is "Succeeded"
 */

export const transferIntent = (phase?: string) => {
  if (phase === 'Succeeded') {
    return Intent.SUCCESS;
  }

  if (phase === 'Failed' || phase === 'Error') {
    return Intent.DANGER;
  }

  return Intent.NONE;
};

export default function Application() {
  const history = useHistory();
  const { applicationStatusResult, queryApplicationStatus } =
    useApplicationCatalog();
  const { currentScenario } = useProjects();

  return (
    <>
      <header className="pane-header">
        <h2 className="pane-title" title={`Application Status`}>
          {'Application Status'}
        </h2>
        <div>
          <Tooltip content="Refresh">
            <AnchorButton
              onClick={() => {
                void queryApplicationStatus();
              }}
              icon={IconNames.REFRESH}
            />
          </Tooltip>
          <Tooltip content="Go back to application list">
            <AnchorButton
              style={{ marginLeft: '10px' }}
              onClick={() => {
                if (!currentScenario) return;
                history.push(
                  `${routeApplication.replace(
                    ':' + routeParamProjectId,
                    currentScenario.id
                  )}`
                );
              }}
              icon={IconNames.CROSS}
            />
          </Tooltip>
        </div>
      </header>
      <div id="sidebar-body">
        {applicationStatusResult?.map(
          (status: IApplicationStatus, index: number) => {
            return (
              // eslint-disable-next-line react/no-array-index-key
              <div key={`${status.name}_${index}`} className={s.statusBlock}>
                {status.name}
                <div style={{ marginTop: '15px' }}>
                  <ProgressBar
                    stripes={isTransferOngoing(status.phase)}
                    intent={transferIntent(status.phase)}
                    value={eval(status.progress.toString())}
                  />
                </div>
                <div className={s.progressDetail}>
                  <small>
                    {' '}
                    Estimated duration: {status.estimated_duration_s + 's'}
                  </small>
                  <small>{status.phase}</small>
                </div>
              </div>
            );
          }
        )}
      </div>
    </>
  );
}
