import React from 'react';
import s from './index.module.scss';
import { Elevation, Card, Button, Intent } from '@blueprintjs/core';

interface IProps {
  handleEnable: () => void;
  saving?: boolean;
}

const LibrarySaveCard = (props: IProps) => {
  return (
    <Card interactive={true} elevation={Elevation.TWO}>
      <span>Alerts are not enabled for this mission</span>
      <Button
        icon="saved"
        className={s.librarySaveBtn}
        onClick={props.handleEnable}
        intent={Intent.PRIMARY}
        loading={props.saving || false}
      >
        Enable
      </Button>
    </Card>
  );
};

export default LibrarySaveCard;
