import { Button, ButtonGroup, Classes } from '@blueprintjs/core';
import { Tooltip } from 'ui/Tooltip';
import { IconNames } from '@blueprintjs/icons';
import s from './index.module.scss';
import { useAnalytics } from 'utils/hooks/analytics/useAnalytics';

interface IProps {
  procedureName?: string;
  setProcedureName: (procedureName: string) => void;
  isFrozen: boolean;
  onFreezeToggle: () => void;
  isGridView: boolean;
  onPileViewToggle: () => void;
}

const CommandPileOptions = (props: IProps) => {
  const { isFrozen, onFreezeToggle, procedureName, setProcedureName } = props;
  const { isGridView, onPileViewToggle } = props;

  const { sendInfo } = useAnalytics();

  const frozenTooltipText = isFrozen
    ? 'Pile is frozen: commands are not removed on sending.'
    : 'Pile is not frozen: commands will be removed on sending.';

  return (
    <>
      <div style={{ width: '100%' }}>
        <input
          type="text"
          className={[Classes.INPUT, s.procedureInput].join(' ')}
          placeholder="Procedure name"
          value={procedureName}
          onChange={(e) => {
            sendInfo({
              type: 'Procedure name change',
              action: 'Change/Type',
              item: 'Procedure name',
              module: 'OPS',
            });
            setProcedureName(e.target.value);
          }}
        />
      </div>
      <div>
        <ButtonGroup>
          <Tooltip
            position="top"
            content={isGridView ? 'Grid view' : 'Default view'}
          >
            <Button
              id="change-pile-view-btn"
              icon={isGridView ? IconNames.LAYOUT_GRID : IconNames.MENU}
              minimal
              small
              onClick={() => {
                sendInfo({
                  type: 'Pile view change',
                  action: 'Click',
                  item: 'Pile view change button',
                  module: 'OPS',
                });
                onPileViewToggle();
              }}
            />
          </Tooltip>
          <Tooltip position="top" content={frozenTooltipText}>
            <Button
              id="freeze-pile-btn"
              icon={IconNames.SNOWFLAKE}
              minimal
              small
              active={isFrozen}
              onClick={() => {
                sendInfo({
                  type: `Pile freeze toggle: ${
                    isFrozen ? 'Frozen' : 'Unfrozen'
                  } `,
                  action: 'Click',
                  item: 'Pile freeze toggle button',
                  module: 'OPS',
                  additionalParams: {
                    freezeStatus: isFrozen ? 'Frozen' : 'Unfrozen',
                  },
                });
                onFreezeToggle();
              }}
            />
          </Tooltip>
        </ButtonGroup>
      </div>
    </>
  );
};

export default CommandPileOptions;
