import type { Coordinates } from './coordinatesToGeoJson';

/**
 * numberArrayToCoordinateObject takes a number array and converts it to a coordinate object array
 * @param coordinates number array of coordinates
 * @returns coordinate object array
 */
export const numberArrayToCoordinateObject = (coordinates: number[]) => {
  if (coordinates.length < 2 || coordinates.length % 2 !== 0) {
    throw new Error('Coordinates must consist of longitude and latitude pairs');
  }

  if (coordinates.some((c) => isNaN(parseFloat(c.toString())))) {
    throw new Error('Coordinates must be numbers');
  }

  const coordinatesObject: Coordinates[] = [];
  // Group coordinates by lng,lat
  for (let i = 0; i < coordinates.length; i += 2) {
    coordinatesObject.push({
      lng: coordinates[i],
      lat: coordinates[i + 1],
    });
  }

  return coordinatesObject;
};

/**
 * dmsCoordinatesStringToDecimalString takes a DMS coordinate string and converts it to a decimal coordinate string
 * which DataCosmos understands
 * @param coordString DMS coordinate string
 * @returns decimal coordinate string
 */
export const dmsCoordinatesStringToDecimalString = (coordString: string) => {
  if (
    !coordString.includes('°') ||
    !coordString.includes("'") ||
    !coordString.includes('"')
  ) {
    return coordString;
  }

  const coordStringNoWhitespace = coordString.replace(/\s/g, '');

  const split = coordStringNoWhitespace
    .split(/°|'|"/g)
    // Filter out whitespaces
    .filter((s) => s !== '')
    // Remove everything that's not a number from each entry
    .map((s) => s.replace(/[^0-9.]/g, ''))
    // Remove NaNs
    .filter((s) => !isNaN(parseFloat(s)));

  const decimals: number[] = [];
  for (let i = 0; i < split.length; i += 3) {
    decimals.push(
      parseFloat(split[i]) +
        parseFloat(split[i + 1]) / 60 +
        parseFloat(split[i + 2]) / 3600
    );
  }

  return decimals.join(',');
};
