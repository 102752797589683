import { useEffect, useState, useCallback, useMemo } from 'react';
import { useAuth } from 'services/auth/AuthWrapper';
import type {
  IRoleAssignment,
  IUserWithPermissions,
} from 'services/api/portal/administration/api/types';
import userApi from 'services/api/portal/administration/api/userApi';
import { BACKEND_BASE_URL } from 'env';

const PORTAL_URL = `${BACKEND_BASE_URL}/portal/v2`;

const useAssignments = (user: IUserWithPermissions) => {
  const [assignments, setAssignments] = useState<IRoleAssignment[]>([]);
  const { token } = useAuth();

  const api = useMemo(() => userApi(PORTAL_URL, token), [token]);

  const getAssignments = useCallback(async () => {
    const data = await api.getUserAssignments(user.id);

    setAssignments(data);
  }, [api, user.id]);

  useEffect(() => {
    void getAssignments();
  }, [getAssignments]);

  return {
    assignments,
    getAssignments,
  };
};

export default useAssignments;
