import type { ChangeEvent } from 'react';
import { useState } from 'react';
import { FileInput } from '@blueprintjs/core';

interface IProps {
  text: string;
  handler: (event: ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  fill?: boolean;
}

const ScenarioUploader = ({ text, handler, disabled, fill }: IProps) => {
  // This is a super hack to make blueprint pick
  // up the same file submitted twice
  const [fileInputRefresher, setFileInputRefresher] = useState(false);
  const [fileName, setFileName] = useState<string>(null);
  const [selected, setSelected] = useState(false);

  return (
    <FileInput
      key={fileInputRefresher ? 'fileInput-1' : 'fileInput-2'}
      className="file-input button"
      text={fileName ? fileName : text}
      onInputChange={(event: ChangeEvent<HTMLInputElement>) => {
        setFileInputRefresher(!fileInputRefresher);
        setFileName(event.target.files[0].name);
        handler(event);
        setSelected(true);
      }}
      hasSelection={selected}
      disabled={disabled}
      fill={fill}
    />
  );
};

export default ScenarioUploader;
