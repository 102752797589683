import { get, size } from 'lodash/fp';
import { Callout } from '@blueprintjs/core';
import { Tooltip } from 'ui/Tooltip';
import { IconNames } from '@blueprintjs/icons';

import { TLE_MAX_LENGTH } from 'constants/ops/gs_scheduling/constants';

import s from '../../index.module.scss';
import type { Ephemeris } from 'api/ephemeris/types';

interface IProps {
  ephemeris: Ephemeris;
}

const GSTleInfo = (props: IProps) => {
  const line1 = get('ephemeris.line1', props);
  const line2 = get('ephemeris.line2', props);

  if (size(line1) !== TLE_MAX_LENGTH || size(line2) !== TLE_MAX_LENGTH)
    return null;

  return (
    <table style={{ marginTop: 10 }}>
      <tr>
        <td>
          <b>Type</b>
        </td>
        <td>TLE</td>
      </tr>
      <tr>
        <td>
          <b>Satellite catalog number</b>
        </td>
        <Tooltip content={`Classification: ${line1.slice(7, 8)}`}>
          {line1.slice(2, 8)}
        </Tooltip>
      </tr>
      <tr>
        <td>
          <b>International Designator</b>
        </td>
        <Tooltip
          content={
            <>
              <div>Last two digits of launch year: {line1.slice(9, 11)}</div>
              <div>Launch number of the year: {line1.slice(11, 14)}</div>
              <div>Piece of the launch: {line1.slice(14, 17)}</div>
            </>
          }
        >
          {line1.slice(9, 17)}
        </Tooltip>
      </tr>
      <tr>
        <td>
          <b>Epoch</b>
        </td>
        <Tooltip content={`Epoch Year: ${line1.slice(18, 20)}`}>
          {line1.slice(18, 32)}
        </Tooltip>
      </tr>
      <tr>
        <td>
          <b>Derivatives of Mean Motion</b>
        </td>
        <Tooltip
          content={
            <>
              <div>
                First Derivative (aka the Ballistic Coefficient):{' '}
                {line1.slice(33, 43)}
              </div>
              <div>Second Derivative: {line1.slice(44, 52)}</div>
            </>
          }
        >
          {line1.slice(33, 52)}
        </Tooltip>
      </tr>
      <tr>
        <td>
          <b>Drag Term</b>
        </td>
        <span>{line1.slice(53, 61)}</span>
      </tr>
      <tr>
        <td>
          <b>Ephemeris type</b>
        </td>
        <span>{line1.slice(62, 63)}</span>
      </tr>
      <tr>
        <td>
          <b>Element set number</b>
        </td>
        <span>{line1.slice(64, 68)}</span>
      </tr>
      <tr>
        <td>
          <b>Inclination</b>
        </td>
        <span>{line2.slice(8, 16)}</span>
      </tr>
      <tr>
        <td>
          <b>RAAN</b>
        </td>
        <span>{line2.slice(17, 25)}</span>
      </tr>
      <tr>
        <td>
          <b>Eccentricity</b>
        </td>
        <span>{line2.slice(26, 33)}</span>
      </tr>
      <tr>
        <td>
          <b>Argument of Perigee</b>
        </td>
        <span>{line2.slice(34, 42)}</span>
      </tr>
      <tr>
        <td>
          <b>Mean Anomaly</b>
        </td>
        <span>{line2.slice(43, 51)}</span>
      </tr>
      <tr>
        <td>
          <b>Mean Motion</b>
        </td>
        <span>{line2.slice(52, 63)}</span>
      </tr>
      <tr>
        <td>
          <b>Revolution number at epoch</b>
        </td>
        <span>{line2.slice(63, 68)}</span>
      </tr>
      <tr>
        <td>
          <b>Checksum</b>
        </td>
        <span>{line1.slice(68, 69)}</span>
      </tr>
    </table>
  );
};

const GSEphemerisInfo = (props: IProps) => {
  const type = props.ephemeris.type;

  return (
    <div className={s.gsEphemerisInfoContainer}>
      <Callout icon={IconNames.INFO_SIGN}>
        Hover over a value to see more details
      </Callout>
      {type === 'TLE' && <GSTleInfo ephemeris={props.ephemeris} />}
    </div>
  );
};

export default GSEphemerisInfo;
