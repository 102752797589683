import type { IRole } from 'services/api/portal/administration/api/types';

export const PERMISSION_TYPE_LIST = [
  'datacosmos_app',
  'datacosmos_scenario',
  'datacosmos_stac_collection',
  'datacosmos_view',
  'datacosmos_stac_item',
  'global',
  'mission',
  'organisation',
  'programme',
] as const;

export type PermissionType = (typeof PERMISSION_TYPE_LIST)[number];

export type AttachedRoleAssignment = {
  id: number;
  parentRoleId: number;
  parentResourceType: string;
  parentResourceId: number;
  childRoleId: number;
  childResourceType: string;
  childResourceId: number;
  createdAt: string;
  parentRole: IRole;
  childRole: IRole;
};

export type PermissionScope =
  | 'data:order:confirm'
  | 'data:order:create'
  | 'data:app:create'
  | 'data:app:execute'
  | 'data:app:read'
  | 'data:app:write'
  | 'data:app'
  | 'data:credit:balance:read:own'
  | 'data:credit:balance:read'
  | 'data:credit:credit:read'
  | 'data:credit:credit:write'
  | 'data:credit:debit:read'
  | 'data:credit:debit:write'
  | 'data:credit'
  | 'data:customer:create'
  | 'data:customer'
  | 'data:docs'
  | 'data:feature:docs'
  | 'data:feature'
  | 'data:image:external:assignment:read'
  | 'data:image:external:read'
  | 'data:processing:assignment:read'
  | 'data:processing:assignment:write'
  | 'data:processing:read'
  | 'data:processing:write'
  | 'data:processing'
  | 'data:scenario:assignment:read'
  | 'data:scenario:assignment:write'
  | 'data:scenario:assignment'
  | 'data:scenario:create'
  | 'data:scenario:read:own'
  | 'data:scenario:read'
  | 'data:scenario:write'
  | 'data:scenario'
  | 'data:stac_collection:assignment:read'
  | 'data:stac_collection:assignment:write'
  | 'data:stac_collection:read'
  | 'data:stac_collection:write'
  | 'data:stac_collection'
  | 'data:tasking:price:read'
  | 'data:tasking:request:create'
  | 'data:tasking:request:delete'
  | 'data:tasking:request:read:own'
  | 'data:tasking:request:update'
  | 'data:tasking:request:update:activities:parameters:platform:roll_angle'
  | 'data:tasking:request:update:activities:start_date'
  | 'data:tasking:request:update:activities:end_date'
  | 'data:tasking:request'
  | 'data:tasking:search'
  | 'data:ticket:create'
  | 'data:ticket:write:create'
  | 'data:ticket'
  | 'data:view:assignment:read'
  | 'data:view:assignment:write'
  | 'data:view:assignment'
  | 'data:view:create'
  | 'data:view:read:own'
  | 'data:view:read'
  | 'data:view:write'
  | 'data:view'
  | 'data'
  | 'ephemeris:read'
  | 'ephemeris:write'
  | 'ephemeris'
  | 'hil'
  | 'mission:assignment:read'
  | 'mission:create'
  | 'mission:read:own'
  | 'mission:read'
  | 'mission:update'
  | 'mission'
  | 'msd'
  | 'ops:activity:read'
  | 'ops:activity:write:create'
  | 'ops:activity:write'
  | 'ops:activity'
  | 'ops:alerts:firing'
  | 'ops:alerts'
  | 'ops:command:aocs:nominal'
  | 'ops:command:aocs:telemetry'
  | 'ops:command:aocs'
  | 'ops:command:baseband'
  | 'ops:command:comms:getversion'
  | 'ops:command:comms:nominal'
  | 'ops:command:comms:setcurrenttime'
  | 'ops:command:comms:syrlinks'
  | 'ops:command:comms'
  | 'ops:command:eps:getversion'
  | 'ops:command:eps:nominal'
  | 'ops:command:eps:setcurrenttime'
  | 'ops:command:eps:settelemetrybroadcaststatus'
  | 'ops:command:eps'
  | 'ops:command:ftp:list'
  | 'ops:command:ftp:nominal'
  | 'ops:command:ftp'
  | 'ops:command:network:nominal'
  | 'ops:command:network:sendpacket'
  | 'ops:command:network'
  | 'ops:command:ops'
  | 'ops:command:pdgs'
  | 'ops:command:piu:getversion'
  | 'ops:command:piu:nominal'
  | 'ops:command:piu'
  | 'ops:command:satellite'
  | 'ops:command:stresstest'
  | 'ops:command:telemetry'
  | 'ops:command:telemetryfileparser'
  | 'ops:command'
  | 'ops:mission-config:create'
  | 'ops:mission-config:delete'
  | 'ops:mission-config:read'
  | 'ops:mission-config:update'
  | 'ops:mission-config'
  | 'ops:node-red'
  | 'ops:rti:command:command-pile'
  | 'ops:rti:command:send-armed-command'
  | 'ops:rti:command:send-unarmed-command'
  | 'ops:rti:command'
  | 'ops:rti:session:connect:user-accessible'
  | 'ops:rti:session:connect'
  | 'ops:rti:session:read:user-accessible'
  | 'ops:rti:session:read'
  | 'ops:rti:session:write:user-accessible'
  | 'ops:rti:session:write'
  | 'ops:rti:session'
  | 'ops:rti'
  | 'ops:satellitegateway:configuration:read'
  | 'ops:satellitegateway:configuration:update'
  | 'ops:satellitegateway:configuration'
  | 'ops:satellitegateway:connect'
  | 'ops:satellitegateway'
  | 'ops:scripting:read'
  | 'ops:scripting'
  | 'ops:tm:definition:read'
  | 'ops:tm:definition:write'
  | 'ops:tm:definition'
  | 'ops:tm:grafana:admin'
  | 'ops:tm:grafana:internal'
  | 'ops:tm:grafana:user'
  | 'ops:tm:grafana'
  | 'ops:tm:point:read'
  | 'ops:tm:point:write'
  | 'ops:tm'
  | 'ops'
  | 'organisation:assignment:read'
  | 'organisation:assignment:write'
  | 'organisation:create'
  | 'organisation:read:own'
  | 'organisation:read'
  | 'organisation:update'
  | 'organisation'
  | 'portal:cloudstorage:file:create'
  | 'portal:cloudstorage:file:delete'
  | 'portal:cloudstorage:file:read'
  | 'portal:cloudstorage:folder:create'
  | 'portal:cloudstorage:folder:read'
  | 'portal:cloudstorage'
  | 'portal:ephemeris:read'
  | 'portal:ephemeris:write'
  | 'portal:gs:ephemeris:read'
  | 'portal:gs:ephemeris:write'
  | 'portal:gs:mission:read'
  | 'portal:gs:mission:write'
  | 'portal:gs:opportunities:read'
  | 'portal:gs:pass:read'
  | 'portal:gs:pass:write'
  | 'portal:gs:provider_config:read'
  | 'portal:gs:site_antenna:write'
  | 'portal:gs'
  | 'portal:none'
  | 'portal'
  | 'programme:assignment:read'
  | 'programme:assignment:write'
  | 'programme:create'
  | 'programme:read:own'
  | 'programme:read'
  | 'programme:update'
  | 'programme'
  | 'relationship:write'
  | 'role:read:user-assignable'
  | 'role:read'
  | 'role:write'
  | 'role'
  | 'user:assignment:read:own'
  | 'user:assignment:read'
  | 'user:assignment:write'
  | 'user:assignment'
  | 'user:read:by-email'
  | 'user:read:own'
  | 'user:read'
  | 'user'
  | 'user-is-public'
  | 'ops:command:piu:setcurrenttime'
  | 'data:project:tasking:write'
  | 'admin:matomo'
  | 'admin:grafana'
  | 'admin:keycloak';
