import type {
  GroupSatelliteBallActionTypes,
  IGroup,
} from '../../constants/groupSatelliteBalls/actionTypes';
import {
  GSB_ADD,
  GSB_REMOVE,
  GSB_UPDATE,
} from '../../constants/groupSatelliteBalls/actionTypes';

export function addGroupSatelliteBall(
  group: IGroup
): GroupSatelliteBallActionTypes {
  return {
    type: GSB_ADD,
    group,
  };
}

export function updateGroupSatelliteBall(
  group: IGroup
): GroupSatelliteBallActionTypes {
  return {
    type: GSB_UPDATE,
    group,
  };
}

export function removeGroupSatelliteBall(
  group: IGroup
): GroupSatelliteBallActionTypes {
  return {
    type: GSB_REMOVE,
    label: group.label,
  };
}
