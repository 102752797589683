import { useCallback, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router';
import { Button, ButtonGroup, Intent, Icon, IconSize } from '@blueprintjs/core';
import { Tooltip } from 'ui/Tooltip';
import { IconNames } from '@blueprintjs/icons';
import { DndProvider } from 'react-dnd';
import type { IJsonSchema } from 'utils/rtiCommands/dereference';
import { HTML5Backend } from 'react-dnd-html5-backend';
import type {
  ICommandPileItem,
  UpdateWorkspace,
} from 'services/api/ops/realtimeTerminalSession';
import { preventDefaultPropagation } from 'utils/common/CommonUtils';
import { useAuth } from 'services/auth/AuthWrapper';
import { useOperate } from 'services/Operate';
import { pileItemSelection, reorderCommandPile } from 'actions/operate/actions';
import CommandPileItem from './CommandPileItem';

import s from './index.module.scss';
import AlertConfirm from 'components/common/AlertConfirm';
import type { PermissionScope } from 'api/administration/permissions';
import { useAnalytics } from 'utils/hooks/analytics/useAnalytics';
import CommandPileContextMenu from './CommandPileContextMenu';
import type { CommandDefinition } from 'api/telecommands/types';
import useSessionStorage from 'utils/hooks/useSessionStorage';

interface IProps {
  availableCommands: CommandDefinition[];
  isFrozenPile: boolean;
  commandPileWorkingSpace?: ICommandPileItem[];
  armedCommand?: ICommandPileItem;
  handleFireCommandClick: () => void;
  handleUnarmCommandClick: () => void;
  handleArmCommandClick: (id: string) => void;
  handlePileCommandRemoveClick: (id: string) => void;
  handleArmCommandEditClick: (
    pileItem: ICommandPileItem,
    addToMultipleItemDrag: boolean
  ) => void;
  selectedPileItem?: ICommandPileItem;
  selectedMultiplePileItems?: ICommandPileItem[];
  handleArmedCommandPreviewClick: () => void;
  isCommandPreview?: boolean;
  handleSendUnarmedCommand: (
    pileItem: ICommandPileItem,
    removeItem: boolean,
    procedureName: string
  ) => Promise<void>;
  updateWorkspace: UpdateWorkspace;
  procedureName?: string;
  isGridView: boolean;
  scrollCommandHistoryToBottom: () => void;
  isSessionOccupied: boolean;
  startUserSession: () => Promise<void>;
  userIsActiveInCurrentMission?: boolean;
  fetchActiveSessions: (abort?: { state: boolean }) => Promise<void>;
}

interface ICommandInfo {
  commandSchema: IJsonSchema;
  commandScope?: PermissionScope;
  dangerous: boolean;
  dangerousMessage?: string;
}

const CommandPile = ({
  availableCommands,
  isFrozenPile,
  commandPileWorkingSpace = [],
  armedCommand,
  handleArmCommandClick,
  handlePileCommandRemoveClick,
  handleArmCommandEditClick,
  selectedPileItem,
  isGridView,
  updateWorkspace,
  handleSendUnarmedCommand,
  procedureName,
  scrollCommandHistoryToBottom,
  isSessionOccupied,
  startUserSession,
  userIsActiveInCurrentMission,
  fetchActiveSessions,
  selectedMultiplePileItems,
}: IProps) => {
  const { dispatch } = useOperate();
  const { checkPermissions } = useAuth();
  const { mission } = useParams<{ mission: string }>();

  const { sendInfo } = useAnalytics();

  const [isSessionWarningOpened, setIsSessionWarningOpened] =
    useState<boolean>(false);

  const [clickedPileItem, setClickedPileItem] =
    useState<ICommandPileItem | null>(null);

  const [shiftClickInitialIndex, setShiftClickInitialIndex] = useState<
    number | null
  >(null);

  const [pileItemsWithPermissions, setPileItemsWithPermissions] = useState<
    ICommandPileItem[]
  >([]);

  // Command permissions cache
  const [pileItemsPerms, setPileItemsPerms] = useSessionStorage<{
    [mission: string]: { [itemId: string]: boolean };
  }>('pile_items_permissions', {});

  const handleHoverPileItem = (dragIndex: number, hoverIndex: number) => {
    if (selectedMultiplePileItems?.length !== 0) {
      dispatch(
        reorderCommandPile(hoverIndex, dragIndex, selectedMultiplePileItems)
      );
      return;
    }
    dispatch(reorderCommandPile(hoverIndex, dragIndex, selectedPileItem));
  };

  const handleShiftSelect = (initialIndex: number, endIndex: number) => {
    const selectedItems = commandPileWorkingSpace.slice(
      Math.min(initialIndex, endIndex),
      Math.max(initialIndex, endIndex) + 1
    );
    dispatch(pileItemSelection(selectedItems));
  };

  const getCommandInfo = useCallback(
    (name?: string): ICommandInfo => {
      const command = availableCommands.find((value) => value.name === name);
      return command
        ? {
            commandSchema: command.schema,
            commandScope: command.scope,
            dangerous: command.dangerous,
            dangerousMessage: command.dangerousMessage,
          }
        : {
            commandSchema: {},
            commandScope: 'ops',
            dangerous: false,
          };
    },
    [availableCommands]
  );

  // This use effect is a bit of a hack. It is used to update the workspace
  // with the value of the state after the latest dispatch.
  const shouldUpdateWorkspace = useRef(false);

  // Debounce is very large here but it shouldn't pose an issue since the
  // workspace is only updated after the user has finished dragging a command
  // or multiple commands. The workspace is updated 5 seconds upon finishing the
  // drag.
  // Other actions such as deleting or sending an unfrozen cmd will update the workspace
  // immediately.
  // The issue where command would get deselected after dragging them multiple times
  // originally occurred due to workspace state updating which would trigger
  // a rerender which I assume messed up sth with the drag and drop library.
  // TL:DR: This is a bit hacky but it works.
  const debounceTimer = useRef<NodeJS.Timeout | null>(null);
  useEffect(() => {
    if (!shouldUpdateWorkspace.current) {
      return;
    }

    if (debounceTimer.current) {
      clearTimeout(debounceTimer.current);
    }
    debounceTimer.current = setTimeout(() => {
      shouldUpdateWorkspace.current = false;
      updateWorkspace(commandPileWorkingSpace, armedCommand, isFrozenPile);
    }, 5000);
  }, [commandPileWorkingSpace, armedCommand, isFrozenPile, updateWorkspace]);

  useEffect(() => {
    const getOpenCommandPermissions = async () => {
      if (commandPileWorkingSpace.length === 0) {
        return;
      }

      // Pile items user has permissions for, from the session storage cache
      const allowedCachedPermissions = commandPileWorkingSpace.filter(
        (pileItem) => pileItemsPerms[mission]?.[pileItem.id ?? '']
      );

      if (allowedCachedPermissions.length === commandPileWorkingSpace.length) {
        setPileItemsWithPermissions(allowedCachedPermissions);
        return;
      }

      const cmdPerms = await checkPermissions(
        commandPileWorkingSpace.map((item) => ({
          id: mission,
          type: 'mission',
          actionScope: getCommandInfo(item.command).commandScope,
        }))
      );

      const allowedPileItems = commandPileWorkingSpace.filter((_, index) => {
        return cmdPerms[index];
      });

      setPileItemsWithPermissions(allowedPileItems);

      setPileItemsPerms((prev) => ({
        ...prev,
        [mission]: {
          ...prev[mission],
          ...commandPileWorkingSpace.reduce((acc, item, index) => {
            return {
              ...acc,
              [item.id ?? '']: cmdPerms[index],
            };
          }, {}),
        },
      }));
    };

    void getOpenCommandPermissions();
  }, [
    checkPermissions,
    commandPileWorkingSpace,
    getCommandInfo,
    mission,
    pileItemsPerms,
    pileItemsWithPermissions.length,
    setPileItemsPerms,
  ]);

  const getIsAllowedToOpenPileItem = (item: ICommandPileItem) => {
    return pileItemsWithPermissions.some((pileItem) => pileItem.id === item.id);
  };

  const handleDropPileItem = (dragIndex: number, hoverIndex: number) => {
    if (selectedMultiplePileItems?.length !== 0) {
      dispatch(
        reorderCommandPile(hoverIndex, dragIndex, selectedMultiplePileItems)
      );
      shouldUpdateWorkspace.current = true;
      return;
    }
    dispatch(reorderCommandPile(hoverIndex, dragIndex, selectedPileItem));

    shouldUpdateWorkspace.current = true;
  };

  const send = (
    event: {
      preventDefault: () => void;
      stopPropagation: () => void;
    },
    pileItem: ICommandPileItem
  ) => {
    event.preventDefault();
    event.stopPropagation();

    if (isSessionOccupied && !userIsActiveInCurrentMission) {
      setIsSessionWarningOpened(true);
      setClickedPileItem(pileItem);
    } else {
      if (!procedureName) return;
      void handleSendUnarmedCommand(
        pileItem,
        !isFrozenPile,
        procedureName
      ).then(() => {
        scrollCommandHistoryToBottom();
      });
    }
  };

  // Leading edge debounce for starting user session
  const userSessionDebounceTimer = useRef<NodeJS.Timeout | null>(null);
  const debouncedStartUserSession = async () => {
    if (userSessionDebounceTimer.current) {
      return;
    }

    userSessionDebounceTimer.current = setTimeout(() => {
      userSessionDebounceTimer.current = null;
      // Debounce for 30 seconds
    }, 30000);

    await fetchActiveSessions();
    await startUserSession();
  };

  if (commandPileWorkingSpace.length === 0) {
    return null;
  }

  return (
    <DndProvider backend={HTML5Backend}>
      <div
        className={
          isGridView ? s.commandPileContainerGridView : s.commandPileContainer
        }
        data-testid="command-pile"
      >
        {commandPileWorkingSpace.map((pileItem, index) => {
          let editMode: boolean = false;
          if (selectedMultiplePileItems?.length) {
            const selectedItem = selectedMultiplePileItems?.find(
              (element) => element?.id === pileItem?.id
            );
            editMode = selectedItem !== undefined ? true : false;
          } else {
            editMode = selectedPileItem
              ? selectedPileItem.id === pileItem.id
              : false;
          }
          const { commandSchema, dangerous, dangerousMessage } = getCommandInfo(
            pileItem.command
          );
          const isAllowedToOpenPileItem = getIsAllowedToOpenPileItem(pileItem);

          let className = isGridView
            ? s.commandPileItemCard__selected
            : s.commandPileItem__selected;

          if (!editMode) className = '';

          return (
            <CommandPileContextMenu pileItemIndex={index} key={pileItem.id}>
              <CommandPileItem
                handleShiftSelect={handleShiftSelect}
                shiftClickInitialIndex={shiftClickInitialIndex}
                setShiftClickInitialIndex={setShiftClickInitialIndex}
                cardView={isGridView}
                itemIndex={index}
                hoverPileItem={handleHoverPileItem}
                dropPileItem={handleDropPileItem}
                className={className}
                pileItem={pileItem}
                commandSchema={commandSchema}
                handleItemClick={handleArmCommandEditClick}
                headButtonsList={
                  <div className={s.commandLeftHandSide}>
                    <Tooltip
                      content="Danger: Send directly to the satellite!"
                      disabled={!isAllowedToOpenPileItem}
                    >
                      <Button
                        disabled={!isAllowedToOpenPileItem}
                        icon={IconNames.SATELLITE}
                        intent={Intent.DANGER}
                        onClick={async (event) => {
                          sendInfo({
                            type: 'Command pile direct send',
                            action: 'Click',
                            item: 'Command pile direct send button',
                            module: 'OPS',
                          });

                          send(event, pileItem);
                          await debouncedStartUserSession();
                        }}
                        className="mr-1"
                      />
                    </Tooltip>
                    {dangerous && (
                      <div className={s.dangerIconContainer}>
                        <Tooltip
                          content={
                            dangerousMessage ?? 'This command can be dangerous'
                          }
                        >
                          <Icon
                            icon={IconNames.WARNING_SIGN}
                            intent={Intent.DANGER}
                            iconSize={IconSize.LARGE}
                          />
                        </Tooltip>
                      </div>
                    )}
                  </div>
                }
                buttonsList={
                  <div className={s.commandRightHandSide}>
                    <ButtonGroup>
                      <Tooltip
                        content="Remove from command pile"
                        disabled={!isAllowedToOpenPileItem}
                      >
                        <Button
                          name="remove-command"
                          data-testid="remove-command-button"
                          disabled={!isAllowedToOpenPileItem}
                          intent={Intent.WARNING}
                          icon={IconNames.TRASH}
                          onClick={(e) => {
                            sendInfo({
                              type: 'Command pile remove',
                              action: 'Click',
                              item: 'Command pile remove button',
                              module: 'OPS',
                            });

                            if (pileItem.id) {
                              preventDefaultPropagation(
                                handlePileCommandRemoveClick
                              )(pileItem.id)(e);
                            }
                          }}
                        />
                      </Tooltip>
                      <Tooltip
                        content="Arm command"
                        disabled={!isAllowedToOpenPileItem}
                      >
                        <Button
                          name="arm-command"
                          disabled={!isAllowedToOpenPileItem}
                          className={
                            isAllowedToOpenPileItem
                              ? s.commandButtonBlack
                              : undefined
                          }
                          icon={IconNames.LOCATE}
                          onClick={(e) => {
                            sendInfo({
                              type: 'Command pile arm command',
                              action: 'Click',
                              item: 'Command pile arm command button',
                              module: 'OPS',
                            });
                            if (pileItem.id) {
                              preventDefaultPropagation(handleArmCommandClick)(
                                pileItem.id
                              )(e);
                            }
                          }}
                        />
                      </Tooltip>
                    </ButtonGroup>
                  </div>
                }
              />
            </CommandPileContextMenu>
          );
        })}
      </div>

      <AlertConfirm
        isOpen={isSessionWarningOpened && !userIsActiveInCurrentMission}
        setIsOpen={setIsSessionWarningOpened}
        onConfirm={async () => {
          if (!clickedPileItem || !procedureName) return;
          void handleSendUnarmedCommand(
            clickedPileItem,
            !isFrozenPile,
            procedureName
          ).then(() => {
            scrollCommandHistoryToBottom();
          });

          await startUserSession();
        }}
        message="The session is currently in use. Are you sure you want to send the command?"
      />
    </DndProvider>
  );
};

export default CommandPile;
