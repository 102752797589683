import type {
  IPayloadUpdateAction,
  IConfirmPayloadSelectionAction,
  ICancelPayloadSelectionAction,
  IAddCustomPayloadAction,
  IUpdateCustomPayloadAction,
  IRemoveCustomPayloadAction,
  ISelectPayloadAction,
  IUnselectPayloadAction,
  IModuleLoadAction,
  IModuleClearAction,
  IModuleLoadActionProps,
} from '../../../constants/ui/moduleSelector/actionTypes';
import {
  PAYLOAD_UPDATE,
  CONFIRM_PAYLOAD_SELECTION,
  CANCEL_PAYLOAD_SELECTION,
  ADD_CUSTOM_PAYLOAD,
  UPDATE_CUSTOM_PAYLOAD,
  REMOVE_CUSTOM_PAYLOAD,
  SELECT_PAYLOAD,
  UNSELECT_PAYLOAD,
  MODULE_LOAD,
  MODULE_CLEAR,
} from '../../../constants/ui/moduleSelector/actionTypes';
import type { IPayload } from '../../../constants/moduleData/types';

export const loadModule = ({
  moduleName,
  satelliteModules,
  multipleSelectable,
}: IModuleLoadActionProps): IModuleLoadAction => {
  return {
    type: MODULE_LOAD,
    payload: { moduleName, satelliteModules, multipleSelectable },
  };
};

export const clearModule = (): IModuleClearAction => {
  return {
    type: MODULE_CLEAR,
  };
};

export const updatePayload = (payloadName: string): IPayloadUpdateAction => {
  return {
    type: PAYLOAD_UPDATE,
    payloadName,
  };
};

export const addCustomPayload = (
  payload: IPayload
): IAddCustomPayloadAction => {
  return {
    type: ADD_CUSTOM_PAYLOAD,
    payload,
  };
};

export const updateCustomPayload = (
  payload: IPayload,
  oldName: string
): IUpdateCustomPayloadAction => {
  return {
    type: UPDATE_CUSTOM_PAYLOAD,
    payload,
    oldName,
  };
};

export const removeCustomPayload = (
  payloadName: string
): IRemoveCustomPayloadAction => {
  return {
    type: REMOVE_CUSTOM_PAYLOAD,
    payloadName,
  };
};

export const selectPayload = (payloadName: string): ISelectPayloadAction => {
  return {
    type: SELECT_PAYLOAD,
    payloadName,
  };
};

export const unselectPayload = (
  payloadName: string
): IUnselectPayloadAction => {
  return {
    type: UNSELECT_PAYLOAD,
    payloadName,
  };
};

export const confirmPayloadSelection = (): IConfirmPayloadSelectionAction => {
  return {
    type: CONFIRM_PAYLOAD_SELECTION,
  };
};

export const cancelPayloadSelection = (): ICancelPayloadSelectionAction => {
  return {
    type: CANCEL_PAYLOAD_SELECTION,
  };
};
