import type { ChangeEvent, ReactElement } from 'react';
import React from 'react';
import type { WithTranslation } from 'react-i18next';
import { withTranslation } from 'react-i18next';
import DefaultLayout from '../../layouts/DefaultLayout';
import MapContainer from '../../components/eventsMap/MapContainer';
import { MAP_ID, MAP_TYPE } from '../../constants/map/constants';
import PopUp from '../../components/popUp/PopUp';
import type { IMapWrapper } from '../../declarations/mapDeclarations/Map';
import { Button, Position, Intent } from '@blueprintjs/core';
import { Tooltip } from 'ui/Tooltip';
import type { IMission } from '../../services/Missions';
import { connect } from 'react-redux';
import type { AppState } from '../../store';
import type { IPopUp } from '../../constants/popUp/actionTypes';
import { setPopUp } from '../../actions/popUp/thunks';
import {
  getMissionsThunk,
  updateMissionThunk,
} from '../../actions/missions/thunks';
import type { IPIState } from '../../constants/pointsOfInterest/actionTypes';
import type { IRIState } from '../../constants/regionsOfInterest/actionTypes';
import type { IGroup } from '../../constants/groupSatelliteBalls/actionTypes';
import { updateGroupSB } from '../../actions/groupSatelliteBalls/thunks';
import { downloadFile, showErrorMessage } from '../../utils/common/CommonUtils';
import type { IData } from '../../actions/uploadData';
import { uploadData } from '../../actions/uploadData';
import { DAYS_INPUT } from '../../constants/msd/geometry/constants';
import type { IUserInformation } from '../../constants/user/actionTypes';
import type {
  IGroundStation,
  IGSState,
} from '../../constants/groundStations/actionTypes';
import GroundStations from '../../components/groundStation/GroundStations';
import { removeGS, updateGS } from '../../actions/groundStations/thunks';
import { setGroundStationSpecify } from '../../services/mapService/contextMenu/map/events';
import { computeGroundSegment } from '../../utils/api/compute/computeGeometry';
import type { ISimulateState } from '../../components/simulation/Simulation';
import Simulation from '../../components/simulation/Simulation';
import Results from '../../components/optimizationResults/Results';
import type { IGeometryObjectState } from '../../constants/msd/geometry/interfaces';
import type {
  ISatelliteData,
  ISTState,
} from '../../constants/satelliteOrbits/actionTypes';
import {
  addSO,
  removeAllSO,
  updateSO,
} from '../../actions/satelliteOrbits/thunks';
import { showGroundStation } from '../../services/mapService/contextMenu/groundStations/events';
import BlockOverlay from '../../layouts/BlockOverlay';
import RedirectToMainPage from '../../layouts/RedirectToMainPage';
import {
  GROUND_SEGMENT_FILE_NAME,
  RESULTS_PATH,
} from '../../constants/msd/groudSegment/constants';
import type { IAnyKey } from '../../utils/getFormData';
import { updateResultsThunk } from '../../actions/results/thunks';
import { setFocus } from '../../actions/focus/thunks';
import type { IFocus } from '../../constants/focus/actionTypes';
import type { ASTRUMS } from '../../constants/API/constant';
import type { IAstrum, IAstrumState } from '../../constants/austrum/interfaces';
import { updateAstrumThunk } from '../../actions/astrum/thunks';
import { defaultAustrum } from '../../constants/austrum/constant';
import type { ThunkDispatch } from 'redux-thunk';
import type { Action } from 'redux';
import type { RouteComponentProps } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import { MSD_URLS } from '../../services/socket/urls';
import { msd } from '../../constants/mixpanelAnalytics';
import ScenarioUploader from '../../components/ScenarioUploader';
import { toTimezone } from 'utils/common/dateUtils';
import { analyticsClient } from 'utils/hooks/analytics/useAnalytics';

const pageKey = 'GSP';

interface IStateProps {
  mission: IMission;
  user: IUserInformation;
  groundStations: IGSState;
  geometry: IGeometryObjectState;
  groups: IGroup[];
  pointsOfInterest: IPIState;
  regionsOfInterest: IRIState;
  visiblePopUp: boolean;
  satelliteOrbits: ISTState;
  results: ISimulateState;
  focus?: IFocus;
  astrums: IAstrumState;
}

interface IDispatchProps {
  updateGroupSB: Function;
  getMissions: Function;
  updateMission: Function;
  uploadData: Function;
  setPopUp: Function;
  addGroundStation: Function;
  updateGroundStation: Function;
  showGroundStation: Function;
  removeGroundStation: Function;
  addSO: Function;
  updateSO: Function;
  removeAllSO: Function;
  updateResultsThunk: Function;
  setFocus?: Function;
  updateAstrumThunk: (astrum: IAstrum) => void;
}

type OverlayType = google.maps.drawing.OverlayType;

interface IMissionGeometryPageState {
  map: IMapWrapper;
  isOpenOptimizationResult: boolean;
  isOpenSimulation: boolean;
  drawingMode: OverlayType;
  days: {
    value: number;
    error: boolean;
  };
}

type IProps = IStateProps &
  IDispatchProps &
  WithTranslation &
  RouteComponentProps;

class GroundSegmentPage extends React.Component<
  IProps,
  IMissionGeometryPageState
> {
  public constructor(props: IProps) {
    super(props);
    this.state = {
      map: null,
      drawingMode: null,
      isOpenOptimizationResult: false,
      isOpenSimulation: false,
      days: {
        value: this.props.mission
          ? this.props.mission.days
          : DAYS_INPUT.defaultValue,
        error: false,
      },
    };
  }

  public componentDidMount(): void {
    analyticsClient.startTracking()({
      type: msd.GROUND_SEGMENT.VISIT,
      action: 'Visit',
      item: 'Ground segment page',
      module: 'MSD',
    });

    if (!this.props.mission) {
      this.props.getMissions();
    }
    if (this.props.astrums) {
      const astrum = this.props.astrums[pageKey];

      this.props.updateAstrumThunk(astrum || defaultAustrum);
    }
  }

  public componentWillUnmount(): void {
    analyticsClient.stopTracking()({
      type: msd.GROUND_SEGMENT.VISIT,
      action: 'Visit',
      item: 'Ground segment page',
      module: 'MSD',
    });
  }

  public componentDidUpdate(prevProps: Readonly<IProps>): void {
    if (this.props.mission !== prevProps.mission) {
      const days = {
        value: this.props.mission.days,
        error: false,
      };
      this.setState({ days });
    }
  }

  private handleShowSimulate = () => {
    analyticsClient.sendInfo()({
      type: msd.GROUND_SEGMENT.SIMULATION.START,
      action: 'Start simulation',
      item: 'Ground segment page simulation',
      module: 'MSD',
      additionalParams: {
        'Ground Stations': this.props.groundStations.list,
      },
    });
    this.setState({ isOpenSimulation: true });
    if (this.props.results) {
      this.handleOnComplete(null);
    }
  };

  private handleShowOptimize = () => {
    this.setState({ isOpenOptimizationResult: true });
  };

  private handleOnClickLogo = () => {
    this.handleOnComplete(null);
  };
  private handleOnComplete = (data: object) => {
    const { updateResultsThunk, mission, groundStations } = this.props;
    updateResultsThunk(
      {
        mission,
        groundStations,
      },
      data
    );
  };

  private handleContinue = () => {
    analyticsClient.sendInfo()({
      type: msd.GROUND_SEGMENT.SIMULATION.CONTINUE,
      action: 'Continue simulation',
      item: 'Ground segment page simulation',
      module: 'MSD',
      additionalParams: {
        'Ground Stations': this.props.groundStations.list,
      },
    });
    this.handleOnComplete({ simulated: false });
    this.props.history.push('/msd');
  };

  protected saveResults = (): void => {
    const { groundStations } = this.props;
    const json = JSON.stringify(
      {
        groundStations,
      },
      null,
      2
    );
    downloadFile(json, GROUND_SEGMENT_FILE_NAME, 'application/json');
  };
  protected uploadResults = (event: ChangeEvent<HTMLInputElement>): void => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (evt): void => {
        try {
          const data = JSON.parse(evt.target.result as string) as IData;
          this.props.uploadData(data, ['groundStations']);
        } catch (e) {
          console.error(e);
          showErrorMessage(
            `File data is incorrect! Error: ${
              (e as { message?: string }).message
            }`
          );
        }
      };
      reader.readAsText(file);
    }
  };

  private renderMap = () => {
    return (
      <MapContainer
        pageKey={pageKey}
        mapId={MAP_ID}
        mapType={MAP_TYPE.GROUND_SEGMENT}
        onMapLoaded={(map) => this.setState({ map })}
      />
    );
  };

  private renderOptimize = (onClose: Function) => (
    //TODO: not finished
    <Results onClose={onClose} />
  );

  private renderCompute = (
    user: IUserInformation,
    mission: IMission,
    astrum: ASTRUMS,
    days: number,
    geometry: IGeometryObjectState,
    groundStations: IGSState,
    onClose: Function,
    onComplete: Function,
    addSO: Function,
    updateSO: Function,
    removeAllSO: Function,
    satelliteOrbits: ISTState,
    predefinedData?: ISimulateState
  ) => {
    if (!user || !mission) {
      return null;
    }

    const computeData = computeGroundSegment(
      { ...mission, date: toTimezone(mission.date, true) },
      days,
      geometry,
      groundStations,
      astrum
    );

    return (
      <Simulation
        mission={mission}
        user={user}
        computeData={computeData}
        onClose={onClose}
        onComplete={onComplete}
        addSO={addSO}
        updateSO={updateSO}
        removeAllSO={removeAllSO}
        satellites={satelliteOrbits}
        predefinedData={predefinedData}
        url={MSD_URLS.EVENT_ANALYSIS}
        analytics={msd.GROUND_SEGMENT.SIMULATION}
      />
    );
  };

  public render(): ReactElement {
    const {
      t,
      user,
      mission,
      geometry,
      groundStations,
      setPopUp,
      addGroundStation,
      updateGroundStation,
      removeGroundStation,
      addSO,
      updateSO,
      removeAllSO,
      satelliteOrbits,
      showGroundStation,
      results,
      focus,
      setFocus,
    } = this.props;
    const { map, isOpenOptimizationResult, isOpenSimulation, days } =
      this.state;

    const isOptimize = Boolean(
      groundStations.list.find((gs: IGroundStation) => gs.optimize)
    );
    const isSelect = Boolean(
      groundStations.list.find((gs: IGroundStation) => gs.select)
    );

    return (
      <>
        <RedirectToMainPage />
        <DefaultLayout
          onClickLogo={this.handleOnClickLogo}
          className={`mission-geometry-page ${
            this.props.visiblePopUp ? 'openedPopUp' : ''
          }`}
        >
          <div className="left-layout">
            <div className="big-part">
              <BlockOverlay disable={isOpenSimulation}>
                <GroundStations
                  groundStations={groundStations}
                  setPopUp={setPopUp}
                  addGroundStation={addGroundStation}
                  removeGroundStation={removeGroundStation}
                  updateGroundStation={updateGroundStation}
                  showGroundStation={showGroundStation}
                  focus={focus}
                  setFocus={setFocus}
                />
              </BlockOverlay>
            </div>
            <div className="small-part transparent" />
          </div>
          <div className="center-layout map-layout">{this.renderMap()}</div>
          <div className="right-layout">
            <div className="big-part" />
            <div className="small-part">
              <div className="bottom-element">
                <div className="bp4-dialog-footer-actions">
                  <div className="mission-geometry-button-group">
                    <Tooltip
                      className={isOptimize ? 'hidden' : ''}
                      position={Position.TOP}
                      disabled={isSelect}
                      content="Please select a ground station(s) to Simulate results"
                    >
                      <Button
                        className="button"
                        disabled={
                          isOpenSimulation ||
                          !(mission && mission.date) ||
                          days.error
                        }
                        icon="database"
                        intent={Intent.PRIMARY}
                        onClick={this.handleShowSimulate}
                      >
                        Simulate
                      </Button>
                    </Tooltip>
                    <Tooltip
                      className={!isOptimize ? 'hidden' : ''}
                      position={Position.TOP}
                      disabled={isSelect}
                      content={
                        'Please select a ground station(s) to optimize results'
                      }
                    >
                      <Button
                        disabled={true}
                        className="button"
                        icon="calculator"
                        intent="primary"
                        onClick={this.handleShowOptimize}
                      >
                        Optimize
                      </Button>
                    </Tooltip>
                    <Button
                      className="button"
                      icon="saved"
                      intent="none"
                      onClick={this.saveResults}
                    >
                      {t('module_msd.ground_segment.save')}
                    </Button>
                    <ScenarioUploader
                      text="Upload data"
                      handler={this.uploadResults}
                    />
                    <Tooltip
                      className={isOptimize ? 'hidden' : ''}
                      position={Position.TOP}
                      disabled={isSelect}
                      content="Please select a ground station(s) to continue"
                    >
                      <Button
                        className="button"
                        disabled={
                          isOpenSimulation ||
                          !(mission && mission.date) ||
                          days.error
                        }
                        icon="tick"
                        intent={Intent.SUCCESS}
                        onClick={this.handleContinue}
                      >
                        Continue
                      </Button>
                    </Tooltip>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </DefaultLayout>
        {isOpenSimulation &&
          this.renderCompute(
            user,
            mission,
            map.getAstrum(),
            mission.days,
            geometry,
            groundStations,
            (): void => this.setState({ isOpenSimulation: false }),
            (data: object) => this.handleOnComplete(data),
            addSO,
            updateSO,
            removeAllSO,
            satelliteOrbits,
            results
          )}
        {isOpenOptimizationResult &&
          this.renderOptimize((): void =>
            this.setState({ isOpenOptimizationResult: false })
          )}
        <PopUp />
      </>
    );
  }
}

export default connect<IStateProps, IDispatchProps>(
  (state: AppState): IStateProps => ({
    mission: state.missions.find((mission) => mission.current),
    user: state.user,
    geometry: state.geometry,
    pointsOfInterest: state.pointsOfInterest,
    regionsOfInterest: state.regionsOfInterest,
    groups: state.groups,
    groundStations: state.groundStations,
    satelliteOrbits: state.satelliteOrbits,
    results: state.results.msd.groundSegmentPage.data,
    visiblePopUp: state.popUp?.visible,
    focus: state.focus,
    astrums: state.astrum,
  }),
  (
    dispatch: ThunkDispatch<AppState, null, Action<string>>
  ): IDispatchProps => ({
    getMissions: (): void => dispatch(getMissionsThunk()),
    updateMission: (mission: IMission): void =>
      dispatch(updateMissionThunk(mission)),
    updateGroupSB: (group: IGroup): void => dispatch(updateGroupSB(group)),
    uploadData: (data: IData, accessNames: string[]): void =>
      dispatch(uploadData(data, accessNames)),
    setPopUp: (popUp: IPopUp): void => dispatch(setPopUp(popUp)),

    addGroundStation: (): void => dispatch(setGroundStationSpecify()),
    updateGroundStation: (groundStationData: IGroundStation): void =>
      dispatch(updateGS(groundStationData)),
    showGroundStation: (groundStationData: IGroundStation): void =>
      dispatch(showGroundStation(groundStationData)),
    removeGroundStation: (id: string): void => dispatch(removeGS(id)),
    addSO: (satelliteOrbit: ISatelliteData): void =>
      dispatch(addSO(satelliteOrbit)),
    updateSO: (satelliteOrbit: ISatelliteData): void =>
      dispatch(updateSO(satelliteOrbit)),
    removeAllSO: (): void => dispatch(removeAllSO()),
    updateResultsThunk: (hash: IAnyKey, data: object): void =>
      dispatch(updateResultsThunk(RESULTS_PATH, hash, data, undefined)),
    setFocus: (focus: IFocus) => dispatch(setFocus(focus)),
    updateAstrumThunk: (astrum: IAstrum) =>
      dispatch(updateAstrumThunk(astrum, false, pageKey)),
  })
)(withTranslation()(withRouter(GroundSegmentPage)));
