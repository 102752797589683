import type { IAxis } from '../../components/charts/Chart';
import type { IInitData } from '../../components/simulation/Simulation';

export const getAxisData = (
  initData: IInitData,
  axisXType?: string
): IAxis[] => {
  let resArray = initData.data.parameterNames.map((p, index): IAxis => {
    return {
      name: `${p} ${
        initData.data.parameterUnits[index]
          ? `[${initData.data.parameterUnits[index]}]`
          : ''
      }`,
      type: initData.data.parameterTypes[index],
      index,
    };
  });
  switch (axisXType) {
    case 'float':
    case 'double': {
      resArray = resArray.filter(
        (r): boolean =>
          r.type === 'integer' ||
          r.type === 'float' ||
          r.type === 'double' ||
          r.type === 'boolean'
      );
    }
  }
  return resArray.sort((a, b): number => {
    if (a.name < b.name) {
      return -1;
    }
    if (a.name > b.name) {
      return 1;
    }
    return 0;
  });
};
