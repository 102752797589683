import type { ILayerOptions } from './layer';
import { Layer, LayerSourceType } from './layer';

export interface IGeoJSONLayerOptions {
  color: string;
  weight: number;
  fillOpacity: number;
  visible: boolean;
}

export const DefaultSwathLayerOptions = {
  color: ' #FFCC55',
  weight: 2,
  fillOpacity: 0.2,
  visible: true,
};

/**
 * GeoJSON Layer is an arbitrary geoJSON shape displayed on the map
 */
export class SwathLayer<
  T extends { SatelliteId: string; Start: string }
> extends Layer {
  declare options: ILayerOptions & IGeoJSONLayerOptions;
  declare layerClass: string;
  name: string;
  data: GeoJSON.Feature | GeoJSON.FeatureCollection;
  metadata: T;
  description: string | undefined;
  isConfirmed: boolean;

  /**
   * newDataCosmosLayer creates a new datacosoms layer
   */
  constructor(
    sourceType: LayerSourceType,
    name: string,
    data: GeoJSON.Feature | GeoJSON.FeatureCollection,
    metadata: T,
    options?: Partial<ILayerOptions & IGeoJSONLayerOptions>,
    isConfirmed?: boolean
  ) {
    super(sourceType);

    this.name = name;
    this.data = data;
    this.metadata = metadata;
    this.options = {
      ...this.options,
      ...DefaultSwathLayerOptions,
      ...options,
    };
    this.isConfirmed = Boolean(isConfirmed);
    this.layerClass = 'SwathLayer';
  }

  /**
   * Returns a copy of the current object with the selected options updates.
   */
  cloneWithOptions(options: Partial<ILayerOptions & IGeoJSONLayerOptions>) {
    const newObject = this.clone();
    newObject.options = { ...newObject.options, ...options };
    return newObject;
  }

  /**
   * Returns a copy of the current object with the new name.
   */
  cloneWithName(name: string) {
    const newObject = this.clone();
    newObject.name = name;
    return newObject;
  }

  /**
   * Returns a copy of the current object with the new metadata.
   */
  cloneWithMetadata(metadata: T) {
    const newObject = this.clone();
    newObject.metadata = metadata;
    return newObject;
  }

  getName() {
    return this.name || this.id;
  }

  getSateliteId() {
    return this.metadata.SatelliteId;
  }

  getStartTime() {
    return this.metadata.Start;
  }

  getDescription() {
    if (this.description) {
      return this.description;
    }

    if (this.sourceType === LayerSourceType.TASKING_OPPORTUNITIES) {
      return 'Swath';
    }

    if (this.sourceType === LayerSourceType.TASKING_REGIONS) {
      return 'Region of interest';
    }

    return '';
  }

  cloneWithConfirmedStatus(confirmed: boolean) {
    const newObject = this.clone();
    newObject.isConfirmed = confirmed;
    return newObject;
  }

  getIsConfirmed() {
    return this.isConfirmed;
  }

  getPopupContent() {
    return `${this.getName()}, ${
      this.getIsConfirmed()
        ? `Confirmed ${this.getDescription()}`
        : this.getDescription()
    }\n Right click the layer for more options`;
  }

  containsSTACItem() {
    return false;
  }

  containsSTACAsset() {
    return false;
  }

  containsBandAlgebra() {
    return false;
  }

  sza(): Number | undefined {
    return undefined;
  }

  href(): string | undefined {
    return undefined;
  }
}
