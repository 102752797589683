export const printStyles = `
a {
  color: black;
  text-decoration: none;
  text-align: end;
}

pre {
  padding: 0;
  margin: 0;
}

@page {
  size: A4; /* Change from the default size of A4 */
  margin: 2cm 2cm 2.7cm 2cm;
}

@page {
  @top-left {
    font-family: 'Open Sans', sans-serif;
    content: 'key_open_cosmos_email';
    height: 1cm;
    text-align: center;
    font-size: 8pt;
  }

  @top-right {
    font-family: 'Open Sans', sans-serif;
    content: url('key_top_right_logo');
    width: 150px;
    height: auto;
  }

  @bottom-left {
    font-family: 'Open Sans', sans-serif;
    content: 'key_user_mission_timestamp';
    width: 70%;
    border-top: 1px solid;
    height: 1cm;
    font-size: 8pt;
  }
  @bottom-right {
    font-family: 'Open Sans', sans-serif;
    width: 30%;
    border-top: 1px solid;
    content: 'key_open_cosmos_copyright key_full_year\A ''Page ' counter(page) ' of '
      counter(pages);
    white-space: pre;
    height: 1cm;
    font-size: 8pt;
  }
}

@page :first {
  @top-right: {
    font-family: 'Open Sans', sans-serif;
    content: '';
    background-image: none;
  };
  @top-left: {
    font-family: 'Open Sans', sans-serif;
    content: '';
  };
  @bottom-right: {
    font-family: 'Open Sans', sans-serif;
    border-top: none;
    content: '';
  };
  @bottom-left: {
    font-family: 'Open Sans', sans-serif;
    border-top: none;
    content: '';
  };
}

@media print {
  body > * {
    background-color: white !important;
  }

  .page {
    display: contents;
  }

  .no-print {
    display: none !important;
  }

  .summary-body {
    border: unset !important;
  }

  .summary-body-body {
    font-family: 'Open Sans', sans-serif;
    width: 100% !important;
    padding: 0 !important;
    max-height: unset !important;
    overflow: unset !important;
  }

  .section {
    font-family: 'Open Sans', sans-serif;
    break-inside: avoid;
  }

  .chapter {
    font-family: 'Open Sans', sans-serif;
    page-break-before: always;
    break-inside: avoid;
  }

  .summary-text {
    text-align: justify;
  }

  .summary-page-header {
    display: none;
  }

  .summary-page-footer {
    display: none;
  }

  .summary-logo-img {
    width: 60%;
    margin: 20% 20% 0 20%;
    object-fit:contain;
  }

  .summary-index-line {
    display: flex;
  }

  .summary-index-line-label {
    width: 90%;
    text-align: left;
    word-break: break-word;
  }

  .summary-index-line-page-wrap {
    width: 10%;
    display: flex;
  }

  .summary-index-line-page {
    width: 100%;
    align-self: flex-end;
    text-align: right;
  }

  .summary-index-line-page::after {
    content: target-counter(attr(href url), page);
  }

  .summary-index-line-bold {
    font-weight: bold;
    margin-top: 5px;
  }

  .summary-copyright {
    margin-top: 40%;
  }

  .summary-copyright > * {
    font-size: 10px;
  }

  .text-center {
    text-align: center;
  }

  .no-margin {
    margin: 0;
  }

  .copyright-relevant {
    font-weight: bold;
  }

  .summary-table {
    font-size: 12px;
    margin: 10px 0 20px 0;
    -webkit-print-color-adjust: exact;
    border-spacing: 0;
  }

  .summary-table-cell {
    border-top: 1px solid black;
    border-left: 1px solid black;
  }

  .summary-table-cell-last-column {
    border-right: 1px solid black;
  }

  .summary-table-cell-last-row {
    border-bottom: 1px solid black;
  }

  .end {
    width: 100%;
    text-align: center;
    margin: 40% auto auto;
  }

  .title-1 {
    font-size: 20px;
    font-family: 'Monda', sans-serif;
    color: rgb(49, 107, 167);
  }

  .title-2 {
    font-size: 16px;
    font-family: 'Monda', sans-serif;
    color: rgb(49, 107, 167);
  }

  .title-3 {
    font-size: 14px;
    font-family: 'Monda', sans-serif;
    color: rgb(49, 107, 167);
  }

  .title-4 {
    font-size: 12px;
    font-family: 'Monda', sans-serif;
    color: rgb(49, 107, 167);
  }

  .title-5 {
    font-size: 12px;
    font-family: 'Monda', sans-serif;
    color: rgb(49, 107, 167);
  }

  .title-6 {
    font-size: 12px;
    font-family: 'Monda', sans-serif;
    font-style: italic;
    color: rgb(49, 107, 167);
  }
}
`;
