import { Field } from 'redux-form';
import type { IOption } from '../../../inputField/SelectField';
import SelectField from '../../../inputField/SelectField';
import { getRow } from '../../getRowWithConstraints';
import React from 'react';
import type { IAnyKey } from '../../../../utils/getFormData';
import { SEPARATOR } from '../../../../constants/popUp/constants';

interface IRenderGroupNames {
  groupNamesWithOption: {
    options: IOption[];
    groupNames: string[][];
  };
  constraints: object & IAnyKey;
  selectedGroup: IAnyKey;
  index: number;
  setSelectedGroup: Function;
  astrum: string;
  change?: Function;
}

export const renderGroupName = (props: IRenderGroupNames) => {
  const {
    groupNamesWithOption,
    change,
    setSelectedGroup,
    selectedGroup,
    index,
  } = props;
  return (
    <>
      <tr key={index}>
        <td colSpan={6}>
          <Field
            name={`group-${index}_${groupNamesWithOption.options
              .map((o) => o.value.split(SEPARATOR)[0])
              .join('_')}`}
            onChangeState={(arg: string) => {
              setSelectedGroup({
                ...selectedGroup,
                [index.toString()]: groupNamesWithOption.options.findIndex(
                  (o) => o.value === arg
                ),
              });
            }}
            title={`Synchronous params`}
            options={groupNamesWithOption.options}
            component={SelectField}
          />
        </td>
      </tr>
      {groupNamesWithOption.groupNames[selectedGroup[index]].map((name) => {
        const { measurement, measurementType } =
          props.constraints[name.split(SEPARATOR)[0] as string];
        return getRow(
          name as string,
          props.constraints,
          change,
          measurement,
          measurementType,
          props.astrum
        );
      })}
    </>
  );
};
