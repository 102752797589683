import React from 'react';
import { PAGE } from 'constants/summary/page';
import type { IUserInformation } from 'constants/user/actionTypes';
import { getFormattedDate } from 'utils/common/getFormattedDate';
import logo from 'images/logo-centric-name-compact.svg';
import { dateAsUTCDateObject } from 'utils/common/dateUtils';

interface ILogoPageProps {
  date: Date;
  title: string;
  user: IUserInformation;
}

export class SummaryLogoPage extends React.PureComponent<ILogoPageProps> {
  public componentDidMount(): void {
    const img = document.getElementById('logo-image');
    img.setAttribute('src', logo);
  }

  public render(): React.ReactNode {
    const { date, user, title } = this.props;
    return (
      <div className="page page-hidden">
        <div className="chapter">
          <img
            id="logo-image"
            className="summary-logo-img"
            src={logo}
            alt="logo-centric-name-compact.svg"
          />
          <h1 className="text-center">{title}</h1>
          <h4 className="text-center no-margin">{`User: ${user.nickname}`}</h4>
          <h4 className="text-center no-margin">{`Date: ${getFormattedDate(
            dateAsUTCDateObject(new Date())
          )}`}</h4>
          <div className="summary-copyright">
            <h2>{PAGE.TITLE}</h2>
            <p>{`${PAGE.COPYRIGHT} ${date.getUTCFullYear()}`}</p>
            <p>{PAGE.P1}</p>
            <p>{PAGE.P2}</p>
            <p>{PAGE.P3}</p>
            <p>{PAGE.SUPPORT_EMAIL}</p>
            <p className="copyright-relevant">{PAGE.P4_RELEVANT}</p>
            <p>{PAGE.P5_RELEVANT}</p>
            <p>{PAGE.P6_RELEVANT}</p>
          </div>
        </div>
      </div>
    );
  }
}
