import type {
  IRegionOfInterest,
  regionOfInterestTypes,
  IRIState,
} from '../../constants/regionsOfInterest/actionTypes';
import {
  RI_ADD,
  RI_UPDATE,
  RI_REMOVE,
  RI_UPLOAD,
} from '../../constants/regionsOfInterest/actionTypes';
import { generateId } from '../../utils/common/generateId';
import { FocusTypes } from '../../constants/focus/actionTypes';

export function addRegionOfInterest(
  regionOfInterest: IRegionOfInterest
): regionOfInterestTypes {
  const id = regionOfInterest.id
    ? regionOfInterest.id
    : generateId(FocusTypes.RI_SUB_ID);
  return {
    type: RI_ADD,
    regionOfInterest: { id, type: FocusTypes.RI_SUB_ID, ...regionOfInterest },
  };
}

export function updateRegionOfInterest(
  regionOfInterest: IRegionOfInterest
): regionOfInterestTypes {
  return {
    type: RI_UPDATE,
    regionOfInterest: { type: FocusTypes.RI_SUB_ID, ...regionOfInterest },
  };
}

export function removeRegionOfInterest(id: string): regionOfInterestTypes {
  return {
    type: RI_REMOVE,
    id,
  };
}

export function uploadRegionsOfInterest(
  regionsOfInterest: IRIState
): regionOfInterestTypes {
  return {
    type: RI_UPLOAD,
    regionsOfInterest,
  };
}
