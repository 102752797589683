import Modal from '_atoms/Modal/Modal';
import Button from '_molecules/Button/Button';
import React, { useMemo } from 'react';
import { OverlayContainer } from 'react-aria';
import {
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip as ChartTooltip,
  AreaChart,
  Area,
  ReferenceLine,
  Legend,
} from 'recharts';
import Tabs from '_molecules/Tabs/Tabs';
import { Item } from 'react-stately';
import type { IHistogramData } from 'datacosmos/utils/hooks/useStacInfo';
import { useLocalisation } from 'utils/hooks/useLocalisation';
import DangerButton from '_molecules/Button/DangerButton';
import { NonIdealState, Spinner } from '@blueprintjs/core';
import { DetailsItem } from 'pages/ops/Scheduling/ScheduleActivityDetails/DetailCards/DetailsItem';
import { useTheme } from 'datacosmos/stores/ThemeProvider';
import type { IAsset } from 'datacosmos/types/stac-types';
import Tooltip from '_atoms/Tooltip/Tooltip';

export type PropertiesData = {
  minimumPixelValue: number | null;
  maximumPixelValue: number | null;
  meanValue: number | null;
  stdValue: number | null;
  varianceValue: number | null;
  percentile25Value: number | null;
  percentile75Value: number | null;
  [name: string]: number | null;
};

type Props = {
  /**
   * Text to display on the button. This button opens the modal.
   */
  buttonText: string;

  /**
   * Whether or not the button is disabled
   */
  buttonDisabled: boolean;
  /**
   * Stac item assets to display the statistics for
   * The key is the band name and the value is the asset object
   */
  assetsToDisplay: { [key: string]: IAsset };
  /**
   * Function to call when a band tab is clicked
   * Expected to be used as a handler for specific asset statistics fetching
   * @param band band that was clicked
   */
  onAssetClick: (band: string) => void;
  /**
   * Key of the asset that is currently selected
   */
  selectedAssetKey: string | undefined;
  /**
   * Whether or not the metadata is being fetched
   */
  isFetchingMetadata: boolean;
  /**
   * Whether or not the modal is open
   */
  isOpen: boolean;
  /**
   * Function to set the modal open state
   * @param isOpen state to set
   */
  setIsOpen: (isOpen: boolean) => void;
  /**
   * Histogram data to display
   */
  histogramData: IHistogramData[] | null;
  /**
   * Title of the modal
   */
  modalTitle: string;
  /**
   * Properties data to display. This is the data that is displayed below the histogram
   */
  propertiesData: PropertiesData;
  /**
   * Function to get the color of the histogram based on the color in the histogram data.
   * Data will usually be labeled with a certain color, such as `redX`, `redY`, `greenX`, `greenY`, etc.
   * This function should return the color based on the label.
   * @param data label of the data e.g. `red`, `green`, `blue`, etc.
   * @returns color of the histogram
   */
  getHistogramColor: (
    data: string
  ) => '#FF695E' | '#5ABE96' | '#5a89be' | '#E79531';
  /**
   * Handler function to get the statistics for the AOI. This function should draw the AOI on the map and then call the `fetchAssetsMetadata` function.
   */
  getStatisticsForAoiHandler: () => Promise<void>;

  /**
   * Handler function to remove the statistics AOI from the map.
   * This function should remove the AOI from the map and then call the `fetchAssetsMetadata` function.
   */
  removeStatisticsAoiHandler: () => Promise<void>;
  /**
   * Whether or not the statistics AOI is present on the map
   */
  isStatisticsAoiPresent: boolean;

  /**
   * Element to display next to the title.
   * Can be used to render an action button, such as a screenshot action button.
   */
  titleElement?: React.ReactNode;

  /**
   * Optional ref to set. Useful for screenshots
   */
  popupRef?: React.RefObject<HTMLDivElement>;
};

/**
 * Popup to display the statistics of the assets. This popup contains a histogram and some summary statistics.
 * It also contains a button to draw an AOI on the map to get the statistics for that AOI.
 */
const StatisticsPopup = ({
  histogramData,
  modalTitle,
  propertiesData,
  getHistogramColor,
  getStatisticsForAoiHandler,
  isStatisticsAoiPresent,
  isOpen,
  setIsOpen,
  removeStatisticsAoiHandler,
  buttonText,
  isFetchingMetadata,
  assetsToDisplay: bandsToDisplay,
  onAssetClick: onBandClick,
  buttonDisabled,
  selectedAssetKey,
  titleElement,
  popupRef,
}: Props) => {
  const { translate, translateWithExplicitFallback } = useLocalisation();

  const { isDarkmode } = useTheme();

  const isLoading = useMemo(() => isFetchingMetadata, [isFetchingMetadata]);

  const getTabLabels = () => {
    if (histogramData?.length && histogramData.length <= 0) {
      return [];
    }

    if (histogramData?.[0] === undefined) {
      return [];
    }

    return [
      ...new Set(
        Object.keys(histogramData[0]).map((k) => k.replace(/X|Y/, ''))
      ),
    ];
  };

  const withNoStatistics = (children: React.ReactNode) => {
    if (!histogramData) {
      return (
        <NonIdealState
          title={
            <span>
              {translate(
                'datacosmos.catalogAndItems.metadata.noStatistics.title'
              )}
            </span>
          }
          description={translate(
            'datacosmos.catalogAndItems.metadata.noStatistics.description'
          )}
          icon="info-sign"
          className="text-surface-contrast dark:text-surface-dark-contrast"
        />
      );
    }

    return children;
  };

  const withLoadingSpinner = (children: React.ReactNode) => {
    if (isLoading) {
      return <Spinner size={36} />;
    }

    return children;
  };

  const getBandSpecificHistogram = (band: string) => {
    return (
      <>
        <div className="flex justify-center">
          <AreaChart
            data={histogramData ?? []}
            height={330}
            width={450}
            barSize={'25px'}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis
              dataKey={`${band}X`}
              stroke={isDarkmode ? '#E6E1DC' : '#191919'}
              tickFormatter={(d: string) => Number(d).toFixed(2)}
              domain={[0, 'maxData']}
              type="number"
              allowDataOverflow={true}
              tickSize={3}
              tick={{ fontSize: 13 }}
              label={{
                value: translate(
                  'datacosmos.catalogAndItems.metadata.xAxisLabel'
                ),
                position: 'insideBottom',
                dy: 6,
                className: 'dark:fill-surface-dark-contrast',
              }}
            />
            <YAxis
              dataKey={`${band}Y`}
              stroke={isDarkmode ? '#E6E1DC' : '#191919'}
              tickSize={1}
              tick={{ fontSize: 13 }}
              label={{
                value: translate(
                  'datacosmos.catalogAndItems.metadata.yAxisLabel'
                ),
                position: 'insideLeft',
                angle: -90,
                dy: 20,
                dx: -5,
                className: 'dark:fill-surface-dark-contrast',
              }}
            />
            <Area
              dataKey={`${band}Y`}
              stroke={getHistogramColor(band)}
              fill={getHistogramColor(band)}
              legendType="none"
            />
            {propertiesData.meanValue && (
              <>
                <ReferenceLine
                  x={Number(propertiesData.meanValue)}
                  stroke={isDarkmode ? '#ffffff' : '#17202A'}
                />

                <Area
                  name={`${translate(
                    'datacosmos.catalogAndItems.metadata.meanValue'
                  )} ${propertiesData.meanValue}`}
                  dataKey="null"
                  stroke={isDarkmode ? '#ffffff' : '#17202A'}
                  legendType="plainline"
                />
              </>
            )}
            {propertiesData.percentile25Value && (
              <>
                <ReferenceLine
                  x={Number(propertiesData.percentile25Value)}
                  stroke={'#8E44AD'}
                ></ReferenceLine>

                <Area
                  name={`${translate(
                    'datacosmos.catalogAndItems.metadata.percentile25'
                  )} ${propertiesData.percentile25Value}`}
                  dataKey="null"
                  stroke="#8E44AD"
                  legendType="plainline"
                />
              </>
            )}
            {propertiesData.percentile75Value && (
              <>
                <ReferenceLine
                  x={Number(propertiesData.percentile75Value)}
                  stroke={'#FFCA33'}
                ></ReferenceLine>

                <Area
                  name={`${translate(
                    'datacosmos.catalogAndItems.metadata.percentile75'
                  )} ${propertiesData.percentile75Value}`}
                  dataKey="null"
                  stroke="#FFCA33"
                  legendType="plainline"
                />
              </>
            )}

            <Legend
              wrapperStyle={{
                paddingTop: '14px',
                fontSize: '12px',
              }}
            />
            <ChartTooltip />
          </AreaChart>
        </div>

        <h3 className="text-data-dark border-b dark:text-data-text">
          {translate('datacosmos.catalogAndItems.metadata.summaryStatistics')}
        </h3>

        <div className="grid gap-4 !shadow-none w-max">
          <div className="col-start-1 col-end-2">
            <DetailsItem
              title={translate(
                'datacosmos.catalogAndItems.metadata.minPixelValue'
              )}
              value={String(propertiesData.minimumPixelValue) ?? 'N/A'}
            />
            <DetailsItem
              title={translate(
                'datacosmos.catalogAndItems.metadata.maxPixelValue'
              )}
              value={String(propertiesData.maximumPixelValue) ?? 'N/A'}
            />
          </div>

          <div className="col-start-2 col-end-3 pr-3 border-r border-primary" />

          <div className="col-start-3 col-end-4">
            <DetailsItem
              title={translate('datacosmos.catalogAndItems.metadata.meanValue')}
              value={String(propertiesData.meanValue) ?? 'N/A'}
            />
            <DetailsItem
              title={translate('datacosmos.catalogAndItems.metadata.stdValue')}
              value={String(propertiesData.stdValue) ?? 'N/A'}
            />
            <DetailsItem
              title={translate('datacosmos.catalogAndItems.metadata.variance')}
              value={String(propertiesData.varianceValue) ?? 'N/A'}
            />
          </div>

          <div className="col-start-4 col-end-5 pr-3 border-r border-primary" />

          <div className="col-start-5 col-end-6">
            <DetailsItem
              title={translate(
                'datacosmos.catalogAndItems.metadata.percentile25'
              )}
              value={String(propertiesData.percentile25Value) ?? 'N/A'}
            />
            <DetailsItem
              title={translate(
                'datacosmos.catalogAndItems.metadata.percentile75'
              )}
              value={String(propertiesData.percentile75Value) ?? 'N/A'}
            />
          </div>
        </div>
      </>
    );
  };

  const getHistogram = () => {
    const labels = getTabLabels();

    // If there's only one label, don't show the tabs
    if (labels.length === 1) {
      return getBandSpecificHistogram(labels[0]);
    }

    return (
      <Tabs customPadding="12px">
        {labels.map((element) => (
          <Item
            title={translateWithExplicitFallback(
              `datacosmos.catalogAndItems.metadata.bandColors.${element}` as unknown as TemplateStringsArray,
              element
            )}
            key={element}
          >
            {getBandSpecificHistogram(element)}
          </Item>
        ))}
      </Tabs>
    );
  };

  return (
    <>
      <OverlayContainer>
        {isOpen && (
          <Modal
            title={modalTitle}
            isDismissable={true}
            shouldCloseOnBlur={false}
            isOpen={isOpen}
            onClose={() => setIsOpen(false)}
            titleElement={titleElement}
            modalRef={popupRef}
          >
            {
              <>
                <Tabs selectedItemKey={selectedAssetKey} customPadding="20px">
                  {Object.entries(bandsToDisplay)
                    .filter(([_, obj]) => obj.roles?.includes('data'))
                    .map(([bandKey, bandObj]) => (
                      <Item
                        title={
                          <Tooltip
                            content={bandObj.title ?? ''}
                            delay={0}
                            placement="top"
                          >
                            <span
                              className="text-ellipsis whitespace-nowrap"
                              onClick={() => onBandClick(bandKey)}
                            >
                              {bandKey}
                            </span>
                          </Tooltip>
                        }
                        key={bandKey}
                      >
                        {withLoadingSpinner(withNoStatistics(getHistogram()))}
                      </Item>
                    ))}
                </Tabs>

                {isStatisticsAoiPresent ? (
                  <div>
                    <DangerButton
                      text={translate(
                        'datacosmos.buttons.removeAoiForStatistics'
                      )}
                      icon="Trash"
                      onPress={async () => {
                        await removeStatisticsAoiHandler();
                      }}
                      className="draw-aoi-btn"
                    />
                  </div>
                ) : (
                  <Button
                    text={translate('datacosmos.buttons.drawAoiForStatistics')}
                    icon="AddAoi"
                    onPress={async () => {
                      setIsOpen(false);
                      await getStatisticsForAoiHandler();
                      setIsOpen(true);
                    }}
                  />
                )}
              </>
            }
          </Modal>
        )}
      </OverlayContainer>

      <Button
        text={buttonText}
        icon={'ChevronRight'}
        iconPlacement="right"
        onPress={() => setIsOpen(true)}
        disabled={buttonDisabled}
      />
    </>
  );
};

export default StatisticsPopup;
