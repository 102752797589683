import type { IMapWrapper } from 'declarations/mapDeclarations/Map';
import type { IAstrum } from 'constants/austrum/interfaces';
import type { IContextMenuSub } from 'services/mapService/contextMenu/map/services';
import { TILES_URL } from 'env';

export interface IMapTypeOptions extends IAstrum, IContextMenuSub {
  name: string;
  url: string;
  tileSize: { x: number; y: number };
  alt: string;
  radius: number;
  projection?: object | any;
}

function capitalizeFirstLetter(string: string): string {
  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
}

// Normalizes the coords that tiles repeat across the x axis (horizontally)
// like the standard Google map tiles.
function getNormalizedCoord(coord: { x: number; y: number }, zoom: number) {
  const y = coord.y;
  let x = coord.x;

  // tile range in one direction range is dependent on zoom level
  // 0 = 1 tile, 1 = 2 tiles, 2 = 4 tiles, 3 = 8 tiles, etc
  const tileRange = 1 << zoom;
  // var tileRange = 1;

  // don't repeat across y-axis (vertically)
  if (y < 0 || y >= tileRange) {
    return null;
  }

  // repeat across x-axis
  if (x < 0 || x >= tileRange) {
    x = ((x % tileRange) + tileRange) % tileRange;
  }

  return { x, y };
}

export function registerMapType(map: IMapWrapper, options: IMapTypeOptions) {
  const { url, projection, radius, tileSize, name, alt, mapOption } = options;
  const mapType = new google.maps.ImageMapType({
    getTileUrl: function (coord: { x: number; y: number }, zoom: number) {
      const normalizedCoord = getNormalizedCoord(coord, zoom);
      if (!normalizedCoord) {
        return null;
      }
      // return url;
      return `${TILES_URL}/${url}/${zoom}/${coord.x}/${coord.y}.png`;
    },
    tileSize: new google.maps.Size(tileSize.x, tileSize.y),
    ...mapOption,
    name: capitalizeFirstLetter(name),
    alt,
  });
  mapType.radius = radius;

  if (projection) {
    mapType.projection = projection;
  } else {
    const { tileSize } = options;
    mapType.projection = {
      fromLatLngToPoint(latLng: google.maps.LatLng): google.maps.Point {
        return new google.maps.Point(
          tileSize.x * (0.5 + latLng.lng() / 360),
          tileSize.y * (0.5 + -latLng.lat() / 180)
        );
      },
      fromPointToLatLng(
        pixel: google.maps.Point,
        noWrap?: boolean
      ): google.maps.LatLng {
        return new google.maps.LatLng(
          -(pixel.y / tileSize.y - 0.5) * 180,
          (pixel.x / tileSize.x - 0.5) * 360,
          noWrap
        );
      },
    };
  }

  map.mapTypes.set(options.name, mapType);

  return map;
}
