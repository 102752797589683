import { has, split, pipe, slice, get, omit } from 'lodash/fp';

export interface IJsonSchema {
  $schema?: string;
  $ref?: string;
  items?: string;
  definitions?: {
    [key: string]: {
      required?: string[];
      properties?: {
        [key: string]: {
          $schema?: string;
          $ref?: string;
          maximum?: number;
          minimum?: number;
          type?: string;
          title?: string;
          description?: string;
          format?: string;
          enum?: string[];
          media?: {
            binaryEncoding: string;
            ocSortingPreference: number;
            ocMeta: null;
            ocSecret: boolean;
          };
        };
      };
      additionalProperties?: boolean;
      type?: string;
    };
  };
  required?: string[];
  properties?: {
    [key: string]: {
      [key: string]: IJsonSchema;
    };
  };
  additionalProperties?: boolean;
  type?: string;
}

const getRefPath = (path: string) => (schema: IJsonSchema) =>
  pipe(get(path), split('/'), (it) => slice(1, it.length, it))(schema);

const dereference = (schema: IJsonSchema) => {
  if (!has('$ref', schema) && !has('items', schema)) return schema;
  let refPath = '$ref';
  const { type } = schema;

  if (has('items', schema)) refPath = 'items.$ref';

  return pipe(
    getRefPath(refPath),
    (path) => get(path, schema),
    (item) => (type === 'array' ? { ...item, type } : item),
    (items) => Object.assign({}, schema, items),
    omit('$ref')
  )(schema);
};

export default dereference;
