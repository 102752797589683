import { InputGroup, Popover } from '@blueprintjs/core';
import { DAYS_INPUT } from '../../constants/msd/geometry/constants';
import type { ChangeEventHandler } from 'react';

interface IDaysInputProps {
  days: {
    value: number | string;
    error: boolean;
  };
  handleDaysChange: ChangeEventHandler<HTMLInputElement>;
}

export const DaysInput = (props: IDaysInputProps) => {
  const { days, handleDaysChange } = props;

  let msg: string = `Error: value has to be [${DAYS_INPUT.min}, ${DAYS_INPUT.max}]`;
  let warn: boolean = false;

  // @ts-expect-error
  if (days.value > DAYS_INPUT.warn && days.value <= DAYS_INPUT.max) {
    msg = `Warning: simulations longer than ${DAYS_INPUT.warn} days may fail to complete`;
    warn = true;
  }

  return (
    <div className="date-container">
      <div className="day-button white">
        <h3 className="text">Days</h3>
        <Popover
          content={
            (days.error && (
              <div className="bp4-callout bp4-intent-danger bp4-icon-error">
                {msg}
              </div>
            )) ||
            (warn && (
              <div className="bp4-callout bp4-intent-warning bp4-icon-error">
                {msg}
              </div>
            ))
          }
          interactionKind={'hover'}
        >
          <InputGroup
            className="input"
            intent={days.error ? 'danger' : 'none'}
            value={days.value.toString()}
            onChange={handleDaysChange}
          />
        </Popover>
      </div>
    </div>
  );
};
