import { AnchorButton, Button, Intent } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import type { GatewayNetworkConfig, MissionTopology } from 'api/gateway/types';
import React, { useState } from 'react';
import useUpdateEffect from 'utils/hooks/useUpdateEffect';
import AppCard from './AppCard';

interface IProps {
  protocolToEdit: GatewayNetworkConfig;
  handleProtocolEdit: (protocol: GatewayNetworkConfig) => void;
  setShouldDisableSaveButton: React.Dispatch<React.SetStateAction<boolean>>;
}

const LibraryNetworkTopology = ({
  protocolToEdit,
  handleProtocolEdit,
  setShouldDisableSaveButton,
}: IProps) => {
  const [apps, setApps] = useState<MissionTopology>(
    protocolToEdit.mission_topology
  );

  const [moreContent, setMoreContent] = useState<string[]>([]);

  const addNewApp = () => {
    setShouldDisableSaveButton(true);
    setApps({
      ...apps,
      [``]: {
        endpoints: [],
        owner: '',
        bypass_decoding_protocol: false,
      },
    });
  };

  useUpdateEffect(() => {
    const modifiedProtocol: GatewayNetworkConfig = {
      ...protocolToEdit,
      mission_topology: apps,
    };

    handleProtocolEdit(modifiedProtocol);
  }, [apps]);

  return (
    <>
      <div className="flex items-center justify-between mb-4">
        <span style={{ fontSize: '45px' }}>Network topology</span>
        <Button
          text={moreContent.length === 0 ? 'Open all' : 'Close all'}
          icon={moreContent.length === 0 ? 'chevron-down' : 'chevron-up'}
          onClick={() =>
            setMoreContent(
              moreContent.length === 0 ? Object.keys(apps) : ([] as string[])
            )
          }
        />
      </div>

      <div
        style={{
          maxHeight: '90%',
          minHeight: '90%',
          paddingBottom: '100px',
          overflow: 'auto',
        }}
      >
        {Object.keys(apps).map((appName) => (
          <div
            key={appName}
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              gap: '10px',
            }}
          >
            <AppCard
              apps={apps}
              appName={appName}
              setApps={setApps}
              moreContent={moreContent}
              setMoreContent={setMoreContent}
              setShouldDisableSaveButton={setShouldDisableSaveButton}
            />

            <AnchorButton
              intent={Intent.DANGER}
              icon={IconNames.TRASH}
              onClick={(e) => {
                e.stopPropagation();
                setApps((prev) => {
                  delete prev[appName];

                  return { ...prev };
                });
              }}
            />
          </div>
        ))}

        <div>
          <Button
            text="Add app"
            intent={Intent.PRIMARY}
            onClick={() => addNewApp()}
          />
        </div>
      </div>
    </>
  );
};

export default LibraryNetworkTopology;
