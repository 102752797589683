import type { ReactElement } from 'react';
import React from 'react';
import { SubForm } from './SubForm';
import { SEPARATOR } from '../../../../constants/popUp/constants';
import { Callout } from '@blueprintjs/core';

interface IWalkerConstellationProps {
  astrum: string;
  change?: Function;
}

const controlNames = [
  `altitude${SEPARATOR}semimajorAxis`,
  'satellitesPerPlane',
  'firstPlaneRaan',
  'relativeSpacing',
  'inclination',
  'numberOfPlanes',
];

class WalkerConstellation extends React.PureComponent<
  IWalkerConstellationProps,
  {}
> {
  public render(): ReactElement {
    const { change, astrum } = this.props;
    return (
      <>
        <SubForm astrum={astrum} change={change} controlNames={controlNames} />
        <Callout icon="info-sign">
          <b>Relative spacing</b> referes to the spacing between the satellites
          in adjacent planes. It can be at most the number of planes - 1.
        </Callout>
      </>
    );
  }
}

export default WalkerConstellation;
