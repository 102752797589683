import EmptyLayout from 'layouts/EmptyLayout';
import { useHistory } from 'react-router';
import { Navbar } from 'ui/Navbar';

const AuthErrorPage = () => {
  const history = useHistory();
  const queryParams = new URLSearchParams(history.location.search);
  const errorDescription = queryParams.get('error_description');
  return (
    <EmptyLayout>
      <Navbar showUserMenu={false} />
      <div className="flex items-center justify-center m-5">
        <div className="w-fit p-3 bg-accent text-center">
          <h2>{errorDescription}</h2>
          {/* TODO: Bind API for resend email for verification */}
          {/* <SecondaryButton text={'Resend email'} /> */}
        </div>
      </div>
    </EmptyLayout>
  );
};

export default AuthErrorPage;
