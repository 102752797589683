import { clientTranslate } from 'utils/hooks/useLocalisation';
import config from '../config';

export default [
  {
    id: 'light',
    name: clientTranslate('datacosmos.map.light'),
    url:
      'https://api.mapbox.com/styles/v1/pepoc/ckqnmf9d849wy18n9t4q9plro/tiles/{z}/{x}/{y}?access_token=' +
      config.map.mapbox.accessToken,
  },
  {
    id: 'dark',
    name: clientTranslate('datacosmos.map.dark'),
    url:
      'https://api.mapbox.com/styles/v1/pepoc/clpjnd7gv00qc01p93x2h2ckx/tiles/{z}/{x}/{y}?access_token=' +
      config.map.mapbox.accessToken,
  },
  {
    id: 'osm',
    name: 'OpenStreetMap',
    url: 'http://a.tile.openstreetmap.org/{z}/{x}/{y}.png',
  },
  {
    id: 'satellite',
    name: clientTranslate('datacosmos.map.satellite'),
    url:
      'https://api.mapbox.com/styles/v1/pepoc/clth2nadt00bu01nr125nch0p/tiles/512/{z}/{x}/{y}?access_token=' +
      config.map.mapbox.accessToken,
  },
];
