import type { IOption } from '../../../inputField/SelectField';
import type { IAnyKey } from '../../../../utils/getFormData';
import { useState } from 'react';
import { SEPARATOR } from '../../../../constants/popUp/constants';

interface IParseControlNames {
  controlNames: (string[][] | string)[];
  t?: Function;
}

export const parseControlNames = (props: IParseControlNames) => {
  let initialSelectedGroup: IAnyKey = {};
  const parsedControlNames = props.controlNames.map((cN, index) => {
    const type = typeof cN;
    switch (type) {
      case 'string': {
        return cN;
      }
      case 'object': {
        const options: IOption[] = (cN as string[][]).map((group) => {
          const value = group.map((g) => g.split(SEPARATOR)[0]).join('-');
          const text = group
            .map((name) =>
              props.t ? props.t(`geometry.${name.split(SEPARATOR)[0]}`) : name
            )
            .join(' & ');
          return {
            value: value,
            text: text,
          };
        });
        initialSelectedGroup[index] = 0;
        return {
          options,
          groupNames: cN,
        };
      }
    }
    return undefined;
  });
  const [selectedGroup, setSelectedGroup] = useState(initialSelectedGroup);

  return {
    parsedControlNames,
    selectedGroup,
    setSelectedGroup,
  };
};
