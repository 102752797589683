import type { SensorId } from 'api/sensors/types';

export type ImagerMode = 'SNAPSHOT' | 'LINE_SCAN';

export type Imager = {
  name: SensorId;
  mode?: ImagerMode;
  compression_factor?: number;
  encoding?: number;
  band_setup?: { [key: string]: number };
};

export const ActivityStatusList = [
  'SUGGESTED',
  'CONFIRMED',
  'SCHEDULED',
  'SUCCEEDED',
  'FAILED',
  'EXPIRED',
  'CANCELLED',
] as const;

export type ActivityStatus = (typeof ActivityStatusList)[number];

export type ActivityStatusQueryFilter =
  | ActivityStatus
  | 'VALID'
  | 'COMMITTED'
  | 'AWAITING'
  | 'COMPLETE'
  | 'INCOMPLETE'
  | 'UNSUCCESSFUL';

export const ActivityTypeList = ['IMAGE_ACQUISITION'] as const;

export type ActivityType = (typeof ActivityTypeList)[number];

export type ActivityPhysicalParameters = {
  geojson: GeoJSON.Feature<GeoJSON.Polygon>;
  midpoint: {
    date: string;
    sza_deg: number;
    oza_deg: number;
    sat_body_pos_m: [number, number, number];
    sat_body_vel_ms: [number, number, number];
  };
  ephemeris?: {
    type: string;
    attitude_law: string;
    data: {
      epoch: string;
      line1: string;
      line2: string;
    };
  };
};

export type ActivityParameters = {
  platform: {
    roll_angle: number;
  };
  imager: Imager;
  onboard_processing?: { [key: string]: unknown } | null;
  ground_processing?: { [key: string]: string } | null;
  physical?: {
    target: ActivityPhysicalParameters | undefined;
    latest: ActivityPhysicalParameters | undefined;
  };
  stac?: { [key: string]: string }[];
};

export type Activity = {
  id: string;
  tasking_request_ids: string[];
  start_date: string;
  end_date: string;
  mission_id: string;
  status: ActivityStatus;
  type: ActivityType;
  operator_notes: string;
  priority: number;
  parameters: ActivityParameters;
};
