import type {
  ISatelliteBall,
  SatelliteBallActionTypes,
} from '../constants/satelliteBalls/actionTypes';
import {
  SB_ADD,
  SB_REMOVE,
  SB_UPDATE,
} from '../constants/satelliteBalls/actionTypes';

type ISatelliteBallState = ISatelliteBall[];
const initialState: ISatelliteBallState = [];

export default function satelliteBallReducer(
  state: ISatelliteBallState = initialState,
  action: SatelliteBallActionTypes
): ISatelliteBallState {
  switch (action.type) {
    case SB_ADD: {
      return [...state, action.satelliteBall];
    }
    case SB_UPDATE: {
      return state.map((satelliteBall): ISatelliteBall => {
        if (satelliteBall.text === action.satelliteBall.text) {
          return { ...action.satelliteBall };
        }
        return satelliteBall;
      });
    }
    case SB_REMOVE: {
      return state.filter(
        (satelliteBall): boolean => satelliteBall.text !== action.text
      );
    }
    default: {
      return state;
    }
  }
}
