import { LTOB } from 'downsample';
import { GLOBAL_LIMIT } from '../../constants/chart/constants';
import type { DataPoint } from 'downsample';

export interface IResValue {
  value: [number | string, number, string?];
}

/**
 * LTD - does not support triple
 */
type IDownSampleDouble = { x: number; y: number };
type IDownSampleTripe = { x: number; y: number; z: number };

export const downSample = (
  triple: boolean,
  points: { value: [number, number, number?] }[],
  shows: number,
  numberOfLayout: number
): IResValue[] => {
  if (triple) {
    const res = LTOB(
      points.map((s): IDownSampleTripe => {
        return { x: s.value[0], y: s.value[1], z: s.value[2] ?? 0 };
      }),
      Math.floor(GLOBAL_LIMIT / numberOfLayout / shows)
    ) as IDownSampleTripe[];

    return res.map((s): IResValue => {
      return { value: [s.x, s.y, new Date(s.z).toISOString()] };
    });
  } else {
    const res = LTOB(
      points.map((s): DataPoint => {
        return { x: new Date(s.value[0]).getTime(), y: s.value[1] };
      }),
      Math.floor(GLOBAL_LIMIT / numberOfLayout / shows)
    ) as IDownSampleDouble[];

    return res.map((s): IResValue => {
      return { value: [new Date(s.x).toISOString(), s.y] };
    });
  }
};
