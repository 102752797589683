import React from 'react';
import type { AppState } from '../../store';
import { connect } from 'react-redux';
import {
  selectPlatformDecisionTree,
  selectPlatformCharacteristics,
  selectCharacteristicsThatCascade,
  selectDisabledCharacteristics,
  selectOptionsForCharacteristics,
} from '../../selectors/platformConfigurator';
import type {
  IPlatformCharacteristics,
  IPlatformCharacteristicType,
} from '../../constants/ui/platformConfigurator/types';
import {
  PLATFORM_SIZE,
  PLATFORM_SOLAR_PANEL_CONFIG,
  PLATFORM_SOLAR_PANEL_DEPLOYABLE,
  PLATFORM_SOLAR_PANEL_DIRECTION,
  PLATFORM_SOLAR_PANEL_MOUNT_PERCENTAGE,
} from '../../constants/ui/platformConfigurator/types';
import { updatePlatformAndCascadeConfiguration } from '../../actions/ui/platformConfigurator/action';
import { showAlert } from '../../services/mapService/alert/services';
import { TYPE_TO_NAME, PLATFORM_SOLAR_PANEL_CONFIG_TYPE } from './constants';
import { get } from 'lodash';

interface IMapStateToProps {
  platformDecisionTree: any;
  platformCharacteristics: IPlatformCharacteristics;
  characteristicsThatCascade: IPlatformCharacteristicType[];
  disabledCharacteristics: IPlatformCharacteristicType[];
  optionsForCharacteristics: any; // this is very annoying to type
}

interface IMapDispatchToProps {
  updatePlatformAndCascadeConfiguration: typeof updatePlatformAndCascadeConfiguration;
}

type IProps = IMapStateToProps & IMapDispatchToProps;

const WARNING_TEXT =
  'Changing this will mean you will have to reselect dependant options';

/* This is to force a re-render and hence selection of default value
  if value is undefined */
const getMaskedSelectorValue = (value: string) => (value ? value : '');

const changeHandlerWithWarning = (
  type: IPlatformCharacteristicType,
  willCascade: boolean,
  changeHandler: typeof updatePlatformAndCascadeConfiguration
) =>
  willCascade
    ? (event: React.ChangeEvent<HTMLSelectElement>) => {
        const eventValue = event.target.value;
        showAlert(
          () =>
            changeHandler({
              name: type,
              value: eventValue,
            }),
          WARNING_TEXT,
          {
            confirmButtonText: 'Continue & Reselect',
          }
        );
      }
    : (event: React.ChangeEvent<HTMLSelectElement>) =>
        changeHandler({
          name: type,
          value: event.target.value,
        });

const getCharacteristicDropdown = (
  type: IPlatformCharacteristicType,
  platformCharacteristics: IPlatformCharacteristics,
  changeHandler: typeof updatePlatformAndCascadeConfiguration,
  allOptions: any,
  showShouldWarning: Function,
  shouldBeDisabled: Function
) => {
  const value = getMaskedSelectorValue(platformCharacteristics[type]);

  const onChange = changeHandlerWithWarning(
    type,
    type === PLATFORM_SOLAR_PANEL_CONFIG &&
      value === PLATFORM_SOLAR_PANEL_CONFIG_TYPE.PERP,
    changeHandler
  );

  const options = allOptions[type];
  const hasOptions = !!options && !!options.length;
  const noDeployables =
    type === PLATFORM_SOLAR_PANEL_DEPLOYABLE && get(options, '[0]') === 'NONE';
  const shouldShowOptions =
    !shouldBeDisabled(type) && hasOptions && !noDeployables;

  return !shouldShowOptions ? (
    <div className="bp4-select select-container">
      <select disabled />
    </div>
  ) : (
    <div className="bp4-select select-container">
      <select onChange={onChange} value={value}>
        <option disabled />
        {options.map((option: string) => (
          <option value={option} key={option}>
            {TYPE_TO_NAME[type][option]}
          </option>
        ))}
      </select>
    </div>
  );
};

const PlatformCharacteristics = ({
  platformDecisionTree,
  platformCharacteristics,
  updatePlatformAndCascadeConfiguration,
  characteristicsThatCascade,
  disabledCharacteristics,
  optionsForCharacteristics,
}: IProps) => {
  const shouldShowWarning = (characteristic: IPlatformCharacteristicType) =>
    characteristicsThatCascade.includes(characteristic);

  const shouldBeDisabled = (characteristic: IPlatformCharacteristicType) =>
    disabledCharacteristics.includes(characteristic);

  return (
    <div className="characteristic-container">
      <h2 className="mainTitle">Layout</h2>
      <h3>Body</h3>
      <div className="characteristic">
        <div className="text-container">
          <p className="label">Form Factor</p>
        </div>
        <div className="bp4-select select-container">
          <select
            onChange={changeHandlerWithWarning(
              PLATFORM_SIZE,
              true,
              updatePlatformAndCascadeConfiguration
            )}
            value={getMaskedSelectorValue(
              platformCharacteristics[PLATFORM_SIZE]
            )}
          >
            <option disabled />
            {platformDecisionTree[PLATFORM_SIZE].map((option: string) => (
              <option value={option} key={option}>
                {TYPE_TO_NAME[PLATFORM_SIZE][option]}
              </option>
            ))}
          </select>
        </div>
      </div>
      <div className="characteristic">
        <div className="text-container">
          <p className="label">Mounted Solar Cells</p>
        </div>
        <div className="bp4-select select-container">
          <select
            onChange={(event) =>
              updatePlatformAndCascadeConfiguration({
                name: PLATFORM_SOLAR_PANEL_MOUNT_PERCENTAGE,
                value: event.target.value,
              })
            }
            value={getMaskedSelectorValue(
              platformCharacteristics[PLATFORM_SOLAR_PANEL_MOUNT_PERCENTAGE]
            )}
          >
            <option disabled />
            {platformDecisionTree[PLATFORM_SOLAR_PANEL_MOUNT_PERCENTAGE].map(
              (option: string) => (
                <option key={option}>
                  {TYPE_TO_NAME[PLATFORM_SOLAR_PANEL_MOUNT_PERCENTAGE][option]}
                </option>
              )
            )}
          </select>
        </div>
      </div>
      <h3>Solar Panels</h3>
      <div className="characteristic">
        <div className="text-container">
          <p className="label">Configuration</p>
        </div>
        {getCharacteristicDropdown(
          PLATFORM_SOLAR_PANEL_CONFIG,
          platformCharacteristics,
          updatePlatformAndCascadeConfiguration,
          optionsForCharacteristics,
          shouldShowWarning,
          shouldBeDisabled
        )}
      </div>
      <div className="characteristic">
        <div className="text-container">
          <p className="label">Size</p>
        </div>
        {getCharacteristicDropdown(
          PLATFORM_SOLAR_PANEL_DEPLOYABLE,
          platformCharacteristics,
          updatePlatformAndCascadeConfiguration,
          optionsForCharacteristics,
          shouldShowWarning,
          shouldBeDisabled
        )}
      </div>
      <div className="characteristic">
        <div className="text-container">
          <p className="label">Orientation</p>
        </div>
        {getCharacteristicDropdown(
          PLATFORM_SOLAR_PANEL_DIRECTION,
          platformCharacteristics,
          updatePlatformAndCascadeConfiguration,
          optionsForCharacteristics,
          shouldShowWarning,
          shouldBeDisabled
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state: AppState) => ({
  platformDecisionTree: selectPlatformDecisionTree(state),
  platformCharacteristics: selectPlatformCharacteristics(state),
  characteristicsThatCascade: selectCharacteristicsThatCascade(state),
  disabledCharacteristics: selectDisabledCharacteristics(state),
  optionsForCharacteristics: selectOptionsForCharacteristics(state),
});

const mapDispatchToProps = {
  updatePlatformAndCascadeConfiguration,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PlatformCharacteristics);
