import type { ChangeEvent, ReactElement } from 'react';
import React from 'react';
import type { WithTranslation } from 'react-i18next';
import { withTranslation } from 'react-i18next';
import {
  ProgressBar,
  Intent,
  Button,
  FileInput,
  ButtonGroup,
  Spinner,
  SpinnerSize,
} from '@blueprintjs/core';
import type { IColumnSettings, IHeaderGroup } from './ResultsTable';
import ResultsTable from './ResultsTable';
import { downloadFile, showErrorMessage } from '../../utils/common/CommonUtils';
import { OPTIMIZATION_RESULT_FILE_NAME } from '../../constants/fileNames';
import { SocketApi } from '../../services/api/msd/eventAnalysisApi';
import type { Client } from '@stomp/stompjs';
import { MSD_URLS } from '../../services/socket/urls';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getColumns = (arrays: any[]): IColumnSettings[] => {
  let columns: IColumnSettings[] = [];
  let columnNumber = 0;
  for (let i = 0; i < arrays.length; i++) {
    const tmpArray: [] = arrays[i].map(
      (name: string, index: number): IColumnSettings => {
        return { fieldName: (index + columnNumber).toString(), title: name };
      }
    );
    columnNumber += tmpArray.length;
    columns = [...columns, ...tmpArray];
  }
  return columns;
};

interface IResultsProps extends WithTranslation {
  onClose: Function;
}

interface IResultsState {
  objectiveNames: string[];
  objectiveTypes: string[];

  parameterNames: string[];
  parameterTypes: string[];

  solutions: IResultItem[];
  progress: number;
  animate?: boolean;
}

interface IResultItem {
  parameterValues: string[];
  objectiveValues: string[];
}

class Results extends React.PureComponent<IResultsProps, IResultsState> {
  public state: IResultsState = {
    objectiveNames: [],
    objectiveTypes: [],
    parameterNames: [],
    parameterTypes: [],
    solutions: [],
    progress: 0,
    animate: true,
  };

  public componentDidMount(): void {
    this.socket = SocketApi(
      null,
      (data: IResultsState): void => this.onUpdate(data),
      MSD_URLS.EVENT_ANALYSIS
    );
  }

  private onUpdate = (result: IResultsState): void => {
    const state: IResultsState = {
      parameterNames: result.parameterNames,
      parameterTypes: result.parameterTypes,
      objectiveNames: result.objectiveNames,
      objectiveTypes: result.objectiveTypes,
      solutions: result.solutions,
      progress: result.progress,
      animate: +result.progress === 1 ? false : this.state.animate,
    };
    this.setState({ ...state });
  };

  private socket: Client = null;

  protected handleCancel = (): void => {
    this.socket.deactivate();
    this.setState({ animate: false });
  };

  protected handleClose = (): void => {
    this.socket.deactivate();
    this.props.onClose();
  };
  protected saveResults = (): void => {
    const {
      parameterNames,
      parameterTypes,
      objectiveNames,
      objectiveTypes,
      solutions,
    } = this.state;
    const json = JSON.stringify(
      {
        parameterNames,
        parameterTypes,
        objectiveNames,
        objectiveTypes,
        solutions,
      },
      null,
      2
    );
    downloadFile(json, OPTIMIZATION_RESULT_FILE_NAME, 'application/json');
  };

  protected uploadResults = (event: ChangeEvent<HTMLInputElement>): void => {
    this.handleCancel();
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (evt): void => {
        try {
          const {
            parameterNames,
            parameterTypes,
            objectiveNames,
            objectiveTypes,
            solutions,
          } = JSON.parse(evt.target.result as string);
          if (!solutions) {
            throw new Error('Incorrect data!');
          }
          this.setState({
            parameterNames,
            parameterTypes,
            objectiveNames,
            objectiveTypes,
            solutions,
            progress: 1,
            animate: false,
          });
        } catch (e) {
          showErrorMessage('File data is incorrect!');
          console.error(e);
        }
      };
      reader.readAsText(file);
    }
  };

  public render(): ReactElement {
    const {
      animate,
      progress,
      solutions,
      objectiveNames,
      parameterNames,
      objectiveTypes,
      parameterTypes,
    } = this.state;
    const { t } = this.props;
    const parameterResult = solutions.map((solution): IResultItem => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const result: any = {};
      for (let i = 0; i < solution.parameterValues.length; i++) {
        result[i] = solution.parameterValues[i];
      }
      return result;
    });
    const objectivesResult = solutions.map((solution): IResultItem => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const result: any = {};
      for (let i = 0; i < solution.objectiveValues.length; i++) {
        result[i + solution.parameterValues.length] =
          solution.objectiveValues[i];
      }
      return result;
    });

    const types = [...parameterTypes, ...objectiveTypes];
    const columns = getColumns([parameterNames, objectiveNames]);
    const results = solutions.map((solution, index): IResultItem => {
      return { ...parameterResult[index], ...objectivesResult[index] };
    });
    const headerGroups: IHeaderGroup[] = [
      { title: t('results.parameter'), colspan: parameterNames.length },
      { title: t('results.objective'), colspan: objectiveNames.length },
    ];
    const cancelClose = animate ? (
      <Button
        className="button"
        disabled={!animate}
        intent={'warning'}
        onClick={this.handleCancel}
      >
        <div className="bp4-control-group">
          <Spinner className="margin" size={SpinnerSize.SMALL} />
          {t('results.cancel')}
        </div>
      </Button>
    ) : (
      <Button
        className="button"
        icon={'chevron-up'}
        intent={'primary'}
        disabled={animate}
        onClick={this.handleClose}
      >
        {t('results.close')}
      </Button>
    );
    return (
      <div className="results">
        <div className="results-header">
          <h3>{t('results.title')}</h3>
          <div className="results-progress-bar">
            <ProgressBar
              animate={animate}
              intent={Intent.SUCCESS}
              value={+progress}
            />
          </div>
          <ButtonGroup className="results-button-group">
            {cancelClose}
            <Button
              className="button"
              intent={'success'}
              disabled={animate}
              onClick={this.saveResults}
            >
              {t('results.save')}
            </Button>
            <FileInput
              className="file-input"
              disabled={animate}
              text="Upload results"
              onInputChange={this.uploadResults}
            />
          </ButtonGroup>
        </div>
        <div>
          {columns.length ? (
            <ResultsTable
              headerGroups={headerGroups}
              types={types}
              columns={columns}
              results={results}
            />
          ) : null}
        </div>
      </div>
    );
  }
}

export default withTranslation()(Results);
