import type { ITransformResponseMappingBody } from './index';
import { STATUS_PENDING } from '../../../constants/ops/rti/oparate/constants';
import { getCurrentUtcDate } from '../../common/dateUtils';
import type { ICommandHistory, IReply } from 'utils/hooks/useCommandHistory';

export default (
  addCommandToHistory: (command: ICommandHistory) => void
): ITransformResponseMappingBody => ({
  validator: null,
  transformer: (data) => {
    const d = data as IReply;
    return {
      command: d.data.command,
      payload: d.data.payload,
      createdAt: getCurrentUtcDate(),
      sequenceId: d.sequenceId,
      status: STATUS_PENDING,
      responsesContent: [],
      procedureName: d.data.procedureName,
      customCommandName: d.data.customName,
      uuid: d.data.uuid,
      subjectID: d.data.subject_id,
      satelliteSequenceID: d.data.satellite_sequence_id,
    };
  },
  handler: (data) => {
    const d = data as ICommandHistory;
    addCommandToHistory(d);
  },
});
