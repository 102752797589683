import React, {
  useEffect,
  useState,
  useContext,
  createContext,
  useCallback,
} from 'react';
import { cloneDeep } from 'lodash';
import { useMission } from 'services/Missions';
import {
  createAlertGroupForMission,
  getAlertsThroughMission,
} from 'api/alerts/helpers';
import {
  postAlertRule as createOrEditAlertRule,
  deleteAlertRule,
  getAlertGroup,
} from 'api/alerts/service';
import type { AlertGroup, AlertRule } from 'api/alerts/types';

export const NEW_DEFINITION: AlertRule = {
  id: (Math.random() * 10000000).toFixed(0),
  alert: 'My Alert',
  expr: '',
  group_id: '',
  for: '0s',
  labels: {
    severity: 'Warning',
    system: 'comms',
    team: 'flight-team',
  },
  annotations: {
    description: '',
    summary: '',
    reviewed: 'not reviewed',
    last_reviewer: '',
  },
};

export type AlertsContext = ReturnType<typeof useAlertsProvider>;

export const AlertsContext = createContext<AlertsContext>(
  null as unknown as AlertsContext
);
export const useAlerts = () => useContext<AlertsContext>(AlertsContext);

const useAlertsProvider = () => {
  const [isFetching, setFetching] = useState(false);
  const [alerts, setAlerts] = useState<AlertRule[]>();
  const [alertGroup, setAlertGroup] = useState<AlertGroup | undefined>();
  const [currentAlert, setCurrentAlert] = useState<AlertRule | undefined>(
    NEW_DEFINITION
  );
  const [errorCreatingAlert, setErrorCreatingAlert] = useState<string | null>(
    null
  );
  const [currentTelemetryHighlighted, highlightTelemetryDefinition] = useState<
    string[]
  >([]);
  const [alertGroupDoesntExist, setAlertGroupDoesntExist] = useState(false);

  const { currentMissionId } = useMission();

  const requestAlerts = useCallback(
    async (throughMission?: boolean) => {
      return throughMission
        ? await getAlertsThroughMission(currentMissionId)
        : (await getAlertGroup({ params: { id: alertGroup?.id } })).data;
    },
    [alertGroup?.id, currentMissionId]
  );

  const requestAlertsForFirstTime = useCallback(async () => {
    setFetching(true);
    const result = await requestAlerts(true);
    if (result) {
      setAlerts(result?.rules || []);
      setAlertGroup(result);
    } else {
      setAlertGroupDoesntExist(true);
    }
    setFetching(false);
  }, [requestAlerts]);

  const enableAlerts = async () => {
    setFetching(true);
    await createAlertGroupForMission(currentMissionId);
    const result = await getAlertsThroughMission(currentMissionId);
    setAlerts(result?.rules ?? []);
    setAlertGroup(result);
    setAlertGroupDoesntExist(false);
    setFetching(false);
  };

  useEffect(() => {
    void requestAlertsForFirstTime();
  }, [requestAlertsForFirstTime]);

  const requestAlertRuleChange = async (alert: AlertRule) => {
    setFetching(true);

    const newAlertRule = cloneDeep(alert);
    newAlertRule.group_id = alertGroup?.id ?? '';

    const { errors } = await createOrEditAlertRule({ body: newAlertRule });
    if (errors.length > 0) {
      setErrorCreatingAlert(errors.join(', '));
      setFetching(false);
      return;
    }
    const { data: alrts } = await getAlertGroup({
      params: { id: alertGroup?.id },
    });

    if (!alrts) {
      setFetching(false);
      return;
    }

    setAlerts(alrts.rules ?? []);
    setAlertGroup(alrts);
    setFetching(false);
  };

  const editAlertRule = (alertId: string) => {
    const editedRule = cloneDeep(alerts?.find((alert) => alert.id === alertId));

    if (!editedRule) {
      return;
    }

    editedRule.editing = true;

    setCurrentAlert(editedRule);
    setErrorCreatingAlert(null);
  };

  const createNewAlertRule = () => {
    const newAlertRule = cloneDeep(NEW_DEFINITION);
    newAlertRule.id = (Math.random() * 10000000).toFixed(0);
    setCurrentAlert(newAlertRule);
    setErrorCreatingAlert(null);
  };

  const highlightOrUnhighlightTelemetry = (name: string) => {
    if (currentTelemetryHighlighted.includes(name)) {
      highlightTelemetryDefinition(
        currentTelemetryHighlighted.filter(
          (telemetryDefinition) => telemetryDefinition !== name
        )
      );
    } else {
      highlightTelemetryDefinition([...currentTelemetryHighlighted, name]);
    }
  };

  const requestDeleteAlertRule = async (
    groupId: string | undefined,
    alertId: string | undefined
  ) => {
    if (!groupId || !alertId) {
      return;
    }

    setFetching(true);

    await deleteAlertRule({ params: { alertRuleId: alertId, groupId } });
    if (alerts?.length === 1) {
      setAlerts([]);
      setAlertGroup(undefined);
      setAlertGroupDoesntExist(true);
    } else {
      const { data: alrts } = await getAlertGroup({
        params: { id: alertGroup?.id },
      });
      setAlerts(alrts?.rules ?? []);
      setAlertGroup(alrts);
    }

    setFetching(false);
  };

  return {
    isFetching,
    alerts,
    alertGroup,
    requestAlertRuleChange,
    currentAlert,
    setCurrentAlert,
    editAlertRule,
    createNewAlertRule,
    errorCreatingAlert,
    currentTelemetryHighlighted,
    highlightTelemetryDefinition: highlightOrUnhighlightTelemetry,
    requestDeleteAlertRule,
    alertGroupDoesntExist,
    enableAlerts,
  };
};

export const AlertsProvider = ({ children }: { children: React.ReactNode }) => {
  return (
    <AlertsContext.Provider value={useAlertsProvider()}>
      {children}
    </AlertsContext.Provider>
  );
};
