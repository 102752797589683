import type {
  PermissionType,
  AttachedRoleAssignment,
} from 'api/administration/permissions';
import type { IRoleAssignment } from './types';
import { hosts } from 'api/hosts';
import { apiFetchHandler } from 'api/clientFetch';

type GetAttachedRoleAssignmentsParams = {
  resourceType: PermissionType | undefined;
  resourceId: string | number;
};

type AddRoleAssignmentsBody = {
  userID: string;
  roleID: number;
};

type DeleteRoleAssignmentsParams = {
  resourceType: PermissionType | undefined;
  assignmentID: number;
  resourceID: number | string;
};

export const getRoleAssignments = apiFetchHandler<
  IRoleAssignment[],
  GetAttachedRoleAssignmentsParams
>({
  host: hosts.portal.v2,
  endpoint: ({ resourceType, resourceId }) =>
    `/${resourceType as string}/${resourceId}/role-assignments`,
  method: 'GET',
  errorMessage: 'Could not get role assignments',
  errorDescription: (e) => e.join('; '),
});

export const createUserRoleAssignment = apiFetchHandler<
  IRoleAssignment[],
  GetAttachedRoleAssignmentsParams,
  AddRoleAssignmentsBody
>({
  host: hosts.portal.v2,
  endpoint: ({ resourceType, resourceId }) =>
    `/${resourceType as string}/${resourceId}/role-assignments`,
  method: 'POST',
  errorMessage: 'Could not add role assignments',
  errorDescription: (e) => e.join('; '),
});

export const deleteRoleAssignments = apiFetchHandler<
  IRoleAssignment[],
  DeleteRoleAssignmentsParams
>({
  host: hosts.portal.v2,
  endpoint: ({ resourceType, resourceID, assignmentID }) =>
    `/${resourceType as string}/${resourceID}/role-assignments/${assignmentID}`,
  method: 'DELETE',
  errorMessage: 'Could not delete role assignments',
  errorDescription: (e) => e.join('; '),
});

export const getRoleAttachmentsForResource = apiFetchHandler<
  AttachedRoleAssignment[],
  GetAttachedRoleAssignmentsParams
>({
  host: hosts.portal.v2,
  endpoint: ({ resourceType, resourceId }) =>
    `/${resourceType as string}/${resourceId}/attachments`,
  method: 'GET',
  errorMessage: 'Could not get attached role assignments',
  errorDescription: (e) => e.join('; '),
});
