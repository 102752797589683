export const setBooleanAxisY = (chart: echarts.ECharts) => {
  chart.setOption({
    yAxis: {
      max: 'dataMax',
      min: 'dataMin',
      axisLabel: {
        formatter: (value: number) => {
          if (value === 1) {
            return 'true';
          }
          if (value === 0) {
            return 'false';
          }
          return null;
        },
        showMaxLabel: true,
        showMinLabel: true,
      },
    },
  });
};
