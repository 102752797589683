import { Key } from "react";
import JoyRide from "react-joyride";
import type { CallBackProps } from "react-joyride";
import type { Placement } from "react-joyride";
import type { Step } from "react-joyride";

export interface ITourInformation {
  key?: Key | null | undefined; // To re-render when one restarts the tour
  run?: boolean;
  continuous?: boolean;
  loading?: boolean;
  stepIndex?: number;
  steps?: Step[];
}

export type ITourStep = {
  target: string;
  content: string;
  disableBeacon?: boolean;
  placement?: Placement | "auto" | "center";
};

export type ITourState = ITourInformation;

interface TourProps {
  tourState: ITourState;
  tourCallback: (data: CallBackProps) => void;
}

const Tour = ({ tourCallback, tourState }: TourProps) => {
  return (
    <JoyRide
      {...tourState}
      callback={tourCallback}
      showSkipButton={true}
      disableCloseOnEsc
      disableOverlayClose
      disableScrolling
      styles={{
        options: {
          primaryColor: "#FFCC55",
        },
        tooltipContainer: {
          textAlign: "left",
        },
        tooltipTitle: {
          padding: 0,
          textDecoration: "uppercase",
          fontSize: "16px",
        },
        tooltipContent: {
          padding: "10px 0px",
        },
        buttonSkip: {
          padding: 0,
        },
        buttonBack: {
          marginRight: 10,
        },
        buttonNext: {
          color: "rgb(25, 25, 25)",
          fontSize: "15px",
          fontWeight: "normal",
        },
      }}
      locale={{
        last: "End tour",
      }}
    />
  );
};

export default Tour;
