import { useCallback, useEffect, useState } from 'react';
import { Icon } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import MailToButton from '_organisms/MailToButton/MailToButton';
import { HeaderButton } from 'components/missionDesignPage/header/HeaderButton';
import type { ScenarioWithPermission } from 'datacosmos/stores/ProjectProvider';
import { useProjects } from 'datacosmos/stores/ProjectProvider';
import { rerouteHandler } from 'datacosmos/utils/useRerouteHandler';
import { useHistory, useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import { useAuth } from 'services/auth/AuthWrapper';
import { getHost } from 'utils/common/CommonUtils';
import useLocalStorage from 'utils/hooks/useLocalStorage';
import { useWindowSize } from 'utils/hooks/useWindowSize';
import bigLogo from 'images/logo-centric-name-compact.svg';
import smallLogo from 'images/logo-monogram-black.svg';
import { AddScenarioModal } from 'datacosmos/components/AddScenarioModal';
import type {
  DialogItemDetailsRenderer,
  DialogListRenderer,
} from 'datacosmos/components/DatacosmosSelectDialog/DatacosmosSelectDialog';
import DatacosmosSelectDialog from 'datacosmos/components/DatacosmosSelectDialog/DatacosmosSelectDialog';
import Views from 'datacosmos/components/DatacosmosViews';
import s from 'datacosmos/components/Header/DatacosmosHeader.module.scss';
import Popover2 from '_atoms/Popover/Popover2';
import Menu from '_molecules/Menu/Menu';
import { Item } from 'react-stately';
import { useTheme } from 'datacosmos/stores/ThemeProvider';
import ListItem from '_atoms/ListItem/ListItem';
import { useViewMode } from 'datacosmos/utils/hooks/useViewMode';
import classNames from 'classnames';
import type { Scenario } from 'api/scenarios/types';
import {
  AUTH0_LOGOUT_URI,
  ENABLE_DARK_MODE,
  ENABLE_SEND_FEEDBACK,
  ENABLE_USER_IMAGES,
  ENABLE_UPGRADE_USER_ROLE,
  LOGO,
  SHOW_MAIN_APP_TOUR,
  ENABLE_USER_TYPE_DISPLAY,
  TASKING_ENABLE,
} from 'env';
import { useLocalisation } from 'utils/hooks/useLocalisation';
import { useAnalytics } from 'utils/hooks/analytics/useAnalytics';
import UpgradeUserPermission from '_organisms/UpgradeUserPermission/UpgradeUserPermission';
import { useTour } from 'datacosmos/stores/TourProvider';
import { tourInitialState } from 'reducers/tour';
import { setTourInformation } from 'actions/tour/action';
import IconButton from '_molecules/IconButton/IconButton';
import { useQuery } from 'api/useQuery';
import { getRoleAssignments } from 'api/administration/service';
import ProjectDetails from './ProjectDetails';
import { findHighestPriorityRole } from 'utils/auth/common';

export const DatacosmosHeader = () => {
  const {
    isAuthenticated,
    loginWithRedirect,
    logout,
    user,
    checkPermissions,
    isAllowedToReadRoles,
    isAllowedToReadUsersRoles,
  } = useAuth();

  const getAssignmentsQuery = useQuery(getRoleAssignments, {
    initialData: [],
    params: user ? { userId: user.sub, resourceType: 'global' } : undefined,
    skip: !user?.sub,
  });

  const { translate } = useLocalisation();

  const { sendInfo } = useAnalytics();

  const { setTheme, theme } = useTheme();

  const history = useHistory();
  const location = useLocation();

  const handleRolesLinkClick = () => {
    history.push('/portal/roles');
  };

  const handleUsersLinkClick = () => {
    history.push('/portal/users');
  };

  const handleOrdersLinkClick = () => {
    history.push('/data/orders');
  };

  const handleTaskingOverviewLinkClick = () => {
    history.push('/data/tasking/overview');
  };

  const handleShortcutsLinkClick = (url: string) => {
    history.push(url);
  };

  const [isAllowedToViewTaskingOverview, setIsAllowedToViewTaskingOverview] =
    useState<boolean>(false);
  const [isAllowedToAccessMatomo, setIsAllowedToAccessMatomo] =
    useState<boolean>(false);
  const [isAllowedToAccessGrafana, setIsAllowedToAccessGrafana] =
    useState<boolean>(false);
  const [isAllowedToAccessKeyCloak, setIisAllowedToAccessKeyCloak] =
    useState<boolean>(false);
  useEffect(() => {
    const checkIfAllowedToViewTaskingOverview = async () => {
      const hasPerm = await checkPermissions([
        {
          type: 'global',
          actionScope: 'data:tasking:request:read:own',
        },
        {
          type: 'global',
          actionScope: 'admin:matomo',
        },
        {
          type: 'global',
          actionScope: 'admin:grafana',
        },
        {
          type: 'global',
          actionScope: 'admin:keycloak',
        },
      ]);

      setIsAllowedToViewTaskingOverview(hasPerm[0]);
      setIsAllowedToAccessMatomo(hasPerm[1]);
      setIsAllowedToAccessGrafana(hasPerm[2]);
      setIisAllowedToAccessKeyCloak(hasPerm[3]);
    };

    void checkIfAllowedToViewTaskingOverview();
  }, [checkPermissions]);

  let userButton = (
    <HeaderButton text={<Icon icon={IconNames.PERSON} iconSize={30} />} />
  );
  let content = (
    <Menu
      onAction={(key) => {
        if (key === 'login') {
          sendInfo({
            type: 'Login',
            action: 'Click',
            item: 'Login button',
            module: 'DataCosmos',
          });
          void loginWithRedirect();
        }
      }}
    >
      <Item>Anonym</Item>
      <Item key={'login'}>
        <span>Login</span>
      </Item>
    </Menu>
  );
  if (isAuthenticated) {
    if (user) {
      userButton = ENABLE_USER_IMAGES ? (
        <img className="header-button" src={user.picture} alt="avatar" />
      ) : (
        <HeaderButton text={<Icon icon={IconNames.PERSON} iconSize={30} />} />
      );

      content = (
        <Menu
          onAction={(key) => {
            switch (key) {
              case 'orders':
                handleOrdersLinkClick();
                break;
              case 'roles':
                handleRolesLinkClick();
                break;
              case 'users':
                handleUsersLinkClick();
                break;
              case 'tasking_overview':
                handleTaskingOverviewLinkClick();
                break;
              case 'matomo':
                handleShortcutsLinkClick('/matomo/');
                break;
              case 'grafana':
                handleShortcutsLinkClick('/monitoring/grafana/');
                break;
              case 'keycloak':
                handleShortcutsLinkClick('/admin/');
                break;
              case 'logout':
                sendInfo({
                  type: 'Logout',
                  action: 'Click',
                  item: 'Logout button',
                  module: 'DataCosmos',
                });
                logout(getHost() + AUTH0_LOGOUT_URI);
                break;
              default:
                break;
            }
          }}
        >
          <Item>
            <div className="flex items-center gap-2">
              <Icon icon={IconNames.PERSON} />
              <div>
                <span>{user.name}</span>
                {ENABLE_USER_TYPE_DISPLAY && (
                  <div className="text-sm text-item-contrast-inactive dark:text-item-dark-contrast-inactive flex gap-1 items-center">
                    {findHighestPriorityRole(getAssignmentsQuery?.data)}
                  </div>
                )}
              </div>
            </div>
          </Item>

          <Item key={'orders'}>
            <div className="flex items-center gap-2" onClick={() => {}}>
              <Icon icon={IconNames.ShoppingCart} />
              <span>{translate('datacosmos.header.menu.orders')}</span>
            </div>
          </Item>
          {ENABLE_DARK_MODE ? (
            <Item
              title={
                <div className="flex items-center gap-2">
                  <Icon icon={IconNames.LIGHTBULB} />
                  <span>{translate('datacosmos.header.menu.theme.title')}</span>
                </div>
              }
            >
              <Item>
                <ListItem
                  content={translate('datacosmos.header.menu.theme.dark')}
                  onClick={() => setTheme('DARK')}
                  active={theme === 'DARK'}
                />
              </Item>
              <Item>
                <ListItem
                  content={translate('datacosmos.header.menu.theme.light')}
                  onClick={() => setTheme('LIGHT')}
                  active={theme === 'LIGHT'}
                />
              </Item>
              <Item>
                <ListItem
                  content={translate('datacosmos.header.menu.theme.auto')}
                  onClick={() => setTheme('AUTO')}
                  active={theme === 'AUTO'}
                />
              </Item>
            </Item>
          ) : (
            (null as unknown as JSX.Element)
          )}

          {TASKING_ENABLE && isAllowedToViewTaskingOverview ? (
            <Item key={'tasking_overview'}>
              <div className="flex items-center gap-2" onClick={() => {}}>
                <Icon icon={IconNames.Calendar} />
                <span>
                  {translate('datacosmos.header.menu.taskingOverview')}
                </span>
              </div>
            </Item>
          ) : (
            (null as unknown as JSX.Element)
          )}

          {isAllowedToReadRoles ? (
            <Item key={'roles'}>
              <div className="flex items-center gap-2" onClick={() => {}}>
                <Icon icon={IconNames.CROWN} />
                <span>{translate('datacosmos.header.menu.rolesManager')}</span>
              </div>
            </Item>
          ) : (
            (null as unknown as JSX.Element)
          )}
          {isAllowedToReadUsersRoles ? (
            <Item key={'users'}>
              <div className="flex items-center gap-2" onClick={() => {}}>
                <Icon icon={IconNames.People} />
                <span>{translate('datacosmos.header.menu.users')}</span>
              </div>
            </Item>
          ) : (
            (null as unknown as JSX.Element)
          )}

          {isAllowedToAccessGrafana ? (
            <Item key={'grafana'}>
              <div className="flex items-center gap-2" onClick={() => {}}>
                <Icon icon={IconNames.Link} />
                <span>{translate('datacosmos.header.menu.grafana')}</span>
              </div>
            </Item>
          ) : (
            (null as unknown as JSX.Element)
          )}

          {isAllowedToAccessMatomo ? (
            <Item key={'matomo'}>
              <div className="flex items-center gap-2" onClick={() => {}}>
                <Icon icon={IconNames.Dashboard} />
                <span>{translate('datacosmos.header.menu.matomo')}</span>
              </div>
            </Item>
          ) : (
            (null as unknown as JSX.Element)
          )}

          {isAllowedToAccessKeyCloak ? (
            <Item key={'keycloak'}>
              <div className="flex items-center gap-2" onClick={() => {}}>
                <Icon icon={IconNames.BACKLINK} />
                <span>{translate('datacosmos.header.menu.keycloak')}</span>
              </div>
            </Item>
          ) : (
            (null as unknown as JSX.Element)
          )}
          <Item key={'logout'}>
            <div className="flex items-center gap-2" onClick={() => {}}>
              <Icon icon={IconNames.LogOut} />
              <span>{translate('datacosmos.header.menu.logout')}</span>
            </div>
          </Item>
        </Menu>
      );
    }
  }

  const {
    currentScenario,
    setCurrentScenario,
    userScenarios,
    setScenario,
    loadUserScenarios,
    isLoading,
    fetchCurrentProjectItems,
  } = useProjects();

  const { isMinimal } = useViewMode();

  const windowSize = useWindowSize();
  const logo = windowSize.isTablet ? smallLogo : bigLogo;

  const [, setCurrentProject] = useLocalStorage<Scenario | undefined>(
    'currentProject',
    undefined
  );
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isAddModalOpen, setIsAddModalOpen] = useState<boolean>(false);
  const [isAllowedToCreateScenario, setIsAllowedToCreateScenario] =
    useState<boolean>(false);

  const [isAllowedToUpgradeRoleForConida, setIsAllowedToUpgradeRoleForConida] =
    useState<boolean>(false);

  const [selectedScenario, setSelectedScenario] =
    useState<ScenarioWithPermission>();

  const opsOrDcRedirectPathname = window.location.pathname.startsWith('/data')
    ? '/data'
    : '/ops';

  const urlTo =
    window.location.pathname.startsWith('/ops') ||
    window.location.pathname.startsWith('/data')
      ? opsOrDcRedirectPathname
      : '/';
  const queryParams = new URLSearchParams(window.location.search);
  const showTour = queryParams.get('tour') === 'show';

  const renderProjects: DialogListRenderer<ScenarioWithPermission> = (
    item,
    select
  ) => {
    return (
      <div
        style={{
          display: 'flex',
          gap: '5px',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
        onClick={() => select()}
        className="p-2"
      >
        <span>{item.title}</span>
      </div>
    );
  };

  const renderProjectDetails: DialogItemDetailsRenderer<
    ScenarioWithPermission
  > = (proj, open) => {
    return (
      <ProjectDetails
        project={proj}
        handler={open}
        selectedScenario={selectedScenario}
        setCurrentProject={setCurrentProject}
      />
    );
  };

  const handleProjectClick = (scenario: Scenario) => {
    sendInfo({
      type: `Project select: ${scenario.id} - ${scenario.title}`,
      action: 'Click',
      item: 'Project',
      module: 'DataCosmos',
      additionalParams: {
        project: scenario,
      },
    });
    setCurrentScenario(scenario);
    setCurrentProject(scenario);
    setIsOpen(false);
    history.push({
      pathname: rerouteHandler(scenario).pathname,
    });
  };

  const handleAddProject = () => {
    setIsAddModalOpen(true);
  };

  const { dispatch } = useTour();

  const handleRestartMainAppTour = () => {
    // Starts the tour manually
    dispatch(setTourInformation('RESTART', tourInitialState));
  };

  const handleScenarioSelection = (item?: ScenarioWithPermission) => {
    if (item) {
      setSelectedScenario(item);
    }
  };

  const handleShowTourFromQueryParams = useCallback(() => {
    dispatch(setTourInformation('RESTART', tourInitialState));
    history.replace({ pathname: location.pathname });
  }, [history, location.pathname, dispatch]);

  useEffect(() => {
    if (showTour) {
      handleShowTourFromQueryParams();
    }
  }, [showTour, handleShowTourFromQueryParams]);

  useEffect(() => {
    const checkScenarioPermissions = async () => {
      const hasPerm = await checkPermissions([
        {
          type: 'global',
          actionScope: 'data:scenario:create',
        },
        { type: 'global', actionScope: 'data:order:create' },
      ]);
      setIsAllowedToCreateScenario(hasPerm[0]);
      setIsAllowedToUpgradeRoleForConida(hasPerm[1]);
    };

    void checkScenarioPermissions();
  }, [checkPermissions]);

  return (
    <div
      className={[
        'datacosmos-header',
        'bg-header-top text-header-top-contrast dark:bg-header-top-dark dark:text-header-top-dark-contrast',
      ].join(' ')}
    >
      <div className="left">
        <div
          onClick={() => {
            if (isMinimal) return;
            sendInfo({
              type: 'Project selection opened',
              action: 'Click',
              item: 'Project selection',
              module: 'DataCosmos',
            });
            setIsOpen(true);
          }}
          data-testid="open-project-modal-button"
        >
          <span
            className={classNames(s.scenarioName, {
              'text-item-contrast dark:text-item-contrast cursor-default':
                isMinimal,
            })}
            data-testid="project-name"
          >
            {currentScenario?.title}
          </span>
        </div>

        <div>
          <Views
            currentScenario={currentScenario}
            fetchCurrentProjectItems={fetchCurrentProjectItems}
          />
        </div>
      </div>
      <div className={['middle', s.middleLogo].join(' ')}>
        <div
          className={['header-logo', s.middleLogo].join(' ')}
          role="presentation"
        >
          <Link to={urlTo}>
            <img
              className={classNames({
                [s.middleLogo]: true,
                'dark:invert': !LOGO,
              })}
              id="logo-image"
              src={LOGO !== '' ? LOGO : logo}
              alt="logo-centric-name-compact.svg"
            />
          </Link>
        </div>
      </div>
      <div className="right">
        {SHOW_MAIN_APP_TOUR && (
          <IconButton
            icon="Info"
            onPress={() => handleRestartMainAppTour()}
            tooltipText="View tour"
          />
        )}

        {ENABLE_UPGRADE_USER_ROLE && !isAllowedToUpgradeRoleForConida && (
          <UpgradeUserPermission
            text={translate('datacosmos.header.upgradePermission')}
            openModalByDefault={false}
          />
        )}

        {ENABLE_SEND_FEEDBACK && (
          <MailToButton
            subject={translate('datacosmos.header.sendFeedback')}
            team="datacosmos"
          />
        )}
        <Popover2
          hideCloseBtn
          popupContent={content}
          placement="bottom left"
          className="w-fit tour-profile'"
          offset={0}
          isDismissable={true}
        >
          {userButton}
        </Popover2>
      </div>
      <DatacosmosSelectDialog<ScenarioWithPermission>
        isContentLoading={isLoading}
        title={translate('datacosmos.projectsDialog.openProject')}
        items={userScenarios}
        listRenderer={renderProjects}
        listItemClickHandler={handleProjectClick}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        addItemTitle={translate('datacosmos.projectsDialog.createNewProject')}
        handleAddItemClick={() => {
          if (isAllowedToCreateScenario) {
            handleAddProject();

            sendInfo({
              type: 'Project creation',
              action: 'Click',
              item: 'Create project button',
              module: 'DataCosmos',
            });
          }
        }}
        handleItemDetailsRender={renderProjectDetails}
        selectedItem={userScenarios.find((p) => p.id === selectedScenario?.id)}
        setSelectedItem={handleScenarioSelection}
        sortListBy="title"
        canOutsideClickClose={false}
      />
      <AddScenarioModal
        isOpen={isAddModalOpen}
        setIsOpen={setIsAddModalOpen}
        setUserScenarios={loadUserScenarios}
        setSelectedScenario={setScenario}
        user={user}
        redirectAfterCreation={
          !location.pathname.includes('tasking') &&
          !location.pathname.includes('catalog')
        }
      />
    </div>
  );
};
