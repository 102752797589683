import { DetailsItem } from 'pages/ops/Scheduling/ScheduleActivityDetails/DetailCards/DetailsItem';

export type TelemetryPoint = {
  id?: number;
  booleanValue?: boolean;
  integerValue?: number | string;
  floatValue?: number;
  bytesValue?: string;
};

export type TelemetryGetSetResponse = {
  seqId: number;
  telemetryPoint: (TelemetryPoint | { compoundValue: TelemetryPoint[] }) & {
    timestamp?: string;
  };
};

type Props = {
  response: TelemetryGetSetResponse;
};

const TelemetryGetSetReply = ({ response }: Props) => {
  const getIsCompound = (
    value: (TelemetryPoint | { compoundValue: TelemetryPoint[] }) | undefined
  ) => {
    return (
      (value as { compoundValue: TelemetryPoint[] })?.compoundValue !==
      undefined
    );
  };

  const getValueType = (
    value: TelemetryPoint | { compoundValue: TelemetryPoint[] } | undefined
  ) => {
    if (getIsCompound(value)) {
      return 'compound';
    }

    return Object.keys(value ?? {})
      .filter((key) => key.toLowerCase().includes('value'))
      .map((key) => {
        return key.replace(/Value/g, '');
      })[0];
  };

  const getSingleValue = (value: TelemetryPoint | undefined) => {
    return String(
      Object.entries(value ?? {})
        .filter(([key]) => key.toLowerCase().includes('value'))
        .find(([, v]) => v !== undefined)?.[1]
    );
  };

  const renderCompoundValue = (
    value: { compoundValue: TelemetryPoint[] } | undefined
  ) => {
    return (
      <div className="w-full">
        {value?.compoundValue.map((v, i) => {
          return (
            <div
              key={v.id}
              className="w-full flex justify-between odd:bg-surface p-1"
            >
              <span>Value {i}</span>
              <span>{getSingleValue(v)}</span>
            </div>
          );
        })}
      </div>
    );
  };

  const renderSingleValue = (value: TelemetryPoint | undefined) => {
    return (
      <DetailsItem
        title={'Value'}
        value={String(
          Object.entries(value ?? {})
            .filter(([key]) => key.toLowerCase().includes('value'))
            .find(([, v]) => v !== undefined)?.[1]
        )}
      />
    );
  };

  const renderValues = (
    value: TelemetryPoint | { compoundValue: TelemetryPoint[] } | undefined
  ) => {
    if (!value) {
      return <div />;
    }

    if (getIsCompound(value)) {
      return renderCompoundValue(value as { compoundValue: TelemetryPoint[] });
    }

    return renderSingleValue(value as TelemetryPoint);
  };

  return (
    <div className="w-full bg-neutral-300 mb-2 flex flex-col gap-3">
      <span className="ml-1">TelemetryGetSetResponse</span>
      <div>
        <DetailsItem title="Sequence id: " value={String(response.seqId)} />
        <DetailsItem
          title="Type: "
          value={getValueType(response.telemetryPoint) ?? 'Unknown'}
        />
        {renderValues(response.telemetryPoint)}
        <DetailsItem
          title="Timestamp"
          value={String(response.telemetryPoint?.timestamp)}
        />
      </div>
    </div>
  );
};

export default TelemetryGetSetReply;
