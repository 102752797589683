import type { ReactElement } from 'react';
import React from 'react';
import type { WithTranslation } from 'react-i18next';
import { withTranslation } from 'react-i18next';
import { Button } from '@blueprintjs/core';
import type { IPopUp } from '../../constants/popUp/actionTypes';
import type { IPIState } from '../../constants/pointsOfInterest/actionTypes';
import type { IRIState } from '../../constants/regionsOfInterest/actionTypes';
import type {
  IGoal,
  IGoalReduceState,
} from '../../constants/goals/interfaceas';
import OptimisationObjectivesForm from '../popUp/OptimisationObjectivesForm';
import {
  goalInitialValues,
  goalsOption,
  missionMetricOptions,
} from '../../constants/goals/constants';
import { getObjectiveName } from '../../utils/getObjectiveName';
import type { IGeometryObjectState } from '../../constants/msd/geometry/interfaces';
import { goalNormalize } from '../../utils/goals/goalNormalize';
import GoalObject from './GoalObject';
import { SubmissionError } from 'redux-form';

interface IGoalProps extends WithTranslation {
  setPopUp: Function;
  addGoal: Function;
  updateGoal: Function;
  removeGoal: Function;

  pointsOfInterest: IPIState;
  regionsOfInterest: IRIState;
  goals: IGoalReduceState;
  geometry: IGeometryObjectState;
}

interface IGoalState {}

class Goals extends React.PureComponent<IGoalProps, IGoalState> {
  public state: IGoalState = {};

  private addGoal = () => {
    const { setPopUp, regionsOfInterest, pointsOfInterest, t, addGoal, goals } =
      this.props;
    const popUp: IPopUp = {
      visible: true,
      classNames: [],
      component: OptimisationObjectivesForm,
      data: goalInitialValues,
      otherProps: {
        regionsOfInterestNames: regionsOfInterest.list.map(
          (r): string => r.name
        ),
        pointsOfInterestNames: pointsOfInterest.list.map((p): string => p.name),
        options: [
          goalsOption.MISSION_METRIC,
          goalsOption.REGION_OF_INTEREST_EVENT,
          goalsOption.POINT_OF_INTEREST_EVENT,
        ],
        missionMetricOptions: [
          missionMetricOptions.NUMBER_OF_PLANES,
          missionMetricOptions.NUMBER_OF_SATELLITES,
        ],
      },
      functions: {
        onSubmit: (goal: IGoal): void => {
          const normalizedGoal = goalNormalize(
            goal,
            pointsOfInterest,
            regionsOfInterest
          );
          const goalWithName: IGoal = {
            ...normalizedGoal,
            name: getObjectiveName(normalizedGoal, t)(),
          };
          if (goals.find((g) => g.name === goalWithName.name)) {
            const errorMessage = 'name_not_unique';
            throw new SubmissionError({
              name: errorMessage,
              _error: errorMessage,
            });
          }
          addGoal(goalWithName);
        },
        onClose: (): void => {},
      },
    };
    setPopUp(popUp);
  };

  public render(): ReactElement {
    const { goals, geometry, pointsOfInterest, regionsOfInterest } = this.props;

    const isOptimize = !!geometry.find((g) => g.force === 1);

    return (
      <div className="geometry-panel">
        <div className="white geometry-header">
          <h3 className="text">Goal</h3>
          <Button
            className="button button_common"
            disabled={!isOptimize}
            onClick={this.addGoal}
            intent={'none'}
            icon={'add'}
            key={'add'}
            text={'Add'}
          />
        </div>
        <div className="white geometry-body">
          {goals.map((g) => (
            <GoalObject
              key={g.id}
              goalObject={g}
              goals={goals}
              pointsOfInterest={pointsOfInterest}
              regionsOfInterest={regionsOfInterest}
              setPopUp={this.props.setPopUp}
              updateGoal={this.props.updateGoal}
              removeGoal={this.props.removeGoal}
            />
          ))}
        </div>
      </div>
    );
  }
}

export default withTranslation()(Goals);
