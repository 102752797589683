import classNames from "classnames";
import React, { useState } from "react";
import Popover, { Direction } from "../Popover/Popover";
import type { PopoverProps } from "../Popover/Popover";
import Icon from "../../icons/Icon";

interface IProps extends PopoverProps {
  /**
   * The content to display inside the accordion.
   */
  children: React.ReactNode;
  /**
   * The text to display in the accordion.
   */
  labelText: string;
  /**
   * Whether the accordion is open or not on first render.
   */
  startExpanded?: boolean;
  /**
   * The className to style the label in the accordion.
   */
  labelClassName?: string;
}

/**
 * Accordion is a component that can be used to show or hide content.
 */
const Accordion = (props: IProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(
    props.startExpanded ? true : false
  );
  return (
    <Popover
      direction={Direction.BottomRight}
      relative
      usePortal={false}
      content={props.children as JSX.Element}
      fill
      className={classNames(props.className, "w-fill")}
      isOpen={isOpen}
    >
      <div
        className={classNames(
          props.className,
          "flex items-center bg-item dark:bg-item-dark text-data-dark p-3 mb-px cursor-pointer",
          "dark:bg-data-dark-item dark:text-data-text"
        )}
        onClick={() => {
          props.isOpen ? null : setIsOpen(!isOpen);
        }}
      >
        {isOpen || props.isOpen ? (
          <Icon icon="ChevronDown" size={16} />
        ) : (
          <Icon icon="ChevronRight" size={16} />
        )}

        <div className={props.labelClassName}>
          <span>{props.labelText}</span>
        </div>
      </div>
    </Popover>
  );
};

export default Accordion;
