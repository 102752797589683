/**
 * https://developers.google.com/maps/documentation/javascript/reference/map#MapOptions
 *
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const mapOptions = (
  google?: any,
  options?: google.maps.MapOptions
): google.maps.MapOptions => {
  return {
    restriction: {
      latLngBounds: { north: 89, south: -89, west: -180, east: 180 },
    },
    center: { lat: 0, lng: 0 },
    mapTypeId: 'default',
    minZoom: 3,
    zoom: 3,
    gestureHandling: 'cooperative',
    mapTypeControl: false,
    panControl: false,
    streetViewControl: false,
    fullscreenControl: false,
    ...options,
  };
};
