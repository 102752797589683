import { InputGroup } from '@blueprintjs/core';
import type { Telemetry } from 'api/telemetry/types';

import s from './index.module.scss';

interface IProps {
  telemetries: Telemetry[];
  handleFilterChange: (newFilter: string) => void;
}

const TelemetryFilter = (props: IProps) => {
  return (
    <InputGroup
      className={s.telemetryFilter}
      leftIcon="filter"
      onChange={(e) => props.handleFilterChange(e.target.value)}
      placeholder="Filter telemetries..."
      small
    />
  );
};

export default TelemetryFilter;
