import type { ReactElement } from 'react';
import React from 'react';
import type { ISummaryTable } from '../../constants/summary/constants';
import type { FontWeightProperty } from 'csstype';

export const SummaryTable = (props: ISummaryTable): ReactElement => {
  const { defaultBackgroundColor } = props;
  const tableStyle = {
    backgroundColor: defaultBackgroundColor,
  };
  const [headerRow, ...bodyCells] = props.cells;
  return (
    <table style={tableStyle} className="summary-table">
      <thead>
        <tr>
          {headerRow.map((c, index): ReactElement => {
            const { backgroundColor, bold } = c;
            const style = {
              backgroundColor,
              fontWeight: (bold ? 'bold' : '') as FontWeightProperty,
            };
            const lastColumnStyle =
              index === headerRow.length - 1
                ? 'summary-table-cell-last-column'
                : '';
            return (
              <th
                key={index}
                className={`summary-table-cell ${lastColumnStyle}`}
                style={style}
              >
                {c.value}
              </th>
            );
          })}
        </tr>
      </thead>
      <tbody>
        {bodyCells.map((r, index): ReactElement => {
          const lastRowStyle =
            index === bodyCells.length - 1 ? 'summary-table-cell-last-row' : '';
          return (
            <tr key={index}>
              {r.map((c, index): ReactElement => {
                const lastColumnStyle =
                  index === r.length - 1
                    ? 'summary-table-cell-last-column'
                    : '';
                const { backgroundColor, bold } = c;
                const style = {
                  backgroundColor,
                  fontWeight: (bold ? 'bold' : '') as FontWeightProperty,
                };
                return (
                  <td
                    key={index}
                    className={`summary-table-cell ${lastColumnStyle} ${lastRowStyle}`}
                    style={style}
                  >
                    {c.value}
                  </td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};
