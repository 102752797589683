import type { IGoalReduceState } from '../constants/goals/interfaceas';
import type { IGoalsActionTypes } from '../constants/goals/actionTypes';
import {
  GOAL_ADD,
  GOAL_REMOVE,
  GOAL_RESET,
  GOAL_UPDATE,
  GOAL_UPLOAD,
} from '../constants/goals/constants';

const initialState: IGoalReduceState = [];

export default function geometryReducer(
  state: IGoalReduceState = initialState,
  action: IGoalsActionTypes
): IGoalReduceState {
  switch (action.type) {
    case GOAL_ADD: {
      return [...state, action.goal];
    }
    case GOAL_UPDATE: {
      return [
        ...state.map((goal) => {
          if (goal.id === action.goal.id) {
            return action.goal;
          }
          return goal;
        }),
      ];
    }
    case GOAL_REMOVE: {
      return [...state.filter((goal): boolean => goal.id !== action.goal.id)];
    }
    case GOAL_RESET: {
      return initialState;
    }
    case GOAL_UPLOAD: {
      return action.goals;
    }
    default: {
      return state;
    }
  }
}
