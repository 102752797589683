import axios from 'axios';
import { get } from 'lodash';

import { showErrorMessage, showSuccessMessage } from 'utils/common/CommonUtils';
import { REQUEST_ERROR } from 'constants/errors';
import type { BookPassRequest, Pass } from 'api/groundStation/types';

import { getNextPass as apiGetNextPass } from 'api/passes/service';

type IPassesState = 'BOOKED' | 'CANCELLED';

//TODO: Move all of this to handlers

export interface IPassesParamsAPI {
  start: string;
  end: string;
  status?: IPassesState;
  // Below are not used yet
  cursor?: string;
  limit?: number;
  order?: string;
}

interface IPassResponse {
  metadata?: any;
  data: Pass[];
}

interface INextPassResponse {
  metadata?: any;
  data: {
    current_pass: Pass;
    next_pass: Pass;
    past_pass: Pass;
  };
}

export default (url: string, missionId: number, token: string | undefined) => {
  const getPasses = async (
    { start, end, status }: IPassesParamsAPI,
    groundStationSiteIds: number[]
  ): Promise<Pass[]> => {
    try {
      const {
        data: { data },
      }: { data: IPassResponse } = await axios.get(
        `${url}/mission/${missionId}/passes`,
        {
          params: { start, end, status, sites: groundStationSiteIds.join(',') },
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      );

      return data;
    } catch (e) {
      showErrorMessage(get(e, 'response.data.data') || REQUEST_ERROR);
      console.error(e);
      throw e;
    }
  };

  const bookAvailablePasses = async (pass: BookPassRequest): Promise<Pass> => {
    try {
      const {
        data: { data },
      } = await axios.post(`${url}/mission/${missionId}/passes`, pass, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });

      showSuccessMessage('Pass was successfully booked.');
      return data;
    } catch (e) {
      showErrorMessage(get(e, 'response.data.data') || REQUEST_ERROR);
      console.error(e);
      throw e;
    }
  };

  const cancelBookedPass = async (passId: number) => {
    try {
      await axios.delete(`${url}/mission/${missionId}/passes/${passId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });
      showSuccessMessage('Pass was successfully cancelled.');
    } catch (e) {
      showErrorMessage(get(e, 'response.data.data') || REQUEST_ERROR);
      console.error(e);
      throw e;
    }
  };

  const manualNewPassEvent = async (passId: number) => {
    try {
      const data = await axios.post(
        `${url}/mission/${missionId}/passes/${passId}/trigger_notification`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      );
      showSuccessMessage(data.data.data || 'Success');
    } catch (e) {
      showErrorMessage(
        get(e, 'response.data.errors[0].message') || REQUEST_ERROR
      );
      throw e;
    }
  };

  // TODO: Remove this in favor of the apiHandler
  const getNextPass = async (mission: number) => {
    const { data: nextPass } = await apiGetNextPass({
      params: { missionId: mission },
    });

    if (!nextPass) {
      return null;
    }

    return {
      upcoming: nextPass.next_pass,
      ongoing: nextPass.current_pass,
      completed: nextPass.past_pass,
    };
  };

  return {
    getPasses,
    bookAvailablePasses,
    cancelBookedPass,
    getNextPass,
    manualNewPassEvent,
  };
};
