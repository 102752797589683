import type { ITreeNodeCustom } from '../../declarations/blueprintjs';

export const findNode = (
  node: ITreeNodeCustom,
  id: string
): ITreeNodeCustom => {
  const currentNode = node;
  let foundedElement = null;
  if (currentNode.id === id) {
    return currentNode;
  } else {
    if (currentNode.childNodes) {
      currentNode.childNodes.forEach((n: ITreeNodeCustom): void => {
        const res = findNode(n, id);
        if (res) {
          foundedElement = res;
        }
      });
    }
    return foundedElement;
  }
};
