import React, { useMemo, useState } from 'react';

import { Pre, HTMLSelect } from '@blueprintjs/core';
import {
  base64ToByteArray,
  byteArrayToUTF8String,
  byteArrayToBase16String,
  byteArrayToBase10String,
  byteArrayToBase2String,
} from '../../utils/common/binnaryUtils';

export interface IProps {
  base64Value: string;
}

enum Mode {
  Utf8 = 'utf-8',
  Hex = 'hex',
  Decimal = 'decimal',
  Binary = 'binary',
}

const availableModes = [Mode.Utf8, Mode.Hex, Mode.Decimal, Mode.Binary];

const transform = (value: Uint8Array, mode: Mode) => {
  if (typeof value === 'undefined' || value === null) {
    throw 'OPS ERROR: Undefined value';
  }
  switch (mode) {
    case Mode.Utf8:
      return byteArrayToUTF8String(value);
    case Mode.Hex:
      return byteArrayToBase16String(value);
    case Mode.Decimal:
      return byteArrayToBase10String(value);
    case Mode.Binary:
      return byteArrayToBase2String(value);
    default:
      throw 'OPS ERROR: Mode not implemented';
  }
};

function ByteDisplay(props: IProps) {
  const [mode, setMode] = useState<Mode>(Mode.Utf8);
  const byteArrayValue = useMemo<Uint8Array>(
    () => base64ToByteArray(props.base64Value),
    [props.base64Value]
  );
  const renderContent = useMemo<string>(
    () => transform(byteArrayValue, mode),
    [byteArrayValue, mode]
  );

  return (
    <div>
      <HTMLSelect
        value={mode}
        onChange={(e) => setMode(e.target.value as Mode)}
      >
        {availableModes.map((m) => (
          <option key={m} value={m}>
            {m}
          </option>
        ))}
      </HTMLSelect>
      <Pre>{renderContent}</Pre>
    </div>
  );
}

export default ByteDisplay;
