import { pipe } from 'lodash/fp';
import { handleHistoryCommandList } from 'utils/rtiCommands/terminalHandlers/handleHistoryCommandList';
import { showErrorMessage } from 'utils/common/CommonUtils';
import { REQUEST_ERROR } from 'constants/errors';
import type { TelecomandApi } from 'services/api/ops/commands/useTelecommandsApi';
import { COMMANDS_PER_REQUEST } from 'services/api/ops/commands/useTelecommandsApi';

export const updateCommandHistory = async (
  sessionId: number,
  dispatch: Function,
  handleNewHistoryCommandsAndReplies: Function,
  _addReplyToCommand: Function,
  telecommandLogApi: TelecomandApi,
  timestampSeconds: number,
  milliseconds: number,
  setNoHistoryLeft: () => void,
  limit?: number
) => {
  try {
    const commandHistory = await telecommandLogApi.getCommandLog(
      sessionId,
      timestampSeconds,
      milliseconds,
      limit
    );

    if (!commandHistory) {
      return;
    }

    const numberOfCommandsReceived = commandHistory.filter(
      (item) => item.type === 'request'
    ).length;
    const parser = handleHistoryCommandList(
      dispatch,
      handleNewHistoryCommandsAndReplies
    );
    if (!parser) return;
    const { validator, transformer, handler } = parser;
    if (validator?.(commandHistory)) return;
    pipe(transformer, handler)(commandHistory);

    if (numberOfCommandsReceived < COMMANDS_PER_REQUEST) {
      setNoHistoryLeft();
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
    showErrorMessage(REQUEST_ERROR);
  }
};
