import type { ReactElement } from 'react';
import React from 'react';
import type { WithTranslation } from 'react-i18next';
import { withTranslation } from 'react-i18next';
import type {
  ISummaryChildren,
  ISummaryData,
} from 'constants/summary/constants';
import { BUTTON_TITLE, PDF, VIEW_TYPES } from 'constants/summary/constants';
import { getComponent } from 'services/summary/getComponent';
import { Button, Icon, Spinner, SpinnerSize, Tree } from '@blueprintjs/core';
import { getMenu } from 'services/summary/getMenu';
import { findNode } from 'services/summary/findNode';
import { handleWheelBlocking } from 'utils/common/handleWheelBlocking';
import { SummaryLogoPage } from 'components/summary/SummaryLogoPage';
import { preparePDF } from 'services/export/ExportService';
import { SummaryIndexPage } from 'components/summary/SummaryIndexPage';
import type { IUserInformation } from 'constants/user/actionTypes';
import PDFPreview from 'components/summary/pdf/PDFPreview';
import { SummaryEndPage } from 'components/summary/SummaryEndPage';
import { BlueprintIcons_16 as IconNames } from '@blueprintjs/icons/lib/esnext/generated-icons/16px/blueprint-icons-16';
import {
  downloadFileFromURL,
  downloadFile,
  showErrorMessage,
} from 'utils/common/CommonUtils';
import { debounce } from 'utils/common/debounce';
import type { IMission } from 'services/Missions';
import type { ITreeNodeCustom } from 'declarations/blueprintjs';
import {
  convertGSAnalysisToCSVRows,
  convertAnnexToCSVRows,
} from 'components/summary/utils';

interface ISummaryProps extends WithTranslation {
  summaryData: ISummaryData;
  user: IUserInformation;
  mission: IMission;
}

interface ISummaryState {
  nodes: ITreeNodeCustom[];
  data: ISummaryChildren;
  shouldRender: boolean;
  date: Date;
  isLoading: boolean;
  exportedPDFSrc: string;
  viewMode: string;
  components: ReactElement[];
}

class Summary extends React.PureComponent<ISummaryProps, ISummaryState> {
  public state: ISummaryState = {
    nodes: null as ITreeNodeCustom[],
    data: null,
    shouldRender: false,
    date: this.props.mission.date,
    isLoading: false,
    exportedPDFSrc: null,
    viewMode: 'html',
    components: [],
  };
  private ref = React.createRef<HTMLDivElement>();

  public componentDidMount(): void {
    const { data } = this.props.summaryData;
    const treeNode: ITreeNodeCustom[] = [
      getMenu(data, '0', this.handleClickEye),
    ];
    this.setState({ nodes: treeNode, data, date: this.props.mission.date });
  }

  public componentDidUpdate(
    prevProps: Readonly<ISummaryProps>,
    prevState: Readonly<ISummaryState>
  ): void {
    const { data, nodes, shouldRender } = this.state;
    if (this.state.data !== prevState.data) {
      const components = getComponent(data, nodes[0], '0');
      this.setState({ components });
    }
    if (this.state.nodes !== prevState.nodes) {
      const components = getComponent(data, nodes[0], '0');
      this.setState({ components });
    }
    if (shouldRender) {
      this.debounceSetState();
    }
  }

  private handleNodeCollapse = (nodeData: ITreeNodeCustom): void => {
    const { nodes } = this.state;
    findNode(nodes[0], nodeData.id.toString()).isExpanded = false;
    this.setState({ nodes }, (): void => this.forceUpdate());
  };

  private handleNodeExpand = (nodeData: ITreeNodeCustom): void => {
    const { nodes } = this.state;
    findNode(nodes[0], nodeData.id.toString()).isExpanded = true;
    this.setState({ nodes }, (): void => this.forceUpdate());
  };

  private handleClickEye = (id: string): void => {
    const { nodes } = this.state;
    const node: ITreeNodeCustom = findNode(nodes[0], id);
    if (node.disabled) {
      const href = node.title;
      node.label = <a href={`#${href}`}>{node.title}</a>;
      node.disabled = false;
      node.secondaryLabel = (
        <Icon
          className="bp4-button bp4-minimal"
          icon={IconNames.EyeOpen}
          onClick={(): void => this.handleClickEye(node.id.toString())}
        />
      );
    } else {
      node.label = node.title;
      node.disabled = true;
      node.isExpanded = false;
      node.secondaryLabel = (
        <Icon
          className="bp4-button bp4-minimal"
          icon={IconNames.EyeOff}
          onClick={(): void => this.handleClickEye(node.id.toString())}
        />
      );
    }
    this.setState({ nodes, shouldRender: true }, (): void => {
      this.forceUpdate();
    });
  };

  private handleExportToPDF = async (): Promise<void> => {
    const { user, mission } = this.props;
    const { date } = this.state;
    this.setState({ isLoading: true });
    try {
      // TODO missing name
      const documentInformation = {
        mission: mission.name,
        username: user.nickname,
        date: date,
        fileName: PDF.FILE_NAME,
      };
      const src = await preparePDF(documentInformation);
      this.setState({ exportedPDFSrc: src, viewMode: VIEW_TYPES.PDF });
    } catch (e) {
      showErrorMessage('Failed to export');
      console.error(e);
    } finally {
      this.setState({ isLoading: false });
    }
  };

  private downloadGSAnalysisCSV = () => {
    const csvRows = convertGSAnalysisToCSVRows(this.props.summaryData);

    downloadFile(
      csvRows.join('\n'),
      'ground-segment-analysis.csv',
      'application/csv'
    );
  };

  private downloadAnnexCSV = () => {
    const csvRows = convertAnnexToCSVRows(this.props.summaryData);

    downloadFile(csvRows.join('\n'), 'annex-events.csv', 'application/csv');
  };

  private handleSwitchMode = async (): Promise<void> => {
    this.setState({
      viewMode:
        this.state.viewMode === VIEW_TYPES.HTML
          ? VIEW_TYPES.PDF
          : VIEW_TYPES.HTML,
    });
  };

  private handleDownloadPDF = async (): Promise<void> => {
    const { isLoading, exportedPDFSrc } = this.state;
    if (isLoading) {
      return undefined;
    }
    this.setState({ isLoading: true });

    try {
      downloadFileFromURL(exportedPDFSrc, PDF.FILE_NAME);
    } catch (e) {
      showErrorMessage('Failed to download');
      console.error(e);
    } finally {
      this.setState({ isLoading: false });
    }
  };

  protected debounceSetState = debounce.call(
    this,
    (): void => {
      const { data, nodes } = this.state;
      const components = getComponent(data, nodes[0], '0');
      this.setState({ components, shouldRender: false });
    },
    2000
  );

  public render(): ReactElement {
    const { user } = this.props;
    const {
      nodes,
      data,
      date,
      isLoading,
      exportedPDFSrc,
      viewMode,
      components,
    } = this.state;
    return (
      <div className="summary">
        <div className="summary-buttons-group no-print">
          <Button
            className={!exportedPDFSrc ? 'hidden' : ''}
            title={BUTTON_TITLE.SWITCH}
            key={BUTTON_TITLE.SWITCH}
            icon={
              isLoading ? (
                <Spinner size={SpinnerSize.SMALL} />
              ) : (
                IconNames.SwapHorizontal
              )
            }
            onClick={this.handleSwitchMode}
            disabled={isLoading}
          />
          <Button
            className={viewMode !== VIEW_TYPES.PDF ? 'hidden' : ''}
            title={BUTTON_TITLE.DOWNLOAD_PDF}
            key={BUTTON_TITLE.DOWNLOAD_PDF}
            icon={
              isLoading ? (
                <Spinner size={SpinnerSize.SMALL} />
              ) : (
                IconNames.Download
              )
            }
            onClick={this.handleDownloadPDF}
            disabled={isLoading}
          />
          <Button
            className={viewMode !== VIEW_TYPES.HTML ? 'hidden' : ''}
            title={BUTTON_TITLE.EXPORT_PDF}
            key={BUTTON_TITLE.EXPORT_PDF}
            icon={
              isLoading ? (
                <Spinner size={SpinnerSize.SMALL} />
              ) : (
                IconNames.Export
              )
            }
            onClick={this.handleExportToPDF}
            disabled={isLoading}
          />
          {!isLoading && [
            <Button
              className={viewMode !== VIEW_TYPES.HTML ? 'hidden' : ''}
              title={BUTTON_TITLE.DOWNLOAD_GROUND_SEGMENT_CSV}
              key={BUTTON_TITLE.DOWNLOAD_GROUND_SEGMENT_CSV}
              icon={IconNames.Th}
              onClick={this.downloadGSAnalysisCSV}
            />,
            <Button
              className={viewMode !== VIEW_TYPES.HTML ? 'hidden' : ''}
              title={BUTTON_TITLE.DOWNLOAD_ANNEX_CSV}
              key={BUTTON_TITLE.DOWNLOAD_ANNEX_CSV}
              icon={IconNames.Download}
              onClick={this.downloadAnnexCSV}
            />,
          ]}
        </div>
        <div className="summary-body">
          <div
            className={`summary-body-menu no-print ${
              viewMode !== VIEW_TYPES.HTML ? 'hidden' : ''
            }`}
          >
            <Tree
              onNodeCollapse={this.handleNodeCollapse}
              onNodeExpand={this.handleNodeExpand}
              contents={nodes}
            />
          </div>
          {nodes && (
            <div
              ref={this.ref}
              className={`summary-body-body ${
                viewMode !== VIEW_TYPES.HTML ? 'hidden' : ''
              }`}
              onWheel={handleWheelBlocking}
            >
              <SummaryLogoPage
                key={'logo'}
                title={nodes[0].title}
                date={date}
                user={user}
              />
              <SummaryIndexPage key={'index'} date={date} nodes={nodes} />
              <div className="page page-infinity">{components}</div>
              <SummaryEndPage />
            </div>
          )}
          <div
            className={`summary-preview-wrap ${
              data && viewMode !== VIEW_TYPES.PDF ? 'hidden' : ''
            }`}
          >
            <PDFPreview className="summary-preview" src={exportedPDFSrc} />
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(Summary);
