import { Spinner } from '@blueprintjs/core';
import React from 'react';
import type { User } from 'api/users/types';

type FieldToShow = keyof User;

type Props = {
  user: User | undefined;
  loading: boolean;
  fieldsToShow: FieldToShow[];
};

const UserInfo = ({ user, loading, fieldsToShow }: Props) => {
  if (loading) return <Spinner />;

  if (!user) {
    return <div>No user data available</div>;
  }

  return (
    <ul className="p-1">
      {fieldsToShow.find((f) => f === 'picture') ? (
        <li>
          <img src={user.picture} />
        </li>
      ) : null}
      {fieldsToShow.find((f) => f === 'id') ? (
        <li>
          <span>{user.id}</span>
        </li>
      ) : null}
      {fieldsToShow.find((f) => f === 'name') ? (
        <li>
          <span>{user.name}</span>
        </li>
      ) : null}
      {fieldsToShow.find((f) => f === 'email') ? (
        <li>
          <span>{user.email}</span>
        </li>
      ) : null}
    </ul>
  );
};

export default UserInfo;
