import type {
  BandAlgebraInputType,
  COLORMAP,
} from 'datacosmos/entities/bandAlgebraLayer';
import { v1 as uuid } from 'uuid';

export interface ICustomBandAlgebraApi {
  saveBandAlgebra: (algebra: ICustomBandAlgebra) => void;
  retrieveBandAlgebra: () => ICustomBandAlgebra[];
}

export interface ICustomBandAlgebra {
  id: string;
  colorMap: COLORMAP;
  expression: string;
  min: number;
  max: number;
  uiName: string;
  description?: string;
  algebraType: BandAlgebraInputType;
}

export class BandAlgebraLocalStorage implements ICustomBandAlgebraApi {
  constructor() {}

  saveBandAlgebra(algebra: ICustomBandAlgebra) {
    let stored = JSON.parse(
      window.localStorage.getItem('customBandAlgebra')
    ) as ICustomBandAlgebra[];

    if (stored === null) stored = [];
    stored.push({ ...algebra, id: uuid() });
    window.localStorage.setItem('customBandAlgebra', JSON.stringify(stored));
  }

  retrieveBandAlgebra() {
    try {
      return JSON.parse(
        window.localStorage.getItem('customBandAlgebra')
      ) as ICustomBandAlgebra[];
    } catch (error) {
      throw error;
    }
  }

  deleteBandAlgebra(algebra: ICustomBandAlgebra) {
    const filtered = this.retrieveBandAlgebra().filter((stored) =>
      stored.id ? algebra.id !== stored.id : stored.uiName !== algebra.uiName
    );
    window.localStorage.setItem('customBandAlgebra', JSON.stringify(filtered));
  }
}
