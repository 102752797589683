import React from 'react';
import type { WithTranslation } from 'react-i18next';
import { withTranslation } from 'react-i18next';
import { Button } from '@blueprintjs/core';
import type { IPayloadModeConfiguration } from '../../constants/satellite/types';
import type { IPopUp } from '../../constants/popUp/actionTypes';
import ModeForm from './ModeForm';
import type { setPopUp } from '../../actions/popUp/thunks';
import {
  DEFAULT_SATELLITE_MODE,
  GROUND_STATION_PASS_MODE,
} from '../../constants/satellite/constants';

interface IProps extends WithTranslation {
  name: string;
  modeConfiguration: IPayloadModeConfiguration;
  removeHandler: Function;
  setPopUp: typeof setPopUp;
}

class ModeCard extends React.Component<IProps> {
  private showUpdateModePanel = (): void => {
    const popUp: IPopUp = {
      visible: true,
      classNames: ['centered'],
      component: ModeForm,
      data: {
        ...this.props.modeConfiguration,
        name: this.props.name,
      },
    };

    this.props.setPopUp(popUp);
  };

  private removeMode = () => this.props.removeHandler(this.props.name);

  public render() {
    const { name } = this.props;

    return (
      <div className={`geometry-object gray`}>
        <div title={name} className="geometry-object_name">
          <div>{name}</div>
        </div>
        <div className="geometry-object_buttons">
          <Button
            icon="settings"
            onClick={this.showUpdateModePanel}
            style={{ maxWidth: 32 }}
          />
          {!(
            name === DEFAULT_SATELLITE_MODE || name === GROUND_STATION_PASS_MODE
          ) && (
            <Button intent="danger" icon="delete" onClick={this.removeMode} />
          )}
        </div>
      </div>
    );
  }
}

export default withTranslation()(ModeCard);
