import { IconButton, Select2 } from 'opencosmos-ui/src/core';
import { useState } from 'react';
import { getDownloadTypes } from '../Layers/LayerCards/helpers';
import type { DownloadType } from '../Layers/LayerCards/helpers';
import { Item } from 'react-stately';
import type { OpportunityLayer } from 'datacosmos/entities/TaskingOpportunityLayer';
import type { Layer } from 'datacosmos/entities/layer';
import { Icon } from 'opencosmos-ui';
import type { CommonOpportunity } from 'api/tasking/helpers';

interface Props {
  layer: Layer | undefined;
  defaultValue?: DownloadType['ext'];
  className?: string;
}

const DownloadLayerMenu = ({ layer, defaultValue, className }: Props) => {
  const [selectedDlType, setSelectedDlType] = useState<DownloadType['ext']>(
    defaultValue ?? 'geojson'
  );

  return (
    <div className={className}>
      <Icon icon="File" />
      <Select2
        items={getDownloadTypes(layer as OpportunityLayer<CommonOpportunity>)}
        selectedKey={selectedDlType}
        onSelectionChange={(k) => setSelectedDlType(k as DownloadType['ext'])}
      >
        {(item) => (
          <Item key={item.ext}>
            <div className="flex items-center justify-between gap-4">
              <span>Download as {item.ext}</span>
              <IconButton icon="Download" onPress={() => item.handler()} />
            </div>
          </Item>
        )}
      </Select2>
    </div>
  );
};

export default DownloadLayerMenu;
