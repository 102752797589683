import type { ReactElement } from 'react';
import React, { useEffect } from 'react';
import { Pre } from '@blueprintjs/core';
import { Field, formValueSelector } from 'redux-form';
import TextField from '../../../inputField/SimpleTextField';
import { connect } from 'react-redux';
import { GEOMETRY_FORM_NAME } from '../../../../constants/popUp/constants';
import type { AppState } from '../../../../store';
import SelectField from '../../../inputField/SelectField';
import {
  TLE_OBJECT_OPTIONS,
  TLE_OBJECT_BY_NAME,
  DEFAULT_TLE_OBJECT,
} from '../../../../services/tle/tleObjectService';
import { PARAMETERISATION_TLE } from '../../../../constants/msd/geometry/satelliteOrbits/constants';

interface IOwnProps {
  astrum: string;
  change?: Function;
  changeHandler: Function;
}

interface IMapStateToProps {
  parameterisationTle: string;
}

type IProps = IOwnProps & IMapStateToProps;

const options = [
  {
    value: PARAMETERISATION_TLE.SPECIFY,
    text: 'Specify',
  },
  { value: PARAMETERISATION_TLE.FETCH, text: 'Fetch' },
];

const renderTLE = (props: IProps): ReactElement => {
  const { changeHandler, parameterisationTle } = props;

  useEffect(() => {
    changeHandler('parameterisationTle', PARAMETERISATION_TLE.SPECIFY);
  }, []);

  useEffect(() => {
    if (parameterisationTle === PARAMETERISATION_TLE.FETCH) {
      changeHandler('tleObject', DEFAULT_TLE_OBJECT);
      changeHandler('tle1', TLE_OBJECT_BY_NAME[DEFAULT_TLE_OBJECT].tle1);
      changeHandler('tle2', TLE_OBJECT_BY_NAME[DEFAULT_TLE_OBJECT].tle2);
    }
  }, [parameterisationTle]);

  const showingFetch = parameterisationTle === PARAMETERISATION_TLE.FETCH;
  const loading = !(
    parameterisationTle === PARAMETERISATION_TLE.SPECIFY ||
    parameterisationTle === PARAMETERISATION_TLE.FETCH
  );

  return loading ? null : (
    <>
      <Field
        name="parameterisationTle"
        title="geometry.parameterisation"
        onChangeState={(props: any) =>
          changeHandler('parameterisationTle', props.value)
        }
        options={options}
        component={SelectField}
      />
      <Pre>
        {showingFetch ? (
          <div className="fetch-label">
            <Field
              name="tleObject"
              title="geometry.tle_objects_label"
              disabled={showingFetch}
              onChangeState={(props: any) => {
                changeHandler('tleObject', props);
                changeHandler('tle1', TLE_OBJECT_BY_NAME[props].tle1);
                changeHandler('tle2', TLE_OBJECT_BY_NAME[props].tle2);
              }}
              options={TLE_OBJECT_OPTIONS}
              component={SelectField}
            />
          </div>
        ) : (
          <table className="bp4-html-table bp4-small bp4-fill walker-constellation table">
            <thead>
              <tr>
                <th className="parameter">Parameter</th>
                <th className="value">Value</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>TLE 1</td>
                <td>
                  <Field
                    name="tle1"
                    disabled={showingFetch}
                    component={TextField}
                    type="textarea"
                  />
                </td>
              </tr>
              <tr>
                <td>TLE 2</td>
                <td>
                  <Field
                    name="tle2"
                    disabled={showingFetch}
                    component={TextField}
                    type="textarea"
                  />
                </td>
              </tr>
            </tbody>
          </table>
        )}
      </Pre>
    </>
  );
};

export const TLE = renderTLE;

const selector = formValueSelector(GEOMETRY_FORM_NAME);
const mapStateToProps = (state: AppState) => ({
  parameterisationTle: selector(state, 'parameterisationTle'),
});

export default connect<IMapStateToProps>(mapStateToProps)(TLE);
