import { TIME_FORMAT } from 'constants/datetime';
import moment from 'moment';
import type { match } from 'react-router';
import type { Activity } from 'api/activities/service';
import s from 'pages/ops/Scheduling/ScheduleActivityList/ActivityCard/index.module.scss';
import classNames from 'classnames';

type ActivityCardProps = {
  activity: Activity;
  handleActivityClick: (activity: Activity) => void;
  routeMatch: match<{
    mission: string;
    activity: string;
  }> | null;
};

export const ActivityCard = ({
  activity,
  handleActivityClick,
  routeMatch,
}: ActivityCardProps) => {
  const formatWrapperClass = () => {
    const baseClass = [
      s.infoWrapper,
      routeMatch?.params.activity === activity.id ? s.focused : null,
    ].join(' ');
    if (activity.status === 'FAILED') {
      return [baseClass, s.failed].join(' ');
    }

    if (activity.status === 'SUGGESTED') {
      return [baseClass, s.suggested].join(' ');
    }

    if (activity.status === 'SUCCEEDED') {
      return [baseClass, s.succeeded].join(' ');
    }

    return baseClass;
  };
  return (
    <div
      className={s.container}
      onClick={() => {
        handleActivityClick(activity);
      }}
      data-testid="activity-card"
    >
      <div className={s.timeWrapper}>
        <span>{moment.utc(activity.start_date).format(TIME_FORMAT)}</span>
        <span>{moment.utc(activity.end_date).format(TIME_FORMAT)}</span>
      </div>

      <div
        className={classNames(formatWrapperClass(), {
          'bg-warning': activity.status === 'CANCELLED',
        })}
      >
        <span className="text-[13px] w-full text-center">{activity.type}</span>
        <span className="text-[13px] w-full text-center whitespace-nowrap">
          {activity.id}
        </span>
        <span className="text-[13px] w-full text-center">
          {activity.status}
        </span>
      </div>
    </div>
  );
};
