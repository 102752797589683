import { Icon, Spinner } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import type { ProviderView } from 'datacosmos/stores/ViewsProvider';
import { useViews } from 'datacosmos/stores/ViewsProvider';
import { useMap } from 'datacosmos/stores/MapProvider';
import { useCallback, useMemo, useState, useEffect, useRef } from 'react';
import { useHistory, useLocation } from 'react-router';
import type {
  DialogListRenderer,
  DialogItemDetailsRenderer,
} from 'datacosmos/components/DatacosmosSelectDialog/DatacosmosSelectDialog';
import DatacosmosSelectDialog from 'datacosmos/components/DatacosmosSelectDialog/DatacosmosSelectDialog';
import s from 'datacosmos/components/Header/DatacosmosHeader.module.scss';
import DeleteViewDialog from './DeleteViewDialog';
import SaveViewDialog from './SaveViewDialog';
import {
  screenshot,
  screenshotLinkToUsableImage,
  screenshotMimeToExtension,
} from 'utils/screenshot';
import type { MimeType as ScMimeType } from 'utils/screenshot';
import { useViewMode } from 'datacosmos/utils/hooks/useViewMode';
import classNames from 'classnames';
import Button from '_molecules/Button/Button';
import type { Scenario } from 'api/scenarios/types';
import moment from 'moment';
import { DATETIME_FORMAT } from 'constants/datetime';
import { Tooltip } from 'ui/Tooltip';
import { useLocalisation } from 'utils/hooks/useLocalisation';
import { useAnalytics } from 'utils/hooks/analytics/useAnalytics';
import { Popover2 } from '@blueprintjs/popover2';
import PrimaryButton from '_molecules/Button/PrimaryButton';

import placeholderImage from 'images/datacosmos/img-placeholder.svg';
import OptionsDialog from '_organisms/OptionsDialog/OptionsDialog';
import { useMapLayers } from 'datacosmos/stores/MapLayersProvider';
import GeoPDFDetails from '../GeoPDFDetailsModal';
import { postNetCDFFile } from 'api/geopdf/service';
import { useAuth } from 'services/auth/AuthWrapper';
import { IconButton } from 'opencosmos-ui';
import { downloadScreenshotAsGeoJson } from 'datacosmos/download/geojson';

interface IProps {
  currentScenario?: Scenario;
  fetchCurrentProjectItems: () => Promise<void>;
}

const Views = ({ currentScenario, fetchCurrentProjectItems }: IProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isSaveDialogOpen, setIsSaveDialogOpen] = useState<boolean>(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState<boolean>(false);

  const uploadFileRef = useRef<HTMLInputElement>(null);

  const [isChangeFormatDialogOpen, setIsChangeFormatDialogOpen] =
    useState<boolean>(false);

  const { translate } = useLocalisation();

  const { sendInfo } = useAnalytics();

  const [isOverwriteDialogOpen, setIsOverwriteDialogOpen] =
    useState<boolean>(false);

  const {
    handleViewOverwrite,
    handleViewSave,
    isSaveEnabled,
    views,
    currentView,
    setCurrentView,
    handleViewDelete,
    initUntitledView,
    handleViewChange,
    loading,
    savedMime,
    setSavedMime,
  } = useViews();

  const { checkPermissions } = useAuth();

  const { isMinimal } = useViewMode();

  const [viewToDelete, setViewToDelete] = useState<ProviderView | undefined>(
    currentView
  );

  const history = useHistory();
  const location = useLocation();

  const { mapRef } = useMap();

  const { layers } = useMapLayers();

  const [selectedView, setSelectedView] = useState<ProviderView>();

  const [viewScreenshot, setViewScreenshot] = useState<string | undefined>(
    placeholderImage
  );

  const [isGeoPdfDetailsOpen, setIsGeoPdfDetailsOpen] =
    useState<boolean>(false);
  // Cache fetcehd screenshots to avoid refetching
  const [cachedScreenshots, setCachedScreenshots] = useState<
    { screenshot: string | undefined; viewId: string }[]
  >([]);

  const [isUploadingFile, setIsUploadingFile] = useState<boolean>(false);

  const [canWriteToProject, setCanWriteToProject] = useState<boolean>(false);

  useEffect(() => {
    const checkPermissionsForProject = async () => {
      if (!currentScenario) {
        return;
      }
      const canWrite = await checkPermissions({
        actionScope: 'data:scenario:write',
        type: 'datacosmos_scenario',
        id: currentScenario.id,
      });
      setCanWriteToProject(canWrite);
    };

    void checkPermissionsForProject();
  }, [checkPermissions, currentScenario]);

  useEffect(() => {
    if (!selectedView) {
      return;
    }
    const foundSc = cachedScreenshots.find(
      (sc) => sc.viewId === selectedView.id
    );
    if (foundSc) {
      setViewScreenshot(foundSc.screenshot);
      return;
    }

    screenshotLinkToUsableImage(selectedView.screenshot_link)
      .then((res) => {
        setViewScreenshot(res);
        setCachedScreenshots([
          ...cachedScreenshots,
          { screenshot: res, viewId: selectedView.id },
        ]);
      })
      .catch(() => {});
  }, [cachedScreenshots, selectedView]);
  const downloadScreenshot = useCallback(
    (mimeType?: ScMimeType) => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      const container = mapRef.current?.getContainer
        ? // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
          (mapRef.current.getContainer() as HTMLDivElement)
        : null;

      if (!container) return;

      const mime = mimeType ?? savedMime;
      setSavedMime(mime);

      if (mimeType === 'application/pdf') {
        setIsGeoPdfDetailsOpen(true);
        return;
      }

      void screenshot(container, {
        mimeType: mime,
        ignore: 'zoom-controls',
      }).then((base64Image) => {
        const downloadLink = document.createElement('a');
        downloadLink.download = `open_cosmos_screenshot_${new Date().toISOString()}${screenshotMimeToExtension(
          mime
        )}`;
        downloadLink.href = base64Image;
        downloadLink.dataset.downloadurl = [
          mime,
          downloadLink.download,
          downloadLink.href,
        ].join(':');

        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
        downloadLink.remove();
      });
    },
    [mapRef, savedMime, setSavedMime]
  );

  const mimeDialogOptions = useMemo(
    () => [
      {
        optionType: 'Raster',
        formats: [
          {
            name: 'PNG',
            onClick: () => {
              setSavedMime('image/png');
              downloadScreenshot('image/png');
            },
          },
          {
            name: 'JPEG',
            onClick: () => {
              setSavedMime('image/jpeg');
              downloadScreenshot('image/jpeg');
            },
          },
          {
            name: 'GIF',
            onClick: () => {
              setSavedMime('image/gif');
              downloadScreenshot('image/gif');
            },
          },
          {
            name: 'TIFF',
            onClick: () => {
              setSavedMime('image/tiff');
              downloadScreenshot('image/tiff');
            },
          },
          {
            name: 'GeoPDF',
            onClick: () => {
              setSavedMime('application/pdf');
              downloadScreenshot('application/pdf');
            },
          },
        ],
      },
      {
        optionType: 'Vector',
        formats: [
          {
            name: 'GeoJSON',
          },
        ],
      },
    ],
    [downloadScreenshot, setSavedMime]
  );

  const renderViews: DialogListRenderer<ProviderView> = (item, select) => {
    if (loading) {
      return <Spinner />;
    }
    return (
      <div
        style={{
          display: 'flex',
          gap: '5px',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
        onClick={() => {
          select();
          setViewToDelete(item);
        }}
      >
        <span>{item.name}</span>
      </div>
    );
  };

  const viewDetailsRenderer: DialogItemDetailsRenderer<ProviderView> = (
    view,
    open
  ) => {
    return (
      <div className="flex flex-col grow h-full p-2 pb-8 gap-2">
        <div className="flex flex-col gap-6">
          <h2 className="p-0 m-0">{view.name}</h2>

          <span>{view.description}</span>

          <img
            src={viewScreenshot}
            alt="View screenshot"
            className="max-h-52 h-full"
          />

          {/* TODO: Implement when available */}
          <span>
            {translate('datacosmos.viewsDialog.sharedWith', { with: 'n/a' })}
          </span>

          <span data-testid="datacosmos-view-panel-created-at">
            {translate('datacosmos.viewsDialog.createdAt', {
              at: moment(view.created_at).format(DATETIME_FORMAT),
            })}
          </span>
          <span data-testid="datacosmos-view-panel-updated-at">
            {translate('datacosmos.viewsDialog.lastUpdated', {
              at: moment(view.updated_at).format(DATETIME_FORMAT),
            })}
          </span>
        </div>

        <div className="flex items-center justify-evenly gap-2">
          <Button
            text={translate('datacosmos.viewsDialog.openView')}
            onPress={() => {
              open(view);
              setSelectedView(undefined);
              sendInfo({
                type: ` Opened a view: ${view.id} - ${view.name} `,
                action: 'Click',
                item: 'View',
                module: 'DataCosmos',
                additionalParams: {
                  view: view,
                },
              });
            }}
            icon="Folder"
          />

          <Button
            text={translate('datacosmos.viewsDialog.shareView')}
            onPress={() => {
              history.push(`/resource/view/${view.id}`);
            }}
            icon="ShareDots"
          />

          <Button
            icon="Trash"
            text={translate('datacosmos.viewsDialog.deleteView')}
            onPress={() => {
              setIsDeleteDialogOpen(true);
            }}
          />
        </div>
      </div>
    );
  };

  const handleViewOpen = (view: ProviderView) => {
    sendInfo({
      type: `View select: ${view.id} - ${view.name} `,
      action: 'Click',
      item: 'View',
      module: 'DataCosmos',
      additionalParams: {
        project: view,
      },
    });
    setCurrentView(view);
    handleViewChange(view);
    setIsOpen(false);
    history.push(`${location.pathname}?view=${view.id}`);
  };

  const returnToBlankView = () => {
    sendInfo({
      type: 'Free edit mode select',
      action: 'Click',
      item: 'Free edit mode',
      module: 'DataCosmos',
    });
    initUntitledView();
    setIsOpen(false);
    history.push(`${location.pathname}`);
  };

  return (
    <div
      style={{
        marginLeft: '5px',
        display: 'flex',
        gap: '10px',
        alignItems: 'center',
      }}
    >
      <span
        className={classNames(s.scenarioName, {
          'text-item-contrast dark:text-item-contrast cursor-default':
            isMinimal,
        })}
        data-testid="open-views-modal-button"
        onClick={() => {
          if (isMinimal) return;
          sendInfo({
            type: 'View selection open',
            action: 'Click',
            item: 'View',
            module: 'DataCosmos',
          });
          setIsOpen(true);
        }}
      >
        |{' '}
        {currentView?.id
          ? currentView.name
          : translate('datacosmos.header.freeEditingMode')}
      </span>
      {isSaveEnabled && !isMinimal && (
        <Tooltip content={translate('datacosmos.tooltips.header.saveView')}>
          <Icon
            icon={IconNames.FLOPPY_DISK}
            style={{ cursor: 'pointer' }}
            onClick={() => {
              sendInfo({
                type: 'View saved',
                action: 'Click',
                item: 'View',
                module: 'DataCosmos',
              });
              currentView?.id
                ? setIsOverwriteDialogOpen(true)
                : setIsSaveDialogOpen(true);
            }}
          />
        </Tooltip>
      )}
      <Popover2
        interactionKind="hover"
        position="bottom"
        minimal
        disabled={layers.length === 0}
        content={
          <div className="p-2 flex items-center gap-2 dark:bg-surface-dark dark:text-item-dark-contrast ">
            <span>
              {translate('datacosmos.tooltips.header.downloadScreenshot', {
                format: screenshotMimeToExtension(savedMime),
              })}
            </span>

            <PrimaryButton
              text={translate('datacosmos.optionsDialog.changeFormat')}
              onPress={() => setIsChangeFormatDialogOpen(true)}
            />
          </div>
        }
      >
        <Icon
          icon={IconNames.CAMERA}
          style={{ cursor: layers.length === 0 ? 'not-allowed' : 'pointer' }}
          onClick={() => {
            if (layers.length === 0) {
              return;
            }
            sendInfo({
              type: 'Screenshot taken',
              action: 'Click',
              item: 'Screenshot',
              module: 'DataCosmos',
              additionalParams: {
                format: screenshotMimeToExtension(savedMime),
              },
            });
            if (savedMime === 'application/pdf') {
              setIsGeoPdfDetailsOpen(true);
              return;
            }

            if (savedMime === 'file/geojson') {
              downloadScreenshotAsGeoJson(layers);
              return;
            }
            downloadScreenshot();
          }}
          className={classNames({
            'opacity-50': layers.length === 0,
          })}
        />
      </Popover2>
      <Popover2
        interactionKind="hover"
        position="bottom"
        minimal
        content={
          <div className="p-2 flex items-center gap-2 dark:bg-surface-dark dark:text-item-dark-contrast ">
            <span>
              {canWriteToProject
                ? translate('datacosmos.tooltips.header.uploadFile')
                : translate('datacosmos.tooltips.header.noUploadPermissions')}
            </span>
          </div>
        }
      >
        {isUploadingFile ? (
          <Spinner size={18} />
        ) : (
          <IconButton
            icon="Upload"
            onPress={() => {
              uploadFileRef.current?.click();
            }}
            disabled={!canWriteToProject}
          />
        )}
      </Popover2>
      <DatacosmosSelectDialog<ProviderView>
        items={views.filter(
          (v) => currentScenario && v.project === currentScenario.id
        )}
        title={translate('datacosmos.viewsDialog.views')}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        listRenderer={renderViews}
        listItemClickHandler={handleViewOpen}
        handleAddItemClick={returnToBlankView}
        addItemTitle={translate(
          'datacosmos.viewsDialog.returnToFreeEditingMode'
        )}
        handleItemDetailsRender={viewDetailsRenderer}
        sortListBy="name"
        selectedItem={selectedView}
        setSelectedItem={(view) => {
          setSelectedView(view);
          sendInfo({
            type: `Selected a view: ${view?.id ?? 'no id'} - ${
              view?.name ?? 'no name'
            } `,
            action: 'Click',
            item: 'View',
            module: 'DataCosmos',
            additionalParams: {
              view: view,
            },
          });
        }}
        isContentLoading={loading}
      />
      <SaveViewDialog
        view={currentView}
        isOpen={isSaveDialogOpen}
        setIsOpen={setIsSaveDialogOpen}
        handleSave={(name, desc) => {
          void handleViewSave(name, desc);
          sendInfo({
            type: `Save new view: ${name}`,
            action: 'Click',
            item: 'View',
            module: 'DataCosmos',
            additionalParams: {
              viewName: name,
              viewDescription: desc,
            },
          });
        }}
      />
      <SaveViewDialog
        view={currentView}
        isOpen={isOverwriteDialogOpen}
        setIsOpen={setIsOverwriteDialogOpen}
        handleSave={(name, desc, id) => {
          id && void handleViewOverwrite(name, desc, id);
          setSelectedView(undefined);
          sendInfo({
            type: `Overwrite existing view; overwritten view ${
              id ?? 'unknown'
            } with view ${name} `,
            action: 'Click',
            item: 'View',
            module: 'DataCosmos',
            additionalParams: {
              viewName: name,
              viewDescription: desc,
            },
          });
        }}
        overwrite
      />
      <DeleteViewDialog
        view={viewToDelete}
        deleteHandler={async (viewId) => {
          await handleViewDelete(viewId);
          setSelectedView(undefined);
        }}
        isOpen={isDeleteDialogOpen}
        setIsOpen={setIsDeleteDialogOpen}
      />
      <OptionsDialog
        options={mimeDialogOptions}
        isOpen={isChangeFormatDialogOpen}
        setIsOpen={setIsChangeFormatDialogOpen}
        title={translate('datacosmos.optionsDialog.changeFormat')}
        setMimeType={setSavedMime}
        showOptionsUnderTabs
        layers={layers}
      />
      <input
        onChange={async (e) => {
          const file = e.target.files?.[0];

          if (!file) {
            return;
          }

          const formData = new FormData();
          formData.append('file', file);

          setIsUploadingFile(true);
          await postNetCDFFile({
            params: { projectId: currentScenario?.id ?? '' },
            body: formData,
          });

          await fetchCurrentProjectItems();
          setIsUploadingFile(false);

          if (uploadFileRef.current?.value) {
            uploadFileRef.current.value = '';
          }
        }}
        ref={uploadFileRef}
        type={'file'}
        style={{ display: 'none' }}
        accept={'.nc4, .dat, .hdr'}
      />
      <GeoPDFDetails
        isOpen={isGeoPdfDetailsOpen}
        setIsOpen={setIsGeoPdfDetailsOpen}
      />
    </div>
  );
};

export default Views;
