import React from "react";
import { useSearchFieldState } from "react-stately";
import { useSearchField } from "react-aria";
import type { SearchFieldProps } from "@react-types/searchfield";
import classNames from "classnames";
import IconButton from "../../core/IconButton/IconButton";
import Spinner from "../Spinner/Spinner";

export type SearchInputProps = {
  /**
   * Whether or not to make the text field fill the entire container
   * Default to 200px
   */
  fullWidth: boolean;
  size?: "small" | "medium" | "large";
  label?: string;
  onSubmit?: ((value: string) => void) | undefined;
  onChange?: ((value: string) => void) | undefined;
  value?: string;
  defaultValue?: string;
  submitLoading?: boolean;
} & SearchFieldProps;

/**
 * SearchInput component represents a search bar with a Search button and
 * handeled keyboard input
 */
const SearchInput = (props: SearchInputProps) => {
  const { label, onSubmit, fullWidth, onChange } = props;
  const state = useSearchFieldState(props);
  const ref = React.useRef(null);
  const { labelProps, inputProps } = useSearchField(props, state, ref);
  return (
    <div
      className={classNames("flex flex-col", {
        "w-full": fullWidth,
        "w-[200px]": !fullWidth,
      })}
      style={{ display: "flex", flexDirection: "column" }}
    >
      <label {...labelProps} className="text-sm">
        {label}
      </label>
      <div
        className={classNames(
          "flex gap-1 items-center border border-neutral-600 p-1 outline-none bg-neutral-100",
          {
            "h-12": props.size === "large",
            "h-8": props.size === "medium" || props.size === undefined,
            "h-6": props.size === "small",
          }
        )}
      >
        {props.submitLoading ? (
          <Spinner />
        ) : (
          <IconButton
            icon="MagnifyingGlass"
            size={props.size === "large" ? 24 : 16}
            className="outline-none"
            onPress={() => onSubmit?.(state.value)}
            disabled={onChange !== undefined || onSubmit === undefined}
          />
        )}
        <input
          ref={ref}
          className="outline-none bg-neutral-100 w-full"
          {...inputProps}
        />
        <IconButton
          icon="Cross"
          size={props.size === "large" ? 24 : 16}
          className="outline-none"
          onPress={() => {
            state.setValue("");
          }}
        />
      </div>
    </div>
  );
};

export default SearchInput;
