import { Dialog } from '@blueprintjs/core';
import type { ProviderView } from 'datacosmos/stores/ViewsProvider';
import React, { useState } from 'react';
import Button from '_molecules/Button/Button';
import s from 'datacosmos/components/DatacosmosViews/index.module.scss';
import { useLocalisation } from 'utils/hooks/useLocalisation';

interface IProps {
  view: ProviderView | undefined;
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  handleSave: (name: string, description: string, viewId?: string) => void;
  overwrite?: true;
}

const SaveViewDialog = ({
  isOpen,
  setIsOpen,
  handleSave,
  overwrite,
  view,
}: IProps) => {
  const [name, setName] = useState<string>(view?.name ?? '');
  const [description, setDescription] = useState<string>(
    view?.description ?? ''
  );

  const { translate } = useLocalisation();
  return (
    <Dialog
      isOpen={isOpen}
      canEscapeKeyClose
      onClose={() => setIsOpen(false)}
      className="dark:bg-surface-dark dark:text-item-dark-contrast bg-surface"
    >
      <div
        className={
          s.saveDialogContainer +
          ' dark:bg-surface-dark dark:text-item-dark-contrast'
        }
      >
        <div className={s.saveDialogHeader}>
          <span>
            {overwrite
              ? translate('datacosmos.saveViewDialog.overwrite', {
                  name: view?.name ?? 'unknown',
                })
              : translate('datacosmos.saveViewDialog.save')}
          </span>
        </div>

        {!overwrite && (
          <div
            className={
              s.saveDialogBody +
              ' dark:bg-surface-dark dark:text-item-dark-contrast'
            }
          >
            <div>
              <label htmlFor="name">
                {translate('datacosmos.saveViewDialog.inputs.name')}{' '}
              </label>
              <input
                type="text"
                name="name"
                id="name"
                placeholder={translate(
                  'datacosmos.saveViewDialog.inputs.namePlaceholder'
                )}
                autoComplete="off"
                onChange={(e) => setName(e.target.value)}
                className="dark:bg-item-dark dark:text-item-dark-contrast h-8 border-2 border-item-contrast-text-field-stroke"
              />
            </div>

            <div>
              <label htmlFor="description">
                {translate('datacosmos.saveViewDialog.inputs.description')}{' '}
              </label>
              <textarea
                name="description"
                id="description"
                placeholder={translate(
                  'datacosmos.saveViewDialog.inputs.descriptionPlaceholder'
                )}
                onChange={(e) => setDescription(e.target.value)}
                style={{ resize: 'none' }}
                className="dark:bg-item-dark dark:text-item-dark-contrast h-16 border-2 border-item-contrast-text-field-stroke"
              />
            </div>
          </div>
        )}

        <div
          className={
            s.saveDialogFooter +
            ' dark:bg-surface-dark dark:text-item-dark-contrast'
          }
        >
          <Button
            onPress={() => setIsOpen(false)}
            className="dark:bg-item-dark"
            text={'Cancel'}
          />
          <Button
            onPress={() => {
              if (view?.id) {
                handleSave(view.name, view.description, view.id);
              } else {
                handleSave(name, description);
              }

              setIsOpen(false);
            }}
            disabled={!overwrite && (!name || !description)}
            text={overwrite ? 'Overwrite' : ' Save'}
          />
        </div>
      </div>
    </Dialog>
  );
};

export default SaveViewDialog;
