import { Button, Intent, MenuItem } from '@blueprintjs/core';
import type { Activity } from 'api/activities/service';
import {
  CompositeDetailsItem,
  DetailsItem,
  JSONDetailsItem,
} from 'pages/ops/Scheduling/ScheduleActivityDetails/DetailCards/DetailsItem';
import { Select2 } from '@blueprintjs/select';
import Textarea from '_atoms/Textarea/Textarea';

type ActivityDetailsCardProps = {
  activity: Activity;
  updateActivityStatus: () => Promise<void>;
  setSelectedStatus: (status: Activity['status']) => void;
  selectedStatus: Activity['status'] | undefined;
  updateActivityWithOperatorNotes: () => void;
  operatorNotes: string | undefined;
  setOperatorNotes: (note: string) => void;
};

const ACTIVITY_STATUSES: Activity['status'][] = [
  'SUGGESTED',
  'CONFIRMED',
  'SCHEDULED',
  'SUCCEEDED',
  'EXPIRED',
  'FAILED',
  'CANCELLED',
];

export const ActivityDetailsCard = ({
  activity,
  updateActivityStatus,
  setSelectedStatus,
  selectedStatus,
  operatorNotes,
  updateActivityWithOperatorNotes,
  setOperatorNotes,
}: ActivityDetailsCardProps) => {
  return (
    <div className="flex flex-col gap-1">
      <div className="flex justify-between items-center">
        <h2>Activity</h2>

        <div className="flex items-center gap-2">
          <span>Status: </span>
          <Select2<Activity['status']>
            onItemSelect={(item) => {
              setSelectedStatus(item);
            }}
            items={ACTIVITY_STATUSES}
            filterable={false}
            itemRenderer={(item, { handleClick, modifiers }) => (
              <MenuItem
                active={modifiers.active}
                disabled={modifiers.disabled}
                key={item}
                text={item}
                onClick={handleClick}
              />
            )}
          >
            <Button text={selectedStatus} rightIcon="double-caret-vertical" />
          </Select2>
          <Button
            text="Update status"
            className="w-32 h-5"
            intent={Intent.DANGER}
            onClick={() => updateActivityStatus()}
            disabled={activity.status === 'CANCELLED'}
          />
        </div>
      </div>

      <div className="flex flex-col justify-between gap-3">
        <DetailsItem title="ID: " value={activity.id} />
        <DetailsItem title="Status: " value={activity.status} />
        <DetailsItem title="Mission ID: " value={activity.mission_id} />
        <DetailsItem
          title="Imager ID:"
          value={activity.parameters.imager.name}
        />

        <DetailsItem
          title="Priority: "
          value={activity?.priority?.toString()}
        />

        <DetailsItem title="Start Time: " value={activity.start_date} />
        <DetailsItem title="End Time: " value={activity.end_date} />
        <DetailsItem
          title="Roll Angle: "
          value={`${Number(activity.parameters.platform.roll_angle).toFixed(
            2
          )} deg`}
        />

        <DetailsItem
          title="Observation Zenith Angle:"
          value={`${Number(
            activity.parameters?.physical?.latest?.midpoint.oza_deg
          ).toFixed(2)} deg`}
        />
        <DetailsItem
          title="Sun Zenith Angle: "
          value={`${Number(
            activity.parameters?.physical?.latest?.midpoint.sza_deg
          ).toFixed(2)} deg`}
        />
        <DetailsItem title="Coverage: " value="-" />
        {activity.parameters.imager && (
          <JSONDetailsItem title="Imager" value={activity.parameters.imager} />
        )}
        {activity.parameters.platform && (
          <JSONDetailsItem
            title="Platform"
            value={activity.parameters.platform}
          />
        )}
        {activity.parameters.ground_processing && (
          <JSONDetailsItem
            title="Ground processing"
            value={activity.parameters.ground_processing}
          />
        )}
        {activity.parameters.onboard_processing && (
          <JSONDetailsItem
            title="Onboard Processing"
            value={activity.parameters.onboard_processing}
          />
        )}
        {activity.parameters.stac && (
          <JSONDetailsItem title="STAC" value={activity.parameters.stac} />
        )}

        <CompositeDetailsItem
          title="Operator notes: "
          element={
            <div>
              <Textarea
                value={operatorNotes}
                onChange={(e) => {
                  setOperatorNotes(e.target.value);
                }}
              />
              <Button
                intent="primary"
                text="Submit"
                onClick={() => {
                  updateActivityWithOperatorNotes();
                }}
              />
            </div>
          }
        />
      </div>
    </div>
  );
};
