import {
  hideLeftPanel,
  hideRightPanel,
  showLeftPanel,
  showRightPanel,
} from 'actions/operate/actions';
import { useHotkeys } from 'react-hotkeys-hook';
import { useOperate } from 'services/Operate';
import type { KeyboardShortcut } from './types';
import {
  HOTKEY_COPY_COMMAND,
  HOTKEY_COPY_COMMAND_PILE,
  HOTKEY_NAVIGATE_CMD_PILE_DOWN,
  HOTKEY_NAVIGATE_CMD_PILE_UP,
  HOTKEY_PASTE_COMMAND,
  HOTKEY_PASTE_COMMAND_PILE,
  HOTKEY_SEND_COMMAND_FOCUS_NEXT,
  HOTKEY_SEND_COMMAND_FOCUS_REPLY,
  HOTKEY_TOGGLE_COMMAND_HISTORY_COLLAPSE,
  HOTKEY_TOGGLE_LEFT_PANEL,
  HOTKEY_TOGGLE_RIGHT_PANEL,
} from './keys';
import { useCommandPileHandlers } from '../commands/useCommandPileHandlers';
import { useSendCommandHandlers } from '../commands/useSendCommandHandlers';
import { useCommandCopyPaste } from '../commands/useCommandCopyPaste';
import { focusNextPileCommand } from './helpers';
import { useCurrentTerminalSession } from 'services/TerminalSessionProvider';

import type { Cmd } from 'opencosmos-ui';

/**
 * useRtiHotkeys is a hook that defines and registers hotkeys for the RTI.
 */
export const useRtiHotkeys = () => {
  const { dispatch, state } = useOperate();
  const { handleEditablePileItemSelect } = useCommandPileHandlers();
  const { handleSendPiledCommand, handleSendArmedCommand } =
    useSendCommandHandlers();
  const {
    handleCopySelectedCommands,
    handlePasteCmd,
    handleCopyCommandPileAndArmedCommands,
    handlePasteCommandPileAndArmedCommands,
  } = useCommandCopyPaste();

  const { availableCommands } = useCurrentTerminalSession();

  const toggleLeftPanelShortcut = (): KeyboardShortcut => {
    const action = () => {
      if (state.isLeftSidePanelHidden) {
        dispatch(showLeftPanel());
        return;
      }
      dispatch(hideLeftPanel());
    };

    return [HOTKEY_TOGGLE_LEFT_PANEL, action];
  };

  const toggleRightPanelShortcut = (): KeyboardShortcut => {
    const action = () => {
      if (state.isRightSidePanelHidden) {
        dispatch(showRightPanel());
        return;
      }
      dispatch(hideRightPanel());
    };

    return [HOTKEY_TOGGLE_RIGHT_PANEL, action];
  };

  const toggleCommandHistoryCollapseShortcut = (): KeyboardShortcut => {
    const action = () => {
      dispatch({
        type: 'TOGGLE_COMMAND_HISTORY_COLLAPSE',
        payload: {
          isCommandHistoryCollapsed: !state.isCommandHistoryCollapsed,
        },
      });
    };

    return [HOTKEY_TOGGLE_COMMAND_HISTORY_COLLAPSE, action];
  };

  const navigateCommandPileUpShortcut = (): KeyboardShortcut => {
    const action = () => {
      if (!state.commandPileWorkingSpace) {
        return;
      }
      if (!state.selectedPileItem) {
        return;
      }
      const foundItemIndex = state.commandPileWorkingSpace?.findIndex(
        (item) => item.id === state.selectedPileItem?.id
      );
      const nextItemIndex = foundItemIndex - 1;

      handleEditablePileItemSelect(
        state.commandPileWorkingSpace[nextItemIndex],
        false
      );
    };

    return [HOTKEY_NAVIGATE_CMD_PILE_UP, action];
  };

  const navigateCommandPileDownShortcut = (): KeyboardShortcut => {
    const action = () => {
      if (!state.commandPileWorkingSpace) {
        return;
      }
      if (!state.selectedPileItem) {
        return;
      }
      const foundItemIndex = state.commandPileWorkingSpace?.findIndex(
        (item) => item.id === state.selectedPileItem?.id
      );
      const nextItemIndex = foundItemIndex + 1;

      handleEditablePileItemSelect(
        state.commandPileWorkingSpace[nextItemIndex],
        false
      );
    };

    return [HOTKEY_NAVIGATE_CMD_PILE_DOWN, action];
  };

  const sendCommandFocusNextShortcut = (): KeyboardShortcut => {
    const action = async () => {
      if (state.armedCommand) {
        handleSendArmedCommand();
      }

      if (!state.selectedPileItem || !state.procedureName) {
        return;
      }

      focusNextPileCommand(state, dispatch, availableCommands);

      await handleSendPiledCommand(
        state.selectedPileItem,
        !state.frozenPile,
        state.procedureName,
        false
      );
    };

    return [HOTKEY_SEND_COMMAND_FOCUS_NEXT, action];
  };

  const sendCommandFocusReplyShortcut = (): KeyboardShortcut => {
    const action = async () => {
      if (state.armedCommand) {
        handleSendArmedCommand();
      }

      if (!state.selectedPileItem || !state.procedureName) {
        return;
      }

      await handleSendPiledCommand(
        state.selectedPileItem,
        !state.frozenPile,
        state.procedureName,
        true
      );
    };

    return [HOTKEY_SEND_COMMAND_FOCUS_REPLY, action];
  };

  const copyCommandShortcut = (): KeyboardShortcut => {
    const action = () => {
      handleCopySelectedCommands();
    };

    return [HOTKEY_COPY_COMMAND, action];
  };

  const pasteCommandShortcut = (): KeyboardShortcut => {
    const action = async () => {
      const foundItemIndex = state.commandPileWorkingSpace?.findIndex(
        (item) => item.id === state.selectedPileItem?.id
      );

      if (!foundItemIndex) {
        return;
      }
      await handlePasteCmd(foundItemIndex);
    };

    return [HOTKEY_PASTE_COMMAND, action];
  };

  const copyCmdPileShortcut = (): KeyboardShortcut => {
    const action = () => {
      handleCopyCommandPileAndArmedCommands();
    };

    return [HOTKEY_COPY_COMMAND_PILE, action];
  };

  const pasteCmdPileShortcut = (): KeyboardShortcut => {
    const action = () => {
      handlePasteCommandPileAndArmedCommands();
    };

    return [HOTKEY_PASTE_COMMAND_PILE, action];
  };

  // Register hotkeys here
  // If a hotkey conflicts with a browser hotkey,
  // use {preventDefault: true} to block the browser hotkey
  // e.g. useHotkeys(...toggleLeftPanelShortcut(), {preventDefault: true});
  useHotkeys(...toggleLeftPanelShortcut());
  useHotkeys(...toggleRightPanelShortcut());
  useHotkeys(...toggleCommandHistoryCollapseShortcut());
  useHotkeys(...navigateCommandPileUpShortcut());
  useHotkeys(...navigateCommandPileDownShortcut());
  useHotkeys(...sendCommandFocusNextShortcut());
  useHotkeys(...sendCommandFocusReplyShortcut());
  useHotkeys(...copyCommandShortcut());
  useHotkeys(...pasteCommandShortcut());
  useHotkeys(...copyCmdPileShortcut());
  useHotkeys(...pasteCmdPileShortcut());

  // Shortcut list for the Command Menu
  const rtiCommandList: Cmd[] = [
    {
      name: 'Toggle left panel',
      action: toggleLeftPanelShortcut()[1],
      keyCombination: toggleLeftPanelShortcut()[0],
      group: 'Layout',
      description: "Toggles the left panel's visibility, on or off.",
    },
    {
      name: 'Toggle right panel',
      action: toggleRightPanelShortcut()[1],
      keyCombination: toggleRightPanelShortcut()[0],
      group: 'Layout',
      description: "Toggles the right panel's visibility, on or off.",
    },
    {
      name: 'Toggle command history collapse',
      action: toggleCommandHistoryCollapseShortcut()[1],
      keyCombination: toggleCommandHistoryCollapseShortcut()[0],
      group: 'Layout',
      description: 'Collapses or expands the command history',
    },
    {
      name: 'Navigate command pile up',
      action: navigateCommandPileUpShortcut()[1],
      keyCombination: navigateCommandPileUpShortcut()[0],
      group: 'Navigation',
      description: 'Navigates up the command pile',
    },
    {
      name: 'Navigate command pile down',
      action: navigateCommandPileDownShortcut()[1],
      keyCombination: navigateCommandPileDownShortcut()[0],
      group: 'Navigation',
      description: 'Navigates down the command pile',
    },
    {
      name: 'Send command and focus next command',
      action: sendCommandFocusNextShortcut()[1],
      keyCombination: sendCommandFocusNextShortcut()[0],
      group: 'Commands',
      description:
        'Send the currently selected command directly to the satellite and focus the next command',
    },
    {
      name: 'Send command and focus reply',
      action: sendCommandFocusReplyShortcut()[1],
      keyCombination: sendCommandFocusReplyShortcut()[0],
      group: 'Commands',
      description:
        'Send the currently selected command directly to the satellite and focus the reply',
    },
    {
      name: 'Copy command',
      action: copyCommandShortcut()[1],
      keyCombination: copyCommandShortcut()[0],
      group: 'Commands',
      description: 'Copies the currently selected command to the clipboard',
    },
    {
      name: 'Paste command',
      action: pasteCommandShortcut()[1],
      keyCombination: pasteCommandShortcut()[0],
      group: 'Commands',
      description: 'Pastes the command from the clipboard to the command pile',
    },
    {
      name: 'Copy command pile',
      action: copyCmdPileShortcut()[1],
      keyCombination: copyCmdPileShortcut()[0],
      group: 'Commands',
      description: 'Copies the entire command pile to the clipboard',
    },
    {
      name: 'Paste command pile',
      action: pasteCmdPileShortcut()[1],
      keyCombination: pasteCmdPileShortcut()[0],
      group: 'Commands',
      description: 'Pastes the command pile from the clipboard',
    },
  ];

  return {
    rtiCommandList,
  };
};
