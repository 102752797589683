import { Classes, H6 } from '@blueprintjs/core';
import type { ReactElement } from 'react';
import React from 'react';
import type { WithTranslation } from 'react-i18next';
import { withTranslation } from 'react-i18next';
import type { WrappedFieldProps } from 'redux-form';

interface ITextFieldProps extends WithTranslation, WrappedFieldProps {
  type: string;
  disable?: boolean;
}

function renderField(field: ITextFieldProps): ReactElement {
  const { input, meta, type, t, disable } = field;
  const titleStyle = [
    'bp4-input-group',
    meta.error ? Classes.INTENT_DANGER : '',
  ].join(' ');
  const errorDiv = meta.error ? (
    <div className="bp4-callout bp4-intent-danger bp4-icon-error">
      <H6 className="bp4-heading">
        {t(`validation.errors.${meta.error}` as any)}
      </H6>
    </div>
  ) : null;
  return (
    <div>
      <div className="bp4-fill">
        <div className={`${titleStyle} right`}>
          <input
            className="bp4-input"
            readOnly={disable}
            type={type}
            placeholder={t(`validation.text-field.names.${input.name}` as any)}
            value={input.value}
            onChange={input.onChange}
          />
        </div>
      </div>
      {errorDiv}
    </div>
  );
}

export default withTranslation()(renderField);
