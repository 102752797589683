import type { Price } from 'api/orders/types';
import classNames from 'classnames';
import type { StacItem } from 'datacosmos/types/stac-types';

interface IProps {
  image: StacItem | undefined;
  price: Price;
  onClick: () => void;
  currentImage: StacItem | undefined;
}

const ConfirmPurchaseImageCard = ({
  image,
  onClick,
  currentImage,
  price,
}: IProps) => {
  const isSelected = image?.id === currentImage?.id;
  return (
    <tr
      data-testid="image-to-purchase"
      onClick={() => onClick()}
      className={classNames('cursor-pointer h-10 table w-full table-fixed', {
        'bg-item-selected dark:bg-item-dark-selected': isSelected,
      })}
    >
      <td data-testid="image-title">{image?.title()}</td>
      <td data-testid="image-location">{image?.location()}</td>
      <td data-testid="image-date">{image?.date()}</td>
      <td data-testid="image-price">
        {price.final} {price.currency}
      </td>
    </tr>
  );
};

export default ConfirmPurchaseImageCard;
