import { Alert, Intent } from '@blueprintjs/core';
import type { ReactElement } from 'react';
import React, { useState } from 'react';

interface IAlertProps {
  message: string;
  confirmText?: string;
  removeDiv: Function;
}

const AlertInfo = (props: IAlertProps): ReactElement => {
  const [isOpen, setIsOpen] = useState(true);
  const onClose = (): void => {
    setIsOpen(false);
    props.removeDiv();
  };

  return (
    <Alert
      confirmButtonText={props.confirmText || 'Okay'}
      icon="issue"
      isOpen={isOpen}
      intent={Intent.NONE}
      onClose={onClose}
    >
      <p>{props.message}</p>
    </Alert>
  );
};

export default AlertInfo;
