import type { IGeometryObject } from '../../constants/msd/geometry/interfaces';
import type { InjectedFormProps } from 'redux-form';
import type { IAnyKey } from '../getFormData';

export const geometryNormalize = (
  geometryObject: IGeometryObject,
  formProps: InjectedFormProps<FormData>
) => {
  const normalizedGeometry: IAnyKey = {};
  let valueKeys = [] as string[];
  // @ts-expect-error
  for (const key in formProps.registeredFields) {
    const splittedName = key.split('.');
    switch (splittedName.length) {
      case 1: {
        valueKeys.push(splittedName[0]);
        break;
      }
      case 2: {
        if (!valueKeys.find((f) => splittedName[0] === f)) {
          valueKeys.push(splittedName[0]);
        }
        break;
      }
    }
  }
  const groups = valueKeys.filter(
    (key) => key.split('_')[0].split('-')[0] === 'group'
  );
  valueKeys = valueKeys.filter(
    (key) => key.split('_')[0].split('-')[0] !== 'group'
  );
  while (valueKeys.length) {
    normalizedGeometry[valueKeys[0]] = (geometryObject as IAnyKey)[
      valueKeys[0]
    ];
    valueKeys.shift();
  }
  normalizedGeometry.id = geometryObject.id;

  normalizedGeometry.groups = {};
  groups.forEach((group) => {
    // @ts-expect-error
    normalizedGeometry.groups[group] = formProps.values[group];
  });
  return normalizedGeometry;
};
