import type echarts from 'echarts';
import type { IInitData } from '../../components/simulation/Simulation';
import type { IAxis, ISeriesData } from '../../components/charts/Chart';
import { getAxisData } from './getAxisData';
import { color, symbolStyle } from '../../constants/chart/constants';
import type { IChartWrapper } from '../../declarations/chartDeclarations/IChartWrapper';

type Format = echarts.EChartOption.Tooltip.Format;

export const updateChartInitData = (
  chart: IChartWrapper,
  initData: IInitData,
  dataZoom: { start: number; end: number },
  setState: Function
): void => {
  const satellitesData = initData.data.satelliteIds.map((id): ISeriesData => {
    return {
      name: id,
      data: [],
      xAxisIndex: 0,
      animation: false,
      hoverAnimation: true,
      smooth: false,
      ...symbolStyle,
    };
  });
  const { parameterTypes } = initData.data;
  const axisData = getAxisData(initData);
  const timeData: IAxis = { name: 'Time', type: 'time', index: -1 };
  const axisX = [
    timeData,
    ...axisData.filter(
      (d): boolean =>
        d.type === 'float' || d.type === 'double' || d.type === 'boolean'
    ),
  ];
  const axisY = [...axisData];
  const selectedY = { type: parameterTypes[0], index: 0 };
  const selectedX = { type: 'time', index: -1 };
  const selected: { [key: string]: boolean } = {};
  initData.data.satelliteIds.forEach((i): void => {
    selected[i] = true;
  });
  setState(
    {
      satellitesData,
      axisTypes: parameterTypes,
      axisX,
      axisY,
      selectedY,
      selectedX,
    },
    async (): Promise<void> => {
      await chart.setOption({ custom: { initData: initData } });
      await chart.setOption({
        tooltip: {
          trigger: 'item',
          formatter: function (params: Format): string {
            const value = params.value as string[];
            let resStr = '';
            if (value.length === 3) {
              resStr += `${value[2]}<hr size=1 style="margin: 3px 0">`;
            }
            resStr += `X: ${value[0]}<br/>Y: ${value[1]}`;
            return resStr;
          },
        },
        legend: {
          type: 'scroll',
          data: [...initData.data.satelliteIds],
          selected: selected,
        },
        color: color,
        xAxis: [
          {
            id: 'time',
            type: 'time',
            min: initData.data.startDate,
            max: initData.data.endDate,
          },
          {
            id: 'value',
            type: 'value',
            show: false,
          },
        ],
        yAxis: {
          max: 'dataMax',
          min: 'dataMin',
          axisLabel: {
            // formatter: (value: number, index: number) => {
            //   if ((value ^ 0) !== value) {
            //     if (value >= 0) {
            //       return Math.ceil(value);
            //     } else {
            //       return Math.floor(value);
            //     }
            //   } else {
            //     return value;
            //   }
            // },
            showMaxLabel: false,
            showMinLabel: false,
          },
        },
        dataZoom: [
          {
            xAxisIndex: 0,
            type: 'slider',
            ...dataZoom,
          },
          {
            type: 'inside',
            ...dataZoom,
          },
        ],
        grid: {
          bottom: 100,
          left: 100,
        },
      });
    }
  );
};
