import type { IOSMFetchResult } from '../../declarations/osm';

export const convertGeoJSONtoPaths = (
  result: IOSMFetchResult
): { lat: number; lng: number }[][] => {
  const paths =
    result.geojson.type === 'MultiPolygon'
      ? result.geojson.coordinates.map((pathWrap: number[][][]) => {
          return pathWrap[0].map((point: number[]) => {
            return {
              lat: point[1] as number,
              lng: point[0] as number,
            };
          });
        })
      : result.geojson.coordinates.map((path: number[][]) =>
          path.map((point: number[]) => {
            return {
              lat: point[1] as number,
              lng: point[0] as number,
            };
          })
        );
  return paths;
};
