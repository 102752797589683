import React, { useRef } from "react";
import type { AriaSelectProps } from "@react-types/select";
import { useSelectState } from "react-stately";
import {
  HiddenSelect,
  mergeProps,
  OverlayContainer,
  OverlayProvider,
  useButton,
  useFocusRing,
  useOverlayPosition,
  useOverlayTrigger,
  useSelect,
} from "react-aria";
import classNames from "classnames";
import Icon from "../../icons/Icon";
import Overlay from "../Overlay/Overlay";
import ListBox from "../List/ListBox";

interface ISelect2Props<T> extends AriaSelectProps<T> {
  /**
   * Whether or not the dropdown is filterable by provided item key
   */
  filterable?: true;
  /**
   * Whether or not the dropdown fills the entire container
   */
  fill?: true;

  /**
   * Handler function that filters the items in the dropdown.
   * @param query - text typed in the filter input
   * @param item - item to check against the query
   * @example filterHandler={(query, item) => item.key.includes(query)}
   * @returns boolean - whether or not the item should be displayed
   */
  filterHandler?: (query: string, item: T) => boolean;
  className?: string;
  selectedItemClassName?: string;
  /**
   * The item to display in the select.
   */
  selectedItem?: React.ReactNode;
}

/**
 * Select2 is a component that allows the user to select an item from a dropdown.
 * It is used to select a single item from a list of items.
 */
const Select2 = <T extends object>(props: ISelect2Props<T>) => {
  const state = useSelectState(props);
  const ref = useRef<HTMLButtonElement>(null);
  const overlayRef = useRef(null);

  const { labelProps, triggerProps, valueProps, menuProps } = useSelect(
    {
      ...props,
      "aria-label": props.name ?? "select",
    },
    state,
    ref
  );

  const { buttonProps } = useButton(triggerProps, ref);
  const { focusProps, isFocusVisible } = useFocusRing();
  const { overlayProps, triggerProps: overlayTrigger } = useOverlayTrigger(
    { ...props, type: "listbox" },
    state,
    ref
  );

  const { overlayProps: positionProps } = useOverlayPosition({
    targetRef: ref,
    overlayRef,
  });

  let selectedItem = props.selectedItem;

  if (!selectedItem) {
    if (state.selectedItem as typeof state.selectedItem | undefined) {
      selectedItem = state.selectedItem.rendered;
    } else {
      selectedItem = "Select";
    }
  }

  return (
    <div
      className={classNames(
        props.className,
        "flex flex-col relative whitespace-pre overflow-hidden",
        {
          "w-full": props.fill,
          "w-fit min-w-[110px]": !props.fill,
        }
      )}
    >
      <div
        {...labelProps}
        className="block text-sm font-medium text-item-contrast dark:bg-surface-dark dark:text-item-dark-contrast text-left cursor-default"
      >
        {props.label}
      </div>
      <HiddenSelect
        aria-label="select"
        state={state}
        triggerRef={ref}
        label={props.label}
        name={props.name}
      />
      <button
        {...mergeProps(buttonProps, focusProps, overlayTrigger)}
        ref={ref}
        className={classNames(
          props.selectedItemClassName,
          "text-start text-sm min-h-[30px] h-fit max-h-2 dark:bg-item-dark dark:text-item-dark-contrast bg-item",
          {
            "border-2 border-accent-900 dark:border-item-dark-hover":
              isFocusVisible,
            "text-contrast-inactive dark:text-item-dark-contrast-inactive":
              props.isDisabled,
          }
        )}
      >
        <span
          {...valueProps}
          className="flex items-center gap-1 bg-item dark:bg-item-dark h-8 pr-2"
        >
          <Icon icon="ChevronDown" />
          {selectedItem}
        </span>
      </button>
      {state.isOpen && (
        <OverlayProvider>
          <OverlayContainer>
            <Overlay
              isOpen={state.isOpen}
              onClose={state.close}
              overlayRef={overlayRef}
              className="w-fit"
              {...overlayProps}
              {...positionProps}
              style={{
                ...positionProps.style,
                zIndex: 99999999,
              }}
            >
              <div
                style={{ minWidth: ref.current?.clientWidth }}
                className="text-item-contrast dark:text-item-dark-contrast"
              >
                <ListBox<T>
                  {...menuProps}
                  state={state}
                  filterable={props.filterable}
                  filterHandler={props.filterHandler}
                />
              </div>
            </Overlay>
          </OverlayContainer>
        </OverlayProvider>
      )}
    </div>
  );
};

export default Select2;
