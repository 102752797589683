import { useEffect, useState } from 'react';
import Icon from '_atoms/Icon/Icon';
import Popover2 from '_atoms/Popover/Popover2';
import RangeSlider from '_molecules/RangeSlider/RangeSlider';
import type { Layer } from 'datacosmos/entities/layer';
import type { SingleBandSTACLayer } from 'datacosmos/entities/singleBandLayer';
import { Tooltip } from 'ui/Tooltip';
import { useLocalisation } from 'utils/hooks/useLocalisation';

type Props = {
  disabled: boolean;
  saturationSupportedLayers: SingleBandSTACLayer[];
  replaceLayer: (layer: Layer, newIndex?: number) => void;
};

const SaturationControl = (props: Props) => {
  const { translate } = useLocalisation();
  const [saturation, setSaturation] = useState<number>(1);

  useEffect(() => {
    setSaturation(props.saturationSupportedLayers[0]?.options.saturation);
  }, [props.saturationSupportedLayers]);
  return (
    <Popover2
      className="w-64 p-1"
      placement="bottom end"
      offset={0}
      disabled={props.disabled}
      isDismissable={true}
      popupContent={
        <div data-testid="brightness-popup">
          <RangeSlider
            label={{
              text: 'Saturation',
              icon: <Icon icon="Saturation" />,
            }}
            showScale={false}
            showValuesAboveHandles
            numberOfHandles={1}
            minValue={0.1}
            step={0.1}
            maxValue={2}
            value={[saturation]}
            onChange={(val) => {
              setSaturation(val[0]);
              props.saturationSupportedLayers[0].setSettingSaturationOn();
              props.replaceLayer(
                props.saturationSupportedLayers[0].cloneWithOptions({
                  saturation: val[0],
                })
              );
            }}
          />
        </div>
      }
    >
      <Tooltip
        content={
          props.disabled
            ? `${translate('datacosmos.layers.saturation')}-${translate(
                'datacosmos.layers.layerOperationDisabled'
              )}`
            : translate('datacosmos.layers.saturation')
        }
      >
        <Icon
          icon="Saturation"
          size={24}
          title="layer saturation"
          className={
            props.disabled ? 'cursor-not-allowed opacity-50' : 'cursor-pointer'
          }
        />
      </Tooltip>
    </Popover2>
  );
};

export default SaturationControl;
