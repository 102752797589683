import type { ReactElement } from 'react';
import React from 'react';
import type { ISTState } from '../../constants/satelliteOrbits/actionTypes';
import { color } from '../../constants/chart/constants';

export interface ISelectBarProps {
  satellites: ISTState;
  onChangeSatelliteShow: Function;
}

export default class SelectBar extends React.PureComponent<
  ISelectBarProps,
  {}
> {
  public componentDidMount(): void {}

  private onChangeSatelliteCheckbox = (arg: {
    target: { checked: boolean; id: string };
  }): void => {
    const { checked, id } = arg.target;
    this.props.onChangeSatelliteShow({ checked, id });
  };

  public render(): ReactElement {
    const { satellites } = this.props;
    const checkboxes = satellites.list.map((s, index): ReactElement => {
      const styleLabel = { color: color[index] };
      const styleSpan = { backgroundColor: color[index] };
      return (
        <div key={s.id} className="satellite-checkbox">
          <label style={styleLabel} className="bp4-control bp4-checkbox">
            <input
              type="checkbox"
              id={s.id}
              checked={s.isShow}
              onChange={this.onChangeSatelliteCheckbox}
            />
            <span style={styleSpan} className="bp4-control-indicator" />
            {s.name}
          </label>
        </div>
      );
    });
    return <>{checkboxes}</>;
  }
}
