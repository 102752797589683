import type { PropsWithChildren, ReactElement, ReactNode } from 'react';
import React, { useEffect, useState } from 'react';
import { Link, useHistory, useLocation, useParams } from 'react-router-dom';
import { find, has, isUndefined, get } from 'lodash/fp';
import { Button, Icon, Menu, MenuItem, Popover } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { HeaderButton } from './HeaderButton';
import { useAuth } from 'services/auth/AuthWrapper';
import { useMission } from 'services/Missions';
import { getHost } from 'utils/common/CommonUtils';
import Clock from 'components/Clock';
import ActiveSessions from 'components/ActiveSessions';
import { useWindowSize } from 'utils/hooks/useWindowSize';
import OrganisationConfiguration from 'components/OrganisationConfiguration';
import bigLogo from 'images/logo-centric-name-compact.svg';
import smallLogo from 'images/logo-monogram-black.svg';
import useLocalStorage from 'utils/hooks/useLocalStorage';
import type { Organisation } from 'api/administration/service';
import MailToButton from '_organisms/MailToButton/MailToButton';
import { FEEDBACK_MAIL } from 'constants/contact';
import {
  AUTH0_LOGOUT_URI,
  ENABLE_SEND_FEEDBACK,
  ENABLE_USER_IMAGES,
  LOGO,
  TASKING_ENABLE,
} from 'env';
import { useAnalytics } from 'utils/hooks/analytics/useAnalytics';
import { useLocalisation } from 'utils/hooks/useLocalisation';

const DEFAULT_MISSION_NAME = 'Mission name';

interface IProps {
  showMissionSelector?: boolean;
  disableMissionSelector?: boolean;
  showReport?: boolean;
  header?: ReactNode;
  leftHeader?: ReactNode;
  rightHeader?: ReactNode;
  light?: boolean;
  shouldHideClock?: boolean;
  onClickLogo?: (event: React.MouseEvent<HTMLDivElement>) => void;
  onMissionClick?: () => void;
}

export const MissionPageHeader = (
  props: PropsWithChildren<IProps>
): ReactElement => {
  const [isOrganizationOpen, setOrganizationOpen] = useState(false);
  const [currentOrganisation, setCurrentOrganisation] =
    useState<Organisation>();
  const {
    isAuthenticated,
    loginWithRedirect,
    logout,
    user,
    checkPermissions,
    isAllowedToReadRoles,
    isAllowedToReadUsersRoles,
    isAllowedToOpenMissionSelector,
  } = useAuth();
  const { currentMissionId } = useMission();
  const location = useLocation();
  const history = useHistory();

  const { sendInfo } = useAnalytics();

  const [isAllowedToUpdateOrganisation, setIsAllowedToUpdateOrganisation] =
    useState<boolean>(false);

  const [isAllowedToViewTaskingOverview, setIsAllowedToViewTaskingOverview] =
    useState<boolean>(false);

  useEffect(() => {
    const checkIfAllowedToUpdateOrg = async () => {
      const canUpdate = await checkPermissions({
        type: 'organisation',
        actionScope: 'organisation:update',
        id: String(get('id', currentOrganisation)),
      });

      setIsAllowedToUpdateOrganisation(canUpdate);
    };

    const checkIfAllowedToViewTaskingOverview = async () => {
      const canUpdate = await checkPermissions({
        type: 'global',
        actionScope: 'data:tasking:request:read:own',
      });

      setIsAllowedToViewTaskingOverview(canUpdate);
    };

    if (currentOrganisation) {
      void checkIfAllowedToUpdateOrg();
      void checkIfAllowedToViewTaskingOverview();
    }
  }, [checkPermissions, currentOrganisation, currentMissionId]);

  const isOps = location.pathname.split('/').includes('ops');
  const isMsd = location.pathname.split('/').includes('msd');

  const getFallbackEmail = () => {
    if (isOps) return 'openapp-help@open-cosmos.com';
    if (isMsd) return 'openlab-help@open-cosmos.com';
    return FEEDBACK_MAIL;
  };

  const getTeam = () => {
    if (isOps) return 'operations';
    if (isMsd) return 'openlab';
    return 'datacosmos';
  };

  const {
    currentMission,
    organisations,
    missions,
    programmes,
    organisationApi,
    setCurrentMission,
  } = useMission();

  const params = useParams<{ mission: string }>();

  const [localStorage] = useLocalStorage<{
    currentMissionId: number | undefined;
  }>('missions', {
    currentMissionId: undefined,
  });

  useEffect(() => {
    if (
      props.showMissionSelector &&
      isAllowedToOpenMissionSelector &&
      !currentMission &&
      !has('mission', params) &&
      !find(['mission', localStorage.currentMissionId], missions)
    ) {
      setOrganizationOpen(true);
    }
  }, [
    props.showMissionSelector,
    currentMission,
    isAllowedToOpenMissionSelector,
    params,
    localStorage.currentMissionId,
    missions,
  ]);

  useEffect(() => {
    if (currentMission) {
      const org = find(['id', currentMission.organisation], organisations);
      org && setCurrentOrganisation(org);
    }
  }, [currentMission, organisations]);

  useEffect(() => {
    const storedMissionId = localStorage.currentMissionId;

    if (!isUndefined(storedMissionId) && isUndefined(currentMission)) {
      const isOPS = location.pathname.split('/').includes('ops');
      const isExactlyOps = location.pathname === '/ops';
      const isGrafana = window.location.href.includes('/ops/telemetry');
      const shouldRedirect = isOPS && !isGrafana;
      const mission = find(['mission', storedMissionId], missions);
      mission && isExactlyOps && setCurrentMission(mission, shouldRedirect);
    }
  }, [
    currentMission,
    localStorage.currentMissionId,
    location.pathname,
    missions,
    setCurrentMission,
  ]);

  const { translate } = useLocalisation();

  // TODO: item interface
  // const handleSetItemId = (): void => {};

  const handleOrganisationLinkClick = () => {
    if (currentMission?.organisation === undefined) return;
    history.push(`/portal/organisation/${currentMission.organisation}`);
  };

  const handleRolesLinkClick = () => {
    history.push('/portal/roles');
  };

  const handleUsersLinkClick = () => {
    history.push('/portal/users');
  };

  const handleOrdersLinkClick = () => {
    history.push('/data/orders');
  };

  const handleTaskingOverviewLinkClick = () => {
    history.push('/data/tasking/overview');
  };

  let userButton = (
    <HeaderButton text={<Icon icon={IconNames.PERSON} iconSize={30} />} />
  );
  let content = (
    <Menu>
      <MenuItem text="Anonym" disabled />
      <MenuItem text="Login" onClick={() => void loginWithRedirect()} />
    </Menu>
  );
  if (isAuthenticated) {
    if (user) {
      userButton = ENABLE_USER_IMAGES ? (
        <img className="header-button" src={user.picture} alt="avatar" />
      ) : (
        <HeaderButton text={<Icon icon={IconNames.PERSON} iconSize={30} />} />
      );
      content = (
        <Menu>
          <MenuItem disabled icon={IconNames.PERSON} text={user.name} />
          {props.showMissionSelector &&
            currentOrganisation &&
            isAllowedToUpdateOrganisation && (
              <MenuItem
                text={currentOrganisation.name}
                icon={IconNames.OFFICE}
                onClick={handleOrganisationLinkClick}
              />
            )}
          {isAllowedToReadRoles && (
            <MenuItem
              icon={IconNames.CROWN}
              text={translate('datacosmos.header.menu.rolesManager')}
              onClick={handleRolesLinkClick}
            />
          )}
          {isAllowedToReadUsersRoles && (
            <MenuItem
              icon={IconNames.PEOPLE}
              text={translate('datacosmos.header.menu.users')}
              onClick={handleUsersLinkClick}
            />
          )}
          <MenuItem
            icon={IconNames.ShoppingCart}
            text={translate('datacosmos.header.menu.orders')}
            onClick={handleOrdersLinkClick}
          />

          {TASKING_ENABLE && isAllowedToViewTaskingOverview && (
            <MenuItem
              icon={IconNames.Calendar}
              text="Tasking overview"
              onClick={handleTaskingOverviewLinkClick}
            />
          )}

          <MenuItem
            text={translate('datacosmos.header.menu.logout')}
            onClick={() => {
              sendInfo({
                type: 'Logout',
                action: 'Click',
                item: 'Logout button',
              });
              logout(getHost() + AUTH0_LOGOUT_URI);
            }}
          />
        </Menu>
      );
    }
  }

  const windowSize = useWindowSize();
  const logo = windowSize.isTablet ? smallLogo : bigLogo;

  const getUrlTo = () => {
    const pathname = window.location.pathname;

    if (currentMissionId && pathname.startsWith('/ops')) {
      return `/ops/mission/${currentMissionId}/`;
    }

    if (!currentMissionId && pathname.startsWith('/ops')) {
      return `/ops`;
    }

    if (pathname.startsWith('/msd')) {
      return `/msd`;
    }

    return '/';
  };

  return (
    <div
      className={[
        `${
          LOGO
            ? 'mission-page-header bg-header-top text-header-top-contrast'
            : 'mission-page-header'
        }`,
        props.light && !LOGO?.length ? 'mission-page-header--light' : '',
      ].join(' ')}
    >
      <div className="left">
        <div
          className={
            LOGO?.length ? 'header-logo header-logo-condata' : 'header-logo'
          }
          role="presentation"
          onClick={(e) => {
            if (!LOGO?.length) {
              sendInfo({
                type: 'Logo click',
                action: 'Click',
                item: 'Open Cosmos logo',
                module: 'OPS',
              });
            }
            props.onClickLogo?.(e);
          }}
        >
          <Link to={getUrlTo()}>
            <img
              height={LOGO?.length ? 'auto' : '100%'}
              id="logo-image"
              className="summary-logo-img"
              src={LOGO?.length ? LOGO : logo}
              alt="logo-centric-name-compact.svg"
            />
          </Link>
        </div>
        {props.showMissionSelector ? (
          <Button
            minimal
            disabled={
              props.disableMissionSelector ?? !isAllowedToOpenMissionSelector
            }
            className="pl-3 pr-2 underline underline-offset-4"
            onClick={() => setOrganizationOpen(true)}
            text={currentMission ? currentMission.name : DEFAULT_MISSION_NAME}
          />
        ) : null}
        {props.leftHeader}
      </div>
      <div className="middle">
        {props.header}
        {props.showReport ? <HeaderButton text="Report" /> : null}
        {!props.shouldHideClock && <Clock dark={!props.light} />}
      </div>
      <div className="right">
        {props.rightHeader}
        {isOps && <ActiveSessions mission={currentMissionId} />}
        {ENABLE_SEND_FEEDBACK && (
          <MailToButton
            subject="Send feedback"
            fallbackEmail={getFallbackEmail()}
            team={getTeam()}
          />
        )}
        <Popover content={content}>{userButton}</Popover>
        {/*TODO hide sheet and question mark icon*/}
        {/*<HeaderButton text={<Icon icon={'help'} iconSize={22} />} />*/}
        {/*<HeaderButton text={<Icon icon={'document'} iconSize={22} />} />*/}
      </div>
      {props.showMissionSelector && (
        <OrganisationConfiguration
          user={user}
          isOpen={isOrganizationOpen}
          organisationsList={organisations}
          programmesList={programmes}
          missionsList={missions}
          organisationApi={organisationApi}
          onClose={() => setOrganizationOpen(false)}
          onMissionClick={props.onMissionClick}
        />
      )}
    </div>
  );
};
