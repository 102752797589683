import React from 'react';
import baseLayers from 'datacosmos/utils/map-layers';
import { useMap } from 'datacosmos/stores/MapProvider';
import Icon from '_atoms/Icon/Icon';
import Popover2 from '_atoms/Popover/Popover2';

function MapLayers() {
  const { baseLayer, setBaseLayer } = useMap();

  const onLayerSelect = (layer: any, e: React.MouseEvent) => {
    e.preventDefault();
    setBaseLayer(layer);
  };

  return (
    <span className="bttn-secondary map-menu-layers">
      <Popover2
        isDismissable={true}
        className="dark:bg-surface-dark dark:hover:bg-item-dark bg-item w-fit p-2"
        placement="right"
        popupContent={
          <div className="openapp-data basemap-selector">
            <div>
              <ul className="drop__menu drop__menu--select map-layers-list">
                {baseLayers.map((o) => (
                  <li key={o.id}>
                    <MapLayerItem
                      selectedLayer={baseLayer}
                      layer={o}
                      onLayerSelect={onLayerSelect}
                    />
                  </li>
                ))}
              </ul>
            </div>
          </div>
        }
      >
        <Icon icon="Map" size={24} />
      </Popover2>
    </span>
  );
}

interface IPropsMapLayerItem {
  selectedLayer: any;
  layer: any;
  onLayerSelect: (layer: any, e: React.MouseEvent) => void;
}

// Each Map layer it's its own component because we need to be able to add the
// actual map on component mount.
class MapLayerItem extends React.Component<IPropsMapLayerItem> {
  static displayName = 'MapLayerItem';

  map: L.Map | null = null;

  componentDidMount() {
    this.map = window.L.map(this.refs.map as HTMLDivElement, {
      zoomControl: false,
    });
    this.map
      ?.addLayer(window.L.tileLayer(this.props.layer.url))
      .setView([9, 0], 0);

    // Disable drag and zoom handlers.
    this.map?.dragging.disable();
    this.map?.touchZoom.disable();
    this.map?.doubleClickZoom.disable();
    this.map?.scrollWheelZoom.disable();
    this.map?.keyboard.disable();

    // Disable tap handler, if present.
    if (this.map?.tap) this.map.tap.disable();
  }

  componentWillUnmount() {
    this.map?.remove();
  }

  render() {
    let c = 'drop__menu-item';
    c +=
      this.props.selectedLayer.id === this.props.layer.id
        ? ' drop__menu-item--active'
        : '';
    return (
      <a
        className={c}
        onClick={(e) => this.props.onLayerSelect(this.props.layer, e)}
        data-hook="dropdown:close"
      >
        <div className="map-layers-list__map" ref="map" />
        <span className="map-layers-list__text">{this.props.layer.name}</span>
      </a>
    );
  }
}

export default MapLayers;
