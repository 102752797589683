import type { RefObject } from 'react';
import React from 'react';
import type { IGroundStation } from '../../constants/groundStations/actionTypes';
import { Button, Checkbox } from '@blueprintjs/core';
import MarkerForm from '../popUp/MarkerForm';
import Toggle from '../3WayToggle/Toggle';
import { GROUND_STATION_FORM_NAME } from '../../constants/popUp/constants';
import type { IFocus } from '../../constants/focus/actionTypes';
import { FocusTypes } from '../../constants/focus/actionTypes';

interface IGroundStationProps {
  gs: IGroundStation;
  setPopUp?: Function;
  updateGS?: Function;
  removeGS?: Function;
  showGroundStation?: Function;
  setFocus?: Function;
  focus?: IFocus;
  disableCRUD?: boolean;
}

interface IGroundStationState {
  checked: boolean;
  hasFocus: boolean;
}

class GroundStationObject extends React.Component<
  IGroundStationProps,
  IGroundStationState
> {
  public constructor(props: Readonly<IGroundStationProps>) {
    super(props);
    this.state = {
      checked: props.gs.select || props.gs.optimize,
      hasFocus: false,
    };
  }

  private ref = React.createRef() as RefObject<HTMLDivElement>;

  public componentDidUpdate(prevProps: Readonly<IGroundStationProps>): void {
    const isSelectedChanged = prevProps.gs.select !== this.props.gs.select;
    const isOptimizeChanged = prevProps.gs.optimize !== this.props.gs.optimize;
    if (isSelectedChanged || isOptimizeChanged) {
      this.setState({
        checked: this.props.gs.select || this.props.gs.optimize,
      });
    }
    if (this.props.focus !== prevProps.focus) {
      const { focus } = this.props;
      if (
        focus.type === FocusTypes.GS_SUB_ID &&
        focus.id === this.props.gs.id
      ) {
        if (focus.needScroll) {
          this.handleChangeCheckbox();
          this.ref.current.scrollIntoView({
            block: 'start',
            behavior: 'smooth',
          });
        }
        this.setState({ hasFocus: true });
      } else {
        this.setState({ hasFocus: false });
      }
    }
  }

  private handleUpdateGS = () => {
    const { setPopUp, updateGS, gs } = this.props;
    this.handleSetFocus(true);
    setPopUp({
      component: MarkerForm,
      visible: true,
      data: gs,
      classNames: ['short-container'],
      otherProps: {
        type: FocusTypes.GS_SUB_ID,
        form: GROUND_STATION_FORM_NAME,
      },
      functions: {
        onSubmit: (groundStationLocal: IGroundStation): void =>
          updateGS({ ...gs, ...groundStationLocal }),
        onClose: () => {},
      },
    });
  };
  private handleShowGS = () => {
    const { gs, showGroundStation } = this.props;
    showGroundStation(gs);
  };

  private handleRemoveGS = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    event.stopPropagation();
    const { gs, removeGS } = this.props;
    removeGS(gs.id);
  };
  private handleToggleOnChange = (value: number) => {
    const { gs, updateGS } = this.props;
    let optimize = gs.optimize;
    let select = gs.select;
    switch (value) {
      case -1: {
        optimize = false;
        select = true;
        break;
      }
      //TODO uncomment after Beta release
      // case 1: {
      //   optimize = true;
      //   select = false;
      //   break;
      // }
    }
    updateGS({ ...gs, select, optimize });
  };

  private handleSetFocus = (withPopUp = false) => {
    const { gs } = this.props;
    const focus: IFocus = {
      needScroll: false,
      id: gs.id,
      type: FocusTypes.GS_SUB_ID,
      withPopUp,
    };
    this.props.setFocus(focus);
  };
  private handleChangeCheckbox = () => {
    const { checked } = this.state;
    this.setState({ checked: !checked }, () => {
      let { gs } = this.props;
      if (this.state.checked) {
        gs.select = true;
        gs.optimize = false;
      } else {
        gs.select = false;
        gs.optimize = false;
      }
      this.props.updateGS(gs);
    });
  };

  public render(): React.ReactNode {
    const { checked, hasFocus } = this.state;
    const { gs } = this.props;
    const toggleValue = gs.select ? -1 : gs.optimize ? 1 : 0;
    let colorClassName = 'gray';
    switch (toggleValue) {
      case -1: {
        colorClassName = 'green';
        break;
      }
      case 1: {
        colorClassName = 'blue';
        break;
      }
    }

    if (this.props.disableCRUD) colorClassName = 'gray';

    const focusClassName = hasFocus ? 'focused' : '';
    return (
      <div
        key={gs.id}
        className={`geometry-object ${colorClassName} ${focusClassName}`}
        role="presentation"
        ref={this.ref}
        onClick={() => this.handleSetFocus(false)}
      >
        <div
          title={`type:\t${gs.custom ? 'Custom' : 'Default'}\nname:\t${
            gs.name
          }`}
          className="geometry-object_name"
        >
          <div>{gs.name}</div>
          <div>{gs.custom ? 'Custom' : 'Default'}</div>
        </div>
        {!this.props.disableCRUD && (
          <div className="geometry-object_center">
            <Checkbox
              className="three-way-toggle"
              onChange={this.handleChangeCheckbox}
              onClick={() => this.handleSetFocus(false)}
              checked={checked}
            />
            <Toggle
              disabled={!checked}
              values={[-1, 0, 1]}
              labelRenderer={false}
              value={toggleValue}
              onChange={this.handleToggleOnChange}
            />
          </div>
        )}
        <div className="geometry-object_buttons">
          {gs.custom ? (
            <Button icon={'settings'} onClick={this.handleUpdateGS} />
          ) : (
            <Button icon={'geosearch'} onClick={this.handleShowGS} />
          )}
          {gs.custom && (
            <Button
              disabled={!gs.custom}
              intent={'danger'}
              icon={'delete'}
              onClick={this.handleRemoveGS}
            />
          )}
        </div>
      </div>
    );
  }
}

export default GroundStationObject;
