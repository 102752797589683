import type { ThunkAction } from 'redux-thunk';
import type { AppState } from 'reducers/rootReducer';
import type { Action } from 'redux';
import type { IConstraints } from 'constants/constraintsTable/actionTypes';
import type { IGSState } from 'constants/groundStations/actionTypes';
import type { IOptimisationObjective } from 'constants/objectivesTable/constants';
import type { IPIState } from 'constants/pointsOfInterest/actionTypes';
import type {
  IPoint,
  IRegionOfInterest,
  IRIState,
} from 'constants/regionsOfInterest/actionTypes';
import { uploadGS } from './groundStations/thunks';
import { uploadPI } from './pointsOfInterest/thunks';
import { uploadRI } from './regionsOfInterest/thunks';
import { uploadConstr } from './constraints/thunks';
import { updateObj } from './objectives/thunks';
import { uploadLO } from './launchOpportunities/thunks';
import type { ILOState } from 'constants/constraintsTable/actionTypesLaunchOpportunity';
import type { IGoalReduceState } from 'constants/goals/interfaceas';
import type { IGeometryObjectState } from 'constants/msd/geometry/interfaces';
import { uploadGeometersThunk } from './geometry/thunks';
import { uploadGoalsThunk } from './goal/thunks';
import type { IMission } from 'services/Missions';
import { updateMissionThunk } from './missions/thunks';
import { uploadSatelliteConfiguration } from './satellite/action';
import { uploadPlatformConfiguration } from './ui/platformConfigurator/action';
import { dateAsUTCDateObject } from 'utils/common/dateUtils';

export interface IData {
  constraints?: { constraints: IConstraints };
  objectives?: IOptimisationObjective[];
  groundStations?: IGSState;
  pointsOfInterest?: IPIState;
  regionsOfInterest?: IRIState;
  launchOpportunities?: ILOState;
  goals?: IGoalReduceState;
  geometry?: IGeometryObjectState;
  mission?: IMission;
  satellite?: any;
  platformConfigurator?: any;
}

export const uploadData =
  (
    data: IData,
    accessNames: string[]
  ): ThunkAction<void, AppState, null, Action<string>> =>
  (dispatch): void => {
    const {
      objectives,
      launchOpportunities,
      constraints,
      regionsOfInterest,
      pointsOfInterest,
      groundStations,
      geometry,
      goals,
      mission,
      satellite,
      platformConfigurator,
    } = data;
    if (mission) {
      if (!accessNames.find((name) => name === 'mission')) {
        throw new Error(
          'Scenario contains mission which is not allowed at this stage.'
        );
      }
      dispatch(
        updateMissionThunk({
          ...mission,
          date: dateAsUTCDateObject(mission.date),
        })
      );
    }
    if (objectives) {
      if (!accessNames.find((name) => name === 'objectives')) {
        throw new Error(
          'Scenario contains objectives which are not allowed at this stage.'
        );
      }
      dispatch(updateObj(objectives));
    }
    if (launchOpportunities) {
      if (!accessNames.find((name) => name === 'launchOpportunities')) {
        throw new Error(
          'Scenario contains launch opportunities which are not allowed at this stage.'
        );
      }
      dispatch(uploadLO(launchOpportunities));
    }
    if (constraints) {
      if (!accessNames.find((name) => name === 'constraints')) {
        throw new Error(
          'Scenario contains constraints which are not allowed at this stage.'
        );
      }
      if (constraints.constraints) {
        dispatch(uploadConstr(constraints.constraints));
      }
    }
    if (regionsOfInterest) {
      if (!accessNames.find((name) => name === 'regionsOfInterest')) {
        throw new Error(
          'Scenario contains regions of interest which are not allowed at this stage.'
        );
      }
      const normalizedRegions = {
        list: regionsOfInterest.list.map((region): IRegionOfInterest => {
          return {
            ...region,
            paths: region.paths.map((path): IPoint[] =>
              path.map((point): IPoint => {
                return { lat: point.lat, lng: point.lon };
              })
            ),
          };
        }),
      };
      dispatch(uploadRI(normalizedRegions));
    }
    if (pointsOfInterest) {
      if (!accessNames.find((name) => name === 'pointsOfInterest')) {
        throw new Error(
          'Scenario contains points of interest which are not allowed at this stage.'
        );
      }
      dispatch(uploadPI(data.pointsOfInterest));
    }
    if (groundStations) {
      if (!accessNames.find((name) => name === 'groundStations')) {
        throw new Error(
          'Scenario contains ground stations which are not allowed at this stage.'
        );
      }
      dispatch(uploadGS(data.groundStations));
    }
    if (geometry) {
      if (!accessNames.find((name) => name === 'geometry')) {
        throw new Error(
          'Scenario contains events which are not allowed at this stage.'
        );
      }
      dispatch(uploadGeometersThunk(geometry));
    }
    if (goals) {
      if (!accessNames.find((name) => name === 'goals')) {
        throw new Error(
          'Scenario contains goals which are not allowed at this stage.'
        );
      }
      dispatch(uploadGoalsThunk(goals));
    }
    if (platformConfigurator) {
      if (!accessNames.find((name) => name === 'platformConfigurator')) {
        throw new Error(
          'Scenario contains platform which is not allowed at this stage.'
        );
      }
      dispatch(uploadPlatformConfiguration(platformConfigurator));
    }
    if (satellite) {
      if (!accessNames.find((name) => name === 'satellite')) {
        throw new Error(
          'Scenario contains satellite which is not allowed at this stage.'
        );
      }
      dispatch(uploadSatelliteConfiguration(satellite));
    }
  };
