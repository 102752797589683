import type {
  IPointOfInterest,
  pointsOfInterestTypes,
  IPIState,
} from '../../constants/pointsOfInterest/actionTypes';
import {
  PI_ADD,
  PI_UPDATE,
  PI_REMOVE,
  PI_UPLOAD,
} from '../../constants/pointsOfInterest/actionTypes';
import { generateId } from '../../utils/common/generateId';
import { FocusTypes } from '../../constants/focus/actionTypes';

export function addPointOfInterest(
  pointOfInterest: IPointOfInterest
): pointsOfInterestTypes {
  const id = pointOfInterest.id
    ? pointOfInterest.id
    : generateId(FocusTypes.PI_SUB_ID);
  return {
    type: PI_ADD,
    pointOfInterest: { id, type: FocusTypes.PI_SUB_ID, ...pointOfInterest },
  };
}

export function updatePointOfInterest(
  pointOfInterest: IPointOfInterest
): pointsOfInterestTypes {
  return {
    type: PI_UPDATE,
    pointOfInterest: { type: FocusTypes.PI_SUB_ID, ...pointOfInterest },
  };
}

export function removePointOfInterest(id: string): pointsOfInterestTypes {
  return {
    type: PI_REMOVE,
    id,
  };
}

export function uploadPointOfInterest(
  pointsOfInterest: IPIState
): pointsOfInterestTypes {
  return {
    type: PI_UPLOAD,
    pointsOfInterest,
  };
}
