import Modal from '_atoms/Modal/Modal';
import Button from '_molecules/Button/Button';
import { createActivities } from 'api/activities/service';
import type { TaskingRequest, TaskingSatellite } from 'api/tasking/types';
import { MapController } from 'datacosmos';
import { ListOpportunity } from 'datacosmos/components/Tasking/ListOpportunity';
import { SearchOpportunity } from 'datacosmos/components/Tasking/SearchOpportunity';
import { SearchSteps, Steps } from 'datacosmos/components/Tasking/helpers';
import { ApplicationCatalogProvider } from 'datacosmos/stores/ApplicationProvider';
import FiltersProvider from 'datacosmos/stores/FiltersProvider';
import { ImageCatalogProvider } from 'datacosmos/stores/ImageCatalogProvider';
import { MapLayersProvider } from 'datacosmos/stores/MapLayersProvider';
import { ProjectProvider } from 'datacosmos/stores/ProjectProvider';
import { TaskingProvider, useTasking } from 'datacosmos/stores/TaskingProvider';
import { useActivitiesMap } from 'pages/ops/Scheduling/ScheduleActivityDetails/ActivitiesMap/useActivitiesMap';
import React, { useEffect, useRef, useState } from 'react';
import { OverlayProvider } from 'react-aria';
import { useTaskingPermissions } from 'datacosmos/components/Tasking/RequestViewing/useTaskingPermissions';

type Props = {
  /**
   * Whether the modal is open or not
   */
  isOpen: boolean;
  /**
   * Callback to set the open state of the modal
   * @param isOpen whether the modal is open or not
   */
  setIsOpen: (isOpen: boolean) => void;
  /**
   * Tasking request to add an activity to
   */
  request: TaskingRequest | undefined;

  /**
   * Function that fires when the activities are successfully created and added to the request
   */
  onSuccess?: () => void;
};

type BaseModalProps = Pick<Props, 'isOpen' | 'setIsOpen'> & {
  children: React.ReactNode;
  onClose: () => void;
};

const BaseModal = ({
  isOpen,
  setIsOpen,
  children,
  onClose,
}: BaseModalProps) => {
  return (
    <OverlayProvider>
      {isOpen && (
        <Modal
          isOpen={isOpen}
          role="dialog"
          onClose={() => {
            onClose();
            setIsOpen(false);
          }}
          className="h-[90vh] w-3/4"
          title="Add activity"
        >
          {children}
        </Modal>
      )}
    </OverlayProvider>
  );
};

/**
 * AddActivityModal is a component that enables the user to add an activity to an existing tasking request
 * It consists of a map and a way to search for opportunities, very much the same as in Datacosmos tasking
 *
 */
const AddActivityModalInner = ({
  isOpen,
  setIsOpen,
  request,
  onSuccess,
}: Props) => {
  const container = useRef<HTMLDivElement>(null);
  const { initMap, mapRef } = useActivitiesMap(container, [], request);
  const taskingPermissionsHook = useTaskingPermissions();
  const { confirmedSwaths } = useTasking();

  const [steps, setSteps] = useState<Steps>(Steps.ManualTasking);

  useEffect(() => {
    if (isOpen) {
      initMap();
    }
  }, [initMap, isOpen, mapRef]);

  return (
    <BaseModal
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      onClose={() => {
        mapRef.current = undefined;
      }}
    >
      <div className="grid !shadow-none grid-cols-[1.07fr_2fr]">
        <div className="overflow-y-auto h-[82vh]">
          {steps !== Steps.ListOfOpportunities &&
            taskingPermissionsHook?.taskingSatellites?.data && (
              <SearchOpportunity
                setSearchSteps={() => {}}
                setSwitchStep={(step) => {
                  setSteps(step);
                }}
                switchStep={steps}
                searchSteps={[SearchSteps.SelectTaskingDateAndInstruments]}
                satellitesForTasking={
                  taskingPermissionsHook.taskingSatellites
                    ?.data as TaskingSatellite[]
                }
                setIsRequestModalOpen={() => {}}
              />
            )}

          {steps === Steps.ListOfOpportunities &&
            taskingPermissionsHook.taskingSatellites?.data && (
              <ListOpportunity
                setIsRequestModalOpen={() => {}}
                setSwitchStep={setSteps}
                requestButton={
                  <Button
                    disabled={confirmedSwaths.length === 0}
                    onPress={async () => {
                      if (!request) {
                        return;
                      }

                      await createActivities({
                        body: confirmedSwaths.map((s) => ({
                          tasking_request_ids: [request?.id],
                          type: 'IMAGE_ACQUISITION',
                          end_date: s.metadata.End,
                          start_date: s.metadata.Start,
                          priority: s.metadata.Priority?.priority_level,
                          mission_id: s.metadata.SatelliteId,
                          parameters: {
                            platform: {
                              roll_angle: parseFloat(s.metadata.RollAngle),
                            },
                            imager: {
                              name: s.metadata.SensorId,
                            },
                          },
                        })),
                      });

                      onSuccess?.();

                      setIsOpen(false);
                    }}
                    text="Add to request"
                    className="flex bg-item w-full h-12 items-center justify-center dark:bg-item-dark dark:text-item-dark-contrast"
                  />
                }
                satellitesForTasking={
                  taskingPermissionsHook.taskingSatellites?.data
                }
              />
            )}
        </div>
        <div
          ref={container}
          id="add-activity-map"
          className={'w-full z-10 h-[82vh]'}
        />
      </div>
    </BaseModal>
  );
};

const AddActivityModal = (props: Props) => {
  return (
    <MapLayersProvider>
      <TaskingProvider>
        <FiltersProvider>
          <ImageCatalogProvider>
            <ProjectProvider>
              <ApplicationCatalogProvider>
                <MapController>
                  <AddActivityModalInner {...props} />
                </MapController>
              </ApplicationCatalogProvider>
            </ProjectProvider>
          </ImageCatalogProvider>
        </FiltersProvider>
      </TaskingProvider>
    </MapLayersProvider>
  );
};

export default AddActivityModal;
