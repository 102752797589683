import { useState } from 'react';
import { NonIdealState } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';

import LibraryFileExplorer from 'pages/ops/RTI/Operate/Library/LibraryFileExplorer';
import type { IFolder } from 'services/api/ops/cloudStorage';
import { useApiCloudStorageContent } from 'services/api/ops/cloudStorage';
import { useMission } from 'services/Missions';
import { useCloudStorage } from 'services/api/ops/cloudStorage/useCloudStorage';
import FileEditor from 'components/FileEditor';
import CollapseLibraryList from 'pages/ops/RTI/Operate/Library/CollapseLibraryList';
import FileTransfer from 'components/FileTransfer';
import { useTransferProgress } from 'services/storage/TransferProgressProvider';
import { FILE_TRANSFER_TYPE_TEXT } from 'constants/ops/rti/oparate/constants';

import s from './index.module.scss';

const LibraryStorage = () => {
  const { currentMissionId, currentMission } = useMission();
  const { isFetching, putContent } = useCloudStorage(
    currentMission?.mission,
    currentMission?.programme
  );

  const [previewFile, setPreviewFile] = useState<IFolder>();
  const [previewFilePath, setPreviewFilePath] = useState<string>();
  const [isRefreshingAllowedCloudStorage, setRefreshingAllowedCloudStorage] =
    useState(false);
  const [isRefreshingCloudStorage, setRefreshingCloudStorage] = useState(false);

  const handleFilePreview = (file: IFolder, contentPath: string) => {
    setPreviewFile(file);
    setPreviewFilePath(contentPath);
  };

  const handleRefreshCloudStorageClick = () => {
    setRefreshingCloudStorage(true);
  };

  const transferProgress = useTransferProgress();

  const cloudApi = useApiCloudStorageContent(
    currentMissionId,
    transferProgress.dispatchTransferUpdate
  );

  return (
    <div className={s.libraryStorageContainer}>
      <div className={s.libraryStorage}>
        <CollapseLibraryList
          stores={[
            {
              tagTitle: 'Cloud Storage',
              tagIcon: 'folder-shared',
              collapsed: true,
              handleRefreshClick:
                isRefreshingAllowedCloudStorage &&
                handleRefreshCloudStorageClick,
              children: (
                <LibraryFileExplorer
                  simplifiedExplorer
                  missionId={currentMissionId}
                  setRefreshing={setRefreshingCloudStorage}
                  isRefreshing={isRefreshingCloudStorage}
                  setRefreshingAllowed={setRefreshingAllowedCloudStorage}
                  previewFileHandler={handleFilePreview}
                  uploadType={FILE_TRANSFER_TYPE_TEXT.PC_TO_CLOUD}
                  canCreateFolder
                  canUploadFile
                  {...cloudApi}
                />
              ),
            },
            {
              tagTitle: 'Transfer Status',
              tagIcon: 'exchange',
              collapsed: true,
              children: (
                <FileTransfer
                  activeTransfers={transferProgress.activeTransfersList}
                  removeTransfer={transferProgress.removeTransfer}
                />
              ),
            },
          ]}
        />
      </div>
      <div className={s.libraryEditor}>
        <FileEditor
          isFetching={isFetching}
          fileContent={previewFile}
          handleUploadClick={(fileList) => {
            if (!fileList || fileList.length === 0) return;
            void putContent(fileList[0], previewFilePath);
          }}
          renderEmptyData={
            <NonIdealState
              className={s.libraryEditorEmptyData}
              icon={IconNames.ANNOTATION}
              title="Choose file from storage."
            />
          }
        />
      </div>
    </div>
  );
};

export default LibraryStorage;
