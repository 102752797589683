import type { IPosition, ISize } from 'constants/missionDesignPage/interfaces';
import type { IProps } from 'pages/MissionDesignPage';
import type { BALL_STATES } from './constants';
import type {
  PermissionScope,
  PermissionType,
} from 'api/administration/permissions';

export const SB_ADD = 'SB_ADD';
export const SB_UPDATE = 'SB_UPDATE';
export const SB_REMOVE = 'SB_REMOVE';

type IStateTypes =
  | typeof BALL_STATES.COMPLETE
  | typeof BALL_STATES.CURRENT
  | typeof BALL_STATES.DISABLE
  | typeof BALL_STATES.WARNING;

export interface ISatelliteBall {
  size: ISize;
  position: IPosition;
  text: string;
  state: IStateTypes;
  dependsOf: number[];
  scopes: PermissionScope[] | PermissionScope;
  permissionType?: PermissionType;
  isDisabled?: (props: IProps) => boolean;
  compareFunction: (props: IProps) => string;
  resetFunction: (props: IProps, ball: ISatelliteBall) => ISatelliteBall;
  handleOnClick: (
    props: IProps,
    ball: ISatelliteBall
  ) => { type: string; value?: number | string | object };
  comingSoon?: boolean;
  redirectPath?: string;
  redirectFn?: (missionId: number) => string;
  icon?: string;
  alt?: string;
}

interface ISBAddAction {
  type: typeof SB_ADD;
  satelliteBall: ISatelliteBall;
}

interface ISBUpdateAction {
  type: typeof SB_UPDATE;
  satelliteBall: ISatelliteBall;
}

interface ISBRemoveAction {
  type: typeof SB_REMOVE;
  text: string;
}

export type SatelliteBallActionTypes =
  | ISBAddAction
  | ISBUpdateAction
  | ISBRemoveAction;
