import { useEffect, useState, useMemo, useCallback } from 'react';
import { useAuth } from 'services/auth/AuthWrapper';
import {
  createUserRoleAssignment,
  deleteRoleAssignments,
  getRoleAssignments,
  getRoleAttachmentsForResource,
} from 'services/api/portal/administration/api/resourceRoleAssignmentsApi';
import roleApi from 'services/api/portal/administration/api/roleApi';
import { mapAsync } from 'utils/common/asyncUtils';
import { showSuccessMessage } from 'utils/common/CommonUtils';
import type { PermissionType } from 'api/administration/permissions';
import type { IRole } from 'services/api/portal/administration/api/types';
import { BACKEND_BASE_URL } from 'env';
import { useQuery } from 'api/useQuery';
import { groupAssignmentsByUser } from 'utils/auth/common';

const PORTAL_URL = `${BACKEND_BASE_URL}/portal`;

const useResource = (
  resourceType: PermissionType | undefined,
  resourceId: number | string
) => {
  const [availableRoles, setAvailableRoles] = useState<IRole[]>([]);

  const { token } = useAuth();

  const roleInstance = useMemo(
    () => roleApi(`${PORTAL_URL}/v0`, token),
    [token]
  );

  const getRoles = useCallback(async () => {
    if (!resourceType) return;
    const data = await roleInstance.getResourceRoles(resourceType);
    setAvailableRoles(data);
  }, [resourceType, roleInstance]);

  const attachedRoleAssignments = useQuery(getRoleAttachmentsForResource, {
    initialData: [],
    params: { resourceType, resourceId },
    skip: !resourceId,
  });

  const roleAssignments = useQuery(getRoleAssignments, {
    initialData: [],
    params: { resourceType, resourceId },
    skip: !resourceId,
  });

  const assignRole = useCallback(
    async (userId: string, roleIds: number[]) => {
      if (!resourceId || !resourceType) {
        return;
      }

      await mapAsync((id: number) =>
        createUserRoleAssignment({
          body: {
            userID: userId,
            roleID: id,
          },
          params: {
            resourceId: resourceId.toString(),
            resourceType: resourceType,
          },
        })
      )(roleIds);
      void roleAssignments.refetch();
    },
    [resourceId, resourceType, roleAssignments]
  );

  const deleteRoleAssignment = useCallback(
    async (assignmentIds: number[], showDeletedMessage?: boolean) => {
      if (!resourceId || !resourceType) {
        return;
      }
      await mapAsync((assignmentId: number) =>
        deleteRoleAssignments({
          params: {
            resourceType: resourceType,
            resourceID: resourceId,
            assignmentID: assignmentId,
          },
        })
      )(assignmentIds);
      showDeletedMessage && showSuccessMessage('Role assignment was deleted');
      void roleAssignments.refetch();
    },
    [roleAssignments, resourceId, resourceType]
  );

  useEffect(() => {
    if (!resourceId) return;
    void getRoles();
  }, [getRoles, resourceId]);

  return {
    availableRoles,
    resourceRoleAssignments: groupAssignmentsByUser(roleAssignments.data),
    assignRole,
    deleteRoleAssignment,
    resourceRoleAttachments: attachedRoleAssignments.data,
    getRoleAttachmentsByResourceTypeAndId: attachedRoleAssignments.refetch,
  };
};

export default useResource;
