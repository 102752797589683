import DrawAreaOfInterest from 'datacosmos/components/DrawAreaOfInterest';
import UploadRegion from 'datacosmos/components/UploadRegion';
import { LayerSourceType } from 'datacosmos/entities/layer';
import { useApplicationCatalog } from 'datacosmos/stores/ApplicationCatalogContext';
import { useMapLayers } from 'datacosmos/stores/MapLayersProvider';
import type { IApplication, IAPIAppValue } from 'datacosmos/types/applications';
import { AppTags } from 'datacosmos/types/applications';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { Item } from 'react-stately';
import DatePicker from '_molecules/DatePicker/DatePicker';
import IconButton from '_molecules/IconButton/IconButton';
import Select2 from '_molecules/Select2/Select2';
import OpenedAppCard from 'datacosmos/components/Applications/SubscriptionApps/Common/OpenedAppCard';
import UnopenedAppCard from 'datacosmos/components/Applications/SubscriptionApps/Common/UnopenedAppCard';
import { parseDate } from '@internationalized/date';
import { btoaSafe } from 'utils/common/btoaSafe';
import { postTicket } from 'api/tickets/service';
import { useActivePage } from 'datacosmos/components/Toolbar/ActivePageProvider';

const DATACOSMOS_IMG = '/images/datacosmos/';

type IProps = {
  app: IApplication;
};

export const QuasarWaterMonitoringApp: IApplication = {
  get id() {
    return btoaSafe(
      JSON.stringify(
        this.name + this.provider + this.description + this.appScreenshotUrl
      ).substring(0, 75)
    );
  },
  name: 'Water Quality Monitoring',
  description:
    'Quasar Science Resources provides routinely maps of turbidity, total suspended matter (TSM), chlorophyll-a, coloured dissolved organic matter (CDOM), and harmful algal blooms (HABs). These products reveal the health and composition of targeted water bodies at a snapshot in time, as well as weeks, months and years; thus support the evaluation of environmental problems and potential health risks through the analysis of changes in water quality and the detection of harmful algal blooms. Our products is also used for monitoring eutrophication, accessing ecosystem/marine productivity, as well as identifying potential sites for fish farming by mapping the spatial and temporal distribution of the pollutants/environmental factors which are not readily available from in-situ measurements.',
  shortDescription:
    'Applications include: Harmful Algae Bloom (HABs) monitoring, eutrophication monitoring, ecosystem monitoring, aquaculture site selection, identification of delta growth and retreat, identification of beach erosion, sediment load estimation, water quality monitoring',
  provider: {
    name: 'Quasar Science Resources, S.L.',
    description: '',
    id: 12,
    url: 'https://quasarsr.com/',
    icon_url: DATACOSMOS_IMG + 'quasar_logo.png',
  },
  appScreenshotUrl: DATACOSMOS_IMG + 'quasar_water_screenshot.png',
  frequencyOfData: 'Between 1 and 4 images per month',
  price: 'Contact us',
  fee: 'Cost model: Number of images to be analysed and wether any kind of technical report is needed or not to accompany the images.',
  inputs: [
    {
      field: 'AOI',
      example: '',
    },
    {
      field: 'startDate',
      example: '',
    },
    {
      field: 'endDate',
      example: '',
    },
    {
      field: 'analysisType',
      example: '',
    },
  ],
  values: {
    AOI: { value: undefined, isError: false, message: '' },
    startDate: { value: undefined, isError: false, message: '' },
    endDate: { value: undefined, isError: false, message: '' },
    analysisType: { value: undefined, isError: false, message: '' },
  },
  renderer: (app: IApplication) => <QuasarWaterMonitoring app={app} />,
  leadTime:
    'This will depend on the product and satellite pass. Urgent requests less than 48 hours after satellite overpass, but should be reflected on price.',
  additionalInfo:
    'For water quality products standard algorithms are used. Quasar can develop regional algorithms on demand, but this requires interaction with the client and corresponding in situ data of the region of interest. This will take between 3 and 6 months development.',
  tags: [AppTags.water_quality],
};

const ANALYSIS_TYPES = [
  'Harmful Algae Bloom (HABs) monitoring',
  'Eutrophication monitoring',
  'Ecosystem monitoring',
  'Aquaculture site selection',
  'Identification of delta growth and retreat',
  'Identification of beach erosion',
  'Sediment load estimation',
  'Water quality monitoring',
];

const QuasarWaterMonitoring = ({ app }: IProps) => {
  const [isAppOpened, setIsAppOpened] = useState<boolean>(false);
  const {
    setApplicationAOIs: setQuasarAOI,
    applicationAOIs: quasarAOI,
    setInputData,
    getInstalledStatus,
    toggleAppInstall,
    shouldAutoOpen,
    setSelectedInstalledApp,
  } = useApplicationCatalog();

  const { activePage, setActivePage } = useActivePage();
  const { removeLayersBySourceType } = useMapLayers();

  const [startDate, setStartDate] = useState<Date>();
  const [endDate, setEndDate] = useState<Date>();
  const [analysisType, setAnalysisType] = useState<string>();

  const setValue = (key: string, value: IAPIAppValue['value']) => {
    setInputData(app.name, {
      ...app.values,
      [key]: { value, isError: false, message: '' },
    });
  };

  useEffect(() => {
    setValue('startDate', startDate);
    setValue('AOI', quasarAOI);
    setValue('analysisType', analysisType);
    setValue('endDate', endDate);
  }, [startDate, quasarAOI, analysisType, endDate]);

  const inputs = (): JSX.Element => {
    return (
      <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
        <div>
          <label htmlFor="aoi">Area of interest: </label>
          <div
            style={{
              display: 'grid',
              gridTemplateColumns: '2fr 2fr 0.2fr',
              gap: '5px',
            }}
            id="aoi"
          >
            <DrawAreaOfInterest
              aoiSourceType={LayerSourceType.APPLICATION_AOI}
              setAreasOfInterest={setQuasarAOI}
              buttonForApplications
              multipleAois
            />

            <UploadRegion
              aoiSourceType={LayerSourceType.APPLICATION_AOI}
              setAreaOfInterest={setQuasarAOI}
              buttonForApplications
              buttonTitle="Upload"
              multipleAois
            />

            <IconButton
              icon="Trash"
              size={24}
              onPress={() => {
                setQuasarAOI([]);
                removeLayersBySourceType(LayerSourceType.APPLICATION_AOI);
              }}
              className="justify-self-center self-center"
            />
          </div>
        </div>

        <div id="startDate">
          <DatePicker
            fill
            label="Start date: "
            onChange={(d) => setStartDate(d.toDate('Etc/UTC'))}
            maxValue={parseDate(moment().add(10, 'years').format('YYYY-MM-DD'))}
          />
        </div>

        <div id="endDate">
          <DatePicker
            fill
            label="End date: "
            onChange={(d) => setEndDate(d.toDate('Etc/UTC'))}
            maxValue={parseDate(moment().add(10, 'years').format('YYYY-MM-DD'))}
          />
        </div>

        <div>
          <Select2
            onSelectionChange={(item) => setAnalysisType(item.toString())}
            fill
            label={<label htmlFor="analysis">Analysis type: </label>}
            selectedItemClassName="border-2 border-item"
          >
            {ANALYSIS_TYPES.map((item) => (
              <Item key={item}>{item}</Item>
            ))}
          </Select2>
        </div>
      </div>
    );
  };

  const formatValuesForBody = () => {
    let body = '';

    for (const [field, value] of Object.entries(app.values)) {
      body += `${field}: ${JSON.stringify(value.value)}%0D%0A`;
    }

    return body;
  };

  const handleSubmit = () => {
    let mailBody = `ENTER EXTRA DETAILS HERE%0D%0A----------------------------%0D%0A`;

    mailBody += formatValuesForBody();

    void postTicket({
      body: {
        title: `DataCosmos Subscription to ${app.name}`,
        description: mailBody,
        team: 'datacosmos',
      },
    });
  };

  if (shouldAutoOpen || (isAppOpened && getInstalledStatus(app))) {
    return (
      <OpenedAppCard
        app={app}
        inputsRenderer={inputs}
        setIsAppOpened={setIsAppOpened}
        handleSubmit={handleSubmit}
        isInstalled={getInstalledStatus(app)}
        toggleAppInstall={toggleAppInstall}
      />
    );
  }

  return (
    <UnopenedAppCard
      app={app}
      setIsAppOpened={setIsAppOpened}
      toggleAppInstall={toggleAppInstall}
      isInstalled={getInstalledStatus(app)}
      setSelectedInstalledApp={(app) => {
        setSelectedInstalledApp(app);
        activePage === 'application' && setActivePage(undefined);
      }}
    />
  );
};
