import React, { useRef } from 'react';
import { useMenu } from 'react-aria';
import type { TreeProps } from 'react-stately';
import { useTreeState, Item } from 'react-stately';
import MenuItem from '_atoms/MenuItem/MenuItem';
import Popover2 from '_atoms/Popover/Popover2';

interface IMenuProps<T> extends TreeProps<T> {
  onAction?: (key: React.Key) => void;
}

const Menu = <T extends object>(props: IMenuProps<T>) => {
  const state = useTreeState(props);
  const ref = useRef<HTMLDivElement>(null);
  const { menuProps } = useMenu(props, state, ref);
  return (
    <div {...menuProps} ref={ref} className="color-surface py-2">
      {[...state.collection].map((item) => {
        const nodes = Array.from(item.childNodes);

        if (nodes.length > 0) {
          return (
            <Popover2
              key={item.key}
              popupContent={
                <Menu aria-labelledby="Menu">
                  {nodes.map((node) => (
                    <Item
                      key={node.key}
                      textValue={
                        (node.props as { textValue?: string }).textValue
                      }
                    >
                      {(node.props as { children?: JSX.Element }).children}
                    </Item>
                  ))}
                </Menu>
              }
              placement="right top"
              toggleClassName="w-full"
              hideCloseBtn
            >
              <MenuItem
                item={item}
                state={state}
                onAction={props.onAction}
                isNested
              />
            </Popover2>
          );
        }

        return (
          <MenuItem
            state={state}
            key={item.key}
            item={item}
            onAction={props.onAction}
          />
        );
      })}
    </div>
  );
};

export default Menu;
