import type {
  IUpdatePlatformAndCascadeConfigurationActionProps,
  IUpdatePlatformAndCascadeConfigurationAction,
  IUpdateModuleSelection,
  ILoadModuleSelection,
  IUploadPlatformConfiguration,
} from '../../../constants/ui/platformConfigurator/actionTypes';
import {
  PLATFORM_UPDATE_CONFIG,
  UPDATE_MODULE_SELECTION,
  LOAD_MODULE_SELECTION,
  UPLOAD_PLATFORM_CONFIGURATION,
} from '../../../constants/ui/platformConfigurator/actionTypes';
import type { ISatelliteModules } from '../../../constants/satellite/types';

export const updatePlatformAndCascadeConfiguration = (
  payload: IUpdatePlatformAndCascadeConfigurationActionProps
): IUpdatePlatformAndCascadeConfigurationAction => {
  return {
    type: PLATFORM_UPDATE_CONFIG,
    payload,
  };
};

export const updateSatelliteModule = (
  moduleType: string,
  selectedNames: string[]
): IUpdateModuleSelection => {
  return {
    type: UPDATE_MODULE_SELECTION,
    moduleType,
    selectedNames,
  };
};

export const loadSatellitePlatform = (
  moduleNames: ISatelliteModules
): ILoadModuleSelection => {
  return {
    type: LOAD_MODULE_SELECTION,
    moduleNames,
  };
};

export const uploadPlatformConfiguration = (
  platformConfiguration: any
): IUploadPlatformConfiguration => {
  return {
    type: UPLOAD_PLATFORM_CONFIGURATION,
    platformConfiguration,
  };
};
