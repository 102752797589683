import { Classes, MenuItem, PopoverPosition } from '@blueprintjs/core';
import { IconNames as BpIcons } from '@blueprintjs/icons';
import type { ItemRenderer } from '@blueprintjs/select';
import { MultiSelect } from '@blueprintjs/select';
import { SATELLITE_DATA_TYPE_LIST } from 'api/satellites/types';
import type { SatelliteDataType } from 'api/satellites/types';
import React from 'react';
import Icon from '_atoms/Icon/Icon';
import Portal from '_atoms/Portal/Portal';
import DetailedTooltip from '_molecules/DetailedTooltip/DetailedTooltip';
import IconButton from '_molecules/IconButton/IconButton';
import RangeSlider from '_molecules/RangeSlider/RangeSlider';
import s from './InstrumentFilter.module.scss';
import { useLocalisation } from 'utils/hooks/useLocalisation';
import { Button } from 'opencosmos-ui';

type InstrumentFiltersProps = {
  setIsFilterOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isFilterOpen: boolean;
  selectedDataTypes: SatelliteDataType[];
  gsd: { min: number; max: number } | null;
  setGsd: (gsd: { min: number; max: number } | null) => void;
  handleDataTypeSelect: (item: SatelliteDataType) => void;
  handleDataTypeRemove: (item: SatelliteDataType) => void;
  getActiveFiltersCount: () => number;
};

export const InstrumentFilters = ({
  selectedDataTypes,
  gsd,
  isFilterOpen,
  setGsd,
  setIsFilterOpen,
  handleDataTypeRemove,
  handleDataTypeSelect,
  getActiveFiltersCount,
}: InstrumentFiltersProps) => {
  const DataTypeMultiselect = MultiSelect.ofType<SatelliteDataType>();

  const dataTypeRenderer: ItemRenderer<SatelliteDataType> = (
    dataType,
    { handleClick, modifiers }
  ) => {
    if (!modifiers.matchesPredicate) {
      return null;
    }
    const selected = selectedDataTypes.includes(dataType);

    return (
      <MenuItem
        onClick={handleClick}
        text={dataType}
        key={dataType}
        icon={selected ? BpIcons.TICK : null}
      />
    );
  };

  const ref = React.useRef<HTMLDivElement>(null);

  const top = ref.current && ref.current.getBoundingClientRect().y - 8;
  const left = ref.current && ref.current.getBoundingClientRect().right - 10;

  const { translate } = useLocalisation();
  return (
    <>
      <div
        className="bg-item flex items-center relative dark:bg-item-dark dark:text-item-dark-contrast"
        ref={ref}
      >
        {getActiveFiltersCount() !== 0 && (
          <Portal>
            <span
              style={{
                position: 'absolute',
                borderRadius: '50%',
                height: '15px',
                width: '15px',
                textAlign: 'center',
                top: top === null ? undefined : top,
                left: left === null ? undefined : left,
                fontSize: '11px',
                cursor: 'pointer',
                zIndex: 999999999,
              }}
              className="color-item"
              onClick={() => setIsFilterOpen(!isFilterOpen)}
            >
              {getActiveFiltersCount()}
            </span>
          </Portal>
        )}
        <Button
          icon="Filter"
          size={24}
          onPress={() => setIsFilterOpen(!isFilterOpen)}
          text="Filter Satellites"
        />
      </div>

      {isFilterOpen && (
        <div
          className="bg-item absolute left-[445px] w-72 p-4 flex flex-col gap-4 dark:bg-item-dark dark:text-item-dark-contrast"
          style={{ top: '35rem' }}
        >
          <IconButton
            icon="Collapse"
            onPress={() => setIsFilterOpen(false)}
            className="absolute top-4 right-3 "
            size={24}
          />
          <div>
            <h5
              className={
                Classes.HEADING +
                ' ' +
                s.filterHeading +
                ' dark:bg-item-dark dark:text-item-dark-contrast'
              }
            >
              <Icon icon="DATA_LINEAGE" />
              <span>
                {translate(
                  'datacosmos.tasking.new.instruments.filters.dataType'
                )}
              </span>
            </h5>
            <DataTypeMultiselect
              fill
              itemRenderer={dataTypeRenderer}
              items={Object.values(SATELLITE_DATA_TYPE_LIST)}
              onItemSelect={handleDataTypeSelect}
              onRemove={handleDataTypeRemove}
              selectedItems={selectedDataTypes}
              tagRenderer={(tag) => tag}
              tagInputProps={{
                className: 'dark:bg-item-dark dark:text-item-dark-contrast',
                tagProps: {
                  onClick: (e) => e.stopPropagation(),
                  className:
                    'dark:bg-item-dark dark:text-item-dark-contrast m-0 p-0 focus:dark:bg-item-dark-hover border-2 border-item-contrast-text-field-stroke',
                },
                inputProps: {
                  className:
                    'dark:bg-item-dark dark:text-item-dark-contrast p-0 focus:dark:bg-item-dark-hover border-2 border-item-contrast-text-field-stroke',
                },
              }}
              popoverProps={{
                position: PopoverPosition.LEFT,
                fill: true,
                className: 'dark:bg-item-dark dark:text-item-dark-contrast',
              }}
              className="dark:bg-item-dark dark:text-item-dark-contrast focus:dark:bg-item-dark-hover border-2 border-item-contrast-text-field-stroke"
            />
          </div>

          <div className="flex-col w-full p-2">
            <div className="flex items-center w-full">
              <div className="flex items-center w-full gap-4">
                <Icon icon="Satellite" />
                <DetailedTooltip
                  delay={0}
                  content={translate('datacosmos.tooltips.tasking.gsd.title')}
                  description={translate(
                    'datacosmos.tooltips.tasking.gsd.description'
                  )}
                >
                  <span>
                    {translate(
                      'datacosmos.tasking.new.instruments.filters.gsd'
                    )}
                  </span>
                </DetailedTooltip>
              </div>
              <IconButton
                icon={gsd ? 'Trash' : 'PlusSquare'}
                onPress={() => {
                  if (gsd) {
                    setGsd(null);
                  } else {
                    setGsd({ min: 0, max: 10 });
                  }
                }}
                data-testid="reset-oza-filter-btn"
              />
            </div>
            {gsd && (
              <RangeSlider
                numberOfHandles={2}
                minValue={0}
                maxValue={10}
                step={0.5}
                showValuesAboveHandles
                showScale={true}
                value={[gsd.min, gsd.max]}
                onChange={(val) => setGsd({ min: val[0], max: val[1] })}
                handleLabelFormatter={(val: number) => {
                  if (val === 10) {
                    return '>10m';
                  }
                  return val.toString() + 'm';
                }}
              />
            )}
          </div>
        </div>
      )}
    </>
  );
};
