import type { ReactElement } from 'react';
import React from 'react';

export const SummaryEndPage = (): ReactElement => {
  return (
    <div className="page summary-page-end page-hidden">
      <div className="chapter">
        <h1 className="end">END OF DOCUMENT</h1>
      </div>
    </div>
  );
};
