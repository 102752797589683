import type { Scene } from '@babylonjs/core';
import {
  MeshBuilder,
  StandardMaterial,
  Texture,
  Axis,
  Space,
  Color3,
  Vector3,
  Mesh,
  DirectionalLight,
  ArcRotateCamera,
  SceneOptimizerOptions,
  MergeMeshesOptimization,
  PostProcessesOptimization,
  ShadowsOptimization,
  HardwareScalingOptimization,
  TextureOptimization,
  SceneOptimizer,
} from '@babylonjs/core';
import type { AdvancedDynamicTexture } from '@babylonjs/gui';
import { TextBlock } from '@babylonjs/gui';
import type { ISolarPanelMeshes, ISatelliteObjects } from './types';
import {
  PLATFORM_SIZE_TYPE,
  PLATFORM_SOLAR_PANEL_CONFIG_TYPE,
  PLATFORM_SOLAR_PANEL_DEPLOYABLE_TYPE,
  PLATFORM_SOLAR_PANEL_DIRECTION_TYPE,
} from '../components/Platform/constants';
import type { IPlatformCharacteristics } from '../constants/ui/platformConfigurator/types';
import {
  PLATFORM_SIZE,
  PLATFORM_SOLAR_PANEL_CONFIG,
  PLATFORM_SOLAR_PANEL_DEPLOYABLE,
  PLATFORM_SOLAR_PANEL_DIRECTION,
  PLATFORM_SOLAR_PANEL_MOUNT_PERCENTAGE,
} from '../constants/ui/platformConfigurator/types';
import COLORS from '../constants/colors';

export const ASTRUM_TEXTURE_PATH = '/images/astrums/';
export const TEXTURES_PATH = '/images/textures/';

export const addLabelToMesh = (
  guiTexture: AdvancedDynamicTexture,
  mesh: Mesh,
  text: string,
  color?: string
) => {
  // GUI texture
  var infoText = new TextBlock('infoText');
  infoText.fontFamily = 'Courier';
  infoText.fontSize = '25px';
  infoText.fontStyle = 'bold';

  infoText.color = color ? color : 'white';
  infoText.text = text;

  guiTexture.addControl(infoText);

  infoText.linkWithMesh(mesh);
};

export const loadAstrum = (
  astrumName: string,
  equatorialRadius: number,
  scene: Scene
) => {
  const astrum = MeshBuilder.CreateSphere(
    'astrum',
    { diameter: 2 * equatorialRadius, segments: 64 },
    scene
  );
  const astrumMaterial = new StandardMaterial(
    `astrum-${astrumName}-material`,
    scene
  );

  const dayTexture = new Texture(
    ASTRUM_TEXTURE_PATH + astrumName + '_day' + '.jpg',
    scene
  );
  dayTexture.uScale = -1;

  const nightTexture = new Texture(
    ASTRUM_TEXTURE_PATH + astrumName + '_night' + '.jpg',
    scene
  );
  nightTexture.uScale = -1;

  const bumpTexture = new Texture(
    ASTRUM_TEXTURE_PATH + astrumName + '_bump' + '.png',
    scene
  );
  bumpTexture.uScale = -1;

  astrumMaterial.diffuseTexture = dayTexture;
  astrumMaterial.emissiveTexture = nightTexture;
  astrumMaterial.bumpTexture = bumpTexture;
  astrumMaterial.useLogarithmicDepth = true;
  // So that the Earth isn't shiny
  astrumMaterial.specularColor = new Color3(0, 0, 0);
  astrumMaterial.specularPower = 0;
  astrum.material = astrumMaterial;

  const clouds = MeshBuilder.CreateSphere(
    'clouds',
    { diameter: 2.001 * equatorialRadius, segments: 64 },
    scene
  );

  const cloudsMaterial = new StandardMaterial(
    `clouds-${astrumName}-material`,
    scene
  );
  const cloudsTexture = new Texture(
    ASTRUM_TEXTURE_PATH + astrumName + '_clouds' + '.png',
    scene
  );

  cloudsTexture.uScale = -1;
  cloudsMaterial.useLogarithmicDepth = true;
  cloudsTexture.hasAlpha = true;

  cloudsMaterial.diffuseTexture = cloudsTexture;
  cloudsMaterial.useAlphaFromDiffuseTexture = true;
  cloudsMaterial.backFaceCulling = false;

  // Do not make the clouds so shiny
  cloudsMaterial.specularColor = new Color3(0, 0, 0);
  cloudsMaterial.specularPower = 0;

  clouds.material = cloudsMaterial;
  clouds.parent = astrum;

  astrum.rotate(Axis.Z, Math.PI, Space.LOCAL);

  return astrum;
};

export const createLocalAxes = (
  mesh: Mesh,
  size: number,
  scene: Scene,
  guiTexture?: AdvancedDynamicTexture
) => {
  var materialX = new StandardMaterial('axis material X', scene);
  materialX.diffuseColor = new Color3(1, 0, 0);
  materialX.emissiveColor = new Color3(1, 0, 0);
  materialX.specularColor = new Color3(0, 0, 0);
  materialX.specularPower = 0;
  materialX.useLogarithmicDepth = true;
  var materialY = new StandardMaterial('axis material Y', scene);
  materialY.diffuseColor = new Color3(0, 1, 0);
  materialY.emissiveColor = new Color3(0, 1, 0);
  materialY.specularColor = new Color3(0, 0, 0);
  materialY.specularPower = 0;
  materialY.useLogarithmicDepth = true;
  var materialZ = new StandardMaterial('axis material Z', scene);
  materialZ.diffuseColor = new Color3(0, 0, 1);
  materialZ.emissiveColor = new Color3(0, 0, 1);
  materialZ.specularColor = new Color3(0, 0, 0);
  materialZ.specularPower = 0;
  materialZ.useLogarithmicDepth = true;

  var localAxisX = Mesh.CreateTube(
    mesh.name + ' local axis X',
    [Vector3.Zero(), new Vector3(0, 0, -size)],
    0.05,
    4,
    () => 0.05,
    Mesh.CAP_ALL,
    scene
  );

  const axisTipX = Mesh.CreateBox('axis-tip-x', 1, scene);
  axisTipX.isVisible = false;
  axisTipX.position = new Vector3(0, 0, -size).scale(1.1);

  localAxisX.material = materialX;

  // TODO: In the future, reverse y mesh creation coordinates
  // so this axis pointer doesn't have to be reversed
  var localAxisY = Mesh.CreateTube(
    mesh.name + ' local axis Y',
    [Vector3.Zero(), new Vector3(-size, 0, 0)],
    0.05,
    4,
    () => 0.05,
    Mesh.CAP_ALL,
    scene
  );

  localAxisY.material = materialY;

  const axisTipY = Mesh.CreateBox('axis-tip-y', 1, scene);
  axisTipY.isVisible = false;
  axisTipY.position = new Vector3(-size, 0, 0).scale(1.1);

  var localAxisZ = Mesh.CreateTube(
    mesh.name + ' local axis Z',
    [Vector3.Zero(), new Vector3(0, size, 0)],
    0.05,
    4,
    () => 0.05,
    Mesh.CAP_ALL,
    scene
  );

  localAxisZ.material = materialZ;

  const axisTipZ = Mesh.CreateBox('axis-tip-z', 1, scene);
  axisTipZ.isVisible = false;
  axisTipZ.position = new Vector3(0, size, 0).scale(1.1);

  if (guiTexture) {
    addLabelToMesh(guiTexture, axisTipX, 'X', 'red');
    addLabelToMesh(guiTexture, axisTipY, 'Y', 'green');
    addLabelToMesh(guiTexture, axisTipZ, 'Z', 'blue');
  }

  localAxisX.parent = mesh;
  localAxisY.parent = mesh;
  localAxisZ.parent = mesh;
  axisTipX.parent = mesh;
  axisTipY.parent = mesh;
  axisTipZ.parent = mesh;
};

export const addLight = (scene: Scene) => {
  const light = new DirectionalLight(
    'light',
    new Vector3(-0.3, -0.1, 1),
    scene
  );
  light.diffuse = new Color3(0.7, 0.7, 0.7);
  light.intensity = 5;
};

export const createCamera = (scene: Scene) => {
  const camera = new ArcRotateCamera(
    'Camera',
    -Math.PI / 4,
    Math.PI / 3,
    10,
    Vector3.Zero(),
    scene
  );

  camera.setTarget(Vector3.Zero());

  const canvas = scene.getEngine().getRenderingCanvas();

  // This attaches the camera to the canvas
  camera.attachControl(canvas, true);

  return camera;
};

const createSolarPanels = (scene: Scene, name: string): ISolarPanelMeshes => {
  const solarPanels: any = {};

  solarPanels['0'] = MeshBuilder.CreateBox(
    `${name}-0`,
    { width: 0.05, height: 1, depth: 1 },
    scene
  );
  solarPanels['1'] = MeshBuilder.CreateBox(
    `${name}-1`,
    { width: 0.05, height: 1, depth: 1 },
    scene
  );
  solarPanels['2'] = MeshBuilder.CreateBox(
    `${name}-2`,
    { width: 0.05, height: 1, depth: 1 },
    scene
  );
  solarPanels['3'] = MeshBuilder.CreateBox(
    `${name}-3`,
    { width: 0.05, height: 1, depth: 1 },
    scene
  );

  return solarPanels;
};

const createSatelliteBody = (scene: Scene) => {
  const satelliteBody = MeshBuilder.CreateBox(
    'satellite',
    { width: 1, height: 1, depth: 3, updatable: true },
    scene
  );

  satelliteBody.position = new Vector3(0, 0, 0);

  const satelliteMaterial = new StandardMaterial('satellite-material', scene);
  satelliteMaterial.diffuseColor = Color3.FromHexString(
    COLORS.MAIN_PRIMARY_HEX
  );
  satelliteMaterial.emissiveColor = new Color3(0.2, 0.18, 0);
  satelliteMaterial.useLogarithmicDepth = true;
  satelliteBody.material = satelliteMaterial;

  return satelliteBody;
};

const createSatelliteMountedSolarCells = (scene: Scene, satellite: Mesh) => {
  const mountedSolarCells = MeshBuilder.CreateBox(
    'cellCoveredSatellite',
    { width: 1.05, height: 1.05, depth: 1.4 },
    scene
  );

  mountedSolarCells.setParent(satellite);
  mountedSolarCells.position = new Vector3(0, 0, 0.75);

  return mountedSolarCells;
};

/* Creates a group of meshes that bind together in one object to create
 * easily accessible portions of the whole satellite. This includes:
 * satellite body, satellite body cells, solar panel, reverse solar panel
 */
export const createSatelliteGroup = (
  scene: Scene,
  guiTexture: AdvancedDynamicTexture
): ISatelliteObjects => {
  let satellite: Mesh;
  let cellCoveredSatellite: Mesh;
  let solarPanels: ISolarPanelMeshes;
  let reverseSolarPanels: ISolarPanelMeshes;
  let solarPanelTexture: Texture;
  let objectGroup: Mesh;

  satellite = createSatelliteBody(scene);
  cellCoveredSatellite = createSatelliteMountedSolarCells(scene, satellite);

  // Materials
  solarPanelTexture = new Texture(TEXTURES_PATH + 'solar_panel.jpg', scene);
  const solarPanelMaterial = new StandardMaterial(
    'cellCoveredSatellite-material',
    scene
  );
  solarPanelMaterial.diffuseTexture = solarPanelTexture;
  solarPanelMaterial.emissiveColor = new Color3(0.4, 0.4, 0.4);
  solarPanelMaterial.useLogarithmicDepth = true;
  cellCoveredSatellite.material = solarPanelMaterial;
  cellCoveredSatellite.isVisible = false;

  solarPanels = createSolarPanels(scene, 'solar-panel');
  reverseSolarPanels = createSolarPanels(scene, 'reverse-solar-panel');

  Object.values(solarPanels).map((solarPanel) => {
    solarPanel.isVisible = false;
    solarPanel.material = solarPanelMaterial;
    solarPanel.setParent(satellite);
  });

  const solarPanelBackMaterial = new StandardMaterial(
    'solar-panel-material',
    scene
  );
  solarPanelBackMaterial.useLogarithmicDepth = true;
  solarPanelBackMaterial.diffuseColor = new Color3(1, 1, 1);
  solarPanelBackMaterial.specularColor = new Color3(0.15, 0.15, 0.15);
  solarPanelBackMaterial.emissiveColor = new Color3(0.11, 0.11, 0.11);
  Object.values(reverseSolarPanels).map((solarPanel) => {
    solarPanel.isVisible = false;
    solarPanel.material = solarPanelBackMaterial;
    solarPanel.setParent(satellite);
  });

  // Rotations
  satellite.rotate(new Vector3(1, 0, 0), -Math.PI / 2);

  // Grouping of axis with satellite
  objectGroup = MeshBuilder.CreateBox(
    'object-group',
    { width: 0.5, height: 0.5, depth: 0.5 },
    scene
  );

  satellite.setParent(objectGroup);
  createLocalAxes(objectGroup, 3, scene, guiTexture);

  return {
    satellite,
    cellCoveredSatellite,
    solarPanels,
    reverseSolarPanels,
    solarPanelTexture,
    objectGroup,
  };
};

export const addBackgroundEarth = (scene: Scene) => {
  const earth = loadAstrum('earth', 100, scene);
  earth.position.y = -130;
  earth.position.x = -150;

  // Rotate the Earth so that it starts over Europe
  earth.rotate(new Vector3(0, 0, 1), 0.05, Space.LOCAL);
  earth.rotate(new Vector3(0, 1, 0), 0.32 * Math.PI, Space.LOCAL);

  return earth;
};

export const configurateSatelliteMeshes = (
  {
    satellite,
    solarPanels,
    reverseSolarPanels,
    cellCoveredSatellite,
  }: ISatelliteObjects,
  platformCharacteristics: IPlatformCharacteristics
) => {
  switch (platformCharacteristics[PLATFORM_SIZE]) {
    case PLATFORM_SIZE_TYPE.SIX_U_A:
      satellite.scaling = new Vector3(2, 1, 1);
      break;
    case PLATFORM_SIZE_TYPE.SIX_U_B:
      satellite.scaling = new Vector3(1, 2, 1);
      break;
    case PLATFORM_SIZE_TYPE.TWELVE_U:
      satellite.scaling = new Vector3(2, 2, 1);
      break;
    default:
    case PLATFORM_SIZE_TYPE.THREE_U:
      satellite.scaling = new Vector3(1, 1, 1);
      break;
  }

  switch (platformCharacteristics[PLATFORM_SOLAR_PANEL_CONFIG]) {
    case PLATFORM_SOLAR_PANEL_CONFIG_TYPE.PERP:
      switch (platformCharacteristics[PLATFORM_SIZE]) {
        case PLATFORM_SIZE_TYPE.SIX_U_A:
          solarPanels['0'].scaling = new Vector3(1, 2, 3);
          solarPanels['1'].scaling = new Vector3(1, 2, 3);
          solarPanels['0'].position = new Vector3(-0.5, -1.5, 0);
          solarPanels['1'].position = new Vector3(-0.5, 1.5, 0);
          break;
        case PLATFORM_SIZE_TYPE.SIX_U_B:
          solarPanels['0'].scaling = new Vector3(1, 0.5, 3);
          solarPanels['1'].scaling = new Vector3(1, 0.5, 3);
          solarPanels['0'].position = new Vector3(-0.5, -0.75, 0);
          solarPanels['1'].position = new Vector3(-0.5, 0.75, 0);
          break;
        default:
        case PLATFORM_SIZE_TYPE.TWELVE_U:
        case PLATFORM_SIZE_TYPE.THREE_U:
          solarPanels['0'].scaling = new Vector3(1, 1, 3);
          solarPanels['1'].scaling = new Vector3(1, 1, 3);
          solarPanels['0'].position = new Vector3(-0.5, -1, 0);
          solarPanels['1'].position = new Vector3(-0.5, 1, 0);
          break;
      }

      solarPanels['0'].isVisible = true;
      solarPanels['1'].isVisible = true;
      break;
    case PLATFORM_SOLAR_PANEL_CONFIG_TYPE.PETAL:
    case PLATFORM_SOLAR_PANEL_CONFIG_TYPE.DIAG:
      break;
    case PLATFORM_SOLAR_PANEL_CONFIG_TYPE.NONE:
    default:
      Object.values(solarPanels).map(
        (solarPanel) => (solarPanel.isVisible = false)
      );
      break;
  }

  switch (platformCharacteristics[PLATFORM_SOLAR_PANEL_DEPLOYABLE]) {
    case PLATFORM_SOLAR_PANEL_DEPLOYABLE_TYPE.DOUBLE:
      Object.values(solarPanels).map((solarPanel) => {
        if (solarPanel) {
          solarPanel.scaling.y *= 2;
          if (solarPanel.position.y > 0) {
            switch (platformCharacteristics[PLATFORM_SIZE]) {
              case PLATFORM_SIZE_TYPE.SIX_U_A:
                solarPanel.position.y = 2.5;
                break;
              case PLATFORM_SIZE_TYPE.SIX_U_B:
                solarPanel.position.y = 1;
                break;
              default:
              case PLATFORM_SIZE_TYPE.TWELVE_U:
              case PLATFORM_SIZE_TYPE.THREE_U:
                solarPanel.position.y = 1.5;
                break;
            }
          } else if (solarPanel.position.y < 0) {
            switch (platformCharacteristics[PLATFORM_SIZE]) {
              case PLATFORM_SIZE_TYPE.SIX_U_A:
                solarPanel.position.y = -2.5;
                break;
              case PLATFORM_SIZE_TYPE.SIX_U_B:
                solarPanel.position.y = -1;
                break;
              default:
              case PLATFORM_SIZE_TYPE.TWELVE_U:
              case PLATFORM_SIZE_TYPE.THREE_U:
                solarPanel.position.y = -1.5;
                break;
            }
          }
        }
      });
      break;

    case PLATFORM_SOLAR_PANEL_DEPLOYABLE_TYPE.TRIPLE:
      Object.values(solarPanels).map((solarPanel) => {
        if (solarPanel) {
          solarPanel.scaling.y *= 3;
          if (solarPanel.position.y > 0) {
            switch (platformCharacteristics[PLATFORM_SIZE]) {
              case PLATFORM_SIZE_TYPE.SIX_U_A:
                solarPanel.position.y = 3.5;
                break;
              case PLATFORM_SIZE_TYPE.SIX_U_B:
                solarPanel.position.y = 1.25;
                break;
              default:
              case PLATFORM_SIZE_TYPE.TWELVE_U:
              case PLATFORM_SIZE_TYPE.THREE_U:
                solarPanel.position.y = 2;
                break;
            }
          } else if (solarPanel.position.y < 0) {
            switch (platformCharacteristics[PLATFORM_SIZE]) {
              case PLATFORM_SIZE_TYPE.SIX_U_A:
                solarPanel.position.y = -3.5;
                break;
              case PLATFORM_SIZE_TYPE.SIX_U_B:
                solarPanel.position.y = -1.25;
                break;
              default:
              case PLATFORM_SIZE_TYPE.TWELVE_U:
              case PLATFORM_SIZE_TYPE.THREE_U:
                solarPanel.position.y = -2;
                break;
            }
          }
        }
      });
  }

  switch (platformCharacteristics[PLATFORM_SOLAR_PANEL_DIRECTION]) {
    case PLATFORM_SOLAR_PANEL_DIRECTION_TYPE.PLUS:
      Object.entries(solarPanels).map(([id, mesh]) => {
        // @ts-ignore - there's no way this would fail
        const reverseSolarPanel = reverseSolarPanels[id];

        reverseSolarPanel.position = new Vector3(
          mesh.position.x,
          mesh.position.y,
          mesh.position.z
        );

        mesh.position = new Vector3(
          mesh.position.x - 0.05,
          mesh.position.y,
          mesh.position.z
        );

        reverseSolarPanel.scaling = mesh.scaling;
        reverseSolarPanel.isVisible = mesh.isVisible;
      });
      break;
    case PLATFORM_SOLAR_PANEL_DIRECTION_TYPE.MINUS:
    default:
      Object.entries(solarPanels).map(([id, mesh]) => {
        // @ts-ignore - there's no way this would fail
        const reverseSolarPanel = reverseSolarPanels[id];

        reverseSolarPanel.position = new Vector3(
          mesh.position.x - 0.05,
          mesh.position.y,
          mesh.position.z
        );

        reverseSolarPanel.scaling = mesh.scaling;
        reverseSolarPanel.isVisible = mesh.isVisible;
      });
      break;
  }

  switch (platformCharacteristics[PLATFORM_SOLAR_PANEL_MOUNT_PERCENTAGE]) {
    case '50%':
      cellCoveredSatellite.isVisible = true;
      break;
    case '0%':
    default:
      if (cellCoveredSatellite) cellCoveredSatellite.isVisible = false;
  }
};

const TARGET_FPS = 60;
export const optimiseScene = (scene: Scene) => {
  const options = new SceneOptimizerOptions(TARGET_FPS, 3000);

  // TODO: Add custom optimisation which will downscale window resolution
  options.optimizations = [
    new MergeMeshesOptimization(0),
    new PostProcessesOptimization(1),
    new ShadowsOptimization(2),
    new HardwareScalingOptimization(3),
    new TextureOptimization(4, 2048),
  ];

  const optimiser = new SceneOptimizer(scene, options, false, true);

  optimiser.start();
};
