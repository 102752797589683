import React, { useEffect } from 'react';
import { Button, Intent } from '@blueprintjs/core';
import type { AppState } from '../../reducers/rootReducer';
import { connect } from 'react-redux';
import type { ISatelliteModeConfiguration } from '../../constants/satellite/types';
import ModeCard from './ModeCard';
import { setPopUp } from '../../actions/popUp/thunks';
import type { IPopUp } from '../../constants/popUp/actionTypes';
import ModeForm from './ModeForm';
import { removeSatelliteOperationalMode } from '../../actions/satellite/action';
import type { WithTranslation } from 'react-i18next';
import { withTranslation } from 'react-i18next';
import type { IPointOfInterest } from '../../constants/pointsOfInterest/actionTypes';
import type { IRegionOfInterest } from '../../constants/regionsOfInterest/actionTypes';
import { GROUND_STATION_PASS_MODE } from '../../constants/satellite/constants';
import { IconNames } from '@blueprintjs/icons';

type IMapStateToProps = {
  currentModeConfigurations: ISatelliteModeConfiguration;
  POIs: IPointOfInterest[];
  ROIs: IRegionOfInterest[];
} & WithTranslation;

interface IMapDispatchToProps {
  setPopUp: typeof setPopUp;
  removeSatelliteOperationalModeWithCheck: typeof removeSatelliteOperationalMode;
}

interface IMergeProps {
  removeSatelliteOperationalMode: (name: string) => void;
}

interface IOwnProps {
  groundStationsOnly?: boolean;
}

type IProps = IMapStateToProps & IMergeProps & IMapDispatchToProps & IOwnProps;

const showCreateModePanel = (setPopUp: Function): void => {
  const popUp: IPopUp = {
    visible: true,
    classNames: ['centered'],
    component: ModeForm,
  };
  setPopUp(popUp);
};

const ModesPanel: React.FunctionComponent<IProps> = ({
  currentModeConfigurations,
  setPopUp,
  removeSatelliteOperationalMode,
  groundStationsOnly,
}) => {
  let filteredModes = Object.keys(currentModeConfigurations);

  if (groundStationsOnly)
    filteredModes = filteredModes.filter(
      (mode) => mode === GROUND_STATION_PASS_MODE
    );
  else
    filteredModes = filteredModes.filter(
      (mode) => mode !== GROUND_STATION_PASS_MODE
    );

  const sortedModes = filteredModes.sort();

  useEffect(() => {
    if (!Object.keys(currentModeConfigurations).length) {
      showCreateModePanel(setPopUp);
    }
  }, []);

  return (
    <div className="geometry-panel center-bottom">
      <div className="white geometry-header">
        <h3 className="text">{groundStationsOnly ? 'GS Modes' : 'Modes'}</h3>
        {!groundStationsOnly && (
          <Button
            className="button button_common"
            onClick={() => showCreateModePanel(setPopUp)}
            intent={Intent.NONE}
            icon={IconNames.ADD}
            key="add"
            text="Add"
          />
        )}
      </div>
      <div className="white geometry-body">
        {sortedModes.map((modeName) => (
          <ModeCard
            name={modeName}
            modeConfiguration={currentModeConfigurations[modeName]}
            key={modeName}
            removeHandler={removeSatelliteOperationalMode}
            setPopUp={setPopUp}
          />
        ))}
      </div>
    </div>
  );
};

const mapStateToProps = (state: AppState): any => ({
  currentModeConfigurations: state.satellite.currentModeConfigurations,
  POIs: state.pointsOfInterest.list,
  ROIs: state.regionsOfInterest.list,
});

const mapDispatchToProps = {
  setPopUp,
  removeSatelliteOperationalModeWithCheck: removeSatelliteOperationalMode,
};

const mergeProps = (
  propsFromState: IMapStateToProps,
  propsFromDispatch: IMapDispatchToProps,
  ownProps: IOwnProps
) => {
  return {
    ...ownProps,
    currentModeConfigurations: propsFromState.currentModeConfigurations,
    POIs: propsFromState.POIs,
    ROIs: propsFromState.ROIs,
    setPopUp: propsFromDispatch.setPopUp,
    removeSatelliteOperationalMode: (name: string) =>
      propsFromDispatch.removeSatelliteOperationalModeWithCheck(
        name,
        propsFromState.POIs,
        propsFromState.ROIs
      ),
  };
};

export default connect<
  IMapStateToProps,
  IMapDispatchToProps,
  IMergeProps & IOwnProps
>(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(withTranslation()(ModesPanel));
