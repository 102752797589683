import type { ActivityStatus } from 'api/activities/types';

export const ACTIVITY_CATEGORY_LIST = [
  'FAILED',
  'PLANNED',
  'SUCCEEDED',
] as const;

export type ActivityCategory = (typeof ACTIVITY_CATEGORY_LIST)[number];

export const getActivityCategory = (
  status: ActivityStatus
): ActivityCategory => {
  if (status === 'CANCELLED' || status === 'EXPIRED' || status === 'FAILED') {
    return 'FAILED';
  }
  if (status === 'CONFIRMED' || status === 'SCHEDULED') {
    return 'PLANNED';
  }
  return 'SUCCEEDED';
};
