import MapLayers from 'datacosmos/components/map-layers';
import { useMapLayers } from 'datacosmos/stores/MapLayersProvider';
import { useMap } from 'datacosmos/stores/MapProvider';
import DrawCircle from 'datacosmos/components/RightClickMenu/DrawCircle';
import { DrawPolygon } from 'datacosmos/components/RightClickMenu/DrawPolygon';
import { DrawLine } from 'datacosmos/components/RightClickMenu/DrawLine';
import Icon from '_atoms/Icon/Icon';
import Popover2 from '_atoms/Popover/Popover2';
import { useViewMode } from 'datacosmos/utils/hooks/useViewMode';
import { DrawRectangle } from './RightClickMenu/DrawRectangle';
import { DrawPolyline } from './RightClickMenu/DrawPolyline';
import UploadRegionMenu from './RightClickMenu/UploadRegionMenu';
import { useFilters } from 'datacosmos/stores/FiltersProvider';

type HeaderProps = {
  leftMenuOpen: boolean;
};

export const Header = ({ leftMenuOpen }: HeaderProps) => {
  const { addLayer, ...mapLayers } = useMapLayers();

  const filters = useFilters();
  const { drawCircle, drawPolygon, measure, drawRectangle, drawPolyline } =
    useMap();
  const { isMinimal } = useViewMode();
  const closedSize = isMinimal ? '40px' : '100px';
  return (
    <header className="main-header" role="banner">
      <div className="main-header-wrapper">
        <div>
          <nav>
            <div>
              <ul
                className="app-menu"
                style={{
                  position: 'absolute',
                  left: leftMenuOpen ? '30.2rem' : closedSize,
                  top: '1rem',
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '5px',
                }}
              >
                {!isMinimal && (
                  <li className="bttn map-control-layers">
                    <Popover2
                      className="dark:bg-item-dark dark:text-item-dark-contrast dark:hover:bg-item-dark bg-item w-fit p-2"
                      placement="right"
                      isDismissable={true}
                      popupContent={
                        <div
                          style={{
                            margin: 0,
                            textAlign: 'center',
                          }}
                        >
                          <ul
                            style={{
                              listStyle: 'none',
                              listStyleType: 'none',
                              margin: 0,
                              padding: '2px',
                            }}
                          >
                            <li
                              style={{ textAlign: 'left' }}
                              className="dark:hover:text-item-dark-hover hover:text-accent-900"
                            >
                              <DrawCircle
                                addLayer={addLayer}
                                drawCircle={drawCircle}
                              />
                            </li>
                            <li
                              style={{ textAlign: 'left' }}
                              className=" dark:hover:text-item-dark-hover hover:text-accent-900 "
                            >
                              <DrawPolygon
                                addLayer={addLayer}
                                drawPolygon={drawPolygon}
                              />
                            </li>
                            <li
                              style={{ textAlign: 'left' }}
                              className="dark:hover:text-item-dark-hover hover:text-accent-900"
                            >
                              <DrawLine measure={measure} />
                            </li>
                            <li
                              style={{ textAlign: 'left' }}
                              className="dark:hover:text-item-dark-hover hover:text-accent-900"
                            >
                              <DrawRectangle
                                addLayer={addLayer}
                                drawRectangle={drawRectangle}
                              />
                            </li>
                            <li
                              style={{ textAlign: 'left' }}
                              className="dark:hover:text-item-dark-hover hover:text-accent-900"
                            >
                              <DrawPolyline
                                addLayer={addLayer}
                                drawPolyline={drawPolyline}
                              />
                            </li>
                            <li
                              style={{ textAlign: 'left' }}
                              className="dark:hover:text-data-dark-hover hover:text-data-light-hover"
                            >
                              <UploadRegionMenu
                                layers={{ ...mapLayers, addLayer }}
                                filters={filters}
                              />
                            </li>
                          </ul>
                        </div>
                      }
                    >
                      <Icon
                        icon="AddAoi"
                        size={24}
                        className="dark:hover:stroke-item-dark-hover"
                      />
                    </Popover2>
                  </li>
                )}
                <li className="bttn map-control-layers">
                  <MapLayers />
                </li>
              </ul>
            </div>
          </nav>
        </div>
      </div>
    </header>
  );
};
