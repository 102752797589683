import { apiFetchHandler } from 'api/clientFetch';
import { hosts } from 'api/hosts';
import type { AlertGroup, AlertGroupId, AlertRule } from './types';

export type GetAlertGroupParams = {
  id: string | undefined;
};

export const getAlertGroup = apiFetchHandler<AlertGroup, GetAlertGroupParams>({
  host: hosts.ops.v0,
  endpoint: ({ id }) => `/alerts/group/${id ?? ''}`,
  errorMessage: 'Could not get alert group',
  method: 'GET',
});

export const getAlertGroupIds = apiFetchHandler<AlertGroupId[]>({
  host: hosts.ops.v0,
  endpoint: '/alerts/group-id',
  errorMessage: 'Could not get alert group ids',
  method: 'GET',
});

export const postAlertGroup = apiFetchHandler<
  undefined,
  {},
  Omit<AlertGroup, 'id'>
>({
  host: hosts.ops.v0,
  endpoint: '/alerts/group',
  errorMessage: 'Could not create alert group',
  method: 'POST',
});

export const postAlertRule = apiFetchHandler<undefined, {}, AlertRule>({
  host: hosts.ops.v0,
  endpoint: '/alerts/rule',
  errorMessage: 'Could not create or edit alert rule',
  method: 'POST',
});

export type DeleteAlertRuleParams = {
  groupId: string;
  alertRuleId: string;
};

export const deleteAlertRule = apiFetchHandler<
  undefined,
  DeleteAlertRuleParams
>({
  host: hosts.ops.v0,
  endpoint: ({ groupId, alertRuleId }) =>
    `/alerts/group/${groupId}/rule/${alertRuleId}`,
  errorMessage: 'Could not delete alert rule',
  method: 'DELETE',
});
