import React from 'react';
import type { IconName } from '@blueprintjs/core';
import { Icon } from '@blueprintjs/core';
import s from './index.module.scss';

interface IProps {
  id: number;
  title: string;
  icon: IconName;
}

const AdministrationHeader = (props: IProps) => {
  return (
    <div className={s.container}>
      <div className={s.idWrapper}>
        <Icon icon={props.icon} />
        {props.id}
      </div>
      <div className={s.title}>{props.title}</div>
    </div>
  );
};

export default AdministrationHeader;
