import { DATETIME_FORMAT } from 'constants/datetime';
import { useTasking } from 'datacosmos/stores/TaskingProvider';
import moment from 'moment';
import { Dialog } from 'opencosmos-ui';
import { DetailsItem } from 'pages/ops/Scheduling/ScheduleActivityDetails/DetailCards/DetailsItem';
import React from 'react';
import { useLocalisation } from 'utils/hooks/useLocalisation';

type Props = {
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
  handleSwitchToExistingPanel: (key: string) => void;
};

const ConfirmAutomatedRequestModal = ({
  handleSwitchToExistingPanel,
  isOpen,
  setIsOpen,
}: Props) => {
  const { translate } = useLocalisation();

  const {
    regions,
    requestNotes,
    setRequestNotes,
    addSatellite,
    SZAConstraints,
    OZAConstraints,
    dateFrom,
    dateTo,
    submitAutomatedTaskingRequest,
  } = useTasking();

  return (
    <Dialog
      isOpen={isOpen}
      buttons={[
        {
          onPress: async () => {
            const hasWorked = await submitAutomatedTaskingRequest();

            if (hasWorked) {
              setIsOpen(false);
              handleSwitchToExistingPanel('Existing');
            }
          },
          shown: true,
          text: 'Submit',
        },
      ]}
      onClose={() => {
        setIsOpen(false);
        handleSwitchToExistingPanel('Existing');
      }}
    >
      <div className="grid !shadow-none grid-cols-2 gap-4 h-[450px]">
        <div className="flex flex-col gap-6">
          <div className="flex flex-col gap-2">
            <span className="text-[16px] block" data-testid="title">
              {translate('datacosmos.tasking.automatedRequestModal.title')}
            </span>
            <span className="block text-sm">
              {translate(
                'datacosmos.tasking.purchaseDialog.youCanFindTheDetailed'
              )}
            </span>
          </div>
          <div className="flex flex-col gap-2">
            <div
              className="flex flex-col gap-1 bg-item dark:bg-item-dark"
              key={1}
            >
              <DetailsItem
                title={translate(
                  'datacosmos.tasking.automatedRequestModal.regionName'
                )}
                value={regions[0]?.getName()}
              />
              <DetailsItem
                title={translate(
                  'datacosmos.tasking.automatedRequestModal.regionArea'
                )}
                value={regions[0]?.getMeasurements()}
              />
            </div>

            <div
              className="flex flex-col gap-1 bg-surface dark:bg-surface-dark"
              key={2}
            >
              <DetailsItem
                title={translate(
                  'datacosmos.tasking.automatedRequestModal.from'
                )}
                value={moment(dateFrom).format(DATETIME_FORMAT)}
              />
              <DetailsItem
                title={translate('datacosmos.tasking.automatedRequestModal.to')}
                value={moment(dateTo).format(DATETIME_FORMAT)}
              />
            </div>

            <div
              className="flex flex-col gap-1 bg-item dark:bg-item-dark"
              key={3}
            >
              <DetailsItem
                title={translate(
                  'datacosmos.tasking.automatedRequestModal.sza.min'
                )}
                value={SZAConstraints.Min.toString()}
              />
              <DetailsItem
                title={translate(
                  'datacosmos.tasking.automatedRequestModal.sza.max'
                )}
                value={SZAConstraints.Max.toString()}
              />
            </div>

            <div
              className="flex flex-col gap-1 bg-surface dark:bg-surface-dark"
              key={4}
            >
              <DetailsItem
                title={translate(
                  'datacosmos.tasking.automatedRequestModal.oza.min'
                )}
                value={OZAConstraints.Min.toString()}
              />
              <DetailsItem
                title={translate(
                  'datacosmos.tasking.automatedRequestModal.oza.min'
                )}
                value={OZAConstraints.Min.toString()}
              />
            </div>
          </div>
          <div>
            <span className="confirm-request-subtitle">
              {translate(
                'datacosmos.tasking.purchaseDialog.statistics.additionalNotes'
              )}
            </span>

            <textarea
              style={{
                width: '100%',
                height: '130px',
                resize: 'none',
                padding: '5px',
              }}
              value={requestNotes}
              onChange={(e) => setRequestNotes(e.target.value)}
              className="dark:bg-item-dark dark:text-item-dark-contrast bg-item"
              aria-label="textarea-notes"
            />
          </div>
        </div>

        <div style={{ marginBottom: '25px', marginTop: '10px' }}>
          <div className="flex flex-col gap-1 h-[400px] overflow-auto">
            <span className="text-[16px] block mb-2" data-testid="title">
              {translate(
                'datacosmos.tasking.automatedRequestModal.instrumentDetails'
              )}
            </span>
            {addSatellite?.map((s) => (
              <div
                key={s.id}
                className="flex flex-col gap-2 bg-item dark:bg-item-dark p-2 odd:bg-surface dark:odd:bg-surface-dark"
              >
                <DetailsItem
                  title={translate(
                    'datacosmos.tasking.automatedRequestModal.satelliteName'
                  )}
                  value={s.satellite.name}
                />
                <DetailsItem
                  title={translate(
                    'datacosmos.tasking.automatedRequestModal.satelliteCOSPAR'
                  )}
                  value={s.satellite.cospar_id}
                />
                <DetailsItem
                  title={translate(
                    'datacosmos.tasking.automatedRequestModal.sensor'
                  )}
                  value={s.sensor.sensorId}
                />
              </div>
            ))}
          </div>

          <div className="text-right p-5">
            <span className="text-lg font-bold">
              {translate(
                'datacosmos.tasking.purchaseDialog.statistics.totalPrice',
                {
                  price: 'contact us',
                }
              )}
            </span>
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default ConfirmAutomatedRequestModal;
