import { apiFetchHandler } from 'api/clientFetch';
import type { OSMRegionInfo } from './types';
import { hosts } from 'api/hosts';
import { clientTranslate } from 'utils/hooks/useLocalisation';

type GetRegionInfoParams = {
  q: string;
  limit: number;
  format: string;
  polygon_geojson: number;
  polygon_threshold: number;
};

export const getRegionInfo = apiFetchHandler<
  OSMRegionInfo[],
  GetRegionInfoParams
>({
  endpoint: ({ format, limit, polygon_geojson, polygon_threshold, q }) =>
    `/search.php?q=${q}&limit=${limit}&format=${format}&polygon_geojson=${polygon_geojson}&polygon_threshold=${polygon_threshold}`,
  host: hosts.OSM.search,
  errorMessage: clientTranslate('datacosmos.fetchErrors.osm.cannotGet'),
  method: 'GET',
  errorDescription: (e) => e.join(';'),
});
