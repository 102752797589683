import axios from 'axios';
import { showErrorMessage, showSuccessMessage } from 'utils/common/CommonUtils';
import { REQUEST_ERROR } from 'constants/errors';

type ResponseError = {
  response: {
    status?: number;
    data?: { errors?: { message?: string }[] };
  };
};

export default (url: string, missionId: number, token: string | undefined) => {
  const checkPodIsRunning = async (): Promise<boolean> => {
    try {
      const { data: data } = await axios.get(`${url}/mission/${missionId}`, {
        params: {},
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });
      console.log(data);
      return true;
    } catch (error) {
      const e = error as ResponseError;
      if (e?.response?.status === 404) {
        return false;
      }
      showErrorMessage(e?.response?.data?.errors[0]?.message || REQUEST_ERROR);
      throw error;
    }
  };

  const createNewInstance = async (): Promise<string> => {
    try {
      const {
        data: { data },
      } = await axios.put(
        `${url}/mission/${missionId}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      );
      showSuccessMessage(data.data.data || 'Successfully created an instance');
      return data;
    } catch (error) {
      const e = error as ResponseError;
      showErrorMessage(e?.response?.data?.errors[0]?.message || REQUEST_ERROR);
      console.error(error);
      throw error;
    }
  };

  const deleteAnInstance = async (): Promise<string> => {
    try {
      const {
        data: { data },
      } = await axios.delete(`${url}/mission/${missionId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });
      showSuccessMessage(data.data.data || 'Successfully deleted an instance');
      return data;
    } catch (error) {
      const e = error as ResponseError;
      showErrorMessage(e?.response?.data?.errors[0]?.message || REQUEST_ERROR);
      console.error(error);
      throw error;
    }
  };

  return {
    checkPodIsRunning,
    createNewInstance,
    deleteAnInstance,
  };
};
