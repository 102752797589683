import { useCallback } from 'react';
import CommandPrimitiveArguments from 'components/CommandPrimitiveArguments';
import type {
  IProperty,
  PropertyGroup,
} from 'components/CommandArguments/index';
import ArrayField from 'components/inputField/ArrayField';
import GenericRemoveButton from 'components/CommandArguments/GenericRemoveButton';
import s from 'components/CommandArguments/index.module.scss';

export interface CommandObjectArgumentProps {
  groupProps: ICommandGroupArgumentProps;
  group: IProperty['group'];
  title?: string;
  path: string;
}

export const CommandObjectArgument = (props: CommandObjectArgumentProps) => {
  const { title, path, groupProps, group } = props;

  const isDisabled = useCallback((item: PropertyGroup) => {
    return (
      (item.groupPath?.includes('imager') && item.path === 'name') ||
      (item.groupPath?.includes('platform') && item.path === 'roll_angle')
    );
  }, []);

  return (
    <div className={s.argument__withRemoveBtn}>
      <GenericRemoveButton
        disabled={groupProps.disabled}
        onRemove={groupProps.onRemove}
      />
      <div className={s.argument__withRemoveBtn__content}>
        <div className="font-bold">{title ?? path}</div>
        <div className={s.commandObjectWrapper}>
          {group.map((item) => (
            <CommandGroupArgument
              {...groupProps}
              onRemove={undefined}
              key={item.path ?? ''}
              property={item as IProperty}
              disabled={isDisabled(item)}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export interface CommandArrayArgumentProps {
  groupProps: ICommandGroupArgumentProps;
  title?: string;
  path?: string;
}

export const CommandArrayArgument = (props: CommandArrayArgumentProps) => {
  const { title, path, groupProps } = props;

  const renderGroup = useCallback(
    (renderProps: ICommandGroupArgumentProps) => (
      <CommandGroupArgument {...renderProps} />
    ),
    []
  );

  return (
    <div className={s.argument__withRemoveBtn}>
      <GenericRemoveButton
        disabled={groupProps.disabled}
        onRemove={groupProps.onRemove}
      />
      <div className={s.argument__withRemoveBtn__content}>
        <div className="font-bold">{title ?? path}</div>
        {
          <ArrayField
            {...groupProps}
            className={s.arrayInput}
            handleItemChange={groupProps.onChange}
            renderGroup={renderGroup}
          />
        }
      </div>
    </div>
  );
};

export interface ICommandGroupArgumentProps {
  key?: string;
  property: IProperty;
  value: unknown;
  onChange: (value: unknown, path: string) => void;
  onBlur: () => void;
  onRemove?: () => void;
  handleDisable?: (value: boolean) => void;
  handleMetaButtonClick?: (value: string) => void;
  canBlur?: boolean;
  canSetDefaultValue?: boolean;
  disabled?: boolean;
  hideInputPath?: boolean;
}

const CommandGroupArgument = (props: ICommandGroupArgumentProps) => {
  const { property } = props;

  let content = <CommandPrimitiveArguments {...props} />;

  if (property.type === 'object') {
    content = (
      <CommandObjectArgument
        groupProps={props}
        title={property.optionalFields.title}
        path={property.path}
        group={property.group}
      />
    );
  }

  if (property.type === 'array') {
    content = (
      <CommandArrayArgument
        groupProps={props}
        title={property.optionalFields.title}
        path={property.path}
      />
    );
  }

  return <div className={s.argument__container}>{content}</div>;
};

export default CommandGroupArgument;
