import type { IAstrum } from 'constants/austrum/interfaces';
import type { ThunkAction } from 'redux-thunk';
import type { AppState } from 'store';
import type { Action } from 'redux';
import { updateAstrum } from 'actions/astrum/action';
import { showAlert } from 'services/mapService/alert/services';
import { toaster } from 'toaster';
import { resetStore } from 'actions/resetStore';
import { resetFocus } from 'actions/focus/thunks';
import i18n from 'i18n/client';

export const updateAstrumThunk =
  (
    astrum: IAstrum,
    withReset: boolean,
    key?: string
  ): ThunkAction<void, AppState, null, Action<string>> =>
  async (dispatch, store): Promise<void> => {
    dispatch(resetFocus());
    const stateAstrum = store().astrum.current;
    if (stateAstrum.planet !== astrum.planet && withReset) {
      showAlert(
        (): void => {
          dispatch(resetStore());
          dispatch(updateAstrum(key, astrum));
          const message = i18n.t('alert.switched-to', {
            astrum: astrum.planet,
          });
          toaster.show({ icon: 'warning-sign', intent: 'warning', message });
        },
        i18n.t('alert.switch-astrum'),
        {
          icon: 'satellite',
          confirmButtonText: i18n.t('alert.ok'),
        }
      );
    } else {
      dispatch(updateAstrum(key, astrum));
    }
  };
