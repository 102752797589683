import type { HTMLAttributes } from 'react';

export type Margin = {
  unit?: '%' | 'px' | 'em' | 'vh' | 'vh';
  value: number | 'auto';
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-member-access
const isSingleMargin = (margin: any): margin is Margin => Boolean(margin.unit);

export const getMargin = (
  margin:
    | Margin
    | { top?: Margin; bottom?: Margin; left?: Margin; right?: Margin }
): HTMLAttributes<HTMLHeadingElement>['style'] => {
  if (isSingleMargin(margin)) {
    return { margin: `${margin.value}${margin.unit ?? ''}` };
  }

  return {
    marginBottom:
      margin.bottom && `${margin.bottom.value}${margin.bottom.unit ?? ''}`,
    marginTop: margin.top && `${margin.top.value}${margin.top.unit ?? ''}`,
    marginLeft: margin.left && `${margin.left.value}${margin.left.unit ?? ''}`,
    marginRight:
      margin.right && `${margin.right.value}${margin.right.unit ?? ''}`,
  };
};
