import React from 'react';
import type { FieldAttributes } from 'formik';
import { ErrorMessage, Field } from 'formik';
import styles from './styles.module.scss';

type IProps = {
  noIcon?: boolean;
  width?: number;
} & FieldAttributes<any>;

const FieldWithErrorCallout = ({ width = 300, ...props }: IProps) => (
  <div className={styles.column}>
    <Field {...props} />
    <ErrorMessage
      name={props.name}
      render={(message) => (
        <div
          className={`bp4-callout bp4-intent-danger ${
            props.noIcon ? '' : 'bp4-icon-error'
          }`}
          style={width ? { width: width } : { flex: 1 }}
        >
          {message}
        </div>
      )}
    />
  </div>
);

export default FieldWithErrorCallout;
