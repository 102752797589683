import { Pre } from '@blueprintjs/core';
import { Field, ErrorMessage } from 'formik';
import FieldWithErrorCallout from 'components/formik/FieldWithErrorCallout';
import type { IFormValues } from './ModeForm';
import {
  TWO_TARGET_SECONDARY_TARGET_KEY,
  TWO_TARGET_PRIMARY_TARGET_KEY,
} from 'components/modesPanel/constants';
import styles from 'components/modesPanel/styles.module.scss';
import { GROUND_STATION_PASS_MODE } from 'constants/satellite/constants';

export const AVAILABLE_POINTING_TARGETS = [
  'Sun',
  'Nadir',
  'Velocity',
  'Ground Station',
];

// Not used anymore but might be in the future
// const filterSecondaryTagets = (values: IFormValues) => {
//   const primaryTarget = values['two-target-primary-target'];
//   AVAILABLE_POINTING_TARGETS.filter(target => target !== primaryTarget);
// };

const TwoTargetAttitudeForm = ({ values }: { values: IFormValues }) => {
  let availableTargets = AVAILABLE_POINTING_TARGETS;
  if (values.name !== GROUND_STATION_PASS_MODE) {
    availableTargets = AVAILABLE_POINTING_TARGETS.filter(
      (target) => target !== 'Ground Station'
    );
  }

  const secondaryTargets = availableTargets;

  return (
    <>
      <Pre>
        <table
          className={`bp4-html-table bp4-small bp4-fill walker-constellation`}
        >
          <thead>
            <tr>
              <td colSpan={2}>Primary target</td>
            </tr>
          </thead>
          <tbody>
            <tr key={TWO_TARGET_PRIMARY_TARGET_KEY}>
              <td className={styles.labelColumn}>
                <span>Target</span>
              </td>
              <td className={styles.valueColumn}>
                <div className="bp4-select bp4-fill disable-left-padding">
                  <Field as="select" name={TWO_TARGET_PRIMARY_TARGET_KEY}>
                    {availableTargets.map((name) => (
                      <option key={name} value={name}>
                        {name}
                      </option>
                    ))}
                  </Field>
                </div>
              </td>
            </tr>
            <tr key="two-target-primary.x">
              <td className={styles.labelColumn}>
                <span>X</span>
              </td>
              <td className={styles.valueColumn}>
                <FieldWithErrorCallout
                  type="text"
                  name="two-target-primary.x"
                  className={`bp4-input bp4-fill ${styles.field}`}
                  width={200}
                />
              </td>
            </tr>
            <tr key="two-target-primary.y">
              <td className={styles.labelColumn}>
                <span>Y</span>
              </td>
              <td className={styles.valueColumn}>
                <FieldWithErrorCallout
                  type="text"
                  name="two-target-primary.y"
                  className={`bp4-input bp4-fill ${styles.field}`}
                  width={200}
                />
              </td>
            </tr>
            <tr key="two-target-primary.z">
              <td className={styles.labelColumn}>
                <span>Z</span>
              </td>
              <td className={styles.valueColumn}>
                <FieldWithErrorCallout
                  type="text"
                  name="two-target-primary.z"
                  className={`bp4-input bp4-fill ${styles.field}`}
                  width={200}
                />
              </td>
            </tr>
          </tbody>
        </table>
        <ErrorMessage
          name={TWO_TARGET_PRIMARY_TARGET_KEY}
          render={(message) => (
            <div>
              <p
                className={`bp4-callout bp4-intent-danger bp4-icon-error ${styles.wrapTextProperly} ${styles.overrideFont}`}
              >
                {message}
              </p>
            </div>
          )}
        />
      </Pre>
      <Pre>
        <table
          className={`bp4-html-table bp4-small bp4-fill walker-constellation ${styles.table}`}
        >
          <thead>
            <tr>
              <td colSpan={2}>Secondary target</td>
            </tr>
          </thead>
          <tbody>
            <tr key={TWO_TARGET_SECONDARY_TARGET_KEY}>
              <td className={styles.labelColumn}>
                <span>Target</span>
              </td>
              <td className={styles.valueColumn}>
                <div className="bp4-select bp4-fill disable-left-padding">
                  <Field as="select" name={TWO_TARGET_SECONDARY_TARGET_KEY}>
                    {secondaryTargets.map((name) => (
                      <option key={name} value={name}>
                        {name}
                      </option>
                    ))}
                  </Field>
                </div>
              </td>
            </tr>
            <tr key="two-target-secondary.x">
              <td className={styles.labelColumn}>
                <span>X</span>
              </td>
              <td className={styles.valueColumn}>
                <FieldWithErrorCallout
                  type="text"
                  name="two-target-secondary.x"
                  className={`bp4-input bp4-fill ${styles.field}`}
                  width={200}
                />
              </td>
            </tr>
            <tr key="two-target-secondary.y">
              <td className={styles.labelColumn}>
                <span>Y</span>
              </td>
              <td className={styles.valueColumn}>
                <FieldWithErrorCallout
                  type="text"
                  name="two-target-secondary.y"
                  className={`bp4-input bp4-fill ${styles.field}`}
                  width={200}
                />
              </td>
            </tr>
            <tr key="two-target-secondary.z">
              <td className={styles.labelColumn}>
                <span>Z</span>
              </td>
              <td className={styles.valueColumn}>
                <FieldWithErrorCallout
                  type="text"
                  name="two-target-secondary.z"
                  className={`bp4-input bp4-fill ${styles.field}`}
                  width={200}
                />
              </td>
            </tr>
          </tbody>
        </table>
        <ErrorMessage
          name={TWO_TARGET_SECONDARY_TARGET_KEY}
          render={(message) => (
            <div>
              <p
                className={`bp4-callout bp4-intent-danger bp4-icon-error ${styles.wrapTextProperly} ${styles.overrideFont}`}
              >
                {message}
              </p>
            </div>
          )}
        />
      </Pre>
    </>
  );
};

export default TwoTargetAttitudeForm;
