import { useCallback, useEffect, useState } from 'react';
import { isNil } from 'lodash/fp';

import { showErrorMessage } from '../../../utils/common/CommonUtils';
import { useMission } from '../../Missions';
import type { Alert } from 'api/alerts/types';
import {
  deleteSilence,
  getAllSilences,
  getFiringAlerts,
  getSilences as apiGetSilences,
  postSilence,
} from 'api/alertmanager/service';
import type {
  Silence,
  SilenceNameToId,
  UpdateAlertSilenceRequest,
} from 'api/alertmanager/types';
import moment from 'moment';
import { useAuth } from 'services/auth/AuthWrapper';

export type AlertManager = {
  isFetching: boolean;
  isFetchingSilence: boolean;
  alerts: Alert[];
  missionSilence: Silence | undefined;
  alertSilence: Silence | undefined;
  silenceMap: SilenceNameToId;
  numberOfActiveAlerts: number;

  getAlerts: Function;
  getMissionSilence: Function;
  getAlertSilence: Function;
  getSilences: Function;
  silenceRule: Function;
  silenceMission: Function;
  expireMissionSilence: Function;
  expireAlertSilence: Function;
};

export type GetAlertsQueryParams = {
  alertName: string;
  silenced?: boolean | undefined;
  inhibited?: boolean | undefined;
  active?: boolean | undefined;
};

export type GetSilencesQueryParams = {
  alertName: string;
  silenced?: boolean | undefined;
  inhibited?: boolean | undefined;
  active?: boolean | undefined;
};

export const useAlertManager = (): AlertManager => {
  const { currentMissionId } = useMission();
  const { user } = useAuth();

  const [isFetching, setFetching] = useState(false);
  const [isFetchingSilence, setFetchingSilence] = useState(false);
  const [alerts, setAlerts] = useState<Alert[]>([]);
  const [missionSilence, setMissionSilence] = useState<Silence>();
  const [alertSilence, setAlertSilence] = useState<Silence>();
  const [silenceMap, setSilenceMap] = useState<SilenceNameToId>({});

  const getAlertsQuery = useCallback(
    async ({ active, inhibited, silenced }: GetAlertsQueryParams) => {
      const { data: alertGroups } = await getFiringAlerts({
        params: {
          currentMissionId,
          active,
          inhibited,
          silenced,
        },
      });

      const moreThanOneAlert = alertGroups?.length && alertGroups.length > 1;
      return moreThanOneAlert ? alertGroups[0].alerts : [];
    },
    [currentMissionId]
  );

  const getSilencesQuery = useCallback(
    async ({
      alertName,
      active,
      inhibited,
      silenced,
    }: GetSilencesQueryParams) => {
      const { data: silence } = await apiGetSilences({
        params: {
          missionId: currentMissionId,
          silenced,
          active,
          alertName,
          inhibited,
        },
      });

      return silence;
    },
    [currentMissionId]
  );

  const updateSilences = async (
    alertRule: string | undefined,
    days: number
  ) => {
    const startDate = moment().format('YYYY-MM-DDThh:mm:ss') + '.000Z';
    const endDate =
      moment().add(days, 'day').format('YYYY-MM-DDThh:mm:ss') + '.000Z';

    const matchers = [
      {
        name: 'mission',
        value: `${currentMissionId ?? ''}`,
        isRegex: false,
      },
    ];

    if (alertRule) {
      matchers.push({
        name: 'alertname',
        value: alertRule,
        isRegex: false,
      });
    }

    const silences = {
      matchers,
      startsAt: startDate,
      endsAt: endDate,
      createdBy: user?.sub ? user?.sub : '',
      comment: 'Created through OpenApp',
    } as UpdateAlertSilenceRequest;

    await postSilence({ body: silences });
  };

  const getAlerts = useCallback(
    async (dontSetLoading?: boolean) => {
      try {
        !dontSetLoading && setFetching(true);
        const data = await getAlertsQuery({ active: true, alertName: '' });
        setAlerts(data);
        !dontSetLoading && setFetching(false);
      } catch (e) {
        showErrorMessage('Cannot get alerts!');
        !dontSetLoading && setFetching(false);
      }
    },
    [getAlertsQuery]
  );

  const getMissionSilence = useCallback(async () => {
    const newSilence = await getSilencesQuery({
      alertName: '',
      active: true,
    });
    let newSilenceSingle = newSilence?.length ? newSilence[0] : null;
    // Manually need to check this because apparently BE filter is not implemented
    if (newSilenceSingle) {
      newSilenceSingle =
        newSilenceSingle.status.state !== 'expired' ? newSilenceSingle : null;
    }
    if (newSilenceSingle !== null) {
      setMissionSilence(newSilenceSingle);
    }
  }, [getSilencesQuery]);

  const getAlertSilence = async (alertName: string) => {
    setFetchingSilence(true);
    const newSilence = await getSilencesQuery({
      alertName,
      active: true,
    });
    let newSilenceSingle = newSilence?.length ? newSilence[0] : null;
    // Manually need to check this because apparently BE filter is not implemented
    if (newSilenceSingle) {
      newSilenceSingle =
        newSilenceSingle.status.state !== 'expired' ? newSilenceSingle : null;
    }
    if (newSilenceSingle !== null) {
      setAlertSilence(newSilenceSingle);
    }
    setFetchingSilence(false);
  };

  const getSilences = async () => {
    const newSilenceMap: SilenceNameToId = {};
    const { data } = await getAllSilences({});

    data?.map((silence) => {
      if (silence.matchers.length > 1 && silence.status.state !== 'expired') {
        const alertName = silence.matchers[1].value;
        newSilenceMap[alertName] = silence.id;
      }
    });
    setSilenceMap(newSilenceMap);
  };

  const silenceRule = async (alertRule: string, days: number) => {
    try {
      setFetching(true);
      await updateSilences(alertRule, days);
      await getSilences();
      setFetching(false);
    } catch (e) {
      showErrorMessage('Cannot silence!');
      setFetching(false);
    }
  };

  const silenceMission = async (days: number) => {
    try {
      setFetching(true);
      await updateSilences(undefined, days);
      await getMissionSilence();
      setFetching(false);
    } catch (e) {
      showErrorMessage('Cannot silence!');
      setFetching(false);
    }
  };

  const expireMissionSilence = async () => {
    if (missionSilence === undefined) {
      return;
    }
    setFetching(true);
    await deleteSilence({
      params: {
        silenceId: missionSilence.id,
      },
    });
    await getMissionSilence();
    setFetching(false);
  };

  const expireAlertSilence = async () => {
    if (alertSilence === undefined) {
      return;
    }
    setFetching(true);
    await deleteSilence({
      params: {
        silenceId: alertSilence.id,
      },
    });
    await getSilences();
    await getAlerts();
    setFetching(false);
  };

  const initialise = useCallback(async () => {
    try {
      const newAlerts = await getAlertsQuery({ active: true, alertName: '' });
      setAlerts(newAlerts);

      await getAlerts(true);
      await getSilences();
      await getMissionSilence();
    } catch (error) {
      // TODO: handle
    }
  }, [getAlerts, getAlertsQuery, getMissionSilence]);

  const activeSilences = Object.keys(silenceMap);
  const numberOfActiveAlerts = alerts.filter(
    (alert) =>
      alert.labels?.alertname &&
      !activeSilences.includes(alert.labels.alertname)
  ).length;

  useEffect(() => {
    if (!isNil(currentMissionId)) {
      setFetching(true);
      void initialise();
      setFetching(false);
    }
    const intervalId = setInterval(initialise, 10000);
    return () => clearInterval(intervalId);
  }, [currentMissionId, initialise]);

  return {
    isFetching,
    isFetchingSilence,
    alerts,
    missionSilence,
    alertSilence,
    silenceMap,
    numberOfActiveAlerts,
    getAlerts,
    getMissionSilence,
    getAlertSilence,
    getSilences,
    silenceRule,
    silenceMission,
    expireMissionSilence,
    expireAlertSilence,
  };
};
