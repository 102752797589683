import React, {
  createContext,
  useContext,
  useEffect,
  useState,
  useCallback,
  useMemo,
} from 'react';
import { useHistory, useLocation } from 'react-router';
import { BandAlgebraSTACLayer } from 'datacosmos/entities/bandAlgebraLayer';
import { CircleLayer } from 'datacosmos/entities/circleLayer';
import { FieldOfRegardLayer } from 'datacosmos/entities/FieldOfRegardLayer';
import {
  GeoJSONLayer,
  GeoJSONLayerFactory,
} from 'datacosmos/entities/geojsonLayer';
import type { Layer } from 'datacosmos/entities/layer';
import { LayerSourceType } from 'datacosmos/entities/layer';
import { LineLayer } from 'datacosmos/entities/lineLayer';
import { PolygonLayer } from 'datacosmos/entities/polygonLayer';
import { SingleBandSTACLayer } from 'datacosmos/entities/singleBandLayer';
import { SwathLayer } from 'datacosmos/entities/SwathLayer';
import { OpportunityLayer } from 'datacosmos/entities/TaskingOpportunityLayer';
import { StacItem } from 'datacosmos/types/stac-types';
import { CURRENT_VIEWS_VERSION } from 'datacosmos/utils/viewMigrations';
import {
  DEFAULT_VIEW_SCREENSHOT_SIZE,
  stringToScreenshot,
  screenshot,
} from 'utils/screenshot';
import { v1 as uuid } from 'uuid';
import { useMapLayers } from 'datacosmos/stores/MapLayersProvider';
import { useMap } from 'datacosmos/stores/MapProvider';
import { useProjects } from 'datacosmos/stores/ProjectProvider';
import type { View, ViewLayer } from 'api/views/types';
import type { PostViewBody, PutViewBody } from 'api/views/service';
import { getViews, postView, putView, deleteView } from 'api/views/service';
import { useQuery } from 'api/useQuery';
import { SCREENSHOT_PLACEHOLDER } from 'api/views/constants';
import { useViewMode } from 'datacosmos/utils/hooks/useViewMode';
import { useLocalisation } from 'utils/hooks/useLocalisation';
import useLocalStorage from 'utils/hooks/useLocalStorage';
import type { MimeType as ScMimeType } from 'utils/screenshot';

const LAYER_CLASSES = {
  CircleLayer,
  GeoJSONLayer,
  PolygonLayer,
  SingleBandSTACLayer,
  BandAlgebraSTACLayer,
  SwathLayer,
  FieldOfRegardLayer,
  OpportunityLayer,
  LineLayer,
};

export type IViewsContext = ReturnType<typeof useViewsProvider>;

export const ViewsContext = createContext<IViewsContext>(
  null as unknown as IViewsContext
);

export const useViews = () => useContext<IViewsContext>(ViewsContext);

const removeCircularStructureFromLayers = (viewLayers: Layer[]) => {
  return viewLayers.map((l) => {
    const gl = l as GeoJSONLayer<unknown>;
    if (!gl.getLeafletLayerMetadata) {
      return gl as ViewLayer;
    }
    gl.leafletLayerMetadata = undefined;
    return gl as ViewLayer;
  });
};

const createViewLayersClasses = (layers: (ViewLayer | Layer)[]): Layer[] => {
  return layers.map((l: any) => {
    if (!l) return [];

    if (l.item) {
      l.item = Object.assign(new StacItem(l.item), l.item);
    }

    let classInstance: any;

    if (l.layerClass === 'SingleBandSTACLayer') {
      classInstance = new SingleBandSTACLayer(l.item, l.assetKey);
    } else if (l.layerClass === 'BandAlgebraSTACLayer') {
      classInstance = new BandAlgebraSTACLayer(l.item, l.expression);
    } else if (l.layerClass) {
      // eslint-disable-next-line @typescript-eslint/prefer-ts-expect-error
      // @ts-ignore
      classInstance = new LAYER_CLASSES[l.layerClass]();
    } else {
      //fallback to a simple geojson layer to avoid crashes if there are views lacking layerClass still in the db
      const g = l as unknown as GeoJSONLayer<unknown>;
      classInstance = GeoJSONLayerFactory(
        g.sourceType,
        g.name,
        g.data,
        g.metadata,
        g.leafletLayerMetadata,
        g.options
      );
    }
    Object.assign(classInstance, l);

    return classInstance;
  });
};

export type ProviderView = Omit<View, 'layers'> & { layers: Layer[] };

const useViewsProvider = () => {
  const [currentView, setCurrentView] = useState<ProviderView>();
  const [isSaveEnabled, setIsSaveEnabled] = useState<boolean>(false);
  const { layers, addLayer, removeEverythingFromMap } = useMapLayers();
  const { disableEditing, zoomLevel, currentCenter, setZoomAndCenter, mapRef } =
    useMap();
  const [savedMime, setSavedMime] = useLocalStorage<ScMimeType>(
    'screenshotMimeType',
    'image/png'
  );
  const { currentScenario } = useProjects();
  const location = useLocation();
  const history = useHistory();
  const screenshotMimeType = 'image/png';
  const screenshotContainer = mapRef.current?.getContainer();

  const { isMinimal } = useViewMode();
  const { translate } = useLocalisation();

  const {
    data: userViews,
    refetch: refetchViews,
    loading,
  } = useQuery(getViews, {
    initialData: [],
  });

  const views = useMemo(
    () =>
      userViews.map(
        (view): ProviderView => ({
          ...view,
          layers: createViewLayersClasses(view.layers),
        })
      ),
    [userViews]
  );

  const handleViewSave = useCallback(
    async (name: string, description: string) => {
      if (!currentScenario || !zoomLevel || !currentCenter) return undefined;

      const displayableImageString = screenshotContainer
        ? await screenshot(screenshotContainer, {
            mimeType: screenshotMimeType,
            ignore: 'zoom-controls',
            size: DEFAULT_VIEW_SCREENSHOT_SIZE,
          })
        : false;

      if (!displayableImageString) return undefined;

      const newView: PostViewBody = {
        id: uuid(),
        project: currentScenario.id,
        name,
        description,
        layers: removeCircularStructureFromLayers(layers),
        zoom: zoomLevel,
        latitude: { units: 'degrees', value: currentCenter.lat },
        longitude: { units: 'degrees', value: currentCenter.lng },
        version: CURRENT_VIEWS_VERSION,
        screenshot: stringToScreenshot(displayableImageString),
      };

      const newViewId = (await postView({ body: newView })).data;

      if (newViewId) {
        await refetchViews();
        (newView as unknown as ProviderView).layers = createViewLayersClasses(
          newView.layers
        );
        history.push(`${location.pathname}?view=${newView.id}`);
        setCurrentView(newView as unknown as ProviderView);

        return newView;
      }

      setIsSaveEnabled(false);
      return newView;
    },
    [
      currentCenter,
      currentScenario,
      history,
      layers,
      location.pathname,
      refetchViews,
      screenshotContainer,
      zoomLevel,
    ]
  );

  const handleViewOverwrite = useCallback(
    async (name: string, description: string, id: string) => {
      if (!currentScenario || !zoomLevel || !currentCenter) return undefined;

      const displayableImageString = screenshotContainer
        ? await screenshot(screenshotContainer, {
            mimeType: screenshotMimeType,
            ignore: 'zoom-controls',
            size: DEFAULT_VIEW_SCREENSHOT_SIZE,
          })
        : false;

      if (!displayableImageString) return undefined;

      const newView: PutViewBody = {
        id,
        name,
        description,
        project: currentScenario.id,
        layers: removeCircularStructureFromLayers(layers),
        zoom: zoomLevel,
        latitude: { units: 'degrees', value: currentCenter.lat },
        longitude: { units: 'degrees', value: currentCenter.lng },
        version: CURRENT_VIEWS_VERSION,
        screenshot: stringToScreenshot(displayableImageString),
      };

      const putViewQuery = await putView({
        params: { viewId: id },
        body: newView,
      });
      if (putViewQuery.success) await refetchViews();

      setIsSaveEnabled(false);
      return newView as unknown as ProviderView;
    },
    [
      currentCenter,
      currentScenario,
      layers,
      refetchViews,
      screenshotContainer,
      zoomLevel,
    ]
  );

  const initUntitledView = useCallback(() => {
    if (!currentScenario) return;
    if (isMinimal) return;

    const BLANK_VIEW = {
      id: undefined,
      name: translate('datacosmos.header.freeEditingMode'),
      description: '',
      layers: [],
      project: currentScenario.id,
      zoom: 3,
      latitude: { units: 'degrees', value: null },
      longitude: { units: 'degrees', value: null },
      version: CURRENT_VIEWS_VERSION,
      screenshot: {
        mime: 'image/png',
        base64: SCREENSHOT_PLACEHOLDER,
      },
    };

    history.push(location.pathname + location.search);
    setCurrentView(BLANK_VIEW as unknown as ProviderView);
  }, [
    currentScenario,
    isMinimal,
    location.search,
    location.pathname,
    translate,
    history,
  ]);

  const handleViewChange = useCallback(
    (view: ProviderView) => {
      removeEverythingFromMap();
      setIsSaveEnabled(false);
      if (view.layers.length > 0) {
        addLayer(
          ...view.layers.map((layer) => {
            layer.id = uuid();
            return layer as unknown as Layer;
          })
        );
      }
    },
    [addLayer, removeEverythingFromMap]
  );

  const handleViewDelete = useCallback(
    async (viewId: string) => {
      const deleteViewQuery = await deleteView({ params: { viewId } });
      if (deleteViewQuery.success) {
        await refetchViews();
        initUntitledView();

        return true;
      }

      return false;
    },
    [initUntitledView, refetchViews]
  );

  useEffect(() => {
    if (location.search && location.search !== '') {
      const viewId = new URLSearchParams(location.search).get('view');

      const foundView = views.find(({ id }) => id === viewId);

      if (foundView) {
        handleViewChange(foundView);
        setCurrentView(foundView);
      }
    }
  }, [handleViewChange, location.search, views]);

  useEffect(() => {
    //Don't show save icon if image outlines/guidelines are visible

    if (!currentView) return;

    const shouldUpdate = layers.every(
      (l) => l.sourceType !== LayerSourceType.ASSET_OUTLINE
    );

    if (!shouldUpdate) return;

    const viewLayers = currentView.layers;

    const differentLayers = layers
      .filter((l) => l.sourceType !== LayerSourceType.ASSET_OUTLINE)
      .filter((l) => !viewLayers.some((vl) => vl.id === l.id));

    if (differentLayers.length > 0 || viewLayers.length !== layers.length) {
      setIsSaveEnabled(true);
    }
  }, [currentView, layers]);

  useEffect(() => {
    if (
      currentView &&
      currentCenter?.lat === currentView.latitude.value &&
      currentCenter.lng === currentView.longitude.value &&
      zoomLevel === currentView.zoom
    ) {
      return;
    }
    setIsSaveEnabled(true);
  }, [currentCenter?.lat, currentCenter?.lng, currentView, zoomLevel]);

  useEffect(() => {
    if (!currentView) {
      initUntitledView();
    }
  }, [currentView, initUntitledView]);

  useEffect(() => {
    if (currentView) {
      setZoomAndCenter(
        currentView.latitude.value,
        currentView.longitude.value,
        currentView.zoom
      );
    }
  }, [currentView, handleViewChange, setZoomAndCenter, isMinimal]);

  useEffect(() => {
    layers.map((l) => {
      if ((l as GeoJSONLayer<unknown>).leafletLayerMetadata !== undefined) {
        disableEditing((l as GeoJSONLayer<unknown>).leafletLayerMetadata);
      }
    });
  }, [disableEditing, layers]);

  return {
    handleViewOverwrite,
    handleViewSave,
    isSaveEnabled,
    views,
    currentView,
    setCurrentView,
    handleViewDelete,
    initUntitledView,
    handleViewChange,
    loading,
    savedMime,
    setSavedMime,
  };
};

export const ViewsProvider = ({ children }: { children: React.ReactNode }) => {
  return (
    <ViewsContext.Provider value={useViewsProvider()}>
      {children}
    </ViewsContext.Provider>
  );
};
