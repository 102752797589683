import { Dialog, Classes, Label, Intent, Spinner } from '@blueprintjs/core';
import React, { useState } from 'react';
import { Formik } from 'formik';
import { toaster } from 'toaster';
import Input from '_molecules/Input/Input';
import Button from '_molecules/Button/Button';
import type { Scenario } from 'api/scenarios/types';
import { putScenario } from 'api/scenarios/service';
import { useLocalisation } from 'utils/hooks/useLocalisation';
import { Select2 } from 'opencosmos-ui';
import { Item } from 'react-stately';
import { useMission } from 'services/Missions';

type EditScenarioModalProps = {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setUserScenarios: () => void;
  detailedScenario?: Scenario;
  setCurrentScenario: React.Dispatch<
    React.SetStateAction<Scenario | undefined>
  >;
  setCurrentProject: (project: Scenario) => void;
};

export const EditScenarioModal = ({
  isOpen,
  setIsOpen,
  detailedScenario,
  setUserScenarios,
  setCurrentScenario,
  setCurrentProject,
}: EditScenarioModalProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { translate } = useLocalisation();
  const { organisations } = useMission();

  const mappedOrganisation = organisations?.find(
    (org) =>
      org.id?.toString() ===
      detailedScenario?.summaries?.['opencosmos:organisation_id']
  );
  return (
    <Dialog
      isOpen={isOpen}
      onClose={() => setIsOpen(false)}
      canOutsideClickClose={false}
      className="dark:bg-surface-dark dark:text-item-dark-contrast bg-surface"
    >
      <Formik
        initialValues={{
          name: detailedScenario?.title,
          description: detailedScenario?.description,
          organisation: detailedScenario?.summaries
            ? mappedOrganisation?.name
            : '',
        }}
        onSubmit={async (values, { setErrors, resetForm }) => {
          if (!detailedScenario) {
            return;
          }
          if (!values.name) {
            setErrors({
              name: translate('datacosmos.editProjectDialog.errors.name'),
            });
            return;
          }

          if (!values.description) {
            setErrors({
              description: translate(
                'datacosmos.editProjectDialog.errors.description'
              ),
            });
            return;
          }

          if (!values.organisation) {
            setErrors({
              organisation: translate(
                'datacosmos.editProjectDialog.errors.organisation'
              ),
            });
            return;
          }

          const selectedOrganisation = organisations?.find(
            (org) => org.name === values.organisation
          );
          if (!selectedOrganisation) {
            return;
          }

          setIsLoading(true);
          const { data } = await putScenario({
            params: { scenarioId: detailedScenario.id },
            body: {
              name: values.name,
              description: values.description,
              organisation_id: selectedOrganisation?.id,
            },
          });
          setIsLoading(false);

          if (!data) return;

          setCurrentProject(data);
          setCurrentScenario(data);
          setUserScenarios();
          setIsOpen(false);
          resetForm();

          toaster.show({
            intent: Intent.SUCCESS,
            message: translate(
              'datacosmos.editProjectDialog.successfullyEdited',
              {
                name: detailedScenario?.title,
              }
            ),
          });
        }}
      >
        {({ values, handleChange, handleSubmit, errors, setFieldValue }) => (
          <>
            <div className="bp4-dialog-header dark:bg-surface-dark bg-surface">
              <h5 className="dark:text-item-dark-contrast bp4-heading">
                {translate('datacosmos.editProjectDialog.title')}
              </h5>
              <button
                aria-label="Close"
                className="bp4-dialog-close-button bp4-button bp4-minimal bp4-icon-cross"
                onClick={() => setIsOpen(false)}
              />
            </div>
            <div className={Classes.DIALOG_BODY}>
              <Label>
                <Input
                  type="text"
                  name="name"
                  value={values.name}
                  label={{
                    text: translate('datacosmos.editProjectDialog.name'),
                    position: 'top',
                  }}
                  onChange={handleChange}
                />
                <small style={{ color: 'red' }}>
                  {errors.name?.toString()}
                </small>
              </Label>
              <Label>
                <Select2
                  selectedItem={values.organisation}
                  onSelectionChange={(val) => {
                    void setFieldValue('organisation', val);
                  }}
                  placeholder="Select organisation"
                  fill
                  label="Organisation"
                  name="organisation"
                >
                  {organisations?.map((org) => (
                    <Item key={org.name}>{org.name}</Item>
                  ))}
                </Select2>
                <small style={{ color: 'red' }}>
                  {errors.organisation?.toString()}
                </small>
              </Label>
              <Label>
                <Input
                  type="textarea"
                  name="description"
                  value={values.description}
                  label={{
                    text: translate('datacosmos.editProjectDialog.description'),
                    position: 'top',
                  }}
                  onChange={handleChange}
                />
                <small style={{ color: 'red' }}>
                  {errors.description?.toString()}
                </small>
              </Label>
            </div>
            <div className={Classes.DIALOG_FOOTER}>
              <div className={Classes.DIALOG_FOOTER_ACTIONS + ' gap-2'}>
                <Button
                  text={translate('datacosmos.buttons.cancel')}
                  onPress={() => {
                    setIsOpen(false);
                  }}
                />
                <Button
                  text={
                    isLoading ? (
                      <Spinner size={20} />
                    ) : (
                      translate('datacosmos.buttons.save')
                    )
                  }
                  onPress={() => handleSubmit()}
                />
              </div>
            </div>
          </>
        )}
      </Formik>
    </Dialog>
  );
};
