import type { Action } from 'redux';
import type { ThunkAction } from 'redux-thunk';
import { uploadLaunchOpportunities } from './action';
import type { ILOState } from '../../constants/constraintsTable/actionTypesLaunchOpportunity';
import type { AppState } from '../../reducers/rootReducer';

export const uploadLO =
  (
    launchOpportunities: ILOState
  ): ThunkAction<void, AppState, null, Action<string>> =>
  (dispatch) => {
    dispatch(uploadLaunchOpportunities(launchOpportunities));
  };
