import {
  routeProjectItems,
  routeParamProjectId,
  routeCatalog,
  routeTasking,
  routeApplication,
  routeApplicationStatus,
} from 'datacosmos/components/routePath';
import type { Scenario } from 'api/scenarios/service';

const REDIRECT_FROM = [
  'scenario',
  'catalog',
  'tasking',
  'application',
  'items',
  'item',
  'status',
];

const formatNonItemRoutes = (
  redirectTo: string[],
  intersection: string[],
  currentProject: Scenario
) => {
  const route = redirectTo
    .filter((r) => r.includes(intersection.join('/')))
    .find((r) => {
      return intersection.length === 1
        ? r.split('/').filter((_) => _ !== '').length <= 4
        : r.split('/').filter((_) => _ !== '').length > 4;
    });

  return route
    ? route.replace(':' + routeParamProjectId, currentProject.id)
    : location.pathname;
};

export const rerouteHandler = (currentProject: Scenario) => {
  const REDIRECT_TO = [
    routeProjectItems,
    routeApplication,
    routeCatalog,
    routeTasking,
    routeApplicationStatus,
  ];

  const pathnameArray = location.pathname.split('/').filter((p) => p !== '');

  const intersection = REDIRECT_FROM.filter((r) =>
    pathnameArray.some((p) => p === r)
  );

  const formatedRoute = formatNonItemRoutes(
    REDIRECT_TO,
    intersection,
    currentProject
  );

  return { pathname: formatedRoute, search: location.search };
};
