const esTranslation = {
  common: {
    project_name: 'PROJECTNAME',
    lorem_ipsum:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut vel lacus diam. Nunc egestas, elit ut pretium dapibus, nisi purus hendrerit velit, et tincidunt turpis massa non ante. Donec eget nunc tempor, ultricies libero pulvinar, faucibus dolor.',
  },
  currentTime: 'Hora actual',
  validation: {
    header: {
      GS: 'Estaciones Terrestres',
      PI: 'Puntos de Interés',
      RI: 'Region de Interés',
    },
    point: {
      lat: 'Latitud',
      lng: 'Longitud',
    },
    'paths-field': {
      regions: 'Regiones',
      draw_title: 'Dibuje nueva región utilizando el gestor de dibujo',
    },
    'path-field': {
      region: 'Región',
    },
    'text-field': {
      names: {
        name: 'Nombre',
        altitude: 'Altitud',
        elevationAngle: 'Ángulo de Elevación',
        tle1: 'TLE1',
        tle2: 'TLE2',
        date: 'Fecha',
        semimajorAxis: 'Semieje Mayor',
        eccentricity: 'Excentricidad',
        inclination: 'Inclinación',
        rightAscensionOfAscendingNode: 'Longitud del Nodo Ascendente',

        argumentOfPerigee: 'Argumento del Periastro',
        trueAnomaly: 'Anomalía verdadera',
      },
    },
    errors: {
      type_not_empty: 'El tipo no debe dejarse vacío.',
      name_not_empty: 'El nombre no debe dejarse vacío.',
      name_not_unique: 'El nombre debe ser único.',
      self_intersecting: 'El trazo del polígono se cruza a sí mismo.',
      lat_error: 'La latitud debe contenerse dentro de los límites [-90,90].',
      lng_error:
        'La longitud debe contenerse dentro de los límites [-180,180].',
      alt_error: 'La altitud debe ser mayor a 0.',
      poi_elevation_ang_error:
        'El ángulo de elevación debe contenerse dentro de los límites [0,90].',
      roi_elevation_ang_error:
        'El ángulo de elevación debe contenerse dentro de los límites[15,90].',
      error: 'Error.',
      semimajorAxis: 'Debe contenerse dentro de los límites [6800, 1000000].',
      degrees: 'Debe contenerse dentro de los límites [-360, 360].',
      eccentricity: 'Debe contenerse dentro de los límites [0, 1].',
      tle: 'El TLE no debe dejarse vacío.',
      criterion_error: 'Elija el criterio Min|Max.',
      object_error: 'Debe seleccionar un objeto.',
      objective_name:
        'Uno de los objetos tiene un nombre incorrecto. Compare con la tabla de eventos.',
      periapsis_error:
        'El semieje mayor/excentricidad debe generar una altitud de periastro',
      apoapsis_error:
        'El semieje mayor/excentricidad debe generar una altitud de apoastro ',
    },
    save_title: 'Guarde el elemento',
  },
  module_msd: {
    module_name: 'Diseño de Misión',
    step_payload_configurator: 'Carga Útil',
    step_mission_geometry: 'Geometría de la Misión',
    step_ground_segment: 'Segmento Terrestre',
    step_satellite_configurator: 'Plataforma',
    step_modes: 'Modos',
    payload_definition: {
      detailed_title: 'Características de la Carga Útil',
      detailed_view: {
        general_tab_title: 'General',
        physical_tab_title: 'Física',
        thermal_tab_title: 'Térmica',
        modes_tab_title: 'Modos',
        general_title: 'Características Generales',
        physical_title: 'Características Físicas',
        thermal_title: 'Características Térmicas',
        modes_title: 'Modos Operativos',
      },
    },
    mission_geometry: {
      orbit: 'Órbita',
      orbit_plural: 'Órbitas',
    },
    ground_segment: {
      save: 'Guarde Segmento Terrestre',
    },
    satellite: {
      modes: {
        single: 'Modo',
        plural: 'Modos',
        full: 'Modo Operativo del Satélite',
        satellite_mode: 'Modo del Satélite',
      },
    },
  },
  module_ops: {
    module_name: 'Operciones',
    telemetry: 'Visualizador de Telemetría',
    library: 'Biblioteca',
    gss: 'Planificador de Estación Terrestre',
    rti: 'Interfaz a Tiempo Real',
    scripting: 'Secuencias de Comandos',
    scheduling: 'Horario',
  },
  module_pdgs: {
    module_name: 'Procesando',
    processing: 'Procesando Carga Útil',
  },
  module_data: {
    module_name: 'DataCosmos',
    search: 'Buscar',
    scenario: 'Elementos del Proyecto',
    application: 'Aplicaciones',
    tasking: 'Planificación',
    docs: 'Documentación',
    views: 'Visitas',
    tasking_overview: 'Descripción General de la Planificación',
  },
  'Tabla-de-eventos': {
    header: {
      type: 'Tipo',
      name: 'Nombre',
    },
    types: {
      PI: 'Punto',
      RI: 'Región',
    },
  },
  constraints: {
    GENERIC_CONSTELLATION: {
      title: 'Constelación Genérica',
      header: {
        Satellites: 'Satélites',
        Name: 'Nombre',
        Orbit_Definition: 'Definición de Órbita',
      },
      satellite: {
        orbit_type: 'Tipo de Órbita',
        type: {
          KEPLERIAN: 'Elementos Keplerianos',
          TLE: 'TLE',
        },
      },
      form_title: 'Satélite:',
    },
    LAUNCH_OPPORTUNITIES: {
      title: 'Oportunidades de Lanzamiento',
    },
    WALKER_CONSTELLATION: {
      title: 'Constelación Walker',
      altitude: 'Altitud',
      numberOfSatellites: 'Número de Satélites',
      numberOfPlanes: 'Número de Planos',
      inclination: 'Inclinación',
      Parameter: 'Parametro',
      Value: 'Valor',
      Optimise: 'Optimizar',
      Boundaries: 'Límites',
      measurement: {
        m: '[m]',
        km: '[km]',
        deg: '[grad]',
        h: '[h]',
      },
      errors: {
        value: 'Valor Incorrecto!',
      },
    },
  },
  objectives: {
    title: 'Objetivos',
    form_title: 'Objetivo de Optimización:',
    table: {
      name: 'Nombre',
      type: 'Tipo',
      criterion: 'Criterio',
      max: 'Maximizar',
      min: 'Minimizar',
    },
    types: {
      title: 'Tipos de Objetivo',
      mission_metric: 'Métrica de la Misión',
      ground_segment_event: 'Evento de Segmento Terrestre',
      point_of_interest_event: 'Evento de Punto de Interés',
      region_of_interest_event: 'Evento de Región de Interés',
    },
    object: {
      title: 'Objeto',
      all_ground_stations: 'Todas las Estaciones Terrestres',
    },
    event: {
      title: 'Evento',
      visibility: 'Visibilidad',
      illuminated_visibility: 'Visibilidad Iluminada',
    },
    scope: {
      title: 'Alcance',
      coverage: 'Covertura',
      revisit: 'Volver a visitar',
    },
    metric: {
      title: 'Métrica',
      number_of_satellites: 'Número de Satélites',
      number_of_planes: 'Número de Planos',
      number_of_ground_stations: 'Número de Estaciones Terrestres',
      incidence: 'Incidencia [%]',
      avg_duration: 'Duración Media [s]',
      max_duration: 'Duración Máxima  [s]',
      min_duration: 'Duración Mínima  [s]',
      duration_std_deviation: 'Duración de Desviación Estándar [s]',
      average: 'Duración Media [s]',
      maximum: 'Duración Máxima [s]',
      minimum: 'Duración Mínima  [s]',
      std: 'Duración de Desviación Estándar [s]',
    },
  },
  SOSTable: {
    launchOpportunities: {
      id: 'ID',
      launcher: 'Lanzadera',
      date: 'Fecha',
      altitude: 'Altitud [km]',
      inclination: 'Inclinación [grad]',
      ltan: 'LTAN [h]',
      sso: 'SSO',
    },
    groundStations: {
      name: 'Nombre',
      custom: 'Personalizado',
      elevationAngle: 'Elevación mínima',
      lat: 'Latitud [grad]',
      lon: 'Longitud [grad]',
      altitude: 'Altitud [m]',
    },
    select: 'Seleccionar',
    optimise: 'Optimizar',
    yes: 'Sí',
    no: 'No',
  },
  results: {
    title: 'Tabla de resultados',
    cancel: 'Cancelar',
    close: 'Cerrar',
    save: 'Guardar',
    json: 'JSON',
    parameter: 'Parámetro',
    objective: 'Objetivo',
  },
  simulate: {
    title: 'Progreso',
    cancel: 'Cancelar',
    close: 'Cerrar',
    save: 'Guardar',
    json: 'JSON',
  },
  optimization_table: {
    optimize: 'Optimizar',
    save: 'JSON',
  },
  events_table: {
    type: 'Tipo',
    name: 'Nombre',
  },
  alert: {
    ok: 'OK',
    cancel: 'Cancelar',
    remove: 'Eliminar',
    'cambiar-astro':
      'Está cambiando de astro. Los eventos, objetivos, y órbitas serán reiniciados ¿Desea Proceder?',
    'cambiado-a': 'Cambiado a {{astrum}}',
  },
  geometry: {
    satelliteMode: 'Modo de Satélite',
    satellitesPerPlane: 'Satélites por plano',
    firstPlaneRaan: 'Primer plano RAAN',
    relativeSpacing: 'Espaciado relativo',
    type: 'Tipo',
    parameterisation: 'Parameterización',
    tle_objects_label: 'Seleccione objeto',
    KEPLERIAN: 'Kepleriano',
    STATIONARY: 'Estacionario',
    SYNCHRONOUS: 'Sincrónico',
    POLAR: 'Polar',
    TLE: 'TLE',
    WALKER_CONSTELLATION: 'Constelación Walker',
    ltan: 'LTAN',
    raan: 'RAAN',
    semimajorAxis: 'Semieje Mayor',
    eccentricity: 'Excentricidad',
    inclination: 'Inclinación',
    longitude: 'Longitud',
    trueAnomaly: 'Anomalía Verdadera',
    rightAscensionOfAscendingNode: 'Longitud del nodo ascendente',
    argumentOfPerigee: 'Argumento de Periastro',
    altitude: 'Altitud',
    numberOfSatellites: 'Número de satélites',
    numberOfPlanes: 'Número de planos',
    mission_metric: 'Métrica de misión',
    ground_segment_event: 'Evento de Segmento Terrestre',
    point_of_interest_event: 'Evento de Punto de Interés',
    region_of_interest_event: 'Evento de Región de Interés',
    save: 'Guardar Geometría de Misión',
    measurement: {
      m: '[m]',
      km: '[km]',
      deg: '[grad]',
      rad: '[rad]',
      h: '[h]',
    },
    errors: {
      value: 'Valor Incorrecto!',
    },
  },
  api: {
    error:
      'Error de simulación, por favor envíe su entorno de ejecución a {{email}}',
  },
  userPage: {
    addResource: 'Añadir recurso',
    resource: 'Recurso',
    role: 'Rol',
    users: 'Usuarios',
    showHiddenRoles: 'Mostrar roles ocultos',
    roles: 'Roles',
    searchPlaceholder:
      'Busque usuarios por nombre o correo electrónico (por ejemplo: John Doe, *@xyz.com)',
    buttons: {
      add: 'Agregar',
    },
    assignedPermissions: {
      title: 'Permisos asignados',

      placeholders: {
        resourceId: 'Ingrese el ID del recurso',
        resourceName: 'Buscar por nombre del recurso o ID del recurso',
        resourceType: 'Buscar por tipo de recurso',
      },
      areYouSureYouWantToRemove:
        '¿Está seguro de que deseas eliminar TODOS los permisos para el recurso seleccionado?',

      selectARole: 'Seleccionar un rol',

      success: {
        added: 'El rol se ha añadido con éxito',
      },

      errors: {
        notEmpty: 'El ID del recurso no debe estar vacío',
        noRole: 'Seleccionar un rol',
        noResultsForResourceType: 'No hay resultados para el tipo de recurso',
        cannotSaveEmptyUser: 'No se puede guardar el usuario vacío',
        cannotSaveUserwithoutRole: 'No se puede guardar el usuario sin rol',
      },
    },
  },

  rolesPage: {
    viewAttachments: 'Vista rol adjunto',
    closeAttachments: 'Cerca rol adjunto',
    addAttachment: 'Añadir role adjunto',
  },

  datacosmos: {
    header: {
      freeEditingMode: 'Modo de edición libre',
      sendFeedback: 'Enviar Comentarios',
      upgradePermission: 'Mejora',
      group: 'Grupo',

      menu: {
        orders: 'Pedidos',
        theme: {
          title: 'Tema',
          dark: 'Oscuro',
          light: 'Claro',
          auto: 'Auto',
        },
        rolesManager: 'Editor de Roles',
        users: 'Usuarios',
        logout: 'Cerrar sesión',
        taskingOverview: 'Descripción General de la Planificación',
        matomo: 'Matomo panel',
        grafana: 'Grafana supervisión',
        keycloak: 'Keycloak panel',
      },
    },
    bottomBar: {
      coordinates: 'Coordenadas',
      selectedCoordinateSystem: 'Sistema de coordenadas seleccionado',
    },
    map: {
      dark: 'Oscuro',
      light: 'Claro',
      satellite: 'Mapbox Satélite',
    },
    fetchErrors: {
      views: {
        cannotGet: 'No se pudo obtener visitas',
        cannotPut: 'No se pudo insertar visitas',
        cannotDelete: 'No se pudo eliminar visitas',
        cannotPost: 'No se pudo enviar visitas',
        noViewsAvailable: 'No hay vistas disponibles para esta consulta',
      },
      activities: {
        cannotGetByMissionId:
          'No se pudo obtener actividades mediante la ID de la misión',
        cannotPatch: 'No se pudo parchear la actividad',
      },
      scenarios: {
        cannotGetMultiple: 'No se pudieron obtener escenarios',
        cannotGetOne: 'No se pudo obtener el escenario',
        cannotPut: 'No se pudo insertar el escenario',
        cannotDelete: 'No se pudo eliminar el escenario',
        cannotPost: 'No se pudo enviar el escenario',
        cannotAddToScenario: 'No se pudo añadir tiempo al escenario',
        cannotRemoveFromScenario: 'No se pudo eliminar el ítem del escenario',
        cannotSearch: 'No se pudo buscar escenarios',
      },
      stac: {
        cannotSearch: 'Error de búsqueda en el catálogo DataCosmos',
      },
      tasking: {
        cannotCreate: 'No se pudo crear una nueva solicitud de tarea',
        cannotGet: 'No se pudo obtener una nueva solicitud de tarea',
        cannotPatch: 'No se pudo parchear una nueva solicitud de tarea',
        cannotGetById:
          'No se pudo obtener la solicitud de tarea mediante la ID del proyecto',
        cannotPost:
          'No se pudo publicar una nueva solicitud de búsqueda de franjas',
        cannotPostManualTasking:
          'No se pudo publicar una nueva solicitud de búsqueda de tareas manuales',
        cannotGetAll: 'No se pudieron recibir solicitudes de tareas',
      },
      osm: {
        cannotGet: 'No se pudo obtener la información de la región',
      },
      samplingPixels: {
        cannotGet: 'No se pudieron obtener los píxeles del elemento STAC',
      },
      orders: {
        price: {
          cannotGet: 'No se pudo obtener el precio de la imagen',
        },
        orderList: {
          cannotGet: 'No se puede obtener la lista de pedidos',
        },
        customers: {
          cannotGet: 'No se pudo obtener el cliente por ID',
        },
        order: {
          cannotPost: 'No se pudo crear el pedido',
          cannotPostPreferences: 'No se pudieron crear preferencias',
          cannotPatch: 'No se pudo actualizar el pedido',
          noOrderSelected: 'No se seleccionó ningún pedido',
          noOrderSelectedDescription: 'Seleccione un pedido para verlo aquí',
          noOrdersFound: 'No se encontraron pedidos',
          noOrdersFoundFiltersDescription:
            'No se encontraron pedidos con los filtros actuales',
          noOrdersFoundDescription: 'No pudimos encontrar ningún pedido.',
        },
        checkout: {
          cannotGetURL: 'No se pudo checkout URL',
        },
      },
      geopdf: {
        postNetCDF: 'No se pudo cargar el archivo',
      },
    },
    fetchSuccesses: {
      activities: {
        successfullyPatched: 'Actividad actualizada exitosamente',
      },
      orders: {
        successfullyCreated: 'Orden creada exitosamente',
      },
      postNetCDFFile: {
        successfullyUploaded: 'Archivo subido exitosamente',
      },
    },

    upgradePermissionsDialog: {
      upgradePermissionsHeader: 'Actualización a nivel de usuario intermedio',
      permissionsBody:
        'Gracias por utilizar la plataforma de geo-información de CONIDA. Desafortunadamente, su nivel de usuario actual no le permite ver imágenes en alta resolución. Necesitamos un poco más de información sobre usted para actualizar su acceso y permitirle el uso de datos en alta resolución.',
      typeOfUser:
        'Por favor, seleccione el tipo de organización para la que trabaja:',
      userType: {
        institutional: 'Instituciones públicas',
        institutionalTooltipText:
          ' Organizaciones que forman parte del gobierno peruano',
        private: 'Entidades privadas',
        privateTooltipText: 'Empresas y particulares peruanos',
      },
      sendButtonText: 'Solicitar mejora de usuario',
      sendEmailText: 'Enviar correo electrónico',
      downloadAndFillDocument: `${'Para continuar, por favor lea la <a href="https://cdn.www.gob.pe/uploads/document/file/1225819/dir_014_compressed__1_.pdf?v=1597110644" target="_blank" rel="noreferrer" class="font-bold underline text-accent"> Directiva</a>. Una vez completada, descargue, imprima, firme y envíe el siguiente <a href="https://cdn.www.gob.pe/uploads/document/file/1299316/Anexo%205.3.pdf?v=1599794505" target="_blank" rel="noreferrer" class="font-bold underline text-accent"> documento</a> a la dirección de correo electrónico: atencionalcliente@conida.gob.pe. Asegúrese de utilizar el título "Solicitud para actualizar a usuario intermedio" e incluya las direcciones de correo electrónico de los usuarios.'}`,
    },

    viewsDialog: {
      returnToFreeEditingMode: 'Volver al modo de edición libre',
      views: 'Visitas',
      sharedWith: 'Compartido con: {{with}}',
      createdAt: 'Creado en: {{at}}',
      lastUpdated: 'Actualizado por última vez: {{at}}',
      openView: 'Abrir vista',
      deleteView: 'Eliminar vista',
      shareView: 'Compartir vista',
      createGeoPDF: 'Crear GeoPDF',
      geoPDFHeader: 'Ingrese los detalles de GeoPDF',
      nonTiffWarning:
        'Las capas sin recursos .tiff se excluirán de la descarga',
    },
    optionsDialog: {
      changeFormat: 'Cambiar formato',
    },
    saveViewDialog: {
      overwrite: 'Sobrescribir vista {{name}}',
      save: 'Guardar como vista nueva',
      inputs: {
        name: 'Nombre:',
        namePlaceholder: 'Nombre de la vista...',
        description: 'Descripción:',
        descriptionPlaceholder: 'Ver descripción...',
      },
    },
    projectsDialog: {
      title: 'Abrir un proyecto',
      openProject: 'Abrir proyecto',
      deleteProject: 'Cerrar proyecto',
      editProject: 'Editar proyecto',
      shareProject: 'Compartir proyecto',
      createNewProject: 'Crear nuevo proyecto',
      organisation: 'Organización',
    },
    deleteViewDialog: {
      areYouSure: '¿Estás seguro de que quieres eliminar la vista?',
      longWarning:
        'Está a punto de eliminar esta vista y perderá todas las capas guardadas. Esto no se puede deshacer. Escriba "eliminar" en el cuadro a continuación y haga clic en "Confirmar" si está seguro de que desea continuar.',
    },
    deleteProjectDialog: {
      warning: 'ALERTA!',
      areYouSure:
        '¿Está seguro de que quiere eliminar el proyecto? {{project}}?',
      longWarning:
        'Está a punto de eliminar este proyecto y perderá los enlaces a todas las imágenes del proyecto. Esto no se puede deshacer. Escriba "eliminar" en el cuadro a continuación y haga clic en "Confirmar" si está seguro de que desea continuar.',
      errors: {
        deletionFailed: 'Error al eliminar',
      },
      success: 'Proyecto eliminado exitosamente',
      placeholder: "Escriba 'eliminar' para confirmar su elección",
    },
    editProjectDialog: {
      title: 'Editar proyecto',
      name: 'Nombre',
      description: 'Descripción',
      labels: 'Etiquetas',
      key: 'Clave',
      value: 'Valor',
      keyPlaceholder: 'Clave del proyecto',
      valuePlaceholder: 'Valor de la clave',
      errors: {
        name: 'El nombre no debe estar vacío.',
        description: 'La descripción no debe estar vacía.',
        organisation: 'La organización no debe estar vacía',
      },
      successfullyEdited: 'El proyecto {{name}} se actualizó correctamente',
    },
    addNewProjectDialog: {
      name: 'Nombre',
      description: 'Descripción',
      labels: 'Etiquetas',
      key: 'Clave',
      value: 'Valor',
      keyPlaceholder: 'Clave del proyecto',
      valuePlaceholder: 'Valor de la clave',
      errors: {
        name: 'El nombre no debe estar vacío',
        description: 'La descripción no debe estar vacía',
        organisation: 'La organización no debe estar vacía',
      },
      successfullyCreated: 'El proyecto {{name}} se actualizó correctamente',
    },
    buttons: {
      cancel: 'Cancelar',
      save: 'Guardar',
      confirm: 'Confirmar',
      overwrite: 'Sobrescribir',
      draw: 'Dibujar',
      update: 'Actualizar',
      purchase: 'Comprar',
      request: 'Solicitar',
      upload: 'Subir',
      enable: 'Activar',
      disable: 'Desactivar',
      completeOrder: 'Completar orden',
      payByCard: 'Pague con tarjeta de crédito o débito',
      payByBank: 'Pagar mediante transferencia bancaria',
      accept: 'Aceptar',
      filters: 'Filtros',
      closeFilters: 'Cerrar Filtros',
      drawAoiForStatistics: 'Dibujar AoI para estadísticas',
      removeAoiForStatistics: 'Eliminar AoI',
      searchByRegion: 'Buscar por región',
      searchByPoint: 'Buscar por punto',
      backToSearch: 'Volver a buscar',
      resetAndClose: 'restablecer y cerrar',
      close: 'Cerca',
    },
    catalogAndItems: {
      catalog: 'Catálogo',
      group: 'Grupo',
      moreInfo: 'Más información',
      project: 'Proyecto',
      buyFullResImages: 'Comprar imágenes en resolución completa',
      metadata: {
        sensors: 'Sensores: ',
        resolution: 'Resolución: ',
        minPixelValue: 'Mínimo:',
        maxPixelValue: 'Máximo:',
        meanValue: 'Media:',
        stdValue: 'Desviación Estándar:',
        variance: 'Diferencia:',
        percentile25: 'percentil 25:',
        percentile75: 'percentil 75:',
        showStatistics: 'Mostrar estadísticas',
        noHistogramData: 'Sin datos de histograma',
        statistics: 'Estadísticas',
        summaryStatistics: 'Resumen estadístico',
        oza: 'Ángulo Cenital de Observación: ',
        noStatistics: {
          title: ' No hay estadísticas disponibles',
          description: 'No hay estadísticas disponibles para este artículo.',
        },
        copyLink: 'Copiar link',
        bandColors: {
          red: 'Rojo',
          green: 'Verde',
          blue: 'Azul',
          gray: 'Gris',
        },
      },

      metadataModal: {
        collection: 'Colección',
        constellation: 'Constelación',
        created: 'Creado',
        datetime: 'Fecha y Hora',
        eo_cloud_cover: 'Porcentaje de cobertura de nubes',
        end_datetime: 'Fecha y Hora de Finalización',
        gsd: 'Resolución espacial (m)',
        instruments: 'Instrumentos',
        item: 'Ítem',
        opencosmos_copyright: 'Derechos De Autor',
        opencosmos_data_area_km2: 'Área De Datos Km2',
        opencosmos_high_resolution_read_permission:
          'Designador Internacional de Plataforma Satelital',
        opencosmos_product_type: 'Tipo De Producto',
        opencosmos_rid: 'Rid',
        opencosmos_source: 'Fuente',
        placeholder: 'Buscar metadatos...',
        platform: 'Plataforma',
        price_currency: 'Precio Moneda',
        price: 'Precio',
        processing_level: 'Nivel de Procesamiento',
        properties: 'Propiedades',
        resolution: 'Resolución',
        sat_platform_international_designator:
          'Permiso De Lectura De Alta Resolución',
        scale: 'Escala',
        season: 'Estación',
        sensor_type: 'Tipo De Sensor',
        sentinel_grid_square: 'Indice de quadricula de sentinel',
        sentinel_latitude_band: 'Banda de latitud',
        sentinel_utm_zone: 'Zona UTM',
        simera_calibration: 'Calibración De Simera',
        simera_capture_mode: 'Modo De Captura De Simera',
        simera_compression: 'Compresión Simera',
        simera_relative_correction: 'Corrección Relativa De Simera',
        simera_session_id: 'Id De Sesión De Simera',
        start_datetime: 'Fecha y Hora de Inicio',
        sun_glint: 'Destello De Sol',
        title: 'STAC metadatos',
        triggerButton: 'Mostrar todos los metadatos',
        updated: 'Actualizado',
        values: 'Valores',
        video: 'Video',
      },

      amountAvailable: '{{amount}} / {{total}} disponible',
      addToProjectPopup: {
        title: 'Seleccione un proyecto al que agregar elementos',
        dropdownInitialItem: 'Seleccione un proyecto',
        errors: {
          selectAProject: 'Seleccione un proyecto',
        },
      },
      errors: {
        noResults: {
          title: 'No hay resultados',
          description: 'Este proyecto esta vacio',
        },
        fetchingFailed: {
          title: 'Algo salió mal',
          description: 'No se pudieron recuperar los artículos',
        },
        nothingMatchesFilters:
          'Ninguna característica coincide con los filtros especificados',
        noAssetsAvailable: 'No hay bienes disponibles',
        nothingMatchesSearchedItems: 'No se encontró ningún activo',
      },
      purchaseDialog: {
        title: 'Título',
        location: 'Ubicación',
        date: 'Fecha',
        subtotal: 'Subtotal: {{subtotal}} {{currency}}',
        discount: 'Descuento: {{discount}} {{currency}}',
        totalPrice: 'Precio total',
        price: 'Precio',
        noPrice:
          'No se puede obtener el precio, contáctenos para obtener más detalles',
        orderCheckout: 'Pago del pedido',
        payingViaBankTransfer: 'Pagando mediante transferencia bancaria',
        paymentViaCardComplete:
          'Pago mediante tarjeta de débito o crédito completado',
        dataPurchasedWillNotBeAvailableUntil:
          'Los datos adquiridos en este pedido no estarán disponibles hasta que se hayan verificado los detalles del pago.',
        pleaseMakeATransferWithRef:
          'Por favor realice una transferencia bancaria con referencia {{reference}} a:',
        verificationOfPaymentDetailsCanTake:
          ' La verificación de los datos de pago puede tardar hasta 2 días laborables a partir de la fecha de pago',
        couldNotFinalizePayment: 'No se pudo finalizar el pago',
      },
    },
    tasking: {
      title: 'Planificación',
      existing: {
        title: 'Existente',
        newRequestFromExisting: 'Nueva solicitud de existente',
        errors: {
          noRequests: {
            title: 'No se encontraron solicitudes',
            description:
              'Cree una nueva solicitud de planificación para comenzar',
          },
          newRequestBtn: 'Nueva solicitud',
        },
        createNewTaskingRequest: 'Crear nueva solicitud de planificación',
        requestType: {
          MANUAL: 'MANUAL',
          AUTOMATED: 'AUTOMATIZADO',
        },
        requestStatus: {
          PENDING: 'PENDIENTE',
          CONFIRMED: 'CONFIRMADO',
          ACQUIRING: 'ADQUIRIENDO',
          SUCCESSFUL: 'EXITOSO',
          FAILED: 'FALLADO',
          CANCELLED: 'CANCELADO',
        },
        activityStatus: {
          SUGGESTED: 'SUGERIDO',
          CONFIRMED: 'CONFIRMADO',
          SCHEDULED: 'PROGRAMADO',
          SUCCEEDED: 'EXITOSO',
          FAILED: 'FALLADO',
          EXPIRED: 'CADUCADO',
          CANCELLED: 'CANCELADO',
        },
      },
      new: {
        aoi: 'Area de Interes',
        satellites: 'Satélites',
        title: 'Nuevo',
        draw: 'Dibujar',
        type: 'Tipo de solicitud',
        label: 'Nombre de region',
        labelTooltip: 'área de interé',
        manual: {
          title: 'Manual',
          description:
            'La planificación manual le permite buscar oportunidades dentro del AoI y las fechas determinadas. Podrá ajustar franjas manualmente.',
        },
        automated: {
          title: 'Automático',
          description:
            'Las planificaciones automatizadas le permiten solicitar resultados de búsqueda optimizados según la fecha de tarea establecida y el AoI',
        },
        calendar: {
          availableOpps: 'Oprtunidades disponibles',
        },
        instruments: {
          add: 'Añadir',
          addAll: 'Añadir todo',
          filters: {
            dataType: 'Tipo de datos',
            gsd: 'Resolución espacial (m)',
          },
          errors: {
            noSatellitesSelected: 'No hay satélites seleccionados',
          },
        },
        upload: 'Cargar',
        parameters: 'Parámetros',
        start: 'Iniciar',
        end: 'Finalizar',
        selectedInstruments: 'Instrumentos seleccionados',
        nonIdealStateTitle: 'No hay satélites seleccionados',
        nonIdealStateDesc:
          'Seleccione los satélites de arriba para buscar oportunidades',
        constraints: {
          title: 'Restricciones',
          rollAngle: 'Ángulo de balanceo',
          sza: 'Ángulo cenital del sol',
          szaDescription:
            'Limitación del ángulo cenital del sol [degrees]. El ángulo cenital del sol es el ángulo entre un vector perpendicular a la superficie de la Tierra en el centro de la franja de barrido proyectado en la superfície y un vector que apunta al sol desde el mismo punto.',
          rollAngleDescription:
            'Limitación del ángulo de balanceo del satélite [degrees]. El ángulo de balanceo del satélite es el ángulo entre el vector NADIR y el vector de apuntado del satélite, proyectado al plano perpendicular al vector de velocidad del satélite.',
          szaDayButton:
            'Día: asegurar la adquisición cuando el sol esté sobre el horizonte',
          szaNightButton:
            'Noche: asegurar la adquisición cuando el sol esté debajo del horizonte',
        },
        objectives: {
          title: 'Objetivos',
          oza: 'Ángulo cenital de observación',
          sza: 'Ángulo cenital del sol',
        },
        search: 'Buscar',
        noneFoundTitle: 'No hay resultados',
        noneFoundDesc:
          'No se encontraron oportunidades para el área seleccionada en las fechas elegidas para los satélites seleccionados',
        request: 'Solicitud',
      },
      swathControl: {
        start: 'Inicar',
        end: 'Finalizar',
        duration: 'Duración: {{duration}}',
        rollAngle: 'Ángulo de inclinación',
        min: 'Min: {{min}}',
        max: 'Max: {{max}}',
      },
      purchaseDialog: {
        thisRequestIsComposed_one:
          'Esta solicitud se compone de {{count}} franja de barrido',
        thisRequestIsComposed_other:
          'Esta solicitud se compone de {{count}} franjas de barrido',
        youCanFindTheDetailed:
          'Puede encontrar las estadísticas detalladas de solicitudes en la sección siguiente',
        statistics: {
          combinedRequestStatistics: 'Estadísticas de solicitudes combinadas',
          joinedOverlaping: 'Área superpuesta unida',
          totalImaged: 'Área total fotografiada',
          roiCoverage: 'Cobertura de región de interés',
          additionalNotes: 'Notas adicionales:',
          totalPrice: 'Precio total {{price}}',
        },
      },
      legend: {
        failed: 'Fallado',
        planned: 'Planeado',
        succeeded: 'Completado con éxito',
        target: 'Franjas de barrido objetivo',
        totalArea: 'Área total',
        uniqueArea: 'Área unica',
        roiCoverage: 'Cobertura de la Región de Interés',
        fieldOfRegard: 'Campo de consideración',
        currentSwath: 'Franja de barrido actual',
        confirmedSwath: 'Franja de barrido confirmada',
        aoi: 'AoI',
      },
      errors: {
        tooLarge: 'El AoI no debe ser mayor de 15,000,000 km²',
      },
    },
    layers: {
      layers: 'Capas',
      blendMode: 'Modo de mezcla',
      opacity: 'Opacidad',
      brightness: 'Brillo',
      saturation: 'Saturación',
      contrast: 'Contraste',
      backToLayers: 'Volver a capas',
      timeSeries: 'Series de tiempo',
      layerOperationDisabled: 'Solicitado imagen en resolución completa',
      reset: 'Reiniciar',
      blendModes: {
        normal: 'normal',
        multiply: 'multiplicar',
        screen: 'pantalla',
        overlay: 'superponer',
        darken: 'oscurecer',
        'color-dodge': 'sobreexposición de color',
        'color-burn': 'subexposición de color',
        'hard-light': 'luz dura',
        'soft-light': 'luz suave',
        difference: 'diferencia',
        exclusion: 'exclusión',
        hue: 'tono',
        saturation: 'saturación',
        color: 'color',
        luminosity: 'luminosidad',
      },
      pixelOperations: {
        title: 'Modos de operación de píxeles',
        singlePixelValue: 'Valor de un solo píxel',
        linePixelValues: 'Valores de línea de píxeles',
        vsWavelength: 'Valor de píxel vs longitud de onda',
        pixelSpectralSignatures: 'Firmas espectrales de píxeles',
        tooltip: {
          loading: 'Cargando...',
          noData: 'Sin datos',
        },
      },
      pixelOperationsChart: {
        wavelength: {
          noData: 'No hay datos de píxeles de longitud de onda',
          download: 'Descargar datos',
          xAxisLabel: 'Longitud de onda',
          downloadDataAs: 'Descargar datos como: ',
        },
        linePixels: {
          noData: 'No hay datos de píxeles de línea',
          horizontalPixels: 'Píxeles horizontales',
          verticalPixels: 'Píxeles verticales',
          xAxisLabelVertical: 'Latitud',
          xAxisLabelHorizontal: 'Longitud',
          downloadDataAs: 'Descargar datos como: ',
          download: 'Descargar datos de píxeles {{axis}}',
        },
        spectralSignature: {
          noData: 'No hay datos de firma espectral',
        },
      },
      bandAlgebra: {
        indexTab: 'Indíce',
        customTab: 'Personalizado',
        title: 'Álgebra de bandas',
        expressionNotSupported: 'Expresión no compatible con este satélite.',
        predefined: {
          title: 'Predefinido',
          index: {
            ndvi: {
              title: 'Índice de Vegetación (NDVI)',
              description:
                'Índice de Vegetación de Diferencia Normalizada: indica cuánta vegetación hay en un área y la salud de esa vegetación.',
            },
            gndvi: {
              title: 'Índice de Vegetación (GNDVI)',
              description:
                'Índice de Vegetación de Diferencia Normalizada Verde: proporciona una estimación de cuánta clorofila/actividad fotosintética hay en un área',
            },
            avi: {
              title: 'Índice de Vegetación (AVI)',
              description:
                'Índice de Vegetación Avanzado: indica qué tan saludable es la vegetación en un área',
            },
            savi: {
              title: 'Índice de Vegetación Ajustado al Suelo (SAVI)',
              description:
                'Índice de Vegetación Ajustado al Suelo - indica qué tan saludable es la vegetación en áreas con poca vegetación, corrigiendo las reflexiones del suelo desnudo',
            },
            arvi: {
              title: 'Índice de Vegetación (ARVI)',
              description:
                'Índice de Vegetación Resistente a la Atmósfera: una medida de la vegetación que se ve menos afectada por la reflectancia atmosférica (neblina, etc.)',
            },
            ndmi: {
              title: 'Índice de Humedad (NDMI)',
              description:
                'Índice de Humedad de Diferencia Normalizada: mide el nivel de humedad en la vegetación',
            },
            msi: {
              title: 'índice de Humedad (MSI)',
              description:
                'Índice de Estrés por Humedad: distingue entre vegetación seca y húmeda',
            },
            gci: {
              title: 'Índice de Cobertura Verde (GCI)',
              description:
                'Índice de Cobertura Verde: mide la cantidad de clorofila en las plantas de un área, un indicador de estrés/salud de la vegetación.',
            },
            nbr: {
              title: 'Índice de Proporción de Quemado (NBR)',
              description:
                'Índice de Quemado Normalizado: resalta las áreas quemadas y mide la gravedad de la quemadura, por ejemplo, después de un incendio forestal',
            },
            bsi: {
              title: 'Índice de Tierra (BSI)',
              description:
                'Índice de Tierra Vacía: identifica regiones de tierra vacía',
            },
            ndwi_gao: {
              title: 'Índice de Agua (NDWI, Gao)',
              description:
                'Índice de Diferencia de Agua Normalizada (Gao): mide el contenido de agua en las hojas',
            },
            ndwi_mcfeeters: {
              title: 'Índice de Agua (NDWI, McFeeters)',
              description:
                'Índice de Diferencia de Agua Normalizada (McFeeters): mide el contenido de agua en cuerpos de agua',
            },
            ndsi: {
              title: 'Índice de Nieve (NDSI)',
              description:
                'Índice de Nieve de Diferencia Normalizada: detecta la presencia de nieve',
            },
            ndgi: {
              title: 'Índice de Glaciar (NDGI)',
              description:
                'Índice de Diferencia Normalizada de Glaciares: identifica glaciares',
            },
            sipi: {
              title: 'Índice de Pigmento (SIPI)',
              description:
                'Índice de Pigmentos Insensibles a la Estructura: mide la cantidad de pigmentos carotenoides en la vegetación.',
            },
            sr: {
              title: 'Índice de Relación Simple (SR)',
              description:
                'Índice de Proporción Simple: distingue entre áreas con vegetación y sin vegetación, plantas estresadas y no estresadas',
            },
            npcri: {
              title:
                'Índice de proporción de clorofila de pigmento normalizado (NPCRI)',
              description:
                'Índice de proporción de clorofila de pigmento normalizado - un indicador numérico que se puede utilizar para determinar el contenido de clorofila de cultivos y/o vegetación. Esta fórmula suele encontrarse en aplicación en la agricultura de precesión.',
            },
          },
        },
        custom: {
          title: 'Álgebra personalizada',
          name: 'Nombre',
          description: 'Descripción',
          save: 'Guardar',
          saveAndApply: 'Guardar y aplicar',
          pallet: 'Paleta',
          none: 'Ninguno',
          success: `Nueva álgebra de banda {{name}} creada exitosamente.`,
          single: {
            title: 'Único',
            showOnlyBand: 'Mostrar solo banda',
            errors: {
              bandNotSelected: 'Selecciona una banda',
              noMinimum: 'El mínimo no debe estar vacío',
              noMaximum: 'El máximo no debe estar vacío',
              noName: 'El nombre no debe estar vacío.',
            },
          },
          rgb: {
            title: 'RGB',
            minimmum: 'Mínimo',
            maximum: 'Máximo',
            errors: {
              min: 'El mínimo no debe estar vacío',
              max: 'El máximo no debe estar vacío',
              r: 'R no debe dejarse vacío',
              g: 'G no debe dejarse vacío',
              b: 'B no debe dejarse vacío',
              name: 'El nombre no debe estar vacío',
            },
          },
          index: {
            title: 'Índice',
            expression: 'Expresión',
            expressionPlaceholder: 'Introduzca su expresión',
            errors: {
              noExpression: 'Introduzca una expresión',
              noMinimum: 'El mínimo no debe estar vacío',
              noMaximum: 'El máximo no debe estar vacío',
              noName: 'El nombre no debe estar vacío',
            },
          },
        },
      },
      vectorOperations: {
        title: 'Operación vectorial',
        union: 'Unión',
        intersection: 'intersección',
        difference: 'diferencia',
      },
    },
    rightClickMenu: {
      copyCoords: {
        copyCurrentCoords: 'Copiar coordenadas actuales',
        success: 'Coordenadas actuales copiadas {{coordinates}}',
      },
      line: 'Dibujar una línea',
      polygon: 'Dibujar un área (polígono)',
      circle: 'Dibujar un área (círculo)',
      rectangle: 'Dibujar un área (rectángulo)',
      select: 'Seleccionar esta capa',
      polyline: 'Dibujar una polilínea',
    },
    tooltips: {
      header: {
        saveView: 'Guardar vista',
        downloadScreenshot: 'Descargar captura de pantalla como {{format}}',
        collapse: 'Colapsar',
        listView: 'Vista de la lista',
        gridView: 'Vista en cuadrícula',
        uploadFile: 'Subir un archivo a este proyecto',
        noUploadPermissions:
          'No tienes permiso para subir imágenes a este proyecto',
      },
      toolbar: {
        catalog: 'Catalógo',
        items: 'Elementos del proyecto',
        tasking: 'Planificación del satélite',
        applications: 'Aplicaciones',
        layers: 'Menú de capas',
        expandMinimap: 'Expandir mini mapa',
      },
      tasking: {
        noMultiple:
          'Actualmente no es posible planificar múltiples áreas de interés (AoI)',
        gsd: {
          title: 'Resolución Espacial',
          description:
            'La Resolución Espacial o Ground Sampling Distance (GSD) es la distancia en el suelo entre los centros de dos píxeles adyacentes, equivalente a la longitud del lado de cada píxel en el suelo. Especialmente en ángulos de balanceo elevados, es posible que el GSD no sea uniforme en toda la imagen.',
        },
        automated: {
          objective:
            'El sistema automatizado de planificación dará prioridad a la selección de capturas cuyos parámetros coincidan más con los valores seleccionados cuando sea posible.',
          constraints:
            'El sistema automatizado de planificación no seleccionará capturas cuyos parámetros estén fuera de los límites seleccionados.',
          sza: 'Limitación del ángulo cenital del sol (SZA). La SZA se mide en el centro de la franja en la superficie de la Tierra.',
          oza: 'Limitación del ángulo cenital de observación (OZA) es el ángulo entre el vector del satélite y el cenit local medido en el punto de observación.',
        },
        manual: {
          constraints:
            'No mostrar oportunidades cuyos parámetros estén fuera de los límites seleccionados',
        },
        satelliteDescriptions: {
          'GAOFEN-1A':
            'Gaofen 1A es un satélite chino de observación óptica de la Tierra de alta resolución',
          'GAOFEN-1B':
            'Gaofen 1B es un satélite chino de observación óptica de la Tierra de alta resolución',
          'GAOFEN-1C':
            'Gaofen 1C es un satélite chino de observación óptica de la Tierra de alta resolución',
          'GAOFEN-1D':
            'Gaofen 1D es un satélite chino de observación óptica de la Tierra de alta resolución',
          'CBERS-4':
            'CBERS-4 es un satélite de teledetección destinado a funcionar como parte del programa China-Brasil de Satélites de Recursos Terrestres',
          'GAOFEN-2':
            'Gaofen-2 Es un satélite chino de observación óptica de la Tierra de alta resolución.',
          'GAOFEN-6':
            'Gaofen-6 Es un satélite chino de observación óptica de la Tierra de alta resolución.',
          'SENTINEL-1A':
            'Sentinel-1A es un satélite europeo de imágenes de radar lanzado en 2014',
          'SENTINEL-1B':
            'Sentinel-1B es un satélite europeo de imágenes de radar lanzado en 25 April 2016',
          'SENTINEL-2A':
            'Sentinel-2A es un satélite europeo de imágenes ópticas lanzado en 2015',
          'SENTINEL-2B':
            'Sentinel-2B es un satélite europeo de imágenes ópticas que se lanzó el 7 de marzo de 2017',
          'SENTINEL-3A':
            'Sentinel-3A es un satélite de observación de la Tierra de la Agencia Espacial Europea (ESA) dedicado a la oceanografía que se lanzó el 16 de febrero de 2016',
          'SENTINEL-3B':
            'Sentinel-3B es un satélite de observación de la Tierra de la Agencia Espacial Europea (ESA) dedicado a la oceanografía que se lanzó el 25 de Abril de 2018',
          'SENTINEL-5P':
            'Sentinel-5P es un satélite de observación de la Tierra desarrollado por la ESA como parte del Programa Copernicus para cubrir los huecos de las observaciones entre Envisat y Sentinel-5',
          'SENTINEL-6':
            'El satélite Sentinel-6 Michael Freilich es un satélite radar-altímetro desarrollado en colaboración entre varias organizaciones europeas y americanas.s',
          'LANDSAT-7':
            'Landsat 7 es el séptimo satélite del programa Landsat lanzado el 15 de abril de 1999.',
          'LANDSAT-8':
            'Landsat 8 es un satélite estadounidense de observación de la Tierra lanzado el 11 de febrero de 2013.',
          'LANDSAT-9':
            'Landsat 9 es un satélite de observación de la Tierra lanzado el 27 de septiembre de 2021',
          'SPOT-6':
            'SPOT 6 es un satélite comercial de imágenes ópticas de la Tierra de alta resolución.',
          'SPOT-7':
            'SPOT 7 es un satélite comercial de imágenes ópticas de la Tierra de alta resolución.',
          SMAP: 'Soil Moisture Active Passive, una misión de la NASA para medir la humedad del suelo y el estado de congelación/descongelación de la tierra',
          SMOS: 'Soil Moisture and Ocean Salinity, una misión de la ESA para medir la humedad del suelo, la salinidad de la superficie del mar y otras propiedades ambientales',
          'GEOSAT-2':
            'Geosat-2 es un satélite español de observación de la Tierra mediante teledetección construido para Elecnor Deimos en virtud de un acuerdo con Satrec Initiative',
          'SUPERVIEW-1-01':
            'La constelación SuperView-1 o GaoJing-1 consta de cuatro satélites comerciales chinos de teledetección operados por Beijing Space View Tech Co Ltd.',
          'SUPERVIEW-1-02':
            'La constelación SuperView-1 o GaoJing-1 consta de cuatro satélites comerciales chinos de teledetección operados por Beijing Space View Tech Co Ltd.',
          'SUPERVIEW-1-03':
            'La constelación SuperView-1 o GaoJing-1 consta de cuatro satélites comerciales chinos de teledetección operados por Beijing Space View Tech Co Ltd.',
          'SUPERVIEW-1-04':
            'La constelación SuperView-1 o GaoJing-1 consta de cuatro satélites comerciales chinos de teledetección operados por Beijing Space View Tech Co Ltd.',
          'JILIN-1-03-B':
            'Satélites comerciales chinos de teledetección para vídeo de alta definición',
          'JILIN-1-04-B':
            'Satélites comerciales chinos de teledetección para vídeo de alta definición',
          'JILIN-1-05-B':
            'Satélites comerciales chinos de teledetección para vídeo de alta definición',
          'JILIN-1-06-B':
            'Satélites comerciales chinos de teledetección para vídeo de alta definición',
          'JILIN-1-07-B':
            'Satélites comerciales chinos de teledetección para vídeo de alta definición',
          'JILIN-1-08-B':
            'Satélites comerciales chinos de teledetección para vídeo de alta definición',
          'JILIN-1-GAOFEN-03-C-01':
            'Satélites comerciales chinos de teledetección para vídeo de alta definicióno',
          'JILIN-1-GAOFEN-03-C-02':
            'Satélites comerciales chinos de teledetección para vídeo de alta definición',
          'JILIN-1-GAOFEN-03-C-03':
            'Satélites comerciales chinos de teledetección para vídeo de alta definición',
          MANTIS:
            'MANTIS es un satélite multiespectral de observación de la Tierra de alta resolución de Open Cosmos',
          MENUT:
            'Menut es un satélite multiespectral de observación de la Tierra de resolución media construido por Open Cosmos',
          'COGNISAT-6':
            'Cognisat-6 es un satélite de observación de la Tierra hiperespectral de resolución media de Open Cosmos con enlace entre satélites',
          'COGNISAT-6-EM':
            'Modelo de ingeniería (EM) para Cognisat-6, únicamente con fines de prueba',
        },
        sensorDescriptions: {
          HiRAIS:
            'Una carga útil óptica con resolución panorámica de 0,75 m, 4 bandas y un sensor de 10 bit',
          SLIM6:
            'Surrey Linear Imager Multiespectral de 6 canales pero 3 bandas espectrales',
          'MultiScape100 CIS':
            'Multiespectral óptico de resolución media - 7 bandas disponibles',
          HRI: 'Multiespectral óptico de alta resolución - 4 bandas disponibles',
          PMC: 'Una carga útil de matriz CCD óptica con resolución panorámica de 2 m, 4 bandas y sensor de 10 bits',
          WFI: 'Cámara de Campo Ancho (Wide Field Imager) con resolución media MS de 16 m y una franja combinada de 830 km',
          PMC2: 'Una carga útil CCD óptica con resolución panorámica de 0,8 m, 4 bandas y sensor de 10 bits',
          'CSAR/EWS': 'Radar de apertura sintética S-1 con franja extra ancha',
          'CSAR/IWS':
            'Banda ancha interferométrica de radar de apertura sintética S-1',
          'CSAR/S': 'Modo de mapa de tira de radar de apertura sintética S-1',
          'CSAR/WV': 'Modo de onda de radar de apertura sintética S-1',
          RFC: 'Modo de calibración RF',
          'CAL-DARK-O':
            'MSI Dark Calibration CSM (mecanismo de obturador de calibración) abierto',
          'CAL-SUN': 'Modo de calibración solar MSI',
          'CAL-VIC': 'Modo de calibración indirecta de MSI',
          'MSI-NOBS':
            'Modo de imagen nominal del generador de imágenes multiespectral',
          OLCI: 'Instrumento de color del océano y la tierra',
          'SLSTR-B':
            'Sea and Land Surface Temperature Radiometer (radiómetro de temperatura del mar y de la superficie terrestre) - Vista inclinada hacia atrás',
          'SLSTR-N':
            'Sea and Land Surface Temperature Radiometer (radiómetro de temperatura del mar y de la superficie terrestre) - Vista cercana al Nadir',
          SRAL: 'Altímetro de radar SAR',
          TROPOMI: 'Instrumento de monitoreo troposférico',
          'Poseidon-4': 'Altímetro de radar de apertura sintética',
          'ETM+': 'Mapper temático mejorado Plus',
          TIR: 'Infrarrojo térmico',
          OLI: 'Imager terrestre operativo (pancromático y multiespectral)',
          TIRS: 'Sensor infrarrojo térmico',
          Imager:
            'GaoJing / SuperView Generador de imágenes de constelaciones de observación de la Tierra',
          'NAOMI-1': 'Nuevo instrumento modular óptico AstroSat',
          'NAOMI-2': 'Nuevo instrumento modular óptico AstroSat',
          MUXCam:
            'Cámara multiespectral para observación de la tierra y la vegetación en alta resolución',
          'PanMUX-PAN':
            'Generador de imágenes pancromáticas y multiespectrales para observación de la tierra y la vegetación en alta resolución',
          IRS: 'Escáner infrarrojo de resolución media',
          'Jilin Imager':
            'Generador de imágenes de vídeo de alta resolución (4K)',
          'Jilin GaoFen Imager':
            'Generador de imágenes de vídeo de alta resolución (4K)',
          HyperScape100:
            'Hiperespectral óptico de resolución media - 32 bandas espectrales simultáneas',
        },
        existing: {
          viewAoi: 'Ver AoI',
          viewSwath: 'Franja de barrido',
          activities: {
            duration: 'Duración',
            imager: 'Generador de imágenes',
            aoiCoverage: 'Cobertura AoI',
            oza: 'Ángulo cenital de observación (OZA)',
            sza: 'Ángulo cenital del sol (SZA)',
            rollAngle: 'Ángulo de inclinación',
            showSwath: 'Mostrar franja de barrido',
            viewStac: 'Ver elemento de la pila',
            editSwath: 'Editar franja de barrido',
            editSwathDisabled: '(Desactivado) Editar franja de barrido',
            processingLevel: 'Nivel de procesamiento',
          },
        },
      },
      catalogAndItems: {
        metadata: {
          platformName: 'Nombre de la plataforma',
          sza: 'Ángulo cenital del sol',
          cloudCoverage: 'Cobertura de nubes',
          location: 'Ubicación',
          price: 'Precio',
        },

        icons: {
          previewOnMap: 'Mostrar vista previa en el mapa',
          showHighResPreview:
            'Mostrar vista previa de alta resolución en el mapa',
          removeFromMap: 'Eliminar del mapa',
          addToCart: 'Agregar al carrito de compras',
          removeFromCart: 'Eliminar del carrito de compras',
          addToProject: 'Agregar elemento al proyecto',
          addedToProject: 'Elemento agregado al proyecto',
          centreItemOnMap: 'Centrar mapa en el elemento',
          showMetadataAssets: 'Mostrar activos de metadatos',
          removeFromProject: 'Eliminar del proyecto',
          imageCannotBePurchased: 'Esta imagen no se puede comprar',
        },

        removeFromProject:
          'Eliminar elementos seleccionados de {{projectName}}',
        addToProject: 'Agregar elementos seleccionados a {{projectName}}',
        fullResAvailable:
          'Imágenes de resolución completa disponibles para este artículo',
        downloadAsset: 'Descargar elemento',
        disableFullRes: 'Desactivar imagen de resolución completa',
        showFullRes: 'Mostrar imagen en resolución completa',

        purchaseDialog: {
          satelliteName: 'Nombre del satélite',
          location: 'Ubicación',
          date: 'Fecha',
          sunElevationAngle: 'Ángulo de elevación del sol',
          cloudCoverage: 'Cobertura de nubes',
          title: 'Título',
        },
      },
    },
    filters: {
      geometry: 'Geometría',
      currentViewArea: 'Sólo el área de visualización actual',
      uploadAoi: 'Subir área de interés',
      drawAoi: 'Dibujar área de interés',
      clearAoi: 'Limpiar área de interés',
      satellite: 'Satélite',
      dates: 'Fechas',
      hours: 'Horas del dia (UTC)',
      fromHours: 'De',
      toHours: 'A',
      cloudCoverage: 'Cobertura de nubes (%)',
      processingLevel: 'Nivel de procesamiento',
      productType: 'Tipo de producto',
      imageBand: 'Banda de la imagen',
      seasons: 'Estaciones',
      assetType: 'Tipo de activo',
      source: 'Fuentes',
      resolution: 'Resolución',
      clearAll: 'Limpiar todos los filtros',
      sza: 'SZA (grados)',
      oza: 'OZA (grados)',
      sunGlint: 'brillo del sol',
      gsd: ' Resolución espacial - GSD (m)',
      sensorType: 'Tipo de sensor',
      platformType: 'Tipo de plataforma',
      placeholder: 'Buscar...',
      startDate: 'Fecha de inicio',
      endDate: 'Fecha final',
      min: 'mín.',
      max: 'máx.',
      fetchRegion: 'Obtener región',
      display: 'Mostrar',
      detailedTooltips: {
        gsd: {
          content: 'Resolución espacial',
          description:
            'La Resolución Espacial o Ground Sampling Distance (GSD) es la distancia en el suelo entre los centros de dos píxeles adyacentes, equivalente a la longitud del lado de cada píxel en el suelo. Especialmente en ángulos de balanceo elevados, es posible que el GSD no sea uniforme en toda la imagen.',
        },
        currentViewArea: {
          content: 'Área de vista actual',
          description:
            'Muestra imágenes del área actualmente visible en el mapa. Al desplazarse por el mapa, las imágenes se actualizarán automáticamente.',
        },
        satellite: {
          content: 'Filtrar por satélite',
          description: 'Elija qué imágenes de satélite mostrar.',
        },
        dates: {
          content: 'Filtrar por fechas',
          description:
            'Un rango de fechas utilizado para filtrar imágenes. Cuando esté activo, solo se mostrarán las imágenes tomadas dentro del rango de fechas seleccionado.',
        },
        sza: {
          content: 'Ángulo cenital del sol',
          description: '',
        },
        oza: {
          content: 'Ángulo cenital de observación',
          description: '',
        },
        cloudCoverage: {
          content: 'Porcentaje de cobertura de la nube',
          description: '',
        },
        sunGlint: {
          content: 'Cantidad de brillo solar',
          description: '',
        },
        hoursOfDay: {
          content: 'Horas del dia',
          description:
            'Un rango de horas utilizado para filtrar imágenes. Cuando está activo, solo se mostrarán las imágenes tomadas dentro del rango de horas seleccionado.',
        },
        sensorType: {
          content: 'Tipo de sensor',
          description:
            'El tipo de sensor del satélite. Cuando está activo, solo se mostrarán las imágenes tomadas con el tipo de sensor seleccionado..',
        },
        processingLevel: {
          content: 'Nivel de procesamiento',
          description: '',
        },
        productType: {
          content: 'Tipo de producto',
          description: '',
        },
        imageBand: {
          content: 'Banda de la imagen',
          description: '',
        },
        assetType: {
          content: 'Tipo de activo',
          description: '',
        },
        sources: {
          content: 'Fuentes',
          description:
            'La fuente de la imagen. Cuando está activo, solo se mostrarán las imágenes tomadas de la fuente seleccionada.',
        },
        resolution: {
          content: 'Resolución',
          description: '',
        },
        platformType: {
          content: 'Tipo de plataforma',
          description: '',
        },
        seasons: {
          content: 'Estaciones',
          description: '',
        },
      },
      resolutions: {
        full: 'Lleno',
        limited: 'Limitado',
      },
      tooltips: {
        closeFilters: 'Cerrar filtros',
      },
      errors: {
        noFilteringByMultiple:
          'No se admite el filtrado por múltiples áreas de interés',
      },
    },
    uploadRegion: {
      fileAlreadyPresent: 'Archivo ya presente en el mapa.',
      filesSupported: '{{files}} archivos soportados',
      geoJsonValidator: {
        invalidType:
          'Tipo de AoI cargado no válido - El tipo de AoI cargado debe ser uno de los siguientes: {{allowed}}',
        invalidGeometryType:
          'Tipo de geometría de AoI cargada no válido - El tipo de geometría de AoI cargada debe ser uno de los siguientes: {{allowed}}',

        typeUnsupported:
          'Tipo de AoI cargado no compatible - el tipo de geometría de AoI cargada no debe ser ninguno de los siguientes: {{disallowed}}',
        tooLarge:
          'Área demasiado grande - el área de AoI cargada no debe ser mayor de 15,000,000 km^2',
        hasHoles:
          'AoI cargado no válido - el AoI cargado tiene anillos interiores (agujeros) que no son compatibles',
        isClockwise:
          'Coordenadas de AoI cargado no válidas - las coordenadas de AoI cargado siempre deben estar en dirección anti-horaria',
      },
    },
    orders: {
      title: 'Pedidos',
      start: 'Fecha de inicio',
      end: 'Fecha final',
      buttons: {
        markAsPaid: 'Marcar como pagado',
        cancel: 'Cancelar',
      },
      orderSummary: {
        title: 'Resumen del pedido',
        description_one: '{{count}} artículo del catálogo',
        description_other: '{{count}} artículos del catálogo',
      },
      orderStatus: {
        title: 'Estado del pedido',
        paid: 'Pagado',
        unpaid: 'No pagado',
        cancelled: 'Cancelado',
      },
      accessStatus: {
        title: 'Estado de acceso',
      },
      orderedOn: 'Ordenado el {{date}}',
    },
    views: {
      order: 'Ordenar',
      by: 'Por',
      from: 'Desde',
      to: 'Hasta',
      inProject: 'En proyecto',
      search: 'Buscar',
    },
    applications: {
      title: 'Aplicaciones',
      install: 'Instalar',
      uninstall: 'Desinstalar',
      global: {
        buttons: {
          submit: 'Enviar',
        },
      },
      toa: {
        title: 'TOA',
        shortDescription: 'Realizar cálculos TOA en imágenes de satélite',
        description:
          'Calcular valores de la parte superior de la atmósfera (TOA) para mediciones precisas antes de la corrección atmosférica. Ideal para investigadores y profesionales en teledetección.',
        inputs: {
          fullResImg: {
            title: 'Haz clic en una imagen de resolución completa en el mapa',
            placeholder: 'ID de imagen de resolución completa...',
          },
        },
      },
      digitalLevelsToRadiance: {
        title: 'Niveles digitales a radiancia',
        shortDescription:
          'Convertir niveles digitales a radiancia en imágenes de satélite',
        description:
          'Convertir de manera eficiente los valores digitales brutos a mediciones de radiancia para imágenes de satélite. Simplifica el proceso para investigadores y analistas.',
        inputs: {
          fullResImg: {
            title: 'Haz clic en una imagen de resolución completa en el mapa',
            placeholder: 'ID de imagen de resolución completa...',
          },
        },
      },
      perusatIngestion: {
        title: 'Ingestión de Perusat-1',
        shortDescription:
          'Ingerir imágenes de satélite Perusat-1 a través de FTP',
        description:
          'Facilita la ingesta de imágenes de satélite Perusat-1 al ingresar la URL de FTP. Esencial para usuarios que trabajan con imágenes de Perusat-1 en flujos de trabajo de análisis.',
        inputs: {
          folderPath: 'Ruta de la carpeta FTP',
          sceneId: 'Identificación de escena',
        },
      },
      spotIngestion: {
        title: 'Ingestión de SPOT',
        shortDescription: 'Ingerir imágenes de satélite SPOT a través de FTP',
        description:
          'Facilita la ingesta de imágenes de satélite SPOT al ingresar la URL de la carpeta FTP en los servidores de CONIDA. Esta aplicación es utilizada por administradores que harán disponibles las imágenes de SPOT en la plataforma para todos los usuarios.',
        inputs: {
          zipFile: 'Ingrese la .zip FTP de una imagen SPOT',
        },
      },
      terraSatXIngestion: {
        title: 'Ingestión de TerraSAT-X',
        shortDescription:
          'Ingerir imágenes de satélite TerraSAT-X a través de FTP',
        description:
          'Facilita la ingesta de imágenes de satélite TerraSAT-X al ingresar la URL de la carpeta FTP en los servidores de CONIDA. Esta aplicación es utilizada por administradores que harán disponibles las imágenes de TerraSAT-X en la plataforma para todos los usuarios.',
        inputs: {
          zipFile: 'Ingrese la .zip FTP de una imagen TerraSAT-X',
        },
      },
      pleiadesIngestion: {
        title: 'Ingestión de PLEIADES',
        shortDescription:
          'Ingerir imágenes de satélite PLEIADES a través de FTP',
        description:
          'Facilita la ingesta de imágenes de satélite PLEIADES al ingresar la URL de la carpeta FTP en los servidores de CONIDA. Esta aplicación es utilizada por administradores que harán disponibles las imágenes de PLEIADES en la plataforma para todos los usuarios.',
        inputs: {
          zipFile: 'Ingrese la .zip FTP de una imagen PLEIADES',
        },
      },
      cosmoSkymedIngestion: {
        title: 'Ingestión de COSMO-skymed',
        shortDescription:
          'Ingerir imágenes de satélite COSMO-skymed a través de FTP',
        description:
          'Facilita la ingesta de imágenes de satélite COSMO-skymed al ingresar la URL de la carpeta FTP en los servidores de CONIDA. Esta aplicación es utilizada por administradores que harán disponibles las imágenes de COSMO-skymed en la plataforma para todos los usuarios.',
        inputs: {
          tarFile: 'Ingrese la .tar FTP de una imagen COSMO-skymed',
        },
      },
      kompsat3Ingestion: {
        title: 'Ingestión de KompSAT-3',
        shortDescription:
          'Ingerir imágenes de satélite KompSAT-3 a través de FTP',
        description:
          'Facilita la ingesta de imágenes de satélite KompSAT-3 al ingresar la URL de la carpeta FTP en los servidores de CONIDA. Esta aplicación es utilizada por administradores que harán disponibles las imágenes de KompSAT-3 en la plataforma para todos los usuarios.',
        inputs: {
          zipFile: 'Ingrese la .zip FTP de una imagen KompSAT-3',
        },
      },
      kazeoSatIngestion: {
        title: 'Ingestión de KazEOSAT',
        shortDescription:
          'Ingerir imágenes de satélite KazEOSAT a través de FTP',
        description:
          'Facilita la ingesta de imágenes de satélite KazEOSAT al ingresar la URL de la carpeta FTP en los servidores de CONIDA. Esta aplicación es utilizada por administradores que harán disponibles las imágenes de KazEOSAT en la plataforma para todos los usuarios.',
        inputs: {
          zipFile: 'Ingrese la .zip FTP de una imagen KazEOSAT',
        },
      },

      changeDetection: {
        title: 'Detección de cambios',
        shortDescription: 'Detectar cambios entre dos imágenes de satélite',
        description:
          'Identifica eficientemente cambios a lo largo del tiempo al comparar dos imágenes de satélite de resolución completa. Esencial para monitorear cambios ambientales y en el uso del suelo.',
        inputs: {
          fullResImg1: {
            title: 'Haz clic en una imagen de resolución completa en el mapa',
            placeholder: 'ID de imagen de resolución completa...',
          },
          fullResImg2: {
            title:
              'Haz clic en una segunda imagen de resolución completa en el mapa',
            placeholder: 'ID de imagen de resolución completa...',
          },
          type: {
            title: 'Seleccione tipo',
          },
          scale: {
            title: 'Introducir escala',
            placeholder: 'P.ej. 5',
          },
        },
      },
      cropImg: {
        title: 'Recortar imagen por AoI',
        shortDescription:
          'Recorta la imagen por una zona de interés especificada',
        description:
          'Crea un flujo de trabajo que recorta una imagen según una zona de interés dada. Perfecto para ajustar imágenes a áreas de interés específicas.',
        inputs: {
          fullResImg: {
            title: 'Haz clic en una imagen de resolución completa en el mapa',
            placeholder: 'ID de imagen de resolución completa...',
          },
          aoi: {
            title: 'Área de interés:',
            placeholder: 'Nombre de la AoI',
          },
          targetProject: {
            title: 'Proyecto de destino:',
            description:
              'Elige el proyecto en el que se almacenarán los resultados del procesamiento.',
          },
        },
      },
      supervisedClassification: {
        title: 'Clasificación Supervisada',
        shortDescription:
          'Realizar clasificación supervisada en imágenes de satélite',
        description:
          'Clasificar imágenes de satélite especificando clases en los píxeles de la imagen. Ideal para investigadores y profesionales en teledetección.',
        inputs: {
          fullResImg: {
            title: 'Haz clic en una imagen de resolución completa en el mapa',
            placeholder: 'ID de imagen de resolución completa...',
          },
          classes: {
            tableTitles: {
              name: 'Nombre',
              color: 'Color',
            },
            placeholders: {
              name: 'Insertar nombre',
            },
            buttons: {
              addClass: 'Agregar clase',
            },
          },
        },

        errors: {
          noClasses: 'Se debe definir al menos una clase',
          notUnique: 'Los nombres de las clases deben ser únicos',
          missingNames: 'Todas las clases deben tener un nombre',
        },
      },
    },
    tours: {
      mainAppTour: {
        catalog: 'Catálogo',
        project: 'Items del proyecto',
        tasking: 'Planificación del satélite',
        applications: 'Aplicaciones',
        layers: 'Menú de capas',
        endTour: 'Fin Recorrido',
        catalogDescription:
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt',
        projectDescription:
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt',
        taskingDescription:
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt',
        applicationsDescription:
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt',
        layersDescription:
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt',
      },
    },
  },
} as const;

export default esTranslation;
