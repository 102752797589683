import { SATELLITE_ID_LIST } from 'api/satellites/types';
import type { Satellite } from 'api/satellites/types';

// Intended for satellites which are owned by Open Cosmos, tasked via DataCosmos,
// and available for tasking in test but not in production
export const INTERNAL_SATELLITES: Satellite[] = [];

// Intended for satellites which are not owned by Open Cosmos, tasked via DataCosmos,
// and available for tasking in both test and production
export const TASKED_FROM_DATACOSMOS: Satellite[] = [
  {
    uiName: 'GEOSAT-2',
    satellite: SATELLITE_ID_LIST.GEOSAT_2,
    description:
      'Geosat-2 is a Spanish remote sensing Earth observation satellite built for Elecnor Deimos under an agreement with Satrec Initiative',
    backend: 'OPEN_COSMOS',
    dataType: ['Optical'],
    gsdData: 0.75,
    COSPAR: '2014-033D',
    taskable: true,
  },
  {
    uiName: 'SUPERVIEW-1-01',
    satellite: SATELLITE_ID_LIST.SUPERVIEW_01,
    description:
      'SuperView-1 or GaoJing-1 constellation consists of four Chinese commercial remote sensing satellites operated by Beijing Space View Tech Co Ltd',
    backend: 'OPEN_COSMOS',
    dataType: ['Optical'],
    gsdData: 0.5,
    COSPAR: '2016-083A',
    taskable: true,
  },
  {
    uiName: 'SUPERVIEW-1-02',
    satellite: SATELLITE_ID_LIST.SUPERVIEW_02,
    description:
      'SuperView-1 or GaoJing-1 constellation consists of four Chinese commercial remote sensing satellites operated by Beijing Space View Tech Co Ltd',
    backend: 'OPEN_COSMOS',
    dataType: ['Optical'],
    gsdData: 0.5,
    COSPAR: '2016-083B',
    taskable: true,
  },
  {
    uiName: 'SUPERVIEW-1-03',
    satellite: SATELLITE_ID_LIST.SUPERVIEW_03,
    description:
      'SuperView-1 or GaoJing-1 constellation consists of four Chinese commercial remote sensing satellites operated by Beijing Space View Tech Co Ltd',
    backend: 'OPEN_COSMOS',
    dataType: ['Optical'],
    gsdData: 0.5,
    COSPAR: '2018-002A',
    taskable: true,
  },
  {
    uiName: 'SUPERVIEW-1-04',
    satellite: SATELLITE_ID_LIST.SUPERVIEW_04,
    description:
      'SuperView-1 or GaoJing-1 constellation consists of four Chinese commercial remote sensing satellites operated by Beijing Space View Tech Co Ltd',
    backend: 'OPEN_COSMOS',
    dataType: ['Optical'],
    gsdData: 0.5,
    COSPAR: '2018-002B',
    taskable: true,
  },
  {
    uiName: 'MANTIS',
    satellite: SATELLITE_ID_LIST.MANTIS,
    description:
      'MANTIS is an Open Cosmos high-resolution multispectral Earth observation satellite',
    backend: 'OPEN_COSMOS',
    dataType: ['Optical'],
    gsdData: 2.5,
    COSPAR: 'COSPAR-MANTIS-TBD',
    taskable: true,
  },
  {
    satellite: SATELLITE_ID_LIST.MENUT,
    description:
      'Menut is an Open Cosmos medium-resolution multispectral Earth observation satellite',
    backend: 'OPEN_COSMOS',
    uiName: 'MENUT',
    dataType: ['Optical'],
    gsdData: 4.75,
    COSPAR: '2023-001B',
    taskable: true,
  },
  {
    satellite: SATELLITE_ID_LIST.COGNISAT_6,
    description:
      'Cognisat-6 is an Open Cosmos medium-resolution hyperspectral Earth observation satellite with an Inter-Satellite Link',
    backend: 'OPEN_COSMOS',
    uiName: 'COGNISAT-6',
    dataType: ['Optical'],
    gsdData: 4.75,
    COSPAR: 'COSPAR-COGNISAT-6-TBD',
    taskable: true,
  },
  {
    satellite: SATELLITE_ID_LIST.COGNISAT_6_EM,
    description:
      'Engineering Model (EM) for Cognisat-6, for test purposes only',
    backend: 'OPEN_COSMOS',
    uiName: 'COGNISAT-6-EM',
    dataType: ['Optical'],
    gsdData: 4.75,
    COSPAR: 'COSPAR-COGNISAT-6-EM-TBD',
    taskable: true,
  },
  {
    satellite: SATELLITE_ID_LIST.PERUSAT_1,
    description:
      'Perusat-1 is owned and operated by the Peruvian government department CONIDA',
    backend: 'OPEN_COSMOS',
    uiName: 'PERUSAT-1',
    dataType: ['Optical'],
    gsdData: 0.7,
    COSPAR: '2016-058A',
    taskable: false,
  },
  {
    satellite: SATELLITE_ID_LIST.ALISIO,
    description:
      'Alisio is an Open Cosmos medium-resolution multispectral Earth observation satellite',
    backend: 'OPEN_COSMOS',
    uiName: 'Alisio',
    dataType: ['Optical'],
    gsdData: 4.75,
    COSPAR: '2023-185M',
    taskable: true,
  },
  {
    satellite: SATELLITE_ID_LIST.PLATERO,
    description:
      'Platero is an Open Cosmos medium-resolution multispectral Earth observation satellite',
    backend: 'OPEN_COSMOS',
    uiName: 'Platero',
    dataType: ['Optical'],
    gsdData: 4.75,
    COSPAR: '2023-174G',
    taskable: true,
  },
  {
    satellite: SATELLITE_ID_LIST.PHISAT2,
    description:
      'Phisat 2 is an Open Cosmos medium-resolution multispectral Earth observation satellite',
    backend: 'OPEN_COSMOS',
    uiName: 'PHISAT-2',
    dataType: ['Optical'],
    gsdData: 4.75,
    COSPAR: 'COSPAR-PHISAT2-TBD',
    taskable: true,
  },
];

export const ALL_SATELLITES: Satellite[] = [
  ...INTERNAL_SATELLITES,
  ...TASKED_FROM_DATACOSMOS,
].sort((a, b) => a.uiName.localeCompare(b.uiName));

export const SATELLITES_FOR_PRODUCTION: Satellite[] = [
  ...TASKED_FROM_DATACOSMOS,
  ...INTERNAL_SATELLITES,
].sort((a, b) => a.uiName.localeCompare(b.uiName));

export const getTaskableSats = (satelliteList: Satellite[]) => {
  return satelliteList.filter(({ taskable }) => taskable);
};
