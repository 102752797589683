import type { AriaOverlayProps } from '@react-aria/overlays';
import type { AriaDialogProps } from '@react-types/dialog';
import IconButton from '_molecules/IconButton/IconButton';
import classNames from 'classnames';
import React, { useRef } from 'react';
import {
  FocusScope,
  mergeProps,
  useDialog,
  useModal,
  useOverlay,
  usePreventScroll,
} from 'react-aria';

type Props = {
  children: React.ReactNode;
  title?: string;
  /**
   * Additional element to be rendered in the title bar, next to the title.
   */
  titleElement?: React.ReactNode;

  /**
   * Optional ref to set. Useful for screenshots
   */
  modalRef?: React.RefObject<HTMLDivElement>;
  className?: string;
} & AriaDialogProps &
  AriaOverlayProps;

const Modal = (props: Props) => {
  const { children, onClose, title } = props;

  let ref = useRef<HTMLDivElement>(null);

  if (props.modalRef) {
    ref = props.modalRef;
  }

  const { overlayProps, underlayProps } = useOverlay(props, ref);
  usePreventScroll();

  const { modalProps } = useModal();
  const { dialogProps, titleProps } = useDialog(props, ref);
  return (
    <div
      {...underlayProps}
      className="fixed inset-0 w-full flex justify-center items-center bg-surface/50 dark:bg-surface-dark/50"
      style={{ zIndex: 9999999 }}
    >
      <FocusScope contain restoreFocus autoFocus>
        <div
          {...mergeProps(dialogProps, modalProps, overlayProps)}
          ref={ref}
          className={classNames(
            'bg-surface !opacity-100 w-2/3 h-[80vh] overflow-auto dark:bg-surface-dark dark:text-item-dark-contrast',
            {
              [props.className!]: props.className,
            }
          )}
        >
          <div className="h-12 color-header flex justify-between items-center p-2 w-full modal-title">
            <div className="flex items-center gap-2">
              {title ? <h3 {...titleProps}>{title}</h3> : <div />}{' '}
              {props.titleElement}
            </div>
            <IconButton icon="Close" onPress={() => onClose?.()} size={24} />
          </div>
          <div className="p-2">{children}</div>
        </div>
      </FocusScope>
    </div>
  );
};
export default Modal;
