import { apiFetchHandler } from 'api/clientFetch';
import { hosts } from 'api/hosts';
import type {
  GatewayConfigurationOptions,
  GatewayNetworkConfig,
  GatewayRadioConfig,
} from './types';

export const getConfigurationOptions =
  apiFetchHandler<GatewayConfigurationOptions>({
    host: hosts.ops.v0,
    endpoint: '/gateway/configuration-options',
    errorMessage: 'Could not get gateway configuration options',
    method: 'GET',
  });

export type GetOptionsByMissionId = {
  missionId: number;
};

export const getNetworkConfigurationOptionsByMission = apiFetchHandler<
  GatewayNetworkConfig[],
  GetOptionsByMissionId
>({
  host: hosts.ops.v0,
  endpoint: ({ missionId }) =>
    `/gateway/mission/${missionId}/configuration/network`,
  errorMessage:
    'Could not get gateway network configuration options by mission',
  method: 'GET',
});

export const getRadioConfigurationOptionsByMissionId = apiFetchHandler<
  GatewayRadioConfig[],
  GetOptionsByMissionId
>({
  host: hosts.ops.v0,
  endpoint: ({ missionId }) =>
    `/gateway/mission/${missionId}/configuration/radio`,
  errorMessage: 'Could not get gateway radio configuration options by mission',
  method: 'GET',
});

export type PutNetworkSettingsByMissionParams = {
  id: number;
  protocol: string;
};

export const putNetworkSettingsByMissionHandler = apiFetchHandler<
  undefined,
  PutNetworkSettingsByMissionParams,
  GatewayNetworkConfig
>({
  host: hosts.ops.v0,
  endpoint: ({ id, protocol }) =>
    `/gateway/mission/${id}/configuration/network/${protocol}`,
  errorMessage: 'Could not update gateway network configuration',
  method: 'PUT',
});

export type PutRadioSettingsByMissionParams = {
  id: number;
  radio: string;
};

export const putRadioSettingsByMissionHandler = apiFetchHandler<
  undefined,
  PutRadioSettingsByMissionParams,
  GatewayRadioConfig
>({
  host: hosts.ops.v0,
  endpoint: ({ id, radio }) =>
    `/gateway/mission/${id}/configuration/radio/${radio}`,
  errorMessage: 'Could not update gateway radio configuration',
  method: 'PUT',
});

export type DeleteNetworkSettingsByMissionParams = {
  id: number;
  protocol: string;
};

export const deleteNetworkSettingsByMissionHandler = apiFetchHandler<
  undefined,
  DeleteNetworkSettingsByMissionParams
>({
  host: hosts.ops.v0,
  endpoint: ({ id, protocol }) =>
    `/gateway/mission/${id}/configuration/network/${protocol}`,
  errorMessage: 'Could not delete gateway network configuration',
  method: 'DELETE',
});

export type DeleteRadioSettingsByMissionParams = {
  id: number;
  radio: string;
};

export const deleteRadioSettingsByMissionHandler = apiFetchHandler<
  undefined,
  DeleteRadioSettingsByMissionParams
>({
  host: hosts.ops.v0,
  endpoint: ({ id, radio }) =>
    `/gateway/mission/${id}/configuration/radio/${radio}`,
  errorMessage: 'Could not delete gateway radio configuration',
  method: 'DELETE',
});
