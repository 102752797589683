import type { ReactElement } from 'react';
import React from 'react';
import type { WithTranslation } from 'react-i18next';
import { withTranslation } from 'react-i18next';
import { Button } from '@blueprintjs/core';
import type { IPopUp } from '../../constants/popUp/actionTypes';
import type { IPointOfInterest } from '../../constants/pointsOfInterest/actionTypes';
import type { IRegionOfInterest } from '../../constants/regionsOfInterest/actionTypes';
import MarkerForm from '../popUp/MarkerForm';
import PolygonForm from '../popUp/PolygonForm';
import type { IFocus } from '../../constants/focus/actionTypes';
import { FocusTypes } from '../../constants/focus/actionTypes';
import type { IMapWrapper } from '../../declarations/mapDeclarations/Map';

interface IEventObjectProps extends WithTranslation {
  map: IMapWrapper;
  eventObject?: IPointOfInterest | IRegionOfInterest;
  setPopUp?: Function;
  updateEvent?: Function;
  removeEvent?: Function;
  events?: (IPointOfInterest | IRegionOfInterest)[];
  setFocus: (focus: IFocus) => void;
  focused?: boolean;
  modesOnly?: boolean;
}

interface IGeometryState {}

class EventObject extends React.Component<IEventObjectProps, IGeometryState> {
  public state: IGeometryState = {};

  private updateEventObject = (): void => {
    const { setPopUp, updateEvent, eventObject, setFocus, map } = this.props;

    const focus: IFocus = {
      id: eventObject.id,
      withPopUp: true,
      needScroll: true,
      type: eventObject.type as FocusTypes,
    };

    let component = null;
    switch (eventObject.type) {
      case FocusTypes.PI_SUB_ID: {
        component = MarkerForm;
        break;
      }
      case FocusTypes.RI_SUB_ID: {
        component = PolygonForm;
        break;
      }
    }

    const popUp: IPopUp = {
      visible: true,
      classNames: ['short-container'],
      component: component,
      data: eventObject,
      otherProps: {
        type: eventObject.type,
        modesOnly: this.props.modesOnly,
      },
      functions: {
        onSubmit: updateEvent,
        onClose: (): void => {},
        addRegion: (): void => {
          map.drawingManager.setDrawingMode(
            google.maps.drawing.OverlayType.POLYGON
          );
        },
      },
    };

    setFocus(focus);
    setPopUp(popUp);
  };
  private removeEventObject = () => {
    const { eventObject, removeEvent } = this.props;
    removeEvent(eventObject.id);
  };

  public render(): ReactElement {
    const { eventObject, t } = this.props;

    return (
      <div className={`geometry-object gray`}>
        <div
          title={`type:\t${t(
            `events-table.types.${eventObject.type}` as any
          )}\nname:\t${eventObject.name}`}
          className="geometry-object_name"
        >
          <div>{t(`events-table.types.${eventObject.type}` as any)}</div>
          <div>{eventObject.name}</div>
        </div>
        <div className="geometry-object_buttons">
          <Button
            icon="settings"
            onClick={this.updateEventObject}
            style={{ maxWidth: 32 }}
          />
          {!this.props.modesOnly && (
            <Button
              intent="danger"
              icon="delete"
              onClick={this.removeEventObject}
            />
          )}
        </div>
      </div>
    );
  }
}

export default withTranslation()(EventObject);
