import { useEffect, useMemo, useRef, useState } from 'react';
import useStacInfo from '../../utils/hooks/useStacInfo';
import type { StacItem } from 'datacosmos/types/stac-types';
import utils from 'datacosmos/utils/utils';
import { useLocalisation } from 'utils/hooks/useLocalisation';
import MetadataModal from '_organisms/MetadataModal/MetadataModal';
import Fuse from 'fuse.js';
import StatisticsPopup from '_organisms/StatisticsPopup/StatisticsPopup';
import { useMapLayers } from 'datacosmos/stores/MapLayersProvider';
import { LayerSourceType } from 'datacosmos/entities/layer';
import type { PolygonLayer } from 'datacosmos/entities/polygonLayer';
import { PolygonLayerFactory } from 'datacosmos/entities/polygonLayer';
import { useMap } from 'datacosmos/stores/MapProvider';
import area from '@turf/area';
import { getVisualAssetKey } from 'datacosmos/utils/stac';
import IconButton from '_molecules/IconButton/IconButton';
import { screenshot } from 'utils/screenshot';
import { downloadFilUrlAsExtension } from 'datacosmos/download/geojson';
import { Button } from 'opencosmos-ui/src/core/Button/Button';
import { copyToClipBoard } from 'utils/common/CommonUtils';

type TooltipContentProps = {
  data: StacItem;
};

export const TooltipContent = ({ data }: TooltipContentProps) => {
  const {
    observationZenithAngle,
    sensors,
    isFetchingMetadata,
    minimumPixelValue,
    maximumPixelValue,
    meanValue,
    stdValue,
    varianceValue,
    percentile25Value,
    percentile75Value,
    histogramData,
    getHistogramColor,
    fetchAssetsMetadata,
  } = useStacInfo(data);

  const { layers, removeLayersBySourceType, addLayer } = useMapLayers();
  const { drawPolygon } = useMap();

  const enrichedProperties = (si: StacItem) => {
    return {
      ...si.properties,
      item: si.id,
      collection: si.collection ?? '',
    };
  };

  const { translate } = useLocalisation();
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [metadata, setMetadata] = useState<StacItem['properties']>(
    enrichedProperties(data)
  );

  const [isStatisticsModalOpen, setIsStatisticsModalOpen] =
    useState<boolean>(false);

  // selectedStatisticsAsset is the asset that is currently selected in the statistics modal for which the statistics are displayed
  const [selectedStatisticsAsset, setSelectedStatisticsAsset] = useState<
    string | undefined
  >(getVisualAssetKey(data));

  const statisticsAoi = useMemo(
    () =>
      layers.find((l) => l.sourceType === LayerSourceType.STATISTICS_AOI) as
        | PolygonLayer
        | undefined,
    [layers]
  );

  const statisticsPopupRef = useRef<HTMLDivElement>(null);

  const getStatisticsForAoi = async () => {
    removeLayersBySourceType(LayerSourceType.STATISTICS_AOI);
    const { polygon, polygonMetadata } = await drawPolygon();

    const aoi = PolygonLayerFactory(
      LayerSourceType.STATISTICS_AOI,
      'Statistics AOI',
      polygon,
      area(polygon),
      polygonMetadata
    );
    addLayer(aoi);
  };

  const handleStatistticsAoiDelete = async () => {
    removeLayersBySourceType(LayerSourceType.STATISTICS_AOI);

    await fetchAssetsMetadata(undefined, undefined, {
      doNotCache: true,
    });
  };

  const metadataToSearchableArray = (meta: StacItem['properties']) =>
    Object.entries(meta).map(([key, val]) => ({
      id: key,
      val: val as string,
    }));

  const searchableMetadataToDisplayableObject = (
    metaArray: { id: string; val: string }[]
  ) =>
    metaArray.reduce((acc, item) => {
      acc = { ...acc, [item.id]: item.val };
      return acc;
    }, []);

  const fuse = useMemo(
    () =>
      new Fuse(metadataToSearchableArray(enrichedProperties(data)), {
        keys: ['id', 'val'],
        minMatchCharLength: 2,
        threshold: 0.4,
      }),
    [data]
  );

  const handleCopyLinkClick = () => {
    if (data.collection) {
      const linkToBeCopied = `${
        window.location.href
      }?view-item=${encodeURIComponent(`${data.collection}/${data.id}`)}`;
      copyToClipBoard(linkToBeCopied);
    }
  };

  useEffect(() => {
    void fetchAssetsMetadata(
      undefined,
      statisticsAoi?.data as GeoJSON.Feature<GeoJSON.Polygon>,
      { doNotCache: statisticsAoi !== undefined }
    );
  }, [fetchAssetsMetadata, statisticsAoi]);

  // When adding or removing the statistics AOI, reset the selected asset back to the visual asset
  useEffect(() => {
    setSelectedStatisticsAsset(getVisualAssetKey(data));
  }, [data, statisticsAoi]);

  useEffect(() => {
    setMetadata(
      searchTerm === ''
        ? enrichedProperties(data)
        : searchableMetadataToDisplayableObject(fuse.search(searchTerm))
    );
  }, [data, data.properties, fuse, searchTerm]);

  const statisticsButtonText =
    histogramData && histogramData.length !== 0
      ? translate('datacosmos.catalogAndItems.metadata.showStatistics')
      : translate('datacosmos.catalogAndItems.metadata.noHistogramData');

  return (
    <ul className="flex flex-col gap-2 dark:text-item-dark-contrast">
      <li className="flex items-center gap-2">
        <span>{translate('datacosmos.catalogAndItems.metadata.oza')} </span>
        <span>{observationZenithAngle}</span>
      </li>
      <li className="flex items-center gap-2">
        <span>{translate('datacosmos.catalogAndItems.metadata.sensors')} </span>
        <span>{sensors}</span>
      </li>
      <li className="flex items-center gap-2">
        <span>
          {translate('datacosmos.catalogAndItems.metadata.resolution')}{' '}
        </span>
        <span>
          {data.properties.gsd ? utils.gsdToUnit(data.properties.gsd) : '-'}
        </span>
      </li>
      <li className="flex items-center gap-2">
        <StatisticsPopup
          selectedAssetKey={selectedStatisticsAsset}
          buttonDisabled={histogramData?.length === 0}
          buttonText={isFetchingMetadata ? '..loading' : statisticsButtonText}
          getHistogramColor={getHistogramColor}
          getStatisticsForAoiHandler={getStatisticsForAoi}
          histogramData={histogramData}
          isFetchingMetadata={isFetchingMetadata}
          isOpen={isStatisticsModalOpen}
          modalTitle={translate(
            'datacosmos.catalogAndItems.metadata.statistics'
          )}
          propertiesData={{
            minimumPixelValue: minimumPixelValue,
            maximumPixelValue: maximumPixelValue,
            meanValue: meanValue,
            stdValue: stdValue,
            varianceValue: varianceValue,
            percentile25Value: percentile25Value,
            percentile75Value: percentile75Value,
          }}
          removeStatisticsAoiHandler={handleStatistticsAoiDelete}
          setIsOpen={setIsStatisticsModalOpen}
          isStatisticsAoiPresent={statisticsAoi !== undefined}
          assetsToDisplay={data.assets}
          onAssetClick={async (assetKey) => {
            setSelectedStatisticsAsset(assetKey);
            await fetchAssetsMetadata(
              assetKey,
              statisticsAoi?.data as GeoJSON.Feature<GeoJSON.Polygon>,
              {
                doNotCache: true,
              }
            );
          }}
          titleElement={
            <IconButton
              icon="Camera"
              onPress={async () => {
                if (!statisticsPopupRef.current) {
                  return;
                }

                const imgString = await screenshot(statisticsPopupRef.current, {
                  mimeType: 'image/png',
                  ignore: ['draw-aoi-btn', 'remove-aoi-btn', 'modal-title'],
                });

                downloadFilUrlAsExtension(
                  imgString,
                  `band_statistics_${new Date().toISOString()}`,
                  'png'
                );
              }}
            />
          }
          popupRef={statisticsPopupRef}
        />
      </li>
      <li
        className="flex items-center gap-2"
        onClick={(e) => e.stopPropagation()}
      >
        <MetadataModal
          data={metadata}
          modalTitle={translate(
            'datacosmos.catalogAndItems.metadataModal.title'
          )}
          buttonText={translate(
            'datacosmos.catalogAndItems.metadataModal.triggerButton'
          )}
          searchBarProps={{
            fullWidth: true,
            placeholder: translate(
              'datacosmos.catalogAndItems.metadataModal.placeholder'
            ),
            onChange: (text) => setSearchTerm(text),
            onSubmit: (text) => setSearchTerm(text),
          }}
        />
      </li>
      <li>
        <Button
          text={translate('datacosmos.catalogAndItems.metadata.copyLink')}
          icon={'File'}
          iconPlacement="right"
          onPress={handleCopyLinkClick}
          fill
        />
      </li>
    </ul>
  );
};
