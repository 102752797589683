import type { ECharts } from 'echarts';

export const getLegendSelected = (
  chart: ECharts
): { checked: boolean; indeterminate: boolean } => {
  const res = {
    checked: false,
    indeterminate: true,
  };
  if (chart) {
    const options = chart.getOption();
    if (options) {
      const legends = options.legend as object[];
      const legend = legends[0] as { selected: { [key: string]: boolean } };
      if (legend) {
        const selected = legend.selected;
        let trulyCounter = 0;
        for (const key in selected) {
          if (selected[key]) {
            trulyCounter++;
          }
        }
        if (trulyCounter === Object.keys(selected).length) {
          res.checked = true;
          res.indeterminate = false;
        } else if (!trulyCounter) {
          res.checked = false;
          res.indeterminate = false;
        }
        return res;
      }
    }
  }
  return undefined;
};
