import type { StacItem } from 'datacosmos/types/stac-types';
import useStacInfo from 'datacosmos/utils/hooks/useStacInfo';
import { getThumbnailAssetKey } from 'datacosmos/utils/stac';
import { useMemo } from 'react';
import Icon from '_atoms/Icon/Icon';
import Tooltip from '_atoms/Tooltip/Tooltip';
import IconButton from '_molecules/IconButton/IconButton';
import { useLocalisation } from 'utils/hooks/useLocalisation';

interface IProps {
  currentImage: StacItem | undefined;
  setCurrentImage: (image: StacItem) => void;
  imagesToPurchase: StacItem[];
}

const ConfirmPurchaseImageCarousel = ({
  currentImage,
  setCurrentImage,
  imagesToPurchase,
}: IProps) => {
  const thumbnailKey = currentImage ? getThumbnailAssetKey(currentImage) : '';

  const { translate } = useLocalisation();

  const { sunElevationAngle, cloudCoverage } = useStacInfo(currentImage);

  const currentItemIndex = useMemo(
    () => imagesToPurchase.findIndex((img) => img.id === currentImage?.id),
    [currentImage?.id, imagesToPurchase]
  );

  return (
    <div data-testid="image-carousel">
      <div className="w-[450px] h-[450px]">
        <img
          src={
            currentImage?.assets[thumbnailKey]?.href ??
            '/images/datacosmos/img-placeholder.svg'
          }
          alt="Image thumbnail"
          data-testid="image-thumbnail"
          className="object-cover w-[450px] h-[450px]"
        />
      </div>

      <div className="flex items-center justify-between mt-2">
        <IconButton
          data-testid="previous-image"
          icon="ChevronLeft"
          onPress={() => {
            if (currentItemIndex <= 0) {
              setCurrentImage(imagesToPurchase[imagesToPurchase.length - 1]);
            } else {
              setCurrentImage(imagesToPurchase[currentItemIndex - 1]);
            }
          }}
          size={32}
        />
        <div
          data-testid="image-info"
          className="grid grid-cols-3 text-center !shadow-none"
        >
          <div className="flex items-center gap-1 justify-center">
            <Tooltip
              content={translate(
                'datacosmos.tooltips.catalogAndItems.purchaseDialog.satelliteName'
              )}
              delay={0}
            >
              <Icon icon="Satellite" />
              {currentImage?.title()}
            </Tooltip>
          </div>
          <div className="flex items-center gap-1 justify-center">
            <Tooltip
              content={translate(
                'datacosmos.tooltips.catalogAndItems.purchaseDialog.location'
              )}
              delay={0}
            >
              <Icon icon="Pin" />
              {currentImage?.location()}
            </Tooltip>
          </div>
          <div>
            <Tooltip
              content={translate(
                'datacosmos.tooltips.catalogAndItems.purchaseDialog.date'
              )}
              delay={0}
            >
              {currentImage?.date()}
            </Tooltip>
          </div>
          <div className="flex items-center gap-1 justify-center">
            <Tooltip
              content={translate(
                'datacosmos.tooltips.catalogAndItems.purchaseDialog.sunElevationAngle'
              )}
              delay={0}
            >
              <Icon icon="SunAngle" />
              {sunElevationAngle}
            </Tooltip>
          </div>
          <div className="flex items-center gap-1 justify-center">
            <Tooltip
              content={translate(
                'datacosmos.tooltips.catalogAndItems.purchaseDialog.cloudCoverage'
              )}
              delay={0}
            >
              <Icon icon="Cloud" />
              {cloudCoverage}
            </Tooltip>
          </div>
        </div>
        <IconButton
          data-testid="next-image"
          icon="ChevronRight"
          onPress={() => {
            if (currentItemIndex === imagesToPurchase.length - 1) {
              setCurrentImage(imagesToPurchase[0]);
            } else {
              setCurrentImage(imagesToPurchase[currentItemIndex + 1]);
            }
          }}
          size={32}
        />
      </div>
    </div>
  );
};

export default ConfirmPurchaseImageCarousel;
