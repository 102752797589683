import React from 'react';
import { Switch } from '@blueprintjs/core';
import classNames from 'classnames';

export interface IProps {
  value: any;
  disabled: boolean;
  fullPath: string;
  defaultValue: boolean;
  onChange: Function;
  handleBlur: () => void;
  handleFocus: () => void;
  required: boolean;
  setTooltipOpen: (open: boolean) => void;
}

const CommandPrimitiveBoolean = (props: IProps) => {
  const {
    value,
    onChange,
    handleBlur,
    handleFocus,
    disabled,
    fullPath,
    defaultValue,
    required,
  } = props;

  const val = Boolean(value || defaultValue);

  return (
    <>
      <Switch
        disabled={disabled}
        checked={val}
        onBlur={handleBlur}
        onFocus={handleFocus}
        onMouseOut={!disabled ? handleBlur : undefined}
        onMouseOver={!disabled ? handleFocus : undefined}
        onChange={() => onChange(!value, fullPath)}
        className={classNames({
          'border-2 border-red-500': required,
        })}
      />
      <span>{val ? 'Enabled' : 'Disabled'}</span>
    </>
  );
};

export default CommandPrimitiveBoolean;
