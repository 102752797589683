import type { ECharts } from 'echarts';
import { getLegendSelected } from './getLegendSelected';

export const onChangeSelectAll = (chart: ECharts, setValue?: boolean): void => {
  const isSelectAll = getLegendSelected(chart);
  const options = chart.getOption();
  if (options) {
    const legends = options.legend as object[];
    const legend = legends[0] as { selected: { [key: string]: boolean } };
    const selected = legend.selected;
    if (setValue === true) {
      for (const key in selected) {
        selected[key] = true;
      }
    } else if (setValue === false) {
      for (const key in selected) {
        selected[key] = false;
      }
    } else {
      if (isSelectAll.indeterminate) {
        for (const key in selected) {
          selected[key] = true;
        }
      } else if (!isSelectAll.checked) {
        for (const key in selected) {
          selected[key] = true;
        }
      } else {
        for (const key in selected) {
          selected[key] = false;
        }
      }
    }
    chart.setOption({
      legend: {
        ...legend,
        selected,
      },
    });
  }
};
