import { apiFetchHandler } from 'api/clientFetch';
import { hosts } from 'api/hosts';
import { formatGetFiringAlertsUrl, formatGetSilencesUrl } from './helpers';
import type { FiringAlert, Silence, UpdateAlertSilenceRequest } from './types';

export const postSilence = apiFetchHandler<
  undefined,
  {},
  UpdateAlertSilenceRequest
>({
  host: hosts.ops.v0,
  endpoint: '/alertmanager/silences',
  method: 'POST',
  errorMessage: 'Could not post silence',
});

export type GetSilencesParams = {
  missionId: string | number | undefined;
  alertName?: string;
  silenced?: boolean;
  inhibited?: boolean;
  active?: boolean;
};

export const getSilences = apiFetchHandler<Silence[], GetSilencesParams>({
  host: hosts.ops.v0,
  endpoint: (params) => formatGetSilencesUrl(params),
  method: 'GET',
  errorMessage: 'Could not get silences',
});

export const getAllSilences = apiFetchHandler<Silence[]>({
  host: hosts.ops.v0,
  endpoint: '/alertmanager/silences',
  method: 'GET',
  errorMessage: 'Could not get all silences',
});

export type ExpireSilenceParams = {
  silenceId: string;
};

export const deleteSilence = apiFetchHandler<undefined, ExpireSilenceParams>({
  host: hosts.ops.v0,
  endpoint: ({ silenceId }) => `/alertmanager/silences/${silenceId}`,
  method: 'DELETE',
  errorMessage: 'Could not delete silence',
});

export type GetFiringAlertsParams = {
  currentMissionId?: string | number;
  silenced?: boolean;
  inhibited?: boolean;
  active?: boolean;
};

export const getFiringAlerts = apiFetchHandler<
  FiringAlert[],
  GetFiringAlertsParams
>({
  host: hosts.ops.v0,
  endpoint: (params) => formatGetFiringAlertsUrl(params),
  method: 'GET',
  errorMessage: 'Could not get firing alerts',
});
