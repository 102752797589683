import type { Layer } from 'datacosmos/entities/layer';
import type { SingleBandSTACLayer } from 'datacosmos/entities/singleBandLayer';
import tilingApi from 'datacosmos/services/tilingApi';
import { useEffect, useState } from 'react';
import Icon from '_atoms/Icon/Icon';
import { range } from 'lodash';
import Popover2 from '_atoms/Popover/Popover2';
import RangeSlider from '_molecules/RangeSlider/RangeSlider';
import { Tooltip } from 'ui/Tooltip';
import { useLocalisation } from 'utils/hooks/useLocalisation';

interface IProps {
  disabled: boolean;
  brightnessSupportedLayers: SingleBandSTACLayer[];
  replaceLayer: (layer: Layer, newIndex?: number) => void;
}

const BrightnessControl = (props: IProps) => {
  const { translate } = useLocalisation();
  const channelCount =
    tilingApi.statistics && Object.keys(tilingApi.statistics).length;

  const [brightness, setBrightness] = useState<number[]>([]);

  let r = 1;
  let g = 1;
  let b = 1;

  if (
    channelCount === 1 &&
    props.brightnessSupportedLayers[0]?.options.brightness &&
    typeof props.brightnessSupportedLayers[0]?.options.brightness !== 'number'
  ) {
    r = props.brightnessSupportedLayers[0].options.brightness.r;
    g = props.brightnessSupportedLayers[0].options.brightness.g;
    b = props.brightnessSupportedLayers[0].options.brightness.b;
  }

  useEffect(() => {
    if (
      typeof props.brightnessSupportedLayers[0]?.options.brightness ===
        'number' &&
      props.brightnessSupportedLayers[0]?.options.brightness === 1
    ) {
      setBrightness([1, 1, 1]);
    }
  }, [props.brightnessSupportedLayers]);

  useEffect(() => {
    setBrightness([r, g, b]);
  }, [channelCount, r, g, b]);

  const getLabelText = (i: number) => {
    if (!channelCount || (channelCount && channelCount === 1)) {
      return 'Brightness';
    }
    if (i === 0) return 'R';
    if (i === 1) return 'G';
    if (i === 2) return 'B';
    return '';
  };

  const getIndexBasedBrightnessAmount = (i: number, value: number[]) => {
    if (!channelCount || (channelCount && channelCount === 1)) {
      return value[0];
    }

    if (i === 0) {
      return {
        r: value[0],
        g: brightness[1],
        b: brightness[2],
      };
    }
    if (i === 1) {
      return {
        r: brightness[0],
        g: value[0],
        b: brightness[2],
      };
    }
    if (i === 2) {
      return {
        r: brightness[0],
        g: brightness[1],
        b: value[0],
      };
    }

    return 0;
  };

  return (
    <Popover2
      className="w-64 p-1"
      placement="bottom end"
      offset={0}
      disabled={props.disabled}
      isDismissable={true}
      popupContent={
        <div data-testid="brightness-popup">
          {channelCount ? (
            range(channelCount).map((i) => (
              <RangeSlider
                key={i}
                label={{
                  text: getLabelText(i),
                  icon: (
                    <Icon icon="Brightness" className="fill-item-contrast" />
                  ),
                }}
                showScale={false}
                showValuesAboveHandles
                numberOfHandles={1}
                minValue={0.1}
                step={0.1}
                maxValue={2}
                value={[brightness[i]]}
                onChange={(val) => {
                  setBrightness((prev) => {
                    const newArr = prev.slice();
                    newArr[i] = val[0];
                    return newArr;
                  });
                  props.brightnessSupportedLayers[0].setSettingBrightnessOn();
                }}
                onChangeEnd={(val) => {
                  const brightnessAmount = getIndexBasedBrightnessAmount(
                    i,
                    val
                  );
                  props.replaceLayer(
                    props.brightnessSupportedLayers[0].cloneWithOptions({
                      brightness: brightnessAmount,
                    })
                  );
                }}
              />
            ))
          ) : (
            <span>Loading...</span>
          )}
        </div>
      }
    >
      <Tooltip
        content={
          props.disabled
            ? `${translate('datacosmos.layers.brightness')} - ${translate(
                'datacosmos.layers.layerOperationDisabled'
              )}`
            : translate('datacosmos.layers.brightness')
        }
      >
        <Icon
          icon="Brightness"
          size={24}
          title="layer brightness"
          className={
            props.disabled ? 'cursor-not-allowed opacity-50' : 'cursor-pointer'
          }
        />
      </Tooltip>
    </Popover2>
  );
};

export default BrightnessControl;
