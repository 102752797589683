import { useRef } from 'react';
import type { AriaNumberFieldProps } from '@react-aria/numberfield';
import classNames from 'classnames';
import { useLocale, useNumberField } from 'react-aria';
import { useNumberFieldState } from 'react-stately';
import Button from '_molecules/Button/Button';

interface INumberInputProps extends Omit<AriaNumberFieldProps, 'locale'> {
  className?: string;
  /**
   * Whether or not the number input should fill the container it's in
   */
  fill?: true;
}

const NumberInput = (props: INumberInputProps) => {
  const { locale } = useLocale();
  const state = useNumberFieldState({ ...props, locale });

  const inputRef = useRef<HTMLInputElement>(null);
  const {
    labelProps,
    groupProps,
    decrementButtonProps,
    incrementButtonProps,
    inputProps,
  } = useNumberField(props, state, inputRef);

  return (
    <div
      className={classNames({
        'w-full': props.fill,
        'w-fit': !props.fill,
      })}
    >
      {props.label && <label {...labelProps}>{props.label}</label>}
      <div {...groupProps} className="flex items-center w-full">
        <input
          {...inputProps}
          ref={inputRef}
          className={classNames(
            'w-full border-2 border-neutral-600 color-item',
            'pl-2 pr-2 h-8 text-sm border-r-0',
            {
              'bg-item-inactive dark:bg-item-inactive-dark': props.isDisabled,
            }
          )}
        />
        <div className="flex flex-col ">
          <Button
            {...incrementButtonProps}
            icon="ChevronUp"
            className="h-4 flex items-center justify-center p-0 pl-2  border-b-[1px] !box-border border-2 border-neutral-600"
            disabled={props.isDisabled}
          />
          <Button
            {...decrementButtonProps}
            icon="ChevronDown"
            className="h-4 flex items-center justify-center p-0 pl-2 !box-border  border-t-[1px] border-2 border-neutral-600"
            disabled={props.isDisabled}
          />
        </div>
      </div>
    </div>
  );
};

export default NumberInput;
