import type { SingleBandSTACLayer } from 'datacosmos/entities/singleBandLayer';
import type {
  ICOGMetadata,
  IGeoJSONMetadata,
} from 'datacosmos/services/tilingApi';
import tilingApi from 'datacosmos/services/tilingApi';
import type { IAsset, StacItem } from 'datacosmos/types/stac-types';
import {
  currencySymbols,
  getThumbnailAssetKey,
  isAssetPreviewable,
} from 'datacosmos/utils/stac';
import { useAuth } from 'services/auth/AuthWrapper';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { BandAlgebraSTACLayer } from 'datacosmos/entities/bandAlgebraLayer';
import type { COGMetadataForRange } from 'datacosmos/services/tilingApi/cogMetadata';

export type IStacInfo = ReturnType<typeof useStacInfo>;

export interface IHistogramData {
  [name: string]: number | number[] | null;
}

export interface ICachedData {
  [itemId: string]:
    | {
        minimumPixelValue: {
          val: number | null;
          retreive: () => void;
        };
        maximumPixelValue: {
          val: number | null;
          retreive: () => void;
        };
        meanValue: {
          val: number | null;
          retreive: () => void;
        };
        stdValue: {
          val: number | null;
          retreive: () => void;
        };
        varianceValue: {
          val: number | null;
          retreive: () => void;
        };
        percentile25Value: {
          val: number | null;
          retreive: () => void;
        };
        percentile75Value: {
          val: number | null;
          retreive: () => void;
        };
        histogramData: {
          val: IHistogramData[];
          retreive: () => void;
        };
        geoJsonMetadata: {
          val: IGeoJSONMetadata;
          retreive: () => void;
        };
      }
    | undefined;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const isLayer = (stac: any): stac is SingleBandSTACLayer =>
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  stac?.cloneWithOptions !== undefined;

const isCOGMetadata = (
  metadata: COGMetadataForRange | IGeoJSONMetadata | undefined
): metadata is COGMetadataForRange => metadata?.histogramData !== undefined;

const useStacInfo = (stac: SingleBandSTACLayer | StacItem | undefined) => {
  const [jsonMetadata, setJsonMetadata] = useState<
    ICOGMetadata | IGeoJSONMetadata
  >();

  const [selectedBands, setSelectedBands] = useState<[string, IAsset][]>([]);
  const [previewUrl, setPreviewUrl] = useState<string | undefined>(undefined);

  const [minimumPixelValue, setMinimumPixelValue] = useState<number | null>(
    null
  );
  const [maximumPixelValue, setMaximumPixelValue] = useState<number | null>(
    null
  );
  const [meanValue, setMeanValue] = useState<number | null>(null);
  const [stdValue, setStdValue] = useState<number | null>(null);
  const [varianceValue, setVarianceValue] = useState<number | null>(null);
  const [percentile25Value, setPercentile25Value] = useState<number | null>(
    null
  );
  const [percentile75Value, setPercentile75Value] = useState<number | null>(
    null
  );

  const [histogramData, setHistogramData] = useState<IHistogramData[] | null>(
    []
  );

  const [isFetchingMetadata, setIsFetchingMetadata] = useState<boolean>(true);

  const [fetchedMetadata, setFetchedMetadata] = useState<
    ICachedData | undefined
  >({});

  const { token } = useAuth();

  const item = useMemo(() => (isLayer(stac) ? stac.item : stac), [stac]);
  const assetKey = useMemo(
    () => (isLayer(stac) ? stac.assetKey : 'visual'),
    [stac]
  );

  const cacheFetchedData = useCallback(
    (data: COGMetadataForRange | IGeoJSONMetadata) => {
      if (isCOGMetadata(data)) {
        setFetchedMetadata({
          ...fetchedMetadata,
          [item?.id ?? '']: {
            geoJsonMetadata: {
              val: undefined,
              retreive: () => setJsonMetadata(undefined),
            },
            histogramData: {
              val: data.histogramData(),
              retreive: () => setHistogramData(data.histogramData()),
            },
            maximumPixelValue: {
              val: data.maximumPixelValue(),
              retreive: () => setMaximumPixelValue(data.maximumPixelValue()),
            },
            minimumPixelValue: {
              val: data.minimumPixelValue(),
              retreive: () => setMinimumPixelValue(data.minimumPixelValue()),
            },
            meanValue: {
              val: data.meanValue(),
              retreive: () => setMeanValue(data.meanValue()),
            },
            stdValue: {
              val: data.stdValue(),
              retreive: () => setStdValue(data.stdValue()),
            },
            varianceValue: {
              val: data.varianceValue(),
              retreive: () => setVarianceValue(data.varianceValue()),
            },
            percentile25Value: {
              val: data.percentile25Value(),
              retreive: () => setPercentile25Value(data.percentile25Value()),
            },
            percentile75Value: {
              val: data.percentile75Value(),
              retreive: () => setPercentile75Value(data.percentile75Value()),
            },
          },
        } as ICachedData);
      } else {
        setFetchedMetadata({
          ...fetchedMetadata,
          [item?.id ?? '']: {
            geoJsonMetadata: {
              val: data,
              retreive: () => setJsonMetadata(data),
            },
            histogramData: {
              val: null,
              retreive: () => setHistogramData([]),
            },
            maximumPixelValue: {
              val: null,
              retreive: () => setHistogramData([]),
            },
            minimumPixelValue: {
              val: null,
              retreive: () => setHistogramData([]),
            },
            meanValue: {
              val: null,
              retreive: () => setHistogramData([]),
            },
            stdValue: {
              val: null,
              retreive: () => setHistogramData([]),
            },
            varianceValue: {
              val: null,
              retreive: () => setHistogramData([]),
            },
            percentile25Value: {
              val: null,
              retreive: () => setHistogramData([]),
            },
            percentile75Value: {
              val: null,
              retreive: () => setHistogramData([]),
            },
          },
        } as ICachedData);
      }
    },
    [fetchedMetadata, item?.id]
  );

  const isInCache = useMemo(
    () =>
      fetchedMetadata &&
      Object.keys(fetchedMetadata).some(
        (meta) => (isLayer(stac) ? stac.item.id : stac?.id) === meta
      ),
    [fetchedMetadata, stac]
  );

  const retreiveFromCache = useCallback(() => {
    if (!fetchedMetadata) {
      return;
    }

    if (!stac) {
      return;
    }

    if (!fetchedMetadata[stac.id]) {
      return;
    }
    Object.values(fetchedMetadata[stac.id] ?? {}).map((meta) =>
      meta.retreive()
    );
  }, [fetchedMetadata, stac]);

  const fetchAssetsMetadata = useCallback(
    async (
      forAssetKey?: string,
      forRegion?: GeoJSON.Feature<GeoJSON.Polygon>,
      options?: { doNotCache?: boolean }
    ) => {
      setIsFetchingMetadata(true);

      const assetKeyToUse = forAssetKey ?? assetKey;

      if (isInCache && !options?.doNotCache) {
        retreiveFromCache();
        setIsFetchingMetadata(false);
        return;
      }

      if (!item?.assets?.[assetKeyToUse]?.href) {
        setIsFetchingMetadata(false);
        return;
      }

      const isAssetGeoJSON =
        item.assets?.[assetKeyToUse].type === 'application/geo+json';

      if (isAssetGeoJSON) {
        const jsonMeta = await tilingApi.fetchMetadataForGeoJSON(
          item.assets?.[assetKeyToUse].href
        );

        if (!options?.doNotCache) {
          cacheFetchedData(jsonMeta);
        }
        setIsFetchingMetadata(false);
        setJsonMetadata(jsonMeta);
      } else {
        const meta = await tilingApi.fetchMetadataForCOG(
          item.assets?.[assetKeyToUse].href,
          token,
          forRegion
        );

        setMaximumPixelValue(meta.maximumPixelValue());
        setMinimumPixelValue(meta.minimumPixelValue());
        setMeanValue(meta.meanValue());
        setStdValue(meta.stdValue());
        setVarianceValue(meta.varianceValue());
        setPercentile25Value(meta.percentile25Value());
        setPercentile75Value(meta.percentile75Value());
        setHistogramData(meta.histogramData());

        if (!options?.doNotCache) {
          cacheFetchedData(meta);
        }
        setIsFetchingMetadata(false);
        setJsonMetadata(undefined);
      }
    },
    [
      assetKey,
      cacheFetchedData,
      isInCache,
      item?.assets,
      retreiveFromCache,
      token,
    ]
  );

  const sunElevationAngle = useMemo(() => {
    const itm: StacItem | undefined = isLayer(stac) ? stac?.item : stac;

    const foundSza: number | undefined = itm?.properties?.[
      'view:sun_elevation'
    ] as number | undefined;

    if (foundSza && isFinite(foundSza)) {
      return Number(foundSza).toFixed(2) + '°';
    }
    return 'N/A';
  }, [stac]);

  const cloudCoverage = useMemo(() => {
    const itm: StacItem | undefined = isLayer(stac) ? stac?.item : stac;

    const foundClouds: number | undefined = (itm?.properties?.[
      'view:cloud_coverage'
    ] ?? itm?.properties?.['eo:cloud_cover']) as number | undefined;
    if (foundClouds && isFinite(foundClouds)) {
      return Number(foundClouds).toFixed(2) + '%';
    }
    return 'N/A';
  }, [stac]);

  const observationZenithAngle = useMemo(() => {
    const itm: StacItem | undefined = isLayer(stac) ? stac?.item : stac;
    const foundOza: number | undefined = itm?.properties?.[
      'view:incidence_angle'
    ] as number | undefined;
    if (foundOza && isFinite(foundOza)) {
      return Number(foundOza).toFixed(2) + '°';
    }
    return 'N/A';
  }, [stac]);

  const sensors = useMemo(() => {
    const itm: StacItem | undefined = isLayer(stac) ? stac?.item : stac;
    const foundSensors: string[] | undefined = itm?.properties
      ?.instruments as string[];

    if (foundSensors && Array.isArray(foundSensors)) {
      return foundSensors.join(', ');
    }
    return 'not available';
  }, [stac]);

  const thumbnailAsset = useMemo(() => {
    if (!item) {
      return undefined;
    }
    const thumbKey = getThumbnailAssetKey(item);

    if (!thumbKey) {
      return undefined;
    }

    return item.assets?.[thumbKey];
  }, [item]);

  const stacItemPrice = useMemo(() => {
    const itm: StacItem | undefined = isLayer(stac) ? stac?.item : stac;

    const itemPrice: number | undefined = itm?.properties?.[
      'opencosmos:price'
    ] as number | undefined;
    const itemCurrency: string | undefined = itm?.properties?.[
      'opencosmos:price_currency'
    ] as string | undefined;
    const currency = itemCurrency && (currencySymbols[itemCurrency] as string);
    if (itemPrice && isFinite(itemPrice) && currency) {
      return currency + Number(itemPrice);
    }
    return 'N/A';
  }, [stac]);

  const isCartActionDisabled = useMemo(() => {
    const itm: StacItem | undefined = isLayer(stac) ? stac?.item : stac;

    const itemPrice: number | undefined = itm?.properties?.[
      'opencosmos:price'
    ] as number | undefined;

    //handles zero case seperately as images with price zero can be added to cart
    if (itemPrice === 0) {
      return false;
    }

    return !itemPrice as boolean;
  }, [stac]);

  const isHighResPermissionGranted = useMemo(() => {
    const itm: StacItem | undefined = isLayer(stac) ? stac?.item : stac;

    const isHighResAvailable: boolean = itm?.properties?.[
      'opencosmos:high_resolution_read_permission'
    ] as boolean;

    return isHighResAvailable;
  }, [stac]);

  const getHistogramColor = (data: string) => {
    if (data.includes('red')) return '#FF695E';
    if (data.includes('green')) return '#5ABE96';
    if (data.includes('blue')) return '#5a89be';
    return '#E79531';
  };

  const nonPreviewableAssets = useMemo(
    () =>
      Object.entries(item?.assets ?? []).filter(
        ([, val]) => !isAssetPreviewable(val)
      ),
    [item?.assets]
  );
  const previewableAssets = useMemo(
    () =>
      Object.entries(item?.assets ?? []).filter(([, val]) =>
        isAssetPreviewable(val)
      ),
    [item?.assets]
  );

  const handleBandSelect = (itm: [string, IAsset] | undefined) => {
    if (!itm) {
      return;
    }
    setSelectedBands([...selectedBands, itm]);
  };

  const handleBandRemove = (itm: [string, IAsset] | undefined) => {
    if (!itm) {
      return;
    }
    setSelectedBands(selectedBands.filter((b) => b[0] !== itm[0]));
  };

  const isAssetSelected = (itm: [string, IAsset]) => assetKey === itm[0];

  const getSelectedAsset = useCallback(() => {
    const foundAsset = Object.entries(item?.assets ?? {}).find(
      ([key]) => assetKey === key
    );

    const exp = isLayer(stac)
      ? (stac as unknown as BandAlgebraSTACLayer).expression
      : '';
    return (
      foundAsset?.[1]?.title ??
      foundAsset?.[0] ??
      (exp.split('::').length > 1 ? exp.split('::')[1] : exp.split('::')[0])
    );
  }, [assetKey, item?.assets, stac]);

  const getAssetPreviewUrl = useCallback(async () => {
    if (!isLayer(stac)) {
      return;
    }

    if (assetKey === 'overview' || assetKey === 'visual') {
      return;
    }

    if (!item) {
      return;
    }

    if (stac instanceof BandAlgebraSTACLayer) {
      const formattedExpression =
        stac.expression.split('::').length > 1
          ? stac.expression.split('::')[1]
          : stac.expression.split('::')[0];
      const bandUrl = tilingApi.generateBandAlgebraPreviewUrl(
        item,
        formattedExpression,
        stac.options.scale,
        stac.options.colormap,
        stac.options.bandAlgebraType,
        stac.options.rgbExpression,
        stac.options.rescaleFalse
      );
      setPreviewUrl(bandUrl);
      return;
    }

    const url = await tilingApi.generateAssetPreviewUrl(
      item,
      assetKey,
      stac.options.color_formula
    );
    setPreviewUrl(url);
  }, [assetKey, item, stac]);

  useEffect(() => {
    void getAssetPreviewUrl();
  }, [getAssetPreviewUrl]);

  return {
    fetchAssetsMetadata,
    jsonMetadata,
    sunElevationAngle,
    cloudCoverage,
    observationZenithAngle,
    sensors,
    maximumPixelValue,
    minimumPixelValue,
    meanValue,
    stdValue,
    varianceValue,
    percentile25Value,
    percentile75Value,
    histogramData,
    thumbnailAsset,
    getHistogramColor,
    previewableAssets,
    nonPreviewableAssets,
    isFetchingMetadata,
    previewUrl,
    isAssetSelected,
    handleBandSelect,
    handleBandRemove,
    getSelectedAsset,
    selectedBands,
    stacItemPrice,
    isHighResPermissionGranted,
    isCartActionDisabled,
  };
};

export default useStacInfo;
