import type { IStacItem, StacItem } from 'datacosmos/types/stac-types';
import type { IGeoJSONLayerOptions } from './geojsonLayer';
import type { ILayerOptions } from './layer';
import { Layer, LayerSourceType } from './layer';

/**
 * Outline Layer is shown on the map in place of another STAC layer
 * until the STAC layer image can be loaded
 */
export class OutlineLayer extends Layer {
  options: ILayerOptions & IGeoJSONLayerOptions = {
    blendMode: 'normal',
    color: 'red',
    fillOpacity: 0,
    opacity: 1,
    visible: true,
    weight: 3,
  };

  item: StacItem; // TODO: Is it enough to have the item? should we check item.id + collection?,

  /**
   * @param renderer function that returns a React element with the UI that sets the specific options of this layer
   * @param item the STAC item one of whose assets will be displayed in place of this outline
   */
  constructor(item: StacItem) {
    super(LayerSourceType.ASSET_OUTLINE);

    this.item = item;
  }

  getName() {
    return this.item.properties.title || this.item.id || this.id;
  }

  getDescription() {
    return 'Pending';
  }

  containsSTACItem(item: IStacItem) {
    return item.id === this.item.id && item.collection === this.item.collection;
  }

  containsSTACAsset() {
    return false;
  }

  containsBandAlgebra() {
    return false;
  }

  /**
   * Looks for the sun/solar zenith angle in three possible properties:
   * view:sun_elevation, sun_elevation, SolarZenith (note zenith = 90 - elevation)
   * @returns the zenith angle if one of the properties is defined, else undefined
   */
  sza(): Number | undefined {
    if (!(this.item && this.item.properties)) {
      return undefined;
    }
    if (this.item.properties['view:sun_elevation']) {
      return 90.0 - Number(this.item.properties['view:sun_elevation']);
    }
    // TODO: remove after data has been cleaned
    if (this.item.properties.sun_elevation) {
      return 90.0 - Number(this.item.properties.sun_elevation);
    }
    // TODO: remove after data has been cleaned
    if (this.item.properties.SolarZenith) {
      return Number(this.item.properties.SolarZenith);
    }
    return undefined;
  }

  href(): string | undefined {
    return undefined;
  }
}
