import { toaster } from 'toaster';
import { parseAsBbox, parseAsPoint, parseAsPolygon } from './coordinateParsers';
import { convertToEPSG4326 } from './coordinateConverter';
import type { EPSG } from './epsgCoordinates';
import {
  dmsCoordinatesStringToDecimalString,
  numberArrayToCoordinateObject,
} from './coordinateHelpers';

export type Coordinates = {
  lng: number;
  lat: number;
};

const PARSERS = [parseAsPoint, parseAsBbox, parseAsPolygon];

/**
 * pruneCoordinateString takes a coordinate string and strips it of everything except dots, commas and numbers.
 * Useful if user inputs a coordinate array from a GeoJSON object, for example.
 * @param coord comma separated coordinate string
 * @returns pruned coordinate string
 */
const pruneCoordinateString = (coord: string) => {
  return coord
    .replace(/:/g, ' ')
    .replace(/[^.\-,0-9\s]/g, '')
    .replace(/(?<=\S)\s+(?=\S)/g, ',')
    .replace(/\s+/g, '')
    .replace(/(,)\1+/g, '$1');
};

/**
 * parseCoordinateString parses pairs of valid coordinates, in string form, into `Coordinate` object array
 * @param coord coordinate string
 * @returns `Coordinate` object array
 */
const parseCoordinateString = (coord: string, epgs?: EPSG) => {
  let coordString = '';

  coordString = dmsCoordinatesStringToDecimalString(coord);
  coordString = pruneCoordinateString(coordString);

  const coordArray = coordString.split(',').map((s) => parseFloat(s));

  const coordinates = numberArrayToCoordinateObject(coordArray);

  if (epgs) {
    const converted = convertToEPSG4326(coordinates, epgs);
    return numberArrayToCoordinateObject(converted);
  }

  return coordinates;
};

/**
 * coordinateStringToGeoJson takes a comma separated string of coordinates and parses them into a GeoJSON feature
 * @param coordStr comma separated coordinate string
 * @returns appropriate GeoJSON given the coordinates
 */
export const coordinateStringToGeoJson = (coordStr: string, epgs?: EPSG) => {
  let coordinates: Coordinates[];
  try {
    coordinates = parseCoordinateString(coordStr, epgs);
  } catch (error) {
    toaster.show({
      message: (error as { message: string }).message,
      intent: 'danger',
      icon: 'issue',
    });
    return undefined;
  }

  return PARSERS.map((parser) => {
    try {
      return parser(coordinates);
    } catch (error) {
      toaster.show({
        message: (error as { message: string }).message,
        intent: 'danger',
        icon: 'issue',
      });
      return undefined;
    }
  }).filter((result) => result !== undefined)[0];
};
