import type { ThunkAction } from 'redux-thunk';
import type { AppState } from '../../../../reducers/rootReducer';
import type { Action } from 'redux';
import { resetFocus } from '../../../../actions/focus/thunks';
import { setGroundStationSpecify } from '../map/events';
import type { IMapWrapper } from '../../../../declarations/mapDeclarations/Map';
import type { IContextMenu } from '../map/services';

export const getAddStation =
  (
    map: IMapWrapper
  ): ThunkAction<IContextMenu, AppState, null, Action<string>> =>
  (dispatch): IContextMenu => {
    return {
      text: 'Add ground station',
      subItems: [
        {
          text: 'Draw',
          clickEvent: (): void => {
            dispatch(resetFocus());
            map.drawingManager.isGroundStation = true;
            map.setDrawingMarkerMod();
          },
          icon: 'draw',
        },
        {
          text: 'Specify',
          clickEvent: (): void => {
            dispatch(resetFocus());
            dispatch(setGroundStationSpecify());
          },
          icon: 'geolocation',
        },
      ],
    };
  };
