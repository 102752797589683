import type { SwathLayer } from 'datacosmos/entities/SwathLayer';
import type { CommonOpportunity } from 'api/tasking/helpers';
import type { GeoJSONLayer } from 'datacosmos/entities/geojsonLayer';
import {
  getJoinedOverlapingArea,
  getRegionOfInterestCoveragePercent,
  getTotalArea,
} from 'datacosmos/utils/geojson';

type ConfirmRequestStatisticsProps = {
  confirmedSwaths: SwathLayer<CommonOpportunity>[];
  regionsOfInterest: GeoJSONLayer<unknown>[];
};

export const ConfirmRequestStatistics = ({
  confirmedSwaths,
  regionsOfInterest,
}: ConfirmRequestStatisticsProps) => {
  return (
    <div>
      <span className="confirm-request-subtitle combined-statistic-subtitle">
        Combined request statistics:
      </span>

      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          padding: '5px 15px',
        }}
      >
        <div className="confirm-request-stat-wrapper">
          <span>Joined overlaping area: </span>
          <span style={{ textAlign: 'right' }}>
            {getJoinedOverlapingArea(confirmedSwaths)} km²
          </span>
        </div>

        <div className="confirm-request-stat-wrapper">
          <span>Total imaged area: </span>
          <span style={{ textAlign: 'right' }}>
            {getTotalArea(confirmedSwaths)} km²
          </span>
        </div>

        <div className="confirm-request-stat-wrapper">
          <span>Region of interest coverage: </span>
          <span style={{ textAlign: 'right' }}>
            {getRegionOfInterestCoveragePercent(
              confirmedSwaths.map(
                ({ data }) => data
              ) as GeoJSON.Feature<GeoJSON.Polygon>[],
              regionsOfInterest.map(
                ({ data }) => data as GeoJSON.Feature<GeoJSON.Polygon>
              )
            )}
            %
          </span>
        </div>
      </div>
    </div>
  );
};
