import moment from 'moment';
import { isEmpty, isUndefined } from 'lodash/fp';
import { DateInput } from '@blueprintjs/datetime';
import { Tag } from '@blueprintjs/core';
import { DATETIME_FORMAT, ISO_FORMAT } from '../../../constants/datetime';
import { toTimezone } from '../../../utils/common/dateUtils';
import { isNull } from 'lodash';

export interface IProps {
  handleBlur: () => void;
  handleFocus: () => void;
  handleChange: Function;
  disabled: boolean;
  fullPath: string;
  value: Date;
  setTooltipOpen: (open: boolean) => void;
}

const CommandStringDateInput = (props: IProps) => {
  const { handleBlur, handleChange, disabled, value, fullPath, handleFocus } =
    props;

  const handleTimeInputChange = (newValue: Date | undefined | null) => {
    if (
      isUndefined(newValue) ||
      !moment(newValue).isValid() ||
      isNull(newValue)
    ) {
      if (isEmpty(newValue)) {
        handleChange(newValue, fullPath);
      }
      return;
    }

    const valueTZ = toTimezone(newValue, true);
    handleChange(valueTZ.toISOString(), fullPath);
  };

  return (
    <DateInput
      inputProps={{ onBlur: handleBlur, onFocus: handleFocus }}
      fill
      rightElement={<Tag minimal>UTC</Tag>}
      disabled={disabled}
      formatDate={(date) =>
        moment(date, 'DD/MM/YYYY HH:mm:ss').format(ISO_FORMAT)
      }
      parseDate={(str) => moment(str, [DATETIME_FORMAT, ISO_FORMAT]).toDate()}
      placeholder={DATETIME_FORMAT}
      timePrecision="second"
      value={toTimezone(value)}
      onChange={(date) => {
        return handleTimeInputChange(date);
      }}
    />
  );
};

export default CommandStringDateInput;
