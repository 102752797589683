import { TLE_OBJECTS } from './tleObjects';
import _ from 'lodash';

const splitByLines = TLE_OBJECTS.split(/\r?\n/);
const optionValues = [];
interface ITleObjectByName {
  [key: string]: { tle1: string; tle2: string };
}
const tleObjectByName: ITleObjectByName = {};

let i;
for (i = 0; i < 7938; i += 3) {
  optionValues.push({ value: splitByLines[i], text: splitByLines[i] });
  tleObjectByName[splitByLines[i]] = {
    tle1: splitByLines[i + 1],
    tle2: splitByLines[i + 2],
  };
}

export const TLE_OBJECT_OPTIONS = _.sortBy(optionValues, (value) => value.text);
export const TLE_OBJECT_BY_NAME = tleObjectByName;

export const DEFAULT_TLE_OBJECT = 'STARLINK-1164           ';
