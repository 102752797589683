import { Alert, Intent } from '@blueprintjs/core';
import type { ReactElement } from 'react';
import React from 'react';

interface IAlertProps {
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
  onConfirm: Function;
  message: string;
  confirmText?: string;
}

const AlertConfirm = (props: IAlertProps): ReactElement => {
  const onCancel = (): void => {
    props.setIsOpen(false);
  };

  const onConfirm = (): void => {
    props.onConfirm();
    props.setIsOpen(false);
  };
  return (
    <Alert
      cancelButtonText={'Cancel'}
      confirmButtonText={props.confirmText || 'Confirm'}
      icon="issue"
      isOpen={props.isOpen}
      intent={Intent.PRIMARY}
      onCancel={onCancel}
      onConfirm={onConfirm}
    >
      <p>{props.message}</p>
    </Alert>
  );
};

export default AlertConfirm;
