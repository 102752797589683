import { TOUR_STEPS } from 'constants/tour/constants';
import type { Key, Reducer } from 'react';
import type {
  ITourInformation,
  tourTypes,
} from '../constants/tour/actionTypes';

export const tourInitialState = {
  key: new Date() as unknown as Key, // To re-render when one restarts the tour
  run: false,
  continuous: true,
  loading: false,
  stepIndex: 0,
  steps: TOUR_STEPS,
};

const tourReducer: Reducer<ITourInformation, tourTypes> = (
  state = tourInitialState,
  action: tourTypes
) => {
  switch (action.type) {
    case 'START':
      return { ...state, run: true };
    case 'RESET':
      return { ...state, stepIndex: 0 };
    case 'STOP':
      return { ...state, run: false };
    case 'NEXT_OR_PREV':
      return { ...state, ...action.payload };
    case 'RESTART':
      return {
        ...state,
        stepIndex: 0,
        run: true,
        loading: false,
        key: new Date() as unknown as Key,
        steps: TOUR_STEPS,
      };
    default:
      return state;
  }
};

export default tourReducer;
