import type { ReactNode } from 'react';
import React from 'react';
import { Spinner, SpinnerSize } from '@blueprintjs/core';
import { MissionPageHeader } from '../../components/missionDesignPage/header/MissionPageHeader';
import { useMission } from '../../services/Missions';
import s from './index.module.scss';

export interface IDefaultLayoutProps {
  children?: ReactNode;
  header?: ReactNode;
  leftHeader?: ReactNode;
  rightHeader?: ReactNode;
  showMissionSelector?: boolean;
  disableMissionSelector?: boolean;
  light?: boolean;
  offLight?: boolean;
  shouldHideClock?: boolean;
  className?: string;
  onClickLogo?: (event: React.MouseEvent<HTMLDivElement>) => void;
  fill?: boolean;
  onMissionClick?: () => void;
}

// TODO: Find a solution to prevent users to access modules by URL

const DefaultLayout = (props: IDefaultLayoutProps) => {
  const { missions } = useMission();

  const { children, className, fill } = props;

  // TODO: Refactor so that light === off_light (need to update OPS colours)
  let colourClass = '';
  if (props.light) colourClass = s.main_light;
  else if (props.offLight) colourClass = s.main_offLight;

  const containerStyles = [className, s.main, colourClass];
  let bodyStyles = ['flex-container', 'mission-page-center'];

  if (fill) {
    containerStyles.push(s.main_fullHeightContainer);
    bodyStyles = [s.main_fullHeightBody];
  }

  const renderSpinner = (
    <div className={s.spinner}>
      <Spinner size={SpinnerSize.LARGE} />
    </div>
  );

  return (
    <div className={containerStyles.join(' ')}>
      <MissionPageHeader
        showMissionSelector={props.showMissionSelector}
        disableMissionSelector={props.disableMissionSelector}
        header={props.header}
        leftHeader={props.leftHeader}
        rightHeader={props.rightHeader}
        light={props.light}
        shouldHideClock={props.shouldHideClock}
        onClickLogo={props.onClickLogo}
        onMissionClick={props.onMissionClick}
      />
      <div className={bodyStyles.join(' ')}>
        {props.showMissionSelector && missions.length === 0
          ? renderSpinner
          : children}
      </div>
    </div>
  );
};

export default DefaultLayout;
