import type { ColumnProps } from 'ui/ColumnLayout/Column';

export type RowProps = ColumnProps;

export const Row = ({ children, justify, style }: RowProps) => {
  return (
    <div
      className="flex flex-row h-full p-2 gap-2 bg-surface dark:bg-surface-dark"
      style={{ ...style, justifyContent: justify }}
    >
      {children}
    </div>
  );
};
