import type { CommandHistoryPayload } from 'utils/hooks/useCommandHistory';

export const getPrunedCommandPayload = (payload: CommandHistoryPayload) => {
  return Object.entries(payload)
    .map(([key, val]) => {
      if (val === '' || val === null) {
        delete payload[key];
        return {};
      }

      if (typeof val === 'object') {
        getPrunedCommandPayload(val as unknown as CommandHistoryPayload);
      }

      return { [key]: val };
    })
    .reduce((acc, curr) => {
      return Object.assign(acc, { ...curr });
    }, {});
};
