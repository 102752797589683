import stringInputValidator from './stringInputValidator';
import numberInputValidator from './numberInputValidator';

//TODO Rewrite with Yup schema
export const createValidator =
  (path: string, type: string, required: boolean, params: any) =>
  (values: any) => {
    const errors: { [key: string]: any } = {};
    let errorMessage;
    if (values[path] !== undefined && values[path] !== '') {
      if (type === 'string') {
        errorMessage = stringInputValidator(values[path], params);
      }

      if (['number', 'integer'].includes(type)) {
        errorMessage = numberInputValidator(values[path], params, type);
      }
    }

    if (required && (values[path] === undefined || values[path] === '')) {
      errorMessage = 'This property is required';
    }

    if (errorMessage) {
      errors[path] = errorMessage;
    }

    return errors;
  };
