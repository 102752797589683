import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useMapLayers } from './MapLayersProvider';
import { SingleBandSTACLayer } from 'datacosmos/entities/singleBandLayer';
import moment from 'moment';
import { useMapLayerStorage } from 'datacosmos/utils/hooks/useMapLayerStorage';
import { BandAlgebraSTACLayer } from 'datacosmos/entities/bandAlgebraLayer';
import { BASE_Z_INDEX } from './MapProvider';

type Props = {
  children: React.ReactNode;
};

export type TimeSeriesContext = ReturnType<typeof useTimeSeriesProvider>;

export const TimeSeriesContext = createContext<TimeSeriesContext>(
  undefined as unknown as TimeSeriesContext
);
export const useTimeSeries = () =>
  useContext<TimeSeriesContext>(TimeSeriesContext);

const useTimeSeriesProvider = () => {
  const { layers, replaceLayer } = useMapLayers();
  const { loadSavedLayers, saveCurrentLayers } = useMapLayerStorage();

  /**
   * The layers that support time series, sorted by date from the latest to the earliest.
   */
  const timeSeriesLayers = useMemo(
    () =>
      (
        layers.filter(
          (l) =>
            l instanceof SingleBandSTACLayer ||
            l instanceof BandAlgebraSTACLayer
        ) as SingleBandSTACLayer[]
      ).sort(
        (a, b) =>
          moment(a.item.properties.datetime).unix() -
          moment(b.item.properties.datetime).unix()
      ),
    [layers]
  );

  const minMaxDates = useMemo(() => {
    const datesUnix = timeSeriesLayers.map((l) =>
      moment(l.item.properties.datetime).unix()
    );

    const minDate = moment.unix(Math.min(...datesUnix)).toDate();
    const maxDate = moment.unix(Math.max(...datesUnix)).toDate();

    return { minDate, maxDate };
  }, [timeSeriesLayers]);

  const [isTimeSeriesToggled, setIsTimeSeriesToggled] =
    useState<boolean>(false);
  const [dateToShow, setDateToShow] = useState<Date | undefined>();

  const isEnabled = useMemo(
    () => timeSeriesLayers.length > 0,
    [timeSeriesLayers]
  );

  const hideNonTimeSeriesLayers = () => {
    layers
      .filter(
        (l) =>
          !(l instanceof SingleBandSTACLayer) &&
          !(l instanceof BandAlgebraSTACLayer)
      )
      .map((l) => replaceLayer(l.cloneWithOptions({ visible: false })));
  };

  const toggleTimeSeries = () => {
    if (!isTimeSeriesToggled) {
      saveCurrentLayers();
      hideNonTimeSeriesLayers();
      hideLayersAfterDate(minMaxDates.minDate);
    } else {
      loadSavedLayers();
      unhideAllTimeseriesLayers();
      setDateToShow(undefined);
    }

    setIsTimeSeriesToggled(!isTimeSeriesToggled);
  };

  const hideLayersAfterDate = (date: Date) => {
    timeSeriesLayers.map((l, index) => {
      const layer = l as SingleBandSTACLayer;
      if (
        moment(layer.item.properties.datetime).isAfter(moment(date), 'second')
      ) {
        replaceLayer(layer.cloneWithOptions({ visible: false }));
      } else {
        replaceLayer(
          layer.cloneWithOptions({
            visible: true,
            zIndex: BASE_Z_INDEX * (index + 2),
          })
        );
      }
    });
  };

  const unhideAllTimeseriesLayers = () => {
    timeSeriesLayers.map((l) => {
      const layer = l as SingleBandSTACLayer;
      replaceLayer(layer.cloneWithOptions({ visible: true }));
    });
  };

  useEffect(() => {
    if (!dateToShow) {
      setDateToShow(minMaxDates.minDate);
    }
  }, [dateToShow, minMaxDates.minDate]);

  // Save the current layers when the time series layers change.
  // This keeps the layers added or removed during time series when the
  // time series is toggled off.
  useEffect(() => {
    saveCurrentLayers();
  }, [saveCurrentLayers, timeSeriesLayers]);

  return {
    toggleTimeSeries,
    /**
     * If the time series button is toggled, then the time series is shown.
     */
    isTimeSeriesToggled,
    /**
     * If there is at least one layer that supports time series, then the time series button is enabled.
     */
    isEnabled,
    dateToShow,
    setDateToShow,
    minMaxDates,
    hideLayersAfterDate,
    timeSeriesLayers,
  };
};

const TimeSeriesProvider = ({ children }: Props) => {
  return (
    <TimeSeriesContext.Provider value={useTimeSeriesProvider()}>
      {children}
    </TimeSeriesContext.Provider>
  );
};

export default TimeSeriesProvider;
