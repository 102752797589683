import type { SwathControlData } from 'api/tasking/helpers';
import { useCallback, useRef, useState } from 'react';

/**
 *
 * @param defaultData initial data to populate state with
 * @returns setSwathControlData - direct state setter
 * @returns swathControlData - data related to the swath's position, duration, coverage etc.
 * @returns debouncedSetSwathData - hander for setting swath control state. Useful when making many calls to the swath service
 */
export const useSwathControlData = (
  defaultData: SwathControlData | (() => SwathControlData)
) => {
  const [swathControlData, setSwathControlData] =
    useState<SwathControlData>(defaultData);

  const timeout = useRef<NodeJS.Timeout | null>(null);

  /**
   * @param value new swath control value
   * @param debounceAction any action that needs to occur after setting the data
   */
  const debouncedSetSwathData = useCallback(
    (value: SwathControlData, debounceAction: Function) => {
      setSwathControlData(value);
      if (timeout.current) {
        clearTimeout(timeout.current);
      }
      timeout.current = setTimeout(async () => {
        timeout.current = null;
        await debounceAction();
      }, 400);
    },
    []
  );

  const resetSwathDataToDefault = (): void => {
    setSwathControlData(defaultData);
  };

  return {
    swathControlData,
    setSwathControlData,
    debouncedSetSwathData,
    resetSwathDataToDefault,
  };
};
