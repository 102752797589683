import type { ReactElement } from 'react';
import React from 'react';
import type { WithTranslation } from 'react-i18next';
import { withTranslation } from 'react-i18next';
import { Button } from '@blueprintjs/core';
import type { IGSState } from '../../constants/groundStations/actionTypes';
import GroundStationObject from './GroundStationObject';
import type { IFocus } from '../../constants/focus/actionTypes';

interface IGroundStationsProps extends WithTranslation {
  groundStations: IGSState;
  setPopUp: Function;
  focus?: IFocus;

  addGroundStation: Function;
  updateGroundStation: Function;
  showGroundStation: Function;
  removeGroundStation: Function;
  setFocus?: Function;

  disableCRUD?: boolean;
}

interface IGroundStationsState {}

class GroundStations extends React.Component<
  IGroundStationsProps,
  IGroundStationsState
> {
  public state: IGroundStationsState = {};

  public componentWillUnmount(): void {}

  public componentDidUpdate(): void {}

  private addGroundStation = (): void => {
    this.props.addGroundStation();
  };

  public render(): ReactElement {
    const {
      groundStations,
      removeGroundStation,
      updateGroundStation,
      showGroundStation,
      setPopUp,

      focus,
      setFocus,
    } = this.props;

    const customGS = groundStations.list.filter((gs) => gs.custom);
    const defaultGS = groundStations.list.filter((gs) => !gs.custom);

    return (
      <div className="geometry-panel">
        <div className="white geometry-header">
          <h3 className="text">Ground Stations</h3>
          {!this.props.disableCRUD && (
            <Button
              className="button button_common"
              onClick={this.addGroundStation}
              intent="none"
              icon="add"
              key="add"
              text="Add"
            />
          )}
        </div>
        <div className="white geometry-body">
          {customGS.map((gs) => {
            return (
              <GroundStationObject
                key={gs.id}
                gs={gs}
                removeGS={removeGroundStation}
                updateGS={updateGroundStation}
                showGroundStation={showGroundStation}
                setPopUp={setPopUp}
                focus={focus}
                setFocus={setFocus}
                disableCRUD={this.props.disableCRUD}
              />
            );
          })}
          {(customGS.length ? true : null) &&
            (defaultGS.length ? true : null) && <hr />}
          {defaultGS.map((gs) => {
            return (
              <GroundStationObject
                key={gs.id}
                gs={gs}
                removeGS={removeGroundStation}
                updateGS={updateGroundStation}
                showGroundStation={showGroundStation}
                setPopUp={setPopUp}
                focus={focus}
                setFocus={setFocus}
                disableCRUD={this.props.disableCRUD}
              />
            );
          })}
        </div>
      </div>
    );
  }
}

export default withTranslation()(GroundStations);
