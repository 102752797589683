import { DATETIME_FORMAT } from 'constants/datetime';
import { BandAlgebraSTACLayer } from 'datacosmos/entities/bandAlgebraLayer';
import { FieldOfRegardLayer } from 'datacosmos/entities/FieldOfRegardLayer';
import type { Layer } from 'datacosmos/entities/layer';
import { LayerSourceType } from 'datacosmos/entities/layer';
import { OutlineLayer } from 'datacosmos/entities/outlineLayer';
import { SingleBandSTACLayer } from 'datacosmos/entities/singleBandLayer';
import { SwathLayer } from 'datacosmos/entities/SwathLayer';
import { OpportunityLayer } from 'datacosmos/entities/TaskingOpportunityLayer';
import { useMapLayers } from 'datacosmos/stores/MapLayersProvider';
import { useMap } from 'datacosmos/stores/MapProvider';
import { StacItem } from 'datacosmos/types/stac-types';
import { isAssetPreviewable } from 'datacosmos/utils/stac';
import utils from 'datacosmos/utils/utils';
import moment from 'moment';
import ListItem from '_atoms/ListItem/ListItem';
import { ListOrientation } from '_molecules/UnorderedList/UnorderedList';
import GridCard from '_organisms/GridCard/GridCard';
import type { IStacInfo } from 'datacosmos/utils/hooks/useStacInfo';
import useStacInfo from 'datacosmos/utils/hooks/useStacInfo';
import bbox from '@turf/bbox';

//Images
import Sat from 'images/datacosmos/Tasking.svg';
import Polygon from 'images/datacosmos/Measures_area.svg';
import Line from 'images/datacosmos/Measures.svg';
import Circle from 'images/datacosmos/Circle.svg';
import AOI from 'images/datacosmos/Custom_AOI.svg';
import Unknown from 'images/datacosmos/Help.svg';
import { LineLayer } from 'datacosmos/entities/lineLayer';
import { PolygonLayer } from 'datacosmos/entities/polygonLayer';
import { CircleLayer } from 'datacosmos/entities/circleLayer';
import { STACLayerFactory } from 'datacosmos/entities/stacLayer';
import Tabs from '_molecules/Tabs/Tabs';
import { Item } from 'react-stately';
import { BarChart, CartesianGrid, XAxis, YAxis, Bar, Tooltip } from 'recharts';
import Select2 from '_molecules/Select2/Select2';
import Popover2 from '_atoms/Popover/Popover2';
import IconButton from '_molecules/IconButton/IconButton';
import { default as CustomTooltip } from '_atoms/Tooltip/Tooltip';
import { useLocalisation } from 'utils/hooks/useLocalisation';
import { useAnalytics } from 'utils/hooks/analytics/useAnalytics';
import DownloadLayerMenu from 'datacosmos/components/RightClickMenu/DownloadLayer';
import type { CommonOpportunity } from 'api/tasking/helpers';

type IProps = {
  layer: Layer;
};
const LayerGridCard = ({ layer }: IProps) => {
  const { replaceLayer, removeLayer, replaceSpecificLayerWithAnother } =
    useMapLayers();
  const { disableEditing, setViewToFitBbox } = useMap();

  const { translate, translateWithExplicitFallback } = useLocalisation();

  const { sendInfo } = useAnalytics();

  const renderDownloadAsFormat = (
    l:
      | LineLayer
      | PolygonLayer
      | CircleLayer
      | OpportunityLayer<CommonOpportunity>
  ) => <DownloadLayerMenu layer={l} defaultValue={'kml'} />;

  const renderStacItemLayerCard = (
    stacLayer: SingleBandSTACLayer,
    stacInfo: IStacInfo
  ) => {
    const {
      cloudCoverage,
      getSelectedAsset,
      handleBandRemove,
      handleBandSelect,
      observationZenithAngle,
      selectedBands,
      sensors,
      sunElevationAngle,
      thumbnailAsset,
      previewUrl,
      isFetchingMetadata,
      maximumPixelValue,
      minimumPixelValue,
      fetchAssetsMetadata,
      histogramData,
      getHistogramColor,
      jsonMetadata,
    } = stacInfo;

    const tooltipContentJsonItems =
      Object.keys(jsonMetadata ?? {}).length !== 0 ? (
        <ul className="flex flex-col gap-2">
          {Object.entries(jsonMetadata ?? {}).map(([key, val]) => (
            <li className="flex items-center gap-2" key={key}>
              <span>{key}: </span>
              <span>{val}</span>
            </li>
          ))}
        </ul>
      ) : undefined;

    const tooltipContent = (
      <ul className="flex flex-col gap-2">
        <li className="flex items-center gap-2">
          <span>{translate('datacosmos.catalogAndItems.metadata.oza')} </span>
          <span>{observationZenithAngle}</span>
        </li>
        <li className="flex items-center gap-2">
          <span>
            {translate('datacosmos.catalogAndItems.metadata.sensors')}{' '}
          </span>
          <span>{sensors}</span>
        </li>
        <li className="flex items-center gap-2">
          <span>
            {translate('datacosmos.catalogAndItems.metadata.resolution')}
          </span>
          <span>
            {stacLayer.item.properties.gsd
              ? utils.gsdToUnit(stacLayer.item.properties.gsd)
              : '-'}
          </span>
        </li>
        <li className="flex items-center gap-2">
          <span>
            {translate('datacosmos.catalogAndItems.metadata.minPixelValue')}
          </span>
          <span>{isFetchingMetadata ? '...loading' : minimumPixelValue}</span>
        </li>
        <li className="flex items-center gap-2">
          <span>
            {translate('datacosmos.catalogAndItems.metadata.maxPixelValue')}
          </span>
          <span>{isFetchingMetadata ? '...loading' : maximumPixelValue}</span>
        </li>
        <li className="flex items-center gap-2">
          <Popover2
            isDismissable={true}
            placement="bottom left"
            disabled={isFetchingMetadata || !histogramData}
            popupContent={
              <div>
                <Tabs>
                  {histogramData?.length && histogramData?.length > 0 ? (
                    [
                      ...new Set(
                        Object.keys(histogramData[0]).map((k) =>
                          k.replace(/X|Y/, '')
                        )
                      ),
                    ].map((data) => {
                      return (
                        <Item
                          title={translateWithExplicitFallback(
                            `datacosmos.catalogAndItems.metadata.bandColors.${data}` as unknown as TemplateStringsArray,
                            data
                          )}
                        >
                          <BarChart
                            data={histogramData}
                            height={200}
                            width={320}
                            barSize={'25px'}
                          >
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey={`${data}X`} />
                            <YAxis dataKey={`${data}Y`} />
                            <Tooltip />
                            <Bar
                              dataKey={`${data}Y`}
                              fill={getHistogramColor(data)}
                            />
                          </BarChart>
                        </Item>
                      );
                    })
                  ) : (
                    <Item>
                      <div />
                    </Item>
                  )}
                </Tabs>
              </div>
            }
            className="shadow-sm shadow-item-contrast color-item w-fit p-1"
          >
            <div className="flex items-center gap-2">
              <span>
                {histogramData?.length !== 0
                  ? translate(
                      'datacosmos.catalogAndItems.metadata.showStatistics'
                    )
                  : translate(
                      'datacosmos.catalogAndItems.metadata.noHistogramData'
                    )}
              </span>
            </div>
          </Popover2>
        </li>
      </ul>
    );

    return (
      <GridCard
        onClick={() => {}}
        title={{ content: moment(stacLayer.item.date()).format('YYYY MMM DD') }}
        image={{
          src: thumbnailAsset
            ? thumbnailAsset.href
            : '/images/datacosmos/img-placeholder.svg',
          position: 'left',
          sizeInPx: 100,
          previewImgUrl: previewUrl === null ? undefined : previewUrl,
        }}
        info={{
          orientation: ListOrientation.HorizontalTwoColumns,
          content: (
            <>
              <ListItem
                icon="Satellite"
                content={stacLayer.item.title()}
                className="text-item-contrast-inactive"
                onClick={() => {}}
              />
              <ListItem
                icon="SunAngle"
                content={sunElevationAngle}
                className="text-item-contrast-inactive"
                onClick={() => {}}
              />

              {stacLayer instanceof BandAlgebraSTACLayer ? (
                <ListItem
                  icon="BandAlgebra"
                  content={stacLayer.expression}
                  className="text-item-contrast-inactive truncate max-w-label w-min overflow-ellipsis"
                  onClick={() => {}}
                  noTruncate={true}
                />
              ) : (
                <ListItem
                  icon="Cloud"
                  content={cloudCoverage}
                  className="text-item-contrast-inactive"
                  onClick={() => {}}
                />
              )}
              <Popover2
                isDismissable={true}
                placement="top"
                popupContent={tooltipContentJsonItems ?? tooltipContent}
                className="p-2 color-item w-fit overflow-y-auto"
              >
                <ListItem
                  icon="More"
                  content={translate('datacosmos.catalogAndItems.moreInfo')}
                  className="text-item-contrast-inactive"
                  onClick={() => {
                    void fetchAssetsMetadata();
                  }}
                />
              </Popover2>
            </>
          ),
        }}
        rightIcons={[
          {
            icon: stacLayer.options.visible ? 'Eye' : 'EyeClosed',
            onClick: () => {
              replaceLayer(
                stacLayer.cloneWithOptions({
                  visible: !stacLayer.options.visible,
                })
              );
            },
            shown: true,
          },
          {
            icon: 'Center',
            onClick: () => {
              setViewToFitBbox(stacLayer.item.bbox);
            },
            shown: true,
          },
          {
            icon: 'Close',
            onClick: () => {
              removeLayer(stacLayer.id);
            },
            shown: true,
          },
        ]}
        actionElement={
          <Select2
            items={Object.entries(stacLayer.item.assets).filter(([, asset]) =>
              isAssetPreviewable(asset)
            )}
            onSelectionChange={(key) => {
              const toRemove = selectedBands.find((b) => b[0] === key);
              const toAdd = Object.entries(stacLayer.item.assets).find(
                (item) => item[0] === key
              );
              if (toRemove !== undefined) {
                handleBandRemove(toRemove);
              } else if (toAdd !== undefined) {
                handleBandSelect(toAdd);
              }
              const l = STACLayerFactory({
                item: new StacItem(stacLayer.item),
                assetKey: key.toString(),
                expression: undefined,
              });
              replaceSpecificLayerWithAnother(l, stacLayer);
            }}
            selectedItem={getSelectedAsset()}
            className="max-w-[200px] min-w-[200px]"
            fill
          >
            {(item) => (
              <Item key={item[0]} textValue={item[1].title}>
                <div className="flex items-center justify-between gap-1">
                  <div className="flex flex-col">
                    <span>{item[1].title ?? item[0]}</span>
                    {item[1].title && (
                      <span className="text-xs">{item[0]}</span>
                    )}
                  </div>
                  <CustomTooltip
                    content={translate(
                      'datacosmos.tooltips.catalogAndItems.downloadAsset'
                    )}
                    delay={0}
                  >
                    <IconButton
                      icon="Install"
                      onPress={() => {
                        window.open(item[1].href, '_blank');

                        sendInfo({
                          action: `Download asset: ${
                            item[1].title ?? item[0]
                          }}`,
                          item: 'Download asset button',
                          type: 'Click',
                          module: 'DataCosmos',
                          download: item[1].href,
                          additionalParams: {
                            asset: item[1].title ?? item[0],
                          },
                        });
                      }}
                    />
                  </CustomTooltip>
                </div>
              </Item>
            )}
          </Select2>
        }
        indicators={[
          {
            icon: 'Brightness',
            shown: stacLayer.isBrightnessApplied?.(),
          },
          {
            icon: 'Saturation',
            shown: stacLayer.isSaturationApplied?.(),
          },
          { icon: 'Contrast', shown: stacLayer.isContrastApplied?.() },
        ]}
      />
    );
  };

  const renderOpportunityLayerCard = (layer: OpportunityLayer<any>) => (
    <GridCard
      onClick={() => {}}
      title={{
        content:
          layer.metadata.OpportunityIndex !== undefined
            ? `#${layer.metadata.OpportunityIndex} ` + layer.getName()
            : layer.getName(),
        noTruncate: true,
      }}
      info={{
        orientation: ListOrientation.Horizontal,
        content: (
          <>
            <ListItem
              content={layer.getDescription()}
              noTruncate
              onClick={() => {}}
            />
            <ListItem
              noTruncate
              content={
                layer &&
                layer.getStartTime() &&
                moment(layer.getStartTime()).utc().format(DATETIME_FORMAT)
              }
              onClick={() => {}}
            />
          </>
        ),
      }}
      image={{
        src: Sat,
        position: 'left',
        sizeInPx: 100,
        className: 'dark:invert',
      }}
      rightIcons={[
        {
          icon: layer.options.visible ? 'Eye' : 'EyeClosed',
          onClick: () => {
            replaceLayer(
              layer.cloneWithOptions({
                visible: !layer.options.visible,
              })
            );
          },
          shown: true,
        },
        {
          icon: 'Center',
          onClick: () => {
            setViewToFitBbox(bbox(layer.data));
          },
          shown: true,
        },
        {
          icon: 'Close',
          onClick: () => {
            removeLayer(layer.id);
          },
          shown: true,
        },
      ]}
      actionElement={renderDownloadAsFormat(layer)}
    />
  );

  const renderLineAndPolygonLayerCards = (layer: LineLayer | PolygonLayer) => (
    <GridCard
      onClick={() => {}}
      title={{ content: layer.getName(), noTruncate: true }}
      info={{
        content: (
          <>
            <ListItem
              content={layer.getDescription() ?? ''}
              noTruncate
              onClick={() => {}}
            />
            <ListItem
              content={layer.getMeasurements()}
              noTruncate
              onClick={() => {}}
            />
          </>
        ),
        orientation: ListOrientation.Horizontal,
      }}
      image={{
        src:
          layer instanceof LineLayer
            ? Line
            : layer.sourceType === LayerSourceType.TASKING_REGIONS ||
              layer.sourceType === LayerSourceType.APPLICATION_AOI ||
              layer.sourceType === LayerSourceType.AREA_OF_INTEREST
            ? AOI
            : Polygon,
        position: 'left',
        sizeInPx: 100,
        className: 'dark:invert',
      }}
      rightIcons={[
        {
          icon: layer.options.visible ? 'Eye' : 'EyeClosed',
          onClick: () => {
            replaceLayer(
              layer.cloneWithOptions({
                visible: !layer.options.visible,
              })
            );
          },
          shown: true,
        },
        {
          icon: 'Center',
          onClick: () => {
            setViewToFitBbox(bbox(layer.data));
          },
          shown: true,
        },
        {
          icon: 'Close',
          onClick: () => {
            removeLayer(layer.id);
            if (layer instanceof PolygonLayer) {
              disableEditing(layer.getLeafletLayerMetadata());
            }
          },
          shown: true,
        },
      ]}
      actionElement={renderDownloadAsFormat(layer)}
    />
  );

  const renderCircleLayerCard = (layer: CircleLayer) => (
    <GridCard
      onClick={() => {}}
      title={{ content: layer.getName(), noTruncate: true }}
      info={{
        content: (
          <>
            <ListItem
              content={layer.getDescription() ?? ''}
              noTruncate
              onClick={() => {}}
            />
            <ListItem
              content={layer.getMeasurements()}
              noTruncate
              onClick={() => {}}
            />
            <ListItem
              content={layer.getRadius()}
              noTruncate
              onClick={() => {}}
            />
          </>
        ),
        orientation: ListOrientation.Horizontal,
      }}
      image={{
        src: Circle,
        position: 'left',
        sizeInPx: 100,
        className: 'dark:invert',
      }}
      rightIcons={[
        {
          icon: layer.options.visible ? 'Eye' : 'EyeClosed',
          onClick: () => {
            replaceLayer(
              layer.cloneWithOptions({
                visible: !layer.options.visible,
              })
            );
          },
          shown: true,
        },
        {
          icon: 'Center',
          onClick: () => {
            setViewToFitBbox(bbox(layer.data));
          },
          shown: true,
        },
        {
          icon: 'Close',
          onClick: () => {
            disableEditing(layer.getLeafletLayerMetadata());
            removeLayer(layer.id);
          },
          shown: true,
        },
      ]}
      actionElement={renderDownloadAsFormat(layer)}
    />
  );

  const renderUnknownLayerCard = (layer: Layer) => (
    <GridCard
      onClick={() => {}}
      title={{
        content: layer?.getName(),
        noTruncate: true,
      }}
      info={{
        content: (
          <ListItem
            icon="Info"
            content={layer.getDescription() ?? ''}
            onClick={() => {}}
          />
        ),
        orientation: ListOrientation.HorizontalTwoColumns,
      }}
      image={{
        src: Unknown,
        position: 'left',
        sizeInPx: 100,
      }}
      rightIcons={[
        {
          icon: layer.options.visible ? 'Eye' : 'EyeClosed',
          onClick: () => {
            replaceLayer(
              layer.cloneWithOptions({
                visible: !layer.options.visible,
              })
            );
          },
          shown: true,
        },

        {
          icon: 'Close',
          onClick: () => {
            removeLayer(layer.id);
          },
          shown: true,
        },
      ]}
    />
  );

  if (
    layer instanceof SingleBandSTACLayer ||
    layer instanceof BandAlgebraSTACLayer ||
    layer instanceof OutlineLayer
  ) {
    const stacInfo = useStacInfo(layer as SingleBandSTACLayer);
    return renderStacItemLayerCard(
      layer as SingleBandSTACLayer,
      stacInfo as IStacInfo
    );
  } else if (
    layer instanceof OpportunityLayer ||
    layer instanceof FieldOfRegardLayer ||
    layer instanceof SwathLayer
  ) {
    return renderOpportunityLayerCard(layer);
  } else if (layer instanceof LineLayer || layer instanceof PolygonLayer) {
    return renderLineAndPolygonLayerCards(layer);
  } else if (layer instanceof CircleLayer) {
    return renderCircleLayerCard(layer);
  }
  return renderUnknownLayerCard(layer);
};

export default LayerGridCard;
