import type { StacItem } from 'datacosmos/types/stac-types';
import { useCallback, useEffect, useMemo, useState } from 'react';
import Dialog from '_atoms/Dialog/Dialog';
import ConfirmPurchaseImageCarousel from 'datacosmos/components/ConfirmPurchase/ConfirmPurchaseImageCarousel';
import ConfirmPurchaseItemList from 'datacosmos/components/ConfirmPurchase/ConfirmPurchaseItemList';
import { useLocalisation } from 'utils/hooks/useLocalisation';
import { getImagePrice, getOrderCheckoutURL } from 'api/orders/service';
import type { Order, PaymentMethod, Price } from 'api/orders/types';
import {
  DATACOSMOS_CHECKOUT_MANUAL_BANK_TRANSFER,
  DATACOSMOS_CHECKOUT_MANUAL_BANK_TRANSFER_TEXT,
  DATACOSMOS_CHECKOUT_CARD,
} from 'env';
import { Text } from 'ui/Text';
import { toaster } from 'toaster';

interface IProps {
  isOpen: boolean;
  imagesToPurchase: StacItem[];
  setIsOpen: (open: boolean) => void;
  createOrderWithItemsInCart: (paymentMethod: PaymentMethod) => Promise<Order>;
}

const ConfirmPurchaseDialog = ({
  isOpen,
  imagesToPurchase,
  setIsOpen,
  createOrderWithItemsInCart,
}: IProps) => {
  const [selectedImage, setSelectedImage] = useState<StacItem>();
  const [totalPrice, setTotalPrice] = useState<Price | undefined>();

  const [isByBankOpen, setIsByBankOpen] = useState<boolean>(false);

  const [isPriceFetching, setIsPriceFetching] = useState<boolean>(false);

  const [isOrderCreating, setIsOrderCreating] = useState<boolean>(false);

  const [paymentReferenceId, setPaymentReferenceId] = useState<string>('');

  const [itemsWithPrice, setItemsWithPrice] = useState<
    { item: StacItem; price: Price }[]
  >([]);

  const urlParams = useMemo(
    () => new URLSearchParams(window.location.search),
    []
  );

  const urlErrorMessage: { error: string; message: string } | undefined =
    useMemo(() => {
      const msgString = urlParams.get('payment_message');

      if (!msgString) {
        return undefined;
      }

      return JSON.parse(msgString) as { error: string; message: string };
    }, [urlParams]);

  const [isByCardOpen, setIsByCardOpen] = useState<boolean>(
    Boolean(urlParams.get('payment_status'))
  );

  const { translate } = useLocalisation();

  const fetchPrices = useCallback(async () => {
    if (!imagesToPurchase || imagesToPurchase.length === 0) {
      return;
    }

    setIsPriceFetching(true);
    const pricesPromises = imagesToPurchase.map(async (img) => {
      const { data: price } = await getImagePrice({
        params: {
          collection: img.collection ?? '',
          itemId: img.id,
        },
      });

      return price ?? ({} as Price);
    });

    const prices = await Promise.all(pricesPromises);

    const withPrice = imagesToPurchase.map((img, index) => ({
      item: img,
      price: prices[index],
    }));

    setItemsWithPrice(withPrice);

    const cumulativePrice = prices.reduce(
      (acc, curr) => {
        if (acc.final === '') {
          acc = curr;
        } else {
          acc = {
            ...acc,
            final: (Number(acc.final) + Number(curr.final)).toString(),
            discount: (Number(acc.discount) + Number(curr.discount)).toString(),
            value: (Number(acc.value) + Number(curr.value)).toString(),
          };
        }

        return acc;
      },
      {
        value: '',
        currency: '',
        discount: '',
        final: '',
      } as Price
    );

    setTotalPrice(cumulativePrice);
    setIsPriceFetching(false);
  }, [imagesToPurchase]);

  useEffect(() => {
    // ensures API calls for price are not triggered before the popup is opened
    if (!isOpen) {
      return;
    }
    void fetchPrices();
  }, [fetchPrices, isOpen]);

  useEffect(() => {
    setSelectedImage(imagesToPurchase[0]);
  }, [imagesToPurchase]);

  const payButtonText =
    Number(totalPrice?.final) > 0
      ? translate('datacosmos.buttons.purchase')
      : translate('datacosmos.buttons.completeOrder');

  return (
    <>
      <Dialog
        title={translate(
          'datacosmos.catalogAndItems.purchaseDialog.orderCheckout'
        )}
        isOpen={isOpen}
        buttons={[
          {
            onPress: async () => {
              setIsOrderCreating(true);
              await createOrderWithItemsInCart('NOT_REQUIRED');
              setIsOrderCreating(false);
            },
            text: payButtonText,
            shown: Number(totalPrice?.final) === 0,
            showLoadingIndicator: isOrderCreating,
          },
          {
            onPress: async () => {
              setIsOrderCreating(true);
              const order = await createOrderWithItemsInCart('CARD');

              const resp = await getOrderCheckoutURL({
                params: {
                  orderId: order.id,
                },
              });

              const newURL = resp.data?.checkout_url;

              if (typeof newURL !== 'undefined') {
                window.location.href = newURL;
              }

              toaster.show({
                icon: 'error',
                intent: 'danger',
                message: 'Could not proceed to checkout.',
              });

              setIsOrderCreating(false);
            },
            text: translate('datacosmos.buttons.payByCard'),
            shown: DATACOSMOS_CHECKOUT_CARD && Number(totalPrice?.final) > 0,
            showLoadingIndicator: isOrderCreating,
          },
          {
            onPress: async () => {
              setIsOrderCreating(true);
              const order = await createOrderWithItemsInCart('BANK_TRANSFER');

              setPaymentReferenceId(
                order.payment_reference_number ?? 'XXXXXXX'
              );
              setIsOrderCreating(false);
              setIsByBankOpen(true);
            },
            text: translate('datacosmos.buttons.payByBank'),
            shown:
              DATACOSMOS_CHECKOUT_MANUAL_BANK_TRANSFER &&
              Number(totalPrice?.final) > 0,
            showLoadingIndicator: isOrderCreating,
          },
          {
            onPress: async () => {
              setIsOrderCreating(true);
              await createOrderWithItemsInCart('NONE');
              setIsOrderCreating(false);
            },
            text: payButtonText,
            shown:
              !DATACOSMOS_CHECKOUT_MANUAL_BANK_TRANSFER &&
              !DATACOSMOS_CHECKOUT_CARD &&
              Number(totalPrice?.final) > 0,
            showLoadingIndicator: isOrderCreating,
          },
        ]}
        onClose={() => {
          setIsOpen(!isOpen);
        }}
      >
        <div data-testid="confirm-purchase-dialog" className="flex gap-4">
          <ConfirmPurchaseImageCarousel
            currentImage={selectedImage}
            imagesToPurchase={imagesToPurchase}
            setCurrentImage={setSelectedImage}
          />
          <ConfirmPurchaseItemList
            currentImage={selectedImage}
            imagesToPurchase={imagesToPurchase}
            setCurrentImage={setSelectedImage}
            totalPrice={totalPrice}
            isFetchingPrice={isPriceFetching}
            itemsWithPrice={itemsWithPrice}
          />
        </div>
      </Dialog>

      <Dialog
        title={translate(
          'datacosmos.catalogAndItems.purchaseDialog.orderCheckout'
        )}
        isOpen={isByBankOpen}
        onClose={() => setIsByBankOpen(false)}
        buttons={[
          {
            text: translate('datacosmos.buttons.accept'),
            shown: true,
            onPress: () => {
              setIsByBankOpen(false);
            },
          },
        ]}
        hideCancelButton={true}
      >
        <div className="flex flex-col gap-2 h-80">
          <Text>
            {translate(
              'datacosmos.catalogAndItems.purchaseDialog.payingViaBankTransfer'
            )}
          </Text>

          <Text>
            {translate(
              'datacosmos.catalogAndItems.purchaseDialog.pleaseMakeATransferWithRef',
              { reference: paymentReferenceId }
            )}
          </Text>

          <Text>{DATACOSMOS_CHECKOUT_MANUAL_BANK_TRANSFER_TEXT}</Text>

          <Text>
            {translate(
              'datacosmos.catalogAndItems.purchaseDialog.dataPurchasedWillNotBeAvailableUntil'
            )}
          </Text>

          <Text>
            {translate(
              'datacosmos.catalogAndItems.purchaseDialog.verificationOfPaymentDetailsCanTake'
            )}
          </Text>
        </div>
      </Dialog>

      <Dialog
        buttons={[]}
        onClose={() => {
          setIsByCardOpen(false);
        }}
        title={translate(
          'datacosmos.catalogAndItems.purchaseDialog.orderCheckout'
        )}
        isOpen={isByCardOpen}
      >
        {urlParams.get('payment_status') === 'error' ? (
          <div className="flex flex-col">
            <Text>{urlErrorMessage?.error}</Text>
            <Text breakLine>{urlErrorMessage?.message}</Text>
          </div>
        ) : (
          <></>
        )}
        {urlParams.get('payment_status') === 'success' ? (
          <Text>
            {translate(
              'datacosmos.catalogAndItems.purchaseDialog.paymentViaCardComplete'
            )}
          </Text>
        ) : (
          <></>
        )}
      </Dialog>
    </>
  );
};

export default ConfirmPurchaseDialog;
