import { SHOW_INTERNAL_SATELLITES } from 'env';

/**
 * Until we have a proper feature flag provider/API
 * we store flag values in .env.
 */

export const FLAGS = [
  {
    name: 'SHOW_INTERNAL_SATELLITES',
    isActive: SHOW_INTERNAL_SATELLITES,
  },
];
