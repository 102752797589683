import { MenuItem } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import type { LeafletMouseEvent } from 'leaflet';
import React from 'react';
import { toaster } from 'toaster';
import { useLocalisation } from 'utils/hooks/useLocalisation';

interface Props {
  e: LeafletMouseEvent;
}

const CopyCoordinates = ({ e }: Props) => {
  const { translate } = useLocalisation();
  function copyLatLng(e: LeafletMouseEvent) {
    const latLng: number[] = Object.values(e.latlng);
    navigator.clipboard.writeText(
      latLng[0].toFixed(6) + ',' + latLng[1].toFixed(6)
    );
    toaster.show({
      icon: 'clipboard',
      intent: 'success',
      message: translate('datacosmos.rightClickMenu.copyCoords.success', {
        coordinates: latLng[0].toFixed(6) + ',' + latLng[1].toFixed(6),
      }),
    });
  }
  return (
    <MenuItem
      text={translate('datacosmos.rightClickMenu.copyCoords.copyCurrentCoords')}
      icon={IconNames.CLIPBOARD}
      onClick={() => copyLatLng(e)}
      className="dark:hover:text-neutral hover:text-accent-900"
    />
  );
};

export default CopyCoordinates;
