import type { ReactElement } from 'react';
import React from 'react';
import type { IconName, NumberRange } from '@blueprintjs/core';
import { Button, RangeSlider } from '@blueprintjs/core';
import { BlueprintIcons_16 } from '@blueprintjs/icons/lib/esnext/generated-icons/16px/blueprint-icons-16';
import { desc } from '../../utils/common/CommonUtils';

const { ArrowUp, ArrowDown, ArrowsHorizontal } = BlueprintIcons_16;

type Sort = 'asc' | 'desc' | null;

export interface IColumnSettings {
  fieldName: string;
  title: string;
}

export interface IHeaderGroup {
  title: string;
  colspan: number;
}

interface IResultsTableProps {
  headerGroups: IHeaderGroup[];
  types: string[];
  columns: IColumnSettings[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  results: any[];
}

interface IColumn {
  minTouched: boolean;
  maxTouched: boolean;
  min: number;
  max: number;
  range: NumberRange;
  sort: Sort;
}

interface IResultsTableState {
  columns: Map<string, IColumn>;
}

const sorting: Sort[] = [null, 'asc', 'desc'];

const SortableIconMap = {
  asc: ArrowUp as IconName,
  desc: ArrowDown as IconName,
  field: ArrowsHorizontal as IconName,
};

function getNextSorting(sort: Sort = null): Sort {
  const currentIndex = sorting.indexOf(sort);
  return sorting[currentIndex + 1] || sorting[0];
}

function getSortIcon(sort: Sort): IconName {
  if (!sort) {
    return SortableIconMap.field;
  }
  return SortableIconMap[sort];
}

const getInitialState = (fields: string[]): IResultsTableState => {
  const columns = fields.reduce((p, c): Map<string, IColumn> => {
    p.set(c, {
      minTouched: false,
      maxTouched: false,
      min: 0,
      max: 0,
      range: [0, 0],
      sort: null,
    });
    return p;
  }, new Map());

  return {
    columns,
  };
};
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getColumnState = (
  results: any[],
  type: string,
  oldVal: IColumn
): IColumn => {
  let min: number;
  let max: number;
  let minTouched: boolean = oldVal.minTouched;
  let maxTouched: boolean = oldVal.maxTouched;

  results.forEach((e): void => {
    if (!min || e[type] < min) {
      min = e[type];
    }
    if (!max || e[type] > max) {
      max = e[type];
    }
  });

  min = min || 0;
  max = max || 0;

  if (oldVal.range[0] < min || oldVal.range[0] > max) {
    minTouched = false;
  }
  if (oldVal.range[1] < min || oldVal.range[1] > max) {
    maxTouched = false;
  }

  return {
    ...oldVal,
    minTouched,
    maxTouched,
    min,
    max,
    range: [
      minTouched ? oldVal.range[0] : min,
      maxTouched ? oldVal.range[1] : max,
    ],
  };
};

class ResultsTable extends React.PureComponent<
  IResultsTableProps,
  IResultsTableState
> {
  public constructor(props: IResultsTableProps) {
    super(props);
    this.state = getInitialState(props.columns.map((e): string => e.fieldName));
  }

  public componentDidMount(): void {
    this.updateState();
  }

  public componentDidUpdate(prevProps: IResultsTableProps): void {
    if (prevProps.results !== this.props.results) {
      this.updateState();
    }
  }

  protected getEntries = (state?: IResultsTableState): [string, IColumn][] =>
    Array.from((state || this.state).columns);

  protected getColumnTitle = (fieldName: string): string =>
    this.props.columns.find((e): boolean => e.fieldName === fieldName).title;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  protected getRows = (): any[] =>
    this.props.results
      .filter((e): boolean => this.filterResults(e))
      .sort((a, b): number => this.sortResults(a, b));

  protected updateState = (): void =>
    this.setState((state): IResultsTableState => {
      const { results = [] } = this.props;
      const entries = this.getEntries(state);

      return {
        columns: new Map(
          entries.map(([key, value]): [string, IColumn] => [
            key,
            getColumnState(results, key, value),
          ])
        ),
      };
    });

  protected tableRef: React.RefObject<HTMLDivElement> = React.createRef();

  protected handleRangeChange =
    (key: string): ((range: NumberRange) => void) =>
    (range: NumberRange): void =>
      this.setState((state): IResultsTableState => {
        const columns = new Map(state.columns);
        const column = columns.get(key);
        const updatedColumn = {
          ...column,
          range,
          minTouched: column.minTouched || column.range[0] !== range[0],
          maxTouched: column.maxTouched || column.range[1] !== range[1],
        };

        columns.set(key, updatedColumn);

        return {
          columns,
        };
      });

  protected handleTouchMove = (): void => {
    this.tableRef.current.style.overflow = 'hidden';
  };

  protected handleTouchEnd = (): void => {
    this.tableRef.current.style.overflow = 'auto';
  };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  protected filterResults = (e: any): boolean =>
    this.getEntries().reduce(
      (p, [key, value]): boolean =>
        p && e[key] >= value.range[0] && e[key] <= value.range[1],
      true
    );

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  protected sortResults = (a: any, b: any): number => {
    const sortedField = this.getEntries().find(
      ([, v]): boolean => v.sort !== null
    );
    if (sortedField) {
      return sortedField[1].sort === 'desc'
        ? desc(a, b, sortedField[0])
        : -desc(a, b, sortedField[0]);
    }
    return 0;
  };

  protected toggleSort =
    (key: string): ((event: React.MouseEvent<HTMLElement>) => void) =>
    (): void =>
      this.setState(
        (state: IResultsTableState): IResultsTableState => ({
          columns: new Map(
            this.getEntries(state).map(([k, value]): [string, IColumn] => [
              k,
              { ...value, sort: k === key ? getNextSorting(value.sort) : null },
            ])
          ),
        })
      );

  public render(): ReactElement {
    const { columns, types, headerGroups } = this.props;
    const entries = this.getEntries(this.state);
    const rows = this.getRows();
    const buttons = entries.map(
      ([key, value]): ReactElement => (
        <th key={key}>
          <div className="range-wrapper">
            <Button
              onClick={this.toggleSort(key)}
              rightIcon={getSortIcon(value.sort)}
              minimal
              fill
              text={this.getColumnTitle(key)}
            />
          </div>
        </th>
      )
    );
    const sliders = entries.map(([key, value], index): ReactElement => {
      return (
        <th key={key}>
          {types[index] === 'boolean' ? null : (
            <div
              className="range-wrapper"
              onTouchMove={this.handleTouchMove}
              onTouchEnd={this.handleTouchEnd}
            >
              <RangeSlider
                min={Math.floor(value.min)}
                max={Math.ceil(value.max)}
                labelStepSize={Math.floor(value.max) || 1}
                onChange={this.handleRangeChange(key)}
                value={[Math.floor(value.range[0]), Math.ceil(value.range[1])]}
              />
            </div>
          )}
        </th>
      );
    });
    const headers = headerGroups.map(
      (header, index): ReactElement => (
        <th key={index} colSpan={header.colspan}>
          {header.title}
        </th>
      )
    );
    return (
      <div className="results-table-wrapper" ref={this.tableRef}>
        <table className="bp4-html-table results-table">
          <thead>
            <tr>{headers}</tr>
            <tr>{buttons}</tr>
            <tr>{sliders}</tr>
          </thead>
          <tbody>
            {rows.map(
              (row, index): ReactElement => (
                <tr className="table-row" key={index}>
                  {columns.map(
                    (col, index): ReactElement => (
                      <td key={index}>{row[col.fieldName]}</td>
                    )
                  )}
                </tr>
              )
            )}
          </tbody>
        </table>
      </div>
    );
  }
}

export default ResultsTable;
