import Keycloak from 'keycloak-js';
import type { IUserWithPermissions } from 'services/api/portal/administration/api/types';
import type { AppState } from './useAuthentication';

/**
 * newKeycloakBackend creates a new authentication backend connecting to keycloak with the provided settings
 * @param url is the base url of the Keycloak instance
 * @param redirectUri to where Keycloak should redirect after authentication/login
 * @param realm in which this client and app users are defined
 * @param clientId of this client
 * @returns an authentication backend implementation which connects to keycloak
 */
export const newKeycloakBackend = (o: Options): Promise<KeycloakBackend> => {
  const kc = new Keycloak({
    url: o.url,
    realm: o.realm,
    clientId: o.clientId,
  });
  kc.redirectUri = o.redirectUri;
  return kc.init({}).then((_) => new KeycloakBackend(kc));
};

/**
 * Implementation of an authentication backend connecting to keycloak
 */
class KeycloakBackend {
  c: Keycloak;

  constructor(c: Keycloak) {
    this.c = c;
  }

  upToDateTokenInBackground(): Promise<string | undefined> {
    return this.c
      .updateToken(MINIMUM_TOKEN_VALIDITY_TIME_S)
      .then((_) => this.c.token);
  }
  // login starts the user login flow via the authentication provider
  login(_currentState: AppState): Promise<void> {
    // Set the redirectUri to the current location, so that the user is redirected back to the page he was on
    return this.c.login({
      redirectUri: window.location.href,
    });
  }
  // authenticationRedirectResult returns, after a login flow with redirects,
  // the state of the application before the login/redirects
  authenticationRedirectResult(): Promise<AppState> {
    throw new Error('authenticationRedirectResult unimplemented');
  }
  // authenticated returns true if the current user has already been authenticated
  authenticated(): Promise<boolean> {
    if (this.c.authenticated === undefined) {
      return Promise.resolve(false);
    }
    return Promise.resolve(this.c.authenticated);
  }
  // user returns information about the current user, including their permissions
  user(): Promise<IUserWithPermissions | undefined> {
    return this.c.loadUserProfile().then((p) => {
      return {
        sub: p.id ? p.id : '',
        id: p.id ? p.id : '',
        name: `${p.firstName ? p.firstName : ''} ${
          p.lastName ? p.lastName : ''
        }`,
        email: p.email ? p.email : '',
        email_verified: p.emailVerified ? p.emailVerified : false,
        picture: '',
        assignments: [],
      };
    });
  }
  // logout logs out (deauthenticates) the current user/ends the current session
  logout(_returnTo?: string): void {
    void this.c.logout();
  }
}

/**
 * The options (potentially) needed for set up the Keycloak client
 */
export interface Options {
  url: string;
  redirectUri: string;
  realm: string;
  clientId: string;
}

// If a token expires sooner than this, it will be refreshed (in seconds, I think)
const MINIMUM_TOKEN_VALIDITY_TIME_S = 10;
