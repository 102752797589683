import type { ReactElement } from 'react';
import React, { useEffect } from 'react';
import type { WithTranslation } from 'react-i18next';
import { withTranslation } from 'react-i18next';
import { H6 } from '@blueprintjs/core';
import type { WrappedFieldProps } from 'redux-form';

export interface IOption {
  value: string;
  text: string;
}

interface ISelectFieldProps extends WithTranslation, WrappedFieldProps {
  title: string;
  onChangeState?: Function;
  options: IOption[];
}

function SelectField(field: ISelectFieldProps): ReactElement {
  const { meta, input, t, onChangeState, title, options } = field;
  useEffect(() => {
    if (meta.initial) {
      onChangeState(meta.initial);
    }
  }, [meta.initial]);
  const { onChange } = input;
  const onChangeWrapper = (arg: React.ChangeEvent<HTMLSelectElement>): void => {
    const { value } = arg.target;
    try {
      onChangeState(value);
    } catch (e) {
      console.warn('onChangeState not found!', e);
    }
    onChange(arg);
  };
  const errorDiv =
    meta.error && meta.dirty ? (
      <div className="bp4-callout bp4-intent-danger bp4-icon-error">
        <H6 className="bp4-heading">
          {t(`validation.errors.${meta.error}` as any)}
        </H6>
      </div>
    ) : null;
  const nodeOptions = options.map(
    (option: IOption, index: number): ReactElement => (
      <option key={index} value={option.value}>
        {t(option.text as any)}
      </option>
    )
  );
  return (
    <div className="bp4-input-group bp4-vertical field-row">
      <div className="bp4-control-group bp4-fill">
        <div className="bp4-input-group left">
          <div className="bp4-input bp4-minimal text-overflow">
            {t(title as any)}
          </div>
          <span className="bp4-icon bp4-icon-torch" />
        </div>
        <div className="bp4-input-group right">
          <div className="bp4-select bp4-fill">
            {/* eslint-disable-next-line */}
            <select value={input.value} onChange={onChangeWrapper}>
              {nodeOptions}
            </select>
          </div>
        </div>
      </div>
      {errorDiv}
    </div>
  );
}

export default withTranslation()(SelectField);
