import Filters from './filters';

interface Props {
  isViewingSingleItem: boolean;
}
const HomeBlurb = ({ isViewingSingleItem }: Props) => {
  return (
    <div className={['py-3 px-5 dark:bg-surface-dark'].join(' ')}>
      <Filters isViewingSingleItem={isViewingSingleItem} />
    </div>
  );
};

export default HomeBlurb;
