import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Icon } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { preventDefaultPropagation } from '../../utils/common/CommonUtils';
import type { CommandDefinition } from 'api/telecommands/types';
import { useAuth } from '../../services/auth/AuthWrapper';
import s from './index.module.scss';

interface IProps {
  cmd: CommandDefinition;
  isFeature: boolean;
  handleFeatureClick: Function;
  handleCommandClick: Function;
}

const CommandListSingleBody = (props: IProps) => {
  const { mission } = useParams<{ mission: string }>();
  const { checkPermissions } = useAuth();
  const { cmd, isFeature, handleFeatureClick, handleCommandClick } = props;

  const [isAllowedToOpenCommand, setIsAllowedToOpenCommand] =
    useState<boolean>(false);

  const handleIconClick = useCallback(() => {
    if (!isAllowedToOpenCommand) return;
    handleFeatureClick(cmd.name);
  }, [cmd.name, handleFeatureClick, isAllowedToOpenCommand]);

  const commandStyles = useMemo(
    () =>
      isAllowedToOpenCommand
        ? s.commandListSingleBodyContainer
        : s.commandListSingleBodyContainerDisabled,
    [isAllowedToOpenCommand]
  );

  useEffect(() => {
    const getCmdOpenPerm = async () => {
      const hasPerm = await checkPermissions({
        id: mission,
        type: 'mission',
        actionScope: cmd.scope,
      });
      setIsAllowedToOpenCommand(hasPerm);
    };

    void getCmdOpenPerm();
  }, [checkPermissions, cmd.scope, mission]);

  return (
    <li
      className={commandStyles}
      //@ts-expect-error
      name={'command-' + cmd.name}
    >
      <Icon
        className={s.commandFeature}
        icon={isFeature ? IconNames.STAR : IconNames.STAR_EMPTY}
        onClick={preventDefaultPropagation(handleIconClick)()}
      />
      <div
        className={s.command}
        role="button"
        tabIndex={0}
        onKeyDown={() => isAllowedToOpenCommand && handleCommandClick(cmd)}
        onClick={() => isAllowedToOpenCommand && handleCommandClick(cmd)}
      >
        {cmd.name}
      </div>
    </li>
  );
};

export default CommandListSingleBody;
