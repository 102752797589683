import { useAuth } from 'services/auth/AuthWrapper';
import missionApi from 'services/api/portal/organisation/missionApi';
import organisationApi from 'services/api/portal/organisation/organisationApi';
import programmeApi from 'services/api/portal/organisation/programmeApi';
import {
  getOrganisations,
  getProgrammes,
  getMissions,
} from 'api/administration/service';
import { useQuery } from 'api/useQuery';
import { BACKEND_BASE_URL } from 'env';

const PORTAL_URL = `${BACKEND_BASE_URL}/portal/v0`;

export const useApiMissionSelectorService = (userId = '') => {
  const getOrganisationsQuery = useQuery(getOrganisations, {
    initialData: [],
    params: { userId },
    skip: !userId,
  });
  const getProgrammesQuery = useQuery(getProgrammes, {
    initialData: [],
    params: { userId },
    skip: !userId,
  });
  const getMissionsQuery = useQuery(getMissions, {
    initialData: [],
    params: { userId },
    skip: !userId,
  });

  const { token } = useAuth();
  const missionApiInstance = missionApi(PORTAL_URL, token);
  const organisationApiInstance = organisationApi(PORTAL_URL, token);
  const programmeApiInstance = programmeApi(PORTAL_URL, token);

  const postOrganisations = async (name: string) => {
    await organisationApiInstance.postOrganisation(name);
    void getOrganisationsQuery.refetch();
  };

  const postProgrammes = async (name: string, organisationId: number) => {
    await programmeApiInstance.postProgramme(name, organisationId);
    void getProgrammesQuery.refetch();
  };

  const postMissions = async (name: string, programmeId: number) => {
    await missionApiInstance.postMission(name, programmeId);
    void getMissionsQuery.refetch();
  };

  return {
    organisationsList: getOrganisationsQuery.data,
    getOrganisations: getOrganisationsQuery.refetch,
    programmesList: getProgrammesQuery.data,
    getProgrammes: getProgrammesQuery.refetch,
    missionsList: getMissionsQuery.data,
    getMissions: getMissionsQuery.refetch,
    postOrganisations,
    postProgrammes,
    postMissions,
  };
};
