import React from 'react';
import { Button, Icon } from '@blueprintjs/core';
import { AOCS_DATA } from '../../constants/moduleData/constants';
import { connect } from 'react-redux';
import type { AppState } from '../../store';
import { selectPlatformModule } from '../../selectors/platformConfigurator';
import type { IPayload } from '../../constants/moduleData/types';
import { showModulePanel } from './utils';
import { setPopUp } from '../../actions/popUp/thunks';
import { IconNames } from '@blueprintjs/icons';

interface IMapStateToProps {
  data: IPayload[];
}

interface IMapDispatchToProps {
  setPopUp: typeof setPopUp;
}

type IProps = IMapStateToProps & IMapDispatchToProps;

const AOCS = ({ data, setPopUp }: IProps) => {
  const shouldShowModuleInfo = !!data.length;

  return (
    <div className="module-card">
      <div
        className={
          shouldShowModuleInfo
            ? 'heading-container'
            : 'heading-spaced-container'
        }
      >
        <div className="module-title">
          <h3 className="module-title-h3">AOCS</h3>
        </div>
        <Button
          text="Select"
          onClick={() => showModulePanel('AOCS Selection', AOCS_DATA, setPopUp)}
        />
      </div>
      {shouldShowModuleInfo && (
        <div className="body-container">
          {data.map((module) => (
            <div key={module.id} className="module-info-container">
              <Icon icon={IconNames.ROTATE_PAGE} />
              <div className="module-info">
                <p>{module.id}</p>
                {/* <p>Some info: {module.data.unitPrice}</p> */}
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state: AppState) => ({
  data: selectPlatformModule(state, AOCS_DATA),
});

const mapDispatchToProps = {
  setPopUp,
};

export default connect<IMapStateToProps, IMapDispatchToProps>(
  mapStateToProps,
  mapDispatchToProps
)(AOCS);
