import { useBandAlgebra } from 'datacosmos/stores/BandAlgebraProvider';
import { useMapLayers } from 'datacosmos/stores/MapLayersProvider';
import type { IExpressionDetails } from 'datacosmos/utils/algebraExpressionFormatter';
import { expressionDetails } from 'datacosmos/utils/algebraExpressionFormatter';
import { useState } from 'react';
import { Item } from 'react-stately';
import Accordion from '_molecules/Accordion/Accordion';
import Tabs from '_molecules/Tabs/Tabs';
import SidebarHeader from '_organisms/SidebarHeader/SidebarHeader';
import { useActivePage } from '../Toolbar/ActivePageProvider';
import ExpressionBased from './CustomAlgebra/ExpressionBased';
import MultiChannel from './CustomAlgebra/MultiChannel';
import SingleBand from './CustomAlgebra/SingleBand';
import IndexCard from './IndexCard';
import { useLocalisation } from 'utils/hooks/useLocalisation';

const BandAlgebra = () => {
  const { setAlgebraMenuOpen } = useActivePage();
  const { setLayersMenuOpen } = useMapLayers();
  const {
    isIndexSelected,
    toggleSelectedIndex,
    saveCustomAlgebra,
    getCustomBandAlgebra,
    deleteIndex,
  } = useBandAlgebra();

  const { translate } = useLocalisation();

  const [customAlgebra, setCustomAlgebra] = useState<IExpressionDetails[]>(
    getCustomBandAlgebra()
  );

  return (
    <div style={{ maxHeight: 'inherit', overflowY: 'hidden' }}>
      <SidebarHeader
        title={translate('datacosmos.layers.bandAlgebra.title')}
        rightIcon={{
          iconName: 'ChevronRight',
          onIconClick: () => {
            setLayersMenuOpen(true);
            setAlgebraMenuOpen(false);
          },
          text: translate('datacosmos.layers.backToLayers'),
        }}
      />

      <Tabs className="p-4 dark:text-item-dark-contrast">
        <Item
          title={
            <div
              className="w-full"
              onClick={() => setCustomAlgebra(getCustomBandAlgebra())}
            >
              {translate('datacosmos.layers.bandAlgebra.indexTab')}
            </div>
          }
        >
          <div>
            <Accordion
              labelText={translate(
                'datacosmos.layers.bandAlgebra.predefined.title'
              )}
              startExpanded
            >
              <div className="w-full">
                {expressionDetails.map((exp) => (
                  <IndexCard
                    index={exp}
                    isIndexSelected={isIndexSelected(exp)}
                    toggleSelectedIndex={(index) => {
                      toggleSelectedIndex(index);
                    }}
                    key={exp.id}
                  />
                ))}
              </div>
            </Accordion>

            <Accordion
              labelText={translate('datacosmos.layers.bandAlgebra.customTab')}
              startExpanded
            >
              <div className="w-full">
                {customAlgebra.map((exp) => (
                  <IndexCard
                    key={exp.id}
                    index={exp}
                    isIndexSelected={isIndexSelected(exp)}
                    toggleSelectedIndex={toggleSelectedIndex}
                    deleteHandler={(expression) => {
                      deleteIndex(expression);
                      setCustomAlgebra(getCustomBandAlgebra());
                      if (isIndexSelected(expression)) {
                        toggleSelectedIndex(expression);
                      }
                    }}
                  />
                ))}
              </div>
            </Accordion>
          </div>
        </Item>

        <Item title={translate('datacosmos.layers.bandAlgebra.custom.title')}>
          <Tabs>
            <Item
              title={translate(
                'datacosmos.layers.bandAlgebra.custom.single.title'
              )}
            >
              <SingleBand
                saveCustomAlgebra={saveCustomAlgebra}
                toggleSelectedIndex={toggleSelectedIndex}
              />
            </Item>
            <Item
              title={translate(
                'datacosmos.layers.bandAlgebra.custom.rgb.title'
              )}
            >
              <MultiChannel
                saveCustomAlgebra={saveCustomAlgebra}
                toggleSelectedIndex={toggleSelectedIndex}
              />
            </Item>
            <Item
              title={translate(
                'datacosmos.layers.bandAlgebra.custom.index.title'
              )}
            >
              <ExpressionBased
                saveCustomAlgebra={saveCustomAlgebra}
                toggleSelectedIndex={toggleSelectedIndex}
              />
            </Item>
          </Tabs>
        </Item>
      </Tabs>
    </div>
  );
};

export default BandAlgebra;
