import { useLocation } from 'react-router';
import { useMission } from 'services/Missions';
import { useApiOpsGetTerminalSessions } from 'services/api/ops/terminalSessions';

export type PossibleRoute = 'msd' | 'ops' | 'data';

export type PageTitleOptions = {
  /**
   * Routes to ignore when setting the page title
   */
  ignoredRoutes?: PossibleRoute[];
};

const DEFAULT_TITLE = 'OpenApp';

/**
 * usePageTitle hook sets the page title based on the current application route.
 * It also allows to ignore certain routes from being set as page title.
 *
 * This hook is intended to be used in the root of the application, for example in the App.tsx file,
 * and it takes effect on every route change.
 *
 * @params options: PageTitleOptions - options to configure the hook
 */
export const usePageTitle = (options?: PageTitleOptions) => {
  const location = useLocation();

  const { currentMission, currentMissionId } = useMission();
  const { sessions } = useApiOpsGetTerminalSessions(currentMissionId);

  const setPageTitle = (title: string) => {
    document.title = title;
  };

  if (
    options?.ignoredRoutes?.includes(
      location.pathname.split('/')[1] as PossibleRoute
    )
  ) {
    setPageTitle(DEFAULT_TITLE);
    return;
  }

  const formatOpsSpecificPathnameAsTitle = (pathNameArray: string[]) => {
    if (!currentMission) {
      return 'Ops';
    }

    const missionName = currentMission?.name;
    const missionId = currentMission?.mission;
    const opsPage = !isNaN(parseInt(pathNameArray[pathNameArray.length - 1]))
      ? 'Ops'
      : pathNameArray[pathNameArray.length - 1].charAt(0).toUpperCase() +
        pathNameArray[pathNameArray.length - 1].slice(1);

    const sessionId = pathNameArray.includes('session')
      ? parseInt(pathNameArray[pathNameArray.length - 1])
      : null;

    const sessionName = sessions.find((s) => s.id === sessionId)?.name;

    return `${missionName} (${missionId}) - ${sessionName ?? opsPage}`;
  };

  const formatMsdSpecificPathnameAsTitle = (pathNameArray: string[]) => {
    return pathNameArray
      .map((p) => p.charAt(0).toUpperCase() + p.slice(1))
      .join(' ');
  };

  const formatDataSpecificPathnameAsTitle = (pathNameArray: string[]) => {
    if (pathNameArray.length <= 1) {
      return `DataCosmos`;
    }

    return `DataCosmos ${
      pathNameArray[pathNameArray.length - 1].charAt(0).toUpperCase() +
      pathNameArray[pathNameArray.length - 1].slice(1)
    }`;
  };

  const formatPathnameAsTitle = () => {
    const pathname = location.pathname.split('/').filter((p) => p !== '');

    if (pathname[0] === 'ops') {
      return formatOpsSpecificPathnameAsTitle(pathname);
    }

    if (pathname[0] === 'msd') {
      return formatMsdSpecificPathnameAsTitle(pathname);
    }

    if (pathname[0] === 'data') {
      return formatDataSpecificPathnameAsTitle(pathname);
    }

    return DEFAULT_TITLE;
  };

  setPageTitle(formatPathnameAsTitle());
};
