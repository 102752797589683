import ResourceLayout from './ResourceLayout';
import ResourceHeader from './ResourceHeader';
import DefaultResourceTypeLayout from 'layouts/datacosmos/DefaultResourceTypesLayout';

interface Props {
  resourceId: string;
  resourceType: string;
}
const ApplicationResources = (props: Props) => {
  return (
    <DefaultResourceTypeLayout
      loading={false}
      middleTitle={<div className="text-lg font-bold">Application</div>}
    >
      <ResourceHeader title={props.resourceId} icon="Menu" />
      <div className="my-5 border-t-[1px] border-accent-700 dark:border-item-dark-hover" />
      <ResourceLayout
        resourceId={props.resourceId}
        resourceType={props.resourceType}
      />
    </DefaultResourceTypeLayout>
  );
};

export default ApplicationResources;
