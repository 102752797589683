import { DATE_FORMAT } from 'constants/datetime';
import moment from 'moment';
import { useMemo, useEffect, useState, useCallback } from 'react';
import { useMission } from 'services/Missions';
import SchedulingHeader from 'pages/ops/Scheduling/SchedulingHeader';
import { ActivityCard } from 'pages/ops/Scheduling/ScheduleActivityList/ActivityCard';
import type {
  Activity,
  ActivityType,
  ActivityStatus,
} from 'api/activities/service';
import type { FromToDate } from 'pages/ops/Scheduling';
import ActivityCalendar from '_organisms/ActivityCalendar/ActivityCalendar';
import { useHistory, useRouteMatch } from 'react-router';
import { Spinner } from '@blueprintjs/core';
import type { TaskingRequest } from 'api/tasking/types';

type ScheduleActivityListProps = {
  activities: Activity[];
  requests: TaskingRequest[];
  fromToDate: FromToDate;
  setFromToDate: React.Dispatch<React.SetStateAction<FromToDate>>;
  setCalendarMode: React.Dispatch<React.SetStateAction<boolean>>;
  isCalendarMode: boolean;
  loading: boolean;
};

export const ScheduleActivityList = ({
  activities,
  requests,
  fromToDate,
  setFromToDate,
  setCalendarMode,
  isCalendarMode,
  loading,
}: ScheduleActivityListProps) => {
  const routeMatch = useRouteMatch<{ mission: string; activity: string }>(
    '/ops/mission/:mission/schedule/activity/:activity'
  );

  const history = useHistory();

  const { currentMissionId } = useMission();

  const [selectedStatuses, setSelectedStatuses] = useState<ActivityStatus[]>(
    []
  );

  const [selectedTypes, setSelectedTypes] = useState<ActivityType[]>([]);

  const [filtered, setFiltered] = useState<Activity[]>(activities);

  const groupedActivities = useMemo(() => {
    return filtered.reduce<{ [date: string]: Activity[] }>((acc, value) => {
      return {
        ...acc,
        [moment(value.start_date).format(DATE_FORMAT)]: [
          ...(acc[moment(value.start_date).format(DATE_FORMAT)] || []),
          value,
        ],
      };
    }, {});
  }, [filtered]);

  const handleActivityClick = useCallback(
    (activity: Activity) => {
      if (!currentMissionId) {
        return;
      }
      history.push(
        `/ops/mission/${currentMissionId}/schedule/activity/${activity.id}${location.search}`
      );
    },
    [currentMissionId, history]
  );

  useEffect(() => {
    setFiltered(
      activities
        .filter((activity) =>
          selectedStatuses.length > 0
            ? selectedStatuses.some((status) => status === activity.status)
            : true
        )
        .filter((activity) =>
          selectedTypes.length > 0
            ? selectedTypes.some((type) => type === activity.type.toString())
            : true
        )
    );
  }, [selectedStatuses, selectedTypes, fromToDate, activities]);

  const renderWithHeader = (content: React.ReactNode) => {
    return (
      <div
        style={{
          padding: '0 20px',
          height: '100%',
          overflowY: 'auto',
          flex: 1,
          paddingBottom: '75px',
        }}
        data-testid="schedule-activity-list"
      >
        <SchedulingHeader
          selectedStatuses={selectedStatuses}
          setActivityStatus={setSelectedStatuses}
          fromToDate={fromToDate}
          setFromToDate={setFromToDate}
          setSelectedTypes={setSelectedTypes}
          selectedTypes={selectedTypes}
          setCalendarMode={setCalendarMode}
          isCalendarMode={isCalendarMode}
        />

        {content}
      </div>
    );
  };

  const renderWithLoading = (content: React.ReactNode) => {
    if (loading) {
      return <Spinner />;
    }
    return content;
  };

  const renderListMode = () => {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '30px',
          maxHeight: 'inherit',
        }}
      >
        {Object.keys(groupedActivities)
          .sort(
            (a, b) =>
              moment(b, 'DD-MM-YYYY').toDate().getTime() -
              moment(a, 'DD-MM-YYYY').toDate().getTime()
          )
          .reverse()
          .map((date) => (
            <div
              key={date}
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '20px',
              }}
            >
              <span style={{ marginLeft: '50px' }}>{date}</span>
              {groupedActivities[date]
                .sort(
                  (a, b) =>
                    moment(a.start_date).toDate().getTime() -
                    moment(b.start_date).toDate().getTime()
                )
                .map((activity) => (
                  <ActivityCard
                    key={activity.id}
                    activity={activity}
                    handleActivityClick={handleActivityClick}
                    routeMatch={routeMatch}
                  />
                ))}
            </div>
          ))}
      </div>
    );
  };

  const renderCalendarMode = () => {
    return (
      <ActivityCalendar
        activities={filtered}
        requests={requests}
        onActivityClick={(activity) => {
          handleActivityClick(activity);
        }}
        selectedActivityId={routeMatch?.params.activity}
        onCellClick={(date) => {
          setFromToDate({
            from: moment.utc(date.toString()).startOf('day').toDate(),
            to: moment
              .utc(date.toString())
              .add(1, 'day')
              .startOf('day')
              .toDate(),
          });
          setCalendarMode(false);
        }}
      />
    );
  };

  if (isCalendarMode) {
    return renderWithHeader(renderWithLoading(renderCalendarMode()));
  }

  return renderWithHeader(renderWithLoading(renderListMode()));
};
