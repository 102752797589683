import type { IOption } from '../../inputField/SelectField';
import {
  GROUND_SEGMENT_EVENT,
  OBJECTIVE_METRIC,
  OBJECTIVE_TYPES,
  SCOPE,
} from '../../../constants/objectivesTable/constants';
import { Pre } from '@blueprintjs/core';
import { Field, Fields } from 'redux-form';
import selectField from '../../inputField/SelectField';
import type { ReactElement } from 'react';
import React from 'react';
import CriterionField from '../../inputField/CriterionField';

export const GroundSegment = (): ReactElement => {
  const objectOptions: IOption[] = [
    {
      value: GROUND_SEGMENT_EVENT.OBJECT,
      text: 'objectives.object.all_ground_stations',
    },
  ];
  const eventOptions: IOption[] = [
    {
      value: GROUND_SEGMENT_EVENT.EVENT.VISIBILITY,
      text: 'objectives.event.visibility',
    },
  ];
  const scopeOptions: IOption[] = [
    {
      value: SCOPE.COVERAGE,
      text: 'objectives.scope.coverage',
    },
    {
      value: SCOPE.REVISIT,
      text: 'objectives.scope.revisit',
    },
  ];
  const metricOptions: IOption[] = [
    {
      value: OBJECTIVE_METRIC.INCIDENCE,
      text: 'objectives.metric.incidence',
    },
    {
      value: OBJECTIVE_METRIC.AVG_DURATION,
      text: 'objectives.metric.avg_duration',
    },
    {
      value: OBJECTIVE_METRIC.MIN_DURATION,
      text: 'objectives.metric.max_duration',
    },
    {
      value: OBJECTIVE_METRIC.MAX_DURATION,
      text: 'objectives.metric.min_duration',
    },
    {
      value: OBJECTIVE_METRIC.DURATION_STD_DEVIATION,
      text: 'objectives.metric.duration_std_deviation',
    },
  ];

  return (
    <Pre key={OBJECTIVE_TYPES.GROUND_SEGMENT_EVENT}>
      <Field
        name="object"
        title={'objectives.object.title'}
        options={objectOptions}
        component={selectField}
      />
      <Field
        name="event"
        title={'objectives.event.title'}
        options={eventOptions}
        component={selectField}
      />
      <Field
        name="scope"
        title={'objectives.scope.title'}
        options={scopeOptions}
        component={selectField}
      />
      <Field
        name="metric"
        title={'objectives.metric.title'}
        options={metricOptions}
        component={selectField}
      />
      <Fields
        names={['criterion', 'constraints.min', 'constraints.max', 'metric']}
        component={CriterionField}
      />
    </Pre>
  );
};
