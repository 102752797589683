import type { Action } from 'redux';
import type { ThunkAction } from 'redux-thunk';
import {
  addPointOfInterest,
  removePointOfInterest,
  updatePointOfInterest,
} from 'actions/pointsOfInterest/action';
import type { IPointOfInterest } from 'constants/pointsOfInterest/actionTypes';
import type { AppState } from 'reducers/rootReducer';
import { SubmissionError } from 'redux-form';
import { resetFocus } from 'actions/focus/thunks';
import { showAlert } from 'services/mapService/alert/services';
import { toaster } from 'toaster';
import { removeGoal, updateGoal } from 'actions/goal/thunks';
import type { IGoal } from 'constants/goals/interfaceas';
import { getObjectiveName } from 'utils/getObjectiveName';

export const addPI =
  (
    pointOfInterestData: IPointOfInterest
  ): ThunkAction<void, AppState, null, Action<string>> =>
  async (dispatch): Promise<void> => {
    dispatch(addPointOfInterest(pointOfInterestData));
  };

export const updatePI =
  (
    pointOfInterestData: IPointOfInterest
  ): ThunkAction<void, AppState, null, Action<string>> =>
  async (dispatch, state): Promise<void> => {
    const { pointsOfInterest } = state();
    pointsOfInterest.list.forEach((pointOfInterest): void => {
      if (pointOfInterest.name === pointOfInterestData.name) {
        if (pointOfInterest.id !== pointOfInterestData.id) {
          const errorMessage = 'name_not_unique';
          throw new SubmissionError({
            name: errorMessage,
            _error: errorMessage,
          });
        }
      }
    });
    const goals = state().goals;
    const foundedElement = goals.find((g) => {
      if (typeof g.object === 'object') {
        return g.object.id === pointOfInterestData.id;
      }
      return false;
    });
    if (foundedElement) {
      foundedElement.object = pointOfInterestData;
      const goalWithName: IGoal = {
        ...foundedElement,
        name: getObjectiveName(foundedElement, () => {})(),
      };
      const message = 'Goals was changed';
      toaster.show({ icon: 'warning-sign', intent: 'warning', message });
      dispatch(updateGoal(goalWithName));
    }

    dispatch(updatePointOfInterest(pointOfInterestData));
  };

export const removePI =
  (id: string): ThunkAction<void, AppState, null, Action<string>> =>
  async (dispatch, state): Promise<void> => {
    const goals = state().goals;
    const foundedElement = goals.find((g) => {
      if (typeof g.object === 'object') {
        return g.object.id === id;
      }
      return false;
    });
    if (foundedElement) {
      showAlert((): void => {
        dispatch(resetFocus());
        dispatch(removePointOfInterest(id));
        dispatch(removeGoal(foundedElement));
        const message = 'Goals was changed';
        toaster.show({ icon: 'warning-sign', intent: 'warning', message });
      }, `This point: '${foundedElement.object.name}' is using in Goals, do you want to remove it?`);
    } else {
      dispatch(resetFocus());
      dispatch(removePointOfInterest(id));
    }
  };
