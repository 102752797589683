export const setNumberAxisY = (chart: echarts.ECharts) => {
  chart.setOption({
    yAxis: {
      max: 'dataMax',
      min: 'dataMin',
      axisLabel: {
        formatter: null,
        showMaxLabel: false,
        showMinLabel: false,
      },
    },
  });
};
