import React from 'react';
import { VisuallyHidden, useCheckbox, useFocusRing } from 'react-aria';
import { useToggleState } from 'react-stately';
import type { AriaCheckboxProps } from '@react-types/checkbox';
import { useTheme } from 'datacosmos/stores/ThemeProvider';

type Props = {
  children?: React.ReactNode;
} & AriaCheckboxProps;

const Checkbox = (props: Props) => {
  const state = useToggleState(props);
  const ref = React.useRef(null);
  const { inputProps } = useCheckbox(props, state, ref);
  const { isFocusVisible, focusProps } = useFocusRing();
  const isSelected = state.isSelected && !props.isIndeterminate;

  const theme = useTheme();

  const getCheckboxFill = () => {
    if (isSelected) {
      return theme?.isDarkmode ? '#FFCC55' : '#FFC848';
    }

    if (props.isDisabled) {
      return theme?.isDarkmode ? '#303030' : '#F2F0ED';
    }

    return theme?.isDarkmode ? '#535353' : '#ECE9E5';
  };

  const checkboxRectangle = (
    <rect
      x={isSelected ? 4 : 5}
      y={isSelected ? 4 : 5}
      width={isSelected ? 16 : 14}
      height={isSelected ? 16 : 14}
      fill={getCheckboxFill()}
      stroke={isSelected ? 'none' : '#8C8C8C'}
      strokeWidth={1}
    />
  );

  const checkboxTick = (
    <path
      transform="translate(7 7)"
      d={`M3.788 9A.999.999 0 0 1 3 8.615l-2.288-3a1 1 0 1 1
            1.576-1.23l1.5 1.991 3.924-4.991a1 1 0 1 1 1.576 1.23l-4.712
            6A.999.999 0 0 1 3.788 9z`}
    />
  );

  return (
    <label
      style={{
        display: 'flex',
        alignItems: 'center',
        opacity: props.isDisabled ? 0.4 : 1,
      }}
    >
      <VisuallyHidden>
        <input {...inputProps} {...focusProps} ref={ref} />
      </VisuallyHidden>
      <svg
        width={24}
        height={24}
        aria-hidden="true"
        style={{ marginRight: 4 }}
        onClick={(e) => e.stopPropagation()}
      >
        {checkboxRectangle}
        {isSelected && checkboxTick}
        {isFocusVisible && (
          <rect
            x={1}
            y={1}
            width={22}
            height={22}
            fill="none"
            stroke="orange"
            strokeWidth={2}
          />
        )}
      </svg>
      {props.children}
    </label>
  );
};

export default Checkbox;
