import type { ReactElement } from 'react';
import React from 'react';
import { SubForm } from './SubForm';

interface IStationaryOrbitProps {
  astrum: string;
  change?: Function;
}

const controlNames = ['longitude', 'inclination'];

const renderStationaryOrbit = (props: IStationaryOrbitProps): ReactElement => {
  const { change, astrum } = props;
  return (
    <SubForm astrum={astrum} change={change} controlNames={controlNames} />
  );
};
export const StationaryOrbit = renderStationaryOrbit;
