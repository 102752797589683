import type { IParameters, IWalkerConstellation } from './interfaces';
import { GEOMETRY_TYPE } from '../constants';
import { SatellitesOrbitConstraints } from '../satelliteOrbits/constants';

function format(obj: any, property: string): IParameters {
  return {
    optimisation: false,
    value: obj[property].min,
    min: obj[property].min,
    max: obj[property].max,
  };
}

export const walkerConstellationInitialValues: IWalkerConstellation = {
  name: '',
  id: '',
  type: GEOMETRY_TYPE.WALKER_CONSTELLATION,
  force: true,
  astrum: '',

  satellitesPerPlane: format(SatellitesOrbitConstraints, 'satellitesPerPlane'),
  firstPlaneRaan: format(SatellitesOrbitConstraints, 'firstPlaneRaan'),
  relativeSpacing: format(SatellitesOrbitConstraints, 'relativeSpacing'),
  inclination: format(SatellitesOrbitConstraints, 'inclination'),
  numberOfPlanes: format(SatellitesOrbitConstraints, 'numberOfPlanes'),
};
