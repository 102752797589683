import React from 'react';
import styles from './styles.module.scss';
import FieldWithErrorCallout from '../../../../components/formik/FieldWithErrorCallout';

interface IProps {
  readOnly: boolean;
}

const PhysicalProperties: React.FunctionComponent<IProps> = ({ readOnly }) => {
  return (
    <div>
      <div>
        <h3 className={styles.title}>Thermal Characteristics</h3>
      </div>
      <div className={styles.body}>
        <div className={styles.row}>
          <div className={styles.labelColumn}>
            <span>Min Temp [°C]</span>
          </div>

          <div className={styles.valueColumn}>
            <FieldWithErrorCallout
              type="text"
              name="data.thermal.minimumTemperature"
              className={`bp4-input ${styles.customBp3Cell}`}
              disabled={readOnly}
            />
          </div>
        </div>
        <div className={styles.row}>
          <div className={styles.labelColumn}>
            <span>Max Temp [°C]</span>
          </div>

          <div className={styles.valueColumn}>
            <FieldWithErrorCallout
              type="text"
              name="data.thermal.maximumTemperature"
              className={`bp4-input ${styles.customBp3Cell}`}
              disabled={readOnly}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PhysicalProperties;
