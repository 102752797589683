import classNames from 'classnames';
import type { IconName } from '_atoms/Icon/Icon';
import Icon from '_atoms/Icon/Icon';

export interface IListItemProps {
  icon?: IconName;
  iconStroke?: string;
  content: string | null | undefined;
  noTruncate?: true;
  className?: string;
  onClick: () => void;
  active?: boolean;
}

const ListItem = (props: IListItemProps) => {
  return (
    <li
      className={classNames(props.className, 'flex items-center gap-2', {
        'dark:text-accent text-accent-900': props.active,
      })}
      onClick={() => props.onClick()}
    >
      {props.icon && <Icon icon={props.icon} stroke={props.iconStroke} />}
      <span
        className={classNames({
          'truncate max-w-label w-min overflow-ellipsis': !props.noTruncate,
        })}
      >
        {props.content}
      </span>
    </li>
  );
};

export default ListItem;
