import type { IScenarioApiSearch } from 'datacosmos/types/stac-types';
import { StacItem } from 'datacosmos/types/stac-types';
import type { IFilter } from 'datacosmos/services/imageCatalogApi';
import { makeQuery } from 'datacosmos/services/imageCatalogApi';
import type { SearchScenariosData } from 'api/scenarios/service';
import { searchScenarios } from 'api/scenarios/service';
import type { STACItem } from 'api/stac/types';

/**
 * Provides access to the scenario service API
 */
export class ScenarioApi {
  token: string | undefined;

  constructor(token: string | undefined) {
    this.token = token;
  }

  async fetchImages(
    scenarioId: string,
    filters: IFilter,
    cursor?: string,
    limit?: number,
    intersects?: GeoJSON.Polygon[]
  ) {
    const query = makeQuery(filters);

    let requestBody;

    if (intersects && intersects.length > 0) {
      requestBody = {
        query,
        limit,
        intersects: intersects[0],
      };
    } else {
      requestBody = {
        query,
        limit,
      };
    }

    const { data: projectItems } = await searchScenarios({
      params: {
        scenarioId,
        cursor,
      },
      body: requestBody,
      abortable: false,
    });

    return projectItems ? fromAPIResponse(projectItems) : undefined;
  }
}

const fromAPIFeature = (f: STACItem): StacItem => {
  return new StacItem({
    id: f.id,
    collection: f.collection,
    stac_version: f.stac_version ?? '',
    stac_extensions: f.stac_extensions ?? [],
    bbox: f.bbox,
    type: f.type,
    links: f.links,
    assets: f.assets,
    geometry: f.geometry,
    properties: f.properties,
  });
};

const fromAPIResponse = (r: SearchScenariosData): IScenarioApiSearch => {
  return {
    features: r.features.map((f: STACItem) => fromAPIFeature(f)),
    type: r.type,
    context: r.context,
    next: r.next,
    prev: r.prev,
  };
};
