import type { ITourState, tourTypes } from 'constants/tour/actionTypes';
import { TOUR_STEPS } from 'constants/tour/constants';
import React, { useContext, useReducer } from 'react';
import type { Key } from 'react';
import tourReducer from 'reducers/tour';

export interface IProps {
  children: JSX.Element;
}

export interface ITourContext {
  state: ITourState;
  dispatch: (action: tourTypes) => void;
}

const initialState = {
  key: new Date() as unknown as Key,
  run: false,
  continuous: true,
  loading: false,
  stepIndex: 0,
  steps: TOUR_STEPS,
};

export const TourContext = React.createContext<ITourContext>(
  null as unknown as ITourContext
);
export const useTour = () => useContext<ITourContext>(TourContext);

export const TourProvider = ({ children }: IProps) => {
  const [state, dispatch] = useReducer(tourReducer, initialState);

  return (
    <TourContext.Provider
      value={{
        state,
        dispatch,
      }}
    >
      {children}
    </TourContext.Provider>
  );
};
