import React, { useEffect, useMemo, useState } from 'react';
import type { Column, Row, TableState } from 'react-table';
import { useTable, useFilters } from 'react-table';
//@ts-ignore - custom part of react-table, js only
import { useRowSelect } from '../customReactTableHooks/useRowSelect';

import { isEqual } from 'lodash';

import styles from './PayloadTable.module.scss';
import { uniq, max } from 'lodash/fp';
import { MenuItem, Checkbox, Button, Menu, Popover } from '@blueprintjs/core';
import { connect } from 'react-redux';
import type { AppState } from '../../reducers/rootReducer';
import { formatPayloadType } from '../../utils/common/stringUtils';
import type {
  IPayload,
  ISelectedPayloads,
} from '../../constants/moduleData/types';
import {
  updatePayload,
  selectPayload,
  unselectPayload,
} from '../../actions/ui/moduleSelector/action';
import {
  selectCurrentPayloadNames,
  selectIsCreatingCustomPayload,
  selectCurrentPayloadName,
  selectAllPayloads,
} from '../../selectors/moduleSelector';
import { formatFromKelvinToCelsius } from '../../utils/common/temperature';
import { CUSTOM_PAYLOAD_NAME } from '../../constants/ui/moduleSelector/constants';

interface IStateProps {
  state: AppState;
  currentPayload: string;
  selectedPayloadNames: string[];
  isCreatingCustomPayload: boolean;
}

interface IDispatchProps {
  updatePayload: typeof updatePayload;
  selectPayload: typeof selectPayload;
  unselectPayload: typeof unselectPayload;
}

type IProps = IStateProps & IDispatchProps;

type ISelectedPayloadTypes = string[];

type TOnSelectedRows = (rows: Row<IPayload>) => void;

interface IPayloadHeaderProps {
  column: {
    filterValue: ISelectedPayloadTypes;
    setFilter: (prevFilters: ISelectedPayloadTypes) => ISelectedPayloadTypes;
  };
}

const getPeakPower = (row: IPayload) => {
  if (!row.data.modes) return 'NaN';

  const modeInfo = Object.values(row.data.modes);

  return !!modeInfo.length
    ? max(
        modeInfo.map((modeConfig) => modeConfig.powerConsumptionMode.data.value)
      )
    : '0';
};

const formatPayloadTypeCell = ({ cell: { value } }: ITableCell) =>
  formatPayloadType(value);

const formatCellTo2DP = ({ cell: { value } }: ITableCell) =>
  Number(value).toFixed(2);

const formatVolumeCell = ({ cell: { value } }: ITableCell) =>
  Number(value).toFixed(2);

interface ITableCell {
  cell: { value: string };
}

const formatTemperatureCell = ({ cell: { value } }: ITableCell) =>
  formatFromKelvinToCelsius(Number(value));

const getAllTypes = (data: IPayload[]) =>
  uniq(data.map((sensor) => sensor.type));

const computeTableInitialProps = (
  existingPayloadName: string,
  data: IPayload[]
) => {
  const selectedRowIndex = data.findIndex(
    (payload) => payload.id === existingPayloadName
  );

  return { [selectedRowIndex]: true };
};

const getTableRowStyle = (
  value: IPayload,
  checked: boolean,
  selectedPayloadNames: string[],
  currentPayload: string
) => {
  const rowSelected = selectedPayloadNames.includes(value.id);

  if (rowSelected) {
    if (currentPayload === value.id) return 'selectedAndPinnedTableRow';
    else return 'selectedTableRow';
  }

  return `${'clickableTableRow'}${checked ? ' pinnedTableRow' : ''}`;
};

function Table({
  columns,
  data,
  onSelectedRows,
  initialState,
  selectedPayloadNames,
  isCreatingCustomPayload,
  currentPayload,
}: {
  columns: Column<IPayload>[];
  data: IPayload[];
  onSelectedRows: TOnSelectedRows;
  initialState: TableState<IPayload>;
  selectedPayloadNames: string[];
  isCreatingCustomPayload: boolean;
  currentPayload: string;
}) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    //@ts-ignore - latest react-table v7 not fully typed yet
    selectedFlatRows,
    //@ts-ignore - latest react-table v7 not fully typed yet
    flatGroupedRowsById,
    //@ts-ignore - latest react-table v7 not fully typed yet
    toggleAllRowsSelected,
  } = useTable<IPayload>(
    {
      columns,
      data,
      initialState,
    },
    useFilters,
    useRowSelect
  );

  React.useEffect(() => {
    isCreatingCustomPayload
      ? onSelectedRows([] as any)
      : onSelectedRows(selectedFlatRows);
  }, [rows, selectedFlatRows, flatGroupedRowsById]);

  // We don't have any header groups for now, so all columns are under the first one
  const header = headerGroups[0];

  return (
    <table {...getTableProps()} className={'minimalTable'}>
      <thead>
        <tr {...header.getHeaderGroupProps()} className={'tableHeader'}>
          {header.headers.map((column: any) => {
            return (
              <th
                {...column.getHeaderProps()}
                className={'tableCell'}
                key={column.Header as string}
              >
                {column.id !== 'type' ? column.render('Header') : null}
                {column.id === 'type' ? column.render('Filter') : null}
              </th>
            );
          })}
        </tr>
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row: any) => {
          prepareRow(row);
          // @ts-ignore - latest react-table v7 not fully typed yet
          const selectionProps = row.getToggleRowSelectedProps();

          const style = getTableRowStyle(
            row.original,
            selectionProps.checked,
            selectedPayloadNames,
            currentPayload
          );

          return (
            <tr
              {...row.getRowProps()}
              className={`${style} tableRow`}
              onClick={() => {
                if (currentPayload !== CUSTOM_PAYLOAD_NAME) {
                  toggleAllRowsSelected(false);
                  //@ts-ignore - latest react-table v7 not fully typed yet
                  row.toggleRowSelected();
                }
              }}
              key={`row_${row.values.id}`}
            >
              {row.cells.map((cell: any) => (
                <td
                  {...cell.getCellProps()}
                  className={'tableCell'}
                  key={`${row.values.id}__${cell.column.Header}__${cell.value}`}
                >
                  {cell.render('Cell')}
                </td>
              ))}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}

const getSelectedRowIds = (
  currentPayload: string,
  payloads: ISelectedPayloads
) => {
  if (!currentPayload || currentPayload === CUSTOM_PAYLOAD_NAME) return [];

  return computeTableInitialProps(currentPayload, Object.values(payloads));
};

function PayloadTable(props: IProps) {
  const {
    state,
    currentPayload,
    selectedPayloadNames,
    updatePayload,
    isCreatingCustomPayload,
    selectPayload,
    unselectPayload,
  } = props;

  const [payloads, setPayloads] = useState(selectAllPayloads(state));

  useEffect(() => {
    const p = selectAllPayloads(state);
    if (!isEqual(p, payloads)) {
      setPayloads(p);
    }
  }, [state.moduleData, state.moduleSelector]);

  const dataRows = useMemo(() => Object.values(payloads), [payloads]);

  const tableInitialState: TableState<IPayload> = {
    //@ts-ignore - latest react-table v7 not fully typed yet
    selectedRowIds: getSelectedRowIds(currentPayload, payloads),
    filters: [],
    hiddenColumns: [],
  };

  const onSelectedRows: TOnSelectedRows = (rows) => {
    const newlySelectedPayload: IPayload | undefined =
      //@ts-ignore - latest react-table v7 not fully typed yet (selectedFlatRows)
      rows && rows.length ? rows[0].original : undefined;

    !isCreatingCustomPayload &&
      updatePayload(newlySelectedPayload && newlySelectedPayload.id);
  };

  const PayloadTypeRenderer = (
    item: string,
    setFilter: (prevFilters: ISelectedPayloadTypes) => ISelectedPayloadTypes,
    filterValue: ISelectedPayloadTypes
  ) => (
    <MenuItem
      key={item}
      text={formatPayloadType(item)}
      onClick={() =>
        //@ts-ignore - expects Array but eslint fixes to []
        setFilter((prevFilters: ISelectedPayloadTypes) => {
          if (!prevFilters) return [item];
          if (prevFilters.includes(item))
            return prevFilters.filter(
              (currentItem: string) => currentItem !== item
            );
          return [...prevFilters, item];
        })
      }
      shouldDismissPopover={false}
      icon={filterValue && filterValue.includes(item) ? 'tick' : 'blank'}
    />
  );

  const PayloadTypeHeader = ({
    column: { filterValue, setFilter },
  }: IPayloadHeaderProps) => (
    <div className={styles.payloadTypeHeader}>
      <p className={styles.payloadText}>Type</p>
      <Popover minimal>
        <Button icon="filter" />
        <Menu>
          {getAllTypes(dataRows).map((item) =>
            PayloadTypeRenderer(item, setFilter, filterValue)
          )}
        </Menu>
      </Popover>
    </div>
  );

  function filterPayloadTypes(
    rows: Row<IPayload>[],
    id: string,
    filterValue: string[]
  ) {
    return filterValue.length
      ? rows.filter((row) => filterValue.includes(row.values[id]))
      : rows;
  }

  const columns = React.useMemo(
    () => [
      {
        Header: 'PayloadTypeHeader',
        accessor: 'type',
        Cell: formatPayloadTypeCell,
        Filter: PayloadTypeHeader,
        filter: filterPayloadTypes,
      },
      {
        Header: 'Name',
        accessor: 'id',
      },
      {
        Header: 'Mass [kg]',
        accessor: 'data.physical.mass',
        Cell: formatCellTo2DP,
      },
      {
        Header: 'Volume [U]',
        accessor: (row: IPayload) =>
          (row.data.physical.xLength *
            100 *
            row.data.physical.yLength *
            100 *
            row.data.physical.zLength *
            100) /
          1000,
        Cell: formatVolumeCell,
      },
      {
        Header: 'Min Temp [°C]',
        accessor: 'data.thermal.minimumTemperature',
        Cell: formatTemperatureCell,
      },
      {
        Header: 'Max Temp [°C]',
        accessor: 'data.thermal.maximumTemperature',
        Cell: formatTemperatureCell,
      },
      {
        Header: 'Peak Power [W]',
        accessor: getPeakPower,
        Cell: formatCellTo2DP,
        show: !!dataRows[0].data.modes,
      },
      {
        Header: 'Selected',
        accessor: 'id',
        Cell: (data: any) => {
          const SelectedCheckbox = (
            <Checkbox
              checked={selectedPayloadNames.includes(data.row.values.id)}
              onClick={() =>
                selectedPayloadNames.includes(data.row.values.id)
                  ? unselectPayload(data.row.values.id)
                  : selectPayload(data.row.values.id)
              }
            />
          );

          return SelectedCheckbox;
        },
      },
    ],
    [selectedPayloadNames]
  );

  return (
    <div className={styles.payloadTable}>
      <Table
        columns={columns as any}
        data={dataRows}
        onSelectedRows={onSelectedRows}
        initialState={tableInitialState}
        selectedPayloadNames={selectedPayloadNames}
        isCreatingCustomPayload={isCreatingCustomPayload}
        currentPayload={currentPayload}
      />
    </div>
  );
}

const mapStateToProps = (state: AppState): IStateProps => ({
  state: state,
  currentPayload: selectCurrentPayloadName(state),
  selectedPayloadNames: selectCurrentPayloadNames(state),
  isCreatingCustomPayload: selectIsCreatingCustomPayload(state),
});

const mapDispatchToProps: IDispatchProps = {
  updatePayload,
  selectPayload,
  unselectPayload,
};

export default connect<IStateProps, IDispatchProps>(
  mapStateToProps,
  mapDispatchToProps
)(PayloadTable);
