import type {
  IAPIConstellation,
  IAPIEvent,
  IAPIEventData,
  IAPIGeometry,
  IAPIGeometryData,
  IAPIGeometryTypes,
  IAPICompute,
  IAPIPointOfInterest,
  IAPIRegionOfInterest,
} from '../../../constants/API/interfaces';
import type {
  IGeometryObject,
  IGeometryObjectState,
} from '../../../constants/msd/geometry/interfaces';
import type {
  IPIState,
  IPointOfInterest,
} from '../../../constants/pointsOfInterest/actionTypes';
import type {
  IRegionOfInterest,
  IRIState,
} from '../../../constants/regionsOfInterest/actionTypes';
import { GEOMETRY_TYPE as geometryTypes } from '../../../constants/msd/geometry/constants';
import {
  ASTRUMS,
  ELEVATION_MASK,
  GEOMETRY_TYPES as geometryTypesAPI,
} from '../../../constants/API/constant';
import type { IMission } from '../../../services/Missions';
import type { IGSState } from '../../../constants/groundStations/actionTypes';
import type { IParameter } from '../../../constants/msd/geometry/satelliteOrbits/interfaces';
import { MESSAGE_TYPE } from '../../../services/api/msd/responseCodes';
import { TLE_OBJECT_BY_NAME } from '../../../services/tle/tleObjectService';
import { FocusTypes } from '../../../constants/focus/actionTypes';

const multiplier = 1000;

const convertParameter = (
  parameter: IParameter,
  multiplier: number
): IParameter => {
  if (!parameter) {
    return undefined;
  }
  return {
    value: parameter.value * multiplier,
    max: parameter.max * multiplier,
    min: parameter.min * multiplier,
    optimisation: parameter.optimisation,
  };
};

const getGeometry = (date: Date, geometry: IGeometryObject): IAPIGeometry => {
  let type: IAPIGeometryTypes;
  let data: IAPIGeometryData;
  switch (geometry.type) {
    case geometryTypes.TLE: {
      type = geometryTypesAPI.TLE;
      data = {
        astrum: geometry.astrum,
        force: Boolean(geometry.force),
        date,
        line1: geometry.tle1,
        line2: geometry.tle2,
      };

      if (geometry.tleObject) {
        data.line1 = TLE_OBJECT_BY_NAME[geometry.tleObject].tle1;
        data.line2 = TLE_OBJECT_BY_NAME[geometry.tleObject].tle2;
      }
      break;
    }
    case geometryTypes.KEPLERIAN: {
      type = geometryTypesAPI.KEPLERIAN;
      data = {
        astrum: geometry.astrum,
        force: Boolean(geometry.force),
        date,
        argumentOfPerigee: geometry.argumentOfPerigee,
        eccentricity: geometry.eccentricity,
        inclination: geometry.inclination,
        semimajorAxis: convertParameter(geometry.semimajorAxis, multiplier),
        trueAnomaly: geometry.trueAnomaly,
        raan: geometry.raan,
      };
      break;
    }
    case geometryTypes.WALKER_CONSTELLATION: {
      type = geometryTypesAPI.WALKER;
      data = {
        astrum: geometry.astrum,
        force: Boolean(geometry.force),
        //TODO constant
        semimajorAxis: convertParameter(geometry.altitude, multiplier),
        satellitesPerPlane: geometry.satellitesPerPlane,
        firstPlaneRaan: geometry.firstPlaneRaan,
        relativeSpacing: geometry.relativeSpacing,
        date,
        inclination: geometry.inclination,
        numberOfPlanes: geometry.numberOfPlanes,
      };
      break;
    }
    case geometryTypes.STATIONARY: {
      type = geometryTypes.STATIONARY;
      data = {
        astrum: geometry.astrum,
        force: Boolean(geometry.force),
        date,
        inclination: geometry.inclination,
        longitude: geometry.longitude,
      };
      break;
    }
    case geometryTypes.SYNCHRONOUS: {
      type = geometryTypes.SYNCHRONOUS;
      data = {
        astrum: geometry.astrum,
        force: Boolean(geometry.force),
        date,
        otherAstrum: ASTRUMS.SUN,
        argumentOfPerigee: geometry.argumentOfPerigee,
        ltan: geometry.ltan,
        eccentricity: geometry.eccentricity,
        inclination: geometry.inclination,
        semimajorAxis: convertParameter(geometry.semimajorAxis, multiplier),
        trueAnomaly: geometry.trueAnomaly,
      };
      break;
    }
    case geometryTypes.POLAR: {
      type = geometryTypes.POLAR;
      data = {
        astrum: geometry.astrum,
        date,
        force: Boolean(geometry.force),
        argumentOfPerigee: geometry.argumentOfPerigee,
        eccentricity: geometry.eccentricity,
        semimajorAxis: convertParameter(geometry.semimajorAxis, multiplier),
        trueAnomaly: geometry.trueAnomaly,
        raan: geometry.raan,
      };
      break;
    }
    case geometryTypes.LAUNCH_OPPORTUNITIES: {
      type = geometryTypesAPI.LAUNCH;
      //TODO constant
      data = null;
      break;
    }
    default: {
      type = 'Unknown type';
      data = null;
    }
  }

  return {
    id: geometry.name,
    type: type,
    data: data,
  };
};

export const getConstellation = (
  date: Date,
  geometry: IGeometryObjectState,
  astrum: string
): IAPIConstellation => {
  //TODO set astrum
  const astrumLocal = geometry.length ? geometry[0].astrum : astrum;
  return {
    astrum: astrumLocal,
    date,
    optimisation: false,
    geometry: geometry.map((g) => getGeometry(date, g)),
  };
};

const getEventPointData = (
  pointOfInterest: IPointOfInterest
): IAPIPointOfInterest => {
  return {
    astrum: pointOfInterest.astrum,
    elevationMask: {
      //TODO constant
      type: ELEVATION_MASK.CONSTANT,
      data: {
        value: pointOfInterest.elevationAngle,
      },
    },
    location: {
      altitude: pointOfInterest.altitude,
      latitude: pointOfInterest.lat,
      longitude: pointOfInterest.lon,
    },
  };
};

const getEventRegionData = (
  regionOfInterest: IRegionOfInterest
): IAPIRegionOfInterest => {
  return {
    astrum: regionOfInterest.astrum,
    elevationAngle: regionOfInterest.elevationAngle,
    polygons: regionOfInterest.paths.map((path) =>
      path.map((point) => [point.lat, point.lng ? point.lng : point.lon])
    ),
  };
};

export const getEvents = (
  date: Date,
  events: {
    pointsOfInterest?: IPIState;
    regionsOfInterest?: IRIState;
    groundStations?: IGSState;
  }
): IAPIEvent[] => {
  const { pointsOfInterest, regionsOfInterest, groundStations } = events;
  const joinedArray = [
    ...(pointsOfInterest
      ? pointsOfInterest.list.map((l) => {
          return { ...l, type: FocusTypes.PI_SUB_ID };
        })
      : []),
    ...(regionsOfInterest
      ? regionsOfInterest.list.map((l) => {
          return { ...l, type: FocusTypes.RI_SUB_ID };
        })
      : []),
    ...(groundStations
      ? groundStations.list
          .filter((gs) => gs.select || gs.optimize)
          .map((l) => {
            return { ...l, type: FocusTypes.GS_SUB_ID };
          })
      : []),
  ];
  // const sortedArray = joinedArray.sort((a, b) => a.orderIndex - b.orderIndex);

  const sortedArray = joinedArray;

  return sortedArray.map((s): IAPIEvent => {
    let data: IAPIEventData;
    switch (s.type) {
      case FocusTypes.PI_SUB_ID:
      case FocusTypes.GS_SUB_ID: {
        data = getEventPointData(s as IPointOfInterest);
        break;
      }
      case FocusTypes.RI_SUB_ID: {
        data = getEventRegionData(s as IRegionOfInterest);
        break;
      }
    }
    return {
      // id: s.id,
      id: s.name,
      type: s.type,
      data: data,
    };
  });
};

export const computeGeometry = (
  mission: IMission,
  days: number,
  geometry: IGeometryObjectState,
  pointsOfInterest: IPIState,
  regionsOfInterest: IRIState,
  astrum: ASTRUMS
): IAPICompute => {
  const date = mission.date;
  return {
    type: MESSAGE_TYPE.COMPUTE_GEOMETRY_REQUEST,
    data: {
      constellation: getConstellation(date, geometry, astrum),
      events: getEvents(date, { pointsOfInterest, regionsOfInterest }),
      span: days * 86400,
    },
  };
};

export const computeGroundSegment = (
  mission: IMission,
  days: number,
  geometry: IGeometryObjectState,
  groundStations: IGSState,
  astrum: ASTRUMS
): IAPICompute => {
  const date = mission.date;
  return {
    type: MESSAGE_TYPE.COMPUTE_GROUND_SEGMENT_REQUEST,
    data: {
      constellation: getConstellation(date, geometry, astrum),
      events: getEvents(date, { groundStations }),
      span: days * 86400,
    },
  };
};
