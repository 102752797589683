import React, { useRef, useState } from "react";
import type { AriaListBoxOptions } from "@react-aria/listbox";
import type { ListState } from "react-stately";
import { useListBox } from "react-aria";
import ListBoxSection from "./ListBoxSection";
import Option from "../Option/Option";
import Icon from "../../icons/Icon";

type ListBoxProps<T> = AriaListBoxOptions<T> & {
  listBoxRef?: React.RefObject<HTMLUListElement>;
  state: ListState<T>;
  filterable?: true;
  filterHandler?: (query: string, item: T) => boolean;
};

const ListBox = <T extends object>(props: ListBoxProps<T>) => {
  const ref = useRef<HTMLUListElement>(null);
  const { listBoxRef = ref, state } = props;
  const { listBoxProps } = useListBox(props, state, listBoxRef);

  const [filterBy, setFilterBy] = useState<string>("");

  return (
    <div>
      {props.filterable && (
        <div className="flex items-center border-2 border-data-light-text-field-stroke dark:border-none bg-data-light-item dark:bg-data-dark-item">
          <Icon icon="MagnifyingGlass" size={24} />
          <input
            type="text"
            placeholder="Filter..."
            className=" w-full text-sm m-0 p-1 bg-data-light-item dark:bg-data-dark-item"
            onChange={(e) => setFilterBy(e.target.value)}
            value={filterBy}
          />
        </div>
      )}
      <ul
        {...listBoxProps}
        ref={listBoxRef}
        className="max-h-72 overflow-auto outline-none bg-data-light-item dark:bg-data-dark-item"
      >
        {[...state.collection]
          .filter((item) => {
            if (!item.value) {
              return true;
            }

            return props.filterHandler
              ? props.filterHandler(filterBy, item.value)
              : true;
          })
          .map((item) =>
            item.type === "section" ? (
              <ListBoxSection<T> key={item.key} section={item} state={state} />
            ) : (
              <Option<T> key={item.key} item={item} state={state} />
            )
          )}
      </ul>
    </div>
  );
};

export default ListBox;
