import type { IDataZoom } from '../components/gridLayout/GridLayout';

export const isDateIntoDateZoom = (
  startDateStr: string,
  endDateStr: string,
  dataZoom: IDataZoom,
  currentDateStr: string
): boolean => {
  const startDate = new Date(startDateStr).getTime();
  const endDate = new Date(endDateStr).getTime();
  const currentDate = new Date(currentDateStr).getTime();
  const hundred = endDate - startDate;
  const dataZoomNumber = {
    start: startDate + hundred * (dataZoom.start / 100),
    end: startDate + hundred * (dataZoom.end / 100),
  };
  return (
    currentDate >= dataZoomNumber.start && currentDate <= dataZoomNumber.end
  );
};
