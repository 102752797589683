import { useState } from 'react';
import { Steps } from 'datacosmos/components/Tasking/helpers';
import type { OpportunityConstraintsProps } from 'datacosmos/components/Tasking/ObjectivesAndConstraints/Constraints';
import { OpportunityConstraints } from 'datacosmos/components/Tasking/ObjectivesAndConstraints/Constraints';
import type { ObjectivesProps } from 'datacosmos/components/Tasking/ObjectivesAndConstraints/Objectives';
import { Objectives } from 'datacosmos/components/Tasking/ObjectivesAndConstraints/Objectives';
import { useLocalisation } from 'utils/hooks/useLocalisation';

type ParametersProps = {
  constraintsProps: Omit<
    OpportunityConstraintsProps,
    | 'isOpen'
    | 'setIsOpen'
    | 'automated'
    | 'constraintsDescription'
    | 'getNoOfActiveConstraints'
    | 'disabled'
  >;
  objectivesProps: Omit<
    ObjectivesProps,
    'isOpen' | 'setIsOpen' | 'getNoOfActiveObjectives' | 'disabled'
  >;
  switchStep: Steps;
  disabled: boolean;
};

export const Parameters = ({
  objectivesProps,
  constraintsProps,
  switchStep,
  disabled,
}: ParametersProps) => {
  const [isObjectivesOpen, setIsObjectivesOpen] = useState<boolean>(false);
  const [isConstraintsOpen, setIsConstraintsOpen] = useState<boolean>(true);

  const { translate } = useLocalisation();

  const getNoOfActiveConstraints = () => {
    let ctr = 0;

    if (constraintsProps.isSZA) ctr++;
    if (constraintsProps.isRollAngle) ctr++;

    return ctr;
  };

  const getNoOfActiveObjectives = () => {
    let ctr = 0;

    if (objectivesProps.isOZAObjective) ctr++;
    if (objectivesProps.isSZAObjective) ctr++;

    return ctr;
  };

  return (
    <div className="flex items-center color-item overflow-x-clip overflow-y-visible">
      <span className="ml-2 mr-4 font-bold">
        {translate('datacosmos.tasking.new.parameters')}
      </span>
      <OpportunityConstraints
        {...constraintsProps}
        disabled={disabled}
        isOpen={isConstraintsOpen && !isObjectivesOpen}
        setIsOpen={(opn) => {
          setIsConstraintsOpen(!opn);
          setIsObjectivesOpen(false);
        }}
        constraintsDescription={
          switchStep === Steps.AutomatedTasking
            ? translate('datacosmos.tooltips.tasking.automated.constraints')
            : translate('datacosmos.tooltips.tasking.manual.constraints')
        }
        getNoOfActiveConstraints={getNoOfActiveConstraints}
      />

      {switchStep === Steps.AutomatedTasking && (
        <Objectives
          {...objectivesProps}
          disabled={disabled}
          isOpen={!isConstraintsOpen && isObjectivesOpen}
          setIsOpen={(opn) => {
            setIsObjectivesOpen(!opn);
            setIsConstraintsOpen(false);
          }}
          getNoOfActiveObjectives={getNoOfActiveObjectives}
        />
      )}
    </div>
  );
};
