import type { IMapWrapper } from '../../../declarations/mapDeclarations/Map';
import type { ISatelliteData } from '../../../constants/satelliteOrbits/actionTypes';
import type { IPolylineWrapper } from '../../../declarations/mapDeclarations/Polyline';

export const addSatellite = (
  satellitesData: ISatelliteData,
  map: IMapWrapper,
  options?: google.maps.PolylineOptions
): IPolylineWrapper => {
  const { id, name, path } = satellitesData;
  const satellite: IPolylineWrapper = new google.maps.Polyline({
    // @ts-ignore
    id,
    name,
    map,
    ...options,
  });
  satellite.setPath(path as google.maps.LatLngLiteral[]);
  if (map) {
    map.satellites.push(satellite);
    return satellite;
  }
  return undefined;
};

export const updateSatellite = (
  satelliteData: ISatelliteData,
  satellite: IPolylineWrapper,
  map: IMapWrapper,
  options?: google.maps.PolylineOptions
): IPolylineWrapper => {
  const { name, path } = satelliteData;
  satellite.name = name;
  satellite.setPath(path as google.maps.LatLngLiteral[]);
  if (options) {
    satellite.setOptions(options);
  }
  return satellite;
};

export const removeSatellite = (
  satelliteIndex: number,
  map: IMapWrapper
): void => {
  map.satellites[satelliteIndex].setMap(null);
  map.satellites.splice(satelliteIndex, 1);
};
