export interface IAnyKey {
  /* eslint-disable @typescript-eslint/no-explicit-any */
  [key: string]: any;
}

export default function getFormData(
  formReducer: IAnyKey,
  formName: string,
  fields: string[]
): IAnyKey {
  const form = formReducer[formName] as IAnyKey;
  if (!form) {
    return undefined;
  }
  const resObject = {} as unknown as IAnyKey;
  fields.forEach((f): void => {
    resObject[f] = form[f];
  });
  return resObject;
}
