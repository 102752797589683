import { createBrowserHistory } from 'history';
import * as Sentry from '@sentry/react';
import configureStore from './store';
import { getHost, getAudienceByDomain } from './utils/common/CommonUtils';
import {
  MODE,
  SENTRY_ENABLE,
  SENTRY_RELEASE,
  AUTH0_SCOPE,
  AUTH0_AUDIENCE,
  AUTH0_DOMAIN,
  AUTH0_CLIENT_ID,
  AUTH0_REDIRECT_URI,
  AUTHENTICATION_PROVIDER,
  KEYCLOAK_BASE_URL,
  KEYCLOAK_REDIRECT_URI,
  KEYCLOAK_REALM,
  KEYCLOAK_CLIENT_ID,
} from 'env';
import type { Options } from 'services/auth/AuthProvider';

// TODO: Make it true by default after all effect dependencies are fixed
export const ENABLE_STRICT_MODE: boolean = MODE === 'development' && false;

// import { setUseWhatChange } from '@simbathesailor/use-what-changed';
// setUseWhatChange(MODE === 'development');

// Usage:
// import { useWhatChanged } from '@simbathesailor/use-what-changed';
// useWhatChanged([a, b, b], 'a, b, c', 'suffix');

export const history = createBrowserHistory({
  basename: '/',
});

// Sentry must be disabled in certain situations such as localhost and gitlab CI due to CORS
if (SENTRY_ENABLE) {
  Sentry.init({
    dsn: 'https://b66bae64bd644772b40f0da41aca412e@o279621.ingest.sentry.io/1502579',
    tunnel: '/api/portal/v0/s-relay/',
    release: SENTRY_RELEASE,
    beforeSend(event) {
      const messages = event.exception?.values;

      const is403Error =
        messages && messages.length > 0
          ? messages[0].value === 'Request failed with status code 403'
          : false;

      if (!is403Error && event.exception) {
        Sentry.showReportDialog({ eventId: event.event_id });
      }
      return event;
    },
    tracesSampleRate: 0.005, // @TODO: Tune for performance (higher value, better performance)
  });
}

export const store = configureStore();

export const authenticationOptions = (): Options => {
  switch (AUTHENTICATION_PROVIDER) {
    case 'auth0':
      return {
        scope: AUTH0_SCOPE,
        audience: getAudienceByDomain(
          AUTH0_AUDIENCE ? AUTH0_AUDIENCE : getHost()
        ),
        domain: AUTH0_DOMAIN,
        clientId: AUTH0_CLIENT_ID,
        redirectUri: `${getHost()}${AUTH0_REDIRECT_URI}`,
      };
    case 'keycloak':
      return {
        url: KEYCLOAK_BASE_URL,
        redirectUri: KEYCLOAK_REDIRECT_URI,
        realm: KEYCLOAK_REALM,
        clientId: KEYCLOAK_CLIENT_ID,
      };
    default:
      throw Error(
        `Invalid authentication provider configured: ${AUTHENTICATION_PROVIDER}`
      );
  }
};
