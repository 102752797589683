import { filter, pipe, reject, reverse, sortBy } from 'lodash/fp';
import type { IFolderTreeItem } from '../../components/FileExplorer/FolderTree';

export const fileExplorerSorter =
  (field: string, fileType: string, orderBy: string) =>
  (arr: IFolderTreeItem[]) =>
    pipe(
      sortBy([field]),
      (items: IFolderTreeItem[]) =>
        orderBy === 'desc' ? reverse(items) : items,
      (items) => [
        ...filter(['type', fileType], items),
        ...reject(['type', fileType], items),
      ]
    )(arr);
