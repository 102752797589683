import type { ReactElement } from 'react';
import React from 'react';
import { connect } from 'react-redux';
import type { AppState } from 'store';
import type { IGroup } from 'constants/groupSatelliteBalls/actionTypes';
import { Redirect } from 'react-router';
import { MODE } from 'env';

export interface IStateProps {
  groups: IGroup[];
}

class RedirectToMainPage extends React.PureComponent<IStateProps> {
  public render(): ReactElement {
    const { groups } = this.props;
    if (groups.length || MODE !== 'production') {
      return null;
    }
    return <Redirect to={'/'} />;
  }
}

export default connect<IStateProps>(
  (state: AppState): IStateProps => ({
    groups: state.groups,
  }),
  () => ({})
)(RedirectToMainPage);
