import area from '@turf/area';
import isClockwise from '@turf/boolean-clockwise';
import { clientTranslate } from 'utils/hooks/useLocalisation';

const VALID_GEOJSON_TYPES = ['Feature', 'FeatureCollection'];
const VALID_GEOMETRY_TYPES = [
  'Point',
  'MultiPoint',
  'LineString',
  'Polygon',
  'MultiPolygon',
  'MultiLineString',
  'GeometryCollection',
];

const UNSUPPORTED_GEOMETRY_TYPES = [
  'Point',
  'MultiPoint',
  'GeometryCollection',
  'MultiPolygon',
  'LineString',
  'MultiLineString',
];

const MAXIMUM_GEOJSON_SIZE_M2 = 15000000 * 1000000;

const INVALID_GEOJSON_TYPE_ERROR_MESSAGE = clientTranslate(
  'datacosmos.uploadRegion.geoJsonValidator.invalidType',
  { allowed: VALID_GEOJSON_TYPES.join(', ') }
);

const INVALID_GEOMETRY_TYPE_ERROR_MESSAGE = clientTranslate(
  'datacosmos.uploadRegion.geoJsonValidator.invalidGeometryType',
  { allowed: VALID_GEOMETRY_TYPES.join(', ') }
);

const UNSUPPORTED_GEOMETRY_TYPES_ERROR_MESSAGE = clientTranslate(
  'datacosmos.uploadRegion.geoJsonValidator.typeUnsupported',
  { disallowed: UNSUPPORTED_GEOMETRY_TYPES.join(', ') }
);
const AREA_TOO_LARGE_ERROR_MESSAGE = clientTranslate(
  'datacosmos.uploadRegion.geoJsonValidator.tooLarge'
);

const HAS_HOLES_ERROR_MESSAGE = clientTranslate(
  'datacosmos.uploadRegion.geoJsonValidator.hasHoles'
);

const IS_CLOCKWISE_ERROR_MESSAGE = clientTranslate(
  'datacosmos.uploadRegion.geoJsonValidator.isClockwise'
);

export default (geoJson: GeoJSON.GeoJSON) => {
  const check = (geo: GeoJSON.GeoJSON = geoJson) => {
    if (geo.type === 'FeatureCollection') {
      for (const f of geo.features) {
        check(f);
      }
      return;
    }
    if (!VALID_GEOJSON_TYPES.includes(geo.type)) {
      throw new Error(INVALID_GEOJSON_TYPE_ERROR_MESSAGE);
    }

    if (
      !VALID_GEOMETRY_TYPES.includes(
        (geo as GeoJSON.GeoJSON & { geometry: GeoJSON.Geometry }).geometry?.type
      )
    ) {
      throw new Error(INVALID_GEOMETRY_TYPE_ERROR_MESSAGE);
    }

    if (
      UNSUPPORTED_GEOMETRY_TYPES.includes(
        (geo as GeoJSON.GeoJSON & { geometry: GeoJSON.Geometry }).geometry?.type
      )
    ) {
      throw new Error(UNSUPPORTED_GEOMETRY_TYPES_ERROR_MESSAGE);
    }

    if (area(geo as GeoJSON.Feature) > MAXIMUM_GEOJSON_SIZE_M2) {
      throw new Error(AREA_TOO_LARGE_ERROR_MESSAGE);
    }

    if (
      (
        (geo as GeoJSON.Feature).geometry as GeoJSON.Geometry & {
          coordinates: number[][][];
        }
      )?.coordinates?.length > 1
    ) {
      throw new Error(HAS_HOLES_ERROR_MESSAGE);
    }

    if (
      isClockwise(
        (
          (geo as GeoJSON.Feature).geometry as GeoJSON.Geometry & {
            coordinates: number[][][];
          }
        ).coordinates[0]
      )
    ) {
      throw new Error(IS_CLOCKWISE_ERROR_MESSAGE);
    }
  };

  return { check };
};
