import type { IFocus, focusTypes } from '../constants/focus/actionTypes';
import { SET_FOCUS, RESET_FOCUS } from '../constants/focus/actionTypes';

const initialState: IFocus = {
  type: null,
  id: null,
  needScroll: false,
};

export default function focusReducer(
  state: IFocus = initialState,
  action: focusTypes
): IFocus {
  switch (action.type) {
    case SET_FOCUS: {
      return { ...action.focus };
    }
    case RESET_FOCUS: {
      return { ...initialState };
    }
    default: {
      return state;
    }
  }
}
