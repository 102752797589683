import type { ReactElement } from 'react';
import React from 'react';
import type { IInitData, IStepData } from '../simulation/Simulation';
import type { ECharts } from 'echarts';
import echarts from 'echarts';
import type { WithTranslation } from 'react-i18next';
import { withTranslation } from 'react-i18next';
import { setDataZoom } from '../../utils/chart/setDataZoom';
import { updateChartData } from '../../utils/chart/updateChartData';
import { updateChartInitData } from '../../utils/chart/updateChartInitData';
import { Button, Checkbox } from '@blueprintjs/core';
import { getAxisData } from '../../utils/chart/getAxisData';
import { getLegendSelected } from '../../utils/chart/getLegendSelected';
import { onChangeSelectAll } from '../../utils/chart/onChangeSelectAll';
import {
  chartTypeHandler,
  debounceDelay,
} from '../../constants/chart/constants';
import { debounce } from '../../utils/common/debounce';
import type { IDataZoom, IInitChartData } from '../gridLayout/GridLayout';
import { downloadDataCSV } from '../../utils/chart/downloadDataCSV';
import { setBooleanAxisY } from '../../utils/chart/setBooleanAxisY';
import { setNumberAxisY } from '../../utils/chart/setNumberAxisY';

export type ISeriesData =
  | echarts.EChartOption.SeriesLine
  | echarts.EChartOption.SeriesScatter;

export interface IChartSeriesData {
  value: [
    string | number | Function,
    string | number | Function,
    (string | number)?
  ];
  symbol?: string;
}

export interface IAxis {
  type: string;
  name: string;
  index: number;
}

export interface ISelected {
  type: string;
  index: number;
}

interface IChartsProps extends WithTranslation {
  initData: IInitData;
  historySteps: IStepData[];
  divId: string;
  id: string;
  dataZoom: IDataZoom;
  onChangeDataZoom: Function;
  onResize: Function;
  onSyncAllStop: Function;
  onSelectAllStop: Function | undefined;
  onCopy: Function;
  onRemove: Function;
  initChartData: IInitChartData;
  numberOfLayout: number;
}

export interface IChartsState {
  chart: ECharts;
  satellitesData: ISeriesData[];
  synchronize: boolean;
  axisX: IAxis[];
  axisY: IAxis[];
  axisTypes: string[];
  selectedX: ISelected;
  selectedY: ISelected;
  chartOption: {
    type: string;
    smooth: boolean;
    idForSelect: string;
  };
}

class Chart extends React.PureComponent<IChartsProps, IChartsState> {
  public state: IChartsState = {
    chart: null as unknown as ECharts,
    satellitesData: null as unknown as ISeriesData[],
    synchronize: true,
    axisX: [],
    axisY: [],
    axisTypes: [],
    selectedX: { type: 'null', index: -1 },
    selectedY: { type: 'null', index: -1 },
    chartOption: {
      type: chartTypeHandler[0].type,
      smooth: chartTypeHandler[0].smooth,
      idForSelect: chartTypeHandler[0].idForSelect,
    },
  };

  public async componentDidMount(): Promise<void> {
    const div = document.getElementById(
      this.props.divId
    ) as HTMLDivElement | null;

    if (!div) {
      return;
    }
    const chart = echarts.init(div);
    await this.setState({ chart }, async (): Promise<void> => {
      await Promise.resolve()
        .then((): void => {
          if (this.props.initData) {
            const { chart } = this.state;
            const { initData, dataZoom } = this.props;
            updateChartInitData(
              chart,
              initData,
              dataZoom,
              this.setState.bind(this)
            );
          }
        })
        .then(async (): Promise<void> => {
          if (this.props.initChartData) {
            const {
              selected,
              selectedX,
              selectedY,
              chartOption,
              synchronize,
              dataZoom,
            } = this.props.initChartData;
            await this.setState(
              {
                chartOption: chartOption ? chartOption : this.state.chartOption,
                synchronize: synchronize ? synchronize : this.state.synchronize,
              },
              async (): Promise<void> => {
                if (selectedX) {
                  await this.onAxisXChange({
                    target: { value: selectedX.index.toString() },
                  });
                }
                if (selectedY) {
                  await this.onAxisYChange({
                    target: { value: selectedY.index.toString() },
                  });
                }
                if (dataZoom) {
                  await setDataZoom(chart, dataZoom);
                }
                if (selected) {
                  await chart.setOption({ legend: { selected } });
                }
                this.forceUpdate();
              }
            );
          }
        })
        .then(async (): Promise<void> => {
          if (this.props.historySteps) {
            const {
              chart,
              satellitesData,
              selectedX,
              selectedY,
              chartOption,
              axisY,
              axisX,
            } = this.state;
            const { historySteps, initData, numberOfLayout } = this.props;
            updateChartData(
              chart,
              satellitesData,
              historySteps,
              initData,
              selectedX,
              selectedY,
              chartOption,
              axisY,
              axisX,
              numberOfLayout
            );
          }
        });
    });
    chart.on('datazoom', (event: { batch?: IDataZoom[] } & IDataZoom): void => {
      const {
        chart,
        satellitesData,
        selectedX,
        selectedY,
        chartOption,
        axisY,
        axisX,
      } = this.state;
      const { historySteps, initData, numberOfLayout } = this.props;
      if (event.end === undefined) {
        event = event.batch[0];
      }
      this.debounceChangeDataZoom({ start: event.start, end: event.end });
      updateChartData(
        chart,
        satellitesData,
        historySteps,
        initData,
        selectedX,
        selectedY,
        chartOption,
        axisY,
        axisX,
        numberOfLayout
      );
    });
    chart.on('legendselectchanged', (): void => {
      const {
        chart,
        satellitesData,
        selectedX,
        selectedY,
        chartOption,
        axisY,
        axisX,
      } = this.state;
      const { historySteps, initData, numberOfLayout } = this.props;
      updateChartData(
        chart,
        satellitesData,
        historySteps,
        initData,
        selectedX,
        selectedY,
        chartOption,
        axisY,
        axisX,
        numberOfLayout
      );
    });
  }

  public componentDidUpdate(
    prevProps: Readonly<IChartsProps>,
    prevState: Readonly<IChartsState>
  ): void {
    if (this.props.initData !== prevProps.initData) {
      const { chart } = this.state;
      const { initData, dataZoom } = this.props;
      updateChartInitData(chart, initData, dataZoom, this.setState.bind(this));
    }
    if (
      this.props.historySteps !== prevProps.historySteps ||
      this.props.numberOfLayout !== prevProps.numberOfLayout
    ) {
      const {
        chart,
        satellitesData,
        selectedX,
        selectedY,
        chartOption,
        axisY,
        axisX,
      } = this.state;
      const { historySteps, initData, numberOfLayout } = this.props;
      updateChartData(
        chart,
        satellitesData,
        historySteps,
        initData,
        selectedX,
        selectedY,
        chartOption,
        axisY,
        axisX,
        numberOfLayout
      );
    }
    if (this.props.dataZoom !== prevProps.dataZoom) {
      const {
        chart,
        satellitesData,
        selectedX,
        selectedY,
        synchronize,
        chartOption,
        axisY,
        axisX,
      } = this.state;
      const { historySteps, initData, dataZoom, numberOfLayout } = this.props;
      if (synchronize) {
        setDataZoom(chart, dataZoom);
        updateChartData(
          chart,
          satellitesData,
          historySteps,
          initData,
          selectedX,
          selectedY,
          chartOption,
          axisY,
          axisX,
          numberOfLayout
        );
      }
    }

    if (this.state.synchronize !== prevState.synchronize) {
      const {
        chart,
        satellitesData,
        selectedX,
        selectedY,
        synchronize,
        chartOption,
        axisY,
        axisX,
      } = this.state;
      const { historySteps, initData, dataZoom, numberOfLayout } = this.props;
      if (synchronize) {
        chart.off('datazoom');
        chart.on(
          'datazoom',
          (event: { batch?: IDataZoom[] } & IDataZoom): void => {
            const {
              chart,
              satellitesData,
              selectedX,
              selectedY,
              chartOption,
              axisY,
              axisX,
            } = this.state;
            const { historySteps, initData, numberOfLayout } = this.props;
            if (event.end === undefined) {
              event = event.batch?.[0];
            }
            this.debounceChangeDataZoom({ start: event.start, end: event.end });
            updateChartData(
              chart,
              satellitesData,
              historySteps,
              initData,
              selectedX,
              selectedY,
              chartOption,
              axisY,
              axisX,
              numberOfLayout
            );
          }
        );
        setDataZoom(chart, dataZoom);
        updateChartData(
          chart,
          satellitesData,
          historySteps,
          initData,
          selectedX,
          selectedY,
          chartOption,
          axisY,
          axisX,
          numberOfLayout
        );
      } else {
        chart.off('datazoom');
        chart.on('datazoom', (): void => {
          const {
            chart,
            satellitesData,
            selectedX,
            selectedY,
            chartOption,
            axisY,
            axisX,
          } = this.state;
          const { historySteps, initData, numberOfLayout } = this.props;
          updateChartData(
            chart,
            satellitesData,
            historySteps,
            initData,
            selectedX,
            selectedY,
            chartOption,
            axisY,
            axisX,
            numberOfLayout
          );
        });
      }
    }
    if (this.props.onResize) {
      this.state.chart.resize();
      this.props.onResize();
    }
    if (this.props.onSyncAllStop) {
      this.setState({ synchronize: true });
      this.props.onSyncAllStop(this.props.id);
    }
    if (this.props.onSelectAllStop) {
      onChangeSelectAll(this.state.chart, true);
      this.props.onSelectAllStop(this.props.id);
      this.forceUpdate();
    }
  }

  protected onRemove = (): void => {
    this.props.onRemove(this.props.divId);
  };
  protected onCopy = (): void => {
    const { selectedX, selectedY, synchronize, chartOption } = this.state;

    const selected = this.state?.chart?.getOption?.()?.legend?.[0].selected as {
      [key: string]: boolean;
    };

    const options = this.state.chart.getOption().dataZoom?.[0];

    this.props.onCopy({
      selectedX,
      selectedY,
      chartOption,
      synchronize,
      selected,
      dataZoom: { start: options?.start, end: options?.end },
    });
  };
  protected onSwitchSynchronize = (): void => {
    const { synchronize } = this.state;
    this.setState({ synchronize: !synchronize });
  };
  protected onMasterize = (): void => {
    if (this.state.synchronize) {
      return undefined;
    }
    const { onChangeDataZoom } = this.props;
    const { chart } = this.state;
    const options = chart.getOption().dataZoom?.[0];

    if (!options) {
      return undefined;
    }
    const { start, end } = options;
    onChangeDataZoom({ start, end });
    this.setState({ synchronize: true });
  };
  protected onChangeSelectAll = (): void => {
    onChangeSelectAll(this.state.chart);
    this.forceUpdate();
  };
  protected handleSetFullZoom = (): void => {
    const { onChangeDataZoom } = this.props;
    const { chart, synchronize } = this.state;
    const start = 0;
    const end = 100;
    if (synchronize) {
      onChangeDataZoom({ start, end });
    } else {
      setDataZoom(chart, { start, end });
    }
  };
  protected onAxisXChange = (arg: { target: { value: string } }): void => {
    const index = arg.target.value;
    const { chart, satellitesData } = this.state;
    const { initData } = this.props;
    let newSelectedX: ISelected | null = null;
    let xAxis: echarts.EChartOption.XAxis[] | null = null;
    let newSatelliteData: ISeriesData[] | null = null;
    if (index === '-1') {
      newSatelliteData = satellitesData.map((s): ISeriesData => {
        return { ...s, xAxisIndex: 0 };
      });
      xAxis = [
        {
          id: 'time',
          type: 'time',
          position: 'bottom',
          min: initData.data.startDate,
          max: initData.data.endDate,
          show: true,
        },
        {
          id: 'value',
          type: 'value',
          show: false,
        },
      ];
      newSelectedX = { type: 'time', index: Number(index) };
    } else {
      newSatelliteData = satellitesData.map((s): ISeriesData => {
        return { ...s, xAxisIndex: 1 };
      });
      xAxis = [
        {
          id: 'time',
          show: false,
          position: 'top',
          type: 'time',
          min: initData.data.startDate,
          max: initData.data.endDate,
        },
        {
          id: 'value',
          type: 'value',
          show: true,
        },
      ];
      newSelectedX = {
        type: initData.data.parameterTypes[Number(index)],
        index: Number(index),
      };
    }
    chart.setOption({ xAxis });
    const newAxisY = getAxisData(initData, newSelectedX.type);
    this.setState(
      {
        selectedX: newSelectedX,
        axisY: newAxisY,
        satellitesData: newSatelliteData,
      },
      (): void => {
        const { selectedY } = this.state;
        let foundedY = newAxisY.find(
          (y): boolean => selectedY.index === y.index
        );
        if (!foundedY) {
          foundedY = newAxisY[0];
        }
        this.onAxisYChange({ target: { value: foundedY.index.toString() } });
      }
    );
  };
  protected onAxisYChange = (arg: { target: { value: string } }): void => {
    const index = arg.target.value;
    const { satellitesData, chart } = this.state;
    const { initData } = this.props;
    const newSelectedY: ISelected = {
      type: initData.data.parameterTypes[Number(index)],
      index: Number(index),
    };

    let newSatelliteData: ISeriesData[] | null = null;
    if (newSelectedY.type === 'boolean') {
      newSatelliteData = satellitesData.map((s): ISeriesData => {
        return { ...s, step: 'end' };
      });
      setBooleanAxisY(chart);
    } else {
      newSatelliteData = satellitesData.map((s): ISeriesData => {
        return { ...s, step: undefined };
      });
      setNumberAxisY(chart);
    }
    this.setState(
      { satellitesData: newSatelliteData, selectedY: newSelectedY },
      (): void => {
        const {
          chart,
          satellitesData,
          selectedX,
          selectedY,
          chartOption,
          axisY,
          axisX,
        } = this.state;
        const { historySteps, initData, numberOfLayout } = this.props;
        updateChartData(
          chart,
          satellitesData,
          historySteps,
          initData,
          selectedX,
          selectedY,
          chartOption,
          axisY,
          axisX,
          numberOfLayout
        );
      }
    );
  };
  protected onChartTypeChange = (arg: { target: { value: string } }): void => {
    const handler = chartTypeHandler.find(
      (t): boolean => t.idForSelect === arg.target.value
    );
    if (!handler) {
      console.error(new Error('Type not found'));
      return undefined;
    }
    const { type, smooth, idForSelect } = handler;

    this.setState(
      { chartOption: { type, smooth, idForSelect: idForSelect } },
      (): void => {
        const {
          chart,
          satellitesData,
          selectedX,
          selectedY,
          chartOption,
          axisY,
          axisX,
        } = this.state;
        const { historySteps, initData, numberOfLayout } = this.props;
        updateChartData(
          chart,
          satellitesData,
          historySteps,
          initData,
          selectedX,
          selectedY,
          chartOption,
          axisY,
          axisX,
          numberOfLayout
        );
      }
    );
  };
  protected handleDownloadAsCSV = (): void => {
    const { chart } = this.state;
    downloadDataCSV(chart);
  };
  protected debounceChangeDataZoom = debounce.call(
    this,
    this.props.onChangeDataZoom,
    debounceDelay
  );

  public render(): ReactElement {
    const { synchronize, selectedX, selectedY, chart, chartOption } =
      this.state;
    const isSelected = getLegendSelected(chart);
    const { divId, id } = this.props;
    return (
      <div className="chart">
        <div className="chart-header">
          <nav className="bp4-navbar bp4-dark">
            <div className="bp4-navbar-group bp4-align-left">
              <div className="bp4-navbar-heading">{id}</div>
              <Button
                className="bp4-minimal"
                icon={'document'}
                title={'Download as CSV'}
                onClick={this.handleDownloadAsCSV}
              />
              <span className="bp4-navbar-divider" />
              <button
                className="bp4-button bp4-minimal"
                onClick={this.onMasterize}
              >
                Masterize
              </button>
              <Button
                className="bp4-minimal"
                icon={'maximize'}
                title={'Set full zoom'}
                onClick={this.handleSetFullZoom}
              />
              <Checkbox
                className="control"
                checked={synchronize}
                label="Sync"
                onChange={this.onSwitchSynchronize}
              />
              <span className="bp4-navbar-divider" />
              <div className="bp4-select select">
                {/* eslint-disable-next-line */}
                <select value={selectedX.index} onChange={this.onAxisXChange}>
                  {this.state.axisX.map(
                    (a, index): ReactElement => (
                      <option key={index} value={a.index}>
                        {a.name}{' '}
                      </option>
                    )
                  )}
                </select>
              </div>
              <div className="bp4-select select">
                {/* eslint-disable-next-line */}
                <select value={selectedY.index} onChange={this.onAxisYChange}>
                  {this.state.axisY.map(
                    (a, index): ReactElement => (
                      <option key={index} value={a.index}>
                        {a.name}{' '}
                      </option>
                    )
                  )}
                </select>
              </div>
              <span className="bp4-navbar-divider" />
              <Checkbox
                className="control"
                label="Select all"
                {...isSelected}
                onChange={this.onChangeSelectAll}
              />
              <span className="bp4-navbar-divider" />
              <div className="bp4-select select">
                {/* eslint-disable-next-line */}
                <select
                  value={chartOption.idForSelect}
                  onChange={this.onChartTypeChange}
                >
                  {chartTypeHandler.map(
                    (c): ReactElement => (
                      <option key={c.idForSelect} value={c.idForSelect}>
                        {c.title}
                      </option>
                    )
                  )}
                </select>
              </div>
            </div>
            <div className="bp4-navbar-group bp4-align-right">
              <button
                className="bp4-button bp4-minimal bp4-icon-duplicate"
                title={'Copy'}
                onClick={this.onCopy}
              />
              <button
                className="bp4-button bp4-minimal bp4-icon-cross"
                title={'Remove'}
                onClick={this.onRemove}
              />
            </div>
          </nav>
        </div>
        <div className="chart-body" id={divId} />
      </div>
    );
  }
}

export default withTranslation()(Chart);
