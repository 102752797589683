import { PopoverPosition } from '@blueprintjs/core';
import { Tooltip } from 'ui/Tooltip';
import AdministrationRoleSelect from '../AdministrationRoleSelect';
import type {
  IRole,
  IUserWithPermissions,
} from 'services/api/portal/administration/api/types';

import { getUserPicture, getTextForNonStandardUser } from './utils';
import { useHistory } from 'react-router-dom';
import { ENABLE_USER_IMAGES } from 'env';
import Icon from '_atoms/Icon/Icon';

interface IProps {
  user?: IUserWithPermissions;
  assignRole: (userId: string, roleIds: number[]) => void;
  deleteRoleAssignments: (
    userId: string,
    assignmentIds: number[],
    showDeletedMessage?: boolean
  ) => void;
  availableRoles: IRole[];
}

const UserListItem = (props: IProps) => {
  const { user } = props;
  const history = useHistory();

  const deleteRoleAssignments = (userId: string, assignmentIds: number[]) => {
    props.deleteRoleAssignments(userId, assignmentIds, true);
  };

  const isFetching = false;
  const userPopover = user ? user.email : '';
  const userPicture = ENABLE_USER_IMAGES ? (
    getUserPicture(isFetching, user?.picture, user)
  ) : (
    <Icon icon="PERSON" />
  );

  const userName = user?.name
    ? user.name
    : getTextForNonStandardUser(isFetching, user?.email);

  return props.user ? (
    <li className="flex justify-between mb-2">
      <Tooltip position={PopoverPosition.LEFT} content={userPopover}>
        <div
          className="flex items-center min-w-[300px]"
          onClick={() =>
            history.push(
              `/portal/users/${encodeURI(
                user.id.replace(/\./g, ' ') as string
              )}`,
              user
            )
          }
          style={{ cursor: 'pointer' }}
        >
          {userPicture}
          <span className="text-sm ml-1">{userName}</span>
        </div>
      </Tooltip>
      <AdministrationRoleSelect
        userId={props.user.id}
        currentId={props.user.id}
        assignRole={props.assignRole}
        deleteRoleAssignments={deleteRoleAssignments}
        availableRoles={props.availableRoles}
        currentAssignments={props.user.assignments}
        disableRemoval
      />
    </li>
  ) : null;
};

export default UserListItem;
