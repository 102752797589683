import { apiFetchHandler } from 'api/clientFetch';
import { hosts } from 'api/hosts';
import type { MatomoDimensions } from 'utils/hooks/analytics/useAnalytics';

export type SendAnalyticsParams = {
  // action_name
  actionName: string;
  // url
  url: string;
  // _id
  visitorId: string;
  // resolution
  resolution: string;
  // h
  hour: number;
  // m
  minute: number;
  // s
  second: number;
  // uid
  userId: string;
  //dimension2
  userRole: string;
  // cvar
  userName: string;
  // e_c
  eventCategory: string;
  // e_a
  eventAction: string;
  // e_n
  eventName: string;
  //download
  download?: string;
  //dimension<id here>
  dimensions?: MatomoDimensions;
};

const getAnalyticsEndpoint = ({
  actionName,
  eventAction,
  eventCategory,
  eventName,
  hour,
  minute,
  resolution,
  second,
  url,
  userId,
  userRole,
  userName,
  visitorId,
  download,
  dimensions,
}: SendAnalyticsParams) => {
  const cvar = JSON.stringify({ '1': ['name', userName] });
  let analyticsEndpoint = `/t/?idsite=1&rec=1&action_name=${actionName}&url=${url}&_id=${visitorId}&res=${resolution}&h=${hour}&m=${minute}&s=${second}&uid=${userId}&e_c=${eventCategory}&e_a=${eventAction}&e_n=${eventName}&dimension2=${userRole}&cvar=${cvar}`;
  if (download) {
    analyticsEndpoint += `&download=${download}`;
  }
  if (dimensions) {
    let dimensionString = '';
    Object.entries(dimensions).forEach(([key, value]) => {
      const dimensionValue = value as string;
      if (dimensionValue !== undefined) {
        dimensionString += `&${key}=${dimensionValue}`;
      }
    });
    analyticsEndpoint += dimensionString;
  }
  return analyticsEndpoint;
};

export const sendAnalytics = apiFetchHandler<undefined, SendAnalyticsParams>({
  endpoint: (params) => getAnalyticsEndpoint(params),
  errorMessage: 'Could not send analytics',
  host: hosts.portal.v0,
  method: 'GET',
});
