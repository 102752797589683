import { Classes, Dialog, Icon, Spinner } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { sortBy } from 'lodash';
import classNames from 'classnames';
import s from './DatacosmosSelectDialog.module.scss';

export type DialogItem = {
  name: string;
  id: string;
};

export type DialogListRenderer<T> = (
  item: T,
  selectItem: () => void
) => JSX.Element | null;

export type DialogItemDetailsRenderer<T> = (
  item: T,
  onClickHandler: (item: T) => void
) => React.ReactNode | null;

interface DatacosmosSelectDialogProps<T> {
  items: T[];
  listRenderer: DialogListRenderer<T>;
  listItemClickHandler: (item: T) => void;
  isOpen: boolean;
  setIsOpen: (state: boolean) => void;
  handleAddItemClick?: () => void;
  addItemTitle?: string;
  title: string;
  isCloseButtonShown?: boolean;
  canOutsideClickClose?: boolean;
  canEscapeKeyClose?: boolean;
  handleItemDetailsRender: DialogItemDetailsRenderer<T>;
  selectedItem: T | undefined;
  setSelectedItem: (item: T | undefined) => void;
  sortListBy: keyof T;
  isContentLoading: boolean;
}

const DatacosmosSelectDialog = <T,>({
  title,
  items,
  listRenderer,
  listItemClickHandler,
  isOpen,
  setIsOpen,
  handleAddItemClick,
  handleItemDetailsRender,
  selectedItem,
  setSelectedItem,
  sortListBy,
  addItemTitle,
  isContentLoading,
  isCloseButtonShown = true,
  canOutsideClickClose = true,
  canEscapeKeyClose = true,
}: DatacosmosSelectDialogProps<T>) => {
  return (
    <Dialog
      isOpen={isOpen}
      onClose={() => {
        setIsOpen(false);
      }}
      className="dark:bg-surface-dark dark:text-item-dark-contrast bg-surface h-[95vh]"
      style={{
        width: selectedItem ? '70%' : '',
      }}
      canOutsideClickClose={canOutsideClickClose}
      canEscapeKeyClose={canEscapeKeyClose}
    >
      <div className="bp4-dialog-header dark:bg-surface-dark  bg-surface">
        <h5 className="dark:text-item-dark-contrast bp4-heading">{title}</h5>
        {isCloseButtonShown && (
          <button
            aria-label="Close"
            className="bp4-dialog-close-button bp4-button bp4-minimal bp4-icon-cross"
            onClick={() => setIsOpen(false)}
          />
        )}
      </div>

      <div
        className="grid w-full"
        style={{
          gridTemplateColumns: selectedItem ? '2fr 2fr' : '2fr',
        }}
      >
        <div
          className={[
            Classes.DIALOG_BODY,
            s.dialogContainer,
            'overflow-auto h-[85vh] scrollbar-light dark:scrollbar-dark',
          ].join(' ')}
          data-testid="datacosmos-select-modal"
        >
          <div
            className={s.dialogListWrapper}
            data-testid="modal-items-container"
          >
            {isContentLoading ? (
              <Spinner />
            ) : (
              sortBy(items, (item) =>
                (item[sortListBy] as string).toLowerCase()
              ).map((item) => (
                <div
                  className={classNames(
                    'hover:bg-item-selected hover:text-accent-900',
                    'dark:hover:bg-item-dark-selected dark:hover:text-item-dark-hover',
                    'cursor-pointer'
                  )}
                  data-testid="modal-item"
                  key={window.btoa(JSON.stringify(item))}
                >
                  {listRenderer(item, () => setSelectedItem(item))}
                </div>
              ))
            )}
          </div>
          <div className={[Classes.DIALOG_FOOTER, s.dialogFooter].join(' ')}>
            {handleAddItemClick && (
              <div
                className={s.dialogItemWrapper}
                style={{
                  display: 'flex',
                  gap: '5px',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
                onClick={handleAddItemClick}
                data-testid="modal-add-item-button"
              >
                {addItemTitle ? addItemTitle : 'Add item'}
                <Icon icon={IconNames.PLUS} />
              </div>
            )}
          </div>
        </div>
        {selectedItem && (
          <div>
            {handleItemDetailsRender(selectedItem, listItemClickHandler)}
          </div>
        )}
      </div>
    </Dialog>
  );
};

export default DatacosmosSelectDialog;
