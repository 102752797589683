import type { ThunkAction } from 'redux-thunk';
import type { AppState } from '../../../reducers/rootReducer';
import type { Action } from 'redux';
import { setFocus } from '../../../actions/focus/thunks';
import type { IRegionOfInterest } from '../../../constants/regionsOfInterest/actionTypes';
import { FocusTypes } from '../../../constants/focus/actionTypes';

export const setFocusRegionOfInterest =
  (
    regionOfInterest: IRegionOfInterest
  ): ThunkAction<void, AppState, null, Action<string>> =>
  async (dispatch): Promise<void> => {
    dispatch(
      setFocus({
        type: FocusTypes.RI_SUB_ID,
        id: regionOfInterest.id,
        needScroll: true,
      })
    );
  };
