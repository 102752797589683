import React from 'react';
import type { ReactElement } from 'react';

interface IHeaderButtonProps {
  text: string | ReactElement;
}

export const HeaderButton = (props: IHeaderButtonProps): ReactElement => {
  const { text } = props;
  return (
    <div className="header-button">
      <div className="header-button-label">{text}</div>
    </div>
  );
};
