import React from 'react';
import { Breadcrumbs } from '@blueprintjs/core';
import DefaultLayout from 'layouts/DefaultLayout';
import mainS from '../RTI/index.module.scss';
import { useMission } from 'services/Missions';
import {
  NodeREDProvider,
  useApiNodeREDService,
} from 'services/api/ops/node-red-operator/Provider';
import NavbarTimers from 'components/Timer/NavbarTimers';
import ScriptingContainer from './Node-red/ScriptingContainer';

const Scripting = () => {
  const { isLoading, isCreating, instanceExists, createNewInstance } =
    useApiNodeREDService();

  return (
    <DefaultLayout
      shouldHideClock
      showMissionSelector
      light
      leftHeader={
        <div className={mainS.headerContainer}>
          <Breadcrumbs items={[{ text: 'Scripting' }]} />
        </div>
      }
      header={<NavbarTimers showLinkButton />}
    >
      <ScriptingContainer
        isLoading={isLoading}
        isCreating={isCreating}
        instanceExists={instanceExists}
        createNewInstance={createNewInstance}
      />
    </DefaultLayout>
  );
};

export default () => {
  const { currentMissionId } = useMission();
  return (
    <NodeREDProvider missionId={currentMissionId}>
      <Scripting />
    </NodeREDProvider>
  );
};
