import type { ReactElement } from 'react';
import React from 'react';
import type { ECharts } from 'echarts';
import echarts from 'echarts';
import type { Point, Window } from 'api/groundStation/types';
import type { WithTranslation } from 'react-i18next';
import { withTranslation } from 'react-i18next';
import { DATETIME_FORMAT } from '../../../../constants/datetime';
import moment from 'moment';

type Format = echarts.EChartOption.Tooltip.Format;

interface IPolarPlotProps extends WithTranslation {
  points: Point[];
  window: Window;
}
export interface IChartsState {
  chart: ECharts;
}

class PolarPlot extends React.PureComponent<IPolarPlotProps> {
  public state: IChartsState = {
    chart: null,
  };

  public async componentDidMount(): Promise<void> {
    // @ts-expect-error
    const chart = echarts.init(document.getElementById('polar'));
    let data = [];
    data = this.props.points.map((item) => {
      const offset = moment(this.props.window.start)
        .add(item.timeOffset, 's')
        .utc()
        .format(DATETIME_FORMAT);
      return [item.elevation, item.azimuth, item.range, offset];
    });
    chart.setOption({
      polar: {
        center: ['50%', '50%'],
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'cross',
        },
        formatter: function (params: Format[]): string {
          const dimention = params[0].dimensionNames!;
          const value = params[0].value as string[];
          const units = ['Degree', 'Degree', 'Meter', 'UTC'];
          return dimention
            .map(
              (v: string, i: number) =>
                `${v}: ${
                  units[i] !== 'UTC'
                    ? parseFloat(value[i]).toFixed(2)
                    : value[i]
                }  ${units[i]}`
            )
            .join('<br/>');
        },
      },
      angleAxis: {
        type: 'value',
        startAngle: 90,
        min: 0,
        max: 360,
        axisLabel: {
          formatter: function (value: number) {
            let dir;
            if (value === 0) dir = 'N';
            else if (value === 90) dir = 'E';
            else if (value === 180) dir = 'S';
            else if (value === 270) dir = 'W';
            else dir = value;
            return dir;
          },
        },
      },
      radiusAxis: {
        min: 90,
        max: 0,
        inverse: true,
        minInterval: 30,
      },
      series: [
        {
          coordinateSystem: 'polar',
          name: 'line',
          type: 'line',
          dimensions: ['Elevation', 'Azimuth', 'Range', 'Time'],
          data: data,
          smooth: true,
        },
      ],
      animation: false,
    });
    this.setState({ chart });
  }

  public render(): ReactElement {
    return (
      <div className="polar-chart">
        <div className="container-chart">
          <div id="polar" />
        </div>
      </div>
    );
  }
}

export default withTranslation()(PolarPlot);
