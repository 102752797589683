import { isEmpty } from 'lodash/fp';
import { InputGroup } from '@blueprintjs/core';
import Fuse from 'fuse.js';
import s from './index.module.scss';
import type { AlertRule } from 'api/alerts/types';

interface IProps {
  alerts: AlertRule[];
  handleFilteredValue?: (alerts: AlertRule[]) => void;
}

const options: Fuse.FuseOptions<AlertRule> = {
  shouldSort: true,
  threshold: 0.3,
  keys: ['labels.system', 'id', 'alert'],
};

const AlertsFilter = (props: IProps) => {
  const handleFilterChange = (filterString: string) => {
    const fuse = new Fuse(props.alerts, options);
    const result = fuse.search(filterString);

    const filteredTelemetries =
      isEmpty(result) || !filterString.length ? null : result;
    props.handleFilteredValue?.(filteredTelemetries as AlertRule[]);
  };

  return (
    <InputGroup
      className={s.alertsFilter}
      leftIcon="filter"
      onChange={(e) => handleFilterChange(e.target.value)}
      placeholder="Filter alert rules..."
      small
    />
  );
};

export default AlertsFilter;
