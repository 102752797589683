import type { ReactElement } from 'react';
import { SubForm } from 'components/popUp/geometryForm/subForms/SubForm';
import { Field, formValueSelector } from 'redux-form';
import SelectField from 'components/inputField/SelectField';
import { connect } from 'react-redux';
import { GEOMETRY_FORM_NAME } from 'constants/popUp/constants';
import type { AppState } from 'store';
import {
  SEMIMAJOR_AXIS_AND_ECCENTRICITY,
  ECCENTRICITY_AND_INCLINATION,
  SEMIMAJOR_AXIS_AND_ECCENTRICITY_AND_INCLINATION,
} from 'components/popUp/geometryForm/subForms/constants';

interface IOwnProps {
  astrum: string;
  change?: Function;
  changeHandler: Function;
}

interface IMapStateToProps {
  parameterisation: string;
}

type ISynchronousOrbitProps = IOwnProps & IMapStateToProps;

const options = [
  {
    value: SEMIMAJOR_AXIS_AND_ECCENTRICITY,
    text: 'Semi-major Axis & Eccentricity',
  },
  { value: ECCENTRICITY_AND_INCLINATION, text: 'Eccentricity & Inclination' },
  {
    value: SEMIMAJOR_AXIS_AND_ECCENTRICITY_AND_INCLINATION,
    text: 'Semi-major Axis & Inclination',
  },
];

const controlNamesByOptions = {
  [SEMIMAJOR_AXIS_AND_ECCENTRICITY]: [
    `semimajorAxis`,
    'eccentricity',
    'ltan',
    'argumentOfPerigee',
    'trueAnomaly',
  ],
  [ECCENTRICITY_AND_INCLINATION]: [
    'eccentricity',
    'inclination',
    'ltan',
    'argumentOfPerigee',
    'trueAnomaly',
  ],
  [SEMIMAJOR_AXIS_AND_ECCENTRICITY_AND_INCLINATION]: [
    `semimajorAxis`,
    'inclination',
    'ltan',
    'argumentOfPerigee',
    'trueAnomaly',
  ],
};

const SynchronousOrbit = (props: ISynchronousOrbitProps): ReactElement => {
  const { change, astrum, changeHandler, parameterisation } = props;

  return (
    <>
      <Field
        name="parameterisation"
        title="geometry.parameterisation"
        onChangeState={(props: any) =>
          changeHandler('parameterisation', props.value)
        }
        options={options}
        component={SelectField}
      />
      <SubForm
        astrum={astrum}
        //@ts-ignore
        controlNames={controlNamesByOptions[parameterisation]}
        change={change}
      />
    </>
  );
};

const selector = formValueSelector(GEOMETRY_FORM_NAME);
const mapStateToProps = (state: AppState) => ({
  parameterisation: selector(state, 'parameterisation'),
});

export default connect<IMapStateToProps>(mapStateToProps)(SynchronousOrbit);
