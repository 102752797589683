import { useState, useRef, useEffect } from 'react';
import {
  ButtonGroup,
  Button,
  EditableText,
  Intent,
  Icon,
} from '@blueprintjs/core';
import { Tooltip } from 'ui/Tooltip';
import { IconNames } from '@blueprintjs/icons';
import type { ITerminalSession } from '../../services/api/ops/terminalSessions';
import {
  useApiCloseOpsTerminalSessions,
  useApiUpdateOpsTerminalSessions,
} from '../../services/api/ops/terminalSessions';
import SessionConnectionTooltip from './SessionConnectionTooltip';
import SessionSettingPopover from './SessionSettingPopover';
import s from './index.module.scss';

export interface IProps {
  session: ITerminalSession;
  handleSessionRename: (name: string) => void;
  handleReloadSessions: () => void;
  handleConnectToSession: (sessionId: number) => void;
  writeAllSessionsPermission: boolean;
  readAllSessionsPermission: boolean;
}

export function TerminalSessionItem({
  handleSessionRename,
  session,
  handleConnectToSession,
  handleReloadSessions,
  writeAllSessionsPermission,
  readAllSessionsPermission,
}: IProps) {
  const editableNameRef = useRef(null);
  const [editableSession, setEditableSession] = useState<boolean>(false);
  const closeSession = useApiCloseOpsTerminalSessions();
  const updateSession = useApiUpdateOpsTerminalSessions();

  useEffect(() => {
    if (editableSession) {
      editableNameRef.current.handleFocus();
    }
  }, [editableSession]);

  const handleDeleteSessionClick = async () => {
    await closeSession(session);
    handleReloadSessions();
  };

  const handleChangeUserAccessibleClick = async () => {
    const s = Object.assign({}, session, {
      userAccessible: !session.userAccessible,
    });
    await updateSession(s);
    handleReloadSessions();
  };

  return (
    <li className={s.itemContainer}>
      {session.open ? (
        <>
          <SessionConnectionTooltip sessionConnections={session.connections} />
          <span className={s.sessionId}>#{session.id}</span>
          <EditableText
            ref={editableNameRef}
            className={s.editableText}
            disabled={!editableSession}
            placeholder={editableSession ? 'Click to Edit' : 'No Name'}
            multiline={false}
            value={session.name}
            onChange={handleSessionRename}
            onConfirm={() => {
              updateSession(session);
              setEditableSession(false);
            }}
          />
          <div style={{ flex: 1 }} />
          {readAllSessionsPermission && (
            <span className={s.sessionId}>
              <Tooltip
                content={
                  session.userAccessible
                    ? 'This session is accessible by the customer'
                    : 'This session is not accessible by the customer'
                }
              >
                <Icon icon={session.userAccessible ? 'eye-open' : 'eye-off'} />
              </Tooltip>
            </span>
          )}
          <ButtonGroup className={s.inlineButtons}>
            <SessionSettingPopover
              userAccessible={session.userAccessible}
              handleRenameSessionClick={() => setEditableSession(true)}
              handleDeleteSessionClick={handleDeleteSessionClick}
              handleChangeUserAccessibleClick={handleChangeUserAccessibleClick}
              writeAllSessionsPermission={writeAllSessionsPermission}
            />
            <Button
              text="Connect"
              icon={IconNames.OFFLINE}
              intent={Intent.PRIMARY}
              onClick={() => handleConnectToSession(session.id)}
            />
          </ButtonGroup>
        </>
      ) : (
        <>
          <SessionConnectionTooltip sessionConnections={session.connections} />
          <span className={s.sessionId}>#{session.id}</span>
          <EditableText
            className={s.editableText}
            disabled
            placeholder={'No Name'}
            value={session.name}
          />
          <div style={{ flex: 1 }} />
          <span>(DELETED)</span>
        </>
      )}
    </li>
  );
}
