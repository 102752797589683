const colorPalette = {
  rplumbo: [
    '#000000FF',
    '#010101FF',
    '#010101FF',
    '#020202FF',
    '#030303FF',
    '#030303FF',
    '#040404FF',
    '#050505FF',
    '#050505FF',
    '#060606FF',
    '#070707FF',
    '#070707FF',
    '#080808FF',
    '#090909FF',
    '#090909FF',
    '#0A0A0AFF',
    '#0B0B0BFF',
    '#0B0B0BFF',
    '#0C0C0CFF',
    '#0D0D0DFF',
    '#0D0D0DFF',
    '#0E0E0EFF',
    '#0F0F0FFF',
    '#0F0F0FFF',
    '#101010FF',
    '#111111FF',
    '#111111FF',
    '#121212FF',
    '#131313FF',
    '#131313FF',
    '#141414FF',
    '#151515FF',
    '#151515FF',
    '#161616FF',
    '#171717FF',
    '#171717FF',
    '#181818FF',
    '#191919FF',
    '#191919FF',
    '#1A1A1AFF',
    '#1B1B1BFF',
    '#1B1B1BFF',
    '#1C1C1CFF',
    '#1D1D1DFF',
    '#1D1D1DFF',
    '#1E1E1EFF',
    '#1F1F1FFF',
    '#1F1F1FFF',
    '#202020FF',
    '#212121FF',
    '#212121FF',
    '#222222FF',
    '#232323FF',
    '#232323FF',
    '#242424FF',
    '#252525FF',
    '#252525FF',
    '#262626FF',
    '#272727FF',
    '#272727FF',
    '#282828FF',
    '#292929FF',
    '#292929FF',
    '#2A2A2AFF',
    '#2B2B2BFF',
    '#2B2B2BFF',
    '#2C2C2CFF',
    '#2D2D2DFF',
    '#2D2D2DFF',
    '#2E2E2EFF',
    '#2F2F2FFF',
    '#2F2F2FFF',
    '#303030FF',
    '#313131FF',
    '#313131FF',
    '#323232FF',
    '#333333FF',
    '#333333FF',
    '#343434FF',
    '#353535FF',
    '#353535FF',
    '#363636FF',
    '#373737FF',
    '#373737FF',
    '#383838FF',
    '#393939FF',
    '#393939FF',
    '#3A3A3AFF',
    '#3B3B3BFF',
    '#3B3B3BFF',
    '#3C3C3CFF',
    '#3D3D3DFF',
    '#3D3D3DFF',
    '#3E3E3EFF',
    '#3F3F3FFF',
    '#3F3F3FFF',
    '#404040FF',
    '#414141FF',
    '#414141FF',
    '#424242FF',
    '#434343FF',
    '#434343FF',
    '#444444FF',
    '#454545FF',
    '#454545FF',
    '#464646FF',
    '#474747FF',
    '#474747FF',
    '#484848FF',
    '#494949FF',
    '#494949FF',
    '#4A4A4AFF',
    '#4B4B4BFF',
    '#4B4B4BFF',
    '#4C4C4CFF',
    '#4D4D4DFF',
    '#4D4D4DFF',
    '#4E4E4EFF',
    '#4F4F4FFF',
    '#4F4F4FFF',
    '#505050FF',
    '#515151FF',
    '#515151FF',
    '#525252FF',
    '#535353FF',
    '#535353FF',
    '#545454FF',
    '#555555FF',
    '#555555FF',
    '#4A0100FF',
    '#540300FF',
    '#5E0500FF',
    '#690700FF',
    '#730A00FF',
    '#7D0C00FF',
    '#871000FF',
    '#911300FF',
    '#9C1700FF',
    '#A61C00FF',
    '#B02000FF',
    '#BA2500FF',
    '#C42B00FF',
    '#CF3000FF',
    '#D93600FF',
    '#E33D00FF',
    '#ED4300FF',
    '#F74A00FF',
    '#FF5100FF',
    '#FF5500FF',
    '#FF5900FF',
    '#FF5E00FF',
    '#FF6200FF',
    '#FF6600FF',
    '#FF6A00FF',
    '#FF6F00FF',
    '#FF7300FF',
    '#FF7700FF',
    '#FF7B00FF',
    '#FF8000FF',
    '#FF8400FF',
    '#FF8800FF',
    '#FF8C00FF',
    '#FF9100FF',
    '#FF9500FF',
    '#FF9900FF',
    '#FF9D00FF',
    '#FFA200FF',
    '#FFA600FF',
    '#FFAA00FF',
    '#FFAE00FF',
    '#FFB300FF',
    '#FFB700FF',
    '#FFBB00FF',
    '#FFBF00FF',
    '#FFC400FF',
    '#FFC800FF',
    '#FFCC00FF',
    '#FFD000FF',
    '#FFD500FF',
    '#FFD900FF',
    '#FFDD00FF',
    '#FFE100FF',
    '#FFE600FF',
    '#FFEA00FF',
    '#FFEE00FF',
    '#FFF200FF',
    '#FFF700FF',
    '#FFFB00FF',
    '#FFFF00FF',
    '#F8FC00FF',
    '#F1F900FF',
    '#EAF700FF',
    '#E4F400FF',
    '#DDF100FF',
    '#D6EE00FF',
    '#D0EB00FF',
    '#CAE900FF',
    '#C3E600FF',
    '#BDE300FF',
    '#B7E000FF',
    '#B1DD00FF',
    '#ABDB00FF',
    '#A5D800FF',
    '#A0D500FF',
    '#9AD200FF',
    '#95CF00FF',
    '#8FCD00FF',
    '#8ACA00FF',
    '#85C700FF',
    '#7FC400FF',
    '#7AC100FF',
    '#75BE00FF',
    '#71BC00FF',
    '#6CB900FF',
    '#67B600FF',
    '#63B300FF',
    '#5EB000FF',
    '#5AAE00FF',
    '#55AB00FF',
    '#51A800FF',
    '#4DA500FF',
    '#49A200FF',
    '#45A000FF',
    '#419D00FF',
    '#3E9A00FF',
    '#3A9700FF',
    '#369400FF',
    '#339200FF',
    '#308F00FF',
    '#2C8C00FF',
    '#298900FF',
    '#268600FF',
    '#238400FF',
    '#208100FF',
    '#1D7E00FF',
    '#1B7B00FF',
    '#187800FF',
    '#167600FF',
    '#137300FF',
    '#117000FF',
    '#0F6D00FF',
    '#0C6A00FF',
    '#0A6800FF',
    '#086500FF',
    '#076200FF',
    '#055F00FF',
    '#035C00FF',
    '#015A00FF',
    '#005700FF',
    '#005401FF',
    '#005103FF',
    '#004E04FF',
    '#004B05FF',
    '#004906FF',
    '#004607FF',
    '#004308FF',
  ],
  accent: [
    '#7FC97FFF',
    '#7FC97FFF',
    '#7FC97FFF',
    '#7FC97FFF',
    '#7FC97FFF',
    '#7FC97FFF',
    '#7FC97FFF',
    '#7FC97FFF',
    '#7FC97FFF',
    '#7FC97FFF',
    '#7FC97FFF',
    '#7FC97FFF',
    '#7FC97FFF',
    '#7FC97FFF',
    '#7FC97FFF',
    '#7FC97FFF',
    '#7FC97FFF',
    '#7FC97FFF',
    '#7FC97FFF',
    '#7FC97FFF',
    '#7FC97FFF',
    '#7FC97FFF',
    '#7FC97FFF',
    '#7FC97FFF',
    '#7FC97FFF',
    '#7FC97FFF',
    '#7FC97FFF',
    '#7FC97FFF',
    '#7FC97FFF',
    '#7FC97FFF',
    '#7FC97FFF',
    '#7FC97FFF',
    '#BEAED4FF',
    '#BEAED4FF',
    '#BEAED4FF',
    '#BEAED4FF',
    '#BEAED4FF',
    '#BEAED4FF',
    '#BEAED4FF',
    '#BEAED4FF',
    '#BEAED4FF',
    '#BEAED4FF',
    '#BEAED4FF',
    '#BEAED4FF',
    '#BEAED4FF',
    '#BEAED4FF',
    '#BEAED4FF',
    '#BEAED4FF',
    '#BEAED4FF',
    '#BEAED4FF',
    '#BEAED4FF',
    '#BEAED4FF',
    '#BEAED4FF',
    '#BEAED4FF',
    '#BEAED4FF',
    '#BEAED4FF',
    '#BEAED4FF',
    '#BEAED4FF',
    '#BEAED4FF',
    '#BEAED4FF',
    '#BEAED4FF',
    '#BEAED4FF',
    '#BEAED4FF',
    '#BEAED4FF',
    '#FDC086FF',
    '#FDC086FF',
    '#FDC086FF',
    '#FDC086FF',
    '#FDC086FF',
    '#FDC086FF',
    '#FDC086FF',
    '#FDC086FF',
    '#FDC086FF',
    '#FDC086FF',
    '#FDC086FF',
    '#FDC086FF',
    '#FDC086FF',
    '#FDC086FF',
    '#FDC086FF',
    '#FDC086FF',
    '#FDC086FF',
    '#FDC086FF',
    '#FDC086FF',
    '#FDC086FF',
    '#FDC086FF',
    '#FDC086FF',
    '#FDC086FF',
    '#FDC086FF',
    '#FDC086FF',
    '#FDC086FF',
    '#FDC086FF',
    '#FDC086FF',
    '#FDC086FF',
    '#FDC086FF',
    '#FDC086FF',
    '#FDC086FF',
    '#FFFF99FF',
    '#FFFF99FF',
    '#FFFF99FF',
    '#FFFF99FF',
    '#FFFF99FF',
    '#FFFF99FF',
    '#FFFF99FF',
    '#FFFF99FF',
    '#FFFF99FF',
    '#FFFF99FF',
    '#FFFF99FF',
    '#FFFF99FF',
    '#FFFF99FF',
    '#FFFF99FF',
    '#FFFF99FF',
    '#FFFF99FF',
    '#FFFF99FF',
    '#FFFF99FF',
    '#FFFF99FF',
    '#FFFF99FF',
    '#FFFF99FF',
    '#FFFF99FF',
    '#FFFF99FF',
    '#FFFF99FF',
    '#FFFF99FF',
    '#FFFF99FF',
    '#FFFF99FF',
    '#FFFF99FF',
    '#FFFF99FF',
    '#FFFF99FF',
    '#FFFF99FF',
    '#FFFF99FF',
    '#386CB0FF',
    '#386CB0FF',
    '#386CB0FF',
    '#386CB0FF',
    '#386CB0FF',
    '#386CB0FF',
    '#386CB0FF',
    '#386CB0FF',
    '#386CB0FF',
    '#386CB0FF',
    '#386CB0FF',
    '#386CB0FF',
    '#386CB0FF',
    '#386CB0FF',
    '#386CB0FF',
    '#386CB0FF',
    '#386CB0FF',
    '#386CB0FF',
    '#386CB0FF',
    '#386CB0FF',
    '#386CB0FF',
    '#386CB0FF',
    '#386CB0FF',
    '#386CB0FF',
    '#386CB0FF',
    '#386CB0FF',
    '#386CB0FF',
    '#386CB0FF',
    '#386CB0FF',
    '#386CB0FF',
    '#386CB0FF',
    '#386CB0FF',
    '#F0027FFF',
    '#F0027FFF',
    '#F0027FFF',
    '#F0027FFF',
    '#F0027FFF',
    '#F0027FFF',
    '#F0027FFF',
    '#F0027FFF',
    '#F0027FFF',
    '#F0027FFF',
    '#F0027FFF',
    '#F0027FFF',
    '#F0027FFF',
    '#F0027FFF',
    '#F0027FFF',
    '#F0027FFF',
    '#F0027FFF',
    '#F0027FFF',
    '#F0027FFF',
    '#F0027FFF',
    '#F0027FFF',
    '#F0027FFF',
    '#F0027FFF',
    '#F0027FFF',
    '#F0027FFF',
    '#F0027FFF',
    '#F0027FFF',
    '#F0027FFF',
    '#F0027FFF',
    '#F0027FFF',
    '#F0027FFF',
    '#F0027FFF',
    '#BF5B16FF',
    '#BF5B16FF',
    '#BF5B16FF',
    '#BF5B16FF',
    '#BF5B16FF',
    '#BF5B16FF',
    '#BF5B16FF',
    '#BF5B16FF',
    '#BF5B16FF',
    '#BF5B16FF',
    '#BF5B16FF',
    '#BF5B16FF',
    '#BF5B16FF',
    '#BF5B16FF',
    '#BF5B16FF',
    '#BF5B16FF',
    '#BF5B16FF',
    '#BF5B16FF',
    '#BF5B16FF',
    '#BF5B16FF',
    '#BF5B16FF',
    '#BF5B16FF',
    '#BF5B16FF',
    '#BF5B16FF',
    '#BF5B16FF',
    '#BF5B16FF',
    '#BF5B16FF',
    '#BF5B16FF',
    '#BF5B16FF',
    '#BF5B16FF',
    '#BF5B16FF',
    '#BF5B16FF',
    '#666666FF',
    '#666666FF',
    '#666666FF',
    '#666666FF',
    '#666666FF',
    '#666666FF',
    '#666666FF',
    '#666666FF',
    '#666666FF',
    '#666666FF',
    '#666666FF',
    '#666666FF',
    '#666666FF',
    '#666666FF',
    '#666666FF',
    '#666666FF',
    '#666666FF',
    '#666666FF',
    '#666666FF',
    '#666666FF',
    '#666666FF',
    '#666666FF',
    '#666666FF',
    '#666666FF',
    '#666666FF',
    '#666666FF',
    '#666666FF',
    '#666666FF',
    '#666666FF',
    '#666666FF',
    '#666666FF',
    '#666666FF',
  ],
  inferno: [
    '#000003FF',
    '#000004FF',
    '#000006FF',
    '#010007FF',
    '#010109FF',
    '#01010BFF',
    '#02010EFF',
    '#020210FF',
    '#030212FF',
    '#040314FF',
    '#040316FF',
    '#050418FF',
    '#06041BFF',
    '#07051DFF',
    '#08061FFF',
    '#090621FF',
    '#0A0723FF',
    '#0B0726FF',
    '#0D0828FF',
    '#0E082AFF',
    '#0F092DFF',
    '#10092FFF',
    '#120A32FF',
    '#130A34FF',
    '#140B36FF',
    '#160B39FF',
    '#170B3BFF',
    '#190B3EFF',
    '#1A0B40FF',
    '#1C0C43FF',
    '#1D0C45FF',
    '#1F0C47FF',
    '#200C4AFF',
    '#220B4CFF',
    '#240B4EFF',
    '#260B50FF',
    '#270B52FF',
    '#290B54FF',
    '#2B0A56FF',
    '#2D0A58FF',
    '#2E0A5AFF',
    '#300A5CFF',
    '#32095DFF',
    '#34095FFF',
    '#350960FF',
    '#370961FF',
    '#390962FF',
    '#3B0964FF',
    '#3C0965FF',
    '#3E0966FF',
    '#400966FF',
    '#410967FF',
    '#430A68FF',
    '#450A69FF',
    '#460A69FF',
    '#480B6AFF',
    '#4A0B6AFF',
    '#4B0C6BFF',
    '#4D0C6BFF',
    '#4F0D6CFF',
    '#500D6CFF',
    '#520E6CFF',
    '#530E6DFF',
    '#550F6DFF',
    '#570F6DFF',
    '#58106DFF',
    '#5A116DFF',
    '#5B116EFF',
    '#5D126EFF',
    '#5F126EFF',
    '#60136EFF',
    '#62146EFF',
    '#63146EFF',
    '#65156EFF',
    '#66156EFF',
    '#68166EFF',
    '#6A176EFF',
    '#6B176EFF',
    '#6D186EFF',
    '#6E186EFF',
    '#70196EFF',
    '#72196DFF',
    '#731A6DFF',
    '#751B6DFF',
    '#761B6DFF',
    '#781C6DFF',
    '#7A1C6DFF',
    '#7B1D6CFF',
    '#7D1D6CFF',
    '#7E1E6CFF',
    '#801F6BFF',
    '#811F6BFF',
    '#83206BFF',
    '#85206AFF',
    '#86216AFF',
    '#88216AFF',
    '#892269FF',
    '#8B2269FF',
    '#8D2369FF',
    '#8E2468FF',
    '#902468FF',
    '#912567FF',
    '#932567FF',
    '#952666FF',
    '#962666FF',
    '#982765FF',
    '#992864FF',
    '#9B2864FF',
    '#9C2963FF',
    '#9E2963FF',
    '#A02A62FF',
    '#A12B61FF',
    '#A32B61FF',
    '#A42C60FF',
    '#A62C5FFF',
    '#A72D5FFF',
    '#A92E5EFF',
    '#AB2E5DFF',
    '#AC2F5CFF',
    '#AE305BFF',
    '#AF315BFF',
    '#B1315AFF',
    '#B23259FF',
    '#B43358FF',
    '#B53357FF',
    '#B73456FF',
    '#B83556FF',
    '#BA3655FF',
    '#BB3754FF',
    '#BD3753FF',
    '#BE3852FF',
    '#BF3951FF',
    '#C13A50FF',
    '#C23B4FFF',
    '#C43C4EFF',
    '#C53D4DFF',
    '#C73E4CFF',
    '#C83E4BFF',
    '#C93F4AFF',
    '#CB4049FF',
    '#CC4148FF',
    '#CD4247FF',
    '#CF4446FF',
    '#D04544FF',
    '#D14643FF',
    '#D24742FF',
    '#D44841FF',
    '#D54940FF',
    '#D64A3FFF',
    '#D74B3EFF',
    '#D94D3DFF',
    '#DA4E3BFF',
    '#DB4F3AFF',
    '#DC5039FF',
    '#DD5238FF',
    '#DE5337FF',
    '#DF5436FF',
    '#E05634FF',
    '#E25733FF',
    '#E35832FF',
    '#E45A31FF',
    '#E55B30FF',
    '#E65C2EFF',
    '#E65E2DFF',
    '#E75F2CFF',
    '#E8612BFF',
    '#E9622AFF',
    '#EA6428FF',
    '#EB6527FF',
    '#EC6726FF',
    '#ED6825FF',
    '#ED6A23FF',
    '#EE6C22FF',
    '#EF6D21FF',
    '#F06F1FFF',
    '#F0701EFF',
    '#F1721DFF',
    '#F2741CFF',
    '#F2751AFF',
    '#F37719FF',
    '#F37918FF',
    '#F47A16FF',
    '#F57C15FF',
    '#F57E14FF',
    '#F68012FF',
    '#F68111FF',
    '#F78310FF',
    '#F7850EFF',
    '#F8870DFF',
    '#F8880CFF',
    '#F88A0BFF',
    '#F98C09FF',
    '#F98E08FF',
    '#F99008FF',
    '#FA9107FF',
    '#FA9306FF',
    '#FA9506FF',
    '#FA9706FF',
    '#FB9906FF',
    '#FB9B06FF',
    '#FB9D06FF',
    '#FB9E07FF',
    '#FBA007FF',
    '#FBA208FF',
    '#FBA40AFF',
    '#FBA60BFF',
    '#FBA80DFF',
    '#FBAA0EFF',
    '#FBAC10FF',
    '#FBAE12FF',
    '#FBB014FF',
    '#FBB116FF',
    '#FBB318FF',
    '#FBB51AFF',
    '#FBB71CFF',
    '#FBB91EFF',
    '#FABB21FF',
    '#FABD23FF',
    '#FABF25FF',
    '#FAC128FF',
    '#F9C32AFF',
    '#F9C52CFF',
    '#F9C72FFF',
    '#F8C931FF',
    '#F8CB34FF',
    '#F8CD37FF',
    '#F7CF3AFF',
    '#F7D13CFF',
    '#F6D33FFF',
    '#F6D542FF',
    '#F5D745FF',
    '#F5D948FF',
    '#F4DB4BFF',
    '#F4DC4FFF',
    '#F3DE52FF',
    '#F3E056FF',
    '#F3E259FF',
    '#F2E45DFF',
    '#F2E660FF',
    '#F1E864FF',
    '#F1E968FF',
    '#F1EB6CFF',
    '#F1ED70FF',
    '#F1EE74FF',
    '#F1F079FF',
    '#F1F27DFF',
    '#F2F381FF',
    '#F2F485FF',
    '#F3F689FF',
    '#F4F78DFF',
    '#F5F891FF',
    '#F6FA95FF',
    '#F7FB99FF',
    '#F9FC9DFF',
    '#FAFDA0FF',
    '#FCFEA4FF',
  ],
  tab10: [
    '#1F77B4FF',
    '#1F77B4FF',
    '#1F77B4FF',
    '#1F77B4FF',
    '#1F77B4FF',
    '#1F77B4FF',
    '#1F77B4FF',
    '#1F77B4FF',
    '#1F77B4FF',
    '#1F77B4FF',
    '#1F77B4FF',
    '#1F77B4FF',
    '#1F77B4FF',
    '#1F77B4FF',
    '#1F77B4FF',
    '#1F77B4FF',
    '#1F77B4FF',
    '#1F77B4FF',
    '#1F77B4FF',
    '#1F77B4FF',
    '#1F77B4FF',
    '#1F77B4FF',
    '#1F77B4FF',
    '#1F77B4FF',
    '#1F77B4FF',
    '#1F77B4FF',
    '#FF7F0EFF',
    '#FF7F0EFF',
    '#FF7F0EFF',
    '#FF7F0EFF',
    '#FF7F0EFF',
    '#FF7F0EFF',
    '#FF7F0EFF',
    '#FF7F0EFF',
    '#FF7F0EFF',
    '#FF7F0EFF',
    '#FF7F0EFF',
    '#FF7F0EFF',
    '#FF7F0EFF',
    '#FF7F0EFF',
    '#FF7F0EFF',
    '#FF7F0EFF',
    '#FF7F0EFF',
    '#FF7F0EFF',
    '#FF7F0EFF',
    '#FF7F0EFF',
    '#FF7F0EFF',
    '#FF7F0EFF',
    '#FF7F0EFF',
    '#FF7F0EFF',
    '#FF7F0EFF',
    '#2CA02CFF',
    '#2CA02CFF',
    '#2CA02CFF',
    '#2CA02CFF',
    '#2CA02CFF',
    '#2CA02CFF',
    '#2CA02CFF',
    '#2CA02CFF',
    '#2CA02CFF',
    '#2CA02CFF',
    '#2CA02CFF',
    '#2CA02CFF',
    '#2CA02CFF',
    '#2CA02CFF',
    '#2CA02CFF',
    '#2CA02CFF',
    '#2CA02CFF',
    '#2CA02CFF',
    '#2CA02CFF',
    '#2CA02CFF',
    '#2CA02CFF',
    '#2CA02CFF',
    '#2CA02CFF',
    '#2CA02CFF',
    '#2CA02CFF',
    '#2CA02CFF',
    '#D62728FF',
    '#D62728FF',
    '#D62728FF',
    '#D62728FF',
    '#D62728FF',
    '#D62728FF',
    '#D62728FF',
    '#D62728FF',
    '#D62728FF',
    '#D62728FF',
    '#D62728FF',
    '#D62728FF',
    '#D62728FF',
    '#D62728FF',
    '#D62728FF',
    '#D62728FF',
    '#D62728FF',
    '#D62728FF',
    '#D62728FF',
    '#D62728FF',
    '#D62728FF',
    '#D62728FF',
    '#D62728FF',
    '#D62728FF',
    '#D62728FF',
    '#9467BDFF',
    '#9467BDFF',
    '#9467BDFF',
    '#9467BDFF',
    '#9467BDFF',
    '#9467BDFF',
    '#9467BDFF',
    '#9467BDFF',
    '#9467BDFF',
    '#9467BDFF',
    '#9467BDFF',
    '#9467BDFF',
    '#9467BDFF',
    '#9467BDFF',
    '#9467BDFF',
    '#9467BDFF',
    '#9467BDFF',
    '#9467BDFF',
    '#9467BDFF',
    '#9467BDFF',
    '#9467BDFF',
    '#9467BDFF',
    '#9467BDFF',
    '#9467BDFF',
    '#9467BDFF',
    '#9467BDFF',
    '#8C564BFF',
    '#8C564BFF',
    '#8C564BFF',
    '#8C564BFF',
    '#8C564BFF',
    '#8C564BFF',
    '#8C564BFF',
    '#8C564BFF',
    '#8C564BFF',
    '#8C564BFF',
    '#8C564BFF',
    '#8C564BFF',
    '#8C564BFF',
    '#8C564BFF',
    '#8C564BFF',
    '#8C564BFF',
    '#8C564BFF',
    '#8C564BFF',
    '#8C564BFF',
    '#8C564BFF',
    '#8C564BFF',
    '#8C564BFF',
    '#8C564BFF',
    '#8C564BFF',
    '#8C564BFF',
    '#E377C2FF',
    '#E377C2FF',
    '#E377C2FF',
    '#E377C2FF',
    '#E377C2FF',
    '#E377C2FF',
    '#E377C2FF',
    '#E377C2FF',
    '#E377C2FF',
    '#E377C2FF',
    '#E377C2FF',
    '#E377C2FF',
    '#E377C2FF',
    '#E377C2FF',
    '#E377C2FF',
    '#E377C2FF',
    '#E377C2FF',
    '#E377C2FF',
    '#E377C2FF',
    '#E377C2FF',
    '#E377C2FF',
    '#E377C2FF',
    '#E377C2FF',
    '#E377C2FF',
    '#E377C2FF',
    '#E377C2FF',
    '#7F7F7FFF',
    '#7F7F7FFF',
    '#7F7F7FFF',
    '#7F7F7FFF',
    '#7F7F7FFF',
    '#7F7F7FFF',
    '#7F7F7FFF',
    '#7F7F7FFF',
    '#7F7F7FFF',
    '#7F7F7FFF',
    '#7F7F7FFF',
    '#7F7F7FFF',
    '#7F7F7FFF',
    '#7F7F7FFF',
    '#7F7F7FFF',
    '#7F7F7FFF',
    '#7F7F7FFF',
    '#7F7F7FFF',
    '#7F7F7FFF',
    '#7F7F7FFF',
    '#7F7F7FFF',
    '#7F7F7FFF',
    '#7F7F7FFF',
    '#7F7F7FFF',
    '#7F7F7FFF',
    '#BCBD22FF',
    '#BCBD22FF',
    '#BCBD22FF',
    '#BCBD22FF',
    '#BCBD22FF',
    '#BCBD22FF',
    '#BCBD22FF',
    '#BCBD22FF',
    '#BCBD22FF',
    '#BCBD22FF',
    '#BCBD22FF',
    '#BCBD22FF',
    '#BCBD22FF',
    '#BCBD22FF',
    '#BCBD22FF',
    '#BCBD22FF',
    '#BCBD22FF',
    '#BCBD22FF',
    '#BCBD22FF',
    '#BCBD22FF',
    '#BCBD22FF',
    '#BCBD22FF',
    '#BCBD22FF',
    '#BCBD22FF',
    '#BCBD22FF',
    '#BCBD22FF',
    '#17BECFFF',
    '#17BECFFF',
    '#17BECFFF',
    '#17BECFFF',
    '#17BECFFF',
    '#17BECFFF',
    '#17BECFFF',
    '#17BECFFF',
    '#17BECFFF',
    '#17BECFFF',
    '#17BECFFF',
    '#17BECFFF',
    '#17BECFFF',
    '#17BECFFF',
    '#17BECFFF',
    '#17BECFFF',
    '#17BECFFF',
    '#17BECFFF',
    '#17BECFFF',
    '#17BECFFF',
    '#17BECFFF',
    '#17BECFFF',
    '#17BECFFF',
    '#17BECFFF',
    '#17BECFFF',
    '#17BECFFF',
  ],
  tab20: [
    '#1F77B4FF',
    '#1F77B4FF',
    '#1F77B4FF',
    '#1F77B4FF',
    '#1F77B4FF',
    '#1F77B4FF',
    '#1F77B4FF',
    '#1F77B4FF',
    '#1F77B4FF',
    '#1F77B4FF',
    '#1F77B4FF',
    '#1F77B4FF',
    '#1F77B4FF',
    '#AEC7E8FF',
    '#AEC7E8FF',
    '#AEC7E8FF',
    '#AEC7E8FF',
    '#AEC7E8FF',
    '#AEC7E8FF',
    '#AEC7E8FF',
    '#AEC7E8FF',
    '#AEC7E8FF',
    '#AEC7E8FF',
    '#AEC7E8FF',
    '#AEC7E8FF',
    '#AEC7E8FF',
    '#FF7F0EFF',
    '#FF7F0EFF',
    '#FF7F0EFF',
    '#FF7F0EFF',
    '#FF7F0EFF',
    '#FF7F0EFF',
    '#FF7F0EFF',
    '#FF7F0EFF',
    '#FF7F0EFF',
    '#FF7F0EFF',
    '#FF7F0EFF',
    '#FF7F0EFF',
    '#FF7F0EFF',
    '#FFBB78FF',
    '#FFBB78FF',
    '#FFBB78FF',
    '#FFBB78FF',
    '#FFBB78FF',
    '#FFBB78FF',
    '#FFBB78FF',
    '#FFBB78FF',
    '#FFBB78FF',
    '#FFBB78FF',
    '#FFBB78FF',
    '#FFBB78FF',
    '#2CA02CFF',
    '#2CA02CFF',
    '#2CA02CFF',
    '#2CA02CFF',
    '#2CA02CFF',
    '#2CA02CFF',
    '#2CA02CFF',
    '#2CA02CFF',
    '#2CA02CFF',
    '#2CA02CFF',
    '#2CA02CFF',
    '#2CA02CFF',
    '#2CA02CFF',
    '#98DF8AFF',
    '#98DF8AFF',
    '#98DF8AFF',
    '#98DF8AFF',
    '#98DF8AFF',
    '#98DF8AFF',
    '#98DF8AFF',
    '#98DF8AFF',
    '#98DF8AFF',
    '#98DF8AFF',
    '#98DF8AFF',
    '#98DF8AFF',
    '#98DF8AFF',
    '#D62728FF',
    '#D62728FF',
    '#D62728FF',
    '#D62728FF',
    '#D62728FF',
    '#D62728FF',
    '#D62728FF',
    '#D62728FF',
    '#D62728FF',
    '#D62728FF',
    '#D62728FF',
    '#D62728FF',
    '#D62728FF',
    '#FF9896FF',
    '#FF9896FF',
    '#FF9896FF',
    '#FF9896FF',
    '#FF9896FF',
    '#FF9896FF',
    '#FF9896FF',
    '#FF9896FF',
    '#FF9896FF',
    '#FF9896FF',
    '#FF9896FF',
    '#FF9896FF',
    '#9467BDFF',
    '#9467BDFF',
    '#9467BDFF',
    '#9467BDFF',
    '#9467BDFF',
    '#9467BDFF',
    '#9467BDFF',
    '#9467BDFF',
    '#9467BDFF',
    '#9467BDFF',
    '#9467BDFF',
    '#9467BDFF',
    '#9467BDFF',
    '#C5B0D5FF',
    '#C5B0D5FF',
    '#C5B0D5FF',
    '#C5B0D5FF',
    '#C5B0D5FF',
    '#C5B0D5FF',
    '#C5B0D5FF',
    '#C5B0D5FF',
    '#C5B0D5FF',
    '#C5B0D5FF',
    '#C5B0D5FF',
    '#C5B0D5FF',
    '#C5B0D5FF',
    '#8C564BFF',
    '#8C564BFF',
    '#8C564BFF',
    '#8C564BFF',
    '#8C564BFF',
    '#8C564BFF',
    '#8C564BFF',
    '#8C564BFF',
    '#8C564BFF',
    '#8C564BFF',
    '#8C564BFF',
    '#8C564BFF',
    '#8C564BFF',
    '#C49C94FF',
    '#C49C94FF',
    '#C49C94FF',
    '#C49C94FF',
    '#C49C94FF',
    '#C49C94FF',
    '#C49C94FF',
    '#C49C94FF',
    '#C49C94FF',
    '#C49C94FF',
    '#C49C94FF',
    '#C49C94FF',
    '#E377C2FF',
    '#E377C2FF',
    '#E377C2FF',
    '#E377C2FF',
    '#E377C2FF',
    '#E377C2FF',
    '#E377C2FF',
    '#E377C2FF',
    '#E377C2FF',
    '#E377C2FF',
    '#E377C2FF',
    '#E377C2FF',
    '#E377C2FF',
    '#F7B6D2FF',
    '#F7B6D2FF',
    '#F7B6D2FF',
    '#F7B6D2FF',
    '#F7B6D2FF',
    '#F7B6D2FF',
    '#F7B6D2FF',
    '#F7B6D2FF',
    '#F7B6D2FF',
    '#F7B6D2FF',
    '#F7B6D2FF',
    '#F7B6D2FF',
    '#F7B6D2FF',
    '#7F7F7FFF',
    '#7F7F7FFF',
    '#7F7F7FFF',
    '#7F7F7FFF',
    '#7F7F7FFF',
    '#7F7F7FFF',
    '#7F7F7FFF',
    '#7F7F7FFF',
    '#7F7F7FFF',
    '#7F7F7FFF',
    '#7F7F7FFF',
    '#7F7F7FFF',
    '#7F7F7FFF',
    '#C7C7C7FF',
    '#C7C7C7FF',
    '#C7C7C7FF',
    '#C7C7C7FF',
    '#C7C7C7FF',
    '#C7C7C7FF',
    '#C7C7C7FF',
    '#C7C7C7FF',
    '#C7C7C7FF',
    '#C7C7C7FF',
    '#C7C7C7FF',
    '#C7C7C7FF',
    '#BCBD22FF',
    '#BCBD22FF',
    '#BCBD22FF',
    '#BCBD22FF',
    '#BCBD22FF',
    '#BCBD22FF',
    '#BCBD22FF',
    '#BCBD22FF',
    '#BCBD22FF',
    '#BCBD22FF',
    '#BCBD22FF',
    '#BCBD22FF',
    '#BCBD22FF',
    '#DBDB8DFF',
    '#DBDB8DFF',
    '#DBDB8DFF',
    '#DBDB8DFF',
    '#DBDB8DFF',
    '#DBDB8DFF',
    '#DBDB8DFF',
    '#DBDB8DFF',
    '#DBDB8DFF',
    '#DBDB8DFF',
    '#DBDB8DFF',
    '#DBDB8DFF',
    '#DBDB8DFF',
    '#17BECFFF',
    '#17BECFFF',
    '#17BECFFF',
    '#17BECFFF',
    '#17BECFFF',
    '#17BECFFF',
    '#17BECFFF',
    '#17BECFFF',
    '#17BECFFF',
    '#17BECFFF',
    '#17BECFFF',
    '#17BECFFF',
    '#17BECFFF',
    '#9EDAE5FF',
    '#9EDAE5FF',
    '#9EDAE5FF',
    '#9EDAE5FF',
    '#9EDAE5FF',
    '#9EDAE5FF',
    '#9EDAE5FF',
    '#9EDAE5FF',
    '#9EDAE5FF',
    '#9EDAE5FF',
    '#9EDAE5FF',
    '#9EDAE5FF',
    '#9EDAE5FF',
  ],
  ocean: [
    '#007F00FF',
    '#007E01FF',
    '#007C02FF',
    '#007B03FF',
    '#007904FF',
    '#007805FF',
    '#007606FF',
    '#007507FF',
    '#007308FF',
    '#007209FF',
    '#00700AFF',
    '#006F0BFF',
    '#006D0CFF',
    '#006C0DFF',
    '#006A0EFF',
    '#00690FFF',
    '#006710FF',
    '#006611FF',
    '#006412FF',
    '#006313FF',
    '#006114FF',
    '#006015FF',
    '#005E16FF',
    '#005D17FF',
    '#005B18FF',
    '#005919FF',
    '#00581AFF',
    '#00571BFF',
    '#00551CFF',
    '#00541DFF',
    '#00521EFF',
    '#00511FFF',
    '#004F20FF',
    '#004E20FF',
    '#004C22FF',
    '#004A23FF',
    '#004924FF',
    '#004824FF',
    '#004626FF',
    '#004527FF',
    '#004328FF',
    '#004228FF',
    '#00402AFF',
    '#003F2BFF',
    '#003D2CFF',
    '#003C2CFF',
    '#003A2EFF',
    '#00382FFF',
    '#003730FF',
    '#003630FF',
    '#003432FF',
    '#003233FF',
    '#003134FF',
    '#003034FF',
    '#002E36FF',
    '#002C37FF',
    '#002B38FF',
    '#002A38FF',
    '#00283AFF',
    '#00273BFF',
    '#00253CFF',
    '#00243CFF',
    '#00223EFF',
    '#00203FFF',
    '#001F40FF',
    '#001E41FF',
    '#001C41FF',
    '#001A43FF',
    '#001944FF',
    '#001845FF',
    '#001646FF',
    '#001447FF',
    '#001348FF',
    '#001249FF',
    '#001049FF',
    '#000F4BFF',
    '#000D4CFF',
    '#000C4DFF',
    '#000A4EFF',
    '#00084FFF',
    '#000750FF',
    '#000651FF',
    '#000451FF',
    '#000253FF',
    '#000154FF',
    '#000055FF',
    '#000156FF',
    '#000257FF',
    '#000458FF',
    '#000559FF',
    '#000759FF',
    '#00085BFF',
    '#000A5CFF',
    '#000B5DFF',
    '#000D5EFF',
    '#000F5FFF',
    '#001060FF',
    '#001161FF',
    '#001361FF',
    '#001563FF',
    '#001664FF',
    '#001865FF',
    '#001966FF',
    '#001B67FF',
    '#001C68FF',
    '#001E69FF',
    '#001F69FF',
    '#00206BFF',
    '#00226CFF',
    '#00236DFF',
    '#00256EFF',
    '#00276FFF',
    '#002870FF',
    '#002971FF',
    '#002B71FF',
    '#002D73FF',
    '#002E74FF',
    '#003075FF',
    '#003176FF',
    '#003277FF',
    '#003478FF',
    '#003579FF',
    '#003779FF',
    '#00387BFF',
    '#003A7CFF',
    '#003B7DFF',
    '#003D7EFF',
    '#003F7FFF',
    '#004080FF',
    '#004181FF',
    '#004382FF',
    '#004483FF',
    '#004683FF',
    '#004885FF',
    '#004986FF',
    '#004B87FF',
    '#004C88FF',
    '#004E89FF',
    '#004F8AFF',
    '#00518BFF',
    '#00528CFF',
    '#00548DFF',
    '#00558EFF',
    '#00578FFF',
    '#005890FF',
    '#005991FF',
    '#005B92FF',
    '#005C93FF',
    '#005E93FF',
    '#006095FF',
    '#006196FF',
    '#006297FF',
    '#006498FF',
    '#006599FF',
    '#00679AFF',
    '#00689BFF',
    '#006A9CFF',
    '#006C9DFF',
    '#006D9EFF',
    '#006F9FFF',
    '#0070A0FF',
    '#0071A1FF',
    '#0073A2FF',
    '#0074A3FF',
    '#0076A3FF',
    '#0078A5FF',
    '#0079A6FF',
    '#007BA7FF',
    '#007CA8FF',
    '#007EA9FF',
    '#007FAAFF',
    '#0281ABFF',
    '#0582ACFF',
    '#0883ADFF',
    '#0B85AEFF',
    '#0E86AFFF',
    '#1188B0FF',
    '#1489B1FF',
    '#178BB2FF',
    '#1A8CB3FF',
    '#1D8EB3FF',
    '#2090B5FF',
    '#2391B6FF',
    '#2693B7FF',
    '#2994B8FF',
    '#2C95B9FF',
    '#2F97BAFF',
    '#3298BBFF',
    '#369ABCFF',
    '#399CBDFF',
    '#3C9DBEFF',
    '#3F9FBFFF',
    '#41A0C0FF',
    '#44A2C1FF',
    '#47A3C2FF',
    '#4AA4C3FF',
    '#4DA6C3FF',
    '#51A8C5FF',
    '#54A9C6FF',
    '#57ABC7FF',
    '#5AACC8FF',
    '#5DAEC9FF',
    '#60AFCAFF',
    '#62B1CBFF',
    '#66B2CCFF',
    '#69B4CDFF',
    '#6CB5CEFF',
    '#6FB7CFFF',
    '#72B8D0FF',
    '#75BAD1FF',
    '#78BBD2FF',
    '#7BBDD3FF',
    '#7EBED3FF',
    '#81C0D5FF',
    '#83C1D6FF',
    '#86C3D7FF',
    '#89C4D8FF',
    '#8CC5D9FF',
    '#8FC7DAFF',
    '#92C8DBFF',
    '#96CADCFF',
    '#99CCDDFF',
    '#9CCDDEFF',
    '#9FCFDFFF',
    '#A2D0E0FF',
    '#A4D2E1FF',
    '#A7D3E2FF',
    '#AAD4E3FF',
    '#ADD6E3FF',
    '#B1D8E5FF',
    '#B4D9E6FF',
    '#B7DBE7FF',
    '#BADCE8FF',
    '#BDDEE9FF',
    '#C0DFEAFF',
    '#C3E1EBFF',
    '#C5E2ECFF',
    '#C8E3EDFF',
    '#CBE5EEFF',
    '#CEE6EFFF',
    '#D1E8F0FF',
    '#D4E9F1FF',
    '#D7EBF2FF',
    '#DAECF3FF',
    '#DDEEF3FF',
    '#E1F0F5FF',
    '#E3F1F6FF',
    '#E6F3F7FF',
    '#E9F4F8FF',
    '#ECF5F9FF',
    '#EFF7FAFF',
    '#F2F8FBFF',
    '#F6FAFCFF',
    '#F9FCFDFF',
    '#FCFDFEFF',
    '#FFFFFFFF',
  ],
  gist_earth: [
    '#000000FF',
    '#00002BFF',
    '#010038FF',
    '#010043FF',
    '#02004EFF',
    '#030058FF',
    '#030063FF',
    '#04006EFF',
    '#050273FF',
    '#050474FF',
    '#060674FF',
    '#070974FF',
    '#070B74FF',
    '#080D74FF',
    '#091075FF',
    '#091275FF',
    '#0A1475FF',
    '#0B1675FF',
    '#0B1975FF',
    '#0C1B75FF',
    '#0D1D76FF',
    '#0D2076FF',
    '#0E2276FF',
    '#0F2476FF',
    '#0F2776FF',
    '#102977FF',
    '#112B77FF',
    '#112D77FF',
    '#123077FF',
    '#133277FF',
    '#133477FF',
    '#143678FF',
    '#153878FF',
    '#153A78FF',
    '#163C78FF',
    '#173E78FF',
    '#174079FF',
    '#184279FF',
    '#194579FF',
    '#194779FF',
    '#1A4979FF',
    '#1B4B79FF',
    '#1B4D7AFF',
    '#1C4F7AFF',
    '#1D517AFF',
    '#1D537AFF',
    '#1E547AFF',
    '#1F567BFF',
    '#1F587BFF',
    '#205A7BFF',
    '#215C7BFF',
    '#215E7BFF',
    '#22607BFF',
    '#23617CFF',
    '#23637CFF',
    '#24657CFF',
    '#25667CFF',
    '#25687CFF',
    '#26697DFF',
    '#276B7DFF',
    '#276D7DFF',
    '#286E7DFF',
    '#29707DFF',
    '#29717DFF',
    '#2A737EFF',
    '#2B747EFF',
    '#2B767EFF',
    '#2C787EFF',
    '#2D797EFF',
    '#2D7B7FFF',
    '#2E7C7FFF',
    '#2F7E7FFF',
    '#2F7F7FFF',
    '#30807EFF',
    '#30817DFF',
    '#31817BFF',
    '#31827AFF',
    '#328279FF',
    '#328378FF',
    '#338477FF',
    '#338475FF',
    '#348574FF',
    '#348573FF',
    '#358672FF',
    '#358670FF',
    '#36876FFF',
    '#36886EFF',
    '#37886DFF',
    '#37896CFF',
    '#38896AFF',
    '#388A69FF',
    '#388A68FF',
    '#398B67FF',
    '#398C65FF',
    '#3A8C64FF',
    '#3A8D63FF',
    '#3B8D62FF',
    '#3B8E61FF',
    '#3C8E5FFF',
    '#3C8F5EFF',
    '#3D905DFF',
    '#3D905CFF',
    '#3E915AFF',
    '#3E9159FF',
    '#3F9258FF',
    '#3F9357FF',
    '#409355FF',
    '#409454FF',
    '#409453FF',
    '#419552FF',
    '#419551FF',
    '#42964FFF',
    '#42974EFF',
    '#43974DFF',
    '#43984CFF',
    '#44984AFF',
    '#449949FF',
    '#459948FF',
    '#479A47FF',
    '#499B46FF',
    '#4B9B46FF',
    '#4E9C47FF',
    '#509C47FF',
    '#529D48FF',
    '#549D48FF',
    '#579E49FF',
    '#599F4AFF',
    '#5B9F4AFF',
    '#5DA04BFF',
    '#5FA04BFF',
    '#62A14CFF',
    '#64A14DFF',
    '#66A24DFF',
    '#68A34EFF',
    '#6BA34EFF',
    '#6DA34FFF',
    '#6FA44FFF',
    '#71A450FF',
    '#73A551FF',
    '#76A551FF',
    '#78A652FF',
    '#79A652FF',
    '#7BA752FF',
    '#7DA752FF',
    '#7EA753FF',
    '#80A853FF',
    '#82A853FF',
    '#83A954FF',
    '#85A954FF',
    '#87AA54FF',
    '#88AA55FF',
    '#8AAB55FF',
    '#8CAB55FF',
    '#8DAB56FF',
    '#8FAC56FF',
    '#91AC56FF',
    '#92AD57FF',
    '#94AD57FF',
    '#96AE57FF',
    '#97AE58FF',
    '#99AE58FF',
    '#9AAF58FF',
    '#9CAF58FF',
    '#9EB059FF',
    '#9FB059FF',
    '#A1B159FF',
    '#A3B15AFF',
    '#A4B25AFF',
    '#A6B25AFF',
    '#A8B25BFF',
    '#A9B35BFF',
    '#ABB35BFF',
    '#ADB45CFF',
    '#AEB45CFF',
    '#B0B55CFF',
    '#B2B55DFF',
    '#B3B55DFF',
    '#B5B65DFF',
    '#B6B65EFF',
    '#B7B55EFF',
    '#B7B55EFF',
    '#B8B45FFF',
    '#B8B35FFF',
    '#B9B25FFF',
    '#B9B15FFF',
    '#B9B060FF',
    '#BAAF60FF',
    '#BAAF60FF',
    '#BBAE61FF',
    '#BBAD61FF',
    '#BCAC61FF',
    '#BCAB62FF',
    '#BCAA62FF',
    '#BDA962FF',
    '#BDA963FF',
    '#BEA863FF',
    '#BEA763FF',
    '#BEA664FF',
    '#BFA564FF',
    '#BFA464FF',
    '#C0A365FF',
    '#C0A367FF',
    '#C1A369FF',
    '#C2A36CFF',
    '#C3A46EFF',
    '#C5A471FF',
    '#C6A573FF',
    '#C7A676FF',
    '#C8A678FF',
    '#C9A77BFF',
    '#CAA87DFF',
    '#CBA97FFF',
    '#CCAA82FF',
    '#CEAB84FF',
    '#CFAC87FF',
    '#D0AD89FF',
    '#D1AD8CFF',
    '#D2AE8EFF',
    '#D3AF91FF',
    '#D4B093FF',
    '#D5B196FF',
    '#D6B298FF',
    '#D8B39AFF',
    '#D9B59DFF',
    '#DAB69FFF',
    '#DBB7A2FF',
    '#DCB9A4FF',
    '#DDBAA7FF',
    '#DEBCA9FF',
    '#DFBDACFF',
    '#E1BFAFFF',
    '#E2C1B2FF',
    '#E3C3B5FF',
    '#E4C5B8FF',
    '#E5C7BBFF',
    '#E6C9BEFF',
    '#E7CBC1FF',
    '#E8CDC4FF',
    '#E9CFC7FF',
    '#EBD1CAFF',
    '#ECD3CDFF',
    '#EDD5D0FF',
    '#EED7D3FF',
    '#EFD9D6FF',
    '#F0DCD9FF',
    '#F1DEDCFF',
    '#F2E0DFFF',
    '#F4E3E2FF',
    '#F5E6E5FF',
    '#F6E9E8FF',
    '#F7ECEBFF',
    '#F8EFEEFF',
    '#F9F2F1FF',
    '#FAF5F4FF',
    '#FBF8F7FF',
    '#FDFAFAFF',
  ],
  terrain: [
    '#333399FF',
    '#31359BFF',
    '#30389EFF',
    '#2F3BA1FF',
    '#2D3DA3FF',
    '#2C40A6FF',
    '#2B43A9FF',
    '#2945ABFF',
    '#2848AEFF',
    '#274BB1FF',
    '#254DB3FF',
    '#2450B6FF',
    '#2353B9FF',
    '#2155BBFF',
    '#2058BEFF',
    '#1F5BC1FF',
    '#1D5DC3FF',
    '#1C60C6FF',
    '#1B62C9FF',
    '#1965CBFF',
    '#1868CEFF',
    '#176BD1FF',
    '#156DD3FF',
    '#1470D6FF',
    '#1373D9FF',
    '#1175DBFF',
    '#1078DEFF',
    '#0E7BE1FF',
    '#0D7DE3FF',
    '#0C80E6FF',
    '#0B83E9FF',
    '#0985EBFF',
    '#0888EEFF',
    '#078AF1FF',
    '#058DF3FF',
    '#0490F6FF',
    '#0393F9FF',
    '#0195FBFF',
    '#0098FEFF',
    '#009AFAFF',
    '#009CF4FF',
    '#009EEEFF',
    '#00A0E8FF',
    '#00A2E2FF',
    '#00A4DCFF',
    '#00A6D6FF',
    '#00A8D0FF',
    '#00AACAFF',
    '#00ACC4FF',
    '#00AEBEFF',
    '#00B0B8FF',
    '#00B2B2FF',
    '#00B4ACFF',
    '#00B6A6FF',
    '#00B8A0FF',
    '#00BA9AFF',
    '#00BC94FF',
    '#00BE8EFF',
    '#00C088FF',
    '#00C282FF',
    '#00C47CFF',
    '#00C676FF',
    '#00C870FF',
    '#00CA6AFF',
    '#01CC66FF',
    '#05CD67FF',
    '#08CD67FF',
    '#0DCE68FF',
    '#11CF69FF',
    '#15D06AFF',
    '#19D16BFF',
    '#1DD16BFF',
    '#21D26CFF',
    '#25D36DFF',
    '#28D46EFF',
    '#2DD56FFF',
    '#31D56FFF',
    '#35D670FF',
    '#39D771FF',
    '#3DD872FF',
    '#41D973FF',
    '#45D973FF',
    '#48DA74FF',
    '#4DDB75FF',
    '#51DC76FF',
    '#55DD77FF',
    '#59DD77FF',
    '#5DDE78FF',
    '#61DF79FF',
    '#65E07AFF',
    '#68E17AFF',
    '#6DE17BFF',
    '#71E27CFF',
    '#75E37DFF',
    '#79E47EFF',
    '#7DE57FFF',
    '#81E57FFF',
    '#85E680FF',
    '#88E781FF',
    '#8DE882FF',
    '#91E983FF',
    '#95E983FF',
    '#99EA84FF',
    '#9DEB85FF',
    '#A1EC86FF',
    '#A5ED87FF',
    '#A8ED87FF',
    '#ADEE88FF',
    '#B1EF89FF',
    '#B5F08AFF',
    '#B9F18BFF',
    '#BDF18BFF',
    '#C1F28CFF',
    '#C5F38DFF',
    '#C8F48EFF',
    '#CDF58FFF',
    '#D1F58FFF',
    '#D5F690FF',
    '#D9F791FF',
    '#DDF892FF',
    '#E1F993FF',
    '#E5F993FF',
    '#E8FA94FF',
    '#EDFB95FF',
    '#F1FC96FF',
    '#F5FD97FF',
    '#F9FD97FF',
    '#FDFE98FF',
    '#FEFD98FF',
    '#FCFB97FF',
    '#FAF896FF',
    '#F8F695FF',
    '#F6F394FF',
    '#F4F093FF',
    '#F2EE91FF',
    '#F0EB90FF',
    '#EEE98FFF',
    '#ECE68EFF',
    '#EAE48DFF',
    '#E8E18CFF',
    '#E6DF8BFF',
    '#E4DC8AFF',
    '#E2D989FF',
    '#E0D788FF',
    '#DED487FF',
    '#DCD286FF',
    '#DACF85FF',
    '#D8CD83FF',
    '#D6CA82FF',
    '#D3C781FF',
    '#D2C580FF',
    '#D0C27FFF',
    '#CEC07EFF',
    '#CCBD7DFF',
    '#CABB7CFF',
    '#C8B87BFF',
    '#C6B67AFF',
    '#C3B379FF',
    '#C2B078FF',
    '#C0AE76FF',
    '#BEAB75FF',
    '#BCA974FF',
    '#BAA673FF',
    '#B8A472FF',
    '#B6A171FF',
    '#B39F70FF',
    '#B29C6FFF',
    '#B0996EFF',
    '#AE976DFF',
    '#AC946CFF',
    '#AA926BFF',
    '#A88F6AFF',
    '#A68D68FF',
    '#A38A67FF',
    '#A28766FF',
    '#A08565FF',
    '#9E8264FF',
    '#9C8063FF',
    '#9A7D62FF',
    '#987B61FF',
    '#967860FF',
    '#93765FFF',
    '#92735EFF',
    '#90705DFF',
    '#8E6E5BFF',
    '#8C6B5AFF',
    '#8A6959FF',
    '#886658FF',
    '#866457FF',
    '#836156FF',
    '#825F55FF',
    '#805C54FF',
    '#815D56FF',
    '#836058FF',
    '#85625BFF',
    '#87655EFF',
    '#886760FF',
    '#8B6A63FF',
    '#8D6D66FF',
    '#8F6F68FF',
    '#91726BFF',
    '#93746EFF',
    '#957770FF',
    '#977973FF',
    '#997C76FF',
    '#9B7F79FF',
    '#9D817BFF',
    '#9F847EFF',
    '#A18681FF',
    '#A38983FF',
    '#A58B86FF',
    '#A78E89FF',
    '#A8908BFF',
    '#AB938EFF',
    '#AD9691FF',
    '#AF9893FF',
    '#B19B96FF',
    '#B39D99FF',
    '#B5A09BFF',
    '#B7A29EFF',
    '#B9A5A1FF',
    '#BBA7A3FF',
    '#BDAAA6FF',
    '#BFADA9FF',
    '#C1AFABFF',
    '#C3B2AEFF',
    '#C4B4B1FF',
    '#C7B7B3FF',
    '#C8B9B6FF',
    '#CBBCB9FF',
    '#CDBFBBFF',
    '#CFC1BEFF',
    '#D1C4C1FF',
    '#D3C6C4FF',
    '#D4C9C6FF',
    '#D7CBC9FF',
    '#D9CECCFF',
    '#DBD0CEFF',
    '#DDD3D1FF',
    '#DFD6D4FF',
    '#E1D8D6FF',
    '#E3DBD9FF',
    '#E4DDDCFF',
    '#E7E0DEFF',
    '#E8E2E1FF',
    '#EBE5E4FF',
    '#EDE7E6FF',
    '#EFEAE9FF',
    '#F1EDECFF',
    '#F3EFEEFF',
    '#F4F2F1FF',
    '#F7F4F4FF',
    '#F9F7F6FF',
    '#FBF9F9FF',
    '#FDFCFCFF',
    '#FFFFFFFF',
  ],
  gray: [
    '#000000FF',
    '#010101FF',
    '#020202FF',
    '#030303FF',
    '#040404FF',
    '#050505FF',
    '#060606FF',
    '#070707FF',
    '#080808FF',
    '#090909FF',
    '#0A0A0AFF',
    '#0B0B0BFF',
    '#0C0C0CFF',
    '#0D0D0DFF',
    '#0E0E0EFF',
    '#0F0F0FFF',
    '#101010FF',
    '#111111FF',
    '#121212FF',
    '#131313FF',
    '#141414FF',
    '#151515FF',
    '#161616FF',
    '#171717FF',
    '#181818FF',
    '#191919FF',
    '#1A1A1AFF',
    '#1B1B1BFF',
    '#1C1C1CFF',
    '#1D1D1DFF',
    '#1E1E1EFF',
    '#1F1F1FFF',
    '#202020FF',
    '#202020FF',
    '#222222FF',
    '#232323FF',
    '#242424FF',
    '#242424FF',
    '#262626FF',
    '#272727FF',
    '#282828FF',
    '#282828FF',
    '#2A2A2AFF',
    '#2B2B2BFF',
    '#2C2C2CFF',
    '#2C2C2CFF',
    '#2E2E2EFF',
    '#2F2F2FFF',
    '#303030FF',
    '#303030FF',
    '#323232FF',
    '#333333FF',
    '#343434FF',
    '#343434FF',
    '#363636FF',
    '#373737FF',
    '#383838FF',
    '#383838FF',
    '#3A3A3AFF',
    '#3B3B3BFF',
    '#3C3C3CFF',
    '#3C3C3CFF',
    '#3E3E3EFF',
    '#3F3F3FFF',
    '#404040FF',
    '#414141FF',
    '#414141FF',
    '#434343FF',
    '#444444FF',
    '#454545FF',
    '#464646FF',
    '#474747FF',
    '#484848FF',
    '#494949FF',
    '#494949FF',
    '#4B4B4BFF',
    '#4C4C4CFF',
    '#4D4D4DFF',
    '#4E4E4EFF',
    '#4F4F4FFF',
    '#505050FF',
    '#515151FF',
    '#515151FF',
    '#535353FF',
    '#545454FF',
    '#555555FF',
    '#565656FF',
    '#575757FF',
    '#585858FF',
    '#595959FF',
    '#595959FF',
    '#5B5B5BFF',
    '#5C5C5CFF',
    '#5D5D5DFF',
    '#5E5E5EFF',
    '#5F5F5FFF',
    '#606060FF',
    '#616161FF',
    '#616161FF',
    '#636363FF',
    '#646464FF',
    '#656565FF',
    '#666666FF',
    '#676767FF',
    '#686868FF',
    '#696969FF',
    '#696969FF',
    '#6B6B6BFF',
    '#6C6C6CFF',
    '#6D6D6DFF',
    '#6E6E6EFF',
    '#6F6F6FFF',
    '#707070FF',
    '#717171FF',
    '#717171FF',
    '#737373FF',
    '#747474FF',
    '#757575FF',
    '#767676FF',
    '#777777FF',
    '#787878FF',
    '#797979FF',
    '#797979FF',
    '#7B7B7BFF',
    '#7C7C7CFF',
    '#7D7D7DFF',
    '#7E7E7EFF',
    '#7F7F7FFF',
    '#808080FF',
    '#818181FF',
    '#828282FF',
    '#838383FF',
    '#838383FF',
    '#858585FF',
    '#868686FF',
    '#878787FF',
    '#888888FF',
    '#898989FF',
    '#8A8A8AFF',
    '#8B8B8BFF',
    '#8C8C8CFF',
    '#8D8D8DFF',
    '#8E8E8EFF',
    '#8F8F8FFF',
    '#909090FF',
    '#919191FF',
    '#929292FF',
    '#939393FF',
    '#939393FF',
    '#959595FF',
    '#969696FF',
    '#979797FF',
    '#989898FF',
    '#999999FF',
    '#9A9A9AFF',
    '#9B9B9BFF',
    '#9C9C9CFF',
    '#9D9D9DFF',
    '#9E9E9EFF',
    '#9F9F9FFF',
    '#A0A0A0FF',
    '#A1A1A1FF',
    '#A2A2A2FF',
    '#A3A3A3FF',
    '#A3A3A3FF',
    '#A5A5A5FF',
    '#A6A6A6FF',
    '#A7A7A7FF',
    '#A8A8A8FF',
    '#A9A9A9FF',
    '#AAAAAAFF',
    '#ABABABFF',
    '#ACACACFF',
    '#ADADADFF',
    '#AEAEAEFF',
    '#AFAFAFFF',
    '#B0B0B0FF',
    '#B1B1B1FF',
    '#B2B2B2FF',
    '#B3B3B3FF',
    '#B3B3B3FF',
    '#B5B5B5FF',
    '#B6B6B6FF',
    '#B7B7B7FF',
    '#B8B8B8FF',
    '#B9B9B9FF',
    '#BABABAFF',
    '#BBBBBBFF',
    '#BCBCBCFF',
    '#BDBDBDFF',
    '#BEBEBEFF',
    '#BFBFBFFF',
    '#C0C0C0FF',
    '#C1C1C1FF',
    '#C2C2C2FF',
    '#C3C3C3FF',
    '#C3C3C3FF',
    '#C5C5C5FF',
    '#C6C6C6FF',
    '#C7C7C7FF',
    '#C8C8C8FF',
    '#C9C9C9FF',
    '#CACACAFF',
    '#CBCBCBFF',
    '#CCCCCCFF',
    '#CDCDCDFF',
    '#CECECEFF',
    '#CFCFCFFF',
    '#D0D0D0FF',
    '#D1D1D1FF',
    '#D2D2D2FF',
    '#D3D3D3FF',
    '#D3D3D3FF',
    '#D5D5D5FF',
    '#D6D6D6FF',
    '#D7D7D7FF',
    '#D8D8D8FF',
    '#D9D9D9FF',
    '#DADADAFF',
    '#DBDBDBFF',
    '#DCDCDCFF',
    '#DDDDDDFF',
    '#DEDEDEFF',
    '#DFDFDFFF',
    '#E0E0E0FF',
    '#E1E1E1FF',
    '#E2E2E2FF',
    '#E3E3E3FF',
    '#E3E3E3FF',
    '#E5E5E5FF',
    '#E6E6E6FF',
    '#E7E7E7FF',
    '#E8E8E8FF',
    '#E9E9E9FF',
    '#EAEAEAFF',
    '#EBEBEBFF',
    '#ECECECFF',
    '#EDEDEDFF',
    '#EEEEEEFF',
    '#EFEFEFFF',
    '#F0F0F0FF',
    '#F1F1F1FF',
    '#F2F2F2FF',
    '#F3F3F3FF',
    '#F3F3F3FF',
    '#F5F5F5FF',
    '#F6F6F6FF',
    '#F7F7F7FF',
    '#F8F8F8FF',
    '#F9F9F9FF',
    '#FAFAFAFF',
    '#FBFBFBFF',
    '#FCFCFCFF',
    '#FDFDFDFF',
    '#FEFEFEFF',
    '#FFFFFFFF',
  ],
  afmhot: [
    '#000000FF',
    '#020000FF',
    '#040000FF',
    '#060000FF',
    '#080000FF',
    '#0A0000FF',
    '#0C0000FF',
    '#0E0000FF',
    '#100000FF',
    '#120000FF',
    '#140000FF',
    '#160000FF',
    '#180000FF',
    '#1A0000FF',
    '#1C0000FF',
    '#1E0000FF',
    '#200000FF',
    '#220000FF',
    '#240000FF',
    '#260000FF',
    '#280000FF',
    '#2A0000FF',
    '#2C0000FF',
    '#2E0000FF',
    '#300000FF',
    '#320000FF',
    '#340000FF',
    '#360000FF',
    '#380000FF',
    '#3A0000FF',
    '#3C0000FF',
    '#3E0000FF',
    '#400000FF',
    '#410000FF',
    '#440000FF',
    '#460000FF',
    '#480000FF',
    '#490000FF',
    '#4C0000FF',
    '#4E0000FF',
    '#500000FF',
    '#510000FF',
    '#540000FF',
    '#560000FF',
    '#580000FF',
    '#590000FF',
    '#5C0000FF',
    '#5E0000FF',
    '#600000FF',
    '#610000FF',
    '#640000FF',
    '#660000FF',
    '#680000FF',
    '#690000FF',
    '#6C0000FF',
    '#6E0000FF',
    '#700000FF',
    '#710000FF',
    '#740000FF',
    '#760000FF',
    '#780000FF',
    '#790000FF',
    '#7C0000FF',
    '#7E0000FF',
    '#800000FF',
    '#820200FF',
    '#830400FF',
    '#860600FF',
    '#880800FF',
    '#8A0A00FF',
    '#8C0C00FF',
    '#8E0E00FF',
    '#901000FF',
    '#921200FF',
    '#931400FF',
    '#961600FF',
    '#981800FF',
    '#9A1A00FF',
    '#9C1C00FF',
    '#9E1E00FF',
    '#A02000FF',
    '#A22200FF',
    '#A32400FF',
    '#A62600FF',
    '#A82800FF',
    '#AA2A00FF',
    '#AC2C00FF',
    '#AE2E00FF',
    '#B03000FF',
    '#B23200FF',
    '#B33400FF',
    '#B63600FF',
    '#B83800FF',
    '#BA3A00FF',
    '#BC3C00FF',
    '#BE3E00FF',
    '#C04000FF',
    '#C24200FF',
    '#C34400FF',
    '#C64600FF',
    '#C84800FF',
    '#CA4A00FF',
    '#CC4C00FF',
    '#CE4E00FF',
    '#D05000FF',
    '#D25200FF',
    '#D35400FF',
    '#D65600FF',
    '#D85800FF',
    '#DA5A00FF',
    '#DC5C00FF',
    '#DE5E00FF',
    '#E06000FF',
    '#E26200FF',
    '#E36400FF',
    '#E66600FF',
    '#E86800FF',
    '#EA6A00FF',
    '#EC6C00FF',
    '#EE6E00FF',
    '#F07000FF',
    '#F27200FF',
    '#F37400FF',
    '#F67600FF',
    '#F87800FF',
    '#FA7A00FF',
    '#FC7C00FF',
    '#FE7E00FF',
    '#FF8000FF',
    '#FF8202FF',
    '#FF8404FF',
    '#FF8606FF',
    '#FF8808FF',
    '#FF8A0BFF',
    '#FF8C0DFF',
    '#FF8E0FFF',
    '#FF9010FF',
    '#FF9212FF',
    '#FF9414FF',
    '#FF9616FF',
    '#FF9819FF',
    '#FF9A1BFF',
    '#FF9C1DFF',
    '#FF9E1FFF',
    '#FFA020FF',
    '#FFA222FF',
    '#FFA424FF',
    '#FFA626FF',
    '#FFA828FF',
    '#FFAA2BFF',
    '#FFAC2DFF',
    '#FFAE2FFF',
    '#FFB030FF',
    '#FFB232FF',
    '#FFB434FF',
    '#FFB636FF',
    '#FFB839FF',
    '#FFBA3BFF',
    '#FFBC3DFF',
    '#FFBE3FFF',
    '#FFC041FF',
    '#FFC242FF',
    '#FFC444FF',
    '#FFC646FF',
    '#FFC848FF',
    '#FFCA4BFF',
    '#FFCC4DFF',
    '#FFCE4FFF',
    '#FFD051FF',
    '#FFD252FF',
    '#FFD454FF',
    '#FFD656FF',
    '#FFD859FF',
    '#FFDA5BFF',
    '#FFDC5DFF',
    '#FFDE5FFF',
    '#FFE061FF',
    '#FFE262FF',
    '#FFE464FF',
    '#FFE666FF',
    '#FFE868FF',
    '#FFEA6BFF',
    '#FFEC6DFF',
    '#FFEE6FFF',
    '#FFF071FF',
    '#FFF272FF',
    '#FFF474FF',
    '#FFF676FF',
    '#FFF879FF',
    '#FFFA7BFF',
    '#FFFC7DFF',
    '#FFFE7FFF',
    '#FFFF81FF',
    '#FFFF83FF',
    '#FFFF84FF',
    '#FFFF86FF',
    '#FFFF88FF',
    '#FFFF8BFF',
    '#FFFF8DFF',
    '#FFFF8FFF',
    '#FFFF91FF',
    '#FFFF93FF',
    '#FFFF94FF',
    '#FFFF96FF',
    '#FFFF99FF',
    '#FFFF9BFF',
    '#FFFF9DFF',
    '#FFFF9FFF',
    '#FFFFA1FF',
    '#FFFFA3FF',
    '#FFFFA4FF',
    '#FFFFA6FF',
    '#FFFFA8FF',
    '#FFFFABFF',
    '#FFFFADFF',
    '#FFFFAFFF',
    '#FFFFB1FF',
    '#FFFFB3FF',
    '#FFFFB4FF',
    '#FFFFB6FF',
    '#FFFFB9FF',
    '#FFFFBBFF',
    '#FFFFBDFF',
    '#FFFFBFFF',
    '#FFFFC1FF',
    '#FFFFC3FF',
    '#FFFFC4FF',
    '#FFFFC6FF',
    '#FFFFC8FF',
    '#FFFFCBFF',
    '#FFFFCDFF',
    '#FFFFCFFF',
    '#FFFFD1FF',
    '#FFFFD3FF',
    '#FFFFD4FF',
    '#FFFFD6FF',
    '#FFFFD9FF',
    '#FFFFDBFF',
    '#FFFFDDFF',
    '#FFFFDFFF',
    '#FFFFE1FF',
    '#FFFFE3FF',
    '#FFFFE4FF',
    '#FFFFE6FF',
    '#FFFFE8FF',
    '#FFFFEBFF',
    '#FFFFEDFF',
    '#FFFFEFFF',
    '#FFFFF1FF',
    '#FFFFF3FF',
    '#FFFFF4FF',
    '#FFFFF6FF',
    '#FFFFF9FF',
    '#FFFFFBFF',
    '#FFFFFDFF',
    '#FFFFFFFF',
  ],
  coolwarm: [
    '#3A4CC0FF',
    '#3B4DC1FF',
    '#3C4FC3FF',
    '#3E51C4FF',
    '#3F53C6FF',
    '#4054C7FF',
    '#4156C9FF',
    '#4258CAFF',
    '#435ACCFF',
    '#455BCDFF',
    '#465DCFFF',
    '#475FD0FF',
    '#4860D1FF',
    '#4962D3FF',
    '#4B64D4FF',
    '#4C66D6FF',
    '#4D67D7FF',
    '#4E69D8FF',
    '#506BDAFF',
    '#516CDBFF',
    '#526EDCFF',
    '#5370DDFF',
    '#5571DEFF',
    '#5673E0FF',
    '#5775E1FF',
    '#5876E2FF',
    '#5A78E3FF',
    '#5B79E4FF',
    '#5C7BE5FF',
    '#5D7DE6FF',
    '#5F7EE7FF',
    '#6080E8FF',
    '#6182EAFF',
    '#6383EAFF',
    '#6485EBFF',
    '#6586ECFF',
    '#6788EDFF',
    '#6889EEFF',
    '#698BEFFF',
    '#6B8DF0FF',
    '#6C8EF1FF',
    '#6D90F1FF',
    '#6F91F2FF',
    '#7093F3FF',
    '#7194F4FF',
    '#7395F4FF',
    '#7497F5FF',
    '#7598F6FF',
    '#779AF6FF',
    '#789BF7FF',
    '#7A9DF8FF',
    '#7B9EF8FF',
    '#7CA0F9FF',
    '#7EA1F9FF',
    '#7FA2FAFF',
    '#80A4FAFF',
    '#82A5FBFF',
    '#83A6FBFF',
    '#85A8FBFF',
    '#86A9FCFF',
    '#87AAFCFF',
    '#89ACFCFF',
    '#8AADFDFF',
    '#8BAEFDFF',
    '#8DAFFDFF',
    '#8EB1FDFF',
    '#90B2FEFF',
    '#91B3FEFF',
    '#92B4FEFF',
    '#94B5FEFF',
    '#95B7FEFF',
    '#97B8FEFF',
    '#98B9FEFF',
    '#99BAFEFF',
    '#9BBBFEFF',
    '#9CBCFEFF',
    '#9DBDFEFF',
    '#9FBEFEFF',
    '#A0BFFEFF',
    '#A2C0FEFF',
    '#A3C1FEFF',
    '#A4C2FEFF',
    '#A6C3FDFF',
    '#A7C4FDFF',
    '#A8C5FDFF',
    '#AAC6FDFF',
    '#ABC7FCFF',
    '#ACC8FCFF',
    '#AEC9FCFF',
    '#AFCAFBFF',
    '#B0CBFBFF',
    '#B2CBFBFF',
    '#B3CCFAFF',
    '#B4CDFAFF',
    '#B6CEF9FF',
    '#B7CFF9FF',
    '#B8CFF8FF',
    '#B9D0F8FF',
    '#BBD1F7FF',
    '#BCD1F6FF',
    '#BDD2F6FF',
    '#BED3F5FF',
    '#C0D3F5FF',
    '#C1D4F4FF',
    '#C2D4F3FF',
    '#C3D5F2FF',
    '#C5D5F2FF',
    '#C6D6F1FF',
    '#C7D6F0FF',
    '#C8D7EFFF',
    '#C9D7EEFF',
    '#CAD8EEFF',
    '#CCD8EDFF',
    '#CDD9ECFF',
    '#CED9EBFF',
    '#CFD9EAFF',
    '#D0DAE9FF',
    '#D1DAE8FF',
    '#D2DAE7FF',
    '#D3DBE6FF',
    '#D5DBE5FF',
    '#D6DBE4FF',
    '#D7DBE2FF',
    '#D8DBE1FF',
    '#D9DCE0FF',
    '#DADCDFFF',
    '#DBDCDEFF',
    '#DCDCDDFF',
    '#DDDCDBFF',
    '#DEDBDAFF',
    '#DFDBD9FF',
    '#E0DAD7FF',
    '#E1DAD6FF',
    '#E2D9D4FF',
    '#E3D9D3FF',
    '#E4D8D1FF',
    '#E5D8D0FF',
    '#E6D7CFFF',
    '#E7D6CDFF',
    '#E7D6CCFF',
    '#E8D5CAFF',
    '#E9D4C9FF',
    '#EAD3C7FF',
    '#EBD3C6FF',
    '#ECD2C4FF',
    '#ECD1C3FF',
    '#EDD0C1FF',
    '#EDCFC0FF',
    '#EECFBEFF',
    '#EFCEBCFF',
    '#EFCDBBFF',
    '#F0CCB9FF',
    '#F1CBB8FF',
    '#F1CAB6FF',
    '#F2C9B5FF',
    '#F2C8B3FF',
    '#F2C7B2FF',
    '#F3C6B0FF',
    '#F3C5AFFF',
    '#F4C4ADFF',
    '#F4C3ABFF',
    '#F4C2AAFF',
    '#F5C1A8FF',
    '#F5C0A7FF',
    '#F5BFA5FF',
    '#F6BDA4FF',
    '#F6BCA2FF',
    '#F6BBA0FF',
    '#F6BA9FFF',
    '#F6B99DFF',
    '#F6B79CFF',
    '#F6B69AFF',
    '#F7B598FF',
    '#F7B397FF',
    '#F7B295FF',
    '#F7B194FF',
    '#F7B092FF',
    '#F7AE91FF',
    '#F7AD8FFF',
    '#F6AB8DFF',
    '#F6AA8CFF',
    '#F6A98AFF',
    '#F6A789FF',
    '#F6A687FF',
    '#F6A486FF',
    '#F6A384FF',
    '#F5A182FF',
    '#F5A081FF',
    '#F59E7FFF',
    '#F49D7EFF',
    '#F49B7CFF',
    '#F49A7BFF',
    '#F39879FF',
    '#F39678FF',
    '#F39576FF',
    '#F29375FF',
    '#F29173FF',
    '#F19072FF',
    '#F18E70FF',
    '#F08D6FFF',
    '#F08B6DFF',
    '#EF896CFF',
    '#EE876AFF',
    '#EE8669FF',
    '#ED8467FF',
    '#EC8266FF',
    '#EC8064FF',
    '#EB7F63FF',
    '#EA7D61FF',
    '#EA7B60FF',
    '#E9795EFF',
    '#E8775DFF',
    '#E7755CFF',
    '#E6745AFF',
    '#E67259FF',
    '#E57057FF',
    '#E46E56FF',
    '#E36C54FF',
    '#E26A53FF',
    '#E16852FF',
    '#E06650FF',
    '#DF644FFF',
    '#DE624EFF',
    '#DD604CFF',
    '#DC5E4BFF',
    '#DB5C4AFF',
    '#DA5A48FF',
    '#D95847FF',
    '#D85646FF',
    '#D75444FF',
    '#D65243FF',
    '#D44F42FF',
    '#D34D40FF',
    '#D24B3FFF',
    '#D1493EFF',
    '#CF463DFF',
    '#CE443CFF',
    '#CD423AFF',
    '#CC3F39FF',
    '#CA3D38FF',
    '#C93B37FF',
    '#C83835FF',
    '#C63534FF',
    '#C53233FF',
    '#C43032FF',
    '#C22D31FF',
    '#C12A30FF',
    '#BF282EFF',
    '#BE232DFF',
    '#BC1F2CFF',
    '#BB1A2BFF',
    '#B9162AFF',
    '#B81129FF',
    '#B60D28FF',
    '#B50827FF',
    '#B30326FF',
  ],
  seismic: [
    '#00004CFF',
    '#00004FFF',
    '#000052FF',
    '#000054FF',
    '#000057FF',
    '#00005AFF',
    '#00005DFF',
    '#000060FF',
    '#000062FF',
    '#000065FF',
    '#000068FF',
    '#00006BFF',
    '#00006EFF',
    '#000070FF',
    '#000073FF',
    '#000076FF',
    '#000079FF',
    '#00007CFF',
    '#00007EFF',
    '#000081FF',
    '#000084FF',
    '#000087FF',
    '#00008AFF',
    '#00008CFF',
    '#00008FFF',
    '#000092FF',
    '#000095FF',
    '#000098FF',
    '#00009AFF',
    '#00009DFF',
    '#0000A0FF',
    '#0000A3FF',
    '#0000A6FF',
    '#0000A8FF',
    '#0000ABFF',
    '#0000AEFF',
    '#0000B1FF',
    '#0000B4FF',
    '#0000B6FF',
    '#0000B9FF',
    '#0000BCFF',
    '#0000BFFF',
    '#0000C2FF',
    '#0000C4FF',
    '#0000C7FF',
    '#0000CAFF',
    '#0000CDFF',
    '#0000D0FF',
    '#0000D2FF',
    '#0000D5FF',
    '#0000D8FF',
    '#0000DBFF',
    '#0000DEFF',
    '#0000E0FF',
    '#0000E3FF',
    '#0000E6FF',
    '#0000E9FF',
    '#0000ECFF',
    '#0000EEFF',
    '#0000F1FF',
    '#0000F4FF',
    '#0000F7FF',
    '#0000FAFF',
    '#0000FCFF',
    '#0101FFFF',
    '#0505FFFF',
    '#0808FFFF',
    '#0D0DFFFF',
    '#1111FFFF',
    '#1515FFFF',
    '#1919FFFF',
    '#1D1DFFFF',
    '#2121FFFF',
    '#2525FFFF',
    '#2828FFFF',
    '#2D2DFFFF',
    '#3131FFFF',
    '#3535FFFF',
    '#3939FFFF',
    '#3D3DFFFF',
    '#4141FFFF',
    '#4545FFFF',
    '#4848FFFF',
    '#4D4DFFFF',
    '#5151FFFF',
    '#5555FFFF',
    '#5959FFFF',
    '#5D5DFFFF',
    '#6161FFFF',
    '#6565FFFF',
    '#6868FFFF',
    '#6D6DFFFF',
    '#7171FFFF',
    '#7575FFFF',
    '#7979FFFF',
    '#7D7DFFFF',
    '#8181FFFF',
    '#8585FFFF',
    '#8888FFFF',
    '#8D8DFFFF',
    '#9191FFFF',
    '#9595FFFF',
    '#9999FFFF',
    '#9D9DFFFF',
    '#A1A1FFFF',
    '#A5A5FFFF',
    '#A8A8FFFF',
    '#ADADFFFF',
    '#B1B1FFFF',
    '#B5B5FFFF',
    '#B9B9FFFF',
    '#BDBDFFFF',
    '#C1C1FFFF',
    '#C5C5FFFF',
    '#C8C8FFFF',
    '#CDCDFFFF',
    '#D1D1FFFF',
    '#D5D5FFFF',
    '#D9D9FFFF',
    '#DDDDFFFF',
    '#E1E1FFFF',
    '#E5E5FFFF',
    '#E8E8FFFF',
    '#EDEDFFFF',
    '#F1F1FFFF',
    '#F5F5FFFF',
    '#F9F9FFFF',
    '#FDFDFFFF',
    '#FFFDFDFF',
    '#FFF9F9FF',
    '#FFF5F5FF',
    '#FFF1F1FF',
    '#FFEDEDFF',
    '#FFE9E9FF',
    '#FFE5E5FF',
    '#FFE1E1FF',
    '#FFDDDDFF',
    '#FFD9D9FF',
    '#FFD5D5FF',
    '#FFD1D1FF',
    '#FFCDCDFF',
    '#FFC9C9FF',
    '#FFC5C5FF',
    '#FFC1C1FF',
    '#FFBDBDFF',
    '#FFB9B9FF',
    '#FFB4B4FF',
    '#FFB1B1FF',
    '#FFADADFF',
    '#FFA9A9FF',
    '#FFA4A4FF',
    '#FFA1A1FF',
    '#FF9D9DFF',
    '#FF9999FF',
    '#FF9494FF',
    '#FF9191FF',
    '#FF8D8DFF',
    '#FF8989FF',
    '#FF8484FF',
    '#FF8181FF',
    '#FF7D7DFF',
    '#FF7979FF',
    '#FF7575FF',
    '#FF7171FF',
    '#FF6D6DFF',
    '#FF6969FF',
    '#FF6565FF',
    '#FF6161FF',
    '#FF5D5DFF',
    '#FF5959FF',
    '#FF5555FF',
    '#FF5151FF',
    '#FF4D4DFF',
    '#FF4949FF',
    '#FF4545FF',
    '#FF4141FF',
    '#FF3D3DFF',
    '#FF3838FF',
    '#FF3535FF',
    '#FF3030FF',
    '#FF2D2DFF',
    '#FF2828FF',
    '#FF2525FF',
    '#FF2020FF',
    '#FF1D1DFF',
    '#FF1818FF',
    '#FF1515FF',
    '#FF1010FF',
    '#FF0D0DFF',
    '#FF0808FF',
    '#FF0505FF',
    '#FF0000FF',
    '#FD0000FF',
    '#FB0000FF',
    '#F90000FF',
    '#F70000FF',
    '#F50000FF',
    '#F30000FF',
    '#F10000FF',
    '#EF0000FF',
    '#ED0000FF',
    '#EB0000FF',
    '#E90000FF',
    '#E70000FF',
    '#E50000FF',
    '#E30000FF',
    '#E10000FF',
    '#DF0000FF',
    '#DD0000FF',
    '#DB0000FF',
    '#D90000FF',
    '#D70000FF',
    '#D50000FF',
    '#D30000FF',
    '#D10000FF',
    '#CF0000FF',
    '#CD0000FF',
    '#CB0000FF',
    '#C90000FF',
    '#C70000FF',
    '#C50000FF',
    '#C30000FF',
    '#C10000FF',
    '#BF0000FF',
    '#BD0000FF',
    '#BB0000FF',
    '#B90000FF',
    '#B70000FF',
    '#B50000FF',
    '#B30000FF',
    '#B10000FF',
    '#AF0000FF',
    '#AD0000FF',
    '#AB0000FF',
    '#A90000FF',
    '#A70000FF',
    '#A50000FF',
    '#A30000FF',
    '#A10000FF',
    '#9F0000FF',
    '#9D0000FF',
    '#9B0000FF',
    '#990000FF',
    '#970000FF',
    '#950000FF',
    '#930000FF',
    '#910000FF',
    '#8F0000FF',
    '#8D0000FF',
    '#8B0000FF',
    '#890000FF',
    '#870000FF',
    '#850000FF',
    '#830000FF',
    '#810000FF',
    '#7F0000FF',
  ],
  cool: [
    '#00FFFFFF',
    '#01FEFFFF',
    '#02FDFFFF',
    '#03FCFFFF',
    '#04FBFFFF',
    '#05FAFFFF',
    '#06F9FFFF',
    '#07F8FFFF',
    '#08F7FFFF',
    '#09F6FFFF',
    '#0AF5FFFF',
    '#0BF4FFFF',
    '#0CF3FFFF',
    '#0DF2FFFF',
    '#0EF1FFFF',
    '#0FF0FFFF',
    '#10EFFFFF',
    '#11EEFFFF',
    '#12EDFFFF',
    '#13ECFFFF',
    '#14EBFFFF',
    '#15EAFFFF',
    '#16E9FFFF',
    '#17E8FFFF',
    '#18E7FFFF',
    '#19E6FFFF',
    '#1AE5FFFF',
    '#1BE4FFFF',
    '#1CE3FFFF',
    '#1DE2FFFF',
    '#1EE1FFFF',
    '#1FE0FFFF',
    '#20DFFFFF',
    '#20DEFFFF',
    '#22DDFFFF',
    '#23DCFFFF',
    '#24DBFFFF',
    '#24DAFFFF',
    '#26D9FFFF',
    '#27D8FFFF',
    '#28D7FFFF',
    '#28D6FFFF',
    '#2AD5FFFF',
    '#2BD3FFFF',
    '#2CD3FFFF',
    '#2CD2FFFF',
    '#2ED1FFFF',
    '#2FD0FFFF',
    '#30CFFFFF',
    '#30CEFFFF',
    '#32CDFFFF',
    '#33CCFFFF',
    '#34CBFFFF',
    '#34CAFFFF',
    '#36C9FFFF',
    '#37C8FFFF',
    '#38C7FFFF',
    '#38C6FFFF',
    '#3AC5FFFF',
    '#3BC3FFFF',
    '#3CC3FFFF',
    '#3CC2FFFF',
    '#3EC1FFFF',
    '#3FC0FFFF',
    '#40BFFFFF',
    '#41BEFFFF',
    '#41BDFFFF',
    '#43BCFFFF',
    '#44BBFFFF',
    '#45BAFFFF',
    '#46B9FFFF',
    '#47B8FFFF',
    '#48B7FFFF',
    '#49B6FFFF',
    '#49B5FFFF',
    '#4BB3FFFF',
    '#4CB3FFFF',
    '#4DB2FFFF',
    '#4EB1FFFF',
    '#4FB0FFFF',
    '#50AFFFFF',
    '#51AEFFFF',
    '#51ADFFFF',
    '#53ACFFFF',
    '#54ABFFFF',
    '#55AAFFFF',
    '#56A9FFFF',
    '#57A8FFFF',
    '#58A7FFFF',
    '#59A6FFFF',
    '#59A5FFFF',
    '#5BA3FFFF',
    '#5CA3FFFF',
    '#5DA2FFFF',
    '#5EA1FFFF',
    '#5FA0FFFF',
    '#609FFFFF',
    '#619EFFFF',
    '#619DFFFF',
    '#639CFFFF',
    '#649BFFFF',
    '#659AFFFF',
    '#6699FFFF',
    '#6798FFFF',
    '#6897FFFF',
    '#6996FFFF',
    '#6995FFFF',
    '#6B93FFFF',
    '#6C93FFFF',
    '#6D92FFFF',
    '#6E91FFFF',
    '#6F90FFFF',
    '#708FFFFF',
    '#718EFFFF',
    '#718DFFFF',
    '#738CFFFF',
    '#748BFFFF',
    '#758AFFFF',
    '#7689FFFF',
    '#7788FFFF',
    '#7887FFFF',
    '#7986FFFF',
    '#7985FFFF',
    '#7B83FFFF',
    '#7C83FFFF',
    '#7D82FFFF',
    '#7E81FFFF',
    '#7F80FFFF',
    '#807FFFFF',
    '#817EFFFF',
    '#827DFFFF',
    '#837CFFFF',
    '#837BFFFF',
    '#8579FFFF',
    '#8679FFFF',
    '#8778FFFF',
    '#8877FFFF',
    '#8976FFFF',
    '#8A75FFFF',
    '#8B74FFFF',
    '#8C72FFFF',
    '#8D71FFFF',
    '#8E71FFFF',
    '#8F70FFFF',
    '#906FFFFF',
    '#916EFFFF',
    '#926DFFFF',
    '#936CFFFF',
    '#936BFFFF',
    '#9569FFFF',
    '#9669FFFF',
    '#9768FFFF',
    '#9867FFFF',
    '#9966FFFF',
    '#9A65FFFF',
    '#9B64FFFF',
    '#9C62FFFF',
    '#9D61FFFF',
    '#9E61FFFF',
    '#9F60FFFF',
    '#A05FFFFF',
    '#A15EFFFF',
    '#A25DFFFF',
    '#A35CFFFF',
    '#A35BFFFF',
    '#A559FFFF',
    '#A659FFFF',
    '#A758FFFF',
    '#A857FFFF',
    '#A956FFFF',
    '#AA55FFFF',
    '#AB54FFFF',
    '#AC52FFFF',
    '#AD51FFFF',
    '#AE51FFFF',
    '#AF50FFFF',
    '#B04FFFFF',
    '#B14EFFFF',
    '#B24DFFFF',
    '#B34CFFFF',
    '#B34BFFFF',
    '#B549FFFF',
    '#B649FFFF',
    '#B748FFFF',
    '#B847FFFF',
    '#B946FFFF',
    '#BA45FFFF',
    '#BB44FFFF',
    '#BC42FFFF',
    '#BD41FFFF',
    '#BE41FFFF',
    '#BF40FFFF',
    '#C03FFFFF',
    '#C13EFFFF',
    '#C23DFFFF',
    '#C33CFFFF',
    '#C33BFFFF',
    '#C539FFFF',
    '#C638FFFF',
    '#C738FFFF',
    '#C837FFFF',
    '#C936FFFF',
    '#CA35FFFF',
    '#CB34FFFF',
    '#CC32FFFF',
    '#CD31FFFF',
    '#CE30FFFF',
    '#CF30FFFF',
    '#D02FFFFF',
    '#D12EFFFF',
    '#D22DFFFF',
    '#D32CFFFF',
    '#D32BFFFF',
    '#D529FFFF',
    '#D628FFFF',
    '#D728FFFF',
    '#D827FFFF',
    '#D926FFFF',
    '#DA25FFFF',
    '#DB24FFFF',
    '#DC22FFFF',
    '#DD21FFFF',
    '#DE20FFFF',
    '#DF20FFFF',
    '#E01FFFFF',
    '#E11EFFFF',
    '#E21DFFFF',
    '#E31CFFFF',
    '#E31BFFFF',
    '#E519FFFF',
    '#E618FFFF',
    '#E718FFFF',
    '#E817FFFF',
    '#E916FFFF',
    '#EA15FFFF',
    '#EB14FFFF',
    '#EC12FFFF',
    '#ED11FFFF',
    '#EE10FFFF',
    '#EF10FFFF',
    '#F00FFFFF',
    '#F10EFFFF',
    '#F20DFFFF',
    '#F30CFFFF',
    '#F30BFFFF',
    '#F509FFFF',
    '#F608FFFF',
    '#F708FFFF',
    '#F807FFFF',
    '#F906FFFF',
    '#FA05FFFF',
    '#FB04FFFF',
    '#FC02FFFF',
    '#FD01FFFF',
    '#FE00FFFF',
    '#FF00FFFF',
  ],
  rdylgn: [
    '#A50026FF',
    '#A60126FF',
    '#A80326FF',
    '#AA0526FF',
    '#AC0726FF',
    '#AE0926FF',
    '#B00B26FF',
    '#B20D26FF',
    '#B40F26FF',
    '#B61026FF',
    '#B81226FF',
    '#BA1426FF',
    '#BC1626FF',
    '#BE1826FF',
    '#C01A26FF',
    '#C21C26FF',
    '#C41E26FF',
    '#C62026FF',
    '#C82126FF',
    '#CA2326FF',
    '#CC2526FF',
    '#CE2726FF',
    '#D02926FF',
    '#D22B26FF',
    '#D42D26FF',
    '#D62F26FF',
    '#D73127FF',
    '#D83328FF',
    '#D93529FF',
    '#DA382AFF',
    '#DC3A2BFF',
    '#DD3D2DFF',
    '#DE3F2EFF',
    '#DF412FFF',
    '#E04430FF',
    '#E14631FF',
    '#E24932FF',
    '#E44B33FF',
    '#E54D34FF',
    '#E65035FF',
    '#E75236FF',
    '#E85538FF',
    '#E95739FF',
    '#EA593AFF',
    '#EC5C3BFF',
    '#ED5E3CFF',
    '#EE613DFF',
    '#EF633EFF',
    '#F0653FFF',
    '#F16840FF',
    '#F26A41FF',
    '#F46D43FF',
    '#F46F44FF',
    '#F47245FF',
    '#F57446FF',
    '#F57747FF',
    '#F57948FF',
    '#F67C4AFF',
    '#F67E4BFF',
    '#F6814CFF',
    '#F7834DFF',
    '#F7864EFF',
    '#F7894FFF',
    '#F88B51FF',
    '#F88E52FF',
    '#F89053FF',
    '#F99354FF',
    '#F99555FF',
    '#FA9856FF',
    '#FA9A58FF',
    '#FA9D59FF',
    '#FB9F5AFF',
    '#FBA25BFF',
    '#FBA55CFF',
    '#FCA75EFF',
    '#FCAA5FFF',
    '#FCAC60FF',
    '#FDAE61FF',
    '#FDB063FF',
    '#FDB265FF',
    '#FDB466FF',
    '#FDB668FF',
    '#FDB86AFF',
    '#FDBA6BFF',
    '#FDBC6DFF',
    '#FDBE6EFF',
    '#FDC070FF',
    '#FDC272FF',
    '#FDC473FF',
    '#FDC675FF',
    '#FDC877FF',
    '#FDCA78FF',
    '#FDCC7AFF',
    '#FDCE7CFF',
    '#FDD07DFF',
    '#FDD27FFF',
    '#FDD481FF',
    '#FDD682FF',
    '#FDD884FF',
    '#FDDA86FF',
    '#FDDC87FF',
    '#FDDE89FF',
    '#FEE08BFF',
    '#FEE18DFF',
    '#FEE28FFF',
    '#FEE391FF',
    '#FEE493FF',
    '#FEE695FF',
    '#FEE797FF',
    '#FEE899FF',
    '#FEE99BFF',
    '#FEEA9DFF',
    '#FEEC9FFF',
    '#FEEDA1FF',
    '#FEEEA3FF',
    '#FEEFA5FF',
    '#FEF1A7FF',
    '#FEF2A9FF',
    '#FEF3ABFF',
    '#FEF4ADFF',
    '#FEF5AFFF',
    '#FEF7B1FF',
    '#FEF8B3FF',
    '#FEF9B5FF',
    '#FEFAB7FF',
    '#FEFBB9FF',
    '#FEFDBBFF',
    '#FEFEBDFF',
    '#FEFEBDFF',
    '#FCFEBBFF',
    '#FBFDB9FF',
    '#F9FCB7FF',
    '#F8FCB5FF',
    '#F6FBB3FF',
    '#F5FAB1FF',
    '#F3FAAFFF',
    '#F2F9ADFF',
    '#F0F9ABFF',
    '#EFF8A9FF',
    '#EDF7A7FF',
    '#ECF7A5FF',
    '#EAF6A3FF',
    '#E9F5A1FF',
    '#E7F59FFF',
    '#E6F49DFF',
    '#E4F49BFF',
    '#E3F399FF',
    '#E1F297FF',
    '#E0F295FF',
    '#DEF193FF',
    '#DDF091FF',
    '#DBF08FFF',
    '#DAEF8DFF',
    '#D9EF8BFF',
    '#D7EE89FF',
    '#D5ED88FF',
    '#D3EC87FF',
    '#D1EB85FF',
    '#CFEA84FF',
    '#CDE983FF',
    '#CBE881FF',
    '#C9E880FF',
    '#C7E77FFF',
    '#C5E67EFF',
    '#C3E57CFF',
    '#C1E47BFF',
    '#BFE37AFF',
    '#BDE278FF',
    '#BBE277FF',
    '#B9E176FF',
    '#B7E075FF',
    '#B5DF73FF',
    '#B3DE72FF',
    '#B1DD71FF',
    '#AFDC6FFF',
    '#ADDC6EFF',
    '#ABDB6DFF',
    '#A9DA6BFF',
    '#A7D96AFF',
    '#A4D869FF',
    '#A2D769FF',
    '#9FD669FF',
    '#9DD569FF',
    '#9AD468FF',
    '#98D268FF',
    '#95D168FF',
    '#93D067FF',
    '#90CF67FF',
    '#8ECE67FF',
    '#8BCD67FF',
    '#89CC66FF',
    '#86CB66FF',
    '#84CA66FF',
    '#81C966FF',
    '#7FC765FF',
    '#7CC665FF',
    '#7AC565FF',
    '#77C464FF',
    '#75C364FF',
    '#72C264FF',
    '#70C164FF',
    '#6DC063FF',
    '#6BBF63FF',
    '#68BE63FF',
    '#66BD63FF',
    '#63BB62FF',
    '#60BA61FF',
    '#5DB860FF',
    '#5AB760FF',
    '#57B55FFF',
    '#54B45EFF',
    '#51B25DFF',
    '#4EB15DFF',
    '#4BAF5CFF',
    '#48AE5BFF',
    '#45AD5AFF',
    '#42AB5AFF',
    '#3FAA59FF',
    '#3CA858FF',
    '#39A757FF',
    '#36A557FF',
    '#33A456FF',
    '#30A255FF',
    '#2DA154FF',
    '#2A9F54FF',
    '#279E53FF',
    '#249D52FF',
    '#219B51FF',
    '#1E9A51FF',
    '#1B9850FF',
    '#19974FFF',
    '#18954EFF',
    '#17934DFF',
    '#16914CFF',
    '#158F4BFF',
    '#148D4AFF',
    '#138B49FF',
    '#128948FF',
    '#118847FF',
    '#108646FF',
    '#0F8445FF',
    '#0E8244FF',
    '#0D8043FF',
    '#0C7E42FF',
    '#0B7C41FF',
    '#0A7A40FF',
    '#09783FFF',
    '#08773EFF',
    '#07753DFF',
    '#06733CFF',
    '#05713BFF',
    '#046F3AFF',
    '#036D39FF',
    '#026B38FF',
    '#016937FF',
    '#006837FF',
  ],
  rdylbu: [
    '#A50026FF',
    '#A60126FF',
    '#A80326FF',
    '#AA0526FF',
    '#AC0726FF',
    '#AE0926FF',
    '#B00B26FF',
    '#B20D26FF',
    '#B40F26FF',
    '#B61026FF',
    '#B81226FF',
    '#BA1426FF',
    '#BC1626FF',
    '#BE1826FF',
    '#C01A26FF',
    '#C21C26FF',
    '#C41E26FF',
    '#C62026FF',
    '#C82126FF',
    '#CA2326FF',
    '#CC2526FF',
    '#CE2726FF',
    '#D02926FF',
    '#D22B26FF',
    '#D42D26FF',
    '#D62F26FF',
    '#D73127FF',
    '#D83328FF',
    '#D93529FF',
    '#DA382AFF',
    '#DC3A2BFF',
    '#DD3D2DFF',
    '#DE3F2EFF',
    '#DF412FFF',
    '#E04430FF',
    '#E14631FF',
    '#E24932FF',
    '#E44B33FF',
    '#E54D34FF',
    '#E65035FF',
    '#E75236FF',
    '#E85538FF',
    '#E95739FF',
    '#EA593AFF',
    '#EC5C3BFF',
    '#ED5E3CFF',
    '#EE613DFF',
    '#EF633EFF',
    '#F0653FFF',
    '#F16840FF',
    '#F26A41FF',
    '#F46D43FF',
    '#F46F44FF',
    '#F47245FF',
    '#F57446FF',
    '#F57747FF',
    '#F57948FF',
    '#F67C4AFF',
    '#F67E4BFF',
    '#F6814CFF',
    '#F7834DFF',
    '#F7864EFF',
    '#F7894FFF',
    '#F88B51FF',
    '#F88E52FF',
    '#F89053FF',
    '#F99354FF',
    '#F99555FF',
    '#FA9856FF',
    '#FA9A58FF',
    '#FA9D59FF',
    '#FB9F5AFF',
    '#FBA25BFF',
    '#FBA55CFF',
    '#FCA75EFF',
    '#FCAA5FFF',
    '#FCAC60FF',
    '#FDAE61FF',
    '#FDB063FF',
    '#FDB265FF',
    '#FDB467FF',
    '#FDB669FF',
    '#FDB86BFF',
    '#FDBA6CFF',
    '#FDBC6EFF',
    '#FDBE70FF',
    '#FDC072FF',
    '#FDC274FF',
    '#FDC476FF',
    '#FDC678FF',
    '#FDC879FF',
    '#FDCA7BFF',
    '#FDCC7DFF',
    '#FDCE7FFF',
    '#FDD081FF',
    '#FDD283FF',
    '#FDD484FF',
    '#FDD686FF',
    '#FDD888FF',
    '#FDDA8AFF',
    '#FDDC8CFF',
    '#FDDE8EFF',
    '#FEE090FF',
    '#FEE191FF',
    '#FEE293FF',
    '#FEE395FF',
    '#FEE497FF',
    '#FEE699FF',
    '#FEE79BFF',
    '#FEE89CFF',
    '#FEE99EFF',
    '#FEEAA0FF',
    '#FEECA2FF',
    '#FEEDA4FF',
    '#FEEEA6FF',
    '#FEEFA7FF',
    '#FEF1A9FF',
    '#FEF2ABFF',
    '#FEF3ADFF',
    '#FEF4AFFF',
    '#FEF5B1FF',
    '#FEF7B3FF',
    '#FEF8B4FF',
    '#FEF9B6FF',
    '#FEFAB8FF',
    '#FEFBBAFF',
    '#FEFDBCFF',
    '#FEFEBEFF',
    '#FEFEC0FF',
    '#FDFEC2FF',
    '#FBFDC4FF',
    '#FAFDC6FF',
    '#F9FCC9FF',
    '#F8FCCBFF',
    '#F7FBCDFF',
    '#F5FBCFFF',
    '#F4FBD2FF',
    '#F3FAD4FF',
    '#F2FAD6FF',
    '#F1F9D8FF',
    '#EFF9DAFF',
    '#EEF8DDFF',
    '#EDF8DFFF',
    '#ECF7E1FF',
    '#EAF7E3FF',
    '#E9F6E6FF',
    '#E8F6E8FF',
    '#E7F5EAFF',
    '#E6F5ECFF',
    '#E4F4EFFF',
    '#E3F4F1FF',
    '#E2F3F3FF',
    '#E1F3F5FF',
    '#E0F3F7FF',
    '#DDF1F7FF',
    '#DBF0F6FF',
    '#D9EFF6FF',
    '#D7EEF5FF',
    '#D5EDF5FF',
    '#D3ECF4FF',
    '#D1EBF3FF',
    '#CFEAF3FF',
    '#CDE9F2FF',
    '#CBE8F2FF',
    '#C9E7F1FF',
    '#C7E6F0FF',
    '#C4E5F0FF',
    '#C2E4EFFF',
    '#C0E3EFFF',
    '#BEE2EEFF',
    '#BCE1EEFF',
    '#BAE0EDFF',
    '#B8DFECFF',
    '#B6DEECFF',
    '#B4DDEBFF',
    '#B2DCEBFF',
    '#B0DBEAFF',
    '#AEDAE9FF',
    '#ACD9E9FF',
    '#A9D8E8FF',
    '#A7D6E7FF',
    '#A5D4E6FF',
    '#A3D2E5FF',
    '#A1D1E4FF',
    '#9FCFE3FF',
    '#9CCDE2FF',
    '#9ACCE1FF',
    '#98CAE1FF',
    '#96C8E0FF',
    '#94C6DFFF',
    '#92C5DEFF',
    '#90C3DDFF',
    '#8DC1DCFF',
    '#8BBFDBFF',
    '#89BEDAFF',
    '#87BCD9FF',
    '#85BAD8FF',
    '#83B9D7FF',
    '#80B7D6FF',
    '#7EB5D5FF',
    '#7CB3D4FF',
    '#7AB2D3FF',
    '#78B0D2FF',
    '#76AED1FF',
    '#74ADD1FF',
    '#72AACFFF',
    '#70A8CEFF',
    '#6EA6CDFF',
    '#6CA4CCFF',
    '#6AA2CBFF',
    '#689FCAFF',
    '#679DC9FF',
    '#659BC7FF',
    '#6399C6FF',
    '#6197C5FF',
    '#5F94C4FF',
    '#5D92C3FF',
    '#5C90C2FF',
    '#5A8EC1FF',
    '#588CBFFF',
    '#5689BEFF',
    '#5487BDFF',
    '#5285BCFF',
    '#5083BBFF',
    '#4F81BAFF',
    '#4D7EB9FF',
    '#4B7CB7FF',
    '#497AB6FF',
    '#4778B5FF',
    '#4576B4FF',
    '#4473B3FF',
    '#4371B2FF',
    '#436EB0FF',
    '#426CAFFF',
    '#4169AEFF',
    '#4067ADFF',
    '#3F64ACFF',
    '#3F62AAFF',
    '#3E60A9FF',
    '#3D5DA8FF',
    '#3C5BA7FF',
    '#3B58A6FF',
    '#3B56A4FF',
    '#3A53A3FF',
    '#3951A2FF',
    '#384EA1FF',
    '#384C9FFF',
    '#37499EFF',
    '#36479DFF',
    '#35449CFF',
    '#34429BFF',
    '#343F99FF',
    '#333D98FF',
    '#323A97FF',
    '#313896FF',
    '#313695FF',
  ],
  bone: [
    '#000000FF',
    '#000001FF',
    '#010102FF',
    '#020203FF',
    '#030304FF',
    '#040406FF',
    '#050507FF',
    '#060608FF',
    '#070609FF',
    '#07070AFF',
    '#08080CFF',
    '#09090DFF',
    '#0A0A0EFF',
    '#0B0B0FFF',
    '#0C0C11FF',
    '#0D0D12FF',
    '#0E0D13FF',
    '#0E0E14FF',
    '#0F0F15FF',
    '#101017FF',
    '#111118FF',
    '#121219FF',
    '#13131AFF',
    '#14141CFF',
    '#15141DFF',
    '#15151EFF',
    '#16161FFF',
    '#171720FF',
    '#181822FF',
    '#191923FF',
    '#1A1A24FF',
    '#1B1B25FF',
    '#1C1B26FF',
    '#1C1C28FF',
    '#1D1D29FF',
    '#1E1E2AFF',
    '#1F1F2BFF',
    '#20202DFF',
    '#21212EFF',
    '#22222FFF',
    '#232230FF',
    '#232331FF',
    '#242433FF',
    '#252534FF',
    '#262635FF',
    '#272736FF',
    '#282838FF',
    '#292939FF',
    '#2A293AFF',
    '#2A2A3BFF',
    '#2B2B3CFF',
    '#2C2C3EFF',
    '#2D2D3FFF',
    '#2E2E40FF',
    '#2F2F41FF',
    '#303042FF',
    '#313044FF',
    '#313145FF',
    '#323246FF',
    '#333347FF',
    '#343449FF',
    '#35354AFF',
    '#36364BFF',
    '#37374CFF',
    '#38374DFF',
    '#38384FFF',
    '#393950FF',
    '#3A3A51FF',
    '#3B3B52FF',
    '#3C3C54FF',
    '#3D3D55FF',
    '#3E3E56FF',
    '#3F3E57FF',
    '#3F3F58FF',
    '#40405AFF',
    '#41415BFF',
    '#42425CFF',
    '#43435DFF',
    '#44445EFF',
    '#454560FF',
    '#464561FF',
    '#464662FF',
    '#474763FF',
    '#484865FF',
    '#494966FF',
    '#4A4A67FF',
    '#4B4B68FF',
    '#4C4C69FF',
    '#4D4C6BFF',
    '#4D4D6CFF',
    '#4E4E6DFF',
    '#4F4F6EFF',
    '#505070FF',
    '#515171FF',
    '#525272FF',
    '#535372FF',
    '#545473FF',
    '#545674FF',
    '#555775FF',
    '#565876FF',
    '#575977FF',
    '#585A78FF',
    '#595C79FF',
    '#5A5D79FF',
    '#5B5E7AFF',
    '#5B5F7BFF',
    '#5C607CFF',
    '#5D627DFF',
    '#5E637EFF',
    '#5F647FFF',
    '#606580FF',
    '#616680FF',
    '#626881FF',
    '#626982FF',
    '#636A83FF',
    '#646B84FF',
    '#656D85FF',
    '#666E86FF',
    '#676F87FF',
    '#687087FF',
    '#697188FF',
    '#697389FF',
    '#6A748AFF',
    '#6B758BFF',
    '#6C768CFF',
    '#6D778DFF',
    '#6E798EFF',
    '#6F7A8EFF',
    '#707B8FFF',
    '#707C90FF',
    '#717D91FF',
    '#727F92FF',
    '#738093FF',
    '#748194FF',
    '#758295FF',
    '#768395FF',
    '#778596FF',
    '#778697FF',
    '#788798FF',
    '#798899FF',
    '#7A899AFF',
    '#7B8B9BFF',
    '#7C8C9CFF',
    '#7D8D9CFF',
    '#7E8E9DFF',
    '#7E8F9EFF',
    '#7F919FFF',
    '#8092A0FF',
    '#8193A1FF',
    '#8294A2FF',
    '#8395A3FF',
    '#8497A3FF',
    '#8598A4FF',
    '#8599A5FF',
    '#869AA6FF',
    '#879BA7FF',
    '#889DA8FF',
    '#899EA9FF',
    '#8A9FAAFF',
    '#8BA0AAFF',
    '#8CA1ABFF',
    '#8CA3ACFF',
    '#8DA4ADFF',
    '#8EA5AEFF',
    '#8FA6AFFF',
    '#90A7B0FF',
    '#91A9B1FF',
    '#92AAB1FF',
    '#93ABB2FF',
    '#93ACB3FF',
    '#94ADB4FF',
    '#95AFB5FF',
    '#96B0B6FF',
    '#97B1B7FF',
    '#98B2B8FF',
    '#99B3B8FF',
    '#9AB5B9FF',
    '#9AB6BAFF',
    '#9BB7BBFF',
    '#9CB8BCFF',
    '#9DBABDFF',
    '#9EBBBEFF',
    '#9FBCBFFF',
    '#A0BDBFFF',
    '#A1BEC0FF',
    '#A1C0C1FF',
    '#A2C1C2FF',
    '#A3C2C3FF',
    '#A4C3C4FF',
    '#A5C4C5FF',
    '#A6C6C6FF',
    '#A7C7C6FF',
    '#A8C7C7FF',
    '#AAC8C8FF',
    '#ABC9C9FF',
    '#ACCACAFF',
    '#AECBCBFF',
    '#AFCCCCFF',
    '#B1CDCDFF',
    '#B2CECDFF',
    '#B3CECEFF',
    '#B5CFCFFF',
    '#B6D0D0FF',
    '#B7D1D1FF',
    '#B9D2D2FF',
    '#BAD3D3FF',
    '#BCD4D4FF',
    '#BDD5D4FF',
    '#BED5D5FF',
    '#C0D6D6FF',
    '#C1D7D7FF',
    '#C2D8D8FF',
    '#C4D9D9FF',
    '#C5DADAFF',
    '#C6DBDBFF',
    '#C8DCDBFF',
    '#C9DCDCFF',
    '#CBDDDDFF',
    '#CCDEDEFF',
    '#CDDFDFFF',
    '#CFE0E0FF',
    '#D0E1E1FF',
    '#D1E2E2FF',
    '#D3E3E2FF',
    '#D4E3E3FF',
    '#D5E4E4FF',
    '#D7E5E5FF',
    '#D8E6E6FF',
    '#DAE7E7FF',
    '#DBE8E8FF',
    '#DCE9E9FF',
    '#DEEAE9FF',
    '#DFEAEAFF',
    '#E0EBEBFF',
    '#E2ECECFF',
    '#E3EDEDFF',
    '#E5EEEEFF',
    '#E6EFEFFF',
    '#E7F0F0FF',
    '#E9F1F0FF',
    '#EAF1F1FF',
    '#EBF2F2FF',
    '#EDF3F3FF',
    '#EEF4F4FF',
    '#EFF5F5FF',
    '#F1F6F6FF',
    '#F2F7F7FF',
    '#F4F8F7FF',
    '#F5F8F8FF',
    '#F6F9F9FF',
    '#F8FAFAFF',
    '#F9FBFBFF',
    '#FAFCFCFF',
    '#FCFDFDFF',
    '#FDFEFEFF',
    '#FFFFFFFF',
  ],
};

export default colorPalette;
