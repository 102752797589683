import type { IPlatformCharacteristicType } from './types';
import type { ISatelliteModules } from '../../satellite/types';

export const PLATFORM_UPDATE_CONFIG = 'PLATFORM_UPDATE_CONFIG';
export const UPDATE_MODULE_SELECTION = 'UPDATE_PLATFORM_MODULE_SELECTION';
export const LOAD_MODULE_SELECTION = 'LOAD_MODULE_SELECTION';
export const UPLOAD_PLATFORM_CONFIGURATION = 'UPLOAD_PLATFORM_CONFIGURATION';

export interface IUpdatePlatformAndCascadeConfigurationActionProps {
  name: IPlatformCharacteristicType;
  value: string;
}
export interface IUpdatePlatformAndCascadeConfigurationAction {
  type: typeof PLATFORM_UPDATE_CONFIG;
  payload: IUpdatePlatformAndCascadeConfigurationActionProps;
}

export interface IUpdateModuleSelection {
  type: typeof UPDATE_MODULE_SELECTION;
  moduleType: string;
  selectedNames: string[];
}

export interface ILoadModuleSelection {
  type: typeof LOAD_MODULE_SELECTION;
  moduleNames: ISatelliteModules;
}

export interface IUploadPlatformConfiguration {
  type: typeof UPLOAD_PLATFORM_CONFIGURATION;
  platformConfiguration: any;
}

export type IPlatformConfiguratorActionTypes =
  | IUpdatePlatformAndCascadeConfigurationAction
  | IUpdateModuleSelection
  | ILoadModuleSelection
  | IUploadPlatformConfiguration;
