import base64InputValidator from './base64InputValidator';

export default (value: any, params: any) => {
  let errorMessage;
  const { minLength, maxLength, meta, media } = params;

  if (minLength && value.length < minLength) {
    errorMessage = `Must be ${minLength} characters or more`;
  }
  if (maxLength && value.length > maxLength) {
    errorMessage = `Must be ${maxLength} characters or less`;
  }
  if ((media && media.binaryEncoding === 'base64') || meta === 'base64string') {
    errorMessage = base64InputValidator(value, params);
  }

  return errorMessage;
};
