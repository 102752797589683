import classNames from 'classnames';
import React, { useRef, useState } from 'react';
import type { IconSize } from '_atoms/Icon/Icon';
import IconButton from '_molecules/IconButton/IconButton';

interface IProps {
  onClick: React.MouseEventHandler<HTMLDivElement>;
  cardContent: JSX.Element | JSX.Element[];
  info: {
    text?: string;
    iconSize: IconSize;
  };
  showInfoOn: 'hover' | 'click';
  className?: string;
  absoluteInfoPosition?: true;
}

const InfoCard = (props: IProps) => {
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const containerRef = useRef<HTMLDivElement>(null);
  return (
    <div
      className={classNames(
        props.className,
        'flex flex-col mb-px p-2 cursor-pointer group',
        'hover:bg-item-hover hover:dark:bg-item-dark-hover hover:dark:text-item-dark-contrast',
        {
          relative: props.absoluteInfoPosition,
        }
      )}
      onClick={props.onClick}
    >
      <div className="flex gap-2 items-center" ref={containerRef}>
        <div>{props.cardContent}</div>

        <IconButton
          icon="Info"
          size={props.info.iconSize}
          onPress={() => {
            props.showInfoOn === 'click' && setIsExpanded(!isExpanded);
          }}
          onMouseEnter={() => {
            props.showInfoOn === 'hover' && setIsExpanded(true);
          }}
          onMouseLeave={() => {
            props.showInfoOn === 'hover' && setIsExpanded(false);
          }}
          stroke="#191919"
        />
      </div>
      {isExpanded && (
        <div
          className={classNames('mt-2', {
            'absolute color-item': props.absoluteInfoPosition,
          })}
          style={{
            top:
              props.absoluteInfoPosition && containerRef.current
                ? containerRef.current.clientHeight + 8
                : undefined,
            zIndex: 10001,
          }}
        >
          <p className="text-sm">{props.info.text}</p>
        </div>
      )}
    </div>
  );
};

export default InfoCard;
