import { Dialog } from '@blueprintjs/core';
import { isNil } from 'lodash';

import type { TAB_STATE } from 'constants/ops/gs_scheduling/constants';
import { GS_TAB_MAPPING } from 'constants/ops/gs_scheduling/constants';
import { useApiGroundStationService } from 'services/api/portal/groundStations/GroundStationProvider';
import type { IMission } from 'services/Missions';
import type { IPassesParamsAPI } from 'services/api/portal/groundStations/passAPI';

import GSSearchBar from './GSSearchBar';
import GSTabList from './GSTabList';
import GSSchedulingList from './GSSchedulingList';
import BookPass from './BookPass';
import ConfirmationOfBooking from './ConfirmationOfBooking';

import s from '../index.module.scss';
import type { Pass } from 'api/groundStation/types';
import type { Ephemeris } from 'api/ephemeris/types';

interface IProps {
  chosenStationIdList: number[];
  selectedTab: TAB_STATE;
  ephemerisView: Ephemeris;
  missions: IMission[];
  gsPasses: Pass[];
  isFetchingPasses: boolean;
  isFetchingPassSlots: boolean;
  getEphemerisById: (ephemerisId: number) => void;
  cancelGSBookedPass: (passId: number) => void;
  manualNewPassEvent: (passId: number) => void;
  getGSPassesByParams: (params: IPassesParamsAPI) => void;
  toggleMapVisibility: () => void;
  isGsMapVisible: boolean;
}

const BookingDialogBody = () => {
  const { passJustBooked } = useApiGroundStationService();

  if (!isNil(passJustBooked)) {
    return <ConfirmationOfBooking />;
  }

  return <BookPass />;
};

const GSSearchListContainer = (props: IProps) => {
  const {
    pickedDate,
    setPickedDate,
    refreshListInView,
    showBookModal,
    closeBookModal,
  } = useApiGroundStationService();

  const {
    chosenStationIdList,
    cancelGSBookedPass,
    manualNewPassEvent,
    selectedTab,
    isFetchingPassSlots,
  } = props;

  const handleSearchClick = () => {
    refreshListInView();
  };

  const renderSchedulingTable = () => {
    if (selectedTab === 'scheduling') {
      return <GSSchedulingList isFetching={isFetchingPassSlots} />;
    }

    const { isCancelable } = GS_TAB_MAPPING[selectedTab];

    return (
      <GSTabList
        {...props}
        pickedDate={pickedDate}
        cancelGSBookedPass={isCancelable ? cancelGSBookedPass : undefined}
        manualNewPassEvent={isCancelable ? manualNewPassEvent : undefined}
      />
    );
  };

  return (
    <>
      <div
        className={s.searchListContainer}
        style={{ height: props.isGsMapVisible ? '40%' : '100%' }}
      >
        <GSSearchBar
          handleSearchClick={handleSearchClick}
          stationIdList={chosenStationIdList}
          pickedDate={pickedDate}
          setPickedDate={setPickedDate}
          toggleMapVisibility={props.toggleMapVisibility}
          isGsMapVisible={props.isGsMapVisible}
        />
        {renderSchedulingTable()}
      </div>
      <Dialog
        icon="info-sign"
        title="Book pass"
        isOpen={showBookModal}
        onClose={closeBookModal}
        style={{ minWidth: '80vw' }}
      >
        <BookingDialogBody />
      </Dialog>
    </>
  );
};

export default GSSearchListContainer;
