export type SchedulingLayoutProps = {
  left: JSX.Element;
  right: JSX.Element;
};

export const SchedulingLayout = ({ left, right }: SchedulingLayoutProps) => {
  return (
    <div
      style={{
        backgroundColor: 'rgb(230, 225, 220)',
        width: '100%',
        height: 'calc(100vh - 50px)',
        maxHeight: 'calc(100vh - 50px)',
        display: 'grid',
        gridTemplateColumns: '2fr 3fr',
        padding: '0 0 20px 50px',
      }}
    >
      <div
        style={{
          backgroundColor: 'white',
          width: '100%',
          height: 'calc(100vh - 50px)',
          maxHeight: 'calc(100vh - 50px)',
        }}
      >
        {left}
      </div>
      <div
        style={{
          width: '100%',
          height: 'calc(100vh - 50px)',
          maxHeight: 'calc(100vh - 50px)',
        }}
      >
        {right}
      </div>
    </div>
  );
};
