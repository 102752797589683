import type { Dispatch, ReactElement } from 'react';
import React from 'react';
import type { WithTranslation } from 'react-i18next';
import { withTranslation } from 'react-i18next';
import type {
  IGeometryObject,
  IGeometryObjectState,
} from '../../constants/msd/geometry/interfaces';
import { Button } from '@blueprintjs/core';
import type { IPopUp } from '../../constants/popUp/actionTypes';
import GeometryForm from '../popUp/geometryForm/GeometryForm';
import {
  GEOMETRY_TYPE,
  geometryOptions,
} from '../../constants/msd/geometry/constants';
import { orbitInitialValues } from '../../constants/msd/geometry/satelliteOrbits/constants';
import GeometryObject from './GeometryObject';
import type { InjectedFormProps } from 'redux-form';
import { SubmissionError } from 'redux-form';
import { geometryNormalize } from '../../utils/geometry/geometryNormalize';
import { generateName } from '../../utils/common/generateName';
import { walkerConstellationInitialValues } from '../../constants/msd/geometry/walkerConstellation/constants';

interface IGeometryProps extends WithTranslation {
  geometry: IGeometryObjectState;
  setPopUp: Function;
  astrum: string;

  addGeometry: Function;
  updateGeometry: Function;
  removeGeometry: Function;
}

interface IGeometryState {}

class Geometry extends React.Component<IGeometryProps, IGeometryState> {
  public state: IGeometryState = {};

  public componentWillUnmount(): void {}

  public componentDidUpdate(): void {}

  private addGeometry = (): void => {
    const { setPopUp, addGeometry, geometry, astrum } = this.props;

    const popUp: IPopUp = {
      visible: true,
      classNames: ['centered'],
      component: GeometryForm,
      data: {
        ...orbitInitialValues(astrum),
        ...walkerConstellationInitialValues,
        name: generateName({ list: geometry }, 'Geometry'),
        type: GEOMETRY_TYPE.KEPLERIAN,
        force: true,
      },
      otherProps: {
        options: [
          geometryOptions.KEPLERIAN,
          geometryOptions.SYNCHRONOUS,
          geometryOptions.STATIONARY,
          geometryOptions.POLAR,
          geometryOptions.TLE,
          //TODO hide WALKER_CONSTELLATION
          geometryOptions.WALKER_CONSTELLATION,
        ],
        apsides: {},
        astrum: astrum,
      },
      functions: {
        onSubmit: (
          geometryObject: IGeometryObject,
          dispatch: Dispatch<Function>,
          props: InjectedFormProps<FormData>
        ): void => {
          if (geometry.find((g) => g.name === geometryObject.name)) {
            const errorMessage = 'name_not_unique';
            throw new SubmissionError({
              name: errorMessage,
              _error: errorMessage,
            });
          }
          addGeometry(geometryNormalize(geometryObject, props));
        },
        onClose: (): void => {},
      },
    };
    setPopUp(popUp);
  };

  public render(): ReactElement {
    const { geometry, t, astrum } = this.props;
    return (
      <div className="geometry-panel">
        <div className="white geometry-header">
          <h3 className="text">
            {t('module_msd.mission_geometry.orbit_plural')}
          </h3>
          <Button
            className="button button_common"
            onClick={this.addGeometry}
            intent={'none'}
            icon={'add'}
            key={'add'}
            text={'Add'}
          />
        </div>
        <div className="white geometry-body">
          {geometry.map((g) => (
            <GeometryObject
              key={g.id}
              geometryObject={g}
              geometry={geometry}
              setPopUp={this.props.setPopUp}
              updateGeometry={this.props.updateGeometry}
              removeGeometry={this.props.removeGeometry}
              astrum={astrum}
            />
          ))}
        </div>
      </div>
    );
  }
}

export default withTranslation()(Geometry);
