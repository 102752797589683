import * as Yup from 'yup';

const modeValidationSchema = Yup.object().shape({
  name: Yup.string().matches(
    /^[0-9a-zA-Z ]+$/,
    'Only letters, numbers, and spaces are valid'
  ),
  ['two-target-primary']: Yup.object().shape({
    x: Yup.number().typeError('Must be a number').required('Required'),
    y: Yup.number().typeError('Must be a number').required('Required'),
    z: Yup.number().typeError('Must be a number').required('Required'),
  }),
  ['two-target-secondary']: Yup.object().shape({
    x: Yup.number().typeError('Must be a number').required('Required'),
    y: Yup.number().typeError('Must be a number').required('Required'),
    z: Yup.number().typeError('Must be a number').required('Required'),
  }),
});

export default modeValidationSchema;
