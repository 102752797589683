import React from 'react';

import { OperateProvider } from 'services/Operate';
import { TransferProgressProvider } from 'services/storage/TransferProgressProvider';

import Operate from './index';
import { TerminalSessionProvider } from 'services/TerminalSessionProvider';

function RTI() {
  return (
    <OperateProvider>
      <TransferProgressProvider>
        <TerminalSessionProvider>
          <Operate />
        </TerminalSessionProvider>
      </TransferProgressProvider>
    </OperateProvider>
  );
}

export default RTI;
