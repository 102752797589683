import { createContext, useContext, useState, useCallback } from 'react';

import { useMission } from 'services/Missions';
import { filterTelemetries } from 'utils/telemetries';

import type { Telemetry } from 'api/telemetry/types';
import { useQuery } from 'api/useQuery';
import { getTelemetryDefinitions } from 'api/telemetry/service';

export type ITelemetryDefinitionsServiceOutput = ReturnType<
  typeof useTelemetryDefinitionsInner
>;

export const TelemetryDefinitionsContext =
  createContext<ITelemetryDefinitionsServiceOutput>(
    null as unknown as ITelemetryDefinitionsServiceOutput
  );

export const useTelemetryDefinitions = () =>
  useContext<ITelemetryDefinitionsServiceOutput>(TelemetryDefinitionsContext);

export const useTelemetryDefinitionsInner = (missionId: number | undefined) => {
  const [filteredDefList, setFilteredDefinions] = useState<Telemetry[]>();

  const getTelemetryDefinitionQuery = useQuery(getTelemetryDefinitions, {
    params: missionId ? { missionId } : undefined,
    initialData: [],
    skip: !missionId,
  });

  const handleFilterChange = useCallback(
    (newFilter: string) => {
      filterTelemetries(
        newFilter,
        getTelemetryDefinitionQuery.data,
        setFilteredDefinions
      );
    },
    [getTelemetryDefinitionQuery.data]
  );

  return {
    isFetching: getTelemetryDefinitionQuery.loading,
    telemetryDefinitions: filteredDefList ?? getTelemetryDefinitionQuery.data,
    handleFilterChange,
  };
};

export const TelemetryDefinitionsProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { currentMissionId } = useMission();
  const props = useTelemetryDefinitionsInner(currentMissionId);
  return (
    <TelemetryDefinitionsContext.Provider value={{ ...props }}>
      {children}
    </TelemetryDefinitionsContext.Provider>
  );
};
