import Spinner from 'opencosmos-ui/src/core/Spinner/Spinner';
import Icon from 'opencosmos-ui/src/icons/Icon';
import type { ReactNode } from 'react';
import { Link } from 'react-router-dom';
import { Navbar } from 'ui/Navbar';

export interface IDefaultResourceTypeLayoutProps {
  children?: ReactNode;
  loading: boolean;
  leftTitle?: JSX.Element;
  middleTitle?: JSX.Element;
}

const DefaultResourceTypeLayout = (props: IDefaultResourceTypeLayoutProps) => {
  const { children, loading, leftTitle, middleTitle } = props;

  return (
    <div className="bg-neutral dark:bg-neutral-dark w-full min-h-screen">
      <Navbar
        left={
          leftTitle ?? (
            <Link
              to={'/data'}
              style={{ lineHeight: '0px' }}
              className="flex gap-2 items-center"
            >
              <Icon icon="ChevronLeft" />
              <div>Back to main</div>
            </Link>
          )
        }
        showLogo={false}
        middle={middleTitle}
        bgClassName="mission-page-header"
        showThemesInUserMenu={false}
      />
      <div className="container max-w-3xl mx-auto mt-4">
        {loading ? <Spinner /> : children}
      </div>
    </div>
  );
};

export default DefaultResourceTypeLayout;
