import type { ReactElement } from 'react';
import React from 'react';
import type { ISummaryBulletList } from '../../constants/summary/constants';

export const SummaryBulletList = (props: ISummaryBulletList): ReactElement => {
  return (
    <ul className="summary-bullet-list">
      {props.list.map(
        (l, index): ReactElement => (
          <li key={index}>{l}</li>
        )
      )}
    </ul>
  );
};
