import type { IChartWrapper } from '../../declarations/chartDeclarations/IChartWrapper';
import { CSV_FILE_NAME } from '../../constants/fileNames';
import { downloadFile } from '../common/CommonUtils';

interface IDataSet {
  time: string;
  id: string;
  x: string;
  y: string;
}

export const downloadDataCSV = (chart: IChartWrapper): void => {
  const chartData = chart.getOption();

  const legend = chartData.legend[0].selected;
  const xIndex = chartData.custom.selectedX.index;
  const yIndex = chartData.custom.selectedY.index;
  const xName =
    xIndex === -1
      ? 'Time'
      : chartData.custom.initData.data.parameterNames[xIndex];
  const yName =
    yIndex === -1
      ? 'Time'
      : chartData.custom.initData.data.parameterNames[yIndex];
  let dataSets: IDataSet[] = [];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  chartData.series.forEach((s: echarts.EChartOption.SeriesLine | any): void => {
    if (!legend[s.name]) {
      return undefined;
    }
    const subData = s.data.map((d: { value: string[] }): IDataSet => {
      const values = d.value;
      return {
        time: values.length === 2 ? values[0] : values[2],
        id: s.name,
        x: values[0],
        y: values[1],
      };
    });
    dataSets = [...dataSets, ...subData];
  });
  dataSets = dataSets.sort((a, b): number => {
    return new Date(a.time).getTime() - new Date(b.time).getTime();
  });

  const headerString = `Date;Satellites ID;${xName};${yName}\n`;
  let bodyString = '';
  dataSets.forEach(
    (d): string => (bodyString += `${d.time};${d.id};${d.x};${d.y}\n`)
  );
  downloadFile(headerString + bodyString, CSV_FILE_NAME, 'application/csv');
};
