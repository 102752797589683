import React from 'react';

interface ITagProps {
  /**
   * String representing tag content
   */
  text: string;
  /**
   * Tag background color. Accepts a valid CSS color format (hex, rgb, rgba)
   */
  color?: string;
}

const Tag = (props: ITagProps) => {
  return (
    <div className="flex justify-center w-fit p-0 items-center">
      <div
        className="box-border h-[14px] w-[14px] rotate-45 relative m-0 p-0 z-10 left-[7px] border-[1px] border-neutral-900 shadow-sm shadow-neutral-900"
        style={{ backgroundColor: props.color ? props.color : '#E79531' }}
      />
      <span
        className="text-[10px] box-border pr-1 pl-1 h-5 text-neutral-900 text-center z-20 border-[1px] border-l-0 border-l-transparent border-neutral-900 rounded-r-sm shadow-sm shadow-neutral-900"
        style={{
          backgroundColor: props.color ? props.color : '#E79531',
          clipPath: 'inset(-5px -5px -5px 0px)',
        }}
      >
        {props.text}
      </span>
    </div>
  );
};

export default Tag;
