import type { ReactElement } from 'react';
import React from 'react';
import type { ISummaryCartesian2DPlot } from '../../constants/summary/constants';
import type { ECharts } from 'echarts';
import echarts from 'echarts';
import type { WithTranslation } from 'react-i18next';
import { withTranslation } from 'react-i18next';
import { getHash } from '../../utils/common/getHash';

interface ISummaryCartesian2DPlotProps
  extends ISummaryCartesian2DPlot,
    WithTranslation {}

interface ISummaryCartesian2DPlotState {
  chart: ECharts;
  id: string;
}

class SummaryCartesian2DPlot extends React.PureComponent<
  ISummaryCartesian2DPlotProps,
  ISummaryCartesian2DPlotState
> {
  public state: ISummaryCartesian2DPlotState = {
    chart: null,
    id: null,
  };

  public async componentDidMount(): Promise<void> {
    const { title, xAxis, series } = this.props;
    const id = `${title}_${getHash(JSON.stringify({ title, series, xAxis }))}`;
    const color = series.map((s): string => s.color);
    const data = series.map((s): number[][] => {
      return s.xPoints.map((x, index): number[] => [x, s.yPoints[index]]);
    });
    const chartSeries = series.map(
      (s, index): echarts.EChartOption.SeriesLine => {
        return {
          type: 'line',
          smooth: false,
          name: s.name,
          data: data[index],
          lineStyle: {
            width: 1.5,
          },
        };
      }
    );
    const legend = {
      icon: 'none',
      orient: 'vertical',
      align: 'left',
      top: 'middle',
      left: 'left',
      data: series.map(
        (
          s,
          index
        ): echarts.EChartOption.SeriesLine.DataObject & {
          textStyle: { color: string };
        } => {
          return {
            name: s.name,
            textStyle: {
              color: color[index],
            },
          };
        }
      ),
    };
    // @ts-ignore
    const chart = echarts.init(document.getElementById(id), 'default', {
      renderer: 'svg',
    });
    chart.setOption({
      animation: false,
      title: {
        text: title,
        textAlign: 'center',
        padding: 0,
        left: '50%',
      },
      xAxis: {
        type: 'value',
        name: xAxis,
        nameLocation: 'center',
        nameGap: 25,
      },
      yAxis: {
        type: 'value',
      },
      grid: {
        top: '10%',
        right: '10%',
        left: '20%',
      },
      legend: legend as any,
      color: color,
      series: chartSeries,
    });
    this.setState({ chart, id });
  }

  public componentDidUpdate(
    prevProps: Readonly<ISummaryCartesian2DPlotProps>,
    prevState: Readonly<ISummaryCartesian2DPlotState>
  ): void {
    if (this.state.id !== prevState.id) {
      const parentDiv = document.getElementById(this.state.id);
      const s = new XMLSerializer().serializeToString(
        parentDiv.firstElementChild.firstElementChild
      );
      const encodedData = window.btoa(s);
      const src = 'data:image/svg+xml;base64,' + encodedData;

      const img = document.createElement('img');
      img.setAttribute('src', src);
      parentDiv.firstElementChild.remove();
      parentDiv.appendChild(img);
    }
  }

  public render(): ReactElement {
    const { title, series, xAxis } = this.props;
    const id = this.state.id
      ? this.state.id
      : `${title}_${getHash(JSON.stringify({ title, series, xAxis }))}`;

    return <div className="summary-cartesian-2D-plot" id={id} />;
  }
}

export default withTranslation()(SummaryCartesian2DPlot);
