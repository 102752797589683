import Icon from '_atoms/Icon/Icon';
import Popover2 from '_atoms/Popover/Popover2';
import RangeSlider from '_molecules/RangeSlider/RangeSlider';
import type { Layer } from 'datacosmos/entities/layer';
import type { SingleBandSTACLayer } from 'datacosmos/entities/singleBandLayer';
import tilingApi from 'datacosmos/services/tilingApi';
import { range } from 'lodash';
import { useEffect, useState } from 'react';
import { Tooltip } from 'ui/Tooltip';
import { useLocalisation } from 'utils/hooks/useLocalisation';

type Props = {
  disabled: boolean;
  contrastSupportedLayers: SingleBandSTACLayer[];
  replaceLayer: (layer: Layer, newIndex?: number) => void;
};

const ContrastControl = (props: Props) => {
  const { translate } = useLocalisation();

  const channelCount =
    tilingApi.statistics && Object.keys(tilingApi.statistics).length;

  const [contrast, setContrast] = useState<number[]>([]);
  const [contrastBias, setContrastBias] = useState<number[]>([]);

  let rContrast = 0;
  let gContrast = 0;
  let bContrast = 0;

  let rBias = 0.5;
  let gBias = 0.5;
  let bBias = 0.5;

  if (
    channelCount === 1 &&
    props.contrastSupportedLayers[0]?.options.contrast &&
    typeof props.contrastSupportedLayers[0]?.options.contrast !== 'number' &&
    props.contrastSupportedLayers[0]?.options.contrastBias &&
    typeof props.contrastSupportedLayers[0]?.options.contrastBias !== 'number'
  ) {
    rContrast = props.contrastSupportedLayers[0].options.contrast.r;
    gContrast = props.contrastSupportedLayers[0].options.contrast.g;
    bContrast = props.contrastSupportedLayers[0].options.contrast.b;

    rBias = props.contrastSupportedLayers[0].options.contrastBias.r;
    gBias = props.contrastSupportedLayers[0].options.contrastBias.g;
    bBias = props.contrastSupportedLayers[0].options.contrastBias.b;
  }

  useEffect(() => {
    if (
      typeof props.contrastSupportedLayers[0]?.options.contrast === 'number' &&
      props.contrastSupportedLayers[0]?.options.contrast === 1
    ) {
      setContrast([0, 0, 0]);
    }

    if (
      typeof props.contrastSupportedLayers[0]?.options.contrastBias ===
        'number' &&
      props.contrastSupportedLayers[0]?.options.contrastBias === 0.5
    ) {
      setContrastBias([0.5, 0.5, 0.5]);
    }
  }, [props.contrastSupportedLayers]);

  useEffect(() => {
    setContrast([rContrast, gContrast, bContrast]);
    setContrastBias([rBias, gBias, bBias]);
  }, [channelCount, rContrast, gContrast, bContrast, rBias, gBias, bBias]);

  const getContrastLabelText = (i: number) => {
    if (!channelCount || (channelCount && channelCount === 1)) {
      return 'Contrast';
    }
    if (i === 0) return 'R contrast';
    if (i === 1) return 'G contrast';
    if (i === 2) return 'B contrast';
    return '';
  };

  const getBiasLabelText = (i: number) => {
    if (!channelCount || (channelCount && channelCount === 1)) {
      return 'Bias';
    }
    if (i === 0) return 'R bias';
    if (i === 1) return 'G bias';
    if (i === 2) return 'B bias';
    return '';
  };

  /**
   * getIndexBasedContrastAmount returns the contrast amount for a specific channel index
   * R = 0, G = 1, B = 2
   *
   * This function uses the directly passed sliderValue to update the contrast amount for the currently
   * dragged slider. The other contrast amounts are taken from the contrast state. This avoids previous state
   * values being used when clicking on a value in the slider.
   * @param i channel index
   * @param sliderValue contrast slider value
   * @returns up to date contrast amount
   */
  const getIndexBasedContrastAmount = (i: number, sliderValue: number[]) => {
    if (!channelCount || (channelCount && channelCount === 1)) {
      return sliderValue[0];
    }

    if (i === 0) {
      return {
        r: sliderValue[0],
        g: contrast[1],
        b: contrast[2],
      };
    }
    if (i === 1) {
      return {
        r: contrast[0],
        g: sliderValue[0],
        b: contrast[2],
      };
    }
    if (i === 2) {
      return {
        r: contrast[0],
        g: contrast[1],
        b: sliderValue[0],
      };
    }

    return 0;
  };

  /**
   * getIndexBasedContrastBiasAmount returns the contrast bias amount for a specific channel index
   * R = 0, G = 1, B = 2
   *
   * This function uses the directly passed sliderValue to update the contrast bias amount for the currently
   * dragged slider. The other contrast bias amounts are taken from the contrast state. This avoids previous state
   * values being used when clicking on a value in the slider.
   * @param i channel index
   * @param sliderValue contrast bias slider value
   * @returns up to date contrast bias amount
   */
  const getIndexBasedContrastBiasAmount = (
    i: number,
    sliderValue: number[]
  ) => {
    if (!channelCount || (channelCount && channelCount === 1)) {
      return sliderValue[0];
    }

    if (i === 0) {
      return {
        r: sliderValue[0],
        g: contrastBias[1],
        b: contrastBias[2],
      };
    }
    if (i === 1) {
      return {
        r: contrastBias[0],
        g: sliderValue[0],
        b: contrastBias[2],
      };
    }
    if (i === 2) {
      return {
        r: contrastBias[0],
        g: contrastBias[1],
        b: sliderValue[0],
      };
    }

    return 0;
  };

  return (
    <Popover2
      className="w-64 p-1"
      placement="bottom end"
      offset={0}
      disabled={props.disabled}
      isDismissable={true}
      popupContent={
        <div data-testid="brightness-popup">
          {channelCount ? (
            range(channelCount).map((i) => (
              <>
                <RangeSlider
                  key={i}
                  label={{
                    text: getContrastLabelText(i),
                    icon: (
                      <Icon icon="Contrast" className="fill-item-contrast" />
                    ),
                  }}
                  showScale={false}
                  showValuesAboveHandles
                  numberOfHandles={1}
                  minValue={-100}
                  step={0.5}
                  maxValue={100}
                  value={[contrast[i]]}
                  onChange={(val) => {
                    setContrast((prev) => {
                      const newArr = prev.slice();
                      newArr[i] = val[0];
                      return newArr;
                    });
                    props.contrastSupportedLayers[0].setSettingContrastOn();
                  }}
                  onChangeEnd={(val) => {
                    const contrastAmount = getIndexBasedContrastAmount(i, val);

                    props.replaceLayer(
                      props.contrastSupportedLayers[0].cloneWithOptions({
                        contrast: contrastAmount,
                      })
                    );
                  }}
                />
                <RangeSlider
                  key={i}
                  label={{
                    text: getBiasLabelText(i),
                    icon: (
                      <Icon icon="Contrast" className="fill-item-contrast" />
                    ),
                  }}
                  showScale={false}
                  showValuesAboveHandles
                  numberOfHandles={1}
                  minValue={0}
                  step={0.1}
                  maxValue={1}
                  value={[contrastBias[i]]}
                  onChange={(val) => {
                    setContrastBias((prev) => {
                      const newArr = prev.slice();
                      newArr[i] = val[0];
                      return newArr;
                    });
                    props.contrastSupportedLayers[0].setSettingContrastOn();
                  }}
                  onChangeEnd={(val) => {
                    const biasAmount = getIndexBasedContrastBiasAmount(i, val);
                    props.replaceLayer(
                      props.contrastSupportedLayers[0].cloneWithOptions({
                        contrastBias: biasAmount,
                      })
                    );
                  }}
                />
              </>
            ))
          ) : (
            <span>Loading...</span>
          )}
        </div>
      }
    >
      <Tooltip
        content={
          props.disabled
            ? `${translate('datacosmos.layers.contrast')}-${translate(
                'datacosmos.layers.layerOperationDisabled'
              )}`
            : translate('datacosmos.layers.contrast')
        }
      >
        <Icon
          icon="Contrast"
          size={24}
          title="layer contrast"
          className={
            props.disabled ? 'cursor-not-allowed opacity-50' : 'cursor-pointer'
          }
        />
      </Tooltip>
    </Popover2>
  );
};

export default ContrastControl;
