import type { AxiosRequestConfig, AxiosResponse } from 'axios';
import axios from 'axios';
import { SubmissionError } from 'redux-form';
import type { IFrame } from '@stomp/stompjs';
import { Client } from '@stomp/stompjs';
import { v1 as uuid } from 'uuid';
import SockJS from 'sockjs-client/dist/sockjs.min.js';
import type { IAPICompute, IApiServiceOptions } from 'constants/API/interfaces';
import { MESSAGE_TYPE } from 'services/api/msd/responseCodes';
import { BACKEND_BASE_URL_MSD, BACKEND_BASE_URL, MOCK_API } from 'env';

export const API_PREFIX = '/msd/v1';

const axiosConfig: AxiosRequestConfig = {
  baseURL: `${BACKEND_BASE_URL_MSD ?? BACKEND_BASE_URL}${API_PREFIX}`,
};

const axiosInst = axios.create(axiosConfig);

let buffer: any[] = [];
const BUFFER_LENGTH_THRESHOLD = 20;
const PROGRESS_THRESHOLD = 2000;

const optimizationTable = {
  post: (data: any): Promise<any> =>
    axiosInst.post('/compute', data).then((res): any => res.data),
};

const simulation = {
  start: (data: any): Promise<any> =>
    axiosInst.post('/simulation/start', data).then((res): any => res.data),
  cancel: (data: any): Promise<any> =>
    axiosInst.post('/simulation/cancel', data).then((res): any => res.data),
};

const EventAnalysisApi = {
  optimizationTable,
  simulation,
  socketConnect: (
    data: IApiServiceOptions,
    cb: Function,
    endpoint: string
  ): Client => {
    const socket = new Client({
      brokerURL: `${axiosConfig.baseURL}/ws/connect`,
      webSocketFactory: () => new SockJS(`${axiosConfig.baseURL}/ws/connect`),
      reconnectDelay: 0.1,
    });

    let lastPushTime: number | null = null;
    let first = true;

    function step(timestamp: number) {
      const progress = lastPushTime ? timestamp - lastPushTime : 0;
      if (!buffer || (progress > 5000 && !buffer.length)) {
        return;
      }
      const length = buffer.length;
      if (
        (length && first) ||
        length > BUFFER_LENGTH_THRESHOLD ||
        (progress > PROGRESS_THRESHOLD && buffer.length)
      ) {
        const arr = buffer.splice(0, buffer.length);

        cb(arr);
        lastPushTime = timestamp;
        first = false;
      }
    }

    let interval: number | null = null;

    socket.debug = (): void => {};
    socket.onConnect = async (): Promise<void> => {
      interval = window.setInterval(() => {
        step(performance.now());
      }, 1500);

      await socket.subscribe(`/topic/${data.id}`, (message): void => {
        const obj = JSON.parse(message.body);
        const BUFFERED_TYPES = [MESSAGE_TYPE.SIMULATION_STEP];
        if (BUFFERED_TYPES.includes(obj.type)) {
          buffer.push(obj);
        } else {
          cb(obj);
        }
      });

      await axiosInst
        .post(`${endpoint}/start`, data)
        .then((res): any => res.data);
    };

    socket.onDisconnect = async (receipt: IFrame) => {
      if (interval !== null) {
        clearInterval(interval);
      }
      step(performance.now());
      buffer = [];
      axiosInst.post(`${endpoint}/cancel`, data).then((res): any => res.data);
    };

    socket.activate();

    return socket;
  },
};

export const SocketApi = (
  data: IAPICompute,
  cb: Function,
  endpoint: string
) => {
  const serviceId = uuid();
  const serviceSpecificData = {
    ...data,
    id: serviceId,
  };

  const socket = EventAnalysisApi.socketConnect(
    serviceSpecificData,
    cb,
    endpoint
  );

  return socket;
};

if (MOCK_API) {
  import('../../../__mock__/optimizationMock')
    .then((mockModule) => {
      mockModule.startMockServer();
    })
    .catch((err) => {
      // eslint-disable-next-line no-console
      console.error("Can't start mock server", err);
    });
}

// apply interceptor on response
axiosInst.interceptors.response.use(
  (response): AxiosResponse<void> => response,
  (error): void => {
    if (error.response) {
      throw new SubmissionError(error.response.data.payload);
    }
  }
);
