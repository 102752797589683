import React from 'react';
import { Field } from 'formik';
import styles from './styles.module.scss';
import FieldWithErrorCallout from '../../../components/formik/FieldWithErrorCallout';
import { Button } from '@blueprintjs/core';

interface IProps {
  rowIndex: number;
  readOnly: boolean;
  removeHandler: () => void;
}

const CustomPayloadModeRow = ({
  rowIndex,
  readOnly,
  removeHandler,
}: IProps) => (
  <tr style={{ verticalAlign: 'top' }}>
    <td>
      <FieldWithErrorCallout
        type="text"
        name={`data.modes.${rowIndex}.id`}
        className={`bp4-input ${styles.modeNameCell} `}
        disabled={readOnly}
        noIcon
        width={200}
      />
    </td>
    <td>
      <Field
        type="text"
        name={`data.modes.${rowIndex}.dataGenerationMode.data.value`}
        className={`bp4-input ${styles.modeEntryCell}`}
        disabled={readOnly}
      />
    </td>
    <td>
      <Field
        type="text"
        name={`data.modes.${rowIndex}.dataConsumptionMode.data.value`}
        className={`bp4-input ${styles.modeEntryCell}`}
        disabled={readOnly}
      />
    </td>
    <td>
      <Field
        type="text"
        name={`data.modes.${rowIndex}.powerConsumptionMode.data.value`}
        className={`bp4-input ${styles.modeEntryCell}`}
        disabled={readOnly}
      />
    </td>
    <td>
      {!readOnly && <Button minimal icon="remove" onClick={removeHandler} />}
    </td>
  </tr>
);

export default CustomPayloadModeRow;
