import { useRef } from 'react';
import type { CalendarState, RangeCalendarState } from 'react-stately';
import type { CalendarDate } from '@internationalized/date';
import { useCalendarCell } from 'react-aria';
import classNames from 'classnames';
import { isSameDay, isSameMonth } from '@internationalized/date';
import type { CellItemsByDate } from '../Calendar/Calendar';

interface ICalendarCellProps {
  state: RangeCalendarState | CalendarState;
  date: CalendarDate;
  currentMonth: CalendarDate;
  isHighlighted: boolean;
  cellItemsByDate?: CellItemsByDate[];
}

const CalendarCell = ({
  state,
  date,
  currentMonth,
  isHighlighted,
  cellItemsByDate,
}: ICalendarCellProps) => {
  const ref = useRef<HTMLButtonElement>(null);
  const { buttonProps, cellProps, formattedDate, isSelected, isDisabled } =
    useCalendarCell({ date }, state, ref);

  let isSelectionStart = isSelected;
  let isSelectionEnd = isSelected;

  const rangeState = state as RangeCalendarState | null;
  if (rangeState?.highlightedRange) {
    isSelectionStart = isSameDay(date, rangeState.highlightedRange.start);
    isSelectionEnd = isSameDay(date, rangeState.highlightedRange.end);
  }

  const isOutsideMonth = !isSameMonth(currentMonth, date);

  const getCellItems = () => {
    return cellItemsByDate?.filter((item) => {
      const d = item.date.getDate();
      const m = item.date.getMonth() + 1;
      const y = item.date.getFullYear();
      return d === date.day && m === date.month && y === date.year;
    });
  };

  return (
    <td {...cellProps} data-testid="date">
      <button
        {...buttonProps}
        ref={ref}
        hidden={isOutsideMonth}
        disabled={isDisabled}
        className="outline-none w-full h-full"
      >
        <div
          className={classNames(
            'w-[26px] h-fit max-h-20 min-h-full min-w-full p-1 flex flex-col text-[12px]',
            'hover:text-accent-900 dark:hover:text-accent hover:bg-item-selected dark:hover:bg-item-dark-selected',
            {
              'border-2 border-accent-900 dark:border-item-dark-hover':
                (isSelectionStart || isSelectionEnd) &&
                isSelected &&
                !cellItemsByDate,
              'bg-item-selected dark:bg-item-dark-selected': isSelected,
              'text-accent-900 dark:text-item-dark-contrast': isHighlighted,
              'border-warning bg-warning text-item-contrast':
                state.isCellDisabled(date) && isSelected,
              'text-contrast-inactive': isDisabled,
              'items-center justify-center': !cellItemsByDate,
            }
          )}
        >
          {formattedDate}
          {getCellItems()?.map(
            (i) =>
              i.item && (
                <div
                  className="dark:text-item-dark-contrast text-item-contrast"
                  key={i.id}
                  onPointerDown={(e) => e.stopPropagation()}
                >
                  {i.item}
                </div>
              )
          )}
        </div>
      </button>
    </td>
  );
};

export default CalendarCell;
