import type { ReactNode, ReactElement } from 'react';
import React from 'react';

export interface IDefaultLayoutProps {
  className?: string;
  disable?: boolean;
  children?: ReactNode;
}

class BlockOverlay extends React.PureComponent<IDefaultLayoutProps, {}> {
  public render(): ReactElement {
    const { children, disable } = this.props;
    return (
      <>
        <div className={`block-overlay ${disable ? '' : 'hidden'}`} />
        {children}
      </>
    );
  }
}

export default BlockOverlay;
