import type { ReactElement } from 'react';
import { Component } from 'react';
import { H3, Pre, Callout } from '@blueprintjs/core';
import { DRAGGABLE, DRAGGABLE_HEADER } from 'constants/draggable/constants';
import { Vector3 } from '@babylonjs/core';
import type { FormikHelpers } from 'formik';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { connect } from 'react-redux';
import type { WithTranslation } from 'react-i18next';
import { ENTER } from '@blueprintjs/core/lib/esm/common/keys';
import { get } from 'lodash';
import { dragElement } from 'services/draggableService/dragElement';
import type { AppState } from 'reducers/rootReducer';
import type {
  IHydratedSatelliteModules,
  IOperationalModesByPayload,
} from 'constants/satellite/types';
import {
  addSatelliteOperationalMode,
  updateSatelliteOperationalMode,
} from 'actions/satellite/action';
import { selectCurrentPayloadNames } from 'selectors/moduleSelector';
import {
  selectOperationalModesByPayload,
  selectHydratedSatelliteModules,
  selectSatelliteModes,
} from 'selectors/satellite';
import {
  AOCS_DATA,
  PAYLOAD_DATA,
  OBDH_DATA,
  COMMS_DATA,
  EPS_DATA,
} from 'constants/moduleData/constants';
import {
  DEFAULT_SATELLITE_MODE,
  GROUND_STATION_PASS_MODE,
} from 'constants/satellite/constants';
import type { IPayload } from 'constants/moduleData/types';
import FieldWithErrorCallout from 'components/formik/FieldWithErrorCallout';
import SatelliteAttitude3DView from 'babylonjs/SatelliteAttitude3DView';
import AttitudeForm, {
  ATTITUDE_TYPES,
} from 'components/modesPanel/AttitudeForm';
import { AVAILABLE_POINTING_TARGETS } from 'components/modesPanel/TwoTargetAttitudeForm';
import modeValidationSchema from 'components/modesPanel/validation';
import styles from 'components/modesPanel/styles.module.scss';
import {
  TWO_TARGET_PRIMARY_TARGET_KEY,
  TWO_TARGET_SECONDARY_TARGET_KEY,
  ATTITUDE_LAW_TYPE_KEY,
} from 'components/modesPanel/constants';

interface ISatelliteFormProps {
  resetPopUp?: Function;
}

interface ISatelliteFormState {
  force: boolean;
}

interface IComponentProps extends ISatelliteFormProps, WithTranslation {}

type ComponentState = ISatelliteFormState;

export interface ITargetDirection {
  x: string;
  y: string;
  z: string;
}
// Hack - didn't add forcePayloads as a type because I'd have
// to change the structure of the data
export interface IFormValues {
  name: string;
  [ATTITUDE_LAW_TYPE_KEY]: string;
  [TWO_TARGET_PRIMARY_TARGET_KEY]: string;
  [TWO_TARGET_SECONDARY_TARGET_KEY]: string;
  ['two-target-primary']: ITargetDirection;
  ['two-target-secondary']: ITargetDirection;
  [moduleName: string]: any;
}

interface IProps extends IComponentProps {
  operationalModesByPayload: IOperationalModesByPayload;
  selectedPayloadNames: string[];
  addSatelliteOperationalMode: typeof addSatelliteOperationalMode;
  updateSatelliteOperationalMode: typeof updateSatelliteOperationalMode;
  initialValues: IFormValues;
  satelliteModules: IHydratedSatelliteModules;
  satelliteModeNames: string[];
}

// Not related to ModesPanel
class ModeForm extends Component<IProps, ComponentState> {
  public constructor(props: IProps) {
    super(props);
    this.state = {
      force: true,
    };
  }

  public componentDidMount(): void {
    dragElement(document.getElementById(DRAGGABLE));
  }

  public onCloseWrapper = (): void => {
    const { resetPopUp } = this.props;
    resetPopUp();
  };

  private getPayloadModeSelector = (
    payloadId: string,
    availableModes: string[]
  ) => {
    return (
      <div className="bp4-select bp4-fill disable-left-padding">
        <Field as="select" name={payloadId}>
          {availableModes.map((name) => (
            <option key={name} value={name}>
              {name}
            </option>
          ))}
        </Field>
      </div>
    );
  };

  private addEmptyAttitudeValues = (formValues: IFormValues) => {
    formValues[ATTITUDE_LAW_TYPE_KEY] = ATTITUDE_TYPES.TWO_TARGET;
    formValues[TWO_TARGET_PRIMARY_TARGET_KEY] = AVAILABLE_POINTING_TARGETS[1];
    formValues['two-target-primary'] = {
      x: '0',
      y: '0',
      z: '1',
    };
    formValues[TWO_TARGET_SECONDARY_TARGET_KEY] = AVAILABLE_POINTING_TARGETS[0];
    formValues['two-target-secondary'] = {
      x: '0',
      y: '1',
      z: '0',
    };
  };

  private getEmptyFormValues = (name: string, formValues: IFormValues) => {
    formValues.name = name;

    if (name === GROUND_STATION_PASS_MODE) formValues.forcePayloads = false;

    Object.entries(this.props.satelliteModules).map(
      ([moduleName, moduleData]) => {
        const typedModuledData = moduleData as IPayload[];

        // Excludes battery and uncompleted modules
        if (get(typedModuledData, '[0].data.modes')) {
          if (moduleName !== PAYLOAD_DATA) {
            formValues[typedModuledData[0].id] = Object.keys(
              typedModuledData[0].data.modes
            )[0];
          } else {
            typedModuledData.map(
              (typedModule) =>
                (formValues[typedModule.id] = Object.keys(
                  typedModule.data.modes
                )[0])
            );
          }
        }
      }
    );

    this.addEmptyAttitudeValues(formValues);

    return formValues;
  };

  public render(): ReactElement {
    const {
      operationalModesByPayload,
      addSatelliteOperationalMode,
      updateSatelliteOperationalMode,
      initialValues,
      t,
      satelliteModules,
    } = this.props;

    const editingMode = Boolean(this.props.initialValues.name);

    // Default mode logic
    const editingDefaultMode =
      this.props.initialValues.name === DEFAULT_SATELLITE_MODE;
    const editingGroundStationMode =
      this.props.initialValues.name === GROUND_STATION_PASS_MODE;
    const initialisingDefaultMode =
      !operationalModesByPayload[DEFAULT_SATELLITE_MODE];
    const initialisingGroundStationMode =
      !operationalModesByPayload[GROUND_STATION_PASS_MODE] &&
      !initialisingDefaultMode;

    const formInitialValues = { ...this.props.initialValues };
    if (initialisingDefaultMode) {
      this.getEmptyFormValues(DEFAULT_SATELLITE_MODE, formInitialValues);
    } else if (initialisingGroundStationMode) {
      this.getEmptyFormValues(GROUND_STATION_PASS_MODE, formInitialValues);
    } else if (!editingMode) {
      // if creating
      this.getEmptyFormValues('Custom Mode', formInitialValues);
    }

    const inDefaultMode = editingDefaultMode || initialisingDefaultMode;
    const inGroundStationMode =
      editingGroundStationMode || initialisingGroundStationMode;

    // Other
    const submitHandler = (
      values: IFormValues,
      { resetForm }: FormikHelpers<IFormValues>
    ) => {
      if (values[ATTITUDE_LAW_TYPE_KEY] === ATTITUDE_TYPES.TWO_TARGET) {
        const secondaryTargetChoice = values[TWO_TARGET_SECONDARY_TARGET_KEY];
        // If no secondary target chosen
        if (!secondaryTargetChoice) {
          const primaryTargetChoice = values[TWO_TARGET_PRIMARY_TARGET_KEY];
          const availableTargets = AVAILABLE_POINTING_TARGETS.filter(
            (target: string) => target !== primaryTargetChoice
          );
          values[TWO_TARGET_SECONDARY_TARGET_KEY] = availableTargets[0];
        }
      }

      editingMode
        ? updateSatelliteOperationalMode({
            ...values,
            oldName: initialValues.name,
          })
        : addSatelliteOperationalMode(values);

      if (initialisingDefaultMode) {
        resetForm({
          values: this.getEmptyFormValues(
            GROUND_STATION_PASS_MODE,
            formInitialValues
          ),
        });
      } else {
        this.onCloseWrapper();
      }
    };

    const validateHandler = (values: IFormValues) => {
      // @ts-expect-error
      const errors: IFormValues = {};

      if (
        values['two-target-primary'].x === '0' &&
        values['two-target-primary'].y === '0' &&
        values['two-target-primary'].z === '0'
      ) {
        errors[TWO_TARGET_PRIMARY_TARGET_KEY] = 'No target vector defined.';
      }

      if (
        values['two-target-secondary'].x === '0' &&
        values['two-target-secondary'].y === '0' &&
        values['two-target-secondary'].z === '0'
      ) {
        errors[TWO_TARGET_SECONDARY_TARGET_KEY] = 'No target vector defined.';
      }

      if (
        errors[TWO_TARGET_PRIMARY_TARGET_KEY] ||
        errors[TWO_TARGET_SECONDARY_TARGET_KEY]
      )
        return errors;

      // Secondary target errors
      if (
        values[TWO_TARGET_PRIMARY_TARGET_KEY] ===
        values[TWO_TARGET_SECONDARY_TARGET_KEY]
      ) {
        errors[TWO_TARGET_SECONDARY_TARGET_KEY] =
          "Secondary target can't be the same as primary.";
      } else if (
        values['two-target-primary'].x &&
        values['two-target-primary'].y &&
        values['two-target-primary'].z &&
        values['two-target-secondary'].x &&
        values['two-target-secondary'].y &&
        values['two-target-secondary'].z
      ) {
        try {
          const primaryVector = new Vector3(
            parseInt(values['two-target-primary'].x),
            parseInt(values['two-target-primary'].y),
            parseInt(values['two-target-primary'].z)
          );

          const secondaryVector = new Vector3(
            parseInt(values['two-target-secondary'].x),
            parseInt(values['two-target-secondary'].y),
            parseInt(values['two-target-secondary'].z)
          );

          const crossProduct = Vector3.Cross(primaryVector, secondaryVector);

          if (!(crossProduct.x || crossProduct.y || crossProduct.z))
            errors[TWO_TARGET_SECONDARY_TARGET_KEY] =
              'Target vectors cannot be linearly dependant.';
        } catch (e) {
          // This means there's probably a string in there, so just ignore it
          // It will be handled by schema validation
        }
      }
      return errors;
    };

    const eps = satelliteModules[EPS_DATA][0];
    const comms = satelliteModules[COMMS_DATA][0];
    const aocs = satelliteModules[AOCS_DATA][0];
    const obdh = satelliteModules[OBDH_DATA][0];
    const payloads = satelliteModules[PAYLOAD_DATA];

    const nameValidator = (value: string) => {
      let error;

      if (
        this.props.satelliteModeNames.includes(value) &&
        this.props.initialValues.name !== value
      ) {
        error = 'Name already used.';
      }

      return error;
    };

    return (
      <div className="bp4-dialog dialog-margin-set-out">
        <div id={DRAGGABLE_HEADER} className="bp4-dialog-header">
          <H3>{t('module_msd.satellite.modes.full')}</H3>
          {!(initialisingDefaultMode || initialisingGroundStationMode) && (
            <div
              role="button"
              tabIndex={0}
              className="bp4-dialog-close-button bp4-button bp4-minimal bp4-icon-cross"
              onClick={this.onCloseWrapper}
              onKeyDown={(ev) => ev.keyCode === ENTER && this.onCloseWrapper()}
            />
          )}
        </div>

        <Formik
          initialValues={formInitialValues}
          onSubmit={submitHandler}
          validate={validateHandler}
          validationSchema={modeValidationSchema}
        >
          {({
            isSubmitting,
            values,
          }: {
            isSubmitting: boolean;
            values: IFormValues;
          }) => (
            <Form>
              <div
                className={`bp4-dialog-body ${this.state.force ? 'hide' : ''}`}
              >
                <div className={styles.threeViewContainer}>
                  <div>
                    <div className={styles.titleRow}>
                      <div className={styles.labelColumn}>
                        <span>Name</span>
                      </div>

                      <div className={styles.valueColumn}>
                        <FieldWithErrorCallout
                          type="text"
                          name="name"
                          className="bp4-input"
                          disabled={inDefaultMode || inGroundStationMode}
                          validate={nameValidator}
                          fill
                          width={null}
                        />
                      </div>
                    </div>
                    {inDefaultMode && (
                      <Callout icon="globe-network" style={{ maxWidth: 450 }}>
                        This mode will be triggered when no events nor ground
                        station passes occur.
                      </Callout>
                    )}
                    {inGroundStationMode && (
                      <Callout icon="cell-tower" style={{ maxWidth: 450 }}>
                        This mode will be triggered when ground station passes
                        occur.
                      </Callout>
                    )}
                    <Pre>
                      <table
                        className={`bp4-html-table bp4-small bp4-fill walker-constellation ${styles.table}`}
                      >
                        <thead>
                          <tr>
                            <th className="Payload">Platform Module</th>
                            <th className="Mode">Operational Mode</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr key="eps">
                            <td className={styles.labelColumn}>
                              <span>EPS</span>
                            </td>
                            <td className={styles.valueColumn}>
                              {this.getPayloadModeSelector(
                                get(eps, 'id') || 'eps',
                                Object.keys(get(eps, 'data.modes') || [])
                              )}
                              <ErrorMessage
                                name={get(eps, 'id') || 'eps'}
                                component="div"
                              />
                            </td>
                          </tr>
                          <tr key="comms">
                            <td className={styles.labelColumn}>
                              <span>Comms</span>
                            </td>
                            <td className={styles.valueColumn}>
                              {this.getPayloadModeSelector(
                                get(comms, 'id') || 'comms',
                                Object.keys(get(comms, 'data.modes') || [])
                              )}
                              <ErrorMessage
                                name={get(comms, 'id') || 'comms'}
                                component="div"
                              />
                            </td>
                          </tr>
                          <tr key="aocs">
                            <td className={styles.labelColumn}>
                              <span>ADCS</span>
                            </td>
                            <td className={styles.valueColumn}>
                              {this.getPayloadModeSelector(
                                get(aocs, 'id') || 'aocs',
                                Object.keys(get(aocs, 'data.modes') || [])
                              )}
                              <ErrorMessage
                                name={get(aocs, 'id') || 'aocs'}
                                component="div"
                              />
                            </td>
                          </tr>
                          <tr key="obdh">
                            <td className={styles.labelColumn}>
                              <span>OBDH</span>
                            </td>
                            <td className={styles.valueColumn}>
                              {this.getPayloadModeSelector(
                                get(obdh, 'id') || 'obdh',
                                Object.keys(get(obdh, 'data.modes') || [])
                              )}
                              <ErrorMessage
                                name={get(obdh, 'id') || 'obdh'}
                                component="div"
                              />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </Pre>
                    <Pre>
                      <table
                        className={`bp4-html-table bp4-small bp4-fill walker-constellation ${styles.table}`}
                      >
                        <thead>
                          <tr>
                            <th className="Payload">Payload Module</th>
                            <th className="Mode">Operational Mode</th>
                          </tr>
                        </thead>
                        <tbody>
                          {payloads.map((payload) => {
                            return (
                              <tr key={payload.id}>
                                <td className={styles.labelColumn}>
                                  <span>{payload.id}</span>
                                </td>
                                <td className={styles.valueColumn}>
                                  {this.getPayloadModeSelector(
                                    payload.id,
                                    Object.keys(payload.data.modes)
                                  )}
                                  <ErrorMessage
                                    name={payload.id}
                                    component="div"
                                  />
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                      {/* TODO: Uncomment when back-end is ready {inGroundStationMode && (
                        <div className={styles.checkboxContainer}>
                          <Field type="checkbox" name="forcePayloads" />
                          <span>Force payload modes</span>
                        </div>
                      )} */}
                    </Pre>
                  </div>
                  <SatelliteAttitude3DView />
                  <AttitudeForm values={values} />
                </div>
              </div>
              <div className="bp4-dialog-footer">
                <div className="bp4-dialog-footer-actions">
                  <button
                    type="submit"
                    title="Submit"
                    className="bp4-button bp4-icon-saved"
                    disabled={isSubmitting}
                  >
                    Save
                  </button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    );
  }
}

const mapStateToProps = (state: AppState): any => ({
  operationalModesByPayload: selectOperationalModesByPayload(state),
  selectedPayloadNames: selectCurrentPayloadNames(state),
  satelliteModules: selectHydratedSatelliteModules(state),
  satelliteModeNames: selectSatelliteModes(state),
});

const mapDispatchToProps = {
  addSatelliteOperationalMode,
  updateSatelliteOperationalMode,
};

export default connect<IProps>(mapStateToProps, mapDispatchToProps)(ModeForm);
