import { SATELLITE_ID_LIST } from 'api/satellites/types';
import type { Sensor } from 'api/sensors/types';

export const SENSOR_LIST: Sensor[] = [
  {
    satellite: SATELLITE_ID_LIST.GEOSAT_2,
    sensorId: 'HiRAIS',
    description:
      'An optical payload with 0.75m pan-sharpened resolution, 4 bands and a 10bit sensor',
  },
  {
    satellite: SATELLITE_ID_LIST.MENUT,
    sensorId: 'MultiScape100 CIS',
    description: 'Medium-resolution optical multispectral - 7 available bands',
  },
  {
    satellite: SATELLITE_ID_LIST.MANTIS,
    sensorId: 'HRI',
    description: 'High-resolution optical multispectral - 4 available bands',
  },
  {
    satellite: SATELLITE_ID_LIST.MARGARET,
    sensorId: 'HRI',
    description: 'High-resolution optical multispectral - 4 available bands',
  },
  {
    satellite: SATELLITE_ID_LIST.SUPERVIEW_01,
    sensorId: 'Imager',
    description: 'GaoJing / SuperView Earth Observation Constellation Imager',
  },
  {
    satellite: SATELLITE_ID_LIST.SUPERVIEW_02,
    sensorId: 'Imager',
    description: 'GaoJing / SuperView Earth Observation Constellation Imager',
  },
  {
    satellite: SATELLITE_ID_LIST.SUPERVIEW_03,
    sensorId: 'Imager',
    description: 'GaoJing / SuperView Earth Observation Constellation Imager',
  },
  {
    satellite: SATELLITE_ID_LIST.SUPERVIEW_04,
    sensorId: 'Imager',
    description: 'GaoJing / SuperView Earth Observation Constellation Imager',
  },
  {
    satellite: SATELLITE_ID_LIST.COGNISAT_6,
    sensorId: 'HyperScape100',
    description:
      'Medium-resolution optical hyperspectral - 32 simultaneous spectral bands',
  },
  {
    satellite: SATELLITE_ID_LIST.COGNISAT_6_EM,
    sensorId: 'HyperScape100',
    description:
      'Medium-resolution optical hyperspectral - 32 simultaneous spectral bands',
  },
  {
    satellite: SATELLITE_ID_LIST.ALISIO,
    sensorId: 'DRAGO-2',
    description: 'Medium-resolution SWIR multispectral - 2 available bands',
  },
  {
    satellite: SATELLITE_ID_LIST.PLATERO,
    sensorId: 'MultiScape100 CIS',
    description: 'Medium-resolution optical multispectral - 7 available bands',
  },
  {
    satellite: SATELLITE_ID_LIST.PHISAT2,
    sensorId: 'MultiScape100 CIS',
    description: 'Medium-resolution optical multispectral - 7 available bands',
  },
];
