export type EPSG = {
  code: string;
  proj4String: string;
};

export const EPSG4326: EPSG = {
  code: 'EPSG:4326',
  proj4String: '+proj=longlat +ellps=WGS84 +datum=WGS84 +no_defs',
};

export const EPSG32630: EPSG = {
  code: 'EPSG:32630',
  proj4String: '+proj=utm +zone=30 +datum=WGS84 +units=m +no_defs +type=crs',
};

export const EPSG4236: EPSG = {
  code: 'Lat,Long - EPSG:4236',
  proj4String:
    '+proj=longlat +ellps=intl +towgs84=-637,-549,-203,0,0,0,0 +no_defs +type=crs',
};

export const EPSG4248: EPSG = {
  code: 'PSAD56 - EPSG:4248',
  proj4String:
    '+proj=longlat +ellps=intl +towgs84=-288,175,-376,0,0,0,0 +no_defs +type=crs',
};

export const EPSG32718: EPSG = {
  code: 'WGS 84 - UTM 18S - EPSG:32718',
  proj4String:
    '+proj=utm +zone=18 +south +datum=WGS84 +units=m +no_defs +type=crs',
};

export const EPSG32719: EPSG = {
  code: 'WGS 84 - UTM 19S - EPSG:32719',
  proj4String:
    '+proj=utm +zone=19 +south +datum=WGS84 +units=m +no_defs +type=crs',
};

export const coordinateFormats: EPSG[] = [
  EPSG4326,
  EPSG32630,
  EPSG4236,
  EPSG4248,
  EPSG32718,
  EPSG32719,
];
