import { getSingleImagePixels } from 'api/sampling/service';
import { LayerSourceType } from 'datacosmos/entities/layer';
import { useMapLayers } from 'datacosmos/stores/MapLayersProvider';
import { useCallback, useState } from 'react';
import { useLocalisation } from 'utils/hooks/useLocalisation';
import { generateMarkerLayer } from './helpers';

export const useSingleImagePixels = () => {
  const { addLayer, removeLayersBySourceType } = useMapLayers();
  const { translate } = useLocalisation();

  const [isToggled, setIsToggled] = useState<boolean>(false);

  const markClickedPoint = useCallback(
    (latLng: { lat: number; lng: number }, pixelData: string) => {
      removeLayersBySourceType(LayerSourceType.PIXEL_MODE_MARKER);
      const marker = generateMarkerLayer(latLng, pixelData, true);
      addLayer(marker);
    },
    [addLayer, removeLayersBySourceType]
  );

  const getClickedPointPixelData = useCallback(
    async (lat: number, lng: number, imgUrl: string | undefined) => {
      if (!imgUrl) {
        return;
      }

      markClickedPoint(
        { lat, lng },
        translate('datacosmos.layers.pixelOperations.tooltip.loading')
      );

      const pixelData = await getSingleImagePixels({
        params: {
          imageUrl: imgUrl,
          latitude: lat,
          longitude: lng,
        },
      });

      if (pixelData.errors.length > 0 || !pixelData.data) {
        markClickedPoint(
          { lat, lng },
          translate('datacosmos.layers.pixelOperations.tooltip.noData')
        );
        return;
      }

      markClickedPoint({ lat, lng }, pixelData.data.value.join(', '));
    },
    [markClickedPoint, translate]
  );

  const toggleSingleImagePixelMode = (toggle?: boolean) => {
    if (isToggled) {
      removeLayersBySourceType(LayerSourceType.PIXEL_MODE_MARKER);
    }
    setIsToggled(toggle !== undefined ? toggle : !isToggled);
  };

  return {
    getClickedPointPixelData,
    toggleSingleImagePixelMode,
    isToggled,
  };
};
