import type {
  IConstraints,
  IConstraintsActionTypes,
} from '../constants/constraintsTable/actionTypes';
import {
  CONSTRAINTS_REMOVE,
  CONSTRAINTS_UPDATE,
} from '../constants/constraintsTable/actionTypes';

export interface IConstraintsReduxState {
  constraints: IConstraints;
}

const initialState: IConstraintsReduxState = {
  constraints: null,
};

export default function constraintsReducer(
  state: IConstraintsReduxState = initialState,
  action: IConstraintsActionTypes
): IConstraintsReduxState {
  switch (action.type) {
    case CONSTRAINTS_UPDATE: {
      return {
        constraints: action.constraints,
      };
    }
    case CONSTRAINTS_REMOVE: {
      return initialState;
    }
    default: {
      return state;
    }
  }
}
