import classNames from 'classnames';
import React from 'react';
import type { IconName } from '_atoms/Icon/Icon';
import Icon from '_atoms/Icon/Icon';
import IconButton from '_molecules/IconButton/IconButton';
import { Tooltip } from 'ui/Tooltip';

type IProps = {
  title: React.ReactNode;
  middleElement?: React.ReactNode;
  rightIcon?: {
    iconName: IconName;
    onIconClick?: () => void;
    text?: string;
  };
  iconTooltipContent?: string;
  forLayers?: {
    isLayerGridView: boolean;
    setIsLayerGridView: (isGridView: boolean) => void;
  };
  onTitleClick?: React.MouseEventHandler<HTMLHeadingElement>;
};

const SidebarHeader = (props: IProps) => {
  return (
    <div
      className="flex justify-between pl-2 pr-2 items-center bg-header dark:bg-header-dark text-header-contrast dark:text-header-dark-contrast"
      style={{ height: '60px' }}
    >
      <h3
        className={classNames('font-normal', {
          'cursor-pointer': props.onTitleClick,
        })}
        onClick={props.onTitleClick && props.onTitleClick}
      >
        {props.title}
      </h3>

      {props.middleElement && (
        <div className="flex items-center">{props.middleElement}</div>
      )}

      {props.rightIcon && (
        <div className="flex items-center ">
          {props.forLayers && (
            <div className="flex gap-2 pr-4 border-r-2 mr-4 border-header-contrast dark:border-header-dark-contrast">
              <IconButton
                icon="Grid"
                size={24}
                active={props.forLayers.isLayerGridView}
                onPress={() => {
                  props.forLayers.setIsLayerGridView(true);
                }}
                inherit={false}
                className="stroke-header-contrast dark:stroke-header-dark-contrast"
              />
              <IconButton
                icon="List"
                size={24}
                active={!props.forLayers.isLayerGridView}
                onPress={() => {
                  props.forLayers.setIsLayerGridView(false);
                }}
                inherit={false}
                className="stroke-header-contrast dark:stroke-header-dark-contrast"
              />
            </div>
          )}
          {/* TODO: div's should not have onClick without a role */}
          <div
            onClick={() => props.rightIcon.onIconClick()}
            className="flex items-center justify-center cursor-pointer"
          >
            {props.rightIcon.text && (
              <span className="text-sm">{props.rightIcon.text}</span>
            )}
            <Tooltip
              content={props.iconTooltipContent ? props.iconTooltipContent : ''}
              position="left"
            >
              <Icon
                icon={props.rightIcon.iconName}
                className="stroke-header-contrast dark:stroke-header-dark-contrast"
                size={32}
                inherit={false}
              />
            </Tooltip>
          </div>
        </div>
      )}
    </div>
  );
};

export default SidebarHeader;
