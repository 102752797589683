import type { IMission } from 'services/Missions';
import type { IGeometryObjectState } from 'constants/msd/geometry/interfaces';
import type {
  IPIState,
  IPointOfInterest,
} from 'constants/pointsOfInterest/actionTypes';
import type {
  IRIState,
  IRegionOfInterest,
} from 'constants/regionsOfInterest/actionTypes';
import type { IGSState } from 'constants/groundStations/actionTypes';
import type { ASTRUMS } from 'constants/API/constant';
import type { IAPICompute } from 'constants/API/interfaces';
import { MESSAGE_TYPE } from 'services/api/msd/responseCodes';
import { getConstellation, getEvents } from './computeGeometry';
import type {
  IHydratedSatelliteModules,
  ISatelliteModeConfiguration,
} from 'constants/satellite/types';
import type { IPayload } from 'constants/moduleData/types';
import type { IPlatformCharacteristics } from 'constants/ui/platformConfigurator/types';
import {
  DEFAULT_SATELLITE_MODE,
  GROUND_STATION_PASS_MODE,
} from 'constants/satellite/constants';
import {
  TWO_TARGET_PRIMARY_TARGET_KEY,
  TWO_TARGET_SECONDARY_TARGET_KEY,
  ATTITUDE_LAW_TYPE_KEY,
} from 'components/modesPanel/constants';
import type { Dictionary } from 'lodash';
import { groupBy } from 'lodash';
import { msd } from 'constants/mixpanelAnalytics';
import { analyticsClient } from 'utils/hooks/analytics/useAnalytics';

interface ISatelliteModulesSimulation {
  platform: {
    batteryPack: IPayload;
    eps: IPayload;
    comms: IPayload;
    adcs: IPayload;
    obdh: IPayload;
  };
  payloads: IPayload[];
}

// This is not the orbit but body properties
interface ISatelliteGeometrySimulation {
  geometry: IPlatformCharacteristics;
}

type ISatelliteSimulation = ISatelliteModulesSimulation &
  ISatelliteGeometrySimulation;

interface IModeSimulation {
  events: {
    pois: string[];
    rois: string[];
  };
  eps: string;
  comms: string;
  adcs: string;
  obdh: string;
  payloads: {
    [name: string]: string;
  };
  attitudeLaw: {
    type: string;
    data: {
      primaryAxis: {
        x: number;
        y: number;
        z: number;
      };
      primaryTarget: string;
      secondaryAxis: {
        x: number;
        y: number;
        z: number;
      };
      secondaryTarget: string;
    };
  };
}

interface ICustomModes {
  [modeName: string]: IModeSimulation;
}

export interface ISimulationMode {
  data: {
    satellite: ISatelliteSimulation;
    operations: {
      defaultMode: IModeSimulation;
      commsMode: IModeSimulation;
      customModes: ICustomModes;
    };
  };
}

export type IAPIFinalCompute = IAPICompute & ISimulationMode;

export const transformModulesToSimulationModules = (
  satelliteModules: IHydratedSatelliteModules
): ISatelliteModulesSimulation => {
  return {
    platform: {
      batteryPack: satelliteModules.power.length && satelliteModules.power[0],
      eps: satelliteModules.eps.length && satelliteModules.eps[0],
      comms: satelliteModules.comms.length && satelliteModules.comms[0],
      adcs: satelliteModules.aocs.length && satelliteModules.aocs[0],
      obdh: satelliteModules.obdh.length && satelliteModules.obdh[0],
    },
    payloads: satelliteModules.payloads,
  };
};

export const transformDefaultModeToSimulationMode = (
  type: string,
  modes: ISatelliteModeConfiguration,
  modules: IHydratedSatelliteModules,
  pois: Dictionary<IPointOfInterest[]>,
  rois: Dictionary<IRegionOfInterest[]>
): IModeSimulation => {
  if (modules.aocs.length) {
    const epsName = modules.eps[0].id;
    const commsName = modules.comms[0].id;
    const aocsName = modules.aocs[0].id;
    const obdhName = modules.obdh[0].id;
    const payloadNames = modules.payloads.map((payload) => payload.id);
    const payloadModes: any = {};

    const satelliteMode = modes[type];

    payloadNames.forEach((name) => (payloadModes[name] = satelliteMode[name]));

    return {
      events: {
        pois: pois[type] ? pois[type].map((event) => event.name) : [],
        rois: rois[type] ? rois[type].map((event) => event.name) : [],
      },
      eps: satelliteMode[epsName],
      comms: satelliteMode[commsName],
      adcs: satelliteMode[aocsName],
      obdh: satelliteMode[obdhName],
      payloads: payloadModes,
      attitudeLaw: {
        type: satelliteMode[ATTITUDE_LAW_TYPE_KEY],
        data: {
          primaryAxis: {
            x: parseInt(satelliteMode['two-target-primary'].x),
            y: parseInt(satelliteMode['two-target-primary'].y),
            z: parseInt(satelliteMode['two-target-primary'].z),
          },

          primaryTarget: satelliteMode[TWO_TARGET_PRIMARY_TARGET_KEY],
          secondaryAxis: {
            x: parseInt(satelliteMode['two-target-secondary'].x),
            y: parseInt(satelliteMode['two-target-secondary'].y),
            z: parseInt(satelliteMode['two-target-secondary'].z),
          },
          secondaryTarget: satelliteMode[TWO_TARGET_SECONDARY_TARGET_KEY],
        },
      },
    };
  } else {
    console.error('No AOCS supplied.');
    return undefined;
  }
};

export const transformModesToSimulationModes = (
  modes: ISatelliteModeConfiguration,
  modules: IHydratedSatelliteModules,
  pois: Dictionary<IPointOfInterest[]>,
  rois: Dictionary<IRegionOfInterest[]>
) => {
  const customModes: ICustomModes = {};

  Object.entries(modes).forEach(([modeName]) => {
    if (
      modeName !== DEFAULT_SATELLITE_MODE &&
      modeName !== GROUND_STATION_PASS_MODE
    )
      customModes[modeName] = transformDefaultModeToSimulationMode(
        modeName,
        modes,
        modules,
        pois,
        rois
      );
  });

  return customModes;
};

export const computeFinal = ({
  mission,
  days,
  geometry,
  pointsOfInterest,
  regionsOfInterest,
  groundStations,
  astrum,
  satelliteModules,
  platformCharacteristics,
  satelliteOperationalModes,
}: {
  mission: IMission;
  days: number;
  geometry: IGeometryObjectState;
  pointsOfInterest: IPIState;
  regionsOfInterest: IRIState;
  groundStations: IGSState;
  astrum: ASTRUMS;
  satelliteModules: IHydratedSatelliteModules;
  platformCharacteristics: IPlatformCharacteristics;
  satelliteOperationalModes: ISatelliteModeConfiguration;
}): IAPIFinalCompute => {
  const date = mission.date;

  const satellite = transformModulesToSimulationModules(satelliteModules);

  const poisBySatelliteMode = groupBy(pointsOfInterest.list, 'satelliteMode');
  const roisBySatelliteMode = groupBy(regionsOfInterest.list, 'satelliteMode');

  const defaultMode = transformDefaultModeToSimulationMode(
    DEFAULT_SATELLITE_MODE,
    satelliteOperationalModes,
    satelliteModules,
    poisBySatelliteMode,
    roisBySatelliteMode
  );

  const commsMode = transformDefaultModeToSimulationMode(
    GROUND_STATION_PASS_MODE,
    satelliteOperationalModes,
    satelliteModules,
    poisBySatelliteMode,
    roisBySatelliteMode
  );

  const customModes = transformModesToSimulationModes(
    satelliteOperationalModes,
    satelliteModules,
    poisBySatelliteMode,
    roisBySatelliteMode
  );

  analyticsClient.sendInfo()({
    type: msd.MODES.SIMULATION.START,
    action: 'Simulation start',
    item: 'MSD modes',
    module: 'MSD',
    additionalParams: {
      'Default Mode': defaultMode,
      'Comms Mode': commsMode,
      'Custom Modes': customModes,
    },
  });

  return {
    type: MESSAGE_TYPE.COMPUTE_FINAL,
    data: {
      constellation: getConstellation(date, geometry, astrum),
      events: getEvents(date, {
        pointsOfInterest,
        regionsOfInterest,
        groundStations,
      }),
      satellite: {
        ...satellite,
        geometry: platformCharacteristics,
      },
      operations: {
        defaultMode,
        commsMode,
        customModes,
      },
      span: days * 86400,
    },
  };
};
