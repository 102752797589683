import { sendAnalytics } from 'api/analytics/service';
import type {
  TrackingParams,
  TrackingEvent,
  TrackingEventWithError,
} from './useAnalytics';
import mixpanelService from 'services/analytics/mixpanel';
/**
 * Interface defining functions for an implementation of an analytics client
 */
export type AnalyticsClient = {
  sendInfo: (
    params?: TrackingParams
  ) => ({ type, ...event }: TrackingEvent) => void;
  startTracking: (
    params?: TrackingParams
  ) => ({ type, ...event }: TrackingEvent) => void;
  stopTracking: (
    params?: TrackingParams
  ) => ({ type, ...event }: TrackingEvent) => void;
  sendError: (
    params?: TrackingParams
  ) => ({ type, ...event }: TrackingEventWithError) => void;
};

/**
 * Mixpanel client represents a specific implementation of a mixpanel tracking analytics
 * using a common AnalyticsClient interface/type
 */
export const mixpanelClient: AnalyticsClient = {
  sendError:
    () =>
    ({ type, ...event }: TrackingEventWithError) => {
      if (typeof window.mixpanel === 'undefined') {
        // eslint-disable-next-line no-console
        console.warn(
          'mixpanel analytics client not available in global scope, this is likely because the code is running in a test environment'
        );
        return;
      }
      mixpanelService.error(type, event.error);
    },
  startTracking:
    () =>
    ({ type, ...event }: TrackingEvent) => {
      if (typeof window.mixpanel === 'undefined') {
        // eslint-disable-next-line no-console
        console.warn(
          'mixpanel analytics client not available in global scope, this is likely because the code is running in a test environment'
        );
        return;
      }
      mixpanelService.track.start(type, event);
    },
  stopTracking:
    () =>
    ({ type, ...event }: TrackingEvent) => {
      if (typeof window.mixpanel === 'undefined') {
        // eslint-disable-next-line no-console
        console.warn(
          'mixpanel analytics client not available in global scope, this is likely because the code is running in a test environment'
        );
        return;
      }
      mixpanelService.track.stop(type, event);
    },
  sendInfo:
    () =>
    ({ type, ...event }: TrackingEvent) => {
      if (typeof window.mixpanel === 'undefined') {
        // eslint-disable-next-line no-console
        console.warn(
          'mixpanel analytics client not available in global scope, this is likely because the code is running in a test environment'
        );
        return;
      }
      mixpanelService.info(type, event);
    },
};

/**
 * Matomo client represents a specific implementation of a matomo tracking analytics
 * using a common AnalyticsClient interface/type
 */
export const matomoClient: AnalyticsClient = {
  sendError:
    (params) =>
    async ({
      type,
      download,
      dimensions,
      ...event
    }: TrackingEventWithError) => {
      if (!params) {
        return;
      }

      await sendAnalytics({
        params: {
          actionName: type,
          eventAction: event.action,
          eventCategory: event.item,
          eventName: type,
          hour: params?.hour,
          minute: params?.minute,
          resolution: params?.resolution,
          second: params?.second,
          url: params?.url,
          userId: params?.userId,
          userRole: params?.userRole,
          userName: params?.userName,
          visitorId: params?.visitorId,
          download: download,
          dimensions: dimensions,
        },
      });
    },
  startTracking:
    (params) =>
    async ({ type, download, dimensions, ...event }: TrackingEvent) => {
      if (!params) {
        return;
      }

      await sendAnalytics({
        params: {
          actionName: type,
          eventAction: event.action,
          eventCategory: event.item,
          eventName: type,
          hour: params?.hour,
          minute: params?.minute,
          resolution: params?.resolution,
          second: params?.second,
          url: params?.url,
          userId: params?.userId,
          userRole: params?.userRole,
          userName: params?.userName,
          visitorId: params?.visitorId,
          download: download,
          dimensions: dimensions,
        },
      });
    },
  stopTracking:
    (params) =>
    async ({ type, download, dimensions, ...event }: TrackingEvent) => {
      if (!params) {
        return;
      }

      await sendAnalytics({
        params: {
          actionName: type,
          eventAction: event.action,
          eventCategory: event.item,
          eventName: type,
          hour: params?.hour,
          minute: params?.minute,
          resolution: params?.resolution,
          second: params?.second,
          url: params?.url,
          userId: params?.userId,
          userRole: params?.userRole,
          userName: params?.userName,
          visitorId: params?.visitorId,
          download: download,
          dimensions: dimensions,
        },
      });
    },
  sendInfo:
    (params) =>
    async ({ type, download, dimensions, ...event }: TrackingEvent) => {
      if (!params) {
        return;
      }

      await sendAnalytics({
        params: {
          actionName: type,
          eventAction: event.action,
          eventCategory: event.item,
          eventName: type,
          hour: params?.hour,
          minute: params?.minute,
          resolution: params?.resolution,
          second: params?.second,
          url: params?.url,
          userId: params?.userId,
          userRole: params?.userRole,
          userName: params?.userName,
          visitorId: params?.visitorId,
          download: download,
          dimensions: dimensions,
        },
      });
    },
};
