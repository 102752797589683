import { useQuery } from 'api/useQuery';
import { getScenario } from 'api/scenarios/service';
import ResourceLayout from './ResourceLayout';
import ResourceHeader from './ResourceHeader';
import DefaultResourceTypeLayout from 'layouts/datacosmos/DefaultResourceTypesLayout';
import { useMission } from 'services/Missions';

interface Props {
  resourceId: string;
  resourceType: string;
}
const ProjectResources = (props: Props) => {
  const { data: projectData } = useQuery(getScenario, {
    initialData: undefined,
    params: { scenarioId: props.resourceId },
  });

  const { organisations } = useMission();

  const mappedOrganisation = organisations?.find(
    (org) =>
      org.id?.toString() ===
      projectData?.summaries?.['opencosmos:organisation_id']
  );

  return (
    <DefaultResourceTypeLayout
      loading={!projectData?.id}
      middleTitle={<div className="text-lg font-bold">Project</div>}
    >
      {projectData && (
        <>
          <ResourceHeader
            description={projectData.description}
            title={projectData?.title}
            icon="Image"
          />

          <div className="mt-1">
            <span className="font-bold">Organisation: </span>
            {mappedOrganisation?.name}
          </div>
          <div className="my-5 border-t-[1px] border-accent-700 dark:border-item-dark-hover" />
        </>
      )}
      <ResourceLayout
        resourceId={props.resourceId}
        resourceType={props.resourceType}
      />
    </DefaultResourceTypeLayout>
  );
};

export default ProjectResources;
