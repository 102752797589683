export const TLE_OBJECTS = `CALSPHERE 1             
1 00900U 64063C   20213.17480788  .00000202  00000-0  20710-3 0  9996
2 00900  90.1528  29.0889 0025123 286.2192 195.0030 13.73398677776456
CALSPHERE 2             
1 00902U 64063E   20212.95567939  .00000022  00000-0  20066-4 0  9992
2 00902  90.1623  31.7493 0017733 346.7682 180.3359 13.52685298565967
LCS 1                   
1 01361U 65034C   20212.76395242  .00000033  00000-0  32297-2 0  9995
2 01361  32.1365 235.7174 0005642 295.9799  64.0117  9.89297766997219
TEMPSAT 1               
1 01512U 65065E   20212.87755971  .00000032  00000-0  44729-4 0  9992
2 01512  89.8731 225.5890 0070568 156.8636 320.8393 13.33427963673879
CALSPHERE 4A            
1 01520U 65065H   20212.87240318  .00000033  00000-0  45718-4 0  9995
2 01520  90.0347 129.2771 0071968  40.6202  45.7576 13.35818996675990
OPS 5712 (P/L 160)      
1 02826U 67053A   20212.90483588  .00000337  00000-0  10477-3 0  9996
2 02826  69.9282  97.2739 0007216 246.6550 113.3824 14.49130376753237
LES-5                   
1 02866U 67066E   20212.61056079 -.00000061  00000-0  00000-0 0  9996
2 02866   2.7645 272.5567 0053295 357.0736  46.1333  1.09424989107539
SURCAL 159              
1 02872U 67053F   20212.68507160 -.00000028  00000-0  17995-4 0  9996
2 02872  69.9752 301.3757 0005765  13.0908 347.0347 13.98822861709821
OPS 5712 (P/L 153)      
1 02874U 67053H   20212.89902815 -.00000034  00000-0  13591-4 0  9992
2 02874  69.9740  35.6144 0004911 108.9089 251.2549 13.96351445707475
SURCAL 150B             
1 02909U 67053J   20212.93729620  .00000461  00000-0  10582-3 0  9996
2 02909  69.9481 118.6942 0006086 287.2688  72.7785 14.61812476763238
RIGIDSPHERE 2 (LCS 4)   
1 05398U 71067E   20213.14106119  .00000136  00000-0  41073-4 0  9996
2 05398  87.6253 212.1630 0065324 127.5217 233.1942 14.33899809566137
OSCAR 7 (AO-7)          
1 07530U 74089B   20212.88958031 -.00000039  00000-0  41204-4 0  9990
2 07530 101.8026 182.3934 0011807 281.5673 132.7959 12.53644397 91431
STARLETTE               
1 07646U 75010A   20212.74329901 -.00000132  00000-0  85412-5 0  9990
2 07646  49.8254 300.6070 0205685 169.1734 191.3685 13.82307849297477
LAGEOS 1                
1 08820U 76039A   20212.65185226 -.00000013  00000-0  00000-0 0  9990
2 08820 109.8160 163.7376 0044444  41.1196 116.5964  6.38664726776303
TIROS N                 
1 11060U 78096A   20212.86657134 -.00000022  00000-0  10668-4 0  9995
2 11060  98.7361 270.0867 0009811 322.1008  37.9476 14.18273245374286
NOAA 6                  
1 11416U 79057A   20212.85982892  .00000012  00000-0  20600-4 0  9994
2 11416  98.6571 211.7450 0011341 120.4415 239.7891 14.33586565143146
UOSAT 2 (UO-11)         
1 14781U 84021B   20212.60148664  .00000065  00000-0  13998-4 0  9993
2 14781  97.5896 222.4286 0009880  64.3056 295.9177 14.83180700938228
NOAA 9                  
1 15427U 84123A   20212.83621685 -.00000017  00000-0  14435-4 0  9993
2 15427  98.9440 167.3181 0014189 317.7079  94.3049 14.16102556839661
AJISAI (EGS)            
1 16908U 86061A   20212.88809039 -.00000073  00000-0  17574-3 0  9995
2 16908  50.0081 270.1350 0011419 115.1316  53.9803 12.44492264213149
TDRS 3                  
1 19548U 88091B   20212.87889078 -.00000315  00000-0  00000+0 0  9994
2 19548  14.1525 357.0325 0032925 322.0647 257.0622  1.00262825103832
COSMOS 1989 (ETALON 1)  
1 19751U 89001C   20212.82012465 -.00000029  00000-0  00000-0 0  9998
2 19751  64.1900 145.9798 0022748 211.1449  27.4246  2.13156266245595
COSMOS 2024 (ETALON 2)  
1 20026U 89039C   20212.84296639 -.00000047  00000-0  00000-0 0  9995
2 20026  65.4970  23.6604 0018194 220.5658 190.8997  2.13204308242728
FLTSATCOM 8 (USA 46)    
1 20253U 89077A   20212.66802873 -.00000095  00000-0  00000+0 0  9994
2 20253  13.0216   5.1723 0004049 101.0815 154.8842  1.00273352239074
LUSAT (LO-19)           
1 20442U 90005G   20212.80420739 -.00000017  00000-0  10391-4 0  9993
2 20442  98.6942 160.2060 0012429  32.2091 327.9849 14.32957513594765
HST                     
1 20580U 90037B   20212.78850059  .00000358  00000-0  10758-4 0  9994
2 20580  28.4705  33.0389 0002634 194.9341 184.9491 15.09410921462601
SKYNET 4C               
1 20776U 90079A   20212.05745059  .00000110  00000-0  00000+0 0  9990
2 20776  13.9584   5.5443 0002678 133.5477 223.2409  1.00278354109392
TDRS 5                  
1 21639U 91054B   20212.70948797  .00000067  00000-0  00000+0 0  9990
2 21639  14.3107  10.3270 0026841  17.3022   8.8060  1.00267336106173
LAGEOS 2                
1 22195U 92070B   20212.24501413 -.00000009  00000-0  00000+0 0  9993
2 22195  52.6384 184.1190 0137595 327.8942  83.9662  6.47294245656574
TDRS 6                  
1 22314U 93003B   20212.31207410 -.00000311  00000-0  00000-0 0  9998
2 22314  14.0454  13.4747 0008070  78.9297 282.2497  1.00281070100919
SCD 1                   
1 22490U 93009B   20212.89127486  .00000203  00000-0  56193-5 0  9994
2 22490  24.9693 124.7441 0043212 126.7566 297.1245 14.44580995450299
UFO 2 (USA 95)          
1 22787U 93056A   20213.05471003  .00000110  00000-0  00000+0 0  9998
2 22787  11.2944  15.0671 0004552 137.3255 205.1668  1.00274548 97129
STELLA                  
1 22824U 93061B   20212.86510559 -.00000034  00000-0  52988-5 0  9998
2 22824  98.9629 204.7814 0006170 351.7576  74.9930 14.27381073398432
EYESAT A (AO-27)        
1 22825U 93061C   20212.75122912 -.00000011  00000-0  13995-4 0  9998
2 22825  98.9238 216.2944 0007370 248.6364 111.4031 14.30089445400001
ITAMSAT (IO-26)         
1 22826U 93061D   20213.00729087 -.00000006  00000-0  15526-4 0  9990
2 22826  98.9180 216.4992 0008009 234.5621 125.4813 14.30425530400522
USA 99 (MILSTAR-1 1)    
1 22988U 94009A   20212.03468201 -.00000245  00000-0  00000+0 0  9993
2 22988  14.2171  50.5817 0001863  87.9515 101.6305  1.00120464 10102
ASTRA 1D                
1 23331U 94070A   20212.44548736 -.00000286  00000-0  00000+0 0  9996
2 23331   9.1631  41.9405 0003305  85.4974 268.4870  1.00273295 94631
USA 107 (DSP 17)        
1 23435U 94084A   20213.08621417 -.00000182  00000-0  00000+0 0  9990
2 23435  14.5589  11.5309 0003241  89.2846 142.8423  1.00269822 10169
RADIO ROSTO (RS-15)     
1 23439U 94085A   20212.84639697 -.00000036  00000-0  19735-3 0  9994
2 23439  64.8148 176.0761 0150366  33.8778 327.1642 11.27568982 53911
UFO 4 (USA 108)         
1 23467U 95003A   20212.62812069 -.00000040  00000-0  00000-0 0  9995
2 23467   9.8347  20.7128 0005950  86.4411 239.8777  1.00271008 93439
AMSC 1                  
1 23553U 95019A   20212.59586898 -.00000116  00000-0  00000-0 0  9996
2 23553  11.3031  30.8866 0006792  44.2541 341.5639  1.00272266 92642
TDRS 7                  
1 23613U 95035B   20212.84474809 -.00000216  00000-0  00000-0 0  9998
2 23613  14.1870   4.1580 0029077  36.7102 296.8729  1.00268318 91710
USA 113                 
1 23628U 95038A   20212.85220483 -.00000355  00000-0  00000+0 0  9998
2 23628  11.4747  29.7424 0001181  81.5609 247.9541  1.00268278 10079
USA 115 (MILSTAR-1 2)   
1 23712U 95060A   20213.01533302 -.00000036  00000-0  00000+0 0  9999
2 23712  13.2325  19.5877 0001614 103.5388  74.7998  1.00126130 10111
POLAR                   
1 23802U 96013A   20211.28364734  .00000261  00000-0  00000+0 0  9995
2 23802  78.7055 249.3062 6284556 281.9029  18.2766  1.29846578116932
INMARSAT 3-F1           
1 23839U 96020A   20213.01157374 -.00000228  00000-0  00000+0 0  9990
2 23839   6.1564  62.0875 0006849  61.1959 111.5039  0.99983573 89083
ASTRA 1F                
1 23842U 96021A   20212.70566115  .00000125  00000-0  00000+0 0  9992
2 23842   0.0648 302.5543 0005677 191.6572 112.8914  1.00271944 88793
MSAT M1                 
1 23846U 96022A   20212.59476307 -.00000105  00000-0  00000-0 0  9997
2 23846   9.3396  41.2860 0005475  60.1151 313.8953  1.00272147 88889
JAS-2 (FO-29)           
1 24278U 96046B   20212.79398074 -.00000025  00000-0  13369-4 0  9996
2 24278  98.5871 316.9588 0349256 262.6130  93.5202 13.53098666182742
INMARSAT 3-F2           
1 24307U 96053A   20212.80048657 -.00000187  00000-0  00000+0 0  9990
2 24307   4.9966  71.6546 0007823  32.1208 293.3321  1.00007098 87471
AMC-1 (GE-1)            
1 24315U 96054A   20212.57788428  .00000049  00000-0  00000-0 0  9998
2 24315   4.0301  78.4566 0003399  38.3245 269.0014  1.00271083 24785
INMARSAT 3-F3           
1 24674U 96070A   20212.80415049  .00000016  00000-0  00000+0 0  9999
2 24674   5.6907  65.9956 0005462  62.9395 287.5672  1.00274844 86431
AMC-2 (GE-2)            
1 24713U 97002A   20213.15690595 -.00000232  00000-0  00000+0 0  9990
2 24713   6.4537  60.1498 0006301  62.5111 158.2053  1.00272125 36797
INTELSAT 26 (IS-26)     
1 24732U 97007A   20212.88076390 -.00000014  00000-0  00000+0 0  9994
2 24732   9.0989  42.7277 0004184  46.0662 240.8773  1.00273200 85745
USA 130 (DSP 18)        
1 24737U 97008A   20212.80626230  .00000010  00000-0  00000+0 0  9991
2 24737  13.5895  19.4065 0000968 343.7952 243.8945  1.00265363 10076
GALAXY 25 (G-25)        
1 24812U 97026A   20212.77510093  .00000139  00000-0  00000+0 0  9992
2 24812   2.0229  90.1207 0002975  41.4341 129.2105  1.00271151 84834
NAVSTAR 43 (USA 132)    
1 24876U 97035A   20212.31681590  .00000042  00000-0  00000+0 0  9996
2 24876  55.4601 182.4807 0040878  58.4865 301.8886  2.00564851168676
INTELSAT 5 (IS-5)       
1 24916U 97046A   20212.57392640  .00000072  00000-0  00000-0 0  9990
2 24916   5.7946  65.3340 0003532  59.2857 253.6544  1.00274001 84089
FORTE                   
1 24920U 97047A   20212.60294518  .00000008  00000-0  27348-4 0  9992
2 24920  69.9533 176.9875 0024680 316.7193  43.1989 14.26381968192315
AMC-3 (GE-3)            
1 24936U 97050A   20212.44611425 -.00000276  00000-0  00000-0 0  9995
2 24936   2.9009  85.4308 0003328  49.5792 262.1126  1.00271640 30897
NSS-5                   
1 24957U 97053A   20212.66486118  .00000086  00000-0  00000-0 0  9992
2 24957   6.0513  63.5484 0003566  54.5739 120.4887  1.00274887 83645
USA 134                 
1 25019U 97065A   20212.59194444 -.00000076  00000-0  00000-0 0  9995
2 25019  10.2667  35.8015 0003148 345.8097  27.9535  1.00268614 11422
NAVSTAR 44 (USA 135)    
1 25030U 97067A   20212.43810860  .00000076  00000-0  00000+0 0  9998
2 25030  55.8824 245.8524 0020754 241.5280 115.1835  1.88438249166211
ASTRA 1G                
1 25071U 97076A   20212.70941229  .00000001  00000-0  00000+0 0  9995
2 25071   4.7095  72.9789 0003878  48.1246 146.2858  1.00271590 83005
ORBCOMM FM08            
1 25112U 97084A   20212.70803391  .00000011  00000-0  49092-4 0  9997
2 25112  45.0148 218.5454 0010872 270.6556  89.3047 14.39098369184403
ORBCOMM FM10            
1 25113U 97084B   20212.76148043  .00000040  00000-0  58465-4 0  9996
2 25113  45.0162 219.7521 0008278 252.7649 107.2294 14.39081244359423
ORBCOMM FM11            
1 25114U 97084C   20212.83571302  .00000027  00000-0  54347-4 0  9993
2 25114  45.0146 218.7218 0008964 252.1326 107.8545 14.39033436184416
ORBCOMM FM12            
1 25115U 97084D   20213.08373679  .00000030  00000-0  55331-4 0  9997
2 25115  45.0154 217.2643 0007991 255.9914 104.0047 14.39053308184488
ORBCOMM FM09            
1 25116U 97084E   20212.80811940  .00000027  00000-0  54359-4 0  9990
2 25116  45.0158 219.1203 0008666 242.2506 117.7464 14.39062788184411
ORBCOMM FM05            
1 25117U 97084F   20212.71356325  .00000012  00000-0  49792-4 0  9991
2 25117  45.0154 219.7908 0005102 270.0352  89.9912 14.39041284183488
ORBCOMM FM06            
1 25118U 97084G   20212.84447774  .00000032  00000-0  56106-4 0  9994
2 25118  45.0152 218.8569 0005156 272.7784 150.5837 14.39087319184422
ORBCOMM FM07            
1 25119U 97084H   20212.72318019  .00000029  00000-0  55132-4 0  9995
2 25119  45.0146 219.6932 0006367 259.1196 100.8937 14.39056108184409
INMARSAT 3-F5           
1 25153U 98006B   20212.33485689 -.00000306  00000-0  00000-0 0  9992
2 25153   4.9014  70.0318 0004856  54.8851 250.0763  1.00270010 82351
ORBCOMM FM04            
1 25159U 98007C   20213.13126592  .00000015  00000-0  56607-4 0  9997
2 25159 107.9973  93.8779 0042895 102.4956 258.0984 14.29822678169357
CELESTIS-02 & TAURUS R/B
1 25160U 98007D   20213.12104132 -.00000115  00000-0  63038-5 0  9993
2 25160 107.9667 325.5093 0063587 206.3288 327.3120 14.21544211165541
UFO 8 (USA 138)         
1 25258U 98016A   20212.80275471 -.00000041  00000-0  00000+0 0  9992
2 25258   8.7095  32.6085 0004184  76.8026 300.2157  1.00268068 82046
NOAA 15                 
1 25338U 98030A   20212.72728199  .00000069  00000-0  47251-4 0  9991
2 25338  98.7148 237.5532 0011302 132.9448 227.2682 14.25982286155147
TECHSAT 1B (GO-32)      
1 25397U 98043D   20213.08904940 -.00000034  00000-0  46194-5 0  9999
2 25397  98.7297 154.2594 0000591 356.5289   3.5886 14.23688117145981
ORBCOMM FM18            
1 25414U 98046B   20212.64473637 -.00000075  00000-0  23806-4 0  9996
2 25414  44.9958 301.3334 0002122 343.5328  16.5449 14.33017364148272
ORBCOMM FM19            
1 25415U 98046C   20212.63803563 -.00000038  00000-0  37268-4 0  9997
2 25415  44.9961 301.9125 0001679 307.6451 200.9895 14.32985453148440
ORBCOMM FM20            
1 25416U 98046D   20212.61871804 -.00000036  00000-0  37929-4 0  9991
2 25416  44.9959 302.2434 0002234 219.2887 140.7797 14.32968278148396
ORBCOMM FM15            
1 25418U 98046F   20212.54867024 -.00000001  00000-0  50435-4 0  9993
2 25418  44.9996 304.4779 0000983  31.9491 342.3253 14.33022831148433
ORBCOMM FM14            
1 25419U 98046G   20212.55524309  .00000023  00000-0  59097-4 0  9997
2 25419  44.9990 304.2047 0000666 267.0543 107.9459 14.33036126148257
ORBCOMM FM13            
1 25420U 98046H   20212.63280560  .00000006  00000-0  53398-4 0  9995
2 25420  44.9993 305.1665 0002352 359.1639   0.9203 14.32873946147325
ASTRA 2A                
1 25462U 98050A   20212.05718888  .00000130  00000-0  00000+0 0  9996
2 25462   1.7246  92.8003 0002611  53.4428 214.9355  1.00067603 80201
ORBCOMM FM21            
1 25475U 98053A   20212.76823130 -.00000067  00000-0  26672-4 0  9996
2 25475  45.0108  28.1232 0000794  81.9241 278.1695 14.32968136141351
ORBCOMM FM23            
1 25477U 98053C   20212.78579123 -.00000006  00000-0  48821-4 0  9993
2 25477  45.0109  29.5747 0000987  13.8988 346.1886 14.32831053141823
ORBCOMM FM27            
1 25481U 98053G   20212.80861210  .00000003  00000-0  52243-4 0  9991
2 25481  45.0083  28.7305 0001650 115.7600 244.3416 14.32935936141802
SCD 2                   
1 25504U 98060A   20212.76163441  .00000209  00000-0  80555-5 0  9997
2 25504  24.9966 335.8765 0017307 142.5672  17.7676 14.44097267149581
ISS (ZARYA)             
1 25544U 98067A   20213.16282241  .00051857  00000-0  93838-3 0  9994
2 25544  51.6433 130.8084 0000656 325.4979 181.1476 15.49147555238832
SWAS                    
1 25560U 98071A   20212.57671298  .00000048  00000-0  13079-4 0  9991
2 25560  69.8983 275.1479 0011062 274.5119  85.4773 14.93891382175462
GLOBALSTAR M023         
1 25621U 99004A   20212.90449625 -.00000113  00000-0 -51832-4 0  9994
2 25621  52.0119  79.8021 0010148 145.7991 226.9277 12.62388849990670
JCSAT-4A                
1 25630U 99006A   20212.44479830 -.00000327  00000-0  00000-0 0  9999
2 25630   4.6930  73.7526 0003661 159.3107  11.5793  1.00271419 29071
SKYNET 4E               
1 25639U 99009B   20213.07969868 -.00000050  00000-0  00000+0 0  9998
2 25639  10.8970  20.7844 0002411 109.7305 206.1415  1.00273161 78487
GLOBALSTAR M037         
1 25652U 99012D   20212.76039351 -.00000053  00000-0  28050-3 0  9999
2 25652  51.9975 211.5370 0001990 203.1864 327.9329 12.62331863986227
ASIASAT 3S              
1 25657U 99013A   20212.34376502 -.00000243  00000-0  00000+0 0  9993
2 25657   4.8809  72.2147 0003832  28.8454 118.6057  1.00269921 78289
LANDSAT 7               
1 25682U 99020A   20213.15390115 -.00000003  00000-0  91109-5 0  9995
2 25682  98.0795 274.5355 0001758  86.9920 273.1488 14.57178133132604
NIMIQ 1                 
1 25740U 99027A   20212.43800392 -.00000213  00000-0  00000-0 0  9998
2 25740   1.1959  93.9066 0005696  36.3072 249.4508  1.00271806 77512
DLR-TUBSAT              
1 25757U 99029B   20213.07250272 -.00000046  00000-0 -42445-7 0  9996
2 25757  98.4264 140.7070 0015591  57.6893 302.5810 14.51422961121827
CXO                     
1 25867U 99040B   20214.12235665  .00000312  00000-0  00000-0 0  9995
2 25867  61.6702 265.8130 7805615 227.6549   0.2843  0.37802212  9229
ABS-7                   
1 25894U 99046A   20212.94298397 -.00000378  00000-0  00000+0 0  9992
2 25894   1.0753  94.6140 0003346  30.0413 279.9488  1.00272395 75612
ABS-6                   
1 25924U 99053A   20212.82549375 -.00000135  00000-0  00000+0 0  9997
2 25924   0.0555 290.7148 0003033 172.4600 301.8929  1.00270100 76331
NAVSTAR 46 (USA 145)    
1 25933U 99055A   20212.14305531 -.00000081  00000-0  00000+0 0  9998
2 25933  52.3249  28.1680 0158343 115.8945  59.5490  2.00557955152481
TELSTAR 12 (ORION 2)    
1 25949U 99059A   20212.59356637 -.00000080  00000-0  00000-0 0  9991
2 25949   3.4092  83.5151 0005411  30.4160 299.2202  1.00272168 75990
AMC-4 (GE-4)            
1 25954U 99060A   20212.57523715  .00000074  00000-0  00000-0 0  9992
2 25954   0.0911 276.6256 0002855 207.3973 256.8024  1.00271412 22024
UFO 10 (USA 146)        
1 25967U 99063A   20212.87930943 -.00000202  00000-0  00000+0 0  9990
2 25967   7.9110  35.5163 0005906  77.1511 130.2167  1.00269897 75806
ORBCOMM FM30            
1 25980U 99065A   20212.79956432  .00000016  00000-0  56970-4 0  9993
2 25980  45.0315 203.8316 0003803 144.5941 215.5158 14.32896432 78575
ORBCOMM FM31            
1 25981U 99065B   20212.78762313  .00000078  00000-0  79472-4 0  9996
2 25981  45.0228 198.8123 0002665 204.7847 155.2872 14.32903690 78205
ORBCOMM FM32            
1 25982U 99065C   20212.69331197 -.00000112  00000-0  10543-4 0  9993
2 25982  45.0163 203.8233 0004877 184.7958 175.2842 14.32086308 79017
ORBCOMM FM33            
1 25983U 99065D   20212.85093323 -.00000022  00000-0  41591-4 0  9995
2 25983  45.0297 145.5958 0006590 143.3534 266.4971 14.35144093 79775
ORBCOMM FM36            
1 25984U 99065E   20213.06799207  .00000131  00000-0  98780-4 0  9996
2 25984  45.0366 205.8848 0002583 341.7596  18.3158 14.32881942 79130
ORBCOMM FM35            
1 25985U 99065F   20212.38418976 -.00000024  00000-0  42358-4 0  9994
2 25985  45.0393 210.4603 0003016 211.5161 148.5505 14.32940939 79051
XMM-NEWTON              
1 25989U 99066A   20213.42787589  .00000018  00000-0  00000+0 0  9999
2 25989  71.1086 319.8783 7058370  92.8606   0.4274  0.50132181 26600
DMSP 5D-3 F15 (USA 147) 
1 25991U 99067A   20212.87770231 -.00000111  00000-0 -33088-4 0  9990
2 25991  98.9726 184.5839 0009712 343.9952  48.3089 14.16477759 66578
TERRA                   
1 25994U 99068A   20213.18761544  .00000045  00000-0  19939-4 0  9999
2 25994  98.2114 286.8615 0001171  98.1291 262.0054 14.57111674 96687
CELESTIS-03 & TAURUS R/B
1 26034U 99070C   20212.82697461  .00001233  00000-0  79875-4 0  9999
2 26034  98.2481 149.0595 0011130 297.2873  62.7220 15.10231938117276
GALAXY 11 (G-11)        
1 26038U 99071A   20212.43427059 -.00000177  00000-0  00000-0 0  9992
2 26038   1.3156  95.6954 0000837 349.4632 286.5829  1.00270762 75540
USA 148                 
1 26052U 00001A   20212.63854977  .00000022  00000-0  00000-0 0  9996
2 26052   8.4753  46.3823 0003054  45.4396 266.8800  1.00272014 11428
GLOBALSTAR M063         
1 26081U 00008A   20212.77301397 -.00000123  00000-0 -59524-3 0  9995
2 26081  51.9952 125.5572 0004545 134.8844 255.8430 11.86124933946067
MTI                     
1 26102U 00014A   20212.64687779  .00003533  00000-0  52134-4 0  9990
2 26102  97.4979  96.9782 0009218  73.6664 286.5610 15.56077170131238
ASIASTAR                
1 26107U 00016A   20213.03467970 -.00000352  00000-0  00000+0 0  9997
2 26107   4.1082  76.8884 0004414  46.5260 303.1607  1.00273776 74622
IMAGE                   
1 26113U 00017A   20211.80377185 -.00000698  00000-0 -20131-1 0  9999
2 26113  94.8631 276.6278 7636315 177.6816 191.2943  1.68644525125289
USA 149 (DSP 20)        
1 26356U 00024A   20212.64384464  .00000082  00000-0  00000-0 0  9994
2 26356  11.5737  26.1283 0002060  22.8998 326.3366  1.00284838 11371
NAVSTAR 47 (USA 150)    
1 26360U 00025A   20212.58832830 -.00000066  00000-0  00000+0 0  9990
2 26360  53.5534 107.3760 0052899 155.9603  19.4521  2.00564861148186
EUTELSAT 80A            
1 26369U 00028A   20212.06041661  .00000106  00000-0  00000+0 0  9993
2 26369   3.4864  80.8623 0005071  45.2165 252.0339  1.00269010 73931
TDRS 8                  
1 26388U 00034A   20212.54334708 -.00000252  00000-0  00000-0 0  9995
2 26388   9.2952  47.2466 0015213 331.3618 214.9725  1.00271411 73649
NAVSTAR 48 (USA 151)    
1 26407U 00040A   20212.40866299 -.00000053  00000-0  00000+0 0  9992
2 26407  55.9623 300.4788 0186356 279.4913  88.3371  2.00564794146867
CLUSTER II-FM7 (SAMBA)  
1 26410U 00041A   20214.58274461 -.00000178  00000-0  00000-0 0  9991
2 26410 131.9782 341.9803 4446520 184.4440 359.1632  0.44216036 10785
CLUSTER II-FM6 (SALSA)  
1 26411U 00041B   20214.68105389 -.00000256  00000-0  00000-0 0  9992
2 26411 132.0109 343.2981 5756298 190.2382 359.1134  0.44215498 10823
INTELSAT 9 (IS-9)       
1 26451U 00043A   20212.30056214 -.00000304  00000-0  00000-0 0  9991
2 26451   5.7831  65.5398 0002668  53.3555 247.6898  1.00271190 22603
CLUSTER II-FM5 (RUMBA)  
1 26463U 00045A   20214.64046989 -.00000191  00000-0  00000-0 0  9997
2 26463 135.6474 342.9619 5359286 189.9852 359.1494  0.44221117 10713
CLUSTER II-FM8 (TANGO)  
1 26464U 00045B   20214.57173600 -.00000178  00000-0  00000-0 0  9996
2 26464 131.9885 341.9716 4445363 184.4191 359.1868  0.44220118 10796
BRASILSAT B4            
1 26469U 00046A   20212.43487766 -.00000188  00000-0  00000-0 0  9997
2 26469   4.0786  78.2193 0003199  48.5247 246.3437  1.00272193 73426
ASTRA 2B                
1 26494U 00054A   20212.77248385  .00000100  00000-0  00000+0 0  9995
2 26494   4.6093  73.5980 0005175  46.5649 126.3473  1.00271890 72703
NSS-11 (AAP-1)          
1 26554U 00059A   20212.63154440  .00000011  00000-0  00000-0 0  9992
2 26554   0.0385 295.1777 0002708 178.4914 238.3932  1.00271943 19769
USA 153                 
1 26575U 00065A   20212.46729591 -.00000340  00000-0  00000-0 0  9994
2 26575   7.9631  49.6563 0001515  21.8573 179.8614  1.00277552 11393
AMC-6 (GE-6)            
1 26580U 00067A   20212.59073817 -.00000003  00000-0  00000-0 0  9995
2 26580   0.0162 350.3889 0000153 165.2332 251.6331  0.99973476 72395
INTELSAT 12 (IS-12)     
1 26590U 00068A   20212.06355711 -.00000003  00000-0  00000-0 0  9995
2 26590   3.0288  85.8894 0005508  48.4948 260.8751  1.00271479 30173
INTELSAT 1R (IS-1R)     
1 26608U 00072A   20212.61680955 -.00000155  00000-0  00000-0 0  9990
2 26608   2.8250  86.3429 0000027 227.1661  14.3251  1.00271655 19785
ANIK F1                 
1 26624U 00076A   20212.59453260 -.00000088  00000-0  00000+0 0  9990
2 26624   0.0260 122.6189 0003206  19.3374 273.4381  1.00269310 72196
ASTRA 2D                
1 26638U 00081A   20212.70819098  .00000045  00000-0  00000+0 0  9991
2 26638   5.8757  64.4747 0001734  66.9299 129.5593  1.00271715 71856
AMC-8 (GE-8)            
1 26639U 00081B   20212.57512205  .00000073  00000-0  00000-0 0  9999
2 26639   0.7605  95.4861 0002636  34.8171 250.3914  1.00272159 71795
NAVSTAR 50 (USA 156)    
1 26690U 01004A   20212.95769502 -.00000069  00000-0  00000+0 0  9993
2 26690  53.4602 112.4208 0002310 324.5421  46.3843  1.91595147142388
ODIN                    
1 26702U 01007A   20213.04728346  .00000341  00000-0  26075-4 0  9999
2 26702  97.5533 229.2867 0012561 115.2564 244.9968 15.08126181 63392
USA 157 (MILSTAR-2 2)   
1 26715U 01009A   20212.42001382 -.00000325  00000-0  00000+0 0  9998
2 26715   9.3669  30.3397 0002463  98.4101 108.7400  1.00271497 10102
EUTELSAT 133 WEST A     
1 26719U 01011A   20212.57703594  .00000062  00000-0  00000-0 0  9993
2 26719   2.0628  88.6100 0005089  39.6127 255.2803  1.00272196 71009
BSAT-2A                 
1 26720U 01011B   20212.59424397 -.00000074  00000-0  00000+0 0  9991
2 26720   6.1985  63.0297 0002448  91.6809 101.4328  0.99175175 70668
INTELSAT 10 (IS-10)     
1 26766U 01019A   20212.77806694  .00000107  00000-0  00000+0 0  9999
2 26766   3.9864  78.7043 0002160  51.5490 146.1976  1.00271045 70394
INTELSAT 901 (IS-901)   
1 26824U 01024A   20212.17202917 -.00000216  00000-0  00000+0 0  9994
2 26824   0.0539 256.7956 0001195 231.0780 214.8135  1.00270260 70041
ASTRA 2C                
1 26853U 01025A   20212.83689035  .00000119  00000-0  00000+0 0  9991
2 26853   2.9277  85.2013 0002814  38.9672 149.7075  1.00274261 70049
ICO F2                  
1 26857U 01026A   20208.72876439 -.00000047  00000-0  00000-0 0  9997
2 26857  45.0003  20.3838 0013977 237.2097 122.6922  3.92402843121297
USA 159 (DSP 21)        
1 26880U 01033A   20212.95099399 -.00000314  00000-0  00000+0 0  9999
2 26880  10.8062  30.0751 0000905 358.8293 213.4604  1.00273254 10084
INTELSAT 902 (IS-902)   
1 26900U 01039A   20212.75861334 -.00000297  00000-0  00000+0 0  9992
2 26900   0.7195  96.4444 0002128  83.4898 351.9004  1.00269792 69233
EUTELSAT 12 WEST B      
1 26927U 01042A   20213.12589847 -.00000116  00000-0  00000+0 0  9993
2 26927   1.1714  93.2866 0007182  24.4057 224.3225  1.00272249 68995
PCSAT (NO-44)           
1 26931U 01043C   20212.86455653 -.00000034  00000-0  18594-4 0  9997
2 26931  67.0517  30.3670 0005949 274.4685  85.5736 14.30583217983289
PROBA-1                 
1 26958U 01049B   20213.14265386 -.00000328  00000-0 -22754-4 0  9996
2 26958  97.7225 167.2176 0073956 114.0856 246.8140 14.95517347 21889
BIRD 2                  
1 26959U 01049C   20212.82705864  .00000953  00000-0  28145-4 0  9993
2 26959  97.8419 249.2499 0006803 309.1148  50.9490 15.37788039 41297
TIMED                   
1 26998U 01055B   20213.09298950  .00000041  00000-0  85307-5 0  9998
2 26998  74.0711 206.3214 0002678 272.3298  87.7576 14.88203807 11036
MAROC-TUBSAT            
1 27004U 01056D   20212.84179248 -.00000037  00000-0  83759-5 0  9993
2 27004  99.5235 345.9018 0018991 263.5131 218.6605 13.70141447932113
COSMOS 2385             
1 27056U 01058B   20212.73775337  .00000069  00000-0  33469-3 0  9996
2 27056  82.5379 153.1415 0007422  38.3563 321.8069 12.60512461855385
COSMOS 2386             
1 27057U 01058C   20213.22267659  .00000026  00000-0  87267-4 0  9994
2 27057  82.5447 148.5740 0000385  82.9595 277.1545 12.61556145856136
USA 164 (MILSTAR-2 3)   
1 27168U 02001A   20212.68047097  .00000084  00000-0  00000+0 0  9996
2 27168   8.8095  31.7458 0002587 104.9050  76.0083  1.00270920 10113
RHESSI                  
1 27370U 02004A   20212.85857209  .00001139  00000-0  39424-4 0  9996
2 27370  38.0325 276.1559 0012299 326.9003  33.0998 15.32928373 20998
ECHOSTAR 7              
1 27378U 02006A   20212.58682329 -.00000014  00000-0  00000+0 0  9990
2 27378   0.0595 269.8475 0000815 224.3790 266.8836  1.00270632 67531
INTELSAT 904 (IS-904)   
1 27380U 02007A   20212.04488861 -.00000228  00000-0  00000-0 0  9998
2 27380   1.5574  92.9935 0003160  39.4802 162.2989  1.00271601 30229
TDRS 9                  
1 27389U 02011A   20212.45105951 -.00000304  00000-0  00000-0 0  9998
2 27389   7.5777  67.0375 0022769 285.9178  55.5006  1.00264461 68906
JCSAT-2A                
1 27399U 02015A   20212.06713378 -.00000171  00000-0  00000-0 0  9990
2 27399   3.0025  84.9939 0006956   7.1574 322.1219  1.00270893 67074
ASTRA 3A                
1 27400U 02015B   20212.43778616 -.00000219  00000-0  00000-0 0  9993
2 27400   6.0019  63.2571 0001508  59.8354 256.1899  1.00268682 67188
NSS-7                   
1 27414U 02019A   20212.40556962 -.00000174  00000-0  00000+0 0  9996
2 27414   4.3295  76.4509 0002315  50.3625 307.6731  1.00271646 67025
AQUA                    
1 27424U 02022A   20213.15042892  .00000039  00000-0  18795-4 0  9990
2 27424  98.2104 153.1573 0001143 141.2438 281.5950 14.57111910970288
DIRECTV 5 (TEMPO 1)     
1 27426U 02023A   20212.36474913 -.00000069  00000-0  00000+0 0  9997
2 27426   0.0135  33.2532 0003544  93.0672 203.3101  1.00272643 66774
INTELSAT 905 (IS-905)   
1 27438U 02027A   20212.04584821 -.00000198  00000-0  00000+0 0  9992
2 27438   1.3780  93.9039 0002827  25.2766 180.9468  1.00273347 66426
GALAXY 3C (G-3C)        
1 27445U 02030A   20211.03413194 -.00000162  00000-0  00000+0 0  9997
2 27445   0.0261 309.5201 0001976  24.6022 250.2652  1.00270907 66406
EUTELSAT 5 WEST A       
1 27460U 02035A   20212.09493928 -.00000056  00000-0  00000+0 0  9991
2 27460   0.6630  96.7551 0005144  30.9473 209.6787  1.00271254 66204
N-STAR C                
1 27461U 02035B   20212.00327709 -.00000231  00000-0  00000-0 0  9992
2 27461   6.7273  58.6980 0008007 163.0780 209.7553  0.98953738 66110
COSMOS 2390             
1 27464U 02036A   20212.75062596  .00000001  00000-0 -64099-4 0  9995
2 27464  82.4815 247.6021 0024032 276.0979  83.7363 12.44573853820682
COSMOS 2391             
1 27465U 02036B   20213.16047475  .00000069  00000-0  41493-3 0  9996
2 27465  82.4812 244.7604 0024914 273.2551  86.5679 12.44893429820932
METEOSAT-8 (MSG-1)      
1 27509U 02040B   20212.70505050  .00000119  00000-0  00000+0 0  9998
2 27509   6.7981  53.9194 0001690  66.9910 123.2198  1.00264849 65713
INTELSAT 906 (IS-906)   
1 27513U 02041A   20212.06355711  .00000002  00000-0  00000-0 0  9992
2 27513   0.3823  99.6298 0002767  35.1644 260.3842  1.00268999 65486
HISPASAT 30W-4          
1 27528U 02044A   20212.56964657  .00000103  00000-0  00000-0 0  9993
2 27528   1.1758  93.5082 0005420  12.8152 264.3525  1.00272387 21432
INTEGRAL                
1 27540U 02048A   20212.04259171  .00000726  00000-0  00000+0 0  9992
2 27540  55.6114 110.5294 8967033 292.6803   2.1460  0.37582189 18555
TDRS 10                 
1 27566U 02055A   20212.70988490  .00000073  00000-0  00000-0 0  9990
2 27566   6.9787  53.4199 0006735 314.2326  25.8168  1.00264033 64692
NSS-6                   
1 27603U 02057A   20212.45975499  .00000094  00000-0  00000+0 0  9993
2 27603   0.0134  64.9787 0005352  77.8637 161.7193  1.00272554 64499
LATINSAT B              
1 27606U 02058B   20212.78390655 -.00000076  00000-0  10006-4 0  9991
2 27606  64.5575 319.1674 0055579 168.2112 192.0305 14.72287539945611
SAUDISAT 1C (SO-50)     
1 27607U 02058C   20212.80891514 -.00000053  00000-0  12825-4 0  9991
2 27607  64.5539 241.8127 0042491 164.0212 196.2243 14.75648420947128
LATINSAT A              
1 27612U 02058H   20213.16615625 -.00000067  00000-0  99652-5 0  9993
2 27612  64.5561 108.0016 0012805 157.1811 202.9865 14.79500469949798
NIMIQ 2                 
1 27632U 02062A   20212.32789067 -.00000232  00000-0  00000-0 0  9997
2 27632   4.4439  75.7156 0004559  41.8437 241.0630  0.99663996 22644
CORIOLIS                
1 27640U 03001A   20213.06157312  .00000012  00000-0  26573-4 0  9996
2 27640  98.7259 221.8354 0012977 271.9593 154.4628 14.19043069909609
SORCE                   
1 27651U 03004A   20212.69791324 -.00000003  00000-0  13867-4 0  9999
2 27651  39.9960  88.8655 0023493 319.8430  40.0623 14.89130919950675
NAVSTAR 51 (USA 166)    
1 27663U 03005A   20212.96650769 -.00000050  00000-0  00000+0 0  9992
2 27663  56.0168 300.2451 0112995  35.5042 135.6560  2.00549062128233
USA 167                 
1 27691U 03008A   20212.57895439  .00000038  00000-0  00000-0 0  9994
2 27691   6.7242  58.6428 0003977  14.8133 313.5167  1.00270843 11415
NAVSTAR 52 (USA 168)    
1 27704U 03010A   20212.84999975 -.00000063  00000-0  00000+0 0  9990
2 27704  54.6542  51.2263 0239889 285.0030  86.7841  2.00398706127036
USA 169 (MILSTAR-2 4)   
1 27711U 03012A   20212.81597222 -.00000212  00000-0  00000+0 0  9992
2 27711   9.1768  44.9140 0002397 108.4349 241.2715  1.00279042 10113
GALAXY 12 (G-12)        
1 27715U 03013B   20212.49381762  .00000043  00000-0  00000+0 0  9992
2 27715   1.5944  92.3004 0002101  32.1992 232.8366  1.00270701 63473
ASIASAT 4               
1 27718U 03014A   20212.05832295  .00000140  00000-0  00000-0 0  9999
2 27718   0.0113  84.9742 0001838  48.7524 233.5912  1.00270784 23757
HELLAS-SAT 2            
1 27811U 03020A   20212.43352806 -.00000165  00000-0  00000-0 0  9995
2 27811   2.0128  91.8106 0002618  24.7835 252.7764  1.00271450 21304
THURAYA-2               
1 27825U 03026A   20212.98774361  .00000112  00000-0  00000+0 0  9990
2 27825   5.9598  33.0545 0004664  97.1706 218.4194  1.00271769 62811
OPTUS C1                
1 27831U 03028B   20212.52541060 -.00000165  00000-0  00000+0 0  9996
2 27831   1.5196  93.9816 0006701  29.3499 170.1314  1.00271714 62784
MOST                    
1 27843U 03031D   20212.65758924 -.00000014  00000-0  13774-4 0  9996
2 27843  98.7092 219.3297 0009501 335.3392  24.7329 14.20614561885735
CUTE-1 (CO-55)          
1 27844U 03031E   20212.83888523  .00000036  00000-0  35781-4 0  9991
2 27844  98.6814 219.9181 0008710 293.2454  66.7807 14.22248656886263
CUBESAT XI-IV (CO-57)   
1 27848U 03031J   20213.01890243  .00000029  00000-0  32859-4 0  9993
2 27848  98.6881 220.6560 0008742 306.5682  53.4691 14.21834365886167
GALAXY 23 (G-23)        
1 27854U 03034A   20212.55041167 -.00000001  00000-0  00000-0 0  9994
2 27854   0.0588 264.2362 0003147 232.8277 248.7347  1.00273706 62201
SCISAT 1                
1 27858U 03036A   20212.80635512  .00000017  00000-0  74799-5 0  9998
2 27858  73.9334  99.4084 0009044 292.7354  67.2864 14.77295412914058
COSMOS 2400             
1 27868U 03037A   20212.70512576 -.00000022  00000-0 -22689-3 0  9990
2 27868  82.4681 236.2829 0022957  75.4595 284.9035 12.45140845770384
COSMOS 2401             
1 27869U 03037B   20213.13106117 -.00000009  00000-0 -13399-3 0  9992
2 27869  82.4674 233.3267 0023578  72.2669 288.0991 12.45458880770633
USA 170                 
1 27875U 03040A   20212.30043539 -.00000290  00000-0  00000-0 0  9992
2 27875   5.9703  64.1461 0006839  60.7935 249.1045  1.00268218 11424
MOZHAYETS 4 (RS-22)     
1 27939U 03042A   20213.17917331  .00000094  00000-0  25503-4 0  9991
2 27939  98.0968 347.4327 0012347 209.9025 150.1481 14.66874573900422
GALAXY 13 (HORIZONS 1)  
1 27954U 03044A   20212.29653552  .00000035  00000-0  00000+0 0  9999
2 27954   0.0174  76.9718 0003480 116.2518  94.9043  1.00267417 61619
IRS-P6 (RESOURCESAT-1)  
1 28051U 03046A   20213.23901712 -.00000009  00000-0  12192-4 0  9991
2 28051  98.4825 289.0418 0056978 256.8869 102.5961 14.34170631872668
DMSP 5D-3 F16 (USA 172) 
1 28054U 03048A   20212.89849394 -.00000101  00000-0 -29753-4 0  9999
2 28054  98.9145 191.8144 0008542  97.9068 292.7937 14.13650398865922
CHUANGXIN 1 (CX-1)      
1 28058U 03049B   20212.79090653  .00000005  00000-0  14318-4 0  9991
2 28058  98.7333  35.4831 0012780 182.1997 177.9140 14.47006313885624
YAMAL 202               
1 28089U 03053A   20212.71746163 -.00000094  00000-0  00000-0 0  9994
2 28089   0.9512  95.0660 0006641  19.9902 255.5108  1.00270981 61156
UFO 11 (USA 174)        
1 28117U 03057A   20213.04565851 -.00000112  00000-0  00000+0 0  9990
2 28117   6.0999  37.4748 0003508  29.2648 333.5978  1.00272141 60893
NAVSTAR 53 (USA 175)    
1 28129U 03058A   20212.01016268 -.00000065  00000-0  00000-0 0  9991
2 28129  53.3638 110.1073 0070994 295.5099  63.7881  2.00551256121703
USA 176 (DSP 22)        
1 28158U 04004A   20212.54359420 -.00000235  00000-0  00000+0 0  9991
2 28158   9.2157  39.0058 0001237 100.0547  92.5168  1.00275956 10079
ABS-4 (MOBISAT-1)       
1 28184U 04007A   20213.10893182  .00000025  00000-0  00000+0 0  9990
2 28184   0.6334  96.4760 0005095  32.0106 280.8996  1.00273715 60053
EUTELSAT 7A             
1 28187U 04008A   20212.79077271  .00000139  00000-0  00000+0 0  9997
2 28187   0.4294  88.7899 0001062  17.6947 157.9720  1.00269083 60049
NAVSTAR 54 (USA 177)    
1 28190U 04009A   20212.53235605 -.00000094  00000-0  00000+0 0  9992
2 28190  56.2690   1.0658 0091719  96.4573 278.7453  2.00561608119905
SHIYAN 1 (SY-1)         
1 28220U 04012A   20212.84892277  .00000196  00000-0  20683-4 0  9994
2 28220  97.8454 182.3233 0017789  91.5139 268.8122 15.00037347887108
DIRECTV 7S              
1 28238U 04016A   20212.35983169 -.00000012  00000-0  00000+0 0  9998
2 28238   0.0241 305.4992 0003161 179.0047 194.4024  1.00270356 59511
AMC-11 (GE-11)          
1 28252U 04017A   20213.17394119  .00000054  00000-0  00000+0 0  9992
2 28252   0.0306 318.3885 0002956 162.5839 119.8814  1.00271224 59175
FORMOSAT-2 (ROCSAT 2)   
1 28254U 04018A   20213.09287212  .00000343  00000-0  25826-3 0  9999
2 28254  98.7086 244.5884 0001223 186.5742 195.4486 14.00668627828170
INTELSAT 10-02          
1 28358U 04022A   20213.07577562 -.00000026  00000-0  00000+0 0  9999
2 28358   0.0200  87.8318 0003314  44.6193 202.9941  1.00271796 59096
APRIZESAT 2             
1 28366U 04025A   20212.68365865 -.00000015  00000-0  94433-5 0  9995
2 28366  98.2595 102.0246 0106547 237.3164 121.7712 14.36708325843206
SAUDICOMSAT 1           
1 28369U 04025D   20212.83384290  .00000009  00000-0  13433-4 0  9990
2 28369  98.3410 163.3352 0034693 118.6125 241.8568 14.52560221852161
SAUDICOMSAT 2           
1 28370U 04025E   20212.81535046  .00000001  00000-0  12974-4 0  9998
2 28370  98.4344 160.4333 0056622 284.5584  74.9333 14.47490805849294
SAUDISAT 2              
1 28371U 04025F   20212.98599944 -.00000137  00000-0 -21294-4 0  9991
2 28371  98.2821 161.4463 0026569  60.5081 299.8755 14.54385684853500
APRIZESAT 1             
1 28372U 04025G   20212.84753086  .00000003  00000-0  12633-4 0  9993
2 28372  98.3944 161.5627 0045687 205.9451 153.9452 14.49632789850628
AURA                    
1 28376U 04026A   20213.07533210  .00000064  00000-0  24268-4 0  9993
2 28376  98.2102 155.2355 0001432  87.0085 273.1277 14.57106078853323
ANIK F2                 
1 28378U 04027A   20212.59250600 -.00000063  00000-0  00000+0 0  9994
2 28378   0.0192 319.7967 0000282 111.1859 339.8991  1.00269608 58829
COSMOS 2407             
1 28380U 04028A   20213.12050188  .00000015  00000-0 -10852-5 0  9996
2 28380  82.9584 224.4886 0040120 134.9163 225.5277 13.76079181804776
SJ-6A                   
1 28413U 04035A   20213.17184502  .00000283  00000-0  31722-4 0  9998
2 28413  97.7590 232.6986 0012282 171.3314 188.8107 14.94260491865527
SJ-6B                   
1 28414U 04035B   20213.17946506 -.00000646  00000-0 -54925-4 0  9994
2 28414  97.7622 232.2923 0006472 163.4657 196.6777 14.94253946865467
COSMOS 2408             
1 28419U 04037A   20213.16410363 -.00000017  00000-0 -18801-3 0  9991
2 28419  82.4818 230.7466 0015500 297.8667  62.0843 12.45620918720764
COSMOS 2409             
1 28420U 04037B   20213.16368461  .00000008  00000-0 -11182-4 0  9999
2 28420  82.4831 233.0646 0013052 308.0505  51.9398 12.45277487720595
AMC-15                  
1 28446U 04041A   20212.36690244 -.00000102  00000-0  00000-0 0  9998
2 28446   0.0489 291.5645 0002803 199.0078 204.9071  1.00270708 57781
JB-3 3 (ZY 2C)          
1 28470U 04044A   20213.11800670 -.00000607  00000-0 -39859-4 0  9997
2 28470  97.3298  96.2676 0047707  64.1631 296.4504 15.02881375864135
AMC-16                  
1 28472U 04048A   20213.12954635 -.00000221  00000-0  00000+0 0  9997
2 28472   0.0568 280.2555 0002988 209.5980 140.9699  1.00272255 57285
NAVSTAR 56 (USA 180)    
1 28474U 04045A   20212.37740132 -.00000063  00000-0  00000+0 0  9991
2 28474  54.9993  51.1381 0199422 267.5262 272.9822  2.00555152115343
SWIFT                   
1 28485U 04047A   20212.01089536  .00000695  00000-0  16848-4 0  9992
2 28485  20.5572  86.9495 0010999 234.7997 125.1407 15.04643324859914
NANOSAT-1               
1 28493U 04049B   20212.80288384  .00000026  00000-0  11835-4 0  9991
2 28493  98.1194 302.5573 0003884 344.1095  15.9990 14.73587569838731
COSMOS 2414             
1 28521U 05002A   20213.08757096 -.00000003  00000-0 -15564-4 0  9995
2 28521  82.9476 227.1007 0039880 332.2298  27.6732 13.87622049786361
NSS-10 (AMC-12)         
1 28526U 05003A   20213.13088253 -.00000267  00000-0  00000+0 0  9994
2 28526   1.1211  94.2159 0003204  29.0779 195.5661  1.00271257 56788
XTAR-EUR                
1 28542U 05005A   20212.06353054  .00000138  00000-0  00000+0 0  9995
2 28542   0.0157  78.3067 0002661  51.7296 229.9725  1.00273778 56644
XM-3 (RHYTHM)           
1 28626U 05008A   20212.31901205 -.00000219  00000-0  00000+0 0  9997
2 28626   0.0089 301.2080 0000417 153.7851 243.1418  1.00269857 56528
INMARSAT 4-F1           
1 28628U 05009A   20211.93575649 -.00000275  00000-0  00000-0 0  9999
2 28628   3.1960  27.5323 0003081 100.7737 300.0886  1.00271330 56133
APSTAR 6                
1 28638U 05012A   20212.44070815 -.00000100  00000-0  00000+0 0  9992
2 28638   1.7485  91.7183 0004383  20.6514 157.6928  1.00271905 80875
IRS-P5 (CARTOSAT-1)     
1 28649U 05017A   20213.13980048 -.00000029  00000-0  27561-5 0  9991
2 28649  97.8104 281.0965 0000719  26.0805 334.0445 14.83704348824985
NOAA 18                 
1 28654U 05018A   20212.86797135  .00000076  00000-0  65488-4 0  9990
2 28654  99.0408 269.6960 0015351 108.3026 251.9817 14.12528538783044
DIRECTV 8               
1 28659U 05019A   20212.59953035 -.00000129  00000-0  00000-0 0  9994
2 28659   0.0082  19.8108 0003167 100.3031 303.6334  1.00271081 55590
GALAXY 28 (G-28)        
1 28702U 05022A   20212.31691067 -.00000199  00000-0  00000+0 0  9997
2 28702   0.0253  85.7746 0001422 334.2274 273.4887  1.00274364 55288
EXPRESS-AM3             
1 28707U 05023A   20212.96957410 -.00000339  00000-0  00000-0 0  9998
2 28707   1.9373  90.4704 0002446 359.2572 311.3989  1.00270716 55303
SJ-7                    
1 28737U 05024A   20212.99304154  .00000143  00000-0  17789-4 0  9994
2 28737  97.6843 211.0722 0035123 100.5093 260.0089 14.96044623825338
THAICOM 4               
1 28786U 05028A   20212.80813436 -.00000377  00000-0  00000+0 0  9993
2 28786   0.0114  86.0981 0002915  41.3420 231.8734  1.00272690 54792
GALAXY 14 (G-14)        
1 28790U 05030A   20213.17743799  .00000022  00000-0  00000+0 0  9992
2 28790   0.0358 307.9635 0002760 177.6396 122.5130  1.00270705 54770
REIMEI (INDEX)          
1 28810U 05031B   20213.09214678  .00000115  00000-0  17931-4 0  9995
2 28810  98.1487 246.5704 0029093  51.9556 308.4280 14.90543855809859
ANIK F1R                
1 28868U 05036A   20212.50437897 -.00000088  00000-0  00000-0 0  9993
2 28868   0.0216 338.0328 0002216 127.4786 277.3546  1.00276319 24915
NAVSTAR 57 (USA 183)    
1 28874U 05038A   20212.44877206 -.00000095  00000-0  00000-0 0  9995
2 28874  56.3692 358.4604 0135083 266.0086  68.8293  2.00557041108751
GALAXY 15 (G-15)        
1 28884U 05041A   20212.31217539  .00000065  00000-0  00000+0 0  9998
2 28884   0.0665 272.1080 0001782 210.3898 165.2815  1.00272009 54084
SYRACUSE 3A             
1 28885U 05041B   20212.77797971  .00000115  00000-0  00000+0 0  9998
2 28885   0.0243  94.4938 0002842  27.9562 153.4488  1.00272122 54195
BEIJING 1               
1 28890U 05043A   20213.19125648  .00000051  00000-0  19606-4 0  9995
2 28890  98.0366 340.7052 0015807 161.5794 198.5989 14.61295636786908
SINAH 1                 
1 28893U 05043D   20212.81106710 -.00000016  00000-0  60554-5 0  9996
2 28893  98.0287 341.9129 0015690 162.1782 197.9972 14.61535374786658
CUBESAT XI-V            
1 28895U 05043F   20213.09427178  .00000072  00000-0  22786-4 0  9998
2 28895  98.0154 348.2412 0017773 139.9055 220.3464 14.63786433787224
INMARSAT 4-F2           
1 28899U 05044A   20212.06346987 -.00000004  00000-0  00000-0 0  9994
2 28899   3.0482  30.3245 0003103 100.4569 264.1134  1.00272646 53988
TELKOM 2                
1 28902U 05046A   20212.52642358 -.00000152  00000-0  00000-0 0  9997
2 28902   0.0125  68.3565 0002121 356.0499 230.7167  1.00272433 53901
SPACEWAY 2              
1 28903U 05046B   20212.34921146  .00000090  00000-0  00000-0 0  9993
2 28903   0.2879  94.0842 0000361  12.9935 188.1640  1.00272281 29806
COSMOS 2416             
1 28908U 05048A   20212.83988164  .00000019  00000-0  55887-4 0  9995
2 28908  82.4656 240.6853 0005983 286.0492 247.5661 12.55502382669425
METEOSAT-9 (MSG-2)      
1 28912U 05049B   20212.80906654 -.00000000  00000-0  00000-0 0  9996
2 28912   4.5141  69.9815 0002655  18.7062 154.7381  1.00272943 53509
EUTELSAT 174A           
1 28924U 05052A   20212.62939532 -.00000005  00000-0  00000-0 0  9999
2 28924   0.0641  21.4177 0006425 106.7416 221.1103  1.00271638 30799
ECHOSTAR 10             
1 28935U 06003A   20212.36475007 -.00000069  00000-0  00000+0 0  9990
2 28935   0.0539 267.6583 0001508 212.7177 209.1708  1.00271446 52979
SPAINSAT                
1 28945U 06007A   20211.22961546 -.00000227  00000-0  00000-0 0  9994
2 28945   0.0594 247.2812 0004375 265.9330 206.7954  1.00272126 52675
EUTELSAT HOT BIRD 13E   
1 28946U 06007B   20212.05335050  .00000074  00000-0  00000+0 0  9990
2 28946   0.0508 243.6562 0006774 261.3932 195.2908  1.00273084 52761
JCSAT-5A                
1 29045U 06010A   20212.75842600 -.00000342  00000-0  00000+0 0  9995
2 29045   0.0069  92.2385 0002111  42.5404 219.1250  1.00269524 52411
FORMOSAT-3 FM6          
1 29047U 06011A   20212.88593764  .00000053  00000-0  37209-4 0  9990
2 29047  71.9788  21.3428 0050850 345.4059  14.5606 14.28744302747535
FORMOSAT-3 FM1          
1 29048U 06011B   20212.62558967  .00000039  00000-0  31950-4 0  9990
2 29048  71.9669 292.7353 0045358 304.3582  55.3267 14.28707588749978
FORMOSAT-3 FM5          
1 29049U 06011C   20213.11428668  .00000022  00000-0  24897-4 0  9994
2 29049  72.0208 103.6342 0035904 328.8164  31.0842 14.28790622745833
FORMOSAT-3 FM3          
1 29050U 06011D   20212.81902226  .00000073  00000-0  25868-4 0  9994
2 29050  72.0142 245.8960 0051694 247.0767 112.4925 14.58932933762653
FORMOSAT-3 FM4          
1 29051U 06011E   20212.83330218  .00000033  00000-0  28685-4 0  9999
2 29051  72.0061   6.1741 0068981 211.0097 148.6960 14.28821278748383
FORMOSAT-3 FM2          
1 29052U 06011F   20212.89981194  .00000006  00000-0  18671-4 0  9995
2 29052  72.0345  80.3776 0041626 290.0030  69.6627 14.28927147745901
ASTRA 1KR               
1 29055U 06012A   20212.79632703  .00000105  00000-0  00000+0 0  9999
2 29055   0.0469 250.9344 0003030 278.2533  85.5529  1.00269813 52219
EROS B                  
1 29079U 06014A   20213.10724865  .00001086  00000-0  50657-4 0  9991
2 29079  97.3757 332.7605 0008462 158.4992  14.3689 15.21925619791897
CLOUDSAT                
1 29107U 06016A   20212.81669018  .00000067  00000-0  22707-4 0  9993
2 29107  98.2334 159.9368 0000411 185.9984 174.1213 14.62429672758665
CALIPSO                 
1 29108U 06016B   20212.81610468  .00000066  00000-0  22528-4 0  9999
2 29108  98.2319 159.7108 0001535  77.6827 282.4547 14.62427141758651
GOES 13                 
1 29155U 06018A   20212.66510980  .00000024  00000-0  00000-0 0  9995
2 29155   0.0757 222.4499 0006966 328.8276  58.3050  1.00268591 21835
EUTELSAT 113 WEST A     
1 29162U 06020A   20212.50015264 -.00000051  00000-0  00000+0 0  9994
2 29162   0.0048  63.6795 0003108  71.1414 240.8163  1.00272469 51933
RESURS-DK 1             
1 29228U 06021A   20213.01985613  .00000015  00000-0  84647-5 0  9993
2 29228  69.9357  77.0933 0003903 353.7337   6.3774 15.02554394779298
GALAXY 16 (G-16)        
1 29236U 06023A   20212.60059771 -.00000140  00000-0  00000-0 0  9991
2 29236   0.0114  44.8358 0002963  84.9395 296.1032  1.00271520 51681
GENESIS 1               
1 29252U 06029A   20212.55685958  .00000219  00000-0  20527-4 0  9997
2 29252  64.5236 160.6016 0003337 153.1622 206.9674 15.18178218774648
ARIRANG-2 (KOMPSAT-2)   
1 29268U 06031A   20213.18400238 -.00000008  00000-0  75296-5 0  9994
2 29268  97.9842  97.9681 0017610  19.3426 340.8454 14.62288611747609
EUTELSAT HOT BIRD 13B   
1 29270U 06032A   20212.92824299  .00000072  00000-0  00000+0 0  9991
2 29270   0.0589   5.9421 0002883  96.5345 193.6741  1.00269518 51322
JCSAT-3A                
1 29272U 06033A   20212.90709396 -.00000360  00000-0  00000+0 0  9993
2 29272   0.0206  90.4006 0002341  38.8498 274.2966  1.00268053 51233
SYRACUSE 3B             
1 29273U 06033B   20212.09493928 -.00000057  00000-0  00000+0 0  9998
2 29273   0.0180  97.7629 0005939  20.5974 218.7914  1.00273130 51215
KOREASAT 5 (MUGUNGWHA 5)
1 29349U 06034A   20212.93778314 -.00000373  00000-0  00000+0 0  9992
2 29349   0.0198 122.0009 0002062  36.1598 241.4943  1.00270291 51109
HINODE (SOLAR-B)        
1 29479U 06041A   20212.99671694  .00000037  00000-0  15951-4 0  9995
2 29479  98.1588 224.4381 0015960 228.6913 131.2917 14.65076191740272
NAVSTAR 58 (USA 190)    
1 29486U 06042A   20212.24322235  .00000081  00000-0  00000+0 0  9999
2 29486  54.8511 236.6680 0095336   6.6803 103.4034  2.00569368101461
DIRECTV 9S              
1 29494U 06043A   20212.36892529 -.00000126  00000-0  00000-0 0  9992
2 29494   0.0230 155.6087 0003356 332.8170 211.6263  1.00272331 29863
OPTUS D1                
1 29495U 06043B   20212.61938853 -.00000125  00000-0  00000-0 0  9991
2 29495   0.0492 267.3461 0003757 219.1819 205.1354  1.00271884 50587
METOP-A                 
1 29499U 06044A   20212.88718831 -.00000013  00000-0  13419-4 0  9995
2 29499  98.5191 258.1811 0001715 107.1497  64.1442 14.21502779714873
SJ-6C                   
1 29505U 06046A   20213.03373699 -.00000393  00000-0 -30766-4 0  9998
2 29505  97.8161 226.6060 0003207 244.3038 115.7854 14.94884049750120
SJ-6D                   
1 29506U 06046B   20213.15379726 -.00000393  00000-0 -31925-4 0  9997
2 29506  97.8367 226.8539 0002053  23.0924 337.0379 14.93522849749693
XM-4 (BLUES)            
1 29520U 06049A   20212.36189878 -.00000036  00000-0  00000-0 0  9994
2 29520   0.0168 149.0690 0000166  16.2398 158.1239  1.00270881 29935
DMSP 5D-3 F17 (USA 191) 
1 29522U 06050A   20212.86145459 -.00000068  00000-0 -12808-4 0  9999
2 29522  98.8008 228.6067 0010843  57.6182 302.6038 14.14014923708849
BADR-4                  
1 29526U 06051A   20212.05596758  .00000131  00000-0  00000-0 0  9993
2 29526   0.0499 341.9172 0001767 153.4635 218.8447  1.00271004 29733
NAVSTAR 59 (USA 192)    
1 29601U 06052A   20211.04327264 -.00000055  00000-0  00000-0 0  9996
2 29601  56.0066 299.2408 0077429  66.2775 294.5757  2.00566175100304
WILDBLUE-1              
1 29643U 06054A   20212.36404628 -.00000062  00000-0  00000-0 0  9998
2 29643   0.0208  72.6345 0001991  48.9494 206.7400  1.00272641 32266
AMC-18                  
1 29644U 06054B   20212.57240035  .00000091  00000-0  00000-0 0  9993
2 29644   0.0551 273.0283 0002859 212.8934 249.7897  1.00272485 50040
MEASAT-3                
1 29648U 06056A   20212.06896574 -.00000255  00000-0  00000-0 0  9991
2 29648   0.0315 143.0130 0003789 348.8013 292.6726  1.00271421 28929
COSMOS 2425 [GLONASS-M] 
1 29670U 06062A   20212.36936753  .00000043  00000-0  00000+0 0  9993
2 29670  64.9424 266.3405 0023006 339.5766  28.0173  2.13110464105808
COROT                   
1 29678U 06063A   20212.99991896  .00000143  00000-0  29380-4 0  9998
2 29678  90.0400  20.1593 0200031 216.2029 142.5501 14.44378714704579
LAPAN-TUBSAT            
1 29709U 07001A   20213.11288299 -.00000011  00000-0  52965-5 0  9998
2 29709  97.8646 171.9935 0012277 336.5423  23.5227 14.82226342732837
CARTOSAT-2 (IRS-P7)     
1 29710U 07001B   20212.76106200  .00000186  00000-0  12952-4 0  9994
2 29710  97.9106 274.9568 0139744 277.9148  80.6240 15.11598233731829
THEMIS A                
1 30580U 07004A   20211.78895221 -.00000316  00000-0  00000-0 0  9996
2 30580  15.0948  42.9452 8405278  38.9298 358.5472  0.87837889 22415
OE (NEXTSAT)            
1 30774U 07006C   20213.04313490  .00001130  00000-0  32665-4 0  9997
2 30774  46.0021 198.7847 0005317 175.5816 184.5137 15.43708370747554
FALCONSAT-3             
1 30776U 07006E   20212.72331968  .00002118  00000-0  56747-4 0  9997
2 30776  35.4356   3.1447 0003123 234.6687 125.3748 15.37964504743508
CFESAT                  
1 30777U 07006F   20212.80974780  .00001915  00000-0  53336-4 0  9997
2 30777  35.4261  37.7331 0005742 235.1124 124.9064 15.36850650743130
INSAT-4B                
1 30793U 07007A   20212.06783167 -.00000201  00000-0  00000+0 0  9994
2 30793   0.5415  97.3898 0002325 107.1033 213.5762  1.00269632 49020
SKYNET 5A               
1 30794U 07007B   20212.82410833 -.00000286  00000-0  00000+0 0  9999
2 30794   0.6658  90.8504 0003694  44.6368 205.3134  1.00271914 49128
THEMIS D                
1 30797U 07004D   20211.77265430 -.00000339  00000-0  79513-2 0  9990
2 30797  13.2719  24.5164 8369435  59.0027 357.1150  0.87839872 22204
THEMIS E                
1 30798U 07004E   20211.83861442 -.00000362  00000-0  00000-0 0  9992
2 30798  12.4540  35.3458 8368136  50.9688 357.6974  0.87844285 47035
ANIK F3                 
1 31102U 07009A   20212.35983602 -.00000015  00000-0  00000-0 0  9993
2 31102   0.0150 322.4260 0002323 181.9681 174.8920  1.00275745 25029
HAIYANG-1B              
1 31113U 07010A   20212.85495027  .00000052  00000-0  36231-4 0  9990
2 31113  98.4003 253.8773 0015055  85.3069 274.9833 14.30248796694354
SAUDISAT 3              
1 31118U 07012B   20213.15424105 -.00000024  00000-0  39834-5 0  9993
2 31118  97.8724 169.7901 0014179 219.7541 140.2627 14.69167789712388
SAUDICOMSAT 7           
1 31119U 07012C   20212.84745968  .00000014  00000-0  12245-4 0  9996
2 31119  98.0985 157.8615 0061434  82.0842 278.7326 14.61794445708398
SAUDICOMSAT 6           
1 31121U 07012E   20212.79814251  .00000015  00000-0  13277-4 0  9992
2 31121  98.1505 152.6971 0076739 169.2213 191.0653 14.58593578706918
SAUDICOMSAT 5           
1 31124U 07012H   20212.82606422  .00000016  00000-0  12349-4 0  9998
2 31124  98.0637 160.3292 0052543  37.5621 322.9234 14.63497092709222
SAUDICOMSAT 3           
1 31125U 07012J   20212.83083602  .00000045  00000-0  17249-4 0  9995
2 31125  98.0261 162.9216 0043182 353.1225   6.9381 14.65258172709954
SAUDICOMSAT 4           
1 31127U 07012L   20213.11287179  .00000014  00000-0  12595-4 0  9999
2 31127  98.1253 156.0276 0069141 122.1229 238.6707 14.60308185707767
AGILE                   
1 31135U 07013A   20212.65741492  .00001917  00000-0  23839-4 0  9996
2 31135   2.4662 216.0045 0011672 133.3780 226.7247 15.32413357738681
AIM                     
1 31304U 07015A   20212.67274532  .00000497  00000-0  31616-4 0  9998
2 31304  97.9014  97.0616 0004409 194.8401 165.2700 15.13651527727223
ASTRA 1L                
1 31306U 07016A   20212.79632703  .00000105  00000-0  00000+0 0  9996
2 31306   0.0518 301.4611 0000802 188.7825 124.4956  1.00269852 48505
GALAXY 17 (G-17)        
1 31307U 07016B   20212.43592123 -.00000188  00000-0  00000+0 0  9991
2 31307   0.0148  65.1724 0003758  66.8268 242.4450  1.00271338 48540
YAOGAN 2                
1 31490U 07019A   20213.10292718 -.00000089  00000-0 -49220-5 0  9993
2 31490  98.1556 342.8034 0019914 331.1363  28.8743 14.76952603710251
GLOBALSTAR M065         
1 31571U 07020A   20212.84953057 -.00000079  00000-0  14024-3 0  9995
2 31571  51.9892 252.3507 0001324 182.5734 225.9832 12.62267771608687
GLOBALSTAR M069         
1 31573U 07020C   20212.73139797 -.00000086  00000-0  96869-4 0  9998
2 31573  51.9925 208.8102 0000980 220.2549 310.7474 12.62271263609467
GLOBALSTAR M072         
1 31574U 07020D   20212.64281640 -.00000082  00000-0  12104-3 0  9999
2 31574  52.0092 303.7490 0001293 109.5033  37.3281 12.62266473607840
GLOBALSTAR M071         
1 31576U 07020F   20212.75801637 -.00000086  00000-0  10156-3 0  9996
2 31576  52.0039 212.7880 0001172 178.6734 352.6066 12.62269043270842
COSMO-SKYMED 1          
1 31598U 07023A   20213.15714142  .00000299  00000-0  44054-4 0  9992
2 31598  97.8871  35.6824 0001414  90.3700 269.7671 14.82157660711305
TERRASAR-X              
1 31698U 07026A   20212.50031250  .00000291  00000-0  17025-4 0  9996
2 31698  97.4464 218.9111 0001729  90.3255 321.8191 15.19155877727830
GENESIS 2               
1 31789U 07028A   20213.12844421  .00000019  00000-0  11446-4 0  9999
2 31789  64.5014 200.4049 0040120 236.6122 123.1160 15.17298033721797
COSMOS 2428             
1 31792U 07029A   20213.12422497 -.00000346  00000-0 -16190-3 0  9994
2 31792  70.9073 181.2588 0009961 352.0695   8.0266 14.12310136675033
ZHONGXING-6B            
1 31800U 07031A   20212.94305653 -.00000376  00000-0  00000+0 0  9991
2 31800   0.0048  96.6617 0003636  30.5511 276.7886  1.00273013 47949
DIRECTV 10              
1 31862U 07032A   20212.59857308 -.00000117  00000-0  00000-0 0  9994
2 31862   0.0136 287.1963 0000243 161.3448 332.8678  1.00271130 47872
SPACEWAY 3              
1 32018U 07036A   20212.49685951 -.00000165  00000-0  00000+0 0  9996
2 32018   0.0015 235.8275 0000198 321.3615 195.2942  1.00271866 47591
BSAT-3A                 
1 32019U 07036B   20212.02144535 -.00000363  00000-0  00000+0 0  9994
2 32019   0.0853 251.4166 0002782 281.8325 252.4160  1.00270954 47522
INSAT-4CR               
1 32050U 07037A   20213.03791284  .00000109  00000-0  00000+0 0  9998
2 32050   1.4379  93.0124 0004280 153.4365 124.1965  1.00271135 47320
WORLDVIEW-1 (WV-1)      
1 32060U 07041A   20212.82111604  .00000444  00000-0  20950-4 0  9995
2 32060  97.3785 331.7842 0002067 114.3658 245.7795 15.24441283715784
OPTUS D2                
1 32252U 07044A   20212.63410139 -.00000197  00000-0  00000-0 0  9991
2 32252   0.0416 270.0108 0003857 217.9189 201.0602  1.00271487 47030
INTELSAT 11 (IS-11)     
1 32253U 07044B   20212.40112058 -.00000282  00000-0  00000+0 0  9990
2 32253   0.0137  88.1977 0001997  60.1321 261.5571  1.00270439 46998
WGS F1 (USA 195)        
1 32258U 07046A   20212.76977955  .00000027  00000-0  00000+0 0  9997
2 32258   0.0117  88.6866 0000794  56.5522  86.7016  1.00272155 46784
NAVSTAR 60 (USA 196)    
1 32260U 07047A   20212.34410359  .00000011  00000-0  00000+0 0  9998
2 32260  53.1944 169.0141 0123486  50.7740 310.2809  2.00557122 93751
GLOBALSTAR M067         
1 32263U 07048A   20212.74967508 -.00000036  00000-0  45600-3 0  9996
2 32263  51.9699 211.0308 0000961 148.6724  22.8659 12.40594209588469
GLOBALSTAR M070         
1 32264U 07048B   20212.58640854 -.00000104  00000-0 -35111-5 0  9996
2 32264  51.9740 158.6427 0000848 249.4686 281.8897 12.62268513588737
GLOBALSTAR M066         
1 32265U 07048C   20212.85386574 -.00000099  00000-0  27637-4 0  9998
2 32265  51.9691  20.4717 0000945  40.7883 106.9697 12.62267938591784
COSMOS 2433 [GLONASS-M] 
1 32275U 07052A   20212.63898163 -.00000041  00000-0  10000-3 0  9994
2 32275  65.9920  27.4823 0001561 186.6142 212.4257  2.13104298 99336
COSMOS 2432 [GLONASS-M] 
1 32276U 07052B   20212.71295914 -.00000041  00000-0  10000-3 0  9999
2 32276  66.0081  27.5708 0008795 342.1900  63.7078  2.13103644 99339
YAOGAN 3                
1 32289U 07055A   20213.21750390  .00000310  00000-0  46839-4 0  9994
2 32289  97.9914 227.8962 0001191 107.4365 252.6975 14.81025329687461
STAR ONE C1             
1 32293U 07056A   20212.44980035 -.00000290  00000-0  00000+0 0  9998
2 32293   0.0136 337.4986 0002662 153.9048 274.0428  1.00270152 46574
SKYNET 5B               
1 32294U 07056B   20212.79580619  .00000128  00000-0  00000+0 0  9990
2 32294   0.0696  11.1870 0003611 128.8528 120.4138  1.00271282 46659
ASTRA 4A (SIRIUS 4)     
1 32299U 07057A   20212.76951782  .00000018  00000-0  00000+0 0  9994
2 32299   0.0098  54.2354 0003237  72.4696 103.9688  1.00272620 46427
COSMO-SKYMED 2          
1 32376U 07059A   20213.19087750 -.00000303  00000-0 -31493-4 0  9999
2 32376  97.8871  35.7153 0001462  92.8970 267.2412 14.82152424684055
RADARSAT-2              
1 32382U 07061A   20213.21822678  .00000050  00000-0  36117-4 0  9993
2 32382  98.5762 219.1553 0001206  97.7900  32.8383 14.29985235659223
NAVSTAR 61 (USA 199)    
1 32384U 07062A   20212.58051874 -.00000095  00000-0  00000+0 0  9994
2 32384  56.4664 359.1354 0014315 126.6958 176.1203  2.00552590 92496
HORIZONS 2              
1 32388U 07063B   20212.76689135 -.00000197  00000-0  00000+0 0  9999
2 32388   0.0136  79.1299 0003133  41.9155 188.7211  1.00272589 46347
COSMOS 2434 [GLONASS-M] 
1 32393U 07065A   20212.62119333  .00000048  00000-0  00000-0 0  9995
2 32393  64.5592 264.9407 0002204 142.5468 130.5341  2.13103232 98037
COSMOS 2436 [GLONASS-M] 
1 32395U 07065C   20212.65649941  .00000048  00000-0  10000-3 0  9997
2 32395  64.5674 264.9899 0012973 352.9197  83.4773  2.13103113 97970
THURAYA-3               
1 32404U 08001A   20212.07036153 -.00000315  00000-0  00000+0 0  9993
2 32404   3.7679  10.7199 0004617  93.4801 327.7867  1.00272483 45991
EXPRESS-AM33            
1 32478U 08003A   20212.07001257 -.00000293  00000-0  00000-0 0  9999
2 32478   0.0467 246.3649 0000683 341.1330 202.3351  1.00270684 46070
THOR 5                  
1 32487U 08006A   20213.07577562 -.00000024  00000-0  00000+0 0  9991
2 32487   0.0532 254.8587 0002572 228.9243 211.8790  1.00271031 45654
AMC-14                  
1 32708U 08011A   20212.21299500  .00000061  00000-0  00000+0 0  9991
2 32708  21.0297  46.8975 0040740   9.9080 346.1995  1.00278369 46556
NAVSTAR 62 (USA 201)    
1 32711U 08012A   20212.26332348  .00000081  00000-0  00000-0 0  9992
2 32711  54.5984 235.7606 0143017 224.5174 134.3841  2.00565913 90690
DIRECTV 11              
1 32729U 08013A   20212.60059750 -.00000140  00000-0  00000+0 0  9999
2 32729   0.0039 303.9489 0000408 203.4797 278.2946  1.00272882 45408
ICO G1                  
1 32763U 08016A   20212.43509392 -.00000185  00000-0  00000-0 0  9993
2 32763   3.6452  13.7698 0002220 112.6267 245.8958  1.00270986 45045
VINASAT-1               
1 32767U 08018A   20212.75843299 -.00000342  00000-0  00000+0 0  9995
2 32767   0.0521 276.0566 0001944 218.3472 219.3963  1.00272486 45060
STAR ONE C2             
1 32768U 08018B   20212.44764348 -.00000278  00000-0  00000-0 0  9998
2 32768   0.0326 123.0048 0002727  23.9405 252.7296  1.00271353 45136
TIANLIAN 1-01           
1 32779U 08019A   20212.97652083 -.00000157  00000-0  00000+0 0  9996
2 32779   4.2158  72.3986 0029000 262.4142  45.8064  1.00272454 44939
CARTOSAT-2A             
1 32783U 08021A   20213.18645211 -.00000742  00000-0 -94084-4 0  9994
2 32783  97.9206 272.4486 0011859 222.0617 137.9665 14.78683936661606
CUTE-1.7+APD II (CO-65) 
1 32785U 08021C   20212.82568409  .00000147  00000-0  21474-4 0  9999
2 32785  97.5749 198.9395 0012898 356.9546   3.1590 14.88493270664390
AAUSAT-II               
1 32788U 08021F   20212.75463009  .00000736  00000-0  72023-4 0  9994
2 32788  97.4914 215.3295 0009953 270.5427  89.4654 14.95102043665781
DELFI-C3 (DO-64)        
1 32789U 08021G   20212.86975725  .00001214  00000-0  83130-4 0  9993
2 32789  97.4154 254.1486 0012661 159.7010 200.4724 15.07818312668353
CANX-2                  
1 32790U 08021H   20212.70469131  .00000125  00000-0  19271-4 0  9992
2 32790  97.5822 197.0564 0013711   0.5464 359.5766 14.88156363664204
SEEDS II (CO-66)        
1 32791U 08021J   20212.55022911  .00000165  00000-0  21980-4 0  9991
2 32791  97.5421 204.8009 0012290 325.2326  34.8089 14.91079525664801
AMOS-3                  
1 32794U 08022A   20212.04994830 -.00000048  00000-0  00000-0 0  9994
2 32794   0.0529 256.3089 0003009 221.7644 203.9039  1.00272310 60822
GALAXY 18 (G-18)        
1 32951U 08024A   20212.40375957  .00000012  00000-0  00000+0 0  9993
2 32951   0.0155  83.7314 0003198  41.3982 205.6965  1.00270543 44549
YUBILEINY (RS-30)       
1 32953U 08025A   20213.01891611  .00000037  00000-0  19437-3 0  9999
2 32953  82.5009 250.8090 0018272 321.2272  38.7493 12.43097485553045
COSMOS 2437             
1 32954U 08025B   20212.74416350  .00000012  00000-0  18239-4 0  9995
2 32954  82.4982 252.3046 0020155 335.5780  24.4344 12.42706840552865
COSMOS 2438             
1 32955U 08025C   20212.74439105 -.00000004  00000-0 -95261-4 0  9992
2 32955  82.4992 249.1671 0020224 315.2864  44.6585 12.43311820553133
COSMOS 2439             
1 32956U 08025D   20212.76826429  .00000007  00000-0 -18365-4 0  9996
2 32956  82.5040 252.5045 0020059 324.3068  35.6670 12.43012863553001
FENGYUN 3A              
1 32958U 08026A   20212.79934850 -.00000010  00000-0  15127-4 0  9997
2 32958  98.4083 196.2972 0008082 204.4902 155.5892 14.18630023630862
CHINASAT 9 (ZX 9)       
1 33051U 08028A   20212.62478017 -.00000262  00000-0  00000-0 0  9993
2 33051   0.0158 109.6143 0004018  22.4226 133.7883  1.00271650 44501
FGRST (GLAST)           
1 33053U 08029A   20213.08472953  .00000390  00000-0  54436-5 0  9998
2 33053  25.5818 163.2436 0012519 151.1707 208.9521 15.11353908669947
SKYNET 5C               
1 33055U 08030A   20212.10049347 -.00000153  00000-0  00000-0 0  9992
2 33055   0.0616   1.4584 0004507 139.4039 185.6743  1.00271710 44414
TURKSAT 3A              
1 33056U 08030B   20212.13090324  .00000133  00000-0  00000+0 0  9993
2 33056   0.0251  75.5234 0005330  44.8046 277.0045  1.00273417 44547
INTELSAT 25 (IS-25)     
1 33153U 08034A   20213.12817907 -.00000239  00000-0  00000+0 0  9997
2 33153   0.0169 134.2210 0000917   6.2048 183.4066  1.00271099 44109
BADR-6                  
1 33154U 08034B   20212.79077271  .00000130  00000-0  00000+0 0  9991
2 33154   0.0499  39.2552 0005122  71.2880 148.9895  1.00268423 44224
ECHOSTAR 11             
1 33207U 08035A   20212.59250538 -.00000070  00000-0  00000+0 0  9992
2 33207   0.0420 262.8011 0002949 218.7972 290.3584  1.00271687 44127
SUPERBIRD-C2            
1 33274U 08038A   20212.83660392 -.00000266  00000-0  00000+0 0  9996
2 33274   0.0112 117.8043 0002596  32.8814 243.3364  1.00270294 43857
AMC-21                  
1 33275U 08038B   20212.58304872  .00000022  00000-0  00000+0 0  9996
2 33275   0.0599 279.1311 0003191 216.3581 258.1659  1.00270318 43936
INMARSAT 4-F3           
1 33278U 08039A   20212.60192149 -.00000154  00000-0  00000-0 0  9993
2 33278   3.1398  13.0427 0002779 126.8007 287.5564  1.00272180 25055
HUANJING 1A (HJ-1A)     
1 33320U 08041A   20213.09698425  .00000007  00000-0  80689-5 0  9998
2 33320  97.7844 263.5494 0025720 291.7552  68.0929 14.77082040641044
HUANJING 1B (HJ-1B)     
1 33321U 08041B   20213.08774540  .00000062  00000-0  15903-4 0  9998
2 33321  97.7925 265.8943 0033962 319.5845  40.2849 14.77246345641043
GEOEYE 1                
1 33331U 08042A   20213.19627691 -.00000064  00000-0 -32335-5 0  9999
2 33331  98.1021 286.6875 0007603 276.4993  83.5349 14.64314729635933
NIMIQ 4                 
1 33373U 08044A   20212.44115053 -.00000234  00000-0  00000-0 0  9995
2 33373   0.0068  33.4384 0002184 104.3496 247.5452  1.00271953 43444
GALAXY 19 (G-19)        
1 33376U 08045A   20212.60254538 -.00000153  00000-0  00000-0 0  9999
2 33376   0.0196  74.3536 0003579  58.5511 295.7016  1.00270025 43405
THEOS                   
1 33396U 08049A   20213.21822955  .00000169  00000-0  10000-3 0  9997
2 33396  98.7344 279.7286 0001455  99.1528 334.8975 14.20020311613273
SJ-6E                   
1 33408U 08053A   20213.04602920  .00000454  00000-0  47613-4 0  9993
2 33408  97.7714 188.7379 0019583  42.4626 317.8090 14.94226161640967
SJ-6F                   
1 33409U 08053B   20213.16258050  .00000334  00000-0  35778-4 0  9994
2 33409  97.7526 190.4014 0016217   7.3929 352.7529 14.94978774641300
COSMO-SKYMED 3          
1 33412U 08054A   20213.17400793 -.00000125  00000-0 -91432-5 0  9993
2 33412  97.8868  35.6982 0001492  87.3812 272.7561 14.82158262636519
SHIYAN 3 (SY-3)         
1 33433U 08056A   20213.07174894 -.00000017  00000-0  10974-4 0  9997
2 33433  98.6992 213.9104 0012916 315.1320  44.8814 14.29765005612305
CHUANGXIN 1-02 (CX-1-02)
1 33434U 08056B   20213.06808551 -.00000035  00000-0  40162-5 0  9994
2 33434  98.7042 213.6224 0013584 314.7665  45.2409 14.29461927612202
ASTRA 1M                
1 33436U 08057A   20212.77239661  .00000106  00000-0  00000+0 0  9993
2 33436   0.0161 123.7214 0005552  12.1092 110.2976  1.00276906 42947
YAOGAN 4                
1 33446U 08061A   20213.10709226  .00000001  00000-0  70567-5 0  9991
2 33446  97.7293 166.8498 0015719 193.6925 166.3862 14.77029228628278
CIEL-2                  
1 33453U 08063A   20212.39975964  .00000045  00000-0  00000+0 0  9992
2 33453   0.0525 253.3418 0003087 230.4940 199.6966  1.00270418 42609
EUTELSAT HOT BIRD 13C   
1 33459U 08065A   20212.92824299  .00000072  00000-0  00000+0 0  9993
2 33459   0.0445  41.2276 0005886  77.9235 176.9982  1.00269352 42715
EUTELSAT 48D            
1 33460U 08065B   20212.07271383  .00000111  00000-0  00000-0 0  9996
2 33460   0.0732 275.2295 0004085 236.4517 230.7421  1.00274009 42421
FENGYUN 2E              
1 33463U 08066A   20212.82482475 -.00000220  00000-0  00000-0 0  9991
2 33463   4.4290  68.4256 0003070 304.3581 319.6318  1.00274830 42557
GOSAT (IBUKI)           
1 33492U 09002A   20212.75306874  .00000003  00000-0  90011-5 0  9998
2 33492  98.0655 323.0455 0001135  86.8969 273.2365 14.67548905616969
STARS (KUKAI)           
1 33498U 09002G   20212.89326124  .00000115  00000-0  23028-4 0  9996
2 33498  98.2587  79.2117 0009815 304.1077  55.9202 14.79161264619921
KKS-1 (KISEKI)          
1 33499U 09002H   20212.85060398  .00000052  00000-0  15649-4 0  9991
2 33499  98.3126  68.7954 0008964 355.4599   4.6526 14.75547665618980
NOAA 19                 
1 33591U 09005A   20212.80400105  .00000067  00000-0  61625-4 0  9997
2 33591  99.1954 219.2793 0013239 317.7766  42.2384 14.12424272591252
EXPRESS-AM44            
1 33595U 09007A   20212.29447021 -.00000102  00000-0  00000+0 0  9990
2 33595   0.0393 237.3432 0000897 318.8215 207.2119  1.00271649 42028
NSS-9                   
1 33749U 09008A   20212.80511008  .00000057  00000-0  00000+0 0  9994
2 33749   0.0367 306.3885 0002384 178.6394 296.7492  1.00272225 42031
EUTELSAT 33E            
1 33750U 09008B   20212.78521564  .00000141  00000-0  00000+0 0  9995
2 33750   0.0514  24.4855 0003654 108.0528 132.0589  1.00271616 42064
SPIRALE A               
1 33751U 09008C   20212.14622984  .00000104  00000-0  64529-3 0  9993
2 33751   1.9882 174.0301 5375496  50.9712 345.6783  4.58747263163246
SPIRALE B               
1 33752U 09008D   20211.28097514  .00001509  00000-0  34414-3 0  9993
2 33752   1.8995 115.9484 6913204 147.5219 284.4430  2.75995808105903
TELSTAR 11N             
1 34111U 09009A   20212.40216741 -.00000265  00000-0  00000+0 0  9993
2 34111   0.0249  75.6826 0002744  38.7628 301.2643  1.00269725 41884
NAVSTAR 63 (USA 203)    
1 34661U 09014A   20212.61922670 -.00000045  00000-0  00000-0 0  9994
2 34661  55.5500 296.8160 0109516  54.0050 270.5169  2.00558042 83209
EUTELSAT 10A            
1 34710U 09016A   20212.77056465  .00000054  00000-0  00000+0 0  9996
2 34710   0.0641  20.9802 0005265 109.9091 105.3426  1.00272570 41433
WGS F2 (USA 204)        
1 34713U 09017A   20212.03328876  .00000035  00000-0  00000+0 0  9996
2 34713   0.0155  89.3145 0000416  44.3685 246.6263  1.00270345 41402
RISAT 2                 
1 34807U 09019A   20213.22995370  .00002665  00000-0  70509-4 0  9990
2 34807  41.2138  43.9299 0007642 311.9877  11.8777 15.40416538633587
YAOGAN 6                
1 34839U 09021A   20212.97532806  .00000304  00000-0  13955-4 0  9990
2 34839  97.0375 218.8903 0026496 223.3540 136.5616 15.26963067626442
SES-7 (PROTOSTAR 2)     
1 34941U 09027A   20212.81368885 -.00000359  00000-0  00000+0 0  9990
2 34941   0.0238  66.0198 0000899 137.2940 146.7796  1.00268635 41093
MERIDIAN 2              
1 35008U 09029A   20212.11946299  .00000284  00000-0  83163-4 0  9993
2 35008  63.0385 203.2108 7269196 249.5445  23.7383  2.24583636 91636
MEASAT-3A               
1 35362U 09032A   20212.06896574 -.00000254  00000-0  00000-0 0  9997
2 35362   0.0601 248.3228 0002564 280.5655 255.5771  1.00269547 40690
GOES 14                 
1 35491U 09033A   20212.50629894 -.00000106  00000-0  00000-0 0  9995
2 35491   0.4217  97.8019 0009474 114.1759 174.4116  1.00270009 40584
SIRIUS FM-5             
1 35493U 09034A   20212.31901194 -.00000213  00000-0  00000-0 0  9992
2 35493   0.0239 311.3317 0000447 144.8895 240.8865  1.00270685 25169
TERRESTAR-1             
1 35496U 09035A   20212.50237476 -.00000070  00000-0  00000-0 0  9993
2 35496   2.5538  18.9649 0002643 114.8361 244.6342  1.00271136 40637
COSMOS 2451             
1 35498U 09036A   20212.76271272  .00000005  00000-0 -36038-4 0  9998
2 35498  82.4889 344.7621 0007199 190.2360 169.8573 12.40822558501376
COSMOS 2453             
1 35500U 09036C   20213.12867054  .00000027  00000-0  12888-3 0  9991
2 35500  82.4893 341.6469 0007445 153.3635 206.7827 12.41464194501686
DEIMOS-1                
1 35681U 09041A   20212.86578923 -.00000046  00000-0  79771-7 0  9991
2 35681  97.8063  84.6471 0000153 326.2206  33.8983 14.71842205590774
DUBAISAT-1              
1 35682U 09041B   20212.80663445  .00000223  00000-0  45946-4 0  9990
2 35682  97.7355  29.1614 0012892 105.6341 254.6304 14.68802171589483
UK-DMC 2                
1 35683U 09041C   20213.18548537  .00000046  00000-0  14940-4 0  9990
2 35683  97.6990  53.3407 0001350  77.6875 282.4482 14.71154598590603
APRIZESAT 4             
1 35684U 09041D   20212.86803587  .00000026  00000-0  11135-4 0  9995
2 35684  98.2771 192.9155 0047962  35.0833 325.3520 14.79283467593552
NANOSAT 1B              
1 35685U 09041E   20213.06122498  .00000062  00000-0  14451-4 0  9998
2 35685  98.2597 244.6818 0057964 327.1573  32.6042 14.84308187595348
APRIZESAT 3             
1 35686U 09041F   20212.72162420  .00000020  00000-0  90712-5 0  9999
2 35686  98.1796 271.0016 0071476 267.3037  91.9996 14.87399721596510
ASIASAT 5               
1 35696U 09042A   20212.85605542 -.00000322  00000-0  00000+0 0  9993
2 35696   0.0393 258.4500 0001292 198.4294 260.7039  1.00274801 40139
NAVSTAR 64 (USA 206)    
1 35752U 09043A   20210.97433993 -.00000055  00000-0  00000-0 0  9991
2 35752  54.6129 113.3553 0058703  46.2672 134.9948  2.00559568 80255
JCSAT-RA (JCSAT-12)     
1 35755U 09044A   20212.53417222 -.00000046  00000-0  00000-0 0  9996
2 35755   0.0507 117.7588 0002411  13.0135 179.0206  1.00271190 40074
OPTUS D3                
1 35756U 09044B   20212.82549375 -.00000162  00000-0  00000+0 0  9992
2 35756   0.0460 325.3710 0003144 144.2754 292.4161  1.00269887 40109
PALAPA D                
1 35812U 09046A   20211.83093591 -.00000371  00000-0  00000+0 0  9993
2 35812   0.2698  94.2837 0002794  62.7927 202.9219  1.00273172 40130
METEOR-M 1              
1 35865U 09049A   20212.99528273  .00000011  00000-0  23799-4 0  9997
2 35865  98.4406 198.1905 0003498  72.4394 287.7167 14.22244534564112
NIMIQ 5                 
1 35873U 09050A   20213.11848330 -.00000272  00000-0  00000+0 0  9997
2 35873   0.0182  69.9831 0003719  79.2661 129.9006  1.00268842 39815
OCEANSAT-2              
1 35931U 09051A   20213.17031279  .00000032  00000-0  19214-4 0  9990
2 35931  98.2991 307.7038 0000175 277.9002  82.2173 14.50840919574751
SWISSCUBE               
1 35932U 09051B   20212.77414413  .00000089  00000-0  31069-4 0  9995
2 35932  98.6114  36.4500 0007562   4.1104 356.0153 14.56410205576134
BEESAT-1                
1 35933U 09051C   20212.75429267  .00000077  00000-0  28309-4 0  9997
2 35933  98.6107  38.2967 0006095  19.5277 340.6152 14.56483948576205
ITUPSAT1                
1 35935U 09051E   20212.77445878  .00000066  00000-0  26321-4 0  9997
2 35935  98.6214  38.5115 0008356  15.4552 344.6898 14.55628302575975
AMAZONAS 2              
1 35942U 09054A   20213.11723400 -.00000297  00000-0  00000+0 0  9995
2 35942   0.0250 136.8876 0005981   1.3537 152.1556  1.00270664 39646
COMSATBW-1              
1 35943U 09054B   20212.78120743  .00000009  00000-0  00000+0 0  9999
2 35943   0.0351 103.5047 0003529  18.1962 171.4161  1.00275351 39727
WORLDVIEW-2 (WV-2)      
1 35946U 09055A   20213.18762843 -.00000207  00000-0 -54586-4 0  9996
2 35946  98.4033 285.3218 0001312 172.1347 187.9863 14.37669503567304
DMSP 5D-3 F18 (USA 210) 
1 35951U 09057A   20212.86333300  .00000058  00000-0  53662-4 0  9996
2 35951  98.6463 201.2981 0009891 242.0808 117.9363 14.13288476329812
NSS-12                  
1 36032U 09058A   20213.04808292  .00000057  00000-0  00000+0 0  9998
2 36032   0.0520 281.2516 0003133 211.5648 250.6040  1.00270658 39395
THOR 6                  
1 36033U 09058B   20213.07577562 -.00000025  00000-0  00000+0 0  9991
2 36033   0.0079 286.6442 0002113 209.8217 199.1152  1.00272297 39438
SMOS                    
1 36036U 09059A   20212.75573798  .00000071  00000-0  36467-4 0  9997
2 36036  98.4445  39.1551 0001097  95.2302 264.9011 14.39771628564451
PROBA-2                 
1 36037U 09059B   20212.79689138 -.00000010  00000-0  83027-5 0  9997
2 36037  98.2271  41.3768 0014603  67.9949 292.2797 14.53104732569533
SJ-11-01                
1 36088U 09061A   20213.06627406  .00000025  00000-0  14463-4 0  9999
2 36088  97.9890 244.4429 0010410 302.8883  57.1326 14.60161329570971
COSMOS 2455             
1 36095U 09063A   20213.01919684 -.00000064  00000-0  82474-5 0  9998
2 36095  67.1483  73.2389 0008724 281.2341  78.7763 13.96769173545439
INTELSAT 14 (IS-14)     
1 36097U 09064A   20212.75966017 -.00000288  00000-0  00000+0 0  9990
2 36097   0.0151  92.8524 0002655  33.3710  51.0901  1.00270963 39162
EUTELSAT 36B            
1 36101U 09065A   20212.78521564  .00000141  00000-0  00000+0 0  9991
2 36101   0.0642  20.1624 0004579 104.7029 142.5379  1.00272048 39274
INTELSAT 15 (IS-15)     
1 36106U 09067A   20212.76701017 -.00000199  00000-0  00000-0 0  9994
2 36106   0.0144  27.0928 0002565 100.0561 182.9525  1.00270067 19775
WGS F3 (USA 211)        
1 36108U 09068A   20213.06754206 -.00000112  00000-0  00000+0 0  9993
2 36108   0.0282  81.8871 0000600  69.8082 169.7579  1.00269943 38980
YAOGAN 7                
1 36110U 09069A   20213.10293591  .00000032  00000-0  12784-4 0  9994
2 36110  98.2650  49.7718 0024558  72.8555 287.5335 14.75131862572932
COSMOS 2456 [GLONASS-M] 
1 36111U 09070A   20212.68593259 -.00000029  00000-0  00000-0 0  9994
2 36111  64.2338 144.6113 0005377 307.7617 188.4510  2.13103063 82706
COSMOS 2457 [GLONASS-M] 
1 36112U 09070B   20212.49070477 -.00000028  00000-0  00000-0 0  9995
2 36112  64.2151 144.5106 0004557 183.2743 286.2077  2.13101606 82703
WISE                    
1 36119U 09071A   20212.82881038  .00000551  00000-0  21403-4 0  9997
2 36119  97.3253 248.5095 0001636 187.2682 172.8536 15.30165678590439
YAOGAN 8                
1 36121U 09072A   20212.81846375  .00000010  00000-0  15940-3 0  9993
2 36121 100.1786 171.3002 0021756 111.2169 249.1265 13.04956701508574
XIWANG-1 (HOPE-1)       
1 36122U 09072B   20212.72792264 -.00000053  00000-0 -29077-4 0  9997
2 36122 100.0954 201.2953 0008097 121.9880 238.2022 13.16355209510561
DIRECTV 12              
1 36131U 09075A   20212.59857264 -.00000117  00000-0  00000-0 0  9998
2 36131   0.0441 239.4337 0000345 163.8591  18.0740  1.00273120 25037
BEIDOU 3                
1 36287U 10001A   20212.72431866 -.00000295  00000-0  00000-0 0  9999
2 36287   1.4753  29.0039 0005583 110.2892 210.6802  1.00273999 38645
RADUGA-1M 2             
1 36358U 10002A   20212.06774443 -.00000197  00000-0  00000-0 0  9998
2 36358   0.0414 234.0577 0003038 260.1036 283.3746  1.00270844 28555
SDO                     
1 36395U 10005A   20212.25105606 -.00000103  00000-0  00000+0 0  9998
2 36395  30.3924 120.5899 0001648   4.8032 171.2310  1.00269127 38496
INTELSAT 16 (IS-16)     
1 36397U 10006A   20212.44409289 -.00000258  00000-0  00000-0 0  9998
2 36397   0.0120  30.3890 0002685 106.8329 254.9646  1.00269262 38326
COSMOS 2459 [GLONASS-M] 
1 36400U 10007A   20212.84308329 -.00000043  00000-0  10000-3 0  9997
2 36400  65.8943  26.4437 0024455 355.0928  56.1035  2.13105094 81068
COSMOS 2461 [GLONASS-M] 
1 36401U 10007B   20202.13250289 -.00000024  00000-0  00000+0 0  9994
2 36401  65.8861  26.7696 0009126  74.0721 307.9879  2.13102009 80835
COSMOS 2460 [GLONASS-M] 
1 36402U 10007C   20212.90901502 -.00000044  00000-0  10000-3 0  9999
2 36402  65.8753  26.3684 0002506 114.0238 294.3628  2.13101428 81063
GOES 15                 
1 36411U 10008A   20213.17537017  .00000040  00000-0  00000+0 0  9996
2 36411   0.0397 232.3001 0002738 213.4889 158.2029  1.00273643 38130
YAOGAN 9A               
1 36413U 10009A   20212.83952258 -.00000093  00000-0 -32801-5 0  9999
2 36413  63.3895 139.5816 0349639   8.6616  60.5335 13.45187142511079
YAOGAN 9B               
1 36414U 10009B   20213.19405440 -.00000059  00000-0  36534-4 0  9993
2 36414  63.3898 138.1986 0349436   8.0854 352.5587 13.45199637511298
YAOGAN 9C               
1 36415U 10009C   20212.97054268 -.00000123  00000-0 -37440-4 0  9997
2 36415  63.3883 139.4470 0350496   8.3690 352.2975 13.45201413511232
ECHOSTAR 14             
1 36499U 10010A   20212.58682295 -.00000013  00000-0  00000+0 0  9999
2 36499   0.0210  74.3590 0002916  57.1738 269.4911  1.00268086 38007
CRYOSAT 2               
1 36508U 10013A   20212.81232424 -.00000020  00000-0 -57413-5 0  9999
2 36508  92.0321 118.2560 0008243  70.5606 289.6492 14.51924498546568
SES-1                   
1 36516U 10016A   20212.59952987 -.00000128  00000-0  00000+0 0  9991
2 36516   0.0367 286.6330 0002720 195.7344 301.1284  1.00271279 37605
COSMOS 2463             
1 36519U 10017A   20212.90520254  .00000022  00000-0  82433-5 0  9996
2 36519  82.9593  27.0706 0035626 329.2611  30.6455 13.71446205513689
ASTRA 3B                
1 36581U 10021A   20212.05544417  .00000124  00000-0  00000-0 0  9991
2 36581   0.0347 344.5042 0003324 158.2876 208.8142  1.00275881 37333
COMSATBW-2              
1 36582U 10021B   20212.92808955  .00000074  00000-0  00000+0 0  9991
2 36582   0.0376  90.4255 0003061  45.0883 160.7752  1.00273315 37454
NAVSTAR 65 (USA 213)    
1 36585U 10022A   20211.57723626 -.00000046  00000-0  00000+0 0  9996
2 36585  55.2798 295.2247 0088942  52.9523 307.9123  2.00553590 74491
BADR-5                  
1 36592U 10025A   20212.05596758  .00000132  00000-0  00000-0 0  9991
2 36592   0.0169 121.0329 0005857  22.2458 210.9979  1.00275943 31674
SJ-12                   
1 36596U 10027A   20212.88919155 -.00000195  00000-0 -12027-4 0  9995
2 36596  97.6560 200.9875 0011987 165.4228 194.7341 14.96743469552283
TANDEM-X                
1 36605U 10030A   20212.50226852  .00000331  00000-0  18955-4 0  9990
2 36605  97.4463 218.9177 0002092  90.0905 332.7451 15.19155750560573
COMS 1                  
1 36744U 10032A   20212.48174341 -.00000358  00000-0  00000+0 0  9995
2 36744   0.0146  74.0163 0001150  50.5852 125.5217  1.00269436 37116
ARABSAT-5A              
1 36745U 10032B   20212.77466475  .00000139  00000-0  00000+0 0  9996
2 36745   0.0614  45.6941 0005045  99.5546 112.9483  1.00271335 37098
ECHOSTAR 15             
1 36792U 10034A   20213.11723365 -.00000297  00000-0  00000+0 0  9999
2 36792   0.0016 143.0488 0001936 330.8545 175.8575  1.00271786 36864
CARTOSAT-2B             
1 36795U 10035A   20213.08742862  .00000031  00000-0  11349-4 0  9990
2 36795  97.9174 271.8570 0012923 305.9297  54.0714 14.78687768542634
AISSAT 1                
1 36797U 10035C   20212.68915070  .00000111  00000-0  19275-4 0  9995
2 36797  98.1782   2.7186 0009951 239.6044 120.4188 14.86326930544419
ALSAT 2A                
1 36798U 10035D   20213.11638770  .00000015  00000-0  11129-4 0  9997
2 36798  97.9634 265.3955 0001731  91.1713 268.9709 14.66624493538150
TISAT 1                 
1 36799U 10035E   20213.04738598  .00000214  00000-0  27575-4 0  9992
2 36799  98.1975  25.0033 0012191 180.8458 179.2740 14.91390265545628
BEIDOU 5                
1 36828U 10036A   20212.84247723 -.00000112  00000-0  10000-3 0  9990
2 36828  54.1652 182.5706 0097818 234.7553 309.8752  1.00246010 36684
NILESAT 201             
1 36830U 10037A   20213.06365334 -.00000073  00000-0  00000+0 0  9999
2 36830   0.0495 189.0972 0005790 291.1807 204.7553  1.00271754 36759
RASCOM-QAF 1R           
1 36831U 10037B   20212.76916889  .00000004  00000-0  00000+0 0  9996
2 36831   0.0197  93.3058 0005308  34.8087 100.5172  1.00272676 36752
YAOGAN 10               
1 36834U 10038A   20213.06551958  .00000015  00000-0  87606-5 0  9991
2 36834  97.9768 216.0726 0001251  94.7481 265.3874 14.80980884539189
AEHF-1 (USA 214)        
1 36868U 10039A   20212.23004793  .00000010  00000-0  00000+0 0  9999
2 36868   2.8430  96.9219 0005947   8.2576 289.9150  1.00272515 36904
TIANHUI 1               
1 36985U 10040A   20212.82629206  .00000101  00000-0  72234-5 0  9993
2 36985  97.7235  38.1948 0011968 196.1387 291.6197 15.24492288552461
COSMOS 2464 [GLONASS-M] 
1 37139U 10041C   20212.81806236  .00000050  00000-0  00000-0 0  9995
2 37139  64.1580 264.3530 0025029   7.6436 279.7481  2.13100813 77120
CHINASAT 6A (ZX 6A)     
1 37150U 10042A   20212.66359574 -.00000368  00000-0  00000+0 0  9992
2 37150   0.0236 228.9528 0001772 292.3052 151.3956  1.00267899 36365
COSMOS 2467             
1 37152U 10043A   20212.72958153  .00000051  00000-0  29770-3 0  9997
2 37152  82.4587 328.2320 0002118 148.1484 211.9723 12.42896697448775
STRELA 3                
1 37153U 10043B   20212.75985519  .00000028  00000-0  13199-3 0  9999
2 37153  82.4588 336.0198 0008095  67.1400 293.0533 12.40738089448122
COSMOS 2468             
1 37154U 10043C   20212.75642189  .00000045  00000-0  25420-3 0  9992
2 37154  82.4626 326.6848 0013207 312.6245  47.3719 12.43658391449003
QZS-1 (MICHIBIKI-1)     
1 37158U 10045A   20211.68452316 -.00000205  00000-0  00000+0 0  9992
2 37158  41.6628 142.9996 0761648 270.8530 279.1804  1.00262104 36174
YAOGAN 11               
1 37165U 10047A   20212.83211073  .00000030  00000-0  12440-4 0  9994
2 37165  98.2813 320.2127 0037567 262.9728  96.7207 14.76229917530823
ZHEDA PIXING 1B         
1 37166U 10047B   20213.02072912  .00000081  00000-0  16938-4 0  9996
2 37166  97.6830 202.6267 0025045 112.3966 247.9909 14.80942584532237
ZHEDA PIXING 1C         
1 37167U 10047C   20213.04564837  .00000056  00000-0  13784-4 0  9999
2 37167  97.7081 198.3177 0024784 111.9216 248.4631 14.80815210532200
SBSS (USA 216)          
1 37168U 10048A   20213.13053382  .00000007  00000-0  75619-5 0  9991
2 37168  97.9066  73.5780 0002000 108.0643  49.9024 14.81579104532129
SJ-6G                   
1 37179U 10051A   20213.09354436 -.00000446  00000-0 -36722-4 0  9996
2 37179  97.6901 197.0091 0011750 247.8077 112.1899 14.93753316534888
SJ-6H                   
1 37180U 10051B   20213.10772130  .00000003  00000-0  56261-5 0  9993
2 37180  97.6963 196.3138 0010418 268.1409  91.8629 14.93376588534816
XM-5                    
1 37185U 10053A   20212.49672711 -.00000218  00000-0  00000-0 0  9999
2 37185   0.0269  73.0705 0002768 331.5634 357.5558  1.00267695 35959
GLOBALSTAR M079         
1 37188U 10054A   20212.81788003 -.00000103  00000-0  20060-5 0  9997
2 37188  52.0023 165.6139 0000339 345.7620  48.3564 12.62269613451563
GLOBALSTAR M074         
1 37189U 10054B   20212.84154686 -.00000106  00000-0 -14473-4 0  9999
2 37189  52.0001 165.1425 0000348 349.3474  31.9572 12.62265629452011
GLOBALSTAR M076         
1 37190U 10054C   20212.86970726 -.00000123  00000-0 -10567-3 0  9992
2 37190  52.0021 165.0650 0000747   4.9862  23.1853 12.62265022451822
GLOBALSTAR M077         
1 37191U 10054D   20213.01156319 -.00000116  00000-0 -67466-4 0  9998
2 37191  52.0070 120.9803 0000673  37.2427 335.9504 12.62266747452813
GLOBALSTAR M075         
1 37192U 10054E   20212.75593781 -.00000107  00000-0 -15678-4 0  9995
2 37192  51.9927 208.6559 0000287 335.7045 195.6728 12.62267864451121
GLOBALSTAR M073         
1 37193U 10054F   20212.80594250 -.00000113  00000-0 -49126-4 0  9996
2 37193  52.0060 120.9703 0000647  39.8287   0.4745 12.62269438452794
EUTE W3B                
1 37206U 10056A   20213.05660525  .00005387  00000-0  93830-3 0  9996
2 37206   2.5792  32.6670 7118713 106.4950 337.0039  2.49917087 85805
BSAT-3B                 
1 37207U 10056B   20212.84522035 -.00000365  00000-0  00000+0 0  9991
2 37207   0.0795 298.7855 0000210 102.0870 322.1717  1.00271262 35740
BEIDOU 6                
1 37210U 10057A   20212.70701918 -.00000127  00000-0  00000-0 0  9997
2 37210   0.8848  51.2029 0004941 111.3710 200.7540  1.00271205 35750
FENGYUN 3B              
1 37214U 10059A   20213.08868926 -.00000010  00000-0  18408-4 0  9994
2 37214  99.0492 195.5034 0017619 141.1101 219.1343 14.15953191503855
COSMO-SKYMED 4          
1 37216U 10060A   20212.86597656 -.00000644  00000-0 -74327-4 0  9998
2 37216  97.8861  35.3931 0001417  86.3487 273.7886 14.82151267526554
SKYTERRA 1              
1 37218U 10061A   20212.50939802 -.00000131  00000-0  00000-0 0  9998
2 37218   2.1406   1.9729 0002606 144.7162 243.9699  1.00270673 35672
ZHONGXING-20A           
1 37234U 10064A   20212.78524073 -.00000366  00000-0  00000-0 0  9999
2 37234   0.9647  96.6606 0004004  16.6192 244.6634  1.00272802 35440
HYLAS 1                 
1 37237U 10065A   20212.61938839 -.00000121  00000-0  00000-0 0  9993
2 37237   0.2294  95.1132 0004144  19.4378 217.5358  1.00271895 35447
INTELSAT 17 (IS-17)     
1 37238U 10065B   20212.91807788 -.00000016  00000-0  00000+0 0  9997
2 37238   0.0065 113.1327 0002974  33.5467 198.8178  1.00272768 35399
BEIDOU 7                
1 37256U 10068A   20213.15628571 -.00000224  00000-0  00000+0 0  9999
2 37256  51.4062 297.8177 0081570 212.9279 331.0707  1.00299764 35262
EUTELSAT KA-SAT 9A      
1 37258U 10069A   20212.77039019  .00000047  00000-0  00000+0 0  9994
2 37258   0.0324 315.8013 0005408 137.8659 141.5044  1.00272489 35283
HISPASAT 30W-5          
1 37264U 10070A   20213.12787813 -.00000231  00000-0  00000+0 0  9991
2 37264   0.0138 123.0483 0006111  11.1378 191.0655  1.00273540 35138
KOREASAT 6              
1 37265U 10070B   20212.00214303 -.00000375  00000-0  00000+0 0  9996
2 37265   0.0121  66.7392 0001294  69.6855 288.4308  1.00271458 35141
COSMOS 2471 [GLONASS-K] 
1 37372U 11009A   20212.47973433 -.00000042  00000-0  00000-0 0  9997
2 37372  65.8767  26.3222 0007986 217.7512  22.0599  2.13107489 73322
BEIDOU 8                
1 37384U 11013A   20212.83277917 -.00000195  00000-0  10000-3 0  9992
2 37384  59.3288  61.1564 0049465 201.5194 102.8149  1.00294087 34143
RESOURCESAT-2           
1 37387U 11015A   20213.13145102  .00000000  00000-0  19965-4 0  9995
2 37387  98.6780 287.2431 0002974 248.7738 111.3124 14.21646402481642
YOUTHSAT                
1 37388U 11015B   20212.91670698 -.00000025  00000-0  69416-5 0  9999
2 37388  98.4184 263.2171 0015237 158.9311  13.9320 14.24285329481879
X-SAT                   
1 37389U 11015C   20213.14002918 -.00000018  00000-0  10117-4 0  9995
2 37389  98.4326 266.7260 0014620 158.0284 202.1527 14.24379538482508
INTELSAT NEW DAWN       
1 37392U 11016A   20212.78521564  .00000141  00000-0  00000+0 0  9993
2 37392   0.0234 143.2537 0000861 308.4130 172.6362  1.00271903 33987
YAHSAT 1A               
1 37393U 11016B   20212.06120174  .00000088  00000-0  00000-0 0  9998
2 37393   0.0244  96.5994 0003085   5.8724 280.1870  1.00273198 20864
MERIDIAN 4              
1 37398U 11018A   20212.53491675 -.00000079  00000-0  10000-3 0  9999
2 37398  64.0052  28.1559 7290967 253.5498  21.0990  2.00627697 67693
SBIRS GEO-1 (USA 230)   
1 37481U 11019A   20212.66828396 -.00000019  00000-0  00000+0 0  9990
2 37481   2.0166 345.2183 0002287 142.7059 127.6045  1.00272384 33809
TELSTAR 14R             
1 37602U 11021A   20212.45127576 -.00000294  00000-0  00000+0 0  9992
2 37602   0.0342 313.0206 0002347 141.8093 313.1454  1.00271923 34256
GSAT-8                  
1 37605U 11022A   20212.67135628  .00000070  00000-0  00000-0 0  9999
2 37605   0.0184  80.2868 0001957 183.4963 341.7252  1.00273973 31688
ST-2                    
1 37606U 11022B   20212.06826785 -.00000223  00000-0  00000+0 0  9994
2 37606   0.0199  80.7423 0002084  56.5027 283.4808  1.00266303 33703
CHINASAT 10 (ZX 10)     
1 37677U 11026A   20212.85823177 -.00000366  00000-0  00000-0 0  9994
2 37677   0.0677 251.2313 0004872 263.8128 213.3179  1.00267868 33448
SJ-11-03                
1 37730U 11030A   20213.09196749 -.00000003  00000-0  82809-5 0  9994
2 37730  97.8784 252.4751 0008377 343.2247  16.8686 14.60826371483458
TIANLIAN 1-02           
1 37737U 11032A   20213.26836983 -.00000035  00000-0  00000+0 0  9995
2 37737   2.8210  75.2489 0015153  90.2361  51.4355  1.00269030 33193
GLOBALSTAR M083         
1 37739U 11033A   20212.89231875 -.00000099  00000-0  25190-4 0  9997
2 37739  51.9865  71.8727 0000461  80.6584 292.3244 12.62265062421981
GLOBALSTAR M088         
1 37740U 11033B   20212.73722198 -.00000108  00000-0 -25926-4 0  9994
2 37740  51.9751 339.3370 0000495 104.6053  43.7208 12.62264628417584
GLOBALSTAR M091         
1 37741U 11033C   20212.67193546 -.00000099  00000-0  27692-4 0  9997
2 37741  52.0004 299.9011 0000540 127.7044  22.3162 12.62267889418336
GLOBALSTAR M085         
1 37742U 11033D   20212.90099921 -.00000084  00000-0  10790-3 0  9990
2 37742  51.9847 251.8180 0000361 159.2141  12.9698 12.62266698418946
GLOBALSTAR M081         
1 37743U 11033E   20212.84793274 -.00000077  00000-0  15204-3 0  9993
2 37743  51.9940 253.6666 0000175 213.7723 317.2495 12.62268587418962
GLOBALSTAR M089         
1 37744U 11033F   20212.72942218 -.00000088  00000-0  85599-4 0  9992
2 37744  51.9902 208.5911 0000341  85.0406  85.8155 12.62266832419699
GSAT-12                 
1 37746U 11034A   20212.90343549 -.00000180  00000-0  00000-0 0  9990
2 37746   0.0135  93.4959 0010915   9.1246 254.6123  1.00271703 21967
SES-3                   
1 37748U 11035A   20212.59764463 -.00000116  00000-0  00000+0 0  9998
2 37748   0.0280 327.3826 0003008 156.2941 297.1419  1.00271875 33168
KAZSAT-2                
1 37749U 11035B   20212.06800615 -.00000211  00000-0  00000+0 0  9997
2 37749   0.0351 144.6608 0002649  31.2145 243.2541  1.00272255 33031
NAVSTAR 66 (USA 232)    
1 37753U 11036A   20212.70470156 -.00000057  00000-0  00000+0 0  9995
2 37753  56.2053  55.5714 0098942  45.5709 136.1497  2.00560333 66192
BEIDOU 9                
1 37763U 11038A   20212.90945186 -.00000053  00000-0  10000-3 0  9991
2 37763  54.4478 184.9752 0074096 225.8229 320.5367  1.00285491 33116
SJ-11-02                
1 37765U 11039A   20213.10877437  .00000002  00000-0  10443-4 0  9995
2 37765  98.4869  35.0045 0012225 107.3030 252.9507 14.61591673480204
ASTRA 1N                
1 37775U 11041A   20212.05457181  .00000107  00000-0  00000-0 0  9992
2 37775   0.0366  16.1532 0004496  68.8239 262.0121  1.00275053 22981
BSAT-3C (JCSAT-110R)    
1 37776U 11041B   20212.68070969 -.00000365  00000-0  00000-0 0  9994
2 37776   0.0130  89.2612 0000370  95.3326 119.1659  1.00270568 28957
PAKSAT-1R               
1 37779U 11042A   20212.84903459  .00000139  00000-0  00000+0 0  9992
2 37779   0.0496 246.8322 0004557 205.4713 200.2693  1.00274772 32742
HAIYANG-2A              
1 37781U 11043A   20213.07780214 -.00000013  00000-0  30618-4 0  9999
2 37781  99.3160 219.9496 0000616 134.4552 225.6650 13.78722735451059
NIGERIASAT-2            
1 37789U 11044B   20213.09193085 -.00000016  00000-0  55967-5 0  9992
2 37789  97.8919 259.0911 0011969  51.0041 309.2239 14.62112046477589
NIGERIASAT-X            
1 37790U 11044C   20213.14279269 -.00000011  00000-0  72483-5 0  9996
2 37790  97.9946 274.4937 0011048 239.5668 120.4448 14.58573095477223
RASAT                   
1 37791U 11044D   20213.09153346 -.00000002  00000-0  87041-5 0  9994
2 37791  98.1127 305.3396 0022957 130.5767 229.7437 14.64776080478635
APRIZESAT 5             
1 37792U 11044E   20213.09640803  .00000033  00000-0  13174-4 0  9992
2 37792  98.4097  53.6391 0058852  29.1475 331.2996 14.75401881481937
APRIZESAT 6             
1 37793U 11044F   20212.74513263  .00000024  00000-0  12419-4 0  9994
2 37793  98.4265  28.8998 0048091  67.3405 304.1145 14.72463139480804
CHINASAT 1A (ZX 1A)     
1 37804U 19001A   20212.83945111 -.00000352  00000-0  00000-0 0  9993
2 37804   0.0388 211.0775 0000495 251.3528 278.4894  1.00272993 32549
COSMOS 2473             
1 37806U 11048A   20213.06844479 -.00000125  00000-0  00000+0 0  9996
2 37806   0.1532 102.0445 0003487  26.9580 191.0240  1.00268779 32535
SES-2                   
1 37809U 11049A   20212.43814751 -.00000210  00000-0  00000+0 0  9995
2 37809   0.0579 275.5235 0002947 209.2782 254.4419  1.00272154 32467
ARABSAT-5C              
1 37810U 11049B   20212.05474628  .00000110  00000-0  00000-0 0  9999
2 37810   0.0597   3.4867 0004823 147.9478 196.4216  1.00269237 32371
EUTELSAT 7 WEST A       
1 37816U 11051A   20213.06365334 -.00000075  00000-0  00000+0 0  9993
2 37816   0.0597  25.5667 0007044 126.4066 172.7577  1.00271480 32430
TACSAT 4                
1 37818U 11052A   20212.07988601  .00000249  00000-0  37247-3 0  9993
2 37818  63.0880 109.6237 4625582 226.3430 329.7908  6.02638842194555
QUETZSAT 1              
1 37826U 11054A   20212.44336293 -.00000255  00000-0  00000+0 0  9990
2 37826   0.0115  43.8621 0003296  87.9388 259.3262  1.00271096 32563
INTELSAT 18 (IS-18)     
1 37834U 11056A   20212.70935058  .00000038  00000-0  00000+0 0  9992
2 37834   0.0246  74.2659 0002424  57.0783 252.8031  1.00270589 32327
EUTELSAT 16A            
1 37836U 11057A   20212.05396115  .00000091  00000-0  00000-0 0  9998
2 37836   0.0652   6.2203 0004787 122.6960 214.6653  1.00274701 24440
MEGHA-TROPIQUES         
1 37838U 11058A   20213.03528494  .00000267  00000-0  21145-5 0  9990
2 37838  19.9778 108.9417 0009197 320.8107  39.1631 14.09665291454132
JUGNU                   
1 37839U 11058B   20212.78638322  .00000302  00000-0  19024-4 0  9996
2 37839  19.9625  19.9773 0018895 145.9174 241.2274 14.12684237454926
SRMSAT                  
1 37841U 11058D   20212.86862347  .00000306  00000-0  23263-4 0  9998
2 37841  19.9727  84.7036 0011782   1.2305  89.9896 14.10664529454301
VIASAT-1                
1 37843U 11059A   20211.66883399 -.00000035  00000-0  00000-0 0  9998
2 37843   0.0248  74.8336 0002787  51.5524 307.0523  1.00269463 25770
GSAT0101 (GALILEO-PFM)  
1 37846U 11060A   20212.35947914 -.00000067  00000-0  00000+0 0  9998
2 37846  56.6564  40.1035 0002579  33.7422 326.3238  1.70475412 54635
GSAT0102 (GALILEO-FM2)  
1 37847U 11060B   20210.52503163 -.00000064  00000-0  00000+0 0  9997
2 37847  56.6557  40.1532 0002606 344.6674  15.6520  1.70475450 54605
SUOMI NPP               
1 37849U 11061A   20212.82410954 -.00000012  00000-0  14922-4 0  9996
2 37849  98.7374 150.1686 0001522  96.7850 340.4895 14.19540341453785
M-CUBED & EXP-1 PRIME   
1 37855U 11061F   20212.81647969  .00000194  00000-0  17947-4 0  9990
2 37855 101.7158 323.5788 0170508 306.2280  52.3273 15.06386323477738
COSMOS 2476 [GLONASS-M] 
1 37867U 11064A   20213.02367735 -.00000031  00000-0  00000+0 0  9998
2 37867  64.5314 145.6862 0020751 241.4332  54.6872  2.13101936 68059
COSMOS 2477 [GLONASS-M] 
1 37868U 11064B   20212.67450668 -.00000028  00000-0  00000-0 0  9994
2 37868  64.5415 145.7542 0018352 243.0862 323.6504  2.13101885 68007
COSMOS 2475 [GLONASS-M] 
1 37869U 11064C   20213.12111816 -.00000031  00000-0  00000+0 0  9998
2 37869  64.5477 145.7419 0022665 262.4834 244.3009  2.13102238 68029
YAOGAN 12               
1 37875U 11066B   20213.19452113  .00000551  00000-0  25972-4 0  9998
2 37875  97.3881 287.9064 0008490 349.2197  63.4270 15.23550021485665
CHUANGXIN 1-03 (CX-1-03)
1 37930U 11068A   20213.03112294 -.00000030  00000-0  56426-5 0  9990
2 37930  98.6364 200.3455 0013923 290.1408  69.8268 14.29784711456155
SHIYAN 4 (SY-4)         
1 37931U 11068B   20213.05947543  .00000026  00000-0  27134-4 0  9993
2 37931  98.6421 200.4493 0022987 322.3798  37.5774 14.29807653453830
ASIASAT 7               
1 37933U 11069A   20213.03467970 -.00000348  00000-0  00000+0 0  9991
2 37933   0.0130 114.4008 0001960 336.3592 336.2947  1.00272249 31933
YAOGAN 13               
1 37941U 11072A   20213.08781133  .00000448  00000-0  25128-4 0  9992
2 37941  97.5567 148.6511 0000073 251.5137 232.0162 15.18388402480597
BEIDOU 10               
1 37948U 11073A   20211.84203976 -.00000170  00000-0  10000-3 0  9991
2 37948  51.5041 297.4283 0070029 213.3398 195.4858  1.00286414 31865
LUCH 5A                 
1 37951U 11074B   20212.77341321 -.00000062  00000-0  00000-0 0  9992
2 37951   3.3591 113.7018 0004725   0.5436 280.1552  1.00275428 31652
SSOT                    
1 38011U 11076E   20213.06975765  .00000062  00000-0  14314-4 0  9996
2 38011  97.8638 289.4242 0001285  89.3615 270.7744 14.82012472466373
PLEIADES 1A             
1 38012U 11076F   20213.09012688 -.00000030  00000-0  33879-5 0  9990
2 38012  98.1986 286.9739 0001240  76.0552 284.0786 14.58550668459026
NIGCOMSAT 1R            
1 38014U 11077A   20212.78521564  .00000130  00000-0  00000+0 0  9990
2 38014   0.0248 131.5542 0002854 354.8275 147.6367  1.00272418 31492
ZIYUAN 1-02C (ZY 1-02C) 
1 38038U 11079A   20213.12088654  .00000012  00000-0  19967-4 0  9991
2 38038  98.6873 266.1982 0008047 131.9751 228.2128 14.35279729451032
GLOBALSTAR M084         
1 38040U 11080A   20212.86611105 -.00000101  00000-0  14510-4 0  9998
2 38040  52.0014  74.5243 0001039  60.0373 313.8419 12.62269979397805
GLOBALSTAR M080         
1 38041U 11080B   20212.91904390 -.00000104  00000-0 -25172-5 0  9997
2 38041  52.0065  75.3455 0001052  56.6603 316.5687 12.62263150397798
GLOBALSTAR M082         
1 38042U 11080C   20212.77389199 -.00000102  00000-0  90485-5 0  9992
2 38042  52.0088  31.0455 0000635 100.8953 272.4111 12.62267271398510
GLOBALSTAR M092         
1 38043U 11080D   20212.83335174 -.00000117  00000-0 -76160-4 0  9994
2 38043  52.0083 121.1398 0000759  70.7510 332.4780 12.62267865397072
GLOBALSTAR M090         
1 38044U 11080E   20212.90915250 -.00000113  00000-0 -48890-4 0  9997
2 38044  51.9903  26.7390 0000687  81.6922  67.5538 12.62264577398538
GLOBALSTAR M086         
1 38045U 11080F   20212.93679367 -.00000097  00000-0  38851-4 0  9993
2 38045  51.9911  27.0663 0001427  23.0323 132.4638 12.62263285398586
ZIYUAN 3-1 (ZY 3-1)     
1 38046U 12001A   20213.20942056  .00000360  00000-0  19120-4 0  9997
2 38046  97.4165 286.7774 0004398  49.1044   9.1334 15.21337945475277
FENGYUN 2F              
1 38049U 12002A   20212.67157201 -.00000372  00000-0  00000-0 0  9998
2 38049   2.1607  83.4361 0002133  80.1964 138.5718  1.00259565 31352
WGS F4 (USA 233)        
1 38070U 12003A   20212.54347035 -.00000229  00000-0  00000+0 0  9995
2 38070   0.0123 101.5290 0000550  24.1440 107.0040  1.00272227 31187
LARES                   
1 38077U 12006A   20212.71333275 -.00000051  00000-0 -79600-4 0  9996
2 38077  69.4942   0.3191 0011578 266.9222  93.0485 12.54930751387741
SES-4                   
1 38087U 12007A   20212.04637162 -.00000181  00000-0  00000+0 0  9992
2 38087   0.0130  32.6032 0002534  99.3683 170.8595  1.00272844 30949
BEIDOU 11               
1 38091U 12008A   20212.67123578  .00000040  00000-0  00000-0 0  9992
2 38091   1.5685  67.8972 0003176 299.3941 241.8576  1.00276184 30949
MUOS-1                  
1 38093U 12009A   20213.12145160 -.00000142  00000-0  00000+0 0  9990
2 38093   2.5294   9.1785 0052352   3.3771 240.3924  1.00272423 30924
INTELSAT 22 (IS-22)     
1 38098U 12011A   20212.82971010 -.00000073  00000-0  00000+0 0  9998
2 38098   0.0142  70.2515 0002280  74.5328 174.9059  1.00272512 30512
APSTAR 7                
1 38107U 12013A   20212.92269079 -.00000116  00000-0  00000-0 0  9996
2 38107   0.0287  95.6959 0002215  23.6326 238.3212  1.00270777 30637
YAHSAT 1B               
1 38245U 12016A   20212.07271383  .00000113  00000-0  00000-0 0  9995
2 38245   0.0250 100.3016 0002535  11.8905 269.7271  1.00270554 30383
BEIDOU 12               
1 38250U 12018A   20212.90932733 -.00000089  00000-0  10000-3 0  9995
2 38250  56.7389  16.2337 0020601 239.1756 113.6756  1.86234438 56292
BEIDOU 13               
1 38251U 12018B   20212.67495987 -.00000089  00000-0  00000-0 0  9991
2 38251  56.6653  15.6099 0011602 239.4662   1.6070  1.86232778 56294
AEHF-2 (USA 235)        
1 38254U 12019A   20213.11851681 -.00000291  00000-0  00000+0 0  9996
2 38254   2.0715  48.3331 0006947  66.7582 169.2458  1.00272553 30149
TIANHUI 1-02            
1 38256U 12020A   20213.18281516  .00000453  00000-0  22021-4 0  9992
2 38256  97.4209 336.2666 0015498 174.8068 305.4882 15.23364489457922
YAOGAN 14               
1 38257U 12021A   20213.19302291  .00000504  00000-0  24052-4 0  9992
2 38257  97.4510 344.9160 0002489  86.0674  36.1109 15.23523613459058
JCSAT-13                
1 38331U 12023A   20212.00371328 -.00000369  00000-0  00000+0 0  9995
2 38331   0.0025  54.5400 0002542  60.2276 318.6470  1.00272903 30030
VINASAT-2               
1 38332U 12023B   20211.99493667 -.00000342  00000-0  00000-0 0  9996
2 38332   0.0555 275.0073 0002270 215.0602 308.0270  1.00270483 25122
GCOM-W1 (SHIZUKU)       
1 38337U 12025A   20213.08994465  .00000004  00000-0  10882-4 0  9994
2 38337  98.2105 152.4670 0001473 129.0436 347.2190 14.57112176436358
ARIRANG-3 (KOMPSAT-3)   
1 38338U 12025B   20213.02097208  .00000042  00000-0  17769-4 0  9991
2 38338  98.1525 150.7240 0010005  13.1449 347.0011 14.61868176437697
SDS-4                   
1 38339U 12025C   20213.11975346  .00000008  00000-0  99646-5 0  9997
2 38339  98.3440 254.5120 0006245 284.5873  75.4639 14.70577566440133
NIMIQ 6                 
1 38342U 12026A   20212.43592086 -.00000187  00000-0  00000-0 0  9992
2 38342   0.0605 274.1978 0002944 205.5353 254.5997  1.00271212 28886
CHINASAT 2A (ZX 2A)     
1 38352U 12028A   20212.88250587 -.00000307  00000-0  00000-0 0  9995
2 38352   0.0567 249.0084 0001328 150.2652 325.6329  1.00270074 29847
YAOGAN 15               
1 38354U 12029A   20212.99069461 -.00000038  00000-0  18714-4 0  9995
2 38354 100.5833 352.4586 0026031 113.7218 246.6624 13.18257667392519
INTELSAT 19 (IS-19)     
1 38356U 12030A   20212.64471362 -.00000071  00000-0  00000+0 0  9999
2 38356   0.0120  22.3080 0003713 107.6640 216.8400  1.00270502 29989
NUSTAR                  
1 38358U 12031A   20212.67717682  .00000868  00000-0  16907-4 0  9994
2 38358   6.0272 315.0600 0011499 156.1979 203.8683 14.88320465442464
ECHOSTAR 17             
1 38551U 12035A   20212.59549792 -.00000089  00000-0  00000+0 0  9997
2 38551   0.0179  66.6959 0002051  61.4233 287.8242  1.00272301 29593
METEOSAT-10 (MSG-3)     
1 38552U 12035B   20212.26789542  .00000050  00000-0  00000+0 0  9998
2 38552   1.3732 341.9336 0001751 126.8868 305.5846  1.00265164 29684
SES-5                   
1 38652U 12036A   20212.09493928  .00000021  00000-0  00000+0 0  9999
2 38652   0.0483  93.1113 0002333  22.2776 231.9376  1.00271883 29525
KANOPUS-V 1             
1 38707U 12039A   20213.10415596  .00000603  00000-0  31847-4 0  9995
2 38707  97.4585 131.4653 0001698  78.2180  35.6087 15.19211508445137
BKA 2                   
1 38708U 12039B   20213.11823902  .00000190  00000-0  11931-4 0  9994
2 38708  97.3762 118.7105 0001232  92.9072  43.0949 15.19960743445122
EXACTVIEW-1 (ADS-1B)    
1 38709U 12039C   20213.10347826 -.00000027  00000-0  87643-5 0  9994
2 38709  99.1049 247.8781 0010113 226.1981 133.8360 14.24021999417066
TET-1                   
1 38710U 12039D   20212.83787194  .00001939  00000-0  34647-4 0  9998
2 38710  97.5993 242.7060 0003203  55.5481 304.6073 15.51423988450615
MKA-PN 1                
1 38711U 12039E   20212.85877098 -.00000015  00000-0  14077-4 0  9994
2 38711  99.1025 246.9986 0010848 226.5029 133.5248 14.24298403417082
TIANLIAN 1-03           
1 38730U 12040A   20212.73825832  .00000111  00000-0  00000-0 0  9996
2 38730   0.3258 105.4150 0003793 305.9955 183.5532  1.00275994 29459
COSMOS 2481             
1 38733U 12041A   20212.76660646  .00000018  00000-0  55701-4 0  9991
2 38733  82.4756 333.9698 0019255  74.6839 285.6367 12.42381592363172
GONETS-M 3              
1 38734U 12041B   20212.73966269  .00000034  00000-0  17099-3 0  9994
2 38734  82.4757 329.7004 0017071  84.2082 276.0950 12.44812985363549
GONETS-M 4              
1 38736U 12041D   20212.74632048  .00000043  00000-0  23729-3 0  9999
2 38736  82.4803 332.4081 0024948  66.0826 294.2856 12.43817313363405
INTELSAT 20 (IS-20)     
1 38740U 12043A   20213.10417515 -.00000039  00000-0  00000+0 0  9996
2 38740   0.0182  99.5642 0000721 113.1068 202.5144  1.00271990 29301
HYLAS 2                 
1 38741U 12043B   20212.05692718  .00000141  00000-0  00000+0 0  9993
2 38741   0.0498 266.0869 0002084 208.6513 244.9206  1.00269295 29318
TELKOM 3                
1 38744U 12044A   20213.12189290  .00158399  12904-4  81763-3 0  9990
2 38744  49.8622 117.9333 0545351   0.4657 359.6685 14.84870370360699
EXPRESS-MD2             
1 38745U 12044B   20212.68508804  .00009991  31723-5  26250-3 0  9991
2 38745  49.8863   1.4411 2206718 153.1657 220.2870 11.04106515312137
INTELSAT 21 (IS-21)     
1 38749U 12045A   20212.33304799 -.00000298  00000-0  00000-0 0  9998
2 38749   0.0154  87.1008 0002283  39.7088 243.4926  1.00270232 29005
SPOT 6                  
1 38755U 12047A   20213.17372673 -.00000034  00000-0  24246-5 0  9997
2 38755  98.1642 279.2170 0001126 100.4284 259.7046 14.58570496420117
AEROCUBE 4.5A           
1 38767U 12048K   20212.83791723  .00000223  00000-0  33650-4 0  9991
2 38767  64.6666 100.6628 0137308 120.9736 240.4951 14.92323682 48276
AEROCUBE 4.5B           
1 38768U 12048L   20212.87426974  .00000516  00000-0  53759-4 0  9996
2 38768  64.6671  68.3268 0126628 116.4502 244.9668 14.96007769 48371
METOP-B                 
1 38771U 12049A   20212.85919638 -.00000022  00000-0  99374-5 0  9990
2 38771  98.6887 271.4976 0000683 155.9329 324.7892 14.21501056408193
BEIDOU 15               
1 38775U 12050B   20212.96600514 -.00000055  00000-0  00000+0 0  9996
2 38775  55.0769 134.8281 0016043 317.9389 314.6559  1.86232396 53679
ASTRA 2F                
1 38778U 12051A   20212.05640376  .00000137  00000-0  00000-0 0  9991
2 38778   0.1044 251.7443 0003314 293.8779 171.0369  1.00276831 19629
GSAT-10                 
1 38779U 12051B   20212.73079968 -.00000179  00000-0  00000-0 0  9997
2 38779   0.0874 272.9702 0001133 151.6966 230.2407  1.00270612 28586
VRSS-1                  
1 38782U 12052A   20213.08170249  .00000018  00000-0  96713-5 0  9997
2 38782  97.7602 271.8644 0017786  25.0496 335.1581 14.76003597422622
NAVSTAR 67 (USA 239)    
1 38833U 12053A   20210.94796218  .00000082  00000-0  00000-0 0  9997
2 38833  53.6419 231.6197 0096843  37.2163 323.5040  2.00566432 56440
GSAT0103 (GALILEO-FM3)  
1 38857U 12055A   20211.22815657  .00000011  00000-0  00000-0 0  9998
2 38857  54.9861 160.6321 0004304 273.8696  86.0421  1.70473707 48523
GSAT0104 (GALILEO-FM4)  
1 38858U 12055B   20212.33568894 -.00000001  00000-0  00000-0 0  9990
2 38858  54.9871 160.6010 0003197 283.0748  76.8442  1.70473702 48547
SJ-9A                   
1 38860U 12056A   20213.09714832  .00000041  00000-0  12106-4 0  9990
2 38860  97.6732 258.8647 0030715  92.9722 267.5018 14.79555321420892
SJ-9B                   
1 38861U 12056B   20213.07325714  .00000025  00000-0  95937-5 0  9992
2 38861  97.6918 266.2702 0019738  90.2611 270.0868 14.81523086421163
INTELSAT 23 (IS-23)     
1 38867U 12057A   20212.42954622 -.00000298  00000-0  00000-0 0  9998
2 38867   0.0180  95.4812 0001996  27.9030 286.7778  1.00271529 28460
BEIDOU 16               
1 38953U 12059A   20212.64607311 -.00000190  00000-0  00000-0 0  9991
2 38953   1.2066  83.9745 0006943 276.1469 265.1088  1.00275129 28511
LUCH 5B                 
1 38977U 12061A   20212.83712565 -.00000152  00000-0  00000+0 0  9996
2 38977   5.8361  67.3694 0003411  44.9277 121.6254  1.00271234 28647
YAMAL 300K              
1 38978U 12061B   20212.64207097  .00000057  00000-0  00000+0 0  9998
2 38978   0.0132  21.1280 0001423 229.9037 111.7602  1.00271489 28346
STAR ONE C3             
1 38991U 12062A   20212.44483699 -.00000262  00000-0  00000-0 0  9996
2 38991   0.0392 268.5041 0002521 222.8706 262.2882  1.00271974 28224
EUTELSAT 21B            
1 38992U 12062B   20213.05501097  .00000116  00000-0  00000+0 0  9994
2 38992   0.0653   8.0091 0002501 116.4610 226.0803  1.00275669 28346
MERIDIAN 6              
1 38995U 12063A   20211.58937392  .00000164  00000-0  00000-0 0  9999
2 38995  65.3361 225.7520 6473159 261.9108  25.3784  2.00597346 56450
HUANJING 1C (HJ-1C)     
1 38997U 12064A   20213.14907692  .00001344  00000-0  32953-4 0  9993
2 38997  97.1517 242.4691 0008859 198.0411 233.0812 15.42437619432527
FENGNIAO 1 (FN-1)       
1 38998U 12064B   20213.06089664  .00000970  00000-0  23860-4 0  9996
2 38998  97.2244 243.8063 0004257 242.7938 117.2877 15.43075859431565
ECHOSTAR 16             
1 39008U 12065A   20212.45201885 -.00000296  00000-0  00000+0 0  9991
2 39008   0.0163  79.0150 0002134  49.1267 281.6056  1.00270589 28272
YAOGAN 16A              
1 39011U 12066A   20212.94830631 -.00000197  00000-0 -14850-3 0  9990
2 39011  63.3714  64.4548 0251824  12.2129 348.4850 13.45195570377289
YAOGAN 16B              
1 39012U 12066B   20212.94868478 -.00000248  00000-0 -22007-3 0  9991
2 39012  63.3721  64.5453 0251594  12.0503 348.6392 13.45193847377291
YAOGAN 16C              
1 39013U 12066C   20212.94812565 -.00000249  00000-0 -22187-3 0  9998
2 39013  63.3729  63.8817 0251466  11.7793 348.8968 13.45199943377318
CHINASAT 12 (ZX 12)     
1 39017U 12067A   20212.46425545 -.00000220  00000-0  00000+0 0  9992
2 39017   0.0174 100.8037 0003716  22.6049  79.7541  1.00270258 28179
PLEIADES 1B             
1 39019U 12068A   20213.12449838 -.00000030  00000-0  33660-5 0  9993
2 39019  98.1973 287.0582 0001319  91.1388 268.9959 14.58552623407883
EUTELSAT 70B            
1 39020U 12069A   20212.78269044 -.00000058  00000-0  00000+0 0  9992
2 39020   0.0601   7.2597 0006258 156.8772 136.9708  1.00271828 28070
YAMAL 402               
1 39022U 12070A   20212.67135628  .00000071  00000-0  00000-0 0  9999
2 39022   0.0145 105.4967 0003128  26.3879 113.4507  1.00273416 27867
GOKTURK 2               
1 39030U 12073A   20212.99594312  .00000005  00000-0  83815-5 0  9992
2 39030  97.9451  99.7399 0002303  44.9114 315.2283 14.72758257408317
SKYNET 5D               
1 39034U 12075A   20213.03687920  .00000084  00000-0  00000+0 0  9998
2 39034   0.0757 351.8935 0003999 147.3851 235.8926  1.00276276 27996
MEXSAT 3                
1 39035U 12075B   20212.58983272 -.00000039  00000-0  00000+0 0  9998
2 39035   0.0235  77.9009 0003003  54.8125 273.4844  1.00268992 27946
COSMOS 2482             
1 39057U 13001A   20212.72063542  .00000072  00000-0  44855-3 0  9993
2 39057  82.4976 116.2850 0029727  28.8565 331.4151 12.42908787342001
COSMOS 2483             
1 39058U 13001B   20212.74176161  .00000013  00000-0  23208-4 0  9993
2 39058  82.4988 113.2123 0017898  26.2188 333.9794 12.43958989342284
COSMOS 2484             
1 39059U 13001C   20212.75721944  .00000029  00000-0  13797-3 0  9998
2 39059  82.5018 117.0839 0026467  39.9882 320.3140 12.42891477341999
TDRS 11                 
1 39070U 13004A   20213.15232390  .00000070  00000-0  00000+0 0  9996
2 39070   3.7694 332.8008 0004535  70.8104 145.9902  1.00271794 27450
GLOBALSTAR M097         
1 39072U 13005A   20212.61270323 -.00000098  00000-0  34562-4 0  9993
2 39072  52.0052 300.7846 0000259  79.8731 280.2165 12.62264876345770
GLOBALSTAR M093         
1 39073U 13005B   20212.82348133 -.00000100  00000-0  22643-4 0  9993
2 39073  51.9816 296.3011 0000260 178.1383 181.9485 12.62264681345764
GLOBALSTAR M094         
1 39074U 13005C   20213.14046416 -.00000097  00000-0  40436-4 0  9994
2 39074  51.9932 207.9362 0000101 287.4564  72.6292 12.62265340347341
GLOBALSTAR M096         
1 39075U 13005D   20212.83681866 -.00000084  00000-0  10886-3 0  9995
2 39075  52.0056 255.9818 0000201 188.3894 171.6969 12.62265879346534
GLOBALSTAR M078         
1 39076U 13005E   20212.81017826 -.00000136  00000-0 -18239-3 0  9992
2 39076  51.9896 342.9942 0001095 152.4392 207.6533 12.62260675345037
GLOBALSTAR M095         
1 39077U 13005F   20212.75744460 -.00000106  00000-0 -13264-4 0  9998
2 39077  51.9817 341.2408 0000729 150.1047 209.9861 12.62266942345037
AMAZONAS 3              
1 39078U 13006A   20212.45201869 -.00000296  00000-0  00000-0 0  9997
2 39078   0.0637 244.8471 0003265 280.8069 244.5850  1.00269823 23356
AZERSPACE 1             
1 39079U 13006B   20212.77780524  .00000119  00000-0  00000+0 0  9995
2 39079   0.0297 285.9152 0001945 218.8586 130.0789  1.00273387 27397
LANDSAT 8               
1 39084U 13008A   20213.07246415  .00000018  00000-0  14008-4 0  9999
2 39084  98.2246 282.2286 0001609  97.6504 262.4880 14.57107751397056
SARAL                   
1 39086U 13009A   20213.12615150 -.00000008  00000-0  13159-4 0  9990
2 39086  98.5441  38.0215 0002026 105.3125 254.8282 14.32030170388253
SAPPHIRE                
1 39088U 13009C   20212.80836760  .00000008  00000-0  18109-4 0  9997
2 39088  98.4676  53.3127 0011900 151.6978 208.4856 14.34214736388691
NEOSSAT                 
1 39089U 13009D   20212.79790921  .00000031  00000-0  26360-4 0  9998
2 39089  98.4651  53.7791 0011799 153.2827 206.8969 14.34515949388768
STRAND-1                
1 39090U 13009E   20212.58465876  .00000020  00000-0  21904-4 0  9992
2 39090  98.4536  55.9086 0009664 149.6744 210.5003 14.35138478388870
SBIRS GEO-2 (USA 241)   
1 39120U 13011A   20213.05501097  .00000110  00000-0  00000+0 0  9997
2 39120   2.0896 344.9860 0002573 139.9853 224.5622  1.00272508 27064
EUTELSAT 117 WEST A     
1 39122U 13012A   20212.49833097 -.00000026  00000-0  00000-0 0  9993
2 39122   0.0317 311.6739 0003280 174.4863 245.0065  1.00271662 26900
ANIK G1                 
1 39127U 13014A   20212.50437848 -.00000088  00000-0  00000+0 0  9993
2 39127   0.0338 268.3123 0000995 237.9172 236.6337  1.00270771 26718
SOMP                    
1 39134U 13015E   20213.09762062  .00000817  00000-0  45480-4 0  9991
2 39134  64.8631 136.2747 0030163 236.4396 123.3851 15.21145972402697
BEESAT-3                
1 39135U 13015F   20212.58515912  .00000504  00000-0  34098-4 0  9991
2 39135  64.8667 162.1096 0035032 236.5342 123.2435 15.18249957401762
BEESAT-2                
1 39136U 13015G   20212.84787703  .00000722  00000-0  45274-4 0  9990
2 39136  64.8712 166.3815 0033108 275.1051  84.6297 15.17641161401709
GAOFEN 1                
1 39150U 13018A   20213.16730435 -.00000004  00000-0  66389-5 0  9990
2 39150  97.8675 288.8716 0018544 341.6626  18.3919 14.76549734391466
NEE-01 PEGASO           
1 39151U 13018B   20213.00353151  .00000075  00000-0  16920-4 0  9992
2 39151  97.9752 311.5235 0016056 323.2478  36.7631 14.80185052392124
COSMOS 2485 [GLONASS-M] 
1 39155U 13019A   20213.17707006 -.00000032  00000-0  00000+0 0  9998
2 39155  64.6998 145.9590 0021324 237.0587 221.3042  2.13102062 56534
CHINASAT 11 (ZX 11)     
1 39157U 13020A   20212.88264236 -.00000306  00000-0  00000-0 0  9997
2 39157   0.0425 263.2117 0001202 142.5514 318.9411  1.00273178 26360
PROBA-V                 
1 39159U 13021A   20213.10229714 -.00000029  00000-0  56173-5 0  9997
2 39159  98.4585 268.8844 0003133 304.6528  55.4355 14.22988188375717
VNREDSAT 1              
1 39160U 13021B   20213.08776423  .00000025  00000-0  14124-4 0  9994
2 39160  98.1469 289.0853 0001555  97.1720 262.9660 14.62940233386265
ESTCUBE 1               
1 39161U 13021C   20213.11504467  .00000119  00000-0  26615-4 0  9998
2 39161  97.9649 295.2704 0010828  98.3871 261.8568 14.72335400388464
EUTELSAT 7B             
1 39163U 13022A   20212.76995400  .00000034  00000-0  00000+0 0  9993
2 39163   0.0576 352.8399 0002989  59.1745 180.9935  1.00271919 26430
NAVSTAR 68 (USA 242)    
1 39166U 13023A   20211.30518863 -.00000090  00000-0  00000-0 0  9994
2 39166  56.0299 355.1369 0082005  31.9072 328.5892  2.00564569 52783
WGS F5 (USA 243)        
1 39168U 13024A   20212.47044624 -.00000298  00000-0  00000-0 0  9995
2 39168   0.0171  62.4507 0000469 103.2445 259.6842  1.00272304 25642
SES-6                   
1 39172U 13026A   20212.76053253 -.00000276  00000-0  00000+0 0  9997
2 39172   0.0501 286.0922 0002247 187.7240  68.2874  1.00269798 26187
COSMOS 2486             
1 39177U 13028A   20213.10145137 -.00000044  00000-0 -33229-6 0  9996
2 39177  97.9871 254.0886 0010178 222.6562 137.3853 14.54713049379114
RESURS P1               
1 39186U 13030A   20213.18040091  .00000508  00000-0  17941-4 0  9994
2 39186  97.2223 277.2908 0015957 308.9451 127.8308 15.33596745397181
O3B FM5                 
1 39188U 13031A   20211.82943487 -.00000024  00000-0  00000-0 0  9995
2 39188   0.0341  26.2467 0003395  97.8381 235.9446  5.00116274129643
O3B FM4                 
1 39189U 13031B   20211.28325984 -.00000023  00000-0  00000-0 0  9991
2 39189   0.0580   7.2428 0002874 125.1667 227.6123  5.00115941129648
O3B FM2                 
1 39190U 13031C   20212.14730739 -.00000025  00000-0  00000+0 0  9998
2 39190   0.0631   6.3062 0004117 219.3012 134.3560  5.00116041129644
O3B PFM                 
1 39191U 13031D   20212.00094740 -.00000025  00000-0  00000-0 0  9990
2 39191   0.0405  16.0394 0003078 119.5077 224.4748  5.00115398129561
COSMOS 2487             
1 39194U 13032A   20213.07828444  .00002191  00000-0  53724-4 0  9998
2 39194  74.7115 313.2379 0001069 117.4303 242.7018 15.41355271396798
IRIS                    
1 39197U 13033A   20213.11772375  .00000065  00000-0  15909-4 0  9999
2 39197  97.9597  34.7317 0029134  88.8518 271.6031 14.78741241382551
IRNSS-1A                
1 39199U 13034A   20208.75982554  .00000063  00000-0  00000+0 0  9997
2 39199  30.9373  96.1503 0017292 183.5970 353.9287  1.00279865 26076
SJ-11-05                
1 39202U 13035A   20213.07471223  .00000030  00000-0  15532-4 0  9995
2 39202  98.1139  17.8444 0012286 111.2690 248.9823 14.60870844375447
MUOS-2                  
1 39206U 13036A   20212.63641168  .00000054  00000-0  00000-0 0  9994
2 39206   2.6014 358.2091 0057016 177.3090 185.2814  1.00270211 25661
SHIYAN 7 (SY-7)         
1 39208U 13037A   20212.83788472  .00000038  00000-0  14898-4 0  9995
2 39208  98.0048 213.6603 0009622 128.5717 231.6351 14.68373206376786
CHUANGXIN-3 (CX-3)      
1 39209U 13037B   20213.06209944  .00000073  00000-0  20967-4 0  9997
2 39209  98.0089 213.7328 0005885 119.2635 240.9161 14.68415173376782
SHIJIAN-15 (SJ-15)      
1 39210U 13037C   20212.88557781  .00000036  00000-0  14627-4 0  9998
2 39210  98.0124 212.6759 0008046 120.0635 240.1369 14.67707882379079
ALPHASAT                
1 39215U 13038A   20212.79077271  .00000121  00000-0  00000+0 0  9995
2 39215   2.4648  19.9138 0002484 119.6025 118.8822  1.00272033 25734
INSAT-3D                
1 39216U 13038B   20212.90606642 -.00000171  00000-0  00000-0 0  9996
2 39216   0.0283  79.1076 0001845  47.0820 231.0673  1.00270483 25571
WGS F6 (USA 244)        
1 39222U 13041A   20212.63343671  .00000075  00000-0  00000-0 0  9999
2 39222   0.0160  77.6826 0000299  26.0902 297.7461  1.00270421 24517
ARIRANG-5 (KOMPSAT-5)   
1 39227U 13042A   20213.09349953 -.00000469  00000-0 -29191-4 0  9990
2 39227  97.6237  36.6464 0001399  84.6406  27.9059 15.04504999381086
EUTELSAT 25B            
1 39233U 13044A   20212.77370515  .00000129  00000-0  00000+0 0  9995
2 39233   0.0495 354.0518 0002039 131.5667 127.2390  1.00272962 25368
GSAT-7                  
1 39234U 13044B   20213.04565851 -.00000092  00000-0  00000+0 0  9996
2 39234   0.0618 264.9561 0005501 347.5806 147.0279  1.00271648 25371
AMOS-4                  
1 39237U 13045A   20213.11332547 -.00000008  00000-0  00000+0 0  9999
2 39237   0.0114  80.6428 0002077  23.0074 311.3197  1.00272194 25369
YAOGAN 17A              
1 39239U 13046A   20212.99249984  .00000085  00000-0  25157-3 0  9997
2 39239  63.4017 217.4757 0234359   4.6681 355.6456 13.45197006339536
YAOGAN 17B              
1 39240U 13046B   20212.91843479 -.00000064  00000-0  37244-4 0  9999
2 39240  63.4033 217.8255 0234292   4.4438 355.8598 13.45196937339527
YAOGAN 17C              
1 39241U 13046C   20212.99255380 -.00000045  00000-0  64376-4 0  9991
2 39241  63.4034 216.7043 0235084   4.3701 355.9305 13.45196470339556
GONETS-M 5              
1 39249U 13048A   20213.18111612  .00000016  00000-0  46886-4 0  9995
2 39249  82.4787 241.7946 0015185 215.1263 144.8816 12.42875037312320
GONETS-M 6              
1 39250U 13048B   20212.74572082  .00000025  00000-0  10865-3 0  9997
2 39250  82.4824 242.6710 0017019 249.7888 110.1361 12.42943428312283
GONETS-M 7              
1 39251U 13048C   20212.75327253  .00000004  00000-0 -40173-4 0  9991
2 39251  82.4871 243.7979 0014504 211.9540 148.0661 12.42872074312255
HISAKI (SPRINT-A)       
1 39253U 13049A   20212.74965973  .00000099  00000-0  43006-4 0  9991
2 39253  29.7217 334.2498 0137296 252.7781 105.7722 13.55224909340927
AEHF-3 (USA 246)        
1 39256U 13050A   20212.47272844 -.00000369  00000-0  00000+0 0  9997
2 39256   0.5891   4.0311 0002622 113.2381 112.5322  1.00275293 25147
FENGYUN 3C              
1 39260U 13052A   20213.15862754 -.00000015  00000-0  13741-4 0  9994
2 39260  98.5470 261.7699 0012323  91.0082 269.2504 14.15842340354806
CASSIOPE                
1 39265U 13055A   20212.85216970  .00002761  00000-0  77002-4 0  9990
2 39265  80.9667 269.0369 0660351 175.9163 184.7816 14.29558857354002
POPACS 1                
1 39268U 13055D   20213.09337698  .00003248  00000-0  86559-4 0  9999
2 39268  80.9999 250.0244 0616838  92.6270 274.5639 14.39589675355746
POPACS 2                
1 39269U 13055E   20212.53282375  .00002280  00000-0  64868-4 0  9993
2 39269  81.0150 289.6188 0677205 195.2113 162.7791 14.25583367352127
POPACS 3                
1 39270U 13055F   20212.68979804  .00000669  00000-0  20792-4 0  9998
2 39270  81.0183 311.7154 0708522 258.6577  93.4247 14.16953337351954
ASTRA 2E                
1 39285U 13056A   20212.77431580  .00000136  00000-0  00000+0 0  9993
2 39285   0.0605  37.0427 0001869  84.7011 134.3274  1.00271490 25058
SHIJIAN-16 (SJ-16)      
1 39358U 13057A   20213.05237265  .00000078  00000-0  12239-4 0  9998
2 39358  74.9744  74.2707 0018143   7.5576 352.5877 14.85666985367055
SIRIUS FM-6             
1 39360U 13058A   20211.66865953 -.00000029  00000-0  00000-0 0  9994
2 39360   0.0092 296.0766 0000699  35.5094 100.7285  1.00271118 24606
YAOGAN 18               
1 39363U 13059A   20213.18139753  .00018528  00000-0  12711-3 0  9996
2 39363  97.2464 265.0479 0023020  45.2699  31.5713 15.74386260374638
RADUGA-1M 3             
1 39375U 13062A   20212.22597383  .00000142  00000-0  10000-3 0  9996
2 39375   0.5365  97.9389 0005951 322.8049   3.8524  1.00271264 24596
STPSAT-3                
1 39380U 13064A   20212.84099403  .00000481  00000-0  18801-4 0  9996
2 39380  40.4992 185.1179 0004184 271.7699  88.2637 15.37681735373466
YAOGAN 19               
1 39410U 13065A   20213.06375275  .00000024  00000-0  17837-3 0  9990
2 39410 100.1684 260.3496 0007940   7.4439 352.6766 13.15263381321370
APRIZESAT 7             
1 39416U 13066A   20212.73024634  .00000067  00000-0  13981-4 0  9994
2 39416  97.5270 219.0739 0042736 100.9026 259.7002 14.83974292362180
ZACUBE-1 (TSHEPISOSAT)  
1 39417U 13066B   20212.73245908  .00000138  00000-0  23575-4 0  9993
2 39417  97.5659 208.3503 0059951 134.8643 225.7463 14.81295967361445
SKYSAT-1                
1 39418U 13066C   20213.13491165 -.00000458  00000-0 -32774-4 0  9995
2 39418  97.6222 291.6934 0022364  52.9594 307.3670 14.98978222365822
DUBAISAT-2              
1 39419U 13066D   20213.11783071  .00000633  00000-0  64157-4 0  9997
2 39419  97.5393 265.4588 0015303  63.7320 296.5472 14.94145129364698
OPTOS                   
1 39420U 13066E   20212.85564092  .00000168  00000-0  37794-4 0  9999
2 39420  97.7761 156.9198 0137772  10.2894 350.1078 14.62386942356909
STSAT-3                 
1 39422U 13066G   20212.83038026  .00000125  00000-0  18377-4 0  9991
2 39422  97.5090 243.3859 0024277  51.8916 308.4487 14.89844541363596
WNISAT-1                
1 39423U 13066H   20212.76781619 -.00000029  00000-0  37147-5 0  9998
2 39423  97.8001 131.2970 0176112 120.2321 241.6444 14.53127209354719
APRIZESAT 8             
1 39425U 13066K   20212.74859905  .00000035  00000-0  10742-4 0  9999
2 39425  97.5557 209.5572 0053082 130.3420 230.2448 14.81189926361309
TRITON-1                
1 39427U 13066M   20212.89621981  .00000126  00000-0  28478-4 0  9994
2 39427  97.7287 171.4200 0112336 299.5843  59.4206 14.68025686358220
GOMX-1                  
1 39430U 13066Q   20213.06806813  .00000162  00000-0  37938-4 0  9998
2 39430  97.7923 148.9644 0152189  47.5100 313.8841 14.59416772356162
FIRST-MOVE              
1 39439U 13066Z   20212.97804695  .00000344  00000-0  47824-4 0  9999
2 39439  97.5732 209.3108 0066323 133.4074 227.2688 14.82174246361417
CUBEBUG-2 (LO-74)       
1 39440U 13066AA  20212.87583326  .00000123  00000-0  24072-4 0  9990
2 39440  97.6333 192.9852 0079488 198.1980 161.6383 14.76031929358984
NEE-02 KRYSAOR          
1 39441U 13066AB  20212.91677423  .00000062  00000-0  15987-4 0  9995
2 39441  97.6409 190.5174 0080848 207.3327 152.3618 14.75031238359970
FUNCUBE-1 (AO-73)       
1 39444U 13066AE  20212.70527573  .00000191  00000-0  29525-4 0  9991
2 39444  97.5599 210.5787 0059199 127.0704 233.5943 14.82153222360361
UWE-3                   
1 39446U 13066AG  20212.92550803  .00000127  00000-0  23416-4 0  9991
2 39446  97.6002 200.2025 0070193 166.7114 193.5968 14.78565174359588
SWARM B                 
1 39451U 13067A   20212.86530754  .00000247  00000-0  93271-5 0  9994
2 39451  87.7499 266.8181 0003913  78.2322 281.9362 15.21760944356565
SWARM A                 
1 39452U 13067B   20212.86205355  .00000802  00000-0  16223-4 0  9999
2 39452  87.3621 114.9342 0002502 100.4776 259.6764 15.44624657375884
SWARM C                 
1 39453U 13067C   20212.86200143  .00000800  00000-0  16174-4 0  9993
2 39453  87.3474 115.8033 0002498 100.4259 259.7280 15.44625472375844
SHIYAN 5 (SY-5)         
1 39455U 13068A   20213.09072063  .00000033  00000-0  21651-4 0  9994
2 39455  98.1008 142.8447 0012552 342.6671  17.4093 14.43943923352071
SES-8                   
1 39460U 13071A   20212.62512392 -.00000284  00000-0  00000-0 0  9996
2 39460   0.0515 249.2895 0002972 223.2072 156.2409  1.00271759 22915
AEROCUBE 5A             
1 39465U 13072D   20213.11764187  .00002114  00000-0  20268-3 0  9992
2 39465 120.4933 321.2943 0257111 290.2427  67.1159 14.76637474 47816
AEROCUBE 5B             
1 39466U 13072E   20212.63928350  .00002933  00000-0  22886-3 0  9990
2 39466 120.4933 358.6794 0230602 299.8065  58.0249 14.86329856 47995
INMARSAT 5-F1           
1 39476U 13073A   20211.96651854  .00000015  00000-0  00000-0 0  9999
2 39476   0.0187  72.6882 0000511  50.4570 235.4420  1.00269867 24395
TKSAT-1 (TUPAC KATARI)  
1 39481U 13075A   20212.43814729 -.00000208  00000-0  00000-0 0  9997
2 39481   0.0154 115.6728 0003110 342.5247 280.8517  1.00270738 24059
COSMOS 2488             
1 39483U 13076A   20213.09118400  .00000012  00000-0  13208-4 0  9996
2 39483  82.4815  66.7568 0020093  93.3841 266.9532 12.42872099299363
COSMOS 2489             
1 39484U 13076B   20212.74463462  .00000015  00000-0  37689-4 0  9991
2 39484  82.4849  67.7009 0022101  95.5583 264.8013 12.42875894299380
COSMOS 2490             
1 39485U 13076C   20212.71883169  .00000012  00000-0  17670-4 0  9994
2 39485  82.4793  66.7359 0021586 109.9950 250.3443 12.42876338299325
EXPRESS-AM5             
1 39487U 13077A   20213.10172187 -.00000295  00000-0  00000+0 0  9990
2 39487   0.0590 248.1390 0000793 118.3557 119.3214  1.00271646 24827
GSAT-14                 
1 39498U 14001A   20211.99376838 -.00000090  00000-0  00000+0 0  9997
2 39498   0.0804 274.2536 0002484 303.4214 162.1701  1.00270992 24049
THAICOM 6               
1 39500U 14002A   20212.60706014 -.00000135  00000-0  00000+0 0  9994
2 39500   0.0488 126.3933 0004886   6.7004 112.6165  1.00271228 23986
TDRS 12                 
1 39504U 14004A   20212.31243212 -.00000277  00000-0  00000+0 0  9991
2 39504   4.6019 338.7388 0003422 159.2420 241.9924  1.00268773 23917
ABS-2                   
1 39508U 14006A   20212.82913596 -.00000100  00000-0  00000+0 0  9997
2 39508   0.0120  72.0530 0003898  45.4820 204.7692  1.00271981 23787
ATHENA-FIDUS            
1 39509U 14006B   20212.84903550  .00000140  00000-0  00000+0 0  9992
2 39509   0.0028  65.5928 0001523  56.7360 170.0505  1.00274395 23960
TURKSAT 4A              
1 39522U 14007A   20212.78521564  .00000132  00000-0  00000+0 0  9993
2 39522   0.0678 267.0055 0001492 282.9430  83.5488  1.00270733 23670
NAVSTAR 69 (USA 248)    
1 39533U 14008A   20212.80229438  .00000075  00000-0  00000+0 0  9990
2 39533  53.7556 236.9825 0048485 201.0138 158.8475  2.00570118 47165
GPM-CORE                
1 39574U 14009C   20212.99059978  .00001756  00000-0  29942-4 0  9994
2 39574  65.0054   3.5498 0011145 283.7700  76.2205 15.55432705364831
EXPRESS-AT1             
1 39612U 14010A   20212.67355896  .00000064  00000-0  00000-0 0  9993
2 39612   0.0558 244.2793 0000263 299.0679  63.8764  1.00271755 23282
EXPRESS-AT2             
1 39613U 14010B   20212.39861354 -.00000295  00000-0  00000+0 0  9990
2 39613   0.0506 248.0102 0000203 261.7709  82.0490  1.00272035 23344
AMAZONAS 4A             
1 39616U 14011A   20212.44555685 -.00000266  00000-0  00000-0 0  9996
2 39616   0.0819  77.5600 0003388  52.3789 264.9989  1.00271549 23153
ASTRA 5B                
1 39617U 14011B   20212.05701441  .00000141  00000-0  00000-0 0  9998
2 39617   0.0443  70.4194 0003719  54.1270 235.5918  1.00270099 23122
COSMOS 2492 [GLONASS-M] 
1 39620U 14012A   20212.81790367 -.00000044  00000-0  10000-3 0  9999
2 39620  65.7672  26.1474 0011767 333.2722 244.5916  2.13103308 49479
SJ-11-06                
1 39624U 14014A   20213.08882905  .00000017  00000-0  13614-4 0  9999
2 39624  98.2366 260.8465 0013799 218.3046 141.7188 14.59054899337540
SENTINEL-1A             
1 39634U 14016A   20213.05326194 -.00000027  00000-0  40919-5 0  9993
2 39634  98.1829 219.4191 0001290  88.1894 271.9453 14.59197623336922
IRNSS-1B                
1 39635U 14017A   20202.13758595  .00000113  00000-0  00000+0 0  9992
2 39635  28.5057 276.6608 0022042 187.6475 299.1310  1.00283016 23166
LUCH 5V                 
1 39727U 14023A   20212.62512328 -.00000280  00000-0  00000+0 0  9994
2 39727   0.4801 342.8534 0004177 140.6176 144.8856  1.00266132540989
KAZSAT-3                
1 39728U 14023B   20212.64083451  .00000048  00000-0  00000+0 0  9998
2 39728   0.0441 244.3024 0001865 287.8908  65.7009  1.00267111 22900
KAZEOSAT 1              
1 39731U 14024A   20213.09718833 -.00000011  00000-0  96542-5 0  9995
2 39731  98.3159 285.0421 0001089  98.5982 261.5334 14.42063963329175
NAVSTAR 70 (USA 251)    
1 39741U 14026A   20211.58505331 -.00000059  00000-0  00000+0 0  9997
2 39741  56.1781  55.1427 0019810 295.2305  64.6265  2.00574345 45427
COSMOS 2496             
1 39761U 14028A   20213.15978168  .00000019  00000-0  68772-4 0  9998
2 39761  82.4450  13.5844 0020196  76.5091 283.8250 12.42876391280867
COSMOS 2497             
1 39762U 14028B   20212.72962965  .00000006  00000-0 -31876-4 0  9991
2 39762  82.4494  14.6990 0020702  71.8858 288.4477 12.42877292280810
COSMOS 2498             
1 39763U 14028C   20213.18950308  .00000012  00000-0  15838-4 0  9997
2 39763  82.4459  13.6795 0021164  71.1809 289.1573 12.42887605280876
ALOS-2                  
1 39766U 14029A   20213.13432072  .00000108  00000-0  21280-4 0  9993
2 39766  97.9229 309.1067 0001508  92.5920 267.5464 14.79472723334154
UNIFORM 1               
1 39767U 14029B   20213.17671330  .00000109  00000-0  19564-4 0  9993
2 39767  97.8530 306.3043 0005793 163.6293 196.5112 14.83856290334998
SOCRATES                
1 39768U 14029C   20213.22639785  .00000033  00000-0  10316-4 0  9990
2 39768  97.8656 309.5981 0006350 189.9685 170.1404 14.84465362335147
RISING 2                
1 39769U 14029D   20213.21547010  .00000039  00000-0  11360-4 0  9995
2 39769  97.8391 301.1498 0004688 144.7157 215.4371 14.82150275334675
SPROUT                  
1 39770U 14029E   20213.13230173  .00000302  00000-0  39142-4 0  9998
2 39770  97.9000 319.2333 0008618 175.1731 184.9581 14.87800859335552
EUTELSAT 3B             
1 39773U 14030A   20212.09493928  .00000006  00000-0  00000+0 0  9993
2 39773   0.0680 346.0219 0005502 165.5290 193.9057  1.00269434 22670
COSMOS 2500 [GLONASS-M] 
1 40001U 14032A   20212.77787509 -.00000044  00000-0  00000+0 0  9990
2 40001  65.7059  26.0595 0006166 180.7084 226.9011  2.13102601 47696
KAZEOSAT 2              
1 40010U 14033A   20213.16323047  .00000004  00000-0  70737-5 0  9993
2 40010  97.8012 107.9576 0016619 156.1850 204.0139 14.82120357330792
HODOYOSHI-4             
1 40011U 14033B   20213.17238273  .00000026  00000-0  99567-5 0  9997
2 40011  97.7703 100.0221 0027025 156.2734 203.9724 14.81205213330494
UNISAT-6                
1 40012U 14033C   20213.11815235  .00000134  00000-0  27219-4 0  9995
2 40012  97.7070  65.8924 0057221 218.1046 141.6111 14.74134877328859
DEIMOS-2                
1 40013U 14033D   20213.17092840  .00000064  00000-0  13829-4 0  9995
2 40013  97.8139 114.8276 0001155 125.3700 234.7631 14.84675655331358
BUGSAT-1 (TITA)         
1 40014U 14033E   20212.88445678 -.00000307  00000-0 -21771-4 0  9991
2 40014  98.0675 175.9668 0032224 146.6414 213.6845 14.95480023333483
HODOYOSHI-3             
1 40015U 14033F   20212.90645685  .00000018  00000-0  92868-5 0  9998
2 40015  97.7323  86.6596 0037047 176.1808 183.9694 14.78601955329858
SAUDISAT 4              
1 40016U 14033G   20212.88758420  .00000013  00000-0  90998-5 0  9995
2 40016  97.7064  72.3524 0047642 204.7349 155.1576 14.75294405329163
APRIZESAT 9             
1 40018U 14033J   20213.10377508  .00000007  00000-0  92525-5 0  9998
2 40018  97.7068  50.8336 0069961 257.1476 102.1909 14.69988164327943
APRIZESAT 10            
1 40019U 14033K   20213.11304076  .00000017  00000-0  11439-4 0  9995
2 40019  97.7255  40.4262 0082838 288.2597  70.9607 14.67047351327345
BRITE-CA1 (TORONTO)     
1 40020U 14033L   20213.07547761  .00000025  00000-0  12806-4 0  9990
2 40020  97.7311  38.7873 0086002 293.9244  65.2971 14.66652690327154
DUCHIFAT-1              
1 40021U 14033M   20212.76918910  .00000317  00000-0  37946-4 0  9990
2 40021  97.9426 144.2736 0013653 139.2496 220.9746 14.90937342332342
NANOSATC-BR1            
1 40024U 14033Q   20212.74914787  .00000281  00000-0  34941-4 0  9991
2 40024  97.9269 140.4932 0012882 143.4928 216.7170 14.90102347331685
QB50P1                  
1 40025U 14033R   20212.79470261  .00000174  00000-0  24447-4 0  9992
2 40025  97.9143 137.0994 0012964 150.5612 209.6337 14.89161482331541
DTUSAT-2                
1 40030U 14033W   20212.77027289  .00000112  00000-0  17958-4 0  9995
2 40030  97.9019 134.4474 0012536 146.6037 213.5973 14.88736201331451
QB50P2                  
1 40032U 14033Y   20212.72342379  .00000155  00000-0  22594-4 0  9991
2 40032  97.9026 134.4032 0012435 147.5626 212.6357 14.88685895331428
PERSEUS-M2              
1 40037U 14033AD  20212.74506009  .00000047  00000-0  11490-4 0  9993
2 40037  97.8866 128.7660 0014652 164.7598 195.4061 14.86553476331099
PERSEUS-M1              
1 40039U 14033AF  20212.83300961  .00000019  00000-0  84051-5 0  9997
2 40039  97.8829 127.5818 0014546 162.5216 197.6503 14.86174706331019
POLYITAN-1              
1 40042U 14033AJ  20212.79803159  .00000184  00000-0  25581-4 0  9998
2 40042  97.9228 137.6609 0015319 142.4257 217.8033 14.88909097331955
TIGRISAT                
1 40043U 14033AK  20213.12660679  .00000086  00000-0  20067-4 0  9990
2 40043  97.7056  67.2632 0058912 216.4556 143.2636 14.74393467328788
LEMUR-1                 
1 40044U 14033AL  20212.83212857  .00000083  00000-0  19691-4 0  9997
2 40044  97.7055  66.3762 0058003 219.5129 140.1845 14.74227446328706
AEROCUBE 6A             
1 40045U 14033AM  20212.88149071  .00000130  00000-0  25864-4 0  9992
2 40045  97.7311  72.7276 0056612 208.3135 151.4995 14.75595296328512
AEROCUBE 6B             
1 40046U 14033AN  20212.55252860  .00000180  00000-0  33053-4 0  9992
2 40046  97.7217  71.6359 0056044 208.4493 151.3655 14.75590131328464
SPOT 7                  
1 40053U 14034A   20213.20889714 -.00000037  00000-0  17423-5 0  9994
2 40053  98.1803 279.5860 0001179 102.0408 258.0933 14.58562548324055
CANX-4                  
1 40055U 14034C   20212.93717439  .00000029  00000-0  12392-4 0  9998
2 40055  98.2621 340.8063 0014775  29.3863 330.8173 14.74938250327542
CANX-5                  
1 40056U 14034D   20212.80446683  .00000034  00000-0  13112-4 0  9992
2 40056  98.2620 340.6624 0014814  30.0372 330.1683 14.74929656318304
OCO 2                   
1 40059U 14035A   20213.06479243 -.00000006  00000-0  86977-5 0  9993
2 40059  98.2106 153.1783 0001506  88.8434 271.2936 14.57108515323387
GONETS-M 8              
1 40061U 14036A   20213.11122355  .00000018  00000-0  59407-4 0  9991
2 40061  82.5088 201.1322 0028946 149.7567 210.5190 12.42897483275733
GONETS-M 9              
1 40062U 14036B   20212.68465950 -.00000000  00000-0 -75754-4 0  9990
2 40062  82.5086 201.4018 0022585 154.7671 205.4521 12.42882233275673
GONETS-M 10             
1 40063U 14036C   20212.74017947 -.00000005  00000-0 -10513-3 0  9993
2 40063  82.5074 201.3053 0022032 140.6339 219.6352 12.42886149275681
METEOR-M 2              
1 40069U 14037A   20212.99811269 -.00000042  00000-0 -21741-6 0  9995
2 40069  98.5000 248.8817 0006178 146.0032 214.1548 14.20673903314399
MKA-PN 2 (RELEC)        
1 40070U 14037B   20212.85323489  .00000041  00000-0  20220-4 0  9990
2 40070  98.0693 250.0033 0136157 249.0724 109.5854 14.52140899321182
DX1                     
1 40071U 14037C   20213.15120785  .00000058  00000-0  14933-4 0  9991
2 40071  98.4626  33.9728 0008636   5.6847 354.4462 14.81378574327721
SKYSAT-2                
1 40072U 14037D   20213.00360449  .00000075  00000-0  17150-4 0  9992
2 40072  98.4623  33.6848 0006457   7.5691 352.5616 14.81300029327692
UKUBE-1                 
1 40074U 14037F   20213.05036027  .00000197  00000-0  30746-4 0  9992
2 40074  98.4546  43.9826 0004508 356.1756   3.9424 14.84293388328224
AISSAT 2                
1 40075U 14037G   20213.12222695  .00000080  00000-0  17322-4 0  9995
2 40075  98.4602  38.8267 0006393  17.6782 342.4648 14.82523916327951
TECHDEMOSAT-1 (TDS-1)   
1 40076U 14037H   20212.83486631  .00000235  00000-0  35060-4 0  9996
2 40076  98.4504  35.3314 0008138 119.2868 240.9159 14.84483049327819
O3B FM3                 
1 40079U 14038A   20211.08256054 -.00000023  00000-0  00000+0 0  9997
2 40079   0.0511  11.5151 0004291  86.4056 262.1254  5.00115682110606
O3B FM7                 
1 40080U 14038B   20211.22891073 -.00000023  00000-0  00000-0 0  9992
2 40080   0.0554  13.2066 0002810 153.7495 193.0487  5.00115473110494
O3B FM6                 
1 40081U 14038C   20212.25501602 -.00000025  00000-0  00000+0 0  9994
2 40081   0.0517  10.1734 0002688 118.0253 231.8226  5.00114857110689
O3B FM8                 
1 40082U 14038D   20211.90841275 -.00000025  00000-0  00000-0 0  9990
2 40082   0.0539   7.8884 0002558 119.9399 232.1920  5.00115215110617
ORBCOMM FM109           
1 40086U 14040A   20212.83714389 -.00000124  00000-0  96431-5 0  9993
2 40086  47.0043 121.9868 0001938  38.7606 321.3417 14.54801118321497
ORBCOMM FM107           
1 40087U 14040B   20212.79112994 -.00000117  00000-0  11335-4 0  9994
2 40087  47.0062 122.8156 0001711  50.3671 309.7364 14.54795748321481
ORBCOMM FM103           
1 40091U 14040F   20212.81507077 -.00000130  00000-0  83346-5 0  9999
2 40091  47.0066 123.0104 0002022  33.2208 326.8803 14.54802978304548
USA 253                 
1 40099U 14043A   20206.47169095 -.00000323  00000-0  00000+0 0  9993
2 40099   0.0260 107.3750 0001218  30.5518  74.8484  1.00344805  6465
USA 254                 
1 40100U 14043B   20212.53732552  .00000002  00000-0  00000+0 0  9997
2 40100   0.6679  96.6837 0000946 123.5871  94.1149  1.00377159  6210
NAVSTAR 71 (USA 256)    
1 40105U 14045A   20212.17499574  .00000026  00000-0  00000-0 0  9995
2 40105  54.5954 174.2436 0013687 103.6720 256.4513  2.00561853 43020
ASIASAT 8               
1 40107U 14046A   20212.26536557 -.00000047  00000-0  00000+0 0  9994
2 40107   0.0379 250.7256 0002425 232.4465 276.8191  1.00272523 21963
YAOGAN 20A              
1 40109U 14047A   20212.93188600 -.00000089  00000-0  21895-5 0  9990
2 40109  63.3933 145.3711 0199226   5.9345 354.3970 13.45190815293620
YAOGAN 20B              
1 40110U 14047B   20212.93205312 -.00000076  00000-0  21355-4 0  9998
2 40110  63.3931 146.2309 0199026   5.9769 354.3562 13.45190852293607
YAOGAN 20C              
1 40111U 14047C   20212.93182584 -.00000066  00000-0  36014-4 0  9991
2 40111  63.3932 146.1343 0199031   6.1109 354.2274 13.45190522293606
WORLDVIEW-3 (WV-3)      
1 40115U 14048A   20213.15339158  .00000050  00000-0  12013-4 0  9997
2 40115  97.7770 288.0282 0001723 120.8494 239.2893 14.85129686323285
GAOFEN 2                
1 40118U 14049A   20213.11384018  .00000019  00000-0  91693-5 0  9999
2 40118  97.8385 289.3858 0008697  36.4723 323.7088 14.80641328321536
BRITE-PL2 (HEWELIUSZ)   
1 40119U 14049B   20212.79496462  .00000043  00000-0  11576-4 0  9998
2 40119  97.8956 304.5894 0016604 331.7739  28.2574 14.84519441322123
GSAT0201 (GALILEO 5)    
1 40128U 14050A   20212.52123319 -.00000120  00000-0  00000+0 0  9991
2 40128  50.5651 359.6547 1662779 100.7846 278.3309  1.85518893 40411
GSAT0202 (GALILEO 6)    
1 40129U 14050B   20211.16778742 -.00000114  00000-0  00000-0 0  9991
2 40129  50.6019 358.7467 1660742 101.5584 277.5145  1.85519547 40616
LING QIAO               
1 40136U 14051A   20213.01860848 -.00000038  00000-0  19065-5 0  9996
2 40136  98.5371 200.2024 0021013 357.1188   2.9870 14.29682679308202
CHUANGXIN 1-04 (CX-1-04)
1 40137U 14051B   20213.07486715 -.00000026  00000-0  65895-5 0  9999
2 40137  98.5382 200.1778 0022644 358.2883   1.8220 14.29622518308196
ASIASAT 6               
1 40141U 14052A   20212.40976692 -.00000376  00000-0  00000+0 0  9998
2 40141   0.0369 248.1516 0000919 248.1703  79.5824  1.00269985 21627
YAOGAN 21               
1 40143U 14053A   20213.20526309  .00000572  00000-0  26825-4 0  9991
2 40143  97.4369 279.5560 0017513 115.3310 307.3126 15.23559928328067
TIANTUO 2               
1 40144U 14053B   20213.16692752  .00000426  00000-0  14880-4 0  9999
2 40144  97.2482 295.3381 0012811 142.3462 274.2425 15.34852492329769
OPTUS 10                
1 40146U 14054A   20212.61597706 -.00000161  00000-0  00000+0 0  9995
2 40146   0.0804 309.4989 0004137 193.4288 183.5154  1.00270441 21583
MEASAT-3B               
1 40147U 14054B   20212.06896574 -.00000255  00000-0  00000-0 0  9995
2 40147   0.0436 322.0473 0001011 151.5636 310.8695  1.00272809 21365
LUCH (OLYMP)            
1 40258U 14058A   20212.87294515 -.00000027  00000-0  00000-0 0  9995
2 40258   0.0270  85.7928 0001525   6.6344 169.6683  1.00268953 19468
SJ-11-07                
1 40261U 14059A   20213.07437737  .00000021  00000-0  13967-4 0  9991
2 40261  98.1768 295.6628 0013250  43.7501 316.4746 14.60528730311308
HIMAWARI-8              
1 40267U 14060A   20211.79166219 -.00000288  00000-0  00000-0 0  9996
2 40267   0.0335 206.5503 0001297 286.3958 240.5958  1.00270175 21215
IRNSS-1C                
1 40269U 14061A   20212.86103182 -.00000176  00000-0  00000-0 0  9994
2 40269   1.9234 172.7816 0020112  14.7243 154.4194  1.00269510 21152
INTELSAT 30 (IS-30)     
1 40271U 14062A   20212.49685951 -.00000170  00000-0  00000+0 0  9999
2 40271   0.0208  65.8933 0003742  61.8761 264.6542  1.00308930 21236
ARSAT 1                 
1 40272U 14062B   20212.44625073 -.00000273  00000-0  00000-0 0  9999
2 40272   0.0629 262.4288 0001817 226.5076 268.4171  1.00270285 21075
YAOGAN 22               
1 40275U 14063A   20213.00354480 -.00000007  00000-0  10000-3 0  9996
2 40275 100.5029 326.4437 0006188   8.3651 351.7553 13.15423106277496
EXPRESS-AM6             
1 40277U 14064A   20212.30049340  .00000085  00000-0  00000+0 0  9997
2 40277   0.0627 258.4064 0000613 304.3043 266.8435  1.00272177 21131
SJ-11-08                
1 40286U 14066A   20213.08288857  .00000038  00000-0  16968-4 0  9996
2 40286  98.1023 333.3403 0014418 118.2727 241.9930 14.61778933307221
NAVSTAR 72 (USA 258)    
1 40294U 14068A   20209.54837469 -.00000036  00000-0  00000-0 0  9997
2 40294  55.3621 115.2881 0029438  42.9160 317.3416  2.00551157 42071
MERIDIAN 7              
1 40296U 14069A   20212.78726889  .00000103  00000-0  00000+0 0  9996
2 40296  63.0010 117.2332 6918971 273.3170  16.7935  2.00623871 42147
ASNARO                  
1 40298U 14070A   20213.20211303  .00000746  00000-0  38179-4 0  9994
2 40298  97.4172 294.5927 0001985 100.2153 311.9354 15.19621386317984
HODOYOSHI-1             
1 40299U 14070B   20213.17741726  .00000461  00000-0  21813-4 0  9999
2 40299  97.3204 288.8972 0014184  47.4604  10.7294 15.24115071318471
QSAT-EOS                
1 40301U 14070D   20213.16227896  .00001237  00000-0  48904-4 0  9991
2 40301  97.2911 286.6057 0025880  37.0309  45.9395 15.27067305318607
YAOGAN 23               
1 40305U 14071A   20213.16965946  .00000484  00000-0  27016-4 0  9995
2 40305  97.5268 152.4788 0001771  46.1561  66.6855 15.18203666316368
YAOGAN 24               
1 40310U 14072A   20213.38191044 -.00183195  00000-0 -27082-1 0  9999
2 40310  97.9007 334.4804 0018841 331.7384  28.6793 14.76256855307014
COSMOS 2501 [GLONASS-K] 
1 40315U 14075A   20212.60510843  .00000046  00000-0  00000+0 0  9993
2 40315  63.8740 265.2883 0017729 209.3768 220.4112  2.13098687 44091
GSAT-16                 
1 40332U 14078A   20212.99161128  .00000070  00000-0  00000+0 0  9992
2 40332   0.0806 273.4512 0007559 183.8506 263.8205  1.00273204 20736
DIRECTV 14              
1 40333U 14078B   20212.60059848 -.00000139  00000-0  00000-0 0  9994
2 40333   0.0212 134.2442 0000466 284.7256   6.6769  1.00270562 20548
CBERS 4                 
1 40336U 14079A   20213.09405866 -.00000009  00000-0  12372-4 0  9992
2 40336  98.5604 284.4137 0001050  59.4845 300.6448 14.35416043295945
YAOGAN 25A              
1 40338U 14080A   20212.91117939 -.00000223  00000-0 -20546-3 0  9997
2 40338  63.4025  73.9292 0173704   0.6242 359.4970 13.45191686276992
YAOGAN 25B              
1 40339U 14080B   20212.91142565 -.00000006  00000-0  13049-3 0  9999
2 40339  63.4029  74.0001 0173780   0.5228 359.5950 13.45192483276999
YAOGAN 25C              
1 40340U 14080C   20212.91134211 -.00000348  00000-0 -39807-3 0  9990
2 40340  63.4012  74.6078 0173673   0.8830 359.2471 13.45190891276970
YAMAL 401               
1 40345U 14082A   20212.46864266 -.00000242  00000-0  00000-0 0  9999
2 40345   0.0147  57.3461 0000289 146.1404   3.6905  1.00269654 20878
O3B FM10                
1 40348U 14083A   20211.93490245 -.00000025  00000-0  00000-0 0  9995
2 40348   0.0426   9.5659 0002617 117.6455 232.8097  5.00115529102587
O3B FM11                
1 40349U 14083B   20212.14800297 -.00000025  00000-0  00000+0 0  9991
2 40349   0.0438  15.7277 0002932 125.5083 218.7821  5.00115684102592
O3B FM12                
1 40350U 14083C   20211.01617802 -.00000023  00000-0  00000-0 0  9995
2 40350   0.0443  14.6005 0002508 117.7951 227.6231  5.00115549102542
O3B FM9                 
1 40351U 14083D   20212.00167500 -.00000025  00000-0  00000-0 0  9993
2 40351   0.0437  17.2852 0002659 111.0415 231.6943  5.00115258102603
KONDOR-E                
1 40353U 14084A   20212.75570475  .00002899  00000-0  68530-4 0  9993
2 40353  74.7412 321.2773 0001051 359.7703   0.3509 15.42286999314233
COSMOS 2502             
1 40358U 14086A   20212.53474960 -.00000075  00000-0  95831-7 0  9990
2 40358  67.1493 194.1083 0004297 254.3444 105.7161 13.97435535285568
RESURS P2               
1 40360U 14087A   20213.15821168  .00000434  00000-0  15672-4 0  9999
2 40360  97.2452 301.1095 0010243  37.6504  22.6700 15.33595419313018
YAOGAN 26               
1 40362U 14088A   20213.15637664  .00000596  00000-0  27714-4 0  9997
2 40362  97.2453 279.6335 0009492 353.3429  65.9921 15.23637679311528
ASTRA 2G                
1 40364U 14089A   20212.79077271  .00000135  00000-0  00000+0 0  9994
2 40364   0.0255 358.4750 0004417  85.0741 178.1579  1.00272194 20482
FENGYUN 2G              
1 40367U 14090A   20212.81924262 -.00000318  00000-0  00000+0 0  9997
2 40367   2.1842  94.0647 0001392 328.2046 281.1396  1.00272784 20507
MUOS-3                  
1 40374U 15002A   20212.76559221 -.00000139  00000-0  00000+0 0  9993
2 40374   2.7855 348.3283 0058299 177.3145  43.3199  1.00273003 20286
SMAP                    
1 40376U 15003A   20213.07958420 -.00000000  00000-0  90143-5 0  9995
2 40376  98.1232 218.9696 0001204 110.6293 249.5037 14.63358163293607
FIREBIRD 3              
1 40377U 15003B   20212.77266448  .00001730  00000-0  69453-4 0  9993
2 40377  99.0923 281.8386 0123804 168.9361 191.4637 15.20182881303882
FIREBIRD 4              
1 40378U 15003C   20213.15139244  .00001896  00000-0  75595-4 0  9997
2 40378  99.0926 282.3965 0123971 167.5522 192.8819 15.20202097303951
GRIFEX                  
1 40379U 15003D   20213.09523531  .00000995  00000-0  45606-4 0  9996
2 40379  99.0992 273.9929 0132412 193.5846 166.1806 15.16497065303461
EXOCUBE (CP10)          
1 40380U 15003E   20212.69507710  .00000665  00000-0  33636-4 0  9998
2 40380  99.1062 269.0462 0137149 210.1831 149.1444 15.14438929303104
INMARSAT 5-F2           
1 40384U 15005A   20212.45496828 -.00000299  00000-0  00000+0 0  9993
2 40384   0.0188  70.9667 0000268 358.0861 348.2506  1.00271923 20140
COSMOS 2503             
1 40420U 15009A   20213.19497870 -.00000715  00000-0 -54081-4 0  9995
2 40420  97.9054 194.4636 0007276 204.9709 155.1163 14.98877860296795
ABS-3A                  
1 40424U 15010A   20212.09493928 -.00000039  00000-0  00000-0 0  9995
2 40424   0.0183  69.8066 0002027 338.3743 291.1844  1.00271864 19867
EUTELSAT 115 WEST B     
1 40425U 15010B   20212.58983200 -.00000040  00000-0  00000+0 0  9998
2 40425   0.0057 136.3109 0000575 103.8626 165.9402  1.00275492 19901
MMS 1                   
1 40482U 15011A   20207.04166667 -.00001588  00000-0  00000+0 0  9995
2 40482  29.1471 120.0719 8597238  22.2843 359.9824  0.28640990 11322
MMS 2                   
1 40483U 15011B   20207.04166667 -.00001587  00000-0  00000+0 0  9995
2 40483  29.1466 120.0725 8594747  22.2869 359.9769  0.28640997 11307
MMS 3                   
1 40484U 15011C   20212.35416667 -.00002347  00000-0  00000+0 0  9999
2 40484  29.1889 119.9635 8587776  22.5013 187.4711  0.28667768 11319
MMS 4                   
1 40485U 15011D   20212.35416667 -.00002346  00000-0  00000+0 0  9999
2 40485  29.1927 119.9904 8587301  22.4775 187.4639  0.28667378 11316
EXPRESS-AM7             
1 40505U 15012A   20212.16153987  .00000137  00000-0  10027+1 0  9991
2 40505   0.0124  47.7983 0002751  83.4072 275.2060  1.00274738 19824
NAVSTAR 73 (USA 260)    
1 40534U 15013A   20212.17948033 -.00000040  00000-0  00000+0 0  9992
2 40534  54.1935 293.1106 0047568   9.4127 350.7293  2.00565670 39170
KOMPSAT-3A              
1 40536U 15014A   20213.15635936  .00000324  00000-0  22401-4 0  9997
2 40536  97.5443 152.1441 0001283  58.0709  56.3717 15.12316011295324
GSAT0203 (GALILEO 7)    
1 40544U 15017A   20212.14046671 -.00000067  00000-0  00000+0 0  9990
2 40544  56.4493  40.2376 0001855 224.0038 136.0311  1.70474713 33241
GSAT0204 (GALILEO 8)    
1 40545U 15017B   20211.33500316 -.00000066  00000-0  00000+0 0  9991
2 40545  56.4549  40.2755 0002301 149.6783 210.3845  1.70475060 33234
IRNSS-1D                
1 40547U 15018A   20211.60535697 -.00000285  00000-0  00000-0 0  9998
2 40547  29.1745 276.5873 0021318 183.4434 177.4285  1.00269422 19707
BEIDOU 17               
1 40549U 15019A   20212.27668593 -.00000213  00000-0  00000-0 0  9998
2 40549  52.9934 320.7100 0039999 188.6705 356.0055  1.00278624 19546
GONETS-M 11             
1 40552U 15020A   20212.73921719  .00000002  00000-0 -56041-4 0  9993
2 40552  82.4866 288.9650 0016174  11.8917 348.2542 12.42895930242005
GONETS-M 12             
1 40553U 15020B   20212.71285574  .00000041  00000-0  22798-3 0  9997
2 40553  82.4898 289.4980 0013937 355.8611   4.2351 12.42895012242007
GONETS-M 13             
1 40554U 15020C   20212.68923143  .00000038  00000-0  20472-3 0  9998
2 40554  82.4857 288.8291 0011808 340.5023  19.5605 12.42885884242001
COSMOS 2504             
1 40555U 15020D   20212.87511632 -.00000031  00000-0 -16834-4 0  9999
2 40555  82.5167  87.4519 0615698 339.7290  18.0336 13.59414876258309
THOR 7                  
1 40613U 15022A   20213.07577562 -.00000024  00000-0  00000+0 0  9997
2 40613   0.0170 104.1332 0002887  25.9023 205.7303  1.00270662 19299
TURKMENALEM52E/MONACOSAT
1 40617U 15023A   20212.06111450  .00000090  00000-0  00000-0 0  9993
2 40617   0.0253  75.3244 0003571  58.4711 248.3970  1.00274295 19291
AEROCUBE 8A             
1 40659U 15025J   20212.81528047  .00006333  00000-0  70971-4 0  9992
2 40659  55.0168  92.0603 0100242 289.4176  69.6049 15.58334714 50311
AEROCUBE 8B             
1 40660U 15025K   20212.97522226  .00006486  00000-0  71701-4 0  9995
2 40660  55.0144  89.1296 0099383 291.9682  67.0817 15.58717812 50358
DIRECTV 15              
1 40663U 15026A   20212.59857279 -.00000116  00000-0  00000+0 0  9990
2 40663   0.0171  91.0846 0000390  35.1342 295.0924  1.00270362 19000
SKY MEXICO-1            
1 40664U 15026B   20212.44263758 -.00000248  00000-0  00000-0 0  9995
2 40664   0.0118  34.4476 0002166  86.9925 267.6473  1.00272224 19009
SENTINEL-2A             
1 40697U 15028A   20213.10967830 -.00000005  00000-0  14600-4 0  9995
2 40697  98.5684 286.7285 0001127  94.5576 265.5740 14.30817028266707
COSMOS 2506             
1 40699U 15029A   20213.13900617 -.00000030  00000-0  37773-5 0  9996
2 40699  98.3658 265.6210 0008848 358.3076   1.8095 14.54310535270936
GAOFEN 8                
1 40701U 15030A   20213.15097399  .00000627  00000-0  29231-4 0  9998
2 40701  97.4781 335.4827 0009976   2.1111 121.9256 15.23534243283897
DMC3-FM1                
1 40715U 15032A   20213.11293135  .00000004  00000-0  82487-5 0  9999
2 40715  97.9286 105.8010 0017151  83.8527 276.4636 14.73695047272082
DMC3-FM2                
1 40716U 15032B   20213.13469779  .00001086  00000-0  17382-3 0  9992
2 40716  97.9308 106.1935 0014251  93.6369 266.5204 14.73673687272097
DMC3-FM3                
1 40717U 15032C   20213.08952262 -.00000009  00000-0  62182-5 0  9996
2 40717  97.9328 106.8786 0018059  85.3096 275.0181 14.73700108272083
CARBONITE 1 (CBNT-1)    
1 40718U 15032D   20213.08477588  .00000077  00000-0  18814-4 0  9996
2 40718  97.8330  92.6059 0018174  71.8237 288.4948 14.74827328272248
NAVSTAR 74 (USA 262)    
1 40730U 15033A   20211.84168729 -.00000095  00000-0  00000+0 0  9991
2 40730  55.5457 354.2679 0053875 354.2553   5.6839  2.00560806 36926
METEOSAT-11 (MSG-4)     
1 40732U 15034A   20213.06034337 -.00000016  00000-0  00000+0 0  9990
2 40732   0.7382 272.6071 0003330 225.7636 192.5812  1.00274065 18500
STAR ONE C4             
1 40733U 15034B   20212.44764725 -.00000278  00000-0  00000-0 0  9994
2 40733   0.0318  64.2969 0001489  45.7882 289.5921  1.00270616 18483
WGS F7 (USA 263)        
1 40746U 15036A   20212.63047810  .00000003  00000-0  00000+0 0  9994
2 40746   0.0122  46.8659 0000125 107.8958 195.9091  1.00271586 18342
BEIDOU 18               
1 40748U 15037A   20213.25927674 -.00000094  00000-0  00000+0 0  9992
2 40748  55.9350  14.8715 0003298  41.2462 141.4601  1.86232110 34131
BEIDOU 19               
1 40749U 15037B   20212.84206228 -.00000092  00000-0  00000+0 0  9990
2 40749  55.9385  14.8723 0002780 332.6742  67.3562  1.86232268 34127
INTELSAT 34 (IS-34)     
1 40874U 15039A   20212.45496764 -.00000299  00000-0  00000-0 0  9994
2 40874   0.0242  71.3499 0000539 347.3026 358.1912  1.00268140 18109
EUTELSAT 8 WEST B       
1 40875U 15039B   20212.09493928 -.00000078  00000-0  00000-0 0  9999
2 40875   0.0694 351.7235 0005143 121.6191 221.0258  1.00273085 18123
YAOGAN 27               
1 40878U 15040A   20213.02019597 -.00000005  00000-0  10000-3 0  9998
2 40878 100.2295 259.8953 0009115 121.9994 238.2011 13.15966085236743
GSAT-6                  
1 40880U 15041A   20212.90622131 -.00000175  00000-0  00000-0 0  9996
2 40880   0.0082 264.8290 0003765 300.6840 152.2318  1.00272418 18113
INMARSAT 5-F3           
1 40882U 15042A   20212.63356990  .00000036  00000-0  00000-0 0  9991
2 40882   0.0270  77.7736 0000140  55.8870 222.7188  1.00270484 18036
MUOS-4                  
1 40887U 15044A   20212.78347557 -.00000103  00000-0  00000+0 0  9998
2 40887   2.9155 344.6633 0051582   2.4273 318.8648  1.00271445 18033
GSAT0205 (GALILEO 9)    
1 40889U 15045A   20210.99094803 -.00000014  00000-0  00000-0 0  9997
2 40889  56.1185 280.9587 0002377 349.5295  10.5345  1.70475422 30375
GSAT0206 (GALILEO 10)   
1 40890U 15045B   20211.21051457 -.00000013  00000-0  00000-0 0  9992
2 40890  56.1177 280.9491 0001423 299.8921  60.1632  1.70475431 30400
TJS-1                   
1 40892U 15046A   20212.90364235 -.00000171  00000-0  10000-3 0  9992
2 40892   0.1070 276.4463 0005824 102.8063  50.0165  1.00272232 18021
GAOFEN 9-01             
1 40894U 15047A   20213.21664960 -.00001773  00000-0 -24639-3 0  9991
2 40894  97.8882 291.4771 0032014 267.5403  92.2083 14.76288300262932
EXPRESS-AM8             
1 40895U 15048A   20212.04794188 -.00000125  00000-0  00000+0 0  9990
2 40895   0.0267 241.6706 0000142  23.1729  46.5423  1.00271275 17838
2015-049A               
1 40899U 15049A   20213.15453378  .00000201  00000-0  13670-4 0  9997
2 40899  97.4688 215.1760 0013480 229.6212 194.7118 15.16722845269049
NUDT-PHONESAT           
1 40900U 15049B   20213.04146051  .00001608  00000-0  68762-4 0  9999
2 40900  97.4306 223.6183 0012239 182.4685 239.1075 15.24248414269830
ZDPS 2A                 
1 40901U 15049C   20213.16061630  .00000718  00000-0  38232-4 0  9992
2 40901  97.4620 218.3112 0012103 215.3963 209.0037 15.18365936269205
ZDPS 2B                 
1 40902U 15049D   20213.14296164  .00000831  00000-0  43724-4 0  9991
2 40902  97.4612 215.5699 0012119 212.7274 211.9153 15.18362530269173
XW-2A                   
1 40903U 15049E   20213.14341858  .00001419  00000-0  33795-4 0  9993
2 40903  97.2851 262.1213 0014870 103.2011 333.0974 15.43223982273218
KAITUO 1A               
1 40904U 15049F   20213.15514194  .00000115  00000-0  95031-5 0  9994
2 40904  97.4774 213.5583 0013093 228.2973 196.0676 15.15740644268865
2015-049G               
1 40905U 15049G   20213.14086771  .00000138  00000-0  11040-4 0  9991
2 40905  97.4862 210.7414 0014350 225.2171 199.1529 15.14585567268692
XW-2C                   
1 40906U 15049H   20213.13203427  .00000550  00000-0  31950-4 0  9990
2 40906  97.4775 213.8383 0013523 217.4817 205.4206 15.16118517268878
XW-2D                   
1 40907U 15049J   20213.11939243  .00000667  00000-0  37782-4 0  9999
2 40907  97.4758 213.5392 0013161 213.3941 211.7526 15.16357867268898
LILACSAT-2              
1 40908U 15049K   20213.24557186  .00000497  00000-0  30913-4 0  9996
2 40908  97.4923 211.9559 0014636 225.7695 146.4262 15.14071188268620
XW-2F                   
1 40910U 15049M   20212.80984060  .00000861  00000-0  45595-4 0  9992
2 40910  97.4683 215.8728 0013540 204.0301 280.4152 15.18012651268917
XW-2B                   
1 40911U 15049N   20213.04160787  .00000435  00000-0  25875-4 0  9998
2 40911  97.4775 214.1600 0012793 215.5391 221.1260 15.16281010268777
KAITUO 1B               
1 40912U 15049P   20213.12128517  .00000874  00000-0  43205-4 0  9997
2 40912  97.4479 220.3731 0014550 179.6241 253.1258 15.20415061269357
COSMOS 2507             
1 40920U 15050A   20212.74324372  .00000070  00000-0  43696-3 0  9993
2 40920  82.4929 336.5250 0009568 356.5091   3.5921 12.42875886220119
COSMOS 2508             
1 40921U 15050B   20212.68779193  .00000024  00000-0  10509-3 0  9998
2 40921  82.4889 335.8936 0013834  29.5648 330.6178 12.42873490220098
COSMOS 2509             
1 40922U 15050C   20212.71683723  .00000028  00000-0  13357-3 0  9994
2 40922  82.4888 335.9445 0015759  42.4791 317.7511 12.42881649220109
PUJIANG 1 (PJ-1)        
1 40925U 15051A   20213.08606279  .00000553  00000-0  19177-4 0  9990
2 40925  97.1381 229.2190 0014850  66.4560   9.9077 15.33740285271179
TIANWANG 1C (TW-1C)     
1 40926U 15051B   20213.12138382  .00019398  00000-0  18061-3 0  9998
2 40926  97.0805 265.2200 0003425 275.0361 136.9539 15.67407484273865
TIANWANG 1A (TW-1A)     
1 40928U 15051D   20213.04979999  .00002383  00000-0  56214-4 0  9997
2 40928  97.1074 239.8173 0011493  36.6445  24.2758 15.42812412272090
ASTROSAT                
1 40930U 15052A   20212.33292006  .00000697  00000-0  87776-5 0  9993
2 40930   5.9982  36.6726 0008333 158.7735 201.2738 14.76322296261569
LAPAN-A2                
1 40931U 15052B   20213.04870487  .00000650  00000-0  13999-5 0  9993
2 40931   6.0001  24.5301 0012657 174.9276 185.1021 14.76638905261712
LEMUR-2-JOEL            
1 40932U 15052C   20211.98523731  .00000804  00000-0  21074-4 0  9995
2 40932   6.0092  12.2322 0013879 205.1469 154.7982 14.77989060261741
LEMUR-2-CHRIS           
1 40933U 15052D   20211.80309386  .00000770  00000-0  16193-4 0  9993
2 40933   6.0072  10.4318 0014607 209.9400 149.9892 14.78137211261742
LEMUR-2-PETER           
1 40935U 15052F   20211.99248020  .00000812  00000-0  23519-4 0  9999
2 40935   6.0049  26.8554 0010468 172.2933 187.7356 14.77283829261599
EXACTVIEW 9             
1 40936U 15052G   20211.99118876  .00000725  00000-0  12250-4 0  9998
2 40936   6.0048  36.9615 0010111 148.5260 211.5473 14.76581490261526
BEIDOU 20               
1 40938U 15053A   20212.19902627 -.00000132  00000-0  00000-0 0  9998
2 40938  52.5535 283.7437 0051778 189.6105   2.1132  1.00263199 17821
SKY MUSTER (NBN1A)      
1 40940U 15054A   20212.27449456 -.00000292  00000-0  00000-0 0  9994
2 40940   0.0136  69.6580 0001898  52.1258  65.6451  1.00269411 17731
ARSAT 2                 
1 40941U 15054B   20212.44115014 -.00000238  00000-0  00000+0 0  9993
2 40941   0.0625 256.2092 0001994 219.9510 270.1676  1.00271274 17785
MORELOS 3               
1 40946U 15056A   20212.50055736 -.00000050  00000-0  00000-0 0  9994
2 40946   4.7531 328.7558 0003426 181.9968 224.9265  1.00272254 17753
LINGQIAO VIDEO A        
1 40959U 15057B   20213.07646806 -.00000024  00000-0  38248-5 0  9997
2 40959  97.8515 275.5206 0018328  15.3141 344.8626 14.73304798258967
LINGQIAO VIDEO B        
1 40960U 15057C   20213.07630882 -.00000001  00000-0  73350-5 0  9994
2 40960  97.8541 276.0125 0018548  16.2676 343.9134 14.73418033258985
JILIN 1                 
1 40961U 15057D   20213.11762887  .00000037  00000-0  13153-4 0  9993
2 40961  97.8580 277.0452 0018613  15.4507 344.7276 14.73733405259036
AEROCUBE 5C             
1 40965U 15058B   20212.83627057 -.00000043  00000-0  14727-4 0  9991
2 40965  64.7792  12.5333 0170726  57.9037 303.8512 14.74540003 47700
AEROCUBE 7              
1 40966U 15058C   20212.80766848 -.00000006  00000-0  19045-4 0  9998
2 40966  64.7793  11.0395 0169661  57.5822 304.1566 14.74843800 47702
APSTAR 9                
1 40982U 15059A   20212.90466150 -.00000281  00000-0  00000+0 0  9992
2 40982   0.0349 219.6693 0003264 270.5297 286.4363  1.00269165 17622
TURKSAT 4B              
1 40984U 15060A   20212.77859036  .00000101  00000-0  00000+0 0  9990
2 40984   0.0160 326.7565 0002088 154.3377 158.0067  1.00270817 17580
TIANHUI 1-03            
1 40988U 15061A   20213.15835826  .00000358  00000-0  17924-4 0  9991
2 40988  97.3256 335.7409 0006430 316.5374 165.6040 15.23459916264858
NAVSTAR 75 (USA 265)    
1 41019U 15062A   20211.89224042 -.00000060  00000-0  00000-0 0  9993
2 41019  55.3616 115.0225 0058474 210.3148 149.3696  2.00564197 34746
CHINASAT 2C (ZX 2C)     
1 41021U 15063A   20212.62960287 -.00000338  00000-0  00000-0 0  9995
2 41021   0.0333 221.8363 0001464 260.5724 156.3817  1.00271121 17470
YAOGAN 28               
1 41026U 15064A   20213.16027086  .00000494  00000-0  23646-4 0  9999
2 41026  97.4606 344.6684 0000675  15.4315 109.2167 15.23504208263747
GSAT-15                 
1 41028U 15065A   20212.62495818 -.00000272  00000-0  00000-0 0  9994
2 41028   0.1016 274.4019 0003289 179.5456 173.2391  1.00273098 17274
BADR-7 (ARABSAT-6B)     
1 41029U 15065B   20212.05596758  .00000132  00000-0  00000+0 0  9990
2 41029   0.0524 250.6957 0004751 315.0777 148.4760  1.00272303 17376
COSMOS 2510             
1 41032U 15066A   20213.23715578 -.00000822  00000-0  00000+0 0  9996
2 41032  62.8655 242.7904 6885671 270.7363 218.9860  2.00668311 34469
LAOSAT 1                
1 41034U 15067A   20212.00458564 -.00000356  00000-0  00000+0 0  9994
2 41034   0.0596 252.5925 0001468 222.2058 323.4311  1.00268237 17301
TELSTAR 12V             
1 41036U 15068A   20212.98097765 -.00000134  00000-0  00000+0 0  9990
2 41036   0.0173  66.4949 0002306  66.3293 154.3864  1.00270462 17196
YAOGAN 29               
1 41038U 15069A   20213.10059832 -.00000011  00000-0  54550-5 0  9990
2 41038  98.0130 201.0052 0002242  96.7807 263.3658 14.80453843252738
CHINASAT 1C (ZX 1C)     
1 41103U 15073A   20213.03941535 -.00000166  00000-0  00000+0 0  9996
2 41103   1.7190  92.0972 0001929  12.5752 300.0855  1.00270983 17134
ELEKTRO-L 2             
1 41105U 15074A   20204.77966084 -.00000102  00000-0  00000+0 0  9996
2 41105   1.2820  96.7253 0001690  61.0117 139.9802  1.00273046 16901
COSMOS 2513             
1 41121U 15075A   20212.82213352 -.00000151  00000-0  00000+0 0  9997
2 41121   0.0545 113.3280 0002307  14.2358 197.5400  1.00267327 17066
VELOX-CI                
1 41166U 15077A   20212.47703882  .00000851  00000-0  11209-4 0  9995
2 41166  14.9823 159.9098 0011324 265.2977  94.6050 15.10024852255382
KENT RIDGE 1            
1 41167U 15077B   20213.09143200  .00000743  00000-0  51930-5 0  9993
2 41167  14.9783 166.6388 0011316 252.7389 198.2388 15.09165805255381
ATHENOXAT 1             
1 41168U 15077C   20213.03571301  .00001584  00000-0  47001-4 0  9992
2 41168  14.9884 101.6322 0011232  14.6954 345.3693 15.14447325255965
TELEOS 1                
1 41169U 15077D   20213.05204233  .00000988  00000-0  18952-4 0  9992
2 41169  14.9804 150.7858 0010303 277.5606  82.3544 15.10578524255526
GALASSIA                
1 41170U 15077E   20212.35126058  .00001555  00000-0  44915-4 0  9993
2 41170  14.9890  98.3592 0012517  32.2368 327.8717 15.14738961255936
VELOX-II                
1 41171U 15077F   20212.46715109  .00001208  00000-0  30508-4 0  9995
2 41171  14.9909 143.4930 0008755 286.4786  73.4573 15.11743552255542
DAMPE                   
1 41173U 15078A   20213.17420705  .00000081  00000-0  62244-5 0  9993
2 41173  97.3264 206.1704 0015527  96.5092  33.8808 15.23886454257055
GSAT0209 (GALILEO 12)   
1 41174U 15079A   20209.62336139  .00000027  00000-0  00000+0 0  9993
2 41174  55.0009 160.3743 0005603 309.7046  50.2132  1.70472885 28695
GSAT0208 (GALILEO 11)   
1 41175U 15079B   20212.18942931 -.00000000  00000-0  00000+0 0  9994
2 41175  55.0008 160.3032 0004712 310.8760  49.0387  1.70472973 28744
ORBCOMM FM114           
1 41179U 15081A   20212.79425276 -.00000094  00000-0  16630-4 0  9990
2 41179  47.0042  32.9276 0002362 116.1218 243.9910 14.54827305244820
ORBCOMM FM110           
1 41182U 15081D   20212.90833321 -.00000093  00000-0  16852-4 0  9996
2 41182  47.0028  31.9472 0001344 298.0491  62.0257 14.54819290244836
ORBCOMM FM118           
1 41183U 15081E   20212.81768772 -.00000102  00000-0  14792-4 0  9996
2 41183  47.0049  32.4303 0001117 305.1788  54.8992 14.54816588244838
ORBCOMM FM112           
1 41184U 15081F   20212.66133374 -.00000069  00000-0  22315-4 0  9996
2 41184  46.9999 302.3033 0002444 193.7543 166.3275 14.54792169246006
ORBCOMM FM113           
1 41185U 15081G   20212.98126846 -.00000075  00000-0  21046-4 0  9995
2 41185  47.0019 300.8010 0002553 208.1076 151.9671 14.54814043246054
ORBCOMM FM115           
1 41186U 15081H   20212.68448110  .00000031  00000-0  42953-4 0  9995
2 41186  46.9981 200.0239 0001539 249.1090 110.9631 14.58646634247346
ORBCOMM FM108           
1 41187U 15081J   20212.73350128  .00000047  00000-0  48996-4 0  9993
2 41187  47.0035 212.7809 0000721  52.6832 307.4118 14.54808707247187
ORBCOMM FM117           
1 41188U 15081K   20212.83796149 -.00000089  00000-0  17731-4 0  9992
2 41188  46.9995 211.6715 0001174 103.2590 256.8426 14.54810272247204
ORBCOMM FM116           
1 41189U 15081L   20212.61493435 -.00000065  00000-0  23193-4 0  9994
2 41189  47.0022 302.3568 0002540 193.5704 166.5112 14.54799913246003
EXPRESS-AMU1            
1 41191U 15082A   20212.78521564  .00000141  00000-0  00000+0 0  9992
2 41191   0.0163  78.6776 0002791  55.0617 133.8641  1.00270775 16970
GAOFEN 4                
1 41194U 15083A   20212.01410446 -.00000348  00000-0  00000+0 0  9998
2 41194   0.1191 271.6233 0005985 268.1604 239.0916  1.00273935 16952
BELINTERSAT-1           
1 41238U 16001A   20213.03687920  .00000092  00000-0  00000+0 0  9999
2 41238   0.0160 165.5845 0000957 322.4453 245.8698  1.00274765 16725
JASON-3                 
1 41240U 16002A   20212.84087463 -.00000054  00000-0  29875-4 0  9992
2 41240  66.0402 275.0286 0008234 266.1587  93.8487 12.80929408212112
IRNSS-1E                
1 41241U 16003A   20211.60535698 -.00000328  00000-0  00000+0 0  9993
2 41241  29.2932  95.5678 0018800 180.0801   1.6500  1.00267337 16655
EUTELSAT 9B             
1 41310U 16005A   20212.08938234  .00000048  00000-0  00000+0 0  9996
2 41310   0.0493  73.2994 0001930 170.7401 105.3133  1.00268612 16575
NAVSTAR 76 (USA 266)    
1 41328U 16007A   20211.98657254  .00000031  00000-0  00000+0 0  9997
2 41328  54.8297 174.7483 0042510 222.9487 136.6914  2.00555542 32806
COSMOS 2514 [GLONASS-M] 
1 41330U 16008A   20212.27035177 -.00000042  00000-0  00000-0 0  9992
2 41330  65.5106  25.9406 0009050 200.7172   0.0497  2.13103008 34842
KMS 4                   
1 41332U 16009A   20212.78687575  .00001081  00000-0  34674-4 0  9997
2 41332  97.3204 283.7340 0020377 276.6322  83.2602 15.34386056250373
SENTINEL-3A             
1 41335U 16011A   20213.12026936 -.00000021  00000-0  91727-5 0  9997
2 41335  98.6219 279.2470 0001408  89.1379 270.9963 14.26738137231907
HORYU-4                 
1 41340U 16012D   20212.84214565  .00000346  00000-0  19380-4 0  9992
2 41340  31.0060  55.4671 0014061 220.1291 139.8307 15.01525208244405
SES-9                   
1 41380U 16013A   20212.00057278 -.00000358  00000-0  00000-0 0  9990
2 41380   0.0531 263.4969 0002143 201.1541 311.9396  1.00269946 15892
EUTELSAT 65 WEST A      
1 41382U 16014A   20213.07442497 -.00000291  00000-0  00000+0 0  9997
2 41382   0.0469  22.7414 0002056  99.8764 148.1220  1.00271196 16119
IRNSS-1F                
1 41384U 16015A   20212.05718888  .00000147  00000-0  00000-0 0  9997
2 41384   2.1285 215.1475 0020248 196.6924 309.3088  1.00272112 16158
RESURS P3               
1 41386U 16016A   20213.21014300  .00000297  00000-0  11614-4 0  9990
2 41386  97.2422 300.5523 0004345   4.4590  52.6552 15.33134471245138
COSMOS 2515             
1 41394U 16020A   20213.15037231 -.00000545  00000-0 -39436-4 0  9995
2 41394  97.7874 155.5647 0033734 334.9745  24.9838 14.98889753238125
BEIDOU IGSO-6           
1 41434U 16021A   20213.03406545 -.00000150  00000-0  00000+0 0  9997
2 41434  57.1008  60.0449 0039439 203.7961 151.0244  1.00290544 15950
SENTINEL-1B             
1 41456U 16025A   20213.01849149 -.00000067  00000-0 -45164-5 0  9998
2 41456  98.1829 219.2104 0001289  87.9095 272.2258 14.59196991227087
MICROSCOPE              
1 41457U 16025B   20212.77861275  .00000070  00000-0  27129-4 0  9997
2 41457  98.2437 216.6345 0001543  60.9834 299.1517 14.54158290226223
E-ST@R-II               
1 41459U 16025D   20213.10124881  .00001135  00000-0  60353-4 0  9991
2 41459  98.1086 325.3749 0165916  65.2233 296.6153 15.06544435234018
AAUSAT 4                
1 41460U 16025E   20213.10462499  .00001610  00000-0  81463-4 0  9996
2 41460  98.1069 327.8643 0154370  53.4611 308.0717 15.08901328234229
MVL-300                 
1 41464U 16026A   20213.18375529  .00000358  00000-0  14089-4 0  9994
2 41464  97.1428  97.5275 0013559 108.7524  44.0827 15.31600309237855
AIST-2D (RS-48)         
1 41465U 16026B   20213.12676436  .00001010  00000-0  32104-4 0  9994
2 41465  97.1463 101.7283 0013192  91.4977  51.4237 15.34799876238124
IRNSS-1G                
1 41469U 16027A   20212.00476010 -.00000347  00000-0  00000-0 0  9990
2 41469   2.1446 218.2841 0006287 269.6262 311.1883  1.00278244 15655
JCSAT-2B                
1 41471U 16028A   20212.61501326 -.00000180  00000-0  00000-0 0  9995
2 41471   0.0186  73.2856 0002196  51.0143 199.7830  1.00269876 15571
YAOGAN 30               
1 41473U 16029A   20213.09624756 -.00000008  00000-0  60912-5 0  9996
2 41473  97.8569 264.2346 0015486 322.2773  37.7355 14.76313714226885
GSAT0211 (GALILEO 14)   
1 41549U 16030A   20211.72486098 -.00000011  00000-0  00000-0 0  9996
2 41549  56.2602 280.7955 0003446 285.6096  74.4235  1.70475150 26042
GSAT0210 (GALILEO 13)   
1 41550U 16030B   20211.43118743 -.00000012  00000-0  00000+0 0  9995
2 41550  56.2598 280.8055 0003853 255.4063 104.6212  1.70475231 26019
THAICOM 8               
1 41552U 16031A   20212.78426069 -.00000135  00000-0  00000+0 0  9996
2 41552   0.0667  78.7264 0003741 356.5384 234.3853  1.00269602 15247
COSMOS 2516 [GLONASS-M] 
1 41554U 16032A   20212.72413648  .00000047  00000-0  00000+0 0  9993
2 41554  64.0984 265.3640 0014682 219.3121 220.4565  2.13101816 32460
ZIYUAN 3-2 (ZY 3-2)     
1 41556U 16033A   20213.11966440  .00000456  00000-0  23375-4 0  9992
2 41556  97.3620 287.2567 0002497 201.4349 246.5047 15.21335264231558
NUSAT-1 (FRESCO)        
1 41557U 16033B   20213.12270936  .00000803  00000-0  32009-4 0  9992
2 41557  97.4040 303.0658 0011926 296.5804  63.4213 15.28101522232447
NUSAT-2 (BATATA)        
1 41558U 16033C   20213.16214574  .00000930  00000-0  34538-4 0  9993
2 41558  97.4099 305.5720 0010919 292.9864  67.0224 15.30096062232577
COSMOS 2517 (GEO-IK 2)  
1 41579U 16034A   20213.10079006 -.00000041  00000-0  25125-5 0  9997
2 41579  99.2466 225.0617 0000894 121.4507 238.6733 13.85902516210227
INTELSAT 31 (IS-31)     
1 41581U 16035A   20212.49685951 -.00000164  00000-0  00000-0 0  9990
2 41581   0.0049 243.4743 0001245 334.8453 174.0648  1.00270819 15085
BEIDOU-2 G7             
1 41586U 16037A   20212.46663233 -.00000369  00000-0  00000-0 0  9990
2 41586   1.5797  65.5553 0008114   9.4465 152.0662  1.00269872 15256
ABS-2A (MONGOLSAT-1)    
1 41588U 16038A   20212.82913713 -.00000098  00000-0  00000+0 0  9992
2 41588   0.0095  85.0732 0002215 324.4702 272.5497  1.00271597 15171
EUTELSAT 117 WEST B     
1 41589U 16038B   20212.40708083 -.00000026  00000-0  00000+0 0  9990
2 41589   0.0128 159.3152 0000741  65.8675 112.8545  1.00274410 15163
BRISAT                  
1 41591U 16039A   20212.61229875 -.00000211  00000-0  00000+0 0  9990
2 41591   0.0145  67.2201 0002311  56.5367 195.8716  1.00269783 15109
ECHOSTAR 18             
1 41592U 16039B   20212.45201888 -.00000296  00000-0  00000-0 0  9991
2 41592   0.0190  82.0062 0001790  41.5109 286.3738  1.00273120 15096
CARTOSAT-2C             
1 41599U 16040A   20213.17181382  .00000251  00000-0  15132-4 0  9992
2 41599  97.4465 272.0545 0015203  62.7259  13.3777 15.19264398227739
SKYSAT-C1               
1 41601U 16040C   20212.80264092  .00000897  00000-0  26362-4 0  9998
2 41601  97.2232 289.8768 0004596  26.1736 333.9741 15.37707970228374
GHGSAT-D                
1 41602U 16040D   20213.12709434  .00000538  00000-0  25720-4 0  9998
2 41602  97.3072 270.0290 0010176  46.4877 313.7204 15.23093884228122
LAPAN-A3                
1 41603U 16040E   20213.12213332  .00000164  00000-0  10717-4 0  9991
2 41603  97.3057 265.2910 0014778  54.1424  33.0486 15.19854516227773
BIROS                   
1 41604U 16040F   20212.83957701  .00000336  00000-0  17618-4 0  9992
2 41604  97.3114 269.1216 0013284  39.0678 321.1516 15.22075313227975
M3MSAT                  
1 41605U 16040G   20212.66155838  .00000335  00000-0  17887-4 0  9992
2 41605  97.3104 267.6842 0013420  52.0681 308.1766 15.21430514227861
FLOCK 2P-6              
1 41606U 16040H   20212.75689614  .00000866  00000-0  35872-4 0  9990
2 41606  97.3231 275.5809 0010639  30.1624 330.0225 15.26512721228363
SWAYAM                  
1 41607U 16040J   20212.85556117  .00001139  00000-0  49299-4 0  9995
2 41607  97.3146 271.1975 0014107  40.3567 319.8714 15.24340949228039
FLOCK 2P-9              
1 41610U 16040M   20212.83520713  .00001855  00000-0  73809-4 0  9999
2 41610  97.3230 275.4345 0011000  29.0866 331.0983 15.26457458228164
FLOCK 2P-10             
1 41612U 16040P   20212.73743500  .00001421  00000-0  57046-4 0  9995
2 41612  97.3278 276.2497 0010973  39.1916 321.0116 15.26544303228322
FLOCK 2P-8              
1 41613U 16040Q   20212.79979211  .00001729  00000-0  69032-4 0  9991
2 41613  97.3273 276.0766 0010998  37.6947 322.5060 15.26438005228324
FLOCK 2P-12             
1 41614U 16040R   20212.62895461  .00001045  00000-0  42805-4 0  9998
2 41614  97.3278 276.2008 0011109  39.8489 320.3563 15.26433081228302
FLOCK 2P-3              
1 41618U 16040V   20212.84325535  .00000992  00000-0  40759-4 0  9992
2 41618  97.3289 276.4102 0010774  38.3321 321.8681 15.26447816228332
BEESAT-4                
1 41619U 16040W   20212.69310850  .00001026  00000-0  45047-4 0  9994
2 41619  97.3166 271.1992 0011926  35.2579 324.9445 15.24104974215989
MUOS-5                  
1 41622U 16041A   20212.41631701 -.00000151  00000-0  00000+0 0  9994
2 41622   7.1579 306.9774 0205189 218.0340 188.3625  1.00271212 15538
SJ 16-02                
1 41634U 16043A   20213.04785637  .00000077  00000-0  12058-4 0  9994
2 41634  75.0042 351.1818 0008104 196.7247 163.3675 14.85702008221713
TIANTONG-1 1            
1 41725U 16048A   20212.26787806 -.00000327  00000-0  00000+0 0  9991
2 41725   2.9283 334.9700 0000887 107.2011  64.0170  1.00270825 14723
GAOFEN 3                
1 41727U 16049A   20213.11141416 -.00000061  00000-0 -53439-5 0  9996
2 41727  98.4118 220.1185 0001134 113.0920 247.0396 14.42215331209163
JCSAT-16                
1 41729U 16050A   20212.06879126 -.00000246  00000-0  00000-0 0  9994
2 41729   0.1430  95.4548 0002322 114.7368 213.1589  1.00270585 14526
QSS (MOZI)              
1 41731U 16051A   20213.07976615  .00000216  00000-0  11428-4 0  9997
2 41731  97.3533 125.2589 0012186 206.2543 278.3573 15.25175856220217
USA 270                 
1 41744U 16052A   20206.17635333  .00000035  00000-0  00000+0 0  9992
2 41744   0.6420  96.3133 0000464 122.8487 206.0076  1.00341887  6543
USA 271                 
1 41745U 14052B   20212.42305259 -.00000332  00000-0  00000+0 0  9998
2 41745   0.3222  98.5805 0000200  99.6800  39.5913  1.00118296  6409
INTELSAT 36 (IS-36)     
1 41747U 16053A   20213.10417515 -.00000039  00000-0  00000+0 0  9992
2 41747   0.0100  48.2564 0001956 328.8043  38.1053  1.00271384 14432
INTELSAT 33E (IS-33E)   
1 41748U 16053B   20213.10789022  .00000034  00000-0  00000+0 0  9992
2 41748   0.0114  63.6517 0002346  67.2901 277.0548  1.00272393 14690
INSAT-3DR               
1 41752U 16054A   20213.04565851 -.00000092  00000-0  00000+0 0  9998
2 41752   0.0777  96.7064 0010276 167.8259 135.0151  1.00271465 14274
PERUSAT 1               
1 41770U 16058A   20213.14420808  .00000001  00000-0  10092-4 0  9991
2 41770  98.1746 285.5739 0001299  92.4696 267.6655 14.58572214206129
SKYSAT-C4               
1 41771U 16058B   20212.75617759  .00000904  00000-0  26437-4 0  9993
2 41771  97.2072 282.1224 0002245 106.6966 253.4526 15.37838110215324
SKYSAT-C5               
1 41772U 16058C   20213.21402209  .00000380  00000-0  18546-4 0  9998
2 41772  97.3543 282.0788 0002208  90.8290 331.2187 15.23977714215200
SKYSAT-C2               
1 41773U 16058D   20212.73004019  .00000896  00000-0  26332-4 0  9996
2 41773  97.2075 282.4174 0001200  91.6994 268.4389 15.37701887215345
SKYSAT-C3               
1 41774U 16058E   20212.80866679  .00000901  00000-0  26426-4 0  9994
2 41774  97.2070 282.4634 0002833 105.1560 254.9999 15.37738376215345
PISAT                   
1 41784U 16059B   20212.84436101  .00000036  00000-0  15889-4 0  9993
2 41784  98.0155 268.1534 0032139 174.2801 185.8775 14.63527877205259
ALSAT 1B                
1 41785U 16059C   20213.10466378 -.00000019  00000-0  54372-5 0  9996
2 41785  98.0101 267.7713 0031420 173.1449 187.0198 14.63365459205285
ALSAT 2B                
1 41786U 16059D   20212.93582701  .00000042  00000-0  16075-4 0  9998
2 41786  98.0591 280.6163 0001685  78.5496 281.5889 14.66589752205714
PATHFINDER 1            
1 41787U 16059E   20212.72954789  .00000052  00000-0  18960-4 0  9991
2 41787  98.0135 268.4913 0030401 172.6057 187.5596 14.63740758205276
CANX-7                  
1 41788U 16059F   20212.74503405  .00006663  00000-0  90285-3 0  9992
2 41788  98.2556 301.6960 0033021 156.8578 203.4107 14.78899530206211
SCATSAT 1               
1 41790U 16059H   20213.05033774 -.00000010  00000-0  83553-5 0  9990
2 41790  98.1584 252.0329 0006427 137.3318 222.8383 14.50923757203541
GSAT-18                 
1 41793U 16060A   20213.04565851 -.00000092  00000-0  00000+0 0  9990
2 41793   0.0479 103.1479 0003321  15.9376 280.4567  1.00271669 14000
SKY MUSTER 2            
1 41794U 16060B   20212.60810550 -.00000259  00000-0  00000+0 0  9996
2 41794   0.0192  79.8347 0001917  52.4435 180.1367  1.00269106 14037
HIMAWARI-9              
1 41836U 16064A   20212.83660392 -.00000289  00000-0  00000+0 0  9992
2 41836   0.0109 355.5390 0000632 117.7053 277.5988  1.00271407 13775
SHIJIAN-17 (SJ-17)      
1 41838U 16065A   20212.87598615 -.00000097  00000-0  00000-0 0  9995
2 41838   0.3417  93.8715 0003799  27.4086 306.2460  1.00270847 13676
XPNAV-1                 
1 41841U 16066A   20213.13337631  .00000470  00000-0  23088-4 0  9998
2 41841  97.4104 218.3620 0014648 186.1089 239.1466 15.22795616206736
XIAOXIANG 1             
1 41842U 16066B   20213.10644519  .00000593  00000-0  27389-4 0  9996
2 41842  97.4056 219.7012 0014153 185.9590 247.6129 15.24001119206840
PINA 2A                 
1 41843U 16066C   20213.13289647  .00000561  00000-0  25344-4 0  9991
2 41843  97.4086 219.9617 0020560 206.1322 222.1702 15.24915876206855
PINA 2B                 
1 41844U 16066D   20213.13382612  .00000622  00000-0  27830-4 0  9998
2 41844  97.4029 220.8129 0014647 183.5729 244.8155 15.24909145206923
LISHUI 1-01             
1 41845U 16066E   20213.22210090  .00001935  00000-0  26591-3 0  9999
2 41845  98.7390 294.5410 0284814  24.8342 336.6256 14.55442007196611
CAS-2T & KS-1Q          
1 41847U 16066G   20212.76332289  .00000044  00000-0  16143-4 0  9997
2 41847  98.6507 263.7074 0366799  99.8714 264.4081 14.38080992195642
WORLDVIEW-4             
1 41848U 16067A   20212.59808448  .00000064  00000-0  38994-5 0  9996
2 41848  97.8531 323.8425 0001680 302.7455  57.3628 15.40396812203474
RAVAN                   
1 41849U 16067B   20212.81040577 -.00000082  00000-0 -16397-5 0  9998
2 41849  97.9387 323.4106 0010265  95.6979 264.5414 14.98600722203111
CELTEE 1                
1 41850U 16067C   20212.84566279  .00000553  00000-0  53043-4 0  9994
2 41850  97.9305 321.0441 0010382 103.4973 256.7405 14.97199697202992
OPTICUBE 04             
1 41851U 16067D   20212.85043220  .00000644  00000-0  61327-4 0  9997
2 41851  97.9298 320.6446 0010560 104.7295 255.5097 14.96937639202970
AEROCUBE 8D             
1 41852U 16067E   20212.79996695  .00000057  00000-0  10246-4 0  9996
2 41852  97.9308 320.4324 0011226 103.9524 256.2946 14.96935140202945
AEROCUBE 8C             
1 41853U 16067F   20212.83256249  .00000173  00000-0  20360-4 0  9993
2 41853  97.9294 320.3658 0011285 107.0698 253.1760 14.96891893202947
PROMETHEUS 2-1          
1 41854U 16067G   20212.79273782  .00000771  00000-0  71854-4 0  9998
2 41854  97.9316 320.6816 0012070 104.8693 255.3865 14.97230555202959
PROMETHEUS 2-3          
1 41855U 16067H   20213.06021649  .00000702  00000-0  65882-4 0  9991
2 41855  97.9318 320.9879 0012242 104.4684 255.7896 14.97240361202999
YUNHAI 1                
1 41857U 16068A   20213.07477747  .00000139  00000-0  67212-4 0  9992
2 41857  98.5611 211.4761 0015182 209.8638 150.1681 14.32639168194359
GSAT0207 (GALILEO 15)   
1 41859U 16069A   20212.26256345 -.00000001  00000-0  00000-0 0  9991
2 41859  54.6585 160.6537 0005359 299.3465  60.5549  1.70472895 22949
GSAT0212 (GALILEO 16)   
1 41860U 16069B   20212.11600649  .00000001  00000-0  00000-0 0  9996
2 41860  54.6562 160.6568 0004427 317.9927  41.9291  1.70472825 23036
GSAT0213 (GALILEO 17)   
1 41861U 16069C   20211.89524634  .00000003  00000-0  00000+0 0  9991
2 41861  54.6589 160.6668 0004527 281.3032  78.6030  1.70473024 23039
GSAT0214 (GALILEO 18)   
1 41862U 16069D   20210.86909275  .00000015  00000-0  00000-0 0  9990
2 41862  54.6576 160.6907 0003797 287.7306  72.1905  1.70472862 23000
GOES 16                 
1 41866U 16071A   20212.44483765 -.00000262  00000-0  00000+0 0  9998
2 41866   0.0434 134.7106 0000707  12.0967 246.6631  1.00271577 13573
SHENZHOU-11 MODULE      
1 41868U 16061G   20212.43066633  .00059869  99891-5  10056-3 0  9992
2 41868  42.7851 227.1681 0012562 106.0445  35.6630 16.02431827212955
TIANLIAN 1-04           
1 41869U 16072A   20212.59584149 -.00000121  00000-0  00000-0 0  9991
2 41869   0.1205 283.4465 0018524 174.9902 141.7754  1.00271163 13770
LEMUR-2-XIAOQING        
1 41871U 16062C   20213.02811532  .00001950  00000-0  85793-4 0  9997
2 41871  51.6411  50.7568 0005868  16.8715 343.2458 15.26896370204874
LEMUR-2-SOKOLSKY        
1 41872U 16062D   20212.86857360  .00000834  00000-0  44386-4 0  9991
2 41872  51.6840  57.3900 0003503 112.2624 247.8728 15.26711348204823
LEMUR-2-ANUBHAVTHAKUR   
1 41873U 16062E   20212.87639395  .00000885  00000-0  50091-4 0  9995
2 41873  51.6915  72.0621 0002025  41.9216 318.1917 15.23750908204570
LEMUR-2-WINGO           
1 41874U 16062F   20212.81192943 -.00000036  00000-0  11959-4 0  9992
2 41874  51.6551  57.3508 0010896 292.7001  67.2826 15.26329269204767
GOKTURK 1A              
1 41875U 16073A   20213.04788732  .00000001  00000-0  94382-5 0  9991
2 41875  98.1491 106.7050 0001486  75.2804 284.8563 14.62763685194933
RESOURCESAT-2A          
1 41877U 16074A   20213.15179597 -.00000008  00000-0  16692-4 0  9994
2 41877  98.7949 282.9614 0001145 110.3258 249.8059 14.21594567189254
WGS F8 (USA 272)        
1 41879U 16075A   20212.61229962 -.00000217  00000-0  00000+0 0  9992
2 41879   0.0155  55.4585 0000157  84.7682 178.6898  1.00270460 13384
FENGYUN 4A              
1 41882U 16077A   20212.85536544 -.00000345  00000-0  00000-0 0  9994
2 41882   0.0508  70.1270 0003973  68.2535 223.1339  1.00273860 13459
CYGFM05                 
1 41884U 16078A   20212.85556621  .00000251  00000-0  15000-4 0  9990
2 41884  34.9557 150.3500 0015052  73.2402 286.9960 15.14652396200694
CYGFM04                 
1 41885U 16078B   20212.87708104  .00000253  00000-0  14913-4 0  9992
2 41885  34.9490 143.1774 0013404  84.5628 275.6612 15.15163107200763
CYGFM02                 
1 41886U 16078C   20213.13347753  .00000257  00000-0  15136-4 0  9994
2 41886  34.9582 143.3450 0014155  82.6382 277.5938 15.15164182200798
CYGFM01                 
1 41887U 16078D   20213.09326497  .00000240  00000-0  14494-4 0  9990
2 41887  34.9524 150.0773 0015523  76.1486 284.0951 15.14464966200713
CYGFM08                 
1 41888U 16078E   20212.54582353  .00000261  00000-0  15438-4 0  9997
2 41888  34.9494 149.0792 0014680  78.4373 281.7986 15.14880366200664
CYGFM06                 
1 41889U 16078F   20212.84898806  .00000265  00000-0  15544-4 0  9996
2 41889  34.9577 143.8042 0013110  79.9487 280.2703 15.15158067200754
CYGFM07                 
1 41890U 16078G   20212.89330524  .00000247  00000-0  14598-4 0  9999
2 41890  34.9515 142.6016 0012870  83.8471 276.3707 15.15156750200763
CYGFM03                 
1 41891U 16078H   20212.88884637  .00000239  00000-0  14178-4 0  9993
2 41891  34.9546 142.8875 0012347  82.4161 277.7952 15.15152261200763
ECHOSTAR 19             
1 41893U 16079A   20211.60343852 -.00000151  00000-0  00000+0 0  9992
2 41893   0.0020 148.8889 0001629 329.5345 309.4094  1.00271386 13195
ARASE (ERG)             
1 41896U 16080A   20208.28632799 -.00000222  00000-0 -53560-3 0  9991
2 41896  31.5472 134.8578 7001089 186.5521 153.8705  2.53851430 33344
TANSAT                  
1 41898U 16081A   20213.09019563  .00000088  00000-0  29958-4 0  9990
2 41898  98.2861 154.4434 0022523 101.0703 259.3029 14.56755114191760
YIJIAN                  
1 41899U 16081B   20213.06502664  .00000035  00000-0  18192-4 0  9997
2 41899  98.2813 153.6398 0023576 102.0091 258.3747 14.56481520191727
SPARK 1                 
1 41900U 16081C   20213.07675581  .00000011  00000-0  13225-4 0  9996
2 41900  98.2836 152.3932 0026425 102.5551 257.8590 14.55523944191600
SPARK 2                 
1 41901U 16081D   20213.10454914  .00000023  00000-0  16014-4 0  9998
2 41901  98.2799 151.2451 0028233 103.0278 257.4074 14.55203500191565
JCSAT-15                
1 41903U 16082A   20212.68071194 -.00000365  00000-0  00000-0 0  9992
2 41903   0.0127 315.6379 0001629 169.7600 178.4723  1.00271026 13229
STAR ONE D1             
1 41904U 16082B   20212.43965135 -.00000225  00000-0  00000+0 0  9997
2 41904   0.0005 251.1520 0002406 245.5344 246.1048  1.00273090 13258
SUPERVIEW-1 01          
1 41907U 16083A   20213.17735356  .00000494  00000-0  30085-4 0  9991
2 41907  97.5220 297.4269 0016763  84.9619 358.3852 15.14869482198399
SUPERVIEW-1 02          
1 41908U 16083B   20213.20988201  .00000499  00000-0  30266-4 0  9991
2 41908  97.4472 287.3598 0015755  82.7936 334.2317 15.14872879198404
TJS-2                   
1 41911U 17001A   20212.85550681 -.00000357  00000-0  00000+0 0  9995
2 41911   0.2095  97.2199 0006518 293.7917 333.3956  1.00272445 13190
XINGYUN SHIYAN-1        
1 41913U 17002A   20213.15785295  .00000935  00000-0  58122-4 0  9997
2 41913  97.3954 274.2498 0014071 119.1136 293.6845 15.11936848196120
JILIN-1-03              
1 41914U 17002B   20213.13431664 -.00000259  00000-0 -12158-4 0  9994
2 41914  97.3894 271.6219 0013479 128.2482 312.8542 15.10007749195970
KAIDUN 1                
1 41915U 17002C   20213.15738522  .00000493  00000-0  32579-4 0  9997
2 41915  97.3945 274.7350 0011449 128.8384 284.0574 15.11701281196160
IRIDIUM 106             
1 41917U 17003A   20212.69027107  .00000057  00000-0  13317-4 0  9993
2 41917  86.3955 265.8766 0002636  97.8824 262.2671 14.34218002185404
IRIDIUM 103             
1 41918U 17003B   20212.88687507  .00000054  00000-0  12159-4 0  9991
2 41918  86.3955 265.7015 0002043 101.9060 258.2365 14.34218426185463
IRIDIUM 109             
1 41919U 17003C   20212.68391993  .00000051  00000-0  11099-4 0  9990
2 41919  86.3955 265.8101 0002248  95.6990 264.4462 14.34218197185393
IRIDIUM 102             
1 41920U 17003D   20212.86149574  .00000062  00000-0  15222-4 0  9992
2 41920  86.3955 265.7056 0002062  88.9473 271.1959 14.34215539185504
IRIDIUM 105             
1 41921U 17003E   20212.76275865  .00000096  00000-0  27079-4 0  9995
2 41921  86.3962 234.1389 0003103 107.7821 252.3714 14.34222487187340
IRIDIUM 104             
1 41922U 17003F   20212.87418804  .00000025  00000-0  19324-5 0  9997
2 41922  86.3955 265.7103 0002487  99.6681 260.4796 14.34215625185494
IRIDIUM 114             
1 41923U 17003G   20212.88053808  .00000055  00000-0  12402-4 0  9990
2 41923  86.3955 265.6971 0002527  87.2682 272.8803 14.34216464185494
IRIDIUM 108             
1 41924U 17003H   20213.11812380  .00000093  00000-0  26256-4 0  9990
2 41924  86.3956 234.0088 0002654  92.6336 267.5163 14.34219812187382
IRIDIUM 112             
1 41925U 17003J   20213.14692261  .00000054  00000-0  12160-4 0  9994
2 41925  86.3950 265.5292 0002330  81.9343 278.2117 14.34219406185568
IRIDIUM 111             
1 41926U 17003K   20212.78539957  .00000061  00000-0  14721-4 0  9999
2 41926  86.3953 265.7046 0002143 100.5857 259.5580 14.34216817185537
SBIRS GEO-4 (USA 273)   
1 41937U 17004A   20212.59353847  .00000121  00000-0  00000-0 0  9991
2 41937   3.7093 312.0045 0002725 172.5893 238.3429  1.00272237 13070
HISPASAT 36W-1          
1 41942U 17006A   20213.13058157 -.00000260  00000-0  00000+0 0  9990
2 41942   0.0231  88.4066 0001249  55.6411 176.1761  1.00269151 12993
TELKOM 3S               
1 41944U 17007A   20212.00249198 -.00000376  00000-0  00000-0 0  9994
2 41944   0.0171  85.4482 0001725  42.8765 298.6544  1.00270508 12599
INTELSAT 32E (IS-32E)   
1 41945U 17007B   20212.97530733 -.00000284  00000-0  00000+0 0  9996
2 41945   0.0185  70.9909 0000561 352.9659 193.0151  1.00270119 12716
CARTOSAT-2D             
1 41948U 17008A   20212.85973744  .00000383  00000-0  21453-4 0  9999
2 41948  97.4889 270.9393 0007160 192.8129 167.2891 15.19229087191551
INS-1A                  
1 41949U 17008B   20212.84809441  .00001022  00000-0  43973-4 0  9993
2 41949  97.3522 276.4278 0010363 138.9713 221.2306 15.24831813192035
FLOCK 3P-20             
1 41950U 17008C   20212.74333023  .00000998  00000-0  41209-4 0  9993
2 41950  97.3613 279.5426 0010144 134.9716 225.2347 15.26302173192182
FLOCK 3P-8              
1 41951U 17008D   20212.72484053  .00001026  00000-0  42138-4 0  9997
2 41951  97.3616 279.6088 0009369 134.3257 225.8751 15.26427576192182
FLOCK 3P-51             
1 41952U 17008E   20212.82814440  .00001039  00000-0  42653-4 0  9992
2 41952  97.3619 279.8731 0008711 149.2112 210.9639 15.26423910192209
INS-1B                  
1 41954U 17008G   20212.85501542  .00000982  00000-0  42332-4 0  9993
2 41954  97.3517 276.4743 0010105 140.1225 220.0757 15.24865567191982
FLOCK 3P-24             
1 41956U 17008J   20212.83928172  .00000803  00000-0  31667-4 0  9996
2 41956  97.3613 281.5449 0009322 123.3161 236.8973 15.28417493192332
FLOCK 3P-4              
1 41965U 17008T   20212.72999906  .00001135  00000-0  46305-4 0  9991
2 41965  97.3614 279.5974 0009356 133.3658 226.8361 15.26448351192102
FLOCK 3P-2              
1 41966U 17008U   20212.73371711  .00001111  00000-0  45397-4 0  9995
2 41966  97.3628 279.6763 0009212 131.2644 228.9389 15.26437807192026
FLOCK 3P-3              
1 41968U 17008W   20213.11676907  .00000861  00000-0  35216-4 0  9995
2 41968  97.3623 280.1577 0009289 129.8444 230.3614 15.26984178192079
FLOCK 3P-12             
1 41972U 17008AA  20212.72008844  .00000952  00000-0  39291-4 0  9993
2 41972  97.3629 279.7075 0009025 135.0124 225.1848 15.26429869192105
FLOCK 3P-11             
1 41975U 17008AD  20212.66121896  .00001266  00000-0  51334-4 0  9993
2 41975  97.3619 279.6244 0009279 134.0998 226.1006 15.26431541191985
FLOCK 3P-60             
1 41976U 17008AE  20212.76558815  .00001022  00000-0  41956-4 0  9999
2 41976  97.3636 279.8934 0008742 134.8138 225.3813 15.26450097191367
FLOCK 3P-58             
1 41977U 17008AF  20212.65884842  .00001022  00000-0  42005-4 0  9996
2 41977  97.3628 279.6493 0009112 135.1450 225.0526 15.26421059192174
FLOCK 3P-57             
1 41978U 17008AG  20212.83885041  .00001041  00000-0  42710-4 0  9993
2 41978  97.3622 279.8519 0009183 132.7247 227.4766 15.26438547192043
FLOCK 3P-70             
1 41980U 17008AJ  20212.64346854  .00001204  00000-0  48989-4 0  9994
2 41980  97.3660 280.0992 0009100 136.7883 223.4071 15.26406906192012
FLOCK 3P-79             
1 41984U 17008AN  20212.82912209  .00001109  00000-0  45254-4 0  9997
2 41984  97.3651 280.2827 0008726 138.0308 222.1600 15.26469994192048
FLOCK 3P-86             
1 41985U 17008AP  20212.83256290  .00000953  00000-0  39360-4 0  9997
2 41985  97.3658 280.3034 0008600 136.7795 223.4120 15.26416775192032
FLOCK 3P-34             
1 41988U 17008AS  20212.77174084  .00001164  00000-0  47464-4 0  9992
2 41988  97.3638 279.8576 0008733 132.8941 227.3032 15.26415156191513
FLOCK 3P-35             
1 41989U 17008AT  20212.72675798  .00001207  00000-0  48325-4 0  9991
2 41989  97.3633 280.1970 0008475 131.1527 229.0445 15.26948361191535
FLOCK 3P-33             
1 41990U 17008AU  20212.74808140  .00000858  00000-0  36028-4 0  9992
2 41990  97.3629 279.7221 0008676 134.8331 225.3614 15.26113126191508
LEMUR-2-JOBANPUTRA      
1 41991U 17008AV  20212.84628083  .00000909  00000-0  39109-4 0  9990
2 41991  97.3544 277.5345 0008444 141.3304 218.8540 15.25119964191403
LEMUR-2-SPIRE-MINIONS   
1 41992U 17008AW  20212.82988535  .00001306  00000-0  52944-4 0  9994
2 41992  97.3577 279.1180 0008169 137.3406 222.8468 15.26395073191498
LEMUR-2-SATCHMO         
1 41993U 17008AX  20212.70878345  .00001048  00000-0  44426-4 0  9998
2 41993  97.3550 277.7153 0008441 140.6057 219.5796 15.25296798191407
LEMUR-2-SMITA-SHARAD    
1 41994U 17008AY  20212.73534714  .00001315  00000-0  53349-4 0  9992
2 41994  97.3573 278.8893 0008329 136.2571 223.9329 15.26351637191476
LEMUR-2-RDEATON         
1 41995U 17008AZ  20212.72857628  .00000899  00000-0  38461-4 0  9998
2 41995  97.3554 277.8733 0009093 153.4974 206.6730 15.25338044192094
LEMUR-2-TACHIKOMA       
1 41996U 17008BA  20212.83459457  .00001254  00000-0  51176-4 0  9993
2 41996  97.3603 279.1363 0008862 151.1375 209.0356 15.26224380191387
LEMUR-2-NOGUECORREIG    
1 41997U 17008BB  20212.76266567  .00001351  00000-0  54573-4 0  9996
2 41997  97.3588 279.3322 0008922 150.6351 209.5391 15.26460456191483
LEMUR-2-MIA-GRACE       
1 41998U 17008BC  20212.80222104  .00000928  00000-0  39752-4 0  9992
2 41998  97.3550 277.7507 0008995 154.0453 206.1238 15.25227014191338
BGUSAT                  
1 41999U 17008BD  20212.77172194  .00001664  00000-0  64234-4 0  9993
2 41999  97.3636 280.7830 0008886 146.7829 213.3970 15.27645441191569
DIDO-2                  
1 42000U 17008BE  20212.82693219  .00000657  00000-0  29522-4 0  9991
2 42000  97.3524 276.9368 0009009 156.8462 203.3183 15.24534983191374
FLOCK 3P-49             
1 42001U 17008BF  20212.72785497  .00001225  00000-0  49585-4 0  9994
2 42001  97.3628 279.9018 0008751 149.6231 210.5516 15.26566735192038
FLOCK 3P-68             
1 42003U 17008BH  20212.75779960  .00001151  00000-0  46913-4 0  9997
2 42003  97.3623 279.8029 0008947 148.7565 211.4207 15.26442033191831
FLOCK 3P-61             
1 42009U 17008BP  20212.74658954  .00001048  00000-0  42954-4 0  9997
2 42009  97.3624 279.8467 0008362 151.8027 208.3666 15.26439633192166
FLOCK 3P-40             
1 42010U 17008BQ  20212.74090951  .00000910  00000-0  37689-4 0  9992
2 42010  97.3620 279.7400 0008329 154.5195 205.6456 15.26444607191421
FLOCK 3P-14             
1 42012U 17008BS  20212.81094289  .00000845  00000-0  35276-4 0  9997
2 42012  97.3651 280.2104 0008007 154.8924 205.2705 15.26338979192003
FLOCK 3P-54             
1 42014U 17008BU  20212.47249919  .00000929  00000-0  38431-4 0  9992
2 42014  97.3632 279.5616 0007754 154.6099 205.5522 15.26431905192117
PEASSS                  
1 42015U 17008BV  20212.83726878  .00000831  00000-0  35413-4 0  9996
2 42015  97.3549 278.6362 0006881 156.3844 203.7711 15.25690707191968
AL-FARABI 1             
1 42016U 17008BW  20212.83422311  .00001108  00000-0  45114-4 0  9999
2 42016  97.3580 279.7501 0006788 154.9930 205.1639 15.26554468192147
NAYIF-1 (EO-88)         
1 42017U 17008BX  20212.68067966  .00001154  00000-0  46826-4 0  9996
2 42017  97.3575 279.6092 0006831 155.1299 205.0271 15.26595698192147
FLOCK 3P-23             
1 42018U 17008BY  20212.73611681  .00000998  00000-0  41052-4 0  9993
2 42018  97.3621 279.5643 0009550 131.8646 228.3409 15.26429967190952
FLOCK 3P-76             
1 42019U 17008BZ  20212.84176454  .00000973  00000-0  40169-4 0  9991
2 42019  97.3668 280.3562 0009240 137.4878 222.7078 15.26386129192032
FLOCK 3P-32             
1 42023U 17008CD  20212.76535362  .00001027  00000-0  42098-4 0  9998
2 42023  97.3623 279.8023 0008962 132.7189 227.4805 15.26483637192195
FLOCK 3P-77             
1 42025U 17008CF  20212.76030890  .00001001  00000-0  41165-4 0  9992
2 42025  97.3651 280.2256 0009033 137.8356 222.3579 15.26437514192032
FLOCK 3P-80             
1 42026U 17008CG  20212.80867421  .00000900  00000-0  37296-4 0  9993
2 42026  97.3652 280.2944 0008678 138.2848 221.9054 15.26451837190184
FLOCK 3P-66             
1 42027U 17008CH  20212.75679303  .00001130  00000-0  46100-4 0  9998
2 42027  97.3628 279.8588 0008901 148.2528 211.9249 15.26446560192141
FLOCK 3P-65             
1 42028U 17008CJ  20212.75568416  .00001134  00000-0  46252-4 0  9993
2 42028  97.3637 279.9033 0008733 148.1094 212.0675 15.26445962192047
FLOCK 3P-50             
1 42029U 17008CK  20212.68645679  .00001158  00000-0  47194-4 0  9995
2 42029  97.3641 279.8972 0008392 149.3687 210.8044 15.26435587191357
FLOCK 3P-46             
1 42031U 17008CM  20212.74929667  .00001030  00000-0  42300-4 0  9990
2 42031  97.3621 279.7896 0008359 149.3412 210.8317 15.26428158192160
FLOCK 3P-47             
1 42032U 17008CN  20212.75010902  .00001167  00000-0  47521-4 0  9996
2 42032  97.3623 279.7916 0008350 149.6957 210.4766 15.26437983192018
FLOCK 3P-64             
1 42034U 17008CQ  20212.67768617  .00001060  00000-0  43394-4 0  9998
2 42034  97.3619 279.7238 0008216 154.0073 206.1580 15.26456039192178
FLOCK 3P-39             
1 42038U 17008CU  20212.72945728  .00001809  00000-0  72089-4 0  9992
2 42038  97.3615 279.7129 0008057 153.5517 206.6135 15.26475438192082
FLOCK 3P-55             
1 42041U 17008CX  20212.83837302  .00000929  00000-0  38402-4 0  9999
2 42041  97.3618 279.9649 0007738 155.8130 204.3474 15.26454403192214
FLOCK 3P-81             
1 42043U 17008CZ  20212.83882591  .00000771  00000-0  32484-4 0  9990
2 42043  97.3654 280.2769 0009108 138.7132 221.4797 15.26293161191386
FLOCK 3P-87             
1 42044U 17008DA  20213.11697457  .00000923  00000-0  38363-4 0  9999
2 42044  97.3642 280.4091 0009165 135.3719 224.8259 15.26266551191575
FLOCK 3P-74             
1 42048U 17008DE  20212.72031807  .00001025  00000-0  42107-4 0  9995
2 42048  97.3662 280.1789 0009329 136.2923 223.9056 15.26424324191345
FLOCK 3P-31             
1 42049U 17008DF  20213.10871728  .00001080  00000-0  44192-4 0  9990
2 42049  97.3622 280.1042 0008909 132.7977 227.4012 15.26451223190862
FLOCK 3P-83             
1 42050U 17008DG  20212.65526598  .00001869  00000-0  74473-4 0  9993
2 42050  97.3657 280.0155 0009143 136.8617 223.3339 15.26436871192066
TK-1                    
1 42061U 17012A   20212.87901715  .00003764  00000-0  49817-4 0  9992
2 42061  96.9300 209.2838 0015453 164.5733 195.5999 15.58790015194182
SENTINEL-2B             
1 42063U 17013A   20213.14460656  .00000005  00000-0  18436-4 0  9998
2 42063  98.5691 286.7592 0001419  93.2425 266.8920 14.30816427177626
ECHOSTAR 23             
1 42070U 17014A   20212.44834104 -.00000284  00000-0  00000-0 0  9996
2 42070   0.0174  73.4937 0002057  54.5175 274.0108  1.00270091 12398
WGS F9 (USA 275)        
1 42075U 17016A   20212.03303806  .00000061  00000-0  00000+0 0  9999
2 42075   0.0104  86.4428 0000374  26.5925 263.7765  1.00268778 12390
SES-10                  
1 42432U 17017A   20212.82700630 -.00000287  00000-0  00000+0 0  9991
2 42432   0.0121  67.4111 0002747  58.5602  53.7425  1.00271515 12200
CHINASAT 16 (SJ-13)     
1 42662U 17018A   20212.58952679 -.00000366  00000-0  00000+0 0  9990
2 42662   0.0517  89.1970 0004874 122.2944  59.8666  1.00267127 12207
KOREASAT 7              
1 42691U 17023A   20212.07385095 -.00000375  00000-0  00000-0 0  9995
2 42691   0.0157  57.8642 0002108 100.8197 291.9531  1.00269863 11834
SGDC                    
1 42692U 17023B   20212.35121564 -.00000263  00000-0  00000-0 0  9992
2 42692   0.0090  33.5950 0003194  93.9946 232.4244  1.00271339 11881
GSAT-9                  
1 42695U 17024A   20212.07018704 -.00000299  00000-0  00000+0 0  9994
2 42695   0.0796 269.9829 0002320 211.3157 309.4584  1.00271408 11897
INMARSAT 5-F4           
1 42698U 17025A   20212.06198686  .00000062  00000-0  00000+0 0  9992
2 42698   0.0271  77.1247 0000490 120.6781 189.1484  1.00271960 11729
SES-15                  
1 42709U 17026A   20212.57924990  .00000044  00000-0  00000+0 0  9997
2 42709   0.0098  57.9914 0001394  93.2537 236.8087  1.00284991 12654
COSMOS 2518             
1 42719U 17027A   20211.65653373 -.00000266  00000-0  00000+0 0  9998
2 42719  63.1955 167.3055 6754843 265.4963 342.8962  2.00619713 23292
QZS-2 (MICHIBIKI-2)     
1 42738U 17028A   20210.48248839 -.00000163  00000-0  00000-0 0  9994
2 42738  42.9226 273.8549 0754799 269.5541  78.1501  1.00276681 11580
VIASAT-2                
1 42740U 17029A   20212.03677567 -.00000278  00000-0  00000+0 0  9991
2 42740   0.0176 303.5175 0000370 200.4528 107.4792  1.00266669 11635
EUTELSAT 172B           
1 42741U 17029B   20212.77384603 -.00000021  00000-0  00000+0 0  9995
2 42741   0.0278  85.6992 0000338  36.5251 277.2089  1.00269510 12172
GSAT-19                 
1 42747U 17031A   20212.77815418  .00000111  00000-0  00000+0 0  9996
2 42747   0.0589 267.8825 0007762 190.1299 178.8228  1.00272104 11598
ECHOSTAR 21             
1 42749U 17032A   20211.98103192  .00000059  00000-0  00000+0 0  9991
2 42749   5.4149 312.4547 0002169 167.4829 191.5433  1.00271813 11586
LEMUR-2-ANGELA          
1 42752U 17019B   20212.84628452  .00001361  00000-0  55741-4 0  9991
2 42752  51.6414 324.6350 0010227 104.1675 256.0443 15.31673811175746
LEMUR-2-JENNYBARNA      
1 42753U 17019C   20212.61303280  .00001234  00000-0  49357-4 0  9991
2 42753  51.6400 318.7328 0009652 108.0935 252.1098 15.33268123175755
LEMUR-2-ROBMOORE        
1 42754U 17019D   20212.52496297  .00001467  00000-0  59342-4 0  9996
2 42754  51.6414 326.9578 0010168 105.1086 255.1020 15.31565552175660
LEMUR-2-SPIROVISION     
1 42755U 17019E   20213.03578067  .00002594  00000-0  90724-4 0  9991
2 42755  51.6415 316.6095 0009543 114.4121  57.6749 15.33343245175736
HXMT (HUIYAN)           
1 42758U 17034A   20213.09073463  .00000022  00000-0  15098-4 0  9992
2 42758  43.0176 156.6018 0012131 300.0384 171.7146 15.08856536172466
ZHUHAI-1 02 (CAS-4B)    
1 42759U 17034B   20213.22529420  .00000185  00000-0  24723-4 0  9992
2 42759  43.0181 146.5540 0012326 304.9127 200.9666 15.09851324172581
NUSAT-3 (MILANESAT)     
1 42760U 17034C   20213.12052083  .00000110  00000-0  19924-4 0  9994
2 42760  43.0152 145.5591 0010942 321.7816 225.4676 15.10331035172598
ZHUHAI-1 01 (CAS-4A)    
1 42761U 17034D   20213.23411245  .00000092  00000-0  19041-4 0  9995
2 42761  43.0169 145.7769 0012690 306.5701 202.8137 15.09929200172587
CHINASAT 9A (ZX 9A)     
1 42763U 17035A   20212.77197497 -.00000327  00000-0  00000-0 0  9999
2 42763   0.0373 238.6448 0002070 237.5135 212.0404  1.00270947 12115
UCLSAT                  
1 42765U 17036A   20212.83280066  .00000850  00000-0  38381-4 0  9991
2 42765  97.2977 265.9038 0009903 227.0776 132.9631 15.23602844173182
NIUSAT                  
1 42766U 17036B   20212.86769620  .00000673  00000-0  32611-4 0  9996
2 42766  97.2972 263.2895 0014193 222.6490 137.3646 15.21782210172291
CARTOSAT-2E             
1 42767U 17036C   20213.20485665  .00000262  00000-0  15615-4 0  9999
2 42767  97.4203 271.1372 0007755 251.3988 108.6405 15.19273281172177
LITUANICASAT-2          
1 42768U 17036D   20212.66227256  .00001510  00000-0  64710-4 0  9996
2 42768  97.3035 265.7794 0013484 217.8273 142.2018 15.24203249172422
CE-SAT-I                
1 42769U 17036E   20212.94195401  .00000328  00000-0  17812-4 0  9991
2 42769  97.2949 262.4926 0013548 229.9012 130.1038 15.20997515172197
LEMUR-2-SHAINAJOHL      
1 42771U 17036G   20212.83457304  .00001020  00000-0  46111-4 0  9999
2 42771  97.2995 264.8460 0013070 219.6586 140.3696 15.23101458172337
LEMUR-2-XUENITERENCE    
1 42772U 17036H   20212.87585278  .00001024  00000-0  45992-4 0  9996
2 42772  97.2972 264.9156 0012610 217.9454 142.0896 15.23301704172359
LEMUR-2-LUCYBRYCE       
1 42773U 17036J   20212.83738899  .00000812  00000-0  37302-4 0  9992
2 42773  97.3029 265.4916 0012640 217.0154 143.0212 15.23085930172369
LEMUR-2-KUNGFOO         
1 42774U 17036K   20212.86104111  .00001041  00000-0  46779-4 0  9993
2 42774  97.2995 265.1144 0012519 217.9438 142.0919 15.23263634172340
AALTO-1                 
1 42775U 17036L   20212.84819270  .00000542  00000-0  26741-4 0  9993
2 42775  97.2951 263.6127 0012606 221.5839 138.4440 15.21893458172271
URSA MAIOR              
1 42776U 17036M   20212.83222141  .00000592  00000-0  28795-4 0  9994
2 42776  97.2963 263.9005 0012440 221.1637 138.8662 15.22081033172281
COMPASS 2               
1 42777U 17036N   20212.88613737  .00001180  00000-0  51489-4 0  9995
2 42777  97.3023 266.4002 0011103 217.2987 142.7480 15.24031088172418
MAX VALIER SAT          
1 42778U 17036P   20212.78127051  .00000643  00000-0  30533-4 0  9994
2 42778  97.3019 265.2128 0010928 223.6223 136.4150 15.22681394172321
LEMUR-2-LYNSEY-SYMO     
1 42779U 17036Q   20212.70332553  .00001180  00000-0  50401-4 0  9995
2 42779  97.3030 267.0427 0010279 221.2548 138.7914 15.24779924172434
LEMUR-2-LISASAURUS      
1 42780U 17036R   20213.15151496  .00000824  00000-0  37301-4 0  9990
2 42780  97.3018 266.4818 0010533 222.6118 137.4303 15.23592173172458
LEMUR-2-SAM-AMELIA      
1 42781U 17036S   20212.83907662  .00000849  00000-0  38027-4 0  9992
2 42781  97.3036 266.7234 0010558 223.2135 136.8275 15.23855838172425
LEMUR-2-MCPEAKE         
1 42782U 17036T   20212.88312367  .00001149  00000-0  50005-4 0  9994
2 42782  97.3013 266.7555 0010215 222.5200 137.5248 15.24176248172433
DIAMOND RED             
1 42783U 17036U   20212.65004318  .00000697  00000-0  32645-4 0  9991
2 42783  97.2977 264.9491 0010566 230.7490 129.2810 15.22902117172323
PEGASUS                 
1 42784U 17036V   20212.80128797  .00000796  00000-0  36442-4 0  9996
2 42784  97.2981 265.5452 0010464 228.8185 131.2150 15.23289542172375
DIAMOND GREEN           
1 42785U 17036W   20212.64881929  .00000670  00000-0  31473-4 0  9992
2 42785  97.2980 264.9848 0010447 231.3999 128.6303 15.22901738172311
DIAMOND BLUE            
1 42786U 17036X   20212.84515773  .00000693  00000-0  32469-4 0  9997
2 42786  97.2978 265.1707 0010311 230.8193 129.2129 15.22902404172422
NUDTSAT                 
1 42787U 17036Y   20213.14868462  .00001113  00000-0  47900-4 0  9992
2 42787  97.3026 267.3803 0009392 227.7872 132.2570 15.24634179172354
SUCHAI                  
1 42788U 17036Z   20212.87535766  .00000970  00000-0  43082-4 0  9997
2 42788  97.2990 266.2462 0010051 226.1381 133.9027 15.23853855172498
SKCUBE                  
1 42789U 17036AA  20212.79467932  .00000884  00000-0  39699-4 0  9991
2 42789  97.2984 266.0068 0009930 226.8080 133.2329 15.23689535172398
VZLUSAT-1               
1 42790U 17036AB  20212.87555917  .00001238  00000-0  52973-4 0  9997
2 42790  97.3010 267.1091 0009500 223.1474 136.9020 15.24620371172469
ROBUSTA-1B              
1 42792U 17036AD  20212.87440552  .00000950  00000-0  42221-4 0  9992
2 42792  97.2987 266.3494 0009512 226.5706 133.4741 15.23882438172431
CICERO 6                
1 42793U 17036AE  20213.14315119  .00000614  00000-0  28829-4 0  9999
2 42793  97.2962 265.9611 0008815 228.3760 131.6722 15.23203475172512
D-SAT                   
1 42794U 17036AF  20212.72011900  .00000091  00000-0  13401-4 0  9993
2 42794  97.4910 243.2301 0125113 220.0379 139.1568 14.91296709169141
TYVAK-53B               
1 42795U 17036AG  20212.82767575  .00001597  00000-0  67186-4 0  9992
2 42795  97.2815 264.5444 0007188  34.0218 326.1480 15.24794071172466
COSMOS 2519             
1 42798U 17037A   20212.84029889  .00017512  00000-0  19616-3 0  9995
2 42798  97.8248 329.5920 0183277 176.5065 183.7513 15.44850520171379
BULGARIASAT-1           
1 42801U 17038A   20212.99844561 -.00000003  00000-0  00000+0 0  9990
2 42801   0.0189  74.8910 0002437  54.2659 181.2598  1.00273156 11372
IRIDIUM 113             
1 42803U 17039A   20212.83513489  .00000043  00000-0  84426-5 0  9991
2 42803  86.4007 139.4648 0002101  92.9718 267.1718 14.34217778163409
IRIDIUM 123             
1 42804U 17039B   20212.94637369  .00000059  00000-0  13929-4 0  9994
2 42804  86.4011 170.9573 0002048  86.5909 273.5521 14.34219102162183
IRIDIUM 120             
1 42805U 17039C   20212.82879219  .00000045  00000-0  89592-5 0  9999
2 42805  86.3996 139.5281 0002141  94.8563 265.2877 14.34218737163393
IRIDIUM 115             
1 42806U 17039D   20212.80382725  .00000057  00000-0  11045-4 0  9999
2 42806  86.4535 139.3405 0000978 358.0158   2.1039 14.43277330164530
IRIDIUM 118             
1 42807U 17039E   20212.83855480  .00000058  00000-0  13503-4 0  9998
2 42807  86.4011 171.0091 0002089  79.0443 281.0988 14.34218745162169
IRIDIUM 117             
1 42808U 17039F   20212.85759030  .00000057  00000-0  13330-4 0  9992
2 42808  86.4010 170.9778 0002513  96.4790 263.6692 14.34217059162197
IRIDIUM 126             
1 42809U 17039G   20212.81318768  .00000062  00000-0  15145-4 0  9990
2 42809  86.4012 171.0139 0002194  87.7669 272.3778 14.34218251162171
IRIDIUM 124             
1 42810U 17039H   20212.80689512  .00000069  00000-0  14633-4 0  9997
2 42810  86.4494 107.8144 0002689  88.6024 271.5485 14.43278158165388
IRIDIUM 128             
1 42811U 17039J   20212.73414401  .00000085  00000-0  23420-4 0  9995
2 42811  86.3987 202.5554 0001836 108.3536 251.7859 14.34219224162183
IRIDIUM 121             
1 42812U 17039K   20212.83222217  .00000151  00000-0  46927-4 0  9990
2 42812  86.4011 171.0219 0002117  88.2422 271.9016 14.34218929162049
HELLAS-SAT 3            
1 42814U 17040A   20212.07827080  .00000139  00000-0  00000+0 0  9992
2 42814   0.0653  28.1783 0003701  50.8954 296.2646  1.00268474 11320
GSAT-17                 
1 42815U 17040B   20212.62495818 -.00000272  00000-0  00000+0 0  9993
2 42815   0.0719 270.8383 0001598   4.1700 352.2254  1.00270781 11332
INTELSAT 35E (IS-35E)   
1 42818U 17041A   20212.04384178 -.00000251  00000-0  00000+0 0  9996
2 42818   0.0501 236.8413 0001983 242.1282 170.4515  1.00271178 11293
KANOPUS-V-IK            
1 42825U 17042A   20213.07831249  .00000148  00000-0  10224-4 0  9993
2 42825  97.4410 120.6616 0002018  83.6132  30.3772 15.19166370168932
NORSAT 1                
1 42826U 17042B   20212.88721310  .00000240  00000-0  29435-4 0  9996
2 42826  97.5160  92.4924 0015853 132.9044 227.3508 14.91129004165760
2017-042C               
1 42827U 17042C   20212.92458938  .00000090  00000-0  14341-4 0  9998
2 42827  97.5172  92.9372 0016170 132.7187 227.5397 14.91345033165743
NORSAT 2                
1 42828U 17042D   20213.12256496  .00000085  00000-0  13796-4 0  9994
2 42828  97.5208  93.7179 0015389 133.1371 227.1138 14.91540821165818
TECHNOSAT               
1 42829U 17042E   20213.10317910  .00000044  00000-0  97644-5 0  9992
2 42829  97.5143  92.8203 0015243 134.8202 225.4259 14.91115209165795
2017-042F               
1 42830U 17042F   20213.08194376  .00000611  00000-0  64961-4 0  9995
2 42830  97.5226  94.3588 0015243 128.7504 231.5080 14.92277413165864
FLYING LAPTOP           
1 42831U 17042G   20212.87970764  .00000186  00000-0  24049-4 0  9995
2 42831  97.5158  92.7518 0015080 135.6322 224.6108 14.91141442165762
2017-042H               
1 42832U 17042H   20212.90565312  .00000044  00000-0  96514-5 0  9999
2 42832  97.5157  93.0253 0014905 133.0627 227.1843 14.91312586165782
2017-042J               
1 42833U 17042J   20212.93805502  .00000149  00000-0  20216-4 0  9992
2 42833  97.5156  93.1327 0014869 133.2175 227.0288 14.91378067165805
2017-042K               
1 42834U 17042K   20213.08901137  .00000224  00000-0  27712-4 0  9993
2 42834  97.5162  93.2963 0014450 136.0563 224.1808 14.91345225165815
WNISAT 1R               
1 42835U 17042L   20212.65088007 -.00000068  00000-0 -15344-5 0  9995
2 42835  97.5143  92.6420 0014346 134.4559 225.7836 14.91240796165744
2017-042M               
1 42836U 17042M   20212.93892891  .00000134  00000-0  18679-4 0  9996
2 42836  97.5157  93.1026 0014222 136.3027 223.9320 14.91324085165801
LEMUR-2-GREENBERG       
1 42837U 17042N   20213.13061603 -.00000024  00000-0  28235-5 0  9998
2 42837  97.5195  94.4144 0014218 128.9387 231.3102 14.92190557165896
LEMUR-2-ANDIS           
1 42838U 17042P   20213.12491499  .00000043  00000-0  94215-5 0  9999
2 42838  97.5191  94.4155 0014319 128.7269 231.5233 14.92160030165894
LEMUR-2-MONSON          
1 42839U 17042Q   20212.78937513  .00000030  00000-0  81748-5 0  9996
2 42839  97.5200  93.9848 0014004 129.7673 230.4783 14.91980079165834
LEMUR-2-FURIAUS         
1 42840U 17042R   20212.89399599  .00000307  00000-0  35368-4 0  9999
2 42840  97.5203  94.2780 0014171 129.3703 230.8775 14.92105858165855
LEMUR-2-PETERG          
1 42841U 17042S   20212.67314616  .00000282  00000-0  32678-4 0  9996
2 42841  97.5221  94.4975 0013719 126.7480 233.5001 14.92408609165856
LEMUR-2-DEMBITZ         
1 42842U 17042T   20212.79829826  .00000075  00000-0  12505-4 0  9991
2 42842  97.5211  94.5877 0013827 127.2644 232.9839 14.92399091165874
2017-042U               
1 42843U 17042U   20213.08981920  .00000188  00000-0  23923-4 0  9990
2 42843  97.5158  94.0031 0013857 128.3624 231.8842 14.91707800165877
NANOACE                 
1 42844U 17042V   20213.13630596  .00000163  00000-0  21098-4 0  9991
2 42844  97.5167  94.5594 0013687 121.7004 238.5552 14.92327398165915
LEMUR-2-ZACHARY         
1 42845U 17042W   20212.76307331  .00000394  00000-0  43368-4 0  9998
2 42845  97.5140  94.1543 0014037 130.5549 229.6896 14.92670987165882
CORVUS BC2              
1 42846U 17042X   20212.89613972  .00000211  00000-0  25963-4 0  9993
2 42846  97.5174  94.4837 0010767 127.2049 233.0155 14.92120528165881
CORVUS BC1              
1 42847U 17042Y   20212.82183062  .00000276  00000-0  32255-4 0  9992
2 42847  97.5177  94.5903 0010432 126.4895 233.7287 14.92221858165879
2017-042Z               
1 42848U 17042Z   20212.95182607  .00000293  00000-0  33727-4 0  9995
2 42848  97.5188  95.2412 0009354 123.6599 236.5514 14.92505943165937
2017-042AA              
1 42849U 17042AA  20212.94333472  .00000176  00000-0  22351-4 0  9994
2 42849  97.5189  95.2562 0009327 125.4728 234.7363 14.92517283165896
FLOCK 2K-03             
1 42850U 17042AB  20213.12435110  .00002665  00000-0  65141-4 0  9998
2 42850  96.8885  78.4723 0009475 162.8526 197.3044 15.41594012170830
FLOCK 2K-04             
1 42851U 17042AC  20212.90309047  .00002591  00000-0  62703-4 0  9992
2 42851  96.8875  78.7208 0009303 164.8588 195.2941 15.41928580170835
FLOCK 2K-47             
1 42854U 17042AF  20212.86297837  .00002672  00000-0  65346-4 0  9999
2 42854  96.8884  78.2309 0009109 162.0900 198.0671 15.41575103170792
FLOCK 2K-45             
1 42856U 17042AH  20213.05489982  .00002098  00000-0  51700-4 0  9998
2 42856  96.8881  78.3942 0009128 160.6755 199.4841 15.41572147170821
FLOCK 2K-23             
1 42859U 17042AL  20212.83614241  .00003938  00000-0  94347-4 0  9992
2 42859  96.8887  78.3385 0009173 162.2572 197.8998 15.41926877170790
FLOCK 2K-21             
1 42860U 17042AM  20212.85752810  .00003284  00000-0  79900-4 0  9993
2 42860  96.8893  78.3026 0009221 162.2755 197.8817 15.41580140170797
FLOCK 2K-07             
1 42862U 17042AP  20212.85489179  .00003469  00000-0  84206-4 0  9990
2 42862  96.8897  78.3495 0009448 162.4667 197.6909 15.41609096170802
FLOCK 2K-40             
1 42865U 17042AS  20212.85389629  .00003206  00000-0  78128-4 0  9997
2 42865  96.8895  78.3481 0009445 163.6021 196.5535 15.41538740170790
FLOCK 2K-39             
1 42866U 17042AT  20212.65502181  .00003409  00000-0  82949-4 0  9997
2 42866  96.8893  78.1368 0009332 163.9399 196.2147 15.41549890170768
FLOCK 2K-37             
1 42867U 17042AU  20212.78244943  .00003599  00000-0  87456-4 0  9999
2 42867  96.8888  78.2509 0009447 163.9216 196.2334 15.41549126170788
FLOCK 2K-31             
1 42869U 17042AW  20212.84097213  .00002301  00000-0  56586-4 0  9994
2 42869  96.8885  78.2832 0009582 159.0320 201.1323 15.41547719170799
FLOCK 2K-32             
1 42870U 17042AX  20213.09817288  .00003333  00000-0  81118-4 0  9991
2 42870  96.8889  78.5244 0009444 159.2835 200.8798 15.41556114170831
FLOCK 2K-29             
1 42871U 17042AY  20213.09505309  .00003290  00000-0  80070-4 0  9998
2 42871  96.8890  78.5621 0009745 158.3652 201.8010 15.41557530170835
FLOCK 2K-30             
1 42872U 17042AZ  20212.89774522  .00002080  00000-0  51317-4 0  9999
2 42872  96.8896  78.4176 0009705 160.3265 199.8359 15.41546769170794
FLOCK 2K-44             
1 42873U 17042BA  20212.89438534  .00002177  00000-0  53582-4 0  9993
2 42873  96.8884  78.3438 0010746 150.8462 209.3388 15.41558720170803
FLOCK 2K-43             
1 42874U 17042BB  20212.87566394  .00001023  00000-0  30178-4 0  9993
2 42874  96.8889  74.0988 0011869 172.0112 188.1325 15.37039536170503
FLOCK 2K-41             
1 42875U 17042BC  20212.87819338  .00003669  00000-0  88843-4 0  9993
2 42875  96.8895  78.4433 0011057 153.1471 207.0352 15.41626080170806
FLOCK 2K-35             
1 42877U 17042BE  20212.89210432  .00001944  00000-0  48034-4 0  9999
2 42877  96.8894  78.4321 0010995 152.7669 207.4158 15.41564815170802
LEMUR-2-ARTFISCHER      
1 42881U 17042BJ  20212.90971498  .00003439  00000-0  84244-4 0  9996
2 42881  96.8864  77.7339 0011457 159.2898 200.8816 15.41317002170740
FLOCK 2K-25             
1 42884U 17042BM  20212.88916231  .00002018  00000-0  49770-4 0  9997
2 42884  96.8888  78.3903 0011007 153.2616 206.9202 15.41582157170804
FLOCK 2K-19             
1 42886U 17042BP  20212.88635625  .00003532  00000-0  85635-4 0  9996
2 42886  96.8893  78.4428 0011417 153.3034 206.8804 15.41610513170800
FLOCK 2K-18             
1 42887U 17042BQ  20212.87674060  .00001966  00000-0  48569-4 0  9996
2 42887  96.8893  78.4503 0011573 152.8219 207.3637 15.41550358170807
FLOCK 2K-16             
1 42889U 17042BS  20212.88420092  .00003716  00000-0  90014-4 0  9990
2 42889  96.8901  78.4798 0011207 151.1753 209.0116 15.41607300170805
FLOCK 2K-15             
1 42890U 17042BT  20212.80851335  .00002623  00000-0  64086-4 0  9998
2 42890  96.8900  78.5026 0011437 151.8044 208.3826 15.41604624170790
FLOCK 2K-12             
1 42893U 17042BW  20212.86866988  .00002044  00000-0  50398-4 0  9995
2 42893  96.8889  78.4937 0011420 152.6175 207.5678 15.41566980170805
FLOCK 2K-11             
1 42894U 17042BX  20212.88067990  .00004354  00000-0  10498-3 0  9996
2 42894  96.8892  78.4409 0011457 149.0043 211.1884 15.41655751170803
FLOCK 2K-10             
1 42895U 17042BY  20213.13072094  .00001944  00000-0  48033-4 0  9996
2 42895  96.8883  78.6507 0011560 149.7768 210.4149 15.41562847170844
FLOCK 2K-09             
1 42896U 17042BZ  20212.85332237  .00002836  00000-0  66658-4 0  9997
2 42896  96.8870  79.3537 0011174 146.3727 213.8233 15.42710754171048
FLOCK 2K-06             
1 42897U 17042CA  20212.90891290  .00003399  00000-0  82684-4 0  9995
2 42897  96.8891  78.3721 0009604 161.7925 198.3669 15.41554770170808
VENUS                   
1 42901U 17044B   20213.15210516  .00000034  00000-0  19699-4 0  9996
2 42901  98.2395 286.9373 0000750  89.3500 270.7789 14.50817325158638
COSMOS 2520             
1 42907U 17046A   20212.05971873  .00000124  00000-0  00000+0 0  9993
2 42907   0.0250 100.4433 0000392 110.5476 163.6529  1.00270682 10856
TDRS 13                 
1 42915U 17047A   20213.06724111 -.00000107  00000-0  00000+0 0  9998
2 42915   5.5081 330.2326 0016856 106.6859 244.9622  1.00271271 10856
QZS-3 (MICHIBIKI-3)     
1 42917U 17048A   20212.85465844 -.00000362  00000-0  00000+0 0  9995
2 42917   0.0698 234.6964 0000998 247.9431 260.9629  1.00275103 10844
FORMOSAT-5              
1 42920U 17049A   20212.82128273 -.00000048  00000-0 -88240-6 0  9990
2 42920  98.2991 291.1892 0010377 191.8527 168.2424 14.50847758155300
ORS-5 SENSORSAT         
1 42921U 17050A   20213.07253142  .00000855  00000-0  89003-5 0  9996
2 42921   0.0477 150.8416 0012500  31.0097 178.1440 14.91327920159830
AMAZONAS 5              
1 42934U 17053A   20212.45201932 -.00000296  00000-0  00000+0 0  9998
2 42934   0.0342 320.2692 0001777 164.6586 285.3216  1.00274728 10598
COSMOS 2522 [GLONASS-M] 
1 42939U 17055A   20212.68188646  .00000046  00000-0  00000-0 0  9999
2 42939  64.3307 265.5607 0009252 242.9017  31.0273  2.13102005 22213
ASIASAT 9               
1 42942U 17057A   20211.93156917 -.00000373  00000-0  00000-0 0  9992
2 42942   0.0085 105.9135 0002405   7.3099 292.2441  1.00269443 10407
YAOGAN 30 A             
1 42945U 17058A   20213.06898571  .00000109  00000-0  13920-4 0  9996
2 42945  35.0031 342.5894 0004654 321.3246  38.7114 14.89776732154562
YAOGAN 30 B             
1 42946U 17058B   20212.75541174  .00000138  00000-0  16925-4 0  9996
2 42946  35.0040 344.7581 0001184 133.3581 226.7222 14.89771024154511
YAOGAN 30 C             
1 42947U 17058C   20212.71083169  .00000116  00000-0  14703-4 0  9996
2 42947  35.0036 345.3255 0007482 352.1056   7.9540 14.89773509154496
INTELSAT 37E (IS-37E)   
1 42950U 17059A   20212.04715675 -.00000154  00000-0  00000-0 0  9995
2 42950   0.0180  91.6247 0003011  44.9912 170.4841  1.00271388 10505
BSAT-4A                 
1 42951U 17059B   20212.68071168 -.00000364  00000-0  00000+0 0  9990
2 42951   0.0380  13.2206 0003262  84.8751 205.5495  1.00271138 10413
VRSS-2                  
1 42954U 17060A   20213.15715543  .00000045  00000-0  13853-4 0  9990
2 42954  97.9267 287.9068 0018013 219.0051 140.9871 14.76088926151331
IRIDIUM 133             
1 42955U 17061A   20213.04493199  .00000092  00000-0  25773-4 0  9996
2 42955  86.3989 202.4928 0001949  96.5348 263.6070 14.34220666147027
IRIDIUM 100             
1 42956U 17061B   20213.03859773  .00000090  00000-0  25102-4 0  9994
2 42956  86.3987 202.4655 0002300  89.8368 270.3092 14.34218678147043
IRIDIUM 122             
1 42957U 17061C   20212.72780956  .00000092  00000-0  25885-4 0  9996
2 42957  86.3986 202.5910 0001916 104.1316 256.0093 14.34218239146981
IRIDIUM 129             
1 42958U 17061D   20212.68341882  .00000086  00000-0  23541-4 0  9993
2 42958  86.3988 202.6531 0001946  96.8265 263.3152 14.34217903146992
IRIDIUM 119             
1 42959U 17061E   20212.72146849  .00000090  00000-0  25055-4 0  9992
2 42959  86.3988 202.6223 0001920  93.7351 266.4064 14.34217355147016
IRIDIUM 107             
1 42960U 17061F   20212.67072829  .00000092  00000-0  25647-4 0  9993
2 42960  86.3988 202.6513 0002265  92.5172 267.6283 14.34218871147022
IRIDIUM 132             
1 42961U 17061G   20213.02592050  .00000090  00000-0  24934-4 0  9993
2 42961  86.3988 202.4946 0002406  96.5588 263.5881 14.34217252147068
IRIDIUM 136             
1 42962U 17061H   20212.70878523  .00000084  00000-0  23003-4 0  9999
2 42962  86.3989 202.6267 0001884  89.9726 270.1686 14.34217056147033
IRIDIUM 139             
1 42963U 17061J   20212.71511889  .00000094  00000-0  26676-4 0  9990
2 42963  86.3990 202.6515 0002179  94.3703 265.7742 14.34219296147047
IRIDIUM 125             
1 42964U 17061K   20212.70244084  .00000096  00000-0  27068-4 0  9998
2 42964  86.3992 202.6789 0002260  93.3322 266.8133 14.34217743147052
QZS-4 (MICHIBIKI-4)     
1 42965U 17062A   20212.65043080 -.00000351  00000-0  00000+0 0  9994
2 42965  40.8270  11.0279 0746168 268.7705  40.0521  1.00294972 10283
SES-11 (ECHOSTAR 105)   
1 42967U 17063A   20213.18798464 -.00000106  00000-0  00000+0 0  9997
2 42967   0.0261  90.6571 0002369  26.7443 154.5987  1.00279596 10271
SENTINEL-5P             
1 42969U 17064A   20212.81136886 -.00000034  00000-0  45563-5 0  9992
2 42969  98.7365 151.3617 0001097  86.4089 273.7213 14.19556089144907
KESTREL EYE IIM (KE2M)  
1 42982U 98067NE  20212.90030168  .00012585  00000-0  10286-3 0  9990
2 42982  51.6347  59.3781 0002650 251.0906 108.9804 15.70991038157907
KOREASAT 5A             
1 42984U 17067A   20212.93778322 -.00000373  00000-0  00000+0 0  9999
2 42984   0.0219 104.1510 0002146  24.7685 270.8241  1.00270164 10105
COSMOS 2523             
1 42986U 17037E   20212.82354605  .00000270  00000-0  34909-4 0  9995
2 42986  97.7553 274.4086 0079450 156.9711 203.5087 14.86707352149263
SKYSAT-C11              
1 42987U 17068A   20212.85208576  .00000991  00000-0  28383-4 0  9997
2 42987  97.2347 326.4686 0008326 251.8288 108.2051 15.38283330152755
SKYSAT-C10              
1 42988U 17068B   20212.84150858  .00000983  00000-0  28188-4 0  9990
2 42988  97.2389 326.2579 0002139 313.4623  46.6444 15.38253561152771
SKYSAT-C9               
1 42989U 17068C   20212.82959370  .00001000  00000-0  28590-4 0  9993
2 42989  97.2349 325.8388 0002806 110.8835 249.2711 15.38317136152964
SKYSAT-C8               
1 42990U 17068D   20212.81937361  .00000996  00000-0  28555-4 0  9994
2 42990  97.2386 326.6218 0000679 254.2796 105.8374 15.38239780152794
SKYSAT-C7               
1 42991U 17068E   20212.80971418  .00001010  00000-0  28910-4 0  9998
2 42991  97.2392 326.6086 0001206  98.4668 261.6714 15.38256777152792
SKYSAT-C6               
1 42992U 17068F   20212.79945400  .00001020  00000-0  29182-4 0  9992
2 42992  97.2407 326.8155 0002737 135.0254 225.1213 15.38257467152806
FLOCK 3M-4              
1 42997U 17068L   20213.11976641  .00001437  00000-0  67027-4 0  9997
2 42997  97.4222 321.6468 0014277 323.5179  36.5083 15.21412735152374
FLOCK 3M-2              
1 42998U 17068M   20213.01726882  .00001295  00000-0  60706-4 0  9996
2 42998  97.4089 320.1660 0012792 319.9713  40.0579 15.21421902152362
BEIDOU-3 M1             
1 43001U 17069A   20212.65223750 -.00000053  00000-0  00000-0 0  9997
2 43001  55.2310 134.7478 0011093 292.2456 286.7863  1.86231958 18602
BEIDOU-3 M2             
1 43002U 17069B   20212.58441343 -.00000052  00000-0  00000+0 0  9998
2 43002  55.2310 134.7850 0010142 312.1552 265.2534  1.86231518 18597
MOHAMMED VI-A           
1 43005U 17070A   20213.13028759  .00000041  00000-0  12940-4 0  9995
2 43005  97.9588 284.2864 0001423  81.8641 278.2740 14.77083061147034
FENGYUN 3D              
1 43010U 17072A   20213.06214792  .00000003  00000-0  21971-4 0  9991
2 43010  98.7532 151.9005 0001167   3.8853 356.2329 14.19746163140385
HEAD-1                  
1 43011U 17072B   20213.14334042 -.00000222  00000-0 -73561-4 0  9999
2 43011  98.7621 163.2677 0009111 262.7410  97.2734 14.26567247141079
NOAA 20                 
1 43013U 17073A   20212.84428036 -.00000018  00000-0  12359-4 0  9995
2 43013  98.7187 150.1222 0000929 103.1704 256.9576 14.19558411139813
BUCCANEER RMM           
1 43014U 17073B   20212.75368829  .00000655  00000-0  58307-4 0  9998
2 43014  97.7136 122.1676 0247501 270.7410  86.5452 14.79302508145540
MIRATA                  
1 43015U 17073C   20212.75010338  .00000449  00000-0  40827-4 0  9997
2 43015  97.7164 123.3208 0251475 267.3899  89.8507 14.79727041145623
MAKERSAT 0              
1 43016U 17073D   20212.72440813  .00000299  00000-0  29246-4 0  9995
2 43016  97.7198 123.4678 0254315 268.6260  88.5809 14.79272167145596
RADFXSAT (FOX-1B)       
1 43017U 17073E   20212.75655542  .00000220  00000-0  23253-4 0  9992
2 43017  97.7202 123.3216 0255312 269.2999  87.8955 14.78981122145562
DELLINGR (RBLE)         
1 43021U 98067NJ  20213.12730337  .00012309  00000-0  97714-4 0  9991
2 43021  51.6364  56.4673 0001221 199.0520 161.0432 15.71719314153735
JILIN-01-04             
1 43022U 17074A   20213.17530443 -.00000092  00000-0 -17809-5 0  9998
2 43022  97.5276 306.1720 0009630 121.1862 328.4188 15.09946715148304
JILIN-01-05             
1 43023U 17074B   20213.19134362  .00000594  00000-0  40480-4 0  9996
2 43023  97.5292 306.3114 0009678 130.4996 287.5062 15.09954784148306
JILIN-01-06             
1 43024U 17074C   20213.15313110  .00000113  00000-0  10846-4 0  9993
2 43024  97.5281 306.2141 0012399 133.7733 319.8161 15.09940820148296
YAOGAN 30 D             
1 43028U 17075A   20213.10474756  .00000048  00000-0  76896-5 0  9992
2 43028  34.9994 223.5162 0001182 107.3764 252.7075 14.89767721146122
YAOGAN 30 E             
1 43029U 17075B   20213.21684001  .00000016  00000-0  43956-5 0  9997
2 43029  34.9966 222.4891 0004753  39.0548 321.0493 14.89759622146133
YAOGAN 30 F             
1 43030U 17075C   20213.12713724  .00000069  00000-0  97619-5 0  9997
2 43030  34.9987 222.7909 0003942 245.8611 114.1699 14.89770587146107
COSMOS 2524             
1 43032U 17076A   20213.12140198  .00000961  00000-0  74050-3 0  9992
2 43032  67.1464  15.9868 0008993 278.9593  81.0467 13.97114651135723
LKW-1                   
1 43034U 17077A   20212.82648242  .00000476  00000-0  22987-4 0  9998
2 43034  97.3727 288.7094 0010904  98.1424  10.8185 15.23315090147779
ALCOMSAT 1              
1 43039U 17078A   20212.76367302 -.00000200  00000-0  00000+0 0  9995
2 43039   0.0461 212.7326 0001861 266.7736  79.4588  1.00270986  9742
LEMUR-2-ROCKETJONAH     
1 43041U 17071E   20212.81284738  .00005514  00000-0  11125-3 0  9994
2 43041  51.6344 198.3395 0001080 102.8511 257.2597 15.48172592151572
AEROCUBE 7B (OCSD B)    
1 43042U 17071F   20212.73929155  .00002692  00000-0  67493-4 0  9999
2 43042  51.6361 214.1192 0004028 199.2640 160.8194 15.43854140149066
AEROCUBE 7C (OCSD C)    
1 43043U 17071G   20212.74338889  .00002703  00000-0  67799-4 0  9991
2 43043  51.6362 214.1617 0004195 204.1723 155.9067 15.43828176149034
CHEFSAT                 
1 43044U 17071H   20212.85192875  .00005711  00000-0  11215-3 0  9990
2 43044  51.6362 197.1798 0003548 212.9434 147.1334 15.48893284149275
LEMUR-2-YONGLIN         
1 43045U 17071J   20213.15990119  .00004943  00000-0  98009-4 0  9995
2 43045  51.6356 192.5085 0003124 202.6207 157.4644 15.48945495149406
LEMUR-2-KEVIN           
1 43046U 17071K   20212.82766132  .00004491  00000-0  95106-4 0  9998
2 43046  51.6363 200.7695 0003099 195.3194 164.7700 15.47246276149277
LEMUR-2-BRIANDAVIE      
1 43047U 17071L   20213.04145614  .00005136  00000-0  10591-3 0  9991
2 43047  51.6348 199.8183 0001965 242.2145 117.8643 15.47694338149292
LEMUR-2-ROMACOSTE       
1 43048U 17071M   20212.54219561  .00004464  00000-0  91318-4 0  9999
2 43048  51.6353 199.5880 0001977 241.5636 118.5153 15.48284629149259
ASGARDIA 1              
1 43049U 17071N   20212.41221687  .00002513  00000-0  63598-4 0  9990
2 43049  51.6373 215.0291 0001864 215.8080 144.2782 15.43852017148984
ISARA                   
1 43050U 17071P   20212.79883323  .00004910  00000-0  10124-3 0  9993
2 43050  51.6343 200.4423 0001568 223.8632 136.2232 15.47803764149229
LEMUR-2-MCCULLAGH       
1 43051U 17071Q   20212.66813606  .00003649  00000-0  76307-4 0  9991
2 43051  51.6376 198.9710 0001103  33.4203 326.6854 15.48225180149226
PROPCUBE 2 (FAUNA)      
1 43052U 17071R   20213.16205981  .00002648  00000-0  65513-4 0  9994
2 43052  51.6368 209.5532 0001816 215.9716 144.1148 15.44328026149123
LEMUR-2-DUNLOP          
1 43053U 17071S   20212.82721688  .00004605  00000-0  97759-4 0  9996
2 43053  51.6345 202.8476 0001099  38.6704 321.4362 15.47099656149194
LEMUR-2-LIU-POU-CHUN    
1 43054U 17071T   20212.84111045  .00004816  00000-0  99846-4 0  9992
2 43054  51.6354 199.2897 0000753 111.9022 248.2046 15.47688500149248
GSAT0215 (GALILEO 19)   
1 43055U 17079A   20211.94579877 -.00000010  00000-0  00000-0 0  9995
2 43055  56.2345 280.6327 0003393 247.3092 112.7270  1.70474322 16363
GSAT0216 (GALILEO 20)   
1 43056U 17079B   20212.23852919 -.00000010  00000-0  00000+0 0  9999
2 43056  56.2353 280.6244 0005140 257.1544 102.8615  1.70474441 16358
GSAT0217 (GALILEO 21)   
1 43057U 17079C   20210.69882606 -.00000014  00000-0  00000-0 0  9999
2 43057  56.2339 280.6672 0004403 265.7894  94.2292  1.70474439 16343
GSAT0218 (GALILEO 22)   
1 43058U 17079D   20211.50540822 -.00000012  00000-0  00000+0 0  9999
2 43058  56.2335 280.6424 0005619 254.9897 105.0187  1.70474390 16359
GCOM-C (SHIKISAI)       
1 43065U 17082A   20212.81989569 -.00000015  00000-0  11553-4 0  9994
2 43065  98.5879 284.8513 0001250 108.9855 251.1461 14.27305439135626
IRIDIUM 135             
1 43070U 17083A   20212.88589239  .00000049  00000-0  10366-4 0  9995
2 43070  86.4001 139.4222 0002631  79.2112 280.9380 14.34217766136373
IRIDIUM 138             
1 43071U 17083B   20212.84147620  .00000050  00000-0  10786-4 0  9994
2 43071  86.3992 139.3350 0002074  87.6981 272.4452 14.34218195136352
IRIDIUM 116             
1 43072U 17083C   20212.80976300  .00000051  00000-0  11062-4 0  9995
2 43072  86.4001 139.4652 0002210  75.3511 284.7930 14.34216271136389
IRIDIUM 130             
1 43073U 17083D   20212.84781494  .00000045  00000-0  88827-5 0  9995
2 43073  86.4010 139.5137 0002087  83.4228 276.7205 14.34217721136338
IRIDIUM 151             
1 43074U 17083E   20212.82244476  .00000047  00000-0  95439-5 0  9997
2 43074  86.4003 139.4682 0002132  73.6837 286.4593 14.34217998136359
IRIDIUM 134             
1 43075U 17083F   20212.86051777  .00000048  00000-0  10142-4 0  9990
2 43075  86.4004 139.4299 0002463  90.1319 270.0159 14.34218280136320
IRIDIUM 137             
1 43076U 17083G   20212.80342038  .00000050  00000-0  10810-4 0  9993
2 43076  86.4005 139.5076 0002106  73.9330 286.2098 14.34218835136385
IRIDIUM 141             
1 43077U 17083H   20212.79707961  .00000048  00000-0  10077-4 0  9994
2 43077  86.4000 139.4588 0001961  81.9931 278.1487 14.34216996136380
IRIDIUM 153             
1 43078U 17083J   20212.74925646  .00000059  00000-0  14179-4 0  9991
2 43078  86.3970 107.8989 0001355 100.8003 259.3345 14.34217878137484
IRIDIUM 131             
1 43079U 17083K   20212.85416125  .00000058  00000-0  13756-4 0  9990
2 43079  86.4006 139.4772 0002105  88.7383 271.4054 14.34218544136321
LKW-2                   
1 43080U 17084A   20213.18384943  .00000459  00000-0  22246-4 0  9991
2 43080  97.3777 290.1894 0008336 159.8871 281.4202 15.23346095144780
YAOGAN 30 G             
1 43081U 17085A   20213.09828708  .00000150  00000-0  18136-4 0  9996
2 43081  35.0012 102.7033 0005567 310.6989  49.3229 14.89761417141490
YAOGAN 30 H             
1 43082U 17085B   20213.05285105  .00000120  00000-0  15065-4 0  9996
2 43082  34.9996 103.2082 0004786 174.5474 185.5283 14.89761263141478
YAOGAN 30 J             
1 43083U 17085C   20213.07570898  .00000163  00000-0  19418-4 0  9998
2 43083  35.0000 103.4193 0006064 293.9985  66.0085 14.89766241141481
SUPERVIEW-1 03          
1 43099U 18002A   20213.19430411  .00000408  00000-0  25490-4 0  9993
2 43099  97.5030 291.7546 0005448 347.3490  64.7006 15.14869749141357
SUPERVIEW-1 04          
1 43100U 18002B   20213.21811774  .00000341  00000-0  21867-4 0  9994
2 43100  97.5037 291.9221 0011488 328.1849  54.5460 15.14866862141360
BEIDOU-3 M3             
1 43107U 18003A   20212.57333948 -.00000091  00000-0  00000-0 0  9997
2 43107  55.3706  16.1204 0004692  50.0618 325.7950  1.86232764 17365
BEIDOU-3 M4             
1 43108U 18003B   20212.50507865 -.00000091  00000-0  00000-0 0  9992
2 43108  55.3618  16.1136 0002609 223.2218 152.0103  1.86232756 17360
CARTOSAT-2F             
1 43111U 18004A   20213.18546039  .00000243  00000-0  14681-4 0  9990
2 43111  97.3824 271.8885 0002979 259.5872 164.1004 15.19291074141358
LEO VANTAGE 1           
1 43113U 18004C   20212.76022531 -.00000042  00000-0  15704-5 0  9997
2 43113  99.3717 267.9607 0004723 321.3625  38.7183 13.69842336128090
ICEYE-X1                
1 43114U 18004D   20213.12938476  .00000690  00000-0  31937-4 0  9996
2 43114  97.4427 282.9236 0008416 244.1124 115.9246 15.23443768141644
CARBONITE 2             
1 43115U 18004E   20213.14797071  .00000299  00000-0  15859-4 0  9993
2 43115  97.4413 282.3759 0008555 248.0727 111.9600 15.22659925141621
INS-1C                  
1 43116U 18004F   20213.09319639  .00000793  00000-0  35758-4 0  9997
2 43116  97.4503 284.1949 0007825 248.5376 111.5027 15.23947512141685
CORVUS BC3              
1 43118U 18004H   20212.71326461  .00000505  00000-0  24194-4 0  9991
2 43118  97.4418 282.6295 0008649 258.8646 101.1619 15.23418125141582
FLOCK 3P'-3             
1 43119U 18004J   20212.84697587  .00000551  00000-0  26097-4 0  9991
2 43119  97.4488 283.2350 0008461 258.1070 101.9219 15.23442644141605
FLOCK 3P'-2             
1 43120U 18004K   20212.83406260  .00000486  00000-0  23527-4 0  9990
2 43120  97.4492 283.1208 0008427 258.6419 101.3871 15.23250243141595
FLOCK 3P'-4             
1 43122U 18004M   20212.70135823  .00000456  00000-0  22259-4 0  9990
2 43122  97.4501 283.0489 0008251 261.3720  98.6582 15.23250922141578
LEMUR-2-MCCAFFERTY      
1 43123U 18004N   20212.84551216  .00001146  00000-0  48132-4 0  9999
2 43123  97.4474 284.7645 0007849 253.9944 106.0429 15.25476683141713
LEMUR-2-PETERWEBSTER    
1 43124U 18004P   20212.83974178  .00001202  00000-0  50492-4 0  9990
2 43124  97.4476 284.6409 0007887 252.6254 107.4122 15.25397875141704
LEMUR-2-BROWNCOW        
1 43125U 18004Q   20212.76225748  .00001515  00000-0  62032-4 0  9995
2 43125  97.4487 284.9087 0007690 253.1948 106.8446 15.25844871141716
LEMUR-2-DAVEWILSON      
1 43126U 18004R   20212.74924100  .00001371  00000-0  56647-4 0  9997
2 43126  97.4487 284.7840 0007684 253.0185 107.0211 15.25719143141702
DEMOSAT 2               
1 43127U 18004S   20212.76110877  .00000583  00000-0  27273-4 0  9995
2 43127  97.4430 283.0955 0008011 260.3580  99.6752 15.23680698141611
MICROSAT-TD             
1 43128U 18004T   20213.09602773  .00032714  00000-0  14421-3 0  9995
2 43128  96.7468 264.8671 0004561  10.4879 349.6487 15.84448943146292
ARKYD 6A                
1 43130U 18004V   20212.74215183  .00000733  00000-0  33219-4 0  9997
2 43130  97.4444 283.3867 0008005 260.0989  99.9345 15.23979930141620
MICROMAS-2A             
1 43131U 18004W   20212.74763019  .00001286  00000-0  53779-4 0  9991
2 43131  97.4469 284.5512 0007460 255.6996 104.3414 15.25403554141691
PICSAT                  
1 43132U 18004X   20212.76749000  .00000925  00000-0  40535-4 0  9993
2 43132  97.4453 283.8408 0007851 259.2451 100.7903 15.24501617141658
CNUSAIL-1               
1 43133U 18004Y   20212.75635329  .00000667  00000-0  30602-4 0  9998
2 43133  97.4438 283.3185 0007880 260.7702  99.2644 15.23883412141623
2018-004Z               
1 43134U 18004Z   20212.75222938  .00000800  00000-0  35696-4 0  9994
2 43134  97.4448 283.6911 0007676 260.2033  99.8338 15.24277448141645
2018-004AA              
1 43135U 18004AA  20212.72088699  .00001034  00000-0  44594-4 0  9993
2 43135  97.4457 284.0661 0007620 259.5904 100.4475 15.24807215141664
2018-004AB              
1 43136U 18004AB  20212.75150347  .00001164  00000-0  49382-4 0  9991
2 43136  97.4471 284.3985 0007396 258.8359 101.2048 15.25118698141687
FOX-1D (AO-92)          
1 43137U 18004AC  20212.74882185  .00000709  00000-0  32053-4 0  9994
2 43137  97.4437 283.6138 0007456 261.9916  98.0476 15.24189454141644
SPACEBEE-4              
1 43139U 18004AE  20212.74421372  .00001937  00000-0  76480-4 0  9999
2 43139  97.4506 285.8358 0006779 254.8879 105.1610 15.26747135141768
SPACEBEE-3              
1 43140U 18004AF  20212.72810464  .00002436  00000-0  92803-4 0  9995
2 43140  97.4523 286.5757 0006486 251.7222 108.3312 15.27687330141803
SPACEBEE-2              
1 43141U 18004AG  20212.76433517  .00002598  00000-0  97823-4 0  9996
2 43141  97.4538 286.8988 0006475 250.7131 109.3408 15.28006756141822
SPACEBEE-1              
1 43142U 18004AH  20212.85272919  .00002895  00000-0  10747-3 0  9993
2 43142  97.4542 287.2740 0006452 249.5589 110.4958 15.28379854141844
CICERO 7                
1 43143U 18004AJ  20212.81343316  .00001113  00000-0  46902-4 0  9995
2 43143  97.4447 284.7890 0005659 266.8712  93.1879 15.25431012141723
TYVAK-61C               
1 43144U 18004AK  20212.63893885  .00001265  00000-0  52097-4 0  9997
2 43144  97.4482 285.3717 0006137 272.9346  87.1190 15.25960554141739
LKW-3                   
1 43146U 18006A   20213.18588145  .00000508  00000-0  24331-4 0  9991
2 43146  97.4213 329.4960 0003902 212.7559 265.5586 15.23372780141577
ASNARO-2                
1 43152U 18007A   20213.13031096 -.00000531  00000-0 -20040-4 0  9993
2 43152  97.4206 216.5230 0001891  96.5041 328.5874 15.22400072140755
XIAOXIANG-2             
1 43155U 18008A   20213.16474337  .00000250  00000-0  18401-4 0  9993
2 43155  97.4442 281.1377 0017589   3.3306  79.4601 15.11682385139521
ZHOU ENLAI              
1 43156U 18008B   20213.14281895  .00000615  00000-0  40295-4 0  9998
2 43156  97.4491 280.9624 0014794   3.3627  84.1645 15.11232795139470
KIPP                    
1 43157U 18008C   20212.81637612  .00000662  00000-0  43274-4 0  9996
2 43157  97.4525 280.6130 0015351 359.8475   0.2748 15.11060176139406
QUANTUTONG 1            
1 43158U 18008D   20213.10279080  .00000582  00000-0  38790-4 0  9999
2 43158  97.4440 279.9273 0013972   1.9981  57.3603 15.10766673139414
JILIN-01-07             
1 43159U 18008E   20213.09262774 -.00000269  00000-0 -12681-4 0  9995
2 43159  97.4408 279.0766 0013807   3.8664  86.9623 15.10020261139385
JILIN-01-08             
1 43160U 18008F   20213.17853550 -.00000045  00000-0  10615-5 0  9991
2 43160  97.4402 279.0589 0009993  20.3816  57.2391 15.10024200139399
SBIRS GEO-3 (USA 282)   
1 43162U 18009A   20212.46741907 -.00000298  00000-0  00000+0 0  9998
2 43162   4.8733 317.1032 0002706 170.6789 128.0246  1.00270645  9303
LEMUR-2-MARSHALL        
1 43165U 18010C   20212.89314333  .00001151  00000-0  49970-4 0  9991
2 43165  82.9185 354.6927 0029143 239.7735 120.0616 15.20846196139972
LEMUR-2-TALLHAMN-ATC    
1 43167U 18010E   20213.10592549  .00000883  00000-0  40825-4 0  9996
2 43167  82.9215 357.5043 0022568 254.7692 105.1048 15.18549066139822
WEINA 1A                
1 43169U 18011A   20213.08572438  .00000149  00000-0  18024-4 0  9992
2 43169  35.0013 103.3343 0003663 240.5786 119.4615 14.89769902136927
YAOGAN 30 K             
1 43170U 18011B   20213.11457609  .00000140  00000-0  17103-4 0  9998
2 43170  35.0008 103.4568 0004908 125.6115 234.5052 14.89774220136928
YAOGAN 30 L             
1 43171U 18011C   20213.04251279  .00000116  00000-0  14649-4 0  9991
2 43171  35.0001 103.7693 0002384 184.3539 175.7144 14.89776266136911
YAOGAN 30 M             
1 43172U 18011D   20213.10295717  .00000139  00000-0  16924-4 0  9997
2 43172  35.0018 103.4165 0003908 232.1400 127.8954 14.89783081136924
AL YAH 3                
1 43174U 18012A   20212.76454538 -.00000170  00000-0  00000+0 0  9998
2 43174   0.0094  26.5697 0002246 102.8019  74.5877  1.00270258  9309
SES-14                  
1 43175U 18012B   20212.83093191 -.00000293  00000-0  00000+0 0  9997
2 43175   0.0174 321.4569 0001178 159.2270  79.8390  1.00272387  9529
KANOPUS-V 3             
1 43180U 18014A   20213.20440841  .00000224  00000-0  13835-4 0  9993
2 43180  97.4406 120.7774 0001814  82.2755  72.3116 15.19172477138308
KANOPUS-V 4             
1 43181U 18014B   20213.24605973 -.00000018  00000-0  23135-5 0  9994
2 43181  97.4403 120.9877 0001644  86.3417  79.2288 15.19160946138329
LEMUR-2-JIN-LUEN        
1 43182U 18014C   20212.69222049  .00000797  00000-0  74834-4 0  9996
2 43182  97.6836 118.5112 0015960  83.7930 276.5109 14.96630570136123
LEMUR-2-URAMCHANSOL     
1 43183U 18014D   20212.99812338  .00000118  00000-0  15401-4 0  9998
2 43183  97.6833 118.8796 0015453  82.4150 277.8826 14.96700071136178
LEMUR-2-KADI            
1 43184U 18014E   20213.05416345 -.00000236  00000-0 -15587-4 0  9993
2 43184  97.6838 118.9654 0015417  82.7859 277.5115 14.96690571136189
LEMUR-2-THENICKMOLO     
1 43185U 18014F   20212.98374781 -.00000198  00000-0 -12252-4 0  9995
2 43185  97.6847 118.9339 0015325  83.1027 277.1937 14.96708391136177
S-NET D                 
1 43186U 18014G   20212.96774407  .00000554  00000-0  53311-4 0  9998
2 43186  97.6913 120.0954 0012848  80.2715 279.9957 14.96829604136205
S-NET B                 
1 43187U 18014H   20212.83387477  .00000572  00000-0  54884-4 0  9998
2 43187  97.6911 119.9409 0012846  80.6389 279.6284 14.96829191136183
S-NET A                 
1 43188U 18014J   20212.70005026  .00000593  00000-0  56745-4 0  9993
2 43188  97.6913 119.8406 0012840  81.1402 279.1273 14.96828808136163
S-NET C                 
1 43189U 18014K   20212.70049006  .00000578  00000-0  55419-4 0  9990
2 43189  97.6910 119.8047 0012837  81.0626 279.2048 14.96828350134717
FENGMANIU-1 (FMN-1)     
1 43192U 18015A   20213.14764181  .00001453  00000-0  59723-4 0  9997
2 43192  97.4487 342.4431 0014027 297.9280 187.2273 15.25741824138564
ZHANGZHENG-1 (CSES)     
1 43194U 18015C   20213.20765248  .00000674  00000-0  33474-4 0  9990
2 43194  97.4415 337.7112 0014343 323.9952 153.6694 15.20995883138315
NUSAT-4 (ADA)           
1 43195U 18015D   20213.13578735  .00000762  00000-0  33771-4 0  9994
2 43195  97.4458 341.5454 0015959 307.6834 225.5642 15.24593477138541
GOMX-4B                 
1 43196U 18015E   20213.07211290  .00000198  00000-0  11058-4 0  9995
2 43196  97.4435 341.2875 0017566 315.1308  44.8508 15.24286357138546
GOMX-4A                 
1 43197U 18015F   20213.13942130  .00000426  00000-0  20219-4 0  9990
2 43197  97.4439 341.4591 0017686 315.3009  33.6962 15.24555567138565
SHAONIAN XING           
1 43199U 18015H   20213.20188838  .00001049  00000-0  43734-4 0  9996
2 43199  97.4273 340.6520 0015329 308.9250 171.5075 15.25867189138636
NUSAT-5 (MARYAM)        
1 43204U 18015K   20212.85182179  .00000836  00000-0  36542-4 0  9997
2 43204  97.4463 341.4924 0015584 311.0230 200.1759 15.24802206138116
BEIDOU-3 M5             
1 43207U 18018A   20212.39295578 -.00000050  00000-0  00000-0 0  9995
2 43207  55.1887 134.8931 0007452 333.6125  26.3366  1.86231650 16748
BEIDOU-3 M6             
1 43208U 18018B   20212.45969484 -.00000051  00000-0  00000-0 0  9998
2 43208  55.1883 134.8957 0008912 304.9565  54.9468  1.86231545 16747
PAZ                     
1 43215U 18020A   20213.07804447  .00000140  00000-0  98492-5 0  9990
2 43215  97.4467 219.2369 0001663  84.9954 346.5569 15.19151013135049
TINTIN A                
1 43216U 18020B   20213.03700936  .00162925  11286-4  66682-3 0  9991
2 43216  97.4362 227.8365 0016496   1.1945 358.9362 15.85867890135527
TINTIN B                
1 43217U 18020C   20213.03896154  .00498715  10547-3  11620-2 0  9998
2 43217  97.4449 229.0900 0014387 189.9770 170.1223 15.96855297135576
GOES 17                 
1 43226U 18022A   20212.57336890  .00000084  00000-0  00000-0 0  9990
2 43226   0.0546 242.8899 0000402 247.2615 247.7091  1.00272366  8879
HISPASAT 30W-6          
1 43228U 18023A   20213.12787813 -.00000231  00000-0  00000+0 0  9994
2 43228   0.0360  55.8789 0005147  51.3417 217.9918  1.00269539  8965
PODSAT                  
1 43229U 18023B   20212.66847209  .00018901 -44219-6  19957-3 0  9993
2 43229  26.9477  94.2849 5681110 273.8642  26.5345  4.64794687 44656
O3B FM15                
1 43231U 18024A   20212.46810873 -.00000026  00000-0  00000+0 0  9990
2 43231   0.0417   4.9757 0003013 124.2820 230.7660  5.00115247 43742
O3B FM16                
1 43232U 18024B   20211.92174074 -.00000025  00000-0  00000-0 0  9997
2 43232   0.0400 359.6460 0003010 129.7615 230.6164  5.00115301 43700
O3B FM14                
1 43233U 18024C   20211.96180951 -.00000025  00000-0  00000-0 0  9993
2 43233   0.0402 357.4268 0002952 135.5264 227.0688  5.00115700 43708
O3B FM13                
1 43234U 18024D   20212.77499831 -.00000027  00000-0  00000-0 0  9996
2 43234   0.0363  40.3729 0005771  96.2066 223.4629  5.00115934 43737
LKW-4                   
1 43236U 18025A   20213.09538245  .00000563  00000-0  26641-4 0  9995
2 43236  97.4127 328.9876 0008389 123.6703  49.1571 15.23373021131967
COSMOS 2525             
1 43243U 18028A   20213.10250748  .00052319  28217-5  12397-3 0  9990
2 43243  96.4346 254.4937 0002082  94.5523 325.2304 15.96895523136286
BEIDOU-3 M7             
1 43245U 18029A   20212.71311957 -.00000094  00000-0  00000-0 0  9993
2 43245  55.3131  13.9235 0000730  88.3641 291.2345  1.86232841 15907
BEIDOU-3 M8             
1 43246U 18029B   20212.95239851 -.00000095  00000-0  10000-3 0  9997
2 43246  55.3115  13.9470 0003442 353.1857 232.7234  1.86232662 15892
IRIDIUM 144             
1 43249U 18030A   20212.75559520  .00000050  00000-0  10913-4 0  9992
2 43249  86.3967 107.8879 0002537  88.9627 271.1859 14.34218095122353
IRIDIUM 149             
1 43250U 18030B   20212.76194411  .00000051  00000-0  11026-4 0  9996
2 43250  86.3966 107.8751 0002693  85.4155 274.7348 14.34217025122369
IRIDIUM 157             
1 43251U 18030C   20213.12981114  .00000049  00000-0  10608-4 0  9994
2 43251  86.3968 107.7268 0001812  78.3098 281.8302 14.34218764122396
IRIDIUM 140             
1 43252U 18030D   20212.79999773  .00000056  00000-0  12754-4 0  9999
2 43252  86.3969 107.8789 0003123  95.0796 265.0756 14.34219677122408
IRIDIUM 145             
1 43253U 18030E   20212.78730682  .00000052  00000-0  11569-4 0  9990
2 43253  86.3969 107.8647 0003565  75.3583 284.8008 14.34218895122339
IRIDIUM 146             
1 43254U 18030F   20212.76827962  .00000054  00000-0  12290-4 0  9991
2 43254  86.3968 107.8897 0002515  78.2998 281.8480 14.34217162122340
IRIDIUM 148             
1 43255U 18030G   20212.80633984  .00000056  00000-0  12856-4 0  9996
2 43255  86.3970 107.8861 0002144  75.7991 284.3443 14.34218738122389
IRIDIUM 142             
1 43256U 18030H   20212.77461702  .00000064  00000-0  15723-4 0  9995
2 43256  86.3966 107.8677 0002269  81.8847 278.2607 14.34216943122342
IRIDIUM 150             
1 43257U 18030J   20212.81266873  .00000053  00000-0  11937-4 0  9994
2 43257  86.3970 107.8987 0002339  87.0036 273.1427 14.34220041122379
IRIDIUM 143             
1 43258U 18030K   20212.79364730  .00000049  00000-0  10400-4 0  9992
2 43258  86.3973 107.9104 0002843  80.1827 279.9689 14.34217104122300
GAOFEN 1-02             
1 43259U 18031A   20212.75773798  .00000043  00000-0  13423-4 0  9990
2 43259  97.9640 290.5130 0005788  59.6004 300.5769 14.76447843125817
GAOFEN 1-03             
1 43260U 18031B   20213.14179913  .00000055  00000-0  15223-4 0  9998
2 43260  97.9634 290.8987 0006426 118.5648 241.6218 14.76449495125868
GAOFEN 1-04             
1 43262U 18031D   20212.78027413  .00000013  00000-0  91557-5 0  9995
2 43262  97.9643 290.5140 0005197  70.1430 290.0336 14.76451163125802
SUPERBIRD 8             
1 43271U 18033A   20212.62078095 -.00000107  00000-0  00000-0 0  9995
2 43271   0.0135 109.9419 0001545  12.2719 211.9404  1.00271608  8518
HYLAS 4                 
1 43272U 18033B   20213.12848000 -.00000249  00000-0  00000+0 0  9999
2 43272   0.0703 119.7214 0004510  40.9979 161.2191  1.00270350  8550
YAOGAN 31 A             
1 43275U 18034A   20213.02871889 -.00000178  00000-0 -14624-3 0  9996
2 43275  63.4025 208.9176 0082404   2.5842 357.5604 13.45196199113367
YAOGAN 31 B             
1 43276U 18034B   20213.02892911 -.00000112  00000-0 -36881-4 0  9994
2 43276  63.4022 208.9070 0082556   2.4896 357.6536 13.45196721113360
YAOGAN 31 C             
1 43277U 18034C   20213.02874133 -.00000103  00000-0 -21542-4 0  9999
2 43277  63.4033 208.0812 0082574   2.4957 357.6475 13.45197268113387
WEINA 1B                
1 43279U 18034E   20213.11663238  .00000446  00000-0  88983-3 0  9992
2 43279  63.4034 208.7693 0082173   4.2459 355.9189 13.45199930113376
IRNSS-1I                
1 43286U 18035A   20212.77963719  .00000058  00000-0  00000+0 0  9998
2 43286  29.2372 108.7101 0018481 180.4472 355.3477  1.00274529  8536
USA 283                 
1 43339U 18036A   20212.39848865 -.00000331  00000-0  00000+0 0  9995
2 43339   0.0057 219.1089 0000890 324.6891  42.2369  1.00273457  3778
COSMOS 2526             
1 43432U 18037A   20212.90709396 -.00000359  00000-0  00000+0 0  9992
2 43432   0.0245  95.7199 0000520  97.6301 210.2444  1.00269919  8431
SENTINEL-3B             
1 43437U 18039A   20212.81236327 -.00000042  00000-0  54057-6 0  9995
2 43437  98.6259 278.9119 0001580  87.9864 272.1497 14.26735948117926
ZHUHAI-1 OHS-01         
1 43439U 18040A   20213.19314118  .00000613  00000-0  32646-4 0  9997
2 43439  97.3304 284.6733 0009824 275.3842 162.9623 15.18778971125492
ZHUHAI-1 OVS-02         
1 43440U 18040B   20213.18104970  .00000645  00000-0  34016-4 0  9999
2 43440  97.3302 284.6920 0009339 306.4046 133.0449 15.18940328125495
ZHUHAI-1 OHS-02         
1 43441U 18040C   20213.22876459  .00000477  00000-0  25966-4 0  9990
2 43441  97.3422 285.9423 0009456 324.4479 110.4100 15.18960934125501
ZHUHAI-1 OHS-03         
1 43442U 18040D   20213.11883222  .00000631  00000-0  33404-4 0  9993
2 43442  97.3288 284.5419 0004743 326.5592 120.2362 15.18883041125484
ZHUHAI-1 OHS-04         
1 43443U 18040E   20213.21679676  .00000453  00000-0  24862-4 0  9991
2 43443  97.3338 285.1320 0002860 271.8508 163.7673 15.18919197125508
USA 285                 
1 43445U 18036E   20212.51211010 -.00000219  00000-0  00000-0 0  9999
2 43445   1.8944  89.8973 0007498  91.0440 214.6642  1.02115562  5001
USA 286                 
1 43446U 18036F   20212.39360819 -.00000197  00000-0  00000+0 0  9992
2 43446   1.8928  89.7837 0007397  89.4109 192.4154  1.02121954  4960
APSTAR 6C               
1 43450U 18041A   20211.93392454 -.00000331  00000-0  00000-0 0  9993
2 43450   0.0420 244.6537 0002517 207.9892 325.6004  1.00271285  8304
GAOFEN 5                
1 43461U 18043A   20213.06994983  .00000017  00000-0  13626-4 0  9990
2 43461  98.1996 149.5689 0000594 268.5994  91.5138 14.57768518118655
BANGABANDHUSAT-1        
1 43463U 18044A   20212.62271531 -.00000376  00000-0  00000+0 0  9997
2 43463   0.0167  84.6162 0002268  52.6467 154.7170  1.00268015  8197
UBAKUSAT                
1 43467U 98067NQ  20212.83767550  .00028077  00000-0  16909-3 0  9994
2 43467  51.6356  58.1274 0001340 199.2503 160.8447 15.77467312126952
GRACE-FO 1              
1 43476U 18047A   20212.83803371  .00000206  00000-0  71357-5 0  9990
2 43476  88.9911 131.9694 0020341  97.0707 263.2854 15.24044519121824
GRACE-FO 2              
1 43477U 18047B   20212.83829687  .00000206  00000-0  71453-5 0  9996
2 43477  88.9911 131.9723 0020135  96.7737 263.5803 15.24044669121821
IRIDIUM 161             
1 43478U 18047C   20212.85424973  .00000063  00000-0  12741-4 0  9993
2 43478  86.4477 265.8303 0002244 102.5292 257.6160 14.43281810115415
IRIDIUM 152             
1 43479U 18047D   20212.76636869  .00000051  00000-0  11256-4 0  9990
2 43479  86.3950 265.7406 0002380  83.5040 276.6427 14.34215999114707
IRIDIUM 147             
1 43480U 18047E   20212.70294975  .00000052  00000-0  11532-4 0  9992
2 43480  86.3951 265.7352 0002012  90.7398 269.4029 14.34217473114682
IRIDIUM 110             
1 43481U 18047F   20212.84882239  .00000055  00000-0  12575-4 0  9995
2 43481  86.3953 265.7089 0002539  98.9622 261.1862 14.34216901114696
IRIDIUM 162             
1 43482U 18047G   20212.84799180  .00000057  00000-0  11076-4 0  9995
2 43482  86.4475 265.7535 0002601 101.2198 258.9295 14.43279987115406
GAOFEN 6                
1 43484U 18048A   20213.14372761  .00000042  00000-0  13381-4 0  9999
2 43484  97.9862 292.4909 0008137 253.4467 106.5853 14.76485578116563
LUOJIA-1 01             
1 43485U 18048B   20213.23487785  .00000092  00000-0  20466-4 0  9990
2 43485  97.9887 292.8058 0010067 248.9740 111.0408 14.76700590116581
SES-12                  
1 43488U 18049A   20212.62512426 -.00000284  00000-0  00000-0 0  9999
2 43488   0.0184  62.7264 0000860 156.5546  49.4499  1.00271518  7893
FENGYUN 2H              
1 43491U 18050A   20211.77132510 -.00000137  00000-0  00000-0 0  9999
2 43491   0.5599 272.4907 0003550 229.8807 162.1644  1.00267508  7966
COSMOS 2527 [GLONASS-M] 
1 43508U 18053A   20212.43656593 -.00000025  00000-0  00000-0 0  9993
2 43508  64.8631 145.8420 0008365 240.7313 236.8527  2.13102643 16503
REMOVEDEBRIS            
1 43510U 98067NT  20212.82834582  .00006423  00000-0  73867-4 0  9990
2 43510  51.6354  92.7195 0002465 218.9702 141.1115 15.63444425120272
XJS A                   
1 43518U 18054A   20213.18451465  .00001307  00000-0  47566-4 0  9994
2 43518  35.0447 131.9117 0009387 277.5557 229.0951 15.28339159117131
XJS B                   
1 43519U 18054B   20213.18297832  .00001768  00000-0  63870-4 0  9998
2 43519  35.0466 131.7725 0008439 309.6061 197.9519 15.28333663117137
PAKTES 1A               
1 43529U 18056A   20213.22512414  .00000196  00000-0  27943-4 0  9997
2 43529  97.9932 300.8593 0026382 343.4818  16.5551 14.87420206111936
PRSS 1                  
1 43530U 18056B   20213.19524571  .00000038  00000-0  12699-4 0  9999
2 43530  97.9960 289.7117 0001532  88.8168 271.3231 14.76903472111153
BEIDOU IGSO-7           
1 43539U 18057A   20213.02566247 -.00000106  00000-0  00000+0 0  9992
2 43539  55.0478 181.8527 0029803 215.3021  32.2640  1.00254209  7628
CUBERRT                 
1 43546U 98067NU  20212.86249963  .00019897  00000-0  13486-3 0  9995
2 43546  51.6333  58.3311 0002258 242.8399 117.2370 15.74993761117080
TEMPEST-D               
1 43547U 98067NV  20213.08572741  .00006705  00000-0  78074-4 0  9993
2 43547  51.6368  91.5149 0004102 212.7571 147.3169 15.63035718116340
RAINCUBE                
1 43548U 98067NW  20212.84631634  .00027223  00000-0  17235-3 0  9993
2 43548  51.6323  66.4260 0003119 242.4028 117.6656 15.76346403117107
HALOSAT                 
1 43549U 98067NX  20212.81048036  .00027660  00000-0  18326-3 0  9996
2 43549  51.6353  67.7908 0002595 251.6190 108.4528 15.75294737116648
ENDUROSAT ONE           
1 43551U 98067NZ  20212.84439040  .00061546  00000-0  24701-3 0  9996
2 43551  51.6327  52.8146 0003272 253.0304 107.0341 15.85863991117258
EQUISAT                 
1 43552U 98067PA  20212.87226339  .00027329  00000-0  17265-3 0  9993
2 43552  51.6326  65.2219 0003441 253.8843 106.1778 15.76391938117050
RADSAT-G                
1 43553U 98067PB  20212.81872185  .00015020  00000-0  12332-3 0  9999
2 43553  51.6408  75.6054 0003856 244.4525 115.6074 15.70692681116963
1998-067PC              
1 43554U 98067PC  20212.78892409  .00082534  00000-0  27401-3 0  9994
2 43554  51.6287  49.3445 0003785 262.3594  97.6982 15.89590529116838
AEROCUBE 12A            
1 43556U 18046C   20212.68065815  .00000927  00000-0  43885-4 0  9998
2 43556  51.6411 257.8404 0008688  91.0859 269.1117 15.29902170113991
AEROCUBE 12B            
1 43557U 18046D   20212.68432748  .00000795  00000-0  39725-4 0  9993
2 43557  51.6411 258.6016 0008762  90.5996 269.5988 15.29608325113938
LEMUR-2-VU              
1 43558U 18046E   20212.60737733  .00001047  00000-0  46220-4 0  9997
2 43558  51.6412 254.4510 0007799  94.4070 265.7802 15.31232648114026
LEMUR-2-ALEXANDER       
1 43559U 18046F   20213.08944485  .00000583  00000-0  30938-4 0  9998
2 43559  51.6409 251.6833 0007745  96.2028 263.9836 15.31377809113883
LEMUR-2-YUASA           
1 43560U 18046G   20212.82429305  .00000411  00000-0  25513-4 0  9993
2 43560  51.6412 253.6427 0007989 107.9200 252.2653 15.31165611114056
LEMUR-2-TOMHENDERSON    
1 43561U 18046H   20213.10556505  .00001013  00000-0  45462-4 0  9993
2 43561  51.6412 252.5173 0008083 108.5139 251.6721 15.30936044113656
TELSTAR 19V             
1 43562U 18059A   20212.45127507 -.00000293  00000-0  00000-0 0  9996
2 43562   0.0143 116.5712 0002810  42.0899 249.3244  1.00270337  7611
GSAT0221 (GALILEO 25)   
1 43564U 18060A   20212.57835589 -.00000067  00000-0  00000-0 0  9998
2 43564  56.8377  39.8640 0002192 266.6013  93.4228  1.70475739 12552
GSAT0222 (GALILEO 26)   
1 43565U 18060B   20212.21162508 -.00000066  00000-0  00000+0 0  9992
2 43565  56.8388  39.8734 0001888 284.4724  75.5560  1.70475732 12594
GSAT0219 (GALILEO 23)   
1 43566U 18060C   20211.25875048 -.00000065  00000-0  00000+0 0  9995
2 43566  56.8390  39.9010 0001579 273.1467  86.8842  1.70475601 12551
GSAT0220 (GALILEO 24)   
1 43567U 18060D   20211.47863286 -.00000065  00000-0  00000+0 0  9991
2 43567  56.8386  39.8953 0001636 274.4168  85.6136  1.70475438 12537
IRIDIUM 160             
1 43569U 18061A   20212.79466375  .00000081  00000-0  21902-4 0  9994
2 43569  86.3958 234.1102 0002130  84.4686 275.6752 14.34218635105583
IRIDIUM 166             
1 43570U 18061B   20212.82002581  .00000098  00000-0  27794-4 0  9998
2 43570  86.3957 234.0797 0002539  92.1821 267.9666 14.34216679105611
IRIDIUM 158             
1 43571U 18061C   20213.13715489  .00000079  00000-0  21084-4 0  9997
2 43571  86.3958 233.9548 0002463  85.1171 275.0306 14.34219245105636
IRIDIUM 165             
1 43572U 18061D   20212.81367937  .00000084  00000-0  22972-4 0  9996
2 43572  86.3959 234.0968 0002505  85.7651 274.3831 14.34219844105609
IRIDIUM 155             
1 43573U 18061E   20213.12446826  .00000087  00000-0  23958-4 0  9995
2 43573  86.3956 233.9386 0002436 104.6735 255.4730 14.34215798105620
IRIDIUM 154             
1 43574U 18061F   20212.82637792  .00000082  00000-0  22236-4 0  9999
2 43574  86.3959 234.0949 0002097  84.2275 275.9159 14.34217183105628
IRIDIUM 163             
1 43575U 18061G   20212.80735472  .00000085  00000-0  23267-4 0  9990
2 43575  86.3956 234.0835 0002791  82.3338 277.8175 14.34217962105590
IRIDIUM 156             
1 43576U 18061H   20212.78197913  .00000080  00000-0  21426-4 0  9990
2 43576  86.3957 234.0988 0002648  93.0025 267.1474 14.34218437105585
IRIDIUM 164             
1 43577U 18061J   20212.76295118  .00000085  00000-0  23429-4 0  9995
2 43577  86.3958 234.1145 0002262  84.3064 275.8389 14.34216881105628
IRIDIUM 159             
1 43578U 18061K   20212.80101052  .00000080  00000-0  21339-4 0  9997
2 43578  86.3956 234.0804 0002191  86.2981 273.8466 14.34217683105599
BEIDOU-3 M9             
1 43581U 18062A   20212.92844926  .00000056  00000-0  00000+0 0  9999
2 43581  54.5462 255.6419 0005793 255.8846 163.4715  1.86231874 13644
BEIDOU-3 M10            
1 43582U 18062B   20212.73584069  .00000056  00000-0  00000-0 0  9999
2 43582  54.5468 255.6368 0004225 317.7869  63.3883  1.86232152 13640
GAOFEN 11               
1 43585U 18063A   20213.15814998  .00000463  00000-0  22253-4 0  9997
2 43585  97.3828 292.0878 0018787  67.3795 352.4532 15.23564278111895
TELKOM 4 (MERAH PUTIH)  
1 43587U 18064A   20212.63776269 -.00000358  00000-0  00000-0 0  9996
2 43587   0.0159  85.0200 0001458  45.8098 155.4520  1.00269507  7336
UITMSAT-1               
1 43589U 98067PD  20212.86901981  .00040525  00000-0  21487-3 0  9999
2 43589  51.6347  61.9968 0002501 270.7100  89.3615 15.80109819112830
MAYA-1                  
1 43590U 98067PE  20212.86189269  .00040541  00000-0  21493-3 0  9993
2 43590  51.6293  61.9207 0002632 261.1764  98.8940 15.80111569112766
BHUTAN-1                
1 43591U 98067PF  20212.82944594  .00039026  00000-0  20522-3 0  9998
2 43591  51.6357  61.8146 0002712 270.3894  89.6797 15.80308656112767
SIRIUSSAT-1             
1 43595U 98067PG  20212.83748908  .00030519  00000-0  18666-3 0  9998
2 43595  51.6279  67.8125 0001782 222.0828 138.0035 15.77068628111866
SIRIUSSAT-2             
1 43596U 98067PH  20212.83899610  .00029967  00000-0  18428-3 0  9997
2 43596  51.6273  68.6485 0001900 221.5841 138.5015 15.76955230111855
TANUSHA-3               
1 43597U 98067PJ  20212.79871239  .00128930  17571-4  34825-3 0  9994
2 43597  51.6255  50.7447 0003656 253.0855 106.9752 15.94071883112087
1998-067PK              
1 43598U 98067PK  20212.83547267  .00203708  28145-4  42404-3 0  9999
2 43598  51.6249  47.9761 0005272 265.1007  94.9400 15.98635993112120
AEOLUS                  
1 43600U 18066A   20212.99781582  .00023684  00000-0  94480-4 0  9992
2 43600  96.7188 218.7392 0005070  96.7083 263.4767 15.86584910112276
BEIDOU-3 M11            
1 43602U 18067A   20212.72809135  .00000059  00000-0  00000+0 0  9992
2 43602  54.6175 254.1938 0005929 341.8393  77.0561  1.86231781 13134
BEIDOU-3 M12            
1 43603U 18067B   20212.86010613  .00000059  00000-0  00000-0 0  9994
2 43603  54.6143 254.1556 0003690 322.6572  95.2750  1.86231690 13158
HAIYANG-1C              
1 43609U 18068A   20213.11328764 -.00000014  00000-0  10756-4 0  9994
2 43609  98.5304 287.8789 0012771  15.3664 344.7910 14.34169842 99339
TELSTAR 18V (APSTAR 5C) 
1 43611U 18069A   20212.42292337 -.00000308  00000-0  00000+0 0  9999
2 43611   0.0276  77.1785 0002438  49.4067 112.1900  1.00268880  7075
ICESAT-2                
1 43613U 18070A   20212.83979897  .00000204  00000-0  71800-5 0  9997
2 43613  92.0038  73.0628 0003063 101.3797 258.7797 15.28277487104514
SURFSAT                 
1 43614U 18070B   20212.83296264  .00002112  00000-0  54089-4 0  9997
2 43614  93.0226 172.5240 0015928 159.1005 201.0904 15.39355634105151
CP7 (DAVE)              
1 43615U 18070C   20213.12866789  .00001919  00000-0  49413-4 0  9995
2 43615  93.0234 172.6343 0016060 157.8587 202.3364 15.39202311105195
ELFIN-B                 
1 43616U 18070D   20213.12982618  .00002813  00000-0  69012-4 0  9997
2 43616  93.0190 172.5917 0016470 158.9928 201.2007 15.40630648105247
ELFIN-A                 
1 43617U 18070E   20212.83296788  .00003222  00000-0  78531-4 0  9992
2 43617  93.0241 173.0169 0015944 156.5181 203.6806 15.40827114105205
SSTL S1-4               
1 43618U 18071A   20213.20995158  .00000598  00000-0  59993-4 0  9997
2 43618  97.7536 108.0926 0014900 127.0060 233.2530 14.94897652102115
NOVASAR 1               
1 43619U 18071B   20213.20000310  .00000248  00000-0  27980-4 0  9998
2 43619  97.7545 108.0552 0007866 124.6051 235.5938 14.94913373102102
BEIDOU-3 M13            
1 43622U 18072A   20212.92830376 -.00000055  00000-0  00000+0 0  9990
2 43622  55.1539 134.4558 0007445 292.4325 201.6479  1.86232006 12666
BEIDOU-3 M14            
1 43623U 18072B   20212.37405463 -.00000051  00000-0  00000-0 0  9992
2 43623  55.1548 134.4647 0006379 313.3288 259.3724  1.86232071 12656
AZERSPACE 2 (IS-38)     
1 43632U 18074A   20212.77754353  .00000122  00000-0  00000+0 0  9990
2 43632   0.0132  89.0678 0001661  39.1289 145.6717  1.00271235  6963
HORIZONS 3E             
1 43633U 18074B   20212.62658759 -.00000045  00000-0  00000-0 0  9994
2 43633   0.0070 303.8070 0000115 233.9777 165.4743  1.00270900  6818
CENTISPACE-1 S1         
1 43636U 18075A   20213.15852729  .00000050  00000-0  21130-4 0  9996
2 43636  98.1482 284.3857 0011166 355.0845   5.0262 14.57256707 97711
1998-067PN              
1 43638U 98067PN  20212.83081528  .00008851  00000-0  94290-4 0  9996
2 43638  51.6366  92.0388 0001894 179.2045 180.8953 15.64855598103478
1998-067PP              
1 43639U 98067PP  20212.91320912  .00016629  00000-0  13858-3 0  9991
2 43639  51.6360  81.3244 0003266 195.3050 164.7849 15.70237276103621
STARS-ME                
1 43640U 98067PQ  20212.36639353  .00010677  00000-0  10480-3 0  9999
2 43640  51.6349  90.3885 0003826 185.4672 174.6283 15.66669734103459
SAOCOM 1A               
1 43641U 18076A   20213.09785834 -.00000011  00000-0  51952-5 0  9992
2 43641  97.8897  38.5866 0001398  82.3682 277.7692 14.82152484 98066
YAOGAN 32 A             
1 43642U 18077A   20213.10073021  .00000023  00000-0  14636-4 0  9993
2 43642  98.1888 268.1689 0001671  81.4244 278.7146 14.59298590 96395
YAOGAN 32 B             
1 43643U 18077B   20213.10060521  .00000020  00000-0  13890-4 0  9993
2 43643  98.1885 268.1521 0001597  87.1309 273.0079 14.59298146 96390
BEIDOU-3 M15            
1 43647U 18078A   20212.77803771 -.00000094  00000-0  10000-3 0  9993
2 43647  55.2094  14.1397 0005834 353.9663  24.7572  1.86232529 12188
BEIDOU-3 M16            
1 43648U 18078B   20212.91175815 -.00000095  00000-0  10000-3 0  9992
2 43648  55.1984  14.1424 0003924  12.4693   4.9258  1.86232038 12192
AEHF-4 (USA 288)        
1 43651U 18079A   20213.16347866  .00000119  00000-0  00000+0 0  9993
2 43651   3.7881 311.2032 0005845 172.7375  94.1076  1.00275652  6768
HAIYANG-2B              
1 43655U 18081A   20213.08353577 -.00000365  00000-0 -31293-3 0  9994
2 43655  99.3479 220.0316 0001217 146.5253 213.5978 13.79295248 88930
COSMOS 2528             
1 43657U 18082A   20213.15766079 -.00000075  00000-0  00000+0 0  9995
2 43657  67.1478 255.0512 0006362 244.8732 115.1702 13.96506077 90119
CFOSAT                  
1 43662U 18083A   20213.05345590  .00004352  00000-0  22769-3 0  9993
2 43662  97.4792 236.9319 0001644 112.1034 326.7544 15.16365238 97143
CUBEBEL-1 (BSUSAT-1)    
1 43666U 18083E   20213.12681735  .00000877  00000-0  45972-4 0  9999
2 43666  97.4879 239.1574 0014109 202.3127 230.5544 15.18329245 97249
CHANGSHAGAOXIN (TY 4-01)
1 43669U 18083H   20213.06998155  .00000552  00000-0  30998-4 0  9994
2 43669  97.4751 237.1599 0010339 193.4737 166.6222 15.17344973 97149
GOSAT 2                 
1 43672U 18084B   20212.83308627 -.00000095  00000-0 -50770-5 0  9997
2 43672  97.8686 322.9950 0001434  92.2745 267.8633 14.84238491 95035
KHALIFASAT              
1 43676U 18084F   20213.13698873 -.00000150  00000-0 -99865-5 0  9990
2 43676  97.8792 328.2154 0015999   1.9634 358.1647 14.88880158 95358
TEN-KOH                 
1 43677U 18084G   20212.79609325  .00000107  00000-0  16865-4 0  9994
2 43677  97.8874 329.7647 0016453  20.6846 339.5034 14.90301197 95393
DIWATA-2B               
1 43678U 18084H   20213.14445423 -.00000283  00000-0 -22390-4 0  9999
2 43678  97.8894 331.5581 0010835  38.9949 321.2063 14.91637785 95530
STARS-AO                
1 43679U 18084J   20212.79722474  .00000157  00000-0  21040-4 0  9993
2 43679  97.8928 331.9573 0010173  48.5830 311.6261 14.92238781 95510
AUTCUBE 2               
1 43681U 18084K   20213.07659297  .00000278  00000-0  33255-4 0  9995
2 43681  97.8897 331.6778 0010341  41.0360 319.1635 14.91866522 95499
BEIDOU-3 G1             
1 43683U 18085A   20212.39426836 -.00000292  00000-0  00000-0 0  9998
2 43683   1.6772 253.5361 0004012 346.2595 350.4916  1.00267789  6526
COSMOS 2529 [GLONASS-M] 
1 43687U 18086A   20212.98870912  .00000048  00000-0  00000+0 0  9993
2 43687  64.5455 265.6808 0011380 264.5755 200.3627  2.13102204 13538
METOP-C                 
1 43689U 18087A   20212.87301955 -.00000027  00000-0  75091-5 0  9996
2 43689  98.6951 271.8288 0001766 185.8885 174.2272 14.21499454 89767
CICERO 10               
1 43690U 18088A   20212.80420155  .00000691  00000-0  29871-4 0  9996
2 43690  85.0358  49.7526 0016099 143.9007 216.3325 15.20281622 95325
NABEO-1 & KICK STAGE    
1 43692U 18088C   20213.05008005  .00001167  00000-0  50772-4 0  9995
2 43692  85.0320  49.0319 0018478 126.1344 234.1610 15.20892574 95362
IRVINE01                
1 43693U 18088D   20212.85812321  .00001836  00000-0  75931-4 0  9994
2 43693  85.0335  48.2739 0019528 126.8185 233.4852 15.23023848 95456
PROXIMA I               
1 43694U 18088E   20212.81253786  .00000801  00000-0  33036-4 0  9996
2 43694  85.0302  48.1695 0021120 120.4607 239.8724 15.22030182 95435
LEMUR-2-ZUPANSKI        
1 43695U 18088F   20212.77096687  .00001308  00000-0  56372-4 0  9998
2 43695  85.0300  48.8377 0018529 140.6730 219.5861 15.21346028 95365
PROXIMA II              
1 43696U 18088G   20212.81070828  .00000790  00000-0  32576-4 0  9997
2 43696  85.0303  48.1748 0021162 120.4047 239.9291 15.22031836 95438
LEMUR-2-CHANUSIAK       
1 43697U 18088H   20212.85202641  .00001346  00000-0  56789-4 0  9992
2 43697  85.0363  48.8183 0017732 122.6331 237.6625 15.22109065 95439
GSAT-29                 
1 43698U 18089A   20211.98992999  .00000072  00000-0  00000-0 0  9995
2 43698   0.0538 262.6190 0002592 180.5375 276.3395  1.00273933  6246
ES'HAIL 2               
1 43700U 18090A   20212.79077271  .00000130  00000-0  00000+0 0  9992
2 43700   0.0230  77.7368 0002094  75.0145 106.5642  1.00271538  6384
BEIDOU-3 M17            
1 43706U 18093A   20212.59230956  .00000056  00000-0  00000-0 0  9998
2 43706  54.6254 255.4882 0011435 260.2183 158.7597  1.86231909 11539
BEIDOU-3 M18            
1 43707U 18093B   20212.43256131  .00000056  00000-0  00000+0 0  9994
2 43707  54.6266 255.4838 0009709 279.1445 123.2866  1.86232132 11530
TIANZHI 1               
1 43710U 18094A   20213.15224167  .00000233  00000-0  12670-4 0  9991
2 43710  97.4060 220.0534 0012415 249.5455 175.0977 15.23695792 94273
SHIYAN 6 (SY-6)         
1 43711U 18094B   20213.07376405  .00000213  00000-0  13011-4 0  9994
2 43711  97.4089 217.3735 0010680 254.9220 105.0833 15.20128587 94082
TIANPING 1A             
1 43712U 18094C   20213.21112612  .00000619  00000-0  28469-4 0  9992
2 43712  97.4054 220.1630 0011782 245.1386 245.3502 15.23981550 94283
JIADING 1               
1 43713U 18094D   20213.19927409  .00000868  00000-0  38703-4 0  9996
2 43713  97.4050 220.2623 0012635 250.6559 175.8038 15.24042457 94280
TIANPING 1B             
1 43714U 18094E   20213.09459233  .00000322  00000-0  16351-4 0  9991
2 43714  97.4061 220.0062 0011200 247.8840 182.5147 15.23757555 94261
MOHAMMED VI-B           
1 43717U 18095A   20213.16399467  .00000011  00000-0  87097-5 0  9996
2 43717  97.9560 284.3353 0001660 101.2576 258.8821 14.77086561 91248
HYSIS                   
1 43719U 18096A   20213.19225870  .00000057  00000-0  14748-4 0  9990
2 43719  97.9021 279.6237 0012112  87.7980 272.4622 14.78755942 90136
HSAT                    
1 43720U 18096B   20212.83283429  .00002033  00000-0  75570-4 0  9992
2 43720  97.4222 286.7862 0014716 334.4514  25.5997 15.28624393 93042
FACSAT-1                
1 43721U 18096C   20213.17379089  .00000970  00000-0  38915-4 0  9999
2 43721  97.4189 286.5097 0016601 333.3781  26.6604 15.27320392 93062
CENTAURI-2              
1 43722U 18096D   20212.72563025  .00001295  00000-0  50215-4 0  9993
2 43722  97.4202 286.3819 0016600 334.6586  25.3837 15.27873489 93003
FLOCK 3R-9              
1 43723U 18096E   20213.01386838  .00001348  00000-0  50397-4 0  9992
2 43723  97.4205 287.2759 0015862 334.7652 148.8718 15.29019521 93082
FLOCK 3R-12             
1 43724U 18096F   20212.78205944  .00002798  00000-0  10166-3 0  9993
2 43724  97.4208 287.0795 0015919 335.9624  24.0871 15.29027266 93056
FLOCK 3R-11             
1 43725U 18096G   20212.76903172  .00002649  00000-0  96411-4 0  9993
2 43725  97.4205 287.0835 0015988 336.1651  23.8847 15.29024450 93050
FLOCK 3R-8              
1 43727U 18096J   20212.81518633  .00001153  00000-0  43503-4 0  9998
2 43727  97.4215 287.2559 0016504 337.0849  22.9653 15.29016747 93068
KEPLER-1 (CASE)         
1 43729U 18096L   20212.71983657  .00001601  00000-0  60816-4 0  9995
2 43729  97.4216 286.9528 0017174 339.5170  20.5379 15.28200006 93039
GLOBAL-1                
1 43730U 18096M   20212.76125084  .00000609  00000-0  25414-4 0  9996
2 43730  97.4222 286.7150 0018890 340.1982  19.8522 15.27409153 93025
LEMUR-2-ORZULAK         
1 43731U 18096N   20212.75424877  .00001563  00000-0  58024-4 0  9991
2 43731  97.4223 287.3678 0019211 337.1855  22.8529 15.28954894 93065
LEMUR-2-KOBYSZCZE       
1 43732U 18096P   20212.76394292  .00001568  00000-0  57975-4 0  9999
2 43732  97.4229 287.5380 0019328 337.2375  22.8006 15.29097780 93075
FLOCK 3R-4              
1 43733U 18096Q   20213.11211484  .00002548  00000-0  92654-4 0  9993
2 43733  97.4217 287.6476 0019425 337.5007  70.4961 15.29029888 93111
FLOCK 3R-3              
1 43734U 18096R   20213.07982035  .00002247  00000-0  81994-4 0  9997
2 43734  97.4218 287.6870 0020128 336.8020  72.6074 15.29036900 93112
FLOCK 3R-15             
1 43736U 18096T   20212.79308893  .00001060  00000-0  40826-4 0  9992
2 43736  97.4218 287.2911 0020546 339.7729  20.2695 15.28444393 93068
CICERO 8                
1 43737U 18096U   20212.73864340  .00001548  00000-0  56575-4 0  9990
2 43737  97.4189 287.6621 0020898 339.8612  20.1801 15.29462907 93105
INNOSAT-2               
1 43738U 18096V   20213.13619505  .00001830  00000-0  64281-4 0  9994
2 43738  97.4223 288.9441 0022442 338.3517  21.6773 15.30503642 93214
FLOCK 3R-10             
1 43740U 18096X   20212.98488411  .00001866  00000-0  68729-4 0  9999
2 43740  97.4204 287.2642 0015794 334.5578  25.4883 15.29023653 93118
FLOCK 3R-6              
1 43741U 18096Y   20213.16617763  .00002421  00000-0  88295-4 0  9993
2 43741  97.4202 287.5393 0016453 334.9062  85.1204 15.29037687 93121
FLOCK 3R-7              
1 43742U 18096Z   20213.16030028  .00001437  00000-0  53557-4 0  9996
2 43742  97.4198 287.5498 0016134 336.2881  86.9887 15.29020535 93111
REAKTOR HELLO WORLD     
1 43743U 18096AA  20212.73111919  .00000838  00000-0  33850-4 0  9996
2 43743  97.4194 286.3846 0017131 340.4178  19.6402 15.27490410 93001
HIBER-1                 
1 43744U 18096AB  20212.78422647  .00001263  00000-0  48735-4 0  9996
2 43744  97.4217 286.8614 0017007 340.0053  20.0518 15.28080579 93035
LEMUR-2-DULY            
1 43745U 18096AC  20212.76826545  .00001513  00000-0  55927-4 0  9999
2 43745  97.4222 287.4960 0019147 336.8074  23.2300 15.29166905 93084
LEMUR-2-VLADIMIR        
1 43746U 18096AD  20212.73056283  .00001563  00000-0  57543-4 0  9992
2 43746  97.4222 287.5363 0019240 337.1292  22.9089 15.29228093 93103
FLOCK 3R-1              
1 43747U 18096AE  20212.73713548  .00001647  00000-0  60879-4 0  9996
2 43747  97.4216 287.3526 0019679 338.8141  21.2282 15.29021148 93078
COSMOS 2530             
1 43751U 18097A   20213.02914170  .00000015  00000-0  36674-4 0  9993
2 43751  82.5143 115.0264 0020262  42.4615 317.8027 12.42889354 75644
COSMOS 2531             
1 43752U 18097B   20213.05444530  .00000022  00000-0  84148-4 0  9992
2 43752  82.5184 115.2783 0017872  59.6837 300.6005 12.42893211 75651
COSMOS 2532             
1 43753U 18097C   20212.99935194  .00004282  00000-0  30832-1 0  9996
2 43753  82.5136 115.0713 0019727  61.6057 298.6944 12.42854338 75642
MINXSS-2                
1 43758U 18099A   20212.81711073  .00000681  00000-0  66229-4 0  9993
2 43758  97.7046 284.0467 0016708  49.1459 311.1207 14.95695520 90434
SIRION PATHFINDER-2     
1 43759U 18099B   20212.80747950  .00000440  00000-0  45049-4 0  9996
2 43759  97.7032 283.7238 0015203  43.5546 316.6873 14.95293441 90419
ELYSIUM STAR 2 & LFF    
1 43760U 18099C   20213.13599814  .00000542  00000-0  53794-4 0  9998
2 43760  97.7062 284.2879 0014744  47.7422 312.5047 14.95702196 90475
2018-099D               
1 43761U 18099D   20212.85707120 -.00000137  00000-0 -72192-5 0  9994
2 43761  97.7052 283.8291 0015497  49.2036 311.0526 14.95140732 90414
STPSAT-5                
1 43762U 18099E   20212.79402060 -.00000050  00000-0  71795-6 0  9995
2 43762  97.7045 284.4169 0015333  51.4667 308.7926 14.96039730 90572
POLAR SCOUT KODIAK      
1 43764U 18099G   20212.74219888  .00000554  00000-0  53691-4 0  9997
2 43764  97.7045 284.7215 0016994  51.5514 308.7230 14.96547282 90467
HAWK-A                  
1 43765U 18099H   20212.79939489  .00000102  00000-0  14224-4 0  9997
2 43765  97.7004 284.2969 0016123  55.3434 304.9304 14.96114876 90459
CORVUS-BC 4             
1 43767U 18099K   20212.89860405 -.00000038  00000-0  16932-5 0  9999
2 43767  97.7015 283.1771 0012904  47.6934 312.5378 14.94740907 90391
AISTECHSAT 2            
1 43768U 18099L   20212.78845146 -.00000082  00000-0 -23615-5 0  9999
2 43768  97.7017 283.0282 0012526  49.1001 311.1302 14.94684961 90098
FLOCK 3S-1              
1 43769U 18099M   20213.15582512  .00000449  00000-0  46175-4 0  9997
2 43769  97.7004 283.4226 0015436  53.9155 306.3492 14.94995466 90443
FOX-1CLIFF (AO-95)      
1 43770U 18099N   20213.14765154  .00000581  00000-0  58220-4 0  9994
2 43770  97.7039 283.7626 0015805  55.5165 304.7546 14.95013564 90442
2018-099P               
1 43771U 18099P   20213.13920559  .00000518  00000-0  52452-4 0  9997
2 43771  97.7040 283.7828 0015778  55.5407 304.7302 14.95048423 90438
RANGE-A                 
1 43772U 18099Q   20212.76074181  .00000420  00000-0  43335-4 0  9999
2 43772  97.7109 283.9415 0015234  44.9930 315.2522 14.95209815 90388
RANGE-B                 
1 43773U 18099R   20212.73744310  .00000407  00000-0  42419-4 0  9992
2 43773  97.6979 282.9674 0014519  45.1293 315.1104 14.94984213 90374
HIBER-2                 
1 43774U 18099S   20212.75460236  .00000605  00000-0  60152-4 0  9991
2 43774  97.7082 283.7677 0013899  48.7317 311.5098 14.95196095 90386
2018-099T               
1 43775U 18099T   20212.42243140  .00000364  00000-0  38341-4 0  9996
2 43775  97.7046 283.2351 0015835  49.4674 310.7923 14.95085965 90333
SPAWAR-CAL-R            
1 43776U 18099U   20212.70036112 -.00000100  00000-0 -39064-5 0  9991
2 43776  97.7038 283.4579 0015832  48.2229 312.0342 14.95032070 90361
LACMA ENOCH             
1 43777U 18099V   20212.76064505  .00007258  00000-0  58760-3 0  9996
2 43777  97.7475 285.5637 0016686  57.5593 302.7241 14.99932182 90462
SPAWAR-CAL-OR           
1 43778U 18099W   20212.70731325 -.00000208  00000-0 -13698-4 0  9994
2 43778  97.6943 282.7013 0015225  52.7957 307.4651 14.95102642 89964
2018-099X               
1 43779U 18099X   20212.69597223  .00000087  00000-0  13173-4 0  9991
2 43779  97.7042 283.4755 0015846  48.3504 311.9071 14.95055375 90367
MOVE-II                 
1 43780U 18099Y   20212.74989045  .00000781  00000-0  75746-4 0  9991
2 43780  97.7044 283.6115 0014756  55.0624 305.1981 14.95437546 90381
SNUSAT-2                
1 43782U 18099AA  20212.76650654  .00000016  00000-0  66460-5 0  9991
2 43782  97.7037 283.6156 0013823  44.5585 315.6745 14.95280263 90390
KAZSTSAT                
1 43783U 18099AB  20213.13166798  .00000112  00000-0  15964-4 0  9994
2 43783  97.7161 283.6277 0009390  45.8820 314.3171 14.93601274 90355
SNUGLITE                
1 43784U 18099AC  20212.73204889  .00000299  00000-0  32213-4 0  9996
2 43784  97.6998 283.3957 0016149  51.3478 308.9185 14.95377229 90408
ORBWEAVER 2             
1 43785U 18099AD  20212.72807915  .00000412  00000-0  42036-4 0  9996
2 43785  97.7010 283.4586 0014315  41.8091 318.4221 14.95749257 89973
ITASAT 1                
1 43786U 18099AE  20212.73322375  .00000253  00000-0  28048-4 0  9990
2 43786  97.7053 283.8061 0016237  50.4814 309.7840 14.95346897 90400
KAZSCISAT-1             
1 43787U 18099AF  20212.73882196  .00000663  00000-0  64664-4 0  9992
2 43787  97.6959 283.1772 0015167  49.0151 311.2380 14.95646088 90419
FLOCK 3S-3              
1 43788U 18099AG  20212.84015574 -.00000195  00000-0 -12446-4 0  9996
2 43788  97.7055 283.8753 0016132  51.2717 308.9943 14.95429132 90417
EAGLET-I                
1 43790U 18099AJ  20212.78112719 -.00000290  00000-0 -20799-4 0  9997
2 43790  97.7044 284.1274 0014695  54.2434 306.0151 14.95728413 90123
CAPELLA-1               
1 43791U 18099AK  20212.85237687  .00001874  00000-0  16398-3 0  9994
2 43791  97.7289 286.0982 0012877  51.5835 308.6541 14.97882626 90497
ESEO                    
1 43792U 18099AL  20212.79479256 -.00000038  00000-0  17907-5 0  9999
2 43792  97.7013 283.9430 0014364  51.6776 308.5734 14.95608016 90181
CSIM-FD                 
1 43793U 18099AM  20212.75908048  .00000609  00000-0  59203-4 0  9999
2 43793  97.7018 283.9178 0014199  50.4770 309.7704 14.96090947 90160
HAWK-B                  
1 43794U 18099AN  20212.73289587  .00000117  00000-0  15490-4 0  9992
2 43794  97.7003 284.2313 0016168  55.6161 304.6586 14.96114108 90437
ORBWEAVER 1             
1 43795U 18099AP  20213.16378844  .00001129  00000-0  10467-3 0  9998
2 43795  97.7039 284.5597 0014857  52.0919 308.1666 14.96344264 90491
THEA                    
1 43796U 18099AQ  20212.74661565  .00000626  00000-0  60849-4 0  9998
2 43796  97.7035 284.2051 0015816  36.5268 323.7028 14.96006787 89342
SKYSAT-C12              
1 43797U 18099AR  20212.77209577  .00000931  00000-0  27175-4 0  9997
2 43797  97.2127 283.1067 0002642 113.7937 246.3586 15.37815474 91854
ASTROCAST 0.1           
1 43798U 18099AS  20213.10043494 -.00000030  00000-0  24354-5 0  9998
2 43798  97.7043 284.5488 0013345  35.5212 324.6895 14.95890921 90471
HAWK-C                  
1 43799U 18099AT  20212.79958797  .00000127  00000-0  16395-4 0  9995
2 43799  97.7005 284.2139 0016075  55.2023 305.0708 14.96115989 91193
ICEYE-X2                
1 43800U 18099AU  20213.15247811  .00000539  00000-0  53897-4 0  9992
2 43800  97.7040 284.0145 0015367  46.1664 314.0824 14.95371055 90457
2018-099AV              
1 43801U 18099AV  20213.14010433  .00000848  00000-0  81375-4 0  9995
2 43801  97.7018 283.9571 0015205  43.1391 317.1018 14.95621779 90460
SKYSAT-C13              
1 43802U 18099AW  20212.75353706  .00000658  00000-0  23059-4 0  9999
2 43802  97.2463 283.2940 0003404 217.8729 142.2275 15.32923559 91653
JY1SAT (JO-97)          
1 43803U 18099AX  20212.67567979  .00000577  00000-0  57093-4 0  9995
2 43803  97.7014 283.5581 0016255  51.3044 308.9628 14.95529335 90399
SUOMI-100               
1 43804U 18099AY  20213.14520590  .00000611  00000-0  60182-4 0  9999
2 43804  97.7012 284.0061 0016327  49.9333 310.3317 14.95517742 90471
AL-FARABI 2             
1 43805U 18099AZ  20213.11580567 -.00000149  00000-0 -82033-5 0  9995
2 43805  97.7020 284.0736 0016308  49.2739 310.9895 14.95643022 90470
2018-099BA              
1 43806U 18099BA  20213.15535852  .00000432  00000-0  44177-4 0  9996
2 43806  97.7007 283.9694 0016143  48.2495 312.0103 14.95441734 90169
EUCROPIS                
1 43807U 18099BB  20212.76677621  .00000046  00000-0  92851-5 0  9998
2 43807  97.7044 283.9135 0013996  48.0620 312.1791 14.95625817 89823
POLAR SCOUT YUKON       
1 43808U 18099BC  20212.75092482  .00000683  00000-0  66456-4 0  9992
2 43808  97.7047 283.9699 0013612  57.3104 302.9428 14.95686173 90117
CENTAURI-1              
1 43809U 18099BD  20212.76379996  .00000129  00000-0  16822-4 0  9999
2 43809  97.7029 283.8609 0016482  51.6344 308.6355 14.95592288 90114
2018-099BE              
1 43810U 18099BE  20213.10661040 -.00000305  00000-0 -22252-4 0  9996
2 43810  97.6928 283.4699 0015549  45.3065 314.9419 14.95546935 90020
NEXTSAT-1               
1 43811U 18099BF  20213.16821486 -.00000096  00000-0 -34414-5 0  9996
2 43811  97.7028 284.2448 0014218  47.6626 312.5797 14.95560554 90031
BLACKSKY GLOBAL 2       
1 43812U 18099BG  20212.83737116 -.00000221  00000-0 -14745-4 0  9990
2 43812  97.7009 283.8022 0014809  51.4608 308.7938 14.95534345 90014
BRIO                    
1 43813U 18099BH  20212.83518731  .00000200  00000-0  22980-4 0  9996
2 43813  97.7134 284.7375 0014791  41.1300 319.1033 14.95888133 90132
PW-SAT2                 
1 43814U 18099BJ  20213.17672536  .00021817  00000-0  11955-2 0  9994
2 43814  97.7930 294.8500 0005135  81.6319 278.5493 15.14209065 90333
FALCONSAT-6             
1 43815U 18099BK  20212.73683038  .00000241  00000-0  27433-4 0  9993
2 43815  97.7062 283.4256 0015496  52.4947 307.7679 14.94738728 90078
SPACEBEE-7              
1 43816U 18099BL  20213.14910624  .00000715  00000-0  69282-4 0  9998
2 43816  97.7056 284.4220 0014240  39.3854 320.8399 14.95724863 90027
SPACEBEE-5              
1 43817U 18099BM  20212.68282159  .00001211  00000-0  11191-3 0  9999
2 43817  97.7111 284.4799 0013927  41.7278 318.5003 14.96375898 89978
SPACEBEE-6              
1 43818U 18099BN  20212.83922142  .00000041  00000-0  88437-5 0  9999
2 43818  97.7074 284.2781 0014243  39.6401 320.5858 14.95928732 90133
EXCITE                  
1 43819U 18099BP  20213.12617515 -.00000064  00000-0 -55285-6 0  9991
2 43819  97.7009 284.0765 0014496  51.0523 309.1988 14.95465974 90057
SEAHAWK-1               
1 43820U 18099BQ  20213.09663721  .00000142  00000-0  17918-4 0  9993
2 43820  97.6986 283.8657 0013645  42.3899 317.8374 14.95622734 89864
FLOCK 3S-2              
1 43821U 18099BR  20213.15707603  .00000475  00000-0  47820-4 0  9993
2 43821  97.7046 284.3172 0015351  49.6528 310.6031 14.95627786 90175
GEO-KOMPSAT-2A          
1 43823U 18100A   20212.00458564 -.00000357  00000-0  00000-0 0  9995
2 43823   0.0051 121.1129 0002494 344.8761 331.9990  1.00270677  6063
GSAT-11                 
1 43824U 18100B   20213.04565851 -.00000092  00000-0  00000+0 0  9994
2 43824   0.1059 275.8994 0008294 197.3113 286.3349  1.00271365  6107
SAUDISAT 5A             
1 43831U 18102A   20213.22914936  .00000733  00000-0  51539-4 0  9995
2 43831  97.5690 288.5660 0014444  14.2123  60.4081 15.08070634 90734
2018-102B               
1 43832U 18102B   20213.20222738  .00000669  00000-0  46026-4 0  9999
2 43832  97.5727 289.3682 0015255  19.1578  59.0628 15.09187596 90769
SAUDISAT 5B             
1 43833U 18102C   20213.18799362 -.00000511  00000-0 -28654-4 0  9994
2 43833  97.5687 288.9219 0015853  21.8227  58.2245 15.08502786 90746
2018-102D               
1 43834U 18102D   20213.19456659 -.00000384  00000-0 -20415-4 0  9990
2 43834  97.5707 289.1577 0014952  23.0236  56.0070 15.08692428 90771
2018-102E               
1 43835U 18102E   20213.19484023  .00000053  00000-0  73733-5 0  9990
2 43835  97.5707 289.2956 0015711  22.2130  58.5645 15.08981023 90780
2018-102F               
1 43836U 18102F   20213.15576497  .00000872  00000-0  58921-4 0  9994
2 43836  97.5731 289.5145 0015662  23.4177  36.3662 15.09106564 90761
2018-102G               
1 43837U 18102G   20213.17558995  .00000206  00000-0  16850-4 0  9996
2 43837  97.5723 289.7103 0015892  27.2240  55.0308 15.09391032 90780
2018-102H               
1 43838U 18102H   20213.10473377  .00000565  00000-0  39284-4 0  9994
2 43838  97.5708 289.5098 0017192  27.1035  34.2661 15.09366476 90775
2018-102J               
1 43839U 18102J   20213.23091927  .00000667  00000-0  45442-4 0  9992
2 43839  97.5821 290.6832 0017279  30.0756  34.7439 15.09568016 90817
2018-102K               
1 43840U 18102K   20213.24097968  .00000265  00000-0  20330-4 0  9999
2 43840  97.5699 289.8695 0017437  28.3469  37.5654 15.09827932 90811
2018-102L               
1 43841U 18102L   20213.17560152  .00000486  00000-0  34001-4 0  9997
2 43841  97.5792 290.5033 0017229  30.5476  51.9309 15.09796822 90802
2018-102M               
1 43842U 18102M   20213.14664763  .00001165  00000-0  74889-4 0  9991
2 43842  97.5379 287.6793 0010169  58.8181   3.2262 15.10314220 90826
TOMSAT R3 (AEROCUBE 11B)
1 43849U 18104A   20212.81139724  .00000896  00000-0  36919-4 0  9999
2 43849  85.0376 146.7702 0015082 269.2894  90.6620 15.22439910 90123
SHIELDS 1               
1 43850U 18104B   20212.87556654  .00000383  00000-0  15259-4 0  9997
2 43850  85.0317 146.6565 0012512 271.3119  88.6689 15.21418338 90071
ELECTRON KICK STAGE R/B 
1 43851U 18104C   20212.86123190  .00001012  00000-0  41350-4 0  9993
2 43851  85.0383 146.5902 0013777 259.7542 100.2147 15.22899530 90138
STF-1                   
1 43852U 18104D   20212.82525941  .00000866  00000-0  36844-4 0  9998
2 43852  85.0358 147.2588 0012313 289.3653  70.6257 15.21353428 90048
2018-104E               
1 43853U 18104E   20212.83203518  .00002260  00000-0  89833-4 0  9999
2 43853  85.0336 145.7416 0014337 258.7632 101.2000 15.24607682 90171
2018-104F               
1 43854U 18104F   20212.82875369  .00001058  00000-0  42719-4 0  9994
2 43854  85.0316 145.8281 0015372 254.6517 105.3027 15.23357336 90124
CHOMPTT                 
1 43855U 18104G   20212.99831441  .00000741  00000-0  29669-4 0  9993
2 43855  85.0398 146.4068 0015170 249.3573 110.6043 15.23033003 90156
ISX (CP11)              
1 43856U 18104H   20212.97655613  .00000717  00000-0  30575-4 0  9998
2 43856  85.0356 147.3666 0012456 295.1037  64.8911 15.20891956 90052
DAVINCI                 
1 43857U 18104J   20212.86012645  .00001820  00000-0  75820-4 0  9997
2 43857  85.0353 146.6780 0013264 281.3460  78.6292 15.22902156 90102
2018-104K               
1 43858U 18104K   20212.82928598  .00000703  00000-0  29339-4 0  9990
2 43858  85.0349 146.9785 0013848 281.0582  78.9102 15.21574112 90060
2018-104L               
1 43859U 18104L   20212.87671566  .00000694  00000-0  28952-4 0  9995
2 43859  85.0324 146.7164 0010908 274.1968  85.8027 15.21625481 90078
SHFT 1 (GOERGEN)        
1 43860U 18104M   20213.16646861  .00002331  00000-0  95666-4 0  9996
2 43860  85.0362 146.3344 0010024 266.2360  93.7737 15.23601972 90173
TOMSAT EAGLESCOUT       
1 43861U 18104N   20213.07488090  .00000841  00000-0  34770-4 0  9998
2 43861  85.0456 147.3363 0013929 268.2893  91.6753 15.22232164 90133
GSAT-7A                 
1 43864U 18105A   20212.78112021  .00000011  00000-0  00000+0 0  9990
2 43864   0.1009 278.1123 0002401 179.6160 195.1536  1.00274111  5932
COSMOS 2533             
1 43867U 18107A   20212.78242875 -.00000053  00000-0  00000+0 0  9993
2 43867   0.0219 103.8523 0000919 155.9342  40.7213  1.00272628  5899
HONGYUN 1               
1 43871U 18108A   20213.11693247  .00000019  00000-0  10000-3 0  9996
2 43871  99.9150 225.5197 0011594 284.9758  75.0097 13.50542078 79258
NAVSTAR 77 (USA 289)    
1 43873U 18109A   20212.73535329  .00000023  00000-0  00000+0 0  9992
2 43873  55.0113 176.9952 0009142 218.7091 216.1373  2.00565836 11959
TJS-3                   
1 43874U 18110A   20212.70048003 -.00000012  00000-0  00000-0 0  9990
2 43874   0.0567 237.6427 0007089 298.4357 197.8095  1.00272485  5888
KANOPUS-V 5             
1 43876U 18111A   20212.83373881  .00000101  00000-0  80090-5 0  9992
2 43876  97.4413 120.3237 0001581  99.6305 260.5108 15.19169568 86280
KANOPUS-V 6             
1 43877U 18111B   20212.87315985  .00000112  00000-0  85188-5 0  9993
2 43877  97.4411 120.4036 0001384 120.4398 239.6973 15.19163513 88321
ISAT                    
1 43879U 18111D   20212.83311956  .00000619  00000-0  59856-4 0  9999
2 43879  97.6992 119.5612 0014896  97.7418 262.5496 14.96268905 84965
UWE-4                   
1 43880U 18111E   20212.87326763  .00000145  00000-0  17788-4 0  9999
2 43880  97.6997 119.8932 0013699  99.4909 260.7862 14.96512516 86985
D-STAR ONE (SPARROW)    
1 43881U 18111F   20212.72060940 -.00000307  00000-0 -21885-4 0  9991
2 43881  97.7010 119.8216 0013940  96.6994 263.5815 14.96558987 86969
LEMUR-2-CHRISTINAHOLT   
1 43882U 18111G   20212.81179233  .00000398  00000-0  39692-4 0  9991
2 43882  97.7015 120.2236 0013097  97.9003 262.3706 14.96923084 86994
LEMUR-2-TINYKEV         
1 43883U 18111H   20212.85677917 -.00000248  00000-0 -16481-4 0  9995
2 43883  97.7023 120.3307 0013157  96.5268 263.7452 14.96991525 87000
LEMUR-2-REMY-COLTON     
1 43884U 18111J   20213.10863695  .00000384  00000-0  38280-4 0  9995
2 43884  97.7038 120.6597 0013423  95.3391 264.9362 14.97070243 85053
LEMUR-2-GUSTAVO         
1 43885U 18111K   20212.84072441  .00000479  00000-0  46575-4 0  9998
2 43885  97.7034 120.3923 0013320  95.3464 264.9278 14.97018168 87008
LEMUR-2-ZO              
1 43886U 18111L   20212.80782472  .00000224  00000-0  24431-4 0  9992
2 43886  97.7027 120.4706 0012728  95.5718 264.6955 14.97098360 85005
LEMUR-2-NATALIEMURRAY   
1 43887U 18111M   20212.82994306  .00000925  00000-0  84837-4 0  9990
2 43887  97.7037 120.5981 0013418  94.4947 265.7808 14.97223474 87010
LEMUR-2-SARAHBETTYBOO   
1 43888U 18111N   20212.80878048  .00000242  00000-0  25870-4 0  9997
2 43888  97.7037 120.6815 0012825  93.6172 266.6516 14.97263038 87016
LEMUR-2-DAISY-HARPER    
1 43889U 18111P   20212.84772946  .00000028  00000-0  73954-5 0  9991
2 43889  97.7050 120.7829 0013213  91.8566 268.4169 14.97356822 87015
GRUS-1A                 
1 43890U 18111Q   20213.04941514 -.00000203  00000-0 -14247-4 0  9991
2 43890  97.6881 117.0652 0004957 130.2706 229.8948 14.92935913 86845
2018-111R               
1 43891U 18111R   20213.15728897  .00000101  00000-0  72056-5 0  9999
2 43891  97.2593 110.3714 0026593   0.8702 359.2577 15.23071526 88591
FLOCK 3K-3              
1 43892U 18111S   20212.97129955  .00000860  00000-0  36187-4 0  9995
2 43892  97.2620 111.7231 0023163 358.5535   1.5632 15.25803271 88668
FLOCK 3K-4              
1 43893U 18111T   20212.83422915  .00001565  00000-0  63591-4 0  9995
2 43893  97.2619 111.6022 0022968 359.0612   1.0580 15.25812371 88644
FLOCK 3K-1              
1 43894U 18111U   20213.02493071  .00001432  00000-0  58399-4 0  9992
2 43894  97.2613 111.7598 0022972 358.4930   1.6236 15.25813285 88671
FLOCK 3K-2              
1 43895U 18111V   20212.95347443  .00001538  00000-0  62496-4 0  9995
2 43895  97.2617 111.7276 0023317 358.6474   1.4697 15.25812761 88664
FLOCK 3K-6              
1 43896U 18111W   20212.81639897  .00000820  00000-0  34689-4 0  9994
2 43896  97.2617 111.6051 0021492 356.4709   3.6375 15.25800330 88648
FLOCK 3K-5              
1 43899U 18111Z   20212.74495986  .00002155  00000-0  86575-4 0  9992
2 43899  97.2621 111.5708 0021590 356.1605   3.9464 15.25803386 88630
FLOCK 3K-8              
1 43901U 18111AB  20212.67329866  .00000882  00000-0  37063-4 0  9994
2 43901  97.2613 111.4677 0021750 356.6204   3.4884 15.25804105 88627
FLOCK 3K-7              
1 43902U 18111AC  20212.66742624  .00000727  00000-0  31048-4 0  9990
2 43902  97.2615 111.4906 0021890 355.9284   4.1772 15.25801339 88620
FLOCK 3K-11             
1 43904U 18111AE  20212.85235998  .00000729  00000-0  31155-4 0  9995
2 43904  97.2618 111.7163 0021407 354.5199   5.5802 15.25801733 88657
FLOCK 3K-10             
1 43905U 18111AF  20212.84645454  .00000733  00000-0  31293-4 0  9995
2 43905  97.2618 111.7266 0021098 354.4265   5.6735 15.25804386 88654
FLOCK 3K-9              
1 43906U 18111AG  20212.85827911  .00001384  00000-0  56614-4 0  9994
2 43906  97.2618 111.7004 0021548 354.6320   5.4684 15.25802134 88659
ZACUBE-2                
1 43907U 18111AH  20213.09825384  .00000324  00000-0  15885-4 0  9992
2 43907  97.2613 111.5396 0021322 354.3641   5.7354 15.24625361 88662
LUME 1                  
1 43908U 18111AJ  20212.81228518  .00000767  00000-0  33286-4 0  9994
2 43908  97.2614 111.4635 0021094 354.9358   5.1664 15.25107551 88638
YUNHAI-2 1              
1 43909U 18112A   20213.06542879 -.00000236  00000-0 -30150-4 0  9993
2 43909  50.0109 145.6161 0000429 280.3106  79.7761 14.27677208 84273
YUNHAI-2 2              
1 43910U 18112B   20213.15795253 -.00000054  00000-0  43207-4 0  9995
2 43910  50.0113 208.6733 0005343 249.3258 110.7084 14.27645461 83402
YUNHAI-2 3              
1 43911U 18112C   20213.10961817 -.00000178  00000-0 -66056-5 0  9998
2 43911  50.0142  88.2760 0000652 127.4419 232.6565 14.27693709 85071
YUNHAI-2 4              
1 43912U 18112D   20213.01467150 -.00000173  00000-0 -45875-5 0  9991
2 43912  50.0132  28.9598 0015698  13.7648 346.3692 14.27637521 80729
YUNHAI-2 5              
1 43913U 18112E   20212.83236713 -.00000024  00000-0  55319-4 0  9997
2 43913  50.0082 269.6639 0005072  29.3967 330.7232 14.27630964 82493
HONGYAN 1 (CHONGQING)   
1 43914U 18112F   20213.04069962 -.00000129  00000-0  00000+0 0  9990
2 43914  50.0063 213.4688 0006899 203.2722 156.7844 13.43702931 77944
YUNHAI-2 6              
1 43915U 18112G   20213.06450395 -.00000202  00000-0 -16611-4 0  9990
2 43915  50.0142 328.8163 0007415 358.6335   1.4558 14.27620875 81630
TJS-3 AKM               
1 43917U 18110C   20212.90204663  .00000040  00000-0  00000-0 0  9996
2 43917   0.0547 190.9850 0003060 288.6014 213.2902  1.00277064  5823
CHINASAT 2D (ZX 2D)     
1 43920U 19001A   20212.64772314 -.00000351  00000-0  00000+0 0  9999
2 43920   0.0536 236.8172 0001245 359.0811  76.0444  1.00271504  5811
IRIDIUM 180             
1 43922U 19002A   20213.14933996  .00000061  00000-0  14676-4 0  9999
2 43922  86.4012 170.8510 0002345  56.6967 303.4453 14.34216517 81294
IRIDIUM 176             
1 43923U 19002B   20212.85527847  .00000106  00000-0  12914-4 0  9994
2 43923  86.6148 171.0009 0002431  81.3253 278.8240 14.72255209 83319
IRIDIUM 168             
1 43924U 19002C   20213.14299411  .00000052  00000-0  11576-4 0  9994
2 43924  86.4014 170.8700 0002113  78.4810 281.6622 14.34215893 81236
IRIDIUM 173             
1 43925U 19002D   20212.85124843  .00000061  00000-0  14672-4 0  9998
2 43925  86.4011 170.9680 0002219  80.2204 279.9242 14.34217995 81184
IRIDIUM 169             
1 43926U 19002E   20212.71784806  .00000068  00000-0  15705-4 0  9990
2 43926  86.4244 202.5669 0004414 183.0862 177.0310 14.38610498 81489
IRIDIUM 172             
1 43927U 19002F   20212.84490404  .00000064  00000-0  15688-4 0  9999
2 43927  86.4010 170.9719 0002205  77.6860 282.4582 14.34217580 81187
IRIDIUM 175             
1 43928U 19002G   20212.84909786  .00000109  00000-0  13397-4 0  9993
2 43928  86.6130 170.8868 0003253  93.4910 266.6679 14.72255271 83301
IRIDIUM 171             
1 43929U 19002H   20212.82586173  .00000073  00000-0  19146-4 0  9991
2 43929  86.4021 171.0471 0002096  79.9906 280.1526 14.34215247 81172
IRIDIUM 170             
1 43930U 19002J   20212.86141009  .00000104  00000-0  12583-4 0  9995
2 43930  86.6148 171.0052 0002727  81.9472 278.2054 14.72256459 83328
IRIDIUM 167             
1 43931U 19002K   20212.81952722  .00000079  00000-0  21154-4 0  9998
2 43931  86.4011 170.9858 0002219  60.6153 299.5264 14.34215525 81171
RAPIS-1                 
1 43932U 19003A   20212.68942690  .00000225  00000-0  18351-4 0  9996
2 43932  97.2317 253.9526 0002027 314.6517  45.4547 15.07929015 84631
ORIGAMISAT-1            
1 43933U 19003B   20213.10682616  .00000555  00000-0  27000-4 0  9991
2 43933  97.2269 261.1670 0019640  88.5189 271.8297 15.22162827 85174
RISESAT                 
1 43934U 19003C   20213.15841674  .00000260  00000-0  14230-4 0  9992
2 43934  97.2263 261.2372 0018463  80.7870 344.3637 15.22053938 85189
MICRODRAGON             
1 43935U 19003D   20212.88881431  .00000481  00000-0  23542-4 0  9998
2 43935  97.2281 261.3890 0020009  82.2254 278.1253 15.22566321 85169
NEXUS (FO-99)           
1 43937U 19003F   20212.88077232  .00001018  00000-0  43906-4 0  9999
2 43937  97.2232 262.3149 0025703  72.6746 287.7300 15.24470903 85251
ALE-1                   
1 43938U 19003G   20212.86765364  .00002696  00000-0  11043-3 0  9997
2 43938  97.2317 262.6548 0026491  75.7775 284.6403 15.24816064 85244
AOBA VELOX-IV           
1 43940U 19003J   20213.14240233  .00001501  00000-0  62171-4 0  9998
2 43940  97.2248 262.9169 0025454  70.6106 289.7880 15.25138851 85306
LINGQUE 1A              
1 43942U 19005A   20213.21306542  .00005320  00000-0  26942-3 0  9994
2 43942  97.5114 310.2099 0018020 169.4522 247.2428 15.17284299 84318
JILIN-01-09             
1 43943U 19005B   20213.16281179 -.00000120  00000-0 -32026-5 0  9994
2 43943  97.5113 307.9165 0015109 162.4560 287.8185 15.12092759 84149
XIAOXIANG 3             
1 43944U 19005C   20213.21501295  .00000676  00000-0  42331-4 0  9998
2 43944  97.5105 308.1191 0013299 161.3912 259.9187 15.12625174 84156
JILIN-01-10             
1 43946U 19005E   20213.25090733  .00000583  00000-0  37489-4 0  9999
2 43946  97.5111 308.0577 0020648 158.2314 228.1744 15.12098073 84091
CATSAT-2                
1 44029U 98067PV  20212.84079539  .00013836  00000-0  13592-3 0  9995
2 44029  51.6386  95.9463 0002838 244.3226 115.7477 15.66349531 85323
DELPHINI                
1 44030U 98067PW  20212.76449825  .00016015  00000-0  14915-3 0  9999
2 44030  51.6341  94.5498 0002698 237.7955 122.2780 15.67542817 85309
UNITE                   
1 44031U 98067PX  20213.01540703  .00007626  00000-0  91688-4 0  9997
2 44031  51.6377 102.3246 0002222 228.7499 131.3304 15.61954966 85224
CATSAT-1                
1 44033U 98067PZ  20212.90449801  .00016518  00000-0  16110-3 0  9999
2 44033  51.6390  95.6292 0002744 243.0552 117.0164 15.66361845 85245
HELLAS-SAT 4 & SGS-1    
1 44034U 19007A   20212.78521564  .00000138  00000-0  00000+0 0  9998
2 44034   0.0651 120.1168 0006276  20.6612 129.7647  1.00268226  5835
GSAT-31                 
1 44035U 19007B   20212.77815418  .00000111  00000-0  00000+0 0  9993
2 44035   0.0187 323.1193 0001100 204.7876 108.9661  1.00273198  5436
2018-092C               
1 44041U 18092C   20213.11792264  .00001259  00000-0  43625-4 0  9994
2 44041  51.6407 183.7929 0003851 134.1714 327.4460 15.37945008 82867
2018-092D               
1 44042U 18092D   20213.14701826  .00000294  00000-0  18209-4 0  9990
2 44042  51.6400 183.9991 0003626 132.5799 317.8391 15.37830933 95823
CHEFSAT 2               
1 44044U 18092E   20213.13809971  .00003149  00000-0  93195-4 0  9990
2 44044  51.6380 187.8062 0011099 120.5922 239.6158 15.38014020 82057
EGYPTSAT-A (MISRSAT-A)  
1 44047U 19008A   20213.10468265  .00000006  00000-0  87293-5 0  9997
2 44047  98.0125 276.6997 0001545  97.2263 262.9126 14.72300356 77313
NUSANTARA SATU          
1 44048U 19009A   20212.60955941 -.00000249  00000-0  00000+0 0  9990
2 44048   0.0029  30.0960 0002200  92.4809 191.5634  1.00271075  5239
ONEWEB-0012             
1 44057U 19010A   20212.91667824 -.00043498  00000-0 -10585+0 0  9999
2 44057  87.9208 325.6946 0002096  98.3103 126.0419 13.21832782 68624
ONEWEB-0010             
1 44058U 19010B   20212.91667824 -.00043792  00000-0 -10656+0 0  9996
2 44058  87.9211 325.6960 0002024  98.6749 117.9300 13.21836910  1706
ONEWEB-0008             
1 44059U 19010C   20212.91667824 -.00043166  00000-0 -10504+0 0  9994
2 44059  87.9214 325.6968 0001999  97.7255 134.2812 13.21831836  1702
ONEWEB-0007             
1 44060U 19010D   20212.91667824 -.00044045  00000-0 -10718+0 0  9990
2 44060  87.9209 325.6952 0001935 105.2347 104.0107 13.21836846  1706
ONEWEB-0006             
1 44061U 19010E   20212.91667824 -.00044254  00000-0 -10769+0 0  9999
2 44061  87.9215 325.6969 0001952  97.5839 104.4204 13.21837996  1710
ONEWEB-0011             
1 44062U 19010F   20212.91667824 -.00044426  00000-0 -10811+0 0  9999
2 44062  87.9219 325.6995 0002022  96.9537  97.5872 13.21837082  1709
S5                      
1 44065U 19009D   20211.77941045  .00000083  00000-0  00000-0 0  9992
2 44065   1.1208  94.4841 0003246  34.5046 324.9861  0.99334574  5103
CHINASAT 6C (ZX 6C)     
1 44067U 19012A   20212.64772104 -.00000351  00000-0  99999+0 0  9996
2 44067   0.0325 239.4405 0001035 157.0314 275.3890  1.00268489  5197
WGS 10 (USA 291)        
1 44071U 19014A   20212.83688965  .00000124  00000-0  00000+0 0  9997
2 44071   0.0975  99.1367 0000516  15.0513 160.0079  1.00271616  5070
PRISMA                  
1 44072U 19015A   20213.19616204 -.00000053  00000-0  00000+0 0  9998
2 44072  97.8589 287.4618 0001319  83.7501 276.3860 14.83676016 73700
R3D2                    
1 44073U 19016A   20212.98225868  .00011731  00000-0  17802-3 0  9994
2 44073  39.5149 318.5424 0008372 161.5650 198.5458 15.54224694 76007
TIANLIAN 2-01           
1 44076U 19017A   20212.60377212 -.00000147  00000-0  00000-0 0  9997
2 44076   1.9071 285.8812 0034012 213.1683 106.9726  1.00269371  5071
EMISAT                  
1 44078U 19018A   20213.15155210 -.00000010  00000-0  95446-5 0  9995
2 44078  98.3227 269.7743 0018043 219.2518 140.7366 14.43730921 70264
FLOCK 4A-1              
1 44079U 19018B   20213.10883284  .00000693  00000-0  32421-4 0  9999
2 44079  97.4058 273.1334 0012819  18.5051 341.6649 15.23006186 74043
FLOCK 4A-2              
1 44080U 19018C   20213.13053508  .00001769  00000-0  78394-4 0  9996
2 44080  97.4062 273.1261 0013120  18.8724 341.2996 15.22866182 74034
FLOCK 4A-3              
1 44081U 19018D   20213.11339246  .00001459  00000-0  65042-4 0  9993
2 44081  97.4059 273.1361 0013247  20.1561 340.0196 15.22950916 74046
FLOCK 4A-4              
1 44082U 19018E   20213.09343324  .00001591  00000-0  70543-4 0  9992
2 44082  97.4064 273.1788 0013382  20.8112 339.3667 15.22993875 74039
ASTROCAST 0.2           
1 44083U 19018F   20213.15227458  .00000556  00000-0  27656-4 0  9996
2 44083  97.4182 273.5871 0013793  26.1460 334.0470 15.21655552 74028
LEMUR-2-JOHANLORAN      
1 44084U 19018G   20213.15637425  .00002061  00000-0  89655-4 0  9996
2 44084  97.4077 273.4429 0013612  22.8780 337.3060 15.23308652 74050
LEMUR-2-BEAUDACIOUS     
1 44085U 19018H   20212.71085849  .00001609  00000-0  71252-4 0  9996
2 44085  97.4074 272.9678 0013784  25.1244 335.0660 15.23018925 73993
LEMUR-2-ELHAM           
1 44086U 19018J   20212.84356034  .00002051  00000-0  88672-4 0  9994
2 44086  97.4084 273.2848 0013782  24.8040 335.3856 15.23534100 74029
LEMUR-2-VICTOR-ANDREW   
1 44087U 19018K   20212.83149704  .00001961  00000-0  84804-4 0  9990
2 44087  97.4085 273.3015 0013619  25.5992 334.5917 15.23569324 74026
FLOCK 4A-17             
1 44088U 19018L   20213.09868223  .00000993  00000-0  45060-4 0  9999
2 44088  97.4070 273.3561 0014005  24.8820 335.3089 15.23051030 74040
FLOCK 4A-18             
1 44089U 19018M   20213.10235896  .00000783  00000-0  36185-4 0  9991
2 44089  97.4071 273.3623 0014062  25.4735 334.7192 15.23043305 74040
FLOCK 4A-19             
1 44090U 19018N   20213.13610000  .00001442  00000-0  64041-4 0  9995
2 44090  97.4070 273.4541 0013971  26.1378 334.0561 15.23079050 74050
FLOCK 4A-7              
1 44093U 19018R   20213.12590748  .00000787  00000-0  36397-4 0  9997
2 44093  97.4081 273.5225 0011768  30.6885 329.5038 15.23042819 74051
FLOCK 4A-6              
1 44094U 19018S   20213.11765486  .00001099  00000-0  49668-4 0  9992
2 44094  97.4077 273.5064 0011770  31.6324 328.5618 15.23016396 74065
FLOCK 4A-5              
1 44095U 19018T   20213.09149489  .00000686  00000-0  32077-4 0  9996
2 44095  97.4077 273.5475 0011892  32.1597 328.0363 15.23050799 74051
FLOCK 4A-11             
1 44096U 19018U   20213.14877571  .00000662  00000-0  31109-4 0  9992
2 44096  97.4079 273.6223 0012011  33.5653 326.6342 15.23015817 74062
FLOCK 4A-10             
1 44097U 19018V   20213.15255697  .00002265  00000-0  99117-4 0  9997
2 44097  97.4078 273.6013 0012172  34.1734 326.0284 15.23016829 74067
FLOCK 4A-9              
1 44098U 19018W   20213.14495859  .00000933  00000-0  42601-4 0  9999
2 44098  97.4080 273.6330 0012288  35.0345 325.1697 15.23014705 74068
FLOCK 4A-16             
1 44099U 19018X   20213.14101474  .00001106  00000-0  49952-4 0  9996
2 44099  97.4073 273.6033 0012431  35.6378 324.5686 15.23011694 74068
FLOCK 4A-15             
1 44100U 19018Y   20213.12299586  .00000580  00000-0  27563-4 0  9998
2 44100  97.4076 273.6297 0012615  36.9924 323.2180 15.23056638 74074
FLOCK 4A-13             
1 44102U 19018AA  20213.12945366  .00000870  00000-0  39898-4 0  9990
2 44102  97.4072 273.6017 0013129  37.6055 322.6098 15.23020419 74064
AISTECHSAT-3            
1 44103U 19018AB  20213.11525230  .00000626  00000-0  29387-4 0  9995
2 44103  97.4055 273.7469 0014270  40.3055  17.3184 15.23220046 74081
AIS & APRS & ARIS & R/B 
1 44104U 19018AC  20212.76284767  .00000698  00000-0  24651-4 0  9991
2 44104  97.4617 282.7043 0059497  60.0072 300.7056 15.31122013 74432
BLUEWALKER 1            
1 44105U 19018AD  20213.13629559  .00000985  00000-0  33320-4 0  9998
2 44105  97.4720 283.9049 0059086  63.5267 297.2018 15.31527045 74505
FLOCK 4A-12             
1 44108U 19018AE  20213.18360481  .00000631  00000-0  29751-4 0  9993
2 44108  97.4056 273.5915 0011941  31.7134  30.6639 15.23041378 73974
M6P                     
1 44109U 19018AF  20212.72704605  .00001678  00000-0  55612-4 0  9990
2 44109  97.4624 282.3240 0060016  58.5802 302.1289 15.31037204 74208
O3B FM20                
1 44112U 19020A   20211.78807804 -.00000024  00000-0  00000-0 0  9999
2 44112   0.0432   9.1140 0003325 123.4991 227.4121  5.00115577 24174
O3B FM19                
1 44113U 19020B   20211.77554251 -.00000024  00000-0  00000-0 0  9994
2 44113   0.0504  27.5054 0004202  95.2973 237.2351  5.00116025 24155
O3B FM17                
1 44114U 19020C   20211.89527842 -.00000025  00000-0  00000-0 0  9995
2 44114   0.0431   7.6780 0003094 121.1470 231.1999  5.00115970 24169
O3B FM18                
1 44115U 19020D   20212.04191634 -.00000025  00000-0  00000-0 0  9990
2 44115   0.0429   9.4810 0002908 118.1188 232.4238  5.00115502 24178
ARABSAT-6A              
1 44186U 19021A   20212.05683994  .00000141  00000-0  00000+0 0  9992
2 44186   0.0568 331.4520 0004035 241.9278 145.6767  1.00273406  4651
BEIDOU-3 IGSO-1         
1 44204U 19023A   20212.50379726 -.00000219  00000-0  10000-3 0  9992
2 44204  55.5559  60.4384 0017128 179.4708   3.6191  1.00281485  4823
TIANHUI 2-01A           
1 44207U 19024A   20213.17796594  .00000250  00000-0  16065-4 0  9998
2 44207  97.4523 217.8985 0001553  79.3411 351.0949 15.16768621 69456
TIANHUI 2-01B           
1 44209U 19024C   20213.11240999  .00000157  00000-0  11343-4 0  9997
2 44209  97.4516 217.8352 0001559 104.9837 327.7058 15.16765901 69449
AFOTEC-1 (FALCON ODE)   
1 44225U 19026A   20212.79978955  .00000513  00000-0  30802-4 0  9997
2 44225  40.0191 106.7871 0006431 347.7878  12.2766 15.20869720 68897
SPARC-1                 
1 44226U 19026B   20212.82762218  .00001128  00000-0  55753-4 0  9990
2 44226  40.0191 101.6333 0010515 353.5867   6.4798 15.22310377 68954
HARBINGER (ICEYE-X3)    
1 44229U 19026E   20212.79178321  .00000556  00000-0  32525-4 0  9991
2 44229  40.0192 105.8227 0007430 343.0492  17.0060 15.21095711 68902
BEIDOU-2 G8             
1 44231U 19027A   20212.83744913 -.00000261  00000-0  00000-0 0  9999
2 44231   0.8879 298.4159 0006996 266.9180 189.5961  1.00269283  4552
RISAT-2B                
1 44233U 19028A   20213.01623673  .00000189  00000-0  21763-4 0  9993
2 44233  37.0036  81.2677 0007175 293.0470  66.9527 14.99029694 65574
STARLINK-31             
1 44235U 19029A   20213.25001157  .00898142  00000-0  63076-2 0  9994
2 44235  52.9918 357.4117 0001255  73.0127 257.0744 15.71371990 67044
STARLINK-22             
1 44236U 19029B   20213.25001157  .00742034  26924-3  13018-2 0  9990
2 44236  53.0027 309.5469 0005705 288.8447 105.3887 16.01493814 67712
STARLINK-23             
1 44237U 19029C   20213.25001157  .00898515  00000-0  63308-2 0  9998
2 44237  52.9964 357.1702 0001204 100.8722 199.6845 15.71293006 65938
STARLINK-25             
1 44239U 19029E   20213.25001157  .00897457  00000-0  65163-2 0  9995
2 44239  53.0048 356.7830 0001164  65.1274 103.0860 15.70573679 65866
STARLINK-26             
1 44240U 19029F   20212.66213670  .00030107  00000-0  61681-3 0  9999
2 44240  53.0089 318.8672 0001945 130.4146 229.7029 15.45847827 65636
STARLINK-27             
1 44241U 19029G   20213.25001157  .00897900  00000-0  63266-2 0  9993
2 44241  53.0096 357.6313 0001247  73.8903 280.6033 15.71294580 65928
STARLINK-28             
1 44242U 19029H   20213.19185252  .00857589  38297-3  12725-2 0  9991
2 44242  52.9955 303.3229 0005720 272.4333  87.6515 16.04325677 66660
STARLINK-29             
1 44243U 19029J   20213.25001157  .00076266  00000-0  28833-3 0  9991
2 44243  52.9991 253.4279 0002078  62.9387 228.4681 15.87048117 67363
STARLINK-30             
1 44244U 19029K   20213.25001157  .00898251  00000-0  63285-2 0  9997
2 44244  52.9976 357.0780 0001101  86.7423 153.8229 15.71294864 65896
STARLINK-21             
1 44245U 19029L   20213.25001157  .00898894  00000-0  63342-2 0  9995
2 44245  52.9994 357.9688 0002152 149.4469 175.0967 15.71293109 65893
STARLINK-33             
1 44247U 19029N   20212.91667824  .00753174  27588-3  13590-2 0  9992
2 44247  53.0061 305.8940 0001418  42.4621  95.4714 16.01043899 66632
STARLINK-34             
1 44248U 19029P   20212.91667824 -.00009812  00000-0 -35430-4 0  9994
2 44248  53.0013 314.7239 0003280 328.1227 200.0864 15.86297890 66426
STARLINK-61             
1 44249U 19029Q   20212.77143622  .00000985  00000-0  62710-4 0  9995
2 44249  53.0038 359.1555 0008110 132.9668 227.2007 15.18374510 64928
STARLINK-36             
1 44250U 19029R   20213.25001157  .00898336  00000-0  63295-2 0  9999
2 44250  52.9974 357.1697 0001238  65.5361 205.0155 15.71293765 65882
STARLINK-37             
1 44251U 19029S   20213.25001157  .00898550  00000-0  63313-2 0  9999
2 44251  52.9787 356.0962 0001165  95.9967 180.5734 15.71291320 65858
STARLINK-71             
1 44252U 19029T   20213.25001157  .00022933  00000-0  13206-2 0  9990
2 44252  53.0004  11.0183 0001853  83.5317  38.4037 15.12343871 65651
STARLINK-39             
1 44253U 19029U   20213.25001157  .00897904  00000-0  63267-2 0  9991
2 44253  53.0087 357.5852 0001227  80.3506 262.1652 15.71294193 65908
STARLINK-32             
1 44254U 19029V   20213.25001157  .00898706  00000-0  63320-2 0  9993
2 44254  52.9987 357.1613 0001232 106.9262 187.5814 15.71293251 65877
STARLINK-66             
1 44255U 19029W   20213.31799941  .00847975  37410-3  12566-2 0  9992
2 44255  53.0101 304.8441 0001362 225.6158 134.4696 16.04379850 66612
STARLINK-42             
1 44256U 19029X   20212.96758227 -.00001758  00000-0 -62350-5 0  9999
2 44256  52.9943 308.3139 0008036 317.0903  42.9489 15.77897039 66499
STARLINK-43             
1 44257U 19029Y   20212.91667824 -.00028883  00000-0 -90214-3 0  9993
2 44257  53.0013 314.2394 0001170  83.3887 285.0414 15.32407378 66509
STARLINK-40             
1 44258U 19029Z   20213.25001157  .00898425  00000-0  63299-2 0  9990
2 44258  52.9977 357.1775 0001175  93.5230 195.0339 15.71294123 65911
STARLINK-52             
1 44259U 19029AA  20212.91667824  .00061504  00000-0  20227-3 0  9997
2 44259  53.0008 256.2066 0001973  62.2658 309.8059 15.89933548 67189
STARLINK-45             
1 44260U 19029AB  20213.25001157  .00897635  00000-0  63246-2 0  9997
2 44260  53.0068 356.1569 0001216  67.5025 251.0236 15.71295529 65983
STARLINK-44             
1 44261U 19029AC  20213.25001157  .00898371  00000-0  63299-2 0  9994
2 44261  52.9973 357.1550 0001332  58.6353 223.9466 15.71293534 65923
STARLINK-49             
1 44262U 19029AD  20213.25001157  .00897725  00000-0  63253-2 0  9997
2 44262  53.0097 357.6372 0001208  91.0087 269.5038 15.71295094 65932
STARLINK-72             
1 44263U 19029AE  20212.91667824  .00897425  00000-0  64719-2 0  9994
2 44263  52.9952 358.6841 0001092  98.8422  49.3939 15.70736954 65873
STARLINK-35             
1 44264U 19029AF  20213.25001157  .01167438  00000-0  81959-2 0  9994
2 44264  52.9988 357.1131 0000999  81.0724 171.5688 15.71142805 65903
STARLINK-63             
1 44265U 19029AG  20213.25001157  .00898277  00000-0  63288-2 0  9991
2 44265  52.9970 356.4814 0001130  86.0223 172.5244 15.71294482 65944
STARLINK-54             
1 44266U 19029AH  20213.25001157  .00897638  00000-0  63252-2 0  9993
2 44266  52.9977 356.4506 0001313  64.4455 338.0672 15.71293532 65871
STARLINK-69             
1 44267U 19029AJ  20213.25001157  .00897987  00000-0  63277-2 0  9998
2 44267  52.9993 356.3250 0001221  98.8575 231.6969 15.71292139 65911
STARLINK-55             
1 44268U 19029AK  20212.91667824 -.00035540  00000-0 -42437-3 0  9997
2 44268  52.9944 311.3129 0001561  76.8020 250.3707 15.60181007 66482
STARLINK-57             
1 44269U 19029AL  20213.25001157  .00897695  00000-0  63254-2 0  9991
2 44269  53.0031 356.8833 0001225  95.7060 270.8261 15.71293907 65957
STARLINK-58             
1 44270U 19029AM  20213.25001157  .00872254  41144-3  11430-2 0  9993
2 44270  53.0010 313.2465 0002511 313.2375  78.3291 16.06382200 66566
STARLINK-59             
1 44271U 19029AN  20213.25001157  .00898181  00000-0  63283-2 0  9997
2 44271  52.9962 356.3993 0001123  77.1185 145.4505 15.71293996 65947
STARLINK-51             
1 44272U 19029AP  20213.25001157  .00898207  00000-0  63287-2 0  9991
2 44272  52.9955 357.0224 0001181  69.2828 177.2910 15.71293378 65919
STARLINK-62             
1 44274U 19029AR  20213.40467867  .00655886  21690-3  98751-3 0  9994
2 44274  52.9992 312.4511 0006333 146.5578 213.5742 16.04053883 66540
STARLINK-64             
1 44275U 19029AS  20212.91667824  .00000357  00000-0  19557-4 0  9999
2 44275  52.9918 339.2001 0007357  97.0779 338.6219 15.38586100 66121
STARLINK-65             
1 44276U 19029AT  20213.25001157  .00039264  00000-0  85903-3 0  9995
2 44276  53.0009 359.0689 0000266  87.1864 157.9378 15.43780880 65904
STARLINK-41             
1 44277U 19029AU  20213.25001157  .00746498  27270-3  13111-2 0  9992
2 44277  53.0054 305.7432 0000953 334.5447  50.4205 16.01508333 66631
STARLINK-68             
1 44279U 19029AW  20213.25001157  .00041773  00000-0  76363-3 0  9996
2 44279  52.9963 357.0309 0000647  70.8136  12.2225 15.48985192 65884
STARLINK-38             
1 44280U 19029AX  20213.25001157  .00898078  00000-0  63275-2 0  9993
2 44280  52.9997 356.9755 0001175  65.7767 138.8192 15.71294888 65931
STARLINK-70             
1 44281U 19029AY  20212.91667824  .00031305  00000-0  99204-3 0  9999
2 44281  53.0005   3.9849 0001681  79.3555 104.1308 15.32472530 65748
STARLINK-56             
1 44283U 19029BA  20213.25001157  .00898093  00000-0  63273-2 0  9991
2 44283  52.9974 357.0579 0001104  75.8712 140.7257 15.71295597 65931
STARLINK-73             
1 44284U 19029BB  20213.25001157  .00898377  00000-0  63294-2 0  9990
2 44284  52.9991 357.0300 0001085  87.5120 123.0671 15.71294468 65923
STARLINK-50             
1 44285U 19029BC  20213.12815522  .00261423  00000-0  46962-2 0  9995
2 44285  53.0029   0.2817 0001240 123.8409 236.2715 15.48419328 66931
STARLINK-75             
1 44286U 19029BD  20212.91667824 -.00029828  00000-0 -25777-3 0  9997
2 44286  53.0058 311.7057 0009425  30.6254  48.8212 15.68133118 67590
STARLINK-76             
1 44287U 19029BE  20213.25001157  .00048576  00000-0  10384-2 0  9993
2 44287  52.9983 358.7742 0000901  94.6965 137.3824 15.44371440 66966
STARLINK-79             
1 44288U 19029BF  20213.25001157  .00897717  00000-0  63254-2 0  9997
2 44288  53.0100 357.7316 0001223  79.8463 298.6249 15.71294591 66998
STARLINK-48             
1 44289U 19029BG  20212.91667824 -.00003387  00000-0 -13986-3 0  9991
2 44289  53.0037 343.9689 0001779  58.8822 291.8086 15.20167781 67135
STARLINK-78             
1 44290U 19029BH  20213.25001157  .00897759  00000-0  63257-2 0  9999
2 44290  53.0097 357.6187 0001276  69.2800 303.2605 15.71294726 66996
STARLINK-77             
1 44291U 19029BJ  20213.25001157  .00042109  00000-0  56966-3 0  9991
2 44291  53.0104 358.2190 0000908  72.5303 112.0722 15.57324797 66973
STARLINK-81             
1 44292U 19029BK  20213.25001157  .00041996  00000-0  56878-3 0  9997
2 44292  53.0104 358.2464 0001640  71.7536 123.9497 15.57294779 66977
STARLINK-74             
1 44293U 19029BL  20213.25001157  .00897861  00000-0  63250-2 0  9999
2 44293  53.0085 357.6650 0001218  80.7638 267.9152 15.71299540 66985
STARLINK-53             
1 44294U 19029BM  20213.25001157  .00045282  00000-0  61432-3 0  9993
2 44294  52.9980 357.4667 0000918  88.3098 270.7532 15.57216037 66971
COSMOS 2534 [GLONASS-M] 
1 44299U 19030A   20213.02536856  .00000049  00000-0  00000+0 0  9996
2 44299  64.6139 265.4989 0013871 283.2216 344.8742  2.13102091  9174
YAMAL 601               
1 44307U 19031A   20212.67109587  .00000105  00000-0  00000-0 0  9998
2 44307   0.0114 103.5539 0001927  17.5221 118.2639  1.00273123  4394
2019-032A               
1 44310U 19032A   20213.14670735 -.00000072  00000-0  12508-4 0  9998
2 44310  44.9855 255.7468 0011090 144.3910 215.7714 14.99011624 63316
2019-032B               
1 44311U 19032B   20213.12506317 -.00000058  00000-0  13418-4 0  9996
2 44311  44.9781 250.4775 0013036 131.7773 228.4225 15.00541078 63375
2019-032C               
1 44312U 19032C   20213.13195171 -.00000040  00000-0  14834-4 0  9994
2 44312  44.9869 250.0285 0012624 131.3955 228.8006 15.00763088 63384
2019-032D               
1 44313U 19032D   20213.14417757  .00000042  00000-0  21211-4 0  9991
2 44313  44.9992 250.5803 0013054 129.8214 230.3806 15.00753167 63384
2019-032E               
1 44314U 19032E   20213.19815082 -.00000043  00000-0  14625-4 0  9993
2 44314  44.9861 249.6711 0013826 131.9587 228.2457 15.00766458 63397
2019-032F               
1 44315U 19032F   20213.12119973 -.00000034  00000-0  15186-4 0  9996
2 44315  44.9839 248.2159 0015901 137.3432 222.8675 15.01290141 63393
2019-032G               
1 44316U 19032G   20213.20215190 -.00000082  00000-0  11874-4 0  9997
2 44316  44.9853 255.3400 0015484 128.8191 231.4071 14.99021477 63308
RCM-1                   
1 44322U 19033A   20213.07277667  .00000122  00000-0  17314-4 0  9996
2 44322  97.7606 219.2020 0001450  86.4910 273.6455 14.92590955 61813
RCM-3                   
1 44323U 19033B   20213.09539748 -.00000409  00000-0 -34373-4 0  9994
2 44323  97.7604 219.3190 0001453  83.2856 276.8536 14.92583849 61828
RCM-2                   
1 44324U 19033C   20213.11761123  .00000308  00000-0  35390-4 0  9998
2 44324  97.7604 219.2943 0001545  85.6561 274.4842 14.92590061 61823
RAAVANA-1               
1 44329U 98067QE  20212.99567109  .00008209  00000-0  11152-3 0  9997
2 44329  51.6394 115.7571 0005895 158.1466 201.9779 15.58567020 63796
UGUISU                  
1 44330U 98067QF  20212.81830947  .00007887  00000-0  10780-3 0  9990
2 44330  51.6371 116.8073 0005918 157.0627 203.0631 15.58468276 63743
NEPALISAT-1             
1 44331U 98067QG  20212.47463936  .00008506  00000-0  11528-3 0  9993
2 44331  51.6373 118.2645 0005892 158.8106 201.3132 15.58574706 63708
SPOOQY-1                
1 44332U 98067QH  20212.80140915  .00007897  00000-0  10792-3 0  9994
2 44332  51.6380 116.6989 0004939 160.6792 199.4389 15.58472458 63716
AT&T T-16               
1 44333U 19034A   20212.59953017 -.00000129  00000-0  00000+0 0  9992
2 44333   0.0126 355.5669 0000785  44.3120  23.7598  1.00271789  4105
EUTELSAT 7C             
1 44334U 19034B   20212.76995400  .00000033  00000-0  00000+0 0  9997
2 44334   0.0155 104.1035 0003496  10.6248 118.2706  1.00271010  4593
BEIDOU-3 IGSO-2         
1 44337U 19035A   20212.82830440 -.00000107  00000-0  00000+0 0  9993
2 44337  55.0732 177.9089 0014305 190.6589 346.4862  1.00277244  4168
PROX-1                  
1 44339U 19036A   20212.67237267  .00000716  00000-0  11993-3 0  9999
2 44339  23.9987 199.9288 0010278 262.9625  96.9697 14.53050753 58427
NPSAT1                  
1 44340U 19036B   20213.09779478  .00000235  00000-0  57169-5 0  9996
2 44340  23.9991 197.4465 0011217 263.4623  96.4591 14.52777399 58487
OTB                     
1 44341U 19036C   20212.66166945  .00000241  00000-0  71912-5 0  9997
2 44341  23.9963 199.7834 0011549 259.0633 100.8558 14.52869621 58429
GPIM                    
1 44342U 19036D   20212.87287503  .00010974  00000-0  12644-3 0  9994
2 44342  23.9980  88.3847 0257949  99.1945 263.7833 15.28210529 59507
FORMOSAT 7-3            
1 44343U 19036E   20212.57431456  .00000638  00000-0  19138-4 0  9992
2 44343  23.9992 179.5994 0010882  93.9239 266.2508 15.07373466 58613
DSX                     
1 44344U 19036F   20210.32595907 -.00000039  00000-0  00000-0 0  9996
2 44344  42.2969  57.5797 1972876 171.5576 192.3799  4.54372004 18137
FALCONSAT-7             
1 44347U 19036J   20212.88216393  .00052680  00000-0  64704-3 0  9993
2 44347  28.5253  63.0890 0288898 107.8950 255.3365 15.22401746 60702
OCULUS-ASR              
1 44348U 19036K   20212.89427498  .00011632  00000-0  17167-3 0  9998
2 44348  28.5152  98.2674 0365225  51.3959 311.8759 15.03064900 60344
FORMOSAT 7-1            
1 44349U 19036L   20212.77289135  .00000661  00000-0  20526-4 0  9994
2 44349  24.0008 354.2756 0012265 132.0368 228.1180 15.07374206 60396
FORMOSAT 7-4            
1 44350U 19036M   20212.88157951  .00000746  00000-0  23120-4 0  9995
2 44350  24.0001  62.3182 0015225 154.2832 205.8431 15.10481470 59757
FORMOSAT 7-2            
1 44351U 19036N   20212.88311548  .00000630  00000-0  18421-4 0  9990
2 44351  23.9992 109.9883 0014684 214.2816 145.6740 15.07374553 59290
ARMADILLO               
1 44352U 19036P   20212.89376272  .00016861  00000-0  24736-3 0  9995
2 44352  28.5305  92.8412 0351929  59.3211 304.1579 15.06041617 60342
FORMOSAT 7-6            
1 44353U 19036Q   20212.63203600  .00000268  00000-0  12817-4 0  9994
2 44353  23.9980 196.5403 0013364 268.6670  91.2290 14.53802077 58443
PSAT2 (NO-104)          
1 44354U 19036R   20212.86295925  .00012307  00000-0  17859-3 0  9998
2 44354  28.5296  95.4047 0361987  54.8388 308.5566 15.04107806 60363
BRICSAT2 (NO-103)       
1 44355U 19036S   20211.98259197  .00024773  00000-0  34304-3 0  9996
2 44355  28.5294  90.1742 0338643  63.3198 300.1656 15.10036975 60333
TBEX-A                  
1 44356U 19036T   20212.74739588  .00069306 -48314-5  70531-3 0  9998
2 44356  28.5163  46.7207 0262250 133.8728 228.3850 15.31112219 60846
2019-036U               
1 44357U 19036U   20212.86658209  .00012484  00000-0  17786-3 0  9994
2 44357  28.5266  94.3189 0362875  56.6589 306.8201 15.04325953 60371
FORMOSAT 7-5            
1 44358U 19036V   20212.61401550  .00000208  00000-0 -13692-5 0  9999
2 44358  23.9988 196.4096 0014755 269.3862  90.4938 14.53830811 58424
TBEX-B                  
1 44359U 19036W   20212.82705504  .00073786 -47541-5  71149-3 0  9995
2 44359  28.5168  42.2434 0257648 141.5134 220.4140 15.33127776 60750
CP9 (LEO)               
1 44360U 19036X   20212.90151459  .00038187 -51988-5  42774-3 0  9998
2 44360  28.5122  66.4980 0317292 102.6822 260.9431 15.18076560 60535
2019-036Y               
1 44361U 19036Y   20212.72626471  .00040454 -51529-5  45298-3 0  9995
2 44361  28.5153  68.0049 0319181 100.1250 263.5501 15.17768084 60514
RED-EYE 1 (PINOT)       
1 44364U 98067QJ  20212.80168748  .00003675  00000-0  62117-4 0  9992
2 44364  51.6409 123.4818 0007434 123.4174 236.7529 15.54411075 62088
PAINANI 1               
1 44365U 19037A   20212.78385554  .00001330  00000-0  43168-4 0  9997
2 44365  45.0070 246.1904 0004038 102.9744 257.1594 15.38349798 61195
PROMETHEUS 2-9          
1 44366U 19037B   20212.78730635  .00002602  00000-0  71735-4 0  9996
2 44366  45.0095 242.1711 0005476  84.7942 275.3571 15.40226166 61248
GLOBAL-3                
1 44367U 19037C   20212.80470620  .00000866  00000-0  30812-4 0  9996
2 44367  45.0083 243.8602 0007490  83.4335 276.7405 15.38768824 61221
ELECTRON KICK STAGE R/B 
1 44368U 19037D   20212.78287799  .00003627  00000-0  90265-4 0  9993
2 44368  45.0055 236.0916 0010351  89.3380 270.8695 15.42331092 61312
ACRUX 1                 
1 44369U 19037E   20212.78993894  .00002645  00000-0  68870-4 0  9990
2 44369  45.0068 236.2030 0012050  77.1462 283.0772 15.41936920 61266
SPACEBEE-9              
1 44370U 19037F   20212.76566184  .00005667  00000-0  12940-3 0  9991
2 44370  45.0053 233.4867 0011562  75.5012 284.7159 15.43918881 61297
SPACEBEE-8              
1 44371U 19037G   20213.10033964  .00012846  00000-0  24111-3 0  9996
2 44371  45.0052 224.1746 0010005  77.5692 282.6319 15.48635455 61414
PROMETHEUS 2-7          
1 44374U 19037K   20212.81433972  .00002815  00000-0  76294-4 0  9994
2 44374  45.0090 241.5275 0005454  84.8036 275.3475 15.40481254 61076
IOD-1 GEMS              
1 44385U 98067QK  20212.84594471  .00013519  00000-0  16194-3 0  9996
2 44385  51.6396 114.0547 0002906 119.6893 240.4390 15.61278120 61273
PROMETHEUS 2-6          
1 44386U 19036AB  20212.88266123  .00026897  00000-0  35971-3 0  9997
2 44386  28.5302  79.7889 0333840  80.1650 283.6462 15.11743701 59236
METEOR-M2 2             
1 44387U 19038A   20213.08121726 -.00000033  00000-0  44940-5 0  9995
2 44387  98.6225 172.2651 0002502  97.1768 262.9695 14.23680153 55744
2019-038B               
1 44388U 19038B   20212.86541817  .00000864  00000-0  79737-4 0  9990
2 44388  97.7281 174.1420 0023218  19.4193 340.7908 14.97038546 58556
ICEYE-X5                
1 44389U 19038C   20213.12008422  .00000595  00000-0  57148-4 0  9994
2 44389  97.7336 174.4859 0021302  10.5981 349.5685 14.96624543 58596
ICEYE-X4                
1 44390U 19038D   20213.17231620  .00000644  00000-0  61415-4 0  9996
2 44390  97.7310 174.4628 0020921  10.6034 349.5624 14.96677002 58603
NSL-1                   
1 44391U 19038E   20212.83603689  .00000941  00000-0  86260-4 0  9992
2 44391  97.7304 174.3347 0020906  11.3780 348.7910 14.97134801 58484
VDNH-80                 
1 44392U 19038G   20213.15313620  .00000616  00000-0  38677-4 0  9990
2 44392  97.5411 174.2162 0021203 344.7564  15.3025 15.12807235 59009
D-STAR ONE (LIGHTSAT)   
1 44393U 19038H   20212.85879579  .00000655  00000-0  41034-4 0  9991
2 44393  97.5413 173.8445 0021611 345.8617  14.2006 15.12663722 59159
AMGU-1 (AMURSAT)        
1 44394U 19038J   20212.87063183  .00000638  00000-0  40107-4 0  9991
2 44394  97.5412 173.8261 0021646 346.1842  13.8793 15.12632063 59159
2019-038K               
1 44395U 19038K   20213.15670490  .00000585  00000-0  37696-4 0  9999
2 44395  97.5416 173.7742 0024658 341.5491 148.4923 15.12003638 59163
LEMUR-2-LILLYJO         
1 44396U 19038L   20212.88265263  .00001361  00000-0  80784-4 0  9996
2 44396  97.5351 173.6038 0024194 341.1332  18.8999 15.12809105 59148
2019-038M               
1 44397U 19038M   20212.86605295  .00000614  00000-0  39255-4 0  9995
2 44397  97.5413 173.5318 0024957 340.4114  19.6155 15.12132846 59138
MOVE-IIB                
1 44398U 19038N   20212.82355438  .00000977  00000-0  59718-4 0  9991
2 44398  97.5386 173.5100 0024745 342.2239  17.8123 15.12396705 59131
DOT-1                   
1 44399U 19038P   20212.86680494  .00000342  00000-0  23597-4 0  9991
2 44399  97.5426 173.5739 0024448 347.0252  13.0346 15.12026330 59132
SONATE                  
1 44400U 19038Q   20212.86901071  .00000326  00000-0  22712-4 0  9991
2 44400  97.5428 173.5672 0024680 340.4637  19.5645 15.11990515 59132
2019-038R               
1 44401U 19038R   20212.87635960  .00000682  00000-0  42891-4 0  9999
2 44401  97.5409 173.6615 0024421 339.8978  20.1289 15.12365687 59143
LEMUR-2-WANLI           
1 44402U 19038S   20213.16423265  .00001348  00000-0  80180-4 0  9999
2 44402  97.5358 173.8743 0024284 340.5751  19.4552 15.12739729 59197
LEMUR-2-MORAG           
1 44403U 19038T   20212.82545266  .00001512  00000-0  89352-4 0  9991
2 44403  97.5392 173.7132 0023173 345.4155  14.6405 15.12817453 59145
SOKRAT                  
1 44404U 19038U   20212.85244812  .00000697  00000-0  43701-4 0  9999
2 44404  97.5410 173.6903 0021978 347.2855  12.7818 15.12448851 59149
LEMUR-2-DUSTINTHEWIND   
1 44405U 19038V   20212.80344083  .00000878  00000-0  53467-4 0  9997
2 44405  97.5349 173.5308 0024202 341.0844  18.9486 15.12823035 59142
LUCKY-7                 
1 44406U 19038W   20213.14168091  .00000408  00000-0  27301-4 0  9996
2 44406  97.5422 173.9550 0024082 339.1789  20.8458 15.12262014 59178
LEMUR-2-ALEX-MADDY      
1 44407U 19038X   20212.85403961  .00000707  00000-0  44178-4 0  9995
2 44407  97.5398 173.6765 0023274 345.8116  14.2458 15.12491619 59134
MOMENTUS-X1             
1 44408U 19038Y   20212.82351047  .00000279  00000-0  19887-4 0  9993
2 44408  97.5358 173.5218 0023275 343.7563  16.2918 15.12166512 59131
LEMUR-2-EJATTA          
1 44409U 19038Z   20212.83925638  .00001479  00000-0  86941-4 0  9996
2 44409  97.5344 173.6160 0023982 340.4557  19.5752 15.13034070 59154
D-STAR ONE (EXOCONNECT) 
1 44410U 19038AA  20213.12853564  .00000983  00000-0  59496-4 0  9993
2 44410  97.5396 174.0538 0021844 345.3851  14.6745 15.12798741 59196
LEMUR-2-GREGROBINSON    
1 44411U 19038AB  20212.84355633  .00001480  00000-0  87707-4 0  9995
2 44411  97.5401 173.7352 0023304 346.2069  13.8523 15.12752170 58817
BEESAT 9                
1 44412U 19038AC  20212.87320093  .00000599  00000-0  38145-4 0  9993
2 44412  97.5412 173.6714 0022368 346.3880  13.6744 15.12354554 58831
LEMUR-2-YNDRD           
1 44413U 19038AD  20212.84398312  .00000779  00000-0  48284-4 0  9995
2 44413  97.5392 173.6534 0023133 345.5342  14.5224 15.12497139 59149
2019-038AE              
1 44414U 19038AE  20213.16849287  .00000791  00000-0  49341-4 0  9993
2 44414  97.5425 173.9840 0023446 345.3170 135.4242 15.12216469 58714
NARSSCUBE-2             
1 44415U 19022E   20212.65498653  .00000450  00000-0  27641-4 0  9998
2 44415  51.6424 186.0798 0012063 110.0535 250.1745 15.30053852 54867
QUANTUM-RADAR 3         
1 44416U 19022F   20212.62699867  .00000942  00000-0  43313-4 0  9996
2 44416  51.6422 185.1648 0011754 109.9585 250.2663 15.30831120 54859
RFTSAT                  
1 44417U 19022G   20212.20763065  .00001364  00000-0  57792-4 0  9998
2 44417  51.6419 187.6080 0012080 108.7157 251.5136 15.30481341 54820
ORCA-1                  
1 44418U 19022H   20212.72926314  .00001295  00000-0  54637-4 0  9992
2 44418  51.6428 184.3715 0011815 109.2749 250.9511 15.31037550 54907
JAISAT-1                
1 44419U 19038F   20212.81348288  .00000626  00000-0  39229-4 0  9995
2 44419  97.5414 173.9095 0021213 345.8646  14.1988 15.12837968 59154
LIGHTSAIL 2             
1 44420U 19036AC  20212.36800520  .00004319  00000-0  91484-3 0  9990
2 44420  24.0072 196.5797 0012285 228.3545 131.5895 14.55984264 56575
COSMOS 2535             
1 44421U 19039A   20212.86041211  .00000015  00000-0  79843-5 0  9999
2 44421  97.8732 265.8597 0010660 100.6217 259.6199 14.85936995 57311
COSMOS 2536             
1 44422U 19039B   20213.10083408  .00000698  00000-0  88663-4 0  9998
2 44422  97.8307 264.0715 0011138 122.8520 237.3778 14.84715839 57325
COSMOS 2537             
1 44423U 19039C   20213.14851212  .00001274  00000-0  15647-3 0  9991
2 44423  97.8509 264.8296 0012230 116.9191 243.3275 14.84729678 57318
COSMOS 2538             
1 44424U 19039D   20212.86226980  .00000038  00000-0  10486-4 0  9992
2 44424  97.8732 265.8584 0010624  95.5439 264.6988 14.86133687 57318
SWIATOWID               
1 44426U 98067QL  20213.11452481  .00016728  00000-0  19071-3 0  9999
2 44426  51.6401 111.7746 0003521 118.2122  23.0306 15.62366417 61332
KRAKSAT                 
1 44427U 98067QM  20213.15469211  .00005861  00000-0  88075-4 0  9990
2 44427  51.6422 118.8008 0003918 114.5132  31.4837 15.56448928 61282
VCC A                   
1 44428U 98067QN  20212.74990150  .00008134  00000-0  11335-3 0  9996
2 44428  51.6398 118.6609 0003068 127.7806 232.3464 15.57900139 61253
ENTRYSAT                
1 44429U 98067QP  20212.36968250  .00009467  00000-0  12549-3 0  9999
2 44429  51.6385 119.0892 0002921 118.3764 241.7523 15.59021541 61214
VCC C                   
1 44430U 98067QQ  20212.43690701  .00012604  00000-0  15674-3 0  9991
2 44430  51.6382 117.2964 0002636 132.4600 227.6616 15.60365909 60061
VCC B                   
1 44431U 98067QR  20213.13324798  .00008174  00000-0  11365-3 0  9990
2 44431  51.6392 116.7028 0003210 128.6926 231.4353 15.57953748 61258
YAOGAN 30 N             
1 44449U 19045A   20213.25097775  .00000099  00000-0  12848-4 0  9999
2 44449  34.9983 282.1784 0003769  82.5265 277.5870 14.89772495 55370
YAOGAN 30 P             
1 44450U 19045B   20213.09456879  .00000100  00000-0  12974-4 0  9990
2 44450  34.9977 282.7807 0010318 250.0320 109.9270 14.89764833 55345
YAOGAN 30 Q             
1 44451U 19045C   20213.20656833  .00000082  00000-0  11123-4 0  9990
2 44451  34.9988 281.8336 0002115 291.5460  68.5024 14.89760610 55331
MERIDIAN 8              
1 44453U 19046A   20212.44325712  .00000145  00000-0  00000+0 0  9994
2 44453  62.7112 295.7529 7121565 272.4405 131.6480  2.00636964  7344
COSMOS 2539             
1 44457U 19048A   20212.30088014  .00000067  00000-0  00000-0 0  9990
2 44457   0.0268 118.0724 0000664 133.2167 177.3945  1.00268225  3661
EDRS-C                  
1 44475U 19049A   20211.06002588  .00000143  00000-0  00000-0 0  9990
2 44475   0.0740 275.3692 0000426 258.5322 186.0863  1.00275524  3641
INTELSAT 39 (IS-39)     
1 44476U 19049B   20212.06312094  .00000020  00000-0  00000-0 0  9992
2 44476   0.0203  64.9903 0001044 277.3297  50.4954  1.00270508  3631
AMOS-17                 
1 44479U 19050A   20212.99068439  .00000095  00000-0  00000+0 0  9991
2 44479   0.0380 266.6970 0002353 228.4914 187.5256  1.00274499  3665
AEHF-5 (USA 292)        
1 44481U 19051A   20213.04838387  .00000077  00000-0  00000+0 0  9999
2 44481   5.8817 307.9956 0003177 169.6924 263.4897  1.00272435  3732
TDO-1                   
1 44482U 19051B   20212.05396445  .00000638  00000-0  17248-3 0  9993
2 44482  25.8599 162.2661 7239798  51.2186 353.5476  2.32367624  8280
AEROCUBE 10B (DOUGSAT)  
1 44484U 19022C   20212.84728661  .00000912  00000-0  42941-4 0  9993
2 44484  51.6418 185.0950 0010375 103.7418 256.4718 15.30288914 54888
AEROCUBE 10A (JIMSAT)   
1 44485U 19022D   20212.78201186  .00000865  00000-0  41380-4 0  9998
2 44485  51.6419 185.4094 0010379 103.3503 256.8635 15.30290034 54886
2019-052A               
1 44486U 19052A   20213.12161919  .00000090  00000-0  10087-4 0  9990
2 44486  97.5729 286.6243 0022296 139.3386 284.2392 15.07323890 52557
2019-052B               
1 44487U 19052B   20213.14916874  .00000942  00000-0  65347-4 0  9996
2 44487  97.5730 286.8886 0024018 143.6401 300.1548 15.07802950 52588
2019-052C               
1 44488U 19052C   20213.10816815 -.00000047  00000-0  10957-5 0  9992
2 44488  97.5722 286.7690 0024693 141.9019 258.2409 15.07669994 52563
BRO-1                   
1 44495U 19054A   20212.97437801 -.00000090  00000-0  99862-5 0  9990
2 44495  45.0204  59.2966 0011404  45.6915  97.1692 15.08567606 52342
PEARL WHITE 1           
1 44497U 19054C   20212.86570715  .00000142  00000-0  24621-4 0  9999
2 44497  45.0156  60.2991 0010430  49.5179 324.7451 15.08438980 52279
PEARL WHITE 2           
1 44498U 19054D   20213.03081037  .00000245  00000-0  31015-4 0  9993
2 44498  45.0157  58.6526 0011329  45.4235 114.9733 15.08699835 52328
GLOBAL-4                
1 44499U 19054E   20212.00647780  .00000067  00000-0  18427-4 0  9997
2 44499  45.0145  58.3611 0014913  44.2679 315.9389 15.12673303 52236
NAVSTAR 78 (USA 293)    
1 44506U 19056A   20211.26460684 -.00000062  00000-0  00000+0 0  9990
2 44506  55.2664  56.5609 0007364 166.0855 190.5239  2.00558594  6969
COSMOS 2540             
1 44517U 19057A   20213.25331019  .00000071  00000-0  10000-3 0  9997
2 44517  99.2700 221.0187 0001257  96.0156  88.8595 13.85915005 46485
XIAOXIANG-1 07          
1 44519U 19058A   20213.04140056  .00000286  00000-0  35923-4 0  9996
2 44519  97.7891 218.6869 0011863 262.9676  97.0225 14.89418171 49870
KX-09                   
1 44520U 19058B   20213.04971401  .00000010  00000-0  68408-5 0  9994
2 44520  97.7895 218.8353 0010564 263.4810  96.5238 14.89551589 49889
ZY-1 02D                
1 44528U 19059A   20213.21656854 -.00000036  00000-0  26869-5 0  9998
2 44528  98.5480 288.9515 0001534  89.7106 270.4265 14.35302194 46346
BNU-1 (JINGSHI 1)       
1 44529U 19059B   20213.18475859  .00000032  00000-0  21947-4 0  9995
2 44529  98.5517 294.0478 0013925  25.6454 334.5444 14.45147444 46657
TAURUS-1 (JINNIUZUO 1)  
1 44530U 19059C   20213.13359869  .00000597  00000-0  18182-3 0  9996
2 44530  98.5695 294.6405 0015571  37.6027 322.6264 14.45534805 46659
SEEKER                  
1 44533U 19022K   20212.61550135  .00000570  00000-0  31023-4 0  9991
2 44533  51.6391 184.2256 0011739  96.3821 263.8497 15.30821722 48758
ZHUHAI-1 03A            
1 44534U 19060A   20213.09672021  .00000737  00000-0  39313-4 0  9991
2 44534  97.4216 322.1801 0014118 291.2518 171.4252 15.18182110 47901
ZHUHAI-1 03B            
1 44536U 19060C   20213.21447626  .00000647  00000-0  34949-4 0  9998
2 44536  97.4227 322.3621 0010012 288.8847 167.1126 15.18169450 47930
ZHUHAI-1 03C            
1 44537U 19060D   20213.19772064  .00000500  00000-0  27770-4 0  9995
2 44537  97.4169 322.1238 0009362 310.7058 112.3951 15.18156836 47935
ZHUHAI-1 03D            
1 44538U 19060E   20213.24954913  .00000441  00000-0  24867-4 0  9995
2 44538  97.4143 322.1163 0008590 295.1068 119.8769 15.18153728 47941
ZHUHAI-1 03E            
1 44539U 19060F   20213.16811801  .00000552  00000-0  30312-4 0  9990
2 44539  97.4242 322.4352 0008033 319.4644  86.9713 15.18158439 47933
BEIDOU-3 M23            
1 44542U 19061A   20212.52635189  .00000056  00000-0  10000-3 0  9997
2 44542  54.8474 255.4972 0008364 306.3851 112.9197  1.86231389  5796
BEIDOU-3 M24            
1 44543U 19061B   20212.03869486  .00000055  00000-0  10000-3 0  9992
2 44543  54.8485 255.4840 0006395 290.2690  72.5211  1.86231648  5793
YUNHAI 1-02             
1 44547U 19063A   20213.10829997  .00000139  00000-0  67206-4 0  9994
2 44547  98.5644 237.9824 0004152  39.3716 320.7771 14.32629613 44392
COSMOS 2541             
1 44552U 19065A   20212.45917287 -.00001270  00000-0  00000-0 0  9992
2 44552  63.7850  33.3005 7063592 269.4698  78.6429  2.00566109  6181
GAOFEN 10R              
1 44622U 19066A   20213.42744434  .00746012  00000-0  91762-1 0  9996
2 44622  97.8152 155.6810 0001797 106.6277 253.3593 14.80617648 44497
EUTELSAT 5 WEST B       
1 44624U 19067A   20213.06305147 -.00000057  00000-0  00000+0 0  9999
2 44624   0.0431  19.5853 0006378  97.7839 209.4428  1.00271379  2920
MEV-1                   
1 44625U 19067B   20211.90173448 -.00000215  00000-0  00000-0 0  9995
2 44625   0.0539 257.3727 0001200 229.2008 118.5424  1.00270250  1451
ICON                    
1 44628U 19068A   20212.84430244  .00000400  00000-0  19083-4 0  9993
2 44628  26.9901 110.4144 0019201  81.8562 278.4173 14.91710968 43905
PALISADE                
1 44634U 19069A   20212.78832126 -.00000469  00000-0 -13523-2 0  9997
2 44634  87.8953 346.4549 0010996  75.0569 285.1775 13.11824223 37737
TJS-4                   
1 44637U 19070A   20212.86103248 -.00000184  00000-0  00000+0 0  9993
2 44637   0.0413 234.8956 0003596 154.1590 313.4512  1.00269588  3022
GAOFEN 7                
1 44703U 19072A   20213.17159351  .00000292  00000-0  16170-4 0  9999
2 44703  97.4694 289.1719 0010504  43.2790  39.3219 15.21280310 41219
SRSS-1                  
1 44704U 19072B   20213.20308623  .00000929  00000-0  41115-4 0  9996
2 44704  97.4717 290.2395 0017001  53.0930 359.3495 15.24086608 41277
HUANGPU-1               
1 44705U 19072C   20213.19099698  .00000948  00000-0  41784-4 0  9997
2 44705  97.4606 289.8859 0016863  54.0635  26.0503 15.24179337 41272
XIAOXIANG 1-08          
1 44706U 19072D   20213.19594465  .00001430  00000-0  61375-4 0  9995
2 44706  97.4705 290.2043 0016313  52.3985 359.8582 15.24258852 41277
BEIDOU-3 IGSO-3         
1 44709U 19073A   20212.40920495 -.00000196  00000-0  10000-3 0  9995
2 44709  58.3323 303.4662 0025060 188.0026  71.6695  1.00270483  2913
STARLINK-1007           
1 44713U 19074A   20213.25001157  .00015380  00000-0  10730-2 0  9999
2 44713  53.0001  17.9321 0001454 108.5651 298.1787 15.05559959 40338
STARLINK-1008           
1 44714U 19074B   20213.25001157  .00014974  00000-0  10454-2 0  9991
2 44714  52.9998  17.9287 0001323 103.2920  33.4370 15.05554945 40348
STARLINK-1009           
1 44715U 19074C   20212.91667824  .00045403  00000-0  31154-2 0  9994
2 44715  52.9985  19.4302 0001362  95.8155  87.2199 15.05578460 40286
STARLINK-1010           
1 44716U 19074D   20212.91667824  .00044401  00000-0  30488-2 0  9991
2 44716  52.9971  19.4390 0001397  97.9268 139.0393 15.05562810  1769
STARLINK-1011           
1 44717U 19074E   20212.91667824  .00195764  00000-0  13132-1 0  9997
2 44717  53.0017  39.4511 0001587 114.9568 300.0107 15.05449852 40007
STARLINK-1012           
1 44718U 19074F   20212.91667824  .00043812  00000-0  30100-2 0  9999
2 44718  52.9998  19.4293 0001352 106.6391 148.3651 15.05548411 40289
STARLINK-1013           
1 44719U 19074G   20212.91667824  .00007318  00000-0  52093-3 0  9997
2 44719  52.9999  19.4302 0001349 104.8985  96.1655 15.05575669  1766
STARLINK-1014           
1 44720U 19074H   20212.91667824  .00042228  00000-0  29006-2 0  9995
2 44720  53.0004  19.4278 0003831 223.4232 247.5965 15.05569637  1764
STARLINK-1015           
1 44721U 19074J   20212.91667824  .00044365  00000-0  30464-2 0  9990
2 44721  52.9984  19.4333 0001352 105.5755  59.4490 15.05562290  1769
STARLINK-1016           
1 44722U 19074K   20212.91667824  .00043604  00000-0  29951-2 0  9995
2 44722  53.0002  19.4241 0001244 103.1356  43.8263 15.05557927  1768
STARLINK-1017           
1 44723U 19074L   20212.91667824  .00040152  00000-0  27616-2 0  9997
2 44723  53.0002  19.4303 0001413  91.2147 343.7900 15.05547732  1764
STARLINK-1019           
1 44724U 19074M   20212.91667824  .00043360  00000-0  29788-2 0  9994
2 44724  52.9994  19.4293 0001431 102.5026 170.5448 15.05554894  1763
STARLINK-1020           
1 44725U 19074N   20212.91667824 -.00028058  00000-0 -19131-2 0  9995
2 44725  53.0021  39.4528 0001492 112.3894  86.4897 15.05599794  1760
STARLINK-1021           
1 44726U 19074P   20212.91667824 -.00010805  00000-0 -72342-3 0  9991
2 44726  53.0000  19.4305 0001458 103.2922 187.7885 15.05586349  1766
STARLINK-1022           
1 44727U 19074Q   20212.91667824  .00005709  00000-0  41056-3 0  9995
2 44727  53.0015  59.4194 0001652  91.4758 269.7650 15.05587671 39732
STARLINK-1023           
1 44728U 19074R   20213.25001157  .00059870  00000-0  40962-2 0  9997
2 44728  52.9989  17.9301 0001437 102.9689 321.7517 15.05554705 40333
STARLINK-1024           
1 44729U 19074S   20212.91667824  .00112651  00000-0  76432-2 0  9997
2 44729  53.0024  39.4468 0001408 102.9712   5.9629 15.05506224 40006
STARLINK-1025           
1 44730U 19074T   20212.91667824  .00040898  00000-0  28122-2 0  9995
2 44730  52.9996  19.4294 0001266 104.1903 348.8880 15.05549164 40285
STARLINK-1026           
1 44731U 19074U   20212.91667824  .00045073  00000-0  30942-2 0  9999
2 44731  53.0011  19.4303 0001474  93.9422 125.0725 15.05564537 40283
STARLINK-1027           
1 44732U 19074V   20212.91667824  .00031470  00000-0  21699-2 0  9995
2 44732  52.9990  39.4309 0001388  98.5748 154.2771 15.05571121  1764
STARLINK-1028           
1 44733U 19074W   20212.91667824  .00041139  00000-0  28271-2 0  9992
2 44733  53.0004  19.4299 0001407 111.7058 197.3048 15.05568134  1768
STARLINK-1029           
1 44734U 19074X   20213.25001157  .00036840  00000-0  42891-3 0  9990
2 44734  52.9989 322.9240 0005111  99.3372 346.1942 15.61343147 41109
STARLINK-1030           
1 44735U 19074Y   20212.91667824  .00172987  00000-0  11623-1 0  9992
2 44735  53.0005  39.4416 0001314 104.8260 112.0363 15.05517283  1762
STARLINK-1031           
1 44736U 19074Z   20212.91667824  .00029015  00000-0  20033-2 0  9992
2 44736  53.0010  39.4536 0001493 110.5796  16.3222 15.05562421 40002
STARLINK-1032           
1 44737U 19074AA  20213.25001157 -.00014523  00000-0 -97968-3 0  9994
2 44737  53.0007  37.9329 0001388 113.3715 327.2696 15.05578024 40057
STARLINK-1033           
1 44738U 19074AB  20212.91667824  .00029240  00000-0  20174-2 0  9990
2 44738  53.0003  39.4346 0001534 107.9933  36.8999 15.05584061 39992
STARLINK-1052           
1 44739U 19074AC  20212.91667824  .00013403  00000-0  93750-3 0  9996
2 44739  53.0019  39.4489 0001438  98.3793  82.5167 15.05572823  1760
STARLINK-1035           
1 44740U 19074AD  20213.25001157 -.00013549  00000-0 -91186-3 0  9991
2 44740  53.0002  37.9318 0001415 111.9810 346.6143 15.05606062 40051
STARLINK-1036           
1 44741U 19074AE  20212.91667824  .00029127  00000-0  20111-2 0  9999
2 44741  52.9996  39.4293 0001459 100.8127 296.1005 15.05558459  1764
STARLINK-1038           
1 44743U 19074AG  20213.25001157 -.00032910  00000-0 -22487-2 0  9994
2 44743  52.9995  57.9348 0001187 102.9046  13.9911 15.05602147 39780
STARLINK-1039           
1 44744U 19074AH  20213.25001157 -.00033041  00000-0 -22577-2 0  9991
2 44744  52.9984  57.9322 0001469  90.0487 350.8039 15.05602071 39779
STARLINK-1041           
1 44746U 19074AK  20213.25001157 -.00032885  00000-0 -22479-2 0  9999
2 44746  52.9995  57.9332 0001425 105.9897  28.9603 15.05586084 39783
STARLINK-1042           
1 44747U 19074AL  20212.91667824  .00006264  00000-0  44869-3 0  9999
2 44747  53.0017  59.4199 0001536  95.6837 247.4796 15.05578831  1760
STARLINK-1043           
1 44748U 19074AM  20212.91667824  .00005998  00000-0  43059-3 0  9993
2 44748  53.0006  59.4297 0001436  93.3821 105.8477 15.05568701  1763
STARLINK-1044           
1 44749U 19074AN  20212.91667824  .00029612  00000-0  20438-2 0  9998
2 44749  53.0010  39.4298 0001273  99.6234 279.2648 15.05564884 40008
STARLINK-1045           
1 44750U 19074AP  20213.25001157 -.00033789  00000-0 -23094-2 0  9992
2 44750  53.0004  57.9355 0001475  99.3779 323.4546 15.05604030 39776
STARLINK-1046           
1 44751U 19074AQ  20213.25001157 -.00033000  00000-0 -22548-2 0  9992
2 44751  52.9986  57.9321 0001284  95.1188   3.8235 15.05604157 39777
STARLINK-1047           
1 44752U 19074AR  20212.91667824  .00030201  00000-0  20832-2 0  9996
2 44752  53.0007  39.4299 0001445  93.5192 267.4203 15.05576829 40004
STARLINK-1048           
1 44753U 19074AS  20212.91667824  .00038824  00000-0  26692-2 0  9996
2 44753  52.9993  19.4306 0001393 104.1679 276.9139 15.05578846 40289
STARLINK-1049           
1 44754U 19074AT  20212.91667824  .00038655  00000-0  26596-2 0  9992
2 44754  52.9980  19.4313 0001451 100.5955 262.4020 15.05552769  1761
STARLINK-1050           
1 44755U 19074AU  20213.25001157 -.00034331  00000-0 -23480-2 0  9990
2 44755  53.0029  57.9182 0001490 100.7897 304.1423 15.05587002 39781
STARLINK-1051           
1 44756U 19074AV  20212.91667824  .00039776  00000-0  27358-2 0  9996
2 44756  52.9989  19.4294 0001251 103.3162 241.7428 15.05551575  1765
STARLINK-1034           
1 44757U 19074AW  20212.91667824  .00031613  00000-0  21810-2 0  9996
2 44757  53.0001  39.4310 0001384  98.5137 190.4249 15.05548144  1767
STARLINK-1053           
1 44758U 19074AX  20212.91667824  .00007970  00000-0  56575-3 0  9993
2 44758  53.0006  59.4220 0001423  95.8340 175.3889 15.05567953  1766
STARLINK-1054           
1 44759U 19074AY  20212.91667824  .00007301  00000-0  51986-3 0  9993
2 44759  53.0028  59.4225 0001627 100.0517 207.1491 15.05569376  1761
STARLINK-1055           
1 44760U 19074AZ  20213.25001157 -.00035207  00000-0 -24082-2 0  9998
2 44760  53.0013  57.9197 0001376 101.9207 284.8869 15.05594451 39781
STARLINK-1056           
1 44761U 19074BA  20213.25001157 -.00037044  00000-0 -25365-2 0  9995
2 44761  53.0011  57.9223 0001262 107.7133 225.2608 15.05580214 39788
STARLINK-1057           
1 44762U 19074BB  20212.91667824  .00031443  00000-0  21686-2 0  9994
2 44762  53.0000  39.4289 0001385  99.8393 207.0848 15.05561894 40003
STARLINK-1058           
1 44763U 19074BC  20213.25001157 -.00036859  00000-0 -25216-2 0  9995
2 44763  53.0003  57.9297 0001450 103.9163 121.0246 15.05611019 39781
STARLINK-1059           
1 44764U 19074BD  20212.91667824 -.00019831  00000-0 -13450-2 0  9995
2 44764  53.0004  39.4286 0001442  84.7513 240.1664 15.05597420 40005
STARLINK-1060           
1 44765U 19074BE  20213.25001157 -.00016298  00000-0 -11021-2 0  9991
2 44765  53.0006  37.9308 0001334 108.2645 134.3067 15.05574737 40056
STARLINK-1061           
1 44766U 19074BF  20212.91667824  .00007525  00000-0  53504-3 0  9997
2 44766  53.0020  59.4273 0001481 100.2496 152.9075 15.05584511 39731
STARLINK-1062           
1 44767U 19074BG  20213.25001157 -.00037231  00000-0 -25483-2 0  9990
2 44767  53.0018  57.9224 0001290  94.2440 202.6052 15.05597971 39784
STARLINK-1063           
1 44768U 19074BH  20213.25001157 -.00033906  00000-0 -23171-2 0  9998
2 44768  52.9990  57.9315 0001426 102.6090  68.3309 15.05610548 39787
STARLINK-1064           
1 44769U 19074BJ  20213.25001157  .00018045  00000-0  12548-2 0  9990
2 44769  52.9998  57.9309 0001619  99.9602  52.8718 15.05569626 39782
STARLINK-1067           
1 44771U 19074BL  20213.25001157 -.00036218  00000-0 -24779-2 0  9996
2 44771  53.0024  57.9266 0001393  96.0434 146.8122 15.05600543 39786
STARLINK-1068           
1 44772U 19074BM  20212.91667824  .00031625  00000-0  21811-2 0  9997
2 44772  53.0007  39.4281 0001515  98.9768 171.9149 15.05559459 40001
JILIN-01 GAOFEN 2A      
1 44777U 19075A   20212.85775547  .00000580  00000-0  40309-4 0  9999
2 44777  97.5088 277.5734 0013863  90.6641  43.1403 15.09276642 39324
NINGXIA-1 1             
1 44779U 19076A   20212.97187170 -.00000130  00000-0  00000+0 0  9995
2 44779  45.0093  51.6329 0009994  75.8864 284.3080 14.01670594 36572
NINGXIA-1 2             
1 44780U 19076B   20212.95702475 -.00000130  00000-0  00000+0 0  9996
2 44780  45.0121  51.6047 0007966  67.6501 292.5177 14.01667961 36579
NINGXIA-1 3             
1 44781U 19076C   20212.94237478 -.00000130  00000-0  00000+0 0  9992
2 44781  45.0035  51.3344 0010217  54.4519 305.7266 14.01678530 36577
NINGXIA-1 4             
1 44782U 19076D   20212.92981143 -.00000130  00000-0  00000-0 0  9997
2 44782  45.0114  51.4339 0013115  46.8789 313.3150 14.01668144 36572
NINGXIA-1 5             
1 44783U 19076E   20212.98551841 -.00000130  00000-0  00000+0 0  9991
2 44783  45.0094  50.9711 0011442  61.7176 298.4801 14.01675881 36588
KL-ALPHA A              
1 44785U 19077A   20213.15028441  .00000049  00000-0  53269-4 0  9997
2 44785  88.9114  99.7110 0009047 246.7724 113.2473 13.55769941 34794
KL-ALPHA B              
1 44786U 19077B   20212.74235182  .00000048  00000-0  51329-4 0  9998
2 44786  88.8964 100.5342 0025061 290.1779  69.6678 13.55749105 34197
RWASAT-1                
1 44790U 98067QV  20213.19218146 -.00000480  00000-0  00000+0 0  9996
2 44790  51.6426 125.7606 0008521 179.2750 332.8491 15.53128858 39428
AQT-D                   
1 44791U 98067QW  20213.13853664  .00003909  00000-0  68758-4 0  9998
2 44791  51.6430 126.4890 0007237 147.8741 212.2691 15.53075955 39412
NARSSCUBE-1             
1 44792U 98067QX  20213.04485704  .00006248  00000-0  10159-3 0  9995
2 44792  51.6404 125.8422 0005262 165.5445 194.5697 15.54112922 39417
BEIDOU-3 M21            
1 44793U 19078A   20210.93790069 -.00000084  00000-0  10000-3 0  9997
2 44793  55.0539  14.3088 0005769   2.5811 177.2357  1.86232170  4628
BEIDOU-3 M22            
1 44794U 19078B   20211.36595494 -.00000087  00000-0  10000-3 0  9994
2 44794  55.0286  14.2998 0003760   1.1641  16.0233  1.86233164  4630
COSMOS 2542             
1 44797U 19079A   20212.86131052  .00006475  00000-0  11506-3 0  9998
2 44797  97.7931 270.8256 0426153 225.9548 130.5831 14.88820419 36741
TIBA-1                  
1 44800U 19080A   20212.07827080  .00000143  00000-0  00000+0 0  9990
2 44800   0.0533 355.0067 0002150 129.4088 247.4311  1.00267845  2541
INMARSAT GX5            
1 44801U 19080B   20213.01344228 -.00000018  00000-0  00000+0 0  9996
2 44801   0.0154  68.0447 0005876  86.7653 143.4212  1.00970328  2558
CARTOSAT-3              
1 44804U 19081A   20213.13233308  .00000257  00000-0  15449-4 0  9991
2 44804  97.4927 273.5304 0015121  92.6243 328.9652 15.19229100 37432
MESHBED                 
1 44806U 19081C   20212.83766314  .00001307  00000-0  64153-4 0  9990
2 44806  97.4921 273.3892 0015945 104.4779 255.8226 15.19779648 37441
FLOCK 4P-9              
1 44807U 19081D   20212.82622386  .00001531  00000-0  74324-4 0  9990
2 44807  97.4924 273.4259 0015447 106.2724 254.0211 15.19899619 37457
FLOCK 4P-10             
1 44808U 19081E   20212.88719031  .00001289  00000-0  63084-4 0  9992
2 44808  97.4927 273.5068 0015289 105.8061 254.4861 15.19910152 37465
FLOCK 4P-11             
1 44809U 19081F   20212.81351424  .00001877  00000-0  90155-4 0  9996
2 44809  97.4924 273.4434 0015260 106.6374 253.6538 15.19993679 37450
FLOCK 4P-12             
1 44810U 19081G   20212.67762002  .00001300  00000-0  63476-4 0  9997
2 44810  97.4927 273.3264 0015011 106.9786 253.3095 15.19993609 37435
FLOCK 4P-4              
1 44811U 19081H   20212.72249223  .00001416  00000-0  68725-4 0  9999
2 44811  97.4944 273.4528 0014160  98.1790 262.1052 15.20051904 37447
FLOCK 4P-3              
1 44812U 19081J   20212.71768497  .00001165  00000-0  57083-4 0  9994
2 44812  97.4944 273.4579 0014041  97.9417 262.3412 15.20070514 37445
FLOCK 4P-2              
1 44813U 19081K   20212.79348880  .00001353  00000-0  66053-4 0  9999
2 44813  97.4936 273.4996 0013831  98.0696 262.2109 15.19930200 37455
FLOCK 4P-1              
1 44814U 19081L   20212.78940116  .00001293  00000-0  63243-4 0  9990
2 44814  97.4935 273.5035 0013722  98.0749 262.2043 15.19944181 37458
FLOCK 4P-7              
1 44816U 19081N   20212.70580017  .00000511  00000-0  26935-4 0  9993
2 44816  97.4936 273.4569 0013169  99.7215 260.5508 15.19995557 37442
FLOCK 4P-6              
1 44817U 19081P   20213.14337423  .00000553  00000-0  28705-4 0  9997
2 44817  97.4932 273.9394 0012900  98.8044 261.4653 15.20213514 37519
FLOCK 4P-5              
1 44818U 19081Q   20212.88849166  .00000512  00000-0  26907-4 0  9997
2 44818  97.4935 273.6728 0012745 100.3228 259.9444 15.20081992 37479
GAOFEN 12               
1 44819U 19082A   20213.08887107 -.00000027  00000-0  32671-5 0  9997
2 44819  97.9111 234.4766 0001173 110.2283 249.9068 14.80213126 36406
ALE-2                   
1 44824U 19084A   20212.89717533  .00002580  00000-0  40181-4 0  9997
2 44824  96.9767  76.4185 0015004 131.0072 229.2483 15.54685239 36897
NOOR 1A                 
1 44827U 19084D   20212.62380065  .00032966  00000-0  21792-3 0  9994
2 44827  96.9712  81.1847 0031826  76.9113 283.5702 15.74775485 37209
FOSSASAT-1              
1 44829U 19084F   20212.37297224  .00059398  00000-0  30136-3 0  9992
2 44829  96.9690  81.7842 0025103  74.8581 285.5463 15.80857124 37219
ATL-1                   
1 44830U 19084G   20212.75635520  .00071574  00000-0  32211-3 0  9997
2 44830  96.9682  82.4491 0022794  73.9449 286.4329 15.83475268 37293
2019-084H               
1 44831U 19084H   20212.89958453  .00066987  00000-0  30784-3 0  9991
2 44831  96.9680  82.5823 0023117  72.4498 287.9295 15.83033365 37320
SMOG-P                  
1 44832U 19084J   20212.76843176  .00080544  41801-5  33057-3 0  9993
2 44832  96.9680  82.7461 0020689  72.7747 287.5786 15.85753246 37311
COSMOS 2543             
1 44835U 19079D   20212.86037414  .00000050  00000-0  11938-4 0  9991
2 44835  97.8732 265.8598 0010672 100.3575 259.8843 14.85942614 34620
JILIN-01 GAOFEN 2B      
1 44836U 19086A   20212.81992789 -.00000155  00000-0 -57914-5 0  9993
2 44836  97.5087 278.0599 0011170 172.7020 297.0063 15.09268199 35715
2019-087A               
1 44838U 19087A   20213.19500063  .00000996  00000-0  47421-4 0  9990
2 44838  97.4033   8.2554 0013701 149.0517  23.9867 15.21393523 36006
2019-087B               
1 44839U 19087B   20213.19834252  .00000632  00000-0  31294-4 0  9999
2 44839  97.4016   8.2254 0013852 149.9510  22.8715 15.21295529 36001
2019-087C               
1 44840U 19087C   20213.15483568  .00000740  00000-0  35900-4 0  9995
2 44840  97.4087   8.4690 0013051 151.6608 323.5784 15.21508669 36005
2019-087D               
1 44841U 19087D   20213.21194931  .00001672  00000-0  75441-4 0  9995
2 44841  97.3985   8.4752 0011499 145.4440  28.0468 15.22343631 36025
2019-087E               
1 44842U 19087E   20213.20002037  .00000663  00000-0  32308-4 0  9994
2 44842  97.3974   8.2297 0012458 154.0688  18.9459 15.21718665 36011
2019-087F               
1 44843U 19087F   20213.11826810  .00000589  00000-0  29092-4 0  9991
2 44843  97.4030   8.3178 0014071 162.9207 250.9603 15.21658704 35995
COSMOS 2544 [GLONASS-M] 
1 44850U 19088A   20212.62276199 -.00000027  00000-0  00000-0 0  9996
2 44850  64.8467 145.7020 0012855 267.8903  36.5823  2.13102344  4947
TYVAK-0092              
1 44852U 19089A   20212.97333434  .00000356  00000-0  34664-4 0  9994
2 44852  36.9670 200.0185 0006043  76.1819 283.9603 14.99670435 34890
TYVAK-0129              
1 44853U 19089B   20213.14278432  .00000379  00000-0  36776-4 0  9992
2 44853  36.9681 199.5178 0005737  66.9691 293.1640 14.99434371 34926
DUCHIFAT-3              
1 44854U 19089C   20213.18905438  .00000188  00000-0  21621-4 0  9991
2 44854  36.9675 199.8813 0007856  49.5341 310.6078 14.99090342 34861
LEMUR-2-JPGSQUARED      
1 44855U 19089D   20213.08709398  .00000742  00000-0  66586-4 0  9993
2 44855  36.9688 200.9228 0005881  41.5311 318.5875 14.98977820 34906
QPS-SAR-1 IZANAGI       
1 44856U 19089E   20213.09631435  .00000369  00000-0  36845-4 0  9991
2 44856  36.9686 201.9251 0005562  19.4513 340.6441 14.98445611 34897
RISAT-2BR1              
1 44857U 19089F   20213.05725434  .00000083  00000-0  13514-4 0  9992
2 44857  36.9615 203.3779 0006050 357.8859   2.1856 14.97769822 34876
2019-089G               
1 44858U 19089G   20212.66137006  .00000549  00000-0  51773-4 0  9997
2 44858  36.9674 204.9675 0005168   5.7147 354.3652 14.98300180 34822
IHOPSAT-TD              
1 44859U 19089H   20212.89199677  .00000070  00000-0  12326-4 0  9995
2 44859  36.9735 203.1573 0006514  21.2293 338.8722 14.98385264 34876
LEMUR-2-HIMOMANDDAD     
1 44860U 19089J   20213.10888063  .00000030  00000-0  88800-5 0  9993
2 44860  36.9674 201.0954 0006260  38.3435 321.7746 14.98793162 34906
LEMUR-2-PAPPY           
1 44861U 19089K   20213.10118870  .00000025  00000-0  85246-5 0  9991
2 44861  36.9683 201.0309 0006147  38.9506 321.1674 14.98840027 34840
LEMUR-2-THEODOSIA       
1 44863U 19089M   20213.15976854  .00000233  00000-0  25337-4 0  9997
2 44863  36.9674 200.5704 0006158  41.9412 318.1793 14.98911865 34754
BEIDOU-3 M19            
1 44864U 19090A   20212.44656620 -.00000051  00000-0  00000-0 0  9992
2 44864  55.0883 134.7194 0016103 266.9691 309.3906  1.86232040  4231
BEIDOU-3 M20            
1 44865U 19090B   20212.96585270 -.00000055  00000-0  10000-3 0  9990
2 44865  55.0920 134.7313 0015402 282.2427  12.2192  1.86232061  4225
JCSAT-18 (KACIFIC 1)    
1 44868U 19091A   20212.61229937 -.00000215  00000-0  00000+0 0  9994
2 44868   0.0228 120.8610 0000937  46.4617 151.7648  1.00270322  2550
CSG-1                   
1 44873U 19092A   20213.08120894 -.00000057  00000-0 -65341-6 0  9996
2 44873  97.8864  35.6082 0001631  83.2705 276.8693 14.82156324 33430
CHEOPS                  
1 44874U 19092B   20212.79485057  .00000021  00000-0  14946-4 0  9991
2 44874  98.2343  36.7726 0009781 307.7008  52.3303 14.56800243 32812
ANGELS                  
1 44876U 19092D   20212.47543702  .00000207  00000-0  14092-4 0  9998
2 44876  97.4622  35.1245 0011472 219.0774 140.9631 15.16380204 34108
EYESAT-NANO             
1 44877U 19092E   20213.07636381  .00000689  00000-0  38352-4 0  9991
2 44877  97.4560  35.6657 0012474 222.4551 137.5718 15.16892150 34203
OPS-SAT                 
1 44878U 19092F   20212.74850683  .00000717  00000-0  41240-4 0  9990
2 44878  97.4697  35.2877 0013465 200.4021 159.6675 15.15544906 34090
2019-093A               
1 44879U 19093A   20213.16673622  .00000087  00000-0  17904-4 0  9995
2 44879  97.9575 288.8997 0013762 254.0765 105.8952 14.81416030 33177
2019-093B               
1 44880U 19093B   20213.16548353  .00000097  00000-0  19227-4 0  9990
2 44880  97.9574 288.9064 0013907 254.5771 105.3914 14.81432819 33162
CAS-6 (TIANQIN 1)       
1 44881U 19093C   20213.16203751 -.00000033  00000-0  26793-5 0  9999
2 44881  97.9569 288.9153 0013668 254.0171 105.9542 14.81415530 33163
2019-093D               
1 44882U 19093D   20213.08641370  .00000148  00000-0  25708-4 0  9994
2 44882  97.9617 288.9534 0014866 257.4445 102.5106 14.81520400 33154
CBERS 4A                
1 44883U 19093E   20213.14761487  .00000049  00000-0  12935-4 0  9992
2 44883  97.9579 288.9459 0001393  86.6738 273.4637 14.81518613 33178
2019-093F               
1 44884U 19093F   20213.17287941  .00000137  00000-0  24163-4 0  9998
2 44884  97.9553 288.9951 0012264 261.9969  97.9867 14.81839129 33173
FLORIPASAT-1            
1 44885U 19093G   20213.07415982  .00000174  00000-0  28631-4 0  9991
2 44885  97.9567 289.0134 0013287 265.6022  94.3667 14.82053210 33169
2019-093H               
1 44886U 19093H   20213.15577848  .00000132  00000-0  23216-4 0  9999
2 44886  97.9581 289.2626 0013811 271.9793  87.9849 14.82390835 33181
2019-093J               
1 44887U 19093J   20213.15707422  .00000067  00000-0  15094-4 0  9995
2 44887  97.9581 289.2484 0013687 271.3302  88.6355 14.82374391 33184
ELEKTRO-L 3             
1 44903U 19095A   20212.73321288 -.00000111  00000-0  00000+0 0  9998
2 44903   0.0670 285.9987 0001005 245.6863 117.1103  1.00272252  2190
GONETS-M 14             
1 44905U 19096A   20212.78080773  .00000003  00000-0 -53923-4 0  9994
2 44905  82.5261 335.9315 0014743 158.0016 202.1699 12.42893114 26931
GONETS-M 15             
1 44906U 19096B   20212.80732237  .00000001  00000-0 -63306-4 0  9991
2 44906  82.5282 336.0040 0016356 180.1021 180.0058 12.42886370 26924
GONETS-M 16             
1 44907U 19096C   20213.08848945  .00000005  00000-0 -31690-4 0  9992
2 44907  82.5222 335.7766 0010864 211.2076 148.8360 12.43176017 26956
RS-44 & BREEZE-KM R/B   
1 44909U 19096E   20212.82855843  .00000008  00000-0 -21214-4 0  9994
2 44909  82.5240 326.4914 0218390 124.9743 237.2079 12.79708066 27736
SHIJIAN-20 (SJ-20)      
1 44910U 19097A   20213.08809920 -.00000368  00000-0  00000+0 0  9995
2 44910   0.3191  94.7857 0008279 122.7487 248.6800  1.00271019  2169
STARLINK-1073           
1 44914U 20001A   20212.91667824  .00016314  00000-0  11365-2 0  9998
2 44914  53.0000 179.4269 0001448  92.2202 238.1632 15.05572106 31207
STARLINK-1084           
1 44915U 20001B   20212.91667824  .00007800  00000-0  55366-3 0  9999
2 44915  53.0002 179.4269 0001497  94.6657  73.7078 15.05595839 31210
STARLINK-1097           
1 44916U 20001C   20213.25001157 -.00029395  00000-0 -20050-2 0  9995
2 44916  52.9996 177.9331 0001470  93.5990  28.4749 15.05609821 31267
STARLINK-1098           
1 44917U 20001D   20213.25001157  .00006949  00000-0  49566-3 0  9998
2 44917  53.0004 177.9326 0001591  96.6621  61.3994 15.05575058 31265
STARLINK-1099           
1 44918U 20001E   20212.91667824  .00012481  00000-0  87413-3 0  9991
2 44918  52.9983 179.4270 0001577 108.9932 347.3931 15.05584525  1760
STARLINK-1101           
1 44919U 20001F   20212.91667824  .00017209  00000-0  11969-2 0  9997
2 44919  52.9998 179.4265 0001206  79.1829 305.2118 15.05594867 31217
STARLINK-1102           
1 44920U 20001G   20212.91667824  .00017099  00000-0  11893-2 0  9992
2 44920  53.0003 179.4269 0001659 107.6769 258.6834 15.05597878 31215
STARLINK-1103           
1 44921U 20001H   20212.91667824 -.00010055  00000-0 -67166-3 0  9995
2 44921  53.0001 179.4262 0001294  90.3241 312.0585 15.05594156  1766
STARLINK-1104           
1 44922U 20001J   20213.25001157  .00005671  00000-0  40832-3 0  9992
2 44922  52.9988 177.9284 0001234  80.2064   5.9561 15.05556550 31267
STARLINK-1106           
1 44923U 20001K   20212.91667824  .00013833  00000-0  96625-3 0  9994
2 44923  52.9981 179.4268 0001264 102.5348 191.8046 15.05596558  1763
STARLINK-1111           
1 44924U 20001L   20212.91667824  .00016833  00000-0  11718-2 0  9997
2 44924  53.0002 179.4273 0001566  93.7997 254.5917 15.05576176 31202
STARLINK-1112           
1 44925U 20001M   20213.25001157  .00009574  00000-0  67593-3 0  9994
2 44925  52.9995 177.9282 0001234  83.4607 200.6598 15.05546947 31256
STARLINK-1113           
1 44926U 20001N   20212.91667824  .00010333  00000-0  72707-3 0  9994
2 44926  53.0010 179.4205 0001702  88.7211 151.6625 15.05593108  1768
STARLINK-1114           
1 44927U 20001P   20212.91667824  .00008616  00000-0  60979-3 0  9994
2 44927  52.9994 179.4241 0001589  91.7712 112.5664 15.05579320  1760
STARLINK-1119           
1 44928U 20001Q   20212.91667824  .00009414  00000-0  66400-3 0  9997
2 44928  53.0001 179.4255 0001401  85.1424 137.2003 15.05602109  1760
STARLINK-1121           
1 44929U 20001R   20213.25001157 -.00029531  00000-0 -20150-2 0  9992
2 44929  52.9992 177.9291 0001282  80.5147 239.6543 15.05599828 31255
STARLINK-1123           
1 44930U 20001S   20212.91667824  .00009785  00000-0  68983-3 0  9999
2 44930  52.9989 179.4296 0001448 106.7171  25.6334 15.05579592  1767
STARLINK-1128           
1 44931U 20001T   20212.91667824  .00011273  00000-0  79154-3 0  9997
2 44931  53.0008 179.4229 0001464  99.6329 158.6789 15.05584706 31959
STARLINK-1130 (DARKSAT) 
1 44932U 20001U   20212.91667824  .00008258  00000-0  58503-3 0  9992
2 44932  52.9989 179.4283 0001159  93.1495  93.2408 15.05595818  1764
STARLINK-1144           
1 44933U 20001V   20213.25001157  .00005275  00000-0  38107-3 0  9996
2 44933  52.9977 177.9347 0001391  88.8449 339.2682 15.05568828 32553
STARLINK-1071           
1 44934U 20001W   20212.91667824 -.00009037  00000-0 -60175-3 0  9990
2 44934  52.9981 159.4261 0001398  85.9631 228.5497 15.05586305 31482
STARLINK-1072           
1 44935U 20001X   20212.91667824 -.00012236  00000-0 -82219-3 0  9999
2 44935  52.9991 159.4286 0001399  96.2436  20.2948 15.05574003 31491
STARLINK-1078           
1 44936U 20001Y   20212.91667824 -.00009657  00000-0 -64424-3 0  9991
2 44936  52.9995 159.4250 0001484  92.4849 258.0292 15.05594173 31480
STARLINK-1079           
1 44937U 20001Z   20212.91667824 -.00011637  00000-0 -78040-3 0  9992
2 44937  53.0002 159.4244 0001442 100.6878 339.8349 15.05597234 31496
STARLINK-1082           
1 44938U 20001AA  20213.25001157  .00033988  00000-0  23427-2 0  9991
2 44938  53.0001 157.9388 0001642  78.9028 153.3314 15.05546671 31539
STARLINK-1083           
1 44939U 20001AB  20212.91667824 -.00011275  00000-0 -75535-3 0  9998
2 44939  52.9977 159.4388 0001407 104.2048  84.2845 15.05604592 31483
STARLINK-1091           
1 44940U 20001AC  20213.25001157  .00032180  00000-0  22183-2 0  9995
2 44940  52.9960 157.9552 0001536  81.7932  60.4489 15.05569550 31537
STARLINK-1094           
1 44941U 20001AD  20213.25001157  .00032754  00000-0  22579-2 0  9992
2 44941  52.9991 157.9322 0001412  93.2166 300.9692 15.05560390 31549
STARLINK-1096           
1 44942U 20001AE  20213.25001157  .00030884  00000-0  21302-2 0  9998
2 44942  52.9998 157.9301 0001326  86.2039  20.0406 15.05568375 31543
STARLINK-1100           
1 44943U 20001AF  20212.91667824 -.00010657  00000-0 -71272-3 0  9990
2 44943  53.0001 159.4340 0001505  95.6402 110.8238 15.05610714  1765
STARLINK-1108           
1 44944U 20001AG  20213.25001157  .00033440  00000-0  23047-2 0  9999
2 44944  53.0007 157.9331 0001533  86.7182 253.5232 15.05558353 31539
STARLINK-1109           
1 44945U 20001AH  20212.91667824 -.00045181  00000-0 -30995-2 0  9998
2 44945  52.9950 159.4452 0001405  86.2312  66.3521 15.05603207 31488
STARLINK-1110           
1 44946U 20001AJ  20212.91667824 -.00009129  00000-0 -60504-3 0  9991
2 44946  53.0014 159.3863 0000462  41.3075 287.6576 15.05771887 31485
STARLINK-1116           
1 44947U 20001AK  20212.91667824 -.00011456  00000-0 -76810-3 0  9995
2 44947  53.0008 159.4280 0001538  98.0915 324.4069 15.05589703 31492
STARLINK-1122           
1 44949U 20001AM  20212.91667824 -.00009348  00000-0 -62251-3 0  9998
2 44949  52.9989 159.4261 0001322  96.4548 163.9559 15.05625008  1760
STARLINK-1125           
1 44950U 20001AN  20212.91667824 -.00011494  00000-0 -77055-3 0  9993
2 44950  52.9966 159.4396 0001620  96.5710  73.9441 15.05598620  1768
STARLINK-1126           
1 44951U 20001AP  20212.91667824 -.00010358  00000-0 -69155-3 0  9994
2 44951  53.0013 159.4306 0000933  43.0553 207.0961 15.05644717 32774
STARLINK-1117           
1 44952U 20001AQ  20212.91667824 -.00010023  00000-0 -66918-3 0  9998
2 44952  53.0000 159.4267 0001259  96.0239 272.5311 15.05607569 31496
STARLINK-1124           
1 44953U 20001AR  20213.25001157  .00034259  00000-0  23608-2 0  9991
2 44953  53.0004 157.9332 0001497  68.6757 217.5842 15.05552097 32822
STARLINK-1066           
1 44954U 20001AS  20212.91667824 -.00028458  00000-0 -19397-2 0  9999
2 44954  53.0008 139.4291 0001456  78.8334  57.7739 15.05619980 31716
STARLINK-1069           
1 44955U 20001AT  20212.91667824 -.00030843  00000-0 -21050-2 0  9990
2 44955  53.0026 139.4253 0001677  94.8288 347.7942 15.05611606  1763
STARLINK-1070           
1 44956U 20001AU  20212.91667824 -.00030419  00000-0 -20775-2 0  9993
2 44956  53.0008 139.4257 0001523  96.3412   4.3411 15.05579165  1764
STARLINK-1074           
1 44957U 20001AV  20212.91667824 -.00025486  00000-0 -17345-2 0  9991
2 44957  52.9997 139.4275 0001406 105.2949 211.2659 15.05617311 31767
STARLINK-1075           
1 44958U 20001AW  20212.91667824 -.00027623  00000-0 -18818-2 0  9993
2 44958  53.0011 139.4311 0001430  95.7330 256.8415 15.05623290 31760
STARLINK-1076           
1 44959U 20001AX  20212.91667824 -.00023790  00000-0 -16189-2 0  9994
2 44959  52.9970 139.4400 0001412  89.4114 155.2531 15.05583055  1764
STARLINK-1077           
1 44960U 20001AY  20212.91667824 -.00027216  00000-0 -73044-3 0  9997
2 44960  53.0001 135.2778 0001403  85.1239 250.4396 15.37111093 31827
STARLINK-1080           
1 44961U 20001AZ  20212.91667824 -.00024851  00000-0 -16915-2 0  9993
2 44961  53.0007 139.4200 0001417  84.7573 123.8166 15.05598518 31779
STARLINK-1081           
1 44962U 20001BA  20212.91667824 -.00029500  00000-0 -20124-2 0  9997
2 44962  53.0012 139.4269 0001310  91.6317  26.9305 15.05607940 31774
STARLINK-1085           
1 44963U 20001BB  20212.91667824 -.00030683  00000-0 -20956-2 0  9995
2 44963  53.0012 139.4245 0001721  95.3570 329.2413 15.05581994 32508
STARLINK-1086           
1 44964U 20001BC  20212.91667824 -.00030528  00000-0 -20834-2 0  9999
2 44964  53.0010 139.4314 0001441 105.7988 300.9224 15.05608785 31774
STARLINK-1088           
1 44966U 20001BE  20212.91667824 -.00024090  00000-0 -16395-2 0  9995
2 44966  52.9993 139.4260 0001522  93.0492 187.5801 15.05586077  1768
STARLINK-1089           
1 44967U 20001BF  20212.91667824 -.00026613  00000-0 -18130-2 0  9998
2 44967  53.0021 139.4250 0001785  74.3411  98.2850 15.05603844  1760
STARLINK-1090           
1 44968U 20001BG  20212.91667824 -.00025748  00000-0 -17534-2 0  9994
2 44968  53.0014 139.4295 0001574  76.4625 114.1418 15.05600150 31777
STARLINK-1092           
1 44969U 20001BH  20212.91667824 -.00024135  00000-0 -16414-2 0  9990
2 44969  52.9953 139.4502 0001915  91.5216 135.1684 15.05612249 31761
STARLINK-1093           
1 44970U 20001BJ  20212.91667824 -.00026552  00000-0 -18090-2 0  9999
2 44970  52.9999 139.4274 0001480  85.1723 249.4624 15.05599099 31768
STARLINK-1095           
1 44971U 20001BK  20212.91667824 -.00029930  00000-0 -20423-2 0  9996
2 44971  53.0012 139.4303 0001378  86.7366 301.8885 15.05604144  1763
STARLINK-1107           
1 44972U 20001BL  20212.91667824 -.00027764  00000-0 -18929-2 0  9998
2 44972  53.0005 139.4234 0001639  78.9217  75.7209 15.05597129  1768
STARLINK-1115           
1 44973U 20001BM  20212.91667824 -.00024047  00000-0 -16367-2 0  9994
2 44973  52.9991 139.4363 0001500  98.1693 164.4987 15.05582499  1766
TJS-5                   
1 44978U 20002A   20212.83417359  .00000029  00000-0  00000+0 0  9993
2 44978   0.5486 281.3221 0002408 243.3516 263.0392  1.00273561  2163
JILIN-01 KUANFU 01      
1 45016U 20003A   20213.17802394  .00000459  00000-0  19867-4 0  9996
2 45016  97.3140 278.4036 0010847 291.4184  68.5898 15.27259696 30224
NUSAT-7 (SOPHIE)        
1 45017U 20003B   20213.15470335  .00000774  00000-0  30114-4 0  9999
2 45017  97.3063 278.5340 0013026 292.0427  67.9429 15.28948147 30238
NUSAT-8 (MARIE)         
1 45018U 20003C   20213.09952294  .00000693  00000-0  28500-4 0  9998
2 45018  97.3158 278.3982 0010619 293.2263  66.7858 15.27399192 30210
TIANQI-5                
1 45019U 20003D   20213.12688840  .00000523  00000-0  21998-4 0  9992
2 45019  97.3139 278.4676 0011821 297.6322  62.3717 15.27676159 30229
YINHE-1                 
1 45024U 20004A   20213.24512593 -.00154090  00000-0 -22443-1 0  9997
2 45024  86.3965 356.2120 0011853 300.3890  59.3142 14.76867369 29130
GSAT-30                 
1 45026U 20005A   20213.02256675 -.00000179  00000-0  00000+0 0  9992
2 45026   0.0412 106.1808 0005643 345.9453 308.0879  1.00271583  2005
EUTELSAT KONNECT        
1 45027U 20005B   20212.30012821  .00000036  00000-0  00000+0 0  9997
2 45027   0.0159  73.3069 0000437 205.2646 145.1032  1.00273028  2799
STPSAT-4                
1 45043U 98067QY  20212.82723248  .00002394  00000-0  49642-4 0  9996
2 45043  51.6420 131.8770 0005823 161.6026 198.5175 15.50174462 28554
STARLINK-1132           
1 45044U 20006A   20212.91667824 -.00026901  00000-0 -18330-2 0  9992
2 45044  53.0007  99.4284 0001506  92.5179  30.4144 15.05601990  1761
STARLINK-1120           
1 45045U 20006B   20212.91667824 -.00032680  00000-0 -22337-2 0  9996
2 45045  52.9994 119.4298 0001430  89.6478  31.0930 15.05586403 28162
STARLINK-1129           
1 45046U 20006C   20212.91667824 -.00034026  00000-0 -23253-2 0  9991
2 45046  52.9993 119.4316 0001536  88.0077 338.7498 15.05611591 28161
STARLINK-1131           
1 45047U 20006D   20212.91667824 -.00033415  00000-0 -22834-2 0  9997
2 45047  52.9995 119.4288 0001695  81.2595  21.5467 15.05605147 28161
STARLINK-1134           
1 45048U 20006E   20212.91667824 -.00033927  00000-0 -23198-2 0  9990
2 45048  53.0009 119.4252 0001572  99.1433 309.5833 15.05590131 28166
STARLINK-1135           
1 45049U 20006F   20212.91667824 -.00033537  00000-0 -22931-2 0  9992
2 45049  53.0003 119.4293 0001416 103.9944 286.8572 15.05585410 28165
STARLINK-1140           
1 45050U 20006G   20212.91667824 -.00040251  00000-0 -27557-2 0  9994
2 45050  53.0013 119.4298 0001453  94.8075 259.9845 15.05624292 28163
STARLINK-1141           
1 45051U 20006H   20212.91667824 -.00048562  00000-0 -33352-2 0  9998
2 45051  53.0017 119.4296 0001566  90.5617 282.2718 15.05595312 28164
STARLINK-1148           
1 45052U 20006J   20212.91667824 -.00030338  00000-0 -20720-2 0  9996
2 45052  52.9988 119.4291 0001308  93.7797 225.0984 15.05578317 28169
STARLINK-1155           
1 45053U 20006K   20212.91667824 -.00028532  00000-0 -19457-2 0  9995
2 45053  52.9995 119.4292 0001557  85.3117 125.4570 15.05602377 28166
STARLINK-1156           
1 45054U 20006L   20212.91667824 -.00031412  00000-0 -21446-2 0  9998
2 45054  52.9997 119.4283 0001624  81.3832 255.4649 15.05607959 28166
STARLINK-1157           
1 45055U 20006M   20212.85628700  .00008776  00000-0  36575-4 0  9998
2 45055  52.9989  79.5627 0005171 286.2388  73.8066 15.86731488 28382
STARLINK-1158           
1 45056U 20006N   20212.91667824 -.00029308  00000-0 -19997-2 0  9999
2 45056  52.9990 119.4318 0001592  90.7936 209.9736 15.05596622 28168
STARLINK-1159           
1 45057U 20006P   20212.91667824 -.00027813  00000-0 -18962-2 0  9990
2 45057  53.0008 119.4285 0001423  96.2026 150.5404 15.05599438 28170
STARLINK-1162           
1 45058U 20006Q   20212.91667824 -.00028538  00000-0 -19458-2 0  9997
2 45058  53.0010 119.4273 0001633  93.4176 189.4035 15.05609198 28175
STARLINK-1165           
1 45059U 20006R   20212.91667824 -.00030069  00000-0 -20517-2 0  9998
2 45059  53.0011 119.4260 0001594  92.9905  81.7184 15.05608430  1766
STARLINK-1166           
1 45060U 20006S   20212.91667824 -.00028930  00000-0 -19725-2 0  9993
2 45060  52.9996 119.4290 0001587  83.2184 109.4745 15.05616703 28161
STARLINK-1169           
1 45061U 20006T   20212.91667824 -.00031096  00000-0 -21237-2 0  9992
2 45061  53.0009 119.4253 0001332  90.0555  66.7213 15.05591759 28162
STARLINK-1171           
1 45062U 20006U   20212.91667824 -.00027955  00000-0 -19063-2 0  9996
2 45062  53.0009 119.4298 0001626  82.4551 146.2889 15.05592915 28167
STARLINK-1178           
1 45063U 20006V   20212.91667824 -.00032029  00000-0 -21885-2 0  9990
2 45063  53.0010 119.4261 0001564  92.7785  45.9930 15.05587979 28160
STARLINK-1133           
1 45064U 20006W   20212.91667824 -.00022333  00000-0 -15178-2 0  9996
2 45064  53.0007  99.4312 0001586  93.5855 137.3110 15.05594975  1769
STARLINK-1139           
1 45065U 20006X   20212.91667824 -.00022862  00000-0 -15537-2 0  9993
2 45065  53.0001  99.4302 0001416  94.3827 118.5304 15.05609470  1766
STARLINK-1145           
1 45066U 20006Y   20212.91667824 -.00025469  00000-0 -17339-2 0  9992
2 45066  52.9999  99.4289 0001564  91.4862 247.4269 15.05604344  1752
STARLINK-1150           
1 45067U 20006Z   20212.91667824 -.00022935  00000-0 -15596-2 0  9991
2 45067  53.0018  99.4159 0001654  97.9292 168.9332 15.05589431  1769
STARLINK-1161           
1 45068U 20006AA  20212.91667824 -.00024680  00000-0 -16798-2 0  9996
2 45068  53.0001  99.4342 0001655  93.0435 227.8534 15.05596032  1767
STARLINK-1163           
1 45069U 20006AB  20212.91667824 -.00027087  00000-0 -18454-2 0  9992
2 45069  53.0015  99.4281 0001547  90.4531 284.4426 15.05610996 28442
STARLINK-1164           
1 45070U 20006AC  20212.91667824 -.00028354  00000-0 -19340-2 0  9997
2 45070  52.9998  99.4292 0001485 101.4539 309.4525 15.05591401  1769
STARLINK-1167           
1 45071U 20006AD  20212.91667824 -.00028198  00000-0 -19226-2 0  9997
2 45071  53.0002  99.4286 0001684  97.0747 349.8118 15.05602088  1763
STARLINK-1168           
1 45072U 20006AE  20212.91667824 -.00027980  00000-0 -19081-2 0  9995
2 45072  52.9992  99.4296 0001751  89.7685 339.1260 15.05591592  1762
STARLINK-1170           
1 45073U 20006AF  20212.91667824 -.00023418  00000-0 -15933-2 0  9990
2 45073  53.0009  99.4290 0001632  94.9574 189.8628 15.05581042  1768
STARLINK-1172           
1 45074U 20006AG  20212.91667824 -.00026246  00000-0 -17883-2 0  9999
2 45074  52.9999  99.4303 0001511  90.3510 266.5757 15.05590798  1759
STARLINK-1174           
1 45075U 20006AH  20212.91667824 -.00023695  00000-0 -16117-2 0  9994
2 45075  52.9999  99.4281 0001441  93.0486 101.8597 15.05597846  1764
STARLINK-1180           
1 45076U 20006AJ  20212.91667824 -.00025526  00000-0 -17384-2 0  9997
2 45076  53.0014  99.4272 0001359  86.4534  72.4327 15.05593324  1764
STARLINK-1182           
1 45077U 20006AK  20212.91667824 -.00027983  00000-0 -19081-2 0  9993
2 45077  53.0012  99.4279 0001331  87.2623 305.6569 15.05596295  1767
STARLINK-1177           
1 45078U 20006AL  20212.91667824 -.00027418  00000-0 -18690-2 0  9992
2 45078  53.0006  99.4300 0001532  99.1238   5.7512 15.05596655  1769
STARLINK-1149           
1 45079U 20006AM  20212.91667824 -.00024895  00000-0 -16946-2 0  9991
2 45079  52.9936  99.4541 0001447 105.9332  71.0388 15.05596896  1767
STARLINK-1153           
1 45080U 20006AN  20212.91667824 -.00026380  00000-0 -17983-2 0  9996
2 45080  52.9996  99.4274 0001550  81.6287  59.3713 15.05575316 28104
STARLINK-1151           
1 45081U 20006AP  20212.91667824 -.00022793  00000-0 -15494-2 0  9996
2 45081  53.0010  99.4266 0001529  97.6879 151.2560 15.05597649  1763
STARLINK-1160           
1 45082U 20006AQ  20212.91667824 -.00023503  00000-0 -15981-2 0  9998
2 45082  52.9972  99.4384 0001436  90.3357 212.5424 15.05605562  1762
STARLINK-1190           
1 45083U 20006AR  20212.91667824 -.00033887  00000-0 -23165-2 0  9998
2 45083  53.0017 119.4292 0001562  95.6500 349.1229 15.05598557 28162
STARLINK-1173           
1 45084U 20006AS  20212.91667824  .00300054  00000-0  19847-1 0  9991
2 45084  53.0033  79.4400 0001401 109.9058  33.1473 15.05398970 28849
STARLINK-1179           
1 45085U 20006AT  20212.91667824  .00079484  00000-0  54204-2 0  9999
2 45085  53.0008  79.4438 0001398 100.7539  60.3247 15.05530138 28734
STARLINK-1181           
1 45086U 20006AU  20212.89371739  .00003374  00000-0  25053-3 0  9990
2 45086  53.0037  79.5266 0002166  95.7879 264.3356 15.05592288 28383
STARLINK-1185           
1 45087U 20006AV  20212.91667824 -.00011039  00000-0 -73912-3 0  9993
2 45087  53.0017  79.4241 0001570  77.1100 155.8906 15.05605208  1768
STARLINK-1183           
1 45088U 20006AW  20212.91667824  .00054785  00000-0  37534-2 0  9996
2 45088  53.0005  79.4426 0001411 100.7871   6.2817 15.05546909 28382
STARLINK-1136           
1 45089U 20006AX  20212.91667824 -.00018172  00000-0 -12313-2 0  9997
2 45089  53.0018  79.4260 0001415  71.2877  17.7815 15.05577198 28384
STARLINK-1176           
1 45090U 20006AY  20212.91667824 -.00066898  00000-0 -46083-2 0  9998
2 45090  53.0021  79.4423 0001583 111.9392  85.0716 15.05638834  1767
STARLINK-1127           
1 45091U 20006AZ  20212.91667824  .00039788  00000-0  27362-2 0  9994
2 45091  53.0014  79.4503 0001458  98.3435 278.7168 15.05557182 28730
STARLINK-1137           
1 45092U 20006BA  20212.91667824  .00044757  00000-0  30740-2 0  9991
2 45092  52.9992  79.4646 0001484  96.4217 244.6499 15.05549266 28720
STARLINK-1142           
1 45093U 20006BB  20212.91667824 -.00092768  00000-0 -64209-2 0  9996
2 45093  52.9961  79.4732 0001578 117.3960 241.6239 15.05639982 28730
STARLINK-1146           
1 45094U 20006BC  20212.91667824 -.00012041  00000-0 -80881-3 0  9998
2 45094  53.0054  79.4019 0001900  88.7013 198.3631 15.05570653  1760
STARLINK-1147           
1 45095U 20006BD  20212.91667824 -.00011453  00000-0 -76816-3 0  9998
2 45095  52.9998  79.4269 0001796  79.8610 189.2113 15.05576797  1769
STARLINK-1152           
1 45096U 20006BE  20212.91667824 -.00012117  00000-0 -81423-3 0  9997
2 45096  52.9939  79.4680 0001560  80.2257 224.8116 15.05563178  1762
STARLINK-1175           
1 45097U 20006BF  20212.91667824 -.00022682  00000-0 -81140-4 0  9993
2 45097  52.9993  35.8001 0002286  19.8540 235.8871 15.87472799 29448
STARLINK-1184           
1 45098U 20006BG  20212.91667824 -.00149385  00000-0 -10421-1 0  9995
2 45098  53.0008  79.4438 0001445 105.2216 289.7690 15.05687236  1768
STARLINK-1186           
1 45099U 20006BH  20212.91667824 -.00051479  00000-0 -35367-2 0  9999
2 45099  53.0001  79.4246 0001398  88.4555 126.6575 15.05611146  1762
STARLINK-1193           
1 45100U 20006BJ  20212.91667824 -.00017730  00000-0 -12009-2 0  9992
2 45100  53.0015  79.4250 0001477  99.7738 313.3335 15.05574717  1762
STARLINK-1194           
1 45101U 20006BK  20212.91667824  .00034531  00000-0  23789-2 0  9996
2 45101  53.0011  79.4450 0001438  99.9133 331.1512 15.05558562 28730
STARLINK-1195           
1 45102U 20006BL  20212.91667824 -.00013851  00000-0 -93355-3 0  9998
2 45102  52.9974  79.4503 0001344  86.6966 236.4014 15.05571474  1764
STARLINK-1196           
1 45103U 20006BM  20212.91667824 -.00186510  00000-0 -13076-1 0  9992
2 45103  53.0028  79.4430 0001361 101.9443  77.0380 15.05709100 28736
ORBITAL FACTORY 2 (OF-2)
1 45113U 19071C   20212.82313171  .00001130  00000-0  45895-4 0  9995
2 45113  51.6439 154.0214 0012592 212.0312 147.9906 15.33474399 27789
AEROCUBE 14A            
1 45114U 19071D   20213.14047401  .00001175  00000-0  47107-4 0  9999
2 45114  51.6433 152.3797 0011436 215.6253 144.3967 15.33573694 27832
SWAMPSAT-2              
1 45115U 19071E   20213.13363709  .00006602  00000-0  19638-3 0  9998
2 45115  51.6405 150.8699 0011597 215.9192 144.1013 15.35912977 27832
AEROCUBE 14B            
1 45116U 19071F   20213.14018907  .00001246  00000-0  49243-4 0  9992
2 45116  51.6435 152.3814 0010947 217.1160 142.9066 15.33588790 27866
AEROCUBE 15B            
1 45117U 19071G   20213.11218857  .00001550  00000-0  58041-4 0  9990
2 45117  51.6437 152.2026 0010626 212.1583 147.8752 15.33825338 27825
AEROCUBE 15A            
1 45118U 19071H   20213.10851492  .00001609  00000-0  59748-4 0  9998
2 45118  51.6433 152.1755 0010976 210.7530 149.2810 15.33860360 27829
HUSKYSAT-1              
1 45119U 19071J   20212.86069937  .00001066  00000-0  43696-4 0  9998
2 45119  51.6437 153.5229 0011874 208.4061 151.6275 15.33681880 27781
2019-071K               
1 45120U 19071K   20213.08410880  .00001880  00000-0  67125-4 0  9999
2 45120  51.6423 152.0269 0011254 209.1955 150.8399 15.34231553 27718
CIRIS                   
1 45121U 19071L   20213.11227333  .00001531  00000-0  57695-4 0  9997
2 45121  51.6438 152.2094 0011237 208.4556 151.5814 15.33697255 27717
2019-071M               
1 45122U 19071M   20213.09709901  .00002280  00000-0  79411-4 0  9993
2 45122  51.6423 152.1100 0010207 178.8509 181.2498 15.34057534 27712
2019-071N               
1 45123U 19071N   20212.85463525  .00001042  00000-0  42926-4 0  9992
2 45123  51.6437 153.4870 0010279 179.7241 180.3749 15.33701997 27366
2019-071P               
1 45124U 19071P   20213.09354689  .00002181  00000-0  76375-4 0  9990
2 45124  51.6425 152.0909 0010169 179.6470 180.4521 15.34091155 27710
2019-071Q               
1 45125U 19071Q   20213.12552417  .00000780  00000-0  35139-4 0  9990
2 45125  51.6439 152.2932 0010426 179.6129 180.4863 15.33579113 27703
2019-071R               
1 45126U 19071R   20213.13526368  .00001980  00000-0  69778-4 0  9997
2 45126  51.6424 151.6264 0010409 182.5788 177.5143 15.34390473 27725
ONEWEB-0013             
1 45131U 20008A   20212.91667824 -.00036582  00000-0 -10515+0 0  9991
2 45131  87.9738 117.3536 0021144  73.1447 290.5394 13.10361414 25814
ONEWEB-0017             
1 45132U 20008B   20212.91667824 -.00038218  00000-0 -69062-2 0  9994
2 45132  87.4392 108.2781 0024004  50.5373 269.9859 14.66594964 26707
ONEWEB-0020             
1 45133U 20008C   20213.25001157 -.00026642  00000-0 -28461-2 0  9990
2 45133  87.4941 108.0487 0023930  26.6400 203.9237 14.88846372 27077
ONEWEB-0021             
1 45134U 20008D   20213.25001157 -.00021894  00000-0 -18763-2 0  9999
2 45134  87.5624 108.1516 0019742  15.0782  59.1113 14.97628237 27300
ONEWEB-0022             
1 45135U 20008E   20212.91667824 -.00038125  00000-0 -44866-2 0  9999
2 45135  87.5168 108.3058 0020468  43.5117 123.8008 14.85012773 26942
ONEWEB-0023             
1 45136U 20008F   20212.91667824 -.00038468  00000-0 -62659-2 0  9990
2 45136  87.4586 108.2801 0022161  40.9033 275.4888 14.71202892 26758
ONEWEB-0024             
1 45137U 20008G   20212.91667824 -.00037658  00000-0 -46560-2 0  9994
2 45137  87.5053 108.2714 0020209  42.3857  99.7552 14.82972389 26913
ONEWEB-0025             
1 45138U 20008H   20212.91667824 -.00038401  00000-0 -32278-2 0  9993
2 45138  87.5835 108.2557 0022989  32.8924  48.1853 14.98387402 27111
ONEWEB-0026             
1 45139U 20008J   20213.25001157 -.00026296  00000-0 -24702-2 0  9995
2 45139  87.5467 108.1698 0022570  22.3421 155.4856 14.94003838 27287
ONEWEB-0028             
1 45140U 20008K   20213.25001157 -.00021381  00000-0 -20639-2 0  9992
2 45140  87.5443 108.1854 0022527  20.4351 325.6748 14.92912096 26351
ONEWEB-0032             
1 45141U 20008L   20212.91667824 -.00039521  00000-0 -47471-2 0  9992
2 45141  87.5081 108.2677 0022433  41.4233 184.9663 14.84144590 27048
ONEWEB-0033             
1 45142U 20008M   20213.25001157 -.00022666  00000-0 -28722-2 0  9992
2 45142  87.4999 108.1844 0021579  42.7170  58.4162 14.81857739 27069
ONEWEB-0035             
1 45143U 20008N   20213.25001157 -.00023803  00000-0 -44012-2 0  9997
2 45143  87.4401 108.2127 0023034  49.6249 168.0252 14.65507627 26858
ONEWEB-0036             
1 45144U 20008P   20213.25001157 -.00021762  00000-0 -23921-2 0  9996
2 45144  87.5232 108.1692 0022107  35.7141  23.5410 14.87702785 27145
ONEWEB-0038             
1 45145U 20008Q   20213.25001157 -.00023898  00000-0 -41025-2 0  9994
2 45145  87.4502 108.1883 0023164  43.7453 146.6752 14.68806286 26804
ONEWEB-0039             
1 45146U 20008R   20213.16667824  .00040672  00000-0  54777-2 0  9999
2 45146  87.4502 108.1510 0020039  39.9293 145.2369 14.78861495 26925
ONEWEB-0040             
1 45147U 20008S   20213.16667824  .00036399  00000-0  72456-2 0  9995
2 45147  87.4391 108.1697 0021849  57.9823 303.8661 14.61726601 26677
ONEWEB-0041             
1 45148U 20008T   20213.25001157 -.00026748  00000-0 -28535-2 0  9995
2 45148  87.5303 108.1848 0018722  36.1861 174.5316 14.88988391 27077
ONEWEB-0043             
1 45149U 20008U   20213.25001157 -.00023128  00000-0 -31210-2 0  9999
2 45149  87.4884 108.1579 0020085  43.2800  70.6011 14.79241337 26911
ONEWEB-0044             
1 45150U 20008V   20213.25001157 -.00023167  00000-0 -21384-2 0  9995
2 45150  87.5513 108.1996 0018558  30.9736  79.5390 14.94750192 27155
ONEWEB-0045             
1 45151U 20008W   20213.25001157 -.00022313  00000-0 -37360-2 0  9999
2 45151  87.4556 108.1926 0019405  47.7388 292.1711 14.69951856 26809
ONEWEB-0047             
1 45152U 20008X   20213.25001157 -.00023842  00000-0 -34437-2 0  9990
2 45152  87.4824 108.1821 0020009  39.4107 113.6770 14.76355285 26906
ONEWEB-0048             
1 45153U 20008Y   20213.25001157 -.00022696  00000-0 -49234-2 0  9998
2 45153  87.4033 108.1708 0021252  58.5635 243.3114 14.58281441 26615
ONEWEB-0049             
1 45154U 20008Z   20213.25001157 -.00022399  00000-0 -39207-2 0  9998
2 45154  87.4462 108.1831 0024114  45.7922 291.6345 14.67923275 26773
ONEWEB-0051             
1 45155U 20008AA  20213.25001157 -.00022384  00000-0 -21788-2 0  9998
2 45155  87.5438 108.1908 0023102  26.0567  57.2678 14.92573637 27133
ONEWEB-0052             
1 45156U 20008AB  20213.25001157 -.00023638  00000-0 -45390-2 0  9997
2 45156  87.4284 108.1799 0023693  55.8774 141.0669 14.63802296 26691
ONEWEB-0053             
1 45157U 20008AC  20213.25001157 -.00023813  00000-0 -45132-2 0  9997
2 45157  87.4302 108.1753 0023522  56.4152 169.8992 14.64392644 26702
ONEWEB-0054             
1 45158U 20008AD  20213.25001157 -.00022353  00000-0 -37071-2 0  9999
2 45158  87.4550 108.1811 0023229  42.7560  30.1768 14.70314846 26782
ONEWEB-0056             
1 45159U 20008AE  20213.25001157 -.00021870  00000-0 -32741-2 0  9992
2 45159  87.4758 108.1975 0019748  39.0426   9.3464 14.74813561 26856
ONEWEB-0057             
1 45160U 20008AF  20213.25001157 -.00022333  00000-0 -35134-2 0  9998
2 45160  87.4618 108.2285 0020872  38.3898 297.0423 14.72655387 26829
ONEWEB-0058             
1 45161U 20008AG  20213.25001157 -.00021367  00000-0 -21691-2 0  9998
2 45161  87.5361 108.1799 0022147  37.6996 324.9935 14.90910103 27079
ONEWEB-0059             
1 45162U 20008AH  20213.25001157 -.00026317  00000-0 -30529-2 0  9999
2 45162  87.5210 108.1871 0023213  35.2520 186.6221 14.85500645 26997
ONEWEB-0062             
1 45163U 20008AJ  20213.25001157 -.00021859  00000-0 -32464-2 0  9996
2 45163  87.4746 108.1794 0019257  38.4384   6.2358 14.75163918 26840
ONEWEB-0065             
1 45164U 20008AK  20213.25001157 -.00022175  00000-0 -43308-2 0  9998
2 45164  87.4280 108.1966 0024802  52.2634 359.5851 14.63013080 26673
AC 10 PROBE (GANGESTAD) 
1 45174U 19022L   20212.60545246  .00887453  00000-0  51869-2 0  9990
2 45174  51.6250 167.9339 0007869 142.8417 217.3128 15.75299059 26113
STARLINK-1138           
1 45178U 20012A   20212.91667824  .00070461  00000-0  48108-2 0  9991
2 45178  52.9993 239.4275 0000623  94.1945 157.7510 15.05550140 25558
STARLINK-1143           
1 45179U 20012B   20213.25001157 -.00028265  00000-0 -19270-2 0  9996
2 45179  52.9959 237.9385 0001416  99.8185 141.8592 15.05607788 25606
STARLINK-1192           
1 45180U 20012C   20213.25001157  .00087587  00000-0  59679-2 0  9995
2 45180  52.9975 237.9488 0001253 114.2542 181.4785 15.05507188 25605
STARLINK-1200           
1 45181U 20012D   20212.91667824  .00073128  00000-0  49904-2 0  9993
2 45181  52.9990 239.4249 0001054 103.8960 202.0441 15.05549518  1765
STARLINK-1201           
1 45182U 20012E   20213.25001157 -.00022989  00000-0 -15634-2 0  9997
2 45182  52.9991 217.9210 0001272  96.3423 111.4011 15.05587733 25895
STARLINK-1202           
1 45183U 20012F   20212.91667824  .00054799  00000-0  37531-2 0  9991
2 45183  52.9984 239.4268 0001063  95.7670  30.1753 15.05559276 25559
STARLINK-1205           
1 45184U 20012G   20212.91667824  .00034879  00000-0  24030-2 0  9999
2 45184  53.0000 219.4234 0001345 107.1034  57.0415 15.05551678  1765
STARLINK-1216           
1 45185U 20012H   20212.91667824  .00032947  00000-0  22713-2 0  9990
2 45185  52.9992 239.4288 0001122 107.6110  54.4156 15.05556148  1768
STARLINK-1224           
1 45186U 20012J   20212.91667824  .00036732  00000-0  25294-2 0  9994
2 45186  53.0003 239.4263 0000816 100.4106 331.6143 15.05545838 25555
STARLINK-1225           
1 45187U 20012K   20213.25001157 -.00000167  00000-0  77040-5 0  9997
2 45187  52.9968 237.9444 0001295 110.7473 310.9054 15.05537984 25353
STARLINK-1228           
1 45188U 20012L   20213.25001157 -.00066802  00000-0 -46031-2 0  9990
2 45188  52.9957 197.9693 0001400  91.3964 118.5244 15.05626871 25829
STARLINK-1230           
1 45189U 20012M   20213.25001157 -.00034542  00000-0 -23618-2 0  9993
2 45189  52.9999 237.9314 0001256 100.9530 302.7058 15.05600273 25318
STARLINK-1234           
1 45190U 20012N   20213.25001157 -.00014004  00000-0 -94349-3 0  9996
2 45190  52.9999 197.9380 0001215  81.4933 164.4588 15.05592865 25838
STARLINK-1236           
1 45191U 20012P   20213.25001157  .00068373  00000-0  46683-2 0  9990
2 45191  52.9968 237.9389 0001035 110.7237 256.9229 15.05565543 25609
STARLINK-1237           
1 45192U 20012Q   20212.91667824  .00033392  00000-0  23016-2 0  9990
2 45192  52.9975 239.4257 0001235 111.3616  32.6134 15.05555950 25266
STARLINK-1239           
1 45193U 20012R   20212.91667824  .00032875  00000-0  22657-2 0  9996
2 45193  52.9989 239.4280 0001083 101.0225  96.9276 15.05568132 25267
STARLINK-1240           
1 45194U 20012S   20213.25001157  .00075432  00000-0  51514-2 0  9996
2 45194  52.9973 237.9444 0001027 110.0757  77.6670 15.05505583 25315
STARLINK-1241           
1 45195U 20012T   20212.91667824  .00033310  00000-0  22956-2 0  9995
2 45195  52.9985 239.4224 0001201 107.7555 108.1917 15.05562694 25269
STARLINK-1244           
1 45196U 20012U   20212.91667824  .00035701  00000-0  24591-2 0  9999
2 45196  52.9976 239.4295 0001211 111.9244 158.1044 15.05548612  1760
STARLINK-1269           
1 45197U 20012V   20212.91667824  .00038042  00000-0  26188-2 0  9995
2 45197  52.9995 239.4250 0001221 114.9004 209.1221 15.05540265 25284
STARLINK-1154           
1 45198U 20012W   20212.91667824  .00044494  00000-0  30564-2 0  9997
2 45198  52.9990 219.4271 0001267  99.8619 298.2882 15.05546365  1760
STARLINK-1197           
1 45199U 20012X   20212.91667824  .00051059  00000-0  34993-2 0  9993
2 45199  52.9997 219.4243 0001462  93.8195 286.2421 15.05567700 25541
STARLINK-1198           
1 45200U 20012Y   20212.91667824  .00044996  00000-0  30904-2 0  9996
2 45200  52.9977 219.4287 0001329  96.6496 265.4500 15.05547013 25549
STARLINK-1199           
1 45201U 20012Z   20212.91667824  .00044372  00000-0  30470-2 0  9993
2 45201  52.9972 219.4284 0001436  89.9684 254.1168 15.05560295 25547
STARLINK-1203           
1 45202U 20012AA  20213.09403987  .00000709  00000-0  67634-4 0  9992
2 45202  52.9830 238.5284 0000313 111.9894 248.1127 15.05693574 25315
STARLINK-1204           
1 45203U 20012AB  20212.91667824  .00036488  00000-0  25114-2 0  9993
2 45203  52.9971 219.4301 0001133 113.7963 122.3163 15.05566837 25514
STARLINK-1206           
1 45204U 20012AC  20213.25001157 -.00023726  00000-0 -16144-2 0  9999
2 45204  52.9994 217.9273 0001313  91.6485  98.1857 15.05584863 25591
STARLINK-1208           
1 45205U 20012AD  20212.91667824  .00042400  00000-0  29132-2 0  9990
2 45205  53.0003 219.4235 0001313  94.4675 339.6506 15.05560977 25552
STARLINK-1209           
1 45206U 20012AE  20212.91667824  .00026340  00000-0  18205-2 0  9995
2 45206  52.9998 199.4263 0001418  91.9734 128.1746 15.05573489 25803
STARLINK-1210           
1 45207U 20012AF  20212.91667824  .00043630  00000-0  29964-2 0  9991
2 45207  52.9997 219.4275 0001113  88.4904 237.5693 15.05563780 25541
STARLINK-1211           
1 45208U 20012AG  20212.91667824  .00040659  00000-0  27969-2 0  9993
2 45208  52.9977 219.4282 0001350 100.2159 189.9787 15.05536490 25548
STARLINK-1218           
1 45209U 20012AH  20212.91667824  .00036002  00000-0  24777-2 0  9995
2 45209  53.0001 219.4231 0001314  91.8421  54.2762 15.05576741 25540
STARLINK-1219           
1 45210U 20012AJ  20212.91667824  .00042550  00000-0  29233-2 0  9994
2 45210  52.9989 219.4249 0001261  99.9755 208.0821 15.05561589 25543
STARLINK-1231           
1 45212U 20012AL  20212.91667824  .00039481  00000-0  27139-2 0  9998
2 45212  52.9983 219.4239 0001459  99.7207  10.4001 15.05577402  1750
STARLINK-1232           
1 45213U 20012AM  20212.91667824  .00041304  00000-0  28381-2 0  9996
2 45213  52.9991 219.4277 0000967  89.9499   2.1860 15.05570311 25545
STARLINK-1233           
1 45214U 20012AN  20212.91667824  .00037738  00000-0  25978-2 0  9992
2 45214  52.9984 219.4296 0001371  94.8672 159.2747 15.05545230  1754
STARLINK-1245           
1 45215U 20012AP  20212.91667824  .00039695  00000-0  27291-2 0  9997
2 45215  52.9975 219.4274 0000887 101.4790 170.6959 15.05568456 25537
STARLINK-1254           
1 45216U 20012AQ  20212.91667824  .00037590  00000-0  25873-2 0  9994
2 45216  52.9996 219.4207 0001392  94.3338  33.7476 15.05551307 25548
STARLINK-1271           
1 45217U 20012AR  20212.91667824  .00044010  00000-0  30221-2 0  9993
2 45217  52.9983 219.4230 0000905 102.8548 313.2649 15.05565032 25546
STARLINK-1105           
1 45218U 20012AS  20212.91667824  .00026404  00000-0  18244-2 0  9992
2 45218  53.0007 199.4190 0001377 103.6163  62.6248 15.05583065  1757
STARLINK-1187           
1 45219U 20012AT  20213.25001157  .00017026  00000-0  11852-2 0  9990
2 45219  53.0006 197.9470 0001470 111.0711 260.8818 15.05569892 25862
STARLINK-1188           
1 45220U 20012AU  20213.25001157 -.00013824  00000-0 -93124-3 0  9999
2 45220  53.0006 197.9245 0001323  86.3441 249.5258 15.05587376 25875
STARLINK-1189           
1 45221U 20012AV  20213.25001157 -.00024350  00000-0 -16568-2 0  9992
2 45221  53.0015 197.9410 0001428  76.1508 277.7818 15.05601598 25875
STARLINK-1191           
1 45222U 20012AW  20213.25001157  .00055741  00000-0  38186-2 0  9999
2 45222  53.0020 197.9450 0001389  86.3950 231.5765 15.05541341 25874
STARLINK-1212           
1 45223U 20012AX  20213.25001157 -.00015346  00000-0 -10358-2 0  9990
2 45223  53.0009 197.9333 0001544  79.9763 111.9820 15.05598472 25875
STARLINK-1214           
1 45224U 20012AY  20213.25001157 -.00010054  00000-0 -67193-3 0  9992
2 45224  52.9946 197.9574 0001462  71.8362 192.0664 15.05575115 25882
STARLINK-1215           
1 45225U 20012AZ  20213.25001157 -.00036642  00000-0 -25068-2 0  9998
2 45225  53.0002 197.9318 0001354  78.9611  23.0181 15.05607392 25878
STARLINK-1217           
1 45226U 20012BA  20213.25001157 -.00005519  00000-0 -35983-3 0  9996
2 45226  52.9998 197.9412 0001423  99.9632 325.9802 15.05585783 25849
STARLINK-1221           
1 45227U 20012BB  20213.25001157 -.00049335  00000-0 -33862-2 0  9994
2 45227  53.0017 197.9456 0001376  79.2173 220.6959 15.05625671 25845
STARLINK-1222           
1 45228U 20012BC  20213.25001157  .00171375  00000-0  11513-1 0  9991
2 45228  53.0008 197.9439 0001414  84.0401 197.8537 15.05531936 25878
STARLINK-1226           
1 45229U 20012BD  20212.91667824  .00029578  00000-0  20398-2 0  9992
2 45229  53.0013 199.4184 0001558  88.7217  23.5338 15.05595130 25829
STARLINK-1227           
1 45230U 20012BE  20213.25001157 -.00121846  00000-0 -84744-2 0  9991
2 45230  53.0007 197.9343 0001622  72.9164  65.0450 15.05639254 25879
STARLINK-1229           
1 45231U 20012BF  20213.25001157  .00001242  00000-0  10435-3 0  9994
2 45231  53.0019 197.9339 0001332  82.2532 325.6855 15.05586736 25879
STARLINK-1235           
1 45232U 20012BG  20212.91667824  .00032034  00000-0  22094-2 0  9992
2 45232  53.0013 199.4202 0001408  86.6399 349.6289 15.05552013 25804
STARLINK-1238           
1 45233U 20012BH  20212.91667824  .00035419  00000-0  24391-2 0  9995
2 45233  52.9964 219.4427 0001079 105.9246 112.1808 15.05560928 25534
STARLINK-1243           
1 45234U 20012BJ  20213.25001157 -.00034011  00000-0 -23240-2 0  9996
2 45234  52.9996 237.9304 0000876 102.8503 282.8277 15.05616992 25342
STARLINK-1246           
1 45235U 20012BK  20212.91667824  .00035938  00000-0  24743-2 0  9994
2 45235  52.9999 239.4249 0001165  97.6003 352.3402 15.05562059  1766
STARLINK-1247           
1 45236U 20012BL  20213.25001157 -.00018608  00000-0 -12606-2 0  9996
2 45236  53.0014 197.9327 0001426  82.6313  73.3607 15.05600308 25875
STARLINK-1270           
1 45237U 20012BM  20212.91667824  .00034583  00000-0  23830-2 0  9997
2 45237  53.0012 199.4179 0001329  90.0643 292.2283 15.05549695 25825
JCSAT-17                
1 45245U 20013A   20211.72315421 -.00000322  00000-0  00000+0 0  9994
2 45245   6.8539 344.0239 0003624 157.2187 202.9136  1.00272698  1700
GEO-KOMPSAT-2B          
1 45246U 20013B   20212.85465844 -.00000358  00000-0  00000+0 0  9994
2 45246   0.0506 255.9986 0000610 300.0868 188.7565  1.00269985  1720
XJS C                   
1 45249U 20014A   20213.18451197  .00001433  00000-0  51982-4 0  9995
2 45249  35.0140 131.7369 0009974 278.2743 229.4227 15.28328990 24855
XJS D                   
1 45250U 20014B   20213.18445221  .00001423  00000-0  51614-4 0  9999
2 45250  35.0148 131.7433 0009762 277.1692 230.5135 15.28326188 24851
XJS E                   
1 45251U 20014C   20213.18463899  .00002793  00000-0  99924-4 0  9998
2 45251  35.0145 131.7423 0009681 280.1993 227.5232 15.28336946 24819
XJS F                   
1 45253U 20014E   20213.11681009  .00001689  00000-0  61036-4 0  9994
2 45253  35.0142 132.1610 0009151 277.3831 218.1485 15.28324958 24807
MERIDIAN 9              
1 45254U 20015A   20212.91312791  .00000128  00000-0  00000+0 0  9998
2 45254  62.9144 204.6617 7122354 295.3934 287.4361  2.00582616  3232
HARP                    
1 45256U 98067QZ  20212.79520818  .00004543  00000-0  83432-4 0  9999
2 45256  51.6421 130.9248 0007483 153.8300 206.3069 15.51393501 25233
1998-067RA              
1 45257U 98067RA  20212.79610944  .00003625  00000-0  68274-4 0  9997
2 45257  51.6416 130.9253 0008195 157.4407 202.6944 15.51337462 25238
PHOENIX                 
1 45258U 98067RB  20212.80857334  .00003107  00000-0  60044-4 0  9998
2 45258  51.6413 131.0019 0006522 138.2769 221.8719 15.51141338 25203
1998-067RC              
1 45259U 98067RC  20212.83883689  .00005231  00000-0  93516-4 0  9994
2 45259  51.6411 130.4604 0007457 154.8215 205.3139 15.51808292 25249
CRYOCUBE                
1 45260U 98067RD  20212.82424213  .00005102  00000-0  90971-4 0  9998
2 45260  51.6411 130.3639 0008223 154.5545 205.5851 15.51937504 25256
AZTECHSAT-1             
1 45261U 98067RE  20212.86727326  .00006191  00000-0  10724-3 0  9990
2 45261  51.6411 129.9016 0007919 154.8792 205.2585 15.52333879 25255
RADSAT-U                
1 45262U 98067RF  20212.85493242  .00003709  00000-0  69493-4 0  9997
2 45262  51.6421 130.5738 0006705 190.5263 169.5587 15.51415450 25257
QARMAN                  
1 45263U 98067RG  20212.79308191  .00005310  00000-0  95485-4 0  9999
2 45263  51.6413 130.9034 0007488 151.4444 208.6957 15.51604827 25263
SORTIE                  
1 45264U 98067RH  20213.03148991  .00002518  00000-0  50821-4 0  9990
2 45264  51.6424 130.2492 0007770 162.8458 197.2795 15.50743906 25266
BEIDOU-3 G2             
1 45344U 20017A   20212.15674436 -.00000150  00000-0  00000-0 0  9990
2 45344   2.8922 328.6326 0002957 142.7845 333.2745  1.00270385  1733
COSMOS 2545 [GLONASS-M] 
1 45358U 20018A   20213.12884919 -.00000047  00000-0  00000+0 0  9997
2 45358  64.8387  27.0857 0006843 241.8388 301.2894  2.13102889  2909
STARLINK-1279           
1 45360U 20019A   20213.25001157  .00041167  00000-0  28293-2 0  9994
2 45360  52.9800 328.0363 0001288  96.1932 288.8542 15.05563372 21555
STARLINK-1301           
1 45361U 20019B   20213.25001157  .00043394  00000-0  29804-2 0  9998
2 45361  53.0044 337.9095 0001023 115.8018 205.2322 15.05564365 21417
STARLINK-1306           
1 45362U 20019C   20212.91667824 -.00029294  00000-0 -19984-2 0  9993
2 45362  52.9918 359.4801 0001476  98.1055  87.0561 15.05602367 21096
STARLINK-1313           
1 45364U 20019E   20212.91667824 -.00023898  00000-0 -16248-2 0  9999
2 45364  52.9858 329.4990 0001332  82.5324 312.8227 15.05619525 21502
STARLINK-1317           
1 45365U 20019F   20213.25001157  .00044009  00000-0  30229-2 0  9999
2 45365  52.9990 337.9349 0001425 110.8416 300.1366 15.05553812 21412
STARLINK-1262           
1 45366U 20019G   20213.25001157  .00037074  00000-0  25515-2 0  9996
2 45366  52.9997 357.9300 0001250 124.9490 139.8939 15.05561817 21140
STARLINK-1273           
1 45367U 20019H   20213.25001157  .00136795  00000-0  92403-2 0  9997
2 45367  53.0005 357.9423 0001518 115.6007  41.2497 15.05526926 21144
STARLINK-1276           
1 45368U 20019J   20212.91667824 -.00048275  00000-0 -33128-2 0  9995
2 45368  52.9986 359.4431 0001386 106.6213 186.5108 15.05623125 21094
STARLINK-1277           
1 45369U 20019K   20213.25001157  .00039564  00000-0  27206-2 0  9994
2 45369  52.9981 357.9297 0001314 120.1659 198.7483 15.05562446 21144
STARLINK-1281           
1 45370U 20019L   20213.25001157  .00041259  00000-0  28362-2 0  9994
2 45370  52.9982 357.9441 0001290 107.9181 264.8700 15.05555119 21128
STARLINK-1287           
1 45371U 20019M   20213.25001157  .00035371  00000-0  24354-2 0  9990
2 45371  53.0003 357.9331 0001291 117.4574  93.3483 15.05567440 21141
STARLINK-1288           
1 45372U 20019N   20213.25001157  .00036282  00000-0  24970-2 0  9997
2 45372  52.9994 357.9290 0001181 121.2793  17.5656 15.05572712 21145
STARLINK-1295           
1 45373U 20019P   20212.91667824 -.00019272  00000-0 -13064-2 0  9993
2 45373  53.0001 359.4372 0001205 105.7509 115.3686 15.05599303 21093
STARLINK-1300           
1 45374U 20019Q   20212.91667824  .00024716  00000-0  17096-2 0  9990
2 45374  53.0003 359.4392 0001217 102.7747 136.4033 15.05578247 21096
STARLINK-1302           
1 45375U 20019R   20212.91667824  .00026352  00000-0  18211-2 0  9999
2 45375  53.0004 359.4378 0001105 105.6748  61.4812 15.05578145 21090
STARLINK-1304           
1 45376U 20019S   20212.91667824  .00087755  00000-0  59775-2 0  9994
2 45376  52.9997 359.4478 0001358 111.0174 326.1855 15.05517627 21091
STARLINK-1305           
1 45377U 20019T   20213.25001157  .00038123  00000-0  26225-2 0  9993
2 45377  52.9991 357.9307 0001265 125.1827 157.7407 15.05566412 21141
STARLINK-1310           
1 45378U 20019U   20213.25001157  .00041141  00000-0  28277-2 0  9997
2 45378  53.0013 357.9287 0001363 120.4547 234.3938 15.05561657 21149
STARLINK-1319           
1 45379U 20019V   20212.91667824 -.00136603  00000-0 -95101-2 0  9999
2 45379  53.0012 359.4461 0001425 116.6195 212.4713 15.05689088 21096
STARLINK-1207           
1 45380U 20019W   20213.25001157  .00036194  00000-0  24934-2 0  9998
2 45380  53.0006 337.9271 0001683 137.1746  93.8235 15.05536716 21423
STARLINK-1258           
1 45381U 20019X   20213.25001157  .00034872  00000-0  24012-2 0  9997
2 45381  53.0007 337.9264 0001149 124.5872  52.4771 15.05572017 21413
STARLINK-1264           
1 45382U 20019Y   20213.25001157  .00003676  00000-0  27117-3 0  9996
2 45382  52.9995 337.9252 0001504 124.7618 160.2210 15.05601802 21231
STARLINK-1266           
1 45383U 20019Z   20213.25001157  .00035123  00000-0  24187-2 0  9992
2 45383  53.0010 337.9284 0001243 118.2279  94.7776 15.05565791 21412
STARLINK-1267           
1 45384U 20019AA  20212.91667824 -.00013347  00000-0 -89869-3 0  9999
2 45384  53.0000 339.4228 0001536 114.3848 144.8946 15.05575607  1759
STARLINK-1268           
1 45385U 20019AB  20212.91667824 -.00008957  00000-0 -77937-4 0  9995
2 45385  53.0001 315.0678 0002615  67.4800 156.4650 15.66844063 21716
STARLINK-1272           
1 45386U 20019AC  20212.91667824 -.00012043  00000-0 -80897-3 0  9995
2 45386  53.0098 339.3649 0001349 107.3289 133.9660 15.05571666 21370
STARLINK-1274           
1 45387U 20019AD  20213.25001157  .00038697  00000-0  31581-2 0  9991
2 45387  53.0004 335.1690 0001476 106.9820  18.3619 14.99080698 21434
STARLINK-1280           
1 45388U 20019AE  20212.91667824 -.00100812  00000-0 -69843-2 0  9996
2 45388  52.9986 359.4518 0001488 108.7028 310.4134 15.05655931 21099
STARLINK-1283           
1 45389U 20019AF  20212.91667824 -.00008456  00000-0 -56175-3 0  9993
2 45389  52.9984 339.4310 0001191 109.6759 311.5481 15.05588182  1760
STARLINK-1284           
1 45390U 20019AG  20212.91667824 -.00007232  00000-0 -47786-3 0  9994
2 45390  53.0005 339.4251 0001292 100.4736  86.8683 15.05568165  1752
STARLINK-1289           
1 45391U 20019AH  20212.91667824 -.00005567  00000-0 -36289-3 0  9990
2 45391  53.0010 339.4244 0001326  86.5186  28.6916 15.05610889  1767
STARLINK-1290           
1 45392U 20019AJ  20213.25001157  .00045035  00000-0  30915-2 0  9991
2 45392  52.9840 338.0134 0001605 123.9166 251.1680 15.05565188 21140
STARLINK-1291           
1 45393U 20019AK  20213.25001157  .00042008  00000-0  28847-2 0  9990
2 45393  53.0015 337.9265 0001538 114.7287 188.2135 15.05585322 21177
STARLINK-1292           
1 45394U 20019AL  20213.25001157  .00035591  00000-0  24488-2 0  9997
2 45394  53.0000 337.9290 0001387 104.3428  54.6384 15.05591721 21154
STARLINK-1297           
1 45395U 20019AM  20213.25001157 -.00008877  00000-0 -59075-3 0  9998
2 45395  53.0100 337.8816 0001331 110.2187 246.8462 15.05586093 21187
STARLINK-1303           
1 45396U 20019AN  20213.25001157  .00039956  00000-0  27467-2 0  9998
2 45396  53.0013 337.9302 0001020 104.1474   0.8497 15.05569303 21134
STARLINK-1307           
1 45397U 20019AP  20212.91667824 -.00012105  00000-0 -81295-3 0  9999
2 45397  52.9882 339.4879 0001317 117.0175 268.2945 15.05584366 21193
STARLINK-1312           
1 45398U 20019AQ  20212.91667824 -.00007077  00000-0 -46692-3 0  9994
2 45398  52.9992 339.4261 0001205 117.7786 321.5032 15.05590051  1761
STARLINK-1255           
1 45399U 20019AR  20212.91667824 -.00046515  00000-0 -31926-2 0  9998
2 45399  53.0014 329.4192 0001310  86.2794 219.1162 15.05599367  1751
STARLINK-1213           
1 45400U 20019AS  20213.25001157  .00034468  00000-0  23747-2 0  9994
2 45400  53.0009 327.9204 0001322  88.8844 152.1540 15.05557088 21293
STARLINK-1256           
1 45401U 20019AT  20212.91667824 -.00014099  00000-0 -95026-3 0  9996
2 45401  53.0013 329.4357 0001400  95.0584 156.3110 15.05585582 21315
STARLINK-1257           
1 45402U 20019AU  20213.25001157  .00036594  00000-0  25189-2 0  9990
2 45402  53.0020 327.9210 0001240 110.7290 166.3574 15.05561998 21361
STARLINK-1259           
1 45403U 20019AV  20213.25001157  .00032771  00000-0  22594-2 0  9991
2 45403  53.0024 327.9230 0001300 122.1154  82.9261 15.05555144 21363
STARLINK-1260           
1 45404U 20019AW  20213.25001157  .00034302  00000-0  23628-2 0  9993
2 45404  53.0007 327.9226 0001316  94.8543  38.1495 15.05566925 21275
STARLINK-1263           
1 45405U 20019AX  20212.91667824 -.00047348  00000-0 -32492-2 0  9990
2 45405  53.0000 329.4162 0001353  96.2244 119.0999 15.05613874 21229
STARLINK-1265           
1 45406U 20019AY  20212.91667824 -.00050335  00000-0 -34553-2 0  9991
2 45406  53.0008 329.4338 0001445  99.1921  62.1338 15.05630431 21222
STARLINK-1275           
1 45407U 20019AZ  20213.25001157 -.00017864  00000-0 -12096-2 0  9999
2 45407  52.9999 327.9271 0001337  98.3481 358.7933 15.05591044 21277
STARLINK-1278           
1 45408U 20019BA  20212.91667824 -.00015136  00000-0 -10216-2 0  9993
2 45408  52.9976 359.4441 0001200 104.9967 296.1930 15.05588532  1766
STARLINK-1282           
1 45409U 20019BB  20213.25001157  .00039870  00000-0  27410-2 0  9996
2 45409  52.9976 327.9274 0001134  94.6307 326.4119 15.05568088 21289
STARLINK-1285           
1 45410U 20019BC  20212.91667824 -.00020453  00000-0 -13884-2 0  9998
2 45410  53.0000 329.4153 0001181  99.3971  79.9937 15.05586655 21227
STARLINK-1293           
1 45411U 20019BD  20213.25001157  .00040972  00000-0  28148-2 0  9993
2 45411  53.0110 327.8658 0001492  94.5211 254.5098 15.05580834 21289
STARLINK-1296           
1 45412U 20019BE  20212.91667824 -.00036445  00000-0 -24915-2 0  9995
2 45412  53.0019 329.4343 0001368  82.0669  61.2476 15.05632283 21221
STARLINK-1298           
1 45413U 20019BF  20213.25001157  .00040129  00000-0  27590-2 0  9999
2 45413  53.0059 327.8924 0001267 100.1331 266.9037 15.05561922 21373
STARLINK-1309           
1 45414U 20019BG  20212.91667824  .00081446  00000-0  55547-2 0  9991
2 45414  52.9987 329.4448 0001331  95.4185 335.9977 15.05513210  1764
STARLINK-1316           
1 45415U 20019BH  20213.25001157  .00037546  00000-0  25834-2 0  9999
2 45415  52.9999 327.9188 0001283  98.6145 196.3959 15.05564658 21290
STARLINK-1318           
1 45416U 20019BJ  20213.25001157  .00037485  00000-0  25778-2 0  9999
2 45416  53.0000 327.9249 0001121 114.0836   0.9011 15.05585788 21280
STARLINK-1286           
1 45417U 20019BK  20213.16667824 -.00000269  00000-0  70238-6 0  9995
2 45417  53.0012 358.3341 0001428 106.0340 264.9028 15.05582442 21134
STARLINK-1299           
1 45418U 20019BL  20212.78341098  .00000555  00000-0  66527-4 0  9996
2 45418  53.0038   0.1298 0001777 107.0773 253.0407 14.99172638 20812
STARLINK-1308           
1 45419U 20019BM  20213.25001157  .00037006  00000-0  25463-2 0  9992
2 45419  53.0083 357.8834 0001034 112.9521   7.8544 15.05570529 20886
ONEWEB-0066             
1 45424U 20020A   20213.16667824  .00015584  00000-0  16111-2 0  9996
2 45424  87.5626  96.4232 0025296 163.8458  87.9913 14.89790167 19795
ONEWEB-0067             
1 45425U 20020B   20213.16667824  .00013628  00000-0  13981-2 0  9996
2 45425  87.5591  96.4230 0026232 164.2600 122.0264 14.90081352 19791
ONEWEB-0080             
1 45426U 20020C   20213.25001157 -.00046426  00000-0 -47957-2 0  9992
2 45426  87.5704  96.4638 0028593 166.4454 357.3273 14.90198493 19791
ONEWEB-0090             
1 45427U 20020D   20213.25001157 -.00044444  00000-0 -46822-2 0  9991
2 45427  87.5496  96.3942 0027532 167.8184  69.3104 14.89420628 19782
ONEWEB-0061             
1 45428U 20020E   20213.25001157 -.00045652  00000-0 -47222-2 0  9999
2 45428  87.5073  96.4427 0028968 171.2626  25.8381 14.90132092 19766
ONEWEB-0081             
1 45429U 20020F   20213.16667824  .00012929  00000-0  13386-2 0  9992
2 45429  87.5602  96.4415 0027970 171.9441 135.4473 14.89677302 19773
ONEWEB-0069             
1 45430U 20020G   20213.25001157 -.00047403  00000-0 -48939-2 0  9994
2 45430  87.5643  96.4095 0028824 175.5943 296.9640 14.90220809 19788
ONEWEB-0031             
1 45431U 20020H   20212.91667824 -.00018622  00000-0 -19422-2 0  9992
2 45431  87.5533  96.4894 0026153 175.6101 140.6749 14.89743139 19727
ONEWEB-0027             
1 45432U 20020J   20213.25001157 -.00046875  00000-0 -48838-2 0  9996
2 45432  87.5634  96.3884 0026553 176.2802 330.7038 14.89899927 19772
ONEWEB-0064             
1 45433U 20020K   20213.25001157 -.00046185  00000-0 -48344-2 0  9993
2 45433  87.5621  96.4064 0025916 176.0484 357.1827 14.89721723 19773
ONEWEB-0018             
1 45434U 20020L   20213.25001157 -.00047242  00000-0 -48808-2 0  9994
2 45434  87.5532  96.3965 0025251 177.3006 278.6198 14.90266042 19789
ONEWEB-0083             
1 45435U 20020M   20213.25001157 -.00046679  00000-0 -48316-2 0  9992
2 45435  87.5506  96.4056 0024418 175.6125 337.3352 14.90204670 19784
ONEWEB-0095             
1 45436U 20020N   20213.25001157 -.00042977  00000-0 -44548-2 0  9993
2 45436  87.5701  96.3970 0023142 172.1315  99.3820 14.90152313 19772
ONEWEB-0092             
1 45437U 20020P   20213.25001157 -.00044318  00000-0 -46057-2 0  9992
2 45437  87.5687  96.4158 0022938 172.6519  66.2473 14.90058411 19768
ONEWEB-0085             
1 45438U 20020Q   20213.25001157 -.00044948  00000-0 -46485-2 0  9997
2 45438  87.5681  96.3797 0023404 170.1171  53.1829 14.90248997 19767
ONEWEB-0096             
1 45439U 20020R   20213.25001157 -.00045345  00000-0 -46920-2 0  9994
2 45439  87.5707  96.4018 0024250 170.9552 234.7259 14.90214264 19769
ONEWEB-0037             
1 45440U 20020S   20213.25001157 -.00044025  00000-0 -45553-2 0  9991
2 45440  87.5680  96.3837 0024445 170.2114  70.7647 14.90205055 19758
ONEWEB-0042             
1 45441U 20020T   20213.25001157 -.00042852  00000-0 -44649-2 0  9993
2 45441  87.5688  96.3936 0024370 169.9347 190.6427 14.89921014 19753
ONEWEB-0098             
1 45442U 20020U   20213.25001157 -.00044857  00000-0 -46181-2 0  9994
2 45442  87.5716  96.3906 0026316 169.1888  57.9404 14.90373963 19731
ONEWEB-0055             
1 45443U 20020V   20213.25001157 -.00042160  00000-0 -43905-2 0  9991
2 45443  87.5681  96.3824 0027709 170.7477 130.4978 14.89870679 19727
ONEWEB-0093             
1 45444U 20020W   20212.91667824 -.00019374  00000-0 -19984-2 0  9994
2 45444  87.5914  96.4876 0027921 171.4586  24.8572 14.90153726  1759
ONEWEB-0029             
1 45445U 20020X   20213.25001157 -.00044613  00000-0 -46088-2 0  9993
2 45445  87.5770  96.3882 0027784 171.8016 221.0952 14.90203731 19726
ONEWEB-0068             
1 45446U 20020Y   20213.25001157 -.00042355  00000-0 -43890-2 0  9993
2 45446  87.5758  96.3935 0027987 174.2144 168.7053 14.90064777 19719
ONEWEB-0046             
1 45447U 20020Z   20213.25001157 -.00045633  00000-0 -47460-2 0  9993
2 45447  87.5712  96.4141 0027097 175.2231  22.9431 14.89953651 19717
ONEWEB-0063             
1 45448U 20020AA  20213.25001157 -.00046551  00000-0 -48058-2 0  9998
2 45448  87.5765  96.3921 0028711 176.1720 252.7256 14.90220076 19727
ONEWEB-0019             
1 45449U 20020AB  20213.25001157 -.00046067  00000-0 -47577-2 0  9996
2 45449  87.5587  96.3281 0028093 178.4119 358.0638 14.90215309 19670
ONEWEB-0094             
1 45450U 20020AC  20213.25001157 -.00046777  00000-0 -48327-2 0  9990
2 45450  87.5766  96.3836 0026905 180.7240 256.0200 14.90230277 19716
ONEWEB-0087             
1 45451U 20020AD  20213.25001157 -.00046766  00000-0 -49082-2 0  9998
2 45451  87.5688  96.3926 0026021 181.8456 322.7895 14.89615889 19670
ONEWEB-0082             
1 45452U 20020AE  20213.25001157 -.00042029  00000-0 -43294-2 0  9996
2 45452  87.5925  96.3701 0026705 181.9982 127.3626 14.90329057 19706
ONEWEB-0050             
1 45453U 20020AF  20212.91667824 -.00017605  00000-0 -16162-2 0  9994
2 45453  87.5411  96.5893 0025647 179.6496 314.7174 14.94825205 19710
ONEWEB-0034             
1 45454U 20020AG  20213.25001157 -.00046404  00000-0 -48316-2 0  9999
2 45454  87.5767  96.2922 0025076 179.3693 348.9162 14.89953155 19702
ONEWEB-0060             
1 45455U 20020AH  20213.25001157 -.00042414  00000-0 -43973-2 0  9991
2 45455  87.5589  96.2993 0025198 181.7872 162.8078 14.90102610 19651
ONEWEB-0086             
1 45456U 20020AJ  20213.25001157 -.00046714  00000-0 -48723-2 0  9997
2 45456  87.5668  96.3663 0023828 179.9543 258.4439 14.89908711 19665
ONEWEB-0088             
1 45457U 20020AK  20212.91667824 -.00016182  00000-0 -16737-2 0  9995
2 45457  87.5700  96.5058 0024646 182.6905 288.0183 14.90104292 19608
YAOGAN 30 R             
1 45460U 20021A   20213.10981291  .00002678  00000-0  27660-3 0  9990
2 45460  34.9980 163.6169 0003619 149.4777 210.6105 14.89808744 19248
YAOGAN 30 S             
1 45461U 20021B   20212.81942094  .00000129  00000-0  15975-4 0  9992
2 45461  34.9977 165.0300 0003020  31.6254 328.4638 14.89773573 19178
YAOGAN 30 T             
1 45462U 20021C   20213.14433306  .00000120  00000-0  14787-4 0  9992
2 45462  34.9991 162.0274 0008428 295.0986  64.8859 14.90545942 19239
AEHF-6 (USA 298)        
1 45465U 20022B   20212.41739014 -.00000174  00000-0  00000+0 0  9998
2 45465   7.4846 308.9102 0197780 180.7134 227.0019  1.00312473  1456
SOYUZ-MS 16             
1 45476U 20023A   20211.85845188 -.00045989  00000-0 -82884-3 0  9996
2 45476  51.6435 137.2685 0000227 278.8486 148.7148 15.49096649 17271
AC 10 PROBE (FULLER)    
1 45528U 19022M   20212.45698795  .00074038  00000-0  17956-2 0  9990
2 45528  51.6364 183.3097 0005368  58.6803 301.4707 15.40365815 15404
NOOR                    
1 45529U 20024A   20213.18833939  .00004838  00000-0  10547-3 0  9998
2 45529  59.8104 132.9203 0020533  32.2917 113.5231 15.45843248 15456
STARLINK-1329           
1 45531U 20025A   20212.91667824 -.00037807  00000-0 -25889-2 0  9998
2 45531  52.9979 299.4283 0001218 101.7781 161.8231 15.05588011  1754
STARLINK-1338           
1 45532U 20025B   20213.16667824  .00035527  00000-0  24467-2 0  9993
2 45532  53.0079 298.2599 0001067 156.0255 311.2475 15.05557830 15915
STARLINK-1339           
1 45533U 20025C   20212.91667824 -.00033527  00000-0 -22913-2 0  9990
2 45533  52.9982 299.4265 0001288  99.4685   2.1591 15.05603300  1769
STARLINK-1341           
1 45534U 20025D   20212.91667824 -.00033473  00000-0 -22880-2 0  9994
2 45534  53.0003 299.4185 0001217  98.2303  39.3372 15.05596599  1752
STARLINK-1350           
1 45535U 20025E   20212.91667824 -.00035991  00000-0 -24607-2 0  9991
2 45535  53.0005 299.4289 0001153  99.3703 290.1714 15.05622281  1761
STARLINK-1352           
1 45536U 20025F   20213.16667824 -.00024785  00000-0 -16867-2 0  9998
2 45536  52.9996 298.3069 0001246 108.8911 178.4922 15.05603925 15907
STARLINK-1353           
1 45537U 20025G   20212.58477742  .00001520  00000-0  10711-3 0  9995
2 45537  53.0047 298.9697 0002846  96.8091 263.3223 15.11107537 16434
STARLINK-1362           
1 45538U 20025H   20212.91667824 -.00037321  00000-0 -25548-2 0  9998
2 45538  52.9996 299.4312 0001115 115.4973 130.0049 15.05594135  1753
STARLINK-1367           
1 45539U 20025J   20213.16667824 -.01085174  00000-0 -19906-1 0  9997
2 45539  52.9885 271.6776 0001116 103.6482 225.9457 15.51517881 16265
STARLINK-1368           
1 45540U 20025K   20212.91667824 -.00037174  00000-0 -25435-2 0  9992
2 45540  52.9993 299.4300 0000842  98.6346 254.9711 15.05611051  1751
STARLINK-1369           
1 45541U 20025L   20213.16667824  .00034268  00000-0  23591-2 0  9991
2 45541  53.0006 298.3086 0001116 126.9753 124.2524 15.05588892 15909
STARLINK-1371           
1 45542U 20025M   20212.91667824 -.00033659  00000-0 -23005-2 0  9999
2 45542  53.0046 299.4032 0001243 102.9402  52.6482 15.05602805 15866
STARLINK-1372           
1 45543U 20025N   20213.16667824  .00047388  00000-0  32501-2 0  9991
2 45543  52.9986 298.3040 0001356 115.4078 117.8901 15.05575277 15905
STARLINK-1373           
1 45544U 20025P   20212.91667824 -.00036498  00000-0 -24974-2 0  9991
2 45544  52.9948 299.4462 0001088 110.2496 117.2716 15.05599404  1757
STARLINK-1374           
1 45545U 20025Q   20213.25001157  .00010085  00000-0  71026-3 0  9994
2 45545  52.9986 297.9273 0000983 131.9599 355.2736 15.05584501 15922
STARLINK-1375           
1 45546U 20025R   20212.91667824 -.00034913  00000-0 -23886-2 0  9994
2 45546  52.9995 299.4260 0001068 103.2081 304.3698 15.05583601  1768
STARLINK-1377           
1 45547U 20025S   20213.16667824  .00042720  00000-0  29336-2 0  9992
2 45547  52.9990 298.2994 0001439 107.3364 251.9559 15.05577604 15917
STARLINK-1378           
1 45548U 20025T   20212.91667824 -.00038108  00000-0 -26102-2 0  9990
2 45548  53.0007 299.4297 0001212 105.0937 194.5107 15.05582379  1751
STARLINK-1379           
1 45549U 20025U   20213.16667824  .00031770  00000-0  21898-2 0  9992
2 45549  53.0002 298.3047 0000919 113.8685  83.4566 15.05579654 15907
STARLINK-1390           
1 45550U 20025V   20212.91667824 -.00041084  00000-0 -28162-2 0  9998
2 45550  52.9956 299.4521 0001284 107.2432 102.3171 15.05588526  1756
STARLINK-1294           
1 45551U 20025W   20212.91667824 -.00019804  00000-0 -13436-2 0  9992
2 45551  52.9852 279.5154 0001390  72.5112 157.1485 15.05584535  1752
STARLINK-1322           
1 45552U 20025X   20213.16667824  .00018490  00000-0  12851-2 0  9999
2 45552  52.9945 278.3380 0001425  76.5166  87.0030 15.05571801 16177
STARLINK-1323           
1 45553U 20025Y   20212.91667824 -.00018016  00000-0 -12208-2 0  9994
2 45553  53.0032 279.4142 0001111  71.5175 104.2081 15.05570328  1751
STARLINK-1325           
1 45554U 20025Z   20212.91667824 -.00019259  00000-0 -13054-2 0  9995
2 45554  52.9820 279.5245 0001665  76.6688 135.0563 15.05601525  1750
STARLINK-1327           
1 45555U 20025AA  20212.91667824 -.00017523  00000-0 -11863-2 0  9994
2 45555  52.9992 279.4161 0001281  79.2427 348.3900 15.05585163  1765
STARLINK-1334           
1 45556U 20025AB  20212.91667824 -.00018573  00000-0 -12588-2 0  9996
2 45556  53.0091 279.3755 0001006  85.6134 108.1032 15.05583790  1758
STARLINK-1336           
1 45557U 20025AC  20213.25001157 -.00013925  00000-0 -93816-3 0  9996
2 45557  53.0007 277.9195 0001195  83.7795  81.6076 15.05590859 16190
STARLINK-1342           
1 45558U 20025AD  20212.91667824 -.00020247  00000-0 -13741-2 0  9991
2 45558  53.0008 279.4166 0001402  67.1517 252.5269 15.05586989  1754
STARLINK-1344           
1 45559U 20025AE  20212.91667824  .00040787  00000-0  28046-2 0  9995
2 45559  53.0018 279.4163 0001089  67.8540  71.8685 15.05549261  1753
STARLINK-1346           
1 45560U 20025AF  20213.16667824  .00019597  00000-0  45797-3 0  9993
2 45560  52.9987 272.3716 0005110 279.7609 152.4752 15.42190985 16262
STARLINK-1348           
1 45561U 20025AG  20212.58764229  .00000791  00000-0  16541-4 0  9998
2 45561  52.9982 274.5702 0007598 283.5973  76.4191 15.58856803 16173
STARLINK-1354           
1 45562U 20025AH  20212.91667824 -.00017148  00000-0 -11610-2 0  9995
2 45562  53.0015 279.4156 0001225 103.3407   0.4089 15.05566163  1763
STARLINK-1355           
1 45563U 20025AJ  20212.91667824 -.01228472  00000-0 -92149-1 0  9996
2 45563  52.9933 278.2470 0001347 103.4806 190.3638 15.05101337  1752
STARLINK-1356           
1 45564U 20025AK  20213.25001157 -.00013131  00000-0 -88349-3 0  9998
2 45564  53.0006 277.9193 0001427  92.5591 324.8122 15.05587281 16183
STARLINK-1357           
1 45565U 20025AL  20212.91667824 -.00017180  00000-0 -11624-2 0  9999
2 45565  53.0011 279.4147 0001106  84.3193   1.4526 15.05593512  1765
STARLINK-1358           
1 45566U 20025AM  20213.25001157 -.00012299  00000-0 -82579-3 0  9993
2 45566  53.0000 277.9186 0001834  81.5727 299.8051 15.05604222 16189
STARLINK-1361           
1 45567U 20025AN  20212.91667824 -.00020542  00000-0 -13948-2 0  9998
2 45567  52.9984 279.4260 0001564  72.7178 211.0052 15.05577901  1758
STARLINK-1363           
1 45568U 20025AP  20212.91667824 -.00020053  00000-0 -13602-2 0  9993
2 45568  53.0019 279.4152 0001241  68.7805 268.9597 15.05602003  1751
STARLINK-1366           
1 45569U 20025AQ  20213.25001157 -.00011461  00000-0 -76865-3 0  9997
2 45569  52.9990 277.9310 0001640  77.5012 195.8918 15.05579750 16188
STARLINK-1376           
1 45570U 20025AR  20213.25001157 -.00011174  00000-0 -74869-3 0  9992
2 45570  53.0015 277.9239 0001531  75.2792 234.1163 15.05591520 16187
STARLINK-1261           
1 45571U 20025AS  20213.16667824  .00016446  00000-0  38629-3 0  9991
2 45571  52.9998 272.3195 0001429  77.6562  12.6659 15.42177372 16256
STARLINK-1320           
1 45572U 20025AT  20212.91667824 -.01054688  00000-0 -22091-1 0  9991
2 45572  52.9968 273.1363 0001385 307.8116  26.5167 15.47705444  1775
STARLINK-1321           
1 45573U 20025AU  20213.16667824 -.01081777  00000-0 -21481-1 0  9991
2 45573  52.9967 271.9273 0001188  69.0975 233.4780 15.49292417 16265
STARLINK-1324           
1 45574U 20025AV  20213.16667824  .00016019  00000-0  37637-3 0  9998
2 45574  52.9993 272.2879 0001199  75.2419  24.9389 15.42190783 16258
STARLINK-1326           
1 45575U 20025AW  20212.91667824 -.00004961  00000-0 -10455-3 0  9995
2 45575  52.9988 273.5375 0000984  55.0829  66.3321 15.42178153  1774
STARLINK-1328           
1 45576U 20025AX  20212.91667824 -.01085282  00000-0 -20252-1 0  9996
2 45576  52.9975 273.0581 0001147  68.6490 313.3714 15.51042303  1779
STARLINK-1330           
1 45577U 20025AY  20213.16667824 -.01081015  00000-0 -21850-1 0  9990
2 45577  52.9979 271.9607 0001222  68.2841 218.9824 15.48789366 16265
STARLINK-1331           
1 45578U 20025AZ  20213.16667824  .00019521  00000-0  45658-3 0  9995
2 45578  52.9991 272.3943 0001408  69.9149 340.3081 15.42177635 16251
STARLINK-1332           
1 45579U 20025BA  20213.16667824  .00020486  00000-0  27518-3 0  9993
2 45579  52.9977 272.4889 0002290 339.2943  18.2030 15.57916457 16255
STARLINK-1333           
1 45580U 20025BB  20212.91667824  .00038132  00000-0  26234-2 0  9997
2 45580  53.0022 279.4161 0001469  74.8057  46.8556 15.05562324  1757
STARLINK-1335           
1 45581U 20025BC  20212.91667824 -.01074084  00000-0 -20325-1 0  9991
2 45581  52.9959 273.0736 0004390 295.2217  73.2049 15.50592425  1774
STARLINK-1337           
1 45582U 20025BD  20213.16667824 -.01080724  00000-0 -22031-1 0  9994
2 45582  52.9978 271.9720 0001188  76.1280 203.4268 15.48547650 16261
STARLINK-1340           
1 45583U 20025BE  20213.16667824 -.01082645  00000-0 -21141-1 0  9990
2 45583  52.9981 271.9027 0001160  68.4386 248.7839 15.49766395 16263
STARLINK-1343           
1 45584U 20025BF  20213.16667824 -.01084348  00000-0 -20502-1 0  9993
2 45584  52.9979 271.8398 0001137  69.5965 275.7571 15.50674148 16269
STARLINK-1345           
1 45585U 20025BG  20212.91667824 -.01061452  00000-0 -20456-1 0  9995
2 45585  52.9962 273.0972 0001946 300.7897  53.3234 15.50073331  1779
STARLINK-1347           
1 45586U 20025BH  20212.91667824 -.01083045  00000-0 -20384-1 0  9998
2 45586  52.9978 273.0730 0001095  72.6483 302.5009 15.50798480  1778
STARLINK-1349           
1 45587U 20025BJ  20213.16667824  .00018519  00000-0  43382-3 0  9993
2 45587  52.9990 272.3812 0001463  78.1793 342.0989 15.42167997 16259
STARLINK-1360           
1 45588U 20025BK  20213.25001157 -.01013930  00000-0 -18530-1 0  9995
2 45588  52.9952 271.4140 0001239  69.0258  12.2079 15.51435184 16272
STARLINK-1364           
1 45589U 20025BL  20213.16667824 -.01080287  00000-0 -22201-1 0  9994
2 45589  52.9975 271.9891 0001214  73.6869 198.1548 15.48316809 16260
STARLINK-1365           
1 45590U 20025BM  20213.16667824  .00037789  00000-0  26002-2 0  9996
2 45590  53.0055 298.2673 0001100 124.9424 324.3766 15.05560187 15890
PROGRESS-MS 14          
1 45595U 20026A   20211.85845188 -.00045989  00000-0 -82884-3 0  9991
2 45595  51.6435 137.2685 0000227 278.8486 148.7148 15.49096649 14854
G-SAT                   
1 45597U 98067RK  20212.81030437  .00003796  00000-0  72166-4 0  9994
2 45597  51.6422 131.7691 0004627 214.7045 145.3643 15.50913406 14591
QUETZAL-1               
1 45598U 98067RL  20212.82643240  .00004610  00000-0  86518-4 0  9990
2 45598  51.6418 131.8751 0002204 240.6321 119.4448 15.50724083 14528
XINGYUN-2 01            
1 45602U 20028A   20213.12322274  .00000577  00000-0  50081-4 0  9997
2 45602  97.5707 242.0856 0011925 352.1091  83.8751 15.00768686 12013
XINGYUN-2 02            
1 45603U 20028B   20213.12244934  .00000631  00000-0  54278-4 0  9997
2 45603  97.5706 242.0844 0011680 352.3935  83.7002 15.00770079 12003
ULTP                    
1 45605U 20011D   20213.11520037 -.00000576  00000-0 -78494-5 0  9998
2 45605  51.6487 143.3695 0006673 265.9818  94.0399 15.28268083 12016
HTV-9 (KOUNOTORI 9)     
1 45607U 20030A   20211.85845188 -.00045989  00000-0 -82884-3 0  9990
2 45607  51.6435 137.2685 0000227 278.8486 148.7148 15.49096649 10887
COSMOS 2546             
1 45608U 20031A   20212.08206016 -.00000721  00000-0  00000+0 0  9999
2 45608  63.7258 295.1595 6950268 269.8120  16.2518  2.00587148  1375
XJS G                   
1 45611U 20032A   20212.98001586  .00008061  00000-0  29378-3 0  9995
2 45611  35.0356 133.6666 0009368 268.1650 192.7953 15.27410693  9481
XJS H                   
1 45612U 20032B   20212.53507377  .00001324  00000-0  48141-4 0  9991
2 45612  35.0343 136.4936 0009909 270.6888 255.7996 15.28328541  9412
CREW DRAGON DEMO-2      
1 45623U 20033A   20211.85845188 -.00045989  00000-0 -82884-3 0  9991
2 45623  51.6435 137.2685 0000227 278.8486 148.7148 15.49096649  9311
HEAD-4                  
1 45624U 20034A   20213.20045139  .00000746  00000-0  33279-4 0  9997
2 45624  97.2934 357.0541 0015179  67.5118 276.8067 15.24321749  9253
GAOFEN 9-02             
1 45625U 20034B   20213.19529370  .00000472  00000-0  22509-4 0  9990
2 45625  97.2939 356.9927 0014451  56.5845 116.2590 15.23650408  9245
STARLINK-1441           
1 45658U 20035B   20213.25001157 -.00028430  00000-0 -19385-2 0  9994
2 45658  53.0020 257.9231 0001302  79.0280 268.5505 15.05605851  9748
STARLINK-1442           
1 45659U 20035C   20213.25001157 -.00026320  00000-0 -17928-2 0  9992
2 45659  53.0003 257.9231 0001390  68.4502 243.1458 15.05603869  9749
STARLINK-1443           
1 45660U 20035D   20212.91667824 -.00031644  00000-0 -21609-2 0  9991
2 45660  53.0011 259.4191 0001343  71.1761 214.7122 15.05604463  1754
STARLINK-1444           
1 45661U 20035E   20213.25001157 -.00027784  00000-0 -18950-2 0  9996
2 45661  52.9997 257.9208 0001469  74.2076 111.3500 15.05582910  9730
STARLINK-1445           
1 45662U 20035F   20212.91667824  .00012516  00000-0  87680-3 0  9990
2 45662  52.9964 259.4426 0001307 114.8554 350.9406 15.05571780  1761
STARLINK-1446           
1 45663U 20035G   20213.25001157 -.00032185  00000-0 -21987-2 0  9993
2 45663  52.9926 257.9710 0001407 102.5687 352.9526 15.05598432  9745
STARLINK-1447           
1 45664U 20035H   20212.83629880  .00022913  00000-0  30294-3 0  9994
2 45664  53.0015 252.3447 0002553  52.7020 307.4223 15.58264417 10194
STARLINK-1448           
1 45665U 20035J   20213.25001157 -.00025141  00000-0 -17119-2 0  9991
2 45665  53.0015 257.9238 0001551  77.3103 198.1104 15.05590759  9741
STARLINK-1449           
1 45666U 20035K   20213.25001157 -.00025208  00000-0 -17162-2 0  9994
2 45666  53.0008 257.9229 0001381  66.7697 190.7288 15.05598653  9747
STARLINK-1450           
1 45667U 20035L   20213.25001157 -.00025530  00000-0 -17379-2 0  9993
2 45667  53.0008 257.9220 0001509  74.0968 165.5123 15.05610246  9789
STARLINK-1451           
1 45668U 20035M   20213.25001157 -.00066544  00000-0 -45861-2 0  9991
2 45668  53.0007 257.9226 0001488  66.6355 316.9299 15.05618620  9744
STARLINK-1452           
1 45669U 20035N   20213.25001157 -.00030259  00000-0 -20652-2 0  9997
2 45669  52.9987 257.9312 0001227  96.1698  53.3851 15.05602737  9732
STARLINK-1453           
1 45670U 20035P   20213.25001157 -.00027184  00000-0 -29164-3 0  9990
2 45670  52.9992 247.5603 0001345  69.5330 346.2449 15.62786597  9889
STARLINK-1454           
1 45671U 20035Q   20213.25001157 -.00030887  00000-0 -21082-2 0  9995
2 45671  53.0005 257.9345 0001133 103.6746  27.8890 15.05610610  9747
STARLINK-1455           
1 45672U 20035R   20212.91667824  .00036370  00000-0  40075-3 0  9992
2 45672  52.9866 248.3941 0001373  48.5142 282.3605 15.62784300  1778
STARLINK-1456           
1 45673U 20035S   20213.25001157 -.00032179  00000-0 -21971-2 0  9990
2 45673  53.0052 257.8962 0006411 298.5790 120.9446 15.05598288  9740
STARLINK-1457           
1 45674U 20035T   20213.25001157  .00080365  00000-0  54914-2 0  9992
2 45674  53.0156 257.9552 0001448  72.2755 293.3731 15.05450239  9751
STARLINK-1458           
1 45675U 20035U   20212.91667824  .00012882  00000-0  90243-3 0  9999
2 45675  52.9869 259.4960 0001107 101.9283 327.9856 15.05548965  1768
STARLINK-1460           
1 45676U 20035V   20212.91667824  .00012668  00000-0  88709-3 0  9996
2 45676  53.0015 259.4193 0001457  68.7161 145.0371 15.05576727  1752
STARLINK-1392           
1 45677U 20035W   20213.25001157 -.00029149  00000-0 -19876-2 0  9998
2 45677  53.0000 257.9287 0001434 109.0465  58.4583 15.05616937  9742
STARLINK-1393           
1 45678U 20035X   20213.25001157 -.01030324  00000-0 -28210-1 0  9998
2 45678  52.9957 249.7596 0001224  71.6304 306.8469 15.39336288  9857
STARLINK-1394           
1 45679U 20035Y   20212.91667824 -.01083554  00000-0 -29192-1 0  9993
2 45679  52.9970 251.3641 0001174  72.7858 249.0407 15.39954938  1774
STARLINK-1395           
1 45680U 20035Z   20213.25001157 -.01070659  00000-0 -38776-1 0  9994
2 45680  52.9993 248.9846 0001346  55.2505 306.4644 15.30734234  9861
STARLINK-1396           
1 45681U 20035AA  20213.25001157 -.01083713  00000-0 -30140-1 0  9997
2 45681  52.9970 249.8106 0001223  61.4175 300.7543 15.38993523  9852
STARLINK-1397           
1 45682U 20035AB  20213.25001157 -.01029525  00000-0 -28891-1 0  9999
2 45682  52.9952 249.8101 0001231  74.8411 270.7488 15.38587069  9856
STARLINK-1399           
1 45683U 20035AC  20213.25001157 -.01054219  00000-0 -28554-1 0  9994
2 45683  52.9958 249.7313 0001189  69.9052 324.7723 15.39721706  9856
STARLINK-1401           
1 45684U 20035AD  20212.91667824 -.01087045  00000-0 -27416-1 0  9995
2 45684  52.9992 251.4526 0001208  63.0295 311.9593 15.41937789  1776
STARLINK-1402           
1 45685U 20035AE  20212.91667824 -.01087248  00000-0 -27243-1 0  9999
2 45685  52.9990 251.4135 0001201  64.7698 317.5193 15.42131891  1778
STARLINK-1404           
1 45686U 20035AF  20212.91667824 -.01087840  00000-0 -26915-1 0  9993
2 45686  52.9988 251.4044 0001241  62.3971 334.4330 15.42508233  1787
STARLINK-1406           
1 45687U 20035AG  20212.91667824  .00085895  00000-0  20010-2 0  9990
2 45687  52.9996 250.2084 0001304  68.4624 104.9177 15.41553957  1769
STARLINK-1408           
1 45688U 20035AH  20213.25001157 -.01075038  00000-0 -39633-1 0  9991
2 45688  52.9942 249.0367 0001208  81.4349 248.1781 15.30177073  9863
STARLINK-1413           
1 45689U 20035AJ  20213.25001157 -.01084710  00000-0 -28423-1 0  9994
2 45689  52.9997 249.8283 0001412  50.6886 359.8818 15.40790228  9854
STARLINK-1414           
1 45690U 20035AK  20213.25001157 -.01072930  00000-0 -30374-1 0  9995
2 45690  52.9956 249.8235 0001188  66.4914 270.6128 15.38428282  9854
STARLINK-1415           
1 45691U 20035AL  20213.25001157 -.01025733  00000-0 -29875-1 0  9999
2 45691  52.9955 249.3855 0001228  79.5669 215.2721 15.37442514 10268
STARLINK-1416           
1 45692U 20035AM  20212.91667824 -.01018459  00000-0 -25136-1 0  9994
2 45692  52.9996 251.3785 0001387  46.5354   4.5689 15.42844769  1772
STARLINK-1417           
1 45693U 20035AN  20213.25001157 -.01086701  00000-0 -28298-1 0  9991
2 45693  52.9977 249.8393 0001343  56.1638   2.0913 15.40982329  9856
STARLINK-1419           
1 45694U 20035AP  20213.25001157 -.01110572  00000-0 -29332-1 0  9996
2 45694  52.9963 249.8027 0001298  61.6938 341.1828 15.40616540  9861
STARLINK-1420           
1 45695U 20035AQ  20213.25001157 -.00027981  00000-0 -30060-3 0  9999
2 45695  53.0006 249.0504 0001328  63.0130 355.5651 15.62769178  9861
STARLINK-1422           
1 45696U 20035AR  20213.25001157 -.01050597  00000-0 -27818-1 0  9995
2 45696  52.9959 249.7787 0001255  71.4727 323.5725 15.40395118  9852
STARLINK-1351           
1 45697U 20035AS  20212.91667824  .00037618  00000-0  41463-3 0  9997
2 45697  52.9997 250.7147 0001434  69.8219 257.2159 15.62760635  1774
STARLINK-1370           
1 45698U 20035AT  20213.25001157 -.00028305  00000-0 -30413-3 0  9995
2 45698  52.9982 249.0600 0001128  59.0435 348.2674 15.62772214  9873
STARLINK-1398           
1 45699U 20035AU  20212.91667824 -.00062511  00000-0 -68028-3 0  9997
2 45699  53.0000 250.6958 0001393  57.8303 278.1051 15.62820698  1776
STARLINK-1400           
1 45700U 20035AV  20212.91667824 -.00066441  00000-0 -72474-3 0  9996
2 45700  53.0005 250.6773 0001162  59.3588 277.9370 15.62787044  1773
STARLINK-1403           
1 45701U 20035AW  20213.25001157 -.00028240  00000-0 -30389-3 0  9990
2 45701  53.0006 249.0793 0001407  49.2700 353.0446 15.62731257  9868
STARLINK-1405           
1 45702U 20035AX  20213.25001157 -.00016942  00000-0 -12488-3 0  9997
2 45702  52.9923 237.9900 0002248 356.3385 352.6005 15.71583374 10410
STARLINK-1407           
1 45703U 20035AY  20212.91667824  .00037875  00000-0  41785-3 0  9997
2 45703  53.0004 250.6919 0001410  61.2317 273.4954 15.62733275  1771
STARLINK-1409           
1 45704U 20035AZ  20213.25001157 -.00027298  00000-0 -18603-2 0  9999
2 45704  53.0000 257.9230 0001553  72.1184 257.3892 15.05604577  9741
STARLINK-1410           
1 45705U 20035BA  20213.25001157 -.00027135  00000-0 -18503-2 0  9999
2 45705  53.0024 257.9241 0001550  82.4472 121.0470 15.05579671  9746
STARLINK-1411           
1 45706U 20035BB  20213.16667824 -.00001892  00000-0 -14839-4 0  9991
2 45706  52.9985 249.5110 0001311  62.7820 220.2018 15.62742576  9857
STARLINK-1412           
1 45707U 20035BC  20213.25001157 -.00240436  00000-0 -26867-2 0  9994
2 45707  52.9984 249.1002 0001327  64.3777 329.2859 15.62862676  9867
STARLINK-1418           
1 45708U 20035BD  20212.91667824  .00037921  00000-0  41818-3 0  9991
2 45708  53.0001 250.7127 0000896  42.9653 285.4123 15.62744198  1778
STARLINK-1421           
1 45709U 20035BE  20213.25001157 -.00042209  00000-0 -45675-3 0  9993
2 45709  53.0006 249.0450 0000988  10.0545  46.1952 15.62796336  9863
STARLINK-1423           
1 45710U 20035BF  20212.91667824 -.00064754  00000-0 -70562-3 0  9998
2 45710  52.9982 250.6749 0001382  62.6967 278.3565 15.62801529  1777
STARLINK-1433           
1 45711U 20035BG  20213.25001157 -.00056836  00000-0 -61911-3 0  9998
2 45711  53.0003 249.0846 0001578  64.2061 335.5053 15.62755849  9863
STARLINK-1434           
1 45712U 20035BH  20213.25001157 -.00028033  00000-0 -30040-3 0  9996
2 45712  52.9866 247.5370 0008558 340.0307  61.6094 15.62775601  9889
STARLINK-1436           
1 45713U 20035BJ  20213.25001157 -.01069410  00000-0 -28316-1 0  9993
2 45713  52.9957 249.7956 0001219  71.6183 303.2725 15.40441305  9851
STARLINK-1437           
1 45714U 20035BK  20213.25001157 -.00028210  00000-0 -30296-3 0  9998
2 45714  53.0004 249.0882 0001374  54.7710 341.0683 15.62782354  9868
STARLINK-1438           
1 45715U 20035BL  20213.25001157 -.00017756  00000-0 -12201-3 0  9998
2 45715  52.9984 246.9304 0001257  62.3025 313.2717 15.73260036  9895
STARLINK-1439           
1 45716U 20035BM  20213.25001157 -.00027078  00000-0 -29015-3 0  9998
2 45716  52.9995 245.2729 0007975 320.3051 126.2396 15.62763770 10315
HAIYANG 1D              
1 45721U 20036A   20213.11717687  .00000010  00000-0  18904-4 0  9993
2 45721  98.4445 153.3077 0012948 113.0718 247.1835 14.34243489  7229
ANDESITE                
1 45726U 20037D   20212.72843698  .00000733  00000-0  77657-4 0  9999
2 45726  97.7119  31.9640 0015587 138.5343 221.7062 14.91988787  4473
M2 PATHFINDER           
1 45727U 20037E   20212.75311448 -.00000431  00000-0 -37761-4 0  9995
2 45727  97.7035  31.8753 0013734 150.2948 209.9041 14.91208314  4475
STARLINK-1461           
1 45730U 20038A   20213.25001157 -.01058980  00000-0 -68338-1 0  9993
2 45730  53.0053  67.8967 0001508  71.5335 123.7238 15.10633143  8739
STARLINK-1465           
1 45731U 20038B   20213.16667824 -.01061549  00000-0 -69452-1 0  9996
2 45731  53.0060  68.3529 0001246  93.7013 301.1072 15.10146274  8569
STARLINK-1466           
1 45732U 20038C   20213.16667824 -.01119742  00000-0 -72480-1 0  9991
2 45732  53.0060  68.2892 0001379  74.5157  10.7446 15.10684336  8716
STARLINK-1467           
1 45733U 20038D   20213.16667824 -.01055297  00000-0 -68697-1 0  9991
2 45733  53.0018  67.3731 0001460  79.2613  96.7147 15.10309543  8729
STARLINK-1468           
1 45734U 20038E   20213.16667824 -.00006581  00000-0 -43263-3 0  9997
2 45734  52.9903  68.4048 0001583 100.7647  58.2538 15.05603204  8707
STARLINK-1471           
1 45735U 20038F   20213.16667824 -.01127303  00000-0 -76574-1 0  9994
2 45735  53.0021  68.3464 0001355 110.9150 201.2867 15.08963194  8717
STARLINK-1472           
1 45736U 20038G   20213.16667824 -.01055766  00000-0 -68403-1 0  9990
2 45736  53.0061  68.3228 0001331  77.4918 350.7365 15.10482094  8714
STARLINK-1473           
1 45737U 20038H   20212.91667824 -.00085970  00000-0 -61292-3 0  9993
2 45737  53.0043  61.9247 0001278  70.9468 149.8178 15.73250417  1772
STARLINK-1474           
1 45738U 20038J   20213.16667824 -.01059359  00000-0 -69625-1 0  9991
2 45738  53.0056  68.3513 0001248  83.1060 294.8341 15.09976198  8719
STARLINK-1475           
1 45739U 20038K   20213.16667824 -.00020651  00000-0 -21939-3 0  9992
2 45739  53.0003  62.8456 0001225  49.4359 166.4081 15.62853568  8638
STARLINK-1479           
1 45740U 20038L   20213.25001157 -.01111588  00000-0 -75524-1 0  9993
2 45740  53.0040  67.9674 0001268  87.8780 334.4515 15.08913330  8723
STARLINK-1480           
1 45741U 20038M   20213.16667824 -.01056244  00000-0 -67516-1 0  9992
2 45741  53.0078  68.2346 0001401  82.3705  35.7889 15.10968541  8712
STARLINK-1481           
1 45742U 20038N   20213.16667824 -.00055528  00000-0 -38206-2 0  9991
2 45742  52.9962  68.3330 0001257  88.5060 106.5402 15.05591309  8714
STARLINK-1483           
1 45743U 20038P   20213.16667824 -.01103393  00000-0 -73004-1 0  9991
2 45743  53.0074  68.3492 0001295 111.4576 249.5594 15.09852094  8568
STARLINK-1500           
1 45744U 20038Q   20212.91667824 -.00004353  00000-0 -27978-3 0  9990
2 45744  53.0008  69.4290 0000924  82.7386 250.2035 15.05576285  1766
STARLINK-1503           
1 45745U 20038R   20212.91667824 -.01066016  00000-0 -71322-1 0  9996
2 45745  53.0011  69.4701 0001364 114.8073 261.3284 15.09348903  1764
STARLINK-1504           
1 45746U 20038S   20213.16667824 -.00012101  00000-0 -81240-3 0  9992
2 45746  53.0004  68.3073 0001464  89.5713 141.3796 15.05595866  8521
STARLINK-1506           
1 45747U 20038T   20213.16667824 -.00010952  00000-0 -73353-3 0  9991
2 45747  53.0004  68.3099 0001077  71.6934 141.2190 15.05585992  8523
STARLINK-1507           
1 45748U 20038U   20212.91667824 -.01071960  00000-0 -72090-1 0  9996
2 45748  53.0003  69.4704 0001483 123.5277 235.5824 15.09177446  1761
STARLINK-1516           
1 45749U 20038V   20213.16667824 -.00017810  00000-0 -18670-3 0  9994
2 45749  53.0009  62.7886 0001620  94.1550 140.0241 15.63072397  8595
STARLINK-1391           
1 45750U 20038W   20212.91667824 -.00076515  00000-0 -83523-3 0  9994
2 45750  53.0000  63.3175 0001560  41.5697 206.4879 15.62825915  1775
STARLINK-1464           
1 45751U 20038X   20213.16667824 -.00097790  00000-0 -10732-2 0  9992
2 45751  52.9999  62.0904 0001392  48.1652 169.8416 15.62795315  8792
STARLINK-1469           
1 45752U 20038Y   20213.16667824 -.00008859  00000-0 -91059-4 0  9994
2 45752  52.9991  62.1024 0001330  47.1155 165.8352 15.62740019  8794
STARLINK-1476           
1 45753U 20038Z   20212.91667824  .00018158  00000-0  20374-3 0  9999
2 45753  53.0002  63.3261 0001582  36.2170 206.8408 15.62747408  1779
STARLINK-1477           
1 45754U 20038AA  20213.16667824 -.00008737  00000-0 -89743-4 0  9998
2 45754  52.9999  62.1108 0001534  36.8275 171.0341 15.62734226  8645
STARLINK-1478           
1 45755U 20038AB  20213.16667824 -.00009597  00000-0 -99071-4 0  9999
2 45755  52.9995  62.0330 0001413  32.6297 210.8781 15.62757570  8807
STARLINK-1484           
1 45756U 20038AC  20212.91667824 -.00076321  00000-0 -83397-3 0  9994
2 45756  53.0002  63.3524 0001655  39.6613 190.6842 15.62798543  1772
STARLINK-1486           
1 45757U 20038AD  20213.16667824 -.00036535  00000-0 -39449-3 0  9995
2 45757  52.9997  62.0568 0001365  40.6498 192.5544 15.62782341  8797
STARLINK-1487           
1 45758U 20038AE  20212.91667824 -.00081169  00000-0 -88814-3 0  9991
2 45758  52.9997  63.2741 0001389  53.4728 217.6026 15.62790781  1770
STARLINK-1493           
1 45759U 20038AF  20213.25001157 -.00022235  00000-0 -23775-3 0  9995
2 45759  53.0001  61.7134 0001314  42.5722 269.2907 15.62742509  8626
STARLINK-1494           
1 45760U 20038AG  20212.91667824 -.00099345  00000-0 -10890-2 0  9997
2 45760  53.0001  63.3466 0001623  41.9599 190.8867 15.62831290  1771
STARLINK-1495           
1 45761U 20038AH  20213.16667824 -.00009073  00000-0 -93279-4 0  9999
2 45761  52.9997  62.0573 0001410  42.9840 187.7243 15.62774610  8618
STARLINK-1499           
1 45762U 20038AJ  20213.16667824 -.00008957  00000-0 -92020-4 0  9993
2 45762  52.9998  62.0858 0001196  46.0457 174.4972 15.62772516  8611
STARLINK-1501           
1 45763U 20038AK  20213.16667824 -.00099690  00000-0 -10946-2 0  9993
2 45763  52.9998  62.0709 0001428  52.3271 175.8741 15.62791134  8615
STARLINK-1502           
1 45764U 20038AL  20212.91667824 -.00090786  00000-0 -99463-3 0  9995
2 45764  53.0000  63.2969 0001145  61.1513 197.1859 15.62806946  1771
STARLINK-1508           
1 45765U 20038AM  20213.16667824 -.00009361  00000-0 -96531-4 0  9997
2 45765  52.9997  62.0793 0001172  52.6898 170.4039 15.62748289  8617
STARLINK-1509           
1 45766U 20038AN  20213.16667824 -.00008487  00000-0 -86784-4 0  9995
2 45766  52.9996  62.0516 0001860  44.6127 191.1644 15.62797263  8618
STARLINK-1511           
1 45767U 20038AP  20212.91667824 -.00044120  00000-0 -47798-3 0  9993
2 45767  52.9996  63.2633 0001363  51.2190 222.3741 15.62786156  1778
STARLINK-1521           
1 45768U 20038AQ  20212.91667824  .00017658  00000-0  19855-3 0  9991
2 45768  53.0004  63.3392 0001623  41.0095 196.9882 15.62714355  1778
STARLINK-1459           
1 45769U 20038AR  20212.91667824 -.01061889  00000-0 -71725-1 0  9992
2 45769  52.9992  69.5066 0001313 113.1791 228.8713 15.08993284  1766
STARLINK-1462           
1 45770U 20038AS  20213.16667824 -.00008646  00000-0 -88737-4 0  9997
2 45770  53.0001  62.1265 0001613  41.0384 160.4594 15.62735608  8793
STARLINK-1463           
1 45771U 20038AT  20213.16667824 -.00008714  00000-0 -89391-4 0  9991
2 45771  53.0001  62.1143 0001459  43.0905 163.4328 15.62763819  8791
STARLINK-1470           
1 45772U 20038AU  20213.16667824 -.00098361  00000-0 -10795-2 0  9999
2 45772  53.0000  62.0945 0001557  40.7076 173.4974 15.62796592  8794
STARLINK-1482           
1 45773U 20038AV  20213.16667824 -.00019074  00000-0 -20300-3 0  9998
2 45773  53.0000  62.0836 0001826  38.7610 180.5390 15.62745012  8640
STARLINK-1485           
1 45774U 20038AW  20213.16667824 -.00008301  00000-0 -84830-4 0  9999
2 45774  52.9998  62.0346 0001256  39.1129 203.0039 15.62776255  8798
STARLINK-1488           
1 45775U 20038AX  20212.91667824 -.00097669  00000-0 -10717-2 0  9998
2 45775  53.0000  63.2727 0001302  53.4668 218.8915 15.62799264  1773
STARLINK-1489           
1 45776U 20038AY  20213.16667824 -.00114641  00000-0 -12600-2 0  9990
2 45776  52.9999  62.0603 0001512  46.1236 185.8431 15.62830360  8790
STARLINK-1490           
1 45777U 20038AZ  20213.16667824 -.00008631  00000-0 -88487-4 0  9990
2 45777  52.9999  62.0505 0001219  42.8401 191.7092 15.62761820  8808
STARLINK-1491           
1 45778U 20038BA  20212.91667824  .00018316  00000-0  20547-3 0  9994
2 45778  53.0004  63.3561 0001558  46.2039 185.3763 15.62745699  1774
STARLINK-1492           
1 45779U 20038BB  20213.16667824 -.00008424  00000-0 -86158-4 0  9995
2 45779  53.0002  62.1187 0001278  38.9280 165.0896 15.62780965  8795
STARLINK-1496           
1 45780U 20038BC  20212.91667824 -.00096907  00000-0 -10616-2 0  9996
2 45780  52.9999  63.2632 0001614  37.0311 240.3463 15.62836572  1770
STARLINK-1497           
1 45781U 20038BD  20212.91667824  .00018254  00000-0  20475-3 0  9999
2 45781  53.0003  63.3039 0001357  64.9363 192.1369 15.62750574  1777
STARLINK-1498           
1 45782U 20038BE  20212.91667824 -.00100110  00000-0 -10974-2 0  9997
2 45782  53.0001  63.2837 0001454  41.8850 220.2063 15.62834974  1775
STARLINK-1505           
1 45783U 20038BF  20212.91667824 -.00086033  00000-0 -94169-3 0  9994
2 45783  53.0001  63.2942 0001474  48.0295 211.5854 15.62806306  1776
STARLINK-1510           
1 45784U 20038BG  20212.91667824 -.00059842  00000-0 -65146-3 0  9996
2 45784  52.9999  63.2707 0001415  51.0119 218.7605 15.62793617  1777
STARLINK-1512           
1 45785U 20038BH  20213.16667824 -.00008454  00000-0 -86528-4 0  9996
2 45785  53.0001  62.1044 0001453  37.6705 173.9955 15.62767220  8610
STARLINK-1513           
1 45786U 20038BJ  20212.91667824 -.00087889  00000-0 -96250-3 0  9990
2 45786  53.0002  63.3036 0001384  50.9805 203.5288 15.62802876  1774
STARLINK-1517           
1 45787U 20038BK  20213.16667824 -.00010490  00000-0 -10884-3 0  9992
2 45787  53.0001  62.0925 0001686  54.1690 162.6107 15.62757621  8612
SKYSAT-C14              
1 45788U 20038BL  20212.85520801  .00003203  00000-0  52074-4 0  9991
2 45788  53.0006  64.8637 0003450  12.3013 347.8080 15.56027870  7620
SKYSAT-C16              
1 45789U 20038BM  20212.87635220  .00002606  00000-0  43396-4 0  9999
2 45789  53.0011  64.2804 0001515  46.8587 313.2549 15.56189619  7622
SKYSAT-C15              
1 45790U 20038BN  20212.83311376  .00002733  00000-0  45520-4 0  9992
2 45790  53.0014  64.7227 0004880 295.8936  64.1571 15.55992786  7615
GAOFEN 9-03             
1 45794U 20039A   20213.09674473 -.00076932  00000-0 -32495-2 0  9995
2 45794  97.3255 333.6558 0014263 129.4064  43.3690 15.23478509  6664
ZHEDA PIXING 3A         
1 45795U 20039B   20213.16840544  .00000564  00000-0  26198-4 0  9999
2 45795  97.3261 333.6954 0015671 113.9159   6.8627 15.23898943  6661
HEAD-5                  
1 45796U 20039C   20213.15905475  .00000719  00000-0  32275-4 0  9999
2 45796  97.3262 333.7064 0015932 119.3751   2.1375 15.24236210  6667
RED-EYE 2 (MERLOT)      
1 45800U 98067RM  20212.16198659  .00002405  00000-0  50457-4 0  9995
2 45800  51.6426 135.6830 0001113 165.2186 194.8836 15.49768319  6653
BEIDOU-3 G3             
1 45807U 20040A   20212.68071611 -.00000363  00000-0  00000+0 0  9999
2 45807   3.0209 298.7826 0005925 345.0309  20.5231  1.00270217   550
RED-EYE 3 (CABERNET)    
1 45809U 98067RN  20212.87232646  .00002522  00000-0  52552-4 0  9995
2 45809  51.6425 132.1775 0001491 165.6959 194.4072 15.49754788  5744
NAVSTAR 79 (USA 304)    
1 45854U 20041A   20210.86132144 -.00000053  00000-0  10000-3 0  9990
2 45854  55.0727 113.8110 0006511  89.8394 270.2622  2.00568325   937
GAOFEN DUOMO (GFDM)     
1 45856U 20042A   20213.16202586  .00000038  00000-0  12874-4 0  9991
2 45856  98.0156 285.9933 0014148 170.6221 189.5264 14.76714065  4139
BY70-2                  
1 45857U 20042B   20213.44274416  .00000664  00000-0  10336-3 0  9999
2 45857  98.0122 286.2361 0011066 159.5813 200.5849 14.76229883  4188
SHIYAN 6 02 (SY-6 02)   
1 45859U 20043A   20213.16234211  .00000000  00000-0  99601-5 0  9998
2 45859  98.2054 219.4276 0015198 176.1539 183.9780 14.59078460  3810
APSTAR 6D               
1 45863U 20045A   20212.33742954 -.00000328  00000-0  00000+0 0  9994
2 45863   0.0783 253.4441 0002658 208.4333 102.5591  1.00270853   338
DEMI                    
1 45916U 98067RP  20212.80760715  .00004785  00000-0  91310-4 0  9998
2 45916  51.6421 132.4953 0002727 102.2487 257.8808 15.50144731  2725
TECHEDSAT 10            
1 45917U 98067RQ  20212.80255063  .00015601  00000-0  27293-3 0  9991
2 45917  51.6412 132.4614 0004562 130.7381 229.4005 15.50802375  2671
AL-AMAL (HOPE)          
1 45918U 20047A   20201.92332176  .00001484 -46927-5  00000-0 0  9999
2 45918  30.3470 309.5833 0007500 293.9000 179.9000 16.12219793    09
KOREASAT 116            
1 45920U 20048A   20212.07393818 -.00000370  00000-0  00000-0 0  9991
2 45920   0.0095   9.3748 0008181   4.1707  77.5149  1.00145035   118
PROGRESS-MS 15          
1 45937U 20050A   20211.85845188 -.00045989  00000-0 -82884-3 0  9998
2 45937  51.6435 137.2685 0000227 278.8486 148.7148 15.49096649   972
2020-051A               
1 45939U 20051A   20211.52826834  .00002367  00000-0  10000-3 0  9994
2 45939  97.4951 285.2224 0009057 243.2036 215.4007 15.24227031   672
2020-051B               
1 45940U 20051B   20211.52966036 -.00000074  00000-0  00000+0 0  9994
2 45940  97.4994 285.2262 0010869 261.0181 213.5046 15.24991895   651
2020-051C               
1 45941U 20051C   20211.52979012 -.00000074  00000-0  00000+0 0  9993
2 45941  97.4981 285.2253 0010311 260.9322 213.6409 15.24950654   657
2020-052A               
1 45983U 20052A   20212.56905538 -.00000119  00000-0  00000+0 0  9997
2 45983  32.2087 317.6209 0065000 265.1078 118.6367  1.42484766    16
MENUT                   
1 55010U 23001B   23072.14306634  .00012842  00000+0  72488-3 0  9996
2 55010  97.4917 133.0639 0010606   6.1957  21.5109 15.13226084 10513`;
