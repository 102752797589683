import { Item } from 'react-stately';
import LegacyMenu from '_molecules/Menu/Menu';
import type { IconName } from 'ui/Icon';
import { Icon } from 'ui/Icon';

export type MenuItem = {
  content: string | undefined;
  onPress?: () => void;
  icon?: IconName;
  show?: boolean;
  items?: MenuItem[];
  active?: boolean;
};

export type MenuProps = {
  items: MenuItem[];
};

const itemFilter = (items: MenuItem[]) =>
  items.filter((item) => !(item.content === undefined || item.show === false));

export const Menu = ({ items }: MenuProps) => {
  return (
    <LegacyMenu aria-label="menu">
      {itemFilter(items).map((item, index) => (
        <Item
          key={`menu-item-${item.content ?? index}`}
          textValue={item.content}
          title={item.items ? <MenuItem {...item} /> : undefined}
        >
          {item.items ? (
            itemFilter(item.items).map((nestedItem, nestedIndex) => (
              <Item
                key={`nested-menu-item-${nestedItem.content ?? nestedIndex}`}
                textValue={nestedItem.content}
              >
                <MenuItem {...nestedItem} />
              </Item>
            ))
          ) : (
            <MenuItem {...item} />
          )}
        </Item>
      ))}
    </LegacyMenu>
  );
};

const MenuItem = ({ content, onPress, icon }: MenuItem) => {
  return (
    <div onClick={onPress} className="flex items-center gap-2">
      {icon ? <Icon kind={icon} inherit={false} /> : null}
      <span>{content}</span>
    </div>
  );
};
