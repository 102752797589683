import { Dialog, Classes, Label, Intent, Spinner } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import React, { useState } from 'react';
import { Formik } from 'formik';
import { toaster } from 'toaster';
import Input from '_molecules/Input/Input';
import Button from '_molecules/Button/Button';
import { postScenario } from 'api/scenarios/service';
import type { IUserWithPermissions } from 'services/api/portal/administration/api/types';
import { useLocalisation } from 'utils/hooks/useLocalisation';
import { Select2 } from 'opencosmos-ui';
import { Item } from 'react-stately';
import { useMission } from 'services/Missions';

type AddScenarioModalProps = {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setUserScenarios: () => void;
  setSelectedScenario: (scenarioId: string, redirect?: boolean) => void;
  redirectAfterCreation: boolean;
  user?: IUserWithPermissions;
};

export const AddScenarioModal = ({
  isOpen,
  setIsOpen,
  setUserScenarios,
  setSelectedScenario,
  user,
  redirectAfterCreation,
}: AddScenarioModalProps) => {
  const [isLoading, setIsLoading] = useState(false);

  const { translate } = useLocalisation();

  const { organisations } = useMission();
  return (
    <Dialog
      isOpen={isOpen}
      onClose={() => setIsOpen(false)}
      canOutsideClickClose={false}
      className="dark:bg-surface-dark dark:text-item-dark-contrast bg-surface"
    >
      <Formik
        initialValues={{
          name: '',
          description: '',
          organisation: '',
        }}
        onSubmit={async (values, { setErrors, resetForm }) => {
          if (!user) return;

          if (values.name === '') {
            setErrors({
              name: translate('datacosmos.addNewProjectDialog.errors.name'),
            });
            return;
          }

          if (values.description === '') {
            setErrors({
              description: translate(
                'datacosmos.addNewProjectDialog.errors.description'
              ),
            });
            return;
          }

          if (values.organisation === '') {
            setErrors({
              organisation: translate(
                'datacosmos.addNewProjectDialog.errors.organisation'
              ),
            });
            return;
          }

          const selectedOrganisation = organisations?.find(
            (org) => org.name === values.organisation
          );
          if (!selectedOrganisation) {
            return;
          }
          setIsLoading(true);

          const { data: newScenario } = await postScenario({
            body: {
              name: values.name,
              description: values.description,
              organisation_id: selectedOrganisation?.id,
            },
          });

          try {
            if (newScenario) {
              setIsOpen(false);
              resetForm();
              setUserScenarios();
              setSelectedScenario(newScenario.id, redirectAfterCreation);
            }

            toaster.show({
              intent: Intent.SUCCESS,
              message: translate(
                'datacosmos.addNewProjectDialog.successfullyCreated',
                {
                  name: values.name,
                }
              ),
            });
          } catch (error) {
            // TODO: Remove try/catch after recreatePermissionTree is updated to handlers
            toaster.show({
              icon: IconNames.ERROR,
              intent: Intent.DANGER,
              message: JSON.stringify(error),
            });
          }

          setIsLoading(false);
        }}
      >
        {({ values, handleChange, handleSubmit, errors, setFieldValue }) => (
          <>
            <div className="bp4-dialog-header dark:bg-surface-dark bg-surface">
              <h5 className="dark:text-item-dark-contrast bp4-heading">
                {translate('datacosmos.projectsDialog.createNewProject')}
              </h5>
              <button
                aria-label="Close"
                className="bp4-dialog-close-button bp4-button bp4-minimal bp4-icon-cross"
                onClick={() => setIsOpen(false)}
              />
            </div>
            <div className={Classes.DIALOG_BODY}>
              <Label>
                <Input
                  type="text"
                  name="name"
                  value={values.name}
                  label={{
                    text: translate('datacosmos.addNewProjectDialog.name'),
                    position: 'top',
                  }}
                  onChange={handleChange}
                />
                <small style={{ color: 'red' }}>
                  {errors.name?.toString()}
                </small>
              </Label>
              <Label>
                <Select2
                  selectedItem={values.organisation}
                  onSelectionChange={(val) => {
                    void setFieldValue('organisation', val);
                  }}
                  placeholder="Select organisation"
                  fill
                  label="Organisation"
                  name="organisation"
                >
                  {organisations?.map((org) => (
                    <Item key={org.name}>{org.name}</Item>
                  ))}
                </Select2>
                <small style={{ color: 'red' }}>
                  {errors.organisation?.toString()}
                </small>
              </Label>
              <Label>
                <Input
                  type="textarea"
                  name="description"
                  value={values.description}
                  label={{
                    text: translate(
                      'datacosmos.addNewProjectDialog.description'
                    ),
                    position: 'top',
                  }}
                  onChange={handleChange}
                />
                <small style={{ color: 'red' }}>
                  {errors.description?.toString()}
                </small>
              </Label>
            </div>
            <div className={Classes.DIALOG_FOOTER}>
              <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                <div className={Classes.DIALOG_FOOTER_ACTIONS + ' gap-2'}>
                  <Button
                    text={translate('datacosmos.buttons.cancel')}
                    onPress={() => {
                      setIsOpen(false);
                    }}
                  />
                  <Button
                    text={
                      isLoading ? (
                        <Spinner size={20} />
                      ) : (
                        translate('datacosmos.buttons.save')
                      )
                    }
                    onPress={() => handleSubmit()}
                  />
                </div>
              </div>
            </div>
          </>
        )}
      </Formik>
    </Dialog>
  );
};
