import type { ReactElement } from 'react';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { resetPopUp } from '../../actions/popUp/thunks';
import { Overlay, H3 } from '@blueprintjs/core';
import type { WithTranslation } from 'react-i18next';
import { withTranslation } from 'react-i18next';
import { DRAGGABLE } from '../../constants/draggable/constants';
import type { IPopUp } from '../../constants/popUp/actionTypes';
import type { AppState } from '../../store';
import type { ThunkDispatch } from 'redux-thunk';
import type { Action } from 'redux';

interface IStateProps {
  popUp?: IPopUp;
}

interface IDispatchProps {
  resetPopUp?: (event: React.SyntheticEvent<HTMLElement>) => void;
}

type IProps = IStateProps & IDispatchProps & WithTranslation;

class PopUp extends Component<IProps> {
  public constructor(props: Readonly<IProps>) {
    super(props);
    this.renderByType = this.renderByType.bind(this);
  }

  private renderByType(): ReactElement {
    const {
      component: Component,
      data,
      functions,
      otherProps,
    } = this.props.popUp;
    if (Component) {
      const initialValues = { ...data };
      return (
        <Component
          {...otherProps}
          initialValues={initialValues}
          {...functions}
          resetPopUp={this.props.resetPopUp}
          t={this.props.t}
        />
      );
    }
    return null;
  }

  public render(): ReactElement {
    const { visible, classNames } = this.props.popUp;
    if (visible) {
      const component = this.renderByType();
      return (
        <Overlay
          isOpen={visible}
          usePortal={false}
          hasBackdrop={false}
          canEscapeKeyClose={true}
          canOutsideClickClose={false}
          onClose={this.props.resetPopUp}
        >
          <div
            id={DRAGGABLE}
            className={`dialog-container ${classNames.join(' ')}`}
          >
            {component ? component : <H3>Form not found!</H3>}
          </div>
        </Overlay>
      );
    }
    return null;
  }
}

export default connect<IStateProps, IDispatchProps>(
  (state: AppState): IStateProps => ({
    popUp: state.popUp,
  }),
  (
    dispatch: ThunkDispatch<AppState, null, Action<string>>
  ): IDispatchProps => ({
    resetPopUp: (): void => dispatch(resetPopUp()),
  })
)(withTranslation()(PopUp));
