import { useAuth } from 'services/auth/AuthWrapper';
import s from 'pages/ops/Scripting/Node-red//index.module.scss';
import { useMission } from 'services/Missions';
import { NODE_RED_INSTANCE_URL } from 'env';

const NodeREDDashboard = () => {
  const { currentMissionId } = useMission();
  const token = useAuth().token;
  const url =
    NODE_RED_INSTANCE_URL +
    currentMissionId +
    '/ui/' +
    '?access_token=' +
    token;

  return (
    <iframe
      id="node_red_d_iframe"
      title="Node-RED Dashboard frame"
      className={s.nodered}
      src={url}
    />
  );
};

export default NodeREDDashboard;
