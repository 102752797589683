import { useState, useMemo } from 'react';

/*
 * Generate a setter per property of an object state
 */
export const useComposedState = <
  State extends object,
  RequiredState = Required<State>,
  Setter = {
    [Key in keyof RequiredState]: (value?: RequiredState[Key]) => void;
  }
>(
  initialState: State
): [State, Setter, React.Dispatch<React.SetStateAction<State>>] => {
  const [state, setState] = useState(initialState);

  const setter = useMemo(() => {
    const keys = Object.keys(state) as (keyof RequiredState)[];
    return keys.reduce((acc, key) => {
      // @ts-expect-error
      acc[key] = (value: RequiredState[typeof key]) => {
        setState((prev) => ({ ...prev, [key]: value }));
      };
      return acc;
    }, {} as Setter);
  }, [state]);

  return [state, setter, setState];
};
