import React, { useRef, useState } from "react";
import {
  OverlayContainer,
  useOverlayPosition,
  useTooltip,
  useTooltipTrigger,
} from "react-aria";
import type {
  AriaTooltipProps,
  TooltipTriggerProps,
} from "@react-types/tooltip";
import { useTooltipTriggerState } from "react-stately";
import Overlay from "../../core/Overlay/Overlay";
import type { Placement } from "@react-types/overlays/src/index";
import classNames from "classnames";

export type TooltipProps = AriaTooltipProps &
  TooltipTriggerProps & {
    /**
     * Trigger for the tooltip.
     */
    children: React.ReactNode;
    /**
     * The text to display in the tooltip.
     */
    content: string;
    /**
     * The function to call when the user clicks the trigger.
     */
    onPress?: () => void;
    /**
     * The placement of the tooltip.
     */
    placement?: Placement;
    className?: string;
  };

const TooltipInner = (props: TooltipProps) => {
  const state = useTooltipTriggerState(props);
  const triggerRef = useRef<HTMLDivElement>(null);
  const tooltipRef = useRef<HTMLDivElement>(null);

  const { tooltipProps } = useTooltip(props, state);
  const { triggerProps } = useTooltipTrigger(props, state, triggerRef);

  const { overlayProps: positionProps } = useOverlayPosition({
    targetRef: triggerRef,
    overlayRef: tooltipRef,
    placement: props.placement ?? "top end",
    isOpen: state.isOpen,
  });

  return (
    <>
      <div
        {...triggerProps}
        ref={triggerRef}
        onClick={props.onPress}
        className={"flex"}
      >
        {props.children}
      </div>

      {state.isOpen && (
        <OverlayContainer>
          <Overlay
            isOpen={state.isOpen}
            onClose={() => state.close()}
            {...tooltipProps}
            style={{ ...positionProps.style, zIndex: 999999999999 }}
            overlayRef={tooltipRef}
            className={classNames(props.className, "w-fit p-1")}
          >
            <span className="text-sm"> {props.content}</span>
          </Overlay>
        </OverlayContainer>
      )}
    </>
  );
};

const Tooltip = ({ children, ...rest }: TooltipProps) => {
  // In order to improve performance, we should only render a tooltip
  // when the user hovers over the element, and remove it when the user
  // stops hovering.
  const [renderTooltip, setRenderTooltip] = useState(false);

  if (renderTooltip) {
    return <TooltipInner {...rest}>{children}</TooltipInner>;
  }

  return (
    <div
      onMouseEnter={() => setRenderTooltip(true)}
      onMouseLeave={() => setRenderTooltip(false)}
      className={"flex"}
    >
      {children}
    </div>
  );
};

export default Tooltip;
