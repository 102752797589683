import type {
  IResults,
  IResultsActionTypes,
} from '../../constants/results/actionTypes';
import {
  RESULTS_RESET,
  RESULTS_UPDATE,
} from '../../constants/results/actionTypes';

export function updateResults(results: IResults): IResultsActionTypes {
  return {
    type: RESULTS_UPDATE,
    results,
  };
}

export function resetResults(): IResultsActionTypes {
  return {
    type: RESULTS_RESET,
  };
}
