import type { ChangeEvent, ReactElement } from 'react';
import React from 'react';
import type { WithTranslation } from 'react-i18next';
import { withTranslation } from 'react-i18next';
import {
  ProgressBar,
  Intent,
  Button,
  ButtonGroup,
  Spinner,
  SpinnerSize,
} from '@blueprintjs/core';
import {
  downloadFile,
  isDevMode,
  showErrorMessage,
} from 'utils/common/CommonUtils';
import { toaster } from 'toaster';
import { SIMULATION_FILE_NAME } from 'constants/fileNames';
import { SocketApi } from 'services/api/msd/eventAnalysisApi';
import type { IDataZoom } from 'components/gridLayout/GridLayout';
import GridLayout from 'components/gridLayout/GridLayout';
import type { Client } from '@stomp/stompjs';
import Summary from 'components/summary/Summary';
import type { ISummaryData } from 'constants/summary/constants';
import { changeCartesian2DPlotColor } from 'utils/simulation/changeCartesian2DPlotColor';
import type { IAPICompute } from 'constants/API/interfaces';
import type { RouteComponentProps } from 'react-router';
import { withRouter } from 'react-router';
import type { IMission } from 'services/Missions';
import type { IUserInformation } from 'constants/user/actionTypes';
import type {
  ISatelliteData,
  ISTState,
} from 'constants/satelliteOrbits/actionTypes';
import { LATITUDE_NAME, LONGITUDE_NAME } from 'constants/map/constants';
import { isDateIntoDateZoom } from 'utils/isDateIntoDateZoom';
import { checkLimit } from 'utils/api/compute/checkLimit';
import { MESSAGE_TYPE } from 'services/api/msd/responseCodes';
import { ASTRUMS } from 'constants/API/constant';
import type { ISimulationAnalyticsTypes } from 'components/simulation/types';
import { DEFAULT_HOURS_IN_DATA_ZOOM } from 'env';
import { analyticsClient } from 'utils/hooks/analytics/useAnalytics';

export interface IErrorData {
  type: MESSAGE_TYPE;
  id: string;
  data: {
    message: string;
    details?: string;
  };
}

export interface IInitData {
  type: MESSAGE_TYPE;
  id: string;
  data: {
    numberOfSatellites: number;
    satelliteIds: string[];
    startDate: string;
    endDate: string;
    parameterNames: string[];
    parameterTypes: string[];
    parameterUnits: string[];
    span: number;
    defaultPlots: { xAxis: string; yAxis: string; width: string }[];
  };
}

export interface IStepData {
  type: MESSAGE_TYPE;
  id: string;
  data: {
    satelliteId: string;
    elapsedTime: number;
    date: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    parameters: any[];
  };
}

interface ISimulateProps extends WithTranslation, RouteComponentProps {
  onClose: Function;
  onComplete: Function;
  computeData: IAPICompute;
  mission: IMission;
  user: IUserInformation;

  satellites: ISTState;
  addSO: Function;
  updateSO: Function;
  removeAllSO: Function;

  predefinedData?: ISimulateState;
  url: string;
  analytics: ISimulationAnalyticsTypes;
}

export interface ISimulateState {
  simulated: boolean; // determines whether simulation has been run yet
  errorData: IErrorData;
  initData: IInitData;
  currentStep: IStepData;
  historySteps: IStepData[];
  progress: number;
  inProgress: boolean;
  resize: boolean;
  isSummaryOpen: boolean;
  summaryData: ISummaryData;
  latIndex: number;
  lonIndex: number;
  dataZoom: IDataZoom;
}

type ScrollBehavior = 'auto' | 'smooth';
const SCROLL_BEHAVIOR: ScrollBehavior = 'smooth';

class Simulate extends React.PureComponent<ISimulateProps, ISimulateState> {
  public state: ISimulateState = {
    simulated: false,
    errorData: null,
    initData: null,
    currentStep: null,
    historySteps: [],
    progress: 0,
    inProgress: true,
    resize: false,
    isSummaryOpen: false,
    summaryData: null,
    latIndex: 0,
    lonIndex: 0,
    dataZoom: this.props.predefinedData
      ? this.props.predefinedData.dataZoom
      : {
          start: 0,
          end: 50,
        },
  };

  private firstMsgType = 0;

  public componentDidMount(): void {
    if (this.props.predefinedData && this.props.predefinedData.simulated) {
      const state = this.props.predefinedData;
      this.onInitialize(state.initData, () => {
        this.onUpdate(state.historySteps);
      });
      this.setState({ summaryData: state.summaryData });
    } else {
      const onSimulationData = (
        data: IInitData & IStepData[] & ISummaryData & IErrorData
      ): void => {
        const validFirstMsgTypes = [
          MESSAGE_TYPE.SIMULATION_INFO,
          MESSAGE_TYPE.SIMULATION_ERROR,
          MESSAGE_TYPE.VALIDATION_ERROR,
        ];
        if (!this.state.inProgress) {
          return;
        }
        if (!this.firstMsgType) {
          this.firstMsgType = data.type;
          if (!validFirstMsgTypes.includes(Number(this.firstMsgType))) {
            const error: IErrorData = {
              id: undefined,
              type: MESSAGE_TYPE.SIMULATION_ERROR,
              data: {
                message: 'Error: simulation headers are lost',
              },
            };
            this.onError(error);
          }
        }
        switch (true) {
          case MESSAGE_TYPE.SIMULATION_ERROR == data.type:
          case MESSAGE_TYPE.VALIDATION_ERROR == data.type: {
            this.onError(data);
            break;
          }
          case MESSAGE_TYPE.SIMULATION_INFO == data.type: {
            this.onInitialize(data);
            break;
          }
          case data.length && MESSAGE_TYPE.SIMULATION_STEP == data[0].type: {
            this.onUpdate(data);
            break;
          }
          case MESSAGE_TYPE.SUMMARY == data.type: {
            this.handleUpdateSummary(data);
            break;
          }
          default:
            console.warn('Other type: ', data);
        }
      };

      const isValidLimits = checkLimit(this.props.computeData);
      if (isValidLimits) {
        this.socket = SocketApi(
          this.props.computeData,
          onSimulationData,
          this.props.url
        );
      } else {
        const error: IErrorData = {
          id: undefined,
          type: MESSAGE_TYPE.VALIDATION_ERROR,
          data: {
            message:
              'Please reduce the number of satellites or the simulation time',
          },
        };
        this.onError(error);
      }
    }

    window.scrollTo({ top: window.outerHeight, behavior: SCROLL_BEHAVIOR });
  }

  public componentWillUnmount(): void {
    this.socket.deactivate();
  }

  private satellitesInit = (): void => {
    const { addSO } = this.props;
    const { initData: data } = this.state;
    data.data.satelliteIds.forEach((id): ISatelliteData => {
      const satellite: ISatelliteData = {
        id,
        name: id,
        path: [],
        visiblePath: [],
        isShow: true,
        astrum: ASTRUMS.EARTH,
      };
      addSO(satellite);
      return satellite;
    });

    const latIndex = data.data.parameterNames.findIndex(
      (n): boolean => n === LATITUDE_NAME
    );
    const lonIndex = data.data.parameterNames.findIndex(
      (n): boolean => n === LONGITUDE_NAME
    );

    //TODO dispatch satellite
    this.setState({ latIndex, lonIndex });
  };

  private satellitesUpdate = (): void => {
    const { satellites } = this.props;
    const {
      latIndex,
      lonIndex,
      historySteps: data,
      initData,
      dataZoom,
    } = this.state;
    const newSatellites = {
      list: satellites.list.map((s): ISatelliteData => {
        return { ...s, path: [], visiblePath: [] };
      }),
    };
    if (data) {
      data.forEach((step): void => {
        const lat = step.data.parameters[latIndex];
        const lng = step.data.parameters[lonIndex];
        const isTrueTime = isDateIntoDateZoom(
          initData.data.startDate,
          initData.data.endDate,
          dataZoom,
          step.data.date
        );
        const newSatellite = newSatellites.list.find(
          (s): boolean => s.id === step.data.satelliteId
        );
        if (newSatellite) {
          if (isTrueTime) {
            newSatellite.visiblePath.push({ lat, lng });
          }
          newSatellite.path.push({ lat, lng });
        }
      });
    }
    newSatellites.list.forEach((s) => this.props.updateSO(s));
  };

  protected onChangeDataZoom = (dataZoom: {
    start: number;
    end: number;
  }): void => {
    this.setState({ dataZoom }, () => {
      this.satellitesUpdate();
    });
  };
  private onError = (data: IErrorData): void => {
    if (
      Number(data.type) !== MESSAGE_TYPE.SIMULATION_ERROR &&
      Number(data.type) !== MESSAGE_TYPE.VALIDATION_ERROR
    ) {
      console.error('incorrect data type');
      return undefined;
    }
    let message = '';
    let timeout;
    switch (Number(data.type)) {
      case MESSAGE_TYPE.VALIDATION_ERROR:
        message = data.data.message;

        analyticsClient.sendError()({
          type: this.props.analytics.FAILURE,
          action: 'Failure',
          item: 'Simulation',
          module: 'MSD',
          error: {
            'Failure Type': 'Validation error',
            error: data.data.message,
          },
        });
        break;
      case MESSAGE_TYPE.SIMULATION_ERROR:
      default:
        const devMode = isDevMode();
        if (devMode) {
          message = `Error: ${data.data.message} \n${data.data.details || ''}`;
          timeout = 0;
        } else {
          message = this.props.t('api.error', {
            email: 'support@open-cosmos.com',
          });
        }
        analyticsClient.sendError()({
          type: this.props.analytics.FAILURE,
          action: 'Failure',
          item: 'Simulation',
          module: 'MSD',
          error: {
            'Failure Type': 'Validation error',
            error: data.data.message,
            errorDetails: data.data.details,
          },
        });

        break;
    }
    toaster.show({
      icon: 'error',
      intent: 'danger',
      message: message,
      timeout: timeout,
    });
    this.setState({ errorData: { ...data } }, this.handleCancel);
  };

  private onInitialize = (data: IInitData, cb?: Function): void => {
    if (Number(data.type) !== MESSAGE_TYPE.SIMULATION_INFO) {
      console.error('incorrect init data type');
      return undefined;
    }
    const { startDate, endDate } = data.data;
    const sDate = new Date(startDate).getTime();
    const eDate = new Date(endDate).getTime();
    const numberHours = (eDate - sDate) / 1000 / 60 / 60;
    const END_HOURS = DEFAULT_HOURS_IN_DATA_ZOOM
      ? Number(DEFAULT_HOURS_IN_DATA_ZOOM)
      : 12;
    let endValue = (END_HOURS * 100) / numberHours;
    endValue = endValue > 100 ? 100 : endValue;
    this.setState(
      {
        simulated: true,
        initData: { ...data },
        dataZoom: { start: 0, end: endValue },
      },
      () => {
        this.props.removeAllSO();
        this.satellitesInit();
        if (cb) {
          cb();
        }
      }
    );
  };
  private onUpdate = (dataArray: IStepData[], cb?: Function): void => {
    const first = dataArray[0];
    const last = dataArray[dataArray.length - 1];
    if (Number(first.type) !== MESSAGE_TYPE.SIMULATION_STEP) {
      console.error('incorrect step data type');
      return undefined;
    }
    let progress = 0;
    if (this.state.initData) {
      const { startDate, endDate } = this.state.initData.data;
      const { date } = last.data;
      const startD = new Date(startDate);
      const endD = new Date(endDate);
      const currD = new Date(date);
      const dPb = endD.getTime() - startD.getTime();
      const dFillPb = currD.getTime() - startD.getTime();
      progress = dFillPb / dPb;
    }

    const animate = progress !== 1;
    this.setState(
      {
        currentStep: { ...last },
        historySteps: [...this.state.historySteps, ...dataArray],
        progress: Math.max(this.state.progress, progress),
        inProgress: animate,
      },
      () => {
        this.satellitesUpdate();
        if (cb) {
          cb();
        }
      }
    );

    // Animate is false when the simulation step received is final, i.e. simulation finished
    if (!animate) {
      analyticsClient.sendInfo()({
        type: this.props.analytics.SUCCESS,
        action: 'Success',
        item: 'Simulation',
        module: 'MSD',
      });
    }
  };
  private handleUpdateSummary = (data: ISummaryData): void => {
    const { satelliteIds } = this.state.initData.data;
    const { data: subData } = data;
    this.setState({
      summaryData: {
        ...data,
        data: changeCartesian2DPlotColor(subData, satelliteIds),
      },
    });
  };
  private socket: Client = null;
  protected handleCancel = (): void => {
    analyticsClient.sendInfo()({
      type: this.props.analytics.CANCEL,
      action: 'Cancel',
      item: 'Simulation',
      module: 'MSD',
    });
    this.socket.deactivate();
    this.setState({ inProgress: false });
  };
  protected handleClose = (): void => {
    this.socket.deactivate();
    this.props.removeAllSO();
    this.props.onClose();
  };
  protected handleComplete = async () => {
    await this.props.onClose();
    await this.props.onComplete(this.state);
    await this.socket.deactivate();
    await this.props.removeAllSO();

    this.props.history.push('/msd');
  };
  protected saveResults = (): void => {
    const { initData, currentStep, historySteps } = this.state;
    const json = JSON.stringify(
      { initData, currentStep, historySteps },
      null,
      2
    );
    downloadFile(json, SIMULATION_FILE_NAME, 'application/json');
  };
  protected uploadResults = (event: ChangeEvent<HTMLInputElement>): void => {
    this.handleCancel();
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (evt): void => {
        try {
          const { initData, currentStep, historySteps } = JSON.parse(
            evt.target.result as string
          );
          this.setState({
            initData,
            currentStep,
            historySteps,
            progress: 1,
            inProgress: false,
          });
        } catch (e) {
          showErrorMessage('File data is incorrect!');
          console.error(e);
        }
      };
      reader.readAsText(file);
    }
  };
  protected handleSwitchSummary = (): void => {
    this.setState({ isSummaryOpen: !this.state.isSummaryOpen });
  };

  public render(): ReactElement {
    const {
      inProgress,
      progress,
      initData,
      errorData,
      historySteps,
      isSummaryOpen,
      summaryData,
      dataZoom,
    } = this.state;
    const { t, mission, user, satellites, updateSO } = this.props;
    const cancelClose = inProgress ? (
      <Button
        className="button"
        disabled={!inProgress}
        intent={'danger'}
        onClick={this.handleCancel}
      >
        <div className="bp4-control-group">
          <>
            <Spinner className="margin" size={SpinnerSize.SMALL} />
            {t('simulate.cancel')}
          </>
        </div>
      </Button>
    ) : (
      <Button
        className="button"
        icon={'chevron-up'}
        intent={'danger'}
        disabled={inProgress}
        onClick={this.handleClose}
      >
        {t('simulate.close')}
      </Button>
    );

    return (
      <div className="results">
        <div className="results-header no-print">
          <h3>{t('simulate.title')}</h3>
          <div className="results-progress-bar">
            <ProgressBar
              animate={inProgress}
              intent={Intent.SUCCESS}
              value={Number(progress)}
            />
          </div>
          <ButtonGroup className="results-button-group">
            {cancelClose}
            <Button
              className="button"
              intent={'none'}
              disabled={!summaryData}
              onClick={this.handleSwitchSummary}
            >
              Report
            </Button>
            <Button
              className="button"
              intent={'success'}
              disabled={progress < 1}
              onClick={this.handleComplete}
            >
              Continue
            </Button>
          </ButtonGroup>
        </div>
        {!errorData && (
          <>
            <div className={isSummaryOpen ? '' : 'hidden'}>
              {summaryData && (
                <Summary
                  mission={mission}
                  user={user}
                  summaryData={summaryData}
                />
              )}
            </div>
            <GridLayout
              dataZoom={dataZoom}
              onChangeDataZoom={this.onChangeDataZoom}
              initData={initData}
              historySteps={historySteps}
              satellites={satellites}
              updateSO={updateSO}
            />
          </>
        )}
      </div>
    );
  }
}

export default withTranslation()(withRouter(Simulate));
