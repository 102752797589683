import { useState } from 'react';
import { showSuccessMessage } from 'utils/common/CommonUtils';
import { useQuery } from 'api/useQuery';
import {
  deleteNetworkSettingsByMissionHandler,
  deleteRadioSettingsByMissionHandler,
  getConfigurationOptions,
  getNetworkConfigurationOptionsByMission,
  getRadioConfigurationOptionsByMissionId,
  putNetworkSettingsByMissionHandler,
  putRadioSettingsByMissionHandler,
} from 'api/gateway/service';
import type {
  GatewayNetworkConfig,
  GatewayRadioConfig,
} from 'api/gateway/types';

const useGatewaySettings = (missionId: number) => {
  const [isFetching, setFetching] = useState(false);

  const { data: options, loading: isFetchingConfigurationOptions } = useQuery(
    getConfigurationOptions,
    { initialData: undefined }
  );

  const {
    data: missionNetworkSettings,
    loading: isFetchingNetworkConfigOptionsByMission,
    refetch: refetchNetworkSettingsByMission,
  } = useQuery(getNetworkConfigurationOptionsByMission, {
    initialData: [],
    skip: !missionId,
    params: { missionId },
  });

  const {
    data: missionRadioSetings,
    loading: isFetchingRadioConfigurationOptions,
    refetch: refetchRadioSettingsByMission,
  } = useQuery(getRadioConfigurationOptionsByMissionId, {
    initialData: [],
    skip: !missionId,
    params: { missionId },
  });

  const putNetworkSettingsByMission = async (
    id: number,
    protocol: string,
    payload: GatewayNetworkConfig
  ) => {
    setFetching(true);
    await putNetworkSettingsByMissionHandler({
      body: payload,
      params: { id, protocol },
    });
    setFetching(false);
    showSuccessMessage('Successfully updated network settings');
    void refetchNetworkSettingsByMission();
  };

  const putRadioSettingsByMission = async (
    id: number,
    radio: string,
    payload: GatewayRadioConfig
  ) => {
    setFetching(true);
    await putRadioSettingsByMissionHandler({
      body: payload,
      params: { id, radio },
    });
    setFetching(false);
    showSuccessMessage('Successfully updated radio settings');
    void refetchRadioSettingsByMission();
  };

  const deleteNetworkSettingsByMission = async (
    id: number,
    protocol: string
  ) => {
    setFetching(true);
    await deleteNetworkSettingsByMissionHandler({
      params: { id, protocol },
    });
    setFetching(false);
    showSuccessMessage('Successfully deleted settings');
  };

  const deleteRadioSettingsByMission = async (id: number, radio: string) => {
    setFetching(true);
    await deleteRadioSettingsByMissionHandler({
      params: { id, radio },
    });
    setFetching(false);
    showSuccessMessage('Successfully deleted settings');
  };

  // The response doesn't return the bypass_decoding_protocol field if it's false,
  // This function adds it to the response to make sure it's always there to avoid
  // save popup appearing when opening individual encodings since upon opening
  // the bypass_decoding_protocol field is set to false if not present.
  const getNetworkSettingsWithDecodingProtocolDefaults = () => {
    return missionNetworkSettings.map((s) => ({
      ...s,
      mission_topology: Object.entries(s.mission_topology)
        .map(([key, app]) => ({
          [key]: {
            ...app,
            bypass_decoding_protocol: Boolean(app.bypass_decoding_protocol),
          },
        }))
        .reduce((acc, curr) => ({ ...acc, ...curr }), {}),
    }));
  };

  return {
    isFetching:
      isFetchingConfigurationOptions ||
      isFetchingNetworkConfigOptionsByMission ||
      isFetchingRadioConfigurationOptions ||
      isFetching,
    options,
    missionNetworkSettings: getNetworkSettingsWithDecodingProtocolDefaults(),
    missionRadioSetings,
    putNetworkSettingsByMission,
    putRadioSettingsByMission,
    deleteRadioSettingsByMission,
    deleteNetworkSettingsByMission,
  };
};

export default useGatewaySettings;
