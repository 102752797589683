import { OpportunityLayer } from 'datacosmos/entities/TaskingOpportunityLayer';
import type { CommonOpportunity } from 'api/tasking/helpers';
import { FieldOfRegardLayer } from 'datacosmos/entities/FieldOfRegardLayer';
import type { SwathLayer } from 'datacosmos/entities/SwathLayer';

export const MAX_ALLOWED_AOI_SIZE_M2 = 15000000 * 1000000;

export enum Steps {
  DataAcquisition,
  ListOfOpportunities,
  ConfirmTaskingRequest,
  AutomatedTasking,
  ManualTasking,
}

export enum TaskingPanel {
  Existing = 'Existing',
  New = 'New',
}

export enum SearchSteps {
  AddAOI,
  SelectTaskingType,
  SelectTaskingDateAndInstruments,
}

export type SwathStyleOptions = {
  layerId: string;
  style: 'normal' | 'highlight';
  visible: boolean;
};

export type FieldOfRegardSwathPair = {
  fieldOfRegard?: FieldOfRegardLayer<CommonOpportunity>;
  swath?: SwathLayer<CommonOpportunity>;
};

export const checkIfWithinTimeframe = (
  fieldOfRegard: FieldOfRegardLayer<CommonOpportunity>,
  swath: SwathLayer<CommonOpportunity>
) => {
  const swathStart = new Date(swath.metadata.Start);
  const swathEnd = new Date(swath.metadata.End);

  const foRStart = new Date(fieldOfRegard.metadata.Start);
  const foREnd = new Date(fieldOfRegard.metadata.End);

  return foRStart <= swathStart && swathEnd <= foREnd;
};

export const swathMatchesFieldOfRegard = (
  swath: SwathLayer<CommonOpportunity>,
  fieldOfRegard: FieldOfRegardLayer<CommonOpportunity>
) => {
  return (
    checkIfWithinTimeframe(fieldOfRegard, swath) &&
    fieldOfRegard.metadata.OrbitNumber === swath.metadata.OrbitNumber
  );
};

export const findSwathForFieldOfRegards = (
  fieldOfRegard: FieldOfRegardLayer<CommonOpportunity>,
  swaths: SwathLayer<CommonOpportunity>[]
) => {
  return swaths.find((swath) =>
    swathMatchesFieldOfRegard(swath, fieldOfRegard)
  );
};

export const pairSwathToFieldOfRegard = (
  fieldsOfRegard: FieldOfRegardLayer<CommonOpportunity>[],
  swaths: SwathLayer<CommonOpportunity>[]
) => {
  const pairs: FieldOfRegardSwathPair[] = fieldsOfRegard.map(
    (fieldOfRegard) => ({
      fieldOfRegard,
      swath: findSwathForFieldOfRegards(fieldOfRegard, swaths),
    })
  );

  const onlySwaths: SwathLayer<CommonOpportunity>[] = swaths.filter(
    (swath) =>
      !pairs.some(
        (pair) => pair.swath && swath.metadata.Id === pair.swath.metadata.Id
      )
  );

  onlySwaths.forEach((swath) => {
    pairs.push({
      swath,
    });
  });

  const onlyFieldsOfRegard: FieldOfRegardLayer<CommonOpportunity>[] =
    fieldsOfRegard.filter(
      (fieldOfRegard) =>
        !pairs.some(
          (pair) =>
            pair.fieldOfRegard &&
            fieldOfRegard.metadata.Id === pair.fieldOfRegard.metadata.Id
        )
    );

  onlyFieldsOfRegard.forEach((fieldOfRegard) => {
    pairs.push({ fieldOfRegard, swath: undefined });
  });

  return pairs;
};

export const extractFieldsOfRegard = (
  opportunities: OpportunityLayer<CommonOpportunity>[]
): FieldOfRegardLayer<CommonOpportunity>[] => {
  if (opportunities.every((opp) => opp instanceof OpportunityLayer)) {
    return opportunities.filter((opp) =>
      opp.metadata.RollAngle.includes('FieldOfRegard')
    );
  }

  return opportunities.filter((opp) => opp instanceof FieldOfRegardLayer);
};

export const extractSwaths = (
  opportunities: OpportunityLayer<CommonOpportunity>[]
): SwathLayer<CommonOpportunity>[] => {
  return opportunities.filter(
    (opp) => !opp.metadata.RollAngle.includes('FieldOfRegard')
  ) as SwathLayer<CommonOpportunity>[];
};
