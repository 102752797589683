import type { ThunkAction } from 'redux-thunk';
import type { AppState } from 'reducers/rootReducer';
import type { Action } from 'redux';
import type { IPointOfInterest } from 'constants/pointsOfInterest/actionTypes';
import { setPopUp } from 'actions/popUp/thunks';
import MarkerForm from 'components/popUp/MarkerForm';
import { removePI, updatePI } from 'actions/pointsOfInterest/helpers';
import type { IMarkerWrapper } from 'declarations/mapDeclarations/Marker';
import { setFocus } from 'actions/focus/thunks';
import { FocusTypes } from 'constants/focus/actionTypes';

export const updatePointOfInterest =
  (
    pointOfInterest: IMarkerWrapper
  ): ThunkAction<void, AppState, null, Action<string>> =>
  async (dispatch, store): Promise<void> => {
    const astrum = store().astrum['current'];
    const pointOfInterestData: IPointOfInterest = {
      id: pointOfInterest.id,
      name: pointOfInterest.name,
      lat: pointOfInterest.getPosition().lat(),
      lon: pointOfInterest.getPosition().lng(),
      altitude: pointOfInterest.altitude,
      elevationAngle: pointOfInterest.elevationAngle,
      orderIndex: pointOfInterest.orderIndex,
      astrum: astrum.planet,
      satelliteMode: pointOfInterest.satelliteMode,
    };
    dispatch(
      setFocus({
        id: pointOfInterestData.id,
        type: FocusTypes.PI_SUB_ID,
        needScroll: true,
        withPopUp: true,
      })
    );
    dispatch(
      setPopUp({
        classNames: ['short-container'],
        component: MarkerForm,
        visible: true,
        data: pointOfInterestData,
        otherProps: {
          type: FocusTypes.PI_SUB_ID,
        },
        functions: {
          onSubmit: (pointOfInterest: IPointOfInterest): void =>
            dispatch(updatePI(pointOfInterest)),
          onClose: (): void => {},
        },
      })
    );
  };
export const removePointOfInterest =
  (
    pointOfInterest: IMarkerWrapper
  ): ThunkAction<void, AppState, null, Action<string>> =>
  async (dispatch): Promise<void> => {
    dispatch(removePI(pointOfInterest.id));
  };
