import { useProjects } from 'datacosmos/stores/ProjectProvider';
import { rerouteHandler } from 'datacosmos/utils/useRerouteHandler';
import { useState, useEffect } from 'react';
import { Redirect, useHistory, useLocation } from 'react-router';
import useLocalStorage from 'utils/hooks/useLocalStorage';
import type {
  DialogListRenderer,
  DialogItemDetailsRenderer,
} from 'datacosmos/components/DatacosmosSelectDialog/DatacosmosSelectDialog';
import DatacosmosSelectDialog from './DatacosmosSelectDialog/DatacosmosSelectDialog';
import type { Scenario } from 'api/scenarios/service';
import { useAuth } from 'services/auth/AuthWrapper';
import { AddScenarioModal } from './AddScenarioModal';
import ProjectDetails from './Header/ProjectDetails';

/**
 * An intermediate page between the "home" screen and the scenarios page.
 * The scenarios are accessed via scenario specific URLs that do not make
 * sense when no scenario is selected. This selector is, on the other hand,
 * allowed to have an undefined scenario selected, and just redirects to
 * the scenario specific URL when one gets selected.
 */
const ProjectRedirectPage = () => {
  const location = useLocation();
  const history = useHistory();

  const [currentProject, setCurrentProject] = useLocalStorage<
    Scenario | undefined
  >('currentProject', undefined);

  const [isOpen, setIsOpen] = useState<boolean>(true);

  const { userScenarios, setCurrentScenario, isLoading } = useProjects();
  const [isAddModalOpen, setIsAddModalOpen] = useState<boolean>(false);

  const [expandedProject, setExpandedProject] = useState<Scenario>();

  const [isAllowedToCreateScenario, setIsAllowedToCreateScenario] =
    useState<boolean>(false);

  const renderProjects: DialogListRenderer<Scenario> = (item, select) => {
    return <span onClick={() => select()}>{item.title}</span>;
  };

  const renderProjectDetails: DialogItemDetailsRenderer<Scenario> = (
    proj,
    open
  ) => {
    return (
      <ProjectDetails
        project={proj}
        handler={open}
        setCurrentProject={setCurrentProject}
        selectedScenario={expandedProject}
        fromProjectRedirect={true}
      />
    );
  };

  const handleProjectClick = (item: Scenario) => {
    setCurrentScenario(item);
    setCurrentProject(item);
    setIsOpen(false);
    history.push({
      pathname: rerouteHandler(item).pathname,
    });
  };

  useEffect(() => {
    if (currentProject) setCurrentScenario(currentProject);
  }, [currentProject, setCurrentScenario]);

  // TODO: this duplicates a lot of code in DataCosmosHeader - share this code
  const { checkPermissions, user } = useAuth();
  const { loadUserScenarios, setScenario } = useProjects();

  useEffect(() => {
    const getScenarioCreatePermission = async () => {
      const permission = await checkPermissions({
        type: 'global',
        actionScope: 'data:scenario:create',
      });
      setIsAllowedToCreateScenario(permission);
    };

    void getScenarioCreatePermission();
  }, [checkPermissions]);

  if (!currentProject) {
    const handleAddProject = () => {
      setIsAddModalOpen(true);
    };
    return (
      <div>
        <DatacosmosSelectDialog<Scenario>
          items={userScenarios}
          listRenderer={renderProjects}
          listItemClickHandler={handleProjectClick}
          title="Select a project"
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          canOutsideClickClose={false}
          canEscapeKeyClose={false}
          isCloseButtonShown={false}
          handleItemDetailsRender={renderProjectDetails}
          selectedItem={expandedProject}
          setSelectedItem={setExpandedProject}
          sortListBy="title"
          handleAddItemClick={
            isAllowedToCreateScenario ? handleAddProject : undefined
          }
          addItemTitle="Create a new project"
          isContentLoading={isLoading}
        />
        <AddScenarioModal
          isOpen={isAddModalOpen}
          setIsOpen={setIsAddModalOpen}
          setUserScenarios={loadUserScenarios}
          setSelectedScenario={setScenario}
          user={user}
          redirectAfterCreation={
            !location.pathname.includes('tasking') &&
            !location.pathname.includes('catalog')
          }
        />
      </div>
    );
  }

  return (
    <Redirect
      from={location.pathname}
      to={{
        pathname: rerouteHandler(currentProject).pathname,
        search: rerouteHandler(currentProject).search,
      }}
      exact
    />
  );
};

export default ProjectRedirectPage;
