import { useEffect } from 'react';
import type { RollAngleConstraint, SZAConstraints } from 'api/tasking/types';
import RangeSlider from '_molecules/RangeSlider/RangeSlider';
import Icon from '_atoms/Icon/Icon';
import IconButton from '_molecules/IconButton/IconButton';
import InfoCard from '_molecules/InfoCard/InfoCard';
import { useLocalisation } from 'utils/hooks/useLocalisation';
import Tooltip from '_atoms/Tooltip/Tooltip';

import { useAnalytics } from 'utils/hooks/analytics/useAnalytics';
import classNames from 'classnames';

export type OpportunityConstraintsProps = {
  setSZAConstraints: (SZAConstraints: SZAConstraints) => void;
  SZAConstraints: SZAConstraints;
  rollAngleConstraint: RollAngleConstraint;
  setRollAngleConstraint: (rollAngleConstraint: RollAngleConstraint) => void;
  setSZAError: (isSSZAErrorZA: boolean) => void;
  setIsRollAngleError: (rollAngleError: boolean) => void;
  SZAError: boolean;
  hasRollAngleError: boolean;
  isSZA: boolean;
  setIsSZA: (isSZA: boolean) => void;
  setIsRollAngle: (isRollAngle: boolean) => void;
  isRollAngle: boolean;
  isOpen: boolean;
  setIsOpen: (opn: boolean) => void;
  constraintsDescription: string;
  getNoOfActiveConstraints: () => number;
  disabled: boolean;
};

export const OpportunityConstraints = ({
  setSZAConstraints,
  SZAConstraints,
  rollAngleConstraint,
  setRollAngleConstraint,
  setSZAError,
  isSZA,
  setIsSZA,
  setIsRollAngle,
  isRollAngle,
  setIsOpen,
  isOpen,
  getNoOfActiveConstraints,
  constraintsDescription,
  disabled,
}: OpportunityConstraintsProps) => {
  const { sendInfo } = useAnalytics();

  useEffect(() => {
    if (SZAConstraints.Min > SZAConstraints.Max) {
      setSZAError(true);
    } else {
      setSZAError(false);
    }
  }, [SZAConstraints.Max, SZAConstraints.Min, setSZAError]);

  const handleSZAConstraintChange = (values: number[]) => {
    setSZAConstraints({
      ...SZAConstraints,
      Min: values[0],
      Max: values[1],
    });
  };

  const handleRollAngleConstraintChange = (values: number[]) => {
    setRollAngleConstraint({
      ...rollAngleConstraint,
      min: values[0],
      max: values[1],
    });
  };

  const getValues = () => {
    return {
      sza: [SZAConstraints.Min, SZAConstraints.Max],
      rollAngle: [rollAngleConstraint.min, rollAngleConstraint.max],
    };
  };

  const areConstraintsApplied = getNoOfActiveConstraints() !== 0;

  const { translate } = useLocalisation();

  return (
    <div className="w-full">
      <InfoCard
        cardContent={
          <div className="flex items-center gap-2">
            <span>Constraints</span>
            {areConstraintsApplied && (
              <span className="-right-4 -top-3 rounded-full bg-surface dark:bg-item-dark dark:text-item-dark-contrast text-xs  h-4 w-4 text-center border-[1px] border-item-contrast">
                {getNoOfActiveConstraints()}
              </span>
            )}
          </div>
        }
        info={{
          iconSize: 16,
          text: constraintsDescription,
        }}
        onClick={() => {
          setIsOpen(isOpen);
        }}
        className={classNames('text-sm w-full', {
          'text-item-contrast-inactive dark:text-item-dark-contrast-inactive !cursor-not-allowed':
            disabled,
          'bg-item-selected dark:bg-item-dark-selected': isOpen,
        })}
        absoluteInfoPosition
        showInfoOn="hover"
      />

      {isOpen && !disabled && (
        <div
          className="absolute w-80 bg-item flex flex-col gap-2 p-1 dark:bg-item-dark dark:text-item-dark-contrast z-20"
          style={{
            left: '445px',
            top: '17rem',
          }}
        >
          <div className="p-2 flex items-center justify-between border-b-2 border-b-contrast-inactive">
            <span>Constraints</span>
            <IconButton
              icon="Collapse"
              onPress={() => {
                setIsOpen(isOpen);
              }}
              size={24}
            />
          </div>

          <div className="flex-col w-full">
            <div className="flex items-center w-full">
              <div className="flex items-center w-full gap-4">
                <InfoCard
                  showInfoOn="hover"
                  cardContent={
                    <div className="flex items-center w-full gap-4">
                      <Icon icon="RollAngle" />
                      <span>Roll angle</span>
                    </div>
                  }
                  info={{
                    text: translate(
                      'datacosmos.tasking.new.constraints.rollAngleDescription'
                    ),
                    iconSize: 16,
                  }}
                  absoluteInfoPosition
                  onClick={() => {}}
                  className="hover:bg-transparent"
                />
              </div>
              <IconButton
                icon={isRollAngle ? 'Trash' : 'PlusSquare'}
                onPress={() => {
                  sendInfo({
                    type: 'Roll angle constraint toggle',
                    action: 'Toggle',
                    item: 'Roll angle constraint',
                    module: 'DataCosmos',
                  });

                  if (isRollAngle) {
                    setIsRollAngle(false);
                  } else {
                    setIsRollAngle(true);
                    setRollAngleConstraint({
                      ...rollAngleConstraint,
                      min: -45,
                      max: 45,
                    });
                  }
                }}
              />
            </div>
            {isRollAngle && (
              <RangeSlider
                minValue={-45}
                maxValue={45}
                numberOfHandles={2}
                onChange={(values) => {
                  handleRollAngleConstraintChange(values);
                }}
                showValuesAboveHandles
                step={1}
                value={getValues().rollAngle}
              />
            )}
          </div>

          <div className="flex-col w-full">
            <div className="flex items-center w-full">
              <div className="flex items-center w-full gap-4">
                <InfoCard
                  showInfoOn="hover"
                  cardContent={
                    <div className="flex items-center w-full gap-4">
                      <Icon icon="SunAngle" />
                      <span>Sun zenith angle</span>
                    </div>
                  }
                  info={{
                    text: translate(
                      'datacosmos.tasking.new.constraints.szaDescription'
                    ),
                    iconSize: 16,
                  }}
                  absoluteInfoPosition
                  onClick={() => {}}
                  className="hover:bg-transparent"
                />
              </div>
              <div className="flex items-center px-1">
                <Tooltip
                  content={translate(
                    'datacosmos.tasking.new.constraints.szaDayButton'
                  )}
                >
                  <IconButton
                    icon="Sun"
                    aria-label="set SZA constraint to day-time"
                    onPress={() => {
                      sendInfo({
                        type: 'SZA constraint set to day',
                        action: 'Set/Click',
                        item: 'SZA constraint',
                        module: 'DataCosmos',
                      });
                      setIsSZA(true);
                      setSZAConstraints({
                        ...SZAConstraints,
                        Active: true,
                        Min: 0,
                        Max: 90,
                      });
                    }}
                  />
                </Tooltip>
              </div>
              <div className="flex items-center px-1 pr-4">
                <Tooltip
                  content={translate(
                    'datacosmos.tasking.new.constraints.szaNightButton'
                  )}
                >
                  <IconButton
                    icon="Moon"
                    aria-label="set SZA constraint to night-time"
                    onPress={() => {
                      sendInfo({
                        type: 'SZA constraint set to night',
                        action: 'Set/Click',
                        item: 'SZA constraint',
                        module: 'DataCosmos',
                      });

                      setIsSZA(true);
                      setSZAConstraints({
                        ...SZAConstraints,
                        Active: true,
                        Min: 90,
                        Max: 180,
                      });
                    }}
                  />
                </Tooltip>
              </div>
              <IconButton
                icon={isSZA ? 'Trash' : 'PlusSquare'}
                onPress={() => {
                  sendInfo({
                    type: 'SZA constraint toggle',
                    action: 'Toggle',
                    item: 'SZA constraint',
                    module: 'DataCosmos',
                  });

                  if (isSZA) {
                    setIsSZA(false);
                  } else {
                    setIsSZA(true);
                    setSZAConstraints({
                      ...SZAConstraints,
                      Active: true,
                      Min: 0,
                      Max: 180,
                    });
                  }
                }}
              />
            </div>
            {isSZA && (
              <RangeSlider
                minValue={0}
                maxValue={180}
                numberOfHandles={2}
                onChange={(values) => {
                  handleSZAConstraintChange(values);
                }}
                showValuesAboveHandles
                step={0.1}
                value={getValues().sza}
              />
            )}
          </div>
        </div>
      )}
    </div>
  );
};
