import type { IStacItem, StacItem } from 'datacosmos/types/stac-types';
import type { ILayerOptions } from './layer';
import { Layer, LayerSourceType } from './layer';

/** Band algebra type options */
export enum BandAlgebraInputType {
  SINGLEBAND = 'Singleband',
  MULTIBAND = 'Multiband',
}

/** Available colormaps https://cogeotiff.github.io/rio-tiler/colormap/ */
export enum COLORMAP {
  RPLUMBO = 'rplumbo',
  INFERNO = 'inferno',
  ACCENT = 'accent',
  TAB10 = 'tab10',
  TAB20 = 'tab20',
  OCEAN = 'ocean',
  GIST_EARTH = 'gist_earth',
  TERRAIN = 'terrain',
  GRAY = 'gray',
  AFMHOT = 'afmhot',
  COOLWARM = 'coolwarm',
  SEISMIC = 'seismic',
  COOL = 'cool',
  RDYLGN = 'rdylgn',
  RDYLBU = 'rdylbu',
  BONE = 'bone',
}

export const ColormapValues = Object.values(COLORMAP)
  .filter((value) => typeof value === 'string')
  .map((value) => value as COLORMAP);

/** Band algebra layer options */
export interface IBandAlgebraLayerOptions {
  colormap: COLORMAP;
  scale: string;
  expression: '';
  bandAlgebraType:
    | BandAlgebraInputType.SINGLEBAND
    | BandAlgebraInputType.MULTIBAND;
  rgbExpression: string;
  rescaleFalse: string;
}

/**
 * Asset Layer an STAC catalog item displayed on the map
 */
export class BandAlgebraSTACLayer extends Layer {
  declare options: ILayerOptions & IBandAlgebraLayerOptions;
  item: StacItem; // TODO: Is it enough to have the item? should we check item.id + collection?,
  expression: string;
  declare layerClass: string;

  /**
   * newDataCosmosLayer creates a new datacosoms layer
   */
  constructor(item: StacItem, expression: string) {
    super(LayerSourceType.ALGEBRA_LAYER);

    this.item = item;
    this.expression = expression;
    this.layerClass = 'BandAlgebraSTACLayer';
  }

  getName() {
    return this.item.properties.title || this.item.id || this.id;
  }

  getDescription() {
    const exp =
      this.expression.split('::').length > 1
        ? this.expression.split('::')[0]
        : this.expression.split('::')[1];
    return 'Expression: ' + exp;
  }

  containsSTACItem(item: IStacItem) {
    return item.id === this.item.id && item.collection === this.item.collection;
  }

  containsSTACAsset() {
    return false;
  }

  containsBandAlgebra(item: IStacItem, expression: string) {
    const expArr = expression ? expression.split('::') : [];
    const storedExpArr = this.expression ? this.expression.split('::') : [];

    if (expArr.length > 1 && storedExpArr.length > 1) {
      return this.containsSTACItem(item) && expArr[0] === storedExpArr[0];
    }

    return Boolean(
      expression &&
        this.containsSTACItem(item) &&
        this.expression === expression
    );
  }

  /**
   * Looks for the sun/solar zenith angle in three possible properties:
   * view:sun_elevation, sun_elevation, SolarZenith (note zenith = 90 - elevation)
   * @returns the zenith angle if one of the properties is defined, else undefined
   */
  sza(): Number | undefined {
    if (!this.item.properties) {
      return undefined;
    }
    if (this.item.properties['view:sun_elevation']) {
      return 90.0 - Number(this.item.properties['view:sun_elevation']);
    }
    // TODO: remove after data has been cleaned
    if (this.item.properties.sun_elevation) {
      return 90.0 - Number(this.item.properties.sun_elevation);
    }
    // TODO: remove after data has been cleaned
    if (this.item.properties.SolarZenith) {
      return Number(this.item.properties.SolarZenith);
    }
    return undefined;
  }

  href(): string | undefined {
    return undefined;
  }

  /**
   * Returns a copy of the current object with the selected options updates.
   */
  cloneWithOptions(options: Partial<ILayerOptions & IBandAlgebraLayerOptions>) {
    const newObject = this.clone();
    newObject.options = { ...newObject.options, ...options };
    return newObject;
  }
}
