import React from 'react';
import type { ReactElement } from 'react';
import { getHash } from '../../utils/common/getHash';
import type { ITreeNodeCustom } from '../../declarations/blueprintjs';

export const getIndexes = (
  nodes: ITreeNodeCustom[],
  index: string = undefined
): ReactElement[] => {
  if (!nodes) {
    return [];
  }
  let indexId = 0;
  let indexLines: ReactElement[] = [];
  nodes.forEach((n) => {
    if (n.disabled) {
      return;
    } else {
      indexId++;
    }
    const splitLength = index ? index.split('.').length : 0;
    const boldStyle = splitLength <= 1 ? 'summary-index-line-bold' : '';
    let currentIndex;
    if (index === undefined) {
      currentIndex = '';
    } else {
      currentIndex = index ? `${index}.${indexId}` : `${indexId}`;
    }
    const children = getIndexes(n.childNodes, currentIndex);
    const tabs = new Array(splitLength).fill('    ').join('');
    const href = n.title;
    const style = {
      paddingLeft: `${splitLength / 2}cm`,
    };
    const current =
      index !== undefined ? (
        <div
          key={getHash(n.title.toString())}
          className={`summary-index-line ${boldStyle}`}
        >
          <div style={style} className="summary-index-line-label">
            {`${tabs}${currentIndex} ${n.title}`}
          </div>
          <span className="summary-index-line-page-wrap">
            {/* eslint-disable-next-line */}
            <a href={`#${href}`} className="summary-index-line-page" />
          </span>
        </div>
      ) : null;
    indexLines.push(...[current, ...children]);
  });
  return indexLines;
};
