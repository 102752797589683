import classNames from 'classnames';
import type { IconName } from 'ui/Icon';
import { Icon } from 'ui/Icon';
import { Text } from 'ui/Text';

export type ItemProps = {
  title?: string;
  icon?: IconName;
};

export type ItemContainerProps = ItemProps & {
  children: React.ReactNode;
  fullWidth?: boolean;
  fullHeight?: boolean;
  className?: string;
};

export const ItemContainer = ({
  children,
  title,
  icon,
  fullHeight,
  fullWidth,
  className,
}: ItemContainerProps) => {
  return (
    <div
      className={classNames(
        'flex items-center p-2 select-none min-w-[100px]',
        className,
        {
          'w-full': fullWidth,
          'h-fit': !fullHeight,
          'h-full': fullHeight,
        }
      )}
    >
      <Icon className="mr-2" kind={icon} />
      <Text className="mr-2" padding={0}>
        {title}
      </Text>
      {children}
    </div>
  );
};

export type ItemTabContainerProps = Omit<ItemContainerProps, keyof ItemProps>;

export const ItemTabContainer = ({
  children,
  fullHeight,
  fullWidth,
  className,
}: ItemTabContainerProps) => {
  return (
    <div
      className={classNames('flex flex-wrap items-center p-2', className, {
        'h-full': fullHeight,
        'h-fit': !fullHeight,
        'w-full': fullWidth,
      })}
    >
      {children}
    </div>
  );
};
