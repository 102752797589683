import type { IStacItem } from 'datacosmos/types/stac-types';
import { BandAlgebraInputType } from 'datacosmos/entities/bandAlgebraLayer';
import type { ISTACOptions } from 'datacosmos/entities/singleBandLayer';
import type { AxiosResponse } from 'axios';
import Axios from 'axios';
import type { COGMetadataForRange } from 'datacosmos/services/tilingApi/cogMetadata';
import {
  emptyCOGMetadataForRange,
  NonEmptyCOGMetadataForRange,
} from 'datacosmos/services/tilingApi/cogMetadata';
import { IMAGE_TILE_SERVER } from 'env';
import type { BBox } from 'geojson';
import type { BandAlgebraOptions } from 'api/views/types';

export interface ICOGMetadataStatistics {
  [key: string]: {
    histogram?: number[][];
    max?: number;
    min?: number;
    percentile_2?: number;
    percentile_98?: number;
    std?: number;
    valid_percent?: number;
    percentile_25?: number;
    percentile_75?: number;
  };
}

export interface ICOGMetadata {
  band_descriptions: [string, string][];
  band_metadata: [string, Object][];
  bounds: number[];
  colorinterp: string[];
  count: number;
  driver: string;
  dtype: string;
  height: number;
  nodata_type: string;
  nodata_value: number;
  overviews: number[];
  width: number;
}

export interface IGeoJSONMetadata {
  [key: string]: any;
}

export interface IBBoxOptions {
  bbox: BBox;
  width: number;
  height: number;
}

const tilingApi = {
  // Generates a URL which can be queried to fetch per-band statistics for the COG at the URL provided.
  generateCOGStatisticsURL(url: string) {
    return `${IMAGE_TILE_SERVER}/cog/statistics?url=${encodeURIComponent(
      url
    )}&p=25&p=75`;
  },

  // Generates a URL which can be queried to fetch metadata for the COG at the URL provided.
  generateCOGMetadataURL(url: string) {
    return `${IMAGE_TILE_SERVER}/cog/info?url=${encodeURIComponent(url)}`;
  },

  metadata: {} as ICOGMetadata,
  statistics: {} as ICOGMetadataStatistics,

  /**
   * Generates a URL template for fetching tiles for a "single band" COG.
   * The tile parameters (zoom, x, y) are left as placeholders.
   * If maximumPixelValue is not null, adds a rescale query parameter between zero and the provided maximum.
   * @param cogURL the location of the tile-able asset (COG)
   * @param maximumPixelValue the maximum value of a pixel in that asset, or null if not known
   * @returns the URL from which tiles of the asset can be fetched
   */
  generateSingleBandTilingURL(
    cogURL: string,
    maximumPixelValue: number | null | undefined,
    options: ISTACOptions | undefined
  ) {
    let rangeQueryParameter = '';
    if (maximumPixelValue !== null && maximumPixelValue !== undefined) {
      rangeQueryParameter = `&rescale=0,${maximumPixelValue}`;
    }

    let optionsParam = '';
    if (options?.color_formula) {
      optionsParam = `&color_formula=${options.color_formula}`;
    }

    let offset = '';
    if (options?.offset?.x) {
      offset = `&x_offset=${options.offset.x}`;
    }

    if (options?.offset?.y) {
      offset += `&y_offset=${options.offset.y}`;
    }

    return (
      IMAGE_TILE_SERVER +
      '/cog/tiles/{z}/{x}/{y}?url=' +
      encodeURIComponent(cogURL) +
      rangeQueryParameter +
      optionsParam +
      offset
    );
  },

  // Generates a URL template for fetching tiles for a band algebra image (combination of multiple images).
  // The tile parameters (zoom, x, y) are left as placeholders.
  generateBandAlgebraTilingURL(
    item: IStacItem,
    expression: string,
    rescale: string,
    color: string,
    bandAlgebraType: string,
    rgbExpression: string,
    rescaleFalse: string
  ) {
    const paramUrl = `/collections/${item.collection}/items/${item.id}`;
    return (
      IMAGE_TILE_SERVER +
      '/stac/tiles/{z}/{x}/{y}?expression=' +
      encodeURIComponent(
        bandAlgebraType === BandAlgebraInputType.MULTIBAND
          ? rgbExpression
          : expression
      ) +
      '&url=' +
      paramUrl +
      '&rescale=' +
      (bandAlgebraType === BandAlgebraInputType.MULTIBAND
        ? rescaleFalse
        : rescale) +
      (bandAlgebraType === BandAlgebraInputType.MULTIBAND
        ? ''
        : '&colormap_name=' + color)
    );
  },

  // Generates a URL which can be queried to fetch geopdf for the COG provided.
  generateSingleBandBboxURL(
    cogURL: string,
    bboxOptions: IBBoxOptions,
    stacOptions: ISTACOptions | undefined
  ) {
    let optionsParam = '';
    if (stacOptions?.color_formula) {
      optionsParam = `&color_formula=${stacOptions.color_formula}`;
    }

    return (
      `http://titiler-service/cog/bbox/${bboxOptions.bbox?.join(',')}/${
        bboxOptions.width
      }x${bboxOptions.height}.tif?url=` +
      cogURL +
      optionsParam
    );
  },

  // Generates a URL which can be queried to fetch geopdf for the band algebra expression provided.
  generateBandAlgebraBboxURL(
    stacItemURL: string,
    expression: string,
    bboxOptions: IBBoxOptions,
    bandOptions: BandAlgebraOptions
  ) {
    return (
      `http://titiler-service/stac/bbox/${bboxOptions.bbox?.join(',')}/${
        bboxOptions.width
      }x${bboxOptions.height}.tif?expression=` +
      encodeURIComponent(
        bandOptions.bandAlgebraType === BandAlgebraInputType.MULTIBAND
          ? bandOptions.rgbExpression
          : expression
      ) +
      '&url=' +
      stacItemURL +
      '&rescale=' +
      (bandOptions.bandAlgebraType === BandAlgebraInputType.MULTIBAND
        ? bandOptions.rescaleFalse
        : bandOptions.scale) +
      (bandOptions.bandAlgebraType === BandAlgebraInputType.MULTIBAND
        ? ''
        : '&colormap_name=' + bandOptions.colormap)
    );
  },
  // Fetch from the tiling service the metadata for the COG at the given URL.
  // tokenProvider is an async function to get a token valid for the tiling API.
  async fetchMetadataForCOG(
    cogURL: string,
    token: string | undefined,
    region?: GeoJSON.Feature
  ): Promise<COGMetadataForRange> {
    const metadataUrl = this.generateCOGMetadataURL(cogURL);
    const statsUrl = this.generateCOGStatisticsURL(cogURL);
    const metadata = Axios.get(metadataUrl, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });

    let stats: Promise<
      AxiosResponse<ICOGMetadataStatistics | GeoJSON.FeatureCollection>
    >;

    if (region) {
      stats = Axios.post(statsUrl, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },

        type: 'FeatureCollection',
        properties: {},
        features: [region],
      });
    } else {
      stats = Axios.get(statsUrl, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });
    }

    return Promise.all([metadata, stats])
      .then((responses) => {
        this.metadata = responses[0].data;

        if (region) {
          this.statistics = (
            (responses[1].data as GeoJSON.FeatureCollection).features[0]
              .properties as { statistics: ICOGMetadataStatistics }
          ).statistics;
        } else {
          this.statistics = responses[1].data as ICOGMetadataStatistics;
        }

        return this.cogMetadataFromJson(this.metadata, this.statistics);
      })
      .catch((error) => {
        console.error('Failed to fetch COG metadata: ', error);
        return emptyCOGMetadataForRange();
      });
  },

  async fetchMetadataForGeoJSON(geoJsonURL: string) {
    const metadataUrl = geoJsonURL;

    return fetch(metadataUrl)
      .then(async (response) => {
        const data = await response.json();
        this.metadata = data.properties;
        return data.properties as IGeoJSONMetadata;
      })
      .catch((err) => {
        console.error('Failed to fetch GeoJSON metadata: ', err);
        return null as unknown as IGeoJSONMetadata;
      });
  },

  // Attempts to convert the given object into a meaningful COGMetadataForRange, returns an empty metadata if it cannot
  cogMetadataFromJson(
    jsonMetadata: ICOGMetadata,
    jsonStatistics: ICOGMetadataStatistics
  ): COGMetadataForRange {
    if (jsonStatistics !== null) {
      return new NonEmptyCOGMetadataForRange(jsonStatistics, jsonMetadata);
    }
    return emptyCOGMetadataForRange();
  },

  async generateAssetPreviewUrl(
    item: IStacItem,
    assetKey: string,
    color_formula?: string
  ) {
    let rangeQueryParameter = '';
    if (
      item.properties['opencosmos:scale'] !== null &&
      item.properties['opencosmos:scale'] !== undefined
    ) {
      rangeQueryParameter = `&rescale=0,${item.properties['opencosmos:scale']}`;
    }

    let optionsParam = '';
    if (color_formula) {
      optionsParam = `&color_formula=${color_formula}`;
    }

    return (
      IMAGE_TILE_SERVER +
      '/stac/preview.webp?height=128&width=128&assets=' +
      encodeURIComponent(assetKey) +
      '&url=' +
      item.assets[assetKey].href +
      rangeQueryParameter +
      optionsParam
    );
  },
  generateBandAlgebraPreviewUrl(
    item: IStacItem,
    expression: string,
    rescale: string,
    color: string,
    bandAlgebraType:
      | BandAlgebraInputType.SINGLEBAND
      | BandAlgebraInputType.MULTIBAND,
    rgbExpression: string,
    rescaleFalse: string
  ) {
    const paramUrl = `/collections/${item.collection}/items/${item.id}`;
    return (
      IMAGE_TILE_SERVER +
      '/stac/preview.webp?expression=' +
      encodeURIComponent(
        bandAlgebraType === BandAlgebraInputType.MULTIBAND
          ? rgbExpression
          : expression
      ) +
      '&url=' +
      paramUrl +
      '&rescale=' +
      (bandAlgebraType === BandAlgebraInputType.MULTIBAND
        ? rescaleFalse
        : rescale) +
      (bandAlgebraType === BandAlgebraInputType.MULTIBAND
        ? ''
        : '&colormap_name=' + color)
    );
  },
};

export default tilingApi;
