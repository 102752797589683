import { apiFetchHandler } from 'api/clientFetch';
import { hosts } from 'api/hosts';
import type { Telemetry } from 'api/telemetry/types';

export * from 'api/telemetry/types';

export type GetTelemetryDefinitionsParams = {
  missionId: number;
};

export const getTelemetryDefinitions = apiFetchHandler<
  Telemetry[],
  GetTelemetryDefinitionsParams
>({
  host: hosts.portal.v0,
  endpoint: ({ missionId }) =>
    `/telemetry/v2/mission/${missionId}/telemetry-definitions`,
  method: 'GET',
  errorMessage: 'Could not get telemetry definitions',
  errorDescription: (e) => e.join('; '),
});
