import type { setPopUp } from '../../actions/popUp/thunks';
import type { IPopUp } from '../../constants/popUp/actionTypes';
import ModuleSelectionPopup from '../ModuleSelectionPopup/ModuleSelectionPopup';

export const showModulePanel = (
  title: string,
  type: string,
  callPopUp: typeof setPopUp
): void => {
  const popUp: IPopUp = {
    visible: true,
    classNames: ['moduleSelector'],
    component: ModuleSelectionPopup,
    otherProps: {
      title,
      type,
    },
  };

  callPopUp(popUp);
};
