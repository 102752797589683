export function throttle(f: Function, t: number): Function {
  // @ts-expect-error
  const self = this;
  let previousCall: number;
  return function (...args: any): void {
    self.lastCall = Date.now();
    if (previousCall === undefined || self.lastCall - previousCall > t) {
      previousCall = self.lastCall;
      // @ts-expect-error
      f.apply(this, args);
    }
  };
}
