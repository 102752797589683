export enum MESSAGE_TYPE {
  VALIDATION_ERROR = -2,
  SIMULATION_ERROR = -1,
  SIMULATION_INFO = 2,
  SIMULATION_STEP = 3,
  SUMMARY = 5,
  COMPUTE_GEOMETRY_REQUEST = 6,
  COMPUTE_GROUND_SEGMENT_REQUEST = 7,
  STOP_SIMULATION = 14,
  UPDATE_SIMULATION_SPEED = 15,
  COMPUTE_FINAL = 17,
}
