import { useState, useMemo } from 'react';
import { Button, NonIdealState, Pre } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import type { ICommandHistory, IReply } from 'utils/hooks/useCommandHistory';
import { SPECIFIC_RESPONSES_PARSERS } from 'constants/ops/rti/oparate/constants';
import s from './index.module.scss';
import CommandResponseErrorBoundary from 'components/CommandResponseErrorBoundary';
import { useAnalytics } from 'utils/hooks/analytics/useAnalytics';

/**
 * Types of responses that need to be aggregated into a single response
 */
const AGGREGATABLE_RESPONSE_TYPES = ['FTPTransferUpdate'];

export const Response = ({
  isShowOriginRes,
  response,
}: {
  isShowOriginRes: boolean;
  response: IReply;
}) => {
  const specificResponseParser = SPECIFIC_RESPONSES_PARSERS.find(
    ({ shouldUse }) => shouldUse(response, response?.type)
  );

  if (!specificResponseParser || isShowOriginRes) {
    const content = JSON.stringify(response, null, 2);
    return <Pre>{content}</Pre>;
  }

  return (
    <CommandResponseErrorBoundary>
      {specificResponseParser.handler(response)}
    </CommandResponseErrorBoundary>
  );
};

export type RepliesInfoProps = {
  commandHistory?: ICommandHistory;
};

const RepliesInfo = ({ commandHistory }: RepliesInfoProps) => {
  const [isShowOriginRes, setShowOriginRes] = useState(false);

  const { sendInfo } = useAnalytics();

  const sortedResponses = useMemo(() => {
    if (!commandHistory) return [];

    const sorted = commandHistory.responsesContent.sort(
      (a, b) =>
        new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
    );

    const latestTransferResponse = [
      sorted.filter((sort) =>
        AGGREGATABLE_RESPONSE_TYPES.includes(sort.type)
      )[0],
    ];

    const nonTransferResponses = sorted.filter((sort) => {
      return !AGGREGATABLE_RESPONSE_TYPES.includes(sort.type);
    });

    if (isShowOriginRes) {
      return sorted;
    }

    return latestTransferResponse
      .concat(nonTransferResponses)
      .filter((r) => Boolean(r));
  }, [commandHistory, isShowOriginRes]);

  if (!commandHistory)
    return (
      <NonIdealState
        icon={IconNames.HISTORY}
        title="Choose item from history."
      />
    );

  return (
    <div className={s.commandHistoryView}>
      <div className={s.commandHistoryViewSequence}>
        <span>SequenceId:</span>
        <span>{commandHistory.sequenceId}</span>
      </div>
      <div className={s.commandHistoryViewItem}>
        <div className={s.commandHistoryViewResponse}>
          <span>Responses:</span>
          <Button
            text={isShowOriginRes ? 'Show Parsed' : 'Show Origin'}
            onClick={() => {
              sendInfo({
                type: isShowOriginRes
                  ? 'Command history show parsed'
                  : 'Command history show origin',
                action: 'Click',
                item: 'Command history show parsed/origin button',
                module: 'OPS',
              });
              setShowOriginRes(!isShowOriginRes);
            }}
          />
        </div>
        {sortedResponses.map((response, index) => (
          <div
            // eslint-disable-next-line react/no-array-index-key
            key={`response.sequenceId-${index}`}
            className={s.repliesPreWrapper}
          >
            <Response isShowOriginRes={isShowOriginRes} response={response} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default RepliesInfo;
