import type { ReactElement } from 'react';
import React, { Component } from 'react';
import type { InjectedFormProps, FormErrors } from 'redux-form';
import { Field, reduxForm, FieldArray } from 'redux-form';
import { Classes, H3, H6, Pre } from '@blueprintjs/core';
import renderField from '../inputField/TextField';
import renderNumericField from '../inputField/NumberField';
import {
  DRAGGABLE,
  DRAGGABLE_HEADER,
} from '../../constants/draggable/constants';
import { dragElement } from '../../services/draggableService/dragElement';
import RenderPaths from '../inputField/PathsField';
import { POLYGON_FORM_NAME } from '../../constants/popUp/constants';
import type {
  IPoint,
  IRegionOfInterest,
} from '../../constants/regionsOfInterest/actionTypes';
import type { IValidateError } from '../../constants/actionTypes';
import {
  inBetweenInclusive,
  isNumeric,
} from '../../utils/common/CommonValidator';
import SelectField from '../inputField/SelectField';
import { selectSatelliteModes } from '../../selectors/satellite';
import type { AppState } from '../../reducers/rootReducer';
import { connect } from 'react-redux';
import formatArrayAsSelectOptions from '../../utils/formatArrayAsSelectOptions';

export interface IValidatePathError {
  _error?: string;
}

interface IValidatePointError {
  lat?: string;
  lng?: string;
}

function validate(values: FormData & IRegionOfInterest): FormErrors {
  if (!Object.keys(values).length) {
    return {};
  }
  let errors: IValidateError = {};
  const { name, elevationAngle } = values;
  if (!name) {
    errors.name = errors._error = 'name_not_empty';
  }
  const isValidAngle =
    !isNumeric(elevationAngle) ||
    !inBetweenInclusive(Number(elevationAngle), 15, 90);
  if (isValidAngle) {
    errors.elevationAngle = errors._error = 'roi_elevation_ang_error';
  }
  const pathsError: IValidatePathError[] = [];
  values.paths.forEach((path: IPoint[], pathIndex: number): void => {
    const pointsError: IValidatePointError[] = [];
    path.forEach((point: IPoint, pointIndex: number): void => {
      const { lat, lng } = point;
      if (!isNumeric(lat) || !inBetweenInclusive(Number(lat), -90, 90)) {
        const latError = 'lat_error';
        pointsError[pointIndex] = { lat: latError };
        pathsError[pathIndex] = { _error: latError };
        errors._error = latError;
      }
      // else {
      //   point.lat = Number(lat);
      // }
      if (!isNumeric(lng) || !inBetweenInclusive(Number(lng), -180, 180)) {
        const lngError = 'lng_error';
        pointsError[pointIndex] = { lat: lngError };
        pathsError[pathIndex] = { _error: lngError };
        errors._error = lngError;
      }
      // else {
      //   point.lng = Number(lng);
      // }
    });
    pathsError[pathIndex] = { ...pathsError[pathIndex], ...pointsError };
  });
  errors = { ...errors, paths: pathsError };
  return errors;
}

interface IPolygonFormProps {
  satelliteModes: string[];
  resetPopUp?: Function;
  onClose?: Function;
  onBack?: Function;
  addRegion?: Function;
  panToBounds?: (bound: google.maps.LatLngBoundsLiteral) => void;
  t?: Function;
  arrayPush?: Function;
  type?: string;
  disable?: boolean;
  modesOnly?: boolean;
}

class PolygonForm extends Component<
  IPolygonFormProps & InjectedFormProps<{}, IPolygonFormProps>
> {
  public componentDidMount(): void {
    dragElement(document.getElementById(DRAGGABLE));
  }

  public onCloseWrapper = (): void => {
    const { initialValues } = this.props;
    const { onClose, resetPopUp } = this.props;
    onClose(initialValues);
    resetPopUp();
  };
  public addRegionWrapper = (): void => {
    const { addRegion } = this.props;
    addRegion();
  };

  public render(): ReactElement {
    const {
      handleSubmit,
      submitSucceeded,
      error,

      resetPopUp,
      panToBounds,
    } = this.props;
    if (submitSucceeded) {
      resetPopUp();
    }
    const { t, type, modesOnly } = this.props;

    const errorDiv = error ? (
      <div className="bp4-callout bp4-intent-danger bp4-icon-error">
        <H6 className="bp4-heading">{t(`validation.errors.${error}`)}</H6>
      </div>
    ) : null;
    const saveTitle = error
      ? t(`validation.errors.${error}`)
      : t('validation.save_title');

    const satelliteModeOptions = formatArrayAsSelectOptions(
      this.props.satelliteModes
    );

    return (
      <form onSubmit={handleSubmit}>
        <div className="bp4-dialog dialog-margin-set-out">
          <div id={DRAGGABLE_HEADER} className="bp4-dialog-header">
            <H3>{t(`validation.header.${type}`)}</H3>
            {/* eslint-disable-next-line */}
            <div
              className="bp4-dialog-close-button bp4-button bp4-minimal bp4-icon-cross"
              onClick={this.onCloseWrapper}
            />
          </div>
          <div className="bp4-dialog-body">
            <div>
              <Field name="id" component="input" type="hidden" />
              <Field
                name="name"
                placeholder="Name"
                component={renderField}
                type="textarea"
              />
            </div>
            {modesOnly && (
              <div>
                <Field
                  name="satelliteModeLabel"
                  component="input"
                  type="hidden"
                />
                <Field
                  name="satelliteMode"
                  title="module_msd.satellite.modes.satellite_mode"
                  onChangeState={(value: string) =>
                    this.props.change('satelliteMode', value)
                  }
                  options={satelliteModeOptions}
                  component={SelectField}
                />
              </div>
            )}
            <Pre>
              <div className="scrolled-body">
                <FieldArray
                  name={'paths'}
                  // @ts-expect-error
                  hideAddRegion={Boolean(this.props.onBack)}
                  drawRegion={this.addRegionWrapper}
                  panToBounds={panToBounds}
                  component={RenderPaths}
                />
              </div>
            </Pre>
            <Pre>
              <div className="bp4-form-group">
                <label className="bp4-label" htmlFor="form-group-input">
                  <H6>
                    {t(`validation.text-field.names.${'elevationAngle'}`)}
                  </H6>
                </label>
                <div className="bp4-form-content">
                  <Field name="elevationAngle" component={renderNumericField} />
                </div>
              </div>
            </Pre>
            {errorDiv}
          </div>
          <div className="bp4-dialog-footer">
            <div className="bp4-dialog-footer-actions">
              {this.props.onBack && (
                <button
                  type="button"
                  className={`bp4-button  ${
                    error ? Classes.INTENT_DANGER : Classes.INTENT_WARNING
                  }`}
                  onClick={() => this.props.onBack()}
                >
                  Back
                </button>
              )}
              <button
                type="submit"
                title={saveTitle}
                className={`bp4-button bp4-icon-saved  ${
                  error ? Classes.INTENT_DANGER : Classes.INTENT_SUCCESS
                }`}
                disabled={Boolean(error)}
                onClick={handleSubmit}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </form>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  satelliteModes: selectSatelliteModes(state),
});

export default reduxForm<{}, IPolygonFormProps>({
  form: POLYGON_FORM_NAME,
  validate,
})(connect(mapStateToProps)(PolygonForm));
