export const SET_FOCUS = 'SET_FOCUS';
export const RESET_FOCUS = 'RESET_FOCUS';

export enum FocusTypes {
  GS_SUB_ID = 'GS',
  PI_SUB_ID = 'PI',
  RI_SUB_ID = 'RI',
  COORDINATES_SUB = 'COORDINATES_SUB',
}

export interface IFocus {
  type: FocusTypes;
  id: string;
  needScroll?: boolean;
  withPopUp?: boolean;
}

interface ISetFocus {
  type: typeof SET_FOCUS;
  focus: IFocus;
}

interface IResetFocus {
  type: typeof RESET_FOCUS;
}

export type focusTypes = ISetFocus | IResetFocus;
