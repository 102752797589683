import React, { useEffect } from 'react';
import moment from 'moment';
import { Tooltip } from 'ui/Tooltip';

import { TIME_FORMAT } from 'constants/datetime';

import s from './styles.module.scss';
import { useLocalisation } from 'utils/hooks/useLocalisation';

function useNewClock() {
  const [date, setDate] = React.useState(moment().utc());

  useEffect(() => {
    const tick = () => {
      setDate(moment().utc());
    };

    const timerID = setInterval(() => tick(), 1000);
    return () => {
      clearInterval(timerID);
    };
  }, []);

  return date;
}

export interface IProps {
  dark?: boolean;
}

function Clock(props: IProps) {
  const timer = useNewClock();
  const { translate } = useLocalisation();
  return (
    <Tooltip content={translate('currentTime')}>
      <div className={[s.container, props.dark && s.container__dark].join(' ')}>
        <div className={s.timer}>{timer.format(TIME_FORMAT)}</div>
        <div className={s.date}>UTC TIME</div>
      </div>
    </Tooltip>
  );
}

export default Clock;
