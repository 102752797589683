import { Button, Intent, MenuItem } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import type { ItemRenderer } from '@blueprintjs/select';
import { Select } from '@blueprintjs/select';
import { Endpoint } from 'api/gateway/types';
import React, { useEffect, useState } from 'react';

export enum Connectors {
  UNKNOWN = 'Select',
  ANY = 'any',
  UMBILICAL = 'umbilical',
  BASEBAND = 'baseband',
}

const VALID_CONNECTORS = [
  Connectors.ANY,
  Connectors.BASEBAND,
  Connectors.UMBILICAL,
];

interface IProps {
  index: number;
  endpoint: Endpoint;
  showButtonIfLast: boolean;
  handleAddEndpoint: (newEndpoint: Endpoint) => void;
  handleModifyAppEndpoint: (modified: Endpoint, index: number) => void;
  handleConnectorDuplicates: (connector: Connectors) => boolean;
  handleDeleteEndpoint: (endpointIndex: number) => void;
  anyConnectorSelectedHandler: (selectedEndpoint: Endpoint) => void;
}

const ConnectorSelect = Select.ofType<Connectors>();

const Endpoint = ({
  index,
  endpoint,
  showButtonIfLast,
  handleAddEndpoint,
  handleModifyAppEndpoint,
  handleConnectorDuplicates,
  handleDeleteEndpoint,
  anyConnectorSelectedHandler,
}: IProps) => {
  const [selectedConnector, setSelectedConnector] = useState<Connectors>(
    endpoint?.when.connector
      ? (endpoint.when.connector as Connectors)
      : Connectors.UNKNOWN
  );

  const [address, setAddress] = useState<string>(
    endpoint ? endpoint.endpoint : ''
  );

  useEffect(() => {
    handleModifyAppEndpoint(
      { endpoint: address, when: { connector: selectedConnector } },
      index
    );
    anyConnectorSelectedHandler({
      endpoint: address,
      when: { connector: selectedConnector },
    });
  }, [
    address,
    anyConnectorSelectedHandler,
    handleModifyAppEndpoint,
    index,
    selectedConnector,
  ]);

  const connectorRenderer: ItemRenderer<Connectors> = (
    connector,
    { handleClick }
  ) => {
    const selected = selectedConnector === connector;

    return (
      <MenuItem
        key={connector}
        onClick={handleClick}
        text={connector}
        active={selected}
        disabled={handleConnectorDuplicates(connector)}
      />
    );
  };

  return (
    <div
      style={{
        display: 'grid',
        gridTemplateColumns: '3.4fr 3.4fr 0.1fr ',
        gap: '10px',
        position: 'relative',
        alignItems: 'center',
      }}
    >
      <div
        style={{
          position: 'absolute',
          backgroundColor: 'black',
          height: '1px ',
          width: '50px',
          left: -25,
          top: 33,
        }}
      />
      <div>
        <label htmlFor="">When using connector</label>
        <div
          className="bp4-select bp4-fill"
          onClick={(e) => e.stopPropagation()}
        >
          <ConnectorSelect
            itemRenderer={connectorRenderer}
            onItemSelect={(item) => setSelectedConnector(item)}
            items={VALID_CONNECTORS}
            filterable={false}
            popoverProps={{ fill: true, minimal: true }}
          >
            <Button
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'flex-start',
              }}
            >
              {selectedConnector}
            </Button>
          </ConnectorSelect>
        </div>
      </div>

      <div>
        <label htmlFor="address">Endpoint or address</label>
        <input
          type="text"
          name="address"
          id="address"
          value={address}
          onChange={(e) => setAddress(e.target.value)}
          className="bp4-input bp4-fill"
          onClick={(e) => e.stopPropagation()}
        />
      </div>

      <div style={{ height: '30px', paddingTop: '9px' }}>
        <Button
          icon={IconNames.TRASH}
          intent={Intent.DANGER}
          onClick={(e) => {
            e.stopPropagation();
            handleDeleteEndpoint(index);
          }}
        />
      </div>

      {showButtonIfLast ? (
        <div style={{ height: '30px', paddingTop: '9px', display: 'flex' }}>
          <Button
            text="Add endpoint"
            style={{ height: '30px' }}
            intent={Intent.PRIMARY}
            onClick={(e) => {
              e.stopPropagation();
              handleAddEndpoint({
                endpoint: '',
                when: { connector: Connectors.UNKNOWN },
              });
            }}
            disabled={selectedConnector === Connectors.ANY}
          />
        </div>
      ) : null}
    </div>
  );
};

export default Endpoint;
