import bboxPolygon from '@turf/bbox-polygon';
import booleanPointInPolygon from '@turf/boolean-point-in-polygon';
import { LayerSourceType } from 'datacosmos/entities/layer';
import { OutlineLayer } from 'datacosmos/entities/outlineLayer';
import { SingleBandSTACLayer } from 'datacosmos/entities/singleBandLayer';
import { useMapLayers } from 'datacosmos/stores/MapLayersProvider';
import { useMap } from 'datacosmos/stores/MapProvider';
import type { LatLng } from 'leaflet';
import { useCallback, useEffect, useMemo, useState } from 'react';

export type ClickedPoint = {
  lat: number;
  lng: number;
};

type ClickedStacItemOptions = {
  /**
   * Whether to add an outline layer around the clicked item
   */
  outlineClickedItem?: boolean;
  /**
   * A callback function that is called when the user clicks on an image
   * @param image The image that the user has clicked on the map
   */
  onImageClick?: (image: SingleBandSTACLayer) => void;
  isDisabled?: boolean;
};

/**
 * useClickedStacItem returns a `SingleBandSTACLayer` that the user has clicked on the map
 * @returns clickedStacLayer: the layer that the user has clicked on the map
 * @returns clickedPoint: the point that the user has clicked on the map as a latlng object `{lat: number, lng: number}`
 * @returns layersContainStacItems: whether the map contains any `SingleBandSTACLayer`s
 * @returns resetClickedLayerAndPoint: a function that resets the clickedStacLayer and clickedPoint
 */
export const useClickedStacItem = (options?: ClickedStacItemOptions) => {
  const { layers, addLayer, removeLayersBySourceType } = useMapLayers();
  const { mapRef } = useMap();

  const [clickedStacLayer, setClickedStacLayer] = useState<
    SingleBandSTACLayer | undefined
  >();

  const [clickedPoint, setClickedPoint] = useState<ClickedPoint | undefined>();

  const stacItemLayers = useMemo<SingleBandSTACLayer[]>(
    () =>
      layers.filter(
        (l) => l instanceof SingleBandSTACLayer
      ) as SingleBandSTACLayer[],
    [layers]
  );

  const layersContainStacItems = useMemo(
    () => stacItemLayers.length > 0,
    [stacItemLayers]
  );

  const resetClickedLayerAndPoint = useCallback(() => {
    setClickedPoint(undefined);
    setClickedStacLayer(undefined);

    if (options?.outlineClickedItem) {
      removeLayersBySourceType(LayerSourceType.ASSET_OUTLINE);
    }
  }, [options?.outlineClickedItem, removeLayersBySourceType]);

  const getClickedLatLngAsCoordArray = useCallback(
    (latlng: LatLng) => {
      if (!mapRef.current) {
        return [];
      }

      if (!latlng) {
        return [];
      }
      return [latlng.lng, latlng.lat];
    },
    [mapRef]
  );

  const getImageLayersContainingClickedPoint = useCallback(
    (point: number[]) => {
      return stacItemLayers.filter((l) => {
        const bboxPoly = bboxPolygon(l.item.bbox);
        return booleanPointInPolygon(point, bboxPoly) && l.options.visible;
      });
    },
    [stacItemLayers]
  );

  useEffect(() => {
    if (!layersContainStacItems) {
      resetClickedLayerAndPoint();
    }
  }, [layersContainStacItems, resetClickedLayerAndPoint]);

  useEffect(() => {
    if (options?.isDisabled) {
      return;
    }
    const map = mapRef.current;

    map?.on('click', (e) => {
      const coords = getClickedLatLngAsCoordArray(e.latlng);
      if (coords.length <= 0) {
        return;
      }

      const imagesContainingPoint =
        getImageLayersContainingClickedPoint(coords);
      if (imagesContainingPoint.length <= 0) {
        return;
      }

      if (
        options?.outlineClickedItem &&
        !layers.find((l) => l instanceof OutlineLayer)
      ) {
        removeLayersBySourceType(LayerSourceType.ASSET_OUTLINE);
        addLayer(new OutlineLayer(imagesContainingPoint[0].item));
      }

      options?.onImageClick?.(imagesContainingPoint[0]);

      setClickedStacLayer(imagesContainingPoint[0]);
      setClickedPoint({ lat: e.latlng.lat, lng: e.latlng.lng });
    });

    return () => {
      map?.off('click');
    };
  }, [
    addLayer,
    getClickedLatLngAsCoordArray,
    getImageLayersContainingClickedPoint,
    mapRef,
    options?.outlineClickedItem,
    layers,
    options,
    removeLayersBySourceType,
  ]);

  return {
    clickedStacLayer,
    clickedPoint,
    layersContainStacItems,
    resetClickedLayerAndPoint,
  };
};
