import React from 'react';
import type { IAsset } from '../../types/stac-types';
import { downloadToFileName } from 'utils/common/CommonUtils';
import { Tooltip } from 'ui/Tooltip';
import type { TrackingEvent } from 'utils/hooks/analytics/useAnalytics';
import { useAnalytics } from 'utils/hooks/analytics/useAnalytics';
import { useLocalisation } from 'utils/hooks/useLocalisation';
import { Icon } from 'opencosmos-ui';
import classNames from 'classnames';
import {
  hasAssetRoleOverview,
  hasAssetRoleThumbnail,
} from 'datacosmos/utils/stac';

interface Props {
  previewableAssets: [string, IAsset][];
  nonPreviewableAssets: [string, IAsset][];
  isAssetDisplayed: (id: string) => boolean;
  handleToggleDisplayAsset: (id: string) => void;
  itemID: string;
  collectionID?: string;
  isHighResPermissionGranted: boolean;
}
const AssetsViewAndDownload: React.FC<Props> = ({
  previewableAssets,
  nonPreviewableAssets,
  itemID,
  collectionID,
  isAssetDisplayed,
  handleToggleDisplayAsset,
  isHighResPermissionGranted,
}) => {
  const { sendInfo } = useAnalytics();
  const { translate } = useLocalisation();

  return (
    <div className="w-full pr-6">
      <div className="w-full pr-2 border-r-2 dark:border-neutral-700 box-border">
        {previewableAssets.length > 0 &&
          previewableAssets.map((a) => (
            <AssetLine
              previewable={true}
              data={a[1]}
              id={a[0]}
              itemID={itemID}
              collectionID={collectionID}
              sendInfo={sendInfo}
              key={a[0]}
              isAssetDisplayed={isAssetDisplayed}
              handleToggleDisplayAsset={handleToggleDisplayAsset}
              isHighResPermissionGranted={isHighResPermissionGranted}
            />
          ))}
        {nonPreviewableAssets.length > 0 &&
          nonPreviewableAssets.map((a) => (
            <AssetLine
              previewable={false}
              data={a[1]}
              id={a[0]}
              itemID={itemID}
              collectionID={collectionID}
              sendInfo={sendInfo}
              key={a[0]}
              isAssetDisplayed={isAssetDisplayed}
              handleToggleDisplayAsset={handleToggleDisplayAsset}
              isHighResPermissionGranted={isHighResPermissionGranted}
            />
          ))}

        {!previewableAssets.length && !nonPreviewableAssets.length && (
          <div className="w-full mt-1 p-1 flex justify-end gap-1 text-sm dark:text-neutral">
            {translate('datacosmos.catalogAndItems.errors.noAssetsAvailable')}
          </div>
        )}
      </div>
    </div>
  );
};
export default AssetsViewAndDownload;

const getAssetTypeIcon = (assetType: string) => {
  if (assetType.includes('xml') || assetType.includes('json')) {
    return 'FileTxt';
  }
  return 'File';
};

const AssetLine = (props: {
  previewable: boolean;
  itemID: string;
  id: string;
  data: IAsset;
  sendInfo: (ev: TrackingEvent) => void;
  isAssetDisplayed: (id: string) => boolean;
  handleToggleDisplayAsset: (id: string) => void;
  isHighResPermissionGranted: boolean;
  collectionID?: string;
}) => {
  const {
    previewable,
    itemID,
    id,
    data,
    collectionID,
    sendInfo,
    handleToggleDisplayAsset,
    isAssetDisplayed,
  } = props;
  const title = data.title ?? id;
  const isDownloadDisabled =
    !hasAssetRoleOverview(data) &&
    !hasAssetRoleThumbnail(data) &&
    !props.isHighResPermissionGranted;
  return (
    <div className="w-full flex gap-1">
      <Tooltip
        content="Open file in new tab"
        className="block flex-1 overflow-hidden"
        disabled={isDownloadDisabled}
      >
        <a
          className={classNames(
            {
              'pointer-events-none': isDownloadDisabled,
            },
            'pl-7 py-1 hover:bg-item-hover dark:hover:bg-item-dark-hover flex justify-start gap-2 text-sm items-center flex-1'
          )}
          href={isDownloadDisabled ? '#' : data.href}
          key={data.href}
          target="_blank"
          rel="noreferrer"
        >
          <Icon icon={getAssetTypeIcon(data?.type ?? 'File')} />
          <span className="text-item-contrast dark:text-item-dark-contrast flex-1 overflow-hidden text-ellipsis">
            {title}
          </span>
        </a>
      </Tooltip>
      {previewable && (
        <Tooltip
          content={
            isAssetDisplayed(id)
              ? 'Remove asset from map'
              : 'Display asset on map'
          }
          className="text-item-contrast dark:text-item-dark-contrast"
        >
          <a
            className="px-2 py-1 hover:bg-item-hover dark:hover:bg-item-dark-hover flex justify-start gap-2 text-sm items-center"
            data-testid="view-asset-button"
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              handleToggleDisplayAsset(id);
            }}
          >
            <Icon icon={isAssetDisplayed(id) ? 'EyeCrossed' : 'Eye'} />
          </a>
        </Tooltip>
      )}
      <Tooltip
        content={'Download file'}
        className="text-item-contrast dark:text-item-dark-contrast"
        disabled={isDownloadDisabled}
      >
        <a
          className={classNames(
            {
              'opacity-50 pointer-events-none': isDownloadDisabled,
            },
            'px-2 py-1 hover:bg-item-hover dark:hover:bg-item-dark-hover flex justify-start gap-2 text-sm items-center'
          )}
          data-testid="download-asset-button"
          onClick={(e) => {
            if (isDownloadDisabled) {
              e.stopPropagation();
              e.preventDefault();
              return;
            }
            e.stopPropagation();
            e.preventDefault();
            void downloadToFileName(data.href, title, data.type);
            if (collectionID)
              sendInfo({
                action: `Download asset`,
                item: 'Download asset button',
                type: 'Click',
                module: 'DataCosmos',
                dimensions: {
                  dimension1: `${collectionID}/${itemID}`,
                  dimension3: `${itemID}`,
                },
                download: data.href,
                additionalParams: {
                  item: itemID,
                  asset: title,
                },
              });
          }}
        >
          <Icon icon="Download" />
        </a>
      </Tooltip>
    </div>
  );
};
