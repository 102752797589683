import axios from 'axios';
import { showErrorMessage } from '../../../../utils/common/CommonUtils';
import { REQUEST_ERROR } from '../../../../constants/errors';
import { portal } from '../../../../constants/mixpanelAnalytics';
import { analyticsClient } from 'utils/hooks/analytics/useAnalytics';

export default (url: string, token: string | undefined) => {
  const postMission = async (name: string, programmeId: number) => {
    try {
      const {
        data: { data },
      } = await axios.post(
        `${url}/missions`,
        { name, programmeId, phase: 'identification' },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      );

      return data;
    } catch (e) {
      analyticsClient.sendError()({
        type: portal.MISSION.CREATE.ERROR,
        action: 'Create error',
        item: 'Mission creation',
        module: 'OPS',
        error: {
          'Failure Type': 'Request error',
          error: REQUEST_ERROR,
          errorDetails: e,
        },
      });
      showErrorMessage(REQUEST_ERROR);
      console.error(e);
    }
  };

  return { postMission };
};
