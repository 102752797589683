import DrawAreaOfInterest from 'datacosmos/components/DrawAreaOfInterest';
import UploadRegion from 'datacosmos/components/UploadRegion';
import { LayerSourceType } from 'datacosmos/entities/layer';
import { useApplicationCatalog } from 'datacosmos/stores/ApplicationCatalogContext';
import { useMapLayers } from 'datacosmos/stores/MapLayersProvider';
import type { IApplication, IAPIAppValue } from 'datacosmos/types/applications';
import { AppTags } from 'datacosmos/types/applications';
import moment from 'moment';
import { useEffect, useState } from 'react';
import DatePicker from '_molecules/DatePicker/DatePicker';
import IconButton from '_molecules/IconButton/IconButton';
import OpenedAppCard from 'datacosmos/components/Applications/SubscriptionApps/Common/OpenedAppCard';
import UnopenedAppCard from 'datacosmos/components/Applications/SubscriptionApps/Common/UnopenedAppCard';
import { parseDate } from '@internationalized/date';
import { btoaSafe } from 'utils/common/btoaSafe';
import { postTicket } from 'api/tickets/service';
import { useActivePage } from 'datacosmos/components/Toolbar/ActivePageProvider';

const DATACOSMOS_IMG = '/images/datacosmos/';

type IProps = {
  app: IApplication;
};

export const QuasarEcosystemCartographyApp: IApplication = {
  get id() {
    return btoaSafe(
      JSON.stringify(
        this.name + this.provider + this.description + this.appScreenshotUrl
      ).substring(0, 75)
    );
  },
  name: 'Marine Ecosystems Cartography',
  description:
    'Quasar Science Resources provides marine ecosystems cartography, in particular, from Posidonia Oceanica (PO) meadows. This cartography provides information on the extent of this important seagrass species, endemic to the Mediterranean Sea. It is based on remotely sensed visual data from the Copernicus Sentinel-2 satellites where the data are analysed using automatic classification with a neural network, developed by Quasar S.R. The cartography is produced on a yearly basis and have a horizontal resolution of 10 meters. They can be used to monitor and evaluate the status of PO meadows, the damage done to them by anchoring over those fields, as well as the restoration in the areas where the PO fields are being protected. Furthermore, the data might be used for carbon stock studies and correlating the meadows with water quality parameters and physical oceanography.',
  shortDescription:
    'The cartography provides yearly information on the Posidonia Oceanica extent on 10-meter spatial resolution, based on remote sensing data from Sentinel-2 satellites.',
  provider: {
    name: 'Quasar Science Resources, S.L.',
    description: '',
    id: 12,
    url: 'https://quasarsr.com/',
    icon_url: DATACOSMOS_IMG + 'quasar_logo.png',
  },
  appScreenshotUrl: DATACOSMOS_IMG + 'quasar_ecosystem_screenshot.jpg',
  frequencyOfData:
    'Frequency of data: Once. It could be a subscription with regular updates. It can be provided on yearly time scales and seasonal time scales',
  price: 'Contact us',
  fee: 'Cost Details: This will depend on the number of images that will make up the final maps, both in extension and over a period of time.',
  inputs: [
    {
      field: 'AOI',
      example: '',
    },
    {
      field: 'startDate',
      example: '',
    },
    {
      field: 'endDate',
      example: '',
    },
  ],
  values: {
    AOI: { value: undefined, isError: false, message: '' },
    startDate: { value: undefined, isError: false, message: '' },
    endDate: { value: undefined, isError: false, message: '' },
  },
  renderer: (app: IApplication) => <QuasarEcosystemCargoraphy app={app} />,
  additionalInfo:
    'This product is not well suited to urgent requests. It can accept subscriptions or on demand. The product can take some weeks to process as it requires external sources of data that would need to be acquired. The product also needs validation.',
  tags: [AppTags.environment],
};

const QuasarEcosystemCargoraphy = ({ app }: IProps) => {
  const [isAppOpened, setIsAppOpened] = useState<boolean>(false);
  const {
    setApplicationAOIs: setQuasarAOI,
    applicationAOIs: quasarAOI,
    setInputData,
    getInstalledStatus,
    toggleAppInstall,
    shouldAutoOpen,
    setSelectedInstalledApp,
  } = useApplicationCatalog();

  const { activePage, setActivePage } = useActivePage();
  const { removeLayersBySourceType } = useMapLayers();

  const [startDate, setStartDate] = useState<Date>();
  const [endDate, setEndDate] = useState<Date>();

  const setValue = (key: string, value: IAPIAppValue['value']) => {
    setInputData(app.name, {
      ...app.values,
      [key]: { value, isError: false, message: '' },
    });
  };

  useEffect(() => {
    setValue('startDate', startDate);
    setValue('AOI', quasarAOI);
    setValue('endDate', endDate);
  }, [startDate, quasarAOI, endDate]);

  const inputs = (): JSX.Element => {
    return (
      <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
        <div>
          <label htmlFor="aoi">Area of interest: </label>
          <div
            style={{
              display: 'grid',
              gridTemplateColumns: '2fr 2fr 0.2fr',
              gap: '5px',
            }}
            id="aoi"
          >
            <DrawAreaOfInterest
              aoiSourceType={LayerSourceType.APPLICATION_AOI}
              setAreasOfInterest={setQuasarAOI}
              buttonForApplications
              multipleAois
            />

            <UploadRegion
              aoiSourceType={LayerSourceType.APPLICATION_AOI}
              setAreaOfInterest={setQuasarAOI}
              buttonForApplications
              buttonTitle="Upload"
              multipleAois
            />

            <IconButton
              icon="Trash"
              size={24}
              onPress={() => {
                setQuasarAOI([]);
                removeLayersBySourceType(LayerSourceType.APPLICATION_AOI);
              }}
              className="justify-self-center self-center"
            />
          </div>
        </div>

        <div id="startDate">
          <DatePicker
            fill
            label="Start date: "
            onChange={(d) => setStartDate(d.toDate('Etc/UTC'))}
            maxValue={parseDate(moment().add(10, 'years').format('YYYY-MM-DD'))}
          />
        </div>

        <div id="endDate">
          <DatePicker
            fill
            label="End date: "
            onChange={(d) => setEndDate(d.toDate('Etc/UTC'))}
            maxValue={parseDate(moment().add(10, 'years').format('YYYY-MM-DD'))}
          />
        </div>
      </div>
    );
  };

  const formatValuesForBody = () => {
    let body = '';

    for (const [field, value] of Object.entries(app.values)) {
      body += `${field}: ${JSON.stringify(value.value)}%0D%0A`;
    }

    return body;
  };

  const handleSubmit = () => {
    let mailBody = `ENTER EXTRA DETAILS HERE%0D%0A----------------------------%0D%0A`;

    mailBody += formatValuesForBody();

    void postTicket({
      body: {
        title: `DataCosmos Subscription to ${app.name}`,
        description: mailBody,
        team: 'datacosmos',
      },
    });
  };

  if (shouldAutoOpen || (isAppOpened && getInstalledStatus(app))) {
    return (
      <OpenedAppCard
        app={app}
        inputsRenderer={inputs}
        setIsAppOpened={setIsAppOpened}
        handleSubmit={handleSubmit}
        isInstalled={getInstalledStatus(app)}
        toggleAppInstall={toggleAppInstall}
      />
    );
  }

  return (
    <UnopenedAppCard
      app={app}
      setIsAppOpened={setIsAppOpened}
      toggleAppInstall={toggleAppInstall}
      isInstalled={getInstalledStatus(app)}
      setSelectedInstalledApp={(app) => {
        setSelectedInstalledApp(app);
        activePage === 'application' && setActivePage(undefined);
      }}
    />
  );
};
