import { useOperate } from 'services/Operate';
import { useCurrentTerminalSession } from 'services/TerminalSessionProvider';
import { useCallback } from 'react';
import { find, get } from 'lodash/fp';
import {
  armedCommandPreviewClose,
  armedCommandPreviewOpen,
  showRightPanel,
} from 'actions/operate/actions';

export const useArmedCommandHandlers = () => {
  const {
    dispatch,
    state: {
      commandPileWorkingSpace,
      armedCommand,
      frozenPile,
      selectedPileItem,
    },
  } = useOperate();

  const { availableCommands, updateWorkspace } = useCurrentTerminalSession();

  const handleUnarmCommand = useCallback(() => {
    updateWorkspace(
      [...(commandPileWorkingSpace ?? []), armedCommand],
      undefined,
      Boolean(frozenPile)
    );
  }, [armedCommand, commandPileWorkingSpace, frozenPile, updateWorkspace]);

  const handleArmedCommandPreview = useCallback(() => {
    if (!armedCommand) return;

    if (get('id', selectedPileItem) === armedCommand.id) {
      dispatch(armedCommandPreviewClose());
      return;
    }

    dispatch(showRightPanel());

    const command = find(['name', armedCommand.command], availableCommands);

    if (command) dispatch(armedCommandPreviewOpen(armedCommand, command));
  }, [armedCommand, availableCommands, dispatch, selectedPileItem]);

  return {
    handleArmedCommandPreview,
    handleUnarmCommand,
  };
};
