import { Breadcrumbs, NonIdealState } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { isEmpty } from 'lodash/fp';

import DefaultLayout from 'layouts/DefaultLayout';
import PopUp from 'components/popUp/PopUp';
import {
  GroundStationProvider,
  useApiGroundStationService,
} from 'services/api/portal/groundStations/GroundStationProvider';
import { useMission } from 'services/Missions';
import NavbarTimers from 'components/Timer/NavbarTimers';
import BottomNavBar from 'components/BottomNavBar';
import type { TAB_STATE } from 'constants/ops/gs_scheduling/constants';
import { GS_SCHEDULING_BOTTOM_NAVBAR_TABS } from 'constants/ops/gs_scheduling/constants';
import { ops } from 'constants/mixpanelAnalytics';
import useOnLoadMetricWithUrlId from 'utils/hooks/analytics/useOnLoadMetricWithUrlId';

import GSMapListContainer from './GSMapListContainer';
import GSSearchListContainer from './GSSearchListContainer';

import mainS from '../RTI/index.module.scss';
import s from './index.module.scss';
import { useState } from 'react';

const GSSchedulingInner = () => {
  const { missions } = useMission();

  const {
    isFetchingPasses,
    isFetchingPassSlots,
    groundStations,
    gsPasses,
    ephemeris,
    ephemerisView,
    ephemerisList,
    chosenStationIdList,
    selectedTab,
    getEphemeris,
    getEphemerisById,
    getGSPassesByParams,
    cancelGSBookedPass,
    manualNewPassEvent,
    setChosenStationIdList,
    setSelectedTab,
    saveEphemeris,
    isSavingTLE,
  } = useApiGroundStationService();

  const [isGsMapVisible, setIsGsMapVisible] = useState<boolean>(true);

  useOnLoadMetricWithUrlId('mission', {
    action: 'Visit',
    item: 'GSScheduling',
    module: 'OPS',
    type: ops.GS_SCHEDULER.VISIT,
  });

  const renderEmptyData = (
    <div className={s.schedulingNoDataContainer}>
      <NonIdealState
        icon={IconNames.CALENDAR}
        title="There is no scheduling for the current mission"
      />
    </div>
  );

  const toggleMapVisibility = () => {
    setIsGsMapVisible(!isGsMapVisible);
  };

  return (
    <>
      <DefaultLayout
        shouldHideClock
        showMissionSelector
        light
        leftHeader={
          <div className={mainS.headerContainer}>
            <Breadcrumbs items={[{ text: 'GS Scheduling' }]} />
          </div>
        }
        header={<NavbarTimers showLinkButton />}
      >
        {isEmpty(groundStations) ? (
          renderEmptyData
        ) : (
          <div className={s.schedulingContainer + ' schedulingContainer'}>
            {isGsMapVisible && (
              <GSMapListContainer
                groundStations={groundStations}
                chosenStationIdList={chosenStationIdList}
                ephemeris={ephemeris}
                ephemerisView={ephemerisView}
                ephemerisList={ephemerisList}
                getEphemeris={getEphemeris}
                getEphemerisById={getEphemerisById}
                setChosenStationIdList={setChosenStationIdList}
                saveTLE={saveEphemeris}
                isSavingTLE={isSavingTLE}
              />
            )}
            <GSSearchListContainer
              chosenStationIdList={chosenStationIdList}
              selectedTab={selectedTab}
              ephemerisView={ephemerisView}
              missions={missions}
              gsPasses={gsPasses || []}
              isFetchingPassSlots={isFetchingPassSlots}
              isFetchingPasses={isFetchingPasses}
              getEphemerisById={getEphemerisById}
              cancelGSBookedPass={cancelGSBookedPass}
              manualNewPassEvent={manualNewPassEvent}
              getGSPassesByParams={getGSPassesByParams}
              isGsMapVisible={isGsMapVisible}
              toggleMapVisibility={toggleMapVisibility}
            />
            <BottomNavBar
              selectedTab={selectedTab}
              setSelectedTab={setSelectedTab as (arg: TAB_STATE) => unknown}
              tabList={GS_SCHEDULING_BOTTOM_NAVBAR_TABS}
            />
          </div>
        )}
      </DefaultLayout>
      <PopUp />
    </>
  );
};

const GSScheduling = () => {
  const { currentMissionId } = useMission();
  return (
    <GroundStationProvider missionId={currentMissionId}>
      <GSSchedulingInner />
    </GroundStationProvider>
  );
};

export default GSScheduling;
