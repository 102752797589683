import { Slider } from '@blueprintjs/core';
import React from 'react';

interface IToggleProps {
  values: string[] | number[];
  labelRenderer: any;
  value: any;
  onChange: any;
  title?: string;
  disabled?: boolean;
}

// const MAX = 1;
// const MIN = -1;
// const STEP = 1;

const Toggle = (props: IToggleProps) => {
  const { values, labelRenderer, onChange, value, title } = props;
  let className;
  switch (value) {
    case values[0]:
      className = 'left';
      break;
    case values[1]:
      className = values.length === 3 ? 'center' : 'right';
      break;
    case values[values.length - 1]:
      className = 'right';
      break;
  }
  if (values.length > 3) {
    console.warn("Wrong length of 'values' array, only 3 elements required.");
  }
  return (
    <>
      {title ? <div className="three-way-toggle__title">{title}</div> : null}
      <div className="three-way-toggle">
        <Slider
          disabled={!!props.disabled}
          className={className}
          labelRenderer={labelRenderer}
          min={+values[0]}
          max={+values[values.length - 1]}
          stepSize={1}
          value={value}
          onChange={onChange}
          showTrackFill={false}
        />
      </div>
    </>
  );
};

export default Toggle;
