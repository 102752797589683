import type { Action } from 'redux';
import type { ThunkAction } from 'redux-thunk';
import {
  addGroundStation,
  updateGroundStation,
  removeGroundStation,
  uploadGroundStations,
} from './action';
import type {
  IGSState,
  IGroundStation,
  IGSService,
} from '../../constants/groundStations/actionTypes';
import type { AppState } from '../../reducers/rootReducer';
import { latLngToDeg } from '../../utils/coordinateConversion';
import CommonApi from '../../services/api/msd/commonMsdApi';
import { getHash } from '../../utils/common/getHash';
import { resetFocus } from '../focus/thunks';
import { FocusTypes } from '../../constants/focus/actionTypes';

export const addGS =
  (
    groundStation: IGroundStation
  ): ThunkAction<void, AppState, null, Action<string>> =>
  async (dispatch): Promise<void> => {
    dispatch(addGroundStation(groundStation));
  };

export const updateGS =
  (
    groundStation: IGroundStation
  ): ThunkAction<void, AppState, null, Action<string>> =>
  async (dispatch): Promise<void> => {
    dispatch(updateGroundStation(groundStation));
  };

export const removeGS =
  (id: string): ThunkAction<void, AppState, null, Action<string>> =>
  async (dispatch): Promise<void> => {
    dispatch(resetFocus());
    dispatch(removeGroundStation(id));
  };

export const loadGroundStations =
  (): ThunkAction<void, AppState, null, Action<string>> =>
  async (dispatch, state): Promise<void> => {
    const stateGS = state().groundStations;
    const astrum = state().astrum['current'];
    if (stateGS.list.find((g) => !g.custom)) {
      return undefined;
    }
    const { groundStations } = await CommonApi.groundStation.get();
    const list: IGroundStation[] = groundStations.map(
      (groundStation: IGSService): IGroundStation => {
        const {
          name,
          latitude: lat,
          longitude: lon,
          altitude,
          minElevation: elevationAngle,
        } = groundStation;
        return {
          id: `${FocusTypes.GS_SUB_ID}-${getHash(
            JSON.stringify(groundStation)
          )}`,
          name,
          custom: false,
          elevationAngle,
          ...latLngToDeg({ lat, lon }),
          altitude,
          select: false,
          optimize: false,

          astrum: astrum.planet,
        };
      }
    );
    const filteredList = list.filter(
      (f, indexF) =>
        !list.find((l, indexL) => f.id === l.id && indexL !== indexF)
    );
    dispatch(uploadGroundStations({ list: filteredList }));
  };

export const uploadGS =
  (
    groundStations: IGSState
  ): ThunkAction<void, AppState, null, Action<string>> =>
  async (dispatch): Promise<void> => {
    dispatch(uploadGroundStations(groundStations));
  };
