import { Icon, Intent, Spinner } from '@blueprintjs/core';
import { IconNames as BpIcons } from '@blueprintjs/icons';
import { Popover2 } from '@blueprintjs/popover2';
import DrawAreaOfInterest from 'datacosmos/components/DrawAreaOfInterest';
import UploadRegion from 'datacosmos/components/UploadRegion';
import type { Scenario } from 'api/scenarios/types';
import { useApplicationCatalog } from 'datacosmos/stores/ApplicationCatalogContext';
import type { IApplication } from 'datacosmos/types/applications';
import { AppTags } from 'datacosmos/types/applications';
import { useEffect, useState } from 'react';
import area from '@turf/area';
import { useAuth } from 'services/auth/AuthWrapper';
import applicationApi from 'datacosmos/services/applicationApi';
import { toaster } from 'toaster';
import { useMapLayers } from 'datacosmos/stores/MapLayersProvider';
import { LayerSourceType } from 'datacosmos/entities/layer';
import { useProjects } from 'datacosmos/stores/ProjectProvider';
import UnopenedAppCard from 'datacosmos/components/Applications/SubscriptionApps/Common/UnopenedAppCard';
import OpenedAppCard from 'datacosmos/components/Applications/SubscriptionApps/Common/OpenedAppCard';
import Select2 from '_molecules/Select2/Select2';
import { Item } from 'react-stately';
import IconButton from '_molecules/IconButton/IconButton';
import { btoaSafe } from 'utils/common/btoaSafe';
import { useActivePage } from 'datacosmos/components/Toolbar/ActivePageProvider';

const DATACOSMOS_IMG = '/images/datacosmos/';

interface IProps {
  app: IApplication;
}

interface IWorkflow {
  uiName: string;
  value: string;
}

const workflows: IWorkflow[] = [
  {
    uiName: 'Buildings',
    value: 'buildings',
  },
  {
    uiName: 'Forest',
    value: 'forest',
  },
  {
    uiName: 'Forest With Heights',
    value: 'forest-with-heights',
  },
  {
    uiName: 'Roads',
    value: 'roads',
  },
  {
    uiName: 'Fields (High Resolution)',
    value: 'fields-high-resolution',
  },
  {
    uiName: 'Fields (Low Resolution)',
    value: 'fields-low-resolution',
  },
  {
    uiName: 'Construction',
    value: 'construction',
  },
];

export const MapflowApp: IApplication = {
  get id() {
    return btoaSafe(
      JSON.stringify(
        this.name + this.provider + this.description + this.appScreenshotUrl
      ).substring(0, 75)
    );
  },
  name: 'MapFlow',
  description:
    'Detect features and objects within your area of interest using satellite imagery, for example buildings, forests, fields and roads, by producing a GeoJSON file that highlights those detected features',
  inputs: [
    {
      example: 'workflow',
      field: 'workflow',
    },
    {
      field: 'areaOfInterest',
      example: 'AoI',
    },
    {
      field: 'scenario',
      example: 'scenario',
    },
  ],
  values: {
    workflow: { value: null, isError: false, message: '' },
    areaOfInterest: { value: null, isError: false, message: '' },
    scenario: { value: null, isError: false, message: '' },
  },
  provider: {
    id: 2,
    name: 'GeoAlert',
    description: 'Geoalert Analytics & Mapping Platform',
    url: 'https://www.geoalert.io/en-US/',
    icon_url: '/images/datacosmos/geoalert.png',
  },
  shortDescription:
    'Detect features and objects within your area of interest using satellite imagery',
  renderer: (app: IApplication) => <MapFlow app={app} />,
  appScreenshotUrl: DATACOSMOS_IMG + 'mapflow_screenshot.png',
  tags: [AppTags.feature_detection],
};

const MapFlow = ({ app }: IProps) => {
  const {
    setInputData,
    applicationAOIs: mapflowAreaOfInterest,
    setApplicationAOIs: setMapflowAreaOfInterest,
    toggleAppInstall,
    getInstalledStatus,
    shouldAutoOpen,
    setSelectedInstalledApp,
  } = useApplicationCatalog();

  const { activePage, setActivePage } = useActivePage();

  const { modifiableScenarios } = useProjects();
  const { token } = useAuth();
  const { removeLayersBySourceType } = useMapLayers();
  const appApi = applicationApi(token);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [selectedWorkflow, setSelectedWorkflow] = useState<IWorkflow>({
    uiName: 'Select a workflow',
    value: '',
  });

  const [selectedScenario, setSelectedScenario] = useState<{
    id: string;
    title: string;
  }>({
    id: '',
    title: 'Select project',
  });

  const [isAppOpened, setIsAppOpened] = useState<boolean>(false);

  const setValue = (key: string, value: any) => {
    setInputData(app.name, {
      ...app.values,
      [key]: { value, isError: false, message: '' },
    });
  };

  const setError = (key: string, message: string) => {
    setInputData(app.name, {
      ...app.values,
      [key]: { value: '', isError: true, message: message },
    });
  };

  const validateInput = (
    workflow: IWorkflow | undefined,
    scenario: Scenario | undefined,
    areaOfInterest: GeoJSON.Polygon | undefined
  ) => {
    let isOk: boolean = true;

    if (!workflow || workflow.value === '') {
      setError('workflow', 'You must select a workflow');
      isOk = false;
    }

    if (!scenario || scenario.id === '') {
      setError('scenario', 'You must select a project');
      isOk = false;
    }

    if (areaOfInterest === undefined) {
      setError('areaOfInterest', 'You must choose an area of interest');
      isOk = false;
    }

    if (areaOfInterest !== undefined && area(areaOfInterest) / 1000000 > 2) {
      setError('areaOfInterest', 'Area of interest must be <= 2 km²');
      isOk = false;
    }

    return isOk;
  };

  const handleSubmit = async () => {
    if (mapflowAreaOfInterest === undefined) {
      return;
    }

    const workflow = app.values.workflow.value as unknown as IWorkflow;
    const scenario = app.values.scenario.value as unknown as Scenario;
    const areaOfInterest = mapflowAreaOfInterest[0];

    if (!validateInput(workflow, scenario, areaOfInterest)) return;

    const payload = {
      'openapp-token': token,
      'workflow-name': workflow.value,
      'area-of-interest': btoaSafe(JSON.stringify(areaOfInterest)),
      'stac-collection-id': 'test_collection_1',
      'datacosmos-scenario': scenario.id,
    };

    try {
      setIsLoading(true);
      await appApi.submitApplication({
        name: 'mapflow-submit-processing',
        inputs: payload,
      });

      setIsLoading(false);

      toaster.show({
        message: 'Completed successfully',
        intent: Intent.SUCCESS,
      });
    } catch (err) {
      toaster.show({
        message: err as string,
        intent: Intent.DANGER,
        icon: BpIcons.ERROR,
      });
    }
  };

  useEffect(() => {
    setValue('workflow', selectedWorkflow);
    setValue('scenario', selectedScenario);
    setValue('areaOfInterest', mapflowAreaOfInterest);

    if (
      mapflowAreaOfInterest?.[0] &&
      area(mapflowAreaOfInterest[0]) / 1000000 > 2
    ) {
      setError('areaOfInterest', 'Area of interest must be <= 2 km²');
    }
  }, [mapflowAreaOfInterest, selectedScenario, selectedWorkflow]);

  const inputs = () => {
    return (
      <>
        <div
          style={{
            display: 'grid',
            gridAutoRows: 'auto',
            gap: '15px',
          }}
        >
          <div>
            <div>
              <Select2
                items={workflows}
                fill
                label={
                  <div style={{ display: 'flex' }}>
                    <span style={{ marginRight: '5px' }}>
                      Mapflow workflow:{' '}
                    </span>
                    <Popover2
                      interactionKind="hover"
                      content={
                        <div
                          style={{
                            lineBreak: 'auto',
                            padding: '7px',
                            lineHeight: '20px',
                            maxWidth: '500px',
                          }}
                          className="dark:bg-surface-dark dark:text-item-dark-contrast"
                        >
                          Choose the workflow to run in your area of interest,
                          see{' '}
                          <a href="https://docs.mapflow.ai/userguides/pipelines.html">
                            https://docs.mapflow.ai/userguides/pipelines.html
                          </a>{' '}
                          and{' '}
                          <a href="https://docs.mapflow.ai/api/processing_api.html#wdname">
                            https://docs.mapflow.ai/api/processing_api.html#wdname
                          </a>
                        </div>
                      }
                    >
                      <Icon
                        icon={BpIcons.INFO_SIGN}
                        color="gray"
                        style={{ marginBottom: '3px' }}
                      />
                    </Popover2>
                  </div>
                }
                onSelectionChange={(item) => {
                  setValue(
                    'workflow',
                    workflows.find((w) => w.value === item)
                  );
                  setSelectedWorkflow(workflows.find((w) => w.value === item));
                }}
                selectedItemClassName="border-2 border-item"
              >
                {(item) => <Item key={item.value}>{item.uiName}</Item>}
              </Select2>
            </div>
            <div>
              {app.values.workflow?.isError && (
                <div>
                  <small style={{ color: '#ff0000' }}>
                    {app.values.workflow.message}
                  </small>
                </div>
              )}
            </div>
          </div>

          <div>
            <span>Area of interest: </span>
            <div
              style={{
                display: 'grid',
                gridTemplateColumns: '2.2fr 2fr 0.2fr',
                gap: '5px',
              }}
            >
              <UploadRegion
                buttonForApplications
                aoiSourceType={LayerSourceType.APPLICATION_AOI}
                setAreaOfInterest={setMapflowAreaOfInterest}
                buttonTitle="Upload"
              />
              <DrawAreaOfInterest
                buttonForApplications
                aoiSourceType={LayerSourceType.APPLICATION_AOI}
                setAreasOfInterest={setMapflowAreaOfInterest}
              />
              <IconButton
                icon="Trash"
                size={24}
                onPress={() => {
                  setMapflowAreaOfInterest(undefined);
                  removeLayersBySourceType(LayerSourceType.APPLICATION_AOI);
                }}
                className="justify-self-center self-center"
              />
            </div>
            <div>
              {app.values.areaOfInterest?.isError && (
                <div>
                  <small style={{ color: '#ff0000' }}>
                    {app.values.areaOfInterest.message}
                  </small>
                </div>
              )}
            </div>
          </div>

          <div>
            <div>
              <Select2
                items={modifiableScenarios}
                fill
                label={
                  <div style={{ display: 'flex' }}>
                    <span style={{ marginRight: '5px' }}>Project: </span>
                    <Popover2
                      interactionKind="hover"
                      content={
                        <div
                          style={{
                            lineBreak: 'auto',
                            padding: '7px',
                            lineHeight: '20px',
                            maxWidth: '200px',
                          }}
                          className="dark:bg-surface-dark dark:text-item-dark-contrast"
                        >
                          Choose the project in which the result of the
                          processing will be stored
                        </div>
                      }
                    >
                      <Icon
                        icon={BpIcons.INFO_SIGN}
                        color="gray"
                        style={{ marginBottom: '3px' }}
                      />
                    </Popover2>
                  </div>
                }
                onSelectionChange={(item) => {
                  setSelectedScenario(
                    modifiableScenarios.find((s) => s.id === item)
                  );
                  setValue('scenario', item);
                }}
                selectedItemClassName="border-2 border-item"
              >
                {(item) => <Item key={item.id}>{item.title}</Item>}
              </Select2>
            </div>
            <div>
              {app.values.scenario?.isError && (
                <div>
                  <small style={{ color: '#ff0000' }}>
                    {app.values.scenario.message}
                  </small>
                </div>
              )}
            </div>
          </div>
        </div>
      </>
    );
  };

  if (shouldAutoOpen || (isAppOpened && getInstalledStatus(app))) {
    return (
      <OpenedAppCard
        app={app}
        inputsRenderer={inputs}
        setIsAppOpened={setIsAppOpened}
        handleSubmit={handleSubmit}
        toggleAppInstall={toggleAppInstall}
        isInstalled={getInstalledStatus(app)}
        submitButtonLabel={
          isLoading ? (
            <div>
              <Spinner size={20} />
            </div>
          ) : (
            'Submit'
          )
        }
      />
    );
  }

  return (
    <UnopenedAppCard
      app={app}
      setIsAppOpened={setIsAppOpened}
      toggleAppInstall={toggleAppInstall}
      isInstalled={getInstalledStatus(app)}
      setSelectedInstalledApp={(app) => {
        setSelectedInstalledApp(app);
        activePage === 'application' && setActivePage(undefined);
      }}
    />
  );
};
