import React, { useState } from 'react';
import { get, pipe, reverse, sortBy, has } from 'lodash/fp';
import { Classes, Icon } from '@blueprintjs/core';

import type { PassSlot } from 'api/groundStation/types';
import { SORTING_ORDER } from 'constants/sortAndOrder';

import s from '../index.module.scss';

interface IGSHeader {
  display: string;
  path?: string;
}

interface IProps {
  tbodyKeyId?: string;
  headerList: IGSHeader[];
  bodyList: string[];
  tableData: any[];
  isFetching?: boolean;
  changeSortOrder?: Function;
  onScroll?: (e: any) => void;
}

export const sorter = (
  field: string,
  orderBy: string,
  arr: PassSlot[]
): PassSlot[] =>
  pipe(sortBy([field]), (items: PassSlot[]) =>
    orderBy === SORTING_ORDER.DESCENDING ? reverse(items) : items
  )(arr);

const GSSchedulingTable = (props: IProps) => {
  const [sortField, setSortField] = useState('startTime');
  const [orderBy, setOrderBy] = useState(SORTING_ORDER.ASCENDING);

  const handleTableHeaderClick = (name: string) => {
    if (props.changeSortOrder) props.changeSortOrder();

    if (name === sortField) {
      setOrderBy(
        orderBy === SORTING_ORDER.ASCENDING
          ? SORTING_ORDER.DESCENDING
          : SORTING_ORDER.ASCENDING
      );
      return;
    }

    setSortField(name);
  };

  const arrowDirection =
    orderBy === SORTING_ORDER.ASCENDING ? 'chevron-down' : 'chevron-up';

  const renderHeader = (displayName: string, fieldName: string) => {
    return (
      <th
        key={displayName}
        className={s.tableHeaderItem}
        onClick={() => handleTableHeaderClick(fieldName)}
      >
        {displayName}
        {fieldName === sortField ? <Icon icon={arrowDirection} /> : null}
      </th>
    );
  };

  const sortedData = !props.changeSortOrder
    ? sorter(sortField, orderBy, props.tableData)
    : props.tableData;

  const renderCell = (item: any, rowIndex: number, columnName: string) => {
    const elementKey = `${columnName}_${rowIndex}`;
    const element = get(columnName, item);

    const isDuration = has('toFormat', element);
    const isAvailability = has('isAvailability', element);
    const isReactElement = React.isValidElement(element);
    const isValid = typeof element === 'string' || typeof element === 'number';

    const isInvalid = !isReactElement && !isValid && !isDuration;

    const colorAvailability = () => {
      const _4MinInSeconds = 240;
      const _2MinInSeconds = 120;
      if (element.data < _2MinInSeconds) {
        return '#FF695E';
      }
      if (element.data <= _4MinInSeconds && element.data > _2MinInSeconds) {
        return 'orange';
      }
      return 'black';
    };

    if (isInvalid) {
      return <td key={elementKey} />;
    }

    if (isReactElement) {
      return <td key={elementKey}>{element}</td>;
    }

    if (isDuration && !isAvailability) {
      return <td key={elementKey}>{element.toFormat(element.data)}</td>;
    }

    if (isAvailability) {
      return (
        <td
          key={elementKey}
          style={{
            color: colorAvailability(),
          }}
        >
          {element.toFormat(element.data)}
        </td>
      );
    }

    return <td key={elementKey}>{element}</td>;
  };

  const renderBody = () =>
    sortedData.map((item, rowIndex) => (
      <tr key={get(props.tbodyKeyId, item) || `tr_${rowIndex}`}>
        {props.bodyList.map((columnName) =>
          renderCell(item, rowIndex, columnName)
        )}
      </tr>
    ));

  return (
    <div onScroll={props.onScroll} className={s.schedulingListTableContainer}>
      <table
        className={[Classes.HTML_TABLE, s.schedulingListTable, 'relative'].join(
          ' '
        )}
      >
        <thead className="sticky top-0 bg-[#f6f7f9]">
          <tr>
            {props.headerList.map(({ display, path }) => {
              return path ? (
                renderHeader(display, path)
              ) : (
                <th key={display}>{display}</th>
              );
            })}
          </tr>
        </thead>
        <tbody>{renderBody()}</tbody>
      </table>
    </div>
  );
};

export default GSSchedulingTable;
