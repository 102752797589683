import type { Activity } from 'api/activities/service';

export const hasImager = (value: unknown) => {
  type Value =
    | { metadata?: { parameters?: { imager?: { name?: string } } } }
    | undefined;
  return Boolean((value as Value)?.metadata?.parameters?.imager?.name);
};

export type PartialSwathLayer = {
  metadata: Activity;
  sourceType: number;
  id: string;
};

export const getLayersWithImager = (layers: unknown[]) => {
  const swathMetadata: PartialSwathLayer[] = [];
  (
    layers as (PartialSwathLayer | Partial<PartialSwathLayer> | undefined)[]
  ).forEach((layer) => {
    if (!hasImager(layer)) return;
    swathMetadata.push(layer as PartialSwathLayer);
  });
  return swathMetadata;
};

export const getLayersWithType = (layers: unknown[]) => {
  return (
    layers as (PartialSwathLayer | Partial<PartialSwathLayer> | undefined)[]
  ).filter((layer) => {
    if (!layer) return false;
    if (!layer.metadata) return false;
    return layer.metadata.type;
  }) as PartialSwathLayer[];
};
