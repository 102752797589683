import type { IInitData, IStepData } from 'components/simulation/Simulation';
import type {
  IAxis,
  IChartSeriesData,
  ISelected,
  ISeriesData,
} from 'components/charts/Chart';
import { downSample } from 'utils/chart/downSample';
import { booleanDownSample } from 'utils/chart/booleanDownSample';
import type { IChartWrapper } from 'declarations/chartDeclarations/IChartWrapper';
import { DOWNSAMPLE_BOOLEAN, DOWNSAMPLE } from 'env';
import echarts from 'echarts';
import EChartOption = echarts.EChartOption;

const selectDownSampleBoolean = (triple: boolean): Function => {
  switch (DOWNSAMPLE_BOOLEAN) {
    case 'boolean':
      return booleanDownSample.bind(null, triple);
    case 'npm':
      return downSample.bind(null, triple);
    default:
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      return (arg: any): any => arg;
  }
};

export const updateChartData = (
  chart: IChartWrapper,
  satellitesData: ISeriesData[],
  historySteps: IStepData[],
  initData: IInitData,
  selectedX: ISelected,
  selectedY: ISelected,
  chartOptions: {
    type: string;
    smooth: boolean;
  },
  axisX: IAxis[],
  axisY: IAxis[],
  numberOfLayout: number
): void => {
  if (!satellitesData) {
    return undefined;
  }
  if (!chart.getOption()) {
    return undefined;
  }
  if (!chart.getOption().dataZoom[0]) {
    return undefined;
  }
  const tmpData = new Array(satellitesData.length);
  const { startValue: start, endValue: end } = chart.getOption().dataZoom[0];
  let downSampleFn: Function;
  for (let i = 0; i < satellitesData.length; i++) {
    tmpData[i] = historySteps.filter(
      (h: IStepData): boolean =>
        h.data.satelliteId === initData.data.satelliteIds[i]
    );
    tmpData[i] = tmpData[i].filter((h: IStepData): boolean => {
      const date = new Date(h.data.date);
      // @ts-expect-error
      return !(date.getTime() < start || date.getTime() > end);
    });
    tmpData[i] = tmpData[i].sort((h1: IStepData, h2: IStepData): number => {
      const h1Date = new Date(h1.data.date);
      const h2Date = new Date(h2.data.date);
      return h1Date.getTime() - h2Date.getTime();
    });
    if (selectedX.type === 'time') {
      if (selectedY.type === 'boolean') {
        satellitesData[i].data = tmpData[i].map(
          (h: IStepData): IChartSeriesData => {
            return {
              value: [h.data.date, Number(h.data.parameters[selectedY.index])],
            };
          }
        );
        downSampleFn = selectDownSampleBoolean(false);
      } else {
        satellitesData[i].data = tmpData[i].map(
          (h: IStepData): IChartSeriesData => {
            return {
              value: [h.data.date, Number(h.data.parameters[selectedY.index])],
            };
          }
        );
        downSampleFn = downSample.bind(null, false);
      }
    } else {
      if (selectedY.type === 'boolean') {
        satellitesData[i].data = tmpData[i].map(
          (h: IStepData): IChartSeriesData => {
            return {
              value: [
                h.data.parameters[selectedX.index],
                Number(h.data.parameters[selectedY.index]),
                h.data.date,
              ],
            };
          }
        );
        downSampleFn = selectDownSampleBoolean(true);
      } else {
        satellitesData[i].data = tmpData[i].map(
          (h: IStepData): IChartSeriesData => {
            return {
              value: [
                h.data.parameters[selectedX.index],
                h.data.parameters[selectedY.index],
                h.data.date,
              ],
            };
          }
        );
        downSampleFn = downSample.bind(null, true);
      }
    }
    if (DOWNSAMPLE) {
      const legend = chart.getOption().legend[0].selected;
      const truth = Object.keys(legend).filter(
        (key) => legend[key] === true
      ).length;
      satellitesData[i].data = downSampleFn(
        satellitesData[i].data,
        truth,
        numberOfLayout
      );
    }
  }
  chart.setOption({
    custom: { selectedX: selectedX, selectedY: selectedY, initData: initData },
  });

  const labelX = {
    name:
      selectedX.index === -1
        ? 'Time'
        : axisX
        ? axisX.find((a) => a.index === selectedX.index).name
        : '',
    nameLocation: 'middle',
    nameGap: 35,
  };
  const labelY = {
    name:
      selectedY.index === -1
        ? 'Time'
        : axisY
        ? axisY.find((a) => a.index === selectedY.index).name
        : '',
    nameLocation: 'middle',
    nameGap: 60,
  };
  const option = chart.getOption();
  const xAxis = option.xAxis as EChartOption.XAxis[] | any;
  const yAxis = option.yAxis as EChartOption.YAxis[] | any;

  if (selectedX.index >= 0) {
    xAxis[1] = {
      ...xAxis[1],
      ...labelX,
    };
  } else {
    xAxis[0] = {
      ...xAxis[0],
      ...labelX,
    };
  }

  yAxis[0] = {
    ...yAxis[0],
    ...labelY,
  };

  chart.setOption({
    xAxis: xAxis,
    yAxis: yAxis,
    series: satellitesData.map((s): ISeriesData => {
      return { ...s, ...chartOptions };
    }),
  });
};
