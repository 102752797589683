import { useRef } from "react";
import type { ListState } from "react-stately";
import type { Node } from "@react-types/shared";
import { useFocusVisible, useOption } from "react-aria";
import classNames from "classnames";

const ELEMENTS_TO_IGNORE = ["svg", "path"];

export type OptionProps<T> = {
  item: Node<T>;
  state: ListState<T>;
};

/**
 * Option is a component that represents an option in a list.
 *
 * **Not expected to be used directly, but through various list components**
 */
const Option = <T extends object>({ item, state }: OptionProps<T>) => {
  const ref = useRef<HTMLLIElement>(null);
  const { optionProps, isDisabled, isSelected, isFocused } = useOption(
    { key: item.key },
    state,
    ref
  );

  const { isFocusVisible } = useFocusVisible();
  return (
    <li
      ref={ref}
      {...optionProps}
      onPointerUp={(e) => {
        // Clicked item is found in the elements to ignore array, so we do the action it has defined instead of selecting an
        // item from the dropdown
        if (
          ELEMENTS_TO_IGNORE.includes(
            (e.target as EventTarget & HTMLElement).nodeName
          )
        )
          return;

        e.stopPropagation();

        optionProps.onClick?.(e);
        optionProps.onPointerUp?.(e);
      }}
      className={classNames(
        "p-1 text-sm cursor-pointer border-none hover:text-accent-900 dark:hover:text-accent",
        {
          "bg-item-selected text-accent-900 dark:bg-item-dark-selected dark:text-accent":
            isSelected,
          "text-item-inactive dark:text-item-inactive-dark": isDisabled,
          "text-accent-900 dark:text-accent": isFocused && isFocusVisible,
          "bg-none": !isFocused || !isSelected,
        }
      )}
    >
      {item.rendered}
    </li>
  );
};

export default Option;
