import type { Dispatch } from 'react';
import { useCallback, useEffect, useState } from 'react';
import { map, isNil } from 'lodash/fp';
import type { Canceler } from 'axios';
import axios from 'axios';
import type { IconName } from '@blueprintjs/core';
import { v1 as uuid } from 'uuid';
import { showErrorMessage } from 'utils/common/CommonUtils';
import { AVAILABLE_CLOUD_STORAGE } from 'constants/fileExplorer/constants';
import {
  downloadFileContent,
  toAsyncCloudContent,
} from 'utils/fileExplorer/cloudStorageUtils';
import { REQUEST_ERROR } from 'constants/errors';
import type { ITransferAction } from 'services/storage/TransferProgressProvider';
import {
  removeDoubleSlashes,
  removeFirstSlash,
} from 'utils/common/stringUtils';

import {
  deleteFileOrFolder,
  getFolderContent as getFolderCont,
  getFileContent as getFileCont,
  getFileMetadata,
  createOrUpdateFile,
  createOrUpdateFolder,
} from 'api/cloudStorage/service';
import type {
  CloudStorageContent,
  CloudStorageContentWithFile,
} from 'api/cloudStorage/types';
import { useQuery } from 'api/useQuery';

// TODO: I belive this does not only represent folders and hence it should be renamed
export interface IFolder {
  type: string;
  path?: string;
  icon?: IconName;
  name: string;
  aliasName?: string;
  mission?: string;
  size?: number;
  lastModified?: string;
  mimeType?: string;
  file?: Uint8Array;
  etag?: string;
  checksum?: number;
  contents?: IFolder[];
}

export type IDownloadFile = (
  path: string,
  preview?: boolean,
  type?: string,
  cancelTokenCb?: (id: string, c: Canceler) => void
) => void;

export interface ICloudContentOutput {
  errorMessage?: string;
  contentPath: string;
  folderContent: CloudStorageContent[];
  fileContent?: IFolder;

  isFetching: boolean;
  currentService?: string;
  getContent?: () => void;
  putContent?: (
    files: FileList,
    type?: string,
    cancelTokenCb?: (id: string, c: Canceler) => void
  ) => void;
  deleteContent?: (fileName: string, filePath?: string) => void;
  setContentPath: (value: string) => void;
  cancelRequest?: () => void;
  downloadFile?: IDownloadFile;
  getFile?: (
    path: string,
    preview?: boolean,
    type?: string,
    cancelTokenCb?: (id: string, c: Canceler) => void
  ) => void;
  setFileContent?: (value: IFolder | undefined) => void;
  createServiceUrl?: (requestedPath: string) => {
    serviceUrl: string;
    fullClearPath: string;
  };
  setCurrentService?: (service: string | undefined) => void;
}

export interface ICloudContent {
  fileContent?: { file: string | ArrayBuffer };
  filePath: string;
  mimeType: string;
}

const CLOUD_STORAGE_URL = '/portal/v0/cloud-storage/';

export const useApiCloudStorageContent = (
  missionId: number | undefined,
  updateTransferStatus?: Dispatch<ITransferAction>
): ICloudContentOutput => {
  const [fileContent, setFileContent] = useState<
    CloudStorageContentWithFile | undefined
  >();

  const [content, setContent] = useState<CloudStorageContent[]>();
  const [currentStorage, setCurrentStorage] = useState<string | undefined>(
    AVAILABLE_CLOUD_STORAGE[0].name
  );
  const [programmeId, setProgrammeId] = useState<number | null>(null);
  const [contentPath, setContentPath] = useState('/');

  // Stored content path contains the path only after a successful callback
  const [storedContentPath, setStoredContentPath] = useState('/');

  // States
  const [isRequestCanceled, setRequestCanceled] = useState<boolean>(false);

  const getFolderContentQuery = useQuery(getFolderCont, {
    initialData: [],
    params: {
      resourceId: currentStorage === 'mission' ? missionId! : programmeId!,
      path: removeFirstSlash(contentPath),
      resource: currentStorage!,
    },
    skip: !currentStorage || !contentPath || !missionId,
  });

  const isSameContent = useCallback(() => {
    if (content?.length === getFolderContentQuery.data.length) {
      if (
        JSON.stringify(content[0]) ===
          JSON.stringify(getFolderContentQuery.data[0]) &&
        JSON.stringify(content[content.length / 2]) ===
          JSON.stringify(
            getFolderContentQuery.data[getFolderContentQuery.data.length / 2]
          )
      ) {
        return true;
      }
    }

    return false;
  }, [content, getFolderContentQuery.data]);

  const createServiceUrl = useCallback(
    (requestedPath: string) => {
      const clearPath = removeFirstSlash(requestedPath);
      const service = currentStorage ?? '';

      const id = service === 'mission' ? missionId : programmeId;
      const fullClearPath = `${service}/${id ?? ''}/${clearPath}`;

      return {
        serviceUrl: `${CLOUD_STORAGE_URL}${fullClearPath}`,
        fullClearPath: fullClearPath,
      };
    },
    [currentStorage, missionId, programmeId]
  );

  // Requires implementing onUploadProgress and onDownloadProgress equivalents to the fetch api handlers before replacing
  const getFileContent = useCallback(
    (cb: Function) =>
      async (path: string, preview?: boolean, type?: string) => {
        try {
          setFileContent(undefined);
          if (!currentStorage) {
            return;
          }

          const id = uuid();

          const { data: fileData } = await getFileCont({
            params: {
              path,
              resource: currentStorage,
              resourceId:
                currentStorage === 'mission' ? missionId! : programmeId!,
            },
            responseType: 'arraybuffer',
            onDownloadProgress: function (progressEvent) {
              let percentCompleted = undefined;
              if (progressEvent.lengthComputable) {
                percentCompleted = Math.round(
                  (progressEvent.loaded * 100) / progressEvent.total
                );
              }

              if (type)
                updateTransferStatus?.({
                  type: 'update-transfer-status',
                  id,
                  percentageComplete: percentCompleted ?? 0,
                });
            },
            cancelToken: new axios.CancelToken((cancelCallback) => {
              if (type) {
                updateTransferStatus?.({
                  type: 'update-cancel-callback',
                  id,
                  cancelCallback,
                });
              }
            }),
          });

          const { data: fileMetadata } = await getFileMetadata({
            params: {
              path,
              resource: currentStorage,
              resourceId:
                currentStorage === 'mission' ? missionId! : programmeId!,
            },
          });

          if (!fileData || !fileMetadata) {
            return;
          }

          const file: CloudStorageContentWithFile = {
            ...fileMetadata,
            file: new Uint8Array(fileData),
          };

          cb(file);
        } catch (e) {
          if (axios.isCancel(e)) {
            return;
          } else {
            showErrorMessage(REQUEST_ERROR);
          }
        }
      },
    [currentStorage, missionId, programmeId, updateTransferStatus]
  );

  const putContent = useCallback(
    async (fileList: FileList, type?: string) => {
      try {
        const base64ContentList = await Promise.all(
          map(toAsyncCloudContent, fileList)
        );

        await Promise.all(
          base64ContentList.map((item) => {
            const { filePath, fileContent: file } = item;
            const transferId = uuid();

            const formattedContentPath =
              contentPath === '/' ? '' : removeDoubleSlashes(contentPath);

            // If file is undefined, we're creating a folder instead
            if (!file) {
              return createOrUpdateFolder({
                params: {
                  path: removeFirstSlash(formattedContentPath + '/' + filePath),
                  resource: currentStorage ?? '',
                  resourceId:
                    currentStorage === 'mission' ? missionId! : programmeId!,
                },
              });
            }

            updateTransferStatus?.({
              type: 'start-transfer',
              id: transferId,
              name: item.filePath,
              transferType: type ?? '',
            });

            return createOrUpdateFile({
              params: {
                path: removeFirstSlash(formattedContentPath + '/' + filePath),
                resource: currentStorage ?? '',
                resourceId:
                  currentStorage === 'mission' ? missionId! : programmeId!,
              },
              body: file.file,
              onUploadProgress: (progressEvent) => {
                const percentCompleted = Math.round(
                  (progressEvent.loaded * 100) / progressEvent.total
                );
                updateTransferStatus?.({
                  type: 'update-transfer-status',
                  id: transferId,
                  percentageComplete: percentCompleted,
                });
              },
              cancelToken: new axios.CancelToken((cancelCallback) => {
                updateTransferStatus?.({
                  type: 'update-cancel-callback',
                  id: transferId,
                  cancelCallback,
                });
              }),
            });
          })
        );

        void getFolderContentQuery.refetch();
      } catch (e) {
        if (axios.isCancel(e)) {
          console.log('Request canceled', (e as { message?: string })?.message);
        } else {
          showErrorMessage(REQUEST_ERROR);
          console.error(e);
        }
      }
    },
    [
      contentPath,
      currentStorage,
      getFolderContentQuery,
      missionId,
      programmeId,
      updateTransferStatus,
    ]
  );

  const deleteContent = useCallback(
    async (fileName: string) => {
      await deleteFileOrFolder({
        params: {
          resourceId: currentStorage === 'mission' ? missionId! : programmeId!,
          path: removeFirstSlash(contentPath),
          resource: currentStorage!,
          fileName,
        },
      });

      void getFolderContentQuery.refetch();
    },
    [contentPath, currentStorage, getFolderContentQuery, missionId, programmeId]
  );

  const cancelRequest = useCallback(() => {
    getFolderContentQuery.cancel();
    setRequestCanceled(true);
    setContentPath(storedContentPath);
  }, [getFolderContentQuery, storedContentPath]);

  const getProgrammeIdByMission = useCallback(() => {
    // TODO: important! add axios request
    setProgrammeId(1);
  }, []);

  const adjustedSetCurrentStorage = useCallback(
    (service: string | undefined) => {
      setCurrentStorage(service);

      // TODO: I believe the 3 following lines could be removed?
      if (isNil(service)) {
        setCurrentStorage(undefined);
      }

      setContentPath('/');
    },
    []
  );

  const downloadFile = useCallback(
    (path: string, preview?: boolean, type?: string) =>
      getFileContent(downloadFileContent)(path, preview, type),
    [getFileContent]
  );

  const getFile = useCallback(
    (path: string, preview?: boolean, type?: string) =>
      getFileContent(setFileContent)(path, preview, type),
    [getFileContent]
  );

  useEffect(() => {
    if (missionId) {
      getProgrammeIdByMission();
    }
  }, [getProgrammeIdByMission, missionId]);

  useEffect(() => {
    setContent(getFolderContentQuery.data);

    if (isRequestCanceled || !isSameContent()) {
      setStoredContentPath(contentPath);
    } else {
      setContentPath(storedContentPath);
    }
  }, [
    getFolderContentQuery.data,
    isRequestCanceled,
    isSameContent,
    storedContentPath,
  ]);

  return {
    errorMessage: getFolderContentQuery.errors.join(';'),
    folderContent: getFolderContentQuery.data,
    fileContent,
    isFetching: getFolderContentQuery.loading,
    putContent,
    deleteContent,
    contentPath,
    setContentPath,
    cancelRequest,
    getContent: getFolderContentQuery.refetch,
    downloadFile,
    getFile,
    createServiceUrl,
    currentService: currentStorage,
    setCurrentService: adjustedSetCurrentStorage,
  };
};
