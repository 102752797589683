import { useState, useRef, useEffect, useCallback, useMemo } from 'react';
import { ResizableBox } from 'react-resizable';
import CommandEditor from 'components/CommandEditor';
import CommandPile from 'components/CommandPile';
import { CommandPileHeader } from 'components/CommandPile/CommandPileHeader';
import CommandHistory from 'components/CommandHistory';
import type {
  FireCommand,
  ICommandPileItem,
  UnarmedCommand,
  UpdateWorkspace,
} from 'services/api/ops/realtimeTerminalSession';
import type { ICommandHistory } from 'utils/hooks/useCommandHistory';
import { useOperate } from 'services/Operate';
import {
  setProcedureName,
  toggleCommandHistoryCollapse,
} from 'actions/operate/actions';
import { useWindowSize } from 'utils/hooks/useWindowSize';
import type { IMessageLog } from 'utils/hooks/operate/useMessageLog';
import Messages from './Messages';
import useActiveSession from 'services/api/portal/administration/hook/useActiveSession';
import { useParams } from 'react-router';
import { usePreviousState } from 'utils/hooks/usePreviousState';
import { useSendCommandHandlers } from 'utils/hooks/commands/useSendCommandHandlers';
import { useCommandPileHandlers } from 'utils/hooks/commands/useCommandPileHandlers';
import { useCommandHistoryHandlers } from 'utils/hooks/commands/useCommandHistoryHandlers';
import { useArmedCommandHandlers } from 'utils/hooks/commands/useArmedCommandHandlers';
import { useCommandEditorHandlers } from 'utils/hooks/commands/useCommandEditorHandlers';
import { useRtiPanelDimensions } from 'utils/hooks/operate/useRtiPanelDimensions';
import type { CommandDefinition } from 'api/telecommands/types';

export interface IProps {
  className?: string;
  availableCommands: CommandDefinition[];
  sendUnarmedCommand: UnarmedCommand;
  updateWorkspace: UpdateWorkspace;
  fireCommand: FireCommand;
  commandHistory: ICommandHistory[];
  addCommandToHistory: (command: ICommandHistory) => void;
  showParametersMenu: (show: boolean) => void;
  handleGetHistoryCommandList: (date: number) => void;
  noHistoryLeft: boolean;
  messageLog: IMessageLog[];
}

const Command = ({
  commandHistory,
  updateWorkspace,
  availableCommands,
  handleGetHistoryCommandList,
  noHistoryLeft,
  messageLog,
}: IProps) => {
  const {
    dispatch,
    state: {
      commandPileWorkingSpace,
      selectedCommand,
      armedCommand,
      isSendCommandDisabled,
      selectedPileItem,
      selectedMultiplePileItems,
      currentHistoryResponse,
      frozenPile,
      procedureName,
      isCommandPreview,
      isCommandHistoryCollapsed,
    },
  } = useOperate();

  const windowSize = useWindowSize();

  const { panelDimensions, setEventsPanelHeight, setCommandHistoryHeight } =
    useRtiPanelDimensions();
  const { mission } = useParams<{ mission: string }>();
  const {
    startUserSession,
    isSessionOccupied,
    userIsActiveInCurrentMission,
    fetchActiveSessions,
  } = useActiveSession(mission);

  const {
    handleSendSelectedCommand,
    handleSendPiledCommand,
    handleSendArmedCommand: handleFireCommandClick,
  } = useSendCommandHandlers();

  const {
    handleArmCommand,
    handleEditablePileItemSelect,
    handlePileCommandRemove,
  } = useCommandPileHandlers();

  const {
    handleCopyCommandToEditor,
    handleToggleOpenHistoryCommand: handleHistoryCommandClick,
  } = useCommandHistoryHandlers();

  const { handleArmedCommandPreview, handleUnarmCommand } =
    useArmedCommandHandlers();

  const { handleSelectCommandEditorItem, handleSendToPileCommand } =
    useCommandEditorHandlers();

  /**
   * Previous state for the command pile is used in order to keep the behaviour of scrolling to the bottom
   * when adding a new command to the pile while fixing the issue of it scrolling to the bottom when deleting
   * a command from the top of the pile.
   * */
  const previousCmdPile = usePreviousState<ICommandPileItem[] | undefined>(
    commandPileWorkingSpace
  );

  const [isGridView, setIsGridView] = useState<boolean>(false);

  const commandHistoryContainerRef = useRef<HTMLDivElement>(null);

  const setCollapsed = (collapsed: boolean) => {
    dispatch(toggleCommandHistoryCollapse(collapsed));
    return;
  };

  const scrollCommandHistoryToBottom = useCallback(() => {
    if (!commandHistoryContainerRef.current) return;
    commandHistoryContainerRef.current.scrollTop =
      commandHistoryContainerRef.current.scrollHeight;
  }, []);

  const renderCommandHistory = (
    <CommandHistory
      availableCommands={availableCommands}
      isTablet={windowSize.isTablet}
      commandHistory={commandHistory}
      handleCommandCopyToEditorClick={handleCopyCommandToEditor}
      handleHistoryCommandClick={handleHistoryCommandClick}
      currentHistoryItem={currentHistoryResponse}
      handleGetHistoryCommandList={handleGetHistoryCommandList}
      noHistoryLeft={noHistoryLeft}
      collapsed={Boolean(isCommandHistoryCollapsed)}
      setCollapsed={setCollapsed}
      commandHistoryContainerRef={commandHistoryContainerRef}
    />
  );

  const commandPileContainerRef = useRef<HTMLDivElement>(null);

  const commandPileWorkingSpaceLength = useMemo(
    () => (commandPileWorkingSpace ? commandPileWorkingSpace.length : 0),
    [commandPileWorkingSpace]
  );

  useEffect(() => {
    if (
      commandPileContainerRef.current &&
      previousCmdPile &&
      // Only scroll to the bottom if a new cmd has been added.
      commandPileWorkingSpaceLength > previousCmdPile?.length
    ) {
      commandPileContainerRef.current.scrollTop =
        commandPileContainerRef.current.scrollHeight;
    }
  }, [commandPileWorkingSpaceLength, previousCmdPile]);

  return (
    <div className="w-full flex flex-col">
      {messageLog.length > 0 ? (
        <div className="flex">
          <ResizableBox
            height={panelDimensions.eventsPanel}
            width={Infinity}
            minConstraints={[Infinity, windowSize.height / 30]}
            maxConstraints={[Infinity, windowSize.height / 4]}
            resizeHandles={['s']}
            className="min-h-full min-w-full pb-2"
            onResizeStop={(_, data) => {
              setEventsPanelHeight(data.size.height);
            }}
          >
            <Messages messages={messageLog} />
          </ResizableBox>
        </div>
      ) : null}

      <div className="flex">
        {isCommandHistoryCollapsed ? (
          renderCommandHistory
        ) : (
          <ResizableBox
            height={panelDimensions.commandHistoryPanel}
            width={Infinity}
            minConstraints={[Infinity, windowSize.height / 5]}
            maxConstraints={[Infinity, windowSize.height / 2]}
            resizeHandles={['s']}
            className="min-w-full pb-2"
            onResizeStop={(_, data) => {
              setCommandHistoryHeight(data.size.height);
            }}
          >
            {renderCommandHistory}
          </ResizableBox>
        )}
      </div>

      <CommandPileHeader
        isFrozenPile={Boolean(frozenPile)}
        isTablet={windowSize.isTablet}
        availableCommands={availableCommands}
        commandPileWorkingSpace={commandPileWorkingSpace}
        armedCommand={armedCommand}
        handleFireCommandClick={handleFireCommandClick}
        handleUnarmCommandClick={handleUnarmCommand}
        selectedPileItem={selectedPileItem}
        handleArmedCommandPreviewClick={handleArmedCommandPreview}
        updateWorkspace={updateWorkspace}
        procedureName={procedureName}
        setProcedureName={(name) => dispatch(setProcedureName(name))}
        isGridView={isGridView}
        setIsGridView={setIsGridView}
      />

      <div className="flex h-full overflow-auto" ref={commandPileContainerRef}>
        <ResizableBox
          height={Infinity}
          width={Infinity}
          resizeHandles={[]}
          className="min-w-full"
        >
          <CommandPile
            isFrozenPile={Boolean(frozenPile)}
            availableCommands={availableCommands}
            commandPileWorkingSpace={commandPileWorkingSpace}
            armedCommand={armedCommand}
            handleArmCommandClick={handleArmCommand}
            handlePileCommandRemoveClick={handlePileCommandRemove}
            handleArmCommandEditClick={handleEditablePileItemSelect}
            selectedMultiplePileItems={selectedMultiplePileItems}
            selectedPileItem={selectedPileItem}
            handleSendUnarmedCommand={handleSendPiledCommand}
            updateWorkspace={updateWorkspace}
            procedureName={procedureName}
            isGridView={isGridView}
            scrollCommandHistoryToBottom={scrollCommandHistoryToBottom}
            isSessionOccupied={isSessionOccupied}
            handleFireCommandClick={handleFireCommandClick}
            handleArmedCommandPreviewClick={handleArmedCommandPreview}
            handleUnarmCommandClick={handleUnarmCommand}
            isCommandPreview={isCommandPreview}
            userIsActiveInCurrentMission={userIsActiveInCurrentMission}
            startUserSession={startUserSession}
            fetchActiveSessions={fetchActiveSessions}
          />
        </ResizableBox>
      </div>

      <CommandEditor
        isTablet={windowSize.isTablet}
        availableCommands={availableCommands}
        handleSendUnarmedCommand={handleSendSelectedCommand}
        handleSendToPileCommand={handleSendToPileCommand}
        selectedCommand={selectedCommand}
        isSendCommandDisabled={Boolean(isSendCommandDisabled)}
        handleSelectCommandEditorItem={handleSelectCommandEditorItem}
        isSessionOccupied={isSessionOccupied}
        userIsActiveInCurrentMission={userIsActiveInCurrentMission!}
        startUserSession={startUserSession}
        fetchActiveSessions={fetchActiveSessions}
      />
    </div>
  );
};

export default Command;
