import type { SingleBandSTACLayer } from 'datacosmos/entities/singleBandLayer';
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
} from 'react';
import { useSingleImagePixels } from 'datacosmos/utils/hooks/pixelModes/useSingleImagePixels';
import { useClickedStacItem } from 'datacosmos/utils/hooks/useClickedStacItem';
import { useLinePixels } from 'datacosmos/utils/hooks/pixelModes/useLinePixels';
import type { BBox } from 'geojson';
import { useSinglePixelVsWavelength } from 'datacosmos/utils/hooks/pixelModes/useSinglePixelVsWavelength';
import { useSpectralSignatures } from 'datacosmos/utils/hooks/pixelModes/useSpectralSignatures';
import { useDrawStacItem } from 'datacosmos/utils/hooks/useDrawStacItem';

export type ClickedItemData = {
  id: string | undefined;
  collection: string | undefined;
  url: string | undefined;
  bbox: BBox | undefined;
  geometry: GeoJSON.Polygon | undefined;
};

export type PixelModeContextType = ReturnType<typeof usePixelModeProvider>;
export type PixelMode =
  | 'SingleImagePixel'
  | 'LinePixelData'
  | 'vsWavelength'
  | 'spectralSignatures';

export const PixelModeContext = createContext<PixelModeContextType>(
  null as unknown as PixelModeContextType
);

export const usePixelMode = () => useContext(PixelModeContext);

export const usePixelModeProvider = () => {
  const {
    aoi,
    drawnStacLayer,
    layersContainStacItems: isDrawEnabled,
    resetDrawnLayerAndAOI,
  } = useDrawStacItem();

  const {
    getClickedPointPixelData,
    toggleSingleImagePixelMode,
    isToggled: isSingleImagePixelModeToggled,
  } = useSingleImagePixels();

  const {
    toggleLinePixelValuesMode,
    isToggled: isLinePixelValuesModeToggled,
    getSelectedLinePixelData,
    linePixelsChartData,
    isFetching: isFetchingLinePixels,
  } = useLinePixels();

  const {
    getPixelValuesVsWavelength,
    isFetching: isFetchingVsWavelength,
    isToggled: isVsWavelengthToggled,
    singlePixelVsWavelengthChartData,
    toggleSinglePixelVsWavelength,
  } = useSinglePixelVsWavelength();

  const {
    getPixelValuesSpectralSignatures,
    isFetching: isFetchingSpectralSignatures,
    isToggled: isSpectralSignaturesToggled,
    pixelSpectralSignaturesChartData,
    togglePixelSpectralSignatures,
  } = useSpectralSignatures();

  const {
    clickedPoint,
    clickedStacLayer,
    layersContainStacItems: isEnabled,
    resetClickedLayerAndPoint,
  } = useClickedStacItem({ isDisabled: isSpectralSignaturesToggled });

  const isAnyOfTheModesToggled = useMemo(
    () =>
      isSingleImagePixelModeToggled ||
      isLinePixelValuesModeToggled ||
      isVsWavelengthToggled ||
      isSpectralSignaturesToggled,
    [
      isLinePixelValuesModeToggled,
      isSingleImagePixelModeToggled,
      isVsWavelengthToggled,
      isSpectralSignaturesToggled,
    ]
  );

  const getClickedLayerItemData = useCallback(
    (clickedLayer: SingleBandSTACLayer): ClickedItemData => {
      return {
        id: clickedLayer.item.id,
        collection: clickedLayer.item.collection,
        url: clickedLayer.item.assets[clickedLayer.assetKey].href,
        bbox: clickedLayer.item.bbox,
        geometry: clickedLayer.item.geometry as GeoJSON.Polygon,
      };
    },
    []
  );

  const togglePixelMode = useCallback(
    (mode: PixelMode) => {
      switch (mode) {
        case 'SingleImagePixel': {
          toggleSingleImagePixelMode();
          toggleLinePixelValuesMode(false);
          toggleSinglePixelVsWavelength(false);
          togglePixelSpectralSignatures(false);
          break;
        }
        case 'LinePixelData': {
          toggleLinePixelValuesMode();
          toggleSingleImagePixelMode(false);
          toggleSinglePixelVsWavelength(false);
          togglePixelSpectralSignatures(false);
          break;
        }
        case 'vsWavelength': {
          toggleSinglePixelVsWavelength();
          toggleLinePixelValuesMode(false);
          toggleSingleImagePixelMode(false);
          togglePixelSpectralSignatures(false);
          break;
        }
        case 'spectralSignatures': {
          togglePixelSpectralSignatures();
          toggleSinglePixelVsWavelength(false);
          toggleLinePixelValuesMode(false);
          toggleSingleImagePixelMode(false);
          break;
        }
      }
    },
    [
      toggleLinePixelValuesMode,
      toggleSingleImagePixelMode,
      toggleSinglePixelVsWavelength,
      togglePixelSpectralSignatures,
    ]
  );

  const toggleOffAllModes = useCallback(() => {
    toggleSingleImagePixelMode(false);
    toggleLinePixelValuesMode(false);
    toggleSinglePixelVsWavelength(false);
    togglePixelSpectralSignatures(false);
  }, [
    toggleLinePixelValuesMode,
    toggleSingleImagePixelMode,
    toggleSinglePixelVsWavelength,
    togglePixelSpectralSignatures,
  ]);

  const handlePixelModes = useCallback(
    (layerItemData: ClickedItemData) => {
      const { url, geometry, collection, id } = layerItemData;
      if (isSingleImagePixelModeToggled) {
        void getClickedPointPixelData(
          clickedPoint!.lat,
          clickedPoint!.lng,
          url
        );
        return;
      }

      if (isLinePixelValuesModeToggled) {
        void getSelectedLinePixelData(clickedPoint!, geometry, url);
        return;
      }

      if (isVsWavelengthToggled) {
        void getPixelValuesVsWavelength(clickedPoint!, collection, id);
        return;
      }

      if (isSpectralSignaturesToggled && aoi) {
        void getPixelValuesSpectralSignatures(aoi, collection, id);
        return;
      }
    },
    [
      clickedPoint,
      aoi,
      getClickedPointPixelData,
      getPixelValuesVsWavelength,
      getSelectedLinePixelData,
      getPixelValuesSpectralSignatures,
      isLinePixelValuesModeToggled,
      isSingleImagePixelModeToggled,
      isVsWavelengthToggled,
      isSpectralSignaturesToggled,
    ]
  );

  useEffect(() => {
    if (!isEnabled) {
      toggleOffAllModes();
    }
  }, [isEnabled, toggleOffAllModes]);

  useEffect(() => {
    if (
      !clickedPoint ||
      !clickedStacLayer ||
      !isEnabled ||
      !isAnyOfTheModesToggled
    ) {
      resetClickedLayerAndPoint();
      return;
    }

    const data = getClickedLayerItemData(clickedStacLayer);
    handlePixelModes(data);
  }, [
    clickedPoint,
    clickedStacLayer,
    getClickedLayerItemData,
    handlePixelModes,
    isAnyOfTheModesToggled,
    isEnabled,
    resetClickedLayerAndPoint,
  ]);

  useEffect(() => {
    if (!aoi || !drawnStacLayer || !isDrawEnabled || !isAnyOfTheModesToggled) {
      resetDrawnLayerAndAOI();
      return;
    }

    if (isSpectralSignaturesToggled) {
      const data = getClickedLayerItemData(drawnStacLayer);
      handlePixelModes(data);
    }
  }, [
    aoi,
    drawnStacLayer,
    getClickedLayerItemData,
    handlePixelModes,
    isAnyOfTheModesToggled,
    isDrawEnabled,
    resetDrawnLayerAndAOI,
    isSpectralSignaturesToggled,
  ]);

  return {
    isEnabled,
    isDrawEnabled,
    isSingleImagePixelModeToggled,
    isLinePixelValuesModeToggled,
    isFetchingLinePixels,
    linePixelsChartData,
    togglePixelMode,
    isFetchingVsWavelength,
    isFetchingSpectralSignatures,
    singlePixelVsWavelengthChartData,
    isVsWavelengthToggled,
    isSpectralSignaturesToggled,
    pixelSpectralSignaturesChartData,
  };
};

export const PixelModeProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const data = usePixelModeProvider();

  return (
    <PixelModeContext.Provider value={data}>
      {children}
    </PixelModeContext.Provider>
  );
};
