import type { FunctionComponent, PropsWithChildren, ReactElement } from 'react';
import React from 'react';
import type { IMapWrapper } from '../../declarations/mapDeclarations/Map';
import { Button } from '@blueprintjs/core';
import type { IMenuHandler } from '../../services/mapService/contextMenu/map/services';
import { addContextMenu } from '../../services/mapService/contextMenu/map/services';
import { useDispatch } from 'react-redux';
import { getAddPoint } from '../../services/mapService/contextMenu/menuItems/getAddPoint';
import { getAddRegion } from '../../services/mapService/contextMenu/menuItems/getAddRegion';
import type {
  IPIState,
  IPointOfInterest,
} from '../../constants/pointsOfInterest/actionTypes';
import type {
  IRegionOfInterest,
  IRIState,
} from '../../constants/regionsOfInterest/actionTypes';
import EventObject from './EventObject';
import type { IFocus } from '../../constants/focus/actionTypes';
import { FocusTypes } from '../../constants/focus/actionTypes';

interface IEventsPanelProps {
  map: IMapWrapper;
  highlightedButton: string;
  pointsOfInterest: IPIState;
  regionOfInterest: IRIState;
  updatePI: Function;
  removePI: Function;
  updateRI: Function;
  removeRI: Function;
  setPopUp: Function;
  children?: ReactElement[];
  focus: IFocus;
  setFocus: (focus: IFocus) => void;
  modesOnly?: boolean;
}

const EventsPanel: FunctionComponent<IEventsPanelProps> = (
  props: PropsWithChildren<IEventsPanelProps>
) => {
  const map: IMapWrapper = props.map;
  const highlightedButton: string = props.highlightedButton;
  const children: ReactElement[] = props.children || [];
  if (!map) {
    return null;
  }

  const addPoint: IMenuHandler[] = [
    {
      fn: getAddPoint,
      arg: [map],
      dispatch: true,
    },
  ];
  const addRegion: IMenuHandler[] = [
    {
      fn: getAddRegion,
      arg: [map],
      dispatch: true,
    },
  ];
  const dispatch = useDispatch();
  const boundContextMenuPoint = addContextMenu.bind(null, map, addPoint, true);
  const boundContextMenuRegion = addContextMenu.bind(
    null,
    map,
    addRegion,
    true
  );

  let objects: (IPointOfInterest | IRegionOfInterest)[] = [
    ...props.regionOfInterest.list,
    ...props.pointsOfInterest.list,
  ];
  objects = objects.sort((a, b) => a.orderIndex - b.orderIndex);

  return (
    <div className="geometry-panel center-bottom">
      <div className="white geometry-header bottom-header-button-group">
        {props.modesOnly ? (
          <h3 className="text">Events</h3>
        ) : (
          <>
            <Button
              className={`button button_common
             ${highlightedButton === 'marker' && 'button_common_toggled'}`}
              onClick={(arg: any) => dispatch(boundContextMenuPoint(arg))}
              intent="none"
              icon="add"
              key="add point"
              text="Add Point"
            />
            <Button
              className={`button button_common
             ${highlightedButton === 'polygon' && 'button_common_toggled'}`}
              onClick={(arg: any) => dispatch(boundContextMenuRegion(arg))}
              intent="none"
              icon="add"
              key="add region"
              text="Add Region"
            />
          </>
        )}
        {children.map((c, index) => (
          <div className="children" key={index}>
            {c}
          </div>
        ))}
      </div>
      <div className="white geometry-body">
        {objects.map((o, index, array) => {
          let removeEvent = null;
          let updateEvent = null;
          switch (o.type) {
            case FocusTypes.PI_SUB_ID: {
              removeEvent = props.removePI;
              updateEvent = props.updatePI;
              break;
            }
            case FocusTypes.RI_SUB_ID: {
              removeEvent = props.removeRI;
              updateEvent = props.updateRI;
              break;
            }
          }

          return (
            <EventObject
              map={map}
              focused={o.id === props.focus.id}
              setFocus={props.setFocus}
              key={o.id}
              eventObject={o}
              events={array}
              removeEvent={removeEvent}
              updateEvent={updateEvent}
              setPopUp={props.setPopUp}
              modesOnly={props.modesOnly}
            />
          );
        })}
      </div>
    </div>
  );
};

export default EventsPanel;
