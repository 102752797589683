import type { ReactElement } from 'react';
import React from 'react';
import type { WithTranslation } from 'react-i18next';
import { withTranslation } from 'react-i18next';
import type { ISelected } from '../charts/Chart';
import Chart from '../charts/Chart';
import type { Layout } from 'react-grid-layout';
import ReactGridLayout from 'react-grid-layout';
import type { IInitData, IStepData } from '../simulation/Simulation';
import { throttle } from '../../utils/common/throttle';
import { Checkbox } from '@blueprintjs/core';
import { throttleDelay } from '../../constants/gridLayout/constants';
import { MAP_ID_SIMULATE } from '../../constants/map/constants';
import DataZoom from '../charts/DataZoom';
import type { ISTState } from '../../constants/satelliteOrbits/actionTypes';
import Loading from '../common/Loading';

export interface IDataZoom {
  start: number;
  end: number;
}

export interface IInitChartData {
  selectedX?: ISelected;
  selectedY?: ISelected;
  chartOption?: {
    type: string;
    smooth: boolean;
    idForSelect: string;
  };
  synchronize?: boolean;
  selected?: { [key: string]: boolean }[];
  index?: string;
  dataZoom?: {
    start: number;
    end: number;
  };
}

interface IGridLayoutProps extends WithTranslation {
  initData: IInitData;
  historySteps: IStepData[];
  dataZoom: IDataZoom;
  onChangeDataZoom: Function;
  satellites: ISTState;
  updateSO: Function;
}

interface IGridLayoutState {
  resize: boolean;
  syncAll: { [key: string]: boolean };
  selectAll: { [key: string]: boolean };
  layout: Layout[];

  historySteps: IStepData[];
  freeze: boolean;
  initChartData: IInitChartData;
  showMap: boolean;
  latIndex: number;
  lonIndex: number;
}

let chartIndex = 1;

const PLOT_WIDTH: { [key: string]: number } = {
  full: 2,
  half: 1,
};

//TODO remove or refactor after beta release
const DEFAULT_CHARTS: {
  [key: string]: IInitChartData[];
} = {
  '/msd/mission-geometry': [],
  '/msd/ground-segment': [],
};

const getDefaultChartData = (index: string) => {
  const pathname = window.location.pathname;
  const defaultChartData = DEFAULT_CHARTS[pathname].find(
    (lData) => lData.index === index
  );
  return defaultChartData ? defaultChartData : null;
};

// const getDefaultLayoutData = (): Layout[] => {
//   const pathname = window.location.pathname;
//   return DEFAULT_CHARTS[pathname].map(data => data.layout);
// };

const addDefaultChartData = (
  chartConfig: { xAxis: string; yAxis: string; width: string },
  initData: IInitData,
  index: number
) => {
  const pathname = window.location.pathname;
  const { parameterNames, parameterTypes } = initData.data;
  const xIndex = parameterNames.findIndex(
    (pName) => pName === chartConfig.xAxis
  );
  const selectedX: ISelected = {
    index: xIndex,
    type: xIndex >= 0 ? parameterTypes[xIndex] : 'null',
  };

  const yIndex = parameterNames.findIndex(
    (pName) => pName === chartConfig.yAxis
  );
  const selectedY: ISelected = {
    index: yIndex,
    type: yIndex >= 0 ? parameterTypes[yIndex] : 'null',
  };
  const chartData: IInitChartData = {
    index: index.toString(),
    selectedX,
    selectedY,
  };
  DEFAULT_CHARTS[pathname].push(chartData);
};

class GridLayout extends React.Component<IGridLayoutProps, IGridLayoutState> {
  public state: IGridLayoutState = {
    resize: false,
    syncAll: {},
    selectAll: {},
    layout: [],
    historySteps: [],
    freeze: false,
    initChartData: null,
    showMap: true,
    latIndex: 0,
    lonIndex: 0,
  };

  public componentDidMount(): void {
    window.addEventListener('resize', () => {
      this.forceUpdate();
      this.onResizeStop();
    });
  }

  public componentDidUpdate(
    prevProps: Readonly<IGridLayoutProps>,
    prevState: Readonly<IGridLayoutState>
  ): void {
    if (this.props.initData !== prevProps.initData) {
      const pathname = window.location.pathname;
      DEFAULT_CHARTS[pathname] = [];

      const { defaultPlots = [] } = this.props.initData.data;
      const newLayout: {
        i: string;
        x: number;
        y: number;
        w: number;
        h: number;
      }[] = [];
      const grid = {
        x: 0,
        y: 0,
      };
      defaultPlots.forEach((p) => {
        addDefaultChartData(p, this.props.initData, chartIndex);
        const layout = {
          i: chartIndex.toString(),
          x: grid.x,
          y: grid.y,
          w: PLOT_WIDTH[p.width],
          h: 2,
        };
        newLayout.push(layout);
        grid.x = layout.w;
        grid.y += layout.h;
        if (grid.x >= 2) {
          grid.x = 0;
          grid.y++;
        }
        chartIndex++;
      });
      this.setState({ layout: [...this.state.layout, ...newLayout] });
    }
    if (this.props.historySteps !== prevProps.historySteps) {
      if (!this.state.freeze && this.props.initData) {
        if (
          this.props.historySteps[this.props.historySteps.length - 1].data
            .date === this.props.initData.data.endDate
        ) {
          this.getHistorySteps();
        } else {
          this.throttledGetHistorySteps();
        }
      }
    }
    if (this.state.freeze !== prevState.freeze) {
      this.getHistorySteps();
    }
  }

  private Ref = React.createRef();

  protected onAddItem = (): void => {
    const { layout } = this.state;
    const newLayout = { i: chartIndex.toString(), x: 0, y: 0, w: 2, h: 2 };
    this.setState({ layout: [newLayout, ...layout] });
    chartIndex++;
  };
  protected onCopy = (chartState: IInitChartData): void => {
    this.setState(
      {
        initChartData: {
          ...chartState,
          index: chartIndex.toString(),
        },
      },
      (): void => this.onAddItem()
    );
  };
  protected onResizeStop = (): void => {
    this.setState({ resize: true });
  };
  protected onLayoutChange = (layout: Layout[]): void => {
    this.setState({ layout });
  };
  protected onChartResize = (): void => {
    this.setState({ resize: false });
  };
  protected onRemoveChart = (chartId: string): void => {
    this.setState({
      layout: this.state.layout.filter(
        (l): boolean => `chart${l.i}` !== chartId
      ),
    });
  };

  protected getHistorySteps = (): void => {
    if (!this.props.initData) {
      console.warn('Init data not found!');
      return undefined;
    }

    this.setState({ historySteps: this.props.historySteps });
  };
  protected onChangeFreeze = (): void => {
    this.setState({ freeze: !this.state.freeze });
  };
  protected onSyncAll = (): void => {
    const { layout } = this.state;
    const syncAll: { [x: string]: boolean } = {};
    layout.forEach((l): void => {
      syncAll[l.i] = true;
    });
    this.setState({ syncAll });
  };
  protected onSyncAllStop = async (id: string): Promise<void> => {
    const { syncAll } = this.state;
    await this.setState({ syncAll: { ...syncAll, [id]: false } });
  };
  protected onSelectAll = (): void => {
    const { layout } = this.state;
    const selectAll: { [x: string]: boolean } = {};
    layout.forEach((l): void => {
      selectAll[l.i] = true;
    });
    selectAll[MAP_ID_SIMULATE] = true;
    this.setState({ selectAll });
    this.onChangeSatelliteShowAll();
    this.forceUpdate();
  };
  protected onSelectAllStop = async (id: string): Promise<void> => {
    const { selectAll } = this.state;
    await this.setState({ selectAll: { ...selectAll, [id]: false } });
  };
  protected onChangeShowMap = (): void => {
    this.setState({ showMap: !this.state.showMap });
  };

  private onChangeSatelliteShow = (arg: {
    checked: boolean;
    id: string;
  }): void => {
    const { checked, id } = arg;
    const { satellites, updateSO } = this.props;
    satellites.list.forEach((s) => {
      if (s.id === id) {
        updateSO({ ...s, isShow: checked });
      }
    });
  };
  private onChangeSatelliteShowAll = () => {
    const { satellites, updateSO } = this.props;
    satellites.list.forEach((s) => {
      updateSO({ ...s, isShow: true });
    });
  };

  protected throttledGetHistorySteps = throttle.call(
    this,
    this.getHistorySteps,
    throttleDelay
  );

  public render(): ReactElement {
    const { initData, dataZoom, onChangeDataZoom, satellites } = this.props;
    const {
      layout,
      resize,
      historySteps,
      freeze,
      initChartData,
      syncAll,
      selectAll,
      showMap,
    } = this.state;
    const eventMap = (
      <>
        {initData && (
          <DataZoom
            onChangeSatellitesShow={this.onChangeSatelliteShow}
            satellites={satellites}
            onChangeDataZoom={onChangeDataZoom}
            initData={initData}
            divId={'zoomChart'}
            dataZoom={dataZoom}
          />
        )}
      </>
    );

    // @ts-expect-error
    const refWidth = this.Ref.current ? this.Ref.current.clientWidth : 0;
    const gridLayout = layout.map((l): ReactElement => {
      const defaultChatData = getDefaultChartData(l.i);
      const initChartDataCustom =
        initChartData && l.i === initChartData.index
          ? initChartData
          : defaultChatData;
      if (!historySteps || historySteps.length === 0) {
        return (
          <div key={l.i}>
            <Loading isLoading={true} />
          </div>
        );
      }
      return (
        <div key={l.i}>
          <Chart
            divId={`chart${l.i}`}
            id={l.i}
            initData={initData}
            historySteps={historySteps}
            dataZoom={dataZoom}
            onChangeDataZoom={onChangeDataZoom}
            onResize={resize ? this.onChartResize : () => {}}
            onSyncAllStop={
              syncAll
                ? syncAll[l.i]
                  ? this.onSyncAllStop
                  : () => {}
                : () => {}
            }
            onSelectAllStop={
              selectAll
                ? selectAll[l.i]
                  ? this.onSelectAllStop
                  : undefined
                : undefined
            }
            onCopy={this.onCopy}
            onRemove={this.onRemoveChart}
            initChartData={initChartDataCustom}
            numberOfLayout={layout.length}
          />
        </div>
      );
    });
    return (
      // @ts-expect-error
      <div ref={this.Ref} className="grid no-print">
        <div className="grid-header">
          <nav className="bp4-navbar bp4-dark">
            <div className="bp4-navbar-group bp4-align-left">
              <button
                className={`bp4-button bp4-minimal bp4-icon-map ${
                  showMap ? 'bp4-intent-primary' : ''
                }`}
                onClick={this.onChangeShowMap}
              />
              <span className="bp4-navbar-divider" />
              <button
                className="bp4-button bp4-minimal bp4-icon-series-add"
                onClick={this.onAddItem}
              >
                Add Chart
              </button>
              <button
                className="bp4-button bp4-minimal bp4-icon-time"
                onClick={this.onSyncAll}
              >
                Sync all
              </button>
              <button
                className="bp4-button bp4-minimal bp4-icon-series-filtered"
                onClick={this.onSelectAll}
              >
                Select all
              </button>
              <span className="bp4-navbar-divider" />
              <Checkbox
                checked={freeze}
                label={'Freeze'}
                onChange={this.onChangeFreeze}
              />
            </div>
          </nav>
        </div>
        {eventMap}
        <div className="grid-body">
          <ReactGridLayout
            layout={layout}
            cols={2}
            rowHeight={200}
            width={refWidth ? refWidth : 1000}
            isResizable={true}
            onResizeStop={this.onResizeStop}
            onLayoutChange={this.onLayoutChange}
            draggableHandle=".chart .chart-header"
          >
            {gridLayout}
          </ReactGridLayout>
        </div>
      </div>
    );
  }
}

export default withTranslation()(GridLayout);
