import { useEffect } from 'react';
import DefaultLayout from '../../layouts/DefaultLayout';
import { Position, Intent, Button } from '@blueprintjs/core';
import { Tooltip } from 'ui/Tooltip';
import PopUp from '../../components/popUp/PopUp';
import type { AppState } from '../../store';
import { updateResultsThunk } from '../../actions/results/thunks';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { selectSatelliteModuleNames } from '../../selectors/satellite';
import PowerModule from '../../components/Platform/PowerModule';
import BatteryModule from '../../components/Platform/BatteryModule';
import Communications from '../../components/Platform/Communications';
import AOCS from '../../components/Platform/AOCS';
import PlatformCharacteristics from '../../components/Platform/PlatformCharacteristics';
import { updateSatellitePlatform } from '../../actions/satellite/action';
import { loadSatellitePlatform } from '../../actions/ui/platformConfigurator/action';
import {
  selectedPlatformModuleNames,
  selectedPlatformModules,
  selectPlatformCharacteristics,
} from '../../selectors/platformConfigurator';
import type {
  IPlatformModules,
  ISatelliteModules,
} from '../../constants/satellite/types';
import Satellite3DView from '../../babylonjs/Satellite3DView';
import ObdhModule from '../../components/Platform/ObdhModule';
import type { IPlatformCharacteristics } from '../../constants/ui/platformConfigurator/types';
import {
  PLATFORM_SIZE,
  PLATFORM_SOLAR_PANEL_CONFIG,
  PLATFORM_SOLAR_PANEL_DEPLOYABLE,
  PLATFORM_SOLAR_PANEL_DIRECTION,
  PLATFORM_SOLAR_PANEL_MOUNT_PERCENTAGE,
} from '../../constants/ui/platformConfigurator/types';
import { msd } from '../../constants/mixpanelAnalytics';
import useOnLoadMetric from 'utils/hooks/analytics/useOnLoadMetric';
import { useAnalytics } from 'utils/hooks/analytics/useAnalytics';

const RESULTS_PATH = 'msd.platformPage';

interface IOwnProps {}

interface IStateProps {
  moduleData: any;
  currentPlatformModuleNames: IPlatformModules;
  initialPlatformModuleNames: ISatelliteModules;
  platformCharacteristics: IPlatformCharacteristics;
}

interface IDispatchProps {
  updateResultsThunk: typeof updateResultsThunk;
  updateSatellitePlatform: typeof updateSatellitePlatform;
  loadSatellitePlatform: typeof loadSatellitePlatform;
}

type IProps = IOwnProps & IStateProps & IDispatchProps;

const PlatformPage = ({
  updateResultsThunk,
  currentPlatformModuleNames,
  initialPlatformModuleNames,
  updateSatellitePlatform,
  loadSatellitePlatform,
  platformCharacteristics,
}: IProps) => {
  useEffect(() => {
    loadSatellitePlatform(initialPlatformModuleNames);
  }, []);

  const history = useHistory();

  const { sendInfo } = useAnalytics();

  useOnLoadMetric({
    type: msd.PLATFORM.VISIT,
    action: 'Visit',
    item: 'MSD platform page',
    module: 'MSD',
  });

  const canProceed =
    Boolean(currentPlatformModuleNames.eps.length) &&
    Boolean(currentPlatformModuleNames.comms.length) &&
    Boolean(currentPlatformModuleNames.power.length) &&
    Boolean(currentPlatformModuleNames.aocs.length) &&
    Boolean(currentPlatformModuleNames.obdh.length);

  const updateModuleBubble = () => {
    updateResultsThunk(
      RESULTS_PATH,
      {
        currentPlatformModuleNames,
        platformCharacteristics,
      },
      {
        currentPlatformModuleNames,
        platformCharacteristics,
      },
      undefined
    );
  };

  return (
    <>
      <DefaultLayout offLight>
        <div className="platform-page">
          <div className="content-container">
            <div className="heading-bar">
              <h1 className="mainTitle">Configure your platform</h1>
            </div>
            <PlatformCharacteristics />
            <Satellite3DView />
            <div className="subsystem-container">
              <h2 className="mainTitle">Sub-systems</h2>
              <div className="container">
                <PowerModule />
                <BatteryModule />
                <Communications />
                <ObdhModule />
                <AOCS />
              </div>
              <div className="completeButtonContainer">
                <Tooltip
                  content="Select a payload to continue"
                  position={Position.BOTTOM}
                  disabled={canProceed}
                >
                  <Button
                    intent={Intent.SUCCESS}
                    onClick={() => {
                      updateModuleBubble();
                      sendInfo({
                        type: msd.PLATFORM.COMPLETE,
                        action: 'Payload complete',
                        item: 'Payload page',
                        module: 'MSD',
                        additionalParams: {
                          EPS: currentPlatformModuleNames.eps[0],
                          Comms: currentPlatformModuleNames.comms[0],
                          Power: currentPlatformModuleNames.power[0],
                          AOCS: currentPlatformModuleNames.aocs[0],
                          OBDH: currentPlatformModuleNames.obdh[0],
                          'Body Form Factor':
                            platformCharacteristics[PLATFORM_SIZE],
                          'Body Mounted Solar Cells':
                            platformCharacteristics[
                              PLATFORM_SOLAR_PANEL_MOUNT_PERCENTAGE
                            ],
                          'Solar Panel Configuration':
                            platformCharacteristics[
                              PLATFORM_SOLAR_PANEL_CONFIG
                            ],
                          'Solar Panel Size':
                            platformCharacteristics[
                              PLATFORM_SOLAR_PANEL_DEPLOYABLE
                            ],
                          'Solar Panel Orientation':
                            platformCharacteristics[
                              PLATFORM_SOLAR_PANEL_DIRECTION
                            ],
                        },
                      });
                      updateSatellitePlatform(currentPlatformModuleNames);
                      history.push('/');
                    }}
                    disabled={!canProceed}
                    text="Continue"
                  />
                </Tooltip>
              </div>
            </div>
          </div>
        </div>
        <PopUp />
      </DefaultLayout>
    </>
  );
};

const mapStateToProps = (state: AppState): IStateProps => ({
  moduleData: selectedPlatformModules(state),
  currentPlatformModuleNames: selectedPlatformModuleNames(state),
  initialPlatformModuleNames: selectSatelliteModuleNames(state),
  platformCharacteristics: selectPlatformCharacteristics(state),
});

const mapDispatchToProps: IDispatchProps = {
  updateResultsThunk,
  updateSatellitePlatform,
  loadSatellitePlatform,
};

const mergeProps = (
  propsFromState: IStateProps,
  propsFromDispatch: IDispatchProps,
  ownProps: IOwnProps
) => {
  return {
    ...propsFromState,
    ...propsFromDispatch,
    ...ownProps,
  };
};

export default connect<IStateProps, IDispatchProps, IOwnProps>(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(PlatformPage);
