import type { Layer } from 'datacosmos/entities/layer';
import type { SingleBandSTACLayer } from 'datacosmos/entities/singleBandLayer';
import { useLocalisation } from 'utils/hooks/useLocalisation';
import { IconButton } from 'opencosmos-ui';

type Props = {
  disabled: boolean;
  supportedLayers: SingleBandSTACLayer[];
  replaceLayer: (layer: Layer, newIndex?: number) => void;
};

const ResetControls = (props: Props) => {
  const { translate } = useLocalisation();

  return (
    <IconButton
      icon="Cross"
      size={24}
      className={
        props.disabled ? 'cursor-not-allowed opacity-50' : 'cursor-pointer'
      }
      tooltipText={translate('datacosmos.layers.reset')}
      onPress={() => {
        props.supportedLayers[0].setSettingContrastOn();
        props.supportedLayers[0].setSettingBrightnessOn();
        props.supportedLayers[0].setSettingSaturationOn();
        props.replaceLayer(
          props.supportedLayers[0].cloneWithOptions({
            brightness: 1,
            saturation: 1,
            contrast: 1,
            opacity: 1,
            contrastBias: 0.5,
          })
        );
      }}
    />
  );
};

export default ResetControls;
