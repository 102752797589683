import React, { useState } from 'react';
import type { IPopoverProps } from '_atoms/Popover/Popover';
import Popover, { Direction } from '_atoms/Popover/Popover';
import SelectItem from '_atoms/SelectItem/SelectItem';
import type { InputProps } from '_molecules/Input/Input';
import Input from '_molecules/Input/Input';

interface ISuggestionInputProps extends InputProps {
  suggestionItems: string[];
  onSuggestionClick: (value: string) => void;
  popoverProps: Partial<IPopoverProps>;
}

const SuggestionInput = (props: ISuggestionInputProps) => {
  const textInputValueArray = (props.value as string).split(/[\s+-\\*]/);

  const [isSuggestionApplied, setIsSuggestionApplied] =
    useState<boolean>(false);
  return (
    <Popover
      target={props.popoverProps.target}
      className="color-item"
      content={
        <div>
          {props.suggestionItems
            .filter((b) =>
              b.startsWith(textInputValueArray[textInputValueArray.length - 1])
            )
            .map((b) => (
              <SelectItem
                text={b}
                key={b}
                onClick={() => {
                  setIsSuggestionApplied(true);
                  props.onSuggestionClick(
                    (props.value as string).replace(/(\w+)$/, b)
                  );
                }}
              />
            ))}
        </div>
      }
      direction={Direction.Bottom}
      isOpen={
        textInputValueArray[textInputValueArray.length - 1].match(
          /(\S*\d+\S*)|([a-zA-Z])/
        ) &&
        textInputValueArray[textInputValueArray.length - 1].length > 0 &&
        !isSuggestionApplied
      }
      fill
    >
      <Input
        {...props}
        onChange={(e) => {
          isSuggestionApplied && setIsSuggestionApplied(false);
          props.onChange(e);
        }}
      />
    </Popover>
  );
};

export default SuggestionInput;
