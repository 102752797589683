import { useRef } from 'react';
import { useDateField, useLocale } from 'react-aria';
import { useDateFieldState } from 'react-stately';
import { createCalendar } from '@internationalized/date';
import type { DateFieldStateOptions } from '@react-stately/datepicker';
import DateSegment from './DateSegment';
import classNames from 'classnames';

interface IDateFieldProps
  extends Omit<DateFieldStateOptions, 'createCalendar' | 'locale'> {
  className?: string;
  fill?: true;
}

const DateField = (props: IDateFieldProps) => {
  const { locale } = useLocale();

  const state = useDateFieldState({
    ...props,
    createCalendar,
    locale,
  });
  const ref = useRef<HTMLDivElement>(null);
  const { fieldProps, labelProps } = useDateField(props, state, ref);

  return (
    <div
      className={classNames(props.className, 'gap-4', {
        'w-full': props.fill,
      })}
    >
      <div className="flex items-centre gap-1">
        <label {...labelProps} className="text-sm self-center">
          {props.label}
        </label>
        <div
          {...fieldProps}
          ref={ref}
          className={classNames(
            'flex p-1 gap-1 text-sm border-2 border-item',
            'color-item',
            {
              'border-warning': state.validationState === 'invalid',
              'w-full': props.fill,
            }
          )}
          data-testid="date-field"
        >
          {state.segments.map((s, i) => (
            <DateSegment segment={s} key={i} state={state} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default DateField;
