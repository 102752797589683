import type {
  GroupSatelliteBallActionTypes,
  IGroup,
} from '../constants/groupSatelliteBalls/actionTypes';
import {
  GSB_ADD,
  GSB_REMOVE,
  GSB_UPDATE,
} from '../constants/groupSatelliteBalls/actionTypes';

type IGroupSatelliteBallState = IGroup[];
const initialState: IGroupSatelliteBallState = [];

export default function satelliteBallReducer(
  state: IGroupSatelliteBallState = initialState,
  action: GroupSatelliteBallActionTypes
): IGroupSatelliteBallState {
  switch (action.type) {
    case GSB_ADD: {
      return [...state, action.group];
    }
    case GSB_UPDATE: {
      return state.map((group): IGroup => {
        if (group.label === action.group.label) {
          return { ...action.group };
        }
        return group;
      });
    }
    case GSB_REMOVE: {
      return state.filter((group): boolean => group.label !== action.label);
    }
    default: {
      return state;
    }
  }
}
