import { useEffect, useState } from 'react';
import { Button, UL } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { useHistory } from 'react-router';
import type { Organisation } from 'api/administration/service';
import CreateButton from 'components/OrganisationConfiguration/CreateButton';
import OrganisationEditableText from 'components/OrganisationConfiguration/OrganisationEditableText';
import { preventDefaultPropagation } from 'utils/common/CommonUtils';
import { useAuth } from 'services/auth/AuthWrapper';
import s from 'components/OrganisationConfiguration/index.module.scss';

interface IProps {
  chosenOrg: number;
  setChosenOrg: (value: number) => void;
  organisations: Organisation[];
  saveOrganisation: (value: string) => void;
}

const OrganisationList = (props: IProps) => {
  const { checkPermissions } = useAuth();
  const history = useHistory();
  const [showNewOrganisation, setShowNewOrganisation] = useState(false);

  const [isAllowedToUpdateOrganisation, setIsAllowedToUpdateOrganisation] =
    useState<boolean[]>([]);
  const [isAllowedToCreateOrganisation, setIsAllowedToCreateOrganisation] =
    useState<boolean>(false);

  const handleEditClick = (organisationId: number) => {
    history.push(`/portal/organisation/${organisationId}`);
  };

  useEffect(() => {
    const checkCreatePermission = async () => {
      const hasPerm = await checkPermissions({
        type: 'global',
        actionScope: 'organisation:create',
      });
      setIsAllowedToCreateOrganisation(hasPerm);
    };

    const checkUpdatePermission = async () => {
      const hasPerm = await checkPermissions(
        props.organisations.map((org) => ({
          id: org.id,
          type: 'organisation',
          actionScope: 'organisation:update',
        }))
      );
      setIsAllowedToUpdateOrganisation(hasPerm);
    };

    void checkCreatePermission();
    void checkUpdatePermission();
  }, [checkPermissions, props.organisations]);

  return (
    <div className={s.organizationContainer}>
      <UL className={s.organizationListContainer}>
        <OrganisationEditableText
          text="New Organisation"
          showEditableText={showNewOrganisation}
          hideEditableText={() => setShowNewOrganisation(false)}
          handleSaveConfirm={props.saveOrganisation}
        />
        {props.organisations.map((item, i) => (
          <li
            key={item.id}
            className={
              props.chosenOrg === item.id ? s.highLightChosenItem : undefined
            }
          >
            <div
              className={s.organizationItem}
              role="button"
              tabIndex={0}
              onKeyDown={() => props.setChosenOrg(item.id)}
              onClick={() => props.setChosenOrg(item.id)}
            >
              {item.name}
              <Button
                minimal
                small
                disabled={!isAllowedToUpdateOrganisation[i]}
                icon={IconNames.WRENCH}
                onClick={preventDefaultPropagation(handleEditClick)(item.id)}
              />
            </div>
          </li>
        ))}
      </UL>
      <CreateButton
        disabled={!isAllowedToCreateOrganisation}
        text="Organisation"
        onClick={() => setShowNewOrganisation(true)}
      />
    </div>
  );
};

export default OrganisationList;
