import React from 'react';
import type { IUserWithPermissions } from 'services/api/portal/administration/api/types';
import s from './index.module.scss';
import { ENABLE_USER_IMAGES } from 'env';
import Icon from '_atoms/Icon/Icon';

interface IProps {
  user: IUserWithPermissions;
}

const UserHeader = ({ user }: IProps) => {
  return (
    <div className={s.userHeaderContainer}>
      <div className={s.userSectionWrapper}>
        {ENABLE_USER_IMAGES ? (
          <img src={user.picture} className={s.profilePicture} />
        ) : (
          <Icon icon="PERSON" size={64} className={s.profilePicture} />
        )}
        <div className={s.userInfoWrapper}>
          <h1 className={s.userInfoUsername}>{user.name}</h1>
          <h2 className={s.userInfoEmail}>{user.email}</h2>
          <h3 className={s.userInfoId}>{user.id}</h3>
        </div>
      </div>
    </div>
  );
};

export default UserHeader;
