import { ColumnLayout } from 'ui/ColumnLayout/ColumnLayout';
import { Column } from 'ui/ColumnLayout/Column';
import { Header } from 'ui/Header';
import { Navbar } from 'ui/Navbar';
import { Screen } from 'ui/Screen';
import { ItemTabContainer } from 'ui/ItemContainer';
import { DateInput } from 'ui/DateInput';
import { ActivitiesMap } from 'pages/ops/Scheduling/ScheduleActivityDetails/ActivitiesMap';
import { Text } from 'ui/Text';
import TaskingCard from '_organisms/TaskingCard/TaskingCard';
import {
  CompositeDetailsItem,
  DetailsItem,
} from 'pages/ops/Scheduling/ScheduleActivityDetails/DetailCards/DetailsItem';
import { RowLayout } from 'ui/RowLayout/RowLayout';
import { Row } from 'ui/RowLayout/Row';
import Button from '_molecules/Button/Button';
import { Icon } from 'ui/Icon';
import { toFormattedDate, toFormattedTime } from 'utils/common/dateUtils';
import { useTaskingOverviewData } from './useTaskingOverviewData';
import { NonIdealState } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import findSatelliteNameByMissionId from 'utils/findSatelliteNameByMissionId';
import { Loader } from 'ui/Loader';
import { SelectInput } from 'ui/SelectInput';
import UserInfo from '_organisms/UserInfo/UserInfo';
import Textarea from '_atoms/Textarea/Textarea';
import AddActivityModal from 'pages/tasking_overview/AddActivityModal';

const TaskingOverviewScreen = () => {
  const {
    classifiedRequests,
    selectedRequest,
    handleTaskingRequestClick,
    loading,
    setFilter,
    filter,
    handleMissionIdFilterClick,
    customer,
    isAddActivityModalOpen,
    setIsAddActivityModalOpen,
    refetchTaskingRequests,
  } = useTaskingOverviewData();

  return (
    <Screen>
      <Navbar />
      <ColumnLayout cols={2} colRatio={[2, 1]} horizontalGap={10}>
        <Column style={{ height: '94.5vh' }}>
          <ItemTabContainer
            fullWidth={true}
            className="dark:bg-header-dark bg-header"
          >
            <Header margin={{ left: { value: 0 }, right: { value: 'auto' } }}>
              Requests
            </Header>
            <Text>Mission: </Text>
            <SelectInput
              options={filter.availableMissionIds}
              setValue={(val) => handleMissionIdFilterClick(val)}
              value={
                filter.missionIds.length > 0
                  ? filter.missionIds.join(', ')
                  : 'Select'
              }
            />
            <DateInput
              setValue={setFilter.fromDate}
              value={filter.fromDate}
              title="From"
            />
            <DateInput
              setValue={setFilter.toDate}
              value={filter.toDate}
              title="To"
            />
          </ItemTabContainer>

          {loading ? (
            <Loader />
          ) : (
            <ColumnLayout
              cols={6}
              colRatio={[1, 1, 1, 1, 1, 1]}
              horizontalGap={3}
            >
              <Column
                key={1}
                data-testid="pending-column"
                style={{ overflowY: 'auto', position: 'relative' }}
              >
                <Text className="sticky top-0 bg-data-dark-surface">
                  Pending
                </Text>
                {classifiedRequests.pending.map((r) => (
                  <TaskingCard
                    onClick={() => handleTaskingRequestClick(r)}
                    taskingRequest={r}
                    key={r.id}
                    active={selectedRequest?.id === r.id}
                  />
                ))}
              </Column>

              <Column
                key={2}
                data-testid="confirmed-column"
                style={{ overflowY: 'auto', position: 'relative' }}
              >
                <Text className="sticky top-0 bg-data-dark-surface">
                  Confirmed
                </Text>
                {classifiedRequests.confirmed.map((r) => (
                  <TaskingCard
                    onClick={() => {
                      handleTaskingRequestClick(r);
                    }}
                    taskingRequest={r}
                    key={r.id}
                    active={selectedRequest?.id === r.id}
                  />
                ))}
              </Column>

              <Column
                key={3}
                data-testid="acquiring-column"
                style={{ overflowY: 'auto', position: 'relative' }}
              >
                <Text className="sticky top-0 bg-data-dark-surface">
                  Acquiring
                </Text>
                {classifiedRequests.acquiring.map((r) => (
                  <TaskingCard
                    onClick={() => handleTaskingRequestClick(r)}
                    taskingRequest={r}
                    key={r.id}
                    active={selectedRequest?.id === r.id}
                  />
                ))}
              </Column>

              <Column
                key={4}
                data-testid="succeeded-column"
                style={{ overflowY: 'auto', position: 'relative' }}
              >
                <Text className="sticky top-0 bg-data-dark-surface">
                  Succeeded
                </Text>
                {classifiedRequests.successful.map((r) => (
                  <TaskingCard
                    onClick={() => handleTaskingRequestClick(r)}
                    taskingRequest={r}
                    key={r.id}
                    active={selectedRequest?.id === r.id}
                  />
                ))}
              </Column>

              <Column
                key={5}
                data-testid="failed-column"
                style={{ overflowY: 'auto', position: 'relative' }}
              >
                <Text className="sticky top-0 bg-data-dark-surface">
                  Failed
                </Text>
                {classifiedRequests.failed.map((r) => (
                  <TaskingCard
                    onClick={() => handleTaskingRequestClick(r)}
                    taskingRequest={r}
                    key={r.id}
                    active={selectedRequest?.id === r.id}
                  />
                ))}
              </Column>

              <Column
                key={6}
                data-testid="canceled-column"
                style={{ overflowY: 'auto' }}
              >
                <Text className="sticky top-0 bg-data-dark-surface">
                  Canceled
                </Text>
                {classifiedRequests.canceled.map((r) => (
                  <TaskingCard
                    onClick={() => handleTaskingRequestClick(r)}
                    taskingRequest={r}
                    key={r.id}
                    active={selectedRequest?.id === r.id}
                  />
                ))}
              </Column>
            </ColumnLayout>
          )}
        </Column>

        <Column style={{ overflow: 'auto' }}>
          {!selectedRequest ? (
            <NonIdealState
              icon={IconNames.HELP}
              title="No request selected"
              description="Select a request to view it here"
              className="dark:text-item-dark-contrast text-item-contrast"
            />
          ) : (
            <>
              {!isAddActivityModalOpen && (
                <ActivitiesMap
                  activity={selectedRequest.activities}
                  request={selectedRequest}
                  aspectRatio="auto"
                />
              )}

              <RowLayout rows={2} rowRatio={[0.1, 1]} gap={5}>
                <Row>
                  <Column data-testid="request-details-column">
                    <Header size="h2">Request</Header>
                    <DetailsItem
                      aria-label="Request id"
                      title="Id:"
                      value={selectedRequest.id}
                    />
                    <DetailsItem
                      aria-label="Request type"
                      title="Type:"
                      value={selectedRequest.type}
                    />
                    <DetailsItem
                      aria-label="Number of activities"
                      title="Number of activities:"
                      value={String(selectedRequest.activities.length)}
                    />
                    <DetailsItem
                      aria-label="Request subject"
                      title="Subject:"
                      value="N/A"
                    />
                    <DetailsItem
                      aria-label="Request region name"
                      title="Region name:"
                      value={selectedRequest.region_name}
                    />
                    <CompositeDetailsItem
                      title="Customer: "
                      element={
                        <UserInfo
                          user={customer}
                          loading={loading}
                          fieldsToShow={['email']}
                        />
                      }
                    />
                    <CompositeDetailsItem
                      title="Notes: "
                      element={
                        <Textarea disabled value={selectedRequest.notes} />
                      }
                    />
                  </Column>
                </Row>

                <Row>
                  <Column data-testid="activities-column">
                    <ItemTabContainer>
                      <Header
                        size="h2"
                        margin={{
                          left: { value: 0 },
                          right: { value: 'auto' },
                        }}
                      >
                        Activities
                      </Header>
                      <Button
                        text="Add"
                        className="w-24"
                        onPress={() => setIsAddActivityModalOpen(true)}
                      />
                    </ItemTabContainer>

                    {selectedRequest.activities.map((a) => (
                      <ItemTabContainer
                        className="justify-between items-center dark:bg-item-dark bg-item"
                        key={a.id}
                      >
                        <Icon kind="Satellite" size={24} />
                        <Text>
                          {findSatelliteNameByMissionId(a.mission_id)}
                        </Text>
                        <Text>{toFormattedDate(a.start_date)}</Text>
                        <Text>{toFormattedTime(a.start_date)}</Text>
                        <Text>-</Text>
                        <Text>{toFormattedTime(a.end_date)}</Text>
                        <Text>{a.status}</Text>
                        <Text>{a?.priority?.toString()}</Text>
                      </ItemTabContainer>
                    ))}
                  </Column>
                </Row>
              </RowLayout>
            </>
          )}
        </Column>
      </ColumnLayout>

      <AddActivityModal
        isOpen={isAddActivityModalOpen}
        setIsOpen={setIsAddActivityModalOpen}
        request={selectedRequest}
        onSuccess={() => refetchTaskingRequests()}
      />
    </Screen>
  );
};

export default TaskingOverviewScreen;
