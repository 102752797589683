import type { ReactElement } from 'react';
import React from 'react';
import { getIndexes } from '../../services/summary/getIndexes';
import type { ITreeNodeCustom } from '../../declarations/blueprintjs';

interface IIndexPageProps {
  date: Date;
  nodes: ITreeNodeCustom[];
}

export const SummaryIndexPage = (props: IIndexPageProps): ReactElement => {
  const { nodes } = props;
  return (
    <div className="page summary-page-index page-hidden">
      <div className="chapter">
        <h1>Table of Contents</h1>
        {getIndexes(nodes)}
      </div>
    </div>
  );
};
