import bboxPolygon from '@turf/bbox-polygon';
import booleanAOIInPolygon from '@turf/boolean-within';
import { LayerSourceType } from 'datacosmos/entities/layer';
import { OutlineLayer } from 'datacosmos/entities/outlineLayer';
import { SingleBandSTACLayer } from 'datacosmos/entities/singleBandLayer';
import { useMapLayers } from 'datacosmos/stores/MapLayersProvider';
import { useMap } from 'datacosmos/stores/MapProvider';
import { useCallback, useEffect, useMemo, useState } from 'react';
import L from 'leaflet';

type DrawnStacItemOptions = {
  /**
   * Whether to add an outline layer around the clicked item
   */
  outlineClickedItem?: boolean;
  /**
   * A callback function that is called when the user clicks on an image
   * @param image The image that the user has clicked on the map
   */
  onImageClick?: (image: SingleBandSTACLayer) => void;
};

export const useDrawStacItem = (options?: DrawnStacItemOptions) => {
  const { layers, addLayer, removeLayersBySourceType } = useMapLayers();
  const { mapRef } = useMap();

  const [drawnStacLayer, setDrawnStacLayer] = useState<
    SingleBandSTACLayer | undefined
  >();

  const [aoi, setAOI] = useState<GeoJSON.Feature | undefined>();

  const stacItemLayers = useMemo<SingleBandSTACLayer[]>(
    () =>
      layers.filter(
        (l) => l instanceof SingleBandSTACLayer
      ) as SingleBandSTACLayer[],
    [layers]
  );

  const layersContainStacItems = useMemo(
    () => stacItemLayers.length > 0,
    [stacItemLayers]
  );

  const resetDrawnLayerAndAOI = useCallback(() => {
    setAOI(undefined);
    setDrawnStacLayer(undefined);

    if (options?.outlineClickedItem) {
      removeLayersBySourceType(LayerSourceType.ASSET_OUTLINE);
    }
  }, [options?.outlineClickedItem, removeLayersBySourceType]);

  const getImageLayersContainingDrawnAOI = useCallback(
    (areaofInterest: GeoJSON.Feature) => {
      return stacItemLayers.filter((l) => {
        const bboxPoly = bboxPolygon(l.item.bbox);
        return (
          booleanAOIInPolygon(areaofInterest, bboxPoly) && l.options.visible
        );
      });
    },
    [stacItemLayers]
  );

  useEffect(() => {
    if (!layersContainStacItems) {
      resetDrawnLayerAndAOI();
    }
  }, [layersContainStacItems, resetDrawnLayerAndAOI]);

  useEffect(() => {
    const map = mapRef.current;
    map?.on(L.Draw.Event.CREATED, (e) => {
      const aoiDrawn = e.layer.toGeoJSON() as GeoJSON.Feature;
      if (!aoiDrawn) {
        return;
      }

      const imagesContainingAOI = getImageLayersContainingDrawnAOI(aoiDrawn);
      if (imagesContainingAOI.length <= 0) {
        return;
      }
      if (
        options?.outlineClickedItem &&
        !layers.find((l) => l instanceof OutlineLayer)
      ) {
        removeLayersBySourceType(LayerSourceType.ASSET_OUTLINE);
        addLayer(new OutlineLayer(imagesContainingAOI[0].item));
      }

      options?.onImageClick?.(imagesContainingAOI[0]);

      setDrawnStacLayer(imagesContainingAOI[0]);
      setAOI(aoiDrawn);
    });

    return () => {
      map?.off('draw:created');
    };
  }, [
    addLayer,
    getImageLayersContainingDrawnAOI,
    mapRef,
    options?.outlineClickedItem,
    layers,
    options,
    removeLayersBySourceType,
  ]);

  return {
    drawnStacLayer,
    aoi,
    layersContainStacItems,
    resetDrawnLayerAndAOI,
  };
};
