import { Component } from 'react';
import { compare } from '../../utils/compareArray';
import type { IMapWrapper } from '../../declarations/mapDeclarations/Map';
import type { IGSState } from '../../constants/groundStations/actionTypes';
import type { IPIState } from '../../constants/pointsOfInterest/actionTypes';
import type {
  IPoint,
  IRIState,
} from '../../constants/regionsOfInterest/actionTypes';
import type { IFocus } from '../../constants/focus/actionTypes';
import { FocusTypes } from '../../constants/focus/actionTypes';
import { focusHandler } from '../../constants/focus/constants';
import {
  GROUND_STATION_FORM_NAME,
  POINT_FORM_NAME,
  POLYGON_FORM_NAME,
} from '../../constants/popUp/constants';
import type { IAnyKey } from '../../utils/getFormData';
import getFormData from '../../utils/getFormData';
import type { WithTranslation } from 'react-i18next';
import { withTranslation } from 'react-i18next';
import type { IMapTypes } from '../../constants/map/constants';
import { COORDINATES_SUB, MAP_TYPE } from '../../constants/map/constants';
import type { ISTState } from '../../constants/satelliteOrbits/actionTypes';
import type { IAstrum } from '../../constants/austrum/interfaces';

export interface IMapOwnProps {
  map?: IMapWrapper;
  mapType?: IMapTypes;
  coordinatesToFocus?: google.maps.LatLngLiteral;
  pageKey?: string;

  groundStations?: IGSState;
  pointsOfInterest?: IPIState;
  regionsOfInterest?: IRIState;
  satellites?: ISTState;
  focus?: IFocus;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  form?: any;
  astrum?: IAstrum;
}

export interface IMapDispatchProps {
  groundStationDrawing?: Function;
  loadGroundStations?: Function;
  pointOfInterestDrawing?: Function;
  regionOfInterestDrawing?: Function;
  satelliteDrawing?: Function;

  pointCompleted?: Function;
  regionCompleted?: Function;
  addContextMenu?: Function;
  resetFocus?: Function;
}

interface IMapState {
  updateControlData: Function;
}

export type IMapProps = IMapOwnProps & IMapDispatchProps;

const mapPropsUpdate = (
  oldProps: IGSState | IPIState | IRIState | ISTState,
  newProps: IGSState | IPIState | IRIState | ISTState,
  drawingFunction: Function,
  map: IMapWrapper,
  focusId: string
): void => {
  const compareArray = compare(oldProps.list, newProps.list);
  compareArray.forEach((item): void => {
    const focus = focusId === item.essence.id;
    const action = drawingFunction(item.essence, map, {
      focus,
      setEvents: true,
    })[item.action];
    if (action) {
      action();
    } else {
      console.warn('Action not found: ', item);
    }
  });
};

const setFocus = (
  id: string,
  type: FocusTypes,
  mapProps: IMapProps,
  inFocus: boolean
): void => {
  if (type === FocusTypes.COORDINATES_SUB) {
    return undefined;
  }
  const itemsName = focusHandler[type].propsName;
  const drawingFunction = focusHandler[type].drawing;
  const props: IMapProps & IAnyKey = mapProps;
  const item = props[itemsName].list.find(
    (i: { id: string }): boolean => i.id === id
  );
  if (item) {
    props[drawingFunction](item, props.map, { focus: inFocus }).update();
  }
};

export class Map extends Component<IMapProps & WithTranslation, IMapState> {
  public state: IMapState = {
    updateControlData: (): void => {},
  };

  public componentDidMount(): void {
    const map = this.props.map;
    const drawingManager = map.drawingManager;

    if (this.props.pointCompleted) {
      const pointCompleted = this.props.pointCompleted.bind(null, map);
      drawingManager.addListener('markercomplete', pointCompleted);
    }

    if (this.props.regionCompleted) {
      const regionCompleted = this.props.regionCompleted.bind(null, map);
      drawingManager.addListener('polygoncomplete', regionCompleted);
    }

    if (this.props.resetFocus) {
      const resetFocus = this.props.resetFocus.bind(null);
      map.addListener('click', resetFocus);
    }

    if (this.props.addContextMenu) {
      this.setState(
        { updateControlData: this.props.addContextMenu() },
        (): void => {
          this.state.updateControlData({});
        }
      );
    }
    if (this.props.astrum) {
      const { map, astrum } = this.props;
      map.setMapTypeId(astrum.name);
      map.setOptions(astrum.mapOption);
    }

    if (
      this.props.mapType === MAP_TYPE.GROUND_SEGMENT &&
      this.props.loadGroundStations
    ) {
      this.props.loadGroundStations(this.props.pageKey);
    }

    if (this.props.groundStations) {
      const focusId = this.props.focus ? this.props.focus.id : '';
      mapPropsUpdate(
        { list: [] },
        this.props.groundStations,
        this.props.groundStationDrawing,
        this.props.map,
        focusId
      );
    }
    if (this.props.pointsOfInterest) {
      const focusId = this.props.focus ? this.props.focus.id : '';
      mapPropsUpdate(
        { list: [] },
        this.props.pointsOfInterest,
        this.props.pointOfInterestDrawing,
        this.props.map,
        focusId
      );
    }
    if (this.props.regionsOfInterest) {
      const focusId = this.props.focus ? this.props.focus.id : '';
      mapPropsUpdate(
        { list: [] },
        this.props.regionsOfInterest,
        this.props.regionOfInterestDrawing,
        this.props.map,
        focusId
      );
    }
    if (this.props.satellites) {
      const focusId = this.props.focus ? this.props.focus.id : '';
      mapPropsUpdate(
        { list: [] },
        this.props.satellites,
        this.props.satelliteDrawing,
        this.props.map,
        focusId
      );
      this.state.updateControlData({ satellites: this.props.satellites });
    }
  }

  public componentDidUpdate(prevProps: Readonly<IMapProps>): void {
    if (this.props.groundStations !== prevProps.groundStations) {
      const focusId = this.props.focus ? this.props.focus.id : '';
      mapPropsUpdate(
        prevProps.groundStations,
        this.props.groundStations,
        this.props.groundStationDrawing,
        this.props.map,
        focusId
      );
    }
    if (this.props.pointsOfInterest !== prevProps.pointsOfInterest) {
      const focusId = this.props.focus ? this.props.focus.id : '';
      mapPropsUpdate(
        prevProps.pointsOfInterest,
        this.props.pointsOfInterest,
        this.props.pointOfInterestDrawing,
        this.props.map,
        focusId
      );
    }
    if (this.props.regionsOfInterest !== prevProps.regionsOfInterest) {
      const focusId = this.props.focus ? this.props.focus.id : '';
      mapPropsUpdate(
        prevProps.regionsOfInterest,
        this.props.regionsOfInterest,
        this.props.regionOfInterestDrawing,
        this.props.map,
        focusId
      );
    }
    if (this.props.satellites !== prevProps.satellites) {
      this.state.updateControlData({ satellites: this.props.satellites });
      mapPropsUpdate(
        prevProps.satellites,
        this.props.satellites,
        this.props.satelliteDrawing,
        this.props.map,
        ''
      );
    }
    if (prevProps?.focus && this.props.focus !== prevProps.focus) {
      const { type: oldType, id: oldId } = prevProps.focus;
      const { type: newType, id: newId } = this.props.focus;
      if (oldType) {
        setFocus(oldId, oldType, this.props, false);
      }
      if (newType) {
        setFocus(newId, newType, this.props, true);
        switch (newType) {
          case FocusTypes.GS_SUB_ID: {
            const groundStation = this.props.map.groundStations.find(
              (g): boolean => g.id === newId
            );
            this.props.map.panTo(groundStation.getPosition());
            if (this.props.focus.withPopUp) {
              this.props.map.getDiv().clientWidth;
              this.props.map.panBy(-this.props.map.getDiv().clientWidth / 4, 0);
            }
            break;
          }
          case FocusTypes.PI_SUB_ID: {
            const pointsOfInterest = this.props.map.pointsOfInterest.find(
              (g): boolean => g.id === newId
            );
            this.props.map.panTo(pointsOfInterest.getPosition());
            if (this.props.focus.withPopUp) {
              this.props.map.getDiv().clientWidth;
              this.props.map.panBy(-this.props.map.getDiv().clientWidth / 4, 0);
            }
            break;
          }
          case COORDINATES_SUB: {
            this.props.map.panTo(this.props.coordinatesToFocus);
            break;
          }
          case FocusTypes.RI_SUB_ID: {
            const regionsOfInterest = this.props.map.regionsOfInterest.find(
              (g): boolean => g.id === newId
            );
            const bounds: google.maps.LatLngBounds =
              new google.maps.LatLngBounds(null);
            regionsOfInterest.getPaths().forEach((path) => {
              path.forEach((elem: google.maps.LatLng) => bounds.extend(elem));
            });

            this.props.map.fitBounds(
              bounds,
              this.props.map.getDiv().clientWidth * 0.4
            );
            if (this.props.focus.withPopUp) {
              this.props.map.panBy(
                -this.props.map.getDiv().clientWidth * 0.2,
                0
              );
            }
          }
        }
      }
    }
    if (this.props.form !== prevProps.form) {
      {
        const form = getFormData(this.props.form, POLYGON_FORM_NAME, [
          'active',
          'values',
        ]);
        if (form) {
          const active: string = form.active;
          if (active) {
            if (active !== 'elevationAngle') {
              const matchedArray = active.match(/^paths\[([\d]+)]\[([\d]+)]/);
              const pathsIndex = Number(matchedArray[1]);
              const pointIndex = Number(matchedArray[2]);
              try {
                let panTo = form.values.paths[pathsIndex][pointIndex];
                panTo = { lat: Number(panTo.lat), lng: Number(panTo.lng) };
                const { map } = this.props;
                map.panTo(panTo);
                if (this.props.focus.withPopUp) {
                  map.getDiv().clientWidth;
                  map.panBy(-map.getDiv().clientWidth / 4, 0);
                }
              } catch (e) {
                console.warn('Paths not found', e);
              }
            }
            this.props.map.supportingPolygon.setOptions({
              map: this.props.map,
              paths: form.values.paths.map((path: IPoint[]) =>
                path.map((point: IPoint) => {
                  return {
                    lat: Number(point.lat),
                    lng: Number(point.lng),
                  };
                })
              ),
            });
          } else {
            this.props.map.supportingPolygon.setOptions({
              map: null,
              paths: [],
            });
          }
        }
      }
      {
        const form = getFormData(this.props.form, POINT_FORM_NAME, [
          'active',
          'values',
        ]);
        if (form) {
          const active: string = form.active;
          const { lat, lon } = form.values;
          if (active) {
            try {
              const panTo = { lat: Number(lat), lng: Number(lon) };
              const { map } = this.props;
              map.panTo(panTo);
              if (this.props.focus.withPopUp) {
                map.getDiv().clientWidth;
                map.panBy(-map.getDiv().clientWidth / 4, 0);
              }
            } catch (e) {
              console.debug('Point not found');
            }
          }

          this.props.map.supportingPoint.setOptions({
            zIndex: 10000,
            map: this.props.map,
            position: { lat: Number(lat), lng: Number(lon) },
          });
        } else {
          this.props.map.supportingPoint.setOptions({ map: null });
        }
      }
      {
        const form = getFormData(this.props.form, GROUND_STATION_FORM_NAME, [
          'active',
          'values',
        ]);
        if (form) {
          const active: string = form.active;
          const { lat, lon } = form.values;
          if (active) {
            try {
              const panTo = { lat: Number(lat), lng: Number(lon) };
              const { map } = this.props;
              map.panTo(panTo);
              if (this.props.focus.withPopUp) {
                map.getDiv().clientWidth;
                map.panBy(-map.getDiv().clientWidth / 4, 0);
              }
            } catch (e) {
              console.debug('Point not found');
            }
          }

          this.props.map.supportingGroundStation.setOptions({
            map: this.props.map,
            position: { lat: Number(lat), lng: Number(lon) },
          });
        } else {
          this.props.map.supportingGroundStation.setOptions({ map: null });
        }
      }
    }
    if (this.props.astrum !== prevProps.astrum) {
      const { map, astrum } = this.props;
      map.setMapTypeId(astrum.name);
      map.setOptions(astrum.mapOption);
    }
  }

  public componentWillUnmount(): void {
    if (this.props.map) {
      google.maps.event.clearInstanceListeners(this.props.map);
    }
  }

  public render(): null {
    return null;
  }
}

export default withTranslation()(Map);
