import classNames from 'classnames';
import React from 'react';

type Props = React.DetailedHTMLProps<
  React.TextareaHTMLAttributes<HTMLTextAreaElement>,
  HTMLTextAreaElement
> & { disabled?: boolean };

const Textarea = (props: Props) => {
  return (
    <textarea
      {...props}
      className={classNames(
        `h-64 bg-item-dark-contrast dark:bg-item-contrast w-full p-2`,
        {
          'bg-item-contrast-inactive dark:bg-item-dark-contrast-inactive':
            props.disabled,
        }
      )}
    ></textarea>
  );
};

export default Textarea;
